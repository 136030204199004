import React, { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import dayjs from 'dayjs';
import { useAccountingUploadSalesInfoPerformanceModalState } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/useState';
import { AccountingUploadSalesInfoPerformanceModal } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/components/modal';

export { Index as AccountingUploadSalesInfoPerformanceModalFeature };

const Index = () => {
  const { setMonth } = useAccountingUploadSalesInfoPerformanceModalState(
    useShallow((state) => ({
      setMonth: state.setMonth,
    }))
  );
  useEffect(() => {
    setMonth(dayjs(new Date()).month() + 1);
  }, [setMonth]);
  return <AccountingUploadSalesInfoPerformanceModal />;
};
