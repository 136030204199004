import React, { useContext, useRef } from 'react';
import { Box } from '@mui/material';
import { AccountingSettingsTexAccountingAccountGetCountContext } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/get-count/provider/context';
import { accountingSettingsManagementAccountingTableLayout } from '@front/src/features/accounting/features/settings/components/layouts/table';
import { useAccountingSettingsTaxAccountingAccountState } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/useState';

export { TableHeader as AccountingSettingsTexAccountingAccountTableHeader };

const TableHeader = () => {
  const { TableHeaderCell } = accountingSettingsManagementAccountingTableLayout;
  const widthInfo = useAccountingSettingsTaxAccountingAccountState((state) => state.widthInfo);
  const cellRef = useRef<HTMLDivElement>(null);
  const { detail } = useContext(AccountingSettingsTexAccountingAccountGetCountContext);
  if (detail?.countList.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
        }}
      >
        {Array.from({ length: 5 }, (_, i) => i + 1).map((item, index) => (
          <TableHeaderCell
            key={`header-${item}`}
            cellRef={cellRef}
            sx={{
              width: widthInfo?.depth === index ? widthInfo?.width : '200px',
            }}
          >
            {item}레벨 (0)
          </TableHeaderCell>
        ))}
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {Array.from({ length: detail?.countList.length ?? 5 }, (_, i) => i + 1).map((item, index) => (
        <TableHeaderCell
          key={`header-${item}`}
          cellRef={cellRef}
          sx={{
            width: widthInfo?.depth === index ? widthInfo?.width : '200px',
          }}
        >
          {item}레벨 ({detail?.countList[index]})
        </TableHeaderCell>
      ))}
    </Box>
  );
};
