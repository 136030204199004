import React from 'react';
import { personalSettingsModalUISettingsModalSettingsRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/repository/repository';
import { TableRow } from '@mui/material';
import { Tbc } from '@front/src/components/ui-builder/table';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { PersonalSettingsModalUISettingsModalSettingsSelectableHeaderTableData } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/components/SelectableHeaderTableData';

export { SelectableHeaderTableContent as PersonalSettingsModalUISettingsModalSettingsSelectableHeaderTableContent };
interface Props {
  sectionId: number;
  authMenuId: number | undefined;
}
const SelectableHeaderTableContent = ({ sectionId, authMenuId }: Props) => {
  const { data: list } =
    personalSettingsModalUISettingsModalSettingsRepository.useGetModalHeaderList(
      sectionId,
      authMenuId
    );
  if (list === undefined) {
    return (
      <TableRow>
        <Tbc
          align="center"
          colSpan={4}
        >
          모달화 설정할 헤더를 선택해주세요.
        </Tbc>
      </TableRow>
    );
  }
  if (list.length === 0) {
    return <NoResult colSpan={4} />;
  }
  return <PersonalSettingsModalUISettingsModalSettingsSelectableHeaderTableData />;
};
