import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { AffiliatedCompanyPersonDetailModalCharacterCharacterContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/character/widgets/context';

export { ButtonGroup as AffiliatedCompanyPersonDetailModalCharacterCharacterButtonGroup };
const ButtonGroup = () => {
  const {
    h: { onCreateCharacter, onDeleteCharacter, setIdList },
    idList,
    readOnly,
  } = useContext(AffiliatedCompanyPersonDetailModalCharacterCharacterContext);
  const handleDeleteClick = useCallback(() => {
    onDeleteCharacter({ idList });
    setIdList([]);
  }, [onDeleteCharacter, idList, setIdList]);
  if (readOnly) {
    return <></>;
  }
  return (
    <>
      <Button
        disabled={readOnly}
        onClick={handleDeleteClick}
      >
        선택삭제
      </Button>
      <Button
        disabled={readOnly}
        onClick={onCreateCharacter}
      >
        특징 추가
      </Button>
    </>
  );
};
