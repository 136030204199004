import { approvalDocumentUrlPath } from '@front/src/features/drawer-approval/utils/constants';
import axios from '@front/src/config/axios';
import type {
  ApprovalDocumentView,
  HasFileItemView,
} from '@front/src/features/drawer-approval/types/view';
import type { AxiosResponse } from 'axios';
import type {
  ApprovalDocumentCreateParameter,
  ApprovalDocumentUpdateParameter,
  ApprovalHasFileListParams,
} from '@front/src/features/drawer-approval/types/parameter';

const url = {
  getApprovalDocumentList: () => `${approvalDocumentUrlPath}`,
  createApprovalDocument: () => `${approvalDocumentUrlPath}`,
  getApprovalDocumentSourceTitle: () => `${approvalDocumentUrlPath}/source-title`,

  deleteTemp: (documentId: number) => `${approvalDocumentUrlPath}/${documentId}`,
  updateTemp: () => `${approvalDocumentUrlPath}`,

  getHasFileList: () => '/api/file-item/list',
};

export const approvalApi = {
  getApprovalDocumentList: async <T = ApprovalDocumentView[]>() => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getApprovalDocumentList());
    return data;
  },
  createApprovalDocument: async <T = unknown>(params: ApprovalDocumentCreateParameter) => {
    const formData = new FormData();
    formData.append(
      'request',
      new Blob([JSON.stringify(params.request)], { type: 'application/json' })
    );
    if (params.fileItemList != undefined) {
      Array.from(params.fileItemList).forEach((file) => {
        formData.append('fileItemList', file?.multipartFile ?? '');
      });
    }
    formData.append(
      'fileItemMetaList',
      new Blob([JSON.stringify(params.fileItemList)], { type: 'application/json' })
    );

    formData.append('beforeFileItem', new Blob([JSON.stringify({})], { type: 'application/json' }));

    formData.append(
      'beforeFileItemMeta',
      new Blob([JSON.stringify({})], { type: 'application/json' })
    );

    formData.append('afterFileItem', new Blob([JSON.stringify({})], { type: 'application/json' }));

    formData.append(
      'afterFileItemMeta',
      new Blob([JSON.stringify({})], { type: 'application/json' })
    );

    await axios.post(url.createApprovalDocument(), formData);
  },
  getApprovalDocumentSourceTitle: async <T = string>(params: {
    menuId?: string;
    dataId?: string;
  }) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getApprovalDocumentSourceTitle(), {
      params,
    });
    return data;
  },

  deleteTemp: async <T = unknown>(documentId: number) =>
    await axios.delete<T, AxiosResponse<T>>(url.deleteTemp(documentId)),
  updateTemp: async <T = unknown>(params: ApprovalDocumentUpdateParameter) => {
    const formData = new FormData();
    formData.append(
      'request',
      new Blob([JSON.stringify(params.request)], { type: 'application/json' })
    );
    if (params.addFileItemList != undefined) {
      Array.from(params.addFileItemList).forEach((file) => {
        formData.append('addFileItemList', file?.multipartFile ?? '');
      });
    }
    formData.append(
      'fileItemMetaList',
      new Blob([JSON.stringify(params.addFileItemList)], { type: 'application/json' })
    );

    formData.append('beforeFileItem', new Blob([JSON.stringify({})], { type: 'application/json' }));

    formData.append(
      'beforeFileItemMeta',
      new Blob([JSON.stringify({})], { type: 'application/json' })
    );

    formData.append('afterFileItem', new Blob([JSON.stringify({})], { type: 'application/json' }));

    formData.append(
      'afterFileItemMeta',
      new Blob([JSON.stringify({})], { type: 'application/json' })
    );
    await axios.put<T, AxiosResponse<T>>(url.updateTemp(), formData);
  },

  getHasFileList: async <T = HasFileItemView[]>(params: ApprovalHasFileListParams) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getHasFileList(), { params });
    return data;
  },
};
