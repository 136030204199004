import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { fixedContentsHeight } from '@front/util/ConstantCommonVariable';
import { voteAction } from '@front/ost_vote/action';
import { initialVoteMemoQuery } from '@front/ost_vote/query';
import MemoDrawerService from '@front/components/Memo/route/drawer';
import VoteFormService from '@front/ost_vote/service/memo/form';
import VoteListService from '@front/ost_vote/service/memo/list';
import VoteFilterService from '@front/ost_vote/service/memo/filter';

const VoteMemoService = () => {
  const dispatch = useDispatch();
  const memoDrawerOpen = useSelector((root: RootState) => root.vote.memoDrawerOpen, shallowEqual);
  const id = useSelector((root: RootState) => root.proposal.id, shallowEqual);
  const setMemoDrawerOpen = useCallback(
    (open: boolean) => dispatch(voteAction.setMemoDrawerOpen(open)),
    [dispatch]
  );
  const [formHeight, setFormHeight] = useState<number>(fixedContentsHeight);

  useEffect(() => {
    if (id) {
      dispatch(voteAction.setMemoFilter(initialVoteMemoQuery));
    }
  }, [id]);
  return (
    <MemoDrawerService
      isOpen={memoDrawerOpen}
      setDrawer={setMemoDrawerOpen}
      children={
        <>
          <VoteFormService setFormHeight={setFormHeight} />
          <VoteFilterService id={id} />
          <VoteListService formHeight={formHeight} />
        </>
      }
    />
  );
};

export default VoteMemoService;
