import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalApi } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/query/api';
import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/types/parameter';

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalMutation = {
  useCreate: () => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (
        params: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateParameter
      ) => projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalApi.create(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'affiliated-company', 'affiliated-company-person'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
