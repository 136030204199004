import { create } from 'zustand';

import { noOp } from '@front/common/contants';
import type { DefaultFunction } from '@front/type/Function';
import type { AlertView, ConfirmView } from '@front/src/features/dialog/types';
import { DialogStatus } from '@front/src/features/dialog/types';

interface State {
  confirmProps?: ConfirmView;
  alertProps?: AlertView;
  openConfirm: (props: ConfirmView) => void;
  openAlert: (props: AlertView) => void;
  onClose: DefaultFunction;
}

export { useState as useDialogState };

const useState = create<State>((set) => ({
  openConfirm: (props: ConfirmView) => {
    const confirmProps: ConfirmView = {
      title: props.title ?? '확인',
      status: props.status ? props.status : DialogStatus.OK,
      closeText: props.closeText ?? '취소',
      afterClose: noOp,
      children: props.children,
      lineBreakChildren: props.lineBreakChildren,
      afterConfirm: props.afterConfirm,
      confirmText: props.confirmText ?? '확인',
    };
    return set(() => ({ confirmProps }));
  },
  openAlert: (props) => {
    const alertProps: AlertView = {
      title: props.title ? props.title : '알림',
      status: props.status ? props.status : DialogStatus.OK,
      closeText: props.closeText ? props.closeText : '닫기',
      children: props.children,
      afterClose: props.afterClose ?? noOp,
    };
    return set(() => ({ alertProps }));
  },
  onClose: () => set(() => ({ confirmProps: undefined, alertProps: undefined })),
}));
