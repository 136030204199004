import React, { type Dispatch, type SetStateAction } from 'react';
import ButtonIconUI from '@front/src/components/components-with-design/component/ButtonIconUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  onOpen: () => void;
  setIsCreate: Dispatch<SetStateAction<boolean>>;
}

export default function WorkOutputUpdateButtonIcon({ onOpen, setIsCreate }: Readonly<Props>) {
  const handleClick = () => {
    setIsCreate(false);
    onOpen();
  };
  return (
    <ButtonIconUI
      shape="tertiary"
      size="medium"
      onClick={handleClick}
    >
      <FontAwesomeIcon icon="pen" />
    </ButtonIconUI>
  );
}
