import { shallow } from 'zustand/shallow';
import { affiliatedCompanyPersonRelationshipRepository } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/repository/repository';
import { useAffiliatedCompanyPersonDetailModalRelationshipState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/widgets/useState';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';

export { useLogic as useAffiliatedCompanyPersonDetailModalRelationshipLogic };

const useLogic = (menuId) => {
  const { modalId } = useAffiliatedCompanyPersonState(
    (state) => ({
      modalId: state.modalId,
    }),
    shallow
  );
  const { idList, setIdList } = useAffiliatedCompanyPersonDetailModalRelationshipState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { run: onRelationshipCreate } =
    affiliatedCompanyPersonRelationshipRepository.usePersonRelationshipCreate(menuId);
  const { isLoading, data: relationshipList } =
    affiliatedCompanyPersonRelationshipRepository.usePersonRelationshipGet(modalId, menuId);
  const { run: onRelationshipUpdate } =
    affiliatedCompanyPersonRelationshipRepository.usePersonRelationshipUpdate(menuId);
  const { run: onRelationshipDelete } =
    affiliatedCompanyPersonRelationshipRepository.usePersonRelationshipDelete(menuId);
  const h = {
    onRelationshipCreate: () => onRelationshipCreate(modalId),
    onRelationshipUpdate,
    onRelationshipDelete,
    setIdList,
  };
  const d = { relationshipList };
  return { d, h, isLoading, idList };
};
