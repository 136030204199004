import React from 'react';
import { SalesInfoActivityTableCellAddAffiliates } from '@front/src/features/project-sales-info/features/activity/widgets/activity-user-item/components/cell-add-affiliates';
import { SalesInfoActivityTableCellUser } from '@front/src/features/project-sales-info/features/activity/widgets/activity-user-item/components/cell-user';

export { Row as ProjectSalesInfoActivityUserRow };

const Row = () => (
  <>
    <SalesInfoActivityTableCellUser />
    <SalesInfoActivityTableCellAddAffiliates />
  </>
);
