import React, { useContext } from 'react';
import { useMyPageAuthorizationState } from '@front/src/features/my-page/features/authorization/useState';
import { useShallow } from 'zustand/react/shallow';
import { MyPageAuthorizationTabContext } from '@front/src/features/my-page/features/authorization/widgets/tab/provider/context';
import { TableRow } from '@mui/material';
import { WorkTd } from '@front/src/features/work/features/work/components/work-td';
import { MyPageAuthorizationTabRow } from '@front/src/features/my-page/features/authorization/widgets/tab/components/row';

export { TabList as MyPageAuthorizationTabList };

const TabList = () => {
  const { list, adminMenuList, notDevelopmentMenuList } = useContext(MyPageAuthorizationTabContext);
  const { tableRowNum } = useMyPageAuthorizationState(
    useShallow((state) => ({
      tableRowNum: state.tableRowNum,
    }))
  );
  if (!list) {
    return (
      <>
        {new Array(tableRowNum).fill(0).map((_, i) => (
          <TableRow key={`tab-${i}`}>
            <WorkTd> </WorkTd>
            <WorkTd> </WorkTd>
            <WorkTd> </WorkTd>
          </TableRow>
        ))}
      </>
    );
  }
  return (
    <>
      {list.map((item) => (
        <MyPageAuthorizationTabRow
          key={item.id}
          item={item}
          adminMenuList={adminMenuList}
          notDevelopmentMenuList={notDevelopmentMenuList}
        />
      ))}
      {new Array(tableRowNum - list.length).fill(0).map((_, i) => (
        <TableRow key={`tab-${i}`}>
          <WorkTd> </WorkTd>
          <WorkTd> </WorkTd>
          <WorkTd> </WorkTd>
        </TableRow>
      ))}
    </>
  );
};
