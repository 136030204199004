import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/widgets/context';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/widgets/useState';
import { useCustomDialog } from '@front/src/features/dialog';
import { handleDeleteIdConfirmAfterClick } from '@front/src/utils';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/widgets/useState';

export { ButtonGroup as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyButtonGroup };

const ButtonGroup = () => {
  const {
    h: { onDelete },
  } = useContext(ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyContext);
  const { setIsOpen, setId, id } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalState(
    useShallow((state) => ({
      setIsOpen: state.setIsOpen,
      setId: state.setId,
      id: state.id,
    }))
  );
  const handleSettingClick = useCallback(() => {
    if (typeof id === 'undefined') {
      return;
    }
    setIsOpen(true);
  }, [id, setIsOpen]);
  const handleCreateClick = useCallback(() => {
    setId(undefined);
    setIsOpen(true);
  }, [setId, setIsOpen]);
  const { confirm } = useCustomDialog();
  const handleDeleteClick = useCallback(() => {
    handleDeleteIdConfirmAfterClick(id, confirm, '삭제하시겠습니까?', () => {
      onDelete(id);
      setId(undefined);
    });
  }, [id, confirm, setId, onDelete]);
  const { readOnly } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      <Button
        onClick={handleDeleteClick}
        disabled={readOnly}
      >
        선택 삭제
      </Button>
      <Button
        onClick={handleSettingClick}
        disabled={readOnly}
      >
        선택 행 관계사 설정
      </Button>
      <Button
        onClick={handleCreateClick}
        disabled={readOnly}
      >
        행 추가
      </Button>
    </>
  );
};
