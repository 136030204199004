import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAccountingUploadSalesInfoGoalModalListLogic } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/widgets/list/provider/useLogic';
import { AccountingUploadSalesInfoGoalModalListContext } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/widgets/list/provider/context';

export { Provider as AccountingUploadSalesInfoGoalModalListProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { list } = useAccountingUploadSalesInfoGoalModalListLogic();
  const value = useMemo(
    () => ({
      list,
    }),
    [list]
  );
  return (
    <AccountingUploadSalesInfoGoalModalListContext.Provider value={value}>
      {children}
    </AccountingUploadSalesInfoGoalModalListContext.Provider>
  );
};
