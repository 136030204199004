import React, { useEffect } from 'react';
import { MyPageAuthorizationMenuWidget } from '@front/src/features/my-page/features/authorization/widgets/menu/widget';
import { MyPageAuthorizationMenuTable } from '@front/src/features/my-page/features/authorization/components/menu-table';
import { Box } from '@mui/material';
import { MyPageAuthorizationTabWidget } from '@front/src/features/my-page/features/authorization/widgets/tab/widget';
import { MyPageAuthorizationTable } from '@front/src/features/my-page/features/authorization/components/authorization-table';
import { useMyPageAuthorizationState } from '@front/src/features/my-page/features/authorization/useState';
import { useShallow } from 'zustand/react/shallow';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';

const MyPageAuthorizationFeature = ({ name, ...rest }: Readonly<SectionComponentProps>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const { setClicked, setTabId } = useMyPageAuthorizationState(
    useShallow((state) => ({
      setClicked: state.setClicked,
      setTabId: state.setTabId,
    }))
  );
  useEffect(
    () => () => {
      setClicked([]);
      setTabId(undefined);
    },
    [setClicked, setTabId]
  );
  return (
    <Layout>
      <Header title={name} />
      <Body>
        <Box
          sx={{
            display: 'flex',
            padding: '20px',
          }}
        >
          <Box
            sx={{
              flex: '4',
            }}
          >
            <MyPageAuthorizationMenuTable>
              <MyPageAuthorizationMenuWidget {...rest} />
            </MyPageAuthorizationMenuTable>
          </Box>
          <Box
            sx={{
              width: '4px',
              height: '850px',
              background: 'black',
            }}
          />
          <Box
            sx={{
              flex: '3',
            }}
          >
            <MyPageAuthorizationTable>
              <MyPageAuthorizationTabWidget {...rest} />
            </MyPageAuthorizationTable>
          </Box>
        </Box>
      </Body>
    </Layout>
  );
};

export default MyPageAuthorizationFeature;
