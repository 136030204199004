import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import TextBox from '@front/layouts/Text';
import { ColorPalette } from '@front/assets/theme';
import Button from '@front/layouts/Button';
import ImageModal from '@front/components/FileUploadWithPreview/ImageModal';
import useDialog from '@front/dialog/hook';
import { fileToView } from '@front/file-item';

export interface Props<T extends { id: any; [x: string]: any }> {
  update: (params: { id: any; [x: string]: any }) => void;
  item: T;
  fieldName: string;
  deleteFlagName: string;
  readOnly?: boolean;
  label?: string;
}

export const FileUploadWithPreview = <T extends { id: any; [x: string]: any }>({
  update,
  item,
  fieldName,
  deleteFlagName,
  readOnly = false,
  label,
}: Props<T>) => {
  const [imageSrc, setImageSrc] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { confirm, alert } = useDialog();

  const onChange = (e) => {
    if (
      !e.target ||
      !e.target.files ||
      e.target.files.length === 0 ||
      e.target.files[0].type.split('/')[0] !== 'image'
    ) {
      alert('이미지 파일을 확인해주세요.');
      return;
    }
    confirm({
      children: '이미지 파일을 등록하시겠습니까?',
      confirmText: '확인',
      afterConfirm: () => {
        update({
          id: item.id,
          [fieldName]: fileToView(e.target.files![0]),
        });
      },
      afterClose: () => {
        e.target.value = '';
      },
    });
  };

  useEffect(() => {
    if (!item[fieldName]) {
      setImageSrc('');
      return;
    }

    if (item[fieldName].id) {
      setImageSrc(`/api/file-item/${item[fieldName].id}`);
      return;
    }
  }, [item]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '5px',
        }}
      >
        <Box>
          <TextBox variant={'heading3'}>{label}</TextBox>
        </Box>
        {!item[fieldName] ? (
          <>
            {!readOnly && (
              <Button component="label">
                이미지 선택
                <Box
                  sx={{
                    display: 'none',
                  }}
                >
                  <input
                    hidden={true}
                    type="file"
                    accept="image/*"
                    onChange={onChange}
                  />
                </Box>
              </Button>
            )}
          </>
        ) : (
          <>
            {!readOnly && (
              <Button
                shape="basic3"
                onClick={() => {
                  confirm({
                    children: '이미지 파일을 삭제하시겠습니까?',
                    confirmText: '확인',
                    afterConfirm: () => {
                      update({
                        id: item.id,
                        [deleteFlagName]: true,
                      });
                    },
                  });
                }}
              >
                이미지 삭제
              </Button>
            )}
          </>
        )}
      </Box>
      <Box
        sx={{
          height: '350px',
          width: '100%',
          border: `1px solid ${ColorPalette._e4e9f2}`,
          cursor: `${item[fieldName] ? 'pointer' : 'default'}`,
        }}
        onClick={() => {
          if (imageSrc) {
            setIsOpen(true);
          }
        }}
      >
        {!readOnly && (
          <>
            {!item[fieldName] && (
              <Box
                sx={{
                  height: '350px',
                  width: '100%',
                  position: 'relative',
                  textAlign: 'center',
                  lineHeight: '350px',
                  color: ColorPalette._9b9ea4,
                  fontSize: '40px',
                }}
              >
                +
                <input
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    opacity: 0,
                    top: 0,
                    left: 0,
                    cursor: 'pointer',
                  }}
                  type="file"
                  accept="image/*"
                  onChange={onChange}
                />
              </Box>
            )}
          </>
        )}
        {item[fieldName] && imageSrc && (
          <img
            src={imageSrc}
            alt={`제안 이미지 ${item[fieldName]}`}
            style={{
              height: '350px',
              width: '100%',
              objectFit: 'contain',
            }}
          />
        )}
      </Box>
      {item[fieldName] && isOpen && (
        <ImageModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          imageSrc={imageSrc}
        />
      )}
    </Box>
  );
};
