import { create } from 'zustand';
import { UISettingsType } from '@front/src/features/personal-settings-modal/features/ui-settings/utils/constant';

export { useState as usePersonalSettingsModalUISettingsState };

interface State {
  sectionId?: number;
  type: UISettingsType;
}

interface Actions {
  setSectionId: (sectionId?: number) => void;
  setType: (type: UISettingsType) => void;
}

const useState = create<State & Actions>((set) => ({
  setSectionId: (sectionId) => set(() => ({ sectionId, type: UISettingsType.HEADER_SETTINGS })),
  type: UISettingsType.HEADER_SETTINGS,
  setType: (type) => set(() => ({ type })),
}));
