import React, { forwardRef, useCallback, useMemo } from 'react';
import { TableBody, TableCell, TableRow as MuiTableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import type { TableComponents } from 'react-virtuoso';
import { TableVirtuoso } from 'react-virtuoso';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';
import { RowClickTableContainer } from '@front/src/components/layout/table/row-click-table-container';

export { Index as MuiTableVirtualizer };

type NestedKeys<T> = T extends object
  ? {
      [K in keyof T]: K | DeferredNestedKeys<T[K]>;
    }[keyof T]
  : never;

type DeferredNestedKeys<T> = T extends any ? NestedKeys<T> : never;

interface ColumnData<T> {
  dataKey: NestedKeys<T> | 'checkbox' | '' | string;
  label: string;
  width: number | string;
}

interface RowComponentProps<T> {
  item: T;
  index?: number;
  menuId?: number | undefined;
}

interface MuiVirtualizerProps<T> {
  columns: ColumnData<T>[];
  list?: T[];
  setId?: (id?: number) => void;
  RowComponent: React.ComponentType<RowComponentProps<T>>;
  colSpan: number;
  isListLoading?: boolean;
  isListEmpty?: boolean;
  isTableFit?: boolean;
  NewRow?: React.ComponentType<RowComponentProps<T>>;
  menuId?: number | undefined;
}

interface TableRowProps<T> {
  item: T;
}

interface WithId {
  id: number;
}

const Index = <T extends WithId>({
  columns,
  list,
  setId,
  RowComponent,
  colSpan,
  isListLoading,
  isListEmpty,
  isTableFit,
  NewRow,
  menuId,
}: MuiVirtualizerProps<T>) => {
  const LoadedTableRow: React.FC<TableRowProps<T>> = useCallback(
    ({ item, ...props }: TableRowProps<T>) => {
      const handleRowClick = (e) => {
        e.stopPropagation();
        setId?.(item.id);
      };

      if (NewRow) {
        return (
          <NewRow
            item={item}
            {...props}
          />
        );
      }

      return (
        <MuiTableRow
          {...props}
          hover={true}
          style={{ cursor: 'pointer' }}
          onClick={handleRowClick}
        />
      );
    },
    [NewRow, setId]
  );

  const VirtuosoTableComponents: TableComponents<T> = useMemo(
    () => ({
      Scroller: forwardRef<HTMLDivElement>((props, ref) => (
        <RowClickTableContainer
          setId={setId}
          {...props}
          virtualRef={ref}
        />
      )),
      Table: (props) => (
        <Table
          {...props}
          style={{ width: isTableFit ? '100%' : '100dvw', tableLayout: 'fixed' }}
        />
      ),
      TableHead: undefined,
      TableRow: isListLoading
        ? () => (
            <TableLoadingSpinner
              colSpan={colSpan}
              height="60px"
            />
          )
        : isListEmpty
        ? () => (
            <NoResult
              colSpan={colSpan}
              height="60px"
            />
          )
        : LoadedTableRow,
      TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
        <TableBody
          {...props}
          ref={ref}
        />
      )),
    }),
    [colSpan, isListEmpty, isListLoading, isTableFit, LoadedTableRow, setId]
  );

  const fixedHeaderContent = () => (
    <MuiTableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align="center"
          style={{ width: column.width, whiteSpace: 'nowrap' }}
          sx={{
            backgroundColor: '#f1f5fc !important',
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </MuiTableRow>
  );

  const rowContent = (_index: number, row: T) => (
    <React.Fragment>
      <RowComponent
        item={row}
        index={_index}
        menuId={menuId}
      />
    </React.Fragment>
  );
  return (
    <TableVirtuoso
      data={isListLoading || isListEmpty ? [{} as T] : list}
      components={VirtuosoTableComponents}
      fixedHeaderContent={fixedHeaderContent}
      itemContent={rowContent}
    />
  );
};
