import React from 'react';
import dayjs from 'dayjs';
import Input from '@front/layouts/Input';
import type { ArrangementCommentParameter } from '@front/ost_proposal/parameter';
import type {
  ArrangementCommentId,
  ArrangementCommentVO,
  ProposalArrangementCommentEditVO,
  ProposalContributorVO,
  ProposalEstimatorVO,
} from '@front/ost_proposal/domain';
import { ProposalStatus } from '@front/ost_proposal/domain';
import { Box, Typography } from '@mui/material';
import TextBox from '@front/layouts/Text';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { ColorPalette } from '@front/assets/theme';
import Button from '@front/layouts/Button';
import useDialog from '@front/dialog/hook';
import { TargetPage } from '@front/navigate/domain';
import type { UserVO } from '@front/user/domain';

interface Props {
  item: ArrangementCommentVO;
  updateArrangementComment: (comment: ArrangementCommentParameter) => void;
  deleteArrangementComment: (id: ArrangementCommentId) => void;
  setArrangementCommentEditInput: (state: ProposalArrangementCommentEditVO) => void;
  setArrangementCommentParentId: (id: ArrangementCommentId | undefined) => void;
}

function ArrangementCommentItem({
  item,
  updateArrangementComment,
  setArrangementCommentEditInput,
  setArrangementCommentParentId,
}: Props) {
  const { error } = useDialog();
  const arrangementCommentInput = useSelector(
    (root: RootState) => root.proposal.arrangementCommentInput,
    shallowEqual
  );
  const status = useSelector((state: RootState) => state.proposal.detail!.status, shallowEqual);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const [estimatorList] = useSelector(
    (state: RootState) => [state.proposal.estimatorList],
    shallowEqual
  );
  const [contributorViewList] = useSelector(
    (state: RootState) => [state.proposal.detail!.contributorViewList],
    shallowEqual
  );
  const commentParentId = useSelector(
    (root: RootState) => root.proposal.commentParentId,
    shallowEqual
  );

  const currentPage = useSelector(
    (root: RootState) => root.navigation.currentPage!.targetPage,
    shallowEqual
  );

  return (
    <Box
      sx={{
        display: 'inherit',
        gridArea: 'contents',
        rowGap: '2px',
      }}
    >
      <Box>
        <TextBox variant="body7">
          {currentPage === TargetPage.VOTE ? (
            <>{getUserName(contributorViewList, estimatorList, item.writer)}</>
          ) : (
            <>{item.writer.name}</>
          )}
        </TextBox>
      </Box>
      <Box>
        {!arrangementCommentInput.show && (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              padding: '12px 0',
            }}
          >
            <Typography
              component="span"
              sx={{
                fontSize: '13px',
                color: ColorPalette._252627,
                fontWeight: 'bold',
                paddingRight: '5px',
              }}
            >
              {currentPage === TargetPage.VOTE && item.recipient ? (
                <>{getUserName(contributorViewList, estimatorList, item.recipient)}</>
              ) : (
                <>{item.recipient ? item.recipient.name : ''}</>
              )}
            </Typography>
            <Typography
              component="span"
              sx={{
                fontSize: '13px',
                color: ColorPalette._252627,
                wordBreak: 'break-all',
                whiteSpace: 'break-spaces',
              }}
            >
              {`${item.content}`}
            </Typography>
          </Box>
        )}
        {arrangementCommentInput.show && arrangementCommentInput.id === item.id && (
          <Input
            inputRef={inputRef}
            multiline
            variant="outlined"
            defaultValue={item.content || ''}
            sx={{
              border: `1px solid ${ColorPalette._e4e9f2}`,
            }}
            endAdornment={
              <>
                <Button
                  shape="basic3"
                  onClick={() => {
                    setArrangementCommentEditInput({
                      id: undefined,
                      show: false,
                    });
                  }}
                  sx={{
                    marginRight: '8px',
                  }}
                >
                  취소
                </Button>
                <Button
                  onClick={() => {
                    if (inputRef.current?.value.trim() === '') {
                      error('내용을 입력해주세요.');
                      inputRef.current.value = '';
                    } else {
                      if (inputRef.current) {
                        updateArrangementComment({
                          id: item.id,
                          content: inputRef.current.value,
                        });
                        setArrangementCommentEditInput({
                          id: undefined,
                          show: false,
                        });
                        inputRef.current.value = '';
                      }
                    }
                  }}
                >
                  수정
                </Button>
              </>
            }
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '100px 50px',
        }}
      >
        <TextBox variant="body12">
          {item.modifiedAt
            ? dayjs(item.modifiedAt).format('YYYY-MM-DD HH:mm')
            : dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')}
        </TextBox>
        {loginUser &&
          loginUser.id !== item.writer.id &&
          item.isPossibleToReply &&
          (status === ProposalStatus.WRITING || status === ProposalStatus.SUBMISSION) && (
            <TextBox
              onClick={() => {
                if (commentParentId) {
                  setArrangementCommentParentId(undefined);
                } else {
                  setArrangementCommentParentId(item.id);
                }
              }}
              onBlur={() => {
                setArrangementCommentParentId(undefined);
              }}
              sx={{
                cursor: 'pointer',
              }}
              variant="body12"
            >
              답글 달기
            </TextBox>
          )}
      </Box>
    </Box>
  );
}

function getUserName(
  contributorViewList: ProposalContributorVO[],
  estimatorViewList: ProposalEstimatorVO[],
  user: UserVO
): string {
  const isContributor = contributorViewList.some(
    (contributorView) => contributorView.contributor && contributorView.contributor.id === user.id
  );
  if (isContributor) {
    return '제안자';
  }
  const isEstimator = estimatorViewList.some(
    (estimatorView) => estimatorView.estimator && estimatorView.estimator.id === user.id
  );
  if (isEstimator) {
    return '심사자';
  }
  return user.name;
}

export default React.memo(ArrangementCommentItem);
