import type { ProposalShortVO } from '@front/ost_proposal/domain';
import type { VoteId, VoteMemoVO, VoteVO } from '@front/ost_vote/domain';
import type { VoteMemoQuery, VoteQuery } from '@front/ost_vote/query';
import { initialVoteMemoQuery, initialVoteQuery } from '@front/ost_vote/query';
import { createReducer } from 'typesafe-actions';
import { VoteAction } from '@front/ost_vote/action';

export interface VoteState {
  /**
   * 투찰 목록 및 상세에 필요한 상태
   */
  id?: VoteId;
  listDrawerOpen: boolean;
  filter: VoteQuery;
  proposalList: ProposalShortVO[];
  loading: boolean;
  detail?: VoteVO;
  searchFilterOpen: boolean;
  /**
   * 투찰 메모 관련 상태
   */
  memoDrawerOpen: boolean;
  memoList: VoteMemoVO[];
  memoLoading: boolean;
  memoFilter: VoteMemoQuery;
}

const initial: VoteState = {
  listDrawerOpen: true,
  filter: initialVoteQuery,
  proposalList: [],
  loading: false,
  searchFilterOpen: false,
  /**
   * 투찰 관련 초기 상태
   */
  memoDrawerOpen: true,
  memoList: [],
  memoLoading: false,
  memoFilter: initialVoteMemoQuery,
};

export const voteReducer = createReducer(initial, {
  /**
   * 투찰 목록 및 상세에 필요한 핸들러
   */
  [VoteAction.setId]: (state, action) => ({
    ...state,
    id: action.payload,
  }),
  [VoteAction.toggleDrawer]: (state, action) => ({
    ...state,
    listDrawerOpen: action.payload,
  }),
  [VoteAction.setList]: (state, action) => ({
    ...state,
    proposalList: action.payload,
  }),
  [VoteAction.setLoading]: (state, action) => ({
    ...state,
    loading: action.payload,
  }),
  [VoteAction.setFilter]: (state, action) => ({
    ...state,
    filter: action.payload,
  }),
  [VoteAction.searchFilterOpen]: (state, action) => ({
    ...state,
    searchFilterOpen: action.payload,
  }),
  /**
   * 투찰 정보 set
   */
  [VoteAction.setVote]: (state, action) => ({
    ...state,
    detail: action.payload,
  }),
  /**
   * 투찰 메모 관련 핸들러
   */
  [VoteAction.setMemoDrawerOpen]: (state, action) => ({
    ...state,
    memoDrawerOpen: action.payload,
  }),
  [VoteAction.setMemoList]: (state, action) => ({
    ...state,
    memoList: action.payload,
  }),
  [VoteAction.setMemoLoading]: (state, action) => ({
    ...state,
    memoLoading: action.payload,
  }),
  [VoteAction.setMemoFilter]: (state, action) => ({
    ...state,
    memoFilter: action.payload,
  }),
});
