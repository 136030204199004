import { projectSalesInfoContractEarningsQuery } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/query';

export const projectSalesInfoContractEarningsRepository = {
  useContractEarningsStatusGet: (id?: number) => {
    const { data, isLoading } =
      projectSalesInfoContractEarningsQuery.useContractEarningsStatusGet(id);
    return {
      data,
      isLoading,
    };
  },
  useContractEarningsGet: (id: number) => {
    const { data, isLoading } = projectSalesInfoContractEarningsQuery.useContractEarningsGet(id);
    return {
      data,
      isLoading,
    };
  },
};
