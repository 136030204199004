import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { SalesInfoActivityUserUpdateParams } from '@front/src/features/project-sales-info/features/activity/types/parameters';

export { context as ProjectSalesInfoActivityUserItemContext };

interface State {
  onCreate: (id: number) => void;
  onUpdate: (params: SalesInfoActivityUserUpdateParams) => void;
  onDelete: (id: number) => void;
}

const context = createContext<State>({
  onCreate: noOp,
  onUpdate: noOp,
  onDelete: noOp,
});
