import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { TableBody, TableHead, TableRow } from '@mui/material';

import { OldTd, OldTh, Table } from '@front/layouts/Table';
import type { RootState } from '@front/services/reducer';
import { DetailStatusTableRow } from '@front/ost_reward/service/detail/detail-status-table-row';
import { OstRewardMenagementDetailMonthStatusModal } from '@front/ost_reward/view/modal/detail-month-status';

export const convertMonthNumber = (month: string): number => {
  if (month[0]?.startsWith('0')) {
    return +month.slice(1, month.length);
  }
  return +month;
};

export const DetailStatusTable = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { detail } = useSelector(
    (root: RootState) => ({
      detail: root.ostRewardMangement.detail,
    }),
    shallowEqual
  );

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <>
      {isOpen && (
        <OstRewardMenagementDetailMonthStatusModal
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
      <Table disableSticky>
        <TableHead>
          <TableRow>
            <OldTh>지급 월</OldTh>
            <OldTh>지급 총액</OldTh>
            <OldTh>지급 대상 인원</OldTh>
            <OldTh>최고액</OldTh>
            <OldTh>평균액</OldTh>
            <OldTh>최저액</OldTh>
          </TableRow>
        </TableHead>
        <TableBody>
          {(typeof detail === 'undefined' ||
            (typeof detail !== 'undefined' && detail.statusList.length === 0)) && (
            <TableRow>
              <OldTd colSpan={9}>{'정보가 없습니다.'}</OldTd>
            </TableRow>
          )}
          {typeof detail !== 'undefined' &&
            detail.statusList.length > 0 &&
            new Array(12).fill(0).map((_, i) => {
              const find = detail.statusList.find(
                (target) => convertMonthNumber(target.month) === i + 1
              );
              if (find) {
                return (
                  <DetailStatusTableRow
                    onOpen={onOpen}
                    key={find.month}
                    item={find}
                  />
                );
              }
              return (
                <TableRow key={`${i + 1}월`}>
                  <OldTd>{i + 1}</OldTd>
                  <OldTd>-</OldTd>
                  <OldTd>-</OldTd>
                  <OldTd>-</OldTd>
                  <OldTd>-</OldTd>
                  <OldTd>-</OldTd>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </>
  );
};
