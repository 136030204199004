import { useQuery } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';

const workWorkQuery = {
  useGetRole: (dataId?: number, menuId?: number) => {
    const { data } = useQuery({
      queryKey: ['work', 'role', dataId, menuId],
      queryFn: () => workWorkApi.getRole(dataId, menuId),
      suspense: true,
      enabled: !!menuId,
    });
    return {
      data,
    };
  },
};

export default workWorkQuery;
