import { create } from 'zustand';

export { useState as useCancelBlockModalState };

interface State {
  isDirty: boolean;
  open: boolean;
  descriptionList: string[];
  title: string;
}

interface Actions {
  setIsDirty: (isDirty: boolean) => void;
  setOpen: (open: boolean, callback?: () => void) => void;
  callback?: () => void;
  reset: () => void;
  setDescriptionList: (descriptionList: string[]) => void;
  setTitle: (title: string) => void;
}

const useState = create<State & Actions>((set) => ({
  isDirty: false,
  open: false,
  setIsDirty: (isDirty) => set(() => ({ isDirty })),
  setOpen: (open, callback) => set(() => ({ open, callback })),
  reset: () =>
    set(() => ({
      open: false,
      callback: undefined,
      descriptionList: [
        '저장되지 않은 변경사항은 반영되지 않습니다.',
        '변경사항을 되돌리시겠습니까?',
      ],
      title: '확인',
    })),
  descriptionList: ['저장되지 않은 변경사항은 반영되지 않습니다.', '변경사항을 되돌리시겠습니까?'],
  setDescriptionList: (descriptionList: string[]) =>
    set(() => ({
      descriptionList,
    })),
  title: '확인',
  setTitle: (title) => set(() => ({ title })),
}));
