import { create } from 'zustand';

export { useState as useAccountingSettingsManagementAccountingAccountHeaderState };

interface State {
  isOpen: boolean;
  setIsOpen: (isOpen) => void;
}

const useState = create<State>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) =>
    set(() => ({
      isOpen,
    })),
}));
