import React from 'react';
import { ControlPanel } from '@front/src/components/control-panel/control-panel';
import DeleteIcon from '@mui/icons-material/Delete';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import notificationMutation from '@front/src/features/notification/query/mutation';
import notificationQuery from '@front/src/features/notification/query/query';
import { useNavigate } from 'react-router-dom';
import { useCustomDialog } from '@front/src/features/dialog';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import { useShallow } from 'zustand/react/shallow';
import useNoteStore from '@front/src/features/note/useState';
import useApprovalDocumentStore from '@front/src/features/drawer-approval-document/useState';

interface Props {
  isBookmarked?: boolean;
  isRead?: boolean;
  notificationId: number;
  isDeleted: boolean;
}

export default function NotificationControlPanel({
  isBookmarked,
  isRead,
  notificationId,
  isDeleted,
}: Props) {
  const { mutate: onDelete } = notificationMutation.useDelete(notificationId);
  const { mutate: onToggleRead } = notificationMutation.useToggleRead(notificationId, {
    isRead: !isRead,
  });
  const { mutate: onToggleBookMark } = notificationMutation.useToggleBookmark(notificationId, {
    isBookmarked: !isBookmarked,
  });
  const { data: link } = notificationQuery.useGetLink(notificationId);
  const navigate = useNavigate();
  const { alert } = useCustomDialog();
  const { setNoteId, setIsRightDrawerOpen, setLeftDrawerState } = useGlobalNavBarState(
    useShallow((state) => ({
      setNoteId: state.setNoteId,
      setIsRightDrawerOpen: state.setIsRightDrawerOpen,
      setLeftDrawerState: state.setLeftDrawerState,
    }))
  );
  const { setSectionId, setRowId } = useNoteStore(
    useShallow((state) => ({
      setSectionId: state.setSectionId,
      setRowId: state.setRowId,
    }))
  );
  const { setDocumentId, setParams } = useApprovalDocumentStore(
    useShallow((state) => ({
      setDocumentId: state.setDocumentId,
      setParams: state.setParams,
    }))
  );
  const handleDelete = () => {
    onDelete();
  };
  const toggleBookMark = () => {
    onToggleBookMark();
  };
  const toggleRead = () => {
    onToggleRead();
  };
  const moveToLink = () => {
    if (isDeleted) {
      alert({
        title: '링크 이동 오류',
        children: [{ value: '해당 글은 삭제되었습니다.' }],
      });
      return;
    } else if (!link?.forwardUrl) {
      alert({
        title: '링크 이동 오류',
        children: [{ value: '열람 권한이 없습니다.' }],
      });
      return;
    }

    const isWork = !link?.noteId && !link?.approvalDocumentId;
    const isApproval = link?.type === 'APPROVAL';
    navigate(link?.forwardUrl ?? '');
    sessionStorage.setItem('menuId', `${link?.menuId ?? ''}`);
    sessionStorage.setItem('dataId', `${link?.dataId ?? ''}`);
    setSectionId(link?.sectionId ?? 0);
    setRowId(link?.rowId ?? 0);

    if (isWork) return;
    setNoteId(link?.noteId ?? 0);
    if (isApproval) {
      setLeftDrawerState('approval-box');
      setDocumentId(link?.approvalDocumentId ?? 0);
      setParams({
        where: 'approval-document',
        noteId: link?.noteId ?? 0,
      });
      return;
    }
    setIsRightDrawerOpen(true);
  };

  const itemList = [
    {
      text: '삭제',
      icon: DeleteIcon,
      action: handleDelete,
    },
    {
      text: isBookmarked ? '북마크 해제' : '북마크',
      icon: isBookmarked ? BookmarkIcon : TurnedInNotIcon,
      action: toggleBookMark,
    },
    {
      text: isRead ? '읽지않음' : '읽음',
      icon: isRead ? VisibilityOffIcon : RemoveRedEyeIcon,
      action: toggleRead,
    },
    {
      text: '링크 이동',
      icon: ArrowForwardIcon,
      action: moveToLink,
    },
  ];
  return <ControlPanel itemList={itemList} />;
}
