import React from 'react';
import { Box } from '@mui/material';
import type { MenuView } from '@front/src/types';
import { useGnbLogic } from '@front/src/features/global-nav-bar/widgets/list/components/useGnbLogic';

export { Depth3Menu as GlobalNavBarClosedChildrenDepth3Menu };

interface Props extends MenuView {}

const Depth3Menu = ({ path, hasDrawer, children, title, id }: Props) => {
  const { onClick, active, clickable } = useGnbLogic({
    path,
    hasDrawer,
    children,
    id,
  });
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '32px',
        backgroundColor: active ? 'rgba(255,255,255,0.1)' : 'transparent',
        color: '#fff',
        cursor: clickable ? 'pointer' : 'default',
        fontSize: '0.9rem',
      }}
    >
      {title}
    </Box>
  );
};
