import { affiliatedPersonMemoRepository } from '@front/src/features/affiliated-person/features/memo/repository/repository';

export { useLogic as useAffiliatedPersonMemoDeleteLogic };

const useLogic = () => {
  const { run: onDelete } = affiliatedPersonMemoRepository.useDelete();
  return {
    onDelete,
  };
};
