import React, { useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import ModalLayout from '@front/layouts/ModalLayout';
import { useCancelBlockModalState } from '@front/src/features/modal/features/cancel-block-modal/useState';
import { useShallow } from 'zustand/react/shallow';
import Button from '@front/layouts/Button';

const CancelBlockModalFeature = () => {
  const { callback, setOpen, open, isDirty, descriptionList, reset, title } =
    useCancelBlockModalState(
      useShallow((state) => ({
        setOpen: state.setOpen,
        open: state.open,
        callback: state.callback,
        isDirty: state.isDirty,
        descriptionList: state.descriptionList,
        reset: state.reset,
        title: state.title,
      }))
    );
  const onClose = useCallback(() => {
    setOpen(false);
    reset();
  }, [setOpen, reset]);
  const onConfirm = useCallback(() => {
    callback?.();
    reset();
  }, [callback, reset]);
  useEffect(
    () => () => {
      reset();
    },
    [reset]
  );
  return (
    <>
      {open && (
        <ModalLayout
          open={isDirty && open}
          onClose={onClose}
          title={title}
          width="30dvw"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {descriptionList.map((item) => (
              <Typography key={item}>{item}</Typography>
            ))}
            <Box
              display="flex"
              gap="10px"
              padding="10px"
            >
              <Button
                shape="basic2"
                onClick={onClose}
              >
                취소
              </Button>
              <Button onClick={onConfirm}>확인</Button>
            </Box>
          </Box>
        </ModalLayout>
      )}
    </>
  );
};

export default CancelBlockModalFeature;
