import { personalSettingsModalRepository } from '@front/src/features/personal-settings-modal/features/auth-settings/repository';
import { useEffect, useState } from 'react';
import type { AuthorizationPersonalSettingsDeptView } from '@front/src/features/personal-settings-modal/features/auth-settings/types/view';
import { usePersonalSettingsModalDeptSettingsState } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/dept-settings/useState';

export { useLogic as usePersonalSettingsModalDeptSettingsLogic };

const useLogic = (authMenuId) => {
  const id = sessionStorage.getItem('menuId');
  const keyword = usePersonalSettingsModalDeptSettingsState((state) => state.keyword);
  const { data: deptList } = personalSettingsModalRepository.useDeptListGet(
    id ? +id : 0,
    {
      name: keyword,
    },
    authMenuId
  );
  const { run: onAuthUpdate } = personalSettingsModalRepository.useDeptListUpdate(authMenuId);
  const [clientDeptList, setClientDeptList] = useState<AuthorizationPersonalSettingsDeptView[]>([]);
  useEffect(() => {
    setClientDeptList(deptList);
  }, [deptList]);
  return {
    clientDeptList,
    deptList,
    onAuthUpdate,
  };
};
