import React from 'react';
import { Typography } from '@mui/material';
import useGetNoteList from '@front/src/features/note/repository/useGetNoteList';

export default function NoteTitle() {
  const { sectionName } = useGetNoteList();

  return (
    <Typography sx={{ ...SX_HEADER_TITLE }}>
      비고 <span>{sectionName}</span>
    </Typography>
  );
}

const SX_HEADER_TITLE = {
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  '& > span': {
    color: 'var(--design-v-10-light-sub-sub-primary, #FFB611)',
    borderLeft: '1px solid #B0B5BE',
    paddingLeft: '10px',
    marginLeft: '10px',
  },
};
