import { baseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanyGiftHistoryGetParameter } from '@front/src/features/affiliated-company/features/gift-history/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getGiftHistoryList: (id?) => `${baseUrl}/${id}/gift-history`,
};

export const affiliatedCompanyGiftHistoryApi = {
  getGiftHistoryList: async (params: AffiliatedCompanyGiftHistoryGetParameter, menuId) => {
    const { data } = await axios.get(url.getGiftHistoryList(params.id), {
      params: { type: params.type, name: params.name },
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
