import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import DateAttr from '@front/src/components/ui-builder/DateAttr';
import NumberAttr from '@front/src/components/ui-builder/NumberAttr';
import TextAttr from '@front/src/components/ui-builder/TextAttr';
import Box from '@mui/material/Box';
import type { WorkCategoryView } from '@front/src/features/work/features/work/features/category/types/view';
import { convertToOptionList } from '@front/src/utils';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import DropDownWithHookForm from '@front/src/components/hook-form/DropDown';
import { useFormContext } from 'react-hook-form';

const workCategoryCellComponent = {
  190: ({ ...rest }: CellComponentProps) => <WorkCategoryArea {...rest} />,
  191: ({ ...rest }: CellComponentProps) => <WorkCategoryDepartment {...rest} />,
  192: ({ ...rest }: CellComponentProps) => <WorkCategoryCollaboration {...rest} />,
  193: ({ ...rest }: CellComponentProps) => <WorkCategoryImpact {...rest} />,
  194: ({ ...rest }: CellComponentProps) => <WorkCategorySecurity {...rest} />,
  196: ({ ...rest }: CellComponentProps) => <WorkCategoryNumberAttr1 {...rest} />,
  197: ({ ...rest }: CellComponentProps) => <WorkCategoryTextAttr1 {...rest} />,
  198: ({ ...rest }: CellComponentProps) => <WorkCategoryDateAttr1 {...rest} />,
  199: ({ ...rest }: CellComponentProps) => <WorkCategoryNumberAttr2 {...rest} />,
  200: ({ ...rest }: CellComponentProps) => <WorkCategoryTextAttr2 {...rest} />,
  201: ({ ...rest }: CellComponentProps) => <WorkCategoryDateAttr2 {...rest} />,
  202: ({ ...rest }: CellComponentProps) => <WorkCategoryNumberAttr3 {...rest} />,
  203: ({ ...rest }: CellComponentProps) => <WorkCategoryTextAttr3 {...rest} />,
  204: ({ ...rest }: CellComponentProps) => <WorkCategoryDateAttr3 {...rest} />,
};

export default workCategoryCellComponent;

interface Props extends CellComponentProps<WorkCategoryView> {}

const WorkCategoryArea = ({
  sx = {},
  onSubmit,
  onClick,
  isEditMode,
  readOnly,
}: Readonly<Props>) => {
  const { getValues } = useFormContext();
  const { data: workAreaList } = adminManageableVariableRepository.useListGet('업무-영역');
  const renderContent = () => {
    if (readOnly || !isEditMode) return getValues('area');
    return (
      <DropDownWithHookForm
        defaultLabel="선택"
        name="area"
        options={convertToOptionList(workAreaList)}
        onSubmit={onSubmit}
      />
    );
  };
  return (
    <Box
      sx={sx}
      onClick={onClick}
    >
      {renderContent()}
    </Box>
  );
};

const WorkCategoryDepartment = ({
  onClick,
  sx = {},
  onSubmit,
  isEditMode,
  readOnly,
}: Readonly<Props>) => {
  const { getValues } = useFormContext();
  const { data: workDepartmentList } = adminManageableVariableRepository.useListGet('업무-부서');
  const renderContent = () => {
    if (readOnly || !isEditMode) return getValues('department');
    return (
      <DropDownWithHookForm
        defaultLabel="선택"
        name="department"
        options={convertToOptionList(workDepartmentList)}
        onSubmit={onSubmit}
      />
    );
  };
  return (
    <Box
      sx={sx}
      onClick={onClick}
    >
      {renderContent()}
    </Box>
  );
};

const WorkCategoryCollaboration = ({
  onClick,
  sx = {},
  onSubmit,
  isEditMode,
  readOnly,
}: Readonly<Props>) => {
  const { getValues } = useFormContext();
  const { data: workCollaborationList } =
    adminManageableVariableRepository.useListGet('업무-협업/영향');
  const renderContent = () => {
    if (readOnly || !isEditMode) return getValues('collaboration');
    return (
      <DropDownWithHookForm
        defaultLabel="선택"
        name="collaboration"
        options={convertToOptionList(workCollaborationList)}
        onSubmit={onSubmit}
      />
    );
  };
  return (
    <Box
      sx={sx}
      onClick={onClick}
    >
      {renderContent()}
    </Box>
  );
};

const WorkCategoryImpact = ({
  onClick,
  sx = {},
  onSubmit,
  isEditMode,
  readOnly,
}: Readonly<Props>) => {
  const { getValues } = useFormContext();
  const { data: workCollaborationList } =
    adminManageableVariableRepository.useListGet('업무-협업/영향');
  const renderContent = () => {
    if (readOnly || !isEditMode) return getValues('impact');
    return (
      <DropDownWithHookForm
        defaultLabel="선택"
        name="impact"
        options={convertToOptionList(workCollaborationList)}
        onSubmit={onSubmit}
      />
    );
  };
  return (
    <Box
      sx={sx}
      onClick={onClick}
    >
      {renderContent()}
    </Box>
  );
};
const WorkCategorySecurity = ({
  onClick,
  sx = {},
  onSubmit,
  isEditMode,
  readOnly,
}: Readonly<Props>) => {
  const { getValues } = useFormContext();
  const { data: workSecurityList } = adminManageableVariableRepository.useListGet('업무-보안');
  const renderContent = () => {
    if (readOnly || !isEditMode) return getValues('security');
    return (
      <DropDownWithHookForm
        defaultLabel="선택"
        name="security"
        options={convertToOptionList(workSecurityList)}
        onSubmit={onSubmit}
      />
    );
  };
  return (
    <Box
      sx={sx}
      onClick={onClick}
    >
      {renderContent()}
    </Box>
  );
};

function WorkCategoryNumberAttr1({ ...rest }: Readonly<Props>) {
  return (
    <NumberAttr
      name="numberAttr1"
      {...rest}
    />
  );
}

function WorkCategoryTextAttr1({ ...rest }: Readonly<Props>) {
  return (
    <TextAttr
      name="textAttr1"
      {...rest}
    />
  );
}

function WorkCategoryDateAttr1({ ...rest }: Readonly<Props>) {
  return (
    <DateAttr
      name="dateAttr1"
      {...rest}
    />
  );
}

function WorkCategoryNumberAttr2({ ...rest }: Readonly<Props>) {
  return (
    <NumberAttr
      name="numberAttr2"
      {...rest}
    />
  );
}

function WorkCategoryTextAttr2({ ...rest }: Readonly<Props>) {
  return (
    <TextAttr
      name="textAttr2"
      {...rest}
    />
  );
}

function WorkCategoryDateAttr2({ ...rest }: Readonly<Props>) {
  return (
    <DateAttr
      name="dateAttr2"
      {...rest}
    />
  );
}

function WorkCategoryNumberAttr3({ ...rest }: Readonly<Props>) {
  return (
    <NumberAttr
      name="numberAttr3"
      {...rest}
    />
  );
}

function WorkCategoryTextAttr3({ ...rest }: Readonly<Props>) {
  return (
    <TextAttr
      name="textAttr3"
      {...rest}
    />
  );
}

function WorkCategoryDateAttr3({ ...rest }: Readonly<Props>) {
  return (
    <DateAttr
      name="dateAttr3"
      {...rest}
    />
  );
}
