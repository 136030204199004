import { projectSalesInfoProjectDivisionModalQuery } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/query';
import { projectSalesInfoProjectDivisionModalMutation } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/query/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';

export const projectSalesInfoProjectDivisionModalRepository = {
  useProjectDivisionModalGet: (id?: number) => {
    const { data, isLoading } =
      projectSalesInfoProjectDivisionModalQuery.useProjectDivisionModalGet(id);
    return {
      data,
      isLoading,
    };
  },
  useProjectDivisionModalUpdate: (id: number) => {
    const { mutate } =
      projectSalesInfoProjectDivisionModalMutation.useProjectDivisionModalUpdate(id);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },

  useProjectDivisionModalCreate: (id: number) => {
    const { mutate } =
      projectSalesInfoProjectDivisionModalMutation.useProjectDivisionModalCreate(id);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },

  useProjectDivisionModalDelete: (id: number) => {
    const { mutate } =
      projectSalesInfoProjectDivisionModalMutation.useProjectDivisionModalDelete(id);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
