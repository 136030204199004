import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workReferenceMutation from '@front/src/features/work/features/work/features/reference/query/mutation';
import type { WorkReferenceFileUpdateParameter } from '@front/src/features/work/features/work/features/reference/types/parameter';

const useWorkReferenceUpdateFile = (menuId) => {
  const callback = getNoOpCallback();
  const { mutate, isLoading } = workReferenceMutation.useUpdateFile(menuId);
  return {
    run: (params: WorkReferenceFileUpdateParameter) => mutate(params, callback),
    setCallback: getSetCallbackFunc(callback),
    isLoading,
  };
};

export default useWorkReferenceUpdateFile;
