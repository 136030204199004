import React, { useContext } from 'react';
import Button from '@front/layouts/Button';
import classes from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/modal/components/contract-earnings-modal.module.scss';
import { FormProvider, useForm } from 'react-hook-form';
import type { ProjectContractEarningsParameters } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/parameters';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { ProjectSalesInfoContractEarningsModalContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/modal/widgets/context';
import { useProjectSalesInfoContractEarningsState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/widgets/useState';
import { collectionModalLayout } from '@front/src/features/project-sales-info/features/collection/components/layout';

export { ContractEarningsModal as ProjectSalesInfoContractEarningsModal };

const ContractEarningsModal = () => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const {
    h: { onCreate },
  } = useContext(ProjectSalesInfoContractEarningsModalContext);
  const setIsModalOpen = useProjectSalesInfoContractEarningsState((state) => state.setIsModalOpen);
  const methods = useForm<ProjectContractEarningsParameters>({
    values: {
      id,
      name: '',
    },
  });
  const { Body, Layout } = collectionModalLayout;
  const onSubmit = methods.handleSubmit((data) => {
    onCreate(data);
    setIsModalOpen();
  });
  return (
    <FormProvider {...methods}>
      <Layout width="70%">
        <Body>
          <HookFormInput
            name="name"
            width="100%"
            placeholder="기성단계 이름을 입력하세요"
            onKeyDown={async (e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.nativeEvent.isComposing || e.keyCode === 229) return;
              if (e.key !== 'Enter') return;
              e.preventDefault();
              await onSubmit();
            }}
          />
        </Body>
        <div className={classes.btn__container}>
          <Button
            fullWidth={true}
            shape="basic2"
            onClick={() => setIsModalOpen()}
          >
            취소
          </Button>
          <Button
            fullWidth={true}
            onClick={onSubmit}
          >
            추가
          </Button>
        </div>
      </Layout>
    </FormProvider>
  );
};
