import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyPersonHobbyMutation } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/query/mutation';
import { affiliatedCompanyPersonHobbyQuery } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/query/query';

export const affiliatedCompanyPersonHobbyRepository = {
  usePersonCharacterHobbyGet: (id?: number, menuId?) => {
    const { data, isLoading } = affiliatedCompanyPersonHobbyQuery.usePersonCharacterHobbyGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
  usePersonCharacterHobbyCreate: (menuId) => {
    const { mutate } = affiliatedCompanyPersonHobbyMutation.usePersonCharacterHobbyCreate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  usePersonCharacterHobbyDelete: (menuId) => {
    const { mutate } = affiliatedCompanyPersonHobbyMutation.usePersonCharacterHobbyDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  usePersonCharacterHobbyUpdate: (menuId) => {
    const { mutate } = affiliatedCompanyPersonHobbyMutation.usePersonCharacterHobbyUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
