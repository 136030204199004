import { useQuery } from 'react-query';
import { projectSalesInfoContractEarningsItemCreateModalApi } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/query/api';
import { projectSalesInfoContractConfirmedApi } from '@front/src/features/project-sales-info/features/contract/features/confirmed/query/api';

export const projectSalesInfoContractEarningsItemCreateModalQuery = {
  useConsortiumCompanyGet: (id: number) => {
    const { data, isLoading } = useQuery({
      queryKey: ['project-sales-info', 'collection', 'contract-earnings', 'consortium', id],
      queryFn: () => projectSalesInfoContractEarningsItemCreateModalApi.getConsortiumCompany(id),
    });
    return {
      data,
      isLoading,
    };
  },
  useConfirmedContractGet: (id: number) => {
    const { data, isLoading } = useQuery({
      queryKey: ['project-sales-info', 'collection', 'contract-earnings', 'confirmed-contract', id],
      queryFn: () => projectSalesInfoContractConfirmedApi.getDetail(id),
    });
    return {
      data,
      isLoading,
    };
  },
};
