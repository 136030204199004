// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZnedeqSDpVz82_n5LdNA{display:flex;justify-content:center;padding:20px;border:1px solid #e4e9f2}.QM9NKQ593eyWOmryVWQE{width:170px}.N9e3lX5ikUmyxl20AxIl{width:1%}.yKNp9WL0Rs0gloQCOTW1{width:120px}.ZK6NHJd2smH5g5UrNZFg{width:200px}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/project-division/features/estimate-modal/components/modal-table.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,sBAAA,CACA,YAAA,CACA,wBAAA,CAGF,sBACE,WAAA,CAGF,sBACE,QAAA,CAGF,sBACE,WAAA,CAGF,sBACE,WAAA","sourcesContent":["@import \"@front/assets/colors.scss\";\n\n.no__result {\n  display: flex;\n  justify-content: center;\n  padding: 20px;\n  border: 1px solid $_e4e9f2;\n}\n\n.width__6 {\n  width: 170px;\n}\n\n.width__4 {\n  width: 1%;\n}\n\n.width__5 {\n  width: 120px;\n}\n\n.width__7 {\n  width: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no__result": `ZnedeqSDpVz82_n5LdNA`,
	"width__6": `QM9NKQ593eyWOmryVWQE`,
	"width__4": `N9e3lX5ikUmyxl20AxIl`,
	"width__5": `yKNp9WL0Rs0gloQCOTW1`,
	"width__7": `ZK6NHJd2smH5g5UrNZFg`
};
export default ___CSS_LOADER_EXPORT___;
