import React, { memo, useMemo } from 'react';
import CircularProgress from '@front/components/CircularProgress';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { AffiliatedCompanyPersonDetailModalCharacterBasicContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/basic/widgets/context';
import { useAffiliatedCompanyPersonDetailModalCharacterBasicLogic } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/basic/widgets/useLogic';
import { AffiliatedCompanyPersonDetailModalCharacterBasic } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/basic/components/basic';

interface Props {
  readOnly?: boolean;
  id?: number;
  menuId?: number;
}

const AffiliatedCompanyPersonDetailModalCharacterBasicWidget = ({
  readOnly,
  id,
  menuId,
}: Props) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    h: { onPersonCharacterBasicUpdate },
    d: { personCharacterBasic },
    isLoading,
  } = useAffiliatedCompanyPersonDetailModalCharacterBasicLogic({ id, menuId });
  const value = useMemo(
    () => ({
      d: { personCharacterBasic },
      h: { onPersonCharacterBasicUpdate },
      isLoading,
      readOnly,
    }),
    [onPersonCharacterBasicUpdate, personCharacterBasic, isLoading, readOnly]
  );
  if (typeof personCharacterBasic === 'undefined' || isLoading) {
    return <Spinner />;
  }
  return (
    <Layout>
      <Header title="기본정보" />
      <Body>
        <AffiliatedCompanyPersonDetailModalCharacterBasicContext.Provider value={value}>
          <AffiliatedCompanyPersonDetailModalCharacterBasic />
        </AffiliatedCompanyPersonDetailModalCharacterBasicContext.Provider>
      </Body>
    </Layout>
  );
};

const Spinner = memo(() => {
  const { Layout, Header } = AddDeleteTable;
  return (
    <Layout height="556px">
      <Header title="기본정보" />
      <CircularProgress size={30} />
    </Layout>
  );
});

Spinner.displayName = 'AffiliatedCompanyPersonDetailModalCharacterBasicWidgetSpinner';

export default AffiliatedCompanyPersonDetailModalCharacterBasicWidget;
