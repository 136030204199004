import React from 'react';
import { Box, Fade, TableBody, TableHead, TableRow } from '@mui/material';
import TextBox from '@front/layouts/Text';
import { Table, OldTd, OldTh } from '@front/layouts/Table';
import CircularProgress from '@front/components/CircularProgress';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';

export { List as ApprovalEstimationList };
const List = () => {
  const detail = useSelector((root: RootState) => root.proposal.detail!, shallowEqual);
  const [estimatorList] = useSelector(
    (root: RootState) => [
      root.proposal.estimatorList.filter(
        (estimatorInfo) =>
          !detail.contributorViewList
            .filter((cv) => cv.contributor)
            .map((cv) => cv.contributor.id)
            .includes(estimatorInfo.estimator.id)
      ),
    ],
    shallowEqual
  );
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        width: '100%',
        padding: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <TextBox variant={'heading3'}>심사 결과</TextBox>
      </Box>
      <Table disableSticky>
        <TableHead>
          <TableRow>
            <OldTh sx={{ width: '10px' }}>No</OldTh>
            <OldTh sx={{ width: '100px' }}>부서</OldTh>
            <OldTh sx={{ width: '100px' }}>이름</OldTh>
            <OldTh sx={{ width: '100px' }}>등급</OldTh>
            <OldTh sx={{ width: '150px' }}>심사의견</OldTh>
          </TableRow>
        </TableHead>
        <TableBody>
          {!estimatorList && (
            <TableRow>
              <OldTd
                colSpan={4}
                sx={{ minHeight: '38px' }}
              >
                <CircularProgress
                  size={4}
                  sx={{ justifyContent: 'center', alignItems: 'center' }}
                />
              </OldTd>
            </TableRow>
          )}
          {estimatorList?.length === 0 && (
            <TableRow>
              <OldTd colSpan={5}>
                <Fade in={true}>
                  <Box>심사 결과가 없습니다.</Box>
                </Fade>
              </OldTd>
            </TableRow>
          )}
          {estimatorList?.map((item, index) => (
            <React.Fragment key={item.id}>
              <TableRow>
                <OldTd>{index + 1}</OldTd>
                <OldTd>{item.estimator?.department.name}</OldTd>
                <OldTd>{item.estimator?.name}</OldTd>
                <OldTd>{item.grade ? item.grade : '-'}</OldTd>
                <OldTd>{item.opinion ? item.opinion : '-'}</OldTd>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
