import React, { useCallback, useContext } from 'react';
import { AccountingSettingsTexAccountingAccountListContext } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/list/provider/context';
import { AccountingSettingsTexAccountingAccountListItem } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/list/components/list-item';
import { Box } from '@mui/material';
import { useAccountingSettingsTaxAccountingAccountState } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/useState';

export { List as AccountingSettingsTexAccountingAccountList };

const List = () => {
  const { list, tableRowNum, countList } = useContext(
    AccountingSettingsTexAccountingAccountListContext
  );
  const parentIdList = useAccountingSettingsTaxAccountingAccountState(
    (state) => state.parentIdList
  );
  const getList = useCallback(
    (level) =>
      list?.filter((t) => {
        if (level === 1) return t.parent === null;
        return t.parent?.id === parentIdList[level - 2];
      }),
    [list, parentIdList]
  );
  const renderList = useCallback(
    (targetList, level) =>
      new Array(tableRowNum).fill(0).map((_, i) => {
        const item = targetList?.[i];
        return (
          <AccountingSettingsTexAccountingAccountListItem
            key={`${item?.id}-${level}-${i}`}
            item={item}
          />
        );
      }),
    [tableRowNum]
  );
  const length = countList?.countList.length;
  const result = length !== undefined && length !== null && length > 0 ? length : 5;
  return (
    <>
      {Array.from({ length: result }, (_, i) => i + 1).map((level, idx) => {
        const rowList =
          idx > parentIdList.length ? renderList([], level) : renderList(getList(level), level);
        return (
          <Box
            key={`row-level-${level}-`}
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {rowList}
          </Box>
        );
      })}
    </>
  );
};
