import React, { useEffect } from 'react';
import { Navigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { navigationAction } from '@front/navigate/action';

const NavigateWrapperService = () => {
  const dispatch = useDispatch();
  const to = useSelector((root: RootState) => root.navigation.to);
  useEffect(() => {
    if (to) {
      dispatch(navigationAction.navigate(undefined));
    }
  }, [dispatch, to]);
  if (!to) {
    return null;
  }
  return <Navigate to={`${to}`} />;
};

export default NavigateWrapperService;
