import { call, fork, put, take } from 'redux-saga/effects';
import { userNotificationAction } from 'user_notification/action';
import { userNotificationApi } from 'user_notification/api';

function* watchCount() {
  while (true) {
    yield take(userNotificationAction.requestCount);
    const count: number = yield call(userNotificationApi.count);
    yield put(userNotificationAction.setCount(count));
    const channel = new BroadcastChannel('sw-messages');
    channel.postMessage(count);
  }
}

export default function* userNotificationSaga() {
  yield fork(watchCount);
}
