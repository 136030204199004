import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { AffiliatedPersonMemoCreateParameter } from '@front/src/features/affiliated-person/features/memo/types/parameter';

export { Context as AffiliatedPersonMemoCreateContext };

interface State {
  onCreate: (params: AffiliatedPersonMemoCreateParameter) => void;
}

const Context = createContext<State>({
  onCreate: noOp,
});
