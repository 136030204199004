import React, { useCallback, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Input from '@front/layouts/Input';
import type { RootState } from '@front/services/reducer';
import { ostAction } from '@front/ost/action';

export const EvaluationMethodBottomRateInput = () => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const { trimmedMeanBottomRate } = useSelector(
    (root: RootState) => ({
      trimmedMeanBottomRate: root.ost.detail?.trimmedMeanBottomRate,
    }),
    shallowEqual
  );

  const onBlur = useCallback(
    (e) => {
      const value = e.target.value;
      dispatch(ostAction.updateTrimmedMeanBottomRate({ trimmedMeanBottomRate: +value }));
    },
    [dispatch]
  );

  return (
    <Input
      inputProps={{ min: 0, max: 100 }}
      isPercent
      type="number"
      key={trimmedMeanBottomRate}
      inputRef={inputRef}
      variant="outlined"
      defaultValue={trimmedMeanBottomRate}
      onBlur={onBlur}
    />
  );
};
