import { createAction } from 'typesafe-actions';
import type { ProposalId, ProposalShortVO } from '@front/ost_proposal/domain';
import type {
  EvaluationMemoAddParameter,
  EvaluationMemoChangeParameter,
} from '@front/ost_evaluation/parameter';
import type { EvaluationMemoQuery, EvaluationQuery } from '@front/ost_evaluation/query';
import type { EvaluationMemoId, EvaluationMemoVO } from '@front/ost_evaluation/domain';

export enum EvaluationAction {
  /**
   * 평가 목록 관련 액션
   */
  setId = 'evaluation/id/set',
  setList = 'evaluation/list/set',
  setFilter = 'evaluation/filter/set',
  toggleDrawer = 'evaluation/drawer/toggle',
  setLoading = 'evaluation/loading/set',
  /**
   * 제안 메모 관련 액션
   */
  setMemoDrawerOpen = 'evaluation/drawer/set',
  addMemo = 'evaluation/memo/add',
  setMemoFilter = 'evaluation/memo/filter',
  setMemoList = 'evaluation/memo/list/set',
  changeMemo = 'evaluation/memo/change',
  deleteMemo = 'evaluation/memo/delete',
  setMemoLoading = 'evaluation/memo/loading/set',
}

export const evaluationAction = {
  /**
   * 평가 목록 관련 액션
   */
  setId: createAction(EvaluationAction.setId)<ProposalId | undefined>(),
  setList: createAction(EvaluationAction.setList)<ProposalShortVO[]>(),
  setFilter: createAction(EvaluationAction.setFilter)<EvaluationQuery>(),
  toggleDrawer: createAction(EvaluationAction.toggleDrawer)<boolean>(),
  setLoading: createAction(EvaluationAction.setLoading)<boolean>(),
  /**
   * 제안 메모 관련 액션
   */
  setMemoDrawerOpen: createAction(EvaluationAction.setMemoDrawerOpen)<boolean>(),
  addMemo: createAction(EvaluationAction.addMemo)<EvaluationMemoAddParameter>(),
  setMemoFilter: createAction(EvaluationAction.setMemoFilter)<EvaluationMemoQuery>(),
  setMemoList: createAction(EvaluationAction.setMemoList)<EvaluationMemoVO[]>(),
  changeMemo: createAction(EvaluationAction.changeMemo)<EvaluationMemoChangeParameter>(),
  deleteMemo: createAction(EvaluationAction.deleteMemo)<EvaluationMemoId>(),
  setMemoLoading: createAction(EvaluationAction.setMemoLoading)<boolean>(),
};
