import React, { useMemo } from 'react';
import { ProjectSalesInfoContractEarningsContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/widgets/context';
import { useProjectSalesInfoContractEarningsLogic } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/widgets/useLogic';

export { Provider as ProviderWrapper };

const Provider = ({ children }: { children: React.ReactNode }) => {
  const {
    d: { contractEarningsStatus, contractEarnings },
    isLoading,
    isStatusLoading,
    isModalOpen,
  } = useProjectSalesInfoContractEarningsLogic();
  const value = useMemo(
    () => ({
      d: { contractEarningsStatus, contractEarnings },
      isLoading,
      isStatusLoading,
      isModalOpen,
    }),
    [contractEarningsStatus, isLoading, contractEarnings, isStatusLoading, isModalOpen]
  );
  return (
    <ProjectSalesInfoContractEarningsContext.Provider value={value}>
      {children}
    </ProjectSalesInfoContractEarningsContext.Provider>
  );
};
