import React from 'react';
import { PersonalSettingsModalUISettingsHeaderSettingsTableContentHistoryTable } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/history/components/HistoryTable';

export { Index as PersonalSettingsModalUISettingsHeaderSettingsTableContentHistoryFeature };
interface Props {
  authMenuId: number | undefined;
}
const Index = ({ authMenuId }: Props) => (
  <PersonalSettingsModalUISettingsHeaderSettingsTableContentHistoryTable authMenuId={authMenuId} />
);
