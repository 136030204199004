import type { ProjectContractEarningsConsortiumCompanyView } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/views';
import type { OptionType } from '@front/src/components/select-with-hook-form/select-with-hook-form';

export const getOptionList = (list) => {
  if (typeof list === 'undefined') {
    return [];
  }
  return list.map((item) => ({
    label: item.name,
    value: item.id.toString(),
  }));
};

export const createProjectSalesInfoAffiliatedCompanyWebHardCompanyList = (list) => {
  const companyList: OptionType[] = [];
  if (typeof list === 'undefined') {
    return companyList;
  }
  if (list.length === 0) {
    return companyList;
  }
  const idSet = new Set();
  list.forEach((item) => {
    if (item.buildingCompany?.id && !idSet.has(item.buildingCompany.id)) {
      idSet.add(item.buildingCompany.id);
      companyList.push({
        label: item.buildingCompany.name,
        value: item.buildingCompany.id.toString(),
      });
    }
    if (item.structuralDesignCompany?.id && !idSet.has(item.structuralDesignCompany.id)) {
      idSet.add(item.structuralDesignCompany.id);
      companyList.push({
        label: item.structuralDesignCompany.name,
        value: item.structuralDesignCompany.id.toString(),
      });
    }
    if (item.implementingCompany?.id && !idSet.has(item.implementingCompany.id)) {
      idSet.add(item.implementingCompany.id);
      companyList.push({
        label: item.implementingCompany.name,
        value: item.implementingCompany.id.toString(),
      });
    }
    if (item.constructingCompany?.id && !idSet.has(item.constructingCompany.id)) {
      idSet.add(item.constructingCompany.id);
      companyList.push({
        label: item.constructingCompany.name,
        value: item.constructingCompany.id.toString(),
      });
    }
    if (item.etcCompany?.id && !idSet.has(item.etcCompany.id)) {
      idSet.add(item.etcCompany.id);
      companyList.push({
        label: item.etcCompany.name,
        value: item.etcCompany.id.toString(),
      });
    }
  });
  return companyList;
};

export const convertAffiliatedCompanyWebHardModalDataForParameter = (data) => ({
  companyId: data.companyId || null,
  webHardId: data.webHardId || null,
  webHardUsername: data.webHardUsername || null,
  webHardPassword: data.webHardPassword || null,
});

export const convertToDepositCompanyOptionList = (
  data?: ProjectContractEarningsConsortiumCompanyView[]
) => {
  const companyList = data
    ?.map((item) => {
      const filteredData = Object.keys(item).filter(
        (key) => key !== 'recommenderName' && key !== 'id' && item[key]?.name !== undefined
      );
      return filteredData.map((key) => item[key]);
    })
    .flat();
  const newCompanyList = Array.from(new Map(companyList?.map((obj) => [obj.id, obj])).values());
  return newCompanyList.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));
};
