import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import React, { memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import { ostAction } from '@front/ost/action';
import type { OstUpdatePeriodParameter } from '@front/ost/parameter';
import { OstStatus } from '@front/ost/domain';
import TextField from '@mui/material/TextField';

interface Props {
  startDate?: string;
  endDate?: string;
  status?: OstStatus;
}

const defaultYearFormat = 'YYYY';

const OstPeriod = ({ startDate, status }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const openSnackbarWarning = useCallback(
    (message) => {
      dispatch(
        snackbarAction.show({
          message,
          severity: SnackbarSeverityType.warning,
        })
      );
    },
    [dispatch]
  );

  const updatePeriod = useCallback(
    (prams: OstUpdatePeriodParameter) => {
      if (status === OstStatus.DONE) {
        openSnackbarWarning('종료된 OST입니다.');
        return;
      }
      dispatch(ostAction.updatePeriod(prams));
    },
    [dispatch, status, openSnackbarWarning]
  );

  const onChange = useCallback(
    (e) => {
      const value = e ? dayjs(e).format(defaultYearFormat) : '';
      if (!value) {
        updatePeriod({ period: undefined });
        return;
      }
      updatePeriod({ period: value });
    },
    [updatePeriod]
  );

  return (
    <DataFieldWithLabel
      label="수행 년도"
      labelSX={{ minWidth: '80px' }}
    >
      <DatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        views={['year']}
        openTo="year"
        inputFormat={defaultYearFormat}
        value={startDate && dayjs(startDate).format(defaultYearFormat)}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            onClick={() => setOpen(true)}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            size="small"
            sx={{
              '& .MuiInputBase-input': {
                width: '30px',
                height: '13px',
                fontSize: '13px',
                cursor: 'pointer',
              },
            }}
            {...params}
          />
        )}
      />
    </DataFieldWithLabel>
  );
};

const OstFormPeriod = memo(OstPeriod);
export default OstFormPeriod;
