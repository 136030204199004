import { useQuery } from 'react-query';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonApi } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/query/api';
import type { ProjectAffiliatedCompanyPersonView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/types/view';

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonQuery = {
  useListGet: (id?: number) => {
    const { data, isLoading } = useQuery<ProjectAffiliatedCompanyPersonView[]>({
      queryKey: ['project', 'sales-info', 'affiliated-company', 'affiliated-company-person', id],
      queryFn: () => projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonApi.getList(id),
      enabled: !!id,
    });
    return {
      data,
      isLoading,
    };
  },
};
