import React from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { useForm } from 'react-hook-form';
import type { AuthorizationType, UserVO } from '@front/user/domain';
import UserSelectorFormFeature from '@front/src/features/user-selector/features/form';
import { Box, Divider } from '@mui/material';
import UserSelectorButtonSection from '@front/src/features/user-selector/features/modal/components/ButtonSection';

interface Props {
  value: number | number[] | undefined;
  onChange: (value: number | number[] | undefined) => void;
  multi?: boolean;
  open: boolean;
  onClose: () => void;
  menuIdList?: number[];
  typeList?: AuthorizationType[];
}

const getConvertedValue = (value) => {
  if (Array.isArray(value)) {
    return value;
  } else if (value === undefined) {
    return [];
  }
  return [value];
};

export default function UserSelectorModalFeature({
  value,
  onChange,
  multi = false,
  open,
  onClose,
  menuIdList = [],
  typeList = [],
}: Readonly<Props>) {
  const { reset, setValue, handleSubmit } = useForm({
    values: {
      selectedIdList: getConvertedValue(value),
    },
  });
  const handleChange = (value: UserVO | UserVO[] | undefined) => {
    if (Array.isArray(value)) {
      setValue(
        'selectedIdList',
        value.map((v) => v.id)
      );
    } else if (value === undefined) {
      setValue('selectedIdList', []);
    } else {
      setValue('selectedIdList', [value.id]);
    }
  };
  const handleCancel = () => {
    onClose();
    reset();
  };
  return (
    <ModalLayout
      open={open}
      title="유저 선택"
      onClose={onClose}
      width="44dvw"
      height="44dvh"
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <UserSelectorButtonSection
          multi={multi}
          onClose={handleCancel}
          onChange={onChange}
          handleSubmit={handleSubmit}
        />
        <Divider />
        <UserSelectorFormFeature
          multi={multi}
          menuIdList={menuIdList}
          typeList={typeList}
          onChange={handleChange}
          value={value}
        />
      </Box>
    </ModalLayout>
  );
}
