import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { useFormContext } from 'react-hook-form';
import { useSnackbar } from '@front/src/features/snackbar';
import { AffiliatedPersonMemoCreateContext } from '@front/src/features/affiliated-person/features/memo/widgets/create/provider/context';
import { useAffiliatedPersonDetailState } from '@front/src/features/affiliated-person/features/detail/useState';
import { useShallow } from 'zustand/react/shallow';

export { CreateButton as AffiliatedPersonMemoCreateButton };

const CreateButton = () => {
  const { onCreate } = useContext(AffiliatedPersonMemoCreateContext);
  const { id: personId } = useAffiliatedPersonDetailState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const {
    handleSubmit,
    getValues,
    formState: { isSubmitting },
    reset,
  } = useFormContext();
  const onSubmit = handleSubmit((data) => {
    onCreate({
      personId,
      description: data.description || null,
      attendanceList: data.attendanceList.length === 0 ? null : data.attendanceList,
    });
  });
  const { show } = useSnackbar();
  const handleButtonClick = useCallback(async () => {
    if (!getValues('description')) {
      show({ message: '내용을 입력해주세요.' });
      return;
    }
    await onSubmit();
    reset();
  }, [onSubmit, show, getValues, reset]);
  return (
    <Button
      fullWidth
      disabled={isSubmitting}
      onClick={handleButtonClick}
    >
      작성 완료
    </Button>
  );
};
