import { createAction } from 'typesafe-actions';
import type { CurrentPage } from '@front/navigate/domain';

export enum NavigationActionType {
  navigate = 'system/navigate',
  setCurrentPage = 'system/current-page/set',
}

export const navigationAction = {
  navigate: createAction(NavigationActionType.navigate)<string | undefined>(),
  setCurrentPage: createAction(NavigationActionType.setCurrentPage)<CurrentPage | undefined>(),
};
