import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useProjectSalesInfoSubjectReviewModalComplexInformationListItemLogic } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/table/features/item/widgets/useLogic';
import { ProjectSalesInfoSubjectReviewModalComplexInformationListItemContext } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/table/features/item/widgets/context';

export { Provider as ProjectSalesInfoSubjectReviewModalComplexInformationListItemProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { onUpdate } = useProjectSalesInfoSubjectReviewModalComplexInformationListItemLogic();
  const value = useMemo(
    () => ({
      onUpdate,
    }),
    [onUpdate]
  );
  return (
    <ProjectSalesInfoSubjectReviewModalComplexInformationListItemContext.Provider value={value}>
      {children}
    </ProjectSalesInfoSubjectReviewModalComplexInformationListItemContext.Provider>
  );
};
