import React, { useCallback, useMemo } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import type { ProjectEstimationView } from '@front/src/features/project-sales-info/features/estimation/features/estimation/types/view';
import Checkbox from '@front/layouts/Checkbox';
import { useProjectSalesInfoEstimationEstimationState } from '@front/src/features/project-sales-info/features/estimation/features/estimation/widgets/useState';
import {
  convertDateToStringFormat,
  DATE_FORMAT,
  downloadFile,
  getChecked,
  getLocaleStringIfExist,
  getValueIfExist,
} from '@front/src/utils';
import classes from '@front/src/features/project-sales-info/features/estimation/features/estimation/components/estimation-table-row.module.scss';
import { useProjectSalesInfoEstimationEstimationUpdateModalState } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/update-modal/widgets/useState';
import { shallow } from 'zustand/shallow';
import { convertIsConfirmed } from '@front/src/features/project-sales-info/features/estimation/features/estimation/utils';
import { spliceAndPushIfExist } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/utils/constants';
import { FileDisplayText } from '@front/src/components/file-display-text/file-display-text';
import { useShallow } from 'zustand/react/shallow';

export { EstimationTableRow as ProjectSalesInfoEstimationEstimationTableRow };

interface Props {
  item: ProjectEstimationView;
}

const EstimationTableRow = ({ item }: Props) => {
  const { idList, setIdList } = useProjectSalesInfoEstimationEstimationState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { setIsOpen, setId } = useProjectSalesInfoEstimationEstimationUpdateModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      setId: state.setId,
    }),
    shallow
  );
  const checked = useMemo(() => getChecked(item.id, idList), [item.id, idList]);
  const handleCheckboxChange = useCallback(() => {
    setIdList(spliceAndPushIfExist(idList, item.id));
  }, [setIdList, idList, item.id]);
  const { readOnly } = useProjectSalesInfoEstimationEstimationState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const handleCodeClick = useCallback(() => {
    setId(item.id);
    setIsOpen(true);
  }, [setIsOpen, setId, item.id]);
  return (
    <TableRow>
      <OldTd>
        <Checkbox
          checked={checked}
          onChange={handleCheckboxChange}
          disabled={readOnly}
        />
      </OldTd>
      <OldTd>{getValueIfExist(item.seq)}</OldTd>
      <OldTd>{convertIsConfirmed(item.isConfirmed)}</OldTd>
      <OldTd>{convertDateToStringFormat(item.estimationDate, DATE_FORMAT)}</OldTd>
      <OldTd>
        <div
          className={classes.code}
          onClick={handleCodeClick}
          aria-hidden
        >
          {getValueIfExist(item.estimationCode)}
        </div>
      </OldTd>
      <OldTd>
        {readOnly
          ? '-'
          : getValueIfExist(
              `${item.requestCompany?.name}${
                item.requestCompanyManager?.name ? ` - ${item.requestCompanyManager?.name}` : ''
              }`
            )}
      </OldTd>
      <OldTd>{getValueIfExist(item.clientCompany?.name)}</OldTd>
      <OldTd>{getValueIfExist(item.experimentInformation)}</OldTd>
      <OldTd>{getLocaleStringIfExist(item.totalAmount)}</OldTd>
      <OldTd>{getValueIfExist(item.schedule)}</OldTd>
      <OldTd>
        {item.pdf?.id ? (
          <FileDisplayText
            onClick={downloadFile(item.pdf?.id)}
            fileName={item.pdf?.filename}
          />
        ) : (
          '-'
        )}
      </OldTd>
    </TableRow>
  );
};
