import type { DepartmentVO } from 'department/domain';
import type { FileItemView } from 'file-item';

export type UserId = number & { readonly _brand: symbol };

export function UserId(id: number) {
  return id as UserId;
}

export interface UserShortVO {
  id: UserId;
  name: string;
  username: string;
  email: string;
  role: AdminRoleNameView;
  department: DepartmentVO;
  profile?: FileItemView;
}

export interface UserVO extends UserShortVO {
  engName?: string;
  sex?: string;
  employeeId?: string;
  mobilePhone?: string;
  privateEmail?: string;
  emergencyPhone?: string;
  relationship?: string;
  address?: string;
  birthDate?: Date;
  createdAt: Date;
  loginAt?: Date;
  passwordChangedAt?: Date;
  menus?: MenuShortView[];
}

export interface MenuShortView {
  id: number;
  parentId: number;
  authorization: AuthorizationShortView;
}

export interface AuthorizationShortView {
  id: number;
  type: AuthorizationType;
}

export enum SexCategory {
  MALE = '남성',
  FEMALE = '여성',
}

export interface AdminRoleNameView {
  id: number;
  name: string;
  canAccessMenuAuthorization: boolean;
  canAccessPageAuthorization: boolean;
  canAccessPageView: boolean;
  canAccessUserManagement: boolean;
  canAccessVariableManagement: boolean;
}

export const sexCategoryList: SexCategory[] = [SexCategory.MALE, SexCategory.FEMALE];

export interface UserError {
  code: string;
  message: string;
}

export enum AuthorizationType {
  V = 'V', // 접근 가능 권한
  R = 'R', // 접근 + 열람 권한
  W = 'W', // 접근 + 열람 + 쓰기 권한
  X = 'X', // 접근 불가
}
