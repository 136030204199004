import React from 'react';
import type { UserVO } from '../../../user/domain';
import { Box } from '@mui/material';
import { ColorPalette } from '../../../assets/theme';
import DataFieldWithLabel from '../../../layouts/DataFieldLabel';
import Input from '../../../layouts/Input';
import dayjs from 'dayjs';

interface Props {
  writer: UserVO;
  createdAt: Date;
  modifiedAt: Date;
}

export default function WriterInfo({ writer, createdAt, modifiedAt }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          width: '100%',
          border: `1px solid ${ColorPalette._e4e9f2}`,
          padding: '10px',
          borderRadius: '5px',
        }}
      >
        <DataFieldWithLabel
          label="소속부서"
          labelSX={{ minWidth: '80px' }}
        >
          <Input
            readOnly
            value={writer.department.name}
          />
        </DataFieldWithLabel>

        <DataFieldWithLabel
          label="작성자"
          labelSX={{ minWidth: '80px' }}
        >
          <Input
            readOnly
            value={writer.name}
          />
        </DataFieldWithLabel>

        <DataFieldWithLabel
          label="최조 작성"
          labelSX={{ minWidth: '80px' }}
        >
          <Input
            readOnly
            value={dayjs(createdAt).format('YYYY-MM-DD HH:mm')}
          />
        </DataFieldWithLabel>
        <DataFieldWithLabel
          label="최종 수정"
          labelSX={{ minWidth: '80px' }}
        >
          <Input
            readOnly
            value={modifiedAt ? dayjs(modifiedAt).format('YYYY-MM-DD HH:mm') : ''}
          />
        </DataFieldWithLabel>
      </Box>
    </Box>
  );
}
