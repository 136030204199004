import React, { useMemo } from 'react';
import { Box, Modal } from '@mui/material';
import { useUserMobileNotificationState } from '@front/user_notification/useState';
import { shallow } from 'zustand/shallow';
import { ColorPalette } from '@front/assets/theme';
import { mobileNotificationLayout } from '@front/src/features/mobile-notification/components/layouts';
import { MobileNotificationListWidget } from '@front/src/features/mobile-notification/widgets/list/widget';
import { MobileNotificationHeaderWidget } from '@front/src/features/mobile-notification/widgets/header/widget';
import { isMobileDevice } from '@front/util/PwaUtil';
import ModalLayout from '@front/layouts/ModalLayout';

export { Index as MobileNotificationFeature };

const Index = () => {
  const { Header, Body, Layout } = mobileNotificationLayout;
  const { isMobileNotificationModalOpen, setIsMobileNotificationModalOpen } =
    useUserMobileNotificationState(
      (state) => ({
        isMobileNotificationModalOpen: state.isMobileNotificationModalOpen,
        setIsMobileNotificationModalOpen: state.setIsMobileNotificationModalOpen,
      }),
      shallow
    );
  const isMobile = isMobileDevice();
  const BoxLayout = useMemo(
    () => (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, calc(-50% + 50px))',
          width: isMobile ? '100dvw' : '50dvw',
          height: isMobile ? '100dvh' : '70dvh',
          backgroundColor: ColorPalette._242e43,
          padding: '5px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {isMobile && (
          <Box
            sx={{
              position: 'absolute',
              top: '-30px',
              right: '25px',
              color: ColorPalette._ffffff,
              fontWeight: 'bold',
              fontSize: '20px',
            }}
            onClick={() => setIsMobileNotificationModalOpen()}
          >
            X
          </Box>
        )}
        <Layout>
          <Header isMobile={isMobile}>
            <MobileNotificationHeaderWidget />
          </Header>
          <Body isMobile={isMobile}>
            <MobileNotificationListWidget />
          </Body>
        </Layout>
      </Box>
    ),
    [isMobile]
  );
  if (!isMobileNotificationModalOpen) return <></>;

  return (
    <>
      {isMobile ? (
        <Modal
          open={isMobileNotificationModalOpen}
          onClose={() => setIsMobileNotificationModalOpen()}
          slotProps={{
            backdrop: {
              sx: {
                backgroundColor: 'transparent',
              },
            },
          }}
        >
          {BoxLayout}
        </Modal>
      ) : (
        <ModalLayout
          open={isMobileNotificationModalOpen}
          onClose={() => setIsMobileNotificationModalOpen()}
          title="알림"
          width="50dvw"
          height="70dvh"
          backdropClose={true}
        >
          {BoxLayout}
        </ModalLayout>
      )}
    </>
  );
};
