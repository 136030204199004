import React from 'react';
import { ProjectSalesInfoBidResultUpdateModalWidget } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/update-modal/widgets/widget';
import { useProjectSalesInfoBidResultState } from '@front/src/features/project-sales-info/features/bid/features/bid-result/widgets/useState';

export { ModalWrapper as ProjectSalesInfoBidResultUpdateModalWrapper };

const ModalWrapper = () => {
  const isUpdateModalOpen = useProjectSalesInfoBidResultState((state) => state.isUpdateModalOpen);
  return <>{isUpdateModalOpen && <ProjectSalesInfoBidResultUpdateModalWidget />}</>;
};
