import { useQuery } from 'react-query';
import { affiliatedCompanyPersonMeetingHistoryApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/meeting-history/query/api';
import type { AffiliatedCompanyMeetingHistoryShortView } from '@front/src/features/affiliated-company/features/meeting-history/types/view';

export const affiliatedCompanyPersonMeetingHistoryQuery = {
  usePersonMeetingHistoryListGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyMeetingHistoryShortView[]>({
      queryFn: () =>
        affiliatedCompanyPersonMeetingHistoryApi.getPersonMeetingHistoryList(id, menuId),
      queryKey: ['affiliated-company', 'person', 'meeting-history', id],
      enabled: !!id,
      cacheTime: 0,
    });
    return {
      data,
      isLoading,
    };
  },
};
