import React, { useContext } from 'react';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { OldTd, Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { convertAmountToStringForForm, convertNullToEmptyForForm } from '@front/src/utils';
import { getOptionListFromVariableList } from '@front/src/features/manageable-variable/utils';
import type { ProjectContractReviewView } from '@front/src/features/project-sales-info/features/contract/features/reivew/types/view';
import { ProjectSalesInfoContractReviewModalContext } from '@front/src/features/project-sales-info/features/contract/features/reivew/features/modal/widgets/context';
import { ProjectSalesInfoContractReviewModalButtonGroup } from '@front/src/features/project-sales-info/features/contract/features/reivew/features/modal/components/modal-button-group';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import { getStructuralDesignCompanyOptionList } from '@front/src/features/project-sales-info/features/contract/utils';
import { HookFormTextarea } from '@front/src/components/textarea-with-hook-form/textarea-with-hook-form';
import { useProjectSalesInfoContractReviewState } from '@front/src/features/project-sales-info/features/contract/features/reivew/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";

export { ModalTable as ProjectSalesInfoContractReviewModalTable };

const getValues = (detail?: ProjectContractReviewView) => ({
  category: convertNullToEmptyForForm(detail?.category),
  structureCompanyId: convertNullToEmptyForForm(detail?.structureCompany?.id),
  reviewAmount: convertAmountToStringForForm(detail?.reviewAmount),
  reviewContractDate: convertNullToEmptyForForm(detail?.reviewContractDate),
  note: convertNullToEmptyForForm(detail?.note),
});
const ModalTable = () => {
  const {
    d: { detail, categoryList, companyList },
  } = useContext(ProjectSalesInfoContractReviewModalContext);
  const methods = useForm({
    values: getValues(detail),
  });
  const { readOnly } = useProjectSalesInfoContractReviewState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <Th
                center={false}
                width="140px"
              >
                계약분류
              </Th>
              <OldTd>
                <HookFormSelect
                  width="124px"
                  defaultLabel="선택"
                  isDefaultPossible
                  name="category"
                  optionList={getOptionListFromVariableList(categoryList)}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>계약 구조사</Th>
              <OldTd>
                <HookFormSelect
                  width="300px"
                  defaultLabel="선택"
                  isDefaultPossible
                  name="structureCompanyId"
                  optionList={getStructuralDesignCompanyOptionList(companyList)}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>구검비</Th>
              <OldTd>
                <HookFormInput
                  width="124px"
                  name="reviewAmount"
                  inputType={InputType.AMOUNT}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow key={Math.random()}>
              <Th center={false}>구검 계약 일자</Th>
              <OldTd sx={{textAlign:'left'}}>
                <DatePickerWithHookForm
                  name="reviewContractDate"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>비고</Th>
              <OldTd sx={{ padding: '10px' }}>
                <HookFormTextarea
                  name="note"
                  minRows={2}
                  resize="none"
                  disabled={readOnly}
                  variable={true}
                />
              </OldTd>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ProjectSalesInfoContractReviewModalButtonGroup />
    </FormProvider>
  );
};
