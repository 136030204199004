import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import type { BackdropProps } from '@mui/material/Backdrop';
import Backdrop from '@mui/material/Backdrop';
import { ColorPalette } from '@front/assets/theme';

export default function BackdropLoadingSpinnerUI({
  open,
  sx = {},
  ...rest
}: Readonly<BackdropProps>) {
  return (
    <Backdrop
      open={open}
      sx={{
        zIndex: 1,
        background: ColorPalette.etc.loading_spinner,
        ...sx,
      }}
      {...rest}
    >
      <CircularProgress
        sx={{
          width: '40px',
          height: '40px',
          strokeLinecap: 'round',
          strokeWidth: 4,
          color: ColorPalette.greyscale.white,
        }}
      />
    </Backdrop>
  );
}
