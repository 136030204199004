import { userNotificationUrlApi } from '@front/src/features/mobile-notification/constants/constant';
import { default as axios } from '@front/src/config/axios';

import type {
  UserNotificationIsReadParameter,
  UserNotificationParameter,
  UserNotificationSearchParams,
} from '@front/src/features/mobile-notification/types/parameter';

const url = {
  toggleAllUserNotification: () => `${userNotificationUrlApi}`,
  toggleUserNotification: (id: number) => `${userNotificationUrlApi}/${id}`,
  deleteAllUserNotification: () => `${userNotificationUrlApi}`,
  deleteUserNotification: (id: number) => `${userNotificationUrlApi}/${id}`,
  getUserNotificationCount: () => `${userNotificationUrlApi}/count`,
  updateUserNotificationPin: (id: number) => `${userNotificationUrlApi}/${id}`,
  getUserNotificationSearchList: () => `${userNotificationUrlApi}/search`,
};

export const mobileNotificationApi = {
  toggleAllUserNotification: async () => {
    await axios.post(url.toggleAllUserNotification());
  },
  toggleUserNotification: async (params: UserNotificationIsReadParameter) => {
    await axios.post(url.toggleUserNotification(params.id), params);
  },
  deleteAllUserNotification: async () => {
    await axios.delete(url.deleteAllUserNotification());
  },
  deleteUserNotification: async (id: number) => {
    await axios.delete(url.deleteUserNotification(id));
  },
  getUserNotificationCount: async () => {
    const { data } = await axios.get(url.getUserNotificationCount());
    return data;
  },
  updateUserNotificationPin: async (params: UserNotificationParameter) =>
    await axios.put(url.updateUserNotificationPin(params.id), params),
  getUserNotificationSearchList: async (params: UserNotificationSearchParams) => {
    const { data } = await axios.get(url.getUserNotificationSearchList(), {
      params: {
        type: params.type,
        status: params.status,
      },
    });
    return data;
  },
};
