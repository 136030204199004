import React from 'react';
import { Box, TableBody, TableContainer, TableHead } from '@mui/material';
import TextBox from '@front/layouts/Text';
import { Table as MuiTable } from '@front/layouts/Table';
import { AccountingUploadSalesInfoGetYearTableBody } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/get-year/components/table-body';
import { AccountingUploadSalesInfoGetYearTableHeader } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/get-year/components/table-header';
import { AccountingUploadSalesInfoGetYearTargetModifiedAt } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/get-year/components/target-modified-at';
import { AccountingUploadSalesInfoGetExpectModifiedAt } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/get-year/components/expect-modified-at';
import { AccountingUploadSalesInfoGetYearTargetIsConfirmed } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/get-year/components/target-is-confirmed';

export { Index as AccountingUploadSalesInfoGetYear };

const Index = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
        }}
      >
        <AccountingUploadSalesInfoGetYearTargetIsConfirmed />
        <AccountingUploadSalesInfoGetYearTargetModifiedAt />
        <AccountingUploadSalesInfoGetExpectModifiedAt />
      </Box>
      <TextBox variant="body11">단위: 만원, 부가세 포함</TextBox>
    </Box>
    <TableContainer
      sx={{
        maxWidth: '88dvw',
        maxHeight: '72dvh',
        overflow: 'auto',
      }}
    >
      <MuiTable>
        <TableHead>
          <AccountingUploadSalesInfoGetYearTableHeader />
        </TableHead>
        <TableBody>
          <AccountingUploadSalesInfoGetYearTableBody />
        </TableBody>
      </MuiTable>
    </TableContainer>
  </Box>
);
