import { createAction } from 'typesafe-actions';
import type { AlertProps, BlockProps, ConfirmProps } from '@front/dialog/parameter';

export enum DialogActionType {
  openAlert = 'system/alert/open',
  openError = 'system/error/open',
  openConfirm = 'system/confirm/open',
  close = 'system/dialog/close',
  openBlock = 'system/block/open',
}

export const dialogAction = {
  openAlert: createAction(DialogActionType.openAlert)<AlertProps | string>(),
  openError: createAction(DialogActionType.openError)<AlertProps | string>(),
  openConfirm: createAction(DialogActionType.openConfirm)<ConfirmProps>(),
  close: createAction(DialogActionType.close)(),
  openBlock: createAction(DialogActionType.openBlock)<BlockProps | string>(),
};
