import { useShallow } from 'zustand/react/shallow';
import { useAccountingUploadSalesInfoPerformanceModalState } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/useState';
import { AccountingUploadSalesInfoPerformanceModalRepository } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/repository/repository';

export { useLogic as useAccountingUploadSalesInfoPerformanceModalUpdateLogic };

const useLogic = () => {
  const { setIsOpen } = useAccountingUploadSalesInfoPerformanceModalState(
    useShallow((state) => ({
      setIsOpen: state.setIsOpen,
    }))
  );
  const { run: onUpdate, setCallback } =
    AccountingUploadSalesInfoPerformanceModalRepository.useUpdate();
  setCallback({
    onSuccess: () => {
      setIsOpen(false);
    },
  });
  return {
    onUpdate,
  };
};
