import { projectSalesInfoSubjectReviewModalComplexInformationRepository } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/repository';
import { useProjectSalesInfoSubjectReviewModalComplexInformationState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/useState';

export { useLogic as useProjectSalesInfoSubjectReviewModalComplexInformationCreateLogic };
const useLogic = () => {
  const { run: onCreate, setCallback } =
    projectSalesInfoSubjectReviewModalComplexInformationRepository.useCreate();
  const setId = useProjectSalesInfoSubjectReviewModalComplexInformationState(
    (state) => state.setId
  );
  setCallback({
    onSuccess: ({ data }) => {
      setId(data);
    },
  });
  return { onCreate };
};
