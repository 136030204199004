import type { ReactNode } from 'react';
import { createContext } from 'react';
import type { MemoView } from '@front/src/types';

export { Context as AffiliatedPersonMemoListContext };

interface State {
  list?: MemoView[];
  updateWidget?: ReactNode;
  deleteWidget?: ReactNode;
}

const Context = createContext<State>({});
