import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import { userNotificationAction } from '@front/user_notification/action';
import { useDispatch } from 'react-redux';
import type { WorkCreatorParameter } from '@front/src/features/work/features/work/features/creator/types/parameter';

const workManagerMutation = {
  useUpdate: (menuId) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: WorkCreatorParameter) =>
        workWorkApi.updateManager(params.id, params, menuId),
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'manager'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'drawer'],
        });
        await queryClient.invalidateQueries(['global-nav-bar']);
        await queryClient.invalidateQueries({
          queryKey: ['work', 'role'],
        });
        dispatch(userNotificationAction.requestCount());
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
};

export default workManagerMutation;
