import React, { Suspense, useRef } from 'react';
import type { AppRoute } from '../../services/routes';
import HowooGantt from '../HowooGantt';
import type { GanttItem, GroupItem } from '../types/HowooGanttTypes';
import { isMobileDevice } from '@front/util/PwaUtil';
import { LoadingSpinner } from '@front/src/components/loading-spinner';

function Element() {
  const tabRef = useRef(null);

  const onTaskClick = (task: GanttItem) => {
    console.debug('task clicked');
    console.debug(task);
  };

  const onGroupClick = (group: GroupItem) => {
    console.debug('group clicked');
    console.debug(group);
  };

  const onTaskDragDone = (task: GanttItem) => {
    console.debug('task drag done');
    console.debug(task);
  };

  return (
    <div
      style={{
        height: 'calc(100% - 30px)',
        padding: '15px',
        paddingTop: isMobileDevice() ? '100px' : '',
      }}
      ref={tabRef}
    >
      <HowooGantt
        showGroup={true}
        containerRef={tabRef}
        taskDraggable={true}
        onTaskClick={onTaskClick}
        onGroupClick={onGroupClick}
        onTaskDragDone={onTaskDragDone}
        testMode={true}
        testItemCount={1000}
      />
    </div>
  );
}

const ganttPageRoute: AppRoute = {
  path: '/gantt',
  element: (
    <Suspense fallback={<LoadingSpinner />}>
      <Element />
    </Suspense>
  ),
};

export default ganttPageRoute;
