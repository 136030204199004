import { create } from 'zustand';
import type { AffiliatedCompanyProjectListGetParameter } from '@front/src/features/affiliated-company/features/project/types/parameter';
import { defaultParameterFromAffiliatedCompanyProject } from '@front/src/features/affiliated-company/features/project/utils/constant';
import type { AffiliatedCompanyProjectView } from '@front/src/features/affiliated-company/features/project/types/view';

export { useState as useAffiliatedCompanyProjectState };

interface State {
  parameter: AffiliatedCompanyProjectListGetParameter;
  defaultList?: AffiliatedCompanyProjectView[];
  readOnly?: boolean;
}

interface Actions {
  setParameter: (parameter: AffiliatedCompanyProjectListGetParameter) => void;
  setDefaultList: (list?: AffiliatedCompanyProjectView[]) => void;
  setReadOnly: (readOnly?: boolean) => void;
}

const useState = create<State & Actions>((set) => ({
  parameter: defaultParameterFromAffiliatedCompanyProject,
  setParameter: (parameter) =>
    set(() => ({
      parameter,
    })),
  setDefaultList: (defaultList) =>
    set(() => ({
      defaultList,
    })),
  readOnly: false,
  setReadOnly: (readOnly) => set(() => ({ readOnly })),
}));
