import React, { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Select } from '@front/src/components/Select';

export interface OptionType<T = string> {
  value: T;
  label: string;
}

export { HookFormSelect };

interface Props<T = string> {
  name: string;
  defaultLabel?: string;
  optionList: OptionType<T>[];
  onSubmit?: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  width?: string;
  height?: string;
  isDefaultPossible?: boolean;
  displayEmpty?: boolean;
  onChange?: (e) => void;
  disabled?: boolean;
  readOnly?: boolean;
  style?: React.CSSProperties;
  required?: boolean;
  disableValue?: string;
}

const HookFormSelect = ({
  name,
  onSubmit,
  height = '52px',
  width,
  style,
  onChange,
  required,
  ...rest
}: Props) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange: onHookFormChange },
  } = useController({ name, control, rules: { required: required } });
  const handleChange = useCallback(
    async (e) => {
      onHookFormChange(e);
      onChange?.(e);
      onSubmit?.();
    },
    [onChange, onHookFormChange, onSubmit]
  );
  return (
    <div
      style={{
        ...style,
        width,
        height,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Select
        value={value}
        onChange={handleChange}
        {...rest}
      />
    </div>
  );
};
