import Layout from '@front/src/components/layout/drawer-search-form/layout';
import ItemBox from '@front/src/components/layout/drawer-search-form/item-box';
import Select from '@front/src/components/layout/drawer-search-form/select';
import Input from '@front/src/components/layout/drawer-search-form/input';

export const DrawerSearchForm = {
  Layout,
  ItemBox,
  Select,
  Input,
};
