import React from 'react';
import classes from '@front/src/features/project-sales-info/features/activity/components/layouts/header.module.scss';
import { Box } from '@mui/material';

export { Header as ActivityHeader };

interface Props {
  children?: React.ReactNode;
  text?: string;
  title?: string;
}

const Header = ({ children, text, title }: Props) => (
  <div className={classes.container}>
    {title && (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          fontSize: '16px',
          fontWeight: 'bold',
        }}
      >
        {title}
      </Box>
    )}
    <span className={classes.text}>{text}</span>
    <div className={classes.btn__container}>{children}</div>
  </div>
);
