import { create } from 'zustand';
import type { DefaultFunction } from '@front/type/Function';

interface State {
  colored?: number;
  editOpenList: number[];
  isLoading: boolean;
}

interface Actions {
  setColored: (colored?: number) => void;
  addEditOpenId: (id: number) => void;
  clearEditOpenList: DefaultFunction;
  setIsLoading: (isLoading: boolean) => void;
}

const useWorkWorkerState = create<State & Actions>((set) => ({
  setColored: (colored) => set({ colored }),
  editOpenList: [],
  clearEditOpenList: () => set({ editOpenList: [] }),
  addEditOpenId: (id) =>
    set((state) => {
      if (state.editOpenList.includes(id)) {
        return { editOpenList: state.editOpenList.filter((originId) => originId !== id) };
      }
      return { editOpenList: [...state.editOpenList, id] };
    }),
  isLoading: false,
  setIsLoading: (isLoading) => set({ isLoading }),
}));

export default useWorkWorkerState;
