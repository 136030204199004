import { default as axios } from '@front/src/config/axios';
import { accountingBankAccountURL } from '@front/src/features/accounting/features/settings/utils/constant';
import type { AccountingSettingsBankAccountUpdateParameter } from '@front/src/features/accounting/features/settings/features/bank-account/types/parameter';

export { Api as AccountingSettingsBankAccountApi };

const url = {
  getList: () => `${accountingBankAccountURL}`,
  update: (id?) => `${accountingBankAccountURL}/${id}`,
};

const Api = {
  getList: async () => {
    const { data } = await axios.get(url.getList());
    return data;
  },
  update: async (params: AccountingSettingsBankAccountUpdateParameter) => {
    const { data } = await axios.put(url.update(params.id), params);
    return data;
  },
};
