import React, { useCallback, useContext } from 'react';
import { OldTd } from '@front/layouts/Table';
import { Box, TableRow } from '@mui/material';
import {
  SalesAccountingAmountSubTypeObjectForLabel,
  SalesAccountingAmountType,
  SalesAccountingAmountTypeObjectForLabel,
} from '@front/src/features/accounting/features/upload/features/sales-info/types/domain';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { ColorPalette } from '@front/assets/theme';
import { useShallow } from 'zustand/react/shallow';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { getTypeForAccountingUploadSalesInfoFormByList } from '@front/src/features/accounting/features/upload/features/sales-info/features/utils';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import ErrorIcon from '@mui/icons-material/Error';
import { useCustomDialog } from '@front/src/features/dialog';
import { useAccountingUploadSalesInfoPerformanceModalState } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/useState';
import { AccountingUploadSalesInfoPerformanceModalListContext } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/widgets/list/provider/context';
import { descriptionObjectFromProjectSalesManagementPerformanceModalForAlert } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/utils';

export { TableBody as AccountingUploadSalesInfoPerformanceModalListTableBody };

const TableBody = () => {
  const { list } = useContext(AccountingUploadSalesInfoPerformanceModalListContext);
  const { setProjectList } = useAccountingUploadSalesInfoPerformanceModalState(
    useShallow((state) => ({
      setProjectList: state.setProjectList,
    }))
  );
  const handleCellClick = useCallback(
    (item) => {
      setProjectList(item.projectList);
    },
    [setProjectList]
  );
  const renderProject = useCallback(
    (item) => {
      if (item.type === SalesAccountingAmountType.NET_ORDER_BALANCE) {
        return <OldTd>-</OldTd>;
      }
      if (item.projectList.length === 0) {
        return <OldTd>0</OldTd>;
      }
      return (
        <OldTd
          onClick={() => handleCellClick(item)}
          sx={{
            '&:hover': {
              textDecoration: 'underline',
              color: ColorPalette._386dd6,
              cursor: 'pointer',
            },
          }}
        >
          {item.projectList.length}
        </OldTd>
      );
    },
    [handleCellClick]
  );
  const { alert } = useCustomDialog();
  const handleIconClick = useCallback(
    (type) => {
      alert({
        title: '산출 근거 설명',
        children: descriptionObjectFromProjectSalesManagementPerformanceModalForAlert[type],
      });
    },
    [alert, descriptionObjectFromProjectSalesManagementPerformanceModalForAlert]
  );
  if (list?.length === 0) {
    return (
      <NoResult
        colSpan={4}
        height="40px"
      />
    );
  }
  return (
    <>
      {list?.map((item) => (
        <TableRow key={item.type}>
          <OldTd
            sx={{
              position: 'relative',
            }}
          >
            {SalesAccountingAmountTypeObjectForLabel[item.type]}
            <Box
              sx={{
                position: 'absolute',
                right: '4%',
                display: 'inline-block',
                textAlign: 'center',
                verticalAlign: 'middle',
                margin: '0 0 0 4px',
                cursor: 'pointer',
              }}
              onClick={() => handleIconClick(item.type)}
            >
              <ErrorIcon fontSize="small" />
            </Box>
          </OldTd>
          <OldTd>{SalesAccountingAmountSubTypeObjectForLabel[item.subType]}</OldTd>
          <OldTd>
            <HookFormInput
              readOnly
              name={`amount${getTypeForAccountingUploadSalesInfoFormByList.indexOf(item.type)}`}
              inputType={InputType.AMOUNT}
            />
          </OldTd>
          {renderProject(item)}
        </TableRow>
      ))}
    </>
  );
};
