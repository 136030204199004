import { default as axios } from '@front/src/config/axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import type {
  ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateParameter,
  ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonSearchParameter,
} from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/types/parameter';

const url = {
  create: (id?) => `${projectSalesInfoURL}/${id}/affiliated-company-person`,
  getPersonList: () => `${projectSalesInfoURL}/affiliated-company-person/search`,
};

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalApi = {
  create: async (
    params: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateParameter
  ) => {
    await axios.post(url.create(params.id), params);
  },
  getPersonList: async (
    params: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonSearchParameter
  ) => {
    const { data } = await axios.get(url.getPersonList(), { params });
    return data;
  },
};
