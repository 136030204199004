import React from 'react';

import { DialogAlertWidget } from '@front/src/features/dialog/widgets/alert/widget';
import { DialogConfirmWidget } from '@front/src/features/dialog/widgets/confirm/widget';

const DialogFeature = () => (
  <>
    <DialogConfirmWidget />
    <DialogAlertWidget />
  </>
);

export default DialogFeature;
