import { accountingUploadRepository } from '@front/src/features/accounting/features/upload/features/ecount-data/repository/repository';
import { useCustomDialog } from '@front/src/features/dialog';
import { useCallback } from 'react';

export { useLogic as useAccountingUploadEcountDataUploadLogic };

const useLogic = () => {
  const {
    run: onTexAccountingCreate,
    setCallback: setCallbackTaxAccounting,
    isLoading: isTexAccountingCreateLoading,
  } = accountingUploadRepository.useCreateTaxAccounting();
  const {
    run: onBankAccountCreate,
    setCallback: setCallbackBankAccount,
    isLoading: isBankAccountCreateLoading,
  } = accountingUploadRepository.useCreateBankAccount();
  const {
    run: onDailyReportCreate,
    setCallback: setCallbackDailyReport,
    isLoading: isDailyReportCreateLoading,
  } = accountingUploadRepository.useCreateDailyReport();
  const { alert } = useCustomDialog();
  const handleUploadSuccess = useCallback(() => {
    alert({
      children: [
        {
          value: '정상적으로 처리하였습니다.',
        },
      ],
    });
  }, [alert]);
  setCallbackTaxAccounting({
    onSuccess: handleUploadSuccess,
  });
  setCallbackBankAccount({
    onSuccess: handleUploadSuccess,
  });
  setCallbackDailyReport({
    onSuccess: handleUploadSuccess,
  });
  return {
    onTexAccountingCreate,
    onBankAccountCreate,
    onDailyReportCreate,
    isLoading:
      isTexAccountingCreateLoading || isBankAccountCreateLoading || isDailyReportCreateLoading,
  };
};
