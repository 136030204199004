import SearchForm, { SearchFormField } from 'layouts/SearchForm';
import CheckboxField from 'components/CheckboxField';

import { keywordTypeList } from 'user/query';
import React, { useContext } from 'react';
import { MenuItem } from '@mui/material';
import Select from 'layouts/Select';
import { FormikContext } from 'formik';
import Input from 'layouts/Input';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';

export default function () {
  const formik = useContext(FormikContext);
  const [roleList] = useSelector((root: RootState) => [root.user.roleList], shallowEqual);
  return (
    <SearchForm>
      <SearchFormField label="권한">
        <CheckboxField
          disableLabel
          name="role"
          label="권한"
          options={
            Array.isArray(roleList)
              ? roleList.map((item) => ({
                  key: item.id,
                  text: item.name,
                }))
              : []
          }
        />
      </SearchFormField>
      <SearchFormField
        label={
          <Select
            displayEmpty
            variant="transparent"
            value={formik.values.keywordType ?? ''}
            onChange={(e) => {
              const value = e.target.value || undefined;
              if (formik.values.keywordType !== value) {
                formik.setFieldValue('keywordType', value);
              }
            }}
          >
            <MenuItem value="">선택</MenuItem>
            {keywordTypeList.map((item) => (
              <MenuItem
                key={item.key}
                value={item.key}
              >
                {item.text}
              </MenuItem>
            ))}
          </Select>
        }
        children={
          <Input
            key={formik.values.keyword}
            defaultValue={formik.values.keyword ?? ''}
            variant="outlined"
            onBlur={(e) => {
              const value = e.target.value || undefined;
              if (formik.values.keyword !== value) {
                formik.setFieldValue('keyword', value);
              }
            }}
            onKeyDown={(e) => {
              if (e.key.toLowerCase() === 'enter') {
                const value = (e.target as HTMLInputElement).value ?? undefined;
                formik.setFieldValue('keyword', value);
                formik.handleSubmit();
                formik.setSubmitting(false);
              }
            }}
          />
        }
      />
    </SearchForm>
  );
}
