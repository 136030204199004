import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useCustomDialog } from '@front/src/features/dialog';
import { getCommonControlPanelHeaderItemList } from '@front/src/features/work/features/work/utils/constant';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import useWorkWorkerState from '@front/src/features/work/features/work/features/worker/useState';
import useWorkWorkerAddTop from '@front/src/features/work/features/work/features/worker/repository/useAddTop';
import useWorkWorkerAddBottom from '@front/src/features/work/features/work/features/worker/repository/useAddBottom';
import workWorkerMutation from '@front/src/features/work/features/work/features/worker/query/mutation';
import useWorkWorkerUpdate from '@front/src/features/work/features/work/features/worker/repository/useUpdate';
import useWorkWorkerCreate from '@front/src/features/work/features/work/features/worker/repository/useCreate';
import useWorkWorkerSeqUp from '@front/src/features/work/features/work/features/worker/repository/useSeqUp';
import useWorkWorkerSeqDown from '@front/src/features/work/features/work/features/worker/repository/useSeqDown';
import WorkWorkerTableBody from '@front/src/features/work/features/work/features/worker/components/TableBody';

export default function WorkWorkerTable({
  maxWidth,
  sectionId,
  dataId,
  menuId,
  ...rest
}: Readonly<SectionComponentProps>) {
  const { clearEditOpenList } = useWorkWorkerState(
    useShallow((state) => ({
      clearEditOpenList: state.clearEditOpenList,
    }))
  );
  const {
    isLoading,
    onAddTop,
    onAddBottom,
    onSeqUp,
    onSeqDown,
    onUpdate,
    onCreate,
    onRemove,
    onRemoveAll,
  } = useMutationLogic({ sectionId, dataId, menuId });
  const actions = {
    onRemoveAll,
    onAddTop,
    onAddBottom,
    onCloseAll: clearEditOpenList,
  };
  const controlPanelHeaderItemList = getCommonControlPanelHeaderItemList({
    actions,
  });
  const rowActions = {
    onUpdate,
    onCreate,
    onRemove,
    onSeqUp,
    onSeqDown,
  };
  return (
    <UiBuilderTable
      isLoading={isLoading}
      rowActions={rowActions}
      BodyComponent={WorkWorkerTableBody}
      controlPanelHeaderItemList={controlPanelHeaderItemList}
      sectionId={sectionId}
      dataId={dataId}
      menuId={menuId}
      {...rest}
      // sx={{
      //   maxWidth,
      // }}
    />
  );
}

function useMutationLogic({ sectionId, dataId, menuId }) {
  const { addEditOpenId, setColored } = useWorkWorkerState(
    useShallow((state) => ({
      setColored: state.setColored,
      addEditOpenId: state.addEditOpenId,
    }))
  );
  const { confirm } = useCustomDialog();
  const {
    run: onAddTop,
    setCallback: setAddTopCallback,
    isLoading: isAddTopLoading,
  } = useWorkWorkerAddTop(dataId, menuId);
  setAddTopCallback({
    onSuccess: ({ data }) => {
      setColored(data);
      addEditOpenId(data);
    },
  });
  const {
    run: onAddBottom,
    setCallback: setAddBottomCallback,
    isLoading: isAddBottomLoading,
  } = useWorkWorkerAddBottom(dataId, menuId);
  setAddBottomCallback({
    onSuccess: ({ data }) => {
      setColored(data);
      addEditOpenId(data);
    },
  });
  const {
    run: onUpdate,
    setCallback: setUpdateCallback,
    isLoading: isUpdateLoading,
  } = useWorkWorkerUpdate(menuId);
  setUpdateCallback({
    onSuccess: ({ data }, _, context) => {
      !context && setColored(data);
    },
  });
  const {
    run: onCreate,
    setCallback: setCreateCallback,
    isLoading: isCreateLoading,
  } = useWorkWorkerCreate(menuId);
  setCreateCallback({
    onSuccess: ({ data }) => {
      setColored(data);
      addEditOpenId(data);
    },
  });
  const { mutate: onDelete, isLoading: isDeleteLoading } = workWorkerMutation.useDelete({
    dataId,
    menuId,
    sectionId,
  });
  const { mutate: onDeleteAll, isLoading: isDeleteAllLoading } = workWorkerMutation.useDeleteAll({
    dataId,
    menuId,
    sectionId,
  });
  const {
    run: onSeqUp,
    setCallback: setSeqUpCallback,
    isLoading: isSeqUpLoading,
  } = useWorkWorkerSeqUp(menuId);
  setSeqUpCallback({
    onSuccess: ({ data }) => setColored(data),
  });
  const {
    run: onSeqDown,
    setCallback: setSeqDownCallback,
    isLoading: isSeqDownLoading,
  } = useWorkWorkerSeqDown(menuId);
  setSeqDownCallback({
    onSuccess: ({ data }) => setColored(data),
  });

  const onRemove = (id) => {
    confirm({
      lineBreakChildren: [{ value: '선택한 내용을 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDelete(id);
      },
    });
  };

  const onRemoveAll = () => {
    confirm({
      lineBreakChildren: [{ value: '선택한 내용을 전체 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDeleteAll();
      },
    });
  };

  const isLoading =
    isDeleteAllLoading ||
    isAddTopLoading ||
    isAddBottomLoading ||
    isUpdateLoading ||
    isCreateLoading ||
    isDeleteLoading ||
    isSeqUpLoading ||
    isSeqDownLoading;
  return {
    isLoading,
    onAddTop,
    onAddBottom,
    onUpdate,
    onCreate,
    onSeqUp,
    onSeqDown,
    onRemove,
    onRemoveAll,
  };
}
