import type { ReactNode } from 'react';
import React from 'react';
import classes from '@front/src/components/layout/modal/footer.module.scss';

export { Footer as ModalLayoutFooter };

interface Props {
  children: ReactNode;
}

const Footer = ({ children }: Props) => <div className={classes.container}>{children}</div>;
