import { SalesAccountingAmountSubType } from '@front/src/features/accounting/features/upload/features/sales-info/types/domain';
import { useAccountingUploadSalesInfoState } from '@front/src/features/accounting/features/upload/features/sales-info/useState';
import { useShallow } from 'zustand/react/shallow';
import { AccountingUploadSalesInfoPerformanceModalRepository } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/repository/repository';
import { useAccountingUploadSalesInfoPerformanceModalState } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/useState';
import { useEffect } from 'react';

export { useLogic as useAccountingUploadSalesInfoPerformanceModalListLogic };

const useLogic = () => {
  const { year } = useAccountingUploadSalesInfoState(
    useShallow((state) => ({
      year: state.year,
    }))
  );
  const { month } = useAccountingUploadSalesInfoPerformanceModalState(
    useShallow((state) => ({
      month: state.month,
    }))
  );
  const { data: list, remove } = AccountingUploadSalesInfoPerformanceModalRepository.useListGet({
    year,
    subType: SalesAccountingAmountSubType.PERFORMANCE,
  });
  const { data: performanceList, remove: removePerformanceList } =
    AccountingUploadSalesInfoPerformanceModalRepository.useExpectOrPerformanceListGet({
      year,
      month,
      subType: SalesAccountingAmountSubType.PERFORMANCE,
    });
  useEffect(
    () => () => {
      remove();
      removePerformanceList();
    },
    [remove, removePerformanceList]
  );
  return {
    list: performanceList,
    isConfirmed: list?.find((l) => l.month === month)?.isConfirmed,
  };
};
