import { useQuery } from 'react-query';
import { projectSalesInfoPaymentHistoryUpdateModalApi } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/update-modal/query/api';
import type { ProjectReviewAmountPaymentHistoryView } from '@front/src/features/project-sales-info/features/collection/features/payment-history/types/view';

export const projectSalesInfoPaymentHistoryUpdateModalQuery = {
  usePaymentHistoryDetailGet: (itemId: number) => {
    const { data, isLoading } = useQuery<ProjectReviewAmountPaymentHistoryView>({
      queryFn: () => projectSalesInfoPaymentHistoryUpdateModalApi.getPaymentHistoryDetail(itemId),
      queryKey: ['project-sales-info', 'collection', 'payment-history', 'detail', itemId],
    });
    return {
      data,
      isLoading,
    };
  },
  useConsortiumCompanyGet: (id: number) => {
    const { data, isLoading } = useQuery({
      queryKey: ['project-sales-info', 'collection', 'payment-history', 'consortium', id],
      queryFn: () => projectSalesInfoPaymentHistoryUpdateModalApi.getConsortiumCompany(id),
    });
    return {
      data,
      isLoading,
    };
  },
};
