import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { arrangementAction } from '@front/ost_arrangement/action';
import { fixedContentsHeight } from '@front/util/ConstantCommonVariable';
import { initialArrangementQuery } from '@front/ost_arrangement/query';
import MemoDrawerService from '@front/components/Memo/route/drawer';
import ArrangementFormService from '@front/ost_arrangement/service/memo/form';
import ArrangementFilterService from '@front/ost_arrangement/service/memo/filter';
import ArrangementListService from '@front/ost_arrangement/service/memo/list';

const ArrangementMemoService = () => {
  const dispatch = useDispatch();
  const memoDrawerOpen = useSelector(
    (root: RootState) => root.arrangement.memoDrawerOpen,
    shallowEqual
  );
  const id = useSelector((root: RootState) => root.proposal.id, shallowEqual);
  const setMemoDrawerOpen = useCallback(
    (open: boolean) => dispatch(arrangementAction.setMemoDrawerOpen(open)),
    [dispatch]
  );
  const [formHeight, setFormHeight] = useState<number>(fixedContentsHeight);

  useEffect(() => {
    if (id) {
      dispatch(arrangementAction.setMemoFilter(initialArrangementQuery));
    }
  }, [id]);

  return (
    <MemoDrawerService
      isOpen={memoDrawerOpen}
      setDrawer={setMemoDrawerOpen}
      children={
        <>
          <ArrangementFormService setFormHeight={setFormHeight} />
          <ArrangementFilterService id={id} />
          <ArrangementListService formHeight={formHeight} />
        </>
      }
    />
  );
};

export default ArrangementMemoService;
