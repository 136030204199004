import React from 'react';
import { PersonTabType } from '@front/src/features/affiliated-company/types/domain';
import AffiliatedCompanyPersonDetailModalMeetingHistoryWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/meeting-history/widgets/widget';
import { useAffiliatedCompanyPersonDetailModalState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import AffiliatedCompanySalesActivityFeature from '@front/src/features/affiliated-company/features/sales-activity';
import AffiliatedCompanyPersonDetailModalOutlineCareerWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/widgets/widget';
import AffiliatedCompanyPersonDetailModalCharacterWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/section/character/widget';
import AffiliatedCompanyPersonDetailModalRelationshipWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/widgets/widget';
import AffiliatedCompanyPersonDetailModalGiftHistoryWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/widgets/widget';

export { Widget as AffiliatedCompanyPersonDetailModalSectionWidget };

interface Props {
  readOnly?: boolean;
}

const Widget = ({ readOnly }: Props) => {
  const { tab } = useAffiliatedCompanyPersonDetailModalState(
    useShallow((state) => ({
      tab: state.tab,
    }))
  );
  if (tab === PersonTabType.OUTLINE) {
    return <AffiliatedCompanyPersonDetailModalOutlineCareerWidget readOnly={readOnly} />;
  }
  if (tab === PersonTabType.CHARACTER) {
    return <AffiliatedCompanyPersonDetailModalCharacterWidget readOnly={readOnly} />;
  }
  if (tab === PersonTabType.RELATION) {
    return <AffiliatedCompanyPersonDetailModalRelationshipWidget readOnly={readOnly} />;
  }
  if (tab === PersonTabType.GIFT_HISTORY) {
    return <AffiliatedCompanyPersonDetailModalGiftHistoryWidget readOnly={readOnly} />;
  }
  if (tab === PersonTabType.MEETING_HISTORY) {
    return <AffiliatedCompanyPersonDetailModalMeetingHistoryWidget />;
  }
  if (tab === PersonTabType.SALES_ACTIVITY) {
    return <AffiliatedCompanySalesActivityFeature />;
  }
  return <></>;
};
