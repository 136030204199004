import { default as axios } from '@front/src/config/axios';
import { contractEarningsApiUrl } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/utils/constants';

const url = {
  getContractEarningsStatus: (id?: number) =>
    `${contractEarningsApiUrl}/${id}/contract-earnings/status`,
  getContractEarnings: (id: number) => `${contractEarningsApiUrl}/${id}/contract-earnings`,
};

export const projectSalesInfoContractEarningsApi = {
  getContractEarningsStatus: async (id?: number) => {
    const { data } = await axios.get(url.getContractEarningsStatus(id));
    return data;
  },

  getContractEarnings: async (id: number) => {
    const { data } = await axios.get(url.getContractEarnings(id));
    return data;
  },
};
