import { toFormData } from '@front/services/api';
import type { LoginVO } from 'login/domain';
import type {
  LoginChangeParameter,
  LoginParameter,
  PasswordChangeParameter,
  PasswordResetParameter,
} from 'login/parameter';
import axios from 'axios';

class LoginApi {
  async login(parameter: LoginParameter): Promise<void> {
    const { data } = await axios.post('/login', toFormData(parameter));
    return data;
  }

  async get(): Promise<LoginVO> {
    const { data } = await axios.get('/api/user/login');
    return data;
  }

  async change(params: LoginChangeParameter): Promise<void> {
    const { data } = await axios.put('/api/user/login', toFormData(params));
    return data;
  }

  async logout(): Promise<void> {
    const { data } = await axios.post('/api/logout', new FormData());
    return data;
  }

  async changePassword(params: PasswordChangeParameter): Promise<void> {
    const { data } = await axios.patch(`/api/admin/user/${params.id}/password`, params);
    return data;
  }

  async resetPassword(params: PasswordResetParameter): Promise<void> {
    const { data } = await axios.patch(`/api/admin/user/password`, params);
    return data;
  }
}

export const loginApi = new LoginApi();
