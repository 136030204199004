import React from 'react';
import type { AppRoute } from 'services/routes';
import AccessDenied from 'login/view/AccessDenied';

function Element() {
  return <AccessDenied />;
}

const passwordInvalidRoute: AppRoute = {
  path: '/user/password-reset/invalid',
  element: <Element />,
};

export default passwordInvalidRoute;
