import { useQuery } from 'react-query';
import type { ProjectEstimationComparedView } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/types/view';
import { projectSalesInfoEstimationComparedUpdateModalApi } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/features/update-modal/query/api';

export { query as projectSalesInfoEstimationComparedUpdateModalQuery };

const query = {
  useDetailGet: (id?: number) => {
    const { data, isLoading } = useQuery<ProjectEstimationComparedView>({
      queryFn: () => projectSalesInfoEstimationComparedUpdateModalApi.getDetail(id),
      queryKey: ['project', 'sales-info', 'estimation', 'estimation-compared', id],
      enabled: !!id,
      cacheTime: 0,
    });
    return {
      data,
      isLoading,
    };
  },
};
