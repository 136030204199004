import React from 'react';
import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import { ColorPalette } from '@front/assets/theme';

interface Props extends ButtonProps {
  shape?: 'primary' | 'primary2' | 'secondary' | 'secondary2' | 'Tertiary' | 'ghost';
  size?: 'medium' | 'small';
}

export default function ButtonBasicUI({
  children,
  size = 'medium',
  shape = 'primary',
  ...props
}: Props) {
  if (shape === 'primary' && size === 'medium')
    return <PrimaryMediumButtonBasic {...props}>{children}</PrimaryMediumButtonBasic>;
  if (shape === 'primary' && size === 'small')
    return <PrimarySmallButtonBasic {...props}>{children}</PrimarySmallButtonBasic>;
  if (shape === 'primary2' && size === 'medium')
    return <Primary2MediumButtonBasic {...props}>{children}</Primary2MediumButtonBasic>;
  if (shape === 'secondary' && size === 'medium')
    return <SecondaryMediumButtonBasic {...props}>{children}</SecondaryMediumButtonBasic>;
  if (shape === 'secondary' && size === 'small')
    return <SecondarySmallButtonBasic {...props}>{children}</SecondarySmallButtonBasic>;
  if (shape === 'secondary2' && size === 'medium')
    return <Secondary2MediumButtonBasic {...props}>{children}</Secondary2MediumButtonBasic>;
  if (shape === 'Tertiary' && size === 'medium')
    return <TertiaryMediumButtonBasic {...props}>{children}</TertiaryMediumButtonBasic>;
  if (shape === 'ghost' && size === 'medium')
    return <GhostMediumButtonBasic {...props}>{children}</GhostMediumButtonBasic>;
  if (shape === 'ghost' && size === 'small')
    return <GhostSmallButtonBasic {...props}>{children}</GhostSmallButtonBasic>;
  return <></>;
}

function PrimaryMediumButtonBasic({ children, sx = {}, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        minWidth: 'auto',
        minHeight: '32px',
        padding: '6px 12px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '6px',
        fontSize: '1.4rem',
        fontWeight: '600',
        lineHeight: 'normal',
        color: ColorPalette.greyscale.white,
        background: ColorPalette.main.main_primary,
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          background: ColorPalette.main.main_hover,
          boxShadow: 'none',
        },
        '&:active': {
          background: ColorPalette.main.main_hover,
          boxShadow: 'none',
        },
        '&:focus-visible': {
          background: ColorPalette.main.main_primary,
          boxShadow: 'none',
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
        },
        '&:disabled': {
          background: ColorPalette.greyscale.disabled,
          boxShadow: 'none',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

function PrimarySmallButtonBasic({ children, sx = {}, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        minWidth: 'auto',
        minHeight: '26px',
        padding: '4px 10px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4px',
        fontSize: '1.2rem',
        fontWeight: '600',
        lineHeight: 'normal',
        color: ColorPalette.greyscale.white,
        background: ColorPalette.main.main_primary,
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          background: ColorPalette.main.main_hover,
          boxShadow: 'none',
        },
        '&:active': {
          background: ColorPalette.main.main_hover,
          boxShadow: 'none',
        },
        '&:focus-visible': {
          background: ColorPalette.main.main_primary,
          boxShadow: 'none',
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
        },
        '&:disabled': {
          background: ColorPalette.greyscale.disabled,
          boxShadow: 'none',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

function Primary2MediumButtonBasic({ children, sx = {}, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        minWidth: 'auto',
        minHeight: '32px',
        padding: '6px 12px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '6px',
        fontSize: '1.4rem',
        fontWeight: '600',
        lineHeight: 'normal',
        color: ColorPalette.greyscale.white,
        background: ColorPalette.background.bg05,
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          background: ColorPalette.background.bg06,
          boxShadow: 'none',
        },
        '&:active': {
          background: ColorPalette.background.bg06,
          boxShadow: 'none',
        },
        '&:focus-visible': {
          background: ColorPalette.background.bg05,
          boxShadow: 'none',
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
        },
        '&:disabled': {
          background: ColorPalette.greyscale.disabled,
          boxShadow: 'none',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

function SecondaryMediumButtonBasic({ children, sx = {}, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        minWidth: 'auto',
        minHeight: '32px',
        padding: '6px 12px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '6px',
        fontSize: '1.4rem',
        fontWeight: '600',
        lineHeight: 'normal',
        color: ColorPalette.greyscale.white,
        background: ColorPalette.main.main_secondary,
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          background: ColorPalette.main.main_hover,
          boxShadow: 'none',
        },
        '&:active': {
          background: ColorPalette.main.main_hover,
          boxShadow: 'none',
        },
        '&:focus-visible': {
          background: ColorPalette.main.main_secondary,
          boxShadow: 'none',
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
        },
        '&:disabled': {
          background: ColorPalette.greyscale.disabled,
          boxShadow: 'none',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

function SecondarySmallButtonBasic({ children, sx = {}, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        minWidth: 'auto',
        minHeight: '26px',
        padding: '4px 10px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4px',
        fontSize: '1.2rem',
        fontWeight: '600',
        lineHeight: 'normal',
        color: ColorPalette.greyscale.white,
        background: ColorPalette.main.main_secondary,
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          background: ColorPalette.main.main_hover,
          boxShadow: 'none',
        },
        '&:active': {
          background: ColorPalette.main.main_hover,
          boxShadow: 'none',
        },
        '&:focus-visible': {
          background: ColorPalette.main.main_secondary,
          boxShadow: 'none',
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
        },
        '&:disabled': {
          background: ColorPalette.greyscale.disabled,
          boxShadow: 'none',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

function Secondary2MediumButtonBasic({ children, sx = {}, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        minWidth: 'auto',
        minHeight: '32px',
        padding: '6px 12px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '6px',
        fontSize: '1.4rem',
        fontWeight: '600',
        lineHeight: 'normal',
        color: ColorPalette.greyscale.white,
        background: ColorPalette.sub2.sub2_primary,
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          background: ColorPalette.sub2.sub2_secondary,
          boxShadow: 'none',
        },
        '&:active': {
          background: ColorPalette.sub2.sub2_secondary,
          boxShadow: 'none',
        },
        '&:focus-visible': {
          background: ColorPalette.sub2.sub2_primary,
          boxShadow: 'none',
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
        },
        '&:disabled': {
          background: ColorPalette.greyscale.disabled,
          boxShadow: 'none',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

function TertiaryMediumButtonBasic({ children, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        minWidth: 'auto',
        minHeight: '32px',
        padding: '6px 12px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '6px',
        fontSize: '1.4rem',
        fontWeight: '600',
        lineHeight: 'normal',
        color: ColorPalette.greyscale.white,
        background: ColorPalette.background.bg07,
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          background: ColorPalette.background.bg06,
          boxShadow: 'none',
        },
        '&:active': {
          background: ColorPalette.background.bg06,
          boxShadow: 'none',
        },
        '&:focus-visible': {
          background: ColorPalette.background.bg07,
          boxShadow: 'none',
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
        },
        '&:disabled': {
          background: ColorPalette.greyscale.disabled,
          boxShadow: 'none',
        },
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

function GhostMediumButtonBasic({ children, sx = {}, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        minWidth: 'auto',
        minHeight: '32px',
        padding: '6px 12px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '6px',
        fontSize: '1.4rem',
        fontWeight: '600',
        lineHeight: 'normal',
        color: ColorPalette.main.main_primary,
        background: 'none',
        border: `1px solid ${ColorPalette.main.main_primary}`,
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          background: 'none',
          border: `1px solid ${ColorPalette.main.main_hover}`,
          boxShadow: 'none',
        },
        '&:active': {
          background: 'none',
          border: `1px solid ${ColorPalette.main.main_hover}`,
          boxShadow: 'none',
        },
        '&:focus-visible': {
          background: 'none',
          border: `1px solid ${ColorPalette.main.main_primary}`,
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
          boxShadow: 'none',
        },
        '&:disabled': {
          color: ColorPalette.greyscale.disabled,
          background: 'none',
          border: `1px solid ${ColorPalette.greyscale.disabled}`,
          boxShadow: 'none',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

function GhostSmallButtonBasic({ children, sx = {}, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        minWidth: 'auto',
        minHeight: '26px',
        padding: '4px 6px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4px',
        fontSize: '1.2rem',
        fontWeight: '600',
        lineHeight: 'normal',
        color: ColorPalette.main.main_primary,
        background: 'none',
        border: `1px solid ${ColorPalette.main.main_primary}`,
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          background: 'none',
          border: `1px solid ${ColorPalette.main.main_hover}`,
          boxShadow: 'none',
        },
        '&:active': {
          background: 'none',
          border: `1px solid ${ColorPalette.main.main_hover}`,
          boxShadow: 'none',
        },
        '&:focus-visible': {
          background: 'none',
          border: `1px solid ${ColorPalette.main.main_primary}`,
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
          boxShadow: 'none',
        },
        '&:disabled': {
          color: ColorPalette.greyscale.disabled,
          background: 'none',
          border: `1px solid ${ColorPalette.greyscale.disabled}`,
          boxShadow: 'none',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}
