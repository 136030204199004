import React, { useContext } from 'react';
import { ProjectSalesInfoEstimationConfirmedContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation-confirmed/widgets/context';
import { OldTd } from '@front/layouts/Table';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { FormProvider, useForm } from 'react-hook-form';
import {
  convertAmountToStringForForm,
  convertEmptyToNullForAmountParameter,
  convertEmptyToNullForParameter,
  convertNullToEmptyForForm,
  getLocaleStringIfExist,
  getValueIfExist,
} from '@front/src/utils';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { shallow } from 'zustand/shallow';
import { getOptionListFromVariableList } from '@front/src/features/manageable-variable/utils';
import { HookFormText } from '@front/src/components/text-with-hook-form/text-with-hook-form';
import { convertEstimationStatusToEmpty } from '@front/src/features/project-sales-info/features/estimation/features/estimation-confirmed/utils/constant';
import { useProjectSalesInfoEstimationConfirmedState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-confirmed/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { EstimationConfirmedTableRow as ProjectSalesInfoEstimationConfirmedTableRow };

const getValues = (detail) => ({
  status: convertEstimationStatusToEmpty(detail?.status),
  winningPossibility: convertNullToEmptyForForm(detail?.winningPossibility),
  unitPrice: convertAmountToStringForForm(detail?.unitPrice),
  schedule: convertNullToEmptyForForm(detail?.schedule),
});

const EstimationConfirmedTableRow = () => {
  const {
    d: { detail, statusVariableList, winningPossibilityVariableList },
    h: { onUpdate },
  } = useContext(ProjectSalesInfoEstimationConfirmedContext);
  const { id } = useProjectSalesInfoDetailState((state) => ({ id: state.id }), shallow);
  const methods = useForm({
    values: getValues(detail),
  });
  const onSubmit = methods.handleSubmit((data) => {
    onUpdate({
      id,
      status: convertEmptyToNullForParameter(data.status),
      winningPossibility: convertEmptyToNullForParameter(data.winningPossibility),
      unitPrice: convertEmptyToNullForAmountParameter(data.unitPrice),
      schedule: convertEmptyToNullForParameter(data.schedule),
    });
  });
  const { readOnly } = useProjectSalesInfoEstimationConfirmedState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <OldTd>
        <HookFormSelect
          defaultLabel="선택"
          isDefaultPossible
          height="52px"
          name="status"
          optionList={getOptionListFromVariableList(statusVariableList)}
          onSubmit={onSubmit}
          disabled={readOnly}
        />
      </OldTd>
      <OldTd>
        <HookFormSelect
          defaultLabel="선택"
          isDefaultPossible
          name="winningPossibility"
          optionList={getOptionListFromVariableList(winningPossibilityVariableList)}
          onSubmit={onSubmit}
          disabled={readOnly}
        />
      </OldTd>
      <OldTd>{getValueIfExist(detail?.experimentInformation)}</OldTd>
      <OldTd>{getLocaleStringIfExist(detail?.experimentAmount)}</OldTd>
      <OldTd>{getLocaleStringIfExist(detail?.reviewAmount)}</OldTd>
      {detail?.isCm && <OldTd>{getLocaleStringIfExist(detail?.CMAmount)}</OldTd>}
      {detail?.isCm && <OldTd>{getLocaleStringIfExist(detail?.convertedCMAmount)}</OldTd>}
      <OldTd>{getLocaleStringIfExist(detail?.totalAmount)}</OldTd>
      <OldTd>
        <HookFormText name="unitPrice" />
      </OldTd>
      <OldTd>
        <HookFormInput
          name="schedule"
          onSubmit={onSubmit}
          disabled={readOnly}
        />
      </OldTd>
    </FormProvider>
  );
};
