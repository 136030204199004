export const onOffOptionList = [
  {
    label: 'ON',
    value: 'ON',
  },
  {
    label: 'OFF',
    value: 'OFF',
  },
];

export const accessAuthSettingsUrl = '/api/admin/authorization';
export const accessAuthSettingsDepartmentUrl = '/api/admin/authorization/department';
export const accessAuthSettingsUserUrl = '/api/admin/authorization/user';
