import type { CSSProperties, ReactNode } from 'react';
import React from 'react';
import { TableCell } from '@mui/material';
import classes from '@front/src/components/layout/table/th.module.scss';
import type { TableCellBaseProps } from '@mui/material/TableCell/TableCell';

export { Th };

interface Props {
  children?: ReactNode;
  className?: string;
  center?: boolean;
  width?: string;
  colSpan?: number;
  rowSpan?: number;
  component?: React.ElementType<TableCellBaseProps>;
  padding?: string;
  sx?: CSSProperties;
  noWrap?: boolean;
  thref?: React.Ref<HTMLTableCellElement>;
}

const Th = ({
  children,
  className,
  width,
  noWrap,
  thref,
  padding = '16px',
  center = true,
  ...rest
}: Props) => (
  <TableCell
    className={`${classes.th} ${center && classes.center} ${className}`}
    style={{ width, minWidth: width, padding }}
    variant="head"
    ref={thref}
    {...rest}
  >
    <div
      className={`${classes.children} ${center && classes.center}`}
      style={{
        whiteSpace: noWrap ? 'nowrap' : 'normal',
      }}
    >
      {children}
    </div>
  </TableCell>
);
