import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workWorkerMutation from '@front/src/features/work/features/work/features/worker/query/mutation';

const useWorkWorkerCreate = (menuId) => {
  const callback = getNoOpCallback();
  const { mutate, isLoading } = workWorkerMutation.useCreate(menuId);
  return {
    run: (workId: number) => mutate(workId, callback),
    setCallback: getSetCallbackFunc(callback),
    isLoading,
  };
};

export default useWorkWorkerCreate;
