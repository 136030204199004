import React, { useEffect, useRef } from 'react';
import Input from '@front/layouts/Input';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import type { SnackbarSeverityType } from '@front/components/Snackbar/action';
import type { TitleUpdateParameter } from '@front/ost_proposal/parameter';
import { ProposalStatus } from '@front/ost_proposal/domain';

interface Props {
  openSnackbar: (message: string, severity?: SnackbarSeverityType) => void;
  updateTitle: (params: TitleUpdateParameter) => void;
  isContributor: boolean;
}

const ProposalTitleInput = ({ openSnackbar, updateTitle, isContributor }: Props) => {
  const title = useSelector((root: RootState) => root.proposal.title, shallowEqual);
  const status = useSelector((state: RootState) => state.proposal.detail!.status, shallowEqual);
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current && title) {
      inputRef.current.value = title;
    }
  }, [title]);
  return (
    <Input
      inputRef={inputRef}
      readOnly={status !== ProposalStatus.WRITING || !isContributor}
      key={title}
      variant="outlined"
      placeholder="제안 주제를 간략히 작성 하세요."
      defaultValue={title}
      onBlur={(e) => {
        const value = e.target.value;
        if (value === '') {
          openSnackbar('제안 주제를 입력 하세요.');
          if (inputRef.current && title) {
            inputRef.current.value = title;
          }
          return;
        }

        if (value != title) {
          updateTitle({
            title: value,
          });
        }
      }}
    />
  );
};

export default ProposalTitleInput;
