export const convertContributionForParameter = (value) => value || null;

export const convertAffiliatedCompanyPersonIsKeyPersonToForm = (value: boolean | null) => {
  if (typeof value !== 'boolean') return '';
  return value ? 'O' : 'X';
};

export const convertAffiliatedCompanyPersonIsKeyPersonToParameter = (value: string) => {
  if (value === '') return null;
  return value === 'O';
};
