import React from 'react';
import { AffiliatedPersonMemoCreateButton } from '@front/src/features/affiliated-person/features/memo/widgets/create/components/create-button';
import { AffiliatedPersonMemoCreateProvider } from '@front/src/features/affiliated-person/features/memo/widgets/create/provider/provider';

export { Widget as AffiliatedPersonMemoCreateWidget };

const Widget = () => (
  <AffiliatedPersonMemoCreateProvider>
    <AffiliatedPersonMemoCreateButton />
  </AffiliatedPersonMemoCreateProvider>
);
