import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { ProjectSalesInfoActivityAffiliatesItemContext } from '@front/src/features/project-sales-info/features/activity/widgets/activity-affiliates-item/provider/context';
import { useProjectSalesInfoActivityAffiliatesItemLogic } from '@front/src/features/project-sales-info/features/activity/widgets/activity-affiliates-item/provider/useLogic';

export { Provider as ProjectSalesInfoActivityAffiliatesItemProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { onUpdate, onDelete } = useProjectSalesInfoActivityAffiliatesItemLogic();
  const value = useMemo(() => ({ onUpdate, onDelete }), [onUpdate, onDelete]);
  return (
    <ProjectSalesInfoActivityAffiliatesItemContext.Provider value={value}>
      {children}
    </ProjectSalesInfoActivityAffiliatesItemContext.Provider>
  );
};
