import { ProjectSalesInfoAbstractEstimationQuery } from '@front/src/features/project-sales-info/features/abstract/features/estimation/query/query';

export { repository as ProjectSalesInfoAbstractEstimationRepository };

const repository = {
  useDetailGet: (id?) => {
    const { data } = ProjectSalesInfoAbstractEstimationQuery.useDetailGet(id);
    return {
      data,
    };
  },
};
