import React, { useCallback, useContext } from 'react';
import Checkbox from '@front/layouts/Checkbox';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useProjectSalesInfoProjectDivisionModalState } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/widgets/useState';
import { ProjectSalesInfoProjectDivisionModalContext } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/widgets/context';
import type {
  PersonModalShortView,
  ProjectStepInformationView,
} from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/type/view';
import {
  changeModalViewToParams,
  spliceAndPushIfExist,
} from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/utils/constants';
import UserSelector from '@front/components/UserSelector';
import type { UserId } from '@front/user/domain';
import classes from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/components/modal-table.module.scss';
import type { UserRecordView } from '@front/src/features/project-sales-info/features/project-division/types/view';
import { convertToOptionList, getValueIfEmpty } from '@front/src/utils';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { Center } from '@front/src/components/layout/table/center-wrapper';
import { ProjectSalesInfoProjectDivisionSelectorWrapper } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/components/selector-wrapper';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoProjectDivisionState } from '@front/src/features/project-sales-info/features/project-division/widgets/useState';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";

export { ModalTableRow as ProjectSalesInfoProjectDivisionModalTableRow };

interface Props {
  item: ProjectStepInformationView;
  index: number;
}

const ModalTableRow = ({ item, index }: Props) => {
  const { ItemTableRow, ItemTableCell, Text } = AddDeleteTable;
  const { idList, setIdList, id, setId } = useProjectSalesInfoProjectDivisionModalState(
    useShallow((state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
      id: state.id,
      setId: state.setId,
    }))
  );
  const {
    h: { onUpdate },
    d: { businessProgressStepOptionList },
  } = useContext(ProjectSalesInfoProjectDivisionModalContext);
  const isSelected = item.id === id;
  const methods = useForm({
    values: {
      id: item.id,
      date: item.date ?? '',
      salesManager: (item.salesManager as UserRecordView)?.id ?? '',
      affiliatedCompanyManager: (item.affiliatedCompanyManager as PersonModalShortView)?.id ?? '',
      businessStatusStep: item.businessStatusStep ?? '',
      expectedWorkStartDate: item.expectedWorkStartDate ?? '',
      note: item.note ?? '',
    },
  });
  const { setValue, control, handleSubmit, getValues } = methods;
  const handleCheckboxChange = useCallback(
    (id) => () => setIdList(spliceAndPushIfExist(idList, id)),
    [setIdList, idList]
  );
  const { readOnly } = useProjectSalesInfoProjectDivisionState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const handleRowClick = useCallback(() => {
    if (readOnly) return;
    setId(item.id);
  }, [setId, item, readOnly]);
  const onSubmit = handleSubmit((data) => onUpdate(changeModalViewToParams(data)));
  const renderIfSelected = useCallback(
    (name) => {
      if (isSelected) {
        return (
          <HookFormInput
            name={name}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        );
      }
      return <Text>{getValueIfEmpty(getValues(name))}</Text>;
    },
    [getValues, isSelected, onSubmit, Text, readOnly]
  );
  const salesManager = useWatch({ control, name: 'salesManager' });
  return (
    <FormProvider {...methods}>
      <ItemTableRow
        onClick={handleRowClick}
        sx={{ height: '54px' }}
      >
        <ItemTableCell
          sx={{
            padding: '12px',
          }}
        >
          <div className={classes.th}>
            <Checkbox
              checked={idList.includes(item.id)}
              onChange={handleCheckboxChange(item.id)}
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
              disabled={readOnly}
            />
          </div>
        </ItemTableCell>
        <ItemTableCell center={true}>{index + 1}</ItemTableCell>
        <ItemTableCell center={true}>
          {isSelected ? (
            <Center>
              <DatePickerWithHookForm
                name="date"
                onSubmit={onSubmit}
                disabled={readOnly}
              />
            </Center>
          ) : (
            <Text>{getValueIfEmpty(getValues('date'))}</Text>
          )}
        </ItemTableCell>
        <ItemTableCell center={true}>
          {isSelected ? (
            <UserSelector
              position="top"
              name="salesManager"
              onChange={(value) => {
                setValue('salesManager', value as number);
                onSubmit();
              }}
              value={salesManager as UserId}
              style={{ width: '100%' }}
              readOnly={readOnly}
            />
          ) : (
            <Text>{getValueIfEmpty((item.salesManager as UserRecordView)?.name)}</Text>
          )}
        </ItemTableCell>
        <ItemTableCell center={true}>
          {getValueIfEmpty(
            (item.affiliatedCompanyManager as PersonModalShortView)?.affiliatedCompanyName
          )}
        </ItemTableCell>
        <ItemTableCell center={true}>
          {isSelected ? (
            <ProjectSalesInfoProjectDivisionSelectorWrapper
              item={item}
              onSubmit={onSubmit}
            />
          ) : (
            <Text>
              {getValueIfEmpty((item.affiliatedCompanyManager as PersonModalShortView)?.name)}
            </Text>
          )}
        </ItemTableCell>
        <ItemTableCell center={true}>
          {isSelected ? (
            <Center>
              <HookFormSelect
                isDefaultPossible
                name="businessStatusStep"
                defaultLabel="선택"
                optionList={convertToOptionList(businessProgressStepOptionList)}
                onSubmit={onSubmit}
                width="80%"
                disabled={readOnly}
              />
            </Center>
          ) : (
            <Text>{getValueIfEmpty(getValues('businessStatusStep'))}</Text>
          )}
        </ItemTableCell>
        <ItemTableCell center={true}>
          {isSelected ? (
            <Center>
              <DatePickerWithHookForm
                name="expectedWorkStartDate"
                onSubmit={onSubmit}
                disabled={readOnly}
              />
            </Center>
          ) : (
            <Text>{getValueIfEmpty(getValues('expectedWorkStartDate'))}</Text>
          )}
        </ItemTableCell>
        <ItemTableCell center={true}>{renderIfSelected('note')}</ItemTableCell>
      </ItemTableRow>
    </FormProvider>
  );
};
