import LogoutButton from '@front/src/app/view/LogoutButton';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction } from '@front/login/action';
import type { RootState } from '@front/services/reducer';
import { closeStatus } from '@front/components/DataFieldProps';
import { useNavigate } from 'react-router-dom';
import { menuAction } from '@front/menu/action';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

export default function LogoutButtonRoute() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { requestLogout } = useSelector((root: RootState) => root.login);
  const open = useSelector((root: RootState) => root.menu.open);
  const toggleMenu = useCallback(() => dispatch(menuAction.toggleMenu()), [dispatch]);
  const setIsRightDrawerOpen = useGlobalNavBarState((state) => state.setIsRightDrawerOpen);
  const onLogout = useCallback(() => {
    dispatch(loginAction.logout());
    open && toggleMenu();
    sessionStorage.removeItem('menuId');
    sessionStorage.removeItem('tabList');
    setIsRightDrawerOpen(false);
  }, [dispatch, open, toggleMenu]);

  useEffect(() => {
    closeStatus(
      requestLogout,
      () => {
        dispatch(loginAction.setDetail(undefined));
        navigate('/login');
      },
      () => {
        dispatch(loginAction.requestLogout('idle'));
      }
    );
  }, [requestLogout]);
  return <LogoutButton onLogout={onLogout} />;
}
