import React, { useCallback, useContext } from 'react';
import Checkbox from '@front/layouts/Checkbox';
import { useProjectSalesInfoActivityState } from '@front/src/features/project-sales-info/features/activity/useState';
import { spliceAndPushIfExist } from '@front/src/utils';
import { useShallow } from 'zustand/react/shallow';
import { SalesInfoActivityRowContext } from '@front/src/features/project-sales-info/features/activity/widgets/row/provider/context';
import { Td } from '@front/src/components/layout/table/td';

export { CellCheckbox as SalesInfoActivityTableCellCheckbox };

const CellCheckbox = () => {
  const { item } = useContext(SalesInfoActivityRowContext);
  const activityId = item.activity.id;
  const { idList, setIdList } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      id: state.id,
      setId: state.setId,
      idList: state.idList,
      setIdList: state.setIdList,
    }))
  );
  const handleCheckboxChange = useCallback(
    (id) => () => setIdList(spliceAndPushIfExist(idList, id)),
    [idList, setIdList]
  );
  const { readOnly } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Td rowSpan={item.sameActivitySize + 1}>
      <Checkbox
        checked={idList.includes(activityId)}
        onChange={handleCheckboxChange(activityId)}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
        disabled={readOnly}
      />
    </Td>
  );
};
