import { paymentHistoryApiUrl } from '@front/src/features/project-sales-info/features/collection/features/payment-history/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { IdDeleteCollectionParams } from '@front/src/features/project-sales-info/features/collection/features/payment-history/types/parameter';

const url = {
  getPaymentHistoryList: (id: number) =>
    `${paymentHistoryApiUrl}/${id}/review-amount-payment-history`,
  deletePaymentHistory: () => `${paymentHistoryApiUrl}/review-amount-payment-history`,
};

export const projectSalesInfoCollectionPaymentHistoryApi = {
  getPaymentHistoryList: async (id: number) => {
    const { data } = await axios.get(url.getPaymentHistoryList(id));
    return data;
  },
  deletePaymentHistory: async (params: IdDeleteCollectionParams) =>
    await axios.delete(url.deletePaymentHistory(), { data: params }),
};
