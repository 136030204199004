import React from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import WorkOutputFeature from '@front/src/features/work/features/work/features/output';
import useWorkOutputBundleQuery from '@front/src/features/work/features/work/features/output-bundle/query/query';

export default function WorkOutputBundleList({
  dataId,
  menuId,
  ...rest
}: Readonly<SectionComponentProps>) {
  const { data: bundleList } = useWorkOutputBundleQuery.useListGet(dataId, menuId);
  return (
    <>
      {bundleList?.map((item, index) => (
        <WorkOutputFeature
          key={item.id}
          item={item}
          index={index}
          dataId={dataId}
          menuId={menuId}
          {...rest}
        />
      ))}
    </>
  );
}
