import React, { useCallback } from 'react';
import type { CurrentPage } from '@front/navigate/domain';
import { TargetPage } from '@front/navigate/domain';
import { OstVoteController } from '@front/ost_proposal/view/detail/VoteController';
import type { ProposalStatusUpdateParameter } from '@front/ost_proposal/parameter';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { OstProposalController } from '@front/ost_proposal/view/detail/ProposalController';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import ProposalAdvisorModalService from '@front/ost_proposal/service/modal/advisorModalService';
import { OstEvaluationController } from '@front/ost_proposal/view/detail/EvaluationController';
import { proposalAction } from '@front/ost_proposal/action';
import ApprovalDocumentModalService from '@front/ost_proposal/service/modal/approvalDocumentModal';
import { ProposalStatus } from '@front/ost_proposal/domain';

interface Props {
  updateStatus: (status: ProposalStatusUpdateParameter) => void;
  currentPage: CurrentPage;
}

const CampaignMangerButtonController = ({ currentPage, updateStatus }: Props) => {
  const dispatch = useDispatch();
  const status = useSelector((root: RootState) => root.proposal.detail!.status, shallowEqual);

  const isAdvisorModalOpen = useSelector(
    (root: RootState) => root.proposal.setAdvisorModalOpen,
    shallowEqual
  );

  const approvalDocumentModalOpen = useSelector(
    (root: RootState) => root.proposal.approvalDocumentModalOpen,
    shallowEqual
  );
  const openApprovalDocumentModal = useCallback(
    () => dispatch(proposalAction.setApprovalDocumentModal(true)),
    [dispatch]
  );

  return (
    <Layout>
      {currentPage.targetPage === TargetPage.VOTE &&
        (status === ProposalStatus.VOTING || status === ProposalStatus.STOP_VOTING) && (
          <OstVoteController />
        )}
      {currentPage.targetPage === TargetPage.PROPOSAL &&
        (status === ProposalStatus.WRITING || status === ProposalStatus.SUBMISSION) && (
          <>
            <OstProposalController updateStatus={updateStatus} />
            {isAdvisorModalOpen && <ProposalAdvisorModalService />}
          </>
        )}
      {currentPage.targetPage === TargetPage.EVALUATION && status === ProposalStatus.ESTIMATING && (
        <>
          <OstEvaluationController onOpen={openApprovalDocumentModal} />
          {approvalDocumentModalOpen && <ApprovalDocumentModalService />}
        </>
      )}
    </Layout>
  );
};

const Layout = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  width: '100%',
  padding: '10px',
});
export default CampaignMangerButtonController;
