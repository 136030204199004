import { affiliatedCompanyOutlineShortApi } from '@front/src/features/affiliated-company/features/outline/features/short/query/api';
import { useQuery } from 'react-query';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';

export const affiliatedCompanyOutlineShortQuery = {
  useCategoryOptionListGet: (menuId) => {
    const { data } = useQuery<VariableInformationView[]>({
      queryKey: ['affiliated-company', 'outline', 'short', 'option-list'],
      queryFn: () => affiliatedCompanyOutlineShortApi.getCategoryOptionList(menuId),
    });
    return { data };
  },
};
