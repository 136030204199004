import React, { useCallback } from 'react';
import { AffiliatedCompanySelectorWidget } from '@front/src/features/affiliated-company-selector/widgets/widget';
import { useFormContext, useWatch } from 'react-hook-form';
import { useAffiliatedCompanyMeetingHistoryState } from '@front/src/features/affiliated-company/features/meeting-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { FormInfoLocationSelector as AffiliatedCompanyMeetingHistoryModalFormInfoLocationSelector };

const FormInfoLocationSelector = () => {
  const { setValue, control } = useFormContext();
  const handleSelector = useCallback(
    async (company) => {
      setValue('businessMeetingAffiliatedCompany', company);
      setValue('location', company?.address ?? '');
    },
    [setValue]
  );
  const businessMeetingAffiliatedCompany = useWatch({
    name: 'businessMeetingAffiliatedCompany',
    control,
  });
  const { readOnly } = useAffiliatedCompanyMeetingHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <AffiliatedCompanySelectorWidget
      value={{
        companyId: businessMeetingAffiliatedCompany?.id,
        textValue: businessMeetingAffiliatedCompany?.name ?? '',
      }}
      onCompanySelect={handleSelector}
      onPersonSelect={() => {}}
      usePersonSelector={false}
      disabled={readOnly}
    />
  );
};
