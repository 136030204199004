import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetCellLogic } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/hooks/useGetCellLogic';
import {
  TableRowBox,
  TbcBox,
} from '@front/src/features/personal-settings-modal/features/ui-settings/components';
import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { usePersonalSettingsModalUISettingsHeaderSettingsPositionState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/useState';
import { useShallow } from 'zustand/react/shallow';

export { Depth1 as PersonalSettingsModalUISettingsHeaderSettingsTableContentDepth1 };

const Depth1 = () => {
  const { watch, setValue } = useFormContext();
  const { setMenuId } = usePersonalSettingsModalUISettingsHeaderSettingsPositionState(
    useShallow((state) => ({
      setMenuId: state.setMenuId,
    }))
  );
  const depth1 = watch('depth1');
  const { getSx, emptyRows, iconSx } = useGetCellLogic({
    key: 'depth1',
    length: depth1.length,
    selected: watch('depth1Id'),
  });
  const handleClick = useCallback(
    (id, children) => {
      setMenuId(undefined);
      setValue('depth1Id', id);
      setValue('depth2Id', undefined);
      setValue('depth3Id', undefined);
      setValue('tabId', undefined);
      const mapped = children.map((c) => ({
        ...c,
        children: c.children,
      }));
      if (children.length === 0) {
        setValue('depth2', []);
        setValue('depth3', []);
        setValue('tab', []);
        setValue('section', []);
        return;
      }
      if (children.some((c) => c.depth === 2)) {
        setValue('depth2', mapped);
        setValue('depth3', []);
        setValue('tab', []);
        setValue('section', []);
        return;
      }
      if (children.some((c) => c.depth === 3)) {
        setValue('depth3', mapped);
        setValue('tab', []);
        setValue('section', []);
        return;
      }
      if (children.some((c) => c.isTab)) {
        setValue('tab', mapped);
        setValue('section', []);
        return;
      }
      if (children.some((c) => c.isSection)) {
        setValue('section', mapped);
      }
    },
    [setMenuId, setValue]
  );
  return (
    <>
      {depth1.map((item) => {
        const { children, title, belongsToHeader, id } = item;
        return (
          <TableRowBox key={id}>
            <TbcBox
              sx={getSx(id)}
              onClick={() => handleClick(id, children)}
            >
              <Box>{title}</Box>
              {belongsToHeader && <CheckIcon sx={iconSx} />}
            </TbcBox>
          </TableRowBox>
        );
      })}
      {emptyRows}
    </>
  );
};
