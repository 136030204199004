import React from 'react';
import { Box } from '@mui/material';
import TextBox from '@front/layouts/Text';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import type { ArrangementCommentParameter } from '@front/ost_proposal/parameter';
import AddArrangementCommentRow from '@front/ost_proposal/view/detail/advisorForm/arrangement_comment/add_arrangement_comment_row/AddArrangementCommentRow';

interface Props {
  inputRef: React.RefObject<HTMLInputElement>;
  addArrangementComment: (comment: ArrangementCommentParameter) => void;
}

export { Index as AddArrangementCommentLayout };
const Index = ({ inputRef, addArrangementComment }: Props) => {
  const loginUserName = useSelector((state: RootState) => state.login.detail?.name, shallowEqual);

  return (
    <Box
      sx={{
        display: 'grid',
        width: '100%',
        gridTemplateRows: '20px auto',
        gridTemplateAreas: `"name"
       "contents"`,
        marginBottom: '10px',
      }}
    >
      <Box
        sx={{
          display: 'inherit',
          width: '100%',
          gridArea: 'name',
        }}
      >
        <TextBox variant="body7">{loginUserName}</TextBox>
      </Box>
      <AddArrangementCommentRow
        addArrangementComment={addArrangementComment}
        inputRef={inputRef}
      />
    </Box>
  );
};
