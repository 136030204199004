import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';

import { ColorPalette } from '@front/assets/theme';
import type { CampaignMemoVO } from '@front/ost_campaign/domain';
import TextBox from '@front/layouts/Text';
import UserIcon from '@front/layouts/UserIcon';

interface Props {
  memo: CampaignMemoVO;
}

const MemoItemContent = ({ memo }: Props) => (
  <>
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        padding: '12px 0',
      }}
    >
      <Typography
        component="span"
        sx={{
          fontSize: '13px',
          color: ColorPalette._252627,
          wordBreak: 'break-all',
          whiteSpace: 'break-spaces',
        }}
      >
        <TextBox variant="body9">{memo.description}</TextBox>
      </Typography>
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'flex-start',
      }}
    >
      {memo.attendanceList?.length !== 0 && (
        <>
          {memo.attendanceList?.map((item) => (
            <UserIcon
              key={item}
              user={item}
              sx={{
                marginRight: '4px',
              }}
            />
          ))}
        </>
      )}
    </Box>
  </>
);

const ContentItemMemo = memo(MemoItemContent);

export default ContentItemMemo;
