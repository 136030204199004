import React, { memo, useContext } from 'react';
import { TableRow } from '@mui/material';
import CircularProgress from '@front/components/CircularProgress';
import { OldTd } from '@front/layouts/Table';

import { AffiliatedCompanyMeetingHistoryTableRow } from '@front/src/features/affiliated-company/features/meeting-history/components/meeting-history-table-row';
import { AffiliatedCompanyMeetingHistoryContext } from '@front/src/features/affiliated-company/features/meeting-history/widgets/context';

export { MeetingHistoryTableBody as AffiliatedCompanyMeetingHistoryTableBody };

const MeetingHistoryTableBody = () => {
  const {
    d: { meetingList },
    isLoading,
  } = useContext(AffiliatedCompanyMeetingHistoryContext);

  if (typeof meetingList === 'undefined' || isLoading) {
    return <Spinner />;
  }
  if (meetingList.length === 0) {
    return <NoResult />;
  }
  return (
    <>
      {meetingList.map((meeting, index) => (
        <AffiliatedCompanyMeetingHistoryTableRow
          key={meeting.id}
          meeting={meeting}
          index={index}
        />
      ))}
    </>
  );
};

const Spinner = memo(() => (
  <TableRow
    sx={{
      height: '54px',
    }}
  >
    <OldTd colSpan={8}>
      <CircularProgress size={30} />
    </OldTd>
  </TableRow>
));

Spinner.displayName = 'AffiliatedCompanyMeetingHistoryTableBodySpinner';

const NoResult = memo(() => (
  <TableRow>
    <OldTd colSpan={8}>저장된 미팅이 없습니다.</OldTd>
  </TableRow>
));

NoResult.displayName = 'AffiliatedCompanyMeetingHistoryTableBodyNoResult';
