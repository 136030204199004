import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import reducer from 'services/reducer';
import departmentSaga from 'department/saga';
import loginSaga from 'login/saga';

import personnelSaga from 'personnel/saga';
import userNotificationSaga from 'user_notification/saga';
import userSaga from 'user/saga';
import addressModalSaga from 'components/AddressModal/saga';
import snackbarSaga from 'components/Snackbar/saga';
import departmentTreeSaga from 'department_tree/saga';
import proposalSaga from '@front/ost_proposal/saga';
import proceedingsSaga from 'proceedings/saga';
import { composeWithDevTools } from '@redux-devtools/extension';
import campaignSaga from '@front/ost_campaign/saga';
import evaluationSaga from '@front/ost_evaluation/saga';
import voteSaga from '@front/ost_vote/saga';
import ostSaga from '@front/ost/saga';
import arrangementSaga from '@front/ost_arrangement/saga';
import { ostRewardManagementSaga } from '@front/ost_reward/saga';

const middleware = createSagaMiddleware();

function* saga() {
  yield all([
    ostSaga(),
    departmentSaga(),
    departmentTreeSaga(),
    loginSaga(),
    personnelSaga(),
    userNotificationSaga(),
    userSaga(),
    addressModalSaga(),
    snackbarSaga(),
    proposalSaga(),
    proceedingsSaga(),
    campaignSaga(),
    evaluationSaga(),
    voteSaga(),
    arrangementSaga(),
    ostRewardManagementSaga(),
  ]);
}

const store = createStore(reducer, composeWithDevTools(applyMiddleware(middleware)));

middleware.run(saga);

export default store;
