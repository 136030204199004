import React, { useContext } from 'react';
import Button from '@front/layouts/Button';
import { useFormContext } from 'react-hook-form';
import classes from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/create-modal/components/modal-table.module.scss';
import { useProjectSalesInfoBidResultState } from '@front/src/features/project-sales-info/features/bid/features/bid-result/widgets/useState';
import { ProjectSalesInfoBidResultCreateModalContext } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/create-modal/widgets/context';
import { convertBidResultForParameter } from '@front/src/features/project-sales-info/features/bid/features/bid-information/utils/constants';

export { ModalBtnGroup as ProjectSalesInfoBidResultCreateModalBtnGroup };

const ModalBtnGroup = () => {
  const setIsCreateModalOpen = useProjectSalesInfoBidResultState(
    (state) => state.setIsCreateModalOpen
  );
  const {
    h: { onCreate },
  } = useContext(ProjectSalesInfoBidResultCreateModalContext);
  const { handleSubmit } = useFormContext();
  const onSubmit = handleSubmit((data) => {
    onCreate(convertBidResultForParameter(data));
    setIsCreateModalOpen();
  });
  return (
    <div className={classes.btn__container}>
      <Button
        onClick={() => setIsCreateModalOpen()}
        fullWidth={true}
        shape="basic2"
      >
        취소
      </Button>
      <Button
        fullWidth={true}
        onClick={onSubmit}
      >
        등록
      </Button>
    </div>
  );
};
