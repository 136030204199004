import React, { useEffect, useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useProjectSalesInfoContractReviewHistoryLogic } from '@front/src/features/project-sales-info/features/contract/features/review-history/widgets/useLogic';
import { ProjectSalesInfoContractReviewHistoryModal } from '@front/src/features/project-sales-info/features/contract/features/review-history/components/contract-review-history-modal';
import { ProjectSalesInfoContractReviewHistoryContext } from '@front/src/features/project-sales-info/features/contract/features/review-history/widgets/context';
import { ProjectSalesInfoContractReviewHistoryButtonGroup } from '@front/src/features/project-sales-info/features/contract/features/review-history/components/contract-review-history-button-group';
import { ProjectSalesInfoContractReviewHistoryTable } from '@front/src/features/project-sales-info/features/contract/features/review-history/components/contract-review-history-table';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoContractReviewHistoryState } from '@front/src/features/project-sales-info/features/contract/features/review-history/widgets/useState';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoContractReviewHistoryWidget = ({ readOnly, name }: Readonly<Props>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  const {
    d: { list },
    h: { onDelete },
    isLoading,
  } = useProjectSalesInfoContractReviewHistoryLogic();
  const value = useMemo(
    () => ({
      d: { list },
      h: { onDelete },
    }),
    [list, onDelete]
  );
  const { setReadOnly } = useProjectSalesInfoContractReviewHistoryState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  if (isLoading) {
    return <LoadingSpinner height="116px" />;
  }
  return (
    <Layout>
      <ProjectSalesInfoContractReviewHistoryModal />
      <ProjectSalesInfoContractReviewHistoryContext.Provider value={value}>
        <Header title={name}>
          <ProjectSalesInfoContractReviewHistoryButtonGroup />
        </Header>
        <Body>
          <ProjectSalesInfoContractReviewHistoryTable />
        </Body>
      </ProjectSalesInfoContractReviewHistoryContext.Provider>
    </Layout>
  );
};

export default ProjectSalesInfoContractReviewHistoryWidget;
