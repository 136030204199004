import React, { type ReactNode, useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, shallowEqual, useSelector } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import store from 'services/store';
import 'dayjs/locale/ko';
import { ThemeProvider } from '@mui/material/styles';
import mainTheme, { ColorPalette } from 'assets/theme';
import { createBrowserRouter, RouterProvider, useNavigate } from 'react-router-dom';
import { App } from '@front/src/app/app';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import { useCustomDialog } from '@front/src/features/dialog';
import type { RootState } from '@front/services/reducer';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  useQueryErrorResetBoundary,
} from 'react-query';
import { handleError } from '@front/src/utils';
import { Box, styled } from '@mui/material';
import TextBox from '@front/layouts/Text';
import Button from '@front/layouts/Button';
import { ErrorBoundary } from 'react-error-boundary';
import './index.css';
import UserAuthenticationForm from '@front/user/view/Page/UserAuthenticationForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const QueryClientProviderWithCustomOptions = ({ children }: { children: ReactNode }) => {
  const { alert } = useCustomDialog();
  const navigate = useNavigate();
  const isLoginModalOpen = useSelector(
    (root: RootState) => root.login.isOpenLoginModal,
    shallowEqual
  );
  const client = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 0,
            refetchOnWindowFocus: !isLoginModalOpen,
            staleTime: 0,
          },
          mutations: {
            onError: async (error) => handleError(error, alert),
          },
        },
        queryCache: new QueryCache({
          onError: async (error) => handleError(error, alert, navigate),
        }),
      }),
    [alert, isLoginModalOpen]
  );
  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
};
const Wrapper = () => {
  const { reset } = useQueryErrorResetBoundary();
  const navigate = useNavigate();
  const fallbackRender = ({ resetErrorBoundary }) => (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <TextBox variant="body2">에러가 발생했습니다. 관리자에게 문의하세요.</TextBox>
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
        }}
      >
        <Button
          onClick={() => {
            resetErrorBoundary();
            navigate('/');
          }}
        >
          확인
        </Button>
      </Box>
    </Box>
  );
  return (
    <ErrorBoundary
      onReset={reset}
      fallbackRender={fallbackRender}
    >
      <App />
    </ErrorBoundary>
  );
};

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent': {
    padding: '15px',
  },
  '&.notistack-MuiContent-success': {
    backgroundColor: ColorPalette.main.main_primary,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: ColorPalette.sub2.sub2_primary,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: ColorPalette.sub.sub_primary,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: ColorPalette.main.main_primary,
  },
}));

const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <Provider store={store}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="ko"
        >
          <ThemeProvider theme={mainTheme}>
            <QueryClientProviderWithCustomOptions>
              <SnackbarProvider
                maxSnack={3}
                autoHideDuration={10000}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom',
                }}
                dense={true}
                iconVariant={{
                  success: (
                    <Box
                      sx={{
                        borderRadius: '100%',
                        backgroundColor: ColorPalette.main.main_tertiary,
                        marginRight: '10px',
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <FontAwesomeIcon
                        icon="check"
                        color={ColorPalette.main.main_primary}
                        fontSize="12px"
                      />
                    </Box>
                  ),
                  error: (
                    <Box
                      sx={{
                        borderRadius: '100%',
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: ColorPalette.sub.sub_quaternary,
                        marginRight: '10px',
                      }}
                    >
                      <FontAwesomeIcon
                        icon="exclamation"
                        fontSize="12px"
                        color={ColorPalette.sub2.sub2_primary}
                      />
                    </Box>
                  ),
                  warning: (
                    <Box
                      sx={{
                        borderRadius: '100%',
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: ColorPalette.sub.sub_quaternary,
                        marginRight: '10px',
                      }}
                    >
                      <FontAwesomeIcon
                        icon="exclamation"
                        fontSize="12px"
                        color={ColorPalette._FFB611}
                      />
                    </Box>
                  ),
                  info: (
                    <Box
                      sx={{
                        borderRadius: '100%',
                        backgroundColor: ColorPalette.main.main_tertiary,
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '10px',
                      }}
                    >
                      <FontAwesomeIcon
                        icon="info"
                        color={ColorPalette.main.main_primary}
                        fontSize="12px"
                      />
                    </Box>
                  ),
                }}
                Components={{
                  success: StyledMaterialDesignContent,
                  error: StyledMaterialDesignContent,
                  warning: StyledMaterialDesignContent,
                  info: StyledMaterialDesignContent,
                }}
              >
                <Wrapper />
              </SnackbarProvider>
            </QueryClientProviderWithCustomOptions>
          </ThemeProvider>
        </LocalizationProvider>
      </Provider>
    ),
  },
  {
    path: '/user/authenticate',
    element: <UserAuthenticationForm />,
  },
]);

const render = () => {
  const root = createRoot(document.getElementById('root')!);
  root.render(<RouterProvider router={router} />);
};

render();

if (module.hot) {
  module.hot.accept('/', render);
}
