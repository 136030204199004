import React from 'react';
import { PersonalSettingsModalUISettingsHeaderSettingsHelp } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/components/Help';
import { PersonalSettingsModalUISettingsHeaderSettingsForm } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/components/Form';
import { Box } from '@mui/material';
import { personalSettingsModalUISettingsHeaderSettingsRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/repository/repository';

export { Index as PersonalSettingsModalUISettingsHeaderSettingsFeature };
interface Props {
  authMenuId: number | undefined;
  onClose: () => void;
}
const Index = ({ authMenuId, onClose }: Props) => {
  const { data: list } =
    personalSettingsModalUISettingsHeaderSettingsRepository.useGetHeaderList(authMenuId);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}
    >
      <PersonalSettingsModalUISettingsHeaderSettingsHelp />
      {list && (
        <PersonalSettingsModalUISettingsHeaderSettingsForm
          authMenuId={authMenuId}
          onClose={onClose}
        />
      )}
    </Box>
  );
};
