import React, { memo } from 'react';
import { TableBody, TableHead, TableRow } from '@mui/material';

import { OldTd, OldTh, Table } from '@front/layouts/Table';
import type { CampaignId, CampaignVO } from '@front/ost_campaign/domain';
import { campaignStatusName } from '@front/ost_campaign/domain';
import OstAllocatedBudgetInput from '@front/ost/service/detail/allocatedBudget';
import UserSelector from '@front/components/UserSelector';
import type { UserId } from '@front/user/domain';
import { AuthorizationType } from '@front/user/domain';
import { PaymentStatusModalService } from '@front/ost/service/modal/PaymentStatusModalService';

interface Props {
  campaignList?: CampaignVO[];
  totalBudget?: number;
  sumAllocated: number;
  onChange: (id: CampaignId) => (value: UserId | UserId[] | undefined) => void;
}

const OstCampaignListTableView = ({ campaignList, totalBudget, onChange, sumAllocated }: Props) => (
  <Table>
    <TableHead>
      <TableRow>
        <OldTh>No</OldTh>
        <OldTh>캠페인 명</OldTh>
        <OldTh>담당자</OldTh>
        <OldTh>접수기간</OldTh>
        <OldTh>상태</OldTh>
        <OldTh>배정예산</OldTh>
        <OldTh>배정비율</OldTh>
        <OldTh>지급액</OldTh>
        <OldTh>집행률</OldTh>
      </TableRow>
    </TableHead>
    <TableBody>
      {!!campaignList && campaignList.length === 0 && (
        <TableRow>
          <OldTd colSpan={9}>{'제안이 없습니다.'}</OldTd>
        </TableRow>
      )}
      {!!campaignList &&
        campaignList.length > 0 &&
        campaignList.map((campaign, index) => (
          <TableRow key={campaign.id}>
            <OldTd>{index + 1}</OldTd>
            <OldTd>{campaign.name}</OldTd>
            <OldTd>
              <UserSelector
                addOnly={true}
                value={campaign.manager?.id || undefined}
                onChange={onChange(campaign.id)}
                menuIdList={[27]}
                typeList={[AuthorizationType.W]}
              />
            </OldTd>
            <OldTd>
              {campaign.startDate}~{campaign.endDate}
            </OldTd>
            <OldTd>{campaignStatusName(campaign.status)}</OldTd>
            <OldTd>
              <OstAllocatedBudgetInput
                sumAllocated={sumAllocated}
                campaignId={campaign.id}
                totalReward={campaign.totalReward}
                totalBudget={totalBudget}
              />
            </OldTd>
            <OldTd>
              {typeof totalBudget !== 'undefined' &&
                `${((campaign.totalReward / totalBudget) * 100).toFixed(2)}%`}
            </OldTd>
            <OldTd>
              {campaign.fixedResultSum.toLocaleString()}
              <PaymentStatusModalService
                name={campaign.name}
                campaignId={campaign.id}
              />
            </OldTd>
            <OldTd>
              {campaign.totalReward !== null &&
              campaign.fixedResultSum !== null &&
              campaign.totalReward !== 0
                ? `${((campaign.fixedResultSum / campaign.totalReward) * 100).toFixed(2)}%`
                : '-'}
            </OldTd>
          </TableRow>
        ))}
    </TableBody>
  </Table>
);

const OstCampaignListTable = memo(OstCampaignListTableView);

export default OstCampaignListTable;
