import type { MenuView } from '@front/src/types';
import type { AuthorizationPersonalSettingsUserView } from '@front/src/features/personal-settings-modal/features/auth-settings/types/view';
import type { AdminRoleNameView } from '@front/user/domain';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

export const getTab = () => {
  const storedTabs = sessionStorage.getItem('tabList');
  const menuId = useGlobalNavBarState((state) => state.menuId);

  let tabs = [];
  if (storedTabs !== 'undefined' && storedTabs !== 'null' && storedTabs)
    tabs = JSON.parse(storedTabs);
  const tab = (tabs as MenuView[]).find((item) => item.id === (menuId ? +menuId : 0));

  return tab?.title;
};

export const sortKor = (list: AuthorizationPersonalSettingsUserView[]) => {
  const newList = [...list];
  newList.sort((curr, prev) => curr.name?.localeCompare(prev.name));
  return newList;
};

export const getRefinedTab = (list: string[], role?: AdminRoleNameView) => {
  if (!role) return list;
  if (role.canAccessPageAuthorization && !role.canAccessPageView) return [list[0]];
  if (role.canAccessPageView && !role.canAccessPageAuthorization) return [list[1]];
  return list;
};
