import type { SalesAccountingAmountInformationView } from '@front/src/features/accounting/features/upload/features/sales-info/types/view';
import XLSX from 'xlsx-js-style';
import {
  SalesAccountingAmountSubType,
  SalesAccountingAmountSubTypeObjectForLabel,
  SalesAccountingAmountTypeObjectForLabel,
} from '@front/src/features/accounting/features/upload/features/sales-info/types/domain';
import { getTypeForAccountingUploadSalesInfoFormByList } from '@front/src/features/accounting/features/upload/features/sales-info/features/utils';

export const downloadExcelAccountingUploadSalesInfo = (
  yearTwoDigits,
  excelFileName,
  data?: SalesAccountingAmountInformationView[]
) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([
    [
      '-',
      '내용',
      '연간목표',
      `${yearTwoDigits}년 합계`,
      `${yearTwoDigits}년 평균`,
      `${yearTwoDigits}01`,
      `${yearTwoDigits}02`,
      `${yearTwoDigits}03`,
      `${yearTwoDigits}04`,
      `${yearTwoDigits}05`,
      `${yearTwoDigits}06`,
      `${yearTwoDigits}07`,
      `${yearTwoDigits}08`,
      `${yearTwoDigits}09`,
      `${yearTwoDigits}10`,
      `${yearTwoDigits}11`,
      `${yearTwoDigits}12`,
    ],
  ]);
  ws['!merges'] = [
    { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } },
    { s: { r: 4, c: 0 }, e: { r: 6, c: 0 } },
    { s: { r: 7, c: 0 }, e: { r: 9, c: 0 } },
    { s: { r: 10, c: 0 }, e: { r: 12, c: 0 } },
    { s: { r: 13, c: 0 }, e: { r: 15, c: 0 } },
    { s: { r: 16, c: 0 }, e: { r: 18, c: 0 } },
    { s: { r: 19, c: 0 }, e: { r: 21, c: 0 } },
    { s: { r: 22, c: 0 }, e: { r: 24, c: 0 } },
    { s: { r: 25, c: 0 }, e: { r: 27, c: 0 } },
    { s: { r: 28, c: 0 }, e: { r: 30, c: 0 } },
  ];

  getTypeForAccountingUploadSalesInfoFormByList.forEach((type) => {
    const listByType = getListByType(type, data);
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        getDataListBySubType(type, SalesAccountingAmountSubType.TARGET, listByType),
        getDataListBySubType(type, SalesAccountingAmountSubType.EXPECTED, listByType),
        getDataListBySubType(type, SalesAccountingAmountSubType.PERFORMANCE, listByType),
      ],
      { origin: -1 }
    );
  });
  XLSX.utils.book_append_sheet(wb, ws, `20${yearTwoDigits}년 영업정보 업로드`);
  XLSX.writeFile(wb, excelFileName + '.xlsx');
};

const getListByType = (type, list) => list?.filter((l) => l.type === type);

const getDataListBySubType = (type, subType, list) => {
  const listBySubType = list?.filter((l) => l.subType === subType);
  return [
    SalesAccountingAmountTypeObjectForLabel[type],
    SalesAccountingAmountSubTypeObjectForLabel[subType],
    '-',
    '-',
    '-',
    ...Array.from({ length: 12 }, (_, i) => i + 1).map((month) => {
      const find = listBySubType.find((l) => l.month === month);
      if (!find) return '-';
      return find.amount;
    }),
  ];
};
