import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyCharacterOpinionMutation } from '@front/src/features/affiliated-company/features/character/features/opinion/query/mutation';
import { affiliatedCompanyCharacterOpinionQuery } from '@front/src/features/affiliated-company/features/character/features/opinion/query/query';

export const affiliatedCompanyCharacterOpinionRepository = {
  useCharacterOpinionCreate: (menuId) => {
    const { mutate } = affiliatedCompanyCharacterOpinionMutation.useCharacterOpinionCreate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useCharacterOpinionGet: (id?: number, menuId?) => {
    const { data, isLoading } = affiliatedCompanyCharacterOpinionQuery.useCharacterOpinionGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
  useCharacterOpinionUpdate: (menuId) => {
    const { mutate } = affiliatedCompanyCharacterOpinionMutation.useCharacterOpinionUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useCharacterOpinionDelete: (menuId) => {
    const { mutate } = affiliatedCompanyCharacterOpinionMutation.useCharacterOpinionDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
