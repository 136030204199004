import React, { useMemo } from 'react';
import { usePersonalSettingsModalAuthSettingsLogic } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/user-settings/provider/useLogic';
import { PersonalSettingsModalAuthSettingsContext } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/user-settings/provider/context';

export { Provider as PersonalSettingsModalAuthSettingsProvider };

interface Props {
  children: React.ReactNode;
  authMenuId: number | undefined;
}

const Provider = ({ children, authMenuId }: Props) => {
  const { onAuthCud, tabList, clientUserList, setClientUserList } =
    usePersonalSettingsModalAuthSettingsLogic(authMenuId);
  const value = useMemo(
    () => ({
      onAuthCud,
      tabList,
      clientUserList,
      setClientUserList,
    }),
    [onAuthCud, tabList, clientUserList, setClientUserList]
  );
  return (
    <PersonalSettingsModalAuthSettingsContext.Provider value={value}>
      {children}
    </PersonalSettingsModalAuthSettingsContext.Provider>
  );
};
