import { approvalDocumentApi } from '@front/src/features/drawer-approval-document/api/api';
import { useMutation, useQueryClient } from 'react-query';
import type { ApprovalDecisionUpdateParams } from '@front/src/features/drawer-approval-document/types/parameter';

export const approvalDocumentMutation = {
  useCancelApprovalDocument: () => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (documentId: number) => approvalDocumentApi.cancelApprovalDocument(documentId),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['approval-document']);
        await queryClient.invalidateQueries(['approval-box']);
      },
    });
    return { mutate };
  },

  useDecideApprovalDocument: (approvalId: number) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ApprovalDecisionUpdateParams) =>
        approvalDocumentApi.decideApprovalDocument(approvalId, params),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['approval-document']);
        await queryClient.invalidateQueries(['approval-box']);
        await queryClient.invalidateQueries(['note', 'list']);
      },
    });
    return { mutate };
  },
};
