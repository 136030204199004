import React from 'react';
import { useProjectSalesInfoContractEarningsState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/widgets/useState';
import { ProjectSalesInfoContractEarningsModalWidgets } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/modal/widgets/widgets';

export { ModalWrapper as ProjectSalesInfoContractEarningsModalWrapper };

const ModalWrapper = () => {
  const isModalOpen = useProjectSalesInfoContractEarningsState((state) => state.isModalOpen);
  return <>{isModalOpen && <ProjectSalesInfoContractEarningsModalWidgets />}</>;
};
