import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip } from '@mui/material';
import type { UserVO } from '@front/user/domain';
import { ColorPalette } from '@front/assets/theme';

interface Props {
  user: UserVO;
  onClick?: () => void;
  readOnly?: boolean;
}

export default function UserSelectorUserIcon({ user, onClick, readOnly = false }: Readonly<Props>) {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        width: '25px',
        height: '25px',
        justifyContent: 'center',
        alignItems: 'flex-end',
        fontSize: '18px',
        borderRadius: '25px',
        backgroundColor: ColorPalette._e4e9f2,
        color: ColorPalette._ffffff,
        border: `1px solid ${ColorPalette._e4e9f2}`,
        overflow: 'hidden',
        cursor: readOnly ? 'default' : 'pointer',
      }}
    >
      <Tooltip
        title={`${user.department.name} ${user.name}`}
        placement="top"
      >
        {user?.profile?.id ? (
          <img
            alt="프로필 사진"
            src={`/api/file-item/${user.profile.id}`}
            style={{
              objectFit: 'cover',
              width: '25px',
              height: '25px',
            }}
          />
        ) : (
          <FontAwesomeIcon icon="user" />
        )}
      </Tooltip>
    </Box>
  );
}
