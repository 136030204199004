import { AccountingSettingsTaxAccountingAccountQuery } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/query/query';

export { Repository as AccountingSettingsTaxAccountingAccountRepository };

const Repository = {
  useAllGet: () => {
    const { data, isLoading } = AccountingSettingsTaxAccountingAccountQuery.useListGet();
    return {
      data,
      isLoading,
    };
  },
  useCountGet: () => {
    const { data, isLoading } = AccountingSettingsTaxAccountingAccountQuery.useCountGet();
    return {
      data,
      isLoading,
    };
  },
  useCreatedAt: () => {
    const { data, isLoading } = AccountingSettingsTaxAccountingAccountQuery.useCreatedAtGet();
    return {
      data,
      isLoading,
    };
  },
};
