import React, { CSSProperties, useEffect, useRef } from 'react';
import type { OnChangeHandlerFunc } from 'react-mentions';
import { Mention, MentionsInput } from 'react-mentions';
import { useController, useFormContext } from 'react-hook-form';
import { ColorPalette } from '@front/assets/theme';
import useNoteStore from '@front/src/features/note/useState';

interface Props {
  inputStyle?: CSSProperties;
  mentionStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  name: string;
  userList: { id: number; display: string }[];
  singleLine?: boolean;
  width?: string;
  placeholder?: string;
  required?: boolean;
  onChange?: () => void;
  disabled?: boolean;
  readonly?: boolean;
  setMentionList?: (mentionList: number[]) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    clickedSuggestion: boolean
  ) => void;
  clickedReviewer?: string;
}

const HookFormMention = ({
  mentionStyle,
  required,
  name,
  singleLine = false,
  onBlur,
  onChange,
  userList,
  setMentionList,
  clickedReviewer,
}: Props) => {
  const { control, setValue, getValues } = useFormContext();
  const {
    field: { value, onChange: onHookFormChange },
  } = useController({ name, control, rules: { required } });
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearch = (searchTerm: string) => {
    const cursorPos = value.length;
    const lastChar = value[cursorPos - 2];

    if (lastChar && !/\s/.test(lastChar)) {
      return [];
    }

    return userList.filter((user) =>
      user.display.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
  };

  const setClickedReviewer = useNoteStore((state) => state.setClickedReviewer);

  const onMentionInputChange: OnChangeHandlerFunc = (e, newValue, newPlainTextValue, mentions) => {
    onHookFormChange(newValue);
    onChange?.();

    const mentionList = mentions.map((mention) => Number(mention.id));
    setMentionList?.(mentionList);
  };

  const mentionMirrorStyle = {
    color: 'blue',
    position: 'relative',
    zIndex: '10',
    backgroundColor: 'white',
    ...(mentionStyle ?? {}),
  };

  useEffect(() => {
    if (clickedReviewer) {
      setValue('comment', getValues('comment') + ' ' + clickedReviewer);
      setClickedReviewer('');
    }
  }, [clickedReviewer, setValue, getValues, setClickedReviewer]);
  return (
    <MentionsInput
      value={value}
      onChange={onMentionInputChange}
      singleLine={singleLine}
      onBlur={onBlur}
      style={{
        fontFamily: 'Noto Sans KR',
        control: {
          backgroundColor: '#fff',
          fontSize: 14,
          fontWeight: 'normal',
          lineHeight: '1.5', // line-height 설정
        },

        '&multiLine': {
          control: {
            minHeight: 63,
          },
          highlighter: {
            padding: 9,
            border: '1px solid transparent',
            lineHeight: '1.5', // highlighter의 line-height 설정
            borderRadius: '5px',
          },
          input: {
            padding: 9,
            border: `1px solid ${ColorPalette._e4e9f2}`,
            lineHeight: '1.5', // input의 line-height 설정
            borderRadius: '5px',
          },
        },

        '&singleLine': {
          highlighter: {
            padding: '6px 10px',
            border: '1px solid transparent',
            lineHeight: '1.5', // singleLine의 highlighter line-height 설정
            borderRadius: '5px',
            height: '32px',
          },
          input: {
            padding: '6px 10px',
            border: `1px solid ${ColorPalette._e4e9f2}`,
            lineHeight: '1.5', // singleLine의 input line-height 설정
            borderRadius: '5px',
            height: '32px',
          },
        },

        suggestions: {
          list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 14,
            position: 'relative',
            zIndex: 100,
          },
          item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',
            '&focused': {
              backgroundColor: '#cee4e5',
            },
          },
        },
      }}
      inputRef={inputRef}
    >
      <Mention
        trigger="@"
        data={handleSearch}
        displayTransform={(_, display) => `@${display}`}
        style={mentionMirrorStyle}
      />
    </MentionsInput>
  );
};

export default HookFormMention;
