import React, { useCallback, useContext, useMemo } from 'react';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { useFormContext } from 'react-hook-form';
import { shallow } from 'zustand/shallow';
import { convertToDepositCompanyOptionList } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/utils';
import { ProjectSalesInfoAffiliatedCompanyWebHardCreateModalContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/create-modal/widgets/context';
import { useProjectSalesInfoAffiliatedCompanyWebHardCreateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/create-modal/widgets/useState';

export { CompanySelect as ProjectSalesInfoAffiliatedCompanyWebHardCreateModalCompanySelect };

const CompanySelect = () => {
  const {
    d: { consortiumCompanyList },
  } = useContext(ProjectSalesInfoAffiliatedCompanyWebHardCreateModalContext);
  const { setCompanyId } = useProjectSalesInfoAffiliatedCompanyWebHardCreateModalState(
    (state) => ({
      setCompanyId: state.setCompanyId,
    }),
    shallow
  );
  const { setValue } = useFormContext();
  const handleCompanySelectChange = useCallback(
    (e) => {
      setCompanyId(+e.target.value);
      setValue('webHardId', '');
    },
    [setCompanyId, setValue]
  );
  const disabled = useMemo(() => consortiumCompanyList?.length === 0, [consortiumCompanyList]);
  return (
    <HookFormSelect
      defaultLabel={disabled ? '관계사를 먼저 추가하세요' : '선택'}
      disabled={disabled}
      name="companyId"
      optionList={convertToDepositCompanyOptionList(consortiumCompanyList)}
      onChange={handleCompanySelectChange}
    />
  );
};
