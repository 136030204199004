import { useQuery } from 'react-query';
import { approvalDocumentApi } from '@front/src/features/drawer-approval-document/api/api';
import type { ApprovalDocumentDetailQueryParams } from '@front/src/features/drawer-approval-document/types/parameter';

export const approvalDocumentQuery = {
  useGetApprovalDocument: (documentId: number, params: ApprovalDocumentDetailQueryParams) => {
    const { data } = useQuery({
      queryKey: ['approval-document', documentId],
      queryFn: () => approvalDocumentApi.getApprovalDocument(documentId, params),
      suspense: true,
      enabled: !!documentId,
    });
    return { data };
  },

  useGetAllFiles: (params: { fileItemIdList: number[] }) => {
    const { data } = useQuery({
      queryKey: ['approval-document', 'all-files'],
      queryFn: () => approvalDocumentApi.getAllFiles(params),
      suspense: true,
    });
    return { data };
  },
};
