import { default as axios } from '@front/src/config/axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import { convertEmptyToNull } from '@front/src/utils';
import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/types/parameter';

const url = {
  create: (id?) => `${projectSalesInfoURL}/${id}/consortium-company`,
  update: (id?) => `${projectSalesInfoURL}/consortium-company/${id}`,
  getDetail: (id?) => `${projectSalesInfoURL}/consortium-company/${id}`,
};

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyModalApi = {
  create: async (params: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalParameter) => {
    await axios.post(url.create(params.id), params);
  },
  update: async (params: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalParameter) => {
    await axios.put(url.update(params.id), convertEmptyToNull(params));
  },
  getDetail: async (id?: number) => {
    const { data } = await axios.get(url.getDetail(id));
    return data;
  },
};
