import React, { useMemo } from 'react';
import { modalLayout } from '@front/src/features/project-selector-modal/components/layout';
import { ProjectSelectorModalBodyContext } from '@front/src/features/project-selector-modal/widgets/body/context';
import { useProjectSelectorModalBodyLogic } from '@front/src/features/project-selector-modal/widgets/body/useLogic';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { ProjectSelectorModalBodyForm } from '@front/src/features/project-selector-modal/components/form';
import { ProjectSelectorModalBodyResultTable } from '@front/src/features/project-selector-modal/components/result-table';

interface Props {
  onSelect: (project) => void;
}

export { Widget as ProjectSelectorModalBodyWidget };

const Widget = ({ onSelect }: Props) => {
  const { Layout, Body } = modalLayout;
  const {
    d: { projectList },
    isLoading,
  } = useProjectSelectorModalBodyLogic();
  const value = useMemo(
    () => ({
      h: { onSelect },
      d: { projectList },
    }),
    [projectList, onSelect]
  );
  if (isLoading) {
    return <LoadingSpinner height="400px" />;
  }
  return (
    <ProjectSelectorModalBodyContext.Provider value={value}>
      <Layout>
        <Body>
          <ProjectSelectorModalBodyForm />
          <ProjectSelectorModalBodyResultTable />
        </Body>
      </Layout>
    </ProjectSelectorModalBodyContext.Provider>
  );
};
