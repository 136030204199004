import { personBaseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import { convertEmptyToNull, getMenuIdHeaders } from '@front/src/utils';
import type { AffiliatedCompanyPersonOutlineRepresentativeContactInformationUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/types/parameter';

const url = {
  getPersonOutlineRepresentativeContactInformation: (id?) =>
    `${personBaseUrl}/${id}/representative-contact-information`,
  updatePersonOutlineRepresentativeContactInformation: (id?) =>
    `${personBaseUrl}/${id}/representative-contact-information`,
};

export const affiliatedCompanyRepresentativeContractInformationApi = {
  getPersonOutlineRepresentativeContactInformation: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getPersonOutlineRepresentativeContactInformation(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updatePersonOutlineRepresentativeContactInformation: async (
    params: AffiliatedCompanyPersonOutlineRepresentativeContactInformationUpdateParameter,
    menuId
  ) => {
    await axios.put(
      url.updatePersonOutlineRepresentativeContactInformation(params.id),
      convertEmptyToNull(params),
      {
        headers: getMenuIdHeaders(menuId),
      }
    );
  },
};
