import type { UserNotificationView } from '@front/src/features/mobile-notification/types/view';
import { useQuery } from 'react-query';
import { mobileNotificationApi } from '@front/src/features/mobile-notification/query/api';
import type { UserNotificationSearchParams } from '@front/src/features/mobile-notification/types/parameter';

export const mobileNotificationQuery = {
  useUserNotificationCountGet: () => {
    const { data, isLoading } = useQuery<number>({
      queryKey: ['mobile-notification', 'count'],
      queryFn: () => mobileNotificationApi.getUserNotificationCount(),
    });
    return {
      data,
      isLoading,
    };
  },
  useUserNotificationSearchListGet: (params: UserNotificationSearchParams) => {
    const { data, isLoading } = useQuery<UserNotificationView[]>({
      queryKey: ['mobile-notification', 'list', params],
      queryFn: () => mobileNotificationApi.getUserNotificationSearchList(params),
      suspense: true,
    });
    return {
      data,
      isLoading,
    };
  },
};
