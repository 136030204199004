import { createContext } from 'react';
import type { ProjectAspectRatioExaminationParameter } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/types/parameter';
import { noOp } from '@front/src/utils';

export { Context as ProjectSalesInfoSubjectReviewShapeRatioListItemContext };

interface State {
  onUpdate: (params: ProjectAspectRatioExaminationParameter) => void;
}

const Context = createContext<State>({
  onUpdate: noOp,
});
