import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

export { TextWithHookForm as HookFormText };

interface Props {
  name: string;
  required?: boolean;
}

const TextWithHookForm = ({ name, required }: Props) => {
  const { control } = useFormContext();
  const {
    field: { value },
  } = useController({ name, control, rules: { required: required } });
  return <>{value}</>;
};
