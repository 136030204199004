import type { ProposalShortVO } from '@front/ost_proposal/domain';
import type { ArrangementId, ArrangementMemoVO } from '@front/ost_arrangement/domain';
import type { ArrangementMemoQuery, ArrangementQuery } from '@front/ost_arrangement/query';
import { initialArrangementMemoQuery, initialArrangementQuery } from '@front/ost_arrangement/query';
import { createReducer } from 'typesafe-actions';
import { ArrangementAction } from '@front/ost_arrangement/action';

export interface ArrangementState {
  /**
   * 제안 목록 및 상세에 필요한 상태
   */
  id?: ArrangementId;
  listDrawerOpen: boolean;
  filter: ArrangementQuery;
  proposalList: ProposalShortVO[];
  loading: boolean;
  searchFilterOpen: boolean;
  /**
   * 제안 메모 관련 상태
   */
  memoDrawerOpen: boolean;
  memoList: ArrangementMemoVO[];
  memoLoading: boolean;
  memoFilter: ArrangementMemoQuery;
}

const initial: ArrangementState = {
  listDrawerOpen: true,
  filter: initialArrangementQuery,
  proposalList: [],
  loading: false,
  searchFilterOpen: false,
  /**
   * 매모 관련 초기 상태
   */
  memoDrawerOpen: true,
  memoList: [],
  memoLoading: false,
  memoFilter: initialArrangementMemoQuery,
};

export const arrangementReducer = createReducer(initial, {
  /**
   * 제안 목록 및 상세에 필요한 핸들러
   */
  [ArrangementAction.setId]: (state, action) => ({
    ...state,
    id: action.payload,
  }),
  [ArrangementAction.toggleDrawer]: (state, action) => ({
    ...state,
    listDrawerOpen: action.payload,
  }),
  [ArrangementAction.setList]: (state, action) => ({
    ...state,
    proposalList: action.payload,
  }),
  [ArrangementAction.setLoading]: (state, action) => ({
    ...state,
    loading: action.payload,
  }),
  [ArrangementAction.setFilter]: (state, action) => ({
    ...state,
    filter: action.payload,
  }),
  [ArrangementAction.searchFilterOpen]: (state, action) => ({
    ...state,
    searchFilterOpen: action.payload,
  }),
  /**
   * 평가 메모 관련 핸들러
   */
  [ArrangementAction.setMemoDrawerOpen]: (state, action) => ({
    ...state,
    memoDrawerOpen: action.payload,
  }),
  [ArrangementAction.setMemoList]: (state, action) => ({
    ...state,
    memoList: action.payload,
  }),
  [ArrangementAction.setMemoLoading]: (state, action) => ({
    ...state,
    memoLoading: action.payload,
  }),
  [ArrangementAction.setMemoFilter]: (state, action) => ({
    ...state,
    memoFilter: action.payload,
  }),
});
