import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { AffiliatedCompanyPersonDetailModalGiftHistoryContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/widgets/context';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedCompanyPersonDetailModalGiftHistoryState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/widgets/useState';

export { GiftHistoryButton as AffiliatedCompanyPersonDetailModalGiftHistoryButton };
const GiftHistoryButton = () => {
  const {
    h: { onGiftHistoryCreate, onGiftHistoryDelete, setIdList },
    idList,
  } = useContext(AffiliatedCompanyPersonDetailModalGiftHistoryContext);
  const handleDeleteClick = useCallback(() => {
    onGiftHistoryDelete({ idList });
    setIdList([]);
  }, [onGiftHistoryDelete, idList, setIdList]);
  const { readOnly } = useAffiliatedCompanyPersonDetailModalGiftHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      <Button
        onClick={handleDeleteClick}
        disabled={readOnly}
      >
        선택삭제
      </Button>
      <Button
        onClick={onGiftHistoryCreate}
        disabled={readOnly}
      >
        선물 추가
      </Button>
    </>
  );
};
