import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import type { ModalHeaderView } from '@front/src/types';
import UIBuilderEditModalContent from '@front/src/components/ui-builder/table/EditModalContent';
import ModalUI, { ModalBodyUI } from '@front/src/components/components-with-design/layout/ModalUI';

interface Props extends Omit<CellComponentProps, 'isEditMode'> {
  open: boolean;
  onClose: () => void;
  headerList: ModalHeaderView[];
  headerName: string;
}

export default function UIBuilderEditModal({
  open,
  onClose,
  headerName,
  ...rest
}: Readonly<Props>) {
  return (
    <ModalUI
      title={`${headerName} 상세`}
      open={open}
      onClose={onClose}
    >
      <ModalBodyUI>
        <UIBuilderEditModalContent
          onClose={onClose}
          {...rest}
        />
      </ModalBodyUI>
    </ModalUI>
  );
}
