import React from 'react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { FormProvider, useForm } from 'react-hook-form';
import '@front/src/features/work/features/work/features/carousel/components/Carousel.scss';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import workCarouselQuery from '@front/src/features/work/features/work/features/carousel/query/query';
import WorkCarouselHeader from '@front/src/features/work/features/work/features/carousel/components/Header';
import WorkCarousel from '@front/src/features/work/features/work/features/carousel/components/Carousel';
import WorkCarouselCreateModal from '@front/src/features/work/features/work/features/carousel/components/CreateModal';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';

export default function WorkCarouselForm({
  sectionId,
  dataId,
  menuId,
  readOnly,
  name,
}: Readonly<SectionComponentProps>) {
  const { Layout } = SectionWrapperUI;
  const { data: list } = workCarouselQuery.useListGet(dataId, menuId);
  const methods = useForm({
    values: {
      list: list ?? [],
      openCreateModal: false,
      imageModalId: undefined,
    },
  });
  return (
    <FormProvider {...methods}>
      <WorkCarouselCreateModal
        sectionId={sectionId}
        dataId={dataId}
        menuId={menuId}
        readOnly={readOnly}
      />
      <Layout height='400px'>
        <WorkCarouselHeader
          name={name}
          readOnly={readOnly}
        />
        <WorkCarousel
          readOnly={readOnly}
          menuId={menuId}
          dataId={dataId}
          sectionId={sectionId}
        />
      </Layout>
    </FormProvider>
  );
}
