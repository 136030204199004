import React, { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { usePersonalSettingsModalUISettingsHeaderSettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/useState';
import { useShallow } from 'zustand/react/shallow';

export { Note as PersonalSettingsModalUISettingsHeaderSettingsTableContentNote };

interface Props {
  index: number;
}

const Note = ({ index }: Readonly<Props>) => {
  const { control, watch } = useFormContext();
  const { editOpenIdList } = usePersonalSettingsModalUISettingsHeaderSettingsState(
    useShallow((state) => ({
      editOpenIdList: state.editOpenIdList,
    }))
  );
  const open = useMemo(() => {
    const id = watch(`list.${index}.id`);
    return editOpenIdList.includes(id);
  }, [editOpenIdList, watch, index]);
  const note = useWatch({ name: `list.${index}.note`, control });
  const content = useMemo(() => {
    if (open) {
      return (
        <HookFormInput
          name={`list.${index}.note`}
          placeholder="직접 입력"
        />
      );
    }
    return watch(`list.${index}.note`);
  }, [note, open, index, watch]);
  return <>{content ?? '-'}</>;
};
