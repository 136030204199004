import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { AxiosError } from 'axios';
import type { WorkCarouselCreateParameter } from '@front/src/features/work/features/work/features/carousel/types/parameter';
import type { CommonGetParameter } from '@front/src/types/parameter';

const workCarouselMutation = {
  useCreate: (workId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation<unknown, AxiosError, WorkCarouselCreateParameter>({
      mutationFn: (params) => workWorkApi.createCarousel(params, workId, menuId),
      onSuccess: async (data) => data,
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'carousel'],
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useDelete: (params: CommonGetParameter) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation<unknown, AxiosError, number>({
      mutationFn: (id) => workWorkApi.deleteCarousel(id, params),
      onSuccess: async (data) => data,
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'carousel'],
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
};

export default workCarouselMutation;
