import { personalSettingsModalUISettingsHeaderSettingsHistoryQuery } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/history/query/query';

export { repository as personalSettingsModalUISettingsHeaderSettingsHistoryRepository };
const repository = {
  useGetHeaderHistoryList: (id, authMenuId) => {
    const { data } =
      personalSettingsModalUISettingsHeaderSettingsHistoryQuery.useGetHeaderHistoryList(
        id,
        authMenuId
      );
    return {
      data,
    };
  },
};
