import React, { memo } from 'react';
import { Table } from '@front/layouts/Table';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { ProjectSalesInfoSubjectReviewModalSpecialWindWidget } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/special-wind/widgets/widget';

export { SpecialWindTable as ProjectSalesInfoSubjectReviewModalSpecialWindTable };

const SpecialWindTable = () => (
  <TableContainer>
    <Table>
      <TableHeader />
      <TableBody>
        <ProjectSalesInfoSubjectReviewModalSpecialWindWidget />
      </TableBody>
    </Table>
  </TableContainer>
);

const TableHeader = memo(() => (
  <TableHead>
    <TableRow>
      <Th
        noWrap={true}
        width="156px"
      >
        특별풍하중 조건(5) 여부
      </Th>
      <Th
        noWrap={true}
        width="96px"
      >
        해안가 3km
      </Th>
      <Th
        noWrap={true}
        width="80px"
      >
        기본풍속
      </Th>
      <Th
        noWrap={true}
        width="200px"
      >
        주소
      </Th>
      <Th
        noWrap={true}
        width="300px"
      >
        기본풍속지도 검토 PDF 첨부
      </Th>
    </TableRow>
  </TableHead>
));

TableHeader.displayName = 'ProjectSalesInfoSubjectReviewModalSpecialWindTableHeader';
