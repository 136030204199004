import { headerHistoryQuery } from '@front/src/features/header-history/query/query';
import { useHeaderHistoryState } from '@front/src/features/header-history/useState';
import { useShallow } from 'zustand/react/shallow';

export { repository as headerHistoryRepository };
const repository = {
  useGetHistoryList: () => {
    const { headerId, sectionId } = useHeaderHistoryState(
      useShallow((state) => ({
        headerId: state.headerId,
        sectionId: state.sectionId,
      }))
    );
    const { data } = headerHistoryQuery.useGetHistoryList(
      sectionId,
      headerId !== '' ? Number(headerId) : undefined
    );
    return {
      data,
    };
  },
  useGetHeaderList: () => {
    const { sectionId } = useHeaderHistoryState(
      useShallow((state) => ({
        sectionId: state.sectionId,
      }))
    );
    const { data } = headerHistoryQuery.useGetHeaderList(sectionId);
    return {
      data,
    };
  },
};
