import { default as axios } from '@front/src/config/axios';
import type { ProjectSalesInfoBidInfoParams } from '@front/src/features/project-sales-info/features/bid/features/bid-information/types/parameter';
import { estimationURLAPI } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/utils/constants';
import { bidURLAPI } from '@front/src/features/project-sales-info/features/bid/utils/constant';
import { toFormData } from '@front/services/api';

const url = {
  createBidInfo: (id: number) => `${bidURLAPI}/${id}/bid-information`,
  getEstimateId: (id: number) => `${estimationURLAPI}/${id}/estimation?isConfirmed=true`,
};

export const projectSalesInfoBidInfoCreateModalApi = {
  createBidInfo: async (data: { params: ProjectSalesInfoBidInfoParams; id: number }) => {
    await axios.post(
      url.createBidInfo(data.id!),
      toFormData({
        ...data.params,
        bidNoticeFile: data.params.bidNoticeFile,
        bidGuideLineFile: data.params.bidGuideLineFile,
      })
    );
  },

  getEstimateId: async (id: number) => {
    const { data } = await axios.get(url.getEstimateId(id));
    return data;
  },
};
