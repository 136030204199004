import React, { useEffect } from 'react';
import { ActivityLayouts } from '@front/src/features/project-sales-info/features/activity/components/layouts';
import { ProjectSalesInfoActivityDeleteButtonWidget } from '@front/src/features/project-sales-info/features/activity/widgets/delete-button/widget';
import { ProjectSalesInfoActivityCreateButtonWidget } from '@front/src/features/project-sales-info/features/activity/widgets/create-button/widget';
import { SalesInfoActivityTable } from '@front/src/features/project-sales-info/features/activity/components/activity-table';
import { SalesInfoActivityListWidget } from '@front/src/features/project-sales-info/features/activity/widgets/list/widget';
import { useProjectSalesInfoActivityState } from '@front/src/features/project-sales-info/features/activity/useState';
import { useShallow } from 'zustand/react/shallow';
import { useGetReadOnly } from '@front/src/hooks/useGetReadOnly';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoActivityFeature = ({ name, maxWidth }: Readonly<Props>) => {
  const { Layout, Header, Body } = ActivityLayouts;
  const { readOnly } = useGetReadOnly();
  const { setReadOnly } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <Layout>
      <Header
        title={name}
        text="*행을 클릭하여 수정하세요."
      >
        <ProjectSalesInfoActivityDeleteButtonWidget />
        <ProjectSalesInfoActivityCreateButtonWidget />
      </Header>
      <Body>
        <SalesInfoActivityTable
          maxWidth={maxWidth}
          listWidget={<SalesInfoActivityListWidget />}
        />
      </Body>
    </Layout>
  );
};

export default ProjectSalesInfoActivityFeature;
