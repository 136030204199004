import type { DefaultFunction } from '@front/type/Function';
import type React from 'react';

export enum DialogStatus {
  OK = 'OK',
  WARN = 'WARN',
  ERROR = 'ERROR',
}

export interface AlertVO {
  title: string;
  status: DialogStatus;
  closeText: string;
  afterClose: DefaultFunction;
  children: React.ReactNode;
}

export interface ConfirmVO extends AlertVO {
  afterConfirm: DefaultFunction;
  confirmText: string;
}

export interface BlockVO {
  title: string;
  status: DialogStatus;
  closeText: string;
  afterClose: DefaultFunction;
  children: React.ReactNode;
}
