import { personalSettingsModalRepository } from '@front/src/features/personal-settings-modal/features/auth-settings/repository';
import { usePersonalSettingsModalState } from '@front/src/features/personal-settings-modal/useState';
import { adminAccessAuthSettingsUserRepository } from '@front/src/features/admin/features/access-authorization-settings/features/user-authorization/repository/repository';
import { useEffect, useState } from 'react';
import type { AuthorizationPersonalSettingsUserView } from '@front/src/features/personal-settings-modal/features/auth-settings/types/view';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

export { useLogic as usePersonalSettingsModalAuthSettingsLogic };

const useLogic = (authMenuId) => {
  const menuId = useGlobalNavBarState((state) => state.menuId);
  const searchKeyword = usePersonalSettingsModalState((state) => state.searchKeyword);
  const { data: userList } = personalSettingsModalRepository.useUserListGet(
    menuId ?? 0,
    searchKeyword,
    authMenuId
  );
  const { data: tabList } = adminAccessAuthSettingsUserRepository.useTabListGet(
    menuId ?? 0,
    authMenuId
  );
  const { run: onAuthCud } = personalSettingsModalRepository.useUserListCud(
    menuId ?? 0,
    searchKeyword,
    authMenuId
  );
  const [clientUserList, setClientUserList] = useState<AuthorizationPersonalSettingsUserView[]>([]);
  useEffect(() => {
    setClientUserList(
      userList?.map((item) => ({
        ...item,
        isNew: false,
      }))
    );
  }, [userList]);

  return {
    clientUserList,
    tabList,
    onAuthCud,
    setClientUserList,
  };
};
