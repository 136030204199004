import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import { useAffiliatedCompanyDetailState } from '@front/src/features/affiliated-company/widgets/detail/useState';
import { affiliatedCompanyPrimaryBusinessRepository } from '@front/src/features/affiliated-company/features/outline/features/primary-business/repository/repository';
import { useAffiliatedCompanyOutlinePrimaryBusinessState } from '@front/src/features/affiliated-company/features/outline/features/primary-business/widgets/useState';

export { useLogic as useAffiliatedCompanyOutlinePrimaryBusinessLogic };

const useLogic = (menuId) => {
  const { id } = useAffiliatedCompanyDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { idList, setIdList } = useAffiliatedCompanyOutlinePrimaryBusinessState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { data: primaryBusinessList, isLoading } =
    affiliatedCompanyPrimaryBusinessRepository.useOutlinePrimaryBusinessGet(id, menuId);
  const { run: onCreatePrimaryBusiness } =
    affiliatedCompanyPrimaryBusinessRepository.useOutlinePrimaryBusinessCreate(menuId);
  const { run: onDeletePrimaryBusiness } =
    affiliatedCompanyPrimaryBusinessRepository.useOutlinePrimaryBusinessDelete(menuId);
  const { run: onUpdatePrimaryBusiness } =
    affiliatedCompanyPrimaryBusinessRepository.useOutlinePrimaryBusinessUpdate(menuId);
  const handlePrimaryBusinessCreate = useCallback(() => {
    onCreatePrimaryBusiness(id);
  }, [onCreatePrimaryBusiness, id]);
  const d = { primaryBusinessList };
  const h = {
    onCreatePrimaryBusiness: handlePrimaryBusinessCreate,
    setIdList,
    onDeletePrimaryBusiness,
    onUpdatePrimaryBusiness,
  };
  return { d, h, isLoading, idList };
};
