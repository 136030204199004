import React, { Suspense } from 'react';
import { AccountingSettingsManagementAccountingAccountTableHeaderProvider } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/table-header/provider/provider';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { AccountingSettingsManagementAccountingAccountTableHeader } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/table-header/components/table-header';

export { Widget as AccountingSettingsManagementAccountingAccountTableHeaderWidget };

const Widget = () => (
  <Suspense
    fallback={
      <LoadingSpinner
        width="1000px"
        height="60px"
      />
    }
  >
    <AccountingSettingsManagementAccountingAccountTableHeaderProvider>
      <AccountingSettingsManagementAccountingAccountTableHeader />
    </AccountingSettingsManagementAccountingAccountTableHeaderProvider>
  </Suspense>
);
