import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import ProposalHistoryLayout from '@front/personnel/view/Page/Log/Proposal';
import VoteHistoryLayout from '@front/personnel/view/Page/Log/Vote';
import { personnelAction } from '@front/personnel/action';
import type { RootState } from '@front/services/reducer';
import { PersonnelId } from '@front/personnel/domain';
import useId from '@front/services/useId';
interface Props {
  menuId?: number | undefined;
}
const OstLogService = ({ menuId }: Props) => {
  const dispatch = useDispatch();
  const urlId = useId();
  const idParams = useSelector((root: RootState) => root.personnel.idParams, shallowEqual);
  useEffect(() => {
    if (urlId) {
      dispatch(
        personnelAction.setId({
          id: idParams?.id ? PersonnelId(idParams.id) : PersonnelId(urlId),
          menuId,
        })
      );
      dispatch(personnelAction.getProposalLog());
      dispatch(personnelAction.getVoteLog());
    }
    return () => {
      dispatch(personnelAction.setProposalLog([]));
      dispatch(personnelAction.setVoteLog([]));
    };
  }, [idParams || urlId]);
  return (
    <Box
      sx={{
        display: 'grid',
        gridRowGap: '10px',
      }}
    >
      <ProposalHistoryLayout />
      <VoteHistoryLayout />
    </Box>
  );
};

export default OstLogService;
