import { useQuery } from 'react-query';
import type { SmProjectCollectionView } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/views';
import { projectSalesInfoContractEarningsItemUpdateModalApi } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/query/api';

export const projectSalesInfoContractEarningsItemUpdateModalQuery = {
  useContractEarningsItemDetailsGet: (detailId: number) => {
    const { data, isLoading, remove } = useQuery<SmProjectCollectionView>({
      queryKey: [
        'project-sales-info',
        'collection',
        'contract-earnings',
        'item',
        'details',
        detailId,
      ],
      queryFn: () =>
        projectSalesInfoContractEarningsItemUpdateModalApi.getContractEarningsItemDetails(detailId),
    });
    return {
      data,
      isLoading,
      remove,
    };
  },
  useConsortiumCompanyGet: (id: number) => {
    const { data, isLoading } = useQuery({
      queryKey: ['project-sales-info', 'collection', 'contract-earnings', 'consortium', id],
      queryFn: () => projectSalesInfoContractEarningsItemUpdateModalApi.getConsortiumCompany(id),
    });
    return {
      data,
      isLoading,
    };
  },
};
