import { useQuery } from 'react-query';
import { accountingManagementAccountingReadApi } from '@front/src/features/accounting/features/settings/features/management-accounting-read/query/api';
import type { AccountingSettingsManagementAccountingReadAccountView } from '@front/src/features/accounting/features/settings/features/management-accounting-read/types/view';
import type {
  AccountingSettingsManagementAccountingReadAccountListGetParameter,
  AccountingSettingsManagementAccountingReadEAccountAndManualListGetParameter,
} from '@front/src/features/accounting/features/settings/features/management-accounting-read/types/parameter';
import type { TaxAccountingView } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/types/view';

export { Query as accountingManagementAccountingReadQuery };

const Query = {
  useListGet: (params: AccountingSettingsManagementAccountingReadAccountListGetParameter) => {
    const { data, isLoading, remove } = useQuery<
      AccountingSettingsManagementAccountingReadAccountView[]
    >({
      queryFn: () => accountingManagementAccountingReadApi.getList(params),
      queryKey: [
        'accounting',
        'management-accounting-read',
        'account-list',
        ...params.parentIdList,
      ],
      suspense: true,
    });
    return {
      data,
      isLoading,
      remove,
    };
  },
  useCategoryMicroListGet: (
    params: AccountingSettingsManagementAccountingReadEAccountAndManualListGetParameter
  ) => {
    const { data, isLoading, remove } = useQuery<TaxAccountingView[]>({
      queryFn: () => accountingManagementAccountingReadApi.getCategoryMicroList(params),
      queryKey: [
        'accounting',
        'management-accounting-read',
        'e-account-and-manual-list',
        params.category,
      ],
      enabled: !!params.category,
      suspense: true,
    });
    return {
      data,
      isLoading,
      remove,
    };
  },
};
