import React, { memo, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import UserSelectorModal from '@front/components/UserSelector/Modal';
import { campaignAction } from '@front/ost_campaign/action';
import type { EstimatorListUpsertParameter } from '@front/ost_campaign/parameter';
import type { RootState } from '@front/services/reducer';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';

const ModalAddEstimatorService = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(
    (root: RootState) => root.campaign.addProposalEstimatorModalOpen,
    shallowEqual
  );
  const proposalEstimatorModalId = useSelector(
    (root: RootState) => root.campaign.proposalEstimatorModalId,
    shallowEqual
  );
  const [proposalList] = useSelector(
    (root: RootState) => [root.campaign.proposalList],
    shallowEqual
  );

  const onClose = useCallback(() => {
    dispatch(campaignAction.setAddProposalEstimatorModalOpen(false));
  }, [dispatch]);

  const getSelectedProposal = useCallback(() => {
    if (!proposalList) {
      return;
    }
    if (proposalList.length === 0) {
      return;
    }
    if (!proposalEstimatorModalId) {
      return;
    }
    return proposalList.find((proposal) => proposal.id === proposalEstimatorModalId);
  }, [proposalEstimatorModalId, proposalList]);

  const getSelectedUserList = () => {
    const selectedProposal = getSelectedProposal();
    if (!selectedProposal) {
      return;
    }
    return selectedProposal.estimatorList.map((estimatorInfo) => estimatorInfo.estimator.id);
  };

  const openSnackbar = useCallback(
    (message: string, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );

  const onChange = useCallback(
    (userIdList) => {
      if (userIdList.length === 0) {
        openSnackbar('최소 한명의 심사자가 필요합니다.');
        return;
      }
      const selectedProposal = getSelectedProposal();
      if (!selectedProposal) {
        return;
      }

      dispatch(
        campaignAction.upsertProposalEstimatorList({
          estimatorList: userIdList,
        } as EstimatorListUpsertParameter)
      );
    },
    [dispatch, getSelectedProposal, openSnackbar]
  );

  return (
    <UserSelectorModal
      open={isOpen}
      onClose={onClose}
      multi={true}
      value={getSelectedUserList()}
      onChange={onChange}
    />
  );
};

const ModalAddProposalEstimatorService = memo(ModalAddEstimatorService);

export default ModalAddProposalEstimatorService;
