import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ArticleItem } from '@front/src/components/article-item';
import type { RootState } from '@front/services/reducer';
import { DetailStatusTable } from '@front/ost_reward/view/detail/detail-status-table';

export const DetailStatus = () => {
  const { detail } = useSelector(
    (root: RootState) => ({
      detail: root.ostRewardMangement.detail,
    }),
    shallowEqual
  );

  return (
    <ArticleItem
      title={
        <>{`${
          typeof detail !== 'undefined' && detail.year
        }년 월별 OST 포상 집행 현황 (월 개인 별 집행 금액 조회는 지급월 클릭)`}</>
      }
    >
      <DetailStatusTable />
    </ArticleItem>
  );
};
