import React, { useContext } from 'react';
import { FormikContext } from 'formik';
import { Box } from '@mui/material';
import Input from '@front/layouts/Input';
import Divider from '@front/layouts/Divider';

const VoteMemoFilter = () => {
  const formik = useContext(FormikContext);

  return (
    <Box
      sx={{
        width: '100%',
        padding: '0 10px',
        margin: '10px 0',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        flex: 1,
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Input
          key={formik.values.keyword}
          defaultValue={formik.values.keyword ?? ''}
          variant="outlined"
          placeholder="검색어 입력 후 엔터"
          onKeyDown={(e) => {
            if (e.key.toLowerCase() === 'enter') {
              const value = (e.target as HTMLInputElement).value || '';
              if (formik.values.keyword !== value) {
                formik.setFieldValue('keyword', value);
                formik.handleSubmit();
              }
            }
          }}
        />
      </Box>
      <Divider sx={{ margin: '10px 0 0 0', padding: '0', width: '100%' }} />
    </Box>
  );
};

export default VoteMemoFilter;
