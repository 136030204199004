import { SalesAccountingAmountType } from '@front/src/features/accounting/features/upload/features/sales-info/types/domain';
import type { SalesAccountingAmountInformation } from '@front/src/features/accounting/features/upload/features/sales-info/types/parameter';
import { NOT_INTEGER_REGEX } from '@front/src/features/affiliated-company/utils/constant';

export const getTypeForAccountingUploadSalesInfoFormByList = [
  SalesAccountingAmountType.ORDER,
  SalesAccountingAmountType.REVIEW,
  SalesAccountingAmountType.NET_ORDER,
  SalesAccountingAmountType.COLLECTION,
  SalesAccountingAmountType.SAVED_REVIEW,
  SalesAccountingAmountType.NET_COLLECTION,
  SalesAccountingAmountType.NET_ORDER_BALANCE,
  SalesAccountingAmountType.UNPAID_AMOUNT,
  SalesAccountingAmountType.EXPECTED_AMOUNT,
  SalesAccountingAmountType.UNCLAIMED_AMOUNT,
];

export const convertDataFromProjectSalesManagementModal = (data, year, month, subType) => {
  const list: SalesAccountingAmountInformation[] = [];
  for (let i = 0; i < 10; i += 1) {
    list.push({
      amount: +data[`amount${i}`].replace(NOT_INTEGER_REGEX, ''),
      type: getTypeForAccountingUploadSalesInfoFormByList[i],
      subType,
      year,
      month,
    });
  }
  return list;
};
