import React, { useState } from 'react';
import { getOutputControlPanelHeaderItemList } from '@front/src/features/work/features/work/utils/constant';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import type { WorkOutputBundleView } from '@front/src/features/work/features/work/features/output-bundle/types/view';
import workOutputBundleMutation from '@front/src/features/work/features/work/features/output-bundle/query/mutation';
import workOutputMutation from '@front/src/features/work/features/work/features/output/query/mutation';
import useWorkOutputAddBottom from '@front/src/features/work/features/work/features/output/repository/useAddBottom';
import useWorkOutputAddTop from '@front/src/features/work/features/work/features/output/repository/useAddTop';
import useWorkOutputCreate from '@front/src/features/work/features/work/features/output/repository/useCreate';
import useWorkOutputSeqDown from '@front/src/features/work/features/work/features/output/repository/useSeqDown';
import useWorkOutputSeqUp from '@front/src/features/work/features/work/features/output/repository/useSeqUp';
import useWorkOutputUpdate from '@front/src/features/work/features/work/features/output/repository/useUpdate';
import { useCustomDialog } from '@front/src/features/dialog';
import WorkOutputTableBody from '@front/src/features/work/features/work/features/output/components/TableBody';
import { toggleItemInArray } from '@front/src/utils';
import useWorkOutputUpdateFile from '@front/src/features/work/features/work/features/output/repository/useUpdateFile';

interface Props extends SectionComponentProps {
  item: WorkOutputBundleView;
  index: number;
  onOpenCreateModal: () => void;
  onOpenUpdateModal: () => void;
}

export interface OutputOptions {
  bundleId: number;
  index: number;
  colored: number | undefined;
  setColored: React.Dispatch<React.SetStateAction<number | undefined>>;
  editOpenList: number[];
  toggleEditOpenId: (id: number | undefined) => void;
  clearEditOpenList: () => void;
}

export default function WorkOutputTable({
  item,
  index,
  maxWidth,
  onOpenCreateModal,
  onOpenUpdateModal,
  dataId,
  menuId,
  sectionId,
  ...rest
}: Readonly<Props>) {
  const [editOpenList, setEditOpenList] = useState<number[]>([]);
  const [colored, setColored] = useState<number | undefined>(undefined);
  const toggleEditOpenId = (id) => {
    setEditOpenList(toggleItemInArray(editOpenList, id));
  };
  const clearEditOpenList = () => {
    if (editOpenList.length === 0) return;
    setEditOpenList([]);
  };
  const {
    isLoading,
    onRemoveBundle,
    onRemoveAll,
    onAddBottom,
    onAddTop,
    onRemove,
    onSeqUp,
    onSeqDown,
    onUpdate,
    onCreate,
    onUpdateFile,
  } = useMutationLogic({
    id: item.id,
    setColored,
    toggleEditOpenId,
    dataId,
    menuId,
    sectionId,
  });
  const actions = {
    onRemoveAll,
    onAddTop,
    onAddBottom,
    onCloseAll: clearEditOpenList,
    onAddBundle: onOpenCreateModal,
    onEditBundleName: onOpenUpdateModal,
    onRemoveBundle,
  };
  const controlPanelHeaderItemList = getOutputControlPanelHeaderItemList({
    actions,
    isBasic: index === 0,
  });
  const rowActions = {
    onUpdate,
    onCreate,
    onRemove,
    onSeqUp,
    onSeqDown,
    onUpdateFile,
  };
  const outputOptions = {
    index,
    bundleId: item.id,
    colored,
    setColored,
    editOpenList,
    toggleEditOpenId,
    clearEditOpenList,
  };
  return (
    <UiBuilderTable
      isLoading={isLoading}
      rowActions={rowActions}
      BodyComponent={WorkOutputTableBody}
      controlPanelHeaderItemList={controlPanelHeaderItemList}
      outputOptions={outputOptions}
      dataId={dataId}
      menuId={menuId}
      sectionId={sectionId}
      {...rest}
      // sx={{
      //   maxWidth,
      // }}
    />
  );
}

function useMutationLogic({
  id: bundleId,
  setColored,
  toggleEditOpenId,
  menuId,
  sectionId,
  dataId,
}) {
  const { confirm } = useCustomDialog();
  const {
    run: onAddBottom,
    isLoading: isAddBottomLoading,
    setCallback: setAddBottomCallback,
  } = useWorkOutputAddBottom(bundleId, menuId);
  setAddBottomCallback({
    onSuccess: ({ data }) => {
      setColored(data);
      toggleEditOpenId(data);
    },
  });
  const {
    run: onAddTop,
    isLoading: isAddTopLoading,
    setCallback: setAddTopCallback,
  } = useWorkOutputAddTop(bundleId, menuId);
  setAddTopCallback({
    onSuccess: ({ data }) => {
      setColored(data);
      toggleEditOpenId(data);
    },
  });
  const {
    run: onCreate,
    isLoading: isCreateLoading,
    setCallback: setCreateCallback,
  } = useWorkOutputCreate(bundleId, menuId);
  setCreateCallback({
    onSuccess: ({ data }) => {
      setColored(data);
      toggleEditOpenId(data);
    },
  });
  const {
    run: onSeqDown,
    isLoading: isSeqDownLoading,
    setCallback: setSeqDownCallback,
  } = useWorkOutputSeqDown(bundleId, menuId);
  setSeqDownCallback({
    onSuccess: ({ data }) => setColored(data),
  });
  const {
    run: onSeqUp,
    isLoading: isSeqUpLoading,
    setCallback: setSeqUpCallback,
  } = useWorkOutputSeqUp(bundleId, menuId);
  setSeqUpCallback({
    onSuccess: ({ data }) => setColored(data),
  });
  const {
    run: onUpdate,
    isLoading: isUpdateLoading,
    setCallback: setUpdateCallback,
  } = useWorkOutputUpdate(bundleId, menuId);
  setUpdateCallback({
    onSuccess: ({ data }) => {
      setColored(data);
    },
  });
  const {
    run: onUpdateFile,
    isLoading: isUpdateFileLoading,
    setCallback: setFileUpdateCallback,
  } = useWorkOutputUpdateFile(bundleId, menuId);
  setFileUpdateCallback({
    onSuccess: ({ data }) => {
      setColored(data);
    },
  });
  const { mutate: onDeleteBundle, isLoading: isBundleDeleteLoading } =
    workOutputBundleMutation.useDelete(menuId);
  const { mutate: onDelete, isLoading: isDeleteLoading } = workOutputMutation.useDelete(bundleId, {
    menuId,
    sectionId,
    dataId,
  });
  const { mutate: onDeleteAll, isLoading: isDeleteAllLoading } = workOutputMutation.useDeleteAll(
    bundleId,
    {
      menuId,
      sectionId,
      dataId,
    }
  );
  const onRemoveBundle = () => {
    confirm({
      title: '삭제 확인',
      lineBreakChildren: [
        { value: '묶음 삭제 시, 관련된 모든 데이터가 삭제됩니다.' },
        { value: '삭제하시겠습니까?' },
      ],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDeleteBundle(bundleId);
      },
    });
  };
  const onRemove = (id: number) => {
    confirm({
      title: '삭제 확인',
      lineBreakChildren: [{ value: '선택한 내용을 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDelete(id);
      },
    });
  };
  const onRemoveAll = () => {
    confirm({
      title: '삭제 확인',
      lineBreakChildren: [{ value: '선택한 내용을 전체 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDeleteAll();
      },
    });
  };
  return {
    onRemoveBundle,
    onAddTop,
    onAddBottom,
    onCreate,
    onUpdate,
    onUpdateFile,
    onSeqUp,
    onSeqDown,
    onRemove,
    onRemoveAll,
    isLoading:
      isBundleDeleteLoading ||
      isAddTopLoading ||
      isAddBottomLoading ||
      isCreateLoading ||
      isUpdateLoading ||
      isUpdateFileLoading ||
      isSeqUpLoading ||
      isSeqDownLoading ||
      isDeleteLoading ||
      isDeleteAllLoading,
  };
}
