import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { projectSalesInfoContractEarningsItemUpdateModalRepository } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/repository';
import { useContractEarningsItemState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/useState';
import { useProjectSalesInfoCollectionManagementModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/widgets/useState';
import { useEffect } from 'react';
import { projectSalesInfoContractEarningsItemCreateModalRepository } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/repository';

export { useLogic as useProjectSalesInfoContractEarningsItemUpdateModalLogic };

const useLogic = () => {
  const itemId = useContractEarningsItemState((state) => state.itemId);
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const detailId = useContractEarningsItemState((state) => state.detailId);
  const setCollectionManagementList = useProjectSalesInfoCollectionManagementModalState(
    (state) => state.setCollectionManagementList
  );
  const { run: onUpdate } =
    projectSalesInfoContractEarningsItemUpdateModalRepository.useContractEarningsItemDetailsUpdate(
      itemId!,
      id!
    );
  const {
    data: contractEarningsItemDetails,
    isLoading,
    remove,
  } = projectSalesInfoContractEarningsItemUpdateModalRepository.useContractEarningsItemDetailsGet(
    detailId!
  );
  const { data: consortiumCompanyList } =
    projectSalesInfoContractEarningsItemUpdateModalRepository.useConsortiumCompanyGet(id!);
  const { data: confirmedContractDetail } =
    projectSalesInfoContractEarningsItemCreateModalRepository.useConfirmedContractGet(id!);
  useEffect(() => {
    setCollectionManagementList(
      contractEarningsItemDetails ? contractEarningsItemDetails.collectionManagementList : []
    );
  }, [contractEarningsItemDetails, setCollectionManagementList]);
  return {
    h: {
      onUpdate,
    },
    d: {
      consortiumCompanyList,
      contractEarningsItemDetails,
      confirmedContractDetail,
    },
    isLoading,
    remove,
  };
};
