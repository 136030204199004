import { createAction } from 'typesafe-actions';

export enum UserNotificationActionType {
  requestCount = 'user-notification/count/request',
  setCount = 'user-notification/count/set',
}

export const userNotificationAction = {
  requestCount: createAction(UserNotificationActionType.requestCount)(),
  setCount: createAction(UserNotificationActionType.setCount)<number>(),
};
