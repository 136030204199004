import React, { useContext } from 'react';
import { Th } from '@front/src/components/layout/table/th';
import { Table } from '@front/src/components/layout/table/table';
import { TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/widgets/context';
import type { AffiliatedCompanyPersonOutlineRepresentativeContactInformationUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/types/parameter';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import type { AffiliatedCompanyPersonOutlineRepresentativeContactInformationView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/types/view';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { getValueIfExist } from '@front/src/utils';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedCompanyPersonDetailModalOutlineState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/section/outline/useState';

const getValues = (
  personOutlineRepresentativeContactInformation?: AffiliatedCompanyPersonOutlineRepresentativeContactInformationView
) => ({
  companyEmail: personOutlineRepresentativeContactInformation?.companyEmail ?? '',
  companyPhoneNumber: personOutlineRepresentativeContactInformation?.companyPhoneNumber ?? '',
  directPhoneNumber: personOutlineRepresentativeContactInformation?.directPhoneNumber ?? '',
  companyFaxNumber: personOutlineRepresentativeContactInformation?.companyFaxNumber ?? '',
});

export { RepresentativeContactInformation as AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformation };

const RepresentativeContactInformation = () => {
  const {
    d: { personOutlineRepresentativeContactInformation },
    h: { onPersonOutlineRepresentativeContactInformationUpdate },
  } = useContext(AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationContext);
  const methods =
    useForm<AffiliatedCompanyPersonOutlineRepresentativeContactInformationUpdateParameter>({
      values: getValues(personOutlineRepresentativeContactInformation),
    });
  const onSubmit = methods.handleSubmit((data) => {
    onPersonOutlineRepresentativeContactInformationUpdate(data);
  });
  const { readOnly } = useAffiliatedCompanyPersonDetailModalOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <Th>회사명</Th>
              <TableCell>
                {getValueIfExist(personOutlineRepresentativeContactInformation?.name)}
              </TableCell>
              <Th>회사전화번호</Th>
              <TableCell>
                <HookFormInput
                  name="companyPhoneNumber"
                  onSubmit={onSubmit}
                  inputType={InputType.COMPANY_CONTACT}
                  disabled={readOnly}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>업체구분</Th>
              <TableCell>
                {getValueIfExist(personOutlineRepresentativeContactInformation?.category)}
              </TableCell>
              <Th>직통전화번호</Th>
              <TableCell>
                <HookFormInput
                  name="directPhoneNumber"
                  onSubmit={onSubmit}
                  inputType={InputType.DIRECT_CONTACT}
                  disabled={readOnly}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>회사이메일</Th>
              <TableCell>
                <HookFormInput
                  name="companyEmail"
                  onSubmit={onSubmit}
                  inputType={InputType.EMAIL}
                  disabled={readOnly}
                />
              </TableCell>
              <Th>회사FAX번호</Th>
              <TableCell>
                <HookFormInput
                  name="companyFaxNumber"
                  onSubmit={onSubmit}
                  inputType={InputType.FAX}
                  disabled={readOnly}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>회사주소1</Th>
              <TableCell>
                {getValueIfExist(
                  personOutlineRepresentativeContactInformation?.addressList?.[0]?.address
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>회사주소2</Th>
              <TableCell>
                {getValueIfExist(
                  personOutlineRepresentativeContactInformation?.addressList?.[1]?.address
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </FormProvider>
  );
};
