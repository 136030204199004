import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyPersonDetailModalMutation } from '@front/src/features/affiliated-company/features/person/features/detail-modal/query/mutation';

export const affiliatedCompanyPersonDetailModalRepository = {
  usePersonDelete: () => {
    const { mutate } = affiliatedCompanyPersonDetailModalMutation.usePersonDelete();
    const callback = getNoOpCallback();
    return {
      run: (id: number) => {
        mutate(id, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
