import { useQuery } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';

const workBasicInfoQuery = {
  useGetOne: (dataId?: number, menuId?: number) => {
    const { data } = useQuery({
      queryKey: ['work', 'basic', dataId],
      queryFn: () => workWorkApi.getBasicInfo(dataId, menuId),
      suspense: true,
      enabled: !!dataId && !!menuId,
    });
    return {
      data,
    };
  },
};

export default workBasicInfoQuery;
