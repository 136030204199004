// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x_48XMk6mJkCtNhtHW5G{width:100%;display:flex;gap:100px}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/subject-review/features/experiment/components/code-table.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,SAAA","sourcesContent":[".container {\n  width: 100%;\n  display: flex;\n  gap: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `x_48XMk6mJkCtNhtHW5G`
};
export default ___CSS_LOADER_EXPORT___;
