import React, { useMemo } from 'react';
import { useProjectSalesInfoAbstractCollectionListLogic } from '@front/src/features/project-sales-info/features/abstract/features/collection/widgets/list/provider/useLogic';
import { ProjectSalesInfoAbstractCollectionListContext } from '@front/src/features/project-sales-info/features/abstract/features/collection/widgets/list/provider/context';

export { Provider as ProjectSalesInfoAbstractCollectionListProvider };

interface Props {
  children: React.ReactNode;
}

const Provider = ({ children }: Props) => {
  const { list } = useProjectSalesInfoAbstractCollectionListLogic();
  const value = useMemo(
    () => ({
      list,
    }),
    [list]
  );
  return (
    <ProjectSalesInfoAbstractCollectionListContext.Provider value={value}>
      {children}
    </ProjectSalesInfoAbstractCollectionListContext.Provider>
  );
};
