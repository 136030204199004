import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { ProjectExperimentInformationParameter } from '@front/src/features/project-sales-info/features/subject-review/features/modal/types/parameter';
import type { ProjectExperimentInformationView } from '@front/src/features/project-sales-info/features/subject-review/features/modal/types/view';

export { context as ProjectSalesInfoSubjectReviewModalSpecialWindContext };

interface State {
  detail?: ProjectExperimentInformationView;
  onUpdate: (params: ProjectExperimentInformationParameter) => void;
  isLoading: boolean;
}

const context = createContext<State>({
  onUpdate: noOp,
  isLoading: false,
});
