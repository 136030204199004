import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { AffiliatedCompanyOutlineWebHardContext } from '@front/src/features/affiliated-company/features/outline/features/web-hard/widgets/context';
import { useAffiliatedCompanyOutlineWebHardState } from '@front/src/features/affiliated-company/features/outline/features/web-hard/widgets/useState';
import { useAffiliatedCompanyDetailState } from '@front/src/features/affiliated-company/widgets/detail/useState';
import { useAffiliatedCompanyOutlineState } from '@front/src/features/affiliated-company/features/outline/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { ButtonGroup as AffiliatedCompanyOutlineWebHardButtonGroup };
const ButtonGroup = () => {
  const {
    h: { onCreate, onDelete },
  } = useContext(AffiliatedCompanyOutlineWebHardContext);
  const { id } = useAffiliatedCompanyDetailState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const { idList, setIdList } = useAffiliatedCompanyOutlineWebHardState(
    useShallow((state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }))
  );
  const handleDeleteClick = useCallback(() => {
    onDelete({ idList });
    setIdList([]);
  }, [onDelete, idList, setIdList]);
  const handleAddClick = useCallback(() => {
    onCreate(id);
  }, [onCreate, id]);
  const { readOnly } = useAffiliatedCompanyOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      <Button
        onClick={handleDeleteClick}
        disabled={readOnly}
      >
        선택삭제
      </Button>
      <Button
        onClick={handleAddClick}
        disabled={readOnly}
      >
        웹하드추가
      </Button>
    </>
  );
};
