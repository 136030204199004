import React, { useEffect } from 'react';
import { AccountingUploadSalesInfoExpectModal } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/components/modal';
import { useAccountingUploadSalesInfoExpectModalState } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/useState';
import { useShallow } from 'zustand/react/shallow';
import dayjs from 'dayjs';

export { Index as AccountingUploadSalesInfoExpectModalFeature };

const Index = () => {
  const { setMonth } = useAccountingUploadSalesInfoExpectModalState(
    useShallow((state) => ({
      setMonth: state.setMonth,
    }))
  );
  useEffect(() => {
    setMonth(dayjs(new Date()).month() + 1);
  }, [setMonth]);
  return <AccountingUploadSalesInfoExpectModal />;
};
