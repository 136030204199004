import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { projectSalesInfoCollectionPaymentHistoryApi } from '@front/src/features/project-sales-info/features/collection/features/payment-history/query/api';
import type { IdDeleteCollectionParams } from '@front/src/features/project-sales-info/features/collection/features/payment-history/types/parameter';
import { handleError } from '@front/src/utils';

export const projectSalesInfoCollectionPaymentHistoryMutation = {
  usePaymentHistoryDelete: (id: number) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: IdDeleteCollectionParams) =>
        projectSalesInfoCollectionPaymentHistoryApi.deletePaymentHistory(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'project-sales-info',
          'collection',
          'payment-history',
          id,
        ]);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
