import React from 'react';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import WorkApprovalTable from '@front/src/features/work/features/work/features/approval/components/Table';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { Box } from '@mui/material';
import workApprovalQuery from '@front/src/features/work/features/work/features/approval/query/query';
import dayjs from 'dayjs';
import { YY_MM_DD_HH_MM } from '@front/src/utils';

export default function WorkApprovalSection({ name, ...rest }: Readonly<SectionComponentProps>) {
  const { Layout, TitleWrap } = SectionWrapperUI;
  return (
    <Layout>
      <TitleWrap
        left={name}
        right={<TitleRight {...rest} />}
      />
      <WorkApprovalTable {...rest} />
    </Layout>
  );
}

function TitleRight({ sectionId, dataId, menuId }: SectionComponentProps) {
  const { TitleRightWrap } = SectionWrapperUI;
  const setLeftDrawerState = useGlobalNavBarState((state) => state.setLeftDrawerState);
  const setNoteId = useGlobalNavBarState((state) => state.setNoteId);
  const { data } = workApprovalQuery.useGetList({
    dataId,
    menuId,
    sectionId,
  });
  const onClick = () => {
    if (!dataId) return;
    sessionStorage.setItem('dataId', dataId.toString());
    setNoteId(0);
    setLeftDrawerState('approval');
  };
  return (
    <TitleRightWrap>
      {data?.temporary && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          임시저장 (
          {dayjs(data?.temporary.approvalDocument?.submissionDateTime).format(YY_MM_DD_HH_MM)})
        </Box>
      )}
      <ButtonBasicUI onClick={onClick}>결재 상신</ButtonBasicUI>
    </TitleRightWrap>
  );
}
