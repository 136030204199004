import { personBaseUrl } from '@front/src/features/affiliated-company/utils/constant';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanyPersonOutlineExtraCompanyCreateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  createPersonOutlineExtraCompany: (id?) => `${personBaseUrl}/${id}/extra-company`,
  getPersonOutlineExtraCompany: (id?) => `${personBaseUrl}/${id}/extra-company`,
  deletePersonOutlineExtraCompany: () => `${personBaseUrl}/extra-company`,
};

export const affiliatedCompanyExtraCompanyApi = {
  createPersonOutlineExtraCompany: async (
    params: AffiliatedCompanyPersonOutlineExtraCompanyCreateParameter,
    menuId
  ) => {
    await axios.post(url.createPersonOutlineExtraCompany(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  getPersonOutlineExtraCompany: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getPersonOutlineExtraCompany(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  deletePersonOutlineExtraCompany: async (
    params: AffiliatedCompanyIdListDeleteParameter,
    menuId
  ) => {
    await axios.delete(url.deletePersonOutlineExtraCompany(), {
      data: params,
      headers: getMenuIdHeaders(menuId),
    });
  },
};
