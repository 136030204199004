import React, { useEffect, useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { AffiliatedCompanyGiftHistoryTable } from '@front/src/features/affiliated-company/features/gift-history/components/gift-history-table';
import { useAffiliatedCompanyGiftHistoryLogic } from '@front/src/features/affiliated-company/features/gift-history/widgets/useLogic';
import { AffiliatedCompanyGiftHistoryContext } from '@front/src/features/affiliated-company/features/gift-history/widgets/context';
import { useShallow } from 'zustand/react/shallow';
import { useGetReadOnly } from '@front/src/hooks/useGetReadOnly';
import { useAffiliatedCompanyGiftHistoryState } from '@front/src/features/affiliated-company/features/gift-history/widgets/useState';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AffiliatedCompanyGiftHistoryWidget = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  const {
    d: { giftList },
    h: { setParameter },
    isLoading,
  } = useAffiliatedCompanyGiftHistoryLogic(menuId);
  const value = useMemo(
    () => ({
      d: { giftList },
      h: { setParameter },
      isLoading,
    }),
    [giftList, isLoading, setParameter]
  );
  const { setReadOnly } = useAffiliatedCompanyGiftHistoryState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  const { readOnly } = useGetReadOnly();
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <AffiliatedCompanyGiftHistoryContext.Provider value={value}>
      <Layout>
        <Header title={name} />
        <Body>
          <AffiliatedCompanyGiftHistoryTable />
        </Body>
      </Layout>
    </AffiliatedCompanyGiftHistoryContext.Provider>
  );
};

export default AffiliatedCompanyGiftHistoryWidget;
