import React from 'react';
import { TableBody, TableHead, TableRow } from '@mui/material';

import { OldTh, Table, OldTd } from '@front/layouts/Table';
import { ArticleItem } from '@front/src/components/article-item';
import type { OstRewardManagementDetailMonthDetailPaymentStatusView } from '@front/ost_reward/domian';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { convertMonthNumber } from '@front/ost_reward/view/detail/detail-status-table';

interface Props {
  paymentStatus: OstRewardManagementDetailMonthDetailPaymentStatusView;
}

export const DetailStatusTableRowDetailPaymentStatusTable = ({ paymentStatus }: Props) => {
  const { month } = useSelector(
    (root: RootState) => ({
      month: root.ostRewardMangement.month,
    }),
    shallowEqual
  );

  return (
    <ArticleItem title={<>{month}월 OST 상금 지급 현황</>}>
      <Table>
        <TableHead>
          <TableRow>
            <OldTh>년도</OldTh>
            <OldTh>월</OldTh>
            <OldTh>연간 총액</OldTh>
            <OldTh>당월 집행상금 (연간 비중)</OldTh>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <OldTd>{paymentStatus.year}</OldTd>
            <OldTd>{convertMonthNumber(paymentStatus.month)}</OldTd>
            <OldTd>{paymentStatus.annualTotal.toLocaleString()} 원</OldTd>
            <OldTd>{paymentStatus.monthlyTotal.toLocaleString()} 원</OldTd>
          </TableRow>
        </TableBody>
      </Table>
    </ArticleItem>
  );
};
