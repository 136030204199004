import { default as axios } from '@front/src/config/axios';
import { apiAminSectionRequestUrl, getMenuIdHeaders } from '@front/src/utils';
import type { AxiosResponse } from 'axios';
import type { HeaderHistoryGetParams } from '@front/src/features/header-history/types/parameter';
import type { HeaderHistoryView } from '@front/src/features/header-history/types/view';

export { api as headerHistoryApi };
const url = {
  getHistoryList: (id?: number) => `${apiAminSectionRequestUrl}/${id}/header-history`,
};

const api = {
  getHistoryList: async <T = HeaderHistoryView[]>(
    id?: number,
    params?: HeaderHistoryGetParams,
    authMenuId?
  ) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getHistoryList(id), {
      params,
      headers: getMenuIdHeaders(authMenuId),
    });
    return data;
  },
};
