import React, { memo, useMemo } from 'react';
import { TableBody, TableContainer } from '@mui/material';
import { Table } from '@front/layouts/Table';
import CircularProgress from '@front/components/CircularProgress';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useAffiliatedCompanyOutlineBankInfoLogic } from '@front/src/features/affiliated-company/features/outline/features/bank-info/widgets/useLogic';
import { AffiliatedCompanyOutlineBankInfoContext } from '@front/src/features/affiliated-company/features/outline/features/bank-info/widgets/context';
import { AffiliatedCompanyOutlineBankInfo } from '@front/src/features/affiliated-company/features/outline/features/bank-info/components/bank-info';
import { AffiliatedCompanyOutlineBankBookFile } from '@front/src/features/affiliated-company/features/outline/features/bank-info/components/file';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AffiliatedCompanyOutlineBankInfoWidget = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    d: { bankInfo, bankBookFile, bankNameOptionList },
    h: { onBankInfoUpdate, onBankBookFileUpdate },
  } = useAffiliatedCompanyOutlineBankInfoLogic(menuId);
  const value = useMemo(
    () => ({
      d: { bankInfo, bankBookFile, bankNameOptionList },
      h: { onBankInfoUpdate, onBankBookFileUpdate },
    }),
    [onBankInfoUpdate, bankInfo, onBankBookFileUpdate, bankBookFile, bankNameOptionList]
  );
  if (typeof bankInfo === 'undefined') {
    return <Spinner />;
  }
  return (
    <Layout>
      <Header title={name} />
      <Body>
        <TableContainer>
          <Table>
            <TableBody>
              <AffiliatedCompanyOutlineBankInfoContext.Provider value={value}>
                <AffiliatedCompanyOutlineBankInfo key={bankInfo?.accountNumber ?? Math.random()} />
                <AffiliatedCompanyOutlineBankBookFile />
              </AffiliatedCompanyOutlineBankInfoContext.Provider>
            </TableBody>
          </Table>
        </TableContainer>
      </Body>
    </Layout>
  );
};

const Spinner = memo(() => {
  const { Layout } = AddDeleteTable;
  return (
    <Layout height="290px">
      <CircularProgress size={30} />
    </Layout>
  );
});

Spinner.displayName = 'AffiliatedCompanyOutlineBankInfoWidgetSpinner';

export default AffiliatedCompanyOutlineBankInfoWidget;
