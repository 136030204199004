import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { projectSalesInfoEstimationCompetitorQuery } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/query/query';
import { projectSalesInfoEstimationCompetitorMutation } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/query/mutation';

export { repository as projectSalesInfoEstimationCompetitorRepository };

const repository = {
  useListGet: (id?: number) => {
    const { data, isLoading } = projectSalesInfoEstimationCompetitorQuery.useListGet(id);
    return {
      data,
      isLoading,
    };
  },
  useDelete: () => {
    const { mutate } = projectSalesInfoEstimationCompetitorMutation.useDelete();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
