import React from 'react';
import { Box } from '@mui/material';
import Button from 'layouts/Button';
import type { FormikContextType } from 'formik';
import type { Address } from 'components/AddressModal/domain';
import type { DefaultFunction } from 'type/Function';

interface Props {
  formik?: FormikContextType<any>;
  fieldName?: string | string[];
  addressValue: Address;
  detailAddress: string;
  onClose: DefaultFunction;
  setIsSaved: (boolean) => void;
  isSaved: boolean;
  onUpdate?: (params: { address: string; id?: number }) => void;
  id?: number;
}

function Footer({
  formik,
  fieldName,
  addressValue,
  detailAddress,
  onClose,
  setIsSaved,
  isSaved,
  onUpdate,
  id,
}: Props) {
  return (
    <Box
      sx={{
        width: '100%',
        margin: '20px 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button
        sx={{
          marginRight: '10px',
        }}
        onClick={() => {
          if (formik && fieldName) {
            if (Array.isArray(fieldName)) {
              fieldName.forEach((name) => {
                if (name.includes('address')) {
                  formik.setFieldValue(name, `${addressValue.roadAddr} ${detailAddress}`);
                } else {
                  formik.setFieldValue(name, addressValue.zipNo);
                }
              });
            } else {
              formik.setFieldValue(fieldName, `${addressValue.roadAddr} ${detailAddress}`);
            }
          }
          if (onUpdate) {
            onUpdate({
              address: `${addressValue.roadAddr} ${detailAddress}`,
              id: id,
            });
          }
          setIsSaved(!isSaved);
          onClose();
        }}
      >
        저장
      </Button>
      <Button
        shape="basic2"
        onClick={() => {
          setIsSaved(!isSaved);
          onClose();
        }}
      >
        취소
      </Button>
    </Box>
  );
}

export default Footer;
