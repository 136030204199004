import { Outlet } from 'react-router-dom';
import React from 'react';
import useSetMenuAndTab from '@front/src/hooks/useSetMenuAndTab';

import { Box } from '@mui/material';
import AffiliatedPersonDrawerFeature from '@front/src/features/affiliated-person/features/drawer';

const AffiliatedPersonFeature = () => {
  const { menuId } = useSetMenuAndTab();
  if (!menuId) return;
  return (
    <>
      <AffiliatedPersonDrawerFeature />
      <Box
        sx={{
          flex: 1,
        }}
      >
        <Outlet />
      </Box>
    </>
  );
};

export default AffiliatedPersonFeature;
