import { baseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import { toFormData } from '@front/services/api';
import type { AffiliatedCompanyOutlineBusinessRegistrationFileUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/number/features/business-registration/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  updateOutlineBusinessRegistrationFile: (id?) => `${baseUrl}/${id}/business-file`,
};

export const affiliatedCompanyBusinessRegistrationApi = {
  updateOutlineBusinessRegistrationFile: async (
    params: AffiliatedCompanyOutlineBusinessRegistrationFileUpdateParameter,
    menuId
  ) => {
    await axios.put(
      url.updateOutlineBusinessRegistrationFile(params.id),
      toFormData({
        file: params.businessRegistrationNumberFile,
      }),
      {
        headers: getMenuIdHeaders(menuId),
      }
    );
  },
};
