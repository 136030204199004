import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { TargetPage } from '@front/navigate/domain';
import type { RootState } from '@front/services/reducer';
import { proposalAction } from '@front/ost_proposal/action';
import EstimateRank from '@front/ost_proposal/view/detail/EstimateRank';
import { ProposalGrade, ProposalStatus } from '@front/ost_proposal/domain';
import type { ProposalGradeUpdateParameter } from '@front/ost_proposal/parameter';

interface Props {
  title: string;
  readonly?: boolean;
  targetPage: TargetPage;
}

const EstimateRankService = ({ title, targetPage }: Props) => {
  const dispatch = useDispatch();
  const { status, myGrade, resultGrade } = useSelector(
    (root: RootState) => ({
      myGrade: root.proposal.gradeByContributor,
      resultGrade: root.proposal.detail!.grade,
      status: root.proposal.detail!.status,
    }),
    shallowEqual
  );

  const updateGrade = useCallback(
    (params: ProposalGradeUpdateParameter) => dispatch(proposalAction.updateGrade(params)),
    [dispatch]
  );

  const onClick = useCallback(
    (e) => {
      if (ProposalStatus.WRITING === status) {
        if (e.target.ariaValueText) {
          updateGrade({ grade: ProposalGrade[e.target.ariaValueText] });
        } else {
          updateGrade({ grade: ProposalGrade[e.target.innerText.toUpperCase()] });
        }
      }
    },
    [status, updateGrade]
  );
  if (
    [
      ProposalStatus.WRITING,
      ProposalStatus.SUBMISSION,
      ProposalStatus.ESTIMATING,
      ProposalStatus.APPROVING,
    ].includes(status)
  ) {
    if (targetPage === TargetPage.PROPOSAL) {
      return (
        <EstimateRank
          title={title}
          onClick={onClick}
          grade={myGrade}
        />
      );
    }
    return (
      <EstimateRank
        title={title}
        grade={myGrade}
      />
    );
  }
  return (
    <EstimateRank
      title={title}
      grade={resultGrade}
    />
  );
};

export default EstimateRankService;
