import React, { useCallback } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { shallow } from 'zustand/shallow';
import { useAccountingUploadSalesInfoGoalModalState } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/useState';
import { AccountingUploadSalesInfoGoalModalListWidget } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/widgets/list/widget';

export { Modal as AccountingUploadSalesInfoGoalModal };

const Modal = () => {
  const { isOpen, setIsOpen } = useAccountingUploadSalesInfoGoalModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      isOpen: state.isOpen,
    }),
    shallow
  );
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  return (
    <ModalLayout
      open={isOpen}
      title="목표 등록/수정"
      onClose={handleClose}
      width="80dvw"
      height="40dvh"
    >
      <AccountingUploadSalesInfoGoalModalListWidget />
    </ModalLayout>
  );
};
