import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import type { AffiliatedCompanyPersonCharacterCharacterUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/character/types/parameter';
import { affiliatedCompanyPersonCharacterCharacterApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/character/query/api';

export const affiliatedCompanyPersonCharacterCharacterMutation = {
  usePersonCharacterCharacterCreate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
      (id: number) =>
        affiliatedCompanyPersonCharacterCharacterApi.createPersonCharacterCharacter(id, menuId),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            'affiliated-company',
            'person',
            'character',
            'character',
          ]);
        },
        onError: async (error) => handleError(error, alert),
      }
    );
    return {
      mutate,
    };
  },
  usePersonCharacterCharacterDelete: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
      (params: AffiliatedCompanyIdListDeleteParameter) =>
        affiliatedCompanyPersonCharacterCharacterApi.deletePersonCharacterCharacter(params, menuId),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            'affiliated-company',
            'person',
            'character',
            'character',
          ]);
        },
        onError: async (error) => handleError(error, alert),
      }
    );
    return {
      mutate,
    };
  },
  usePersonCharacterCharacterUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
      (params: AffiliatedCompanyPersonCharacterCharacterUpdateParameter) =>
        affiliatedCompanyPersonCharacterCharacterApi.updatePersonCharacterCharacter(params, menuId),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            'affiliated-company',
            'person',
            'character',
            'character',
          ]);
        },
        onError: async (error) => handleError(error, alert),
      }
    );
    return {
      mutate,
    };
  },
};
