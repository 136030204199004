import { default as axios } from '@front/src/config/axios';
import { bidURLAPI } from '@front/src/features/project-sales-info/features/bid/utils/constant';
import type { IdDeleteParams } from '@front/src/features/project-sales-info/features/bid/features/bid-result/types/parameter';

const url = {
  getBidResultList: (id: number) => `${bidURLAPI}/${id}/bid-result`,
  deleteBidResult: () => `${bidURLAPI}/bid-result`,
};

export const projectSalesInfoBidResultApi = {
  getBidResultList: async (id: number) => {
    const { data } = await axios.get(url.getBidResultList(id));
    return data;
  },
  deleteBidResult: async (params: IdDeleteParams) => {
    await axios.delete(url.deleteBidResult(), { data: params });
  },
};
