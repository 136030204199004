import { useQuery } from 'react-query';
import { personalSettingsModalApi } from '@front/src/features/personal-settings-modal/api/api';

export { query as personalSettingsModalUISettingsHeaderSettingsHistoryQuery };

const query = {
  useGetHeaderHistoryList: (id: number, authMenuId) => {
    const { data } = useQuery({
      queryKey: [
        'personal-settings-modal',
        'ui-settings',
        'header-settings',
        'header',
        id,
        'history',
      ],
      queryFn: () => personalSettingsModalApi.getHeaderHistoryList(id, authMenuId),
      enabled: !!id,
    });
    return {
      data,
    };
  },
};
