import { create } from 'zustand';

interface State {
  isMobileNotificationModalOpen: boolean;
  setIsMobileNotificationModalOpen: () => void;
}

export { useState as useUserMobileNotificationState };

const useState = create<State>((set) => ({
  isMobileNotificationModalOpen: false,
  setIsMobileNotificationModalOpen: () =>
    set((state) => ({ isMobileNotificationModalOpen: !state.isMobileNotificationModalOpen })),
}));
