import { useQuery } from 'react-query';
import type { AffiliatedPersonCompanyView } from '@front/src/features/affiliated-person/types/view';
import { affiliatedPersonApi } from '@front/src/features/affiliated-person/api/api';

export { query as affiliatedPersonAppBarCompanySelectQuery };

const query = {
  useListGet: (params) => {
    const { data, remove } = useQuery<AffiliatedPersonCompanyView[]>({
      queryFn: () => affiliatedPersonApi.getAffiliatedCompanyList(params),
      queryKey: ['company-select-modal', 'list', params],
      suspense: true,
    });
    return {
      data,
      remove,
    };
  },
};
