import React from 'react';
import useNoteStore from '@front/src/features/note/useState';
import { useShallow } from 'zustand/react/shallow';
import noteQuery from '@front/src/features/note/query/query';
import noteMutation from '@front/src/features/note/query/mutation';
import useIdGroup from '@front/src/features/note/hooks/useIdGroup';
import type { UserId } from '@front/user/domain';
import NoteUserSelectorModal from '@front/src/features/note/components/NoteUserSelectorModal';

interface Props {
  noteId: number;
}

export default function NoteUserSelectorModalWrapper({ noteId }: Props) {
  const { isUserSelectorOpen, selectedUserList, setSelectedUserList, setIsUserSelectorOpen } =
    useNoteStore(
      useShallow((state) => ({
        isUserSelectorOpen: state.isUserSelectorOpen,
        selectedUserList: state.selectedUserList,
        setSelectedUserList: state.setSelectedUserList,
        setIsUserSelectorOpen: state.setIsUserSelectorOpen,
      }))
    );

  const { menuId, dataId } = useIdGroup();

  const { data: reviewerList } = noteQuery.useGetAuthUserList({ menuId });
  const { data: reviewerDetail } = noteQuery.useGetReviewerDetail(noteId);
  const { mutate: updateReview } = noteMutation.useUpdateReview(reviewerDetail?.id ?? 0);
  const { mutate: createReview } = noteMutation.useCreateReview(noteId);

  const isUpdate = reviewerDetail?.reviewDate;

  return (
    <>
      {isUserSelectorOpen && reviewerList && (
        <NoteUserSelectorModal
          key={Math.random()}
          multi={true}
          onChange={(value, date, addList, deleteList) => {
            setSelectedUserList(value as UserId[]);
            isUpdate
              ? updateReview({
                  reviewDate: date,
                  addReviewerInfoIdList: addList ?? [],
                  deleteReviewerInfoIdList: deleteList ?? [],
                })
              : createReview({
                  reviewDate: date,
                  reviewerInfoIdList: value as number[],
                  menuId,
                  dataId,
                  targetId: noteId,
                });
          }}
          fixedList={reviewerList?.map((item) => item.id) ?? []}
          value={reviewerDetail?.reviewerList?.map((item) => item.reviewerId) as UserId[]}
          reviewerDetail={reviewerDetail}
        />
      )}
    </>
  );
}
