// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AxGNH0MAWhYCvLu2Dy7S{display:flex;align-items:center;justify-content:center;gap:12px;margin-top:20px}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/contract/features/history/features/modal/components/modal-button-group.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CACA,eAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 12px;\n  margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AxGNH0MAWhYCvLu2Dy7S`
};
export default ___CSS_LOADER_EXPORT___;
