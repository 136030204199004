import { useMutation, useQueryClient } from 'react-query';
import { useCustomDialog } from '@front/src/features/dialog';
import type { ProjectReviewSavePaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/save-history/types/parameter';
import { projectSalesInfoCollectionSaveHistoryApi } from '@front/src/features/project-sales-info/features/collection/features/save-history/query/api';
import { handleError } from '@front/src/utils';

export const projectSalesInfoCollectionSaveHistoryMutation = {
  useSaveHistoryUpdate: (id: number) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectReviewSavePaymentHistoryParams) =>
        projectSalesInfoCollectionSaveHistoryApi.updateSaveHistory(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'project-sales-info',
          'collection',
          'save-history',
          id,
        ]);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
