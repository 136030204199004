import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import type { RootState } from '@front/services/reducer';
import type { ProposalMemoChangeParameter } from '@front/ost_proposal/parameter';
import { proposalAction } from '@front/ost_proposal/action';
import type { ProposalMemoId } from '@front/ost_proposal/domain';
import ProposalMemoList from '@front/ost_proposal/view/memo/List';

interface Props {
  formHeight: number;
}

export default function ProposalListService({ formHeight }: Props) {
  const dispatch = useDispatch();
  const detail = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const memoLoading = useSelector((root: RootState) => root.proposal.memoLoading, shallowEqual);
  const [memoList] = useSelector((root: RootState) => [root.proposal.memoList], shallowEqual);
  const onChange = useCallback(
    (params: ProposalMemoChangeParameter) => dispatch(proposalAction.changeMemo(params)),
    [dispatch]
  );
  const onDelete = useCallback(
    (id: ProposalMemoId) => dispatch(proposalAction.deleteMemo(id)),
    [dispatch]
  );
  const formik = useFormik<ProposalMemoChangeParameter>({
    initialValues: {} as ProposalMemoChangeParameter,
    onSubmit: (values) => {
      onChange(values);
      formik.setValues({} as ProposalMemoChangeParameter);
    },
  });

  return (
    <FormikProvider value={formik}>
      <ProposalMemoList
        list={memoList}
        loading={memoLoading}
        login={detail}
        onChange={onChange}
        onDelete={onDelete}
        formHeight={formHeight}
      />
    </FormikProvider>
  );
}
