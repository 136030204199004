import { shallow } from 'zustand/shallow';
import { projectSelectorModalRepository } from '@front/src/features/project-selector-modal/repository';
import { useProjectSelectorModalFormState } from '@front/src/features/project-selector-modal/widgets/body/useState';

export { useLogic as useProjectSelectorModalBodyLogic };
const useLogic = () => {
  const { parameter } = useProjectSelectorModalFormState(
    (state) => ({
      parameter: state.parameter,
    }),
    shallow
  );
  const { data: projectList, isLoading } =
    projectSelectorModalRepository.useProjectListGet(parameter);
  const d = {
    projectList,
  };
  return {
    d,
    isLoading,
  };
};
