import React from 'react';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoContractReviewHistoryModalState } from '@front/src/features/project-sales-info/features/contract/features/review-history/features/modal/widgets/useState';
import { ProjectSalesInfoContractReviewHistoryModalWidget } from '@front/src/features/project-sales-info/features/contract/features/review-history/features/modal/widgets/widget';

export { ContractReviewHistoryModal as ProjectSalesInfoContractReviewHistoryModal };

const ContractReviewHistoryModal = () => {
  const { isOpen } = useProjectSalesInfoContractReviewHistoryModalState(
    (state) => ({
      isOpen: state.isOpen,
    }),
    shallow
  );
  if (!isOpen) {
    return <></>;
  }
  return <ProjectSalesInfoContractReviewHistoryModalWidget />;
};
