import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import { projectSalesInfoSubjectReviewShapeRatioApi } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/query/api';
import type { ProjectAspectRatioExaminationParameter } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/types/parameter';
import { useCustomDialog } from '@front/src/features/dialog';

export const projectSalesInfoSubjectReviewShapeRatioMutation = {
  useCreate: () => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => projectSalesInfoSubjectReviewShapeRatioApi.create(id),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'subject-info', 'shape-ratio', 'list'],
        });
        return data;
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useUpdate: () => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectAspectRatioExaminationParameter) =>
        projectSalesInfoSubjectReviewShapeRatioApi.update(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'subject-info', 'shape-ratio', 'list'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'subject-review', 'experiment'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'header'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
