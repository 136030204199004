import React from 'react';
import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import { ColorPalette } from '@front/assets/theme';

interface Props extends Omit<ButtonProps, 'size'> {
  shape?: 'primary' | 'secondary' | 'ghost' | 'tertiary' | 'quaternary';
  size?: 'xLarge' | 'large' | 'medium' | 'small';
}

export default function ButtonIconUI({
  children,
  size = 'medium',
  shape = 'primary',
  ...props
}: Props) {
  if (shape === 'primary' && size === 'medium')
    return <PrimaryMediumButtonIcon {...props}>{children}</PrimaryMediumButtonIcon>;
  if (shape === 'primary' && size === 'small')
    return <PrimarySmallButtonIcon {...props}>{children}</PrimarySmallButtonIcon>;
  if (shape === 'secondary' && size === 'medium')
    return <SecondaryMediumButtonIcon {...props}>{children}</SecondaryMediumButtonIcon>;
  if (shape === 'secondary' && size === 'small')
    return <SecondarySmallButtonIcon {...props}>{children}</SecondarySmallButtonIcon>;
  if (shape === 'ghost' && size === 'medium')
    return <GhostMediumButtonIcon {...props}>{children}</GhostMediumButtonIcon>;
  if (shape === 'ghost' && size === 'small')
    return <GhostSmallButtonIcon {...props}>{children}</GhostSmallButtonIcon>;
  if (shape === 'tertiary' && size === 'large')
    return <TertiaryLargeButtonIcon {...props}>{children}</TertiaryLargeButtonIcon>;
  if (shape === 'tertiary' && size === 'medium')
    return <TertiaryMediumButtonIcon {...props}>{children}</TertiaryMediumButtonIcon>;
  if (shape === 'quaternary' && size === 'xLarge')
    return <QuaternaryXLargeButtonIcon {...props}>{children}</QuaternaryXLargeButtonIcon>;
  if (shape === 'quaternary' && size === 'large')
    return <QuaternaryLargeButtonIcon {...props}>{children}</QuaternaryLargeButtonIcon>;
  if (shape === 'quaternary' && size === 'medium')
    return <QuaternaryMediumButtonIcon {...props}>{children}</QuaternaryMediumButtonIcon>;
  return <></>;
}

function PrimaryMediumButtonIcon({ children, sx = {}, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        width: '32px',
        minWidth: 'auto',
        height: '32px',
        padding: '0px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.4rem',
        fontWeight: '900',
        lineHeight: 'normal',
        color: ColorPalette.greyscale.white,
        background: ColorPalette.main.main_primary,
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          background: ColorPalette.main.main_hover,
          boxShadow: 'none',
        },
        '&:active': {
          background: ColorPalette.main.main_hover,
          boxShadow: 'none',
        },
        '&:focus-visible': {
          background: ColorPalette.main.main_primary,
          boxShadow: 'none',
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
        },
        '&:disabled': {
          background: ColorPalette.greyscale.disabled,
          boxShadow: 'none',
        },

        '& svg': {
          fontSize: '1.4rem',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

function PrimarySmallButtonIcon({ children, sx = {}, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        width: '26px',
        minWidth: 'auto',
        height: '26px',
        padding: '0px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.2rem',
        fontWeight: '900',
        lineHeight: 'normal',
        color: ColorPalette.greyscale.white,
        background: ColorPalette.main.main_primary,
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          background: ColorPalette.main.main_hover,
          boxShadow: 'none',
        },
        '&:active': {
          background: ColorPalette.main.main_hover,
          boxShadow: 'none',
        },
        '&:focus-visible': {
          background: ColorPalette.main.main_primary,
          boxShadow: 'none',
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
        },
        '&:disabled': {
          background: ColorPalette.greyscale.disabled,
          boxShadow: 'none',
        },

        '& svg': {
          fontSize: '1.2rem',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

function SecondaryMediumButtonIcon({ children, sx = {}, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        width: '32px',
        minWidth: 'auto',
        height: '32px',
        padding: '0px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.4rem',
        fontWeight: '900',
        lineHeight: 'normal',
        color: ColorPalette.greyscale.white,
        background: ColorPalette.main.main_secondary,
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          background: ColorPalette.main.main_hover,
          boxShadow: 'none',
        },
        '&:active': {
          background: ColorPalette.main.main_hover,
          boxShadow: 'none',
        },
        '&:focus-visible': {
          background: ColorPalette.main.main_secondary,
          boxShadow: 'none',
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
        },
        '&:disabled': {
          background: ColorPalette.greyscale.disabled,
          boxShadow: 'none',
        },

        '& svg': {
          fontSize: '1.4rem',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

function SecondarySmallButtonIcon({ children, sx = {}, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        width: '26px',
        minWidth: 'auto',
        height: '26px',
        padding: '0px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.2rem',
        fontWeight: '900',
        lineHeight: 'normal',
        color: ColorPalette.greyscale.white,
        background: ColorPalette.main.main_secondary,
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          background: ColorPalette.main.main_hover,
          boxShadow: 'none',
        },
        '&:active': {
          background: ColorPalette.main.main_hover,
          boxShadow: 'none',
        },
        '&:focus-visible': {
          background: ColorPalette.main.main_secondary,
          boxShadow: 'none',
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
        },
        '&:disabled': {
          background: ColorPalette.greyscale.disabled,
          boxShadow: 'none',
        },

        '& svg': {
          fontSize: '1.2rem',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

function GhostMediumButtonIcon({ children, sx = {}, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        width: '32px',
        minWidth: 'auto',
        height: '32px',
        padding: '0px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.4rem',
        fontWeight: '600',
        lineHeight: 'normal',
        color: ColorPalette.main.main_primary,
        background: 'none',
        border: `1px solid ${ColorPalette.main.main_primary}`,
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          background: 'none',
          border: `1px solid ${ColorPalette.main.main_hover}`,
          boxShadow: 'none',
        },
        '&:active': {
          background: 'none',
          border: `1px solid ${ColorPalette.main.main_hover}`,
          boxShadow: 'none',
        },
        '&:focus-visible': {
          background: 'none',
          border: `1px solid ${ColorPalette.main.main_primary}`,
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
          boxShadow: 'none',
        },
        '&:disabled': {
          color: ColorPalette.greyscale.disabled,
          background: 'none',
          border: `1px solid ${ColorPalette.greyscale.disabled}`,
          boxShadow: 'none',
        },

        '& svg': {
          fontSize: '1.4rem',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

function GhostSmallButtonIcon({ children, sx = {}, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        width: '26px',
        minWidth: 'auto',
        height: '26px',
        padding: '0px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.2rem',
        fontWeight: '600',
        lineHeight: 'normal',
        color: ColorPalette.main.main_primary,
        background: 'none',
        border: `1px solid ${ColorPalette.main.main_primary}`,
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          background: 'none',
          border: `1px solid ${ColorPalette.main.main_hover}`,
          boxShadow: 'none',
        },
        '&:active': {
          background: 'none',
          border: `1px solid ${ColorPalette.main.main_hover}`,
          boxShadow: 'none',
        },
        '&:focus-visible': {
          background: 'none',
          border: `1px solid ${ColorPalette.main.main_primary}`,
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
          boxShadow: 'none',
        },
        '&:disabled': {
          color: ColorPalette.greyscale.disabled,
          background: 'none',
          border: `1px solid ${ColorPalette.greyscale.disabled}`,
          boxShadow: 'none',
        },

        '& svg': {
          fontSize: '1.2rem',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

function TertiaryLargeButtonIcon({ children, sx = {}, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        width: '18px',
        minWidth: 'auto',
        height: '18px',
        padding: '0px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.6rem',
        fontWeight: '900',
        lineHeight: 'normal',
        color: ColorPalette.main.main_primary,
        background: 'none',
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          color: ColorPalette.main.main_hover,
          background: 'none',
          boxShadow: 'none',
        },
        '&:active': {
          color: ColorPalette.main.main_hover,
          background: 'none',
          boxShadow: 'none',
        },
        '&:focus-visible': {
          color: ColorPalette.main.main_primary,
          background: 'none',
          boxShadow: 'none',
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
        },
        '&:disabled': {
          color: ColorPalette.greyscale.disabled,
          background: 'none',
          boxShadow: 'none',
        },

        '& svg': {
          fontSize: '1.6rem',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

function TertiaryMediumButtonIcon({ children, sx = {}, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        width: '16px',
        minWidth: 'auto',
        height: '16px',
        padding: '0px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.4rem',
        fontWeight: '900',
        lineHeight: 'normal',
        color: ColorPalette.main.main_primary,
        background: 'none',
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          color: ColorPalette.main.main_hover,
          background: 'none',
          boxShadow: 'none',
        },
        '&:active': {
          color: ColorPalette.main.main_hover,
          background: 'none',
          boxShadow: 'none',
        },
        '&:focus-visible': {
          color: ColorPalette.main.main_primary,
          background: 'none',
          boxShadow: 'none',
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
        },
        '&:disabled': {
          color: ColorPalette.greyscale.disabled,
          background: 'none',
          boxShadow: 'none',
        },

        '& svg': {
          fontSize: '1.4rem',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

function QuaternaryXLargeButtonIcon({ children, sx = {}, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        width: '22px',
        minWidth: 'auto',
        height: '22px',
        padding: '0px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '2.0rem',
        fontWeight: '900',
        lineHeight: 'normal',
        color: ColorPalette.background.bg07,
        background: 'none',
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          color: ColorPalette.main.main_hover,
          background: 'none',
          boxShadow: 'none',
        },
        '&:active': {
          color: ColorPalette.main.main_hover,
          background: 'none',
          boxShadow: 'none',
        },
        '&:focus-visible': {
          color: ColorPalette.greyscale.text_secondary,
          background: 'none',
          boxShadow: 'none',
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
        },
        '&:disabled': {
          color: ColorPalette.greyscale.disabled,
          background: 'none',
          boxShadow: 'none',
        },

        '& svg': {
          fontSize: '2.0rem',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

function QuaternaryLargeButtonIcon({ children, sx = {}, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        width: '18px',
        minWidth: 'auto',
        height: '18px',
        padding: '0px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.6rem',
        fontWeight: '900',
        lineHeight: 'normal',
        color: ColorPalette.background.bg07,
        background: 'none',
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          color: ColorPalette.main.main_hover,
          background: 'none',
          boxShadow: 'none',
        },
        '&:active': {
          color: ColorPalette.main.main_hover,
          background: 'none',
          boxShadow: 'none',
        },
        '&:focus-visible': {
          color: ColorPalette.greyscale.text_secondary,
          background: 'none',
          boxShadow: 'none',
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
        },
        '&:disabled': {
          color: ColorPalette.greyscale.disabled,
          background: 'none',
          boxShadow: 'none',
        },

        '& svg': {
          fontSize: '1.6rem',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

function QuaternaryMediumButtonIcon({ children, sx = {}, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        display: 'inline-flex',
        width: '16px',
        minWidth: 'auto',
        height: '16px',
        padding: '0px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.4rem',
        fontWeight: '900',
        lineHeight: 'normal',
        color: ColorPalette.background.bg07,
        background: 'none',
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          color: ColorPalette.main.main_hover,
          background: 'none',
          boxShadow: 'none',
        },
        '&:active': {
          color: ColorPalette.main.main_hover,
          background: 'none',
          boxShadow: 'none',
        },
        '&:focus-visible': {
          color: ColorPalette.greyscale.text_secondary,
          background: 'none',
          boxShadow: 'none',
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
        },
        '&:disabled': {
          color: ColorPalette.greyscale.disabled,
          background: 'none',
          boxShadow: 'none',
        },

        '& svg': {
          fontSize: '1.4rem',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}
