import React from 'react';
import Depth1Menu from '@front/src/features/global-nav-bar/widgets/list/components/Depth1Menu';
import globalNavBarRepository from '@front/src/features/global-nav-bar/repository/repository';

export const GlobalNavBarList = () => {
  const { data: menuList } = globalNavBarRepository.useListGet();
  return (
    <>
      {menuList?.map((item) => {
        if (item.depth === null) return;
        return (
          <Depth1Menu
            key={item.id}
            {...item}
          />
        );
      })}
    </>
  );
};
