import React from 'react';
import ButtonIconUI from '@front/src/components/components-with-design/component/ButtonIconUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import PopoverUI from '@front/src/components/components-with-design/component/PopoverUI';

interface Props {}

export default function ButtonIconFileInfo({}: Readonly<Props>) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  return (
    <Box>
      <ButtonIconUI
        shape="tertiary"
        size="medium"
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      >
        <FontAwesomeIcon icon="circle-info" />
      </ButtonIconUI>
      <PopoverUI
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Box
          sx={{
            p: '5px',
          }}
        >
          <Box>첨부 가능한 파일</Box>
          <Box>1. 문서 파일</Box>
          <Box>- PDF: .pdf</Box>
          <Box>- 워드 문서: .doc, .docx</Box>
          <Box>- 엑셀 문서: .xls, .xlsx</Box>
          <Box>- 파워포인트 문서: .ppt, .pptx</Box>
          <Box>- 텍스트 파일: .txt</Box>
          <Box>- 리치 텍스트 파일: .rtf</Box>
          <Box>2. 이미지 파일</Box>
          <Box>- JPEG: .jpg, .jpeg</Box>
          <Box>- PNG: .png</Box>
          <Box>- GIF: .gif</Box>
          <Box>- BMP: .bmp</Box>
          <Box>- TIFF: .tiff</Box>
          <Box>3. 압축 파일</Box>
          <Box>- ZIP: .zip</Box>
          <Box>- RAR: .rar</Box>
          <Box>- 7-Zip: .7z 4. 오디오 파일</Box>
          <Box>- MP3: .mp3</Box>
          <Box>- WAV: .wav</Box>
          <Box>- AAC: .aac</Box>
          <Box>5. 비디오 파일</Box>
          <Box>- MP4: .mp4</Box>
          <Box>- AVI: .avi</Box>
          <Box>- MOV: .mov</Box>
          <Box>- WMV: .wmv</Box>
        </Box>
      </PopoverUI>
    </Box>
  );
}
