import type { ReactNode } from 'react';
import React from 'react';
import classes from '@front/src/components/layout/modal/body.module.scss';

export { Body as ModalLayoutBody };

interface Props {
  children: ReactNode;
}

const Body = ({ children }: Props) => <div className={classes.container}>{children}</div>;
