import { Box } from '@mui/material';
import { FormikContext } from 'formik';
import React, { useCallback, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Button from '@front/layouts/Button';
import useDialog from '@front/dialog/hook';
import ModalLayout from '@front/layouts/ModalLayout';
import type { RootState } from '@front/services/reducer';
import type { ProposalApprovalDocumentVO, ProposalApprovalVO } from '@front/ost_proposal/domain';
import { ProposalStatus } from '@front/ost_proposal/domain';
import { ApprovalList } from '@front/ost_proposal/view/modal/approval/table/List';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';

interface Props {
  open: boolean;
  onClose: () => void;
  approvalList: ProposalApprovalVO[];
  approvalDocument: ProposalApprovalDocumentVO;
}

export { Index as ApprovalModal };

const Index = ({ open, onClose, approvalList, approvalDocument }: Props) => {
  const dispatch = useDispatch();

  const formik = useContext(FormikContext);
  const status = useSelector((root: RootState) => root.proposal.detail!.status, shallowEqual);
  const { confirm } = useDialog();

  const onSubmit = useCallback(() => {
    if (typeof formik.values.result === 'undefined' || typeof formik.values.id === 'undefined') {
      dispatch(
        snackbarAction.show({
          message: '결재에 필요한 모든 값을 입력해주세요',
          severity: SnackbarSeverityType.warning,
        })
      );
      return;
    }
    formik.handleSubmit();
  }, [formik]);

  return (
    <ModalLayout
      width={1200}
      height={400}
      open={open}
      onClose={onClose}
      title={'결재창'}
      children={
        <ApprovalList
          approvalList={approvalList}
          approvalDocument={approvalDocument}
        />
      }
      footer={
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            columnGap: '10px',
          }}
        >
          {status === ProposalStatus.APPROVING && (
            <Button
              shape="basic1"
              onClick={() => {
                confirm({
                  children: '결재 하시겠습니까?',
                  afterConfirm: () => {
                    onSubmit();
                  },
                  confirmText: '확인',
                });
              }}
            >
              결재
            </Button>
          )}

          <Button
            shape="basic3"
            onClick={onClose}
          >
            닫기
          </Button>
        </Box>
      }
    />
  );
};
