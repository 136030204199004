import type { ReactNode } from 'react';
import React from 'react';
import classes from '@front/src/components/layout/add-delete-table/help.module.scss';

export { Help as AddDeleteTableHelp };

interface Props {
  children: ReactNode;
  style?: React.CSSProperties;
}

const Help = ({ children, style }: Props) => (
  <div
    style={style}
    className={classes.container}
  >
    {children}
  </div>
);
