import type { CSSProperties } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useController } from 'react-hook-form';
import Input from '@front/layouts/Input';
import { useInput } from '@front/src/components/input-with-hook-form/useInput';
import { useExtraInput } from '@front/src/components/input-with-hook-form/useExtraInput';
import { onChangeValidator } from '@front/src/components/input-with-hook-form/onChangeValidator';
import type { InputType } from '@front/src/features/affiliated-company/types/domain';
import { ColorPalette } from '@front/assets/theme';
import { Box } from '@mui/material';

export { HookFormInput };

interface Props {
  name: string;
  onSubmit?: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  onBlur?: (value: string) => void;
  placeholder?: string;
  multiline?: boolean;
  allowMultiline?: boolean;
  type?: string;
  inputType?: InputType;
  className?: string;
  width?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  readOnly?: boolean;
  openModal?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  sx?: CSSProperties;
  onPressChange?: () => void;
  defaultValue?: string;
  maxRows?: number;
  disabled?: boolean;
}

const HookFormInput = ({
  name,
  onSubmit,
  onBlur,
  width,
  type = 'text',
  inputType,
  autoFocus,
  openModal = false,
  required,
  onPressChange,
  defaultValue,
  sx,
  ...rest
}: Props) => {
  const { control, validateString, validateNumber } = useInput({
    type,
    name,
  });
  const validateInput = useExtraInput(inputType);
  const {
    field: { value, onChange },
    formState: { defaultValues },
  } = useController({ name, control, rules: { required } });
  const currentVal = defaultValues?.[name];

  const handleChange = useCallback(
    (e) => {
      const newValue = onChangeValidator(e, value, inputType);
      onChange({
        ...e,
        target: { ...e.target, value: newValue },
      });
      validateString(e, currentVal);
      onPressChange?.();
    },
    [currentVal, validateString, inputType, onPressChange, value, onChange]
  );

  const handleBlur = useCallback(
    async (e) => {
      onBlur?.(value);
      const isPositive = validateNumber(e, value, currentVal);
      const isValid = validateInput(value);
      if (value === currentVal) return;
      if (onSubmit && isPositive && isValid) {
        await onSubmit();
      }
    },
    [onBlur, onSubmit, currentVal, value, validateNumber, validateInput]
  );
  const sxColor = useMemo(() => {
    if (!defaultValue) return;
    const isSame = value === defaultValue;
    return {
      color: isSame ? ColorPalette._9b9ea4 : ColorPalette._252627,
    };
  }, [defaultValue, value]);
  return (
    <Box sx={{ width }}>
      <Input
        autoFocus={autoFocus}
        type={type}
        variant="outlined"
        value={value ?? ''}
        onChange={handleChange}
        onBlur={handleBlur}
        openModal={openModal}
        sx={{
          ...sx,
          ...sxColor,
        }}
        {...rest}
      />
    </Box>
  );
};
