import { useShallow } from 'zustand/react/shallow';
import { useEffect } from 'react';
import { useAffiliatedPersonDetailState } from '@front/src/features/affiliated-person/features/detail/useState';
import { useAffiliatedPersonSalesActivityState } from '@front/src/features/affiliated-person/features/sales-activity/useState';
import type { AffiliatedPersonSalesActivityConvertedView } from '@front/src/features/affiliated-person/features/sales-activity/types/view';
import { affiliatedPersonSalesActivityRepository } from '@front/src/features/affiliated-person/features/sales-activity/repository/repository';

export { useLogic as useAffiliatedPersonSalesActivityListLogic };

const useLogic = () => {
  const { id } = useAffiliatedPersonDetailState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const { setList } = useAffiliatedPersonSalesActivityState(
    useShallow((state) => ({
      setList: state.setList,
    }))
  );
  const {
    data: list,
  }: {
    data: AffiliatedPersonSalesActivityConvertedView[];
  } = affiliatedPersonSalesActivityRepository.useListGet(id!);
  useEffect(() => {
    if (typeof list === 'undefined') return;
    const convertedList: AffiliatedPersonSalesActivityConvertedView[] = [];
    const activityIdListBySet = Array.from(new Set(list.map((aa) => aa.activity.id)));
    list.forEach((target) => {
      const idListFromSameActivity = list
        .filter((aa) => aa.activity.id === target.activity.id)
        .map((aa) => aa.id);
      const idListFromSameActivityUser = list
        .filter((aa) => aa.activityUser.id === target.activityUser.id)
        .map((aa) => aa.id);
      const minIdFromSameActivity = idListFromSameActivity.at(0);
      const maxIdFromSameActivity = idListFromSameActivity.at(-1);
      const minIdFromSameActivityUser = idListFromSameActivityUser.at(0);
      const convertedTarget: AffiliatedPersonSalesActivityConvertedView = {
        ...target,
        isFirstActivity: false,
        isLastActivity: false,
        isFirstActivityUser: false,
        sameActivitySize: 1,
        sameActivityUserSize: 1,
        index: 1,
      };
      convertedTarget.isFirstActivity = target.id === minIdFromSameActivity;
      convertedTarget.isLastActivity = target.id === maxIdFromSameActivity;
      convertedTarget.isFirstActivityUser = target.id === minIdFromSameActivityUser;
      convertedTarget.sameActivitySize = idListFromSameActivity.length;
      convertedTarget.sameActivityUserSize = idListFromSameActivityUser.length;
      convertedTarget.index = activityIdListBySet.indexOf(convertedTarget.activity.id);
      convertedList.push(convertedTarget);
    });
    setList(convertedList);
  }, [list, setList]);
};
