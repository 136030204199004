import React, { useMemo } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { ProjectSalesInfoEstimationComparedUpdateModalContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/features/update-modal/widgets/context';
import { useProjectSalesInfoEstimationComparedUpdateModalLogic } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/features/update-modal/widgets/useLogic';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { ProjectSalesInfoEstimationComparedUpdateModalForm } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/features/update-modal/components/modal-form';
import { isMobileDevice } from '@front/util/PwaUtil';

export { Widget as ProjectSalesInfoEstimationComparedUpdateModalWidget };
const Widget = () => {
  const {
    d: { detail },
    h: { onClose, onUpdate },
    isLoading,
    isOpen,
  } = useProjectSalesInfoEstimationComparedUpdateModalLogic();
  const value = useMemo(
    () => ({
      d: { detail },
      h: { onClose, onUpdate },
    }),
    [detail, onClose, onUpdate]
  );
  return (
    <ProjectSalesInfoEstimationComparedUpdateModalContext.Provider value={value}>
      <ModalLayout
        position="left"
        width={isMobileDevice() ? '29dvw' : '560px'}
        title="대비견적 수정"
        open={isOpen}
        onClose={onClose}
      >
        {isLoading && <LoadingSpinner height="840px" />}
        {!isLoading && <ProjectSalesInfoEstimationComparedUpdateModalForm />}
      </ModalLayout>
    </ProjectSalesInfoEstimationComparedUpdateModalContext.Provider>
  );
};
