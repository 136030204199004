import React, { useEffect } from 'react';
import { useHeaderHistoryState } from '@front/src/features/header-history/useState';
import { useShallow } from 'zustand/react/shallow';
import {
  ModalBodyUI,
  ModalContentUI,
} from '@front/src/components/components-with-design/layout/ModalUI';
import HeaderHistoryContent from '@front/src/features/header-history/components/Content';

interface Props {
  sectionId: number;
  onClose?: () => void;
}

export default function HeaderHistoryFeature({ sectionId, onClose }: Readonly<Props>) {
  const { setSectionId, setHeaderId } = useHeaderHistoryState(
    useShallow((state) => ({
      setSectionId: state.setSectionId,
      setHeaderId: state.setHeaderId,
    }))
  );
  useEffect(() => {
    setSectionId(sectionId);
    return () => {
      setHeaderId('');
    };
  }, [setSectionId, sectionId, setHeaderId]);
  return (
    <ModalBodyUI>
      <ModalContentUI>
        <HeaderHistoryContent onClose={onClose} />
      </ModalContentUI>
    </ModalBodyUI>
  );
}
