import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';

import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { affiliatedCompanyOutlineAddressApi } from '@front/src/features/affiliated-company/features/outline/features/address/query/api';
import type { AffiliatedCompanyOutlineAddressUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/address/types/parameter';

export const affiliatedCompanyOutlineAddressMutation = {
  useOutlineAddressCreate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) =>
        affiliatedCompanyOutlineAddressApi.createOutlineAddress(id, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['affiliated-company', 'address']);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useOutlineAddressDelete: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
      (params: AffiliatedCompanyIdListDeleteParameter) =>
        affiliatedCompanyOutlineAddressApi.deleteOutlineAddress(params, menuId),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['affiliated-company', 'address']);
        },
        onError: async (error) => handleError(error, alert),
      }
    );
    return {
      mutate,
    };
  },
  useOutlineAddressUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyOutlineAddressUpdateParameter) =>
        affiliatedCompanyOutlineAddressApi.updateOutlineAddress(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['affiliated-company', 'address']);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
