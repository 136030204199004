import { accountingUploadURL } from '@front/src/features/accounting/features/upload/features/ecount-data/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { AccountingUploadEcountUploadParameter } from '@front/src/features/accounting/features/upload/features/ecount-data/types/parameter';
import {
  accountingBankAccountURL,
  accountingDailyReportURL,
  accountingTaxAccountingURL,
} from '@front/src/features/accounting/features/settings/utils/constant';

export { Api as AccountingUploadApi };

const url = {
  getList: () => `${accountingUploadURL}`,
  createTaxAccounting: () => `${accountingTaxAccountingURL}`,
  createBankAccount: () => `${accountingBankAccountURL}`,
  createDailyReport: () => `${accountingDailyReportURL}`,
};

const Api = {
  getList: async () => {
    const { data } = await axios.get(url.getList());
    return data;
  },
  createTaxAccounting: async (params: AccountingUploadEcountUploadParameter) => {
    const formData = new FormData();
    formData.append('file', params.file.multipartFile!);
    formData.append('category', params.category);
    const { data } = await axios.post(url.createTaxAccounting(), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  },
  createBankAccount: async (params: AccountingUploadEcountUploadParameter) => {
    const formData = new FormData();
    formData.append('file', params.file.multipartFile!);
    formData.append('category', params.category);
    const { data } = await axios.post(url.createBankAccount(), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  },
  createDailyReport: async (params: AccountingUploadEcountUploadParameter) => {
    const formData = new FormData();
    formData.append('file', params.file.multipartFile!);
    formData.append('category', params.category);
    const { data } = await axios.post(url.createDailyReport(), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  },
};
