import React from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { AffiliatedCompanyPersonTableBody } from '@front/src/features/affiliated-company/features/person/components/person-table-body';

export { PersonTable as AffiliatedCompanyPersonTable };

const PersonTable = () => (
  <TableContainer>
    <Table>
      <TableHeader />
      <TableBody>
        <AffiliatedCompanyPersonTableBody />
      </TableBody>
    </Table>
  </TableContainer>
);

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <Th
        width="100px"
        center
      >
        이름
      </Th>
      <Th
        center
        width="100px"
      >
        직급
      </Th>
      <Th
        width="80px"
        center
      >
        상태
      </Th>
      <Th
        width="100px"
        center
      >
        주요인물
      </Th>
      <Th
        width="200px"
        center
      >
        소속
      </Th>
      <Th
        width="100px"
        center
      >
        출생연도
      </Th>
      <Th
        width="200px"
        center
      >
        학교
      </Th>
      <Th
        width="120px"
        center
      >
        학번
      </Th>
      <Th
        width="200px"
        center
      >
        특징
      </Th>
      <Th
        width="200px"
        center
      >
        최근만남
      </Th>
      <Th
        width="200px"
        center
      >
        선호풍동
      </Th>
    </TableRow>
  </TableHead>
);
