import React from 'react';
import Box from '@mui/material/Box';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { FormProvider, useForm } from 'react-hook-form';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import {
  conventWorkStatusLabelToVariable,
  conventWorkStatusVariableToLabel,
} from '@front/src/features/work/features/work/features/name/utils';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';
import workNameQuery from '@front/src/features/work/features/work/features/name/query/query';
import workNameMutation from '@front/src/features/work/features/work/features/name/query/mutation';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import TypographyUI from '@front/src/components/components-with-design/component/TypographyUI';
import { ColorPalette } from '@front/assets/theme';
import BackdropLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/BackdropLoadingSpinnerUI';
import InputWithHookForm from '@front/src/components/hook-form/Input';
import DropDownWithHookForm from '@front/src/components/hook-form/DropDown';

export default function WorkNameTable({
  readOnly,
  dataId,
  menuId,
}: Readonly<SectionComponentProps>) {
  const { data: detail } = workNameQuery.useGetOne(dataId, menuId);
  const { data: statusList } = adminManageableVariableRepository.useListGet('일정-상태', menuId);
  const { isLoading, onUpdate } = useMutationLogic({ dataId, menuId });
  const methods = useForm({
    values: getFormValues(detail),
  });
  const { handleSubmit, getValues } = methods;
  const onSubmit = handleSubmit((data) => {
    onUpdate({
      name: data.name,
      status: data.status || null,
      completionRate: +data.completionRate,
    });
  });
  const renderStatus = () => {
    if (readOnly) return conventWorkStatusVariableToLabel[getValues('status')];
    return (
      <DropDownWithHookForm
        name="status"
        defaultLabel="선택"
        onSubmit={onSubmit}
        options={getOptions(statusList)}
      />
    );
  };
  return (
    <TableContainerUI
      sx={{
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <BackdropLoadingSpinnerUI
        sx={{
          position: 'absolute',
        }}
        open={isLoading}
      />
      <TableTableUI>
        <TableBodyUI>
          <TableRowUI>
            <FormProvider {...methods}>
              <TableCellUI
                isHead
                sx={{
                  width: '10%',
                }}
              >
                업무명
                <RedStar />
              </TableCellUI>
              <TableCellUI
                sx={{
                  width: '40%',
                }}
              >
                <InputWithHookForm
                  name="name"
                  onSubmit={onSubmit}
                  readOnly={readOnly}
                />
              </TableCellUI>
              <TableCellUI
                isHead
                sx={{
                  width: '12.5%',
                }}
              >
                상태
              </TableCellUI>
              <TableCellUI
                sx={{
                  width: '12.5%',
                }}
              >
                {renderStatus()}
              </TableCellUI>
              <TableCellUI
                isHead
                sx={{
                  width: '12.5%',
                }}
              >
                완료율
              </TableCellUI>
              <TableCellUI
                sx={{
                  width: '12.5%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                  }}
                >
                  <InputWithHookForm
                    type="number"
                    name="completionRate"
                    onSubmit={onSubmit}
                    readOnly={readOnly}
                  />
                  %
                </Box>
              </TableCellUI>
            </FormProvider>
          </TableRowUI>
        </TableBodyUI>
      </TableTableUI>
    </TableContainerUI>
  );
}

function RedStar() {
  return (
    <TypographyUI
      variant="caption"
      sx={{
        color: ColorPalette.sub2.sub2_primary,
      }}
    >
      *
    </TypographyUI>
  );
}

function useMutationLogic({ dataId, menuId }) {
  const { mutate: onUpdate, isLoading } = workNameMutation.useUpdate(dataId, menuId);
  return {
    isLoading,
    onUpdate,
  };
}

function getFormValues(detail) {
  return {
    name: detail?.name ?? '',
    status: detail?.status ?? '',
    completionRate: detail?.completionRate ?? '',
  };
}

function getOptions(view?: VariableInformationView[]) {
  if (!view) return [];
  return view?.map((item) => ({
    label: item.label,
    value: conventWorkStatusLabelToVariable[item.value],
  }));
}
