import React, { memo } from 'react';
import Input from '@front/layouts/Input';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';

interface Props {
  totalReward?: number;
}

const TotalReward = ({ totalReward }: Props) => (
  <DataFieldWithLabel
    label="총 상금"
    labelSX={{ minWidth: '80px' }}
  >
    <Input
      readOnly={true}
      isAmount
      key={totalReward}
      variant="outlined"
      defaultValue={totalReward?.toLocaleString()}
    />
  </DataFieldWithLabel>
);

const TotalRewardForm = memo(TotalReward);

export default TotalRewardForm;
