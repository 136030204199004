import React, { useContext } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { Table } from '@front/src/components/layout/table/table';
import CircularProgress from '@front/components/CircularProgress';
import { AffiliatedCompanyPersonDetailModalRelationshipContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/widgets/context';
import { AffiliatedCompanyPersonDetailModalRelationshipTableRow } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/components/relationship-table-row';
import { NoResultBox } from '@front/src/components/no-result-box/no-result-box';

export { RelationshipTable as AffiliatedCompanyPersonDetailModalRelationshipTable };
const RelationshipTable = () => {
  const {
    d: { relationshipList },
    isLoading,
  } = useContext(AffiliatedCompanyPersonDetailModalRelationshipContext);
  if (typeof relationshipList === 'undefined' || isLoading) {
    return <CircularProgress size={30} />;
  }
  if (relationshipList.length === 0) {
    return <NoResultBox message="저장된 인맥이 없습니다." />;
  }
  return (
    <TableContainer>
      <Table>
        <TableHeader />
        <TableBody>
          {relationshipList.map((item, i) => (
            <AffiliatedCompanyPersonDetailModalRelationshipTableRow
              key={item.id}
              item={item}
              index={i}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <Th
        center
        width="40px"
      >
        {' '}
      </Th>
      <Th
        center
        width="100px"
      >
        이름
      </Th>
      <Th
        center
        width="80px"
      >
        직급
      </Th>
      <Th
        center
        width="200px"
      >
        소속
      </Th>
      <Th
        center
        width="200px"
      >
        회사명
      </Th>
      <Th
        center
        width="80px"
      >
        관계정도
      </Th>
      <Th
        center
        width="600px"
      >
        친분 사유
      </Th>
    </TableRow>
  </TableHead>
);
