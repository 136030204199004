// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bxW9WDjpvqdaJhhIN1ID{position:absolute;right:0;width:33px;height:20px;display:flex;justify-content:center;align-items:center;transform:translate3d(calc(100% - 10px), 0, 0);transition:right .3s ease}.vVHoOndzMRMt7wgDohkZ:hover .bxW9WDjpvqdaJhhIN1ID{right:-15px}.Ikq25HQcrFKy1Q1BDabc:hover .bxW9WDjpvqdaJhhIN1ID{right:-20px}`, "",{"version":3,"sources":["webpack://./front/src/features/affiliated-person/features/drawer/widgets/list/components/row.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,OAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,8CAAA,CACA,yBAAA,CAIA,kDACE,WAAA,CAKF,kDACE,WAAA","sourcesContent":[".badge_container {\n  position: absolute;\n  right: 0;\n  width: 33px;\n  height: 20px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transform: translate3d(calc(100% - 10px), 0, 0);\n  transition: right 0.3s ease;\n}\n\n.link_small {\n  &:hover .badge_container {\n    right: -15px;\n  }\n}\n\n.link_large {\n  &:hover .badge_container {\n    right: -20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge_container": `bxW9WDjpvqdaJhhIN1ID`,
	"link_small": `vVHoOndzMRMt7wgDohkZ`,
	"link_large": `Ikq25HQcrFKy1Q1BDabc`
};
export default ___CSS_LOADER_EXPORT___;
