// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Z0ah6edKnWBcdlxRGu8q{display:flex;align-items:center;gap:5px;width:100%;transform:translate3d(0, -5px, 0);padding:10px 0 0 0}.rrrSWr6aZlgsXtBLgMFB{display:flex;align-items:center;gap:10px;margin-left:auto}.xNF1Xo1kzoKCH7yy2mbw{transform:translate3d(0, -5px, 0);margin-bottom:15px}.YfGgQa1sFuMBdxJHjukD{cursor:pointer}`, "",{"version":3,"sources":["webpack://./front/src/features/mobile-notification/widgets/list/widgets/item/components/mobile-notification-list-item.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,UAAA,CACA,iCAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,gBAAA,CAGF,sBACE,iCAAA,CACA,kBAAA,CAGF,sBACE,cAAA","sourcesContent":[".date__name__container {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n  width: 100%;\n  transform: translate3d(0, -5px, 0);\n  padding: 10px 0 0 0;\n}\n\n.fav__toggle_container {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  margin-left: auto;\n}\n\n.project__info__container {\n  transform: translate3d(0, -5px, 0);\n  margin-bottom: 15px;\n}\n\n.hover {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date__name__container": `Z0ah6edKnWBcdlxRGu8q`,
	"fav__toggle_container": `rrrSWr6aZlgsXtBLgMFB`,
	"project__info__container": `xNF1Xo1kzoKCH7yy2mbw`,
	"hover": `YfGgQa1sFuMBdxJHjukD`
};
export default ___CSS_LOADER_EXPORT___;
