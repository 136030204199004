import React from 'react';
import BadgeUI from '@front/src/components/components-with-design/component/BadgeUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorPalette } from '@front/assets/theme';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import Tooltip from '@front/components/Tooltip';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import { approvalBoxQuery } from '@front/src/features/approval-box/query/query';

export default function ApprovalButton() {
  const { data: { approvalDocumentCount = 0, approvalCount = 0, referenceCount = 0 } = {} } =
    approvalBoxQuery.useGetApprovalBoxCount();
  const totalCount = approvalDocumentCount + approvalCount + referenceCount;
  const setLeftDrawerState = useGlobalNavBarState((state) => state.setLeftDrawerState);

  const onClick = () => {
    setLeftDrawerState('approval-box');
  };

  return (
    <Tooltip
      title="결재"
      placement="bottom"
    >
      <ButtonBasicUI
        shape="ghost"
        sx={{ padding: '0', border: 'none', width: '2rem', height: '2rem' }}
        onClick={onClick}
      >
        <BadgeUI badgeContent={totalCount}>
          <FontAwesomeIcon
            icon="signature"
            color={totalCount ? ColorPalette._ffb72b : ColorPalette._697183}
            size="xl"
          />
        </BadgeUI>
      </ButtonBasicUI>
    </Tooltip>
  );
}
