import React, { memo } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyTableBody } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/components/table-body';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';

export { AffiliatedCompanyTable as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyTable };

const AffiliatedCompanyTable = () => (
  <TableContainer
    sx={{
      ...useGetMaxWidthSx({ hasMemo: true }),
      maxHeight: '400px',
      overflow: 'auto',
    }}
  >
    <Table>
      <TableHeader />
      <TableBody>
        <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyTableBody />
      </TableBody>
    </Table>
  </TableContainer>
);

const TableHeader = memo(() => (
  <TableHead>
    <TableRow>
      <Th width="40px" />
      <Th width="200px">건축</Th>
      <Th width="200px">구조</Th>
      <Th width="200px">시행사</Th>
      <Th width="200px">시공사</Th>
      <Th width="100px">소개자</Th>
      <Th width="200px">발주처(계약서)</Th>
      <Th width="100px">실발주처</Th>
      <Th width="200px">기타</Th>
      <Th width="200px">비고</Th>
    </TableRow>
  </TableHead>
));

TableHeader.displayName = 'ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyTableTableHeader';
