import React from 'react';
import { PersonalSettingsModalUISettingsModalSettingsModalStatusParentTableRow } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/components/ParentTableRow';
import {
  TableRowBox,
  TbcBox,
} from '@front/src/features/personal-settings-modal/features/ui-settings/components';
import { personalSettingsModalUISettingsModalSettingsRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/repository/repository';

export { ParentTableContent as PersonalSettingsModalUISettingsModalSettingsModalStatusParentTableContent };
interface Props {
  sectionId: number;
  authMenuId: number | undefined;
}
const ParentTableContent = ({ sectionId, authMenuId }: Props) => {
  const { data: list } = personalSettingsModalUISettingsModalSettingsRepository.useGetHeaderList(
    sectionId,
    authMenuId
  );
  const emptyRows = Array(20 - (list ? list.length : 0))
    .fill(null)
    .map((_, index) => (
      <TableRowBox key={`empty-${index}`}>
        <TbcBox
          sx={{
            flex: 2,
          }}
        />
        <TbcBox
          sx={{
            flex: 1,
          }}
        />
      </TableRowBox>
    ));
  return (
    <>
      {list?.map((item) => (
        <PersonalSettingsModalUISettingsModalSettingsModalStatusParentTableRow
          key={item.id}
          item={item}
        />
      ))}
      {emptyRows}
    </>
  );
};
