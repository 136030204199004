import React, { useMemo } from 'react';
import { useProjectSalesInfoAbstractEstimationDetailLogic } from '@front/src/features/project-sales-info/features/abstract/features/estimation/widgets/detail/provider/useLogic';
import { ProjectSalesInfoAbstractEstimationDetailContext } from '@front/src/features/project-sales-info/features/abstract/features/estimation/widgets/detail/provider/context';

export { Provider as ProjectSalesInfoAbstractEstimationDetailProvider };

interface Props {
  children: React.ReactNode;
}

const Provider = ({ children }: Props) => {
  const { detail } = useProjectSalesInfoAbstractEstimationDetailLogic();
  const value = useMemo(
    () => ({
      detail,
    }),
    [detail]
  );
  return (
    <ProjectSalesInfoAbstractEstimationDetailContext.Provider value={value}>
      {children}
    </ProjectSalesInfoAbstractEstimationDetailContext.Provider>
  );
};
