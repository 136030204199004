import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import DateAttr from '@front/src/components/ui-builder/DateAttr';
import NumberAttr from '@front/src/components/ui-builder/NumberAttr';
import TextAttr from '@front/src/components/ui-builder/TextAttr';
import Box from '@mui/material/Box';
import type { WorkApprovalDocumentView } from '@front/src/features/work/features/work/features/approval/types/view';
import dayjs from 'dayjs';
import { YY_MM_DD_HH_MM } from '@front/src/utils';
import { getApprovalDocumentStatusLabel } from '@front/src/features/drawer-approval-document/utils';
import WorkApprovalModal from '@front/src/features/work/features/work/features/approval/components/Modal';
import { useFormContext } from 'react-hook-form';

const workApprovalCellComponent = {
  299: ({ ...rest }: CellComponentProps) => <WorkApprovalTableRowRequestDate {...rest} />,
  300: ({ ...rest }: CellComponentProps) => <WorkApprovalTableRowTitle {...rest} />,
  301: ({ ...rest }: CellComponentProps) => <WorkApprovalTableRowRequestUser {...rest} />,
  302: ({ ...rest }: CellComponentProps) => <WorkApprovalTableRowApprovalStatus {...rest} />,
  303: ({ ...rest }: CellComponentProps) => <WorkApprovalTableRowNumberAttr1 {...rest} />,
  304: ({ ...rest }: CellComponentProps) => <WorkApprovalTableRowTextAttr1 {...rest} />,
  305: ({ ...rest }: CellComponentProps) => <WorkApprovalTableRowDateAttr1 {...rest} />,
  306: ({ ...rest }: CellComponentProps) => <WorkApprovalTableRowNumberAttr2 {...rest} />,
  307: ({ ...rest }: CellComponentProps) => <WorkApprovalTableRowTextAttr2 {...rest} />,
  308: ({ ...rest }: CellComponentProps) => <WorkApprovalTableRowDateAttr2 {...rest} />,
  309: ({ ...rest }: CellComponentProps) => <WorkApprovalTableRowNumberAttr3 {...rest} />,
  310: ({ ...rest }: CellComponentProps) => <WorkApprovalTableRowTextAttr3 {...rest} />,
  311: ({ ...rest }: CellComponentProps) => <WorkApprovalTableRowDateAttr3 {...rest} />,
};

export default workApprovalCellComponent;

interface Props extends CellComponentProps<WorkApprovalDocumentView> {}

function WorkApprovalTableRowRequestDate({
  item,
  onClick,
  sx = {},
  sectionId,
  dataId,
  menuId,
}: Readonly<Props>) {
  const { watch } = useFormContext();
  const workId = watch('workId');
  return (
    <Box
      sx={sx}
      onClick={onClick}
    >
      {workId && (
        <WorkApprovalModal
          workId={workId}
          sectionId={sectionId}
          dataId={dataId}
          menuId={menuId}
        />
      )}
      {dayjs(item.approvalDocument?.submissionDateTime).format(YY_MM_DD_HH_MM)}
    </Box>
  );
}

function WorkApprovalTableRowTitle({ item, onClick, sx = {} }: Readonly<Props>) {
  return (
    <Box
      sx={sx}
      onClick={onClick}
    >
      {item.approvalDocument?.title}
    </Box>
  );
}

function WorkApprovalTableRowRequestUser({ item, onClick, sx = {} }: Readonly<Props>) {
  return (
    <Box
      sx={sx}
      onClick={onClick}
    >
      {item.approvalDocument?.user?.name}
    </Box>
  );
}

function WorkApprovalTableRowApprovalStatus({ item, onClick, sx = {} }: Readonly<Props>) {
  return (
    <Box
      sx={sx}
      onClick={onClick}
    >
      {getApprovalDocumentStatusLabel[item.approvalDocument?.status]}
    </Box>
  );
}

function WorkApprovalTableRowNumberAttr1({ ...rest }: Readonly<Props>) {
  return (
    <NumberAttr
      name="numberAttr1"
      {...rest}
    />
  );
}

function WorkApprovalTableRowTextAttr1({ ...rest }: Readonly<Props>) {
  return (
    <TextAttr
      name="textAttr1"
      {...rest}
    />
  );
}

function WorkApprovalTableRowDateAttr1({ ...rest }: Readonly<Props>) {
  return (
    <DateAttr
      name="dateAttr1"
      {...rest}
    />
  );
}

function WorkApprovalTableRowNumberAttr2({ ...rest }: Readonly<Props>) {
  return (
    <NumberAttr
      name="numberAttr2"
      {...rest}
    />
  );
}

function WorkApprovalTableRowTextAttr2({ ...rest }: Readonly<Props>) {
  return (
    <TextAttr
      name="textAttr2"
      {...rest}
    />
  );
}

function WorkApprovalTableRowDateAttr2({ ...rest }: Readonly<Props>) {
  return (
    <DateAttr
      name="dateAttr2"
      {...rest}
    />
  );
}

function WorkApprovalTableRowNumberAttr3({ ...rest }: Readonly<Props>) {
  return (
    <NumberAttr
      name="numberAttr3"
      {...rest}
    />
  );
}

function WorkApprovalTableRowTextAttr3({ ...rest }: Readonly<Props>) {
  return (
    <TextAttr
      name="textAttr3"
      {...rest}
    />
  );
}

function WorkApprovalTableRowDateAttr3({ ...rest }: Readonly<Props>) {
  return (
    <DateAttr
      name="dateAttr3"
      {...rest}
    />
  );
}
