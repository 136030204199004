import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAccountingSettingsBankAccountUploadLogic } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/upload/provider/useLogic';
import { AccountingSettingsBankAccountUploadContext } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/upload/provider/context';

export { Provider as AccountingSettingsBankAccountUploadProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { onCreate } = useAccountingSettingsBankAccountUploadLogic();
  const value = useMemo(() => ({ onCreate }), [onCreate]);
  return (
    <AccountingSettingsBankAccountUploadContext.Provider value={value}>
      {children}
    </AccountingSettingsBankAccountUploadContext.Provider>
  );
};
