import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { FileItemRecordView } from '@front/src/features/affiliated-company/types/view';
import type {
  AffiliatedCompanyOutlineBankBookFileUpdateParameter,
  AffiliatedCompanyOutlineBankInfoUpdateParameter,
} from '@front/src/features/affiliated-company/features/outline/features/bank-info/types/parameter';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';

export { Context as AffiliatedCompanyOutlineBankInfoContext };

interface State {
  d: {
    bankInfo?: {
      bankName: string;
      accountNumber: string;
    };
    bankBookFile?: FileItemRecordView;
    bankNameOptionList?: VariableInformationView[];
  };
  h: {
    onBankInfoUpdate: (params: AffiliatedCompanyOutlineBankInfoUpdateParameter) => void;
    onBankBookFileUpdate: (params: AffiliatedCompanyOutlineBankBookFileUpdateParameter) => void;
  };
}

const Context = createContext<State>({
  d: {},
  h: {
    onBankInfoUpdate: noOp,
    onBankBookFileUpdate: noOp,
  },
});
