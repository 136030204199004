import React from 'react';
import { ProjectSalesInfoSubjectReviewModalDesignDateDeleteButton } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date/features/delete-button/components/delete-button';
import { ProjectSalesInfoSubjectReviewModalDesignDateDeleteButtonProvider } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date/features/delete-button/widgets/provider';

export { Widget as ProjectSalesInfoSubjectReviewModalDesignDateDeleteButtonWidget };

const Widget = () => (
  <ProjectSalesInfoSubjectReviewModalDesignDateDeleteButtonProvider>
    <ProjectSalesInfoSubjectReviewModalDesignDateDeleteButton />
  </ProjectSalesInfoSubjectReviewModalDesignDateDeleteButtonProvider>
);
