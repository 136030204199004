import React from 'react';

import PageLayoutWithMemo from '@front/layouts/PageLayoutWithMemo';
import DetailService from '@front/ost/service/detail';
import MemoService from '@front/ost/service/memo';
import { useMobileMenuState } from '@front/mobile-menu/useState';
import { useOstAuth } from '@front/src/hooks/useOstAuth';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';

const OstManagementDetailRoute = (props: Readonly<SectionComponentProps>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  const isMemo = useMobileMenuState((state) => state.isMemo);
  useOstAuth('/ost/ost-management');
  return (
    <Layout>
      <Header title={props.name} />
      <Body>
        <PageLayoutWithMemo>
          <DetailService {...props} />
          {isMemo && <MemoService />}
        </PageLayoutWithMemo>
      </Body>
    </Layout>
  );
};

export default OstManagementDetailRoute;
