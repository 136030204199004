import { createAction } from 'typesafe-actions';
import type { UserQuery } from 'user/query';
import type { AdminRoleNameView, UserError, UserId, UserShortVO, UserVO } from 'user/domain';
import type {
  UserChangeParameter,
  UserInviteParameter,
  UserPasswordChangeParameter,
} from 'user/parameter';
import type Page from 'type/Page';
import type { ApiStatus } from 'components/DataFieldProps';
import type { UrlValidateParameter } from 'login/parameter';

export enum UserActionType {
  setFilter = 'user/filter/set',
  setPage = 'user/page/set',
  setId = 'user/id/set',
  setOne = 'user/one/set',
  change = 'user/change',
  invite = 'user/invite',
  requestChange = 'user/change/request',
  requestEmailToChangePassword = 'user/change/password/request',
  validateUrlForPasswordReset = 'user/reset/password/validate',
  setUrlValidatedResult = 'user/reset/password/validate/result',
  userError = 'user/error',
  requestFindPasswordByUsername = 'user/find/password/request',
  requestInitPassword = 'user/init/password/request',
  setRoleList = 'user/role/list/set',
  getRoleList = 'user/role/list/get',
}

interface InviteProps {
  parameter: UserInviteParameter;
  callback: any;
}

export const userAction = {
  setFilter: createAction(UserActionType.setFilter)<UserQuery>(),
  setPage: createAction(UserActionType.setPage)<Page<UserShortVO>>(),
  setOne: createAction(UserActionType.setOne)<UserVO | undefined>(),
  setId: createAction(UserActionType.setId)<UserId | undefined>(),
  change: createAction(UserActionType.change)<UserChangeParameter>(),
  invite: createAction(UserActionType.invite)<InviteProps>(),
  requestChange: createAction(UserActionType.requestChange)<ApiStatus>(),
  requestEmailToChangePassword: createAction(
    UserActionType.requestEmailToChangePassword
  )<UserPasswordChangeParameter>(),
  validateUrlForPasswordReset: createAction(
    UserActionType.validateUrlForPasswordReset
  )<UrlValidateParameter>(),
  setUrlValidatedResult: createAction(UserActionType.setUrlValidatedResult)<boolean>(),
  userError: createAction(UserActionType.userError)<UserError | undefined>(),
  requestFindPasswordByUsername: createAction(
    UserActionType.requestFindPasswordByUsername
  )<ApiStatus>(),
  requestInitPassword: createAction(UserActionType.requestInitPassword)(),
  setRoleList: createAction(UserActionType.setRoleList)(),
  getRoleList: createAction(UserActionType.getRoleList)<AdminRoleNameView[]>(),
};
