import { useQuery } from 'react-query';
import { projectSalesInfoSubjectReviewModalApi } from '@front/src/features/project-sales-info/features/subject-review/features/modal/query/api';
import type { ProjectExperimentInformationView } from '@front/src/features/project-sales-info/features/subject-review/features/modal/types/view';

export const projectSalesInfoSubjectReviewModalQuery = {
  useDetailGet: (id?: number) => {
    const { data, isLoading, remove } = useQuery<ProjectExperimentInformationView>({
      queryFn: () => projectSalesInfoSubjectReviewModalApi.getDetail(id),
      queryKey: [
        'project',
        'sales-info',
        'subject-review',
        'shape-ratio',
        'experiment-information',
        id,
      ],
      enabled: !!id,
    });
    return {
      data,
      isLoading,
      remove,
    };
  },
};
