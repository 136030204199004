import type { ReactNode } from 'react';
import React from 'react';
import classes from '@front/src/features/project-selector-modal/components/layout/body.module.scss';

interface Props {
  children: ReactNode;
}

export { Body };
const Body = ({ children }: Props) => <div className={classes.container}>{children}</div>;
