import React from 'react';
import type { TableRowProps } from '@mui/material/TableRow';
import TableRow from '@mui/material/TableRow';

export default function TableRowUI({ sx = {}, ...rest }: Readonly<TableRowProps>) {
  return (
    <TableRow
      sx={{
        '&:last-child td': {
          borderBottom: `0px`,
        },
        ...sx,
      }}
      {...rest}
    />
  );
}
