import { create } from 'zustand';

export { useState as usePersonalSettingsModalUISettingsHeaderSettingsPositionState };

interface State {
  header?: {
    id: number;
    name: string;
    isDuplicate?: boolean;
  };
  menuId?: number;
}

interface Actions {
  setHeader: (header?: { id: number; name: string; isDuplicate?: boolean }) => void;
  setMenuId: (menuId?: number) => void;
}

const useState = create<State & Actions>((set) => ({
  setHeader: (header) => set(() => ({ header })),
  setMenuId: (menuId) => set(() => ({ menuId })),
}));
