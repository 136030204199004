import React from 'react';
import type { RadioProps } from '@mui/material/Radio';
import Radio from '@mui/material/Radio';
import { ColorPalette } from '@front/assets/theme';
import { FormControlLabel } from '@mui/material';

interface Props extends RadioProps {
  label?: string;
}

export default function RadioButtonUI({
  value,
  disabled,
  checked,
  label,
  ...rest
}: Readonly<Props>) {
  return (
    <FormControlLabel
      value={value}
      control={
        <RadioButton
          disabled={disabled}
          checked={checked}
          {...rest}
        />
      }
      label={label}
      sx={{
        '& .MuiTypography-root': {
          fontSize: '1.4rem',
          color: disabled ? ColorPalette.greyscale.disabled : ColorPalette.greyscale.text_primary,
        },
      }}
    />
  );
}

const RadioButton = ({ ...rest }: Readonly<Props>) => (
  <Radio
    {...rest}
    sx={{
      padding: '6px',

      '& .MuiSvgIcon-root': {
        fontSize: '22px',
        color: ColorPalette.line.line02,
      },

      '&.Mui-checked .MuiSvgIcon-root': {
        color: ColorPalette.main.main_primary,
      },

      '&:hover .MuiSvgIcon-root': {
        color: ColorPalette.main.main_hover,
      },

      '&:active .MuiSvgIcon-root': {
        color: ColorPalette.main.main_hover,
      },

      '&.Mui-focusVisible .MuiSvgIcon-root': {
        outline: `2px solid ${ColorPalette.sub.sub_primary}`,
        outlineOffset: '-4px',
        borderRadius: '50%',
      },

      '&.Mui-disabled': {
        '& .MuiSvgIcon-root': {
          color: ColorPalette.greyscale.disabled,
        },
        '&.Mui-checked .MuiSvgIcon-root': {
          color: ColorPalette.greyscale.white,
          background: ColorPalette.greyscale.disabled,
          outline: `2px solid ${ColorPalette.greyscale.white}`,
          outlineOffset: '-2px',
          borderRadius: '50%',
        },
      },
    }}
  />
);
