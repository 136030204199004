import React, { Suspense } from 'react';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';
import { ProjectSalesInfoAbstractContractDetailProvider } from '@front/src/features/project-sales-info/features/abstract/features/contract/widgets/detail/provider/provider';
import { ProjectSalesInfoAbstractContractDetail } from '@front/src/features/project-sales-info/features/abstract/features/contract/widgets/detail/components/detail';

export { Widget as ProjectSalesInfoAbstractContractDetailWidget };

const Widget = () => (
  <Suspense fallback={<TableLoadingSpinner colSpan={8} />}>
    <ProjectSalesInfoAbstractContractDetailProvider>
      <ProjectSalesInfoAbstractContractDetail />
    </ProjectSalesInfoAbstractContractDetailProvider>
  </Suspense>
);
