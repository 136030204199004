import React from 'react';
import { Box } from '@mui/material';
import DropDownUI from '@front/src/components/components-with-design/component/DropDownUI';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import { useFormContext } from 'react-hook-form';
import { approvalMutation } from '@front/src/features/drawer-approval/query/mutation';
import { APPROVAL_DOCUMENT_STATUS, BODY_TEMPLATE_TYPE } from '@front/type/domain';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import { useShallow } from 'zustand/react/shallow';
import useIdGroup from '@front/src/features/note/hooks/useIdGroup';
import { useSnackbar } from 'notistack';
import { useCustomDialog } from '@front/src/features/dialog';

export default function ApprovalForm() {
  const dataId = sessionStorage.getItem('dataId');
  const menuId = useGlobalNavBarState((state) => state.menuId);
  const { handleSubmit, getValues } = useFormContext();
  const { documentId } = useIdGroup();
  const { mutate: createApprovalDocument } = approvalMutation.useCreateApprovalDocument();
  const { mutate: updateTemp } = approvalMutation.useUpdateTemp();
  const { mutate: deleteTemp } = approvalMutation.useDeleteTemp(documentId);
  const { noteId, setLeftDrawerState } = useGlobalNavBarState(
    useShallow((state) => ({
      noteId: state.noteId,
      setLeftDrawerState: state.setLeftDrawerState,
    }))
  );
  const { confirm } = useCustomDialog();

  const isTemp = getValues(`request.status`) === APPROVAL_DOCUMENT_STATUS.TEMPORARY;
  const { enqueueSnackbar } = useSnackbar();

  const validateEmpty = () => getValues('request.title') && getValues('request.content');

  const onSubmit = handleSubmit((data: any) => {
    if (!validateEmpty()) {
      enqueueSnackbar('값을 입력해주세요', { variant: 'warning' });
      return;
    }
    !isTemp
      ? createApprovalDocument({
          ...data,
          request: {
            ...data.request,
            approvalList: data.request.approvalList.map((item, index) => ({
              ...item,
              seq: index + 1,
            })),
            dataId: Number(dataId) || 0,
            contentsType: BODY_TEMPLATE_TYPE.BASIC,
            menuId: Number(menuId) || 0,
            noteId: noteId,
            status: APPROVAL_DOCUMENT_STATUS.SUBMISSION,
            hasFileItemIdAddList: data.request.hasFileItemFileAddList.map((item) => item.id),
          },
        })
      : updateTemp({
          request: {
            ...data.request,
            approvalList: data.request.approvalList.map((item, index) => ({
              ...item,
              seq: index + 1,
            })),
            dataId: Number(dataId) || 0,
            contentsType: BODY_TEMPLATE_TYPE.BASIC,
            menuId: Number(menuId) || 0,
            noteId: noteId,
            status: APPROVAL_DOCUMENT_STATUS.SUBMISSION,
            hasFileItemIdAddList: data.request.hasFileItemFileAddList.map((item) => item.id),
          },
          addFileItemList: data.fileItemList.filter((item) => !item.id),
        });
    setLeftDrawerState('');
  });

  const onSubmitClick = () => {
    confirm({
      title: '결재 상신 확인',
      lineBreakChildren: [
        { value: '결재가 요청되면 되돌릴 수 없습니다.' },
        { value: '결재 상신하시겠습니까?' },
      ],
      afterConfirm: () => {
        onSubmit();
      },
    });
  };

  const onTempClick = handleSubmit((data: any) => {
    if (!validateEmpty()) {
      enqueueSnackbar('값을 입력해주세요', { variant: 'warning' });
      return;
    }
    isTemp
      ? updateTemp({
          request: {
            ...data.request,
            approvalList: data.request.approvalList.map((item, index) => ({
              ...item,
              seq: index + 1,
            })),
            dataId: Number(dataId) || 0,
            contentsType: BODY_TEMPLATE_TYPE.BASIC,
            menuId: Number(menuId) || 0,
            noteId: noteId,
            status: APPROVAL_DOCUMENT_STATUS.TEMPORARY,
            hasFileItemIdAddList: data.request.hasFileItemFileAddList.map((item) => item.id),
          },
          addFileItemList: data.fileItemList.filter((item) => !item.id),
        })
      : createApprovalDocument({
          ...data,
          request: {
            ...data.request,
            approvalList: data.request.approvalList.map((item, index) => ({
              ...item,
              seq: index + 1,
            })),
            dataId: Number(dataId) || 0,
            contentsType: BODY_TEMPLATE_TYPE.BASIC,
            menuId: Number(menuId) || 0,
            noteId: noteId,
            status: APPROVAL_DOCUMENT_STATUS.TEMPORARY,
            hasFileItemIdAddList: data.request.hasFileItemFileAddList.map((item) => item.id),
          },
        });
    setLeftDrawerState('');
  });

  const onDelete = () => {
    confirm({
      title: '초기화 확인',
      lineBreakChildren: [{ value: '임시 저장된 데이터를 초기화 하시겠습니까?' }],
      confirmText: '예',
      closeText: '아니오(그냥 닫기)',
      afterConfirm: () => {
        deleteTemp();
        setLeftDrawerState('');
      },
      afterClose: () => setLeftDrawerState(''),
    });
  };

  return (
    <Box sx={{ ...SX_FORM_WRAP }}>
      <Box width="200px">
        <DropDownUI
          defaultLabel={'기본 템플릿'}
          options={[
            { value: 1, label: '기본 템플릿' },
            { value: 2, label: '개선 결과 전/후 템플릿' },
          ]}
        />
      </Box>
      <Box
        display="flex"
        gap="10px"
      >
        <ButtonBasicUI onClick={onDelete}>{isTemp ? '취소 or 초기화' : '취소'}</ButtonBasicUI>
        <ButtonBasicUI onClick={onTempClick}>임시 저장</ButtonBasicUI>
        <ButtonBasicUI onClick={onSubmitClick}>결재 상신</ButtonBasicUI>
      </Box>
    </Box>
  );
}

const SX_FORM_WRAP = {
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  padding: '14px',
  alignItems: 'flex-start',
  background: 'var(--design-v-10-light-background-bg-03, #D8DCE2)',
};
