import { affiliatedCompanyPersonOutlineBasicRepository } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/basic/repository/repository';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { useLogic as useAffiliatedCompanyPersonDetailModalOutlineBasicLogic };

const useLogic = (menuId) => {
  const { modalId } = useAffiliatedCompanyPersonState(
    useShallow((state) => ({
      modalId: state.modalId,
    }))
  );
  const { isLoading, data: personOutlineBasic } =
    affiliatedCompanyPersonOutlineBasicRepository.usePersonOutlineBasicGet(modalId, menuId);
  const { run: onPersonOutlineBasicUpdate } =
    affiliatedCompanyPersonOutlineBasicRepository.usePersonOutlineBasicUpdate(menuId);
  const h = {
    onPersonOutlineBasicUpdate: (params) => {
      onPersonOutlineBasicUpdate({ ...params, id: modalId });
    },
  };
  const d = { personOutlineBasic };
  return { d, h, isLoading };
};
