import { affiliatedPersonAppBarMutation } from '@front/src/features/affiliated-person/features/app-bar/query/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedPersonAppBarQuery } from '@front/src/features/affiliated-person/features/app-bar/query/query';

export { repository as affiliatedPersonAppBarRepository };
const repository = {
  useCreate: () => {
    const { mutate } = affiliatedPersonAppBarMutation.useCreate();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useListByPersonNameGet: (params) => {
    const { data, remove } = affiliatedPersonAppBarQuery.useListByPersonNameGet(params);
    return {
      data,
      remove,
    };
  },
};
