import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import type { AccountingUploadEcountUploadParameter } from '@front/src/features/accounting/features/upload/features/ecount-data/types/parameter';

export { Context as AccountingUploadEcountDataUploadContext };

interface State {
  onTexAccountingCreate: (params: AccountingUploadEcountUploadParameter) => void;
  onBankAccountCreate: (params: AccountingUploadEcountUploadParameter) => void;
  onDailyReportCreate: (params: AccountingUploadEcountUploadParameter) => void;
  isLoading: boolean;
}

const Context = createContext<State>({
  onTexAccountingCreate: noOp,
  onBankAccountCreate: noOp,
  onDailyReportCreate: noOp,
  isLoading: false,
});
