import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AccountingSettingsManagementAccountingAccountTableCategoryListContext } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/provider/context';
import { AccountingSettingsManagementAccountingAccountContext } from '@front/src/features/accounting/features/settings/features/management-accounting-account/provider/context';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';

export { CategoryListCreateInput as AccountingSettingsManagementAccountingAccountTableCategoryListCreateInput };

interface Props {
  depth: number;
  parentId: number | null;
}

const CategoryListCreateInput = ({ depth, parentId }: Props) => {
  const { readOnly } = useContext(AccountingSettingsManagementAccountingAccountContext);
  const { onCreate } = useContext(
    AccountingSettingsManagementAccountingAccountTableCategoryListContext
  );
  const ref = useRef<HTMLDivElement>(null);
  const methods = useForm({
    values: {
      name: '',
    },
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    onCreate({
      ...data,
      sequence: null,
      alias: null,
      parentId,
      depth,
    });
  });
  const handleKeyDown = useCallback(
    async (e) => {
      if (e.isComposing || e.keyCode === 229) return;
      if (e.key !== 'Enter') return;
      await onSubmit();
    },
    [onSubmit]
  );
  useEffect(() => {
    if (!ref.current) return;
    (ref.current.children[0].children[0].children[0] as HTMLInputElement).focus();
  }, []);
  if (readOnly) {
    return null;
  }
  return (
    <div ref={ref}>
      <FormProvider {...methods}>
        <HookFormInput
          name="name"
          onKeyDown={handleKeyDown}
        />
      </FormProvider>
    </div>
  );
};
