import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import { useFormContext } from 'react-hook-form';
import DateAttr from '@front/src/components/ui-builder/DateAttr';
import NumberAttr from '@front/src/components/ui-builder/NumberAttr';
import TextAttr from '@front/src/components/ui-builder/TextAttr';
import type { WorkDetailView } from '@front/src/features/work/features/work/features/detail/types/view';
import Box from '@mui/material/Box';
import DatePickerWithHookForm from '@front/src/components/hook-form/DatePicker';
import { YY_MM_DD } from '@front/src/utils';
import EditorUI from '@front/src/components/components-with-design/compound/EditorUI';
import 'react-quill/dist/quill.snow.css';

const workDetailCellComponent = {
  18: ({ ...rest }: CellComponentProps) => <WorkDetailTableRowDate {...rest} />,
  19: ({ ...rest }: CellComponentProps) => <WorkDetailTableRowUserName {...rest} />,
  20: ({ ...rest }: CellComponentProps) => <WorkDetailTableRowContent {...rest} />,
  95: ({ ...rest }: CellComponentProps) => <WorkDetailTableRowNumberAttr1 {...rest} />,
  96: ({ ...rest }: CellComponentProps) => <WorkDetailTableRowTextAttr1 {...rest} />,
  97: ({ ...rest }: CellComponentProps) => <WorkDetailTableRowDateAttr1 {...rest} />,
  98: ({ ...rest }: CellComponentProps) => <WorkDetailTableRowNumberAttr2 {...rest} />,
  99: ({ ...rest }: CellComponentProps) => <WorkDetailTableRowTextAttr2 {...rest} />,
  100: ({ ...rest }: CellComponentProps) => <WorkDetailTableRowDateAttr2 {...rest} />,
  101: ({ ...rest }: CellComponentProps) => <WorkDetailTableRowNumberAttr3 {...rest} />,
  102: ({ ...rest }: CellComponentProps) => <WorkDetailTableRowTextAttr3 {...rest} />,
  103: ({ ...rest }: CellComponentProps) => <WorkDetailTableRowDateAttr3 {...rest} />,
};

export default workDetailCellComponent;

interface Props extends CellComponentProps<WorkDetailView> {}

const WorkDetailTableRowDate = ({ isEditMode, onSubmit, sx = {}, onClick }: Readonly<Props>) => {
  const { getValues } = useFormContext();
  const getContent = () => {
    if (isEditMode) {
      return (
        <DatePickerWithHookForm
          name="date"
          onSubmit={onSubmit}
        />
      );
    }
    return getValues('date').format(YY_MM_DD);
  };
  return (
    <Box
      onClick={onClick}
      sx={{
        ...sx,
      }}
    >
      {getContent()}
    </Box>
  );
};

const WorkDetailTableRowUserName = ({ item, onClick, sx = {} }: Readonly<Props>) => (
  <Box
    onClick={onClick}
    sx={sx}
  >
    {item.user?.name}
  </Box>
);

function WorkDetailTableRowContent({
  isEditMode,
  onSubmit,
  sx = {},
  dataId,
  menuId,
  sectionId,
}: Readonly<Props>) {
  const { watch, getValues, setValue } = useFormContext();
  const update = ({ beforeContent }) => {
    setValue('content', beforeContent);
    onSubmit?.();
  };
  if (isEditMode) {
    return (
      <EditorUI
        value={{
          id: getValues('id'),
          beforeContent: watch('content'),
        }}
        update={update}
        menuId={menuId}
        dataId={dataId}
        sectionId={sectionId}
      />
    );
  }
  return (
    <div className="quill">
      <div
        className="ql-container ql-snow"
        style={{
          border: 'none',
        }}
      >
        <div className="ql-editor">
          <div
            dangerouslySetInnerHTML={{
              __html: getValues('content'),
            }}
          />
        </div>
      </div>
    </div>
  );
}

const WorkDetailTableRowNumberAttr1 = ({ ...rest }: Readonly<Props>) => (
  <NumberAttr
    name="numberAttr1"
    {...rest}
  />
);

const WorkDetailTableRowTextAttr1 = ({ ...rest }: Readonly<Props>) => (
  <TextAttr
    name="textAttr1"
    {...rest}
  />
);

const WorkDetailTableRowDateAttr1 = ({ ...rest }: Readonly<Props>) => (
  <DateAttr
    name="dateAttr1"
    {...rest}
  />
);

const WorkDetailTableRowNumberAttr2 = ({ ...rest }: Readonly<Props>) => (
  <NumberAttr
    name="numberAttr2"
    {...rest}
  />
);

const WorkDetailTableRowTextAttr2 = ({ ...rest }: Readonly<Props>) => (
  <TextAttr
    name="textAttr2"
    {...rest}
  />
);

const WorkDetailTableRowDateAttr2 = ({ ...rest }: Readonly<Props>) => (
  <DateAttr
    name="dateAttr2"
    {...rest}
  />
);

const WorkDetailTableRowNumberAttr3 = ({ ...rest }: Readonly<Props>) => (
  <NumberAttr
    name="numberAttr3"
    {...rest}
  />
);

const WorkDetailTableRowTextAttr3 = ({ ...rest }: Readonly<Props>) => (
  <TextAttr
    name="textAttr3"
    {...rest}
  />
);

const WorkDetailTableRowDateAttr3 = ({ ...rest }: Readonly<Props>) => (
  <DateAttr
    name="dateAttr3"
    {...rest}
  />
);
