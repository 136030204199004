import * as yup from 'yup';
import { useFormContext } from 'react-hook-form';
import type React from 'react';
import { useCallback } from 'react';
import { useSnackbar } from '@front/src/features/snackbar';

interface Props {
  type: string;
  name: string;
}

export const useInput = ({ type, name }: Props) => {
  const { control, setValue } = useFormContext();
  const { show } = useSnackbar();

  const maxLengthValidationSchema = yup.string().max(255);
  const minValidationSchema = yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0);

  const validateString = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, currentVal: string) => {
      if (type === 'text') {
        try {
          maxLengthValidationSchema.validateSync(e.target.value);
        } catch (error) {
          setValue(name, currentVal);
          show({ message: '255자 미만으로 입력하세요.' });
        }
      }
    },
    [type, maxLengthValidationSchema, name, setValue, show]
  );

  const validateNumber = useCallback(
    (e: React.FocusEvent<HTMLInputElement>, value: any, currentVal: string) => {
      let isPositive = true;
      if (type === 'number') {
        if (value !== '0' && value !== '') setValue(name, String(Number(value)));
        try {
          minValidationSchema.validateSync(e.target.value);
        } catch (error) {
          setValue(name, currentVal);
          show({ message: '0이상의 숫자만 입력하세요.' });
          isPositive = false;
        }
      }
      return isPositive;
    },
    [type, minValidationSchema, name, setValue, show]
  );

  return {
    control,
    validateString,
    validateNumber,
    setValue,
  };
};
