import React, { useCallback } from 'react';
import { Checkbox, Radio } from '@mui/material';
import {
  type FieldValues,
  useController,
  type UseFieldArrayAppend,
  type UseFieldArrayRemove,
  type UseFieldArrayReplace,
  useFormContext,
} from 'react-hook-form';
import type { UserVO } from '@front/user/domain';

interface Props {
  item: UserVO;
  multi: boolean;
  append: UseFieldArrayAppend<FieldValues, 'selectedList'>;
  remove: UseFieldArrayRemove;
  onChange: (value: UserVO | UserVO[] | undefined) => void;
  replace: UseFieldArrayReplace<FieldValues, 'selectedList'>;
}

export default function UserSelectorUserInput({
  item,
  multi,
  append,
  remove,
  onChange,
  replace,
}: Readonly<Props>) {
  const { control } = useFormContext();
  const {
    field: { value: selectedList },
  } = useController({ control, name: 'selectedList' });
  const getChecked = useCallback(
    (id) => selectedList.map((s) => s.id).includes(id),
    [selectedList]
  );
  const handleCheckboxChange = useCallback(() => {
    const index = selectedList.findIndex((s) => s.id === item.id);
    const copy = [...selectedList];
    if (index > -1) {
      remove(index);
      copy.splice(index, 1);
    } else {
      append(item);
      copy.push(item);
    }
    onChange(copy);
  }, [append, remove, selectedList, item, onChange]);
  const handleRadioChange = useCallback(() => {
    replace([item]);
    onChange(item);
  }, [item, onChange, replace]);
  if (multi) {
    return (
      <Checkbox
        checked={getChecked(item.id)}
        onChange={handleCheckboxChange}
      />
    );
  }
  return (
    <Radio
      checked={getChecked(item.id)}
      onChange={handleRadioChange}
    />
  );
}
