import { useQuery } from 'react-query';
import { projectSalesInfoAbstractApi } from '@front/src/features/project-sales-info/features/abstract/query/api';
import type { SmProjectBriefEstimationView } from '@front/src/features/project-sales-info/features/abstract/features/estimation/types/view';

export { query as ProjectSalesInfoAbstractEstimationQuery };
const query = {
  useDetailGet: (id?) => {
    const { data } = useQuery<SmProjectBriefEstimationView>({
      queryKey: ['project', 'sales-info', 'abstract', 'estimation', id],
      queryFn: () => projectSalesInfoAbstractApi.getEstimation(id),
      enabled: !!id,
      suspense: true,
    });
    return {
      data,
    };
  },
};
