import { useQuery } from 'react-query';
import type { AffiliatedCompanyCharacterOpinionView } from '@front/src/features/affiliated-company/features/character/features/opinion/types/view';
import { affiliatedCompanyCharacterOpinionApi } from '@front/src/features/affiliated-company/features/character/features/opinion/query/api';

export const affiliatedCompanyCharacterOpinionQuery = {
  useCharacterOpinionGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyCharacterOpinionView[]>({
      queryKey: ['affiliated-company', 'character', 'opinion', id],
      queryFn: () => affiliatedCompanyCharacterOpinionApi.getCharacterOpinion(id, menuId),
      enabled: !!id && !!menuId,
      cacheTime: 0,
    });
    return {
      data,
      isLoading,
    };
  },
};
