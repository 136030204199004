import React from 'react';
import { Box, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { OldTh, Table } from '@front/layouts/Table';
import type { AttachedFileId } from '@front/ost_proposal/domain';
import { ProposalStatus } from '@front/ost_proposal/domain';
import Button from '@front/layouts/Button';
import { fileToView } from '@front/file-item';
import type { AttachedFileParameter } from '@front/ost_proposal/parameter';
import useDialog from '@front/dialog/hook';
import Tooltip from '@front/components/Tooltip';
import { fileTypeText } from '@front/util/CommonText';
import FileList from '@front/ost_proposal/view/detail/attached_file/FileList';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { ArticleItem } from '@front/src/components/article-item';

interface Props {
  addAttachedFile: (params: AttachedFileParameter) => void;
  deleteAttachedFile: (id: AttachedFileId) => void;
  isContributor: boolean;
}

function AttachedFileLayout({ addAttachedFile, deleteAttachedFile, isContributor }: Props) {
  const { confirm } = useDialog();
  const status = useSelector((root: RootState) => root.proposal.detail!.status, shallowEqual);
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        width: '100%',
        padding: '10px',
      }}
    >
      <ArticleItem
        title="첨부 파일"
        buttonsNode={
          <>
            {status === ProposalStatus.WRITING && isContributor && (
              <Tooltip title={fileTypeText}>
                <Button component="label">
                  파일 선택
                  <input
                    hidden
                    type="file"
                    onChange={(e) => {
                      if (!e.target || !e.target.files || e.target.files.length === 0) {
                        return;
                      }
                      confirm({
                        children: '해당 첨부 파일을 등록하시겠습니까?',
                        confirmText: '확인',
                        afterConfirm: () => {
                          addAttachedFile({
                            file: fileToView(e.target.files![0]),
                          });
                          e.target.value = '';
                        },
                        afterClose: () => {
                          e.target.value = '';
                        },
                      });
                    }}
                  />
                </Button>
              </Tooltip>
            )}
          </>
        }
        children={
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <OldTh>날짜</OldTh>
                  <OldTh>부서</OldTh>
                  <OldTh>기록자</OldTh>
                  <OldTh colSpan={2}>파일명</OldTh>
                </TableRow>
              </TableHead>
              <TableBody>
                <FileList
                  deleteAttachedFile={deleteAttachedFile}
                  isContributor={isContributor}
                />
              </TableBody>
            </Table>
          </TableContainer>
        }
      />
    </Box>
  );
}

export default AttachedFileLayout;
