import type { FocusEvent } from 'react';
import React, { memo } from 'react';
import { Box } from '@mui/material';
import type { FormikProps } from 'formik';

import Input from '@front/layouts/Input';

import Button from '@front/layouts/Button';
import type { CampaignMemoAddParameter } from '@front/ost_campaign/parameter';
import type { DefaultFunction } from '@front/type/Function';
import type { UserId } from '@front/user/domain';

interface Props {
  setFormHeight: (formHeight: number) => void;
  formik: FormikProps<CampaignMemoAddParameter>;

  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  onChange: (list: UserId | UserId[] | undefined) => void;
  onClick: DefaultFunction;
}

const CampaignMemoForm = ({ formik, onBlur, onClick }: Props) => (
  <Box
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexWrap: 'unwrap',
          padding: '0 10px',
        }}
      >
        <Input
          required
          multiline
          key={formik.values.description}
          defaultValue={formik.values.description ?? ''}
          variant="outlined"
          placeholder="메모 입력"
          onBlur={onBlur}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          padding: '10px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexWrap: 'unwrap',
          justifyContent: 'space-between',
          padding: '0 10px',
          flex: 1,
          alignItems: 'center',
        }}
      >
        <Button
          disabled={formik.isSubmitting}
          fullWidth
          onClick={onClick}
        >
          작성 완료
        </Button>
      </Box>
    </Box>
  </Box>
);

const FormMemo = memo(CampaignMemoForm);

export default FormMemo;
