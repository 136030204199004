import React from 'react';
import type { PopoverProps } from '@mui/material/Popover';
import Popover from '@mui/material/Popover';

interface Props extends Omit<PopoverProps, 'open'> {
  onClose: () => void;
  anchorEl: HTMLElement | null;
}

export default function PopoverUI({ children, anchorEl, onClose }: Props) {
  const open = Boolean(anchorEl);
  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: 'none',
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onClose}
      disableRestoreFocus
    >
      {children}
    </Popover>
  );
}
