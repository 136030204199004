import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import FormMemo from '@front/ost_campaign/view/memo/form';
import type { CampaignMemoAddParameter } from '@front/ost_campaign/parameter';
import { campaignAction } from '@front/ost_campaign/action';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';

const FormService = () => {
  const dispatch = useDispatch();

  const openSnackbar = useCallback(
    (message, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );

  const addMemo = useCallback(
    (params: CampaignMemoAddParameter) => {
      dispatch(campaignAction.addMemo(params));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    (values) => {
      if (!values.description.trim()) {
        openSnackbar('내용을 입력해주세요.');
        formik.resetForm();
        return;
      }
      addMemo(values);
      formik.resetForm();
    },
    [addMemo]
  );

  const formik = useFormik({
    initialValues: {
      description: '',
      attendanceList: [],
    } as CampaignMemoAddParameter,
    onSubmit: onSubmit,
  });

  const setFormHeight = useCallback(
    (formHeight: number) => {
      dispatch(campaignAction.setFormHeight(formHeight));
    },
    [dispatch]
  );

  const onBlur = useCallback(
    (e) => {
      const value = e.target.value || undefined;
      if (value !== formik.values.description) {
        formik.setFieldValue('description', value);
      }
    },
    [formik]
  );

  const onChange = useCallback(
    (list) => {
      formik.setFieldValue('attendanceList', list);
    },
    [formik]
  );

  const onClick = useCallback(() => {
    formik.handleSubmit();
  }, [formik]);

  return (
    <FormikProvider value={formik}>
      <FormMemo
        formik={formik}
        setFormHeight={setFormHeight}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
      />
    </FormikProvider>
  );
};

const FormMemoService = memo(FormService);

export default FormMemoService;
