import { create } from 'zustand';

interface State {
  isCreateModalOpen: boolean;
  isDetailModalOpen: boolean;
  modalId?: number;
  readOnly?: boolean;
}

interface Actions {
  setReadOnly: (readOnly?: boolean) => void;
  setCreateModalIsOpen: (isCreateModalOpen: boolean) => void;
  setDetailModalIsOpen: (isDetailModalOpen: boolean) => void;
  setModalId: (modalId?: number) => void;
}

export { useState as useAffiliatedCompanyPersonState };

const useState = create<State & Actions>((set) => ({
  isCreateModalOpen: false,
  isDetailModalOpen: false,
  setCreateModalIsOpen: (isCreateModalOpen) => set(() => ({ isCreateModalOpen })),
  setDetailModalIsOpen: (isDetailModalOpen) => set(() => ({ isDetailModalOpen })),
  setModalId: (modalId) => set(() => ({ modalId })),
  readOnly: false,
  setReadOnly: (readOnly) => set(() => ({ readOnly })),
}));
