import React from 'react';
import SectionLayout from '@front/personnel/component/Section';
import ProposalHistoryTable from '@front/personnel/view/Page/Log/Proposal/Table';

const ProposalHistoryLayout = () => (
  <SectionLayout title="제안 이력">
    <ProposalHistoryTable />
  </SectionLayout>
);

export default ProposalHistoryLayout;
