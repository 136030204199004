import React from 'react';
import { Box, Typography } from '@mui/material';
import HookFormMention from '@front/src/components/mention-with-hook-form/hook-form-mention';
import Button from '@front/layouts/Button';
import DrawerCommentItem from '@front/src/components/drawer-comment/DrawerCommentItem';
import type { CommentView } from '@front/src/features/note/types/view';
import type { CommentUpdateParams } from '@front/src/features/note/types/parameter';
import { HookFormTextarea } from '@front/src/components/textarea-with-hook-form/textarea-with-hook-form';
import { useSnackbar } from 'notistack';
import { useFormContext } from 'react-hook-form';

interface SectionProps {
  children: React.ReactNode;
}

interface CountProps {
  text: string;
  count: number;
}

interface InputProps {
  onSubmit: () => void;
  userList?: { id: number; display: string }[];
  isMention?: boolean;
  setMentionList?: (mentionList: number[]) => void;
  clickedReviewer?: string;
}

interface ListProps {
  comments?: CommentView[];
  onDelete: (commentId: number) => void;
  onUpdate: (params: CommentUpdateParams) => void;
}

const DrawerCommentSection = ({ children }: SectionProps) => {
  return <Box>{children}</Box>;
};

const CommentCount = ({ count, text }: CountProps) => (
  <Typography sx={{ fontWeight: 600, margin: '10px 0' }}>
    {text} {count}건
  </Typography>
);

const CommentInput = ({
  onSubmit,
  userList,
  isMention,
  setMentionList,
  clickedReviewer,
}: InputProps) => {
  const { getValues } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();
  const onClick = () => {
    if (!getValues('comment')) {
      enqueueSnackbar('댓글을 입력해주세요.', { variant: 'warning' });
      return;
    }

    onSubmit();
  };

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        gap="10px"
      >
        {isMention ? (
          <HookFormMention
            name="comment"
            userList={userList ?? []}
            setMentionList={setMentionList}
            clickedReviewer={clickedReviewer}
          />
        ) : (
          <HookFormTextarea
            name="comment"
            sx={{ height: 63 }}
            resize="none"
          />
        )}
        <Box textAlign="right">
          <Button onClick={onClick}>등록</Button>
        </Box>
      </Box>
    </Box>
  );
};

const CommentList = ({ comments, onDelete, onUpdate }: ListProps) => (
  <Box
    padding="10px 0px 10px 10px"
    display="flex"
    flexDirection="column"
    gap="10px"
  >
    {comments?.map((comment, index) => (
      <DrawerCommentItem
        key={index}
        item={comment}
        onDelete={onDelete}
        onUpdate={onUpdate}
      />
    ))}
  </Box>
);

DrawerCommentSection.Count = CommentCount;
DrawerCommentSection.Input = CommentInput;
DrawerCommentSection.List = CommentList;

export default DrawerCommentSection;
