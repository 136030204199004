import React from 'react';
import type { SectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyWidget from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/widgets/widget';
import ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonWidget from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/widgets/widget';
import ProjectSalesInfoAffiliatedCompanyWebHardWidget from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/widgets/widget';

export { sectionComponent as projectSalesInfoAffiliatedCompanySectionComponent };

const sectionComponent: SectionComponent = {
  22: ({ ...rest }) => <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyWidget {...rest} />,
  23: ({ ...rest }) => <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonWidget {...rest} />,
  24: ({ ...rest }) => <ProjectSalesInfoAffiliatedCompanyWebHardWidget {...rest} />,
};
