import React, { createRef, useCallback, useState } from 'react';
import { OldTd, Table } from '@front/layouts/Table';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import Checkbox from '@front/layouts/Checkbox';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import Button from '@front/layouts/Button';
import { useFormContext, useWatch } from 'react-hook-form';
import { spliceAndPushIfExist } from '@front/src/features/affiliated-company/utils';
import classes from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form-participant.module.scss';
import { AffiliatedCompanyMeetingHistoryModalFormParticipantSelector } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form-participant-selector';
import { useAffiliatedCompanyMeetingHistoryState } from '@front/src/features/affiliated-company/features/meeting-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { FormParticipant as AffiliatedCompanyMeetingHistoryModalFormParticipant };

const FormParticipant = () => {
  const { Layout, Header, Body } = AddDeleteTable;
  const { setValue, getValues, control } = useFormContext();
  const [idList, setIdList] = useState<number[]>([]);
  const participantList = useWatch({ name: 'participantList', control });
  const handleDelete = useCallback(() => {
    const requester = getValues('requester');
    setValue(
      'participantList',
      [...participantList].filter((item) => !idList.includes(item.id))
    );
    if (requester && idList.includes(requester.id)) {
      setValue('requester', undefined);
    }
    setIdList([]);
  }, [idList, setValue, getValues, setIdList, participantList]);
  const inputRef = createRef<HTMLInputElement>();
  const handleCreate = useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.click();
    }
  }, [inputRef]);
  const handleCheckboxChange = useCallback(
    (id) => () => {
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  const { readOnly } = useAffiliatedCompanyMeetingHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Layout>
      <Header>
        <div className={classes.display__none}>
          <AffiliatedCompanyMeetingHistoryModalFormParticipantSelector inputRef={inputRef} />
        </div>
        <Button
          onClick={handleDelete}
          disabled={readOnly}
        >
          선택삭제
        </Button>
        <Button
          onClick={handleCreate}
          disabled={readOnly}
        >
          추가
        </Button>
      </Header>
      <Body>
        <TableContainer>
          <Table>
            <TableHeader />
            <TableBody>
              {participantList.length === 0 && (
                <TableRow>
                  <OldTd colSpan={7}>참석자가 없습니다.</OldTd>
                </TableRow>
              )}
              {participantList?.length > 0 &&
                participantList.map((item, index) => (
                  <TableRow key={item.id}>
                    <OldTd>
                      <Checkbox
                        checked={idList.includes(item.id)}
                        onChange={handleCheckboxChange(item.id)}
                        disabled={readOnly}
                      />
                    </OldTd>
                    <OldTd>{index + 1}</OldTd>
                    <OldTd>{item.affiliatedCompany?.name}</OldTd>
                    <OldTd>{item.name}</OldTd>
                    <OldTd>{item.position}</OldTd>
                    <OldTd>{item.phoneNumber}</OldTd>
                    <OldTd>{item.email}</OldTd>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Body>
    </Layout>
  );
};

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <Th
        center
        width="12%"
        colSpan={2}
      >
        참석자
      </Th>
      <Th
        center
        width="12%"
      >
        회사명
      </Th>
      <Th
        center
        width="12%"
      >
        인물
      </Th>
      <Th
        center
        width="12%"
      >
        직급
      </Th>
      <Th
        center
        width="10%"
      >
        핸드폰
      </Th>
      <Th
        center
        width="12%"
      >
        이메일
      </Th>
    </TableRow>
  </TableHead>
);
