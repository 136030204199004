import { default as axios } from '@front/src/config/axios';

import type { OstMemoSearchQuery, OstSearchQuery } from '@front/ost/query';
import type {
  OstAddParameter,
  OstMemoAddParameter,
  OstMemoUpdateParameter,
  OstTrimmedMeanBottomRateUpdateParameter,
  OstTrimmedMeanTopRateUpdateParameter,
  OstUpdateNameParameter,
  OstUpdatePeriodParameter,
  OstUpdateStatusParameter,
  OstUpdateTotalBudgetParameter,
} from '@front/ost/parameter';
import type { OstId, OstMemoId, OstShortVO, OstVO } from '@front/ost/domain';
import type { CampaignVO } from '@front/ost_campaign/domain';

const ostApi = {
  getList: async (query: OstSearchQuery): Promise<OstShortVO[]> => {
    const { data } = await axios.get(
      `/api/ost/management?keyword=${query.keyword}&status=${query.status}`
    );
    return data;
  },
  getCampaignList: async (id: OstId): Promise<CampaignVO[]> => {
    const { data } = await axios.get(`/api/ost/management/${id}/campaign-list`);
    return data;
  },
  add: async (params: OstAddParameter): Promise<OstVO> => {
    const { data } = await axios.post(`/api/ost/management`, params);
    return data;
  },
  getDetail: async (id: OstId): Promise<OstVO> => {
    const { data } = await axios.get(`/api/ost/management/${id}`);
    return data;
  },
  updateName: async (id: OstId, params: OstUpdateNameParameter): Promise<void> => {
    const { data } = await axios.put(`/api/ost/management/${id}/name`, params);
    return data;
  },
  updatePeriod: async (id: OstId, params: OstUpdatePeriodParameter): Promise<void> => {
    const { data } = await axios.put(`/api/ost/management/${id}/period`, params);
    return data;
  },
  updateTotalBudget: async (id: OstId, params: OstUpdateTotalBudgetParameter): Promise<void> => {
    const { data } = await axios.put(`/api/ost/management/${id}/total-budget`, params);
    return data;
  },
  updateStatus: async (id: OstId, params: OstUpdateStatusParameter): Promise<void> => {
    const { data } = await axios.put(`/api/ost/management/${id}/status`, params);
    return data;
  },
  delete: async (id: OstId): Promise<void> => {
    const { data } = await axios.delete(`/api/ost/management/${id}`);
    return data;
  },
  addMemo: async (id: OstId, params: OstMemoAddParameter): Promise<void> => {
    const { data } = await axios.post(`/api/ost/${id}/memo`, params);
    return data;
  },
  getMemoList: async (id: OstId, query: OstMemoSearchQuery) => {
    const { data } = await axios.get(`/api/ost/${id}/memo?keyword=${query.keyword}`);
    return data;
  },
  updateMemo: async (id: OstId, params: OstMemoUpdateParameter) => {
    const { data } = await axios.put(`/api/ost/${id}/memo/${params.id}`, params);
    return data;
  },
  deleteMemo: async (ostId: OstId, ostMemoId: OstMemoId) => {
    const { data } = await axios.delete(`/api/ost/${ostId}/memo/${ostMemoId}`);
    return data;
  },
  updateTrimmedMeanBottomRate: async (
    id: OstId,
    params: OstTrimmedMeanBottomRateUpdateParameter
  ) => {
    const { data } = await axios.put(`/api/ost/management/${id}/trimmed-mean-bottom-rate`, params);
    return data;
  },
  updateTrimmedMeanTopRate: async (id: OstId, params: OstTrimmedMeanTopRateUpdateParameter) => {
    const { data } = await axios.put(`/api/ost/management/${id}/trimmed-mean-top-rate`, params);
    return data;
  },
};

export default ostApi;
