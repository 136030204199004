import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type {
  WorkOutputContentUpdateParameter,
  WorkOutputFileUpdateParameter,
} from '@front/src/features/work/features/work/features/output/types/parameter';
import type { CommonGetParameter } from '@front/src/types/parameter';

const workOutputMutation = {
  useAddTop: (bundleId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: () => workWorkApi.createOutputTop(bundleId, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle', 'output', bundleId],
        });

        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },

  useAddBottom: (bundleId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: () => workWorkApi.createOutputBottom(bundleId, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle', 'output', bundleId],
        });

        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },

  useCreate: (bundleId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.createOutput(id, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle', 'output', bundleId],
        });

        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },

  useUpdate: (bundleId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: WorkOutputContentUpdateParameter) =>
        workWorkApi.updateOutput(params, menuId),
      // onMutate: async (data: WorkOutputContentUpdateParameter) => {
      //   const { data: previousDataList } =
      //     (queryClient.getQueryData(['work', 'output-bundle', bundleId, 'output']) as any) ?? {};
      //   const newData = convertEmptyToNull(data);
      //   const getIsSame = (index: number) => {
      //     const previousData = previousDataList[index]?.outputList?.find(
      //       (item) => item?.id === newData.id
      //     );
      //     const prevData = convertEmptyToNull(previousData);
      //     return newData.content === prevData?.content;
      //   };
      //
      //   return getIsSame;
      // },
      onSuccess: async (data, _) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle', bundleId, 'output'],
        });

        return { data };
      },
    });
    return {
      mutate,
      isLoading,
    };
  },

  useUpdateFile: (bundleId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: WorkOutputFileUpdateParameter) =>
        workWorkApi.updateOutputFile(params, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle', 'output', bundleId],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'carousel'],
        });

        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },

  useSeqUp: (bundleId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.updateOutputSeqUp(id, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle', 'output', bundleId],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'carousel'],
        });
        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },

  useSeqDown: (bundleId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.updateOutputSeqDown(id, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle', 'output', bundleId],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'carousel'],
        });
        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },

  useDelete: (bundleId: number, params: CommonGetParameter) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.deleteOutput(id, params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle', 'output', bundleId],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'carousel'],
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },

  useDeleteAll: (bundleId: number, params: CommonGetParameter) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: () => workWorkApi.deleteAllOutput(bundleId, params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle', 'output', bundleId],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'carousel'],
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
};

export default workOutputMutation;
