import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workDetailMutation from '@front/src/features/work/features/work/features/detail/query/mutation';

const useWorkDetailAddBottom = (workId: number, menuId) => {
  const callback = getNoOpCallback();
  const { mutate, isLoading } = workDetailMutation.useAddBottom(workId, menuId);
  return {
    run: () => mutate(undefined, callback),
    setCallback: getSetCallbackFunc(callback),
    isLoading,
  };
};

export default useWorkDetailAddBottom;
