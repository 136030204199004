import React, { useCallback, useMemo } from 'react';
import { TableRow } from '@mui/material';
import {
  downloadFile,
  getChecked,
  getLocaleStringIfExist,
  getValueIfExist,
} from '@front/src/utils';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoContractContractState } from '@front/src/features/project-sales-info/features/contract/features/contract/widgets/useState';
import type { ProjectContractContractView } from '@front/src/features/project-sales-info/features/contract/features/contract/types/view';
import { spliceAndPushIfExist } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/utils/constants';
import { useProjectSalesInfoContractContractUpdateModalState } from '@front/src/features/project-sales-info/features/contract/features/contract/features/update-modal/widgets/useState';
import { OldTd } from '@front/layouts/Table';
import Checkbox from '@front/layouts/Checkbox';
import { convertIsConfirmed } from '@front/src/features/project-sales-info/features/estimation/features/estimation/utils';
import classes from '@front/src/features/project-sales-info/features/contract/features/contract/components/contract-table-row.module.scss';
import { useShallow } from 'zustand/react/shallow';

export { ContractTableRow as ProjectSalesInfoContractContractTableRow };

interface Props {
  item: ProjectContractContractView;
}

const ContractTableRow = ({ item }: Props) => {
  const { idList, setIdList } = useProjectSalesInfoContractContractState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { setIsOpen, setId } = useProjectSalesInfoContractContractUpdateModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      setId: state.setId,
    }),
    shallow
  );
  const checked = useMemo(() => getChecked(item.id, idList), [item.id, idList]);
  const handleCheckboxChange = useCallback(() => {
    setIdList(spliceAndPushIfExist(idList, item.id));
  }, [setIdList, idList, item.id]);
  const { readOnly } = useProjectSalesInfoContractContractState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const handleCodeClick = useCallback(() => {
    setId(item.id);
    setIsOpen(true);
  }, [setIsOpen, setId, item.id]);

  return (
    <TableRow>
      <OldTd>
        <Checkbox
          checked={checked}
          onChange={handleCheckboxChange}
          disabled={readOnly}
        />
      </OldTd>
      <OldTd>
        <div
          className={classes.code}
          onClick={handleCodeClick}
          aria-hidden
        >
          {getValueIfExist(item.seq)}
        </div>
      </OldTd>
      <OldTd>{convertIsConfirmed(item.isConfirmed)}</OldTd>
      <OldTd>{getValueIfExist(item.category)}</OldTd>
      <OldTd>{getValueIfExist(item.orderCompany?.name)}</OldTd>
      <OldTd>{getValueIfExist(item.experimentInformation)}</OldTd>
      <OldTd>{getLocaleStringIfExist(item.totalAmount)}</OldTd>
      <OldTd>{getValueIfExist(item.schedule)}</OldTd>
      <OldTd>{convertIsConfirmed(item.isCm)}</OldTd>
      <OldTd>
        {item.pdf?.id ? (
          <div
            className={classes.file}
            onClick={downloadFile(item.pdf?.id)}
            aria-hidden
          >
            {item.pdf?.filename}
          </div>
        ) : (
          '-'
        )}
      </OldTd>
      <OldTd>{getValueIfExist(item.note)}</OldTd>
    </TableRow>
  );
};
