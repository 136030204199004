import { baseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  deletePerson: (id) => `${baseUrl}/person/${id}`,
};

export const affiliatedCompanyPersonDetailModalApi = {
  deletePerson: async (id: number, menuId?) => {
    const { data } = await axios.delete(url.deletePerson(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
