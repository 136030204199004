import { default as axios } from '@front/src/config/axios';

import { accountingTaxAccountingURL } from '@front/src/features/accounting/features/settings/utils/constant';

export { Api as AccountingSettingsTexAccountingAccountApi };

const url = {
  getAll: () => `${accountingTaxAccountingURL}/get-all`,
  getCount: () => `${accountingTaxAccountingURL}/get-count`,
  getCreatedAt: () => `${accountingTaxAccountingURL}/created-at`,
};

const Api = {
  getAll: async () => {
    const { data } = await axios.get(url.getAll());
    return data;
  },
  getCount: async () => {
    const { data } = await axios.get(url.getCount());
    return data;
  },
  getCreatedAt: async () => {
    const { data } = await axios.get(url.getCreatedAt());
    return data;
  },
};
