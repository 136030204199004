import React from 'react';
import type { SectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import ProjectSalesInfoBidInfoWidget from '@front/src/features/project-sales-info/features/bid/features/bid-information/widgets/widget';
import ProjectSalesInfoBidResultWidget from '@front/src/features/project-sales-info/features/bid/features/bid-result/widgets/widget';

export { sectionComponent as projectSalesInfoBidSectionComponent };

const sectionComponent: SectionComponent = {
  20: ({ ...rest }) => <ProjectSalesInfoBidInfoWidget {...rest} />,
  21: ({ ...rest }) => <ProjectSalesInfoBidResultWidget {...rest} />,
};
