import React from 'react';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import {
  FieldValues,
  UseFieldArrayAppend,
  UseFieldArrayMove,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form';
import ApprovalStepControlPanel from '@front/src/features/drawer-approval/components/ApprovalStepControlPanel';
import useApprovalStore from '@front/src/features/drawer-approval/useState';
import UserSelectorInputFeature from '@front/src/features/user-selector/features/input';
import userSelectorRepository from '@front/src/features/user-selector/repository/repository';
import { useCustomDialog } from '@front/src/features/dialog';

interface Props {
  index: number;
  append: UseFieldArrayAppend<FieldValues, 'request.approvalList'>;
  remove: UseFieldArrayRemove;
  move: UseFieldArrayMove;
  id: string;
}

export default function ApprovalStepTableRow({ index, append, remove, move, id }: Props) {
  const { setValue, watch, getValues } = useFormContext();
  const editOpenList = useApprovalStore((state) => state.editOpenList);
  const isEditOpen = editOpenList.includes(id);
  const currentUserId = watch(`request.approvalList.${index}.userId`);
  const { data: user } = userSelectorRepository.useGetUser(currentUserId);
  const { alert } = useCustomDialog();

  return (
    <TableRowUI
      sx={{
        '& .MuiTableCell-root': { padding: '0' },
      }}
    >
      <TableCellUI>
        <ApprovalStepControlPanel
          append={append}
          remove={remove}
          move={move}
          index={index}
          isEditOpen={isEditOpen}
          id={id}
        />
      </TableCellUI>
      <TableCellUI>{index + 1}차 결재자</TableCellUI>
      <TableCellUI>{user?.department.name ?? '-'}</TableCellUI>
      <TableCellUI>{user?.role.name ?? '-'}</TableCellUI>
      <TableCellUI>
        <UserSelectorInputFeature
          value={currentUserId}
          onChange={(value) => {
            if (getValues('request.referenceList').find((item) => item.userId === value)) {
              alert({
                children: [{ value: '참조자를 결재자로 선택할 수 없습니다.' }],
                title: '선택 에러',
                closeText: '닫기',
              });
              return;
            }
            setValue(`request.approvalList.${index}.userId`, value);
          }}
        />
      </TableCellUI>
    </TableRowUI>
  );
}
