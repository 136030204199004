import React, { useContext, useEffect } from 'react';
import { OldTd, Table } from '@front/layouts/Table';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { useFormContext, useWatch } from 'react-hook-form';
import Button from '@front/layouts/Button';
import { AffiliatedCompanyMeetingHistoryModalContext } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/widgets/context';
import classes from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form-companion-table.module.scss';
import { useAffiliatedCompanyMeetingHistoryState } from '@front/src/features/affiliated-company/features/meeting-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

interface Props {
  onClick: () => void;
}

export { FormCompanionTable as AffiliatedCompanyMeetingHistoryModalFormCompanionTable };
const FormCompanionTable = ({ onClick }: Props) => {
  const {
    d: { userList },
  } = useContext(AffiliatedCompanyMeetingHistoryModalContext);
  const { control, setValue } = useFormContext();
  const companionList = useWatch({ name: 'companionList', control });
  const { readOnly } = useAffiliatedCompanyMeetingHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  useEffect(() => {
    if (!userList) {
      return;
    }
    setValue('companionList', userList);
  }, [setValue, userList]);
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <Th width="140px">동행자</Th>
            <OldTd className={classes.container}>
              <div className={classes.user__list}>
                {companionList?.map((user) => (
                  <div key={user.id}>{user.name}</div>
                ))}
              </div>
            </OldTd>
            <OldTd>
              <Button
                onClick={onClick}
                disabled={readOnly}
              >
                선택
              </Button>
            </OldTd>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
