import type { ReactNode } from 'react';
import React, { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { MemoDrawerClosedChildren } from '@front/src/components/memo-drawer/components/closed-children';
import { useMemoDrawerState } from '@front/src/components/memo-drawer/useState';
import { MemoDrawer } from '@front/src/components/memo-drawer/components/memo-drawer';

export { Index as MemoDrawerFeature };

interface Props {
  children: ReactNode;
}

const Index = ({ children }: Props) => {
  const { open, setOpen } = useMemoDrawerState(
    useShallow((state) => ({
      open: state.open,
      setOpen: state.setOpen,
    }))
  );
  useEffect(
    () => () => {
      setOpen(true);
    },
    [setOpen]
  );
  return (
    <MemoDrawer
      open={open}
      closedWidth={40}
      closedChildren={<MemoDrawerClosedChildren />}
    >
      {children}
    </MemoDrawer>
  );
};
