import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyPersonOutlineBasicMutation } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/basic/query/mutation';
import { affiliatedCompanyPersonOutlineBasicQuery } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/basic/query/query';

export const affiliatedCompanyPersonOutlineBasicRepository = {
  usePersonOutlineBasicGet: (id?: number, menuId?) => {
    const { data, isLoading } = affiliatedCompanyPersonOutlineBasicQuery.usePersonOutlineBasicGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
  usePersonOutlineBasicUpdate: (menuId) => {
    const { mutate } =
      affiliatedCompanyPersonOutlineBasicMutation.usePersonOutlineBasicUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
