import { useQuery } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';

const workScheduleQuery = {
  useGetDetail: (id?: number, menuId?: number) => {
    const { data } = useQuery({
      queryKey: ['work', 'schedule', id],
      queryFn: () => workWorkApi.getSchedule(id, menuId),
      suspense: true,
      enabled: !!id,
    });
    return {
      data,
    };
  },
};

export default workScheduleQuery;
