import type { ReactNode } from 'react';
import React, { useCallback } from 'react';
import { FileUploader } from '@front/src/components/file-uploader/file-uploader';
import TextLink from '@front/layouts/TextLink';
import { useController, useFormContext } from 'react-hook-form';
import { fileToView } from '@front/file-item';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useCustomDialog } from '@front/src/features/dialog';
import { useSnackbar } from '@front/src/features/snackbar';
import { Box } from '@mui/material';
import { downloadFile } from '@front/src/utils';

export { HookFormFileUploader };

interface Props {
  name: string;
  onSubmit?: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  ext?: string;
  message?: string;
  isPossibleDownload?: boolean;
  label?: string;
  width?: string;
  download?: (id: number) => () => void;
  id?: string;
  disabled?: boolean;
}

const HookFormFileUploader = ({
  name,
  onSubmit,
  ext,
  width,
  download = downloadFile,
  isPossibleDownload = true,
  message = '파일 형식을 확인해주세요.',
  label = '파일 선택',
  id,
  disabled,
}: Props) => {
  const { control, setValue } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ control, name });
  const { show } = useSnackbar();
  const handleFileChange = useCallback(
    (e) => {
      const [file] = e.target.files;
      if (file && ext && file.name.split('.').pop().toLowerCase() !== ext) {
        show({
          message,
        });
        return;
      }
      onChange(fileToView(file));
      e.target.value = '';
      onSubmit?.();
    },
    [onChange, onSubmit, ext, message, show]
  );
  const { confirm } = useCustomDialog();
  const handleDeleteIconClick = useCallback(() => {
    confirm({
      children: '선택한 내용을 삭제하시겠습니까?',
      confirmText: '삭제',
      closeText: '취소',
      title: '삭제 확인',
      afterConfirm: () => {
        setValue(name, '');
        onSubmit?.();
      },
    });
  }, [setValue, onSubmit, confirm, name]);
  const handleDownloadClick = useCallback(() => {
    if (!isPossibleDownload) return;
    download(value.id);
  }, [isPossibleDownload, value, download]);
  return (
    <Container>
      {!disabled && (
        <FileUploader
          name={name}
          onChange={handleFileChange}
          label={label}
          id={id}
        />
      )}
      {!value && <NoFile>선택된 파일 없음</NoFile>}
      {value && (
        <Result>
          {!isPossibleDownload && (
            <Box
              onClick={handleDownloadClick}
              sx={{
                cursor: 'default',
                textAlign: 'left',
              }}
            >
              {value.filename}
            </Box>
          )}
          {isPossibleDownload && (
            <TextLink
              onClick={handleDownloadClick}
              sx={{
                width: width ?? '250px',
              }}
            >
              {value.filename}
            </TextLink>
          )}
          {!disabled && <DeleteForeverIcon onClick={handleDeleteIconClick} />}
        </Result>
      )}
    </Container>
  );
};

const Container = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      display: 'flex',
      gap: '12px',
      alignItems: 'center',
      flexWrap: 'nowrap',
      width: '100%',
    }}
  >
    {children}
  </Box>
);

const NoFile = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      fontSize: '12px',
    }}
  >
    {children}
  </Box>
);

const Result = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      fontWeight: 'bold',
      cursor: 'pointer',
    }}
  >
    {children}
  </Box>
);
