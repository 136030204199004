import { createContext } from 'react';
import type {
  MenuShortView,
  MyPageAuthorizationTabView,
} from '@front/src/features/my-page/features/authorization/types/view';

export { context as MyPageAuthorizationTabContext };

interface State {
  list?: MyPageAuthorizationTabView[];
  adminMenuList?: MenuShortView[];
  notDevelopmentMenuList?: MenuShortView[];
}

const context = createContext<State>({});
