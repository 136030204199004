import React from 'react';

import { DialogConfirm } from '@front/src/features/dialog/components/confirm';
import { useDialogConfirmLogic } from '@front/src/features/dialog/widgets/confirm/useLogic';

export { Widget as DialogConfirmWidget };

const Widget = () => {
  const {
    h: { onClose },
    confirmProps,
  } = useDialogConfirmLogic();

  const isOpen = typeof confirmProps !== 'undefined';

  if (!confirmProps) {
    return <></>;
  }
  return (
    <>
      {isOpen && (
        <DialogConfirm
          {...confirmProps}
          open={typeof confirmProps !== 'undefined'}
          onClose={() => {
            if (confirmProps?.afterClose) {
              confirmProps.afterClose();
            }
            onClose();
          }}
          onConfirm={() => {
            if (confirmProps?.afterConfirm) {
              confirmProps.afterConfirm();
            }
            onClose();
          }}
        />
      )}
    </>
  );
};
