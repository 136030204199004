import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
import { voteAction } from '@front/ost_vote/action';
import type { VoteAddParameter } from '@front/ost_vote/parameter';
import VoteLayout from '@front/ost_proposal/view/detail/vote/VoteLayout';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';

const VoteService = () => {
  const dispatch = useDispatch();

  const vote = useCallback(
    (params: VoteAddParameter) => dispatch(voteAction.add(params)),
    [dispatch]
  );
  const openSnackbar = useCallback(
    (message, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );

  return (
    <VoteLayout
      vote={vote}
      openSnackbar={openSnackbar}
    />
  );
};

export default VoteService;
