import type { Dispatch, RefObject, SetStateAction } from 'react';
import React from 'react';
import { WorkTd } from '@front/src/features/work/features/work/components/work-td';
import { ControlPanel } from '@front/src/components/control-panel/control-panel';
import { LayerPopup } from '@front/src/components/layer-popup/layer-popup';
import {
  Button as MuiButton,
  Link,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Table } from '@front/layouts/Table';
import { WorkTh } from '@front/src/features/work/features/work/components/work-th';
import { ColorPalette } from '@front/assets/theme';
import { usePersonalSettingsModalState } from '@front/src/features/personal-settings-modal/useState';
import { useShallow } from 'zustand/react/shallow';
import {
  getPersonalSettingsModalControlPanelItemList,
  settingsModalRowDummy,
} from '@front/src/features/personal-settings-modal/features/auth-settings/utils/contstants';
import type { AuthorizationPersonalSettingsUserView } from '@front/src/features/personal-settings-modal/features/auth-settings/types/view';
import UserSelector from '@front/components/UserSelector';
import { useFormContext } from 'react-hook-form';
import { AuthorizationType } from '@front/user/domain';
import { useSnackbar } from '@front/src/features/snackbar';
import { useCustomDialog } from '@front/src/features/dialog';
import { sortKor } from '@front/src/features/personal-settings-modal/features/auth-settings/utils';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

export { SettingsModalTableUserRow as PersonalSettingsModalTableUserRow };

interface Props {
  item: AuthorizationPersonalSettingsUserView;
  tableRef: RefObject<HTMLDivElement>;
  setClientUserList: Dispatch<SetStateAction<AuthorizationPersonalSettingsUserView[]>>;
  index: number;
  insert: (index: number, value: AuthorizationPersonalSettingsUserView) => void;
  remove: (index: number) => void;
  arr: AuthorizationPersonalSettingsUserView[];
  replace: (obj: AuthorizationPersonalSettingsUserView[]) => void;
}

const SettingsModalTableUserRow = ({
  item,
  tableRef,
  index,
  insert,
  remove,
  arr,
  replace,
}: Props) => {
  const {
    anchorEls,
    setAnchorEls,
    setEditOpenList,
    editOpenList,
    setColoredId,
    coloredId,
    newItemIdList,
    setNewItemIdList,
    setCreateList,
    createList,
    deleteList,
    setDeleteList,
  } = usePersonalSettingsModalState(
    useShallow((state) => ({
      anchorEls: state.anchorEls,
      setAnchorEls: state.setAnchorEls,
      setEditOpenList: state.setEditOpenList,
      editOpenList: state.editOpenList,
      coloredId: state.coloredId,
      setColoredId: state.setColoredId,
      newItemIdList: state.newItemIdList,
      setNewItemIdList: state.setNewItemIdList,
      setCreateList: state.setCreateList,
      setDeleteList: state.setDeleteList,
      createList: state.createList,
      deleteList: state.deleteList,
    }))
  );
  const { setValue, watch, getValues } = useFormContext();
  const menuId = useGlobalNavBarState((state) => state.menuId);
  const actions = {
    onAdd: () => {
      const newDummy = settingsModalRowDummy();
      insert(index + 1, newDummy);
      setColoredId(newDummy.id);
      setNewItemIdList([...newItemIdList, newDummy.id]);
    },
    onRemove: () => {
      remove(index);
      !item.isNew && setDeleteList([...deleteList, item.menus[0].authorizationUser.id!]);
    },
    onEdit: () => setEditOpenList(item.originalId),
  };
  const isNewItem = newItemIdList.includes(item.originalId!);
  const { show } = useSnackbar();
  const { alert } = useCustomDialog();
  return (
    <TableRow
      key={item.id}
      sx={{ backgroundColor: coloredId === item.originalId ? ColorPalette._d2e7fa : 'inherit' }}
    >
      <WorkTd>
        <ControlPanel
          itemList={getPersonalSettingsModalControlPanelItemList({
            actions,
            isEditOpen: editOpenList.includes(item.originalId!),
          })}
        />
      </WorkTd>
      <WorkTd height="53px">{item.department.name}</WorkTd>
      <WorkTd>{item.jobDuty ?? '-'}</WorkTd>
      <WorkTd>{item.employeeId}</WorkTd>
      <WorkTd>
        {isNewItem ? (
          <UserSelector
            onChange={(value, name) => {
              if (arr.find((el) => el.userId === value || el.originalId === value)) {
                alert({
                  children: [
                    {
                      value: '이미 등록된 유저입니다.',
                    },
                  ],
                });
                return;
              }
              setNewItemIdList([...newItemIdList].filter((el) => el !== item.originalId));
              setValue(`users.${index}.name`, name);
              setCreateList([
                ...createList,
                {
                  menuId: menuId ? +menuId : 0,
                  userId: value as number,
                  type: AuthorizationType.X,
                },
              ]);
              setValue(`users.${index}.userId`, value as number);
              setEditOpenList(item.originalId);
              replace(sortKor(getValues('users')));
              show({
                message: '이름 가나다순으로 정렬되었습니다. 해당 직원의 권한을 수정해주세요.',
              });
            }}
          />
        ) : (
          <>{watch(`users.${index}.name`)}</>
        )}
      </WorkTd>
      <WorkTd>
        <LayerPopup
          anchorEls={anchorEls}
          setAnchorEls={setAnchorEls}
          text={`key-${item.id}`}
          containerRef={tableRef}
          body={
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <WorkTh>
                      <Typography
                        sx={{
                          whiteSpace: 'pre',
                          fontSize: '14px',
                        }}
                        align="center"
                      >
                        {'관리자 메뉴 -\n사용자 관리'}
                      </Typography>
                    </WorkTh>
                    <WorkTd
                      sx={{
                        backgroundColor: item.adminRoleManagement.canAccessUserManagement
                          ? 'inherit'
                          : ColorPalette._b2b4b7,
                      }}
                    >
                      {item.adminRoleManagement.canAccessUserManagement ? 'ON' : 'OFF'}
                    </WorkTd>
                  </TableRow>
                  <TableRow>
                    <WorkTh>
                      <Typography
                        sx={{ whiteSpace: 'pre', fontSize: '14px' }}
                        align="center"
                      >
                        {'관리자 메뉴 -\n관리형 변수 설정'}
                      </Typography>
                    </WorkTh>
                    <WorkTd
                      sx={{
                        backgroundColor: item.adminRoleManagement.canAccessVariableManagement
                          ? 'inherit'
                          : ColorPalette._b2b4b7,
                      }}
                    >
                      {item.adminRoleManagement.canAccessVariableManagement ? 'ON' : 'OFF'}
                    </WorkTd>
                  </TableRow>
                  <TableRow>
                    <WorkTh>
                      <Typography
                        sx={{ whiteSpace: 'pre', fontSize: '14px' }}
                        align="center"
                      >
                        {'페이지설정\n(권한)'}
                      </Typography>
                    </WorkTh>
                    <WorkTd
                      sx={{
                        backgroundColor: item.adminRoleManagement.canAccessPageAuthorization
                          ? 'inherit'
                          : ColorPalette._b2b4b7,
                      }}
                    >
                      {item.adminRoleManagement.canAccessPageAuthorization ? 'ON' : 'OFF'}
                    </WorkTd>
                  </TableRow>
                  <TableRow>
                    <WorkTh>
                      <Typography
                        sx={{ whiteSpace: 'pre', fontSize: '14px' }}
                        align="center"
                      >
                        {'페이지설정\n(화면)'}
                      </Typography>
                    </WorkTh>
                    <WorkTd
                      sx={{
                        backgroundColor: item.adminRoleManagement.canAccessPageView
                          ? 'inherit'
                          : ColorPalette._b2b4b7,
                      }}
                    >
                      {item.adminRoleManagement.canAccessPageView ? 'ON' : 'OFF'}
                    </WorkTd>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          }
        >
          {item.adminRoleManagement.name ? (
            <MuiButton
              variant="text"
              sx={{
                padding: 0,
              }}
            >
              <Link
                underline="hover"
                sx={{ fontSize: '13px' }}
              >
                {item.adminRoleManagement.name}
              </Link>
            </MuiButton>
          ) : null}
        </LayerPopup>
      </WorkTd>
    </TableRow>
  );
};
