import type { FocusEvent } from 'react';
import React, { memo } from 'react';
import { Box } from '@mui/material';
import type { FormikProps } from 'formik';

import type { CampaignMemoId, CampaignMemoVO } from '@front/ost_campaign/domain';
import Input from '@front/layouts/Input';
import Button from '@front/layouts/Button';
import { ColorPalette } from '@front/assets/theme';
import type { CampaignMemoUpdateParameter } from '@front/ost_campaign/parameter';

interface Props {
  formik: FormikProps<CampaignMemoUpdateParameter>;
  memo: CampaignMemoVO;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  onSave: (campaignMemoId: CampaignMemoId) => () => void;
  onCancel: (campaignMemoId: CampaignMemoId) => () => void;
}

const MemoUpdateForm = ({ formik, memo, onBlur, onSave, onCancel }: Props) => (
  <Box
    sx={{
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      padding: '12px 0',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        border: `1px solid ${ColorPalette._e4e9f2}`,
        marginBottom: '5px',
      }}
    >
      <Input
        required
        multiline
        variant="outlined"
        placeholder="메모 입력"
        key={formik.values.description}
        defaultValue={formik.values.description ?? ''}
        onBlur={onBlur}
      />
    </Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Button
          sx={{
            margin: '0px 5px',
          }}
          onClick={onSave(memo.id)}
        >
          저장
        </Button>
        <Button
          shape="basic2"
          onClick={onCancel(memo.id)}
        >
          취소
        </Button>
      </Box>
    </Box>
  </Box>
);

const FormUpdateMemo = memo(MemoUpdateForm);

export default FormUpdateMemo;
