import React, { useCallback, useContext, useMemo } from 'react';
import { AffiliatedCompanySelectorWidget } from '@front/src/features/affiliated-company-selector/widgets/widget';
import { useFormContext, useWatch } from 'react-hook-form';
import { spliceAndPushParticipantListIfExist } from '@front/src/features/affiliated-company/utils';
import { AffiliatedCompanyMeetingHistoryModalContext } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/widgets/context';
import { AffiliatedCompanyStatus } from '@front/src/features/affiliated-company/types/domain';
import { useAffiliatedCompanyDetailState } from '@front/src/features/affiliated-company/widgets/detail/useState';
import { useAffiliatedCompanyMeetingHistoryState } from '@front/src/features/affiliated-company/features/meeting-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import { useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import type { AffiliatedCompanySelectWidgetValueProp } from '@front/src/features/affiliated-company-selector/types/affiliated-company-selector-types';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

export { FormInfoRequesterSelector as AffiliatedCompanyMeetingHistoryModalFormInfoRequesterSelector };

const FormInfoRequesterSelector = () => {
  const {
    d: { status },
  } = useContext(AffiliatedCompanyMeetingHistoryModalContext);
  const { id } = useAffiliatedCompanyDetailState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const { setValue, getValues, control } = useFormContext();
  const requester = useWatch({ name: 'requester', control });
  const handleSelector = useCallback(
    (company, person) => {
      setValue(
        'participantList',
        spliceAndPushParticipantListIfExist(getValues('participantList'), person, requester)
      );
      setValue('requester', person);
    },
    [setValue, getValues, requester]
  );
  const value = useMemo(() => {
    if (typeof requester === 'undefined') {
      if (status === AffiliatedCompanyStatus.CLOSED) {
        return {
          textValue: '',
          companyId: undefined,
          personId: undefined,
        };
      }
      return {
        textValue: '',
        companyId: id,
        personId: undefined,
      };
    }
    if (status === AffiliatedCompanyStatus.CLOSED) {
      return {
        textValue: `${requester.affiliatedCompany?.name} - ${requester.name}`,
        companyId: undefined,
        personId: undefined,
      };
    }
    return {
      textValue: `${requester.affiliatedCompany?.name} - ${requester.name}`,
      companyId: requester.affiliatedCompany?.id,
      personId: requester.id,
    };
  }, [requester, status, id]);
  const { readOnly } = useAffiliatedCompanyMeetingHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const menuId = useGlobalNavBarState((state) => state.menuId);
  const loginUser = useSelector((root: RootState) => root.login.detail);
  const currentAuth = loginUser?.menus?.find((item) => item.id === (menuId ? +menuId : 0))
    ?.authorization.type;
  return (
    <AffiliatedCompanySelectorWidget
      value={currentAuth === 'V' ? ({} as AffiliatedCompanySelectWidgetValueProp) : value}
      onPersonSelect={handleSelector}
      usePersonSelector
      from="company"
      disabled={readOnly}
    />
  );
};
