import React, { useCallback, useRef } from 'react';

import Input from '@front/layouts/Input';
import { useDispatch } from 'react-redux';
import { proposalAction } from '@front/ost_proposal/action';
import type { ProposalId } from '@front/ost_proposal/domain';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';

interface Props {
  readOnly: boolean;
  proposalId: ProposalId;
  fixedResult: number;
}

export const FixResultInput = ({ fixedResult, proposalId, readOnly }: Props) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLInputElement>();

  const openSnackbar = useCallback(
    (message, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );

  const onFocus = useCallback(() => {
    if (!ref.current) {
      return;
    }
    ref.current.value = ref.current.value.split(',').join('');
  }, [ref]);

  const onBlur = useCallback(
    (e) => {
      const value = e?.target.value || '';
      if (!value) {
        dispatch(proposalAction.updateFixedResult({ proposalId, fixedResult: undefined }));
        return;
      }
      if (+value > 2000000000) {
        openSnackbar('확정금액을 확인해주세요.');
        return;
      }
      dispatch(proposalAction.updateFixedResult({ proposalId, fixedResult: +value }));
    },
    [dispatch, proposalId, openSnackbar]
  );

  return (
    <Input
      inputRef={ref}
      readOnly={readOnly}
      isAmount
      key={fixedResult}
      variant="outlined"
      defaultValue={fixedResult != null ? fixedResult.toLocaleString() : null}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};
