import React from 'react';
import type { SectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import ProjectSalesInfoSubjectReviewExperimentWidget from '@front/src/features/project-sales-info/features/subject-review/features/experiment/widgets/widget';
import ProjectSalesInfoSubjectReviewShapeRatioFeature from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio';

export { sectionComponent as projectSalesInfoSubjectReviewSectionComponent };

const sectionComponent: SectionComponent = {
  18: ({ ...rest }) => <ProjectSalesInfoSubjectReviewExperimentWidget {...rest} />,
  19: ({ ...rest }) => <ProjectSalesInfoSubjectReviewShapeRatioFeature {...rest} />,
};
