import { default as axios } from '@front/src/config/axios';
import { contractEarningsApiUrl } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/utils/constants';
import type { ProjectContractEarningsParameters } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/parameters';

const url = {
  createContractEarnings: (id: number) => `${contractEarningsApiUrl}/${id}/contract-earnings`,
};

export const projectSalesInfoContractEarningsModalApi = {
  createContractEarnings: async (params: ProjectContractEarningsParameters) =>
    await axios.post(url.createContractEarnings(params.id!), params),
};
