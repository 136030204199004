import React, { createRef, useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import classes from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/components/extra-company-button.module.scss';
import { AffiliatedCompanyPersonDetailModalOutlineExtraCompanyContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/widgets/context';
import { AffiliatedCompanyPersonOutlineExtraCompanySelector } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/components/extra-company-selector';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedCompanyPersonDetailModalOutlineState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/section/outline/useState';

export { ExtraCompanyButton as AffiliatedCompanyPersonDetailModalOutlineExtraCompanyButton };
const ExtraCompanyButton = () => {
  const {
    h: { onExtraCompanyDelete, setIdList },
    idList,
  } = useContext(AffiliatedCompanyPersonDetailModalOutlineExtraCompanyContext);
  const handleDeleteClick = useCallback(() => {
    onExtraCompanyDelete({ idList });
    setIdList([]);
  }, [onExtraCompanyDelete, idList, setIdList]);
  const inputRef = createRef<HTMLInputElement>();
  const handleCreateClick = useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.click();
    }
  }, [inputRef]);
  const { readOnly } = useAffiliatedCompanyPersonDetailModalOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      <div className={classes.display__none}>
        <AffiliatedCompanyPersonOutlineExtraCompanySelector inputRef={inputRef} />
      </div>
      <Button
        onClick={handleDeleteClick}
        disabled={readOnly}
      >
        선택삭제
      </Button>
      <Button
        onClick={handleCreateClick}
        disabled={readOnly}
      >
        소속회사추가
      </Button>
    </>
  );
};
