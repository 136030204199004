import React, { memo, Suspense, useEffect, useState } from 'react';
import ResizableRightDrawer from '@front/src/components/list-drawer/components/resizable-right-drawer';
import NoteContent from '@front/src/features/note/components/Content';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import useNoteMenuId from '@front/src/hooks/useNoteMenuId';
import { useParams } from 'react-router';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import { useShallow } from 'zustand/react/shallow';

function NoteFeature() {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <NoteFeatureWrapper />
    </Suspense>
  );
}

export default memo(NoteFeature);

const NoteFeatureWrapper = () => {
  useNoteMenuId();
  const params = useParams();
  const { setIsRightDrawerOpen } = useGlobalNavBarState(
    useShallow((state) => ({
      setIsRightDrawerOpen: state.setIsRightDrawerOpen,
    }))
  );
  const [isFirst, setIsFirst] = useState(true);

  useEffect(() => {
    setIsFirst(false);
    if (isFirst) return;
    setIsRightDrawerOpen(false);
  }, [params]);

  return (
    <ResizableRightDrawer>
      <NoteContent />
    </ResizableRightDrawer>
  );
};
