import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useCustomDialog } from '@front/src/features/dialog';
import type { WorkInterimReportDateView } from '@front/src/features/work/features/work/features/schedule/features/interim-report-date/types/view';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import type { WorkScheduleInterimReportDateParameter } from '@front/src/features/work/features/work/features/schedule/features/interim-report-date/types/parameter';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import DatePickerWithHookForm from '@front/src/components/hook-form/DatePicker';
import dayjs from 'dayjs';
import InputWithHookForm from '@front/src/components/hook-form/Input';
import { Box } from '@mui/material';
import { YYYY_MM_DD } from '@front/src/utils';

interface Props extends SectionComponentProps {
  onCreate: () => void;
  onUpdate: (params: WorkScheduleInterimReportDateParameter) => void;
  onDelete: (id: number) => void;
  item: WorkInterimReportDateView;
  index: number;
  size: number;
}

export default function WorkInterimReportDateRow({
  item,
  index,
  size,
  readOnly,
  onUpdate,
  onCreate,
  onDelete,
}: Readonly<Props>) {
  const methods = useForm({
    values: getFormValues(item),
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    onUpdate({
      ...data,
      date: data.date ? data.date.format(YYYY_MM_DD) : null,
      description: data.description || null,
    });
  });
  const { confirm } = useCustomDialog();
  const onDeleteWithConfirm = () => {
    confirm({
      lineBreakChildren: [{ value: '선택한 내용을 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDelete(item.id);
      },
    });
  };
  const renderButton = () => {
    if (readOnly) return <></>;
    const onClick = index === 0 ? onCreate : onDeleteWithConfirm;
    const children = index === 0 ? '+' : '-';
    return (
      <ButtonBasicUI
        shape="ghost"
        size="small"
        onClick={onClick}
        sx={{
          position: 'absolute',
          right: -8,
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Box
          sx={{
            width: '14px',
            height: '14px',
          }}
        >
          {children}
        </Box>
      </ButtonBasicUI>
    );
  };
  const getLabel = () => {
    const label = '중간보고일';
    if (size === 1) {
      return label;
    }
    return `${label}${index + 1}`;
  };
  return (
    <FormProvider {...methods}>
      <TableRowUI>
        <TableCellUI
          isHead
          width="160px"
          sx={{
            position: 'relative',
          }}
        >
          {getLabel()}
          {renderButton()}
        </TableCellUI>
        <TableCellUI>
          <DatePickerWithHookForm
            name="date"
            onSubmit={onSubmit}
            readOnly={readOnly}
          />
        </TableCellUI>
        <TableCellUI>
          <InputWithHookForm
            name="description"
            readOnly={readOnly}
            onSubmit={onSubmit}
            placeholder="중간 보고 목적 및 KPI가 있다면 해당 내용을 입력해주세요"
          />
        </TableCellUI>
      </TableRowUI>
    </FormProvider>
  );
}

function getFormValues(item) {
  return {
    id: item.id,
    date: item.date ? dayjs(item.date) : null,
    description: item.description ?? '',
  };
}
