import React, { memo } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { ProjectSalesInfoSubjectReviewModalDesignDateDetailWidget } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date/features/detail/widgets/widget';

export { DesignDateTable as ProjectSalesInfoSubjectReviewModalDesignDateTable };

const DesignDateTable = () => (
  <TableContainer
    sx={{
      width: '600px',
    }}
  >
    <Table>
      <TableHeader />
      <TableBody>
        <ProjectSalesInfoSubjectReviewModalDesignDateDetailWidget />
      </TableBody>
    </Table>
  </TableContainer>
);

const TableHeader = memo(() => (
  <TableHead>
    <TableRow>
      <Th width="120px">도면수급일</Th>
      <Th width="160px">적용코드</Th>
      <Th width="100px">대지모형 개수</Th>
    </TableRow>
  </TableHead>
));

TableHeader.displayName = 'ProjectSalesInfoSubjectReviewModalDesignDateTableHeader';
