import React, { memo } from 'react';
import { Box } from '@mui/material';

import { ColorPalette } from '@front/assets/theme';
import StateTitle from '@front/ost_campaign/view/detail/state/title';
import StateTable from '@front/ost_campaign/view/detail/state/table';

const ProposalState = () => (
  <Box
    sx={{
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'column',
      width: '100%',
      minHeight: '60px',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <StateTitle />
    </Box>
    <Box
      sx={{
        minHeight: '80px',
        border: `1px solid ${ColorPalette._e4e9f2}`,
      }}
    >
      <StateTable />
    </Box>
  </Box>
);

const ProposalStateForm = memo(ProposalState);

export default ProposalStateForm;
