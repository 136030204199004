import React from 'react';
import { Box, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import TextBox from '../../../layouts/Text';
import Button from '../../../layouts/Button';
import { OldTd, OldTh, Table } from '../../../layouts/Table';
import dayjs from 'dayjs';
import TextLink from '../../../layouts/TextLink';
import useDialog from '../../../dialog/hook';
import type { ProceedingsAttachedFileVO, ProceedingsVO } from '../../domain';
import { ProceedingsAttachedFileId, ProceedingsStatus } from '../../domain';
import type { ProceedingsAttachedFileParameter } from '../../parameter';
import { fileToView } from '../../../file-item';
import { fileTypeText } from '../../../util/CommonText';
import Tooltip from '../../../components/Tooltip';

interface Props {
  list: ProceedingsAttachedFileVO[];
  addAttachedFile: (params: ProceedingsAttachedFileParameter) => void;
  deleteAttachedFile: (id: ProceedingsAttachedFileId) => void;
  isReader: boolean;
  isWriter: boolean;
  isAttendance: boolean;
  detail: ProceedingsVO;
}

function AttachedFileListSection({
  list,
  addAttachedFile,
  deleteAttachedFile,
  isWriter,
  isAttendance,
  detail,
}: Props) {
  const { confirm } = useDialog();
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        width: '100%',
        margin: '10px 0px',
        padding: '10px',
      }}
    >
      <Box
        sx={{
          display: 'inherit',
          marginBottom: '10px',
        }}
      >
        <Box>
          <TextBox variant={'body7'}>첨부 파일</TextBox>
        </Box>
        {(isWriter || (isAttendance && detail.status === ProceedingsStatus.READING)) && (
          <Box
            sx={{
              marginLeft: 'auto',
            }}
          >
            <Tooltip title={fileTypeText}>
              <Button component="label">
                파일 선택
                <input
                  hidden
                  multiple
                  type="file"
                  onChange={(e) => {
                    if (!e.target || !e.target.files || e.target.files.length === 0) {
                      return;
                    }
                    confirm({
                      children: '해당 첨부 파일을 등록하시겠습니까?',
                      confirmText: '확인',
                      afterConfirm: () => {
                        addAttachedFile({
                          file: fileToView(e.target.files![0]),
                        });
                      },
                    });
                  }}
                />
              </Button>
            </Tooltip>
          </Box>
        )}
      </Box>
      <TableContainer
        sx={{
          maxHeight: '250px',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <OldTh>날짜</OldTh>
              <OldTh>소속</OldTh>
              <OldTh>기록자</OldTh>
              <OldTh
                colSpan={
                  isWriter || (isAttendance && detail.status === ProceedingsStatus.READING) ? 3 : 1
                }
              >
                파일명
              </OldTh>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length === 0 && (
              <TableRow>
                <OldTd colSpan={5}>{'첨부 파일이 없습니다.'}</OldTd>
              </TableRow>
            )}
            {list.map((item, index) => (
              <TableRow key={`${item.file.id}_${index}`}>
                <OldTd>{dayjs(item.file.createdAt).format('YYYY-MM-DD')}</OldTd>
                <OldTd>{item.writer.department.name}</OldTd>
                <OldTd>{item.writer.name}</OldTd>
                <OldTd
                  colSpan={
                    isWriter || (isAttendance && detail.status === ProceedingsStatus.READING)
                      ? 2
                      : 1
                  }
                >
                  <TextLink
                    onClick={() => {
                      window.location.assign(`/api/file-item/${item.file.id}`);
                    }}
                  >
                    {item.file.filename}
                  </TextLink>
                </OldTd>
                {(isWriter || (isAttendance && detail.status === ProceedingsStatus.READING)) && (
                  <OldTd>
                    <Button
                      onClick={() => {
                        confirm({
                          children: '해당 첨부 파일을 삭제하시겠습니까?',
                          confirmText: '확인',
                          afterConfirm: () => {
                            deleteAttachedFile(ProceedingsAttachedFileId(item.id));
                          },
                        });
                      }}
                    >
                      삭제
                    </Button>
                  </OldTd>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default AttachedFileListSection;
