import React from 'react';
import type { FormikLayoutProps } from 'layouts/PageLayout';
import PageLayout from 'layouts/PageLayout';
import type { ListProps } from 'personnel/view/Page/List';
import List from 'personnel/view/Page/List';
import type { PersonnelQuery } from 'personnel/query';
import SearchBox from 'personnel/view/Page/SearchForm';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import PersonalSettingsFeature from 'src/features/personal-settings-modal';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends ListProps, FormikLayoutProps<PersonnelQuery>, SectionComponentProps {}

export default function PersonnelPage(props: Props) {
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const isValid = loginUser?.role.canAccessPageAuthorization || loginUser?.role.canAccessPageView;
  return (
    <PageLayout
      titleRightComponent={<>{isValid && <PersonalSettingsFeature authMenuId={props.menuId} />}</>}
      filter={<SearchBox menuId={props.menuId} />}
      body={<List {...props} />}
      formik={props.formik}
    />
  );
}
