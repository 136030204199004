import React from 'react';
import { Box, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ApprovalDocumentContentTable from '@front/src/features/drawer-approval-document/components/ApprovalDocumentContentTable';
import { FormProvider, useForm } from 'react-hook-form';
import ApprovalDocumentComment from '@front/src/features/drawer-approval-document/components/ApprovalDocumentComment';
import type { ApprovalDocumentDetailView } from '@front/src/features/drawer-approval-document/types/view';
import dayjs from 'dayjs';
import { ColorPalette } from '@front/assets/theme';

interface Props {
  documentDetail?: ApprovalDocumentDetailView;
}

export default function ApprovalDocumentDetailWidget({ documentDetail }: Props) {
  const methods = useForm({
    defaultValues: {
      comment: '',
    },
  });

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
      >
        <Typography sx={{ ...SX_TITLE_SECONDARY }}>{documentDetail?.sourceTitle}</Typography>
      </Box>
      <Typography sx={{ ...SX_TITLE_PRIMARY }}>{documentDetail?.title}</Typography>
      <Box sx={{ marginBottom: '14px' }}>
        <Typography sx={{ ...SX_APPROVAL_DOCUMENT_SECTION }}>결재 단계</Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '5px',
            overflow: 'auto',
            padding: '0 0 10px 0',
          }}
        >
          {documentDetail?.approvalSteps?.map((step, index) => (
            <Box
              key={`${step.manager.id}${index}`}
              sx={{
                display: 'flex',
                width: index < documentDetail?.approvalSteps?.length - 1 ? '150px' : '130px',
                flexDirection: 'row',
                gap: '8px',
              }}
            >
              <Box>
                <Typography
                  sx={{
                    ...SX_APPROVAL_STEP_ICON,
                    backgroundColor:
                      step.stepName === '진행'
                        ? ColorPalette.sub.sub_primary
                        : step.stepName === '승인'
                        ? ColorPalette.background.bg05
                        : 'transparent',
                    border: `1px solid ${
                      step.stepName === '진행'
                        ? ColorPalette.sub.sub_primary
                        : ColorPalette.background.bg05
                    }`,
                  }}
                >
                  {step.stepName}
                </Typography>
              </Box>
              <Box sx={{ ...SX_APPROVAL_STEP_DETAIL_WRAP }}>
                <Typography sx={{ ...SX_APPROVAL_STEP_DETAIL_NAME }}>
                  {step.manager.name}
                </Typography>
                <Typography sx={{ ...SX_APPROVAL_STEP_DETAIL_DATE }}>
                  {step.submissionDate ? dayjs(step.submissionDate).format('YY-MM-DD HH:mm') : '-'}
                </Typography>
              </Box>
              {index < documentDetail?.approvalSteps?.length - 1 && (
                <Box sx={{ ...SX_APPROVAL_STEP_NEXT_ICON }}>
                  <ArrowForwardIosIcon />
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={{ marginBottom: '14px' }}>
        <Typography sx={{ ...SX_APPROVAL_DOCUMENT_SECTION }}>참조자</Typography>
        <Box
          display="flex"
          gap="5px"
        >
          {documentDetail?.referenceList.length === 0 && (
            <Box>
              <Typography sx={{ ...SX_REFERENCE_USER_PLACEHOLDER }}>참조자가 없습니다.</Typography>
            </Box>
          )}
          {documentDetail?.referenceList.map((item) => (
            <Box key={item.id}>
              <Typography sx={{ ...SX_REFERENCE_USER_ITEM }}>{item.name}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box>
        <Typography sx={{ ...SX_APPROVAL_DOCUMENT_SECTION }}>본문</Typography>
        <ApprovalDocumentContentTable item={documentDetail} />
      </Box>
      <FormProvider {...methods}>
        <ApprovalDocumentComment item={documentDetail} />
      </FormProvider>
    </>
  );
}

const SX_APPROVAL_DOCUMENT_SECTION = {
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  margin: '10px 0',
};

const SX_REFERENCE_USER_PLACEHOLDER = {
  color: 'var(--design-v-10-light-greyscale-text-quaternary, #BABABA)',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
};

const SX_REFERENCE_USER_ITEM = {
  background: 'var(--design-v-10-light-background-bg-02, #EBEEF2)',
  padding: '0 10px',
  borderRadius: '10px',
  fontWeight: 600,
};

const SX_TITLE_PRIMARY = {
  color: 'var(--design-v-10-light-greyscale-text-primary, #282828)',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '30px',
  marginBottom: '10px',
};

const SX_TITLE_SECONDARY = {
  color: 'var(--design-v-10-light-greyscale-text-primary, #282828)',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  marginBottom: '10px',
};

const SX_APPROVAL_STEP_ICON = {
  display: 'flex',
  width: '50px',
  height: '50px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50px',
  border: '1px solid var(--design-v-10-light-background-bg-05, #35455F)',
  background: 'var(--design-v-10-light-background-bg, #FFF)',
  color: 'var(--design-v-10-light-greyscale-text-primary, #282828)',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '15px',
};

const SX_APPROVAL_STEP_DETAIL_WRAP = {
  display: 'flex',
  flexDirection: 'column',
  width: '70px',
  height: '50px',
  justifyContent: 'center',
};

const SX_APPROVAL_STEP_DETAIL_NAME = {
  color: 'var(--design-v-10-light-greyscale-text-primary, #282828)',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',
};

const SX_APPROVAL_STEP_DETAIL_DATE = {
  color: 'var(--design-v-10-light-greyscale-text-secondary, #7E7E7E)',
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '12px',
  whiteSpace: 'normal', // 줄 바꿈 허용
  overflowWrap: 'break-word', // 단어가 넘칠 때 줄 바꿈
};

const SX_APPROVAL_STEP_NEXT_ICON = {
  width: '30px',
  height: '50px',
  alignContent: 'center',
  color: '#BABABA',
};
