import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAccountingUploadSalesInfoExpectModalState } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/useState';
import { useShallow } from 'zustand/react/shallow';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';

export { MonthSelect as AccountingUploadSalesInfoExpectModalMonthSelect };

const MonthSelect = () => {
  const { month, setMonth } = useAccountingUploadSalesInfoExpectModalState(
    useShallow((state) => ({
      month: state.month,
      setMonth: state.setMonth,
    }))
  );
  const methods = useForm({
    values: {
      month: month ? month.toString() : '',
    },
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    const month = data.month ? +data.month : undefined;
    setMonth(month);
  });
  return (
    <FormProvider {...methods}>
      <HookFormSelect
        width="80px"
        name="month"
        optionList={getMonthStringList()}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
};

const getMonthStringList = () => {
  const list: { value: string; label: string }[] = [];
  for (let m = 1; m < 13; m += 1) {
    const mString = m.toString();
    list.push({
      value: mString,
      label: mString,
    });
  }
  return list;
};
