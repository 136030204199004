import { default as axios } from '@front/src/config/axios';
import type { ProposalId } from '@front/ost_proposal/domain';
import type {
  ArrangementMemoAddParameter,
  ArrangementMemoChangeParameter,
} from '@front/ost_arrangement/parameter';
import type { ArrangementMemoQuery, ArrangementQuery } from '@front/ost_arrangement/query';
import type { ArrangementMemoId } from '@front/ost_arrangement/domain';

class ArrangementApi {
  /**
   * 제안 목록 조회 API
   * @param query
   */
  async getProposalList(query: ArrangementQuery): Promise<void> {
    const { data } = await axios.get(
      `/api/ost/proposal/arrangement/list?keyword=${query.keyword}&status=${query.status}&arrangementStatus=${query.arrangementStatus}`
    );
    return data;
  }

  /**
   * 제안 메모 관련 API
   * @param id
   * @param params
   */
  async addMemo(id: ProposalId, params: ArrangementMemoAddParameter): Promise<void> {
    const { data } = await axios.post(`/api/arrangement/${id}/memo`, params);
    return data;
  }

  async getMemoList(id: ProposalId, query: ArrangementMemoQuery): Promise<void> {
    const { data } = await axios.get(`/api/arrangement/${id}/memo?keyword=${query.keyword}`);
    return data;
  }

  async changeMemo(id: ProposalId, params: ArrangementMemoChangeParameter): Promise<void> {
    const { data } = await axios.put(`/api/arrangement/${id}/memo/${params.id}`, params);
    return data;
  }

  async deleteMemo(id: ProposalId, memoId: ArrangementMemoId): Promise<void> {
    const { data } = await axios.delete(`/api/arrangement/${id}/memo/${memoId}`);
    return data;
  }
}

export const arrangementApi = new ArrangementApi();
