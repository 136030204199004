import { default as axios } from '@front/src/config/axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import { toFormData } from '@front/services/api';
import type { ProjectEstimationComparedParameter } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/types/parameter';

export { api as projectSalesInfoEstimationComparedCreateModalApi };
const url = {
  getDetail: (id?) => `${projectSalesInfoURL}/estimation-compared/${id}`,
  create: (id?) => `${projectSalesInfoURL}/${id}/estimation-compared`,
  update: (id?) => `${projectSalesInfoURL}/estimation-compared/${id}`,
};

const api = {
  getDetail: async (id?: number) => {
    const { data } = await axios.get(url.getDetail(id));
    return data;
  },
  create: async (params: ProjectEstimationComparedParameter) => {
    await axios.post(url.create(params.id), toFormData(params));
  },
  update: async (params: ProjectEstimationComparedParameter) => {
    await axios.put(url.update(params.id), toFormData(params));
  },
};
