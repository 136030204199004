import React, { useCallback } from 'react';
import BannerForm from '@front/ost_campaign/view/detail/BannerForm';
import { useDispatch } from 'react-redux';
import type {
  CampaignBannerUpdateContentParameter,
  CampaignBannerUpdateIsExposedParameter,
} from '@front/ost_campaign/parameter';
import { campaignAction } from '@front/ost_campaign/action';

const BannerFormService = () => {
  const dispatch = useDispatch();

  const onUpdateContent = useCallback(
    (parameter: CampaignBannerUpdateContentParameter) => {
      dispatch(campaignAction.updateCampaignBannerContent(parameter));
    },
    [dispatch]
  );

  const onUpdateIsExposed = useCallback(
    (parameter: CampaignBannerUpdateIsExposedParameter) => {
      dispatch(campaignAction.updateCampaignBannerExposed(parameter));
    },
    [dispatch]
  );

  return (
    <BannerForm
      onUpdateContent={onUpdateContent}
      onUpdateIsExposed={onUpdateIsExposed}
    />
  );
};

export default BannerFormService;
