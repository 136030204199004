import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import type { ProposalMemoId } from '@front/ost_proposal/domain';
import { FormikProvider, useFormik } from 'formik';
import type { ArrangementMemoChangeParameter } from '@front/ost_arrangement/parameter';
import { arrangementAction } from '@front/ost_arrangement/action';
import ArrangementMemoList from '@front/ost_arrangement/view/memo/List';

interface Props {
  formHeight: number;
}

const ArrangementListService = ({ formHeight }: Props) => {
  const dispatch = useDispatch();
  const detail = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const memoLoading = useSelector((root: RootState) => root.arrangement.memoLoading, shallowEqual);
  const [memoList] = useSelector((root: RootState) => [root.arrangement.memoList], shallowEqual);
  const onChange = useCallback(
    (params: ArrangementMemoChangeParameter) => dispatch(arrangementAction.changeMemo(params)),
    [dispatch]
  );
  const onDelete = useCallback(
    (id: ProposalMemoId) => dispatch(arrangementAction.deleteMemo(id)),
    [dispatch]
  );
  const formik = useFormik<ArrangementMemoChangeParameter>({
    initialValues: {} as ArrangementMemoChangeParameter,
    onSubmit: (values) => {
      onChange(values);
      formik.setValues({} as ArrangementMemoChangeParameter);
    },
  });
  return (
    <FormikProvider value={formik}>
      <ArrangementMemoList
        list={memoList}
        loading={memoLoading}
        login={detail}
        onChange={onChange}
        onDelete={onDelete}
        formHeight={formHeight}
      />
    </FormikProvider>
  );
};

export default ArrangementListService;
