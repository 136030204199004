import React, { useContext } from 'react';
import Button from '@front/layouts/Button';
import { useFormContext } from 'react-hook-form';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { convertEstimationCompetitorDataForParameter } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/utils';
import { ProjectSalesInfoEstimationCompetitorCreateModalContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/features/create-modal/widgets/context';
import { modalLayout } from '@front/src/components/layout/modal';
import { useProjectSalesInfoEstimationCompetitorState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { ModalButtonGroup as ProjectSalesInfoEstimationCompetitorCreateModalButtonGroup };

const ModalButtonGroup = () => {
  const { Footer } = modalLayout;
  const {
    h: { onCreate, onClose },
  } = useContext(ProjectSalesInfoEstimationCompetitorCreateModalContext);
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { handleSubmit } = useFormContext();
  const onSubmit = handleSubmit((data) => {
    onCreate({
      id,
      ...convertEstimationCompetitorDataForParameter(data),
    });
  });
  const { readOnly } = useProjectSalesInfoEstimationCompetitorState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Footer>
      <Button
        shape="basic2"
        onClick={onClose}
      >
        취소
      </Button>
      <Button
        onClick={onSubmit}
        disabled={readOnly}
      >
        저장
      </Button>
    </Footer>
  );
};
