import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import type { SalesAccountingAmountInformationExpectPerformanceView } from '@front/src/features/accounting/features/upload/features/sales-info/types/view';
import TextBox from '@front/layouts/Text';
import { getTypeForAccountingUploadSalesInfoFormByList } from '@front/src/features/accounting/features/upload/features/sales-info/features/utils';
import { AccountingUploadSalesInfoPerformanceModalTable } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/widgets/list/components/table';
import { AccountingUploadSalesInfoPerformanceModalListTableBody } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/widgets/list/components/table-body';
import { AccountingUploadSalesInfoPerformanceModalProjectTable } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/widgets/list/components/project-table';
import { AccountingUploadSalesInfoPerformanceModalProjectTableBody } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/widgets/list/components/proejct-table-body';
import { AccountingUploadSalesInfoPerformanceModalListContext } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/widgets/list/provider/context';
import { AccountingUploadSalesInfoPerformanceModalUpdateWidget } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/widgets/update/widget';

export { Form as AccountingUploadSalesInfoPerformanceModalForm };

const Form = () => {
  const { list } = useContext(AccountingUploadSalesInfoPerformanceModalListContext);
  const methods = useForm({
    values: getValues(list),
  });
  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '3fr 1fr',
          gap: '4%',
          width: '100%',
          height: '560px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <TextBox variant="body11">단위: 만원, 부가세포함</TextBox>
          </Box>
          <AccountingUploadSalesInfoPerformanceModalTable>
            <AccountingUploadSalesInfoPerformanceModalListTableBody />
          </AccountingUploadSalesInfoPerformanceModalTable>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            sx={{
              height: '28px',
            }}
          />
          <AccountingUploadSalesInfoPerformanceModalProjectTable>
            <AccountingUploadSalesInfoPerformanceModalProjectTableBody />
          </AccountingUploadSalesInfoPerformanceModalProjectTable>
        </Box>
      </Box>
      <AccountingUploadSalesInfoPerformanceModalUpdateWidget />
    </FormProvider>
  );
};

const getValues = (list?: SalesAccountingAmountInformationExpectPerformanceView[]) => {
  const result = {
    amount0: '',
    amount1: '',
    amount2: '',
    amount3: '',
    amount4: '',
    amount5: '',
    amount6: '',
    amount7: '',
    amount8: '',
    amount9: '',
  };
  for (let i = 0; i < 10; i += 1) {
    result[`amount${i}`] =
      list
        ?.find((l) => l.type === getTypeForAccountingUploadSalesInfoFormByList[i])
        ?.amount?.toLocaleString() ?? '';
  }
  return result;
};
