import type { CampaignStatus } from '@front/ost_campaign/domain';

export interface CampaignQuery {
  keyword: string;
  status: CampaignStatus | '';
}

export const initialCampaignQuery: CampaignQuery = {
  keyword: '',
  status: '',
};

export interface CampaignMemoQuery {
  keyword: string;
}

export const initialCampaignMemoQuery: CampaignMemoQuery = {
  keyword: '',
};
