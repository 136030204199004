import React from 'react';
import { TableHead, TableRow } from '@mui/material';
import { Thc } from '@front/src/components/ui-builder/table';
import { PersonalSettingsModalUISettingsHeaderSettingsHeaderControlPanel } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/components/HeaderControlPanel';

export { TableHeader as PersonalSettingsModalUISettingsHeaderSettingsTableHeader };

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <Thc width="36px">
        <PersonalSettingsModalUISettingsHeaderSettingsHeaderControlPanel />
      </Thc>
      <Thc width="160px">현재 헤더명</Thc>
      <Thc width="160px">변경될 헤더명</Thc>
      <Thc width="60px">숨기기</Thc>
      <Thc width="60px">용도</Thc>
      <Thc width="200px">비고</Thc>
    </TableRow>
  </TableHead>
);
