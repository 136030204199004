import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { IdListDeleteParameter } from '@front/src/types/parameter';
import type { ProjectEstimationView } from '@front/src/features/project-sales-info/features/estimation/features/estimation/types/view';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';

export { context as ProjectSalesInfoEstimationEstimationContext };

interface State {
  d: {
    list?: ProjectEstimationView[];
    CMMethodOptionList?: VariableInformationView[];
  };
  h: {
    onDelete: (params: IdListDeleteParameter) => void;
  };
  isLoading: boolean;
}

const context = createContext<State>({
  d: {},
  h: {
    onDelete: noOp,
  },
  isLoading: false,
});
