import { useQuery } from 'react-query';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';
import { adminManageableVariableApi } from '@front/src/features/manageable-variable/query/api';
import type { AdminManageableVariableSearchParams } from '@front/src/features/manageable-variable/types/parameter';

export { query as adminManageableVariableQuery };
const query = {
  useAdminManageableVariableSearch: (params: AdminManageableVariableSearchParams, menuId) => {
    const { data, isLoading } = useQuery<VariableInformationView[]>({
      queryKey: ['admin', 'manageable-variable', 'search', params.keyword, params.keywordType],
      queryFn: () => adminManageableVariableApi.onAdminManageableVariableSearch(params, menuId),
      enabled: !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
  useListGet: (key: string, menuId) => {
    const { data, isLoading } = useQuery<VariableInformationView[]>({
      queryKey: ['admin', 'manageable-variable', key],
      queryFn: () => adminManageableVariableApi.getList(key, menuId),
      suspense: true,
      enabled: !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
