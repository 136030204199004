import React, { useCallback, useState } from 'react';

import MemoDrawerService from '@front/components/Memo/route/drawer';
import { OstRewardManagementMemoCreateService } from '@front/ost_reward/service/memo/create';
import { OstRewardManagementMemoListService } from '@front/ost_reward/service/memo/list';
import { OstRewardManagementMemoSearchService } from '@front/ost_reward/service/memo/search';

export { Index as OstRewardManagementMemoService };
const Index = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const setDrawer = useCallback(
    (isOpen) => {
      setIsOpen(isOpen);
    },
    [setIsOpen]
  );

  return (
    <MemoDrawerService
      isOpen={isOpen}
      setDrawer={setDrawer}
    >
      <OstRewardManagementMemoCreateService />
      <OstRewardManagementMemoSearchService />
      <OstRewardManagementMemoListService />
    </MemoDrawerService>
  );
};
