import { shallow } from 'zustand/shallow';
import { useCallback } from 'react';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import {
  projectSalesInfoContractCategoryKey,
  projectSalesInfoContractTypeKey,
} from '@front/src/features/project-sales-info/features/contract/utils/constant';
import { useProjectSalesInfoContractHistoryModalState } from '@front/src/features/project-sales-info/features/contract/features/history/features/modal/widgets/useState';
import { projectSalesInfoContractHistoryModalRepository } from '@front/src/features/project-sales-info/features/contract/features/history/features/modal/repository';

export { useLogic as useProjectSalesInfoContractHistoryModalLogic };

const useLogic = () => {
  const { isOpen, setIsOpen, id, setId } = useProjectSalesInfoContractHistoryModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      isOpen: state.isOpen,
      id: state.id,
      setId: state.setId,
    }),
    shallow
  );
  const { data: detail, isLoading } =
    projectSalesInfoContractHistoryModalRepository.useDetailGet(id);
  const { run: onCreate, setCallback: setCreateCallback } =
    projectSalesInfoContractHistoryModalRepository.useCreate();
  const { run: onUpdate, setCallback: setUpdateCallback } =
    projectSalesInfoContractHistoryModalRepository.useUpdate();
  const { data: categoryList, isLoading: isCategoryLoading } =
    adminManageableVariableRepository.useListGet(projectSalesInfoContractCategoryKey);
  const { data: typeList, isLoading: isTypeLoading } = adminManageableVariableRepository.useListGet(
    projectSalesInfoContractTypeKey
  );
  setCreateCallback({
    onSuccess: () => {
      handleClose();
    },
  });
  setUpdateCallback({
    onSuccess: () => {
      handleClose();
    },
  });
  const handleClose = useCallback(() => {
    setId(undefined);
    setIsOpen(false);
  }, [setIsOpen, setId]);
  const d = { detail, categoryList, typeList };
  const h = { onCreate, onUpdate, onClose: handleClose };
  return {
    d,
    h,
    isLoading,
    isOpen,
    isCategoryLoading,
    isTypeLoading,
  };
};
