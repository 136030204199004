import { useCallback, useRef } from 'react';

export const useThrottle = <T extends (...args: any[]) => void>(callback: T, delay: number): T => {
  const throttling = useRef<boolean>(false);

  const throttleHandler = useCallback(
    (...args: Parameters<T>) => {
      if (!throttling.current) {
        callback(...args);
        throttling.current = true;
        setTimeout(() => {
          throttling.current = false;
        }, delay);
      }
    },
    [callback, delay]
  ) as T;

  return throttleHandler;
};
