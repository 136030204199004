import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ProposalStatusUpdateParameter } from '@front/ost_proposal/parameter';
import type { CurrentPage } from '@front/navigate/domain';
import TitleButtonBar from '@front/ost_proposal/view/detail/TitleButtonBar';

interface Props {
  updateStatus: (status: ProposalStatusUpdateParameter) => void;
  currentPage: CurrentPage;
  updateFavorite: (isFavorite: boolean) => void;
  submit: () => void;
  openCampaignInfoModal: () => void;
  requestAdvisor: () => void;
  isContributor: boolean;
  onOpenCancelSubmissionModal: () => void;
}

const ButtonGroup = ({
  updateStatus,
  updateFavorite,
  submit,
  openCampaignInfoModal,
  requestAdvisor,
  isContributor,
  onOpenCancelSubmissionModal,
}: Props) => (
  <Layout>
    <TitleButtonBar
      updateFavorite={updateFavorite}
      submit={submit}
      updateStatus={updateStatus}
      openCampaignInfoModal={openCampaignInfoModal}
      requestAdvisor={requestAdvisor}
      isContributor={isContributor}
      onOpenCancelSubmissionModal={onOpenCancelSubmissionModal}
    />
  </Layout>
);
const Layout = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  width: '100%',
  padding: '0 10px',
});

export default ButtonGroup;
