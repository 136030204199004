import React from 'react';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';

interface Props {
  height?: string;
  colSpan?: number;
  message?: string;
}

export default function TableRowNoResultUI({
  height,
  colSpan,
  message = '등록된 정보가 없습니다.',
}: Readonly<Props>) {
  return (
    <TableRowUI
      sx={{
        height,
      }}
    >
      {/* 헤더 컬럼에 명시적으로 설정한 너비가 정상적으로 반영되기 위해 추가한 컬럼 대응을 위해 +1 처리  */}
      <TableCellUI colSpan={colSpan ? colSpan + 1 : colSpan}>{message}</TableCellUI>
    </TableRowUI>
  );
}
