import React from 'react';
import ModalUI, {
  ModalBodyUI,
  ModalContentUI,
} from '@front/src/components/components-with-design/layout/ModalUI';
import { Box } from '@mui/material';
import TypographyUI from '@front/src/components/components-with-design/component/TypographyUI';
import dayjs from 'dayjs';
import { YY_MM_DD } from '@front/src/utils';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import { ColorPalette } from '@front/assets/theme';
import { useShallow } from 'zustand/react/shallow';
import useApprovalStore from '@front/src/features/drawer-approval/useState';
import type { ApprovalFileParameter } from '@front/src/features/drawer-approval/types/parameter';
import { fileToView } from '@front/file-item';
import type { FileItemDto } from '@front/src/features/drawer-approval-document/types/view';

interface Props {
  item: ApprovalFileParameter;
  fileDto?: FileItemDto;
  fieldId: string;
}

export default function ApprovalImgModal({ item, fileDto, fieldId }: Readonly<Props>) {
  const { imgModalId, setImgModalId } = useApprovalStore(
    useShallow((state) => ({
      imgModalId: state.imgModalId,
      setImgModalId: state.setImgModalId,
    }))
  );
  const open = imgModalId === fieldId;
  const onClose = () => {
    setImgModalId('');
  };
  const newFileURL = item.multipartFile && URL.createObjectURL(item.multipartFile);
  const newFile = item.multipartFile && fileToView(item.multipartFile);
  return (
    <ModalUI
      title="미리 보기"
      open={open}
      onClose={onClose}
    >
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px',
            borderBottom: `1px solid ${ColorPalette.greyscale.black}`,
            backgroundColor: ColorPalette.background.bg,
          }}
        >
          <TypographyUI>
            {`날짜: ${dayjs(item.multipartFile ? newFile?.createdAt : fileDto?.createdAt).format(
              YY_MM_DD
            )} / 파일명: ${item.multipartFile ? newFile?.filename : fileDto?.filename}`}
          </TypographyUI>
          <ButtonBasicUI
            shape="secondary"
            size="medium"
            onClick={onClose}
          >
            닫기
          </ButtonBasicUI>
        </Box>
        <ModalBodyUI>
          <ModalContentUI>
            <img
              src={item.multipartFile ? newFileURL : `/api/file-item/${item.id}`}
              alt="클릭한 자료 이미지"
              style={{
                width: '800px',
                height: '800px',
              }}
            />
          </ModalContentUI>
        </ModalBodyUI>
      </>
    </ModalUI>
  );
}
