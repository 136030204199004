import { default as axios } from '@front/src/config/axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import type { ProjectSalesInfoAffiliatedCompanyWebHardParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/types/parameter';

const url = {
  getDetail: (id?) => `${projectSalesInfoURL}/affiliated-company-web-hard/${id}`,
  update: (id?) => `${projectSalesInfoURL}/affiliated-company-web-hard/${id}`,
};

export const projectSalesInfoAffiliatedCompanyWebHardUpdateModalApi = {
  getDetail: async (id?: number) => {
    const { data } = await axios.get(url.getDetail(id));
    return data;
  },
  update: async (params: ProjectSalesInfoAffiliatedCompanyWebHardParameter) => {
    await axios.put(url.update(params.id), params);
  },
};
