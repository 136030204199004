import React, { useCallback, useMemo } from 'react';
import Button from '@front/layouts/Button';
import { useAccountingSettingsTaxAccountingAccountState } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/useState';
import { useShallow } from 'zustand/react/shallow';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { getIsShowForAuthorizationFromMenu } from '@front/src/utils';

export { UploadButton as AccountingSettingsTexAccountingAccountUploadButton };

const UploadButton = () => {
  const { setIsOpen } = useAccountingSettingsTaxAccountingAccountState(
    useShallow((state) => ({
      setIsOpen: state.setIsOpen,
    }))
  );
  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const isShow = useMemo(() => {
    if (!loginUser?.menus) return false;
    return getIsShowForAuthorizationFromMenu(loginUser.menus);
  }, [loginUser]);
  if (!isShow) return <></>;
  return <Button onClick={handleClick}>E계정 업로드</Button>;
};
