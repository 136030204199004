import React, { useContext, useEffect } from 'react';
import { ProjectSalesInfoSubjectReviewModalKaKaoMapContext } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/kakao-map/widgets/context';
import classes from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/kakao-map/components/kakao-map.module.scss';

export { KaKaoMap as ProjectSalesInfoSubjectReviewModalKaKaoMap };

declare global {
  interface Window {
    kakao: any;
  }
}
const style = {
  width: '20dvw',
  height: '900px',
};
const KaKaoMap = () => {
  const { kakao } = window;
  const {
    d: { address },
    h: { remove },
  } = useContext(ProjectSalesInfoSubjectReviewModalKaKaoMapContext);

  useEffect(() => {
    if (!kakao?.maps) {
      return;
    }
    if (!address) {
      kakao.maps.load(() => {
        new kakao.maps.services.Geocoder().addressSearch('한양대학로55', (result, status) => {
          if (status === kakao.maps.services.Status.OK) {
            const { x, y } = result[0];
            const center = new kakao.maps.LatLng(y, x);
            new kakao.maps.Map(document.getElementById('map'), {
              center,
              level: 8,
            });
          }
        });
      });
      return;
    }

    kakao.maps.load(() => {
      new kakao.maps.services.Geocoder().addressSearch(address, (result, status) => {
        if (status === kakao.maps.services.Status.OK) {
          const { x, y } = result[0];
          const center = new kakao.maps.LatLng(y, x);
          const map = new kakao.maps.Map(document.getElementById('map'), {
            center,
            level: 8,
          });
          const marker = new kakao.maps.Marker({
            position: center,
          });
          marker.setMap(map);
        } else {
          const center = new kakao.maps.LatLng(37.566826, 126.9786567);

          const content =
            '<div class ="label" ' +
            'style=' +
            '"background-color: #f1f5fc; ' +
            'height: 80px;' +
            'display: flex;' +
            'justify-content: center;' +
            'align-items: center;' +
            'box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);' +
            'border-radius: 4px;' +
            'font-size: 14px;' +
            'padding: 15px";' +
            '>' +
            '<span class="center">주소 검색 결과가 없습니다.</span>' +
            '</div>';
          const map = new kakao.maps.Map(document.getElementById('map'), {
            center,
            level: 8,
          });
          const customOverlay = new kakao.maps.CustomOverlay({
            position: center,
            content: content,
          });
          customOverlay.setMap(map);
        }
      });
    });
    return () => {
      remove();
    };
  }, [kakao, address]);
  return (
    <div className={classes.container}>
      <div
        id="map"
        style={style}
        className={classes.map}
      />
    </div>
  );
};
