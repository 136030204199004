import { createContext } from 'react';
import type { AffiliatedCompanyMeetingHistoryShortView } from '@front/src/features/affiliated-company/features/meeting-history/types/view';

export { Context as AffiliatedCompanyPersonDetailModalMeetingHistoryContext };

interface State {
  d: {
    meetingList?: AffiliatedCompanyMeetingHistoryShortView[];
  };
  isLoading: boolean;
}

const Context = createContext<State>({
  d: {
    meetingList: [],
  },
  isLoading: false,
});
