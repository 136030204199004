import { useQuery } from 'react-query';
import { affiliatedCompanyCharacterPartnerCompanyApi } from '@front/src/features/affiliated-company/features/character/features/partner-company/query/api';
import type { AffiliatedCompanyCharacterPartnerCompanyView } from '@front/src/features/affiliated-company/features/character/features/partner-company/types/view';

export const affiliatedCompanyCharacterPartnerCompanyQuery = {
  useCharacterPartnerCompanyGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyCharacterPartnerCompanyView[]>({
      queryKey: ['affiliated-company', 'character', 'partner-company', id],
      queryFn: () =>
        affiliatedCompanyCharacterPartnerCompanyApi.getCharacterPartnerCompany(id, menuId),
      enabled: !!id && !!menuId,
      cacheTime: 0,
    });
    return {
      data,
      isLoading,
    };
  },
};
