import React from 'react';
import type { RootState } from '@front/services/reducer';
import { shallowEqual, useSelector } from 'react-redux';
import EditorComponent from '@front/components/TextEditor/EditorComponent';
import { Box } from '@mui/material';
import TextBox from '@front/layouts/Text';
import { ColorPalette } from '@front/assets/theme';

const ProposalBanner = () => {
  const { id, content: beforeContent } = useSelector(
    (root: RootState) => root.proposal.detail!.campaign.campaignBanner,
    shallowEqual
  );
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        padding: '10px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          border: `1px solid ${ColorPalette._e4e9f2}`,
          borderRadius: '5px',
          padding: '10px',
          backgroundColor: ColorPalette._d7e2f7,
        }}
      >
        <TextBox
          variant={'heading1'}
          sx={{ width: '13%', marginBottom: '10px', fontSize: '16px' }}
        >
          캠페인 소개
        </TextBox>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        <EditorComponent
          value={{
            id,
            beforeContent,
          }}
          update={() => {}}
          readOnly
        />
      </Box>
    </Box>
  );
};

export default ProposalBanner;
