import React, { useContext } from 'react';

import { NoResult } from '@front/src/components/layout/table/no-result';
import { AccountingSettingsManagementAccountingReadListContext } from '@front/src/features/accounting/features/settings/features/management-accounting-read/widgets/list/provider/context';
import { AccountingSettingsManagementAccountingReadListItem } from '@front/src/features/accounting/features/settings/features/management-accounting-read/widgets/list/components/list-item';

export { List as AccountingSettingsManagementAccountingReadList };

const List = () => {
  const { list, tableRowNum } = useContext(AccountingSettingsManagementAccountingReadListContext);
  if (list?.length === 0) {
    return <NoResult colSpan={7} />;
  }
  return (
    <>
      {Array.from({ length: tableRowNum }, (_, i) => i).map((sequence) => (
        <AccountingSettingsManagementAccountingReadListItem
          key={`table-row-${sequence}`}
          sequence={sequence}
        />
      ))}
    </>
  );
};
