import { useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { useCustomDialog } from '@front/src/features/dialog';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getIsShowForAuthorizationFromMenu, WriteAuthErrorMsgArr } from '@front/src/utils';

export const useOstAuth = (path: string) => {
  const loginUser = useSelector((root: RootState) => root.login.detail);
  const { alert } = useCustomDialog();
  const navigate = useNavigate();
  useEffect(() => {
    if (!loginUser) return;
    const auth = getIsShowForAuthorizationFromMenu(loginUser?.menus ?? []);
    if (auth) return;
    alert({
      title: '권한 오류',
      closeText: '확인',
      afterClose: () => navigate(path),
      children: WriteAuthErrorMsgArr,
    });
  }, [alert, loginUser, navigate, path]);
};
