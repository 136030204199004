import React, { useCallback } from 'react';
import { getEstimationOptionList } from '@front/src/features/project-sales-info/features/contract/features/contract/utils';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { useController, useFormContext } from 'react-hook-form';
import {
  convertAmountToStringForForm,
  convertBooleanToYNIfExistForForm,
  convertNullToEmptyForForm,
} from '@front/src/utils';
import type { ProjectEstimationView } from '@front/src/features/project-sales-info/features/estimation/features/estimation/types/view';

export { ModalEstimationSelect as ProjectSalesInfoContractContractModalEstimationSelect };

interface Props {
  estimationList?: ProjectEstimationView[];
  readOnly?: boolean;
}

const ModalEstimationSelect = ({ estimationList, readOnly }: Props) => {
  const { control, setValue } = useFormContext();
  const {
    field: { onChange },
  } = useController({ control, name: 'estimationId' });
  const handleChange = useCallback(
    (e) => {
      onChange(e);
      const selected = estimationList?.find((item) => item.id === +e.target.value);
      setValue('experimentInformation', convertNullToEmptyForForm(selected?.experimentInformation));
      setValue('experimentAmount', convertAmountToStringForForm(selected?.experimentAmount));
      setValue('reviewAmount', convertAmountToStringForForm(selected?.experimentAmount));
      setValue('totalAmount', convertAmountToStringForForm(selected?.experimentAmount));
      setValue('unitPrice', convertAmountToStringForForm(selected?.unitPrice));
      setValue('schedule', convertNullToEmptyForForm(selected?.schedule));
      setValue('CMAmount', convertAmountToStringForForm(selected?.CMAmount));
      setValue('CMMethod', convertNullToEmptyForForm(selected?.CMMethod));
      setValue('convertedCMAmount', convertAmountToStringForForm(selected?.convertedCMAmount));
      setValue('isCm', convertBooleanToYNIfExistForForm(selected?.isCm));
    },
    [onChange, estimationList, setValue]
  );
  return (
    <HookFormSelect
      width="200px"
      defaultLabel="선택"
      isDefaultPossible
      name="estimationId"
      optionList={getEstimationOptionList(estimationList)}
      onChange={handleChange}
      disabled={readOnly}
    />
  );
};
