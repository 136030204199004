import React from 'react';
import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import workIndicatorQuery from '@front/src/features/work/features/work/features/creator/features/indicator/query/query';
import type { WorkCreatorView } from '@front/src/features/work/features/work/features/creator/types/view';
import WorkIndicatorTableRow from '@front/src/features/work/features/work/features/creator/features/indicator/components/TableRow';

export default function WorkIndicatorTableBody({
  sectionId,
  dataId,
  menuId,
  ...rest
}: Readonly<UIBuilderTableBodyProps>) {
  const { data: detail } = workIndicatorQuery.useGet({
    dataId,
    menuId,
    sectionId,
  });
  let list: WorkCreatorView[] | undefined = undefined;
  if (detail) {
    list = [{ ...detail }];
  }
  return (
    <UIBuilderTableBody<WorkCreatorView>
      list={list}
      RowComponent={WorkIndicatorTableRow}
      sectionId={sectionId}
      dataId={dataId}
      menuId={menuId}
      {...rest}
    />
  );
}
