import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { convertEmptyToNull, getMenuIdHeaders } from '@front/src/utils';
import { personBaseUrl } from '@front/src/features/affiliated-company/utils/constant';
import type { AffiliatedCompanyPersonCharacterCharacterUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/character/types/parameter';

const url = {
  getPersonCharacterCharacterList: (id?) => `${personBaseUrl}/${id}/character`,
  createPersonCharacterCharacter: (id?) => `${personBaseUrl}/${id}/character`,
  deletePersonCharacterCharacter: () => `${personBaseUrl}/character`,
  updatePersonCharacterCharacter: (id?) => `${personBaseUrl}/character/${id}`,
};

export const affiliatedCompanyPersonCharacterCharacterApi = {
  getPersonCharacterCharacterList: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getPersonCharacterCharacterList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  createPersonCharacterCharacter: async (id?: number, menuId?) => {
    await axios.post(url.createPersonCharacterCharacter(id), null, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  deletePersonCharacterCharacter: async (
    params: AffiliatedCompanyIdListDeleteParameter,
    menuId
  ) => {
    await axios.delete(url.deletePersonCharacterCharacter(), {
      data: params,
      headers: getMenuIdHeaders(menuId),
    });
  },
  updatePersonCharacterCharacter: async (
    params: AffiliatedCompanyPersonCharacterCharacterUpdateParameter,
    menuId
  ) => {
    await axios.put(url.updatePersonCharacterCharacter(params.id), convertEmptyToNull(params), {
      headers: getMenuIdHeaders(menuId),
    });
  },
};
