import { useMutation } from 'react-query';
import { commonApi } from '@front/src/api/api';

const commonMutation = {
  useCreateImage: (menuId) => {
    const { mutate, isLoading } = useMutation<any, unknown, FormData>({
      mutationFn: (formData) => commonApi.createImage(formData, menuId),
      onSuccess: ({ data }) => data.data,
    });
    return {
      mutate,
      isLoading,
    };
  },
};

export default commonMutation;
