import type { UserNotificationView } from '@front/src/features/mobile-notification/types/view';
import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import type {
  UserNotificationIsReadParameter,
  UserNotificationParameter,
} from '@front/src/features/mobile-notification/types/parameter';

interface State {
  mobileNotificationList?: UserNotificationView[];
  onToggle: (params: UserNotificationIsReadParameter) => void;
  onDelete: (id: number) => void;
  onPinUpdate: (params: UserNotificationParameter) => void;
}

export { Context as MobileNotificationListContext };

const Context = createContext<State>({
  onToggle: noOp,
  onDelete: noOp,
  onPinUpdate: noOp,
});
