import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useProjectSalesInfoActivityState } from '@front/src/features/project-sales-info/features/activity/useState';
import { useShallow } from 'zustand/react/shallow';
import { SalesInfoActivityTableCellCheckbox } from '@front/src/features/project-sales-info/features/activity/widgets/activity-item/components/cell-checkbox';
import { SalesInfoActivityTableCellNo } from '@front/src/features/project-sales-info/features/activity/widgets/activity-item/components/cell-no';
import { SalesInfoActivityTableCellDate } from '@front/src/features/project-sales-info/features/activity/widgets/activity-item/components/cell-date';
import { SalesInfoActivityTableCellTime } from '@front/src/features/project-sales-info/features/activity/widgets/activity-item/components/cell-time';
import { SalesInfoActivityTableCellCategory } from '@front/src/features/project-sales-info/features/activity/widgets/activity-item/components/cell-category';
import { SalesInfoActivityTableCellAddUser } from '@front/src/features/project-sales-info/features/activity/widgets/activity-item/components/cell-add-user';
import { SalesInfoActivityRowContext } from '@front/src/features/project-sales-info/features/activity/widgets/row/provider/context';
import { ProjectSalesInfoActivityItemContext } from '@front/src/features/project-sales-info/features/activity/widgets/activity-item/provider/context';
import dayjs from 'dayjs';
import { convertEmptyToNull, HH_MM_SS } from '@front/src/utils';

export { ActivityItemRow as ProjectSalesInfoActivityItemRow };

const ActivityItemRow = () => {
  const { item } = useContext(SalesInfoActivityRowContext);
  const { onActivityUpdate } = useContext(ProjectSalesInfoActivityItemContext);
  const { note } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      note: state.note,
    }))
  );
  const methods = useForm({
    values: {
      id: item.activity?.id,
      date: item.activity?.date ?? '',
      time: item.activity?.time ?? '',
      category: item.activity?.category ?? '',
      note: note ?? '',
    },
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    const converted = {
      ...convertEmptyToNull(data),
      time:
        data.time && dayjs(data.time, HH_MM_SS).isValid()
          ? dayjs(data.time, HH_MM_SS).format(HH_MM_SS)
          : null,
    };
    onActivityUpdate(converted);
  });
  return (
    <FormProvider {...methods}>
      <SalesInfoActivityTableCellCheckbox />
      <SalesInfoActivityTableCellNo />
      <SalesInfoActivityTableCellDate onSubmit={onSubmit} />
      <SalesInfoActivityTableCellTime onSubmit={onSubmit} />
      <SalesInfoActivityTableCellCategory onSubmit={onSubmit} />
      <SalesInfoActivityTableCellAddUser />
    </FormProvider>
  );
};
