import { useMutation, useQueryClient } from 'react-query';
import { useCustomDialog } from '@front/src/features/dialog';
import { handleError } from '@front/src/utils';
import type { AffiliatedPersonCreateParameter } from '@front/src/features/affiliated-person/features/app-bar/types/parameter';
import { affiliatedPersonApi } from '@front/src/features/affiliated-person/api/api';

export { mutation as affiliatedPersonAppBarMutation };

const mutation = {
  useCreate: () => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedPersonCreateParameter) => affiliatedPersonApi.create(params),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-person', 'drawer', 'list'],
        });
        return data;
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
