import { affiliatedPersonSalesActivityQuery } from '@front/src/features/affiliated-person/features/sales-activity/query/query';

export { repository as affiliatedPersonSalesActivityRepository };
const repository = {
  useListGet: (id: number) => {
    const { data } = affiliatedPersonSalesActivityQuery.useListGet(id);
    return {
      data,
    };
  },
};
