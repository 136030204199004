import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { AccountingUploadEcountUploadParameter } from '@front/src/features/accounting/features/upload/features/ecount-data/types/parameter';

export { Context as AccountingSettingsBankAccountUploadContext };

interface State {
  onCreate: (params: AccountingUploadEcountUploadParameter) => void;
}

const Context = createContext<State>({
  onCreate: noOp,
});
