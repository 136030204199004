import { default as axios } from '@front/src/config/axios';
import type Page from 'type/Page';
import type {
  DepartmentAddParameter,
  DepartmentChangeParameter,
  DepartmentChangeTreeParameter,
  DepartmentQuery,
} from './parameter';
import type { Department, ListDepartment } from 'department_tree/entity';

export class DepartmentTreeApi {
  async getAll(): Promise<ListDepartment[]> {
    const { data } = await axios.get('/api/department/all');
    return data;
  }

  async getPage(query: DepartmentQuery): Promise<Page<ListDepartment>> {
    const { data } = await axios.get('/api/department', {
      params: query,
      paramsSerializer: {
        indexes: null,
      },
    });
    return data;
  }

  async getOne(id: number): Promise<Department> {
    const { data } = await axios.get(`/api/department/${id}`);
    return data;
  }

  async add(params: DepartmentAddParameter): Promise<Department> {
    const { data } = await axios.post('/api/department', params, {
      paramsSerializer: {
        indexes: null,
      },
    });
    return data;
  }

  async change(params: DepartmentChangeParameter): Promise<Department> {
    const { id } = params;
    const { data } = await axios.patch(`/api/department/${id}`, params, {
      paramsSerializer: {
        indexes: null,
      },
    });

    return data;
  }

  async changeTree(params: DepartmentChangeTreeParameter): Promise<ListDepartment[]> {
    const { data } = await axios.post('/api/department/tree', params, {
      paramsSerializer: {
        indexes: null,
      },
    });
    return data;
  }
}

const departmentTreeApi = new DepartmentTreeApi();
export default departmentTreeApi;
