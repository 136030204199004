import { projectSalesInfoPaymentHistoryCreateModalRepository } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/create-modal/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import { projectSalesInfoPaymentHistoryUpdateModalRepository } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/update-modal/repository';

export { useLogic as useProjectSalesInfoPaymentHistoryCreateModalLogic };
const useLogic = () => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { run: onCreate } =
    projectSalesInfoPaymentHistoryCreateModalRepository.usePaymentHistoryCreate(id!);
  const { data: isPaidOptionList } =
    adminManageableVariableRepository.useListGet('영업관리-지급여부');
  const { data: consortiumCompanyList } =
    projectSalesInfoPaymentHistoryUpdateModalRepository.useConsortiumCompanyGet(id!);
  return {
    d: {
      isPaidOptionList,
      consortiumCompanyList,
    },
    h: {
      onCreate,
    },
  };
};
