import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanySearchParameter } from '@front/src/features/affiliated-company/types/parameter';
import { baseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { companyBaseURL } from '@front/src/features/affiliated-company-selector/utils/constants';

const url = {
  getList: () => `${baseUrl}`,
  getPersonList: (id?) => `${baseUrl}/${id}/person`,
  getCompanyList: () => `${companyBaseURL}`,
};

export const affiliatedCompanySelectorApi = {
  getList: async (params: AffiliatedCompanySearchParameter) => {
    const { data } = await axios.get(url.getList(), {
      params: {
        keyword: params.keyword,
      },
    });
    return data;
  },
  getPersonList: async (id?: number) => {
    const { data } = await axios.get(url.getPersonList(id), {
      params: {
        isIncludedResignedPerson: false,
      },
    });
    return data;
  },
  getCompanyList: async (params: AffiliatedCompanySearchParameter) => {
    const { data } = await axios.get(url.getCompanyList(), {
      params: {
        keyword: params.keyword,
      },
    });
    return data;
  },
};
