import React from 'react';
import classes from '@front/src/features/project-sales-info/features/collection/components/layout/footer.module.scss';

interface Props {
  children: React.ReactNode;
  width?: string;
}

export const Footer = ({ children, width }: Props) => (
  <div
    className={classes.footer}
    style={{ width: width }}
  >
    {children}
  </div>
);
