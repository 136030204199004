import { useQuery } from 'react-query';
import type { AffiliatedCompanyAddressView } from '@front/src/features/affiliated-company/features/outline/features/address/types/view';
import { affiliatedCompanyOutlineAddressApi } from '@front/src/features/affiliated-company/features/outline/features/address/query/api';

export const affiliatedCompanyOutlineAddressQuery = {
  useOutlineAddressGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyAddressView[]>({
      queryKey: ['affiliated-company', 'address', id],
      queryFn: () => affiliatedCompanyOutlineAddressApi.getOutlineAddress(id, menuId),
      enabled: !!id,
    });
    return {
      data,
      isLoading,
    };
  },
};
