import React, { Suspense, useRef } from 'react';
import type { ControlPanelItem } from '@front/src/types/domain';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material/styles';
import { controlPanelTableCellWidth, getLeftListFromHeaderList } from '@front/src/utils';
import { commonRepository } from '@front/src/api/repository';
import HistoryIcon from '@mui/icons-material/History';
import type {
  UIBuilderRowActions,
  UIBuilderTableBodyProps,
} from '@front/src/components/ui-builder/table/TableBody';
import headerMeta from '@front/src/components/ui-builder/headerMeta';
import type { OutputOptions } from '@front/src/features/work/features/work/features/output/components/Table';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableHeadUI from '@front/src/components/components-with-design/compound/table/TableHeadUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import TableRowLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/TableRowLoadingSpinnerUI';
import UiBuilderHistoryModal from '@front/src/components/ui-builder/table/HistoryModal';
import TableBodyLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/TableBodyLoadingSpinnerUI';
import ContextMenuUI from '@front/src/components/components-with-design/compound/ContextMenuUI';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import useModalOpen from '@front/src/hooks/useModalOpen';
import ButtonIconFileInfo from '@front/src/features/work/features/work/components/ButtonIconFileInfo';
import { Box } from '@mui/material';

interface Props extends SectionComponentProps {
  controlPanelHeaderItemList?: ControlPanelItem[];
  outputOptions?: OutputOptions;
  sx?: SxProps<Theme>;
  BodyComponent: (props: UIBuilderTableBodyProps) => JSX.Element;
  isLoading?: boolean;
  rowActions?: UIBuilderRowActions;
}

export default function UiBuilderTable({
  readOnly,
  controlPanelHeaderItemList = [],
  sx = {},
  sectionId,
  BodyComponent,
  isLoading = false,
  menuId,
  ...rest
}: Readonly<Props>) {
  const ref = useRef<HTMLTableSectionElement | null>(null);
  const { data: headerList, stickyIdx } = commonRepository.useGetHeaderList(sectionId, menuId);
  const { onOpen, open, onClose } = useModalOpen();
  const leftList = getLeftListFromHeaderList(headerList, readOnly);
  const itemList = [
    ...controlPanelHeaderItemList,
    {
      text: '헤더명 변경이력',
      icon: HistoryIcon,
      action: onOpen,
    },
  ];
  const renderControlPanel = () => {
    if (readOnly) return <></>;
    return (
      <TableCellUI
        isHead
        // left={stickyIdx === -1 ? undefined : 0}
        width={`${controlPanelTableCellWidth}px`}
      >
        <ContextMenuUI
          isHeader
          itemList={itemList}
        />
      </TableCellUI>
    );
  };
  const headerCellList = headerList?.map((h, i) => {
    const { width } = headerMeta[h.id] ?? 100;
    if (stickyIdx === undefined) return null;
    if (i <= stickyIdx) {
      return (
        <TableCellUI
          isHead
          key={h.id}
          width={`${width}px`}
          // isFixed={h.isFixed}
          // left={leftList?.[i]}
        >
          {h.name}
        </TableCellUI>
      );
    }
    if (h.name === '파일명') {
      return (
        <TableCellUI
          isHead
          key={h.id}
          width={`${width}px`}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: '5px',
            }}
          >
            {h.name}
            <ButtonIconFileInfo />
          </Box>
        </TableCellUI>
      );
    }
    return (
      <TableCellUI
        isHead
        key={h.id}
        width={`${width}px`}
      >
        {h.name}
      </TableCellUI>
    );
  });
  const renderTableBody = () => (
    <BodyComponent
      readOnly={readOnly}
      sectionId={sectionId}
      menuId={menuId}
      {...rest}
    />
  );
  const renderNote = () => (
    <TableCellUI
      isHead
      width={`${controlPanelTableCellWidth}px`}
    >
      비고
    </TableCellUI>
  );
  return (
    <TableContainerUI
      sx={{
        position: 'relative',
        ...sx,
      }}
    >
      <TableBodyLoadingSpinnerUI
        sx={{
          height: ref.current ? ref.current.clientHeight : 0,
          top: 42,
        }}
        open={isLoading}
      />
      <UiBuilderHistoryModal
        open={open}
        onClose={onClose}
        sectionId={sectionId}
      />
      <TableTableUI sx={{
        '& .MuiTableRow-root > .MuiTableCell-root:nth-last-child(2)' : {
          borderRight: 'none'
        },
        '& thead .MuiTableRow-root > .MuiTableCell-root:last-child' : {
        }
      }}>
        <TableHeadUI>
          <TableRowUI>
            {renderControlPanel()}
            {renderNote()}
            {headerCellList}
            {/* 컬럼에 명시적으로 설정한 너비가 정상적으로 반영되기 위한 여백 컬럼(헤더/바디 각각 추가) */}
            <TableCellUI
              isHead
              sx={{width:'auto'}}
            />
          </TableRowUI>
        </TableHeadUI>
        <TableBodyUI ref={ref}>
          <Suspense fallback={<TableRowLoadingSpinnerUI colSpan={headerList?.length ?? 0} />}>
            {renderTableBody()}
          </Suspense>
        </TableBodyUI>
      </TableTableUI>
    </TableContainerUI>
  );
}
