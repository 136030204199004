import React from 'react';

import PageLayoutWithMemo from '@front/layouts/PageLayoutWithMemo';
import { OstRewardManagementDetailService } from '@front/ost_reward/service/detail';
import { OstRewardManagementMemoService } from '@front/ost_reward/service/memo';
import { useMobileMenuState } from '@front/mobile-menu/useState';
import { useOstAuth } from '@front/src/hooks/useOstAuth';
import { useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const OstRewardManagementDetailElement = ({ name }: Readonly<Props>) => {
  const isMemo = useMobileMenuState((state) => state.isMemo);
  useOstAuth('/ost/ost-reward-management');
  const loginUser = useSelector((root: RootState) => root.login.detail);
  const menuId = sessionStorage.getItem('menuId');
  const isAuthV = loginUser?.menus?.find((item) => item.id === (menuId ? +menuId : 0));
  if (isAuthV?.authorization.type === 'V') return <></>;
  return (
    <PageLayoutWithMemo>
      {name}
      <OstRewardManagementDetailService />
      {isMemo && <OstRewardManagementMemoService />}
    </PageLayoutWithMemo>
  );
};

export default OstRewardManagementDetailElement;
