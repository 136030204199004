import { requestUrlMyPage } from '@front/src/features/my-page/utils/constant';
import { getMenuIdHeaders } from '@front/src/utils';
import axios from 'axios';

const url = {
  getMenuList: () => `${requestUrlMyPage}/menu`,
  getTabList: (id) => `${requestUrlMyPage}/menu-parent/${id}`,
  getAdminMenuList: () => `/api/admin/menu/admin`,
  getNotDevelopmentMenuList: (id) => `/api/admin/menu/parent/${id}/not-development`,
};

const myPageApi = {
  getMenuList: async (menuId) => {
    const { data } = await axios.get(url.getMenuList(), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getTabList: async (id, menuId) => {
    const { data } = await axios.get(url.getTabList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getAdminMenuList: async (menuId) => {
    const { data } = await axios.get(url.getAdminMenuList(), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getNotDevelopmentMenuList: async (id, menuId) => {
    const { data } = await axios.get(url.getNotDevelopmentMenuList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};

export default myPageApi;
