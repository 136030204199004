import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useShallow } from 'zustand/react/shallow';
import { useAccountingUploadSalesInfoPerformanceModalState } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/useState';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import dayjs from 'dayjs';
import { useAccountingUploadSalesInfoState } from '@front/src/features/accounting/features/upload/features/sales-info/useState';

export { MonthSelect as AccountingUploadSalesInfoPerformanceModalMonthSelect };

const MonthSelect = () => {
  const { year, setYear } = useAccountingUploadSalesInfoState(
    useShallow((state) => ({
      setYear: state.setYear,
      year: state.year,
    }))
  );
  const { month, setMonth } = useAccountingUploadSalesInfoPerformanceModalState(
    useShallow((state) => ({
      month: state.month,
      setMonth: state.setMonth,
    }))
  );
  const methods = useForm({
    values: {
      month: month ? month.toString() : '',
    },
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    if (year) {
      if (data.month === '12') {
        setYear(year - 1);
      }
      if (data.month === '1') {
        setYear(year + 1);
      }
    }
    const month = data.month ? +data.month : undefined;
    setMonth(month);
  });
  return (
    <FormProvider {...methods}>
      <HookFormSelect
        width="80px"
        name="month"
        optionList={getMonthStringList()}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
};

const getMonthStringList = () => {
  const now = dayjs(new Date());
  const lastMonth = (now.month() - 1 + 12) % 12;
  const list: { value: string; label: string }[] = [];
  const lastMonthString = (lastMonth + 1).toString();
  list.push({
    value: lastMonthString,
    label: lastMonthString,
  });
  const thisMonth = (lastMonth + 1 + 12) % 12;
  const thisMonthString = (thisMonth + 1).toString();
  list.push({
    value: thisMonthString,
    label: thisMonthString,
  });
  return list;
};
