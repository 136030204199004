import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { AffiliatedPersonMemoCreateContext } from '@front/src/features/affiliated-person/features/memo/widgets/create/provider/context';
import { useAffiliatedPersonMemoCreateLogic } from '@front/src/features/affiliated-person/features/memo/widgets/create/provider/useLogic';

export { Provider as AffiliatedPersonMemoCreateProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { onCreate } = useAffiliatedPersonMemoCreateLogic();
  const value = useMemo(
    () => ({
      onCreate,
    }),
    [onCreate]
  );
  return (
    <AffiliatedPersonMemoCreateContext.Provider value={value}>
      {children}
    </AffiliatedPersonMemoCreateContext.Provider>
  );
};
