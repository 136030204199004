import React, { Suspense } from 'react';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';
import { AffiliatedPersonCompanySelectModalListProvider } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/widgets/list/provider/provider';
import { AffiliatedPersonCompanySelectModalList } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/widgets/list/components/list';

export { Widget as AffiliatedPersonCompanySelectModalListWidget };
const Widget = () => (
  <Suspense
    fallback={
      <TableLoadingSpinner
        colSpan={4}
        height="360px"
      />
    }
  >
    <AffiliatedPersonCompanySelectModalListProvider>
      <AffiliatedPersonCompanySelectModalList />
    </AffiliatedPersonCompanySelectModalListProvider>
  </Suspense>
);
