import React, { Suspense } from 'react';
import { AccountingSettingsManagementAccountingAccountHeaderText } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/header/widgets/get-updated-at/components/text';
import { AccountingSettingsManagementAccountingAccountHeaderGetUpdatedAtProvider } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/header/widgets/get-updated-at/provider/provider';
import { LoadingSpinner } from '@front/src/components/loading-spinner';

export { Widget as AccountingSettingsManagementAccountingAccountHeaderGetUpdatedAtWidget };

const Widget = () => (
  <Suspense fallback={<LoadingSpinner height="60px" />}>
    <AccountingSettingsManagementAccountingAccountHeaderGetUpdatedAtProvider>
      <AccountingSettingsManagementAccountingAccountHeaderText />
    </AccountingSettingsManagementAccountingAccountHeaderGetUpdatedAtProvider>
  </Suspense>
);
