import React, { useCallback, useMemo } from 'react';
import { Checkbox } from '@mui/material';
import { usePersonalSettingsModalUISettingsModalSettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/useState';
import { useShallow } from 'zustand/react/shallow';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { personalSettingsModalUISettingsModalSettingsRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/repository/repository';
import type { HeaderSettingsConvertToModalHeaderView } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/types/view';

export { SelectableHeaderTableCheckbox as PersonalSettingsModalUISettingsModalSettingsSelectableHeaderTableCheckbox };

interface Props {
  index: number;
  sectionId: number;
  authMenuId: number | undefined;
}

const SelectableHeaderTableCheckbox = ({ index, sectionId, authMenuId }: Readonly<Props>) => {
  const { control } = useFormContext();
  const { data: list } = personalSettingsModalUISettingsModalSettingsRepository.useGetHeaderList(
    sectionId,
    authMenuId
  );
  const { data: originalSelectableHeaderList } =
    personalSettingsModalUISettingsModalSettingsRepository.useGetModalHeaderList(
      sectionId,
      authMenuId
    );
  const {
    parentId,
    append: appendChild,
    remove: removeChild,
  } = usePersonalSettingsModalUISettingsModalSettingsState(
    useShallow((state) => ({
      parentId: state.parentId,
      remove: state.remove,
      append: state.append,
    }))
  );
  const sh: HeaderSettingsConvertToModalHeaderView = useWatch({
    name: `selectableHeaderList.${index}`,
    control,
  });
  const childList = useWatch({ name: 'childList', control });
  const findIndex = useMemo(
    () => childList?.findIndex((c) => c.headerSettingId === sh.headerSettingId),
    [childList, sh]
  );
  const {
    field: { value: isUsed, onChange: onIsUsedChange },
  } = useController({
    name: `selectableHeaderList.${index}.isUsed`,
    control,
  });
  const {
    field: { onChange: onParentIdChange },
  } = useController({
    name: `selectableHeaderList.${index}.parentId`,
    control,
  });
  const {
    field: { onChange: onParentNameChange },
  } = useController({
    name: `selectableHeaderList.${index}.parentName`,
    control,
  });
  const parent = useMemo(() => list?.find((l) => l.id === parentId), [list, parentId]);
  const onCheck = useCallback(() => {
    onParentIdChange(parent?.id);
    onParentNameChange(parent?.name ?? '');
    appendChild({
      headerSettingId: sh.headerSettingId,
      name: sh.name,
    });
  }, [list, appendChild, onParentNameChange, sh, parent, onParentIdChange]);
  const originalSelectableHeader = useMemo(
    () => originalSelectableHeaderList?.find((o) => o.headerSettingId === sh.headerSettingId),
    [originalSelectableHeaderList, sh]
  );
  const onUncheck = useCallback(() => {
    removeChild(findIndex);
    if (originalSelectableHeader) {
      onParentIdChange(originalSelectableHeader.parentId);
      onParentNameChange(originalSelectableHeader.parentName);
      return;
    }
    onParentIdChange(undefined);
    onParentNameChange('');
  }, [removeChild, onParentNameChange, findIndex, onParentIdChange, originalSelectableHeader]);
  const handleCheckboxChange = useCallback(
    (e) => {
      const {
        target: { checked },
      } = e;
      onIsUsedChange(e);
      if (checked) {
        onCheck();
        return;
      }
      onUncheck();
    },
    [onIsUsedChange, onCheck, onUncheck]
  );
  return (
    <Checkbox
      checked={isUsed}
      onChange={handleCheckboxChange}
    />
  );
};
