import { accountingUploadMutation } from '@front/src/features/accounting/features/upload/features/ecount-data/query/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { accountingUploadQuery } from '@front/src/features/accounting/features/upload/features/ecount-data/query/query';

export { Repository as accountingUploadRepository };

const Repository = {
  useCreateTaxAccounting: () => {
    const { mutate, isLoading } = accountingUploadMutation.useCreateTaxAccounting();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
      isLoading,
    };
  },
  useCreateBankAccount: () => {
    const { mutate, isLoading } = accountingUploadMutation.useCreateBankAccount();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
      isLoading,
    };
  },
  useCreateDailyReport: () => {
    const { mutate, isLoading } = accountingUploadMutation.useCreateDailyReport();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
      isLoading,
    };
  },
  useGetList: () => {
    const { data, isLoading } = accountingUploadQuery.useGetList();
    return {
      data,
      isLoading,
    };
  },
};
