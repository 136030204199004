import type { SectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import React from 'react';
import OstCampaignManagementDetailRoute from '@front/ost_campaign/route';
import OstProposalDetailRoute from '@front/ost_proposal/route';
import OstEvaluationDetailRoute from '@front/ost_evaluation/route';
import OstVoteDetailRoute from '@front/ost_vote/route';
import OstRewardManagementDetailElement from '@front/ost_reward/route/detail';
import OstManagementDetailRoute from '@front/ost/route';
import OstArrangementDetailRoute from '@front/ost_arrangement/route';

export { sectionComponent as ostSectionComponent };

const sectionComponent: SectionComponent = {
  66: ({ ...rest }) => <OstCampaignManagementDetailRoute {...rest} />,
  67: ({ ...rest }) => <OstProposalDetailRoute {...rest} />,
  68: ({ ...rest }) => <OstEvaluationDetailRoute {...rest} />,
  69: ({ ...rest }) => <OstVoteDetailRoute {...rest} />,
  70: ({ ...rest }) => <OstRewardManagementDetailElement {...rest} />,
  71: ({ ...rest }) => <OstManagementDetailRoute {...rest} />,
  72: ({ ...rest }) => <OstArrangementDetailRoute {...rest} />,
};
