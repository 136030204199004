export const enum MOBILE_DEFAULT_ENUM {
  NOTIFICATION = '알림',
  NOTICE = '공지',
  MAIL = '메일',
  HAS_WORK = 'HAS 업무',
  COMMUNITY = '커뮤니티',
  SETTINGS = '설정',
  LOGOUT = '로그아웃',
}

export const enum APPROVAL_DOCUMENT_STATUS {
  TEMPORARY = 'TEMPORARY',
  ON_GOING = 'ON-GOING',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  SUBMISSION = 'SUBMISSION',
}

export const enum PARENTS_TYPE {
  PROPOSAL = 'PROPOSAL',
  PROCEEDINGS = 'PROCEEDINGS',
  PROJECT = 'PROJECT',
  CAMPAIGN = 'CAMPAIGN',
  EVALUATION = 'EVALUATION',
  VOTE = 'VOTE',
  OST = 'OST',
  ARRANGEMENT = 'ARRANGEMENT',
  APPROVAL = 'APPROVAL',
  REWARD = 'REWARD',
  AFFILIATED_COMPANY = 'AFFILIATED-COMPANY',
  AFFILIATED_COMPANY_PERSON = 'AFFILIATED-PERSON',
  WORK = 'WORK',
  ACCOUNTING = 'ACCOUNTING',
}

export const enum BODY_TEMPLATE_TYPE {
  BASIC = 'BASIC',
  COMPARE = 'COMPARE',
}

export const enum APPROVAL_TYPE {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  BEFORE_REJECTED = 'BEFORE_REJECTED',
}

export const enum APPROVAL_BOX_TYPE {
  APPROVAL = 'APPROVAL',
  APPROVAL_DOCUMENT = 'APPROVAL-DOCUMENT',
  REFERENCE = 'REFERENCE',
}

export const enum COMMON_TARGET_TYPE {
  NOTE = 'NOTE',
  APPROVAL = 'APPROVAL',
  CONTENTS = 'CONTENTS',
  WORK_REFERENCE = 'WORK_REFERENCE',
  WORK_OUTPUT = 'WORK_OUTPUT',
  WORK_CAROUSEL = 'WORK_CAROUSEL',
}

export const enum LINK_TYPE {
  NOTE = 'NOTE',
  APPROVAL = 'APPROVAL',
  REVIEW = 'REVIEW',
  ASSIGNMENT = 'ASSIGNMENT',
}
