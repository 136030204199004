import React, { useCallback } from 'react';
import Checkbox from '@front/layouts/Checkbox';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/widgets/useState';
import type { ProjectAffiliatedCompanyPersonView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/types/view';
import { spliceAndPushIfExist } from '@front/src/features/affiliated-company/utils';
import { shallow } from 'zustand/shallow';
import { useShallow } from 'zustand/react/shallow';

export { CheckBox as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonTableRowCheckBox };

interface Props {
  item: ProjectAffiliatedCompanyPersonView;
}

const CheckBox = ({ item }: Props) => {
  const { idList, setIdList } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
      id: state.id,
    }),
    shallow
  );
  const handleChange = useCallback(
    (e) => {
      e.stopPropagation();
      setIdList(spliceAndPushIfExist(idList, item.id));
    },
    [setIdList, idList, item.id]
  );
  const { readOnly } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Checkbox
      onClick={handleChange}
      checked={idList.includes(item.id)}
      onChange={handleChange}
      disabled={readOnly}
    />
  );
};
