import React, { Suspense } from 'react';
import { AccountingSettingsManagementAccountingReadTableHeader2 } from '@front/src/features/accounting/features/settings/features/management-accounting-read/widgets/count/components/table-header';
import { AccountingSettingsManagementAccountingReadCountProvider } from '@front/src/features/accounting/features/settings/features/management-accounting-read/widgets/count/provider/provider';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';

export { Widget as AccountingSettingsManagementAccountingReadCountWidget };

const Widget = () => (
  <Suspense
    fallback={
      <TableLoadingSpinner
        colSpan={7}
        height="40px"
      />
    }
  >
    <AccountingSettingsManagementAccountingReadCountProvider>
      <AccountingSettingsManagementAccountingReadTableHeader2 />
    </AccountingSettingsManagementAccountingReadCountProvider>
  </Suspense>
);
