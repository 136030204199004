import React, { useCallback } from 'react';
import EvaluationInfo from '@front/ost_proposal/view/detail/EvaluationInfo';
import { useDispatch } from 'react-redux';
import { proposalAction } from '@front/ost_proposal/action';
import type { ProposalEstimatorUpdateGradeParameter } from '@front/ost_proposal/parameter';

const EvaluationInfoService = () => {
  const dispatch = useDispatch();
  const updateGrade = useCallback(
    (parameter: ProposalEstimatorUpdateGradeParameter) =>
      dispatch(proposalAction.updateGradeByEstimator(parameter)),
    [dispatch]
  );

  return (
    <>
      <EvaluationInfo updateGrade={updateGrade} />
    </>
  );
};

export default EvaluationInfoService;
