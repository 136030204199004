import React, { useContext } from 'react';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { OldTd, Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { ProjectSalesInfoSubjectReviewExperimentContext } from '@front/src/features/project-sales-info/features/subject-review/features/experiment/widgets/context';
import classes from '@front/src/features/project-sales-info/features/subject-review/features/experiment/components/code-table.module.scss';
import { getValueIfExist } from '@front/src/utils';

export { CodeTable as ProjectSalesInfoSubjectReviewExperimentCodeTable };

const CodeTable = () => {
  const {
    d: { detail },
  } = useContext(ProjectSalesInfoSubjectReviewExperimentContext);
  return (
    <div className={classes.container}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <Th width="200px">실험대상 및 종류</Th>
              <OldTd width="280px">{getValueIfExist(detail?.experiment?.name)}</OldTd>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <Th width="200px">적용 코드</Th>
              <OldTd width="280px">{getValueIfExist(detail?.experimentStandardCode)}</OldTd>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
