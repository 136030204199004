import { useQuery } from 'react-query';
import { affiliatedCompanyOutlineApi } from '@front/src/features/affiliated-company/features/outline/query/api';
import type { AffiliatedCompanyOutlineView } from '@front/src/features/affiliated-company/features/outline/types/view';

export const affiliatedCompanyOutlineQuery = {
  useOutlineGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyOutlineView>({
      queryKey: ['affiliated-company', 'outline', id],
      queryFn: () => affiliatedCompanyOutlineApi.getOutline(id, menuId),
      enabled: !!id && !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
