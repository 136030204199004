import type { ReactNode, RefObject } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Popover } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useThrottle } from '@front/hook/useThrottle';

export { LayerPopup };

interface Props {
  text: string;
  containerRef: RefObject<HTMLDivElement> | null;
  anchorEls: {
    [key: string]: HTMLElement | null;
  };
  setAnchorEls: (anchorEls: { [key: string]: HTMLElement | null }) => void;
  children: ReactNode;
  body: ReactNode;
}

const LayerPopup = ({ text, containerRef, anchorEls, setAnchorEls, children, body }: Props) => {
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState(true);
  const boxRef = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const id = event.currentTarget.id;
    setAnchorEls({ ...anchorEls, [id]: event.currentTarget });
  };
  const handleClose = (id: string) => {
    setAnchorEls({ ...anchorEls, [id]: null });
  };

  const onScroll = useCallback(() => {
    const btn = document.getElementById(text);
    if (!btn) return;
    setOffset({
      y: btn.offsetTop,
      x: btn.offsetLeft,
    });
    if (!containerRef || !containerRef.current || !boxRef.current[text]) return;
    if (!anchorEls[text]) return;
    const containerRect = containerRef.current.getBoundingClientRect();
    const boxRect = boxRef.current[text]!.getBoundingClientRect();

    setIsVisible(boxRect.top >= containerRect.top && boxRect.bottom <= containerRect.bottom);
  }, [text, containerRef, boxRef, anchorEls]);

  const throttleHandler = useThrottle(onScroll, 10);

  useEffect(() => {
    document.addEventListener('scroll', throttleHandler, { capture: true, passive: true });
    return () => {
      document.removeEventListener('scroll', throttleHandler, { capture: true });
    };
  }, [throttleHandler]);

  return (
    <Box
      sx={{
        margin: '0 auto',
      }}
      ref={(node: HTMLDivElement) => {
        if (node) {
          boxRef.current[text] = node;
        } else {
          boxRef.current[text] = null;
        }
      }}
    >
      <Box
        id={text}
        onClick={handleClick}
      >
        {children}
      </Box>
      <Popover
        anchorEl={anchorEls[text]}
        open={!!anchorEls[text]}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -154,
        }}
        anchorPosition={{
          top: offset.y,
          left: offset.x,
        }}
        disableScrollLock={true}
        disableEscapeKeyDown={true}
        hideBackdrop={true}
        sx={{
          backgroundColor: 'transparent !important',
          pointerEvents: 'none',
          visibility: isVisible ? 'visible' : 'hidden',
        }}
      >
        <Box
          sx={{
            padding: '16px',
            pointerEvents: 'all',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '8px',
            }}
          >
            <CloseIcon
              sx={{
                fontSize: '16px',
                cursor: 'pointer',
              }}
              onClick={() => handleClose(text)}
            />
          </Box>
          {body}
        </Box>
      </Popover>
    </Box>
  );
};
