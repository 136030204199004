import React, { useCallback, useContext } from 'react';
import { Box, TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import dayjs from 'dayjs';
import { HH_MM, openNewTabFromUrl } from '@front/src/utils';
import { AffiliatedPersonSalesActivityRowContext } from '@front/src/features/affiliated-person/features/sales-activity/widgets/row/provider/context';
import { ColorPalette } from '@front/assets/theme';

export { Row as AffiliatedPersonSalesActivityRow };

const Row = () => {
  const { item } = useContext(AffiliatedPersonSalesActivityRowContext);
  const handleCodeClick = useCallback(() => {
    openNewTabFromUrl(`/project/sales-info/${item.project.id}/abstract`);
  }, [item.project.id]);
  const renderExceptNote = useCallback(
    () => (
      <TableRow>
        {item.isFirstActivity && (
          <>
            <OldTd rowSpan={item.sameActivitySize + 1}>{item.index + 1}</OldTd>
            <OldTd rowSpan={item.sameActivitySize}>
              <Box
                onClick={handleCodeClick}
                sx={{
                  color: ColorPalette._386dd6,
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                {item.project?.code}
              </Box>
            </OldTd>
            <OldTd rowSpan={item.sameActivitySize}>{item.project?.nickName}</OldTd>
            <OldTd rowSpan={item.sameActivitySize}>{item.activity?.date}</OldTd>
            <OldTd rowSpan={item.sameActivitySize}>
              {item.activity?.time && dayjs(item.activity.time, HH_MM).format(HH_MM)}
            </OldTd>
            <OldTd rowSpan={item.sameActivitySize}>{item.activity?.category}</OldTd>
          </>
        )}
        {item.isFirstActivityUser && (
          <OldTd rowSpan={item.sameActivityUserSize}>{item.activityUser?.user?.name}</OldTd>
        )}
        <OldTd>{item.affiliatedCompany?.name}</OldTd>
        <OldTd>{item.person?.name}</OldTd>
        <OldTd>{item.person?.department}</OldTd>
        <OldTd>{item.person?.position}</OldTd>
        <OldTd>{item.date}</OldTd>
        <OldTd>{item.category}</OldTd>
      </TableRow>
    ),
    [item, handleCodeClick]
  );
  const renderNote = useCallback(
    () => (
      <TableRow
        sx={{
          height: '80px',
        }}
      >
        <OldTd
          colSpan={12}
          sx={{
            padding: '10px',
          }}
        >
          <Box
            sx={{
              maxWidth: '1600px',
              height: '100%',
              textAlign: 'start',
              overflowWrap: 'break-word',
            }}
          >
            {item.activity?.note}
          </Box>
        </OldTd>
      </TableRow>
    ),
    [item.activity?.note]
  );
  if (item.isLastActivity) {
    return (
      <>
        {renderExceptNote()}
        {renderNote()}
      </>
    );
  }
  return <>{renderExceptNote()}</>;
};
