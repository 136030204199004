import type { UserId, UserShortVO, UserVO } from '@front/user/domain';
import type { DrawingView, FileItemView } from '@front/file-item';
import type {
  CampaignVO,
  ProposalApprovalId,
  ProposalCategoryVO,
} from '@front/ost_campaign/domain';
import type { VoteShortVO } from '@front/ost_vote/domain';

export type ProposalId = number & { readonly _brand: symbol };

export function ProposalId(id: number) {
  return id as ProposalId;
}

/**
 * 상태 선택
 */
export enum ProposalStatus {
  /**
   * 작성중
   */
  WRITING = 'WRITING',
  /**
   * 제출
   */
  SUBMISSION = 'SUBMISSION',
  /**
   * 평가중
   */
  ESTIMATING = 'ESTIMATING',
  /**
   * 결재중
   */
  APPROVING = 'APPROVING',
  /**
   * 투찰중
   */
  VOTING = 'VOTING',
  /**
   * 종료
   */
  END = 'END',
  /**
   * 반려
   */
  DENY = 'DENY',
  /**
   * 투표 중단
   */
  STOP_VOTING = 'STOP_VOTING',
}

export const proposalStatusList: ProposalStatus[] = [
  ProposalStatus.WRITING,
  ProposalStatus.SUBMISSION,
  ProposalStatus.ESTIMATING,
  ProposalStatus.APPROVING,
  ProposalStatus.VOTING,
  ProposalStatus.END,
  ProposalStatus.DENY,
  ProposalStatus.STOP_VOTING,
];

export function proposalStatusName(status: ProposalStatus | '') {
  switch (status) {
    case ProposalStatus.WRITING:
      return '작성';
    case ProposalStatus.SUBMISSION:
      return '제출';
    case ProposalStatus.ESTIMATING:
      return '심사';
    case ProposalStatus.APPROVING:
      return '결재';
    case ProposalStatus.VOTING:
      return '투찰';
    case ProposalStatus.END:
      return '종료';
    case ProposalStatus.DENY:
      return '종료';
    case ProposalStatus.STOP_VOTING:
      return '투찰중지';
  }
}

export function proposalStatusFilterName(status: ProposalStatus | '') {
  switch (status) {
    case ProposalStatus.WRITING:
      return '작성';
    case ProposalStatus.SUBMISSION:
      return '제출';
    case ProposalStatus.ESTIMATING:
      return '심사';
    case ProposalStatus.APPROVING:
      return '결재';
    case ProposalStatus.VOTING:
      return '투찰';
    case ProposalStatus.END:
      return '종료';
    case ProposalStatus.DENY:
      return '반려';
    case ProposalStatus.STOP_VOTING:
      return '투찰중지';
  }
}

export function gradeRangeConverter(grade: ProposalGrade) {
  switch (grade) {
    case ProposalGrade.TALL:
      return '0원 ~ 10만원 미만';
    case ProposalGrade.GRANDE:
      return '10만원 ~ 100만원 미만';
    case ProposalGrade.VENTI:
      return '100만원 ~ 500만원 미만';
    case ProposalGrade.SUPREME:
      return '500만원 ~ 1000만원 이하';
    default:
      '선정된 등급 없음';
  }
}

/**
 * 제안 등급
 */

export enum ProposalGrade {
  TALL = 'TALL',
  GRANDE = 'GRANDE',
  VENTI = 'VENTI',
  SUPREME = 'SUPREME',
  DENY = 'DENY',
  HOLD = 'HOLD',
  EDIT = 'EDIT',
}

export function proposalGradeConvertToString(grade: ProposalGrade): string {
  switch (grade) {
    case ProposalGrade.TALL:
      return 'tall';
    case ProposalGrade.GRANDE:
      return 'grande';
    case ProposalGrade.VENTI:
      return 'venti';
    case ProposalGrade.SUPREME:
      return 'supreme';
    case ProposalGrade.DENY:
      return '제안무효';
    case ProposalGrade.HOLD:
      return '심사보류';
    case ProposalGrade.EDIT:
      return '수정요청';
  }
}

export const proposalGradeList = [
  ProposalGrade.TALL,
  ProposalGrade.GRANDE,
  ProposalGrade.VENTI,
  ProposalGrade.SUPREME,
];

/**
 * 협의 상태
 */

export enum ProposalArrangementInformationStatus {
  WAIT = 'WAIT',
  SKIP = 'SKIP',
  ON_GOING = 'ON_GOING',
  COMPLETE = 'COMPLETE',
}

export const proposalArrangementInformationStatusList: ProposalArrangementInformationStatus[] = [
  ProposalArrangementInformationStatus.ON_GOING,
  ProposalArrangementInformationStatus.COMPLETE,
];

export function proposalArrangementInformationStatusName(
  status: ProposalArrangementInformationStatus | ''
) {
  switch (status) {
    case ProposalArrangementInformationStatus.ON_GOING:
      return '협의중';
    case ProposalArrangementInformationStatus.COMPLETE:
      return '협의완료';
    default:
      return '-';
  }
}

export interface ProposalVO {
  id: ProposalId;
  code: string;
  title: string;
  status: ProposalStatus;
  contentList?: ProposalContentVO[];
  fileList: AttachFileVO[];
  writer: UserVO;
  commentList: CommentVO[];
  category: ProposalCategoryVO;
  grade: ProposalGrade;
  gradeByContributor: ProposalGrade;
  result: number;
  paymentDate: string;
  estimatorList: ProposalEstimatorVO[];
  campaign: CampaignVO;
  contributorViewList: ProposalContributorVO[];
  resultCount: number;
  isRequestedAdvisor: boolean;
  arrangementStatus: ProposalArrangementInformationStatus;
  advisor: UserVO;
  ratioEstimated: string;
  ratioApproved: string;
  ratioVoted: string;
  fixedResult: number;
  fixedAt: string;
  voteEndAt: string;
}

export interface ProposalShortVO {
  id: ProposalId;
  title: string;
  status: ProposalStatus;
  voteList: VoteShortVO[];
  arrangementStatus: ProposalArrangementInformationStatus;
  code: string;
  alarmCount: number;
}

export interface DrawingVO {
  id: DrawingId;
  writer: UserVO;
  drawing: DrawingView;
}

export interface AttachFileVO {
  id: AttachedFileId;
  writer: UserVO;
  file: FileItemView;
}

export interface CommentVO {
  id: CommentId;
  writer: UserVO;
  recipient: UserVO;
  req: number;
  step: number;
  isBlind: boolean;
  content: string;
  parentId: CommentId;
  createdAt: string;
  modifiedAt?: string;
  isPossibleToReply: boolean;
}

export type ProposalContentId = number & { readonly _brand: symbol };

export function ProposalContentId(id: number) {
  return id as ProposalContentId;
}

export interface ProposalContentVO {
  id: ProposalContentId;
  beforeContent: string;
  beforeFile: FileItemView;
  afterContent: string;
  afterFile: FileItemView;
  type: ProposalContentType;
}

export type CommentId = number & { readonly _brand: symbol };

export function CommentId(id: number) {
  return id as CommentId;
}

export type DrawingId = number & { readonly _brand: symbol };

export function DrawingId(id: number) {
  return id as DrawingId;
}

export type AttachedFileId = number & { readonly _brand: symbol };

export function AttachedFileId(id: number) {
  return id as AttachedFileId;
}

export type ProposalMemoId = number & { readonly _brand: symbol };

export function ProposalMemoId(id: number) {
  return id as ProposalMemoId;
}

export interface ProposalMemoVO {
  id: ProposalMemoId;
  description: string;
  writer: UserVO;
  createdAt: Date;
  modifiedAt?: Date;
  attendanceList: UserId[];
  isOpenedAttendanceList: boolean;
}

export type ProposalContributorId = number & { readonly _brand: unique symbol };

export function ProposalContributorId(id: number) {
  return id as ProposalContributorId;
}

export interface ProposalContributorVO {
  id: ProposalContributorId;
  rate?: number;
  contributor: UserShortVO;
  note?: string;
}

export type ProposalEstimatorId = number & { readonly _brand: unique symbol };

export function ProposalEstimatorId(id: number) {
  return id as ProposalEstimatorId;
}

export interface ProposalEstimatorVO {
  id: ProposalEstimatorId;
  grade?: ProposalGrade;
  estimator: UserShortVO;
  opinion?: string;
  isAgreed: boolean;
}

export interface ProposalCommentEditVO {
  id: CommentId | undefined;
  show: boolean;
}

export type ProposalArrangementPlanId = number & { readonly _brand: unique symbol };

export interface ProposalArrangementPlanVO {
  id: ProposalArrangementPlanId;
  startDate: string;
  endDate: string;
  title: string;
  note: string;
}

export type ArrangementCommentId = number & { readonly _brand: symbol };

export function ArrangementCommentId(id: number) {
  return id as ArrangementCommentId;
}

export interface ProposalArrangementCommentEditVO {
  id: ArrangementCommentId | undefined;
  show: boolean;
}

export interface ArrangementCommentVO {
  id: ArrangementCommentId;
  writer: UserVO;
  recipient: UserVO;
  req: number;
  step: number;
  isBlind: boolean;
  content: string;
  parentId: ArrangementCommentId;
  createdAt: string;
  modifiedAt?: string;
  isPossibleToReply: boolean;
}

export type ArrangementAttachedFileId = number & { readonly _brand: symbol };

export function ArrangementAttachedFileId(id: number) {
  return id as ArrangementAttachedFileId;
}

export interface ArrangementAttachFileVO {
  id: ArrangementAttachedFileId;
  writer: UserVO;
  file: FileItemView;
}

export type ProposalCancelRequestId = number & { readonly _brand: symbol };

export interface ProposalCancelRequestVO {
  id: ProposalCancelRequestId;
  reason: string;
  isEndedAt: string;
}

export interface ProposalApprovalVO {
  id: ProposalApprovalId;
  manager: UserVO;
  result: ApprovalResult;
  comment: string;
  grade: ProposalGrade;
  position: string;
}

export enum ApprovalResult {
  'APPROVED' = 'APPROVED',
  'REJECTED' = 'REJECTED',
  'BEFORE_REJECTED' = 'BEFORE_REJECTED',
}

export const ApprovalResultList: ApprovalResult[] = [
  ApprovalResult.APPROVED,
  ApprovalResult.REJECTED,
  ApprovalResult.BEFORE_REJECTED,
];

export function ApprovalResultName(result: ApprovalResult | '') {
  switch (result) {
    case ApprovalResult.APPROVED:
      return '승인';
    case ApprovalResult.REJECTED:
      return '반려';
    case ApprovalResult.BEFORE_REJECTED:
      return '직전 반려';
    case null:
      return '-';
    default:
      return '대기';
  }
}

export type ApprovalDocumentId = number & { readonly _brand: symbol };

export interface ProposalApprovalDocumentVO {
  id: ApprovalDocumentId;
  writer: UserVO;
  content: string;
}

export const enum ProposalContentType {
  BEFORE_COMPARISON_FORM = 'BEFORE_COMPARISON_FORM',
  IMAGE_TEXT_FORM = 'IMAGE_TEXT_FORM',
  TEXT_FORM = 'TEXT_FORM',
}
