import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import type { ProposalMemoAddParameter } from '@front/ost_proposal/parameter';
import { proposalAction } from '@front/ost_proposal/action';
import ProposalMemoForm from '@front/ost_proposal/view/memo/Form';
import type { RootState } from '@front/services/reducer';

interface Props {
  setFormHeight: (height: number) => void;
}

export default function ProposalFormService({ setFormHeight }: Props) {
  const dispatch = useDispatch();

  const [contributorList] = useSelector(
    (root: RootState) => [root.proposal.contributorList],
    shallowEqual
  );

  const addMemo = useCallback(
    (params: ProposalMemoAddParameter) => dispatch(proposalAction.addMemo(params)),
    [dispatch]
  );
  const formik = useFormik<ProposalMemoAddParameter>({
    initialValues: {} as ProposalMemoAddParameter,
    onSubmit: (values) => {
      addMemo(values);
      formik.resetForm();
    },
  });

  return (
    <FormikProvider value={formik}>
      <ProposalMemoForm
        setFormHeight={setFormHeight}
        contributorList={contributorList?.map((contributor) => contributor.contributor?.id)}
      />
    </FormikProvider>
  );
}
