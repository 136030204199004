import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { useProjectSalesInfoAffiliatedCompanyWebHardCreateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/create-modal/widgets/useState';
import { shallow } from 'zustand/shallow';
import { useFormContext } from 'react-hook-form';
import { ProjectSalesInfoAffiliatedCompanyWebHardCreateModalContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/create-modal/widgets/context';
import { modalLayout } from '@front/src/components/layout/modal';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { convertAffiliatedCompanyWebHardModalDataForParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/utils';

export { ButtonGroup as ProjectSalesInfoAffiliatedCompanyWebHardCreateModalButtonGroup };

const ButtonGroup = () => {
  const { Footer } = modalLayout;
  const {
    h: { onCreate },
  } = useContext(ProjectSalesInfoAffiliatedCompanyWebHardCreateModalContext);
  const { handleSubmit } = useFormContext();
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { setIsOpen } = useProjectSalesInfoAffiliatedCompanyWebHardCreateModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
    }),
    shallow
  );
  const handleCancelClick = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const onSubmit = handleSubmit((data) => {
    onCreate({ id, ...convertAffiliatedCompanyWebHardModalDataForParameter(data) });
  });
  return (
    <Footer>
      <Button
        shape="basic2"
        onClick={handleCancelClick}
      >
        취소
      </Button>
      <Button onClick={onSubmit}>저장</Button>
    </Footer>
  );
};
