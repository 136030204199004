import React, { useCallback } from 'react';
import Input from '@front/layouts/Input';
import { useController, useFormContext } from 'react-hook-form';
import { NOT_INTEGER_REGEX } from '@front/src/features/affiliated-company/utils/constant';
import { onChangeValidator } from '@front/src/components/input-with-hook-form/onChangeValidator';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import classes from '@front/src/features/project-sales-info/components/hook-form.module.scss';

export { ReviewAmountHookForm as ProjectSalesInfoReviewAmountHookForm };

interface Props {
  readOnly?: boolean;
}

const ReviewAmountHookForm = ({ readOnly }: Props) => {
  const { control, setValue, getValues } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ control, name: 'reviewAmount' });
  const handleChange = useCallback(
    (e) => {
      const newValue = onChangeValidator(e, value, InputType.AMOUNT);
      onChange({ ...e, target: { ...e.target, value: newValue } });
      const experimentAmount = getValues('experimentAmount').replace(NOT_INTEGER_REGEX, '');
      const reviewAmount = newValue.replace(NOT_INTEGER_REGEX, '');
      if (experimentAmount !== '-' && reviewAmount !== '-') {
        const result = +experimentAmount + +reviewAmount;
        const totalAmount = result.toLocaleString();
        setValue('totalAmount', totalAmount === '0' ? '' : totalAmount);
      }
    },
    [setValue, value, getValues, onChange]
  );
  return (
    <div className={classes.container}>
      <Input
        variant="outlined"
        value={value}
        onChange={handleChange}
        disabled={readOnly}
      />
    </div>
  );
};
