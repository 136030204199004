import { useCallback, useLayoutEffect, useRef, useState } from 'react';

export { useFocus as useAccountingSettingsManagementAccountingAccountFocus };
const useFocus = (defaultFocused = false) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState<boolean>(defaultFocused);
  const onFocusIn = useCallback(() => {
    setIsFocused(true);
  }, [setIsFocused]);
  const onFocusOut = useCallback(() => {
    setIsFocused(false);
  }, [setIsFocused]);
  useLayoutEffect(() => {
    if (!ref.current?.children[0]) {
      return;
    }
    const target = ref.current.children[0] as HTMLDivElement;
    if (isFocused) {
      target.focus();
    }
    target.addEventListener('focus', onFocusIn);
    target.addEventListener('blur', onFocusOut);
    return () => {
      target.removeEventListener('focus', onFocusIn);
      target.removeEventListener('blur', onFocusOut);
    };
  }, [isFocused]);
  return { ref, isFocused, setIsFocused };
};
