// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HwCXhOCYd90YQVvrIGFo{display:flex;flex-direction:column;gap:5px;padding:15px 0 60px 0}.X9TKF7Dbj3vJJI_ZD5V2{height:97%;width:15dvw;display:flex;justify-content:center;align-items:center;margin:1px}@media(max-width: 360px){.X9TKF7Dbj3vJJI_ZD5V2{width:13dvw}}`, "",{"version":3,"sources":["webpack://./front/src/features/mobile-notification/widgets/list/components/mobile-notification-list.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,qBAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,yBAPF,sBAQI,WAAA,CAAA","sourcesContent":["@import \"@front/assets/colors.scss\";\n\n.container {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n  padding: 15px 0 60px 0;\n}\n\n.swipe__action__card {\n  height: 97%;\n  width: 15dvw;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 1px;\n  @media (max-width: 360px) {\n    width: 13dvw;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HwCXhOCYd90YQVvrIGFo`,
	"swipe__action__card": `X9TKF7Dbj3vJJI_ZD5V2`
};
export default ___CSS_LOADER_EXPORT___;
