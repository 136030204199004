import { useQuery } from 'react-query';
import { AccountingSettingsTexAccountingAccountApi } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/query/api';
import type {
  TaxAccountingCountView,
  TaxAccountingView,
} from '@front/src/features/accounting/features/settings/features/tax-accounting-account/types/view';

export { Query as AccountingSettingsTaxAccountingAccountQuery };

const Query = {
  useListGet: () => {
    const { data, isLoading } = useQuery<TaxAccountingView[]>({
      queryFn: () => AccountingSettingsTexAccountingAccountApi.getAll(),
      queryKey: ['accounting', 'settings', 'text-accounting-account', 'all'],
      suspense: true,
    });
    return {
      data,
      isLoading,
    };
  },
  useCountGet: () => {
    const { data, isLoading } = useQuery<TaxAccountingCountView>({
      queryFn: () => AccountingSettingsTexAccountingAccountApi.getCount(),
      queryKey: ['accounting', 'settings', 'text-accounting-account', 'count'],
      suspense: true,
    });
    return {
      data,
      isLoading,
    };
  },
  useCreatedAtGet: () => {
    const { data, isLoading } = useQuery<string>({
      queryFn: () => AccountingSettingsTexAccountingAccountApi.getCreatedAt(),
      queryKey: ['accounting', 'settings', 'text-accounting-account', 'created-at'],
      suspense: true,
    });
    return {
      data,
      isLoading,
    };
  },
};
