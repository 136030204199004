import { projectSalesInfoCMPaymentHistoryUpdateModalQuery } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/update-modal/query';
import { projectSalesInfoCMPaymentHistoryUpdateModalMutation } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/update-modal/query/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { ProjectCMPaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/types/parameter';

export const projectSalesInfoCMPaymentHistoryUpdateModalRepository = {
  useCMPaymentHistoryDetailGet: (itemId: number) => {
    const { data, isLoading } =
      projectSalesInfoCMPaymentHistoryUpdateModalQuery.useCMPaymentHistoryDetailGet(itemId);
    return {
      data,
      isLoading,
    };
  },
  useCMPaymentHistoryUpdate: () => {
    const callback = getNoOpCallback();
    const { mutate } =
      projectSalesInfoCMPaymentHistoryUpdateModalMutation.useCMPaymentHistoryUpdate();
    return {
      run: (params: ProjectCMPaymentHistoryParams) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
