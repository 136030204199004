import React, { Suspense } from 'react';
import { AccountingSettingsTexAccountingAccountList } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/list/components/list';
import { AccountingSettingsTexAccountingAccountListProvider } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/list/provider/provider';
import { LoadingSpinner } from '@front/src/components/loading-spinner';

export { Widget as AccountingSettingsTexAccountingAccountListWidget };

const Widget = () => (
  <Suspense fallback={<LoadingSpinner height="400px" />}>
    <AccountingSettingsTexAccountingAccountListProvider>
      <AccountingSettingsTexAccountingAccountList />
    </AccountingSettingsTexAccountingAccountListProvider>
  </Suspense>
);
