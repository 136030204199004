import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { ProjectDivisionParams } from '@front/src/features/project-sales-info/features/project-division/types/parameter';

const url = {
  createCode: (id: number) => `${projectSalesInfoURL}/${id}/summary/code`,
  updateProjectDivision: (id: number) => `${projectSalesInfoURL}/${id}/summary`,
  getProjectDivision: (id?: number) => `${projectSalesInfoURL}/${id}/summary`,
};

export const projectSalesInfoProjectDivisionApi = {
  updateProjectDivision: async (params: ProjectDivisionParams) =>
    await axios.put(url.updateProjectDivision(params.id!), params),
  getProjectDivision: async (id?: number) => {
    const { data } = await axios.get(url.getProjectDivision(id));
    return data;
  },
  createCode: async (id: number) => await axios.post(url.createCode(id)),
};
