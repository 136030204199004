// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TNX7fD3mFmhN0pKUcbyd{width:200px}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/components/hook-form.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA","sourcesContent":[".container {\n  width: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TNX7fD3mFmhN0pKUcbyd`
};
export default ___CSS_LOADER_EXPORT___;
