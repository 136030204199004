import type { ReactNode } from 'react';
import React from 'react';
import { Box } from '@mui/material';

export { Header as AccountingUploadEcountDataHeader };

interface Props {
  children?: ReactNode;
  title?: string;
}

const Header = ({ children, title }: Props) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    }}
  >
    {title && (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '16px',
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
        }}
      >
        {title}
      </Box>
    )}
    {children}
  </Box>
);
