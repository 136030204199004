import React, { useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useAffiliatedCompanyDetailModalCharacterHobbyLogic } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/widgets/useLogic';
import { AffiliatedCompanyPersonDetailModalCharacterHobbyContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/widgets/context';
import { AffiliatedCompanyPersonDetailModalCharacterHobbyTable } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/components/hobby-table';
import { AffiliatedCompanyPersonDetailModalCharacterHobbyButtonGroup } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/components/button-group';

interface Props {
  readOnly?: boolean;
  id?: number;
  menuId?: number;
}

const AffiliatedCompanyPersonDetailModalCharacterHobbyWidget = ({
  readOnly,
  id,
  menuId,
}: Props) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    d: { hobbyList },
    h: { onCreateHobby, setIdList, onDeleteHobby, onUpdateHobby },
    idList,
    isLoading,
  } = useAffiliatedCompanyDetailModalCharacterHobbyLogic({ id, menuId });
  const value = useMemo(
    () => ({
      d: { hobbyList },
      h: { onCreateHobby, setIdList, onDeleteHobby, onUpdateHobby },
      isLoading,
      idList,
      readOnly,
    }),
    [hobbyList, isLoading, onCreateHobby, setIdList, idList, onDeleteHobby, onUpdateHobby, readOnly]
  );
  return (
    <AffiliatedCompanyPersonDetailModalCharacterHobbyContext.Provider value={value}>
      <Layout>
        <Header title="취미">
          <AffiliatedCompanyPersonDetailModalCharacterHobbyButtonGroup />
        </Header>
        <Body>
          <AffiliatedCompanyPersonDetailModalCharacterHobbyTable />
        </Body>
      </Layout>
    </AffiliatedCompanyPersonDetailModalCharacterHobbyContext.Provider>
  );
};

export default AffiliatedCompanyPersonDetailModalCharacterHobbyWidget;
