import React from 'react';
import { Box } from '@mui/material';
import { useAffiliatedPersonDetailState } from '@front/src/features/affiliated-person/features/detail/useState';
import { useShallow } from 'zustand/react/shallow';
import { Link, useLocation } from 'react-router-dom';
import { ColorPalette } from '@front/assets/theme';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

export { Tab as AffiliatedPersonDetailTab };

const Tab = () => {
  const { id } = useAffiliatedPersonDetailState(
    useShallow((state) => ({
      setTab: state.setTab,
      id: state.id,
      tab: state.tab,
    }))
  );
  const tabList = useGlobalNavBarState((state) => state.tabList);

  const { pathname } = useLocation();

  return (
    <Box
      sx={{
        display: 'flex',
        padding: '0 20px',
        borderBottom: `2px solid ${ColorPalette._e4e9f2}`,
        height: '38px',
      }}
    >
      {tabList?.map((tab) => {
        const path = tab.path?.replace('{:id}', `${id}`);
        const selected = path === pathname;
        return (
          <Link
            to={path ?? ''}
            key={`key-${path}`}
            style={{
              textDecoration: 'none',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100px',
                height: '38px',
                marginRight: '5px',
                border: `2px solid ${ColorPalette._e4e9f2}`,
                borderRadius: '5px 5px 0 0',
                borderBottom: 'none',
                color: ColorPalette._252627,
                fontSize: '14px',
                backgroundColor: selected ? ColorPalette._ffffff : ColorPalette._e4e9f2,
                fontWeight: selected ? 'bold' : 'inherit',
                cursor: selected ? 'default' : 'pointer',
              }}
            >
              {tab.title}
            </Box>
          </Link>
        );
      })}
    </Box>
  );
};
