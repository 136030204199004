import { default as axios } from '@front/src/config/axios';
import { projectSalesInfoAspectRatioExaminationUrl } from '@front/src/features/project-sales-info/features/subject-review/features/modal/utils/constant';
import type { IdListDeleteParameter } from '@front/src/types/parameter';
import type { ProjectComplexInformationParameter } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/types/parameter';

export { api as projectSalesInfoSubjectReviewModalComplexInformationApi };

const url = {
  create: (id?) => `${projectSalesInfoAspectRatioExaminationUrl}/${id}/complex-information`,
  getList: (id?) => `${projectSalesInfoAspectRatioExaminationUrl}/${id}/complex-information`,
  update: (id?) => `${projectSalesInfoAspectRatioExaminationUrl}/complex-information/${id}`,
  delete: () => `${projectSalesInfoAspectRatioExaminationUrl}/complex-information`,
};

const api = {
  create: async (id: number) => await axios.post(url.create(id)),
  getList: async (id?: number) => {
    const { data } = await axios.get(url.getList(id));
    return data;
  },
  update: async (params: ProjectComplexInformationParameter) => {
    await axios.put(url.update(params.id), params);
  },
  delete: async (params: IdListDeleteParameter) => {
    await axios.delete(url.delete(), {
      data: params,
    });
  },
};
