import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import type { RootState } from '@front/services/reducer';
import OstBudgetStatus from '@front/ost/view/detail/BudgetStatus';
import type { UserId } from '@front/user/domain';
import { campaignAction } from '@front/ost_campaign/action';
import type { CampaignId } from '@front/ost_campaign/domain';
import type { CampaignUpdateManagerParameter } from '@front/ost_campaign/parameter';

const OstBudgetStatusService = () => {
  const dispatch = useDispatch();
  const [sumAllocated, setSumAllocated] = useState(0);
  const [campaignList] = useSelector((root: RootState) => [root.ost.campaignList], shallowEqual);
  const { totalBudget, status, manager } = useSelector((root: RootState) => ({
    totalBudget: root.ost.detail?.totalBudget,
    status: root.ost.detail?.status,
    manager: root.campaign.detail?.manager,
  }));

  const updateManager = useCallback(
    (params: CampaignUpdateManagerParameter) => {
      dispatch(campaignAction.updateManager(params));
    },
    [dispatch]
  );

  const onChange = useCallback(
    (id: CampaignId) => (value) => {
      if (manager?.id === value) {
        return;
      }
      if (!value) {
        updateManager({
          campaignId: id,
          managerId: undefined,
        });
        return;
      }
      updateManager({
        campaignId: id,
        managerId: value as UserId,
      });
    },
    [manager, updateManager]
  );

  useEffect(() => {
    if (!campaignList) {
      return;
    }
    const list = campaignList
      .filter((c) => c.totalReward !== null)
      .map((c) => c.totalReward)
      .reduce((a, c) => a + c, 0);
    setSumAllocated(list);
  }, [campaignList]);

  return (
    <>
      <OstBudgetStatus
        sumAllocated={sumAllocated}
        campaignList={campaignList}
        status={status}
        totalBudget={totalBudget}
        onChange={onChange}
      />
    </>
  );
};

export default OstBudgetStatusService;
