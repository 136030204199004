import React from 'react';
import { Box } from '@mui/material';
import useDialog from '@front/dialog/hook';
import Button from '@front/layouts/Button';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { ProposalArrangementInformationStatus, ProposalStatus } from '@front/ost_proposal/domain';
import type { ProposalStatusUpdateParameter } from '@front/ost_proposal/parameter';
import { ApprovalService } from '@front/ost_proposal/service/detail/approval';

interface Props {
  updateFavorite: (isFavorite: boolean) => void;
  submit: () => void;
  updateStatus: (params: ProposalStatusUpdateParameter) => void;
  openCampaignInfoModal: () => void;
  requestAdvisor: () => void;
  isContributor: boolean;
  onOpenCancelSubmissionModal: () => void;
}

export default function TitleButtonBar({
  submit,
  updateStatus,
  openCampaignInfoModal,
  requestAdvisor,
  isContributor,
  onOpenCancelSubmissionModal,
}: Props) {
  const { confirm } = useDialog();
  const isRequestedAdvisor = useSelector(
    (root: RootState) => root.proposal.detail!.isRequestedAdvisor,
    shallowEqual
  );
  const status = useSelector((root: RootState) => root.proposal.detail!.status, shallowEqual);
  const arrangementStatus = useSelector(
    (root: RootState) => root.proposal.detail!.arrangementStatus,
    shallowEqual
  );
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box>
        <Button
          shape="basic1"
          onClick={openCampaignInfoModal}
        >
          캠페인 안내
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          columnGap: '10px',
        }}
      >
        {(status === ProposalStatus.WRITING || status === ProposalStatus.SUBMISSION) &&
          isContributor &&
          arrangementStatus === ProposalArrangementInformationStatus.WAIT && (
            <Button
              disabled={isRequestedAdvisor}
              shape="basic5"
              onClick={() => {
                confirm({
                  children: '협의자 지정을 요청하시겠습니까?',
                  confirmText: '확인',
                  afterConfirm: () => {
                    requestAdvisor();
                  },
                });
              }}
            >
              협의 요청
            </Button>
          )}
        {status === ProposalStatus.WRITING && isContributor && (
          <>
            <Button
              shape="basic6"
              disabled={arrangementStatus === ProposalArrangementInformationStatus.ON_GOING}
              onClick={() => {
                confirm({
                  children: `${
                    arrangementStatus === ProposalArrangementInformationStatus.SKIP ||
                    arrangementStatus === ProposalArrangementInformationStatus.COMPLETE
                      ? '심사 단계로 이동합니다.'
                      : '제안을 제출하시겠습니까?'
                  }`,
                  afterConfirm: () => {
                    submit();
                  },
                  confirmText: '확인',
                });
              }}
            >
              제출하기
            </Button>
          </>
        )}
        {(status === ProposalStatus.SUBMISSION || status === ProposalStatus.ESTIMATING) &&
          isContributor && (
            <>
              <Button
                shape="basic6"
                onClick={() => {
                  if (status === ProposalStatus.ESTIMATING) {
                    onOpenCancelSubmissionModal();
                  } else {
                    confirm({
                      children: '제출한 제안을 회수하시겠습니까?',
                      afterConfirm: () => {
                        updateStatus({ status: ProposalStatus.WRITING });
                      },
                      confirmText: '확인',
                    });
                  }
                }}
              >
                제안 회수
              </Button>
            </>
          )}
        {status === ProposalStatus.APPROVING && <ApprovalService />}
      </Box>
    </Box>
  );
}
