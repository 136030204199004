import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { AffiliatedCompanyMeetingHistoryContext } from '@front/src/features/affiliated-company/features/meeting-history/widgets/context';
import { useAffiliatedCompanyMeetingHistoryState } from '@front/src/features/affiliated-company/features/meeting-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { MeetingHistoryButton as AffiliatedCompanyMeetingHistoryButton };
const MeetingHistoryButton = () => {
  const {
    h: { onCreateModalOpen, onMeetingHistoryDelete, setIdList },
    idList,
  } = useContext(AffiliatedCompanyMeetingHistoryContext);
  const handleDeleteClick = useCallback(() => {
    onMeetingHistoryDelete({ idList });
    setIdList([]);
  }, [onMeetingHistoryDelete, setIdList, idList]);
  const { readOnly } = useAffiliatedCompanyMeetingHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      <Button
        onClick={handleDeleteClick}
        disabled={readOnly}
      >
        선택삭제
      </Button>
      <Button
        onClick={onCreateModalOpen}
        disabled={readOnly}
      >
        출장보고서 등록
      </Button>
    </>
  );
};
