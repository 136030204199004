import React, { useContext } from 'react';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { ProjectSalesInfoContractHistoryContext } from '@front/src/features/project-sales-info/features/contract/features/history/widgets/context';
import { ProjectSalesInfoContractHistoryTableRow } from '@front/src/features/project-sales-info/features/contract/features/history/components/contract-history-table-row';

export { ContractHistoryTableBody as ProjectSalesInfoContractHistoryTableBody };

const ContractHistoryTableBody = () => {
  const {
    d: { list },
  } = useContext(ProjectSalesInfoContractHistoryContext);
  if (list?.length === 0) {
    return (
      <NoResult
        height="52px"
        colSpan={12}
      />
    );
  }
  return (
    <>
      {list?.map((item, index) => (
        <ProjectSalesInfoContractHistoryTableRow
          key={item.id}
          item={item}
          index={index}
        />
      ))}
    </>
  );
};
