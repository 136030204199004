import React, { useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useAffiliatedCompanyDetailModalCharacterPreferenceLogic } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/widgets/useLogic';
import { AffiliatedCompanyPersonDetailModalCharacterPreferenceContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/widgets/context';
import { AffiliatedCompanyPersonDetailModalCharacterPreferenceButtonGroup } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/components/button-group';
import { AffiliatedCompanyPersonDetailModalCharacterPreferenceTable } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/components/preference-table';

interface Props {
  readOnly?: boolean;
  id?: number;
  menuId?: number;
}

const AffiliatedCompanyPersonDetailModalCharacterPreferenceWidget = ({
  readOnly,
  id,
  menuId,
}: Props) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    d: { preferenceList },
    h: { onCreatePreference, setIdList, onDeletePreference, onUpdatePreference },
    idList,
    isLoading,
  } = useAffiliatedCompanyDetailModalCharacterPreferenceLogic({ id, menuId });
  const value = useMemo(
    () => ({
      d: { preferenceList },
      h: { onCreatePreference, setIdList, onDeletePreference, onUpdatePreference },
      isLoading,
      idList,
      readOnly,
    }),
    [
      preferenceList,
      isLoading,
      onCreatePreference,
      setIdList,
      idList,
      onDeletePreference,
      onUpdatePreference,
      readOnly,
    ]
  );
  return (
    <AffiliatedCompanyPersonDetailModalCharacterPreferenceContext.Provider value={value}>
      <Layout>
        <Header title="취향">
          <AffiliatedCompanyPersonDetailModalCharacterPreferenceButtonGroup />
        </Header>
        <Body>
          <AffiliatedCompanyPersonDetailModalCharacterPreferenceTable />
        </Body>
      </Layout>
    </AffiliatedCompanyPersonDetailModalCharacterPreferenceContext.Provider>
  );
};

export default AffiliatedCompanyPersonDetailModalCharacterPreferenceWidget;
