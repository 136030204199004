import { useMutation, useQueryClient } from 'react-query';
import { projectSalesInfoAffiliatedCompanyWebHardApi } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/query/api';
import type { IdListDeleteParameter } from '@front/src/types/parameter';

export const projectSalesInfoAffiliatedCompanyWebHardMutation = {
  useDelete: () => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: IdListDeleteParameter) =>
        projectSalesInfoAffiliatedCompanyWebHardApi.delete(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'affiliated-company', 'web-hard'],
        });
      },
    });
    return {
      mutate,
    };
  },
};
