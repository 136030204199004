import React, { useContext } from 'react';
import { TableRow } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { Th } from '@front/src/components/layout/table/th';
import { OldTd } from '@front/layouts/Table';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { AffiliatedCompanyOutlineNumberContext } from '@front/src/features/affiliated-company/features/outline/features/number/widgets/context';
import { useAffiliatedCompanyOutlineState } from '@front/src/features/affiliated-company/features/outline/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { ContactNumber as AffiliatedCompanyOutlineNumberTableRowContactNumber };
const ContactNumber = () => {
  const {
    d: { detailOutline },
    h: { onOutlineNumberUpdate },
  } = useContext(AffiliatedCompanyOutlineNumberContext);
  const { handleSubmit } = useFormContext();

  const onSubmit = handleSubmit((data) => {
    onOutlineNumberUpdate(data);
  });
  const { readOnly } = useAffiliatedCompanyOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <TableRow key={detailOutline?.contactNumber ?? Math.random()}>
      <Th>회사 대표 번호</Th>
      <OldTd>
        <HookFormInput
          name="contactNumber"
          onSubmit={onSubmit}
          inputType={InputType.COMPANY_MAIN_CONTACT}
          disabled={readOnly}
        />
      </OldTd>
    </TableRow>
  );
};
