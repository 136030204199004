import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@front/layouts/Button';

const ListButton = () => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/hr-card-management');
  };
  return (
    <Button
      shape="basic3"
      children="목록"
      onClick={onClick}
    />
  );
};

export default ListButton;
