import React, { useMemo } from 'react';
import { TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { useAccountingUploadSalesInfoState } from '@front/src/features/accounting/features/upload/features/sales-info/useState';
import { useShallow } from 'zustand/react/shallow';

export { TableHeader as AccountingUploadSalesInfoGetYearTableHeader };

const TableHeader = () => {
  const { year } = useAccountingUploadSalesInfoState(
    useShallow((state) => ({
      year: state.year,
    }))
  );
  const yearTwoDigits = useMemo(() => {
    if (!year) {
      return '-';
    }
    return (year - 2000).toString();
  }, [year]);
  return (
    <TableRow>
      <Th width="88px">-</Th>
      <Th width="60px">내용</Th>
      <Th width="160px">연간목표</Th>
      <Th width="160px">{yearTwoDigits}년 합계</Th>
      <Th width="160px">{yearTwoDigits}년 평균</Th>
      {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
        <Th
          key={`th-${month}`}
          width="160px"
        >
          {yearTwoDigits}
          {month.toString().padStart(2, '0')}
        </Th>
      ))}
    </TableRow>
  );
};
