import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import DropDownUI from '@front/src/components/components-with-design/component/DropDownUI';
import TextFieldBasicUI from '@front/src/components/components-with-design/component/TextFieldBasicUI';
import DatePickerUI from '@front/src/components/components-with-design/component/DatePickerUI';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import ApprovalBoxTable from '@front/src/features/approval-box/components/ApprovalBoxTable';
import {
  approvalBoxApprovalFilterOptionList,
  approvalBoxFilterOptionList,
} from '@front/src/features/approval-box/utils/constants';
import useApprovalBoxStore from '@front/src/features/approval-box/useState';
import { useShallow } from 'zustand/react/shallow';
import { APPROVAL_BOX_TYPE } from '@front/type/domain';
import { APPROVAL_BOX_STATUS } from '@front/src/features/approval-box/type/domain';
import type { ApprovalBoxSearchParams } from '@front/src/features/approval-box/type/parameter';
import dayjs from 'dayjs';

export default function ApprovalBoxForm() {
  const { setBoxSearchParams, boxSearchParams } = useApprovalBoxStore(
    useShallow((state) => ({
      setBoxSearchParams: state.setBoxSearchParams,
      boxSearchParams: state.boxSearchParams,
    }))
  );
  const [queryParams, setQueryParams] = useState<Partial<ApprovalBoxSearchParams>>({
    filter: '',
  });
  const isApproval = boxSearchParams.type === APPROVAL_BOX_TYPE.APPROVAL.toLowerCase();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  
  useEffect(() => {
    if (isApproval) {
      setQueryParams((prev) => ({ ...prev, filter: APPROVAL_BOX_STATUS.WAITING.toLowerCase() }));
    } else {
      setQueryParams((prev) => ({ ...prev, filter: APPROVAL_BOX_STATUS.ON_GOING.toLowerCase() }));
    }
  }, [isApproval]);

  useEffect(() => {
    if (queryParams.filter !== '' && isFirstLoad) {
      setBoxSearchParams(queryParams);
      setIsFirstLoad(false);
    }
  }, [queryParams]);

  useEffect(() => {
    setQueryParams((prev) => ({ ...prev, keyword: '', startDate: '', endDate: '' }));
  }, [boxSearchParams.type]);

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(15, 1fr)"
      gap="10px"
      alignItems="center"
    >
      <Box
        sx={{
          gridColumn: '1 / 4',
        }}
      >
        <DropDownUI
          value={queryParams.filter}
          options={isApproval ? approvalBoxApprovalFilterOptionList : approvalBoxFilterOptionList}
          onChange={(e) => {
            setQueryParams((prev) => ({ ...prev, filter: e.target.value as APPROVAL_BOX_STATUS }));
          }}
        />
      </Box>
      <TextFieldBasicUI
        placeholder="제목 또는 요청자 검색"
        sx={{
          gridColumn: '4 / -1',
        }}
        value={queryParams.keyword ?? ''}
        onChange={(e) => setQueryParams({ ...queryParams, keyword: e.target.value })}
      />
      <Box
        sx={{
          gridColumn: '1 / 5',
        }}
      >
        <DatePickerUI
          value={dayjs(queryParams.startDate ?? '')}
          onChange={(value) =>
            setQueryParams({ ...queryParams, startDate: value.format('YYYY-MM-DD') })
          }
        />
      </Box>
      <Typography sx={{ textAlign: 'center' }}>~</Typography>
      <Box
        sx={{
          gridColumn: '6 / 10',
        }}
      >
        <DatePickerUI
          value={dayjs(queryParams.endDate ?? '')}
          onChange={(value) =>
            setQueryParams({ ...queryParams, endDate: value.format('YYYY-MM-DD') })
          }
        />
      </Box>
      <ButtonBasicUI
        sx={{
          gridColumn: '10 / 13',
        }}
        onClick={() => setBoxSearchParams(queryParams)}
      >
        검색
      </ButtonBasicUI>
      <ButtonBasicUI
        sx={{
          gridColumn: '13 / -1',
          backgroundColor: 'var(--design-v-10-light-main-main-secondary, #77A0E5)',
        }}
      >
        초기화
      </ButtonBasicUI>
      <Box
        sx={{
          gridColumn: '1 / -1',
          marginTop: '5px',
        }}
      >
        <ApprovalBoxTable />
      </Box>
    </Box>
  );
}
