import noteQuery from '@front/src/features/note/query/query';

const useNoteMenuId = () => {
  const menuId = sessionStorage.getItem('menuId');
  const { data: noteAuth } = noteQuery.useGetNoteMenuId(Number(menuId ?? 0));
  sessionStorage.setItem('noteAuth', JSON.stringify(noteAuth ?? {}));

  return noteAuth;
};

export default useNoteMenuId;
