import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAccountingUploadSalesInfoGoalModalUpdateLogic } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/widgets/update/provider/useLogic';
import { AccountingUploadSalesInfoGoalModalUpdateContext } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/widgets/update/provider/context';

export { Provider as AccountingUploadSalesInfoGoalModalUpdateProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { onUpdate } = useAccountingUploadSalesInfoGoalModalUpdateLogic();
  const value = useMemo(
    () => ({
      onUpdate,
    }),
    [onUpdate]
  );
  return (
    <AccountingUploadSalesInfoGoalModalUpdateContext.Provider value={value}>
      {children}
    </AccountingUploadSalesInfoGoalModalUpdateContext.Provider>
  );
};
