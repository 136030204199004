import { default as axios } from '@front/src/config/axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import type { ProjectSalesInfoContractReviewParameter } from '@front/src/features/project-sales-info/features/contract/features/reivew/features/modal/types/parameter';

export { api as projectSalesInfoContractReviewModalApi };
const url = {
  getDetail: (id?) => `${projectSalesInfoURL}/review-contract/${id}`,
  create: (id?) => `${projectSalesInfoURL}/${id}/review-contract`,
  update: (id?) => `${projectSalesInfoURL}/review-contract/${id}`,
};

const api = {
  getDetail: async (id?: number) => {
    const { data } = await axios.get(url.getDetail(id));
    return data;
  },
  create: async (params: ProjectSalesInfoContractReviewParameter) => {
    await axios.post(url.create(params.id), params);
  },
  update: async (params: ProjectSalesInfoContractReviewParameter) => {
    await axios.put(url.update(params.id), params);
  },
};
