import { accountingUploadRepository } from '@front/src/features/accounting/features/upload/features/ecount-data/repository/repository';

export { useLogic as useAccountingSettingsBankAccountUploadLogic };

const useLogic = () => {
  const { run: onCreate } = accountingUploadRepository.useCreateBankAccount();
  return {
    onCreate,
  };
};
