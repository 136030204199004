import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { projectSalesInfoBidResultRepository } from '@front/src/features/project-sales-info/features/bid/features/bid-result/repository';

export { useLogic as useProjectSalesInfoBidResultLogic };

const useLogic = () => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { data: bidResultList, isLoading: isLoading } =
    projectSalesInfoBidResultRepository.useProjectSalesInfoBidResultListGet(id!);
  const { run: onDelete } =
    projectSalesInfoBidResultRepository.useProjectSalesInfoBidResultDelete();
  return {
    h: { onDelete },
    d: { bidResultList },
    isLoading,
  };
};
