import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import ModalLayout from '@front/layouts/ModalLayout';
import type { RootState } from '@front/services/reducer';
import type { DefaultFunction } from '@front/type/Function';
import { DetailStatusTableRowDetail } from '@front/ost_reward/view/detail/detail-status-table-row-detail';

export { DetailMonthStatus as OstRewardMenagementDetailMonthStatusModal };

interface Props {
  onClose: DefaultFunction;
  isOpen: boolean;
}

const DetailMonthStatus = ({ onClose, isOpen }: Props) => {
  const { detail, month } = useSelector(
    (root: RootState) => ({
      detail: root.ostRewardMangement.detail,
      month: root.ostRewardMangement.month,
    }),
    shallowEqual
  );

  return (
    <ModalLayout
      title={`${detail?.year}년도 ${month}월 OST 상금 지급 현황`}
      width="80dvw"
      height="80dvh"
      open={isOpen}
      onClose={onClose}
    >
      <DetailStatusTableRowDetail />
    </ModalLayout>
  );
};
