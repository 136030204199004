// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZymxZt7dYPHnNi6daBId{display:flex;justify-content:center;gap:10px;width:100%;margin-top:20px}`, "",{"version":3,"sources":["webpack://./front/src/features/affiliated-company/features/meeting-history/features/modal/components/form-button.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,QAAA,CACA,UAAA,CACA,eAAA","sourcesContent":[".footer {\n  display: flex;\n  justify-content: center;\n  gap: 10px;\n  width: 100%;\n  margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `ZymxZt7dYPHnNi6daBId`
};
export default ___CSS_LOADER_EXPORT___;
