import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyBusinessRegistrationMutation } from '@front/src/features/affiliated-company/features/outline/features/number/features/business-registration/query/mutation';

export const affiliatedCompanyBusinessRegistrationRepository = {
  useOutlineBusinessRegistrationFileUpdate: (menuId) => {
    const { mutate } =
      affiliatedCompanyBusinessRegistrationMutation.useOutlineBusinessRegistrationFileUpdate(
        menuId
      );
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
