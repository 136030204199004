import type { ProjectReviewAmountPaymentHistoryView } from '@front/src/features/project-sales-info/features/collection/features/payment-history/types/view';
import type { IdDeleteCollectionParams } from '@front/src/features/project-sales-info/features/collection/features/payment-history/types/parameter';
import { createContext } from 'react';
import { noOp } from '@front/common/contants';

export { Context as ProjectSalesInfoCollectionPaymentHistoryContext };

interface State {
  d: {
    paymentHistoryList?: ProjectReviewAmountPaymentHistoryView[];
  };
  h: {
    onDelete: (params: IdDeleteCollectionParams) => void;
  };
  isLoading: boolean;
}

const Context = createContext<State>({
  d: {},
  h: {
    onDelete: noOp,
  },
  isLoading: false,
});
