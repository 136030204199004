import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetCellLogic } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/hooks/useGetCellLogic';
import {
  TableRowBox,
  TbcBox,
} from '@front/src/features/personal-settings-modal/features/ui-settings/components';
import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export { Section as PersonalSettingsModalUISettingsHeaderSettingsTableContentSection };

const Section = () => {
  const { watch } = useFormContext();
  const section = watch('section');
  const { emptyRows, iconSx } = useGetCellLogic({
    key: 'section',
    length: section.length,
  });
  return (
    <>
      {section.map((item, i) => {
        const { title, belongsToHeader, id } = watch(`section.${i}`);
        return (
          <TableRowBox key={id}>
            <TbcBox>
              <Box>{title}</Box>
              {belongsToHeader && <CheckIcon sx={iconSx} />}
            </TbcBox>
          </TableRowBox>
        );
      })}
      {emptyRows}
    </>
  );
};
