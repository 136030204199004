import React, { useContext } from 'react';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';
import { ProjectSalesInfoEstimationEstimationTableRow } from '@front/src/features/project-sales-info/features/estimation/features/estimation/components/estimation-table-row';
import { ProjectSalesInfoEstimationEstimationContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation/widgets/context';
import { NoResult } from '@front/src/components/layout/table/no-result';

export { EstimationTableBody as ProjectSalesInfoEstimationEstimationTableBody };

const EstimationTableBody = () => {
  const {
    d: { list },
  } = useContext(ProjectSalesInfoEstimationEstimationContext);
  if (typeof list === 'undefined') {
    return (
      <TableLoadingSpinner
        height="52px"
        colSpan={12}
      />
    );
  }
  if (list.length === 0) {
    return (
      <NoResult
        height="52px"
        colSpan={12}
      />
    );
  }
  return (
    <>
      {list.map((item) => (
        <ProjectSalesInfoEstimationEstimationTableRow
          key={item.id}
          item={item}
        />
      ))}
    </>
  );
};
