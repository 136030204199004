import { createContext } from 'react';
import type { MyPageAuthorizationMenuView } from '@front/src/features/my-page/features/authorization/types/view';

export { context as MyPageAuthorizationMenuContext };

interface State {
  list?: MyPageAuthorizationMenuView[];
}

const context = createContext<State>({});
