import type { CSSProperties } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { PersonSearchHelper } from '@front/src/features/affiliated-company-selector/widgets/useLogic';
import {
  InputAdornment,
  MenuItem,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import CircularProgress from '@front/components/CircularProgress';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { useAffiliatedCompanyModalListStyles } from '@front/src/features/affiliated-company-selector/components/affiliated-company-selector-modal-list-style';
import Input from '@front/layouts/Input';
import Select from '@front/layouts/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorPalette } from '@front/assets/theme';
import type { AffiliatedCompanyPersonShortView } from '@front/src/features/affiliated-company/features/person/types/view';

type AffiliatedCompanySelectorModalPersonListProps = {
  helper: PersonSearchHelper;
  targetCompanyId: number | undefined;
  onSelect?: (person: AffiliatedCompanyPersonShortView) => void;
};

export default function AffiliatedCompanySelectorModalPersonList(
  props: AffiliatedCompanySelectorModalPersonListProps
) {
  const { setCategory, setKeyword, result, category, keyword, targetPersonId } = props.helper;

  return (
    <div style={{ height: '100%' }}>
      <Form
        category={category}
        keyword={keyword}
        setCategory={setCategory}
        setKeyword={setKeyword}
        targetCompanyId={props.targetCompanyId}
      />
      <List
        onSelect={props.onSelect}
        isLoading={result.isLoading}
        data={result.data ? result.data : []}
        category={category}
        keyword={keyword}
        targetCompanyId={props.targetCompanyId}
        targetPersonId={targetPersonId}
      />
    </div>
  );
}

type FormProp = {
  category: string;
  keyword: string;
  setCategory: (category: string) => void;
  setKeyword: (keyword: string) => void;
  targetCompanyId: number | undefined;
};

const Form = (prop: FormProp) => {
  const inputRef = useRef<HTMLInputElement>();
  const { setCategory, setKeyword, category, targetCompanyId } = prop;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
      setKeyword('');
    }
  }, [targetCompanyId, setKeyword, inputRef]);

  const handleOptionChange = useCallback(
    (event) => {
      setCategory(event.target.value);
    },
    [setCategory]
  );

  const handleSearch = useCallback(() => {
    if (inputRef.current) {
      setKeyword(inputRef.current.value);
    }
  }, [inputRef, setKeyword]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleSearch();
      }
    },
    [handleSearch]
  );

  const searchCategories = ['이름', '소속', '직급', '핸드폰번호'];

  return (
    <div style={{ marginBottom: '10px' }}>
      <Select
        style={{ width: '20%', marginRight: '1%' }}
        variant="outlined"
        onChange={handleOptionChange}
        value={category}
      >
        {searchCategories.map((value) => (
          <MenuItem
            key={value}
            value={value}
          >
            {value}
          </MenuItem>
        ))}
      </Select>
      <Input
        inputRef={inputRef}
        style={{ width: '79%' }}
        placeholder="검색 키워드 입력(엔터)"
        variant="outlined"
        onKeyDown={handleKeyDown}
        endAdornment={
          <InputAdornment
            position="end"
            sx={{ marginRight: '10px' }}
            onClick={handleSearch}
          >
            <FontAwesomeIcon
              icon="search"
              style={{
                fontSize: '16px',
                color: ColorPalette._386dd6,
                cursor: 'pointer',
              }}
            />
          </InputAdornment>
        }
      />
    </div>
  );
};

type ListProp = {
  isLoading: boolean;
  data: AffiliatedCompanyPersonShortView[];
  category: string;
  keyword: string;
  targetPersonId: number | undefined;
  targetCompanyId: number | undefined;
  onSelect?: (person: AffiliatedCompanyPersonShortView) => void;
};

const List = (props: ListProp) => {
  const tableRowHeight = 35;
  const classes = useAffiliatedCompanyModalListStyles();
  const { isLoading, data, onSelect, category, keyword, targetCompanyId, targetPersonId } = props;
  const [filteredData, setFilteredData] = useState<AffiliatedCompanyPersonShortView[]>([]);

  useEffect(() => {
    setFilteredData(filterPersonData(category, keyword, data));
  }, [data, category, keyword]);

  const handlePersonSelect = useCallback(
    (person: AffiliatedCompanyPersonShortView) => {
      onSelect && onSelect(person);
    },
    [onSelect]
  );

  const TableRowWrap = useCallback(
    (props: any) => {
      const index: number = props.index;
      const person = props.data[index] as AffiliatedCompanyPersonShortView;
      const style: CSSProperties = { ...props.style };

      return (
        <TableRow
          component="div"
          className={classes.row}
          style={style}
          onClick={() => {
            handlePersonSelect(person);
          }}
        >
          <TableCell
            className={classes.cell_person}
            component="div"
          >
            <Radio
              disableRipple={true}
              value={person.id}
              checked={targetPersonId === person.id}
            />
          </TableCell>
          <TableCell
            className={classes.cell_person}
            component="div"
          >
            <span>{person.department}</span>
          </TableCell>
          <TableCell
            className={classes.cell_person}
            component="div"
          >
            <span>{person.name}</span>
          </TableCell>
          <TableCell
            className={classes.cell_person}
            component="div"
          >
            <span>{person.position}</span>
          </TableCell>
          <TableCell
            className={classes.cell_person}
            component="div"
          >
            <span>{person.phoneNumber}</span>
          </TableCell>
        </TableRow>
      );
    },
    [handlePersonSelect, classes]
  );

  const initialFocusItemOffset = useMemo(() => {
    let foundIndex = 0;
    if (props.targetPersonId) {
      data.forEach((person, index) => {
        if (person.id === props.targetPersonId) {
          foundIndex = index;
          return false;
        }
      });
    }
    return foundIndex;
  }, [data, props.targetPersonId]);

  return (
    <div
      className={classes.root}
      style={{
        height: '100%',
      }}
    >
      <Table
        component="div"
        stickyHeader
        aria-label="sticky table"
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        <TableHead
          component="div"
          className={classes.thead}
        >
          <TableRow
            component="div"
            className={classes.row}
          >
            <TableCell
              className={classes.cell_person}
              component="div"
              sx={{ whiteSpace: 'nowrap' }}
            >
              선택
            </TableCell>
            <TableCell
              className={classes.cell_person}
              component="div"
            >
              소속
            </TableCell>
            <TableCell
              className={classes.cell_person}
              component="div"
            >
              이름
            </TableCell>
            <TableCell
              className={classes.cell_person}
              component="div"
            >
              직급
            </TableCell>
            <TableCell
              className={classes.cell_person}
              component="div"
              sx={{ whiteSpace: 'nowrap' }}
            >
              핸드폰 번호
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody
          component="div"
          className={classes.tbody}
        >
          {isLoading && (
            <TableRow
              component="div"
              className={classes.no_result}
            >
              <TableCell
                component="div"
                colSpan={3}
                children={<CircularProgress size={30} />}
              />
            </TableRow>
          )}
          {!isLoading && filteredData && filteredData.length === 0 && (
            <TableRow
              component="div"
              className={classes.no_result}
            >
              <TableCell
                component="div"
                colSpan={3}
                children={targetCompanyId ? '결과가 없습니다.' : '업체명을 먼저 선택하세요'}
              />
            </TableRow>
          )}
          {!isLoading && filteredData && filteredData.length > 0 && (
            <AutoSizer>
              {({ height, width }) => (
                <FixedSizeList
                  initialScrollOffset={
                    initialFocusItemOffset > 0 ? (initialFocusItemOffset - 1) * tableRowHeight : 0
                  }
                  height={height}
                  itemCount={filteredData ? filteredData.length : 0}
                  itemSize={tableRowHeight}
                  width={width}
                  itemData={filteredData}
                  className="scroll-bar-holder"
                >
                  {TableRowWrap as any}
                </FixedSizeList>
              )}
            </AutoSizer>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

const filterPersonData = (
  category: string,
  keyword: string,
  data: AffiliatedCompanyPersonShortView[]
) =>
  data?.filter((person) => {
    if (keyword === '') {
      return true;
    } else if (category === '이름') {
      return person.name?.indexOf(keyword) > -1;
    } else if (category === '소속') {
      return person.department?.indexOf(keyword) > -1;
    } else if (category === '직급') {
      return person.position?.indexOf(keyword) > -1;
    } else if (category === '핸드폰번호') {
      return person.phoneNumber?.indexOf(keyword) > -1;
    } else {
      return false;
    }
  });
