import React from 'react';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import { ModalHeaderUI } from '@front/src/components/components-with-design/layout/ModalUI';
import { useFormContext } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import type { WorkCarouselCreateParameter } from '@front/src/features/work/features/work/features/carousel/types/parameter';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {
  onCreate: (params: WorkCarouselCreateParameter) => void;
  onClose: () => void;
}

export default function WorkCarouselCreateFormButtonGroup({
  onCreate,
  onClose,
  sectionId,
  dataId,
  menuId,
}: Readonly<Props>) {
  const { handleSubmit } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();
  const onValid = (data) => {
    const formData = new FormData();
    const request = {
      position: data.position,
      menuId,
      dataId,
      sectionId,
    };
    formData.append(`request`, new Blob([JSON.stringify(request)], { type: 'application/json' }));
    Array.from(data.images as File[]).forEach((image) => {
      formData.append(`images`, image);
    });
    onCreate({
      formData,
    });
  };
  const onInvalid = (errors) => {
    if (errors.position) {
      enqueueSnackbar(errors.position.message, { variant: 'error' });
    }
    if (errors.images) {
      enqueueSnackbar(errors.images.message, { variant: 'error' });
    }
  };
  const onSubmit = handleSubmit(onValid, onInvalid);
  return (
    <ModalHeaderUI>
      <ButtonBasicUI
        shape="ghost"
        size="medium"
        onClick={onClose}
      >
        취소
      </ButtonBasicUI>
      <ButtonBasicUI
        shape="primary"
        size="medium"
        onClick={onSubmit}
      >
        등록
      </ButtonBasicUI>
    </ModalHeaderUI>
  );
}
