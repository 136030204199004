import type { ReactNode } from 'react';
import React from 'react';
import { Box } from '@mui/material';
import type { SxProps } from '@mui/system';

export { Header as AddDeleteTableHeader };

interface Props {
  title?: string | ReactNode;
  children?: ReactNode;
  sx?: SxProps;
}

const Header = ({ children, title, sx = {} }: Props) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      ...sx,
    }}
  >
    <Box>
      {title && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '16px',
            fontWeight: 'bold',
          }}
        >
          {title}
        </Box>
      )}
    </Box>
    <Box>
      {children && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  </Box>
);
