import React from 'react';
import { ProjectSalesInfoSubjectReviewModalDesignDateDeleteButtonWidget } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date/features/delete-button/widgets/widget';
import classes from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date/index.module.scss';
import { ProjectSalesInfoSubjectReviewModalDesignDateTable } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date/components/design-date-table';

export { Index as ProjectSalesInfoSubjectReviewModalDesignDateFeature };
const Index = () => (
  <div className={classes.container}>
    <ProjectSalesInfoSubjectReviewModalDesignDateTable />
    <ProjectSalesInfoSubjectReviewModalDesignDateDeleteButtonWidget />
  </div>
);
