import React from 'react';
import MenuDrawer from '@front/src/features/global-nav-bar/components/MenuDrawer';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import { useShallow } from 'zustand/react/shallow';
import { useLocation } from 'react-router-dom';

const GlobalNavBarFeature = () => {
  const { open } = useGlobalNavBarState(
    useShallow((state) => ({
      open: state.open,
    }))
  );
  const { pathname } = useLocation();
  const isLoginPage = pathname.startsWith('/login');
  const isPasswordResetPage = pathname.startsWith('/user/password-reset');
  if (isLoginPage || isPasswordResetPage) {
    return null;
  }
  return <MenuDrawer open={open} />;
};

export default GlobalNavBarFeature;
