import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workReviewerMutation from '@front/src/features/work/features/work/features/reviewer/query/mutation';

const useWorkReviewerAddBottom = (workId: number, menuId) => {
  const callback = getNoOpCallback();
  const { mutate, isLoading } = workReviewerMutation.useAddBottom(workId, menuId);
  return {
    run: () => mutate(undefined, callback),
    setCallback: getSetCallbackFunc(callback),
    isLoading,
  };
};

export default useWorkReviewerAddBottom;
