import { personBaseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { convertEmptyToNull, getMenuIdHeaders } from '@front/src/utils';
import type { AffiliatedCompanyPersonGiftHistoryUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/types/parameter';

const url = {
  createPersonGiftHistory: (id?) => `${personBaseUrl}/${id}/gift-history`,
  getPersonGiftHistoryList: (id?) => `${personBaseUrl}/${id}/gift-history`,
  updatePersonGiftHistory: (id?) => `${personBaseUrl}/gift-history/${id}`,
  deletePersonGiftHistory: () => `${personBaseUrl}/gift-history`,
};

export const affiliatedCompanyPersonGiftHistoryApi = {
  createPersonGiftHistory: async (id?: number, menuId?) => {
    await axios.post(url.createPersonGiftHistory(id), null, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  getPersonGiftHistoryList: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getPersonGiftHistoryList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updatePersonGiftHistory: async (
    params: AffiliatedCompanyPersonGiftHistoryUpdateParameter,
    menuId
  ) => {
    await axios.put(url.updatePersonGiftHistory(params.id), convertEmptyToNull(params), {
      headers: getMenuIdHeaders(menuId),
    });
  },
  deletePersonGiftHistory: async (params: AffiliatedCompanyIdListDeleteParameter, menuId) => {
    await axios.delete(url.deletePersonGiftHistory(), {
      data: params,
      headers: getMenuIdHeaders(menuId),
    });
  },
};
