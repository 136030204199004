import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyOutlineInfoMutation } from '@front/src/features/affiliated-company/features/outline/features/info/query/mutation';

export const affiliatedCompanyOutlineInfoRepository = {
  useOutlineInfoUpdate: (menuId) => {
    const { mutate } = affiliatedCompanyOutlineInfoMutation.useOutlineInfoUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
