import React, { memo } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { ProjectSalesInfoAffiliatedCompanyWebHardTableBody } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/components/web-hard-table-body';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';

export { WebHardTable as ProjectSalesInfoAffiliatedCompanyWebHardTable };

const WebHardTable = () => (
  <TableContainer
    sx={{
      ...useGetMaxWidthSx({ hasMemo: true }),
      maxHeight: '400px',
      overflow: 'auto',
    }}
  >
    <Table>
      <TableHeader />
      <TableBody>
        <ProjectSalesInfoAffiliatedCompanyWebHardTableBody />
      </TableBody>
    </Table>
  </TableContainer>
);

const TableHeader = memo(() => (
  <TableHead>
    <TableRow>
      <Th width="40px" />
      <Th width="200px">회사명</Th>
      <Th width="400px">웹하드 주소</Th>
      <Th width="200px">용도</Th>
      <Th width="200px">ID</Th>
      <Th width="300px">PW</Th>
    </TableRow>
  </TableHead>
));

TableHeader.displayName = 'ProjectSalesInfoAffiliatedCompanyWebHardTableTableHeader';
