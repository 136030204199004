import React, { useContext } from 'react';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { OldTd, Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { convertNullToEmptyForForm, downloadFile } from '@front/src/utils';
import { HookFormFileUploader } from '@front/src/components/file-uploader/hook-form-file-uploader';
import { getOptionListFromVariableList } from '@front/src/features/manageable-variable/utils';
import { ProjectSalesInfoContractHistoryModalContext } from '@front/src/features/project-sales-info/features/contract/features/history/features/modal/widgets/context';
import { ProjectSalesInfoContractHistoryModalButtonGroup } from '@front/src/features/project-sales-info/features/contract/features/history/features/modal/components/modal-button-group';
import type { ProjectContractHistoryView } from '@front/src/features/project-sales-info/features/contract/features/history/types/view';
import { useProjectSalesInfoContractHistoryState } from '@front/src/features/project-sales-info/features/contract/features/history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import { FileDisplayText } from '@front/src/components/file-display-text/file-display-text';

export { ModalTable as ProjectSalesInfoContractHistoryModalTable };

const getValues = (detail?: ProjectContractHistoryView) => ({
  category: convertNullToEmptyForForm(detail?.category),
  type: convertNullToEmptyForForm(detail?.type),
  hwp: detail?.hwp,
  pdf: detail?.pdf,
});
const ModalTable = () => {
  const {
    d: { detail, categoryList, typeList },
  } = useContext(ProjectSalesInfoContractHistoryModalContext);
  const methods = useForm({
    values: getValues(detail),
  });
  const { readOnly } = useProjectSalesInfoContractHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <Th
                center={false}
                width="140px"
              >
                계약분류
              </Th>
              <OldTd>
                <HookFormSelect
                  width="124px"
                  defaultLabel="선택"
                  isDefaultPossible
                  name="category"
                  optionList={getOptionListFromVariableList(categoryList)}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>계약서 구분</Th>
              <OldTd>
                <HookFormSelect
                  width="124px"
                  defaultLabel="선택"
                  isDefaultPossible
                  name="type"
                  optionList={getOptionListFromVariableList(typeList)}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>한글파일 업로드</Th>
              <OldTd>
                {readOnly ? (
                  <FileDisplayText
                    fileName={detail?.hwp?.filename ?? '-'}
                    onClick={downloadFile(detail?.hwp?.id)}
                  />
                ) : (
                  <HookFormFileUploader
                    name="hwp"
                    ext="hwp"
                    disabled={readOnly}
                  />
                )}
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>PDF 업로드</Th>
              <OldTd>
                {readOnly ? (
                  <FileDisplayText
                    fileName={detail?.pdf?.filename ?? '-'}
                    onClick={downloadFile(detail?.pdf?.id)}
                  />
                ) : (
                  <HookFormFileUploader
                    name="pdf"
                    ext="pdf"
                    disabled={readOnly}
                  />
                )}
              </OldTd>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ProjectSalesInfoContractHistoryModalButtonGroup />
    </FormProvider>
  );
};
