import React from 'react';
import SectionLayout from '@front/personnel/component/Section';
import VoteHistoryTable from '@front/personnel/view/Page/Log/Vote/Table';

const VoteHistoryLayout = () => (
  <SectionLayout title="투찰 이력">
    <VoteHistoryTable />
  </SectionLayout>
);

export default VoteHistoryLayout;
