import { useMutation, useQueryClient } from 'react-query';
import type { ProjectEstimationParameter } from '@front/src/features/project-sales-info/features/estimation/features/estimation/types/parameter';
import { projectSalesInfoEstimationEstimationCreateModalApi } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/create-modal/query/api';

export { mutation as projectSalesInfoEstimationEstimationCreateModalMutation };

const mutation = {
  useCreate: () => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectEstimationParameter) =>
        projectSalesInfoEstimationEstimationCreateModalApi.create(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'estimation', 'estimation', 'list'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'estimation', 'confirmed'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'header'],
        });
      },
    });
    return {
      mutate,
    };
  },
};
