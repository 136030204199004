import React, { useMemo } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { useProjectSalesInfoCollectionManagementModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/widgets/useState';
import { collectionModalLayout } from '@front/src/features/project-sales-info/features/collection/components/layout';
import { useProjectSalesInfoCollectionManagementModalUpdateModalLogic } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/features/update-modal/widgets/useLogic';
import { ProjectSalesInfoCollectionManagementModalUpdateModalContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/features/update-modal/widgets/context';
import { ProjectSalesInfoCollectionManagementModalUpdateModalTable } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/features/update-modal/components/update-modal-table';
import { isMobileDevice } from '@front/util/PwaUtil';
import { useShallow } from 'zustand/react/shallow';

export { Widget as ProjectSalesInfoCollectionManagementModalUpdateModalWidget };

const Widget = () => {
  const { Layout, Body } = collectionModalLayout;
  const {
    d: { affiliatedPersonList },
  } = useProjectSalesInfoCollectionManagementModalUpdateModalLogic();
  const { isUpdateModalOpen, setIsUpdateModalOpen } =
    useProjectSalesInfoCollectionManagementModalState(
      useShallow((state) => ({
        isUpdateModalOpen: state.isUpdateModalOpen,
        setIsUpdateModalOpen: state.setIsUpdateModalOpen,
      }))
    );
  const value = useMemo(
    () => ({
      d: {
        affiliatedPersonList,
      },
    }),
    [affiliatedPersonList]
  );
  return (
    <ModalLayout
      position="left"
      width={isMobileDevice() ? '29dvw' : '560px'}
      open={isUpdateModalOpen}
      title="수금 예정일 수정"
      onClose={() => setIsUpdateModalOpen()}
    >
      <ProjectSalesInfoCollectionManagementModalUpdateModalContext.Provider value={value}>
        <Layout width="80%">
          <Body>
            <ProjectSalesInfoCollectionManagementModalUpdateModalTable />
          </Body>
        </Layout>
      </ProjectSalesInfoCollectionManagementModalUpdateModalContext.Provider>
    </ModalLayout>
  );
};
