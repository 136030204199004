import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useSalesInfoActivityItemLogic } from '@front/src/features/project-sales-info/features/activity/widgets/row/provider/useLogic';
import type { SalesActivityAffiliatesConvertedView } from '@front/src/features/project-sales-info/features/activity/types/views';
import { SalesInfoActivityRowContext } from '@front/src/features/project-sales-info/features/activity/widgets/row/provider/context';

export { Provider as SalesInfoActivityRowProvider };

interface Props {
  children: ReactNode;
  item: SalesActivityAffiliatesConvertedView;
}

const Provider = ({ children, item }: Props) => {
  const { onUpdate } = useSalesInfoActivityItemLogic();
  const value = useMemo(() => ({ onUpdate, item }), [item, onUpdate]);
  return (
    <SalesInfoActivityRowContext.Provider value={value}>
      {children}
    </SalesInfoActivityRowContext.Provider>
  );
};
