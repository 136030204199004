import { create } from 'zustand';

export { useState as useListDrawerState };

interface State {
  open: boolean;
  setOpen: (open: boolean) => void;
  toggle: () => void;
}

const useState = create<State>((set) => ({
  open: true,
  setOpen: (open) => set(() => ({ open })),
  toggle: () => set(({ open }) => ({ open: !open })),
}));
