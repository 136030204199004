import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import type { UserId } from '@front/user/domain';
import EditIcon from '@mui/icons-material/Edit';
import type { WorkCreatorView } from '@front/src/features/work/features/work/features/creator/types/view';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/TableBody';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import useNoteStore from '@front/src/features/note/useState';
import { useShallow } from 'zustand/react/shallow';
import NoteIcon from '@mui/icons-material/Note';
import type { NoteMenuIdView } from '@front/src/features/note/types/view';

export default function WorkIndicatorTableRow({
  item,
  sectionId,
  rowActions,
  dataId,
  menuId,
  ...rest
}: Readonly<UIBuilderTableRowProps<WorkCreatorView>>) {
  const [open, setOpen] = useState<boolean>(false);
  const methods = useForm({
    values: getFormValues(item),
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    rowActions?.onUpdate({
      id: data.id,
      description: data.description,
      userId: data.userId as UserId,
      textAttr1: item.textAttr1 ?? '',
      textAttr2: item.textAttr2 ?? '',
      textAttr3: item.textAttr3 ?? '',
      numberAttr1: item.numberAttr1,
      numberAttr2: item.numberAttr2,
      numberAttr3: item.numberAttr3,
      dateAttr1: item.dateAttr1 ?? '',
      dateAttr2: item.dateAttr2 ?? '',
      dateAttr3: item.dateAttr3 ?? '',
      menuId,
      dataId,
      sectionId,
    });
  });
  const setIsRightDrawerOpen = useGlobalNavBarState((state) => state.setIsRightDrawerOpen);
  const { setRowId, setSectionId } = useNoteStore(
    useShallow((state) => ({
      setRowId: state.setRowId,
      setSectionId: state.setSectionId,
    }))
  );
  const onNoteOpen = () => {
    const noteAuth: NoteMenuIdView = JSON.parse(sessionStorage.getItem('noteAuth') ?? '{}');
    if (!noteAuth.noteId || noteAuth.type === 'X') {
      alert({
        title: '권한 오류',
        children: [{ value: '접근 권한이 없습니다.' }],
      });
      return;
    }
    setIsRightDrawerOpen(true);
    setRowId(item.id);
    setSectionId(sectionId);
  };
  const toggle = () => setOpen(!open);
  const itemList = [
    {
      text: open ? '수정 닫기' : '수정',
      icon: EditIcon,
      action: toggle,
    },
    { text: '비고', icon: NoteIcon, action: onNoteOpen },
  ];
  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        item={item}
        onSubmit={onSubmit}
        itemList={itemList}
        isEditMode={open}
        sectionId={sectionId}
        dataId={dataId}
        menuId={menuId}
        {...rest}
      />
    </FormProvider>
  );
}

const getFormValues = (item) => ({
  id: item.id,
  description: item.description ?? '',
  userId: item.user?.id,
});
