import { baseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { convertEmptyToNull, getMenuIdHeaders } from '@front/src/utils';
import type { AffiliatedCompanyOutlineAddressUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/address/types/parameter';

const url = {
  getOutlineAddress: (id?) => `${baseUrl}/${id}/address`,
  createOutlineAddress: (id?) => `${baseUrl}/${id}/address`,
  deleteOutlineAddress: () => `${baseUrl}/address`,
  updateOutlineAddress: (id?) => `${baseUrl}/address/${id}`,
};

export const affiliatedCompanyOutlineAddressApi = {
  getOutlineAddress: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getOutlineAddress(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  createOutlineAddress: async (id?: number, menuId?) => {
    await axios.post(url.createOutlineAddress(id), null, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  deleteOutlineAddress: async (params: AffiliatedCompanyIdListDeleteParameter, menuId) => {
    await axios.delete(url.deleteOutlineAddress(), {
      data: params,
      headers: getMenuIdHeaders(menuId),
    });
  },
  updateOutlineAddress: async (params: AffiliatedCompanyOutlineAddressUpdateParameter, menuId) => {
    await axios.put(url.updateOutlineAddress(params.id), convertEmptyToNull(params), {
      headers: getMenuIdHeaders(menuId),
    });
  },
};
