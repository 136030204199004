import { useQuery } from 'react-query';
import { projectSalesInfoContractEarningsApi } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/query/api';
import type {
  ProjectContractEarningsCollectionStatusView,
  ProjectContractEarningsView,
} from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/views';

export const projectSalesInfoContractEarningsQuery = {
  useContractEarningsStatusGet: (id?: number) => {
    const { data, isLoading } = useQuery<ProjectContractEarningsCollectionStatusView>({
      queryKey: ['project-sales-info', 'collection', 'contract-earnings', 'status', id],
      queryFn: () => projectSalesInfoContractEarningsApi.getContractEarningsStatus(id),
      enabled: !!id,
    });
    return {
      data,
      isLoading,
    };
  },
  useContractEarningsGet: (id: number) => {
    const { data, isLoading } = useQuery<ProjectContractEarningsView[]>({
      queryKey: ['project-sales-info', 'collection', 'contract-earnings', id],
      queryFn: () => projectSalesInfoContractEarningsApi.getContractEarnings(id),
    });
    return {
      data,
      isLoading,
    };
  },
};
