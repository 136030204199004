import React, { useContext } from 'react';
import { TableBody, TableContainer } from '@mui/material';
import { Table } from '@front/src/components/layout/table/table';
import CircularProgress from '@front/components/CircularProgress';
import { AffiliatedCompanyOutlinePrimaryBusinessTableRow } from '@front/src/features/affiliated-company/features/outline/features/primary-business/components/primary-business-table-row';
import { NoResultBox } from '@front/src/components/no-result-box/no-result-box';
import { AffiliatedCompanyOutlinePrimaryBusinessContext } from '@front/src/features/affiliated-company/features/outline/features/primary-business/widgets/context';

export { PrimaryBusinessTable as AffiliatedCompanyOutlinePrimaryBusinessTable };
const PrimaryBusinessTable = () => {
  const {
    d: { primaryBusinessList },
    isLoading,
  } = useContext(AffiliatedCompanyOutlinePrimaryBusinessContext);
  if (typeof primaryBusinessList === 'undefined' || isLoading) {
    return <CircularProgress size={30} />;
  }
  if (primaryBusinessList.length === 0) {
    return <NoResultBox message="저장된 주력사업이 없습니다." />;
  }
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {primaryBusinessList?.map((item, i) => (
            <AffiliatedCompanyOutlinePrimaryBusinessTableRow
              key={item.id}
              index={i}
              item={item}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
