import { projectSalesInfoSubjectReviewModalRepository } from '@front/src/features/project-sales-info/features/subject-review/features/modal/repository';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoSubjectReviewModalState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/useState';

export { useLogic as useProjectSalesInfoSubjectReviewModalKaKaoMapLogic };

const useLogic = () => {
  const { id } = useProjectSalesInfoSubjectReviewModalState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const {
    data: detail,
    isLoading,
    remove,
  } = projectSalesInfoSubjectReviewModalRepository.useDetailGet(id);
  const d = {
    address: detail?.address?.trim(),
  };
  const h = {
    remove,
  };
  return {
    d,
    h,
    isLoading,
  };
};
