import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { shallow } from 'zustand/shallow';
import { ProjectSalesInfoContractContractContext } from '@front/src/features/project-sales-info/features/contract/features/contract/widgets/context';
import { useProjectSalesInfoContractContractState } from '@front/src/features/project-sales-info/features/contract/features/contract/widgets/useState';
import { useCustomDialog } from '@front/src/features/dialog';
import { handleDeleteIdListConfirmAfterClick } from '@front/src/utils';
import { useProjectSalesInfoContractContractCreateModalState } from '@front/src/features/project-sales-info/features/contract/features/contract/features/create-modal/widgets/useState';
import { useSnackbar } from '@front/src/features/snackbar';
import { useShallow } from 'zustand/react/shallow';

export { ContractButtonGroup as ProjectSalesInfoContractContractButtonGroup };

const ContractButtonGroup = () => {
  const {
    d: { list },
    h: { onDelete },
  } = useContext(ProjectSalesInfoContractContractContext);
  const { idList, setIdList } = useProjectSalesInfoContractContractState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { setIsOpen } = useProjectSalesInfoContractContractCreateModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
    }),
    shallow
  );
  const { confirm } = useCustomDialog();
  const { show } = useSnackbar();
  const handleDeleteClick = useCallback(() => {
    handleDeleteIdListConfirmAfterClick(idList, confirm, () => {
      for (const id of idList) {
        const target = list?.find((item) => item.id === id);
        if (target?.isConfirmed) {
          show({ message: '확정된 계약은 삭제할 수 없습니다.' });
          return;
        }
      }
      onDelete({ idList });
      setIdList([]);
    });
  }, [idList, confirm, setIdList, onDelete, show, list]);
  const handleAddClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const { readOnly } = useProjectSalesInfoContractContractState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      <Button
        onClick={handleDeleteClick}
        disabled={readOnly}
      >
        선택삭제
      </Button>
      <Button
        onClick={handleAddClick}
        disabled={readOnly}
      >
        계약 추가
      </Button>
    </>
  );
};
