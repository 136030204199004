import type { ReactNode } from 'react';
import React from 'react';
import { useAffiliatedPersonSalesActivityListLogic } from '@front/src/features/affiliated-person/features/sales-activity/widgets/list/provider/useLogic';

export { Provider as AffiliatedPersonSalesActivityListProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  useAffiliatedPersonSalesActivityListLogic();
  return <>{children}</>;
};
