import { create } from 'zustand';

export { useState as useAffiliatedPersonDrawerState };

interface State {
  isOpen: boolean;
  keyword: string;
}

interface Actions {
  setIsOpen: (isOpen: boolean) => void;
  setKeyword: (keyword: string) => void;
}

const useState = create<State & Actions>((set) => ({
  isOpen: true,
  keyword: '',
  setIsOpen: (isOpen) =>
    set(() => ({
      isOpen,
    })),
  setKeyword: (keyword) =>
    set(() => ({
      keyword,
    })),
}));
