import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { affiliatedCompanyPersonHobbyApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/query/api';
import type { AffiliatedCompanyPersonCharacterHobbyUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/types/parameter';

export const affiliatedCompanyPersonHobbyMutation = {
  usePersonCharacterHobbyCreate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
      (id: number) => affiliatedCompanyPersonHobbyApi.createPersonCharacterHobby(id, menuId),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: ['affiliated-company', 'person', 'character', 'hobby'],
          });
        },
        onError: async (error) => handleError(error, alert),
      }
    );
    return {
      mutate,
    };
  },
  usePersonCharacterHobbyDelete: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
      (params: AffiliatedCompanyIdListDeleteParameter) =>
        affiliatedCompanyPersonHobbyApi.deletePersonCharacterHobby(params, menuId),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: ['affiliated-company', 'person', 'character', 'hobby'],
          });
        },
        onError: async (error) => handleError(error, alert),
      }
    );
    return {
      mutate,
    };
  },
  usePersonCharacterHobbyUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
      (params: AffiliatedCompanyPersonCharacterHobbyUpdateParameter) =>
        affiliatedCompanyPersonHobbyApi.updatePersonCharacterHobby(params, menuId),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: ['affiliated-company', 'person', 'character', 'hobby'],
          });
        },
        onError: async (error) => handleError(error, alert),
      }
    );
    return {
      mutate,
    };
  },
};
