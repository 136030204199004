import { projectSalesInfoCMPaymentHistoryCreateModalRepository } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/create-modal/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import {
  CMPaymentMethodKeyword,
  CMPaymentStatusKeyword,
} from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/utils/constants';

export { useLogic as useProjectSalesInfoCMPaymentHistoryCreateModalLogic };
const useLogic = () => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { run: onCreate } =
    projectSalesInfoCMPaymentHistoryCreateModalRepository.useCMPaymentHistoryCreate(id!);
  const { data: CMPaymentMethodOptionList } =
    adminManageableVariableRepository.useListGet(CMPaymentMethodKeyword);
  const { data: CMPaymentStatusOptionList } =
    adminManageableVariableRepository.useListGet(CMPaymentStatusKeyword);
  return {
    h: {
      onCreate,
    },
    d: {
      CMPaymentMethodOptionList,
      CMPaymentStatusOptionList,
    },
  };
};
