import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { WorkCategoryParams } from '@front/src/features/work/features/work/features/category/types/parameter';

const workCategoryMutation = {
  useUpdate: (id: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: WorkCategoryParams) => workWorkApi.updateCategory(params, id, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'category'],
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
};

export default workCategoryMutation;
