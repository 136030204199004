export interface VoteQuery {
  keyword: string;
  status: string;
  isVoted: boolean | string;
}

export const initialVoteQuery: VoteQuery = {
  keyword: '',
  status: '',
  isVoted: '',
};

export interface VoteMemoQuery {
  keyword: string;
}

export const initialVoteMemoQuery: VoteMemoQuery = {
  keyword: '',
};
