// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nXZrWXN6D1cSwbb0mTnb{width:2%}.sLPQpcXv3iUF7RY2qCsx{width:4%}.A_M7EavIt2SnRomzmyL1{width:6%;white-space:nowrap}.I0BqhMh92fLWse3y8MHA{width:8%}._L_7IRxdYtRLSvzPspZ5{width:10%}.bmwD99893FL_3UzIzRAp{word-break:keep-all}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/collection/features/CM-payment-history/components/CM-payment-history-table.module.scss"],"names":[],"mappings":"AAAA,sBACE,QAAA,CAGF,sBACE,QAAA,CAGF,sBACE,QAAA,CACA,kBAAA,CAGF,sBACE,QAAA,CAGF,sBACE,SAAA,CAGF,sBACE,mBAAA","sourcesContent":[".width__2 {\n  width: 2%;\n}\n\n.width__4 {\n  width: 4%;\n}\n\n.width__6 {\n  width: 6%;\n  white-space: nowrap;\n}\n\n.width__8 {\n  width: 8%;\n}\n\n.width__10 {\n  width: 10%;\n}\n\n.bid__th {\n  word-break: keep-all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"width__2": `nXZrWXN6D1cSwbb0mTnb`,
	"width__4": `sLPQpcXv3iUF7RY2qCsx`,
	"width__6": `A_M7EavIt2SnRomzmyL1`,
	"width__8": `I0BqhMh92fLWse3y8MHA`,
	"width__10": `_L_7IRxdYtRLSvzPspZ5`,
	"bid__th": `bmwD99893FL_3UzIzRAp`
};
export default ___CSS_LOADER_EXPORT___;
