import { useCallback } from 'react';
import { useAccountingSettingsManagementAccountingAccountTableState } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/useState';
import { shallow } from 'zustand/shallow';

export { useCategoryClick as useAccountingSettingsManagementAccountingAccountCategoryClick };
const useCategoryClick = () => {
  const { parentIdList, setParentIdList, setTargetItem, setUpdateId, setCategoryMircoName } =
    useAccountingSettingsManagementAccountingAccountTableState(
      (state) => ({
        parentIdList: state.parentIdList,
        setParentIdList: state.setParentIdList,
        setTargetItem: state.setTargetItem,
        setUpdateId: state.setUpdateId,
        setCategoryMircoName: state.setCategoryMircoName,
      }),
      shallow
    );
  const handleLargeCategoryClick = useCallback(
    (item) => {
      setCategoryMircoName(undefined);
      setUpdateId(undefined);
      setTargetItem(item);
      setParentIdList([item.id]);
    },
    [setParentIdList, setUpdateId, setTargetItem, setCategoryMircoName]
  );
  const handleCategoryClick = useCallback(
    (item) => {
      setCategoryMircoName(undefined);
      setUpdateId(undefined);
      setTargetItem(item);
      const { length } = parentIdList;
      const { depth } = item;
      // 동일 뎁스 클릭시 하나 제거 후 추가
      if (length === depth + 1) {
        const copy = [...parentIdList];
        copy.pop();
        setParentIdList([...copy, item.id]);
        return;
      }
      // 상위 뎁스를 클릭시 제거
      if (length > depth + 1) {
        const copy = [...parentIdList];
        for (let i = 0; i < length - depth; i += 1) {
          copy.pop();
        }
        setParentIdList([...copy, item.id]);
        return;
      }
      // 하위 뎁스 클릭시 추가
      setParentIdList([...parentIdList, item.id]);
      return;
    },
    [parentIdList, setParentIdList, setTargetItem, setUpdateId, setCategoryMircoName]
  );
  const handleCategoryMicroClick = useCallback(
    (item) => {
      setCategoryMircoName(item.id);
      setTargetItem(item);
    },
    [setCategoryMircoName, setTargetItem]
  );
  return {
    handleLargeCategoryClick,
    handleCategoryClick,
    handleCategoryMicroClick,
  };
};
