// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YJwrRg7ZGkJNqhzhSrvg{width:2%}.i9aJV8ZJ8Ks7ECB0gyjL{width:4%}.UXrBKwXUhRa_d1XiFdMe{width:6%;white-space:nowrap}.WTe36oj8y1Tr5cJl1sXR{width:8%}.CvEO9TQkq5uFuWc1VQJl{width:10%}.yT2V_IKcUNJLReA0Zlms{word-break:keep-all}.A1aU7Y9l224icqDkpVMw{display:flex;justify-content:center;padding:20px;border:1px solid #e4e9f2}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/collection/features/payment-history/components/payment-history-table.module.scss"],"names":[],"mappings":"AAAA,sBACE,QAAA,CAGF,sBACE,QAAA,CAGF,sBACE,QAAA,CACA,kBAAA,CAGF,sBACE,QAAA,CAGF,sBACE,SAAA,CAGF,sBACE,mBAAA,CAKF,sBACE,YAAA,CACA,sBAAA,CACA,YAAA,CACA,wBAAA","sourcesContent":[".width__2 {\n  width: 2%;\n}\n\n.width__4 {\n  width: 4%;\n}\n\n.width__6 {\n  width: 6%;\n  white-space: nowrap;\n}\n\n.width__8 {\n  width: 8%;\n}\n\n.width__10 {\n  width: 10%;\n}\n\n.bid__th {\n  word-break: keep-all;\n}\n\n@import \"@front/assets/colors.scss\";\n\n.no__result {\n  display: flex;\n  justify-content: center;\n  padding: 20px;\n  border: 1px solid $_e4e9f2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"width__2": `YJwrRg7ZGkJNqhzhSrvg`,
	"width__4": `i9aJV8ZJ8Ks7ECB0gyjL`,
	"width__6": `UXrBKwXUhRa_d1XiFdMe`,
	"width__8": `WTe36oj8y1Tr5cJl1sXR`,
	"width__10": `CvEO9TQkq5uFuWc1VQJl`,
	"bid__th": `yT2V_IKcUNJLReA0Zlms`,
	"no__result": `A1aU7Y9l224icqDkpVMw`
};
export default ___CSS_LOADER_EXPORT___;
