import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { projectSalesInfoAffiliatedCompanyWebHardCreateModalMutation } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/create-modal/query/mutation';

export const projectSalesInfoAffiliatedCompanyWebHardCreateModalRepository = {
  useCreate: () => {
    const { mutate } = projectSalesInfoAffiliatedCompanyWebHardCreateModalMutation.useCreate();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
