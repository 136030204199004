import React, { useCallback } from 'react';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import Button from '@front/layouts/Button';
import { useFormContext } from 'react-hook-form';
import classes from '@front/src/features/project-sales-info/features/estimation/features/estimation/components/experiment-information.module.scss';
import type { ProjectExperimentTargetByComplexInformationView } from '@front/src/features/project-sales-info/features/subject-review/types/view';
import { useProjectSalesInfoEstimationEstimationState } from '@front/src/features/project-sales-info/features/estimation/features/estimation/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { ExperimentInformation as ProjectSalesInfoEstimationEstimationCreateModalTableExperimentInformation };

interface Props {
  experiment?: ProjectExperimentTargetByComplexInformationView;
  onPressChange?: () => void;
}

const ExperimentInformation = ({ experiment, onPressChange }: Props) => {
  const { setValue } = useFormContext();
  const handleButtonClick = useCallback(() => {
    setValue('experimentInformation', experiment?.experiment?.name ?? '');
  }, [setValue, experiment]);
  const { readOnly } = useProjectSalesInfoEstimationEstimationState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <div className={classes.container}>
      <HookFormInput
        width="200px"
        name="experimentInformation"
        required={true}
        onPressChange={onPressChange}
        disabled={readOnly}
      />
      {typeof experiment !== 'undefined' && (
        <>
          {experiment.experiment?.name}
          <Button
            onClick={handleButtonClick}
            disabled={readOnly}
          >
            적용
          </Button>
        </>
      )}
    </div>
  );
};
