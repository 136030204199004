import { personBaseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { convertEmptyToNull, getMenuIdHeaders } from '@front/src/utils';
import type { AffiliatedCompanyPersonCharacterPreferenceUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/types/parameter';

const url = {
  getPersonCharacterPreference: (id?) => `${personBaseUrl}/${id}/preference`,
  createPersonCharacterPreference: (id?) => `${personBaseUrl}/${id}/preference`,
  deletePersonCharacterPreference: () => `${personBaseUrl}/preference`,
  updatePersonCharacterPreference: (id?) => `${personBaseUrl}/preference/${id}`,
};

export const affiliatedCompanyPersonPreferenceApi = {
  getPersonCharacterPreference: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getPersonCharacterPreference(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  createPersonCharacterPreference: async (id?: number, menuId?) => {
    await axios.post(url.createPersonCharacterPreference(id), null, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  deletePersonCharacterPreference: async (
    params: AffiliatedCompanyIdListDeleteParameter,
    menuId
  ) => {
    await axios.delete(url.deletePersonCharacterPreference(), {
      data: params,
      headers: getMenuIdHeaders(menuId),
    });
  },
  updatePersonCharacterPreference: async (
    params: AffiliatedCompanyPersonCharacterPreferenceUpdateParameter,
    menuId
  ) => {
    await axios.put(url.updatePersonCharacterPreference(params.id), convertEmptyToNull(params), {
      headers: getMenuIdHeaders(menuId),
    });
  },
};
