import { useDispatch } from 'react-redux';
import React, { useCallback, useContext } from 'react';
import { FormikContext, FormikProvider } from 'formik';

import useDialog from '@front/dialog/hook';
import { ostRewardManagementAction } from '@front/ost_reward/action';
import type {
  OstRewardManagementMemoId,
  OstRewardManagementMemoView,
} from '@front/ost_reward/domian';
import { OstRewardManagementMemoListItem } from '@front/ost_reward/view/memo/list-item';
import type { OstRewardManagementMemoUpdateParameter } from '@front/ost_reward/parameter';

interface Props {
  memo: OstRewardManagementMemoView;
}

export { ListItem as OstRewardManagementMemoListItemService };

const ListItem = ({ memo }: Props) => {
  const dispatch = useDispatch();
  const { confirm } = useDialog();
  const formik = useContext(FormikContext);

  const onModify = useCallback(
    (params: OstRewardManagementMemoUpdateParameter) => () => {
      formik.setValues(params);
    },
    [formik]
  );
  const onDelete = useCallback(
    (id: OstRewardManagementMemoId) => () => {
      confirm({
        children: '해당 메모를 삭제하시겠습니까?',
        afterConfirm: () => {
          dispatch(ostRewardManagementAction.deleteMemo(id));
        },
        confirmText: '확인',
      });
    },
    [dispatch]
  );
  const onSave = useCallback(
    (id: OstRewardManagementMemoId) => () => {
      if (formik.values.id === id) {
        formik.handleSubmit();
      }
    },
    [dispatch, formik]
  );

  const onCancel = useCallback(
    (id: OstRewardManagementMemoId) => () => {
      if (formik.values.id === id) {
        formik.resetForm();
      }
    },
    [dispatch, formik]
  );

  const onBlur = useCallback(
    (e) => {
      const value = e.target.value ?? '';
      if (value !== formik.values.description) {
        formik.setFieldValue('description', value);
      }
    },
    [dispatch, formik]
  );

  return (
    <FormikProvider value={formik}>
      <OstRewardManagementMemoListItem
        onModify={onModify}
        onDelete={onDelete}
        onSave={onSave}
        onCancel={onCancel}
        onBlur={onBlur}
        memo={memo}
      />
    </FormikProvider>
  );
};
