import { createReducer } from 'typesafe-actions';
import { ProposalAction } from '@front/ost_proposal/action';
import type {
  ArrangementAttachFileVO,
  ArrangementCommentId,
  ArrangementCommentVO,
  AttachFileVO,
  CommentId,
  CommentVO,
  DrawingVO,
  ProposalApprovalDocumentVO,
  ProposalApprovalVO,
  ProposalArrangementCommentEditVO,
  ProposalArrangementPlanVO,
  ProposalCancelRequestVO,
  ProposalCommentEditVO,
  ProposalContentVO,
  ProposalContributorVO,
  ProposalEstimatorVO,
  ProposalGrade,
  ProposalId,
  ProposalMemoVO,
  ProposalShortVO,
  ProposalVO,
} from '@front/ost_proposal/domain';
import type { ProposalMemoQuery, ProposalQuery } from '@front/ost_proposal/query';
import { initialProposalMemoQuery, initialProposalQuery } from '@front/ost_proposal/query';
import type { ApiStatus } from '@front/components/DataFieldProps';
import type { CampaignId, ProposalCategoryVO } from '@front/ost_campaign/domain';

export interface ProposalState {
  /**
   * 제안 목록 및 상세에 필요한 상태
   */
  id?: ProposalId;
  listDrawerOpen: boolean;
  filter: ProposalQuery;
  proposalList: ProposalShortVO[];
  loading: boolean;
  /**
   * 제안 메모 관련 상태
   */
  memoDrawerOpen: boolean;
  memoList: ProposalMemoVO[];
  memoLoading: boolean;
  memoFilter: ProposalMemoQuery;
  /**
   * 제안 등록 관련 상태
   */
  addModalOpen: boolean;
  /**
   * 제안 상세 관련 상태
   */
  detail?: ProposalVO;
  /**
   * 제안 삭제 관련 상태
   */
  requestDelete: ApiStatus;
  /**
   * 제안 드로잉 관련 상태
   */
  drawingList: DrawingVO[];
  /**
   * 제안 파일 첨부 관련 상태
   */
  fileList: AttachFileVO[];
  /**
   * 제안 댓글 관련 상태
   */
  commentList: CommentVO[];
  /**
   * 제안 내용 관련 상태
   */
  contentList: ProposalContentVO[];
  title?: string;
  /**
   * 제안 참여자 관련 상태
   */
  contributorList: ProposalContributorVO[];
  /**
   * 제안 카테고리 관련 상태
   */
  campaignId?: CampaignId;
  proposalCategoryList: ProposalCategoryVO[];
  /**
   * 제안 등급 관련 상태
   */
  gradeByContributor?: ProposalGrade;
  /**
   * 제안 코멘트 수정 삭제 드롭다운 관련 상태
   */
  commentId?: CommentId;
  commentInput: ProposalCommentEditVO;
  commentParentId?: CommentId;
  replyCommentParentId?: CommentId;
  /**
   * 제안 결과 관련 상태
   */
  result?: number;
  /**
   * 평가 목록
   */
  estimatorList: ProposalEstimatorVO[];

  /**
   * 캠페인 안내 모달
   */
  campaignInfoModalOpen: boolean;
  /**
   * 협의자 지정 모달
   */
  setAdvisorModalOpen: boolean;
  /**
   * 협의 계획 리스트
   */
  proposalArrangementPlanList: ProposalArrangementPlanVO[];

  /**
   * 제안 협의 댓글 관련 상태
   */
  arrangementCommentList: ArrangementCommentVO[];
  /**
   * 제안 협의 댓글 수정 삭제 드롭다운 관련 상태
   */
  arrangementCommentId?: ArrangementCommentId;
  arrangementCommentInput: ProposalArrangementCommentEditVO;
  arrangementCommentParentId?: ArrangementCommentId;
  replyArrangementCommentParentId?: ArrangementCommentId;

  /**
   * 제안 협의 파일 첨부 관련 상태
   */
  arrangementFileList: ArrangementAttachFileVO[];

  /**
   * 제안 회수 관련 모달
   */
  setProposalSubmissionCancelModalOpen: boolean;
  /**
   * 제안 취소 요청
   */
  proposalCancelRequest?: ProposalCancelRequestVO;

  /**
   * 결재 직접 상신 요청 모달
   */
  approvalDocumentModalOpen: boolean;
  /**
   * 결재 모달창 열기
   */
  approvalModalOpen: boolean;
  /**
   * 결재선 목록
   */
  approvalList: ProposalApprovalVO[];
  /**
   * 기안
   */
  approvalDocument?: ProposalApprovalDocumentVO;
}

const initial: ProposalState = {
  memoDrawerOpen: true,
  listDrawerOpen: true,
  addModalOpen: false,
  filter: initialProposalQuery,
  proposalList: [],
  loading: false,
  requestDelete: 'idle',
  memoList: [],
  memoLoading: false,
  memoFilter: initialProposalMemoQuery,
  drawingList: [],
  fileList: [],
  commentList: [],
  contentList: [],
  contributorList: [],
  proposalCategoryList: [],
  commentInput: {
    id: undefined,
    show: false,
  },
  estimatorList: [],
  campaignInfoModalOpen: false,
  setAdvisorModalOpen: false,
  proposalArrangementPlanList: [],
  // w
  arrangementCommentList: [],
  arrangementCommentInput: {
    id: undefined,
    show: false,
  },
  arrangementFileList: [],
  setProposalSubmissionCancelModalOpen: false,
  approvalDocumentModalOpen: false,
  approvalModalOpen: false,
  approvalList: [],
};

export const proposalReducer = createReducer(initial, {
  /**
   * 제안 목록 및 상세에 필요한 핸들러
   */
  [ProposalAction.setId]: (state, action) => ({
    ...state,
    id: action.payload,
  }),
  [ProposalAction.toggleDrawer]: (state, action) => ({
    ...state,
    listDrawerOpen: action.payload,
  }),
  [ProposalAction.setList]: (state, action) => ({
    ...state,
    proposalList: action.payload,
  }),
  [ProposalAction.setLoading]: (state, action) => ({
    ...state,
    loading: action.payload,
  }),
  [ProposalAction.setFilter]: (state, action) => ({
    ...state,
    filter: action.payload,
  }),
  /**
   * 제안 메모 관련 핸들러
   */
  [ProposalAction.setMemoDrawerOpen]: (state, action) => ({
    ...state,
    memoDrawerOpen: action.payload,
  }),
  [ProposalAction.setMemoList]: (state, action) => ({
    ...state,
    memoList: action.payload,
  }),
  [ProposalAction.setMemoLoading]: (state, action) => ({
    ...state,
    memoLoading: action.payload,
  }),
  [ProposalAction.setMemoFilter]: (state, action) => ({
    ...state,
    memoFilter: action.payload,
  }),
  /**
   * 제안 등록 관련 핸들러
   */
  [ProposalAction.setAddModal]: (state, action) => ({
    ...state,
    addModalOpen: action.payload,
  }),
  /**
   * 제안 상세 관련 핸들러
   */
  [ProposalAction.setDetail]: (state, action) => ({
    ...state,
    detail: action.payload,
  }),
  /**
   * 제안 삭제 관련 핸들러
   */
  [ProposalAction.requestDelete]: (state, action) => ({
    ...state,
    requestDelete: action.payload,
  }),
  /**
   * 제안 드로잉 관련 핸들러
   */
  [ProposalAction.setDrawingList]: (state, action) => ({
    ...state,
    drawingList: action.payload,
  }),
  /**
   * 제안 파일 첨부 관련 핸들러
   */
  [ProposalAction.setAttachedFileList]: (state, action) => ({
    ...state,
    fileList: action.payload,
  }),
  /**
   * 제안 댓글 관련 상태
   */
  [ProposalAction.setCommentList]: (state, action) => ({
    ...state,
    commentList: action.payload,
  }),
  /**
   * 제안 내용 관련 상태
   */
  [ProposalAction.setContentList]: (state, action) => ({
    ...state,
    contentList: action.payload,
  }),
  /**
   * 제안 제목 관련 상태
   */
  [ProposalAction.setTitle]: (state, action) => ({
    ...state,
    title: action.payload,
  }),
  /**
   * 제안 참여자 관련 상태
   */
  [ProposalAction.setContributorList]: (state, action) => ({
    ...state,
    contributorList: action.payload,
  }),
  /**
   * 제안 카테고리 관련 상태
   */
  [ProposalAction.setProposalCategoryList]: (state, action) => ({
    ...state,
    proposalCategoryList: action.payload,
  }),
  [ProposalAction.setCampaignId]: (state, action) => ({
    ...state,
    campaignId: action.payload,
  }),
  /**
   * 제안자 및 기여자 제안 평가 등급 관련 상태
   */
  [ProposalAction.setGrade]: (state, action) => ({
    ...state,
    gradeByContributor: action.payload,
  }),
  /**
   * 제안 코멘트 수정 삭제 드롭다운 관련 상태
   */
  [ProposalAction.setCommentId]: (state, action) => ({
    ...state,
    commentId: action.payload,
  }),
  [ProposalAction.setCommentEditInput]: (state, action) => ({
    ...state,
    commentInput: action.payload,
  }),
  [ProposalAction.setCommentParentId]: (state, action) => ({
    ...state,
    commentParentId: action.payload,
  }),
  [ProposalAction.setReplyCommentParentId]: (state, action) => ({
    ...state,
    replyCommentParentId: action.payload,
  }),
  /**
   * 제안 진척 현황 관련 상태
   */
  [ProposalAction.setProposalStatus]: (state, action) => {
    if (state.detail) {
      return {
        ...state,
        detail: {
          ...state.detail,
          status: action.payload,
        },
      };
    }
    return {
      ...state,
    };
  },
  /**
   * 최종 확정 금액
   */
  [ProposalAction.setProposalResult]: (state, action) => ({
    ...state,
    result: action.payload,
  }),
  /**
   * 평가목록
   */
  [ProposalAction.setEstimatorInfoList]: (state, action) => ({
    ...state,
    estimatorList: action.payload,
  }),
  /**
   * 캠페인 안내 모달
   */
  [ProposalAction.setCampaignInfoModal]: (state, action) => ({
    ...state,
    campaignInfoModalOpen: action.payload,
  }),
  /**
   * 협의자 지정 모달
   */
  [ProposalAction.setAdvisorModal]: (state, action) => ({
    ...state,
    setAdvisorModalOpen: action.payload,
  }),
  /**
   * 협의 계획 목록
   */
  [ProposalAction.setProposalArrangementPlanList]: (state, action) => ({
    ...state,
    proposalArrangementPlanList: action.payload,
  }),
  /**
   * 제안 협의 댓글 관련 상태
   */
  [ProposalAction.setArrangementCommentList]: (state, action) => ({
    ...state,
    arrangementCommentList: action.payload,
  }),
  /**
   * 제안 협의 댓글 수정 삭제 드롭다운 관련 상태
   */
  [ProposalAction.setArrangementCommentId]: (state, action) => ({
    ...state,
    arrangementCommentId: action.payload,
  }),
  [ProposalAction.setArrangementCommentEditInput]: (state, action) => ({
    ...state,
    arrangementCommentInput: action.payload,
  }),
  [ProposalAction.setArrangementCommentParentId]: (state, action) => ({
    ...state,
    arrangementCommentParentId: action.payload,
  }),
  [ProposalAction.setReplyArrangementCommentParentId]: (state, action) => ({
    ...state,
    replyArrangementCommentParentId: action.payload,
  }),
  /**
   * 제안 협의 파일 첨부 관련 핸들러
   */
  [ProposalAction.setArrangementAttachedFileList]: (state, action) => ({
    ...state,
    arrangementFileList: action.payload,
  }),
  /**
   * 제안 회수 모달
   */
  [ProposalAction.setProposalSubmissionCancelModal]: (state, action) => ({
    ...state,
    setProposalSubmissionCancelModalOpen: action.payload,
  }),
  /**
   * 제안 취소 요청 내용 set
   */
  [ProposalAction.setProposalSubmissionCancel]: (state, action) => ({
    ...state,
    proposalCancelRequest: action.payload,
  }),
  /**
   * 결재 직전 상신 요청 모달창
   */
  [ProposalAction.setApprovalDocumentModal]: (state, action) => ({
    ...state,
    approvalDocumentModalOpen: action.payload,
  }),
  /**
   * 결재 모달창 열기
   */
  [ProposalAction.setApprovalModal]: (state, action) => ({
    ...state,
    approvalModalOpen: action.payload,
  }),
  /**
   * 결재 목록
   */
  [ProposalAction.setApprovalList]: (state, action) => ({
    ...state,
    approvalList: action.payload,
  }),
  /**
   * 기안
   */
  [ProposalAction.setApprovalDocument]: (state, action) => ({
    ...state,
    approvalDocument: action.payload,
  }),
});
