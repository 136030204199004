import React from 'react';
import { PersonalSettingsModalUISettingsHeaderSettingsPositionTable } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/components/PositionTable';

export { Index as PersonalSettingsModalUISettingsHeaderSettingsPositionFeature };
interface Props {
  authMenuId: number | undefined;
}
const Index = ({ authMenuId }: Props) => (
  <PersonalSettingsModalUISettingsHeaderSettingsPositionTable authMenuId={authMenuId} />
);
