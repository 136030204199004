import React, { useCallback, useMemo } from 'react';
import { TableCell } from '@mui/material';
import Checkbox from '@front/layouts/Checkbox';
import { spliceAndPushCombinedIdIfExist } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/utils';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/widgets/useState';
import type { PersonModalShortView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/types/view';
import { shallow } from 'zustand/shallow';
import { getValueIfExist } from '@front/src/utils';

export { ResultTableBodyRowContent as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalResultTableBodyRowContent };

interface Props {
  item: PersonModalShortView;
}

const ResultTableBodyRowContent = ({ item }: Props) => {
  const { addList, setAddList } =
    useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalState(
      (state) => ({
        addList: state.addList,
        setAddList: state.setAddList,
      }),
      shallow
    );
  const handleCheckbox = useCallback(() => {
    setAddList(spliceAndPushCombinedIdIfExist(addList, item));
  }, [addList, setAddList, item]);
  const checked = useMemo(
    () =>
      addList
        .map((v) => `${v.id}${v.affiliatedCompanyId}`)
        .includes(`${item.id}${item.affiliatedCompanyId}`),
    [addList, item.id, item.affiliatedCompanyId]
  );
  return (
    <>
      <TableCell align="center">
        <Checkbox
          checked={checked}
          onChange={handleCheckbox}
        />
      </TableCell>
      <TableCell align="center">{getValueIfExist(item.affiliatedCompanyCategory)}</TableCell>
      <TableCell
        align="center"
        sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
      >
        {getValueIfExist(item.affiliatedCompanyName)}
      </TableCell>
      <TableCell align="center">{getValueIfExist(item.name)}</TableCell>
      <TableCell
        align="center"
        sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
      >
        {getValueIfExist(item.position)}
      </TableCell>
      <TableCell align="center">{getValueIfExist(item.phoneNumber)}</TableCell>
      <TableCell align="center">{getValueIfExist(item.email)}</TableCell>
    </>
  );
};
