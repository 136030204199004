import { createContext } from 'react';

export { Context as AccountingUploadEcountDataContext };

interface State {
  isModalMode?: boolean;
}

const Context = createContext<State>({
  isModalMode: false,
});
