// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HjoAVMkfbO6BxUzPyOoI{width:2%}.AUKThjt8NN6IkJqUEUue{width:4%}.UspiZ4rKS5TQKW5i8io1{width:6%;white-space:nowrap}.aNbLn_tCX1m7f8LxRwEQ{width:8%}.G8_U0UpWskxQsnLyUZi3{width:10%}.xVEpRENQASng2gMAxd2G{word-break:keep-all}.cawdrptwXQ6xGtJ46ceU{display:flex;justify-content:center;padding:20px;border:1px solid #e4e9f2}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/components/manage-collection-table.module.scss"],"names":[],"mappings":"AAAA,sBACE,QAAA,CAGF,sBACE,QAAA,CAGF,sBACE,QAAA,CACA,kBAAA,CAGF,sBACE,QAAA,CAGF,sBACE,SAAA,CAGF,sBACE,mBAAA,CAKF,sBACE,YAAA,CACA,sBAAA,CACA,YAAA,CACA,wBAAA","sourcesContent":[".width__2 {\n  width: 2%;\n}\n\n.width__4 {\n  width: 4%;\n}\n\n.width__6 {\n  width: 6%;\n  white-space: nowrap;\n}\n\n.width__8 {\n  width: 8%;\n}\n\n.width__10 {\n  width: 10%;\n}\n\n.bid__th {\n  word-break: keep-all;\n}\n\n@import \"@front/assets/colors.scss\";\n\n.no__result {\n  display: flex;\n  justify-content: center;\n  padding: 20px;\n  border: 1px solid $_e4e9f2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"width__2": `HjoAVMkfbO6BxUzPyOoI`,
	"width__4": `AUKThjt8NN6IkJqUEUue`,
	"width__6": `UspiZ4rKS5TQKW5i8io1`,
	"width__8": `aNbLn_tCX1m7f8LxRwEQ`,
	"width__10": `G8_U0UpWskxQsnLyUZi3`,
	"bid__th": `xVEpRENQASng2gMAxd2G`,
	"no__result": `cawdrptwXQ6xGtJ46ceU`
};
export default ___CSS_LOADER_EXPORT___;
