import useIdGroup from '@front/src/features/note/hooks/useIdGroup';
import noteMutation from '@front/src/features/note/query/mutation';

const useCreateNote = () => {
  const { menuId, dataId, sectionId, rowId } = useIdGroup();
  const params = {
    menuId,
    dataId,
    sectionId,
    rowId,
  };
  const { mutate: createNote, isLoading } = noteMutation.useCreate();
  return { createNote, params, isLoading };
};

export default useCreateNote;
