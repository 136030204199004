import React from 'react';
import { useProjectSalesInfoBidInfoState } from '@front/src/features/project-sales-info/features/bid/features/bid-information/widgets/useState';
import { ProjectSalesInfoBidInfoCreateModalWidget } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/widgets/widget';

export { ModalWrapper as ProjectSalesInfoBidInfoCreateModalWrapper };

const ModalWrapper = () => {
  const isCreateModalOpen = useProjectSalesInfoBidInfoState((state) => state.isCreateModalOpen);
  return <>{isCreateModalOpen && <ProjectSalesInfoBidInfoCreateModalWidget />}</>;
};
