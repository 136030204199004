import React, { useEffect } from 'react';
import { ProjectSalesInfoCMPaymentHistoryProvider } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/widgets/provider';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoCMPaymentHistoryBtn } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/components/CM-payment-history-btn';
import { ProjectSalesInfoCmPaymentHistoryTable } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/components/CM-payment-history-table';
import { ProjectSalesInfoCMPaymentHistoryCreateModalWrapper } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/create-modal/components/modal-wrapper';
import { ProjectSalesInfoCMPaymentHistoryUpdateModalWrapper } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/update-modal/components/modal-wrapper';
import { useProjectSalesInfoCMPaymentHistoryState } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoCMPaymentHistoryWidget = ({ readOnly, name }: Readonly<Props>) => {
  const { Body, Layout, Header } = AddDeleteTable;
  const { setReadOnly } = useProjectSalesInfoCMPaymentHistoryState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <ProjectSalesInfoCMPaymentHistoryProvider>
      <ProjectSalesInfoCMPaymentHistoryCreateModalWrapper />
      <ProjectSalesInfoCMPaymentHistoryUpdateModalWrapper />
      <Layout>
        <Header title={name}>
          <ProjectSalesInfoCMPaymentHistoryBtn />
        </Header>
        <Body>
          <ProjectSalesInfoCmPaymentHistoryTable />
        </Body>
      </Layout>
    </ProjectSalesInfoCMPaymentHistoryProvider>
  );
};

export default ProjectSalesInfoCMPaymentHistoryWidget;
