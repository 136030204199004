import React from 'react';
import { MobileNotificationHeaderProvider } from '@front/src/features/mobile-notification/widgets/header/provider/provider';
import { MobileNotificationBtnGroup } from '@front/src/features/mobile-notification/widgets/header/components/mobile-notification-btn-group';
import { MobileNotificationSelectorGroup } from '@front/src/features/mobile-notification/widgets/header/components/mobile-notification-selector-group';

export { Widget as MobileNotificationHeaderWidget };

const Widget = () => (
  <MobileNotificationHeaderProvider>
    <MobileNotificationSelectorGroup />
    <MobileNotificationBtnGroup />
  </MobileNotificationHeaderProvider>
);
