import React from 'react';
import { Box } from '@mui/material';
import OpinionTextArea from '@front/ost_proposal/view/modal/approval_document/OpinionTextArea';

const Form = () => (
  <Box
    sx={{
      width: '100%',
    }}
  >
    <OpinionTextArea />
  </Box>
);

export { Form as RequestReportApprovalModalForm };
