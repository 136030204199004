import React from 'react';

import { useFormContext } from 'react-hook-form';
import WorkCarouselCreateForm from '@front/src/features/work/features/work/features/carousel/components/CreateForm';
import ModalUI from '@front/src/components/components-with-design/layout/ModalUI';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import workCarouselMutation from '@front/src/features/work/features/work/features/carousel/query/mutation';

import ModalLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/ModalLoadingSpinner';

export default function WorkCarouselCreateModal({
  menuId,
  dataId,
  ...rest
}: Readonly<SectionComponentProps>) {
  const { watch, setValue } = useFormContext();
  const open = watch('openCreateModal');
  const onClose = () => {
    setValue('openCreateModal', false);
  };
  const { isLoading, onCreate } = useMutationLogic({ dataId, menuId });
  return (
    <ModalUI
      title="이미지 슬라이드 추가"
      open={open}
      onClose={onClose}
    >
      <>
        <ModalLoadingSpinnerUI open={isLoading} />
        <WorkCarouselCreateForm
          onClose={onClose}
          onCreate={onCreate}
          menuId={menuId}
          dataId={dataId}
          {...rest}
        />
      </>
    </ModalUI>
  );
}

function useMutationLogic({ dataId, menuId }) {
  const { mutate: onCreate, isLoading } = workCarouselMutation.useCreate(dataId, menuId);
  return {
    onCreate,
    isLoading,
  };
}
