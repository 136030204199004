import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import Button from '@front/layouts/Button';
import { useFormContext } from 'react-hook-form';
import { personalSettingsModalUISettingsModalSettingsRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/repository/repository';
import { useCancelBlockModalState } from '@front/src/features/modal/features/cancel-block-modal/useState';
import { useShallow } from 'zustand/react/shallow';
import { usePersonalSettingsModalUISettingsModalSettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/useState';
import { usePersonalSettingsModalUISettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/useState';

export { SubmitSection as PersonalSettingsModalUISettingsModalSettingsSubmitSection };
interface Props {
  authMenuId: number | undefined;
}
const SubmitSection = ({ authMenuId }: Props) => {
  const { sectionId } = usePersonalSettingsModalUISettingsState(
    useShallow((state) => ({
      sectionId: state.sectionId,
    }))
  );
  const { data: list } = personalSettingsModalUISettingsModalSettingsRepository.useGetHeaderList(
    sectionId,
    authMenuId
  );
  const { data: originalModalHeaderList } =
    personalSettingsModalUISettingsModalSettingsRepository.useGetModalHeaderList(
      sectionId,
      authMenuId
    );
  const { run: onUpdate, setCallback } =
    personalSettingsModalUISettingsModalSettingsRepository.useUpdate(authMenuId);
  const { setOpen, setDescriptionList, setTitle } = useCancelBlockModalState(
    useShallow((state) => ({
      setOpen: state.setOpen,
      setDescriptionList: state.setDescriptionList,
      setTitle: state.setTitle,
    }))
  );
  const { parentId } = usePersonalSettingsModalUISettingsModalSettingsState(
    useShallow((state) => ({
      parentId: state.parentId,
    }))
  );
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormContext();
  const handleCancelClick = useCallback(() => {
    setTitle('취소 확인');
    setDescriptionList(['저장되지 않은 변경사항은 반영되지 않습니다.', '모달을 닫으시겠습니까?']);
    setOpen(true, () => {
      reset();
    });
  }, [setOpen, reset, setDescriptionList, setTitle]);
  const onSubmit = useCallback(
    (data) => {
      let result: PersonalSettingsModalUISettingsModalSettingsUpdateType[] = [];
      data.selectableHeaderList.forEach((sh) => {
        delete sh['name'];
        delete sh['parentName'];
        const originalSelectableHeader = originalModalHeaderList?.find(
          (o) => o.headerSettingId === sh.headerSettingId
        );
        const originalNote = originalSelectableHeader?.note ?? '';
        const seq = data.childList.findIndex((c) => c.headerSettingId === sh.headerSettingId) + 1;
        const inUse =
          originalSelectableHeader?.isUsed && originalSelectableHeader?.parentId === parentId;
        const parent = list?.find((l) => l.id === sh.headerSettingId);
        const modalHeaderList = parent?.modalHeaderList ?? [];
        const items = getItems(sh, inUse, originalNote, seq, modalHeaderList);
        result = result.concat(items);
      });
      onUpdate({
        list: result,
      });
    },
    [originalModalHeaderList, onUpdate, parentId, list]
  );
  const handleSaveClick = useCallback(() => {
    setTitle('저장 확인');
    setDescriptionList(['저장하면 되돌릴 수 없습니다.', '저장하시겠습니까?']);
    setOpen(true, () => {
      handleSubmit(onSubmit)();
    });
  }, [setOpen, handleSubmit, onSubmit, setDescriptionList, setTitle]);
  setCallback({
    onSuccess: () => {
      reset();
    },
  });
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '12px',
      }}
    >
      <Box
        sx={{
          fontSize: '13px',
        }}
      >
        저장하기 않은 내용은 반영되지 않습니다.
      </Box>
      <Button
        shape="basic2"
        onClick={handleCancelClick}
        disabled={!isDirty}
      >
        취소
      </Button>
      <Button
        onClick={handleSaveClick}
        disabled={!isDirty}
      >
        저장
      </Button>
    </Box>
  );
};

interface PersonalSettingsModalUISettingsModalSettingsUpdateType {
  headerSettingId: number;
  id?: number;
  note?: string;
  isUsed?: boolean;
  parentId?: number | null;
  version?: number;
  seq?: number;
}

const getItems = (sh, inUse, originalNote, seq, modalHeaderList) => {
  const items: PersonalSettingsModalUISettingsModalSettingsUpdateType[] = [];
  const note = originalNote === sh.note ? undefined : sh.note;
  if (sh.isUsed) {
    modalHeaderList.forEach((m) => {
      items.push({
        headerSettingId: m.headerSettingId,
        isUsed: false,
        id: m.id,
        parentId: null,
        version: m.version,
      });
    });
    items.push({
      ...sh,
      isUsed: inUse ? undefined : true,
      note,
      parentId: sh.parentId,
      seq,
    });
    return items;
  }
  if (inUse) {
    items.push({
      ...sh,
      isUsed: false,
      note,
      parentId: null,
    });
    return items;
  }
  if (originalNote !== sh.note) {
    items.push({
      ...sh,
      isUsed: undefined,
      note: sh.note,
      parentId: sh.parentId ?? null,
    });
  }
  return items;
};
