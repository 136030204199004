import React, { memo, useCallback } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import FilterMemo from '@front/ost_campaign/view/memo/filter';
import type { CampaignMemoQuery } from '@front/ost_campaign/query';
import { campaignAction } from '@front/ost_campaign/action';

const CampaignMemoFilterService = () => {
  const dispatch = useDispatch();

  const onSubmit = (values: CampaignMemoQuery) => {
    dispatch(campaignAction.setFilterMemo(values));
  };

  const formik = useFormik({
    initialValues: {} as CampaignMemoQuery,
    onSubmit: onSubmit,
  });

  const enterKeyDownHandler = useCallback(
    (e) => {
      if (e.key.toLowerCase() === 'enter') {
        const value = (e.target as HTMLInputElement).value || '';
        if (formik.values.keyword === value) {
          return;
        }
        formik.setFieldValue('keyword', value);
        formik.handleSubmit();
      }
    },
    [formik]
  );

  return (
    <FormikProvider value={formik}>
      <FilterMemo
        formik={formik}
        enterKeyDownHandler={enterKeyDownHandler}
      />
    </FormikProvider>
  );
};

const FilterMemoService = memo(CampaignMemoFilterService);

export default FilterMemoService;
