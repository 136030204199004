import React, { memo, useCallback } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import type { TextFieldProps } from '@mui/material';
import { Box } from '@mui/material';

import { isValidDate } from '@front/util/DateUtil';
import { RenderDateInput } from '@front/components/RenderDateInput';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import type {
  CampaignUpdateEndDateParameter,
  CampaignUpdateStartDateParameter,
} from '@front/ost_campaign/parameter';
import { useDispatch } from 'react-redux';

interface Props {
  startDate?: string;
  endDate?: string;

  updateStartDate: (params: CampaignUpdateStartDateParameter) => void;
  updateEndDate: (params: CampaignUpdateEndDateParameter) => void;
}

const ReceptionPeriod = ({ startDate, endDate, updateStartDate, updateEndDate }: Props) => {
  const dispatch = useDispatch();
  const openSnackbar = useCallback(
    (message) => {
      dispatch(
        snackbarAction.show({
          message,
          severity: SnackbarSeverityType.warning,
        })
      );
    },
    [dispatch]
  );

  const onChangeStarDate = useCallback(
    (e) => {
      const value = e ? dayjs(e).format('YYYY-MM-DD') : '';
      const error = !value || !isValidDate(value);
      if (!value) {
        updateStartDate({
          startDate: undefined,
        });
        return;
      }
      if (error) {
        openSnackbar('올바르지 않은 날짜 형식입니다.');
        return;
      }
      updateStartDate({
        startDate: value,
      });
    },
    [openSnackbar, updateStartDate]
  );

  const onChangeEndDate = useCallback(
    (e) => {
      const value = e ? dayjs(e).format('YYYY-MM-DD') : '';
      const error = !value || !isValidDate(value);
      if (!value) {
        updateEndDate({
          endDate: undefined,
        });
        return;
      }
      if (error) {
        openSnackbar('올바르지 않은 날짜 형식입니다.');
        return;
      }
      updateEndDate({
        endDate: value,
      });
    },
    [openSnackbar, updateEndDate]
  );

  return (
    <DataFieldWithLabel
      label="접수 기간"
      labelSX={{ minWidth: '80px' }}
    >
      <DatePicker
        inputFormat="YYYY-MM-DD"
        mask="____-__-__"
        value={startDate && dayjs(startDate).format('YYYY-MM-DD')}
        onChange={onChangeStarDate}
        renderInput={(parameter: TextFieldProps) => <RenderDateInput parameter={parameter} />}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginInline: '10px',
        }}
      >
        ~
      </Box>
      <DatePicker
        inputFormat="YYYY-MM-DD"
        mask="____-__-__"
        value={endDate && dayjs(endDate).format('YYYY-MM-DD')}
        onChange={onChangeEndDate}
        renderInput={(parameter: TextFieldProps) => <RenderDateInput parameter={parameter} />}
      />
    </DataFieldWithLabel>
  );
};

const ReceptionPeriodForm = memo(ReceptionPeriod);

export default ReceptionPeriodForm;
