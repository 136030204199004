import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { WorkScheduleParams } from '@front/src/features/work/features/work/features/schedule/types/parameter';

const workScheduleMutation = {
  useUpdate: (workId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: WorkScheduleParams) =>
        workWorkApi.updateSchedule(params, workId, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'schedule'],
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
};

export default workScheduleMutation;
