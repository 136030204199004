import React, { useCallback, useContext } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { AffiliatedCompanyPersonDetailModalOutlineEducationTable } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/widgets/components/table';
import { AffiliatedCompanyPersonDetailModalOutlineEducationTableRow } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/widgets/components/row';
import { AffiliatedCompanyPersonDetailModalOutlineEducationContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/widgets/provider/context';
import Button from '@front/layouts/Button';
import { useAffiliatedCompanyPersonDetailModalOutlineEducationState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/useState';
import { useShallow } from 'zustand/react/shallow';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';
import { useAffiliatedCompanyPersonDetailModalOutlineState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/section/outline/useState';

export { Education as AffiliatedCompanyPersonDetailModalOutlineEducation };

const Education = () => {
  const { Layout, Header, Body, Help } = AddDeleteTable;
  const { onCreate, list, onDelete } = useContext(
    AffiliatedCompanyPersonDetailModalOutlineEducationContext
  );
  const { modalId } = useAffiliatedCompanyPersonState(
    useShallow((state) => ({
      modalId: state.modalId,
    }))
  );
  const { idList } = useAffiliatedCompanyPersonDetailModalOutlineEducationState(
    useShallow((state) => ({
      idList: state.idList,
    }))
  );
  const handleDeleteClick = useCallback(() => {
    if (idList.length === 0) return;
    onDelete({ idList });
  }, [onDelete, idList]);
  const handleAddClick = useCallback(() => {
    if (!modalId) return;
    onCreate(modalId);
  }, [onCreate, modalId]);
  const { readOnly } = useAffiliatedCompanyPersonDetailModalOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Layout>
      <Header title="학력">
        <Help style={{ justifyContent: 'flex-end' }}>*행을 클릭하여 수정할 수 있습니다.</Help>
        <Button
          onClick={handleDeleteClick}
          disabled={readOnly}
        >
          선택삭제
        </Button>
        <Button
          onClick={handleAddClick}
          disabled={readOnly}
        >
          학력추가
        </Button>
      </Header>
      <Body>
        <AffiliatedCompanyPersonDetailModalOutlineEducationTable>
          {list?.length === 0 && (
            <NoResult
              colSpan={7}
              height="40px"
            />
          )}
          {list?.map((item) => (
            <AffiliatedCompanyPersonDetailModalOutlineEducationTableRow
              key={item.id}
              item={item}
            />
          ))}
        </AffiliatedCompanyPersonDetailModalOutlineEducationTable>
      </Body>
    </Layout>
  );
};
