import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import { projectSalesInfoSubjectReviewModalApi } from '@front/src/features/project-sales-info/features/subject-review/features/modal/query/api';
import type { ProjectExperimentInformationParameter } from '@front/src/features/project-sales-info/features/subject-review/features/modal/types/parameter';
import { useCustomDialog } from '@front/src/features/dialog';

export const projectSalesInfoSubjectReviewModalMutation = {
  useUpdate: () => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectExperimentInformationParameter) =>
        projectSalesInfoSubjectReviewModalApi.update(params),
      onSuccess: async (_, variables) => {
        await queryClient.invalidateQueries({
          queryKey: [
            'project',
            'sales-info',
            'subject-review',
            'shape-ratio',
            'experiment-information',
          ],
        });
        if (variables.isChanged) {
          await queryClient.invalidateQueries({
            queryKey: [
              'project',
              'sales-info',
              'subject-review',
              'shape-ratio',
              'complex-information',
              'list',
            ],
          });
        }
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useDelete: () => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => projectSalesInfoSubjectReviewModalApi.delete(id),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'subject-info', 'shape-ratio', 'list'],
        });
      },
      onError: async (error) => {
        handleError(error, alert);
      },
    });
    return {
      mutate,
    };
  },
};
