import { shallow } from 'zustand/shallow';
import { useAccountingUploadSalesInfoGoalModalState } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/useState';
import { AccountingUploadSalesInfoGoalModalRepository } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/repository/repository';

export { useLogic as useAccountingUploadSalesInfoGoalModalUpdateLogic };

const useLogic = () => {
  const { setIsOpen } = useAccountingUploadSalesInfoGoalModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
    }),
    shallow
  );
  const { run: onUpdate, setCallback } = AccountingUploadSalesInfoGoalModalRepository.useUpdate();
  setCallback({
    onSuccess: () => {
      setIsOpen(false);
    },
  });
  return {
    onUpdate,
  };
};
