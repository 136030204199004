import React, { memo, useContext } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { ProjectSalesInfoContractConfirmedTableRow } from '@front/src/features/project-sales-info/features/contract/features/confirmed/components/contract-confirmed-table-row';
import { ProjectSalesInfoContractConfirmedContext } from '@front/src/features/project-sales-info/features/contract/features/confirmed/widgets/context';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';

export { ContractConfirmedTable as ProjectSalesInfoContractConfirmedTable };

const ContractConfirmedTable = () => (
  <TableContainer
    sx={{
      ...useGetMaxWidthSx({ hasMemo: true }),
    }}
  >
    <Table>
      <TableHeader />
      <TableBody>
        <ProjectSalesInfoContractConfirmedTableRow />
      </TableBody>
    </Table>
  </TableContainer>
);

const TableHeader = memo(() => {
  const {
    d: { detail },
  } = useContext(ProjectSalesInfoContractConfirmedContext);
  return (
    <TableHead>
      <TableRow>
        <Th width="160px">계약상태</Th>
        <Th width="200px">업무진행도</Th>
        <Th width="120px">계약일자</Th>
        <Th width="200px">실험정보</Th>
        <Th width="200px">풍동금액</Th>
        <Th width="200px">구검비</Th>
        {detail?.isCm && <Th width="200px">CM비</Th>}
        {detail?.isCm && <Th width="200px">환산CM비</Th>}
        <Th width="200px">총액</Th>
        <Th width="200px">순동단가</Th>
        <Th width="120px">일정</Th>
      </TableRow>
    </TableHead>
  );
});

TableHeader.displayName = 'ProjectSalesInfoContractConfirmedTableHeader';
