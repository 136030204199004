import React, { memo } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';

export { NoResult };

interface Props {
  height?: string;
  colSpan?: number;
  message?: string;
}

const NoResult = memo(({ height, colSpan, message = '등록된 정보가 없습니다.' }: Props) => (
  <TableRow
    sx={{
      height,
    }}
  >
    <OldTd colSpan={colSpan}>{message}</OldTd>
  </TableRow>
));

NoResult.displayName = 'NoResult';
