import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Box, Fade, InputAdornment, TableBody, TableHead, TableRow } from '@mui/material';

import Input from '@front/layouts/Input';
import TextBox from '@front/layouts/Text';
import { OldTd, OldTh, Table } from '@front/layouts/Table';
import CircularProgress from '@front/components/CircularProgress';
import UserSelector from '@front/components/UserSelector';
import Button from '@front/layouts/Button';
import type {
  ProposalContributorId,
  ProposalContributorVO,
  ProposalEstimatorVO,
} from '@front/ost_proposal/domain';
import { ProposalStatus } from '@front/ost_proposal/domain';
import type { ProposalContributorUpdateParameter } from '@front/ost_proposal/parameter';
import type { RootState } from '@front/services/reducer';
import type { UserId } from '@front/user/domain';
import { AuthorizationType } from '@front/user/domain';
import type { SnackbarSeverityType } from '@front/components/Snackbar/action';
import { ArticleItem } from '@front/src/components/article-item';

interface Props {
  addContributor: () => void;
  updateContributor: (params: ProposalContributorUpdateParameter) => void;
  deleteContributor: (id: ProposalContributorId) => void;
  openSnackbar: (message: string, severity?: SnackbarSeverityType) => void;
  getExclude: (
    contributorList: ProposalContributorVO[],
    estimatorList: ProposalEstimatorVO[],
    item: ProposalContributorVO
  ) => UserId[];
  isContributor: boolean;
}

function ContributorInfo({
  addContributor,
  deleteContributor,
  updateContributor,
  openSnackbar,
  getExclude,
  isContributor,
}: Props) {
  const [contributorList] = useSelector(
    (root: RootState) => [root.proposal.contributorList],
    shallowEqual
  );
  const [estimatorList] = useSelector(
    (root: RootState) => [root.proposal.estimatorList],
    shallowEqual
  );
  const writer = useSelector((root: RootState) => root.proposal.detail!.writer, shallowEqual);
  const status = useSelector((root: RootState) => root.proposal.detail!.status, shallowEqual);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        width: '100%',
        padding: '10px',
      }}
    >
      <ArticleItem
        title="참여자"
        children={
          <Table disableSticky>
            <TableHead>
              <TableRow>
                <OldTh sx={{ width: '10px' }}>No</OldTh>
                <OldTh sx={{ width: '150px' }}>부서</OldTh>
                <OldTh sx={{ width: '150px' }}>이름</OldTh>
                <OldTh sx={{ width: '100px' }}>구분</OldTh>
                <OldTh sx={{ width: '100px' }}>기여도(%)</OldTh>
                <OldTh
                  colSpan={isContributor && status === ProposalStatus.WRITING ? 2 : 1}
                  sx={{ width: '150px' }}
                >
                  역할
                </OldTh>
              </TableRow>
            </TableHead>
            <TableBody>
              {!contributorList && (
                <TableRow>
                  <OldTd
                    colSpan={5}
                    sx={{ minHeight: '38px' }}
                  >
                    <CircularProgress
                      size={24}
                      sx={{ justifyContent: 'center', alignItems: 'center' }}
                    />
                  </OldTd>
                </TableRow>
              )}
              {contributorList?.length === 0 && (
                <TableRow>
                  <OldTd colSpan={6}>
                    <Fade in={true}>
                      <Box>조회 결과가 없습니다</Box>
                    </Fade>
                  </OldTd>
                </TableRow>
              )}
              {contributorList?.map((item, index) => (
                <TableRow key={item.id}>
                  <OldTd>{index + 1}</OldTd>
                  <OldTd>{item.contributor?.department.name}</OldTd>
                  <OldTd>
                    <UserSelector
                      readOnly={status !== ProposalStatus.WRITING || !isContributor}
                      variant="outlined"
                      value={item.contributor?.id}
                      onChange={(value) => {
                        if (item.contributor?.id !== value) {
                          updateContributor({ id: item.id, contributorId: value as UserId });
                        }
                      }}
                      addOnly
                      exclude={getExclude(contributorList, estimatorList, item)}
                      menuIdList={[28]}
                      typeList={[AuthorizationType.W]}
                    />
                  </OldTd>
                  <OldTd>
                    <TextBox variant="body9">
                      {item.contributor
                        ? item.contributor.id === writer.id
                          ? '제안자'
                          : '참여자'
                        : ''}
                    </TextBox>
                  </OldTd>
                  <OldTd>
                    <Input
                      readOnly={status !== ProposalStatus.WRITING || !isContributor}
                      type="number"
                      variant="outlined"
                      key={item.rate}
                      defaultValue={item.rate ?? ''}
                      onBlur={(e) => {
                        const value = +e.target.value || undefined;
                        if (!value) {
                          updateContributor({ id: item.id, isRateDeleted: true });
                          return;
                        }
                        if (value < 0 || value > 100) {
                          openSnackbar('올바른 기여도를 입력해주세요.');
                          return;
                        }
                        if (item.rate !== value) {
                          updateContributor({ id: item.id, rate: value });
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <TextBox variant="body11">%</TextBox>
                        </InputAdornment>
                      }
                    />
                  </OldTd>
                  <OldTd>
                    <Input
                      readOnly={status !== ProposalStatus.WRITING || !isContributor}
                      variant="outlined"
                      key={item.note}
                      defaultValue={item.note ?? ''}
                      onBlur={(e) => {
                        const value = e.target.value || undefined;
                        if (!value || value?.trim() === '') {
                          updateContributor({ id: item.id, isNoteDeleted: true });
                          return;
                        }
                        if (item.note !== value) {
                          updateContributor({ id: item.id, note: value });
                        }
                      }}
                    />
                  </OldTd>
                  {isContributor && status === ProposalStatus.WRITING && (
                    <OldTd>
                      <Button
                        shape="basic3"
                        onClick={() => {
                          deleteContributor(item.id);
                        }}
                      >
                        삭제
                      </Button>
                    </OldTd>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
        buttonsNode={
          <>
            {status === ProposalStatus.WRITING && isContributor && (
              <Button onClick={addContributor}>추가</Button>
            )}
          </>
        }
      />
    </Box>
  );
}

export default ContributorInfo;
