import { default as axios } from '@front/src/config/axios';
import type { IdListDeleteParameter } from '@front/src/types/parameter';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import type { ProjectAffiliatedCompanyPersonUpdateParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/types/parameter';

const url = {
  getList: (id?) => `${projectSalesInfoURL}/${id}/affiliated-company-person`,
  delete: () => `${projectSalesInfoURL}/affiliated-company-person`,
  update: (id?) => `${projectSalesInfoURL}/affiliated-company-person/${id}`,
};

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonApi = {
  getList: async (id?: number) => {
    const { data } = await axios.get(url.getList(id));
    return data;
  },
  delete: async (params: IdListDeleteParameter) => {
    await axios.delete(url.delete(), { data: params });
  },
  update: async (params: ProjectAffiliatedCompanyPersonUpdateParameter) => {
    await axios.put(url.update(params.id), params);
  },
};
