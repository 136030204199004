import React from 'react';
import { useGetReadOnly } from '@front/src/hooks/useGetReadOnly';
import AffiliatedCompanyPersonDetailModalGiftHistoryWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/widgets/widget';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AffiliatedPersonGiftHistoryFeature = ({ name }: Readonly<Props>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  const { readOnly } = useGetReadOnly();
  return (
    <Layout>
      <Header title={name} />
      <Body>
        <AffiliatedCompanyPersonDetailModalGiftHistoryWidget readOnly={readOnly} />
      </Body>
    </Layout>
  );
};

export default AffiliatedPersonGiftHistoryFeature;
