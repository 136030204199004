import React, { useEffect, useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoBidResultTable } from '@front/src/features/project-sales-info/features/bid/features/bid-result/components/bid-result-table';
import { ProjectSalesInfoBidResultBtn } from '@front/src/features/project-sales-info/features/bid/features/bid-result/components/bid-result-btn';
import { ProjectSalesInfoBidResultContext } from '@front/src/features/project-sales-info/features/bid/features/bid-result/widgets/context';
import { useProjectSalesInfoBidResultLogic } from '@front/src/features/project-sales-info/features/bid/features/bid-result/widgets/useLogic';
import { ProjectSalesInfoBidResultCreateModalWrapper } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/create-modal/components/modal-wrapper';
import { ProjectSalesInfoBidResultUpdateModalWrapper } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/update-modal/components/modal-wrapper';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoBidResultState } from '@front/src/features/project-sales-info/features/bid/features/bid-result/widgets/useState';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoBidResultWidget = ({ readOnly, name }: Readonly<Props>) => {
  const { Header, Layout, Body } = AddDeleteTable;
  const {
    h: { onDelete },
    d: { bidResultList },
    isLoading,
  } = useProjectSalesInfoBidResultLogic();

  const value = useMemo(
    () => ({
      h: { onDelete },
      d: { bidResultList },
      isLoading,
    }),
    [onDelete, bidResultList, isLoading]
  );
  const { setReadOnly } = useProjectSalesInfoBidResultState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <ProjectSalesInfoBidResultContext.Provider value={value}>
      <ProjectSalesInfoBidResultCreateModalWrapper />
      <ProjectSalesInfoBidResultUpdateModalWrapper />
      <Layout>
        <Header title={name}>
          <ProjectSalesInfoBidResultBtn />
        </Header>
        <Body>
          <ProjectSalesInfoBidResultTable />
        </Body>
      </Layout>
    </ProjectSalesInfoBidResultContext.Provider>
  );
};

export default ProjectSalesInfoBidResultWidget;
