import { FormControl, RadioGroup } from '@mui/material';
import React, { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import type { OptionType } from '@front/src/components/Select';
import RadioButtonUI from '@front/src/components/components-with-design/component/RadioButtonUI';

interface Props<T = string> {
  name: string;
  onSubmit?: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => void;
  direction?: 'row' | 'column';
  radioList: OptionType<T>[];
  required?: string;
  disabled?: boolean;
  readOnly?: boolean;
}

export default function RadioWithHookForm({
  name,
  onSubmit,
  radioList,
  required,
  disabled,
  direction = 'row',
  readOnly,
}: Readonly<Props>) {
  const { control, watch } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name, control, rules: { required } });
  const handleChange = useCallback(
    async (e) => {
      onChange(e);
      onSubmit?.();
    },
    [onChange, onSubmit]
  );
  const getChecked = (value) => watch(name) === value;
  if (readOnly) return watch(name);
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
        style={{ flexDirection: direction }}
      >
        {radioList.map((radio) => (
          <RadioButtonUI
            key={radio.value}
            value={radio.value}
            disabled={disabled}
            label={radio.label}
            checked={getChecked(radio.value)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
