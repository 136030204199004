import React, { memo, useContext } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';

import { OldTd, Table } from '@front/layouts/Table';
import { contractEarningsItemHeadList } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/utils/constants';

import classes from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/components/contract-earnings-table.module.scss';
import { ProjectSalesInfoContractEarningsItemContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/context';
import { ProjectSalesInfoContractEarningsTableRow } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/components/contract-earnings-table-row';
import CircularProgress from '@front/components/CircularProgress';

export { ContractEarningsTable as ProjectSalesInfoContractEarningsTable };

const ContractEarningsTable = () => {
  const {
    d: { contractEarningsItemList },
    isLoading,
  } = useContext(ProjectSalesInfoContractEarningsItemContext);
  const isItemListLoading = typeof contractEarningsItemList === 'undefined' || isLoading;
  const isItemListEmpty = contractEarningsItemList?.length === 0;
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {contractEarningsItemHeadList.map((item) => {
              let className = `${classes.width__4}`;
              if (item === '발주처명' || item === '지급조건' || item === '비고')
                className = `${classes.width__8}`;
              else if (item === '' || item === '지급비율') className = `${classes.width__2}`;
              return (
                <Th
                  key={item}
                  className={`${className} ${classes.bid__th}`}
                >
                  {item}
                </Th>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {isItemListLoading && <Spinner />}
          {!isItemListLoading && isItemListEmpty && <NoResult />}
          {!isItemListLoading &&
            !isItemListEmpty &&
            contractEarningsItemList?.map((item, index) => (
              <ProjectSalesInfoContractEarningsTableRow
                key={item.id}
                item={item}
                index={index}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const NoResult = memo(() => (
  <TableRow>
    <OldTd colSpan={28}>저장된 수금정보가 없습니다.</OldTd>
  </TableRow>
));

const Spinner = memo(() => (
  <TableRow
    sx={{
      height: '54px',
    }}
  >
    <OldTd colSpan={28}>
      <CircularProgress size={30} />
    </OldTd>
  </TableRow>
));
