import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workDetailMutation from '@front/src/features/work/features/work/features/detail/query/mutation';

const useWorkDetailSeqDown = (workId, menuId) => {
  const callback = getNoOpCallback();
  const { mutate, isLoading } = workDetailMutation.useSeqDown(workId, menuId);
  return {
    run: (id: number) => mutate(id, callback),
    setCallback: getSetCallbackFunc(callback),
    isLoading,
  };
};

export default useWorkDetailSeqDown;
