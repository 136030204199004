import type React from 'react';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import {
  BUSINESS_REGISTRATION_NUMBER_CHANGE_REGEX,
  MOBILE_CHANGE_REGEX,
} from '@front/src/features/affiliated-company/utils/constant';

export const onChangeValidator = (
  e: React.ChangeEvent<HTMLInputElement>,
  value: any,
  inputType?: InputType
) => {
  let newValue = e.target.value;
  const inputAction = (e.nativeEvent as InputEvent).inputType;

  const isBackspaceOrDelete =
    inputAction === 'deleteContentBackward' || inputAction === 'deleteContentForward';

  if (inputType === InputType.FAX) {
    if (value.length === 13 && !isBackspaceOrDelete) return value;
  }
  if (inputType === InputType.COMPANY_MAIN_CONTACT) {
    if (value.length === 13 && !isBackspaceOrDelete) return value;
  }
  if (inputType === InputType.COMPANY_CONTACT) {
    if (value.length === 13 && !isBackspaceOrDelete) return value;
  }
  if (inputType === InputType.DIRECT_CONTACT) {
    if (value.length === 13 && !isBackspaceOrDelete) return value;
  }
  if (inputType === InputType.RATIO) {
    return newValue.replace(/^(?!(?:100|[1-9]?[0-9])$).+$/g, '');
  }
  if (inputType === InputType.AMOUNT) {
    const match = newValue.replace(/^[^0-9-]*|\D*/g, '');
    if (match === '-') {
      return '-';
    }
    return Number(match).toLocaleString();
  }
  if (inputType === InputType.EST_AMOUNT) {
    const match = newValue.replace(/^[^0-9-]*|\D*/g, '');
    if (match === '-') {
      return '-';
    }
    if (+match === 0) return match;
    return Number(match).toLocaleString();
  }
  if (inputType === InputType.MOBILE) {
    if (value.length === 13 && !isBackspaceOrDelete) return value;
    newValue = newValue
      .replace(/\D/g, '')
      .replace(MOBILE_CHANGE_REGEX, (_, p1: string, p2: string) => {
        if (p1 && p2) {
          return `010-${p1}-${p2}`;
        }
        if (p1) {
          return `010-${p1}`;
        }
        return '010';
      })
      .trim();
  }
  if (inputType === InputType.BUSINESS_NUMBER) {
    if (value.length === 12 && !isBackspaceOrDelete) return value;
    newValue = newValue
      .replace(/\D/g, '')
      .replace(
        BUSINESS_REGISTRATION_NUMBER_CHANGE_REGEX,
        (_, p1: string, p2: string, p3: string) => {
          if (p2 && p3) {
            return `${p1}-${p2}-${p3}`;
          }
          if (p2) {
            return `${p1}-${p2}`;
          }
          return p1;
        }
      )
      .trim();
  }
  return newValue;
};
