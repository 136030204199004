import React, { Suspense } from 'react';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';
import { ProjectSalesInfoAbstractProjectInfoDetail } from '@front/src/features/project-sales-info/features/abstract/features/project-info/widgets/detail/components/detail';
import { ProjectSalesInfoAbstractProjectInfoDetailProvider } from '@front/src/features/project-sales-info/features/abstract/features/project-info/widgets/detail/provider/provider';

export { Widget as ProjectSalesInfoAbstractProjectInfoDetailWidget };

const Widget = () => (
  <Suspense fallback={<TableLoadingSpinner colSpan={7} />}>
    <ProjectSalesInfoAbstractProjectInfoDetailProvider>
      <ProjectSalesInfoAbstractProjectInfoDetail />
    </ProjectSalesInfoAbstractProjectInfoDetailProvider>
  </Suspense>
);
