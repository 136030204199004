import {
  contractEarningsApiUrl,
  smProjectCollectionApiUrl,
} from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/utils/constants';
import { default as axios } from '@front/src/config/axios';
import type { SmProjectCollectionParameter } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/parameters';

const url = {
  updateContractEarningsItemDetails: (detailId: number) =>
    `${smProjectCollectionApiUrl}/collection/${detailId}`,
  getContractEarningsItemDetails: (detailId: number) =>
    `${smProjectCollectionApiUrl}/collection/${detailId}`,
  getConsortiumCompany: (id: number) => `${contractEarningsApiUrl}/${id}/consortium-company`,
};

export const projectSalesInfoContractEarningsItemUpdateModalApi = {
  updateContractEarningsItemDetails: async (params: SmProjectCollectionParameter) =>
    await axios.put(url.updateContractEarningsItemDetails(params.id!), params),
  getContractEarningsItemDetails: async (detailId: number) => {
    const { data } = await axios.get(url.getContractEarningsItemDetails(detailId));
    return data;
  },
  getConsortiumCompany: async (id: number) => {
    const { data } = await axios.get(url.getConsortiumCompany(id));
    return data;
  },
};
