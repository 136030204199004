import React from 'react';
import { Box } from '@mui/material';
import useNoteStore from '@front/src/features/note/useState';
import { useShallow } from 'zustand/react/shallow';
import useGetNoteList from '@front/src/features/note/repository/useGetNoteList';

export default function NoteFilter() {
  const { setIsUnreadReview, setIsWaitApproval, setNoteFilter, noteFilter } = useNoteStore(
    useShallow((state) => ({
      setIsUnreadReview: state.setIsUnreadReview,
      setIsWaitApproval: state.setIsWaitApproval,
      setNoteFilter: state.setNoteFilter,
      noteFilter: state.noteFilter,
    }))
  );
  const onIsUnreadReviewChange = () => {
    setIsUnreadReview();
    setNoteFilter(noteFilter === 'my-review' ? 'all' : 'my-review');
  };

  const onIsWaitApprovalChange = () => {
    setIsWaitApproval();
    setNoteFilter(noteFilter === 'my-approval' ? 'all' : 'my-approval');
  };

  const { unreadReviewCount, waitApprovalCount } = useGetNoteList();

  return (
    <Box sx={{ ...SX_WRAP }}>
      <Box
        onClick={onIsUnreadReviewChange}
        sx={{ ...SX_CELL }}
      >
        <Box>읽지 않은 회람</Box>
        <Box>
          <span>{unreadReviewCount}</span>건
        </Box>
      </Box>
      <Box sx={{ ...SX_SPLIT }}></Box>
      <Box
        onClick={onIsWaitApprovalChange}
        sx={{ ...SX_CELL }}
      >
        <Box>결재 대기</Box>
        <Box>
          <span>{waitApprovalCount}</span>건
        </Box>
      </Box>
    </Box>
  );
}

const SX_SPLIT = {
  width: '1px',
  height: '16px',
  background: '#B0B5BE',
};

const SX_WRAP = {
  display: 'flex',
  padding: '7px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '30px',
  alignSelf: 'stretch',
  background: 'var(--design-v-10-light-background-bg-06, #49576F)',
};

const SX_CELL = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '1 0 0',
  color: 'var(--design-v-10-light-greyscale-white, #FFF)',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '22px',
  cursor: 'pointer',
  '& > *': {
    color: 'white',
  },
  '& > * > span': {
    color: 'var(--design-v-10-light-sub-sub-primary, #FFB611)',
    paddingLeft: '20px',
    paddingRight: '5px',
  },
};
