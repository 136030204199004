import React, { useContext } from 'react';
import { Table } from '@front/src/components/layout/table/table';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { OldTd } from '@front/layouts/Table';
import { useFormContext } from 'react-hook-form';
import { ProjectSalesInfoProjectDivisionContext } from '@front/src/features/project-sales-info/features/project-division/widgets/context';
import {
  changeViewToParams,
  periodValidator,
} from '@front/src/features/project-sales-info/features/project-division/utils/constants';
import type { ProjectDivisionView } from '@front/src/features/project-sales-info/features/project-division/types/view';
import { useProjectSalesInfoProjectDivisionState } from '@front/src/features/project-sales-info/features/project-division/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";

export { Index as ProjectSalesInfoProjectDivisionIntakeDateTable };

const Index = () => {
  const {
    d: { projectDivisionData },
    h: { onProjectDivisionUpdate },
  } = useContext(ProjectSalesInfoProjectDivisionContext);
  const { handleSubmit } = useFormContext();
  const onSubmit = handleSubmit((data) =>
    onProjectDivisionUpdate(changeViewToParams(data as ProjectDivisionView))
  );
  const { readOnly } = useProjectSalesInfoProjectDivisionState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <Th>수주일</Th>
              <OldTd sx={{ textAlign: 'left' }}>
                {projectDivisionData?.orderIntakeDate ?? '계약탭의 수주일이 미정상태 입니다.'}
              </OldTd>
            </TableRow>
            <TableRow>
              <Th>계약일</Th>
              <OldTd sx={{ textAlign: 'left' }}>
                {projectDivisionData?.contractDate ?? '계약탭의 확정 계약일이 미정상태 입니다.'}
              </OldTd>
            </TableRow>
            <TableRow key={Math.random()}>
              <Th>착수일</Th>
              <OldTd sx={{textAlign: 'left'}}>
                <DatePickerWithHookForm
                  name="workStartDate"
                  onSubmit={onSubmit}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <Th>수주 소요기간</Th>
              <OldTd align="left">{periodValidator(projectDivisionData?.orderIntakePeriod)}</OldTd>
            </TableRow>
            <TableRow>
              <Th>착수 소요기간</Th>
              <OldTd align="left">{periodValidator(projectDivisionData?.workStartPeriod)}</OldTd>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
