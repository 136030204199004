import { create } from 'zustand';

export { useState as useProjectSalesInfoContractReviewHistoryState };

interface State {
  idList: number[];
  setIdList: (idList: number[]) => void;
  readOnly?: boolean;
  setReadOnly: (readOnly?: boolean) => void;
}

const useState = create<State>((set) => ({
  idList: [],
  setIdList: (idList) => set(() => ({ idList })),
  readOnly: false,
  setReadOnly: (readOnly) => set({ readOnly }),
}));
