import { shallow } from 'zustand/shallow';
import { useCallback } from 'react';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import { projectSalesInfoContractCategoryKey } from '@front/src/features/project-sales-info/features/contract/utils/constant';
import { useProjectSalesInfoContractReviewModalState } from '@front/src/features/project-sales-info/features/contract/features/reivew/features/modal/widgets/useState';
import { projectSalesInfoContractReviewModalRepository } from '@front/src/features/project-sales-info/features/contract/features/reivew/features/modal/repository';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyRepository } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';

export { useLogic as useProjectSalesInfoContractReviewModalLogic };

const useLogic = () => {
  const { id } = useProjectSalesInfoDetailState((state) => ({ id: state.id }), shallow);
  const {
    isOpen,
    setIsOpen,
    id: modalId,
    setId,
  } = useProjectSalesInfoContractReviewModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      isOpen: state.isOpen,
      id: state.id,
      setId: state.setId,
    }),
    shallow
  );
  const { data: detail } = projectSalesInfoContractReviewModalRepository.useDetailGet(modalId);
  const { run: onCreate, setCallback: setCreateCallback } =
    projectSalesInfoContractReviewModalRepository.useCreate();
  const { run: onUpdate, setCallback: setUpdateCallback } =
    projectSalesInfoContractReviewModalRepository.useUpdate();
  const { data: categoryList } = adminManageableVariableRepository.useListGet(
    projectSalesInfoContractCategoryKey
  );
  const { data: companyList } =
    projectSalesInfoAffiliatedCompanyAffiliatedCompanyRepository.useListGet(id);

  setCreateCallback({
    onSuccess: () => {
      handleClose();
    },
  });
  setUpdateCallback({
    onSuccess: () => {
      handleClose();
    },
  });
  const handleClose = useCallback(() => {
    setId(undefined);
    setIsOpen(false);
  }, [setIsOpen, setId]);
  const d = { detail, categoryList, companyList };
  const h = { onCreate, onUpdate, onClose: handleClose };
  return {
    d,
    h,
    isOpen,
  };
};
