import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import type { TableContainerProps } from '@mui/material/TableContainer/TableContainer';
import { ColorPalette } from '@front/assets/theme';

export default function TableContainerUI({ sx = {}, ...rest }: Readonly<TableContainerProps>) {
  return (
    // <Box
    //   sx={{
    //     width: '100%',
    //     borderRadius: '5px',
    //     border: `1px solid ${ColorPalette.line.line}`,
    //     overflow: 'hidden',
    //   }}
    // >
    <TableContainer
      sx={{
        width: '100%',
        borderRadius: '5px',
        border: `1px solid ${ColorPalette.line.line}`,
        overflow: 'hidden',
        ...sx,
      }}
      {...rest}
    />
    // </Box>
  );
}
