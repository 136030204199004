import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { PlanTable } from '@front/ost_proposal/view/detail/advisorForm/table/Plan';
import { proposalAction } from '@front/ost_proposal/action';
import type { ProposalUpdateArrangementPlanParameter } from '@front/ost_proposal/parameter';
import type { ProposalArrangementPlanId } from '@front/ost_proposal/domain';

interface Props {
  isAdvisor: boolean;
}

const PlanService = ({ isAdvisor }: Props) => {
  const dispatch = useDispatch();
  const onAdd = useCallback(
    () => dispatch(proposalAction.addProposalArrangementPlan()),
    [dispatch]
  );
  const onUpdate = useCallback(
    (params: ProposalUpdateArrangementPlanParameter) =>
      dispatch(proposalAction.updateProposalArrangementPlan(params)),
    [dispatch]
  );
  const onDelete = useCallback(
    (id: ProposalArrangementPlanId) => dispatch(proposalAction.deleteProposalArrangementPlan(id)),
    [dispatch]
  );
  return (
    <PlanTable
      onAdd={onAdd}
      onUpdate={onUpdate}
      onDelete={onDelete}
      isAdvisor={isAdvisor}
    />
  );
};

export default PlanService;
