// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zhV3Yo86WstkeTNpB9AW{position:relative;height:100%;display:flex;justify-content:center}.s9MVWuYITZQcPI7cTFnS{position:sticky;top:10px}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/subject-review/features/modal/features/kakao-map/components/kakao-map.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,WAAA,CACA,YAAA,CACA,sBAAA,CAGF,sBACE,eAAA,CACA,QAAA","sourcesContent":[".container {\n  position: relative;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n.map {\n  position: sticky;\n  top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `zhV3Yo86WstkeTNpB9AW`,
	"map": `s9MVWuYITZQcPI7cTFnS`
};
export default ___CSS_LOADER_EXPORT___;
