import { all, call, delay, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { proposalAction } from '@front/ost_proposal/action';
import { proposalApi } from '@front/ost_proposal/api';
import { dialogAction } from '@front/dialog/action';
import { getErrorCode, getErrorMessage } from '@front/type/Error';
import type {
  ArrangementAttachFileVO,
  ArrangementCommentVO,
  AttachFileVO,
  CommentVO,
  DrawingVO,
  ProposalApprovalDocumentVO,
  ProposalApprovalVO,
  ProposalArrangementPlanId,
  ProposalArrangementPlanVO,
  ProposalContentVO,
  ProposalContributorVO,
  ProposalGrade,
  ProposalShortVO,
  ProposalVO,
} from '@front/ost_proposal/domain';
import { ApprovalResult, ProposalArrangementInformationStatus } from '@front/ost_proposal/domain';
import type { RootState } from '@front/services/reducer';
import { userNotificationAction } from '@front/user_notification/action';
import type { VoteVO } from '@front/ost_vote/domain';
import { voteApi } from '@front/ost_vote/api';
import { navigationAction } from '@front/navigate/action';
import type {
  ApprovalDocumentParameter,
  ProposalApprovalParameter,
  ProposalArrangementInformationStatusParameter,
  ProposalEstimatorUpdateGradeParameter,
  ProposalSubmissionCancelReasonParameter,
  ProposalUpdateArrangementInformationAdvisorParameter,
  ProposalUpdateArrangementPlanParameter,
} from '@front/ost_proposal/parameter';
import { evaluationAction } from '@front/ost_evaluation/action';
import { TargetPage } from '@front/navigate/domain';
import { voteAction } from '@front/ost_vote/action';
import { arrangementAction } from '@front/ost_arrangement/action';
import { CustomHttpStatus, getCustomErrorMessage } from '@front/common/type/http-status.type';

/**
 * 제안 등록 관련 saga
 */
function* watchAdd() {
  while (true) {
    const { payload: addParameter } = yield take(proposalAction.add);
    const { filter } = yield select((root: RootState) => root.proposal);
    if (addParameter) {
      try {
        const detail: ProposalVO = yield call(proposalApi.add, addParameter);
        yield put(proposalAction.setId(detail.id));
        yield put(dialogAction.openAlert('등록이 완료 됐습니다.'));
        yield put(proposalAction.add(undefined));
        yield put(proposalAction.setAddModal(false));
        yield put(proposalAction.setFilter(filter));
        yield put(navigationAction.navigate(`/ost/proposal-center/${detail.id}`));
      } catch (e) {
        const message = getErrorMessage(proposalAction.add, e);
        yield put(dialogAction.openError(message));
      }
    }
  }
}

/**
 * 제안 목록 및 검색 관련 saga
 */
function* watchFilter() {
  while (true) {
    const { payload: query } = yield take(proposalAction.setFilter);
    yield put(proposalAction.setLoading(true));
    try {
      const list: ProposalShortVO[] = yield call(proposalApi.getProposalList, query);
      yield put(proposalAction.setList(list));
    } catch (e) {
      const message = getErrorMessage(proposalAction.setFilter, e);
      yield put(dialogAction.openError(message));
    } finally {
      yield delay(500);
      yield put(proposalAction.setLoading(false));
    }
  }
}

function* watchId(action) {
  try {
    if (action.payload) {
      const detail: ProposalVO = yield call(proposalApi.getOne, action.payload);
      yield put(proposalAction.setDetail(detail));
      yield all([
        fork(handleSetContentList),
        fork(handleSetCommentList),
        fork(handleSetAttachedFileList),
        fork(handleSetTitle),
        fork(handleContributorList),
        fork(handleSetGrade),
        fork(handleGetVote),
        fork(handleSetDrawingList),
        fork(handleGetEvaluationList),
        fork(getProposalArrangementPlanList),
        fork(handleSetArrangementCommentList),
        fork(handleSetArrangementAttachedFileList),
        fork(getProposalSubmissionCancel),
      ]);
    }
  } catch (e) {
    const message = getErrorMessage(proposalAction.setId, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchUpdateTitle() {
  while (true) {
    const { payload: params } = yield take(proposalAction.updateTitle);
    const { id, filter } = yield select((root: RootState) => root.proposal);
    try {
      yield call(proposalApi.updateTitle, params, id);
      yield call(handleSetTitle);
      if (params.title) {
        yield put(proposalAction.setFilter(filter));
      }
    } catch (e) {
      const message = getErrorMessage(proposalAction.updateTitle, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* handleSetTitle() {
  const { id } = yield select((root: RootState) => root.proposal);
  try {
    const title: string = yield call(proposalApi.getProposalTitle, id);
    yield put(proposalAction.setTitle(title));
  } catch (e) {
    const message = getErrorMessage(proposalAction.updateTitle, e);
    yield put(dialogAction.openError(message));
  }
}

/**
 * 제안 즐겨찾기 관련 saga
 */
function* watchUpdateFavorite() {
  // while (true) {
  //   // const {} = yield take(proposalAction.updateFavorite);
  //   const { filter } = yield select((root: RootState) => root.proposal);
  //   try {
  //     // TODO: api만 교체 필요
  //     // yield call(proposalApi.updateFavorite, id, params);
  //     // yield put(proposalAction.setId(id));
  //     yield put(proposalAction.setFilter(filter));
  //   } catch (e) {
  //     const message = getErrorMessage(proposalAction.updateFavorite, e);
  //     yield put(dialogAction.openError(message));
  //   }
  // }
}

/**
 * 제안 삭제 관련 saga
 */
function* watchDeleteDetail() {
  while (true) {
    const { payload: id } = yield take(proposalAction.deleteDetail);
    try {
      yield put(proposalAction.requestDelete('request'));
      yield call(proposalApi.deleteDetail, id);
      yield put(dialogAction.openAlert('삭제하였습니다.'));
      yield put(proposalAction.requestDelete('done'));
    } catch (e) {
      const message = getErrorMessage(proposalAction.deleteDetail, e);
      yield put(dialogAction.openError(message));
    }
  }
}

/**
 * 내용 관련 saga
 */
function* watchAddContent() {
  while (true) {
    const { payload: params } = yield take(proposalAction.addContent);
    const { id } = yield select((root: RootState) => root.proposal);
    try {
      yield call(proposalApi.addContent, id, params);
      yield call(handleSetContentList);
    } catch (e) {
      const message = getErrorMessage(proposalAction.addContent, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* handleSetContentList() {
  const { id } = yield select((root: RootState) => root.proposal);
  try {
    const contentList: ProposalContentVO[] = yield call(proposalApi.getContentList, id);
    yield put(proposalAction.setContentList(contentList));
  } catch (e) {
    const message = getErrorMessage(proposalAction.setContentList, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchDeleteContent() {
  while (true) {
    const { payload: contentId } = yield take(proposalAction.deleteContent);
    const { id } = yield select((root: RootState) => root.proposal);
    try {
      yield call(proposalApi.deleteContent, id, contentId);
      yield call(handleSetContentList);
    } catch (e) {
      const message = getErrorMessage(proposalAction.deleteContent, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchUpdateContent() {
  while (true) {
    const { payload: params } = yield take(proposalAction.updateContent);
    const { id } = yield select((root: RootState) => root.proposal);
    try {
      yield call(proposalApi.updateContent, id, params);
      yield call(handleSetContentList);
    } catch (e) {
      const message = getErrorMessage(proposalAction.updateContent, e);
      yield put(dialogAction.openError(message));
    }
  }
}

/**
 * 드로잉 관련 saga
 */
function* watchAddDrawing() {
  while (true) {
    const { payload: params } = yield take(proposalAction.addDrawing);
    const { id } = yield select((root: RootState) => root.proposal);
    try {
      yield call(proposalApi.addDrawing, id, params);
      yield call(handleSetDrawingList);
    } catch (e) {
      const message = getErrorMessage(proposalAction.addDrawing, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* handleSetDrawingList() {
  const { id } = yield select((root: RootState) => root.proposal);
  try {
    const drawingList: DrawingVO[] = yield call(proposalApi.getDrawingList, id);
    yield put(proposalAction.setDrawingList(drawingList));
  } catch (e) {
    const message = getErrorMessage(proposalAction.setDrawingList, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchDeleteDrawing() {
  while (true) {
    const { payload: drawingId } = yield take(proposalAction.deleteDrawing);
    try {
      yield call(proposalApi.deleteDrawing, drawingId);
      yield call(handleSetDrawingList);
      yield put(dialogAction.openAlert('삭제하였습니다.'));
    } catch (e) {
      const message = getErrorMessage(proposalAction.deleteDrawing, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchUpdateDrawing() {
  while (true) {
    const { payload: params } = yield take(proposalAction.updateDrawing);
    try {
      yield call(proposalApi.updateDrawing, params.id, params.stringifiedData);
      yield call(handleSetDrawingList);
    } catch (e) {
      const message = getErrorMessage(proposalAction.updateDrawing, e);
      yield put(dialogAction.openError(message));
    }
  }
}

/**
 * 첨부 파일 관련 saga
 */
function* watchAddAttachedFile() {
  while (true) {
    const { payload: params } = yield take(proposalAction.addAttachedFile);
    const { id } = yield select((root: RootState) => root.proposal);
    try {
      yield call(proposalApi.addAttachedFile, params, id);
      yield call(handleSetAttachedFileList);
    } catch (e) {
      const message = getErrorMessage(proposalAction.addAttachedFile, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* handleSetAttachedFileList() {
  const { id } = yield select((root: RootState) => root.proposal);
  try {
    const fileList: AttachFileVO[] = yield call(proposalApi.getAttachedFileList, id);
    yield put(proposalAction.setAttachedFileList(fileList));
  } catch (e) {
    const message = getErrorMessage(proposalAction.setAttachedFileList, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchDeleteAttachedFile() {
  while (true) {
    const { payload: attachedFileId } = yield take(proposalAction.deleteAttachedFile);
    try {
      yield call(proposalApi.deleteAttachedFile, attachedFileId);
      yield call(handleSetAttachedFileList);
      yield put(dialogAction.openAlert('삭제하였습니다.'));
    } catch (e) {
      const message = getErrorMessage(proposalAction.deleteAttachedFile, e);
      yield put(dialogAction.openError(message));
    }
  }
}

/**
 * 댓글 관련 saga
 */
function* watchAddComment() {
  while (true) {
    const { payload: params } = yield take(proposalAction.addComment);
    const { id } = yield select((root: RootState) => root.proposal);
    try {
      yield call(proposalApi.addComment, params, id);
      yield call(handleSetCommentList);
      if (params.parentId) {
        yield put(proposalAction.setCommentParentId(undefined));
        yield put(proposalAction.setReplyCommentParentId(undefined));
      }
    } catch (e) {
      const message = getErrorMessage(proposalAction.addComment, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* handleSetCommentList() {
  const { id } = yield select((root: RootState) => root.proposal);
  const currentPage = yield select((root: RootState) => root.navigation.currentPage?.targetPage);
  try {
    if (currentPage === TargetPage.ARRANGEMENT) {
      return;
    }
    const commentList: CommentVO[] = yield call(proposalApi.getCommentList, id, currentPage);
    yield put(proposalAction.setCommentList(commentList));
  } catch (e) {
    const message = getErrorMessage(proposalAction.setCommentList, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchUpdateComment() {
  while (true) {
    const { payload: params } = yield take(proposalAction.updateComment);
    try {
      yield call(proposalApi.updateComment, params);
      yield call(handleSetCommentList);
    } catch (e) {
      const message = getErrorMessage(proposalAction.updateComment, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchDeleteComment() {
  while (true) {
    const { payload: commentId } = yield take(proposalAction.deleteComment);
    try {
      yield call(proposalApi.deleteComment, commentId);
      yield call(handleSetCommentList);
      yield put(dialogAction.openAlert('삭제하였습니다.'));
    } catch (e) {
      const message = getErrorMessage(proposalAction.deleteComment, e);
      yield put(dialogAction.openError(message));
    }
  }
}

/**
 * 메모 관련 saga
 */
function* watchAddMemo() {
  while (true) {
    const { payload: params } = yield take(proposalAction.addMemo);
    const { id, memoFilter } = yield select((root: RootState) => root.proposal);
    try {
      yield call(proposalApi.addMemo, id, params);
      // yield put(proposalAction.setId(id));
      yield put(proposalAction.setMemoFilter(memoFilter));
      if (Array.isArray(params.attendanceList) && params.attendanceList.length > 0) {
        yield put(userNotificationAction.requestCount());
      }
    } catch (e) {
      const message = getErrorMessage(proposalAction.addMemo, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchMemoFilter() {
  while (true) {
    const { payload: params } = yield take(proposalAction.setMemoFilter);
    yield put(proposalAction.setMemoLoading(true));
    const { id } = yield select((root: RootState) => root.proposal);
    try {
      const list = yield call(proposalApi.getMemoList, id, params);
      yield put(proposalAction.setMemoList(list));
    } catch (e) {
      const message = getErrorMessage(proposalAction.setMemoFilter, e);
      const code = getErrorCode(voteAction.setMemoFilter, e);
      if (code === CustomHttpStatus.NO_AUTHORIZATION) {
        yield put(dialogAction.openBlock(getCustomErrorMessage(CustomHttpStatus.NO_AUTHORIZATION)));
        return;
      }
      yield put(dialogAction.openError(message));
    } finally {
      yield put(proposalAction.setMemoLoading(false));
    }
  }
}

function* watchMemoChange() {
  while (true) {
    const { payload: params } = yield take(proposalAction.changeMemo);
    const { id, memoFilter } = yield select((root: RootState) => root.proposal);
    try {
      yield call(proposalApi.changeMemo, id, params);
      yield put(proposalAction.setMemoFilter(memoFilter));
    } catch (e) {
      const message = getErrorMessage(proposalAction.addMemo, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchMemoDelete() {
  while (true) {
    const { payload: memoId } = yield take(proposalAction.deleteMemo);
    const { id, memoFilter } = yield select((root: RootState) => root.proposal);
    try {
      yield call(proposalApi.deleteMemo, id, memoId);
      yield put(proposalAction.setMemoFilter(memoFilter));
    } catch (e) {
      const message = getErrorMessage(proposalAction.deleteMemo, e);
      yield put(dialogAction.openError(message));
    }
  }
}

/**
 * 참여자 관련 saga
 */
function* watchAddContributor() {
  while (true) {
    yield take(proposalAction.addContributor);
    const { id } = yield select((root: RootState) => root.proposal);
    try {
      if (id) {
        yield call(proposalApi.addContributor, id);
        yield call(handleContributorList);
      }
    } catch (e) {
      const message = getErrorMessage(proposalAction.addContributor, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* handleContributorList() {
  const { id } = yield select((root: RootState) => root.proposal);
  try {
    const list: ProposalContributorVO[] = yield call(proposalApi.getContributorList, id);
    yield put(proposalAction.setContributorList(list));
  } catch (e) {
    const message = getErrorMessage(proposalAction.setContributorList, e);
    yield put(dialogAction.openError(message));
  }
}

/**
 * 기여자 삭제
 */
function* watchDeleteContributor() {
  yield takeLatest(proposalAction.deleteContributor, handleDeleteContributor);
}

function* handleDeleteContributor({ payload }) {
  try {
    const { id } = yield select((root: RootState) => root.proposal);
    yield call(proposalApi.deleteContributor, id, payload);
    yield call(handleContributorList);
  } catch (e) {
    const message = getErrorMessage(proposalAction.deleteContributor, e);
    yield put(dialogAction.openError(message));
  }
}

/**
 *  기여자 업데이트
 */
function* watchUpdateContributor() {
  yield takeLatest(proposalAction.updateContributor, handleUpdateContributor);
}

function* handleUpdateContributor({ payload }) {
  try {
    const { id } = yield select((root: RootState) => root.proposal);
    yield call(proposalApi.updateContributor, id, payload);
    yield call(handleContributorList);
  } catch (e) {
    const message = getErrorMessage(proposalAction.updateContributor, e);
    yield put(dialogAction.openError(message));
  }
}

/**
 * 제안 구분 목록 관련 saga
 */

function* setProposalCategoryList({ payload }) {
  const campaignList = yield select((root: RootState) => root.campaign.campaignListUnderOnGoing);
  const proposalCategoryList =
    campaignList.find((item) => item.id === payload)?.proposalCategoryList ?? [];
  yield put(proposalAction.setProposalCategoryList(proposalCategoryList));
}

function* watchSetCampaignId() {
  yield takeLatest(proposalAction.setCampaignId, setProposalCategoryList);
}

/**
 * 제안 등급 관련 saga
 */
function* watchUpdateGrade() {
  yield takeLatest(proposalAction.updateGrade, handleUpdateGrade);
}

function* handleUpdateGrade({ payload }) {
  try {
    const { id } = yield select((root: RootState) => root.proposal);
    yield call(proposalApi.updateGradeByContributor, id, payload);
    yield call(handleSetGrade);
  } catch (e) {
    const message = getErrorMessage(proposalAction.updateGrade, e);
    yield put(dialogAction.openError(message));
  }
}

function* handleSetGrade() {
  try {
    const { id } = yield select((root: RootState) => root.proposal);
    const grade: ProposalGrade = yield call(proposalApi.getGradeByContributor, id);
    yield put(proposalAction.setGrade(grade));
  } catch (e) {
    const message = getErrorMessage(proposalAction.setGrade, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchSubmit() {
  yield takeLatest(proposalAction.submit, handleSubmit);
}

function* handleSubmit() {
  try {
    const { id, filter } = yield select((root: RootState) => root.proposal);
    yield call(proposalApi.submitProposal, id);
    yield put(proposalAction.setFilter(filter));
    // yield put(proposalAction.setProposalStatus(ProposalStatus.AGREEMENT));
  } catch (e) {
    const message = getErrorMessage(proposalAction.submit, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchUpdateStatus() {
  yield takeLatest(proposalAction.updateProposalStatus, handleUpdateStatus);
}

function* handleUpdateStatus({ payload }) {
  try {
    const { id, filter } = yield select((root: RootState) => root.proposal);
    const { voteFilter } = yield select((root: RootState) => ({ voteFilter: root.vote.filter }));
    const currentPage = yield select((root: RootState) => root.navigation.currentPage);
    yield call(proposalApi.updateStatus, id, payload);
    if (currentPage.targetPage === TargetPage.EVALUATION) {
      yield put(evaluationAction.setFilter(filter));
    }
    if (currentPage.targetPage === TargetPage.PROPOSAL) {
      yield put(proposalAction.setFilter(filter));
    }
    if (currentPage.targetPage === TargetPage.VOTE) {
      yield put(voteAction.setFilter(voteFilter));
    }
    yield put(proposalAction.setProposalStatus(payload.status));
    yield call(handleGetEvaluationList);
  } catch (e) {
    const message = getErrorMessage(proposalAction.updateProposalStatus, e);
    yield put(dialogAction.openError(message));
  }
}

/**
 * 투찰
 */
function* watchVote() {
  yield takeLatest(voteAction.add, handleVote);
}

function* handleVote({ payload }) {
  try {
    const id = yield select((root: RootState) => root.proposal.id);
    const filter = yield select((root: RootState) => root.vote.filter);
    yield call(voteApi.addVote, id, payload);
    yield call(handleGetVote);
    yield call(handleVoteMessage);
    yield call(handleGetResult);
    yield put(voteAction.setFilter(filter));
  } catch (e) {
    const message = getErrorMessage(voteAction.add, e);
    yield put(dialogAction.openError(message));
  }
}

function* handleGetVote() {
  try {
    const id = yield select((root: RootState) => root.proposal.id);
    const vote: VoteVO = yield call(voteApi.getVote, id);
    yield put(voteAction.setVote(vote));
  } catch (e) {
    const message = getErrorMessage(voteAction.setVote, e);
    yield put(dialogAction.openError(message));
  }
}

function* handleVoteMessage() {
  const seq = yield select((root: RootState) => root.vote.detail?.seq);
  yield put(dialogAction.openAlert(`${seq} 번째로 투찰하셨습니다.`));
}

function* handleGetResult() {
  const id = yield select((root: RootState) => root.proposal.id);
  try {
    // const result: number = yield call(proposalApi.getProposalResult, id);
    // if (result) {
    //   yield put(proposalAction.setProposalResult(result));
    //   yield put(proposalAction.setProposalStatus(ProposalStatus.END));
    // }
    yield call(watchId, { payload: id });
  } catch (e) {
    console.error(e);
  }
}

function* watchGetPermission() {
  yield takeLatest(proposalAction.requestPermission, handleGetPermission);
}

function* handleGetPermission() {
  const id = yield select((root: RootState) => root.proposal.id);
  const currentPage = yield select((root: RootState) => root.navigation.currentPage);
  try {
    if (currentPage) {
      yield call(proposalApi.getPermission, id, currentPage);
    }
  } catch (e) {
    const message = getErrorMessage(proposalAction.requestPermission, e);
    const code = getErrorCode(proposalAction.requestPermission, e);
    if (code === CustomHttpStatus.NO_AUTHORIZATION) {
      yield put(dialogAction.openBlock(getCustomErrorMessage(CustomHttpStatus.NO_AUTHORIZATION)));
      return;
    }
    yield put(dialogAction.openError(message));
  }
}

/**
 * 평가 등급
 */

function* watchEvaluationGrade() {
  yield takeLatest(proposalAction.updateGradeByEstimator, updateGradeByEstimator);
}

function* updateGradeByEstimator({
  payload: parameter,
}: {
  payload: ProposalEstimatorUpdateGradeParameter;
}) {
  const id = yield select((root: RootState) => root.proposal.id);
  const detail = yield select((root: RootState) => root.proposal.detail);
  const filter = yield select((root: RootState) => root.proposal.filter);
  const estimatorInfoList = yield select((root: RootState) =>
    root.proposal.estimatorList.filter(
      (estimatorInfo) =>
        !detail.contributorViewList
          .filter((cv) => cv.contributor)
          .map((cv) => cv.contributor.id)
          .includes(estimatorInfo.estimator.id)
    )
  );
  const estimationCompletionRateByCampaign = yield select(
    (root: RootState) => root.proposal.detail?.campaign.estimationCompletionRate
  );
  try {
    yield call(proposalApi.updateEvaluationGrade, id, parameter);
    yield call(handleGetEvaluationList);
    yield put(userNotificationAction.requestCount());
    const estimatorInfoListSize = estimatorInfoList.length;
    const estimatedProposalSize =
      estimatorInfoList.filter((estimatorInfo) => estimatorInfo.grade !== null).length + 1;
    const estimationCompletedRate = (estimatedProposalSize / estimatorInfoListSize) * 100;
    if (estimationCompletionRateByCampaign > estimationCompletedRate && parameter.grade) {
      yield put(dialogAction.openAlert('심사가 완료됐습니다.'));
      yield put(proposalAction.setId(id));
    } else if (estimationCompletionRateByCampaign <= estimationCompletedRate && parameter.grade) {
      yield put(dialogAction.openAlert('결재 단계로 넘어갑니다.'));
      yield put(proposalAction.setId(id));
      yield put(evaluationAction.setFilter(filter));
    }
  } catch (e) {
    const message = getErrorMessage(proposalAction.updateGradeByEstimator, e);
    yield put(dialogAction.openError(message));
  }
}

/**
 * 평가 목록
 */
function* handleGetEvaluationList() {
  const id = yield select((root: RootState) => root.proposal.id);
  const currentPage = yield select((root: RootState) => root.navigation.currentPage);
  if (typeof currentPage === 'undefined') {
    return;
  }
  try {
    if (currentPage.targetPage === TargetPage.EVALUATION) {
      const estimatorList = yield call(proposalApi.getEstimatorList, id);
      yield put(proposalAction.setEstimatorInfoList(estimatorList));
    }
  } catch (e) {
    const message = getErrorMessage(proposalAction.setEstimatorInfoList, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchEndVote() {
  yield takeLatest(proposalAction.endVote, endVote);
}

function* endVote() {
  const { id, filter } = yield select((root) => ({
    id: root.proposal.id,
    filter: root.vote.filter,
  }));
  try {
    yield call(proposalApi.endVote, id);
    yield put(proposalAction.setId(id));
    yield put(voteAction.setFilter(filter));
  } catch (e) {
    const message = getErrorMessage(proposalAction.endVote, e);
    yield put(dialogAction.openError(message));
  }
}

function* requestAdvisor() {
  const { id } = yield select((root) => ({
    id: root.proposal.id,
  }));
  try {
    yield call(proposalApi.requestAdvisor, id);
    yield put(proposalAction.setId(id));
  } catch (e) {
    const message = getErrorMessage(proposalAction.requestAdvisor, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchRequestAdvisor() {
  yield takeLatest(proposalAction.requestAdvisor, requestAdvisor);
}

function* updateProposalArrangementStatus({
  payload: params,
}: {
  payload: ProposalArrangementInformationStatusParameter;
}) {
  const { id, filter } = yield select((root) => ({
    id: root.proposal.id,
    filter: root.arrangement.filter,
  }));
  try {
    yield call(proposalApi.updateProposalArrangementInformationStatus, id, params);
    yield put(proposalAction.setId(id));
    if (params.status === ProposalArrangementInformationStatus.SKIP) {
      yield put(dialogAction.openAlert('합의 단계를 생략하셨습니다.'));
    } else {
      yield put(arrangementAction.setFilter(filter));
    }
  } catch (e) {
    const message = getErrorMessage(proposalAction.updateProposalArrangementInformationStatus, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchProposalArrangementInformationStatus() {
  yield takeLatest(
    proposalAction.updateProposalArrangementInformationStatus,
    updateProposalArrangementStatus
  );
}

function* getAdvisor({
  payload: params,
}: {
  payload: ProposalUpdateArrangementInformationAdvisorParameter;
}) {
  const { id } = yield select((root) => ({
    id: root.proposal.id,
  }));
  const { detail } = yield select((root) => ({
    detail: root.proposal.detail,
  }));
  try {
    yield call(proposalApi.updateProposalArrangementInformationAdvisor, id, params);
    yield put(proposalAction.setId(id));
    yield put(
      dialogAction.openAlert(
        `${detail.advisor ? '협의자가 변경됐습니다.' : '협의자가 지정됐습니다.'}`
      )
    );
  } catch (e) {
    const message = getErrorMessage(proposalAction.setAdvisor, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchProposalArrangementInformationAdvisor() {
  yield takeLatest(proposalAction.setAdvisor, getAdvisor);
}

/**
 * 협의 계획
 */

function* getProposalArrangementPlanList() {
  const { id } = yield select((root: RootState) => root.proposal);
  try {
    const planList: ProposalArrangementPlanVO[] = yield call(
      proposalApi.getProposalArrangementPlanList,
      id
    );
    yield put(proposalAction.setProposalArrangementPlanList(planList));
  } catch (e) {
    const message = getErrorMessage(proposalAction.setProposalArrangementPlanList, e);
    yield put(dialogAction.openError(message));
  }
}

function* addProposalArrangementPlan() {
  const { id } = yield select((root) => ({
    id: root.proposal.id,
  }));
  try {
    yield call(proposalApi.addProposalArrangementPlan, id);
    yield put(proposalAction.setId(id));
  } catch (e) {
    const message = getErrorMessage(proposalAction.addProposalArrangementPlan, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchAddProposalArrangementPlan() {
  yield takeLatest(proposalAction.addProposalArrangementPlan, addProposalArrangementPlan);
}

function* updateProposalArrangementPlan({
  payload: params,
}: {
  payload: ProposalUpdateArrangementPlanParameter;
}) {
  const { id } = yield select((root) => ({
    id: root.proposal.id,
  }));
  try {
    yield call(proposalApi.updateProposalArrangementPlan, id, params);
    yield call(getProposalArrangementPlanList);
  } catch (e) {
    const message = getErrorMessage(proposalAction.updateProposalArrangementPlan, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchUpdateProposalArrangementPlan() {
  yield takeLatest(proposalAction.updateProposalArrangementPlan, updateProposalArrangementPlan);
}

function* deleteProposalArrangementPlan({
  payload: planId,
}: {
  payload: ProposalArrangementPlanId;
}) {
  const { id } = yield select((root) => ({
    id: root.proposal.id,
  }));
  try {
    yield call(proposalApi.deleteProposalArrangementPlan, id, planId);
    yield call(getProposalArrangementPlanList);
  } catch (e) {
    const message = getErrorMessage(proposalAction.deleteProposalArrangementPlan, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchDeleteProposalArrangementPlan() {
  yield takeLatest(proposalAction.deleteProposalArrangementPlan, deleteProposalArrangementPlan);
}

/**
 * 협의 댓글 관련 saga
 */
function* watchAddArrangementComment() {
  while (true) {
    const { payload: params } = yield take(proposalAction.addArrangementComment);
    const { id } = yield select((root: RootState) => root.proposal);
    try {
      yield call(proposalApi.addArrangementComment, params, id);
      yield call(handleSetArrangementCommentList);
      if (params.parentId) {
        yield put(proposalAction.setArrangementCommentParentId(undefined));
        yield put(proposalAction.setReplyArrangementCommentParentId(undefined));
      }
    } catch (e) {
      const message = getErrorMessage(proposalAction.addArrangementComment, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* handleSetArrangementCommentList() {
  const { id } = yield select((root: RootState) => root.proposal);
  try {
    const arrangementCommentList: ArrangementCommentVO[] = yield call(
      proposalApi.getArrangementCommentList,
      id
    );
    yield put(proposalAction.setArrangementCommentList(arrangementCommentList));
  } catch (e) {
    const message = getErrorMessage(proposalAction.setArrangementCommentList, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchUpdateArrangementComment() {
  while (true) {
    const { payload: params } = yield take(proposalAction.updateArrangementComment);
    try {
      yield call(proposalApi.updateArrangementComment, params);
      yield call(handleSetArrangementCommentList);
    } catch (e) {
      const message = getErrorMessage(proposalAction.updateArrangementComment, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchDeleteArrangementComment() {
  while (true) {
    const { payload: arrangementCommentId } = yield take(proposalAction.deleteArrangementComment);
    try {
      yield call(proposalApi.deleteArrangementComment, arrangementCommentId);
      yield call(handleSetArrangementCommentList);
      yield put(dialogAction.openAlert('삭제하였습니다.'));
    } catch (e) {
      const message = getErrorMessage(proposalAction.deleteArrangementComment, e);
      yield put(dialogAction.openError(message));
    }
  }
}

/**
 * 첨부 파일 관련 saga
 */
function* watchAddArrangementAttachedFile() {
  while (true) {
    const { payload: params } = yield take(proposalAction.addArrangementAttachedFile);
    const { id } = yield select((root: RootState) => root.proposal);
    try {
      yield call(proposalApi.addArrangementAttachedFile, params, id);
      yield call(handleSetArrangementAttachedFileList);
    } catch (e) {
      const message = getErrorMessage(proposalAction.addArrangementAttachedFile, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* handleSetArrangementAttachedFileList() {
  const { id } = yield select((root: RootState) => root.proposal);
  try {
    const fileList: ArrangementAttachFileVO[] = yield call(
      proposalApi.getArrangementAttachedFileList,
      id
    );
    yield put(proposalAction.setArrangementAttachedFileList(fileList));
  } catch (e) {
    const message = getErrorMessage(proposalAction.setArrangementAttachedFileList, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchDeleteArrangementAttachedFile() {
  while (true) {
    const { payload: attachedFileId } = yield take(proposalAction.deleteArrangementAttachedFile);
    try {
      yield call(proposalApi.deleteArrangementAttachedFile, attachedFileId);
      yield call(handleSetArrangementAttachedFileList);
      yield put(dialogAction.openAlert('삭제하였습니다.'));
    } catch (e) {
      const message = getErrorMessage(proposalAction.deleteArrangementAttachedFile, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* addProposalSubmissionCancelReason({
  payload: params,
}: {
  payload: ProposalSubmissionCancelReasonParameter;
}) {
  const { id } = yield select((root: RootState) => root.proposal);
  try {
    yield call(proposalApi.addProposalSubmissionCancelReason, id, params);
    yield put(proposalAction.setProposalSubmissionCancelModal(false));
    yield put(dialogAction.openAlert('제출 회수 사유를 작성하였습니다.'));
  } catch (e) {
    const message = getErrorMessage(proposalAction.addProposalSubmissionCancelReason, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchAddProposalSubmissionCancelReason() {
  yield takeLatest(
    proposalAction.addProposalSubmissionCancelReason,
    addProposalSubmissionCancelReason
  );
}

function* agreeProposalSubmissionCancel({ payload: params }: { payload: boolean }) {
  const { id } = yield select((root: RootState) => root.proposal);
  const filter = yield select((root: RootState) => root.evaluation.filter);
  try {
    yield call(proposalApi.agreeProposalSubmissionCancel, id, params);
    yield put(proposalAction.setId(id));
    yield put(evaluationAction.setFilter(filter));
  } catch (e) {
    const message = getErrorMessage(proposalAction.agreeProposalSubmissionCancel, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchAgreeProposalSubmissionCancelReason() {
  yield takeLatest(proposalAction.agreeProposalSubmissionCancel, agreeProposalSubmissionCancel);
}

function* getProposalSubmissionCancel() {
  const { id } = yield select((root: RootState) => root.proposal);
  try {
    const proposalCancelRequest = yield call(proposalApi.getProposalSubmissionCancel, id);
    yield put(proposalAction.setProposalSubmissionCancel(proposalCancelRequest));
  } catch (e) {
    const message = getErrorMessage(proposalAction.getProposalSubmissionCancel, e);
    yield put(dialogAction.openError(message));
  }
}

/**
 * 결재 직접 상신 등록
 */

function* watchAddReportOpinion() {
  yield takeLatest(proposalAction.addApprovalDocument, handleAddApprovalDocument);
}

function* handleAddApprovalDocument({ payload: params }: { payload: ApprovalDocumentParameter }) {
  const { id, filter } = yield select((root: RootState) => root.proposal);
  try {
    yield call(proposalApi.addApprovalDocument, params);
    yield put(proposalAction.setApprovalDocumentModal(false));
    yield put(evaluationAction.setFilter(filter));
    const detail: ProposalVO = yield call(proposalApi.getOne, id);
    yield put(proposalAction.setDetail(detail));
  } catch (e) {
    const message = getErrorMessage(proposalAction.addApprovalDocument, e);
    yield put(dialogAction.openError(message));
  }
}

function* handleGetApprovalDocument() {
  const { id } = yield select((root: RootState) => root.proposal);
  try {
    const approvalDocument: ProposalApprovalDocumentVO = yield call(
      proposalApi.getApprovalDocument,
      id
    );
    yield put(proposalAction.setApprovalDocument(approvalDocument));
  } catch (e) {
    const message = getErrorMessage(proposalAction.getApprovalDocument, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchGetApprovalDocument() {
  yield takeLatest(proposalAction.getApprovalDocument, handleGetApprovalDocument);
}

function* handleGetApprovalList() {
  const { id } = yield select((root: RootState) => root.proposal);
  try {
    const approvalList: ProposalApprovalVO[] = yield call(proposalApi.getApprovalList, id);
    yield put(proposalAction.setApprovalList(approvalList));
  } catch (e) {
    const message = getErrorMessage(proposalAction.getApprovalList, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchGetApprovalList() {
  yield takeLatest(proposalAction.getApprovalList, handleGetApprovalList);
}

/**
 * 결재
 */
function* handleUpdateProposalApproval({
  payload: params,
}: {
  payload: ProposalApprovalParameter;
}) {
  const { id } = yield select((root: RootState) => root.proposal);
  const filter = yield select((root: RootState) => root.evaluation.filter);
  try {
    yield call(proposalApi.updateProposalApproval, params);
    yield put(proposalAction.setApprovalModal(false));
    if (params.result === ApprovalResult.REJECTED || params.result === ApprovalResult.APPROVED) {
      yield put(proposalAction.setId(id));
      yield put(evaluationAction.setFilter(filter));
    }
    yield put(dialogAction.openAlert('결재 했습니다.'));
  } catch (e) {
    const message = getErrorMessage(proposalAction.updateProposalApproval, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchUpdateProposalApproval() {
  yield takeLatest(proposalAction.updateProposalApproval, handleUpdateProposalApproval);
}

export default function* proposalSaga() {
  yield fork(watchAdd);
  yield fork(watchFilter);
  yield fork(watchAddComment);
  yield fork(watchDeleteComment);
  yield fork(watchUpdateComment);

  yield fork(watchAddAttachedFile);
  yield fork(watchDeleteAttachedFile);

  yield fork(watchAddDrawing);
  yield fork(watchDeleteDrawing);
  yield fork(watchUpdateDrawing);

  yield fork(watchUpdateTitle);
  yield fork(watchUpdateFavorite);
  yield fork(watchDeleteDetail);
  yield fork(watchAddMemo);
  yield fork(watchMemoFilter);
  yield fork(watchMemoChange);
  yield fork(watchMemoDelete);

  yield fork(watchAddContent);

  yield fork(watchDeleteContent);
  yield fork(watchUpdateContent);
  yield fork(watchAddContributor);
  yield fork(watchSetCampaignId);
  yield fork(watchDeleteContributor);
  yield fork(watchUpdateContributor);
  yield fork(watchUpdateGrade);
  yield fork(watchSubmit);
  yield fork(watchUpdateStatus);
  yield fork(watchVote);

  yield fork(watchEvaluationGrade);
  yield fork(watchGetPermission);
  yield fork(watchAddReportOpinion);

  yield fork(watchEndVote);

  yield fork(watchRequestAdvisor);
  yield fork(watchProposalArrangementInformationStatus);
  yield fork(watchProposalArrangementInformationAdvisor);

  /**
   * 협의 계획
   */
  yield fork(watchAddProposalArrangementPlan);
  yield fork(watchUpdateProposalArrangementPlan);
  yield fork(watchDeleteProposalArrangementPlan);

  /**
   * 협의 댓글
   */
  yield fork(watchAddArrangementComment);
  yield fork(watchUpdateArrangementComment);
  yield fork(watchDeleteArrangementComment);

  yield fork(watchAddArrangementAttachedFile);
  yield fork(watchDeleteArrangementAttachedFile);
  /**
   * 제안 회수 요청
   */
  yield fork(watchAddProposalSubmissionCancelReason);

  /**
   * 제안 회수 허용 / 불허
   */
  yield fork(watchAgreeProposalSubmissionCancelReason);

  /**
   * 기안 문서 get
   */
  yield fork(watchGetApprovalDocument);

  /**
   * 결재 리스트 get
   */
  yield fork(watchGetApprovalList);

  /**
   * 결재
   */
  yield fork(watchUpdateProposalApproval);
  yield all([takeLatest(proposalAction.setId, watchId)]);
}
