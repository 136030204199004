import React from 'react';
import ModalUI from '@front/src/components/components-with-design/layout/ModalUI';
import WorkZoomImage from '@front/src/features/work/features/work/components/ZoomImage';
import type { CommonImageView } from '@front/src/features/work/features/work/components/ZoomImageButton';

interface Props<T> {
  open: boolean;
  onClose: () => void;
  item: T;
}

export default function WorkZoomImageModal<T extends CommonImageView>({
  open,
  onClose,
  item,
}: Readonly<Props<T>>) {
  return (
    <ModalUI
      title="크게 보기"
      open={open}
      onClose={onClose}
    >
      <WorkZoomImage<T>
        item={item}
        onClose={onClose}
      />
    </ModalUI>
  );
}
