import { default as axios } from '@front/src/config/axios';

import { affiliatedPersonUrl } from '@front/src/features/affiliated-person/utils';
import type {
  AffiliatedPersonCreateParameter,
  AffiliatedPersonGetListByPersonNameParameter,
} from '@front/src/features/affiliated-person/features/app-bar/types/parameter';
import type { AffiliatedPersonCompanyListGetParameter } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/types/parameter';
import type {
  AffiliatedPersonMemoCreateParameter,
  AffiliatedPersonMemoListGetParameter,
} from '@front/src/features/affiliated-person/features/memo/types/parameter';
import type { AffiliatedPersonGetListParameter } from '@front/src/features/affiliated-person/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

export { api as affiliatedPersonApi };
export { memoApi as affiliatedPersonMemoApi };

const affiliatedPersonMemoUrl = '/api/affiliated-person';

const url = {
  create: () => `${affiliatedPersonUrl}`,
  getList: () => `${affiliatedPersonUrl}`,
  getListByPersonName: () => `${affiliatedPersonUrl}/by-person-name`,
  getAffiliatedCompanyList: () => `${affiliatedPersonUrl}/company-list`,
  updateCompany: (id) => `${affiliatedPersonUrl}/${id}`,
  getSalesActivityList: (id) => `${affiliatedPersonUrl}/${id}/sales-activity`,
};

const memoUrl = {
  create: (personId) => `${affiliatedPersonMemoUrl}/${personId}/memo`,
  getList: (personId) => `${affiliatedPersonMemoUrl}/${personId}/memo`,
  update: (personId, memoId) => `${affiliatedPersonMemoUrl}/${personId}/memo/${memoId}`,
  delete: (personId, memoId) => `${affiliatedPersonMemoUrl}/${personId}/memo/${memoId}`,
};

const api = {
  create: async (params: AffiliatedPersonCreateParameter, menuId) => {
    const { data } = await axios.post(url.create(), params, {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getList: async (params: AffiliatedPersonGetListParameter, menuId) =>
    await axios.get(url.getList(), { params, headers: getMenuIdHeaders(menuId) }),
  getListByPersonName: async (params: AffiliatedPersonGetListByPersonNameParameter, menuId) => {
    const { data } = await axios.get(url.getListByPersonName(), {
      params,
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getAffiliatedCompanyList: async (params: AffiliatedPersonCompanyListGetParameter, menuId) => {
    const { data } = await axios.get(url.getAffiliatedCompanyList(), {
      params,
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updateCompany: async (params, menuId) => {
    await axios.patch(url.updateCompany(params.personId), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  getSalesActivityList: async (id, menuId) =>
    await axios.get(url.getSalesActivityList(id), {
      headers: getMenuIdHeaders(menuId),
    }),
};

const memoApi = {
  create: async (params: AffiliatedPersonMemoCreateParameter, menuId) => {
    await axios.post(memoUrl.create(params.personId), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  getList: async (params: AffiliatedPersonMemoListGetParameter, menuId) => {
    const { data } = await axios.get(memoUrl.getList(params.personId), {
      params,
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  update: async (params, menuId) => {
    await axios.put(memoUrl.update(params.personId, params.memoId), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  delete: async (params) => {
    await axios.delete(memoUrl.delete(params.personId, params.memoId), params);
  },
};
