import { default as axios } from '@front/src/config/axios';
import {
  accountingProjectSalesInfoAmountUrl,
  accountingProjectSalesInfoUrl,
} from '@front/src/features/accounting/features/upload/utils/constant';
import type {
  SalesAccountingAmountInformationGetParameter,
  SalesAccountingAmountInformationParameter,
} from '@front/src/features/accounting/features/upload/features/sales-info/types/parameter';

export { Api as AccountingUploadSalesInfoApi };

const url = {
  getList: () => `${accountingProjectSalesInfoUrl}`,
  update: () => `${accountingProjectSalesInfoUrl}`,
  getExpectOrPerformanceList: () => `${accountingProjectSalesInfoAmountUrl}`,
};

const Api = {
  getList: async (params: SalesAccountingAmountInformationGetParameter) => {
    const { data } = await axios.get(url.getList(), {
      params,
    });
    return data;
  },
  update: async (params: SalesAccountingAmountInformationParameter) => {
    await axios.put(url.update(), params);
  },
  getExpectOrPerformanceList: async (params: SalesAccountingAmountInformationGetParameter) => {
    const { data } = await axios.get(url.getExpectOrPerformanceList(), {
      params,
    });
    return data;
  },
};
