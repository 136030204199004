import React, { useCallback, useMemo } from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ColorPalette } from '@front/assets/theme';
import Button from '@front/layouts/Button';
import type { UseFieldArraySwap } from 'react-hook-form';
import { useFormContext, useWatch } from 'react-hook-form';
import type { ButtonProps } from '@mui/material';
import { Box } from '@mui/material';
import {
  TableRowBox,
  TbcBox,
} from '@front/src/features/personal-settings-modal/features/ui-settings/components';
import { personalSettingsModalUISettingsModalSettingsRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/repository/repository';
import { usePersonalSettingsModalUISettingsModalSettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/useState';
import { useShallow } from 'zustand/react/shallow';

export { ChildrenTableRow as PersonalSettingsModalUISettingsModalSettingsCurrentSettingsChildrenTableRow };

interface Props {
  index: number;
  swap: UseFieldArraySwap;
  length: number;
  sectionId: number;
  authMenuId: number | undefined;
}

const ChildrenTableRow = ({ index, swap, length, authMenuId, sectionId }: Readonly<Props>) => {
  const { control } = useFormContext();
  const { data: list } = personalSettingsModalUISettingsModalSettingsRepository.useGetHeaderList(
    sectionId,
    authMenuId
  );
  const { parentId } = usePersonalSettingsModalUISettingsModalSettingsState(
    useShallow((state) => ({
      parentId: state.parentId,
    }))
  );
  const child = useWatch({ control, name: `childList.${index}` });
  const { name, headerSettingId } = child ?? {};
  const handleUp = useCallback(() => {
    swap(index, index - 1);
  }, [swap, index]);
  const handleDown = useCallback(() => {
    swap(index, index + 1);
  }, [swap, index]);
  const original = useMemo(
    () =>
      list
        ?.find((l) => l.id === parentId)
        ?.modalHeaderList.find((m) => m.headerSettingId === headerSettingId),
    [headerSettingId, list, parentId]
  );
  const isChanged = useMemo(() => original === undefined, [original]);
  const sx = useMemo(
    () => ({
      backgroundColor: isChanged ? ColorPalette._fff2cc : ColorPalette._d7e2f7,
    }),
    [isChanged]
  );
  const upButtonDisabled = useMemo(() => index - 1 < 0, [index]);
  const downButtonDisabled = useMemo(() => index + 1 >= length, [index, length]);
  return (
    <TableRowBox>
      <TbcBox
        sx={{
          flex: 2,
          ...sx,
        }}
      >
        {name}
      </TbcBox>
      <TbcBox
        sx={{
          flex: 1,
          justifyContent: 'center',
          gap: '4px',
        }}
      >
        <Box>
          <SeqButton
            disabled={upButtonDisabled}
            onClick={handleUp}
          >
            <ArrowDropUpIcon />
          </SeqButton>
        </Box>
        <Box>
          <SeqButton
            disabled={downButtonDisabled}
            onClick={handleDown}
          >
            <ArrowDropDownIcon />
          </SeqButton>
        </Box>
      </TbcBox>
    </TableRowBox>
  );
};

const SeqButton = ({ children, disabled }: ButtonProps) => (
  <Button
    disabled={disabled}
    sx={{ height: '25px', minWidth: '25px', padding: 0 }}
  >
    {children}
  </Button>
);
