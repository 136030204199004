import { createContext } from 'react';
import type { ProjectDivisionView } from '@front/src/features/project-sales-info/features/project-division/types/view';

interface State {
  d: {
    divisionDetail?: ProjectDivisionView;
  };
}

export { Context as ProjectSalesInfoContractEarningsCollectionManagementContext };

const Context = createContext<State>({
  d: {},
});
