import { createContext } from 'react';
import type { SalesAccountingAmountInformationView } from '@front/src/features/accounting/features/upload/features/sales-info/types/view';

export { Context as AccountingUploadSalesInfoDownloadExcelContext };

interface State {
  list?: SalesAccountingAmountInformationView[];
}

const Context = createContext<State>({});
