import { createAction } from 'typesafe-actions';
import type {
  ApprovalDocumentParameter,
  ArrangementCommentParameter,
  AttachedFileParameter,
  CommentParameter,
  DrawingParameter,
  ProposalAddParameter,
  ProposalApprovalParameter,
  ProposalArrangementInformationStatusParameter,
  ProposalContentAddParameter,
  ProposalContentChangeParameter,
  ProposalContributorUpdateParameter,
  ProposalEstimatorUpdateGradeParameter,
  ProposalFixedResultUpdateParameter,
  ProposalGradeUpdateParameter,
  ProposalMemoAddParameter,
  ProposalMemoChangeParameter,
  ProposalStatusUpdateParameter,
  ProposalSubmissionCancelReasonParameter,
  ProposalUpdateArrangementInformationAdvisorParameter,
  ProposalUpdateArrangementPlanParameter,
  TitleUpdateParameter,
} from '@front/ost_proposal/parameter';
import type {
  ArrangementAttachedFileId,
  ArrangementAttachFileVO,
  ArrangementCommentId,
  ArrangementCommentVO,
  AttachedFileId,
  AttachFileVO,
  CommentId,
  CommentVO,
  DrawingId,
  DrawingVO,
  ProposalApprovalDocumentVO,
  ProposalApprovalVO,
  ProposalArrangementCommentEditVO,
  ProposalArrangementPlanId,
  ProposalArrangementPlanVO,
  ProposalCancelRequestVO,
  ProposalCommentEditVO,
  ProposalContentId,
  ProposalContentVO,
  ProposalContributorId,
  ProposalContributorVO,
  ProposalEstimatorVO,
  ProposalGrade,
  ProposalId,
  ProposalMemoId,
  ProposalMemoVO,
  ProposalShortVO,
  ProposalStatus,
  ProposalVO,
} from '@front/ost_proposal/domain';
import type { ProposalMemoQuery, ProposalQuery } from '@front/ost_proposal/query';
import type { ApiStatus } from '@front/components/DataFieldProps';
import type { CampaignId, ProposalCategoryVO } from '@front/ost_campaign/domain';
import type { CurrentPage } from '@front/navigate/domain';

export enum ProposalAction {
  /**
   * 제안 등록 관련 액션
   */
  add = 'proposal/add',
  setAddModal = 'proposal/addModal/set',
  /**
   * 제안 목록 관련 액션
   */
  setId = 'proposal/id/set',
  setList = 'proposal/list/set',
  setFilter = 'proposal/filter/set',
  toggleDrawer = 'proposal/drawer/toggle',
  setLoading = 'proposal/loading/set',
  /**
   * 제안 메모 관련 액션
   */
  setMemoDrawerOpen = 'proposal/drawer/set',
  addMemo = 'proposal/memo/add',
  setMemoFilter = 'proposal/memo/filter',
  setMemoList = 'proposal/memo/list/set',
  changeMemo = 'proposal/memo/change',
  deleteMemo = 'proposal/memo/delete',
  setMemoLoading = 'proposal/memo/loading/set',

  /**
   * 제안 상세 관련 액션
   */
  setDetail = 'proposal/detail/set',

  /**
   * 제안 댓글 관련 액션
   */
  addComment = 'proposal/comment/add',
  deleteComment = 'proposal/comment/delete',
  updateComment = 'proposal/comment/update',
  setCommentList = 'proposal/comment/list/set',
  /**
   * 제안 댓글 수정 삭제 드롭 다운 열기
   */
  setCommentId = 'proposal/comment/id/set',
  setCommentEditInput = 'proposal/comment/edit-input/set',
  setCommentParentId = 'proposal/comment/parent-id/set',
  setReplyCommentParentId = 'proposal/comment/reply-parent-id/set',
  /**
   * 제안 드로잉 관련 액션
   */
  addDrawing = 'proposal/drawing/add',
  deleteDrawing = 'proposal/drawing/delete',
  updateDrawing = 'proposal/drawing/update',
  setDrawingList = 'proposal/drawing/list/set',

  /**
   * 제안 첨부 파일 관련 액션
   */
  addAttachedFile = 'proposal/attached-file/add',
  deleteAttachedFile = 'proposal/attached-file/delete',
  setAttachedFileList = 'proposal/attached-file/list/set',

  /**
   * 제안 주제
   */
  updateTitle = 'proposal/title/update',
  setTitle = 'proposal/title/set',

  /**
   * 제안 즐겨찾기 관련 액션
   */
  updateFavorite = 'proposal/favorite/update',
  /**
   * 제안 삭제 관련 액션
   */
  requestDelete = 'proposal/delete/request',
  deleteDetail = 'proposal/detail/delete',
  /**
   * 제안 내용 관련 액션
   */
  addContent = 'proposal/content/add',
  deleteContent = 'proposal/content/delete',
  updateContent = 'proposal/content/update',
  setContentList = 'proposal/content/list/set',
  /**
   * 제안 참여자 관련 액션
   */
  addContributor = 'proposal/contributor/add',
  updateContributor = 'proposal/contributor/update',
  deleteContributor = 'proposal/contributor/delete',
  setContributorList = 'proposal/contributor/list/set',

  /**
   * 캠페인 제안 구분 리스트 액션
   */
  setCampaignId = 'proposal/campaign-id/set',
  setProposalCategoryList = 'proposal/proposal-category/list/set',

  /**
   * 제안 등급 관련 액션
   */
  setGrade = 'proposal/grade/set',
  updateGrade = 'proposal/grade/update',

  /**
   * 제안 제출
   */
  submit = 'proposal/submit',

  /**
   * 제안 진척 현황
   */
  setProposalStatus = 'proposal/status/set',

  /**
   * 제안 진척 현황 업데이트
   */
  updateProposalStatus = 'proposal/status/update',

  /**
   * 최종 확정 금액
   */
  setProposalResult = 'proposal/result/set',

  /**
   * 권한 체크
   */
  requestPermission = 'proposal/permission/request',

  /**
   * 평가 의견
   */
  updateGradeByEstimator = 'proposal/grade/update-by-estimator',
  /**
   * 평가자 목록
   */
  setEstimatorInfoList = 'proposal/estimator-info/list/set',

  /**
   * 제안 투찰 중지 및 종료
   */
  endVote = 'proposal/vote/end',

  /**
   * 캠페인 안내 모달창
   */
  setCampaignInfoModal = 'proposal/campaign-info-modal/set',

  /**
   * 협의자 지정 요청
   */
  requestAdvisor = 'proposal/advisor/request',

  /**
   * 협의 정보 상태 업데이트
   */
  updateProposalArrangementInformationStatus = 'proposal/arrangement-information-status/update',

  /**
   * 협의자 지정 모달
   */
  setAdvisorModal = 'proposal/advisor-modal/set',

  /**
   * 협의자 지정
   */
  setAdvisor = 'proposal/advisor/set',

  /**
   * 협의 계획
   */
  setProposalArrangementPlanList = 'proposal/arrangement-plan-list/set',
  getProposalArrangementPlanList = 'proposal/arrangement-plan-list/get',
  addProposalArrangementPlan = 'proposal/arrangement-plan/add',
  updateProposalArrangementPlan = 'proposal/arrangement-plan/update',
  deleteProposalArrangementPlan = 'proposal/arrangement-plan/delete',

  /**
   * 제안 협의 댓글 관련 액션
   */
  addArrangementComment = 'proposal/arrangement-comment/add',
  deleteArrangementComment = 'proposal/arrangement-comment/delete',
  updateArrangementComment = 'proposal/arrangement-comment/update',
  setArrangementCommentList = 'proposal/arrangement-comment/list/set',
  /**
   * 제안 협의 댓글 수정 삭제 드롭 다운 열기
   */
  setArrangementCommentId = 'proposal/arrangement-comment/id/set',
  setArrangementCommentEditInput = 'proposal/arrangement-comment/edit-input/set',
  setArrangementCommentParentId = 'proposal/arrangement-comment/parent-id/set',
  setReplyArrangementCommentParentId = 'proposal/arrangement-comment/reply-parent-id/set',

  /**
   * 제안 협의 첨부 파일 관련 액션
   */
  addArrangementAttachedFile = 'proposal/arrangement-attached-file/add',
  deleteArrangementAttachedFile = 'proposal/arrangement-attached-file/delete',
  setArrangementAttachedFileList = 'proposal/arrangement-attached-file/list/set',

  /**
   * 제안 회수 모달 관련 액션
   */
  setProposalSubmissionCancelModal = 'proposal/proposal-submission-cancel-modal/set',
  addProposalSubmissionCancelReason = 'proposal/proposal-submission-cancel-reason/add',

  /**
   * 제안 회수 허용 / 불허
   */
  agreeProposalSubmissionCancel = 'proposal/proposal-submission-cancel/agree',
  getProposalSubmissionCancel = 'proposal/proposal-submission-cancel/get',
  setProposalSubmissionCancel = 'proposal/proposal-submission-cancel/set',

  /**
   * 결재 직전 상신 요청 모달창
   */
  setApprovalDocumentModal = 'proposal/approval-document-modal/set',
  addApprovalDocument = 'proposal/approval-document/add',

  /**
   * 결재창 모달 열기
   */
  setApprovalModal = 'proposal/approval-modal/set',

  /**
   * 결재 리스트 목록 set, get
   */
  setApprovalList = 'proposal/approval-list/set',
  getApprovalList = 'proposal/approval-list/get',
  setApprovalDocument = 'proposal/approval-document/set',
  getApprovalDocument = 'proposal/approval-document/get',

  /**
   * 결재
   */
  updateProposalApproval = 'proposal/approval/request',

  /**
   * 확정 금액
   */
  updateFixedResult = 'proposal/fixed-result/update',
}

export const proposalAction = {
  /**
   * 제안 등록 관련 액션
   */
  add: createAction(ProposalAction.add)<ProposalAddParameter | undefined>(),
  setAddModal: createAction(ProposalAction.setAddModal)<boolean>(),
  /**
   * 제안 목록 관련 액션
   */
  setId: createAction(ProposalAction.setId)<ProposalId | undefined>(),
  setList: createAction(ProposalAction.setList)<ProposalShortVO[]>(),
  setFilter: createAction(ProposalAction.setFilter)<ProposalQuery>(),
  toggleDrawer: createAction(ProposalAction.toggleDrawer)<boolean>(),
  setLoading: createAction(ProposalAction.setLoading)<boolean>(),
  /**
   * 제안 메모 관련 액션
   */
  setMemoDrawerOpen: createAction(ProposalAction.setMemoDrawerOpen)<boolean>(),
  addMemo: createAction(ProposalAction.addMemo)<ProposalMemoAddParameter>(),
  setMemoFilter: createAction(ProposalAction.setMemoFilter)<ProposalMemoQuery>(),
  setMemoList: createAction(ProposalAction.setMemoList)<ProposalMemoVO[]>(),
  changeMemo: createAction(ProposalAction.changeMemo)<ProposalMemoChangeParameter>(),
  deleteMemo: createAction(ProposalAction.deleteMemo)<ProposalMemoId>(),
  setMemoLoading: createAction(ProposalAction.setMemoLoading)<boolean>(),
  /**
   * 제안 상세 관련 액션
   */
  setDetail: createAction(ProposalAction.setDetail)<ProposalVO | undefined>(),
  /**
   * 제안 댓글 관련 액션
   */
  addComment: createAction(ProposalAction.addComment)<CommentParameter | undefined>(),
  setCommentList: createAction(ProposalAction.setCommentList)<CommentVO[]>(),
  deleteComment: createAction(ProposalAction.deleteComment)<CommentId | undefined>(),
  updateComment: createAction(ProposalAction.updateComment)<CommentParameter | undefined>(),
  /**
   * 제안 댓글 수정 삭제 드롭 다운 열기
   */
  setCommentId: createAction(ProposalAction.setCommentId)<CommentId | undefined>(),
  setCommentEditInput: createAction(ProposalAction.setCommentEditInput)<ProposalCommentEditVO>(),
  setCommentParentId: createAction(ProposalAction.setCommentParentId)<CommentId | undefined>(),
  setReplyCommentParentId: createAction(ProposalAction.setReplyCommentParentId)<
    CommentId | undefined
  >(),
  /**
   * 제안 드로잉 관련 액션
   */
  addDrawing: createAction(ProposalAction.addDrawing)<DrawingParameter | undefined>(),
  setDrawingList: createAction(ProposalAction.setDrawingList)<DrawingVO[]>(),
  deleteDrawing: createAction(ProposalAction.deleteDrawing)<DrawingId | undefined>(),
  updateDrawing: createAction(ProposalAction.updateDrawing)<DrawingParameter | undefined>(),

  /**
   * 제안 첨부 파일 관련 액션
   */
  addAttachedFile: createAction(ProposalAction.addAttachedFile)<
    AttachedFileParameter | undefined
  >(),
  setAttachedFileList: createAction(ProposalAction.setAttachedFileList)<AttachFileVO[]>(),
  deleteAttachedFile: createAction(ProposalAction.deleteAttachedFile)<AttachedFileId | undefined>(),

  updateTitle: createAction(ProposalAction.updateTitle)<TitleUpdateParameter | undefined>(),
  setTitle: createAction(ProposalAction.setTitle)<string>(),
  /**
   * 제안 즐겨찾기 관련 액션
   */
  updateFavorite: createAction(ProposalAction.updateFavorite)<boolean>(),
  /**
   * 제안 삭제 관련 액션
   */
  deleteDetail: createAction(ProposalAction.deleteDetail)<ProposalId | undefined>(),
  requestDelete: createAction(ProposalAction.requestDelete)<ApiStatus>(),
  /**
   * 제안 내용 관련 액션
   */
  addContent: createAction(ProposalAction.addContent)<ProposalContentAddParameter>(),
  deleteContent: createAction(ProposalAction.deleteContent)<ProposalContentId>(),
  updateContent: createAction(ProposalAction.updateContent)<ProposalContentChangeParameter>(),
  setContentList: createAction(ProposalAction.setContentList)<ProposalContentVO[]>(),
  /**
   * 제안 참여자 관련 액션
   */
  addContributor: createAction(ProposalAction.addContributor)(),
  updateContributor: createAction(
    ProposalAction.updateContributor
  )<ProposalContributorUpdateParameter>(),
  deleteContributor: createAction(ProposalAction.deleteContributor)<ProposalContributorId>(),
  setContributorList: createAction(ProposalAction.setContributorList)<ProposalContributorVO[]>(),
  /**
   * 캠페인 제안 구분 리스트
   */
  setCampaignId: createAction(ProposalAction.setCampaignId)<CampaignId | undefined>(),
  setProposalCategoryList: createAction(ProposalAction.setProposalCategoryList)<
    ProposalCategoryVO[]
  >(),
  /**
   * 제안 등급 관련 액션
   */
  setGrade: createAction(ProposalAction.setGrade)<ProposalGrade | undefined>(),
  updateGrade: createAction(ProposalAction.updateGrade)<ProposalGradeUpdateParameter>(),

  /**
   * 제안 제출
   */
  submit: createAction(ProposalAction.submit)(),
  /**
   * 제안 진척현황
   */
  setProposalStatus: createAction(ProposalAction.setProposalStatus)<ProposalStatus>(),
  /**
   * 제안 진척 현황 업데이트
   */
  updateProposalStatus: createAction(
    ProposalAction.updateProposalStatus
  )<ProposalStatusUpdateParameter>(),
  /**
   * 최종 확정 금액
   */
  setProposalResult: createAction(ProposalAction.setProposalResult)<number>(),
  /**
   * 권한 체크
   */
  requestPermission: createAction(ProposalAction.requestPermission)<CurrentPage>(),
  /**
   * 평가 의견
   */
  updateGradeByEstimator: createAction(
    ProposalAction.updateGradeByEstimator
  )<ProposalEstimatorUpdateGradeParameter>(),
  /**
   * 평가 목록
   */
  setEstimatorInfoList: createAction(ProposalAction.setEstimatorInfoList)<ProposalEstimatorVO[]>(),

  /**
   * 제안 투찰 중지 및 종료
   */
  endVote: createAction(ProposalAction.endVote)(),

  /**
   * 캠페인 안내 모달창
   */
  setCampaignInfoModal: createAction(ProposalAction.setCampaignInfoModal)<boolean>(),
  /**
   * 협의자 지정 요청
   */
  requestAdvisor: createAction(ProposalAction.requestAdvisor)(),
  /**
   * 협의자 정보 상태 업데이트
   */
  updateProposalArrangementInformationStatus: createAction(
    ProposalAction.updateProposalArrangementInformationStatus
  )<ProposalArrangementInformationStatusParameter>(),
  /**
   * 협의자 지정 모달
   */
  setAdvisorModal: createAction(ProposalAction.setAdvisorModal)<boolean>(),
  /**
   * 협의자 지정
   */
  setAdvisor: createAction(
    ProposalAction.setAdvisor
  )<ProposalUpdateArrangementInformationAdvisorParameter>(),
  /**
   * 협의 계획
   */
  setProposalArrangementPlanList: createAction(ProposalAction.setProposalArrangementPlanList)<
    ProposalArrangementPlanVO[]
  >(),
  getProposalArrangementPlanList: createAction(ProposalAction.getProposalArrangementPlanList)(),
  addProposalArrangementPlan: createAction(ProposalAction.addProposalArrangementPlan)(),
  updateProposalArrangementPlan: createAction(
    ProposalAction.updateProposalArrangementPlan
  )<ProposalUpdateArrangementPlanParameter>(),
  deleteProposalArrangementPlan: createAction(
    ProposalAction.deleteProposalArrangementPlan
  )<ProposalArrangementPlanId>(),

  /**
   * 제안 댓글 관련 액션
   */
  addArrangementComment: createAction(ProposalAction.addArrangementComment)<
    ArrangementCommentParameter | undefined
  >(),
  setArrangementCommentList: createAction(ProposalAction.setArrangementCommentList)<
    ArrangementCommentVO[]
  >(),
  deleteArrangementComment: createAction(ProposalAction.deleteArrangementComment)<
    ArrangementCommentId | undefined
  >(),
  updateArrangementComment: createAction(ProposalAction.updateArrangementComment)<
    ArrangementCommentParameter | undefined
  >(),
  /**
   * 제안 댓글 수정 삭제 드롭 다운 열기
   */
  setArrangementCommentId: createAction(ProposalAction.setArrangementCommentId)<
    ArrangementCommentId | undefined
  >(),
  setArrangementCommentEditInput: createAction(
    ProposalAction.setArrangementCommentEditInput
  )<ProposalArrangementCommentEditVO>(),
  setArrangementCommentParentId: createAction(ProposalAction.setArrangementCommentParentId)<
    ArrangementCommentId | undefined
  >(),
  setReplyArrangementCommentParentId: createAction(
    ProposalAction.setReplyArrangementCommentParentId
  )<ArrangementCommentId | undefined>(),

  /**
   * 제안 협의 첨부 파일
   */
  addArrangementAttachedFile: createAction(ProposalAction.addArrangementAttachedFile)<
    AttachedFileParameter | undefined
  >(),
  setArrangementAttachedFileList: createAction(ProposalAction.setArrangementAttachedFileList)<
    ArrangementAttachFileVO[]
  >(),
  deleteArrangementAttachedFile: createAction(ProposalAction.deleteArrangementAttachedFile)<
    ArrangementAttachedFileId | undefined
  >(),

  /**
   * 제안 회수 모달 관련 액션
   */
  setProposalSubmissionCancelModal: createAction(
    ProposalAction.setProposalSubmissionCancelModal
  )<boolean>(),
  addProposalSubmissionCancelReason: createAction(
    ProposalAction.addProposalSubmissionCancelReason
  )<ProposalSubmissionCancelReasonParameter>(),

  /**
   * 제안 회수 허용 / 불허
   */
  agreeProposalSubmissionCancel: createAction(
    ProposalAction.agreeProposalSubmissionCancel
  )<boolean>(),
  getProposalSubmissionCancel: createAction(ProposalAction.getProposalSubmissionCancel)(),
  setProposalSubmissionCancel: createAction(ProposalAction.setProposalSubmissionCancel)<
    ProposalCancelRequestVO | undefined
  >(),

  /**
   * 결재 직전 상신 요청 모달창
   */
  setApprovalDocumentModal: createAction(ProposalAction.setApprovalDocumentModal)<boolean>(),
  addApprovalDocument: createAction(
    ProposalAction.addApprovalDocument
  )<ApprovalDocumentParameter>(),

  /**
   * 결재창 모달 열기
   */
  setApprovalModal: createAction(ProposalAction.setApprovalModal)<boolean>(),
  /**
   * 결재 리스트 목록 set, get
   */
  setApprovalList: createAction(ProposalAction.setApprovalList)<ProposalApprovalVO[]>(),
  getApprovalList: createAction(ProposalAction.getApprovalList)(),
  setApprovalDocument: createAction(ProposalAction.setApprovalDocument)<
    ProposalApprovalDocumentVO | undefined
  >(),
  getApprovalDocument: createAction(ProposalAction.getApprovalDocument)(),
  /**
   * 결재
   */
  updateProposalApproval: createAction(
    ProposalAction.updateProposalApproval
  )<ProposalApprovalParameter>(),

  /**
   * 확정 금액
   */
  updateFixedResult: createAction(
    ProposalAction.updateFixedResult
  )<ProposalFixedResultUpdateParameter>(),
};
