import { shallow } from 'zustand/shallow';
import { useCallback } from 'react';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import { projectSalesInfoContractCategoryKey } from '@front/src/features/project-sales-info/features/contract/utils/constant';
import { useProjectSalesInfoContractContractCreateModalState } from '@front/src/features/project-sales-info/features/contract/features/contract/features/create-modal/widgets/useState';
import { projectSalesInfoContractContractCreateModalRepository } from '@front/src/features/project-sales-info/features/contract/features/contract/features/create-modal/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { projectSalesInfoProjectDivisionRepository } from '@front/src/features/project-sales-info/features/project-division/repository/form/repository';
import { projectSalesInfoContractConfirmedRepository } from '@front/src/features/project-sales-info/features/contract/features/confirmed/repository';
import { projectSalesInfoContractContractRepository } from '@front/src/features/project-sales-info/features/contract/features/contract/repository';

export { useLogic as useProjectSalesInfoContractContractCreateModalLogic };

const useLogic = () => {
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { isOpen, setIsOpen } = useProjectSalesInfoContractContractCreateModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      isOpen: state.isOpen,
    }),
    shallow
  );
  const { data: detail, isLoading: isDetailLoading } =
    projectSalesInfoProjectDivisionRepository.useProjectDivisionGet(id!);
  const { run: onCreate, setCallback } =
    projectSalesInfoContractContractCreateModalRepository.useCreate();
  const { data: categoryList, isLoading: isCategoryLoading } =
    adminManageableVariableRepository.useListGet(projectSalesInfoContractCategoryKey);
  const { data: contractConfirmed, isLoading: isContractConfirmedLoading } =
    projectSalesInfoContractConfirmedRepository.useDetailGet(id);
  const { data: estimationList, isLoading: isEstimationListLoading } =
    projectSalesInfoContractContractRepository.useEstimationListGet(id);
  setCallback({
    onSuccess: () => {
      handleClose();
    },
  });
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const d = { projectFullName: detail?.fullName, categoryList, contractConfirmed, estimationList };
  const h = { onCreate, onClose: handleClose };
  return {
    d,
    h,
    isLoading:
      isDetailLoading || isCategoryLoading || isContractConfirmedLoading || isEstimationListLoading,
    isOpen,
  };
};
