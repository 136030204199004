import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useProjectSalesInfoSubjectReviewModalComplexInformationCreateLogic } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/create/widgets/useLogic';
import { ProjectSalesInfoSubjectReviewModalComplexInformationCreateContext } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/create/widgets/context';

export { Provider as ProjectSalesInfoSubjectReviewModalComplexInformationCreateProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { onCreate } = useProjectSalesInfoSubjectReviewModalComplexInformationCreateLogic();
  const value = useMemo(
    () => ({
      onCreate,
    }),
    [onCreate]
  );
  return (
    <ProjectSalesInfoSubjectReviewModalComplexInformationCreateContext.Provider value={value}>
      {children}
    </ProjectSalesInfoSubjectReviewModalComplexInformationCreateContext.Provider>
  );
};
