import React from 'react';
import type { BlockVO } from '@front/dialog/domain';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';

interface Props extends BlockVO {
  open: boolean;
}

const Block = (props: Props) => (
  <Dialog
    open={props.open}
    fullWidth={true}
    maxWidth="sm"
    sx={{
      '& .MuiDialog-paper': {
        borderRadius: 0,
      },
    }}
  >
    <DialogTitle
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: ColorPalette._2d3a54,
        color: ColorPalette._ffffff,
        height: '50px',
        padding: '0 20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          flexWrap: 'wrap',
          alignContent: 'center',
          alignItems: 'center',
        }}
      />
    </DialogTitle>
    <DialogContent>
      <Box
        sx={{
          flexWrap: 'wrap',
          display: 'flex',
          width: '100%',
          paddingTop: '20px',
          height: '100px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {props.children}
      </Box>
    </DialogContent>
  </Dialog>
);

export default Block;
