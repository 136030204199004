import { useQuery } from 'react-query';
import { affiliatedCompanyMeetingHistoryModalApi } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/query/api';
import type { AffiliatedCompanyMeetingHistoryView } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/types/view';
import type { UserRecordView } from '@front/src/types';

export const affiliatedCompanyMeetingHistoryModalQuery = {
  useMeetingHistoryGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyMeetingHistoryView>({
      queryFn: () => affiliatedCompanyMeetingHistoryModalApi.getMeetingHistory(id, menuId),
      queryKey: ['affiliated-company', 'meeting-history', id],
      enabled: !!id,
      cacheTime: 0,
    });
    return {
      data,
      isLoading,
    };
  },
  useUserListGet: (idList: number[], menuId?) => {
    const { data, isLoading } = useQuery<UserRecordView[]>({
      queryFn: () => affiliatedCompanyMeetingHistoryModalApi.getUserList(idList, menuId),
      queryKey: ['user-list', idList],
      cacheTime: 0,
      enabled: idList.length !== 0,
    });
    return {
      data,
      isLoading,
    };
  },
};
