import React, { useCallback, useContext, useRef } from 'react';
import { AccountingSettingsBankAccountUpdateBankNameContext } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/update-bank-name/provider/context';
import { FormProvider, useForm } from 'react-hook-form';
import { OldTd } from '@front/layouts/Table';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { useSnackbar } from '@front/src/features/snackbar';
import { useAccountingSettingsBankAccountState } from '@front/src/features/accounting/features/settings/features/bank-account/useState';
import { useShallow } from 'zustand/react/shallow';

export { BankName as AccountingSettingsBankAccountUpdateBankName };

const BankName = () => {
  const { onUpdate, item } = useContext(AccountingSettingsBankAccountUpdateBankNameContext);
  const methods = useForm({
    values: getValuesForForm(item),
  });
  const { handleSubmit } = methods;
  const { show } = useSnackbar();
  const ref = useRef<HTMLDivElement>(null);
  const onSubmit = handleSubmit((data) => {
    if (!data.alias) {
      show({
        message: '통합계좌명은 공란을 허용하지 않습니다.',
      });
      (ref.current?.children[0].children[0].children[0] as HTMLInputElement).focus();
      return;
    }
    onUpdate(data);
  });
  const handleKeyDown = useCallback(
    async (e) => {
      const { isComposing, key, keyCode } = e;
      if (isComposing || keyCode === 229) return;
      if (key !== 'Enter') return;
      await onSubmit();
    },
    [onSubmit]
  );
  const { readOnly } = useAccountingSettingsBankAccountState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <OldTd>
        <div ref={ref}>
          <HookFormInput
            name="alias"
            onSubmit={onSubmit}
            onKeyDown={handleKeyDown}
            disabled={readOnly}
          />
        </div>
      </OldTd>
    </FormProvider>
  );
};

const getValuesForForm = (item) => ({
  id: item.id,
  alias: item.alias,
});
