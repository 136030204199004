import React from 'react';
import type { WorkCategoryView } from '@front/src/features/work/features/work/features/category/types/view';
import workCategoryQuery from '@front/src/features/work/features/work/features/category/query/query';
import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import WorkWorkCategoryTableRow from '@front/src/features/work/features/work/features/category/components/TableRow';

export default function WorkCategoryTableBody({
  sectionId,
  dataId,
  menuId,
  ...rest
}: Readonly<UIBuilderTableBodyProps>) {
  const { data: detail } = workCategoryQuery.useGetOne({
    dataId,
    menuId,
    sectionId,
  });
  let list: WorkCategoryView[] | undefined = undefined;
  if (detail) {
    list = [{ ...detail }];
  }
  return (
    <UIBuilderTableBody<WorkCategoryView>
      list={list}
      RowComponent={WorkWorkCategoryTableRow}
      sectionId={sectionId}
      dataId={dataId}
      menuId={menuId}
      {...rest}
    />
  );
}
