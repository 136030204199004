import React, { useContext } from 'react';
import Button from '@front/layouts/Button';
import classes from '@front/src/features/mobile-notification/widgets/header/components/mobile-notification-btn-group.module.scss';
import { MobileNotificationHeaderContext } from '@front/src/features/mobile-notification/widgets/header/provider/context';
import { useCustomDialog } from '@front/src/features/dialog';
import { confirmDeleteAll } from '@front/src/utils';
import { useMobileNotificationState } from '@front/src/features/mobile-notification/useState';

export const MobileNotificationBtnGroup = () => {
  const { onDeleteAll, onToggleAll } = useContext(MobileNotificationHeaderContext);
  const { confirm, alert } = useCustomDialog();
  const deleteAllHandler = () => {
    if (!isAllRead) {
      alert({
        children: [
          {
            value: '읽지 않은 메시지가 있습니다.',
          },
        ],
      });
      return;
    }
    confirmDeleteAll(confirm, onDeleteAll);
  };
  const isAllRead = useMobileNotificationState((state) => state.isAllRead);
  return (
    <div className={classes.container}>
      <Button
        shape="basic5"
        onClick={deleteAllHandler}
        sx={{
          width: '100%',
          minWidth: '60px',
          fontWeight: 'bold',
        }}
      >
        일괄삭제
      </Button>
      <Button
        shape="basic1"
        onClick={onToggleAll}
        sx={{
          width: '100%',
          minWidth: '60px',
          fontWeight: 'bold',
        }}
      >
        일괄확인
      </Button>
    </div>
  );
};
