import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useShallow } from 'zustand/react/shallow';
import { ColorPalette } from '@front/assets/theme';
import { personalSettingsModalUISettingsRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/repository/repository';
import { useCancelBlockModalState } from '@front/src/features/modal/features/cancel-block-modal/useState';
import { usePersonalSettingsModalUISettingsHeaderSettingsPositionState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/useState';
import { usePersonalSettingsModalUISettingsHeaderSettingsHistoryState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/history/useState';
import { usePersonalSettingsModalUISettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/useState';

export { NavBar as PersonalSettingsModalUISettingsNavBar };
interface Props {
  authMenuId: number | undefined;
}
const NavBar = ({ authMenuId }: Props) => {
  const { data: sectionList } =
    personalSettingsModalUISettingsRepository.useGetSectionList(authMenuId);
  const { isDirty, setOpen, setDescriptionList, setTitle } = useCancelBlockModalState(
    useShallow((state) => ({
      setOpen: state.setOpen,
      isDirty: state.isDirty,
      setDescriptionList: state.setDescriptionList,
      setTitle: state.setTitle,
    }))
  );
  const { setSectionId, sectionId } = usePersonalSettingsModalUISettingsState(
    useShallow((state) => ({
      setSectionId: state.setSectionId,
      sectionId: state.sectionId,
    }))
  );
  const setHeader = usePersonalSettingsModalUISettingsHeaderSettingsPositionState(
    (state) => state.setHeader
  );
  const { setHeader: setHistoryHeader } =
    usePersonalSettingsModalUISettingsHeaderSettingsHistoryState(
      useShallow((state) => ({ setHeader: state.setHeader }))
    );
  const handleClick = useCallback(
    (id) => {
      if (isDirty) {
        setTitle('테이블 이동 확인');
        setDescriptionList([
          '저장되지 않은 변경사항은 반영되지 않습니다.',
          '테이블을 이동하시겠습니까?',
        ]);
        setOpen(true, () => {
          setSectionId(id);
          setHeader();
          setHistoryHeader();
        });
        return;
      }
      setSectionId(id);
      setHeader();
      setHistoryHeader();
    },
    [setSectionId, isDirty, setOpen, setDescriptionList, setTitle, setHeader]
  );
  const getActiveSx = useCallback(
    (id) => ({
      backgroundColor: sectionId === id ? ColorPalette._d7e2f7 : 'transparent',
    }),
    [sectionId]
  );
  return (
    <Box sx={containerSx}>
      {sectionList?.map((s) => (
        <Box
          key={s.id}
          onClick={() => handleClick(s.id)}
          sx={{
            ...sectionSx,
            ...getActiveSx(s.id),
          }}
        >
          <FontAwesomeIcon
            icon="circle"
            style={iconStyle}
          />
          <Box>{s.name ?? '이름 없는 섹션'}</Box>
        </Box>
      ))}
    </Box>
  );
};

const containerSx = {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  minWidth: '8dvw',
};

const sectionSx = {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  p: '8px',
  cursor: 'pointer',
};

const iconStyle = {
  fontSize: '8px',
};
