import React, { memo, Suspense } from 'react';
import ApprovalContent from '@front/src/features/drawer-approval/components/ApprovalContent';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import ResizableLeftDrawer from '@front/src/components/list-drawer/components/resizable-left-drawer';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import useIdGroup from '@front/src/features/note/hooks/useIdGroup';

function ApprovalFeature() {
  const leftDrawerState = useGlobalNavBarState((state) => state.leftDrawerState);
  const { documentId } = useIdGroup();

  return (
    <ResizableLeftDrawer leftDrawerState={leftDrawerState === 'approval'}>
      <Suspense fallback={<LoadingSpinner />}>
        <ApprovalContent key={documentId} />
      </Suspense>
    </ResizableLeftDrawer>
  );
}

export default memo(ApprovalFeature);
