import type { ReactNode } from 'react';
import React from 'react';
import { styled } from '@mui/material/styles';
import { ColorPalette } from '@front/assets/theme';
import { Box } from '@mui/material';
import type { SxProps } from '@mui/system';
import TextBox from '@front/layouts/Text';
import Button from '@front/layouts/Button';

export type SectionActionProp = {
  name: string;
  onClick: () => void;
};

type SectionProps = {
  children: ReactNode;
  title?: string;
  actions?: SectionActionProp[];
  minHeight?: number;
  sx?: SxProps;
};

export default function Section(props: SectionProps) {
  const { title, actions, children, minHeight } = props;
  const sx = { minHeight, ...props.sx };
  return (
    <SectionContainer className="section-container">
      {title && (
        <SectionHead>
          <Box>
            <TextBox
              variant={'heading1'}
              sx={{ width: '13%', marginBottom: '10px' }}
            >
              {title}
            </TextBox>
          </Box>
          <Box sx={{ marginLeft: 'auto' }}>
            {actions?.map((action) => (
              <Button
                key={`{index}_{action.name}`}
                onClick={action.onClick}
              >
                {action.name}
              </Button>
            ))}
          </Box>
        </SectionHead>
      )}
      <SectionBody sx={sx}>{children}</SectionBody>
    </SectionContainer>
  );
}

const SectionHead = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${ColorPalette._e4e9f2}`,
  borderRadius: '5px',
  padding: '10px',
  backgroundColor: ColorPalette._d7e2f7,
});

const SectionBody = styled(Box)({
  display: 'grid',
  border: `1px solid ${ColorPalette._e4e9f2}`,
  padding: '20px',
  borderRadius: '5px',
});

const SectionContainer = styled(Box)({
  margin: '0px 0px 30px 0px',
  display: 'flex',
  flexWrap: 'nowrap',
  flexDirection: 'column',
  width: '100%',
  minHeight: '60px',
});
