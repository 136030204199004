import React, { useCallback } from 'react';
import type { ProposalStatusUpdateParameter } from '@front/ost_proposal/parameter';
import type { CurrentPage } from '@front/navigate/domain';
import CampaignMangerButtonController from '@front/ost_proposal/view/detail/CampaignMangerButtonController';
import { useLocation } from 'react-router-dom';
import type { ProposalId } from '@front/ost_proposal/domain';
import { ProposalStatus } from '@front/ost_proposal/domain';
import { proposalAction } from '@front/ost_proposal/action';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import DeleteButton from '@front/ost_proposal/view/detail/DeleteButton';
import { Box } from '@mui/material';
import PersonalSettingsFeature from 'src/features/personal-settings-modal';

interface Props {
  updateStatus: (status: ProposalStatusUpdateParameter) => void;
  currentPage: CurrentPage;
  isContributor: boolean;
  menuId?: number | undefined;
}

const HeadButtonControllerService = ({
  currentPage,
  updateStatus,
  isContributor,
  menuId,
}: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const deleteDetail = useCallback(
    (id: ProposalId) => dispatch(proposalAction.deleteDetail(id)),
    [dispatch]
  );
  const status = useSelector((root: RootState) => root.proposal.detail!.status, shallowEqual);
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const isValid = loginUser?.role.canAccessPageAuthorization || loginUser?.role.canAccessPageView;
  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '12px',
          p: '20px',
        }}
      >
        {isValid && <PersonalSettingsFeature authMenuId={menuId} />}
        {status === ProposalStatus.WRITING && isContributor && (
          <DeleteButton deleteDetail={deleteDetail} />
        )}
      </Box>
      {location.state && location.state.isManager && (
        <CampaignMangerButtonController
          currentPage={currentPage}
          updateStatus={updateStatus}
        />
      )}
    </>
  );
};

export default HeadButtonControllerService;
