import React, { Fragment } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useFormContext } from 'react-hook-form';
import { TableRow, Typography } from '@mui/material';
import { WorkTd } from '@front/src/features/work/features/work/components/work-td';
import { getAuthColor } from '@front/src/features/admin/features/access-authorization-settings/features/department-authorization/utils';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { authOptionList } from '@front/src/features/admin/features/access-authorization-settings/utils/constants';
import type { AuthorizationPersonalSettingsDeptView } from '@front/src/features/personal-settings-modal/features/auth-settings/types/view';
import type { AuthorizationMenuView } from '@front/src/features/admin/features/access-authorization-settings/features/department-authorization/type/view';
import { ColorPalette } from '@front/assets/theme';
import { usePersonalSettingsModalDeptSettingsState } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/dept-settings/useState';

export { DeptSettingsTableAuthRow as PersonalSettingsModalDeptSettingsTableAuthRow };

interface Props {
  item: AuthorizationPersonalSettingsDeptView;
  rowIdx: number;
}

const DeptSettingsTableAuthRow = ({ item, rowIdx }: Props) => {
  const { editId, setEditId, setColoredId, updateList, setUpdateList } =
    usePersonalSettingsModalDeptSettingsState(
      useShallow((state) => ({
        editId: state.editId,
        setEditId: state.setEditId,
        setColoredId: state.setColoredId,
        updateList: state.updateList,
        setUpdateList: state.setUpdateList,
      }))
    );
  const { getValues, watch } = useFormContext();
  const isEditOpen = editId === item.originalId;
  const onSelectChange = (el: AuthorizationMenuView) => {
    setUpdateList([...updateList, { id: el.authorization.id, type: el.authorization.type }]);
    setColoredId(item.originalId!);
  };
  const onRowClick = () => {
    setEditId(item.originalId!);
  };

  return (
    <TableRow
      sx={{
        cursor: 'pointer',
      }}
      hover={true}
      onClick={onRowClick}
    >
      {item.menus.map((el, index) => (
        <Fragment key={el.id}>
          <WorkTd
            height="53px"
            sx={{
              backgroundColor: updateList
                .map((u) => u.id)
                .includes(watch(`dept.${rowIdx}.menus.${index}.authorization.id`))
                ? ColorPalette._eb4c4c
                : getAuthColor(watch(`dept.${rowIdx}.menus.${index}.authorization.type`)),
            }}
          >
            {!isEditOpen ? (
              <Typography id="access-auth-dep-text">
                {getValues(`dept.${rowIdx}.menus.${index}.authorization.type`)}
              </Typography>
            ) : (
              <HookFormSelect
                name={`dept.${rowIdx}.menus.${index}.authorization.type`}
                optionList={authOptionList}
                onChange={() => onSelectChange(el)}
              />
            )}
          </WorkTd>
        </Fragment>
      ))}
    </TableRow>
  );
};
