import React from 'react';
import { getCommonControlPanelItemList } from '@front/src/features/work/features/work/utils/constant';
import { useShallow } from 'zustand/react/shallow';
import type { WorkReferenceView } from '@front/src/features/work/features/work/features/reference/types/view';
import { ColorPalette } from '@front/assets/theme';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { useCustomDialog } from '@front/src/features/dialog';
import { FormProvider, useForm } from 'react-hook-form';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/TableBody';
import useWorkReferenceState from '@front/src/features/work/features/work/features/reference/useState';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import useNoteStore from '@front/src/features/note/useState';
import type { NoteMenuIdView } from '@front/src/features/note/types/view';

export default function WorkReferenceTableRow({
  item,
  rowActions,
  ...rest
}: UIBuilderTableRowProps<WorkReferenceView>) {
  const { editOpenList, toggleEditOpenId, colored } = useWorkReferenceState(
    useShallow((state) => ({
      editOpenList: state.editOpenList,
      toggleEditOpenId: state.toggleEditOpenId,
      colored: state.colored,
    }))
  );
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const { alert } = useCustomDialog();
  const onEdit = () => {
    if (item.user?.id !== loginUser?.id) {
      alert({
        children: [
          {
            value: '작성자만 수정할 수 있습니다.',
          },
        ],
      });
      return;
    }
    toggleEditOpenId(item.id);
  };
  const setIsRightDrawerOpen = useGlobalNavBarState((state) => state.setIsRightDrawerOpen);
  const { setRowId, setSectionId } = useNoteStore(
    useShallow((state) => ({
      setRowId: state.setRowId,
      setSectionId: state.setSectionId,
    }))
  );
  const actions = {
    onAdd: () => rowActions?.onCreate(item.id),
    onRemove: () => rowActions?.onRemove(item.id),
    onMoveUp: () => rowActions?.onSeqDown(item.id),
    onMoveDown: () => rowActions?.onSeqUp(item.id),
    onEdit,
    onNoteOpen: () => {
      const noteAuth: NoteMenuIdView = JSON.parse(sessionStorage.getItem('noteAuth') ?? '{}');
      if (!noteAuth.noteId || noteAuth.type === 'X') {
        alert({
          title: '권한 오류',
          children: [{ value: '접근 권한이 없습니다.' }],
        });
        return;
      }
      setIsRightDrawerOpen(true);
      setRowId(item.id);
      setSectionId(rest.sectionId);
    },
  };
  const methods = useForm({
    values: getFormValues(item),
  });
  const { handleSubmit } = methods;
  const sx = {
    backgroundColor: colored === item.id ? `${ColorPalette._d2e7fa}` : 'inherit',
  };
  const onSubmit = handleSubmit((data) => {
    rowActions?.onUpdate(data);
  });
  const isEditOpen = editOpenList.includes(item.id);
  const itemList = getCommonControlPanelItemList({ actions, isEditOpen });
  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        {...rest}
        sx={sx}
        item={item}
        onSubmit={onSubmit}
        itemList={itemList}
        isEditMode={isEditOpen}
        rowActions={rowActions}
      />
    </FormProvider>
  );
}

function getFormValues(item) {
  return {
    id: item.id,
    textAttr1: item.textAttr1 ?? '',
    textAttr2: item.textAttr2 ?? '',
    textAttr3: item.textAttr3 ?? '',
    numberAttr1: item.numberAttr1,
    numberAttr2: item.numberAttr2,
    numberAttr3: item.numberAttr3,
    dateAttr1: item.dateAttr1 ?? '',
    dateAttr2: item.dateAttr2 ?? '',
    dateAttr3: item.dateAttr3 ?? '',
  };
}
