import { create } from 'zustand';
import type { SalesActivityAffiliatesConvertedView } from '@front/src/features/project-sales-info/features/activity/types/views';

export { useState as useProjectSalesInfoActivityState };

interface State {
  idList: number[];
  id?: number;
  list?: SalesActivityAffiliatesConvertedView[];
  note: string;
  readOnly?: boolean;
}

interface Actions {
  setIdList: (idList: number[]) => void;
  setId: (id?: number) => void;
  setList: (list?: SalesActivityAffiliatesConvertedView[]) => void;
  setNote: (note: string) => void;
  setReadOnly: (readOnly?: boolean) => void;
}

const useState = create<State & Actions>((set) => ({
  idList: [],
  setIdList: (idList) =>
    set(() => ({
      idList,
    })),
  setId: (id) =>
    set(() => ({
      id,
    })),
  setList: (list) =>
    set(() => ({
      list,
    })),
  note: '',
  setNote: (note) =>
    set(() => ({
      note,
    })),
  readOnly: false,
  setReadOnly: (readOnly) => set({ readOnly }),
}));
