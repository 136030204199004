import React, { useCallback, useContext } from 'react';
import { ProjectSalesInfoProjectDivisionModalContext } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/widgets/context';
import { useProjectSalesInfoProjectDivisionModalState } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/widgets/useState';
import Button from '@front/layouts/Button';
import { shallow } from 'zustand/shallow';
import { handleDeleteIdListConfirmAfterClick } from '@front/src/utils';
import { useCustomDialog } from '@front/src/features/dialog';
import { useProjectSalesInfoProjectDivisionState } from '@front/src/features/project-sales-info/features/project-division/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { ButtonGroup as ProjectSalesInfoProjectDivisionModalButtonGroup };

const ButtonGroup = () => {
  const {
    h: { onCreate, onDelete },
  } = useContext(ProjectSalesInfoProjectDivisionModalContext);
  const { confirm } = useCustomDialog();
  const { idList, setIdList } = useProjectSalesInfoProjectDivisionModalState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const handleDeleteClick = useCallback(
    () =>
      handleDeleteIdListConfirmAfterClick(idList, confirm, () => {
        onDelete({ idList });
        setIdList([]);
      }),
    [onDelete, idList, setIdList, confirm]
  );
  const { readOnly } = useProjectSalesInfoProjectDivisionState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <Button
        onClick={handleDeleteClick}
        disabled={readOnly}
      >
        선택삭제
      </Button>
      <Button
        onClick={onCreate}
        disabled={readOnly}
      >
        행 추가
      </Button>
    </div>
  );
};
