import { useQuery } from 'react-query';
import type { ProjectCMPaymentHistoryView } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/types/view';
import { projectSalesInfoCMPaymentHistoryApi } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/query/api';

export const projectSalesInfoCMPaymentHistoryQuery = {
  useCMPaymentHistoryGet: (id: number) => {
    const { data, isLoading } = useQuery<ProjectCMPaymentHistoryView[]>({
      queryFn: () => projectSalesInfoCMPaymentHistoryApi.getCMPaymentHistory(id),
      queryKey: ['project-sales-info', 'collection', 'CM-payment-history', id],
    });
    return {
      data,
      isLoading,
    };
  },
};
