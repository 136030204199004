// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v3NUwXMmcG853OgKt6ZO{display:flex;gap:10px;padding:0 10px;width:300px;justify-content:right}.DTwr_XpwtG7cvIkvBzYV{display:flex;justify-content:space-between;align-items:center}.XYoUSeG2ftlNewPMwMC2{font-size:13px;color:#eb4c4c;padding:10px}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/activity/components/layouts/header.module.scss","webpack://./front/assets/colors.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,QAAA,CACA,cAAA,CACA,WAAA,CACA,qBAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,sBACE,cAAA,CACA,aCGQ,CDFR,YAAA","sourcesContent":["@import '@front/assets/colors.scss';\n\n.btn__container {\n  display: flex;\n  gap: 10px;\n  padding: 0 10px;\n  width: 300px;\n  justify-content: right;\n}\n\n.container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.text {\n  font-size: 13px;\n  color: $_eb4c4c;\n  padding: 10px;\n}","$_ffffff: #ffffff;\n$_9b9ea4: #9b9ea4;\n$_b2b4b7: #b2b4b7;\n$_94a6ca: #94a6ca;\n$_ffb72b: #ffb72b;\n$_fff2cc: #fff2cc;\n$_252627: #252627;\n$_386dd6: #386dd6;\n$_4c9eeb: #4c9eeb;\n$_9bb6ea: #9bb6ea;\n$_d7e2f7: #d7e2f7;\n$_d2e7fa: #d2e7fa;\n$_cddaf5: #cddaf5;\n$_e4e9f2: #e4e9f2;\n$_f1f5fc: #f1f5fc;\n$_2d3a54: #2d3a54;\n$_242e43: #242e43;\n$_414d65: #414d65;\n$_4c576d: #4c576d;\n$_697183: #697183;\n$_0047d3: #0047d3;\n$_eb4c4c: #eb4c4c;\n$_f4f4f4: #f4f4f4;\n$_a7abb2: #a7abb2;\n$_e4e9f2: #e4e9f2;\n$transparent: transparent;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn__container": `v3NUwXMmcG853OgKt6ZO`,
	"container": `DTwr_XpwtG7cvIkvBzYV`,
	"text": `XYoUSeG2ftlNewPMwMC2`
};
export default ___CSS_LOADER_EXPORT___;
