// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pATZ6exVPBnv8tu02fKo{display:flex;gap:5px;width:250px;min-width:100px}`, "",{"version":3,"sources":["webpack://./front/src/features/mobile-notification/widgets/header/components/mobile-notification-selector-group.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,OAAA,CACA,WAAA,CACA,eAAA","sourcesContent":[".container {\n  display: flex;\n  gap: 5px;\n  width: 250px;\n  min-width: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pATZ6exVPBnv8tu02fKo`
};
export default ___CSS_LOADER_EXPORT___;
