import { shallow } from 'zustand/shallow';
import { affiliatedCompanyPersonMeetingHistoryRepository } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/meeting-history/repository/repository';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';

export { useLogic as useAffiliatedCompanyPersonDetailModalMeetingHistoryLogic };

const useLogic = (menuId) => {
  const { modalId } = useAffiliatedCompanyPersonState(
    (state) => ({
      modalId: state.modalId,
    }),
    shallow
  );
  const { data: meetingList, isLoading } =
    affiliatedCompanyPersonMeetingHistoryRepository.usePersonMeetingHistoryListGet(modalId, menuId);
  const d = { meetingList };

  return {
    d,
    isLoading,
  };
};
