import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import {
  getReadOnlyForAuthorizationFromChildren,
  getReadonlyForAuthorizationFromMenu,
} from '@front/src/utils';
import { useMemo } from 'react';

export const useGetReadOnly = () => {
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const readOnly = useMemo(() => {
    if (!loginUser?.menus) return true;
    return (
      getReadonlyForAuthorizationFromMenu(loginUser.menus) &&
      getReadOnlyForAuthorizationFromChildren(loginUser.menus)
    );
  }, [loginUser]);
  return { readOnly };
};
