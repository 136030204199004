// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PjKw7GDGedp_wo2WijRv{color:#0047d3;font-weight:bold;cursor:pointer}.evXXmXpz4vSdLj1dDXRE{color:#0047d3;font-weight:bold;cursor:pointer;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:300px}.evXXmXpz4vSdLj1dDXRE:hover{text-decoration:underline}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/estimation/features/estimation/components/estimation-table-row.module.scss","webpack://./front/assets/colors.scss"],"names":[],"mappings":"AAEA,sBACE,aCiBQ,CDhBR,gBAAA,CACA,cAAA,CAGF,sBACE,aCWQ,CDVR,gBAAA,CACA,cAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CACA,eAAA,CAEA,4BACE,yBAAA","sourcesContent":["@import \"assets/colors.scss\";\n\n.code {\n  color: $_0047d3;\n  font-weight: bold;\n  cursor: pointer;\n}\n\n.file {\n  color: $_0047d3;\n  font-weight: bold;\n  cursor: pointer;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 300px;\n\n  &:hover {\n    text-decoration: underline;\n  }\n}","$_ffffff: #ffffff;\n$_9b9ea4: #9b9ea4;\n$_b2b4b7: #b2b4b7;\n$_94a6ca: #94a6ca;\n$_ffb72b: #ffb72b;\n$_fff2cc: #fff2cc;\n$_252627: #252627;\n$_386dd6: #386dd6;\n$_4c9eeb: #4c9eeb;\n$_9bb6ea: #9bb6ea;\n$_d7e2f7: #d7e2f7;\n$_d2e7fa: #d2e7fa;\n$_cddaf5: #cddaf5;\n$_e4e9f2: #e4e9f2;\n$_f1f5fc: #f1f5fc;\n$_2d3a54: #2d3a54;\n$_242e43: #242e43;\n$_414d65: #414d65;\n$_4c576d: #4c576d;\n$_697183: #697183;\n$_0047d3: #0047d3;\n$_eb4c4c: #eb4c4c;\n$_f4f4f4: #f4f4f4;\n$_a7abb2: #a7abb2;\n$_e4e9f2: #e4e9f2;\n$transparent: transparent;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"code": `PjKw7GDGedp_wo2WijRv`,
	"file": `evXXmXpz4vSdLj1dDXRE`
};
export default ___CSS_LOADER_EXPORT___;
