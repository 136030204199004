import { useMutation, useQueryClient } from 'react-query';
import { useCustomDialog } from '@front/src/features/dialog';
import type { SmProjectCollectionParameter } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/parameters';
import { handleError } from '@front/src/utils';
import { projectSalesInfoContractEarningsItemUpdateModalApi } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/query/api';

export const projectSalesInfoContractEarningsItemUpdateModalMutation = {
  useContractEarningsItemDetailsUpdate: (itemId: number, id: number) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: SmProjectCollectionParameter) =>
        projectSalesInfoContractEarningsItemUpdateModalApi.updateContractEarningsItemDetails(
          params
        ),
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'project-sales-info',
          'collection',
          'contract-earnings',
          'item',
          itemId,
        ]);
        await queryClient.invalidateQueries([
          'project-sales-info',
          'collection',
          'save-history',
          id,
        ]);
        await queryClient.invalidateQueries([
          'project-sales-info',
          'collection',
          'contract-earnings',
          'status',
          id,
        ]);
        await queryClient.invalidateQueries({
          queryKey: ['project-sales-info', 'collection', 'CM-save-history', id],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
