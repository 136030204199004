import { ManageableVariableIsUsed } from '@front/src/features/manageable-variable/types/domain';

export const adminManageableVariableApiURL = '/api/admin/variable-information';

export const keywordTypeOptionList = [
  {
    label: '관리 코드',
    value: 'code',
  },
  {
    label: '비고',
    value: 'note',
  },
];

export const manageableVariableIsUsedForLabel = {
  [ManageableVariableIsUsed.USED]: '사용',
  [ManageableVariableIsUsed.UNUSED]: '미사용',
};

export const isUsedOptionList = [
  {
    label: manageableVariableIsUsedForLabel[ManageableVariableIsUsed.USED],
    value: ManageableVariableIsUsed.USED,
  },
  {
    label: manageableVariableIsUsedForLabel[ManageableVariableIsUsed.UNUSED],
    value: ManageableVariableIsUsed.UNUSED,
  },
];

export const convertIsUsedToString = (value: boolean | undefined | null) => {
  if (value === undefined || value === null) return '';
  return value ? 'USED' : 'UNUSED';
};

export const defaultManageableVariablesValues = {
  keywordType: 'code',
  keyword: '',
};
