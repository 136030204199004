import React from 'react';
import { OldTd, Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { AffiliatedCompanyMeetingHistoryModalFormInfoRequesterSelector } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form-info-requester-selector';
import { AffiliatedCompanyMeetingHistoryModalFormInfoLocationSelector } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form-info-location-selector';
import { purposeCategoryOptionList } from '@front/src/features/affiliated-company/utils/constant';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { useAffiliatedCompanyMeetingHistoryState } from '@front/src/features/affiliated-company/features/meeting-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";

export { FormInfo as AffiliatedCompanyMeetingHistoryModalFormInfo };
const FormInfo = () => {
  const { login } = useSelector(
    (root: RootState) => ({
      login: root.login.detail,
    }),
    shallowEqual
  );
  const { readOnly } = useAffiliatedCompanyMeetingHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <Th width="140px">작성자</Th>
            <OldTd>{login?.name}</OldTd>
            <Th width="140px">출장일</Th>
            <OldTd>
              <DatePickerWithHookForm
                name={'date'}
                disabled={readOnly}
              />
            </OldTd>
            <Th width="140px">미팅요청자</Th>
            <OldTd>
              <AffiliatedCompanyMeetingHistoryModalFormInfoRequesterSelector />
            </OldTd>
          </TableRow>
          <TableRow>
            <Th width="140px">출장 장소</Th>
            <OldTd>
              <AffiliatedCompanyMeetingHistoryModalFormInfoLocationSelector />
            </OldTd>
            <OldTd colSpan={4}>
              <HookFormInput
                name="location"
                disabled={readOnly}
              />
            </OldTd>
          </TableRow>
          <TableRow>
            <Th width="140px">출장목적</Th>
            <TableCell>
              <HookFormSelect
                name="purpose"
                defaultLabel="선택"
                optionList={purposeCategoryOptionList}
                disabled={readOnly}
              />
            </TableCell>
            <OldTd colSpan={4}>
              <HookFormInput
                name="purposeDetail"
                placeholder="세부 목적을 작성하십시오"
                disabled={readOnly}
              />
            </OldTd>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
