import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import { useAffiliatedCompanyDetailModalCharacterPreferenceState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/widgets/useState';
import { affiliatedCompanyPersonPreferenceRepository } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/repository/repository';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';

export { useLogic as useAffiliatedCompanyDetailModalCharacterPreferenceLogic };

interface Props {
  id?: number;
  menuId?: number;
}

const useLogic = ({ id, menuId }: Props) => {
  const { idList, setIdList } = useAffiliatedCompanyDetailModalCharacterPreferenceState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { modalId } = useAffiliatedCompanyPersonState(
    (state) => ({
      modalId: state.modalId,
    }),
    shallow
  );
  const { data: preferenceList, isLoading } =
    affiliatedCompanyPersonPreferenceRepository.usePersonCharacterPreferenceGet(
      id ?? modalId,
      menuId
    );
  const { run: onCreatePreference } =
    affiliatedCompanyPersonPreferenceRepository.usePersonCharacterPreferenceCreate(menuId);
  const { run: onDeletePreference } =
    affiliatedCompanyPersonPreferenceRepository.usePersonCharacterPreferenceDelete(menuId);
  const { run: onUpdatePreference } =
    affiliatedCompanyPersonPreferenceRepository.usePersonCharacterPreferenceUpdate(menuId);
  const handlePreferenceCreate = useCallback(() => {
    onCreatePreference(modalId);
  }, [onCreatePreference, modalId]);
  const d = { preferenceList };
  const h = {
    onCreatePreference: handlePreferenceCreate,
    setIdList,
    onDeletePreference,
    onUpdatePreference,
  };
  return { d, h, isLoading, idList };
};
