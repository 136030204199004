import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

export { useState as useAccountingUploadSalesInfoGoalModalState };

interface State {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const useState = createWithEqualityFn<State>(
  (set) => ({
    isOpen: false,
    setIsOpen: (isOpen) =>
      set(() => ({
        isOpen,
      })),
  }),
  shallow
);
