import globalNavBarQuery from '@front/src/features/global-nav-bar/query/query';

const globalNavBarRepository = {
  useListGet: () => {
    const { data } = globalNavBarQuery.useMenuListGet();
    return { data };
  },
};

export default globalNavBarRepository;
