import React, { useMemo } from 'react';
import { useProjectSalesInfoAbstractProjectInfoDetailLogic } from '@front/src/features/project-sales-info/features/abstract/features/project-info/widgets/detail/provider/useLogic';
import { ProjectSalesInfoAbstractProjectInfoDetailContext } from '@front/src/features/project-sales-info/features/abstract/features/project-info/widgets/detail/provider/context';

export { Provider as ProjectSalesInfoAbstractProjectInfoDetailProvider };

interface Props {
  children: React.ReactNode;
}

const Provider = ({ children }: Props) => {
  const { detail } = useProjectSalesInfoAbstractProjectInfoDetailLogic();
  const value = useMemo(
    () => ({
      detail,
    }),
    [detail]
  );
  return (
    <ProjectSalesInfoAbstractProjectInfoDetailContext.Provider value={value}>
      {children}
    </ProjectSalesInfoAbstractProjectInfoDetailContext.Provider>
  );
};
