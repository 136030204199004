import { affiliatedCompanyPersonQuery } from '@front/src/features/affiliated-company/features/person/query/query';
import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyPersonMutation } from '@front/src/features/affiliated-company/features/person/query/mutation';

export const affiliatedCompanyPersonRepository = {
  usePersonListGet: (id?: number, menuId?) => {
    const { data, isLoading } = affiliatedCompanyPersonQuery.usePersonListGet(id, menuId);
    return {
      data,
      isLoading,
    };
  },
  usePersonCreate: (menuId) => {
    const { mutate } = affiliatedCompanyPersonMutation.usePersonCreate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
