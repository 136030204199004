import React from 'react';
import { ProjectSalesInfoSubjectReviewShapeRatioListProvider } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/features/list/widgets/provider';
import { ProjectSalesInfoSubjectReviewShapeRatioList } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/features/list/components/list';

export { Widget as ProjectSalesInfoSubjectReviewShapeRatioListWidget };

const Widget = () => (
  <ProjectSalesInfoSubjectReviewShapeRatioListProvider>
    <ProjectSalesInfoSubjectReviewShapeRatioList />
  </ProjectSalesInfoSubjectReviewShapeRatioListProvider>
);
