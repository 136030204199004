import React from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { Table } from '@front/layouts/Table';
import classes from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/components/CM-payment-history-table.module.scss';
import { ProjectSalesInfoCMPaymentHistoryTableList } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/components/CM-payment-history-table-list';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';

export { CmPaymentHistoryTable as ProjectSalesInfoCmPaymentHistoryTable };

const CmPaymentHistoryTable = () => (
  <TableContainer
    sx={{
      ...useGetMaxWidthSx({ hasMemo: true }),
    }}
  >
    <Table>
      <TableHead>
        <TableRow>
          <Th
            noWrap={true}
            className={classes.width__2}
          >
            {}
          </Th>
          <Th
            noWrap={true}
            className={classes.width__2}
          >
            순번
          </Th>
          <Th
            noWrap={true}
            className={classes.width__10}
          >
            대상자
          </Th>
          <Th
            noWrap={true}
            className={classes.width__6}
          >
            지급예정일
          </Th>
          <Th
            noWrap={true}
            className={classes.width__6}
          >
            지급액
          </Th>
          <Th
            noWrap={true}
            className={classes.width__6}
          >
            지급방법
          </Th>
          <Th
            noWrap={true}
            className={classes.width__4}
          >
            지급여부
          </Th>
          <Th
            noWrap={true}
            className={classes.width__4}
          >
            지급일
          </Th>
          <Th
            noWrap={true}
            className={classes.width__4}
          >
            비고
          </Th>
        </TableRow>
      </TableHead>
      <TableBody>
        <ProjectSalesInfoCMPaymentHistoryTableList />
      </TableBody>
    </Table>
  </TableContainer>
);
