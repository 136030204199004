import React, { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import CheckIcon from '@mui/icons-material/Check';
import { Checkbox } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { usePersonalSettingsModalUISettingsHeaderSettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/useState';

export { IsBlind as PersonalSettingsModalUISettingsHeaderSettingsTableContentIsBlind };

interface Props {
  index: number;
}

const IsBlind = ({ index }: Readonly<Props>) => {
  const { control, watch } = useFormContext();
  const { editOpenIdList } = usePersonalSettingsModalUISettingsHeaderSettingsState(
    useShallow((state) => ({
      editOpenIdList: state.editOpenIdList,
    }))
  );
  const open = useMemo(() => {
    const id = watch(`list.${index}.id`);
    return editOpenIdList.includes(id);
  }, [editOpenIdList, watch]);
  const {
    field: { value, onChange },
  } = useController({
    name: `list.${index}.isBlind`,
    control,
  });
  const content = useMemo(() => {
    if (open) {
      return (
        <Checkbox
          checked={value}
          onChange={onChange}
        />
      );
    }
    return value ? <CheckIcon /> : '-';
  }, [value, open]);
  return <>{content}</>;
};
