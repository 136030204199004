import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workReviewerMutation from '@front/src/features/work/features/work/features/reviewer/query/mutation';

const useWorkReviewerCreate = (menuId) => {
  const callback = getNoOpCallback();
  const { mutate, isLoading } = workReviewerMutation.useCreate(menuId);
  return {
    run: (workId: number) => mutate(workId, callback),
    setCallback: getSetCallbackFunc(callback),
    isLoading,
  };
};

export default useWorkReviewerCreate;
