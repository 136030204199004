import { Box } from '@mui/material';
import { FormikContext } from 'formik';
import type { FocusEvent } from 'react';
import React, { memo, useContext } from 'react';

import Input from '@front/layouts/Input';
import Button from '@front/layouts/Button';
import type { UserId } from '@front/user/domain';
import type { DefaultFunction } from '@front/type/Function';

interface Props {
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  onChange: (value: UserId | UserId[] | undefined) => void;
  onClick: DefaultFunction;
}

const OstMemoAddFormView = ({ onBlur, onClick }: Props) => {
  const formik = useContext(FormikContext);
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexWrap: 'unwrap',
            padding: '0 10px',
          }}
        >
          <Input
            required
            multiline
            key={formik.values.description}
            defaultValue={formik.values.description ?? ''}
            variant="outlined"
            placeholder="메모 입력"
            onBlur={onBlur}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexWrap: 'unwrap',
            justifyContent: 'space-between',
            padding: '0 10px',
            flex: 1,
            alignItems: 'center',
          }}
        >
          <Button
            disabled={formik.isSubmitting}
            fullWidth
            onClick={onClick}
          >
            작성 완료
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const OstMemoAddForm = memo(OstMemoAddFormView);

export default OstMemoAddForm;
