import React from 'react';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import useId from '@front/services/useId';

interface ButtonProps {
  children: string;
  path: string;
}

const propsList: ButtonProps[] = [
  {
    children: '일반',
    path: 'detail',
  },
  {
    children: 'OST 이력',
    path: 'ost-log',
  },
];
const PersonnelContainerTab = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const id = useId();

  if (!id) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        padding: '0 20px',
        width: '100%',
        justifyContent: 'flex-start',
        borderBottom: `2px solid ${ColorPalette._e4e9f2}`,
        height: '38px',
        '& > div:not(:last-child)': {
          marginRight: '5px',
        },
      }}
    >
      {propsList.map((props) => {
        const selected = pathname.endsWith(`/${props.path}`);
        return (
          <Box
            key={props.path}
            children={props.children}
            sx={{
              flex: 1,
              display: 'flex',
              maxWidth: '200px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '5px 5px 0 0',
              border: `2px solid ${ColorPalette._e4e9f2}`,
              borderBottom: 'none',
              height: '38px',
              backgroundColor: selected ? ColorPalette._ffffff : ColorPalette._e4e9f2,
              cursor: selected ? 'default' : 'pointer',
              fontSize: '14px',
              fontWeight: selected ? 'bold' : 'inherit',
              color: ColorPalette._252627,
            }}
            onClick={() => {
              navigate(`/hr-card-management/${id}/${props.path}`);
            }}
          />
        );
      })}
    </Box>
  );
};

export default PersonnelContainerTab;
