export interface ProceedingsQuery {
  keyword: string;
  department: string;
  status: string;
  startTime: string;
  endTime: string;
}

export const initialProceedingsQuery: ProceedingsQuery = {
  keyword: '',
  department: '',
  status: '',
  startTime: '',
  endTime: '',
};

export interface ProceedingsMemoQuery {
  keyword: string;
}

export const initialProceedingsMemoQuery: ProceedingsMemoQuery = {
  keyword: '',
};
