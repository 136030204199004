import { useCallback } from 'react';

import { useDialogState } from '@front/src/features/dialog/widgets/useState';
import type { AlertView, ConfirmView } from '@front/src/features/dialog/types';
import { useShallow } from 'zustand/react/shallow';

export const useCustomDialog = () => {
  const { openConfirm, openAlert } = useDialogState(
    useShallow((state) => ({
      openConfirm: state.openConfirm,
      openAlert: state.openAlert,
    }))
  );

  const alert = useCallback((props: AlertView) => openAlert(props), [openAlert]);

  const error = useCallback((props: AlertView) => openAlert(props), [openAlert]);

  const confirm = useCallback((props: ConfirmView) => openConfirm(props), [openConfirm]);

  return {
    alert,
    error,
    confirm,
  };
};
