import React from 'react';
import { SalesInfoActivityTableRow } from '@front/src/features/project-sales-info/features/activity/widgets/row/components/row';
import type { SalesActivityAffiliatesConvertedView } from '@front/src/features/project-sales-info/features/activity/types/views';
import { SalesInfoActivityRowProvider } from '@front/src/features/project-sales-info/features/activity/widgets/row/provider/proivder';

export { Widget as SalesInfoActivityRowWidget };

interface Props {
  item: SalesActivityAffiliatesConvertedView;
}

const Widget = ({ item }: Props) => (
  <SalesInfoActivityRowProvider item={item}>
    <SalesInfoActivityTableRow />
  </SalesInfoActivityRowProvider>
);
