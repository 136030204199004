import type { ReactNode } from 'react';
import React from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { WorkTh } from '@front/src/features/work/features/work/components/work-th';

export { AuthorizationTable as MyPageAuthorizationTable };

interface Props {
  children: ReactNode;
}

const AuthorizationTable = ({ children }: Props) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <WorkTh colSpan={3}>권한</WorkTh>
        </TableRow>
        <TableRow>
          <WorkTh>보기 (V)</WorkTh>
          <WorkTh>읽기 (R)</WorkTh>
          <WorkTh>읽기 + 쓰기 (W)</WorkTh>
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  </TableContainer>
);
