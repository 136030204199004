import { AccountingSettingsManagementAccountingAccountTableLayoutTable } from '@front/src/features/accounting/features/settings/features/management-accounting-account/components/layouts/table/table';
import { AccountingSettingsManagementAccountingAccountTableLayoutTableHeader } from '@front/src/features/accounting/features/settings/features/management-accounting-account/components/layouts/table/table-header';
import { AccountingSettingsManagementAccountingAccountTableLayoutTableHeaderCell } from '@front/src/features/accounting/features/settings/features/management-accounting-account/components/layouts/table/table-header-cell';
import { AccountingSettingsManagementAccountingAccountTableLayoutTableRowFlexColumn } from '@front/src/features/accounting/features/settings/features/management-accounting-account/components/layouts/table/table-row-flex-column';
import { AccountingSettingsManagementAccountingAccountTableLayoutTableBody } from '@front/src/features/accounting/features/settings/features/management-accounting-account/components/layouts/table/table-body';
import { AccountingSettingsManagementAccountingAccountTableLayoutTableBodyCell } from '@front/src/features/accounting/features/settings/features/management-accounting-account/components/layouts/table/table-body-cell';

export { index as accountingSettingsManagementAccountingAccountTableLayout };

const index = {
  Table: AccountingSettingsManagementAccountingAccountTableLayoutTable,
  TableHeader: AccountingSettingsManagementAccountingAccountTableLayoutTableHeader,
  TableHeaderCell: AccountingSettingsManagementAccountingAccountTableLayoutTableHeaderCell,
  TableRowFlexColumn: AccountingSettingsManagementAccountingAccountTableLayoutTableRowFlexColumn,
  TableBody: AccountingSettingsManagementAccountingAccountTableLayoutTableBody,
  TableBodyCell: AccountingSettingsManagementAccountingAccountTableLayoutTableBodyCell,
};
