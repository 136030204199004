import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import type {
  UserNotificationIsReadParameter,
  UserNotificationParameter,
} from '@front/src/features/mobile-notification/types/parameter';

interface State {
  onToggle: (params: UserNotificationIsReadParameter) => void;
  onNavigateToggle: (params: UserNotificationIsReadParameter) => void;
  onDelete: (id: number) => void;
  onPinUpdate: (params: UserNotificationParameter) => void;
}

export { Context as MobileNotificationListItemContext };

const Context = createContext<State>({
  onToggle: noOp,
  onDelete: noOp,
  onPinUpdate: noOp,
  onNavigateToggle: noOp,
});
