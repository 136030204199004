import React from 'react';
import { ProjectSalesInfoSubjectReviewShapeRatioListItemProvider } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/features/list/features/list-item/widgets/provider';
import { ProjectSalesInfoSubjectReviewShapeRatioListItem } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/features/list/features/list-item/components/list-item';
import type { ProjectAspectRatioExaminationView } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/types/view';

export { Widget as ProjectSalesInfoSubjectReviewShapeRatioListItemWidget };

interface Props {
  item: ProjectAspectRatioExaminationView;
  index: number;
  existIsConfirmed: boolean;
}

const Widget = ({ item, index, existIsConfirmed }: Props) => (
  <ProjectSalesInfoSubjectReviewShapeRatioListItemProvider>
    <ProjectSalesInfoSubjectReviewShapeRatioListItem
      item={item}
      index={index}
      existIsConfirmed={existIsConfirmed}
    />
  </ProjectSalesInfoSubjectReviewShapeRatioListItemProvider>
);
