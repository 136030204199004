import type { ReactNode } from 'react';
import React from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { Table as MuiTable } from '@front/layouts/Table';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';

export { Table as ProjectSalesInfoAbstractCollectionTable };

interface Props {
  children: ReactNode;
  maxWidth?: string;
}

const Table = ({ children }: Props) => (
  <TableContainer
    sx={{
      maxHeight: '400px',
      ...useGetMaxWidthSx({ hasMemo: true }),
      overflowX: 'auto',
    }}
  >
    <MuiTable>
      <TableHead>
        <TableRow>
          <Th width="100px">기성단계</Th>
          <Th width="160px">계약액</Th>
          <Th width="160px">순금액</Th>
          <Th width="160px">순수금액</Th>
          <Th width="160px">순미수금액</Th>
          <Th width="100px">입금일</Th>
          <Th width="100px">한풍예측일</Th>
          <Th width="100px">수금가능성</Th>
          <Th width="100px">기성Case</Th>
          <Th width="120px">미수금사유</Th>
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </MuiTable>
  </TableContainer>
);
