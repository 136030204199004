import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import type { ProposalUpdateArrangementPlanParameter } from '@front/ost_proposal/parameter';
import type { ProposalArrangementPlanId } from '@front/ost_proposal/domain';
import { PlanItem } from '@front/ost_proposal/view/detail/advisorForm/table/Item';

interface Props {
  onUpdate: (params: ProposalUpdateArrangementPlanParameter) => void;
  onDelete: (id: ProposalArrangementPlanId) => void;
  isAdvisor: boolean;
}

export { List as PlanList };
const List = ({ onUpdate, onDelete, isAdvisor }: Props) => {
  const [proposalArrangementPlanList] = useSelector(
    (root: RootState) => [root.proposal.proposalArrangementPlanList],
    shallowEqual
  );
  return (
    <>
      {proposalArrangementPlanList.length === 0 && (
        <TableRow>
          <OldTd colSpan={5}>{'데이터가 없습니다.'}</OldTd>
        </TableRow>
      )}
      {proposalArrangementPlanList.map((item, index) => (
        <PlanItem
          key={item.id}
          item={item}
          index={index}
          onUpdate={onUpdate}
          onDelete={onDelete}
          isAdvisor={isAdvisor}
        />
      ))}
    </>
  );
};
