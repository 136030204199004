import Button from '@front/layouts/Button';
import React, { useContext } from 'react';
import classes from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/features/create-modal/components/create-modal.module.scss';
import { useProjectSalesInfoCollectionManagementModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/widgets/useState';
import { useFormContext } from 'react-hook-form';
import type { ProjectCollectionManagementParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/types/parameter';
import { ProjectSalesInfoCollectionManagementModalCreateModalContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/features/create-modal/widgets/context';
import dayjs from 'dayjs';
import {formatDateOrNull} from "@front/src/features/project-sales-info/utils";

export { CreateModalBtn as ProjectSalesInfoCollectionManagementModalCreateModalBtn };

const CreateModalBtn = () => {
  const { setIsCreateModalOpen, addCollectionManagementList } =
    useProjectSalesInfoCollectionManagementModalState((state) => ({
      setIsCreateModalOpen: state.setIsCreateModalOpen,
      addCollectionManagementList: state.addCollectionManagementList,
    }));
  const {
    d: { affiliatedPersonList },
  } = useContext(ProjectSalesInfoCollectionManagementModalCreateModalContext);
  const { handleSubmit, getValues } = useFormContext<ProjectCollectionManagementParams>();
  const onClick = handleSubmit((data) => {
    addCollectionManagementList({
      ...data,
      collectionDueDate: formatDateOrNull(data.collectionDueDate),
      billingDate: formatDateOrNull(data.billingDate),
      collectionForecastDate: formatDateOrNull(data.collectionForecastDate),
      affiliatedPersonName:
        affiliatedPersonList?.find((item) => item.person?.id === +getValues('affiliatedPersonId'))
          ?.person?.name ?? '',
      createdAt: dayjs().format('YYYY-MM-DD'),
    });
    setIsCreateModalOpen();
  });

  return (
    <div className={classes.btn__group}>
      <Button
        shape="basic2"
        fullWidth={true}
        onClick={() => setIsCreateModalOpen()}
      >
        취소
      </Button>
      <Button
        fullWidth={true}
        onClick={onClick}
      >
        저장
      </Button>
    </div>
  );
};
