import React from 'react';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import TextBox from '@front/layouts/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { ProposalStatus } from '@front/ost_proposal/domain';

function Status() {
  const { status, ratioEstimated, ratioApproved, ratioVoted } = useSelector(
    (root: RootState) => ({
      status: root.proposal.detail!.status,
      advisor: root.proposal.detail!.advisor,
      ratioEstimated: root.proposal.detail!.ratioEstimated,
      ratioApproved: root.proposal.detail!.ratioApproved,
      ratioVoted: root.proposal.detail!.ratioVoted,
    }),
    shallowEqual
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        width: '100%',
        padding: '10px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'inherit',
          width: '100%',
          padding: '10px',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'inherit',
            justifyContent: 'center',
            border: `1px solid ${ColorPalette._e4e9f2}`,
            padding: '15px',
            height: '68px',
            borderRadius: '5px',
            minWidth: '100px',
            backgroundColor:
              status === ProposalStatus.WRITING || status === ProposalStatus.SUBMISSION
                ? ColorPalette._9bb6ea
                : null,
          }}
        >
          <TextBox variant="body9">{status !== ProposalStatus.WRITING ? '제출' : '작성'}</TextBox>
        </Box>
        <Box
          sx={{
            display: 'inherit',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon icon="arrow-right-long" />
        </Box>
        <Box
          sx={{
            display: 'inherit',
            justifyContent: 'center',
            height: '68px',
            border: `1px solid ${ColorPalette._e4e9f2}`,
            padding: '15px',
            borderRadius: '5px',
            minWidth: '100px',
            backgroundColor: status === ProposalStatus.ESTIMATING ? ColorPalette._9bb6ea : null,
          }}
        >
          <TextBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
            }}
            variant="body9"
          >
            <Box>심사</Box>
            {[ProposalStatus.ESTIMATING].includes(status) && <Box>{`(${ratioEstimated})`}</Box>}
          </TextBox>
        </Box>
        <Box
          sx={{
            display: 'inherit',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon icon="arrow-right-long" />
        </Box>
        <Box
          sx={{
            display: 'inherit',
            justifyContent: 'center',
            height: '68px',
            border: `1px solid ${ColorPalette._e4e9f2}`,
            padding: '15px',
            borderRadius: '5px',
            minWidth: '100px',
            backgroundColor: status === ProposalStatus.APPROVING ? ColorPalette._9bb6ea : null,
          }}
        >
          <TextBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
            }}
            variant="body9"
          >
            <Box>결재</Box>
            {ratioApproved !== null && [ProposalStatus.APPROVING].includes(status) && (
              <Box>{`(${ratioApproved})`}</Box>
            )}
          </TextBox>
        </Box>
        <Box
          sx={{
            display: 'inherit',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon icon="arrow-right-long" />
        </Box>
        <Box
          sx={{
            display: 'inherit',
            justifyContent: 'center',
            height: '68px',
            border: `1px solid ${ColorPalette._e4e9f2}`,
            padding: '15px',
            borderRadius: '5px',
            minWidth: '100px',
            backgroundColor:
              status === ProposalStatus.VOTING || status === ProposalStatus.STOP_VOTING
                ? ColorPalette._9bb6ea
                : null,
          }}
        >
          <TextBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
            }}
            variant="body9"
          >
            <Box>{status === ProposalStatus.STOP_VOTING ? '투찰중지' : '투찰'}</Box>
            {[ProposalStatus.STOP_VOTING, ProposalStatus.VOTING].includes(status) && (
              <Box>{`(${ratioVoted})`}</Box>
            )}
          </TextBox>
        </Box>
        <Box
          sx={{
            display: 'inherit',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon icon="arrow-right-long" />
        </Box>
        <Box
          sx={{
            display: 'inherit',
            justifyContent: 'center',
            height: '68px',
            border: `1px solid ${ColorPalette._e4e9f2}`,
            padding: '15px',
            borderRadius: '5px',
            minWidth: '100px',
            backgroundColor:
              status === ProposalStatus.END || status === ProposalStatus.DENY
                ? ColorPalette._9bb6ea
                : null,
          }}
        >
          <TextBox variant="body9">종료</TextBox>
        </Box>
      </Box>
    </Box>
  );
}

export default Status;
