import {
  AffiliatedCompanyStatus,
  FamilyRelation,
  GiftTargetType,
  HomeTown,
  PersonStatus,
  PersonTabType,
  PreferenceLevel,
  PurposeCategory,
  Sex,
  TransportationType,
} from '@front/src/features/affiliated-company/types/domain';
import type { OptionType } from '@front/src/components/Select';

export const baseUrl = `/api/affiliated-company`;
export const personBaseUrl = `/api/affiliated-company-person`;

export const defaultAffiliatedCompanyPath = '/affiliated-company';
export const affiliatedPersonPath = '/affiliated-person';

export const workPath = '/work';

export const COMPANY_CONTACT_NUMBER_REGEX = /^(1544|1566|1577|1588|1644|1688)-?(\d{4})$/;
export const FAX_NUMBER_REGEX = /^(0[2-8][0-5]?)-?(\d\d{2,3})-?(\d{4})$/;
export const EMAIL_REGEX = /^\w([-_.]?\w)*@\w([-_.]?\w)*.\w{2,3}$/i;
export const BUSINESS_REGISTRATION_NUMBER_CHANGE_REGEX = /^(\d{1,3})(\d{0,2})?(\d{0,5})?$/;
export const URL_REGEX = /^(((http(s?))\/\/)?)([0-9a-zA-Z]+(\.)?)+[a-zA-Z]{2,6}(\d+)?(\/\S*)?/;
export const BUSINESS_REGISTRATION_NUMBER_REGEX = /^(\d{3})-(\d{2})-(\d{5})$/;

export const YEAR_REGEX = /^\d{4}$/;
export const AMOUNT_CHANGE_REGEX = /\B(?=(\d{3})+(?!\d))/g;
export const MOBILE_CHANGE_REGEX = /^010(\d{0,4})(\d{0,4})$/;
export const MOBILE_REGEX = /^010-(\d{4})-(\d{4})$/;
export const YEAR_MONTH_DAY_REGEX = /^((19|20)\d{2})-(0[1-9]|1[0-2])-([0-2][1-9]|[1-2]0|3[01])$/;
export const POSITIVE_NUMBER_REGEX = /^[1-9]\d*$/;
export const NOT_INTEGER_REGEX = /^[^0-9-]*|\D*/g;

export const TOTAL_DATE_REGEX =
  /^((19|20)\d{2})-(0[1-9]|1[0-2])-([0-2][1-9]|[1-2]0|3[01]) ([01][0-9]|2[0-3]):[0-5][0-9]$/;

export const convertAffiliatedPersonTabObjForLabel = {
  [PersonTabType.OUTLINE]: '개요',
  [PersonTabType.CHARACTER]: '특징',
  [PersonTabType.RELATION]: '인맥',
  [PersonTabType.GIFT_HISTORY]: '선물이력',
  [PersonTabType.MEETING_HISTORY]: '미팅이력',
  [PersonTabType.SALES_ACTIVITY]: '영업활동',
};

export const affiliatedPersonTabList = [
  {
    label: convertAffiliatedPersonTabObjForLabel[PersonTabType.OUTLINE],
    value: PersonTabType.OUTLINE,
    to: 'outline',
  },
  {
    label: convertAffiliatedPersonTabObjForLabel[PersonTabType.CHARACTER],
    value: PersonTabType.CHARACTER,
    to: 'character',
  },
  {
    label: convertAffiliatedPersonTabObjForLabel[PersonTabType.RELATION],
    value: PersonTabType.RELATION,
    to: 'relation',
  },
  {
    label: convertAffiliatedPersonTabObjForLabel[PersonTabType.GIFT_HISTORY],
    value: PersonTabType.GIFT_HISTORY,
    to: 'gift-history',
  },
  {
    label: convertAffiliatedPersonTabObjForLabel[PersonTabType.MEETING_HISTORY],
    value: PersonTabType.MEETING_HISTORY,
    to: 'meeting-history',
  },
  {
    label: convertAffiliatedPersonTabObjForLabel[PersonTabType.SALES_ACTIVITY],
    value: PersonTabType.SALES_ACTIVITY,
    to: 'sales-activity',
  },
];

export const booleanOptionList: OptionType[] = [
  {
    label: 'O',
    value: 'O',
  },
  {
    label: 'X',
    value: 'X',
  },
];

export const sexOptionList: OptionType[] = [
  {
    label: '남',
    value: Sex.MALE,
  },
  {
    label: '여',
    value: Sex.FEMALE,
  },
];

export const YNOptionList: OptionType[] = [
  {
    label: '예',
    value: '예',
  },
  {
    label: '아니오',
    value: '아니오',
  },
];

export const familyOptionList: OptionType[] = [
  {
    label: '배우자',
    value: FamilyRelation.SPOUSE,
  },
  {
    label: '아들',
    value: FamilyRelation.SON,
  },
  {
    label: '딸',
    value: FamilyRelation.DAUGHTER,
  },
  {
    label: '부',
    value: FamilyRelation.FATHER,
  },
  {
    label: '모',
    value: FamilyRelation.MOTHER,
  },
  {
    label: '조부',
    value: FamilyRelation.GRANDFATHER,
  },
  {
    label: '조모',
    value: FamilyRelation.GRANDMOTHER,
  },
  {
    label: '기타',
    value: FamilyRelation.OTHER,
  },
];

export const familyTableHeadList = ['관계', '이름', '나이', '생일'];

export const purposeCategoryOptionList: OptionType[] = [
  {
    label: '킥오프',
    value: PurposeCategory.KICK_OFF,
  },
  {
    label: '일정 관련',
    value: PurposeCategory.SCHEDULE,
  },
  {
    label: '기술 관련',
    value: PurposeCategory.TECH,
  },
  {
    label: '영업',
    value: PurposeCategory.SALES,
  },
  {
    label: '기타',
    value: PurposeCategory.ETC,
  },
];

export const transportationTypeList: OptionType[] = [
  {
    label: '차량',
    value: TransportationType.CAR,
  },
  {
    label: '대중교통',
    value: TransportationType.PUBLIC_TRANSPORTATION,
  },
  {
    label: '기타',
    value: TransportationType.ETC,
  },
];

export const giftTargetTypeList: OptionType[] = [
  {
    label: '개인',
    value: GiftTargetType.PERSON,
  },
  {
    label: '회사',
    value: GiftTargetType.COMPANY,
  },
];

export const preferenceLevelList: OptionType[] = [
  {
    label: '최상',
    value: PreferenceLevel.TOP,
  },
  {
    label: '상',
    value: PreferenceLevel.HIGH,
  },
  {
    label: '중',
    value: PreferenceLevel.MIDDLE,
  },
  {
    label: '하',
    value: PreferenceLevel.LOW,
  },
  {
    label: '최하',
    value: PreferenceLevel.BOTTOM,
  },
];

export const affiliatedCompanyStatusRadioList: OptionType<AffiliatedCompanyStatus>[] = [
  { label: '정상', value: AffiliatedCompanyStatus.NORMAL },
  { label: '폐업', value: AffiliatedCompanyStatus.CLOSED },
];

export const personStatusRadioList: OptionType<PersonStatus>[] = [
  { label: '재직', value: PersonStatus.EMPLOYED },
  { label: '퇴사', value: PersonStatus.RESIGNATION },
];

export const affiliatedCompanyProjectURL = `/api/affiliated-company`;

export const affiliatedCompanyPersonHomeTownForLabel = {
  [HomeTown.SEOUL]: '서울',
  [HomeTown.BUSAN]: '부산',
  [HomeTown.DAEGU]: '대구',
  [HomeTown.GWANGJU]: '광주',
  [HomeTown.INCHEON]: '인천',
  [HomeTown.DAEJEON]: '대전',
  [HomeTown.ULSAN]: '울산',
  [HomeTown.GYEONGGI_DO]: '경기',
  [HomeTown.GANGWON_DO]: '강원도',
  [HomeTown.CHUNGCHEONGBUK_DO]: '충청북도',
  [HomeTown.CHUNGCHEONGNAM_DO]: '충청남도',
  [HomeTown.JEOLLABUK_DO]: '전라북도',
  [HomeTown.JEOLLANAM_DO]: '전라남도',
  [HomeTown.GYEONGSANGBUK_DO]: '경상북도',
  [HomeTown.GYEONGSANGNAM_DO]: '경상남도',
  [HomeTown.JEJU_DO]: '제주도',
};

export const affiliatedCompanyPersonHomeTownOptionList = [
  {
    label: affiliatedCompanyPersonHomeTownForLabel[HomeTown.SEOUL],
    value: HomeTown.SEOUL,
  },
  {
    label: affiliatedCompanyPersonHomeTownForLabel[HomeTown.GYEONGGI_DO],
    value: HomeTown.GYEONGGI_DO,
  },
  {
    label: affiliatedCompanyPersonHomeTownForLabel[HomeTown.CHUNGCHEONGNAM_DO],
    value: HomeTown.CHUNGCHEONGNAM_DO,
  },
  {
    label: affiliatedCompanyPersonHomeTownForLabel[HomeTown.CHUNGCHEONGBUK_DO],
    value: HomeTown.CHUNGCHEONGBUK_DO,
  },
  {
    label: affiliatedCompanyPersonHomeTownForLabel[HomeTown.JEOLLABUK_DO],
    value: HomeTown.JEOLLABUK_DO,
  },
  {
    label: affiliatedCompanyPersonHomeTownForLabel[HomeTown.JEOLLANAM_DO],
    value: HomeTown.JEOLLANAM_DO,
  },
  {
    label: affiliatedCompanyPersonHomeTownForLabel[HomeTown.GANGWON_DO],
    value: HomeTown.GANGWON_DO,
  },
  {
    label: affiliatedCompanyPersonHomeTownForLabel[HomeTown.GYEONGSANGNAM_DO],
    value: HomeTown.GYEONGSANGNAM_DO,
  },
  {
    label: affiliatedCompanyPersonHomeTownForLabel[HomeTown.GYEONGSANGBUK_DO],
    value: HomeTown.GYEONGSANGBUK_DO,
  },
  {
    label: affiliatedCompanyPersonHomeTownForLabel[HomeTown.JEJU_DO],
    value: HomeTown.JEJU_DO,
  },
];
