import { accountingUploadRepository } from '@front/src/features/accounting/features/upload/features/ecount-data/repository/repository';
import { ECOUNT_FILE_TYPES } from '@front/src/features/accounting/features/upload/features/ecount-data/types/domain';

export { useLogic as useAccountingSettingsManagementAccountingAccountHeaderGetUpdatedAtLogic };
const useLogic = () => {
  const { data: list } = accountingUploadRepository.useGetList();
  return {
    detail: list?.filter((t) => t.category === ECOUNT_FILE_TYPES.FINANCIAL_ACCOUNT).at(-1),
  };
};
