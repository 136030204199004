import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type {
  WorkOutputBundleCreateParameter,
  WorkOutputBundleUpdateParameter,
} from '@front/src/features/work/features/work/features/output-bundle/types/parameter';

const workOutputBundleMutation = {
  useCreate: (workId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: WorkOutputBundleCreateParameter) =>
        workWorkApi.createOutputBundle(params, workId, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle'],
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useUpdate: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: WorkOutputBundleUpdateParameter) =>
        workWorkApi.updateOutputBundle(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle'],
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useDelete: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.deleteOutputBundle(id, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle'],
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
};

export default workOutputBundleMutation;
