import { useQuery } from 'react-query';
import { accessAuthSettingsApi } from '@front/src/features/admin/features/access-authorization-settings/api/api';

const adminAccessAuthSettingsQuery = {
  useTabListGet: (menuId: number, authMenuId?: number) => {
    const { data } = useQuery({
      queryKey: ['admin', 'access-auth-settings', 'tab-list', menuId],
      queryFn: () => accessAuthSettingsApi.getTabList(menuId, authMenuId),
      suspense: true,
    });
    return {
      data,
    };
  },
};

export default adminAccessAuthSettingsQuery;
