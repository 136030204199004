import React, { useContext } from 'react';
import { TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { FormProvider, useForm } from 'react-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/widgets/context';
import { ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalCompanySelect } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/components/company-select';
import { ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalWebHardSelect } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/components/web-hard-select';
import { convertNullToEmptyForForm } from '@front/src/utils';
import type { ProjectSalesInfoAffiliatedCompanyWebHardDetailView } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/types/view';
import { ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalButtonGroup } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/components/button-group';

export { ModalForm as ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalForm };

const ModalForm = () => {
  const {
    d: { detail },
  } = useContext(ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalContext);
  const methods = useForm({
    values: getValues(detail),
  });
  return (
    <FormProvider {...methods}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <Th>회사명</Th>
              <TableCell>
                <ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalCompanySelect />
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>웹하드</Th>
              <TableCell>
                <ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalWebHardSelect />
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>ID</Th>
              <TableCell>
                <HookFormInput name="webHardUsername" />
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>PW</Th>
              <TableCell>
                <HookFormInput name="webHardPassword" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalButtonGroup />
    </FormProvider>
  );
};

const getValues = (detail?: ProjectSalesInfoAffiliatedCompanyWebHardDetailView) => ({
  companyId: convertNullToEmptyForForm(detail?.affiliatedCompanyId),
  webHardId: '',
  webHardUsername: convertNullToEmptyForForm(detail?.webHardUsername),
  webHardPassword: convertNullToEmptyForForm(detail?.webHardPassword),
});
