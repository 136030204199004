import React, { useMemo } from 'react';

import type { ProposalContentVO } from '@front/ost_proposal/domain';
import EditorComponent from '@front/components/TextEditor/EditorComponent';
import type { ProposalContentChangeParameter } from '@front/ost_proposal/parameter';

interface Props {
  item: ProposalContentVO;
  updateContent: (params: ProposalContentChangeParameter) => void;
  readOnly: boolean;
  dataId?: number;
  filePath?: string;
}

export const ContentTextForm = ({ item, updateContent, readOnly, dataId, filePath }: Props) => {
  const value = useMemo(
    () => ({
      id: item.id,
      beforeContent: item.beforeContent,
    }),
    [item]
  );

  return (
    <EditorComponent
      value={value}
      update={updateContent}
      readOnly={readOnly}
      dataId={dataId}
      filePath={filePath}
    />
  );
};
