import React, { useCallback, useContext, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Table } from '@front/src/components/layout/table/table';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { OldTd } from '@front/layouts/Table';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { addressModalAction } from '@front/components/AddressModal/action';
import { useDispatch } from 'react-redux';
import Button from '@front/layouts/Button';
import {
  convertBooleanToYN,
  convertDataFromDesignOutlineForForm,
  YNOptionList,
} from '@front/src/features/project-sales-info/features/design-outline/utils/constants';
import { ProjectSalesInfoDesignOutlineContext } from '@front/src/features/project-sales-info/features/design-outline/widgets/context';
import { changeCityDataToOptionData } from '@front/src/features/project-sales-info/features/project-division/utils/constants';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { AddressModal } from '@front/components/AddressModal/AddressModal';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import { useProjectSalesInfoDesignOutlineCityState } from '@front/src/features/project-sales-info/features/design-outline/widgets/useState';
import { convertToOptionList } from '@front/src/utils';
import { useShallow } from 'zustand/react/shallow';

/**
 * @module 영업정보>설계개요탭>이름없는섹션
 */
export { Form as ProjectSalesInfoDesignOutlineForm };

const Form = () => {
  const {
    h: { onUpdate, setRegCode },
    d: { projectDesignOutlineData, structurePurposeOptionList, cityOptionList2, cityOptionList1 },
  } = useContext(ProjectSalesInfoDesignOutlineContext);
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const regCode = useProjectSalesInfoDesignOutlineCityState((state) => state.regCode);
  const methods = useForm({
    values: {
      id: projectDesignOutlineData?.id,
      address: projectDesignOutlineData?.address ?? '',
      city1: projectDesignOutlineData?.city1 ?? '',
      city2: projectDesignOutlineData?.city2 ?? '',
      blockArea: projectDesignOutlineData?.blockArea ?? '',
      landArea: projectDesignOutlineData?.landArea ?? '',
      grossArea: projectDesignOutlineData?.grossArea ?? '',
      highest: projectDesignOutlineData?.highest ?? '',
      topFloor: projectDesignOutlineData?.topFloor ?? '',
      totalCountOfComplex: projectDesignOutlineData?.totalCountOfComplex ?? '',
      countOfHousehold: projectDesignOutlineData?.countOfHousehold ?? '',
      buildPurpose: projectDesignOutlineData?.buildPurpose ?? '',
      isTargetOfSafetyEvaluation: convertBooleanToYN(
        projectDesignOutlineData?.isTargetOfSafetyEvaluation
      ),
    },
  });
  const dispatch = useDispatch();
  const openAddressModal = useCallback(
    () => dispatch(addressModalAction.addressModal(true)),
    [dispatch]
  );
  const { handleSubmit, control } = methods;
  const onSubmit = handleSubmit((data) => {
    onUpdate({
      ...convertDataFromDesignOutlineForForm(data),
    });
  });
  const onCitySubmit = handleSubmit((data) => {
    onUpdate({
      ...convertDataFromDesignOutlineForForm(data),
      city2: null,
    });
    setRegCode(data.city1);
  });
  const { readOnly } = useProjectSalesInfoDesignOutlineCityState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  useEffect(() => {
    setRegCode(projectDesignOutlineData?.city1 ?? '');
  }, []);
  useEffect(() => {
    if (!regCode) return;
    methods.setValue('city2', projectDesignOutlineData?.city2 ?? '');
  }, [regCode]);
  const disabled = !useWatch({ control, name: 'city1' });
  return (
    <FormProvider {...methods}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <Th>주소</Th>
                <OldTd
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '58px',
                  }}
                >
                  <HookFormInput
                    name="address"
                    onClick={() => {
                      if (readOnly) return;
                      openAddressModal();
                    }}
                    width="85%"
                    onSubmit={onSubmit}
                    openModal={true}
                    readOnly={true}
                  />
                  <AddressModal
                    position="left"
                    updateByDispatch={{
                      onUpdate: (params) => {
                        methods.setValue('address', params.address);
                        onSubmit();
                      },
                      id,
                    }}
                  />
                  <Button
                    disabled={readOnly}
                    onClick={() => {
                      openAddressModal();
                    }}
                  >
                    주소 검색
                  </Button>
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>시/도</Th>
                <OldTd>
                  <HookFormSelect
                    isDefaultPossible
                    name="city1"
                    optionList={changeCityDataToOptionData(cityOptionList1)}
                    width="25%"
                    defaultLabel="선택"
                    onSubmit={onCitySubmit}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>시/군/구</Th>
                <OldTd>
                  {cityOptionList2 && cityOptionList2?.length > 0 && (
                    <HookFormSelect
                      isDefaultPossible
                      name="city2"
                      optionList={changeCityDataToOptionData(cityOptionList2)}
                      width="25%"
                      defaultLabel="선택"
                      onSubmit={onSubmit}
                      disabled={disabled || readOnly}
                    />
                  )}
                </OldTd>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <Th>구역면적(㎡)</Th>
                <OldTd>
                  <HookFormInput
                    name="blockArea"
                    type="number"
                    width="20%"
                    onSubmit={onSubmit}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>대지면적(㎡)</Th>
                <OldTd>
                  <HookFormInput
                    name="landArea"
                    type="number"
                    width="20%"
                    onSubmit={onSubmit}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>연면적(㎡)</Th>
                <OldTd>
                  <HookFormInput
                    name="grossArea"
                    type="number"
                    width="20%"
                    onSubmit={onSubmit}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>최고높이(m)</Th>
                <OldTd>
                  <HookFormInput
                    name="highest"
                    type="number"
                    width="20%"
                    onSubmit={onSubmit}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>최고층수</Th>
                <OldTd>
                  <HookFormInput
                    name="topFloor"
                    type="number"
                    width="10%"
                    onSubmit={onSubmit}
                    inputType={InputType.POSITIVE}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>총동수</Th>
                <OldTd>
                  <HookFormInput
                    name="totalCountOfComplex"
                    type="number"
                    width="10%"
                    onSubmit={onSubmit}
                    inputType={InputType.POSITIVE}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>세대수</Th>
                <OldTd>
                  <HookFormInput
                    name="countOfHousehold"
                    type="number"
                    width="10%"
                    onSubmit={onSubmit}
                    inputType={InputType.POSITIVE}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <Th>건물용도</Th>
                <OldTd>
                  <HookFormSelect
                    isDefaultPossible
                    name="buildPurpose"
                    optionList={convertToOptionList(structurePurposeOptionList)}
                    width="25%"
                    defaultLabel="선택"
                    onSubmit={onSubmit}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>건안평 대상 유무</Th>
                <OldTd>
                  <HookFormSelect
                    isDefaultPossible
                    name="isTargetOfSafetyEvaluation"
                    optionList={YNOptionList}
                    width="10%"
                    defaultLabel="선택"
                    onSubmit={onSubmit}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </FormProvider>
  );
};
