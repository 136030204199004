import { ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/types/domain';
import type { PersonModalShortView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/types/view';

export const convertAffiliatedCompanyPersonSearchKeywordTypeEnglishToKorean = (
  type: ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType
) => {
  switch (type) {
    case ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType.AFFILIATED_COMPANY_NAME:
      return '업체명';
    case ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType.NAME:
      return '이름';
    case ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType.PHONE_NUMBER:
      return '핸드폰번호';
    case ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType.EMAIL:
      return '이메일';
  }
};

export const spliceAndPushPersonIdIfExist = (idList: string[], targetId: string) => {
  const idListCopy = [...idList];
  const index = idListCopy.findIndex((id) => id === targetId);
  if (index === -1) {
    idListCopy.push(targetId);
  } else {
    idListCopy.splice(index, 1);
  }
  return idListCopy;
};

export const spliceAndPushCombinedIdIfExist = (
  idList: PersonModalShortView[],
  targetId: PersonModalShortView
) => {
  const idListCopy = [...idList];
  const index = idListCopy.findIndex(
    (id) => `${id.id}${id.affiliatedCompanyId}` === `${targetId.id}${targetId.affiliatedCompanyId}`
  );
  if (index === -1) {
    idListCopy.push(targetId);
  } else {
    idListCopy.splice(index, 1);
  }
  return idListCopy;
};

export const spliceAndPushPersonListIfExist = (
  list: PersonModalShortView[],
  addList: PersonModalShortView[]
) => {
  const listCopay = [...list];
  const idList: number[] = [];
  addList.forEach((target) => {
    const index = listCopay.findIndex(
      (item) =>
        `${item.id}${item.affiliatedCompanyId}` === `${target.id}${target.affiliatedCompanyId}`
    );
    if (index === -1) {
      idList.push(target.id);
    }
  });
  addList.forEach((target) => {
    if (idList.includes(target.id)) {
      listCopay.push(target);
    }
  });
  return listCopay;
};

export const filterPersonListIfNotIncludes = (
  list: PersonModalShortView[],
  deleteIdList: string[]
) => list.filter((person) => !deleteIdList.includes(`${person.id}${person.affiliatedCompanyId}`));
