import React from 'react';
import { ModalBodyUI } from '@front/src/components/components-with-design/layout/ModalUI';
import { FormProvider, useForm } from 'react-hook-form';
import WorkApprovalReadingHeader from '@front/src/features/work/features/work/features/approval/features/reading/components/Header';
import WorkApprovalReadingContent from '@front/src/features/work/features/work/features/approval/features/reading/components/Content';

interface Props {
  workId: number;
  onClose: () => void;
  sectionId: number;
  dataId: number;
  menuId?: number;
}

export default function WorkApprovalReadingFeature({ workId, onClose, ...rest }: Readonly<Props>) {
  const methods = useForm({
    values: {
      workId: workId.toString(),
    },
  });
  const { watch } = methods;
  return (
    <ModalBodyUI
      sx={{
        width: '1334px',
      }}
    >
      <FormProvider {...methods}>
        <WorkApprovalReadingHeader
          onClose={onClose}
          {...rest}
        />
        <WorkApprovalReadingContent key={watch('workId')} />
      </FormProvider>
    </ModalBodyUI>
  );
}
