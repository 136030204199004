import { useQuery } from 'react-query';
import type { AffiliatedCompanyPersonCharacterFamilyInformationView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/types/view';
import { affiliatedCompanyFamilyInformationApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/query/api';

export const affiliatedCompanyFamilyInformationQuery = {
  usePersonCharacterFamilyInformationListGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyPersonCharacterFamilyInformationView[]>({
      queryKey: ['affiliated-company', 'person', 'character', 'family-information', id],
      queryFn: () =>
        affiliatedCompanyFamilyInformationApi.getPersonCharacterFamilyInformationList(id, menuId),
      enabled: !!id,
    });
    return {
      data,
      isLoading,
    };
  },
};
