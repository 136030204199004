import {
  convertEmptyToNullForAmountParameter,
  convertEmptyToNullForParameter,
} from '@front/src/utils';
import {formatDateOrNull} from "@front/src/features/project-sales-info/utils";

export const convertEstimationComparedDataForParameter = (data) => ({
  estimationDate: formatDateOrNull(data.estimationDate),
  competitorId: convertEmptyToNullForParameter(data.competitorCompany?.id),
  clientCompanyId: convertEmptyToNullForParameter(data.clientCompany?.id),
  experimentInformation: convertEmptyToNullForParameter(data.experimentInformation),
  experimentAmount: convertEmptyToNullForAmountParameter(data.experimentAmount),
  reviewAmount: convertEmptyToNullForAmountParameter(data.reviewAmount),
  totalAmount: convertEmptyToNullForAmountParameter(data.totalAmount),
  unitPrice: convertEmptyToNullForAmountParameter(data.unitPrice),
  date: convertEmptyToNullForParameter(data.date),
  pdf: data.pdf,
  note: convertEmptyToNullForParameter(data.note),
});
