import { createContext } from 'react';

export { Context as AccountingUploadSalesInfoContext };

interface State {
  readOnly: boolean;
}

const Context = createContext<State>({
  readOnly: false,
});
