import { useQuery } from 'react-query';
import { projectSalesInfoAbstractApi } from '@front/src/features/project-sales-info/features/abstract/query/api';
import type { SmProjectBriefPersonView } from '@front/src/features/project-sales-info/features/abstract/features/affiliated-company/types/view';

export { query as projectSalesInfoAbstractAffiliatedCompanyQuery };
const query = {
  useListGet: (id?) => {
    const { data, remove } = useQuery<SmProjectBriefPersonView[]>({
      queryKey: ['project', 'sales-info', 'abstract', 'affiliated-company', id],
      queryFn: () => projectSalesInfoAbstractApi.getAffiliatedCompanyList(id),
      enabled: !!id,
      suspense: true,
    });
    return {
      data,
      remove,
    };
  },
};
