import type { MouseEventHandler } from 'react';
import React from 'react';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonIconUI from '@front/src/components/components-with-design/component/ButtonIconUI';
import { ColorPalette } from '@front/assets/theme';
import type { SxProps } from '@mui/system';

interface Props {
  order: number;
  onDownload: MouseEventHandler<HTMLButtonElement>;
  onDelete: MouseEventHandler<HTMLButtonElement>;
  onClick: MouseEventHandler<HTMLDivElement>;
  sx?: SxProps;
  readOnly: boolean;
}

export default function ImgCarouselUI({
  onDownload,
  order,
  onDelete,
  onClick,
  sx = {},
  readOnly,
}: Readonly<Props>) {
  return (
    <Box
      onClick={onClick}
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        fontSize: '1.6rem',
        fontWeight: '600',
        lineHeight: '22px',
        color: ColorPalette.greyscale.white,
        background: 'transparent',
        borderRadius: '5px',
        overflow: 'hidden',
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
        visibility: 'hidden',
        cursor: 'pointer',
        '& > div': {
          visibility: 'hidden',
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          padding: '10px',
          boxSizing: 'border-box',
        }}
      >
        <Box>{order}</Box>
        {!readOnly && (
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <ButtonIconUI
              onClick={onDownload}
              shape="primary"
              size="medium"
            >
              <FontAwesomeIcon icon="download" />
            </ButtonIconUI>
            <ButtonIconUI
              onClick={onDelete}
              shape="secondary"
              size="medium"
            >
              <FontAwesomeIcon icon="trash" />
            </ButtonIconUI>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          backgroundColor: ColorPalette.etc.modal_scrim,
        }}
      >
        <Box>클릭하여</Box>
        <Box>크게 보기</Box>
      </Box>
    </Box>
  );
}
