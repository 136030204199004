import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { projectSalesInfoDesignOutlineRepository } from '@front/src/features/project-sales-info/features/design-outline/repository';
import { useProjectSalesInfoDesignOutlineCityState } from '@front/src/features/project-sales-info/features/design-outline/widgets/useState';
import { shallow } from 'zustand/shallow';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';

export { useLogic as useProjectSalesInfoDesignOutlineLogic };

const useLogic = () => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { regCode, setRegCode } = useProjectSalesInfoDesignOutlineCityState(
    (state) => ({
      regCode: state.regCode,
      setRegCode: state.setRegCode,
    }),
    shallow
  );
  const { data: projectDesignOutlineData, isLoading } =
    projectSalesInfoDesignOutlineRepository.useProjectDesignOutlineGet(id!);
  const { run: onUpdate } = projectSalesInfoDesignOutlineRepository.useProjectDesignOutlineUpdate(
    id!
  );
  const { data: structurePurposeOptionList } =
    adminManageableVariableRepository.useListGet('영업관리-건물용도');
  const { data: cityOptionList1 } = projectSalesInfoDesignOutlineRepository.useCity1DataGet(id!);
  const { data: cityOptionList2 } = projectSalesInfoDesignOutlineRepository.useCity2DataGet(
    id!,
    regCode
  );

  return {
    h: {
      onUpdate,
      setRegCode,
    },
    d: {
      projectDesignOutlineData,
      cityOptionList1,
      cityOptionList2,
      structurePurposeOptionList,
    },
    isLoading,
    id,
  };
};
