import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workOutputMutation from '@front/src/features/work/features/work/features/output/query/mutation';
import type { WorkOutputFileUpdateParameter } from '@front/src/features/work/features/work/features/output/types/parameter';

const useWorkOutputUpdateFile = (bundleId: number, menuId) => {
  const callback = getNoOpCallback();
  const { mutate, isLoading } = workOutputMutation.useUpdateFile(bundleId, menuId);
  return {
    run: (params: WorkOutputFileUpdateParameter) => mutate(params, callback),
    setCallback: getSetCallbackFunc(callback),
    isLoading,
  };
};

export default useWorkOutputUpdateFile;
