import { projectSalesInfoEstimationConfirmedQuery } from '@front/src/features/project-sales-info/features/estimation/features/estimation-confirmed/query/query';
import { projectSalesInfoEstimationConfirmedMutation } from '@front/src/features/project-sales-info/features/estimation/features/estimation-confirmed/query/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';

export { repository as projectSalesInfoEstimationConfirmedRepository };

const repository = {
  useDetailGet: (id?: number) => {
    const { data, isLoading } = projectSalesInfoEstimationConfirmedQuery.useDetailGet(id);
    return {
      data,
      isLoading,
    };
  },
  useUpdate: () => {
    const { mutate } = projectSalesInfoEstimationConfirmedMutation.useUpdate();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
