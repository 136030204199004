import React, { useCallback, useMemo } from 'react';
import AttendanceListSection from '../../view/Form/AttendanceListSection';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../../../services/reducer';
import { proceedingsAction } from '../../action';
import type { ProceedingsMemoAddParameter, ProceedingsUpdateParameter } from '../../parameter';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { getAttendance, getWriter } from '@front/proceedings/util/getAuthority';

interface Props extends SectionComponentProps {}

export default function AttendanceInfoRoute({ name }: Readonly<Props>) {
  const { Layout, Header, Body } = AddDeleteTable;
  const dispatch = useDispatch();
  const { attendanceModalOpen, requestAddMemo } = useSelector(
    (root: RootState) => root.proceedings
  );
  const onCloseModal = useCallback(
    () => dispatch(proceedingsAction.addAttendanceModal(false)),
    [dispatch]
  );
  const onOpenModal = useCallback(
    () => dispatch(proceedingsAction.addAttendanceModal(true)),
    [dispatch]
  );
  const addMemo = useCallback(
    (params: ProceedingsMemoAddParameter) => dispatch(proceedingsAction.addMemo(params)),
    [dispatch]
  );
  const { detail, access } = useSelector((root: RootState) => root.proceedings);
  const update = useCallback(
    (params: ProceedingsUpdateParameter) => dispatch(proceedingsAction.update(params)),
    [dispatch]
  );
  const isWriter: boolean = useMemo(() => {
    if (access && detail) {
      return getWriter(access, detail);
    }
    return false;
  }, [detail?.writer, access]);
  const isAttendance: boolean = useMemo(() => {
    if (access && detail) {
      return getAttendance(access, detail);
    }
    return false;
  }, [detail?.attendanceList, access]);
  return (
    <Layout>
      <Header title={name} />
      <Body>
        {detail && (
          <AttendanceListSection
            open={attendanceModalOpen}
            onClose={onCloseModal}
            onOpen={onOpenModal}
            update={update}
            list={detail.attendanceList}
            addMemo={addMemo}
            detail={detail}
            isWriter={isWriter}
            isAttendance={isAttendance}
            requestAddMemo={requestAddMemo}
          />
        )}
      </Body>
    </Layout>
  );
}
