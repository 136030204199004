import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { ProjectSalesInfoActivityItemContext } from '@front/src/features/project-sales-info/features/activity/widgets/activity-item/provider/context';
import { useProjectSalesInfoActivityState } from '@front/src/features/project-sales-info/features/activity/useState';
import { useShallow } from 'zustand/react/shallow';
import { SalesInfoActivityRowContext } from '@front/src/features/project-sales-info/features/activity/widgets/row/provider/context';
import { Td } from '@front/src/components/layout/table/td';

export { CellAddUser as SalesInfoActivityTableCellAddUser };
const CellAddUser = () => {
  const { onUserCreate } = useContext(ProjectSalesInfoActivityItemContext);
  const { item } = useContext(SalesInfoActivityRowContext);
  const { id } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const handleButtonClick = useCallback(() => {
    onUserCreate(item.activity?.id);
  }, [onUserCreate, item.activity?.id]);
  const { readOnly } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  if (item.activity?.id !== id) {
    return (
      <Td
        sx={{ width: '25%' }}
        rowSpan={item.sameActivitySize}
      />
    );
  }
  return (
    <Td
      rowSpan={item.sameActivitySize}
      sx={{ width: '25%' }}
    >
      <Button
        onClick={handleButtonClick}
        disabled={readOnly}
        sx={{
          minWidth: '40px',
        }}
      >
        +
      </Button>
    </Td>
  );
};
