import React from 'react';
import type { ControlPanelTextListProps } from '@front/src/types/domain';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const getApprovalDocumentHeaderControlPanelList = ({
  actions,
}: ControlPanelTextListProps) => {
  if (!actions) return [];
  return [
    {
      text: '추가',
      icon: () => <FontAwesomeIcon icon="plus" />,
      action: actions.onAdd,
    },
    {
      text: '전체 삭제',
      icon: () => <FontAwesomeIcon icon="trash" />,
      action: actions.onRemoveAll,
    },
    {
      text: '전체 수정 닫기',
      icon: () => <FontAwesomeIcon icon="xmark" />,
      action: actions.onCloseAll,
    },
  ];
};

export const getApprovalDocumentControlPanelList = ({
  actions,
  isEditOpen,
  isTop,
  isBottom,
}: ControlPanelTextListProps) => {
  if (!actions) return [];
  return [
    {
      text: '추가',
      icon: () => <FontAwesomeIcon icon="plus" />,
      action: actions.onAdd,
    },
    {
      text: isEditOpen ? '수정 닫기' : '수정',
      icon: () => <FontAwesomeIcon icon="pen" />,
      action: actions.onEdit,
    },
    {
      text: '삭제',
      icon: () => <FontAwesomeIcon icon="trash" />,
      action: actions.onRemove,
    },
    {
      text: '이동(위)',
      icon: () => <FontAwesomeIcon icon="arrow-up-long" />,
      action: actions.onMoveUp,
      disabled: isTop,
    },
    {
      text: '이동(아래)',
      icon: () => <FontAwesomeIcon icon="arrow-down-long" />,
      action: actions.onMoveDown,
      disabled: isBottom,
    },
  ];
};

export const approvalDocumentUrlPath = '/api/approval-document';
export const approvalUrlPath = '/api/approval';
