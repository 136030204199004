import { createContext } from 'react';
import type { ProjectAspectRatioExaminationView } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/types/view';

export { Context as ProjectSalesInfoSubjectReviewShapeRatioListContext };

interface State {
  list?: ProjectAspectRatioExaminationView[];
  isLoading: boolean;
}

const Context = createContext<State>({
  isLoading: false,
});
