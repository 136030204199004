import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAffiliatedPersonAppBarPersonCreateLogic } from '@front/src/features/affiliated-person/features/app-bar/widgets/create/provider/useLogic';
import { AffiliatedPersonAppBarPersonCreateContext } from '@front/src/features/affiliated-person/features/app-bar/widgets/create/provider/context';

export { Provider as AffiliatedPersonAppBarPersonCreateProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { onCreate } = useAffiliatedPersonAppBarPersonCreateLogic();
  const value = useMemo(
    () => ({
      onCreate,
    }),
    [onCreate]
  );
  return (
    <AffiliatedPersonAppBarPersonCreateContext.Provider value={value}>
      {children}
    </AffiliatedPersonAppBarPersonCreateContext.Provider>
  );
};
