import React, { useContext } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import CircularProgress from '@front/components/CircularProgress';
import { NoResultBox } from '@front/src/components/no-result-box/no-result-box';
import { AffiliatedCompanyCharacterCharacterContext } from '@front/src/features/affiliated-company/features/character/features/character/widgets/context';
import { AffiliatedCompanyCharacterCharacterTableRow } from '@front/src/features/affiliated-company/features/character/features/character/components/character-table-row';

export { CharacterTable as AffiliatedCompanyCharacterCharacterTable };

const CharacterTable = () => {
  const {
    d: { characterList },
    isLoading,
  } = useContext(AffiliatedCompanyCharacterCharacterContext);
  if (typeof characterList === 'undefined' || isLoading) {
    return <CircularProgress size={30} />;
  }
  if (characterList.length === 0) {
    return <NoResultBox message="저장된 특징이 없습니다." />;
  }
  return (
    <TableContainer>
      <Table>
        <TableHeadRow />
        <TableBody>
          {characterList?.map((item, i) => (
            <AffiliatedCompanyCharacterCharacterTableRow
              key={item.id}
              item={item}
              index={i}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TableHeadRow = () => (
  <TableHead>
    <TableRow>
      <Th
        center
        width="40px"
      >
        {' '}
      </Th>
      <Th
        center
        width="200px"
      >
        특징 항목
      </Th>
      <Th
        center
        width="400px"
      >
        특징 내용
      </Th>
      <Th
        center
        width="200px"
      >
        출처
      </Th>
    </TableRow>
  </TableHead>
);
