import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAffiliatedPersonMemoDeleteLogic } from '@front/src/features/affiliated-person/features/memo/widgets/delete/provider/useLogic';
import { AffiliatedPersonMemoDeleteContext } from '@front/src/features/affiliated-person/features/memo/widgets/delete/provider/context';

export { Provider as AffiliatedPersonMemoDeleteProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { onDelete } = useAffiliatedPersonMemoDeleteLogic();
  const value = useMemo(
    () => ({
      onDelete,
    }),
    [onDelete]
  );
  return (
    <AffiliatedPersonMemoDeleteContext.Provider value={value}>
      {children}
    </AffiliatedPersonMemoDeleteContext.Provider>
  );
};
