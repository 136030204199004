import React from 'react';
import TextBox from '@front/layouts/Text';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';

export const MemoNoResult = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: 'calc(100vh - 321px)',
      padding: '0 10px 15px 10px',
      overflowY: 'auto',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '10px',
        padding: '15px',
        border: `1px solid ${ColorPalette._e4e9f2}`,
        borderRadius: '5px',
        backgroundColor: ColorPalette._ffffff,
      }}
    >
      <TextBox
        variant="body2"
        sx={{
          minHeight: '30px',
          lineHeight: '30px',
        }}
      >
        해당하는 메모가 없습니다.
      </TextBox>
    </Box>
  </Box>
);
