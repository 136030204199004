import type { ProjectCollectionManagementView } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/types/view';
import { create } from 'zustand';
import produce from 'immer';
import type { ProjectCollectionManagementParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/types/parameter';

interface State {
  collectionManagementList: ProjectCollectionManagementView[];
  addCollectionManagementList: (item: ProjectCollectionManagementParams) => void;
  deleteCollectionManagementList: (idList: number[]) => void;
  deleteDueCollectionManagementList: (idList: number[]) => void;
  updateCollectionManagementList: (index: number, item: ProjectCollectionManagementParams) => void;
  resetCollectionManagementList: () => void;
  idList: number[];
  setIdList: (idList: number[]) => void;
  isCreateModalOpen: boolean;
  setIsCreateModalOpen: () => void;
  isUpdateModalOpen: boolean;
  setIsUpdateModalOpen: () => void;
  idx?: number;
  setIdx: (idx: number) => void;
}

export { useState as useProjectSalesInfoCollectionManagementModalState };

const useState = create<State>((set) => ({
  collectionManagementList: [],
  addCollectionManagementList: (item: ProjectCollectionManagementParams) =>
    set(
      produce(({ collectionManagementList }) => {
        collectionManagementList.push(item);
      })
    ),
  deleteCollectionManagementList: (idList: number[]) => {
    set(({ collectionManagementList }) => ({
      collectionManagementList: collectionManagementList.filter(
        (_, index) => !idList.includes(index)
      ),
    }));
  },
  deleteDueCollectionManagementList: (idList: number[]) => {
    set(({ collectionManagementList }) => ({
      collectionManagementList: collectionManagementList.filter(
        (item, index) => !idList.includes(index) && item.unpaidReason !== '6. 입금완료'
      ),
    }));
  },
  updateCollectionManagementList: (index: number, item: ProjectCollectionManagementParams) =>
    set(
      produce(({ collectionManagementList }) => {
        collectionManagementList[index] = item;
      })
    ),
  resetCollectionManagementList: () =>
    set(() => ({
      collectionManagementList: [],
    })),
  idList: [],
  setIdList: (idList: number[]) => {
    set({ idList });
  },
  isCreateModalOpen: false,
  setIsCreateModalOpen: () =>
    set(({ isCreateModalOpen }) => ({ isCreateModalOpen: !isCreateModalOpen })),
  isUpdateModalOpen: false,
  setIsUpdateModalOpen: () =>
    set(({ isUpdateModalOpen }) => ({ isUpdateModalOpen: !isUpdateModalOpen })),
  setIdx: (idx: number) => set({ idx }),
}));
