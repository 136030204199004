import { useQuery } from 'react-query';
import myPageApi from '@front/src/features/my-page/api/api';

const myPageAuthorizationQuery = {
  useGetMenuList: (menuId) => {
    const { data } = useQuery({
      queryFn: () => myPageApi.getMenuList(menuId),
      queryKey: ['my-page', 'authorization', 'menu'],
      suspense: true,
    });
    return { data };
  },
  useGetTabList: (id, menuId) => {
    const { data } = useQuery({
      queryFn: () => myPageApi.getTabList(id, menuId),
      queryKey: ['my-page', 'authorization', 'tab', id],
      suspense: true,
      enabled: !!id,
    });
    return { data };
  },
  useGetAdminMenuList: (menuId) => {
    const { data } = useQuery({
      queryFn: () => myPageApi.getAdminMenuList(menuId),
      queryKey: ['menu', 'admin'],
      suspense: true,
    });
    return { data };
  },
  useGetNotDevelopmentMenuList: (id, menuId) => {
    const { data } = useQuery({
      queryFn: () => myPageApi.getNotDevelopmentMenuList(id, menuId),
      queryKey: ['menu', 'not-development', id],
      suspense: true,
      enabled: !!id,
    });
    return { data };
  },
};

export default myPageAuthorizationQuery;
