import { useQuery } from 'react-query';
import type { ProjectSalesInfoAffiliatedCompanyWebHardView } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/types/view';
import { projectSalesInfoAffiliatedCompanyWebHardApi } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/query/api';

export { query as projectSalesInfoAffiliatedCompanyWebHardQuery };
const query = {
  useListGet: (id?: number) => {
    const { data, isLoading } = useQuery<ProjectSalesInfoAffiliatedCompanyWebHardView[]>({
      queryKey: ['project', 'sales-info', 'affiliated-company', 'web-hard', 'list', id],
      queryFn: () => projectSalesInfoAffiliatedCompanyWebHardApi.getList(id),
      enabled: !!id,
      cacheTime: 0,
    });
    return {
      data,
      isLoading,
    };
  },
};
