import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { usePersonalSettingsModalDeptSettingsLogic } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/dept-settings/provider/useLogic';
import { PersonalSettingsModalDeptSettingsContext } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/dept-settings/provider/context';

export { Provider as PersonalSettingsModalDeptSettingsProvider };

interface Props {
  children: ReactNode;
  authMenuId: number | undefined;
}

const Provider = ({ children, authMenuId }: Props) => {
  const { deptList, onAuthUpdate, clientDeptList } =
    usePersonalSettingsModalDeptSettingsLogic(authMenuId);
  const value = useMemo(
    () => ({
      deptList,
      onAuthUpdate,
      clientDeptList,
    }),
    [deptList, onAuthUpdate, clientDeptList]
  );
  return (
    <PersonalSettingsModalDeptSettingsContext.Provider value={value}>
      {children}
    </PersonalSettingsModalDeptSettingsContext.Provider>
  );
};
