import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import type { ProjectSalesInfoBidResultParams } from '@front/src/features/project-sales-info/features/bid/features/bid-result/types/parameter';
import { projectSalesInfoBidResultUpdateModalApi } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/update-modal/query/api';

export const projectSalesInfoBidResultUpdateModalMutation = {
  useProjectSalesInfoBidResultUpdate: (id: number) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectSalesInfoBidResultParams) =>
        projectSalesInfoBidResultUpdateModalApi.updateBidResult(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['project-sales-info', 'bid-result', 'list', id]);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
