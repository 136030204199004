import React from 'react';
import WriterInfo from '../../view/Form/WriterInfo';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';

interface Props extends SectionComponentProps {}

export default function WriterInfoRoute({ name }: Readonly<Props>) {
  const { Layout, Header, Body } = AddDeleteTable;
  const { detail } = useSelector((root: RootState) => root.proceedings);
  return (
    <Layout>
      <Header title={name} />
      <Body>
        {detail && (
          <WriterInfo
            writer={detail.writer}
            createdAt={detail.createdAt}
            modifiedAt={detail.modifiedAt}
          />
        )}
      </Body>
    </Layout>
  );
}
