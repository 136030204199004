import { default as axios } from '@front/src/config/axios';
import type { ProjectReviewSavePaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/save-history/types/parameter';
import { saveHistoryApiUrl } from '@front/src/features/project-sales-info/features/collection/features/save-history/utils/constant';

const url = {
  getSaveHistory: (id: number) => `${saveHistoryApiUrl}/${id}/review-amount-save-history`,
  updateSaveHistory: (id: number) => `${saveHistoryApiUrl}/review-amount-save-history/${id}`,
};

export const projectSalesInfoCollectionSaveHistoryApi = {
  getSaveHistory: async (id: number) => {
    const { data } = await axios.get(url.getSaveHistory(id));
    return data;
  },
  updateSaveHistory: async (params: ProjectReviewSavePaymentHistoryParams) =>
    await axios.put(url.updateSaveHistory(params.id!), params),
};
