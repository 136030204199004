import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import type { CommentId, ProposalCommentEditVO } from '@front/ost_proposal/domain';
import { proposalAction } from '@front/ost_proposal/action';
import type { CommentParameter } from '@front/ost_proposal/parameter';
import CommentLayout from '@front/ost_proposal/view/detail/comment/CommentLayout';

function CommentService() {
  const dispatch = useDispatch();
  const addComment = useCallback(
    (params: CommentParameter) => dispatch(proposalAction.addComment(params)),
    [dispatch]
  );
  const deleteComment = useCallback(
    (id: CommentId) => dispatch(proposalAction.deleteComment(id)),
    [dispatch]
  );
  const updateComment = useCallback(
    (params: CommentParameter) => dispatch(proposalAction.updateComment(params)),
    [dispatch]
  );
  const setCommentId = useCallback(
    (id: CommentId | undefined) => dispatch(proposalAction.setCommentId(id)),
    [dispatch]
  );
  const setCommentParentId = useCallback(
    (id: CommentId | undefined) => dispatch(proposalAction.setCommentParentId(id)),
    [dispatch]
  );
  const setReplyCommentParentId = useCallback(
    (id: CommentId | undefined) => dispatch(proposalAction.setReplyCommentParentId(id)),
    [dispatch]
  );
  const setCommentEditInput = useCallback(
    (state: ProposalCommentEditVO) => dispatch(proposalAction.setCommentEditInput(state)),
    [dispatch]
  );

  return (
    <CommentLayout
      addComment={addComment}
      deleteComment={deleteComment}
      updateComment={updateComment}
      setCommentId={setCommentId}
      setCommentParentId={setCommentParentId}
      setCommentEditInput={setCommentEditInput}
      setReplyCommentParentId={setReplyCommentParentId}
    />
  );
}

export default CommentService;
