import { useQuery } from 'react-query';
import { affiliatedCompanyPrimaryBusinessApi } from '@front/src/features/affiliated-company/features/outline/features/primary-business/query/api';
import type { AffiliatedCompanyPrimaryBusinessView } from '@front/src/features/affiliated-company/features/outline/features/primary-business/types/view';

export const affiliatedCompanyPrimaryBusinessQuery = {
  useOutlinePrimaryBusinessGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyPrimaryBusinessView[]>({
      queryKey: ['affiliated-company', 'primary-business', id],
      queryFn: () => affiliatedCompanyPrimaryBusinessApi.getOutlinePrimaryBusiness(id, menuId),
      enabled: !!id,
    });
    return {
      data,
      isLoading,
    };
  },
};
