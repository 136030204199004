import React, { Suspense } from 'react';
import CommonSectionList from '@front/src/components/ui-builder/CommonSectionList';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { useParams } from 'react-router';
import useSetMenuAndTab from '@front/src/hooks/useSetMenuAndTab';

interface Props {
  dataId?: number;
  menuId?: number;
}

function Index({ ...rest }: Readonly<Props>) {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <CommonSectionList {...rest} />
    </Suspense>
  );
}

export default function UIBuilderRouteElement() {
  const { id } = useParams<{ id: string }>();
  const { menuId } = useSetMenuAndTab();
  const dataId = id ? +id : undefined;
  if (!menuId) return <></>;
  return (
    <Index
      menuId={menuId}
      dataId={dataId}
    />
  );
}
