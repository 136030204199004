import { createContext } from 'react';
import type { SmProjectBriefEstimationView } from '@front/src/features/project-sales-info/features/abstract/features/estimation/types/view';

interface State {
  detail?: SmProjectBriefEstimationView;
}

export { Context as ProjectSalesInfoAbstractEstimationDetailContext };

const Context = createContext<State>({});
