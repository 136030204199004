import { create } from 'zustand';
import type { DefaultFunction } from '@front/type/Function';

interface State {
  recentUpdatedId?: number;
  editOpenList: number[];
}

interface Actions {
  setRecentUpdatedId: (recentUpdatedId?: number) => void;
  addEditOpenId: (id: number) => void;
  clearEditOpenList: DefaultFunction;
}

const useWorkWorkDetailState = create<State & Actions>((set) => ({
  setRecentUpdatedId: (recentUpdatedId) => set({ recentUpdatedId }),
  editOpenList: [],
  clearEditOpenList: () => set({ editOpenList: [] }),
  addEditOpenId: (id) =>
    set((state) => {
      if (state.editOpenList.includes(id)) {
        return { editOpenList: state.editOpenList.filter((originId) => originId !== id) };
      }
      return { editOpenList: [...state.editOpenList, id] };
    }),
}));

export default useWorkWorkDetailState;
