import React, { useRef } from 'react';
import { Box } from '@mui/material';
import TextBox from '@front/layouts/Text';
import type { CommentId, ProposalCommentEditVO } from '@front/ost_proposal/domain';
import type { CommentParameter } from '@front/ost_proposal/parameter';
import CommentContents from '@front/ost_proposal/view/detail/comment/contents';
import { ColorPalette } from '@front/assets/theme';
import AddCommentLayout from '@front/ost_proposal/view/detail/comment/addCommentRow';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { ArticleItem } from '@front/src/components/article-item';

interface Props {
  addComment: (comment: CommentParameter) => void;
  updateComment: (comment: CommentParameter) => void;
  deleteComment: (id: CommentId) => void;
  setCommentId: (id: CommentId | undefined) => void;
  setCommentParentId: (id: CommentId | undefined) => void;
  setReplyCommentParentId: (id: CommentId | undefined) => void;
  setCommentEditInput: (state: ProposalCommentEditVO) => void;
}

function CommentLayout({
  addComment,
  updateComment,
  deleteComment,
  setCommentId,
  setCommentEditInput,
  setCommentParentId,
  setReplyCommentParentId,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [commentList] = useSelector((root: RootState) => [root.proposal.commentList], shallowEqual);
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        width: '100%',
        padding: '10px',
      }}
    >
      <ArticleItem
        title={
          <>
            <TextBox variant={'heading3'}>
              제안 피드백 이력
              <TextBox variant={'body9'}>(문의 사항을 먼저 작성하셔도 무방합니다.)</TextBox>
            </TextBox>
          </>
        }
        children={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'nowrap',
              width: '100%',
              border: `1px solid ${ColorPalette._e4e9f2}`,
              padding: '10px',
              borderRadius: '5px',
              marginTop: '10px',
            }}
          >
            <AddCommentLayout
              addComment={addComment}
              inputRef={inputRef}
            />
            {commentList.length > 0 && (
              <CommentContents
                addComment={addComment}
                updateComment={updateComment}
                deleteComment={deleteComment}
                inputRef={inputRef}
                setCommentId={setCommentId}
                setCommentEditInput={setCommentEditInput}
                setCommentParentId={setCommentParentId}
                setReplyCommentParentId={setReplyCommentParentId}
              />
            )}
          </Box>
        }
      />
    </Box>
  );
}

export default CommentLayout;
