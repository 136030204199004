import { useQuery } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { CommonGetParameter } from '@front/src/types/parameter';

const workWorkerQuery = {
  useGetList: (params: CommonGetParameter) => {
    const { data } = useQuery({
      queryKey: ['work', 'worker', params.dataId],
      queryFn: () => workWorkApi.getWorkerList(params),
      suspense: true,
    });
    return {
      data,
    };
  },
};

export default workWorkerQuery;
