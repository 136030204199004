import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { AffiliatedCompanyPersonDetailModalCharacterHobbyContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/widgets/context';

export { ButtonGroup as AffiliatedCompanyPersonDetailModalCharacterHobbyButtonGroup };
const ButtonGroup = () => {
  const {
    h: { onCreateHobby, onDeleteHobby, setIdList },
    idList,
    readOnly,
  } = useContext(AffiliatedCompanyPersonDetailModalCharacterHobbyContext);
  const handleDeleteClick = useCallback(() => {
    onDeleteHobby({ idList });
    setIdList([]);
  }, [onDeleteHobby, idList, setIdList]);
  if (readOnly) {
    return <></>;
  }
  return (
    <>
      <Button
        disabled={readOnly}
        onClick={handleDeleteClick}
      >
        선택삭제
      </Button>
      <Button
        disabled={readOnly}
        onClick={onCreateHobby}
      >
        취미 추가
      </Button>
    </>
  );
};
