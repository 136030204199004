import React, { useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { AffiliatedCompanyPersonDetailModalOutlineCareerButton } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/components/career-button';
import { AffiliatedCompanyPersonDetailModalOutlineCareerTable } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/components/career-table';
import { useAffiliatedCompanyPersonDetailModalOutlineCareerLogic } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/widgets/useLogic';
import { AffiliatedCompanyPersonDetailModalOutlineCareerContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/widgets/context';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AffiliatedCompanyPersonDetailModalOutlineCareerWidget = ({
  name,
  menuId,
}: Readonly<Props>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    d: { careerList },
    h: { setIdList, onCareerUpdate, onCareerCreate, onCareerDelete },
    idList,
    isLoading,
  } = useAffiliatedCompanyPersonDetailModalOutlineCareerLogic(menuId);
  const value = useMemo(
    () => ({
      d: { careerList },
      h: { onCareerUpdate, setIdList, onCareerCreate, onCareerDelete },
      isLoading,
      idList,
    }),
    [careerList, isLoading, onCareerUpdate, setIdList, idList, onCareerCreate, onCareerDelete]
  );
  return (
    <AffiliatedCompanyPersonDetailModalOutlineCareerContext.Provider value={value}>
      <Layout>
        <Header title={name}>
          <AffiliatedCompanyPersonDetailModalOutlineCareerButton />
        </Header>
        <Body>
          <AffiliatedCompanyPersonDetailModalOutlineCareerTable />
        </Body>
      </Layout>
    </AffiliatedCompanyPersonDetailModalOutlineCareerContext.Provider>
  );
};

export default AffiliatedCompanyPersonDetailModalOutlineCareerWidget;
