import React from 'react';
import { AffiliatedPersonDrawerListWidget } from '@front/src/features/affiliated-person/features/drawer/widgets/list/widget';
import { AffiliatedPersonDrawerResultTable } from '@front/src/features/affiliated-person/features/drawer/components/result-table';
import { AffiliatedPersonDrawerForm } from '@front/src/features/affiliated-person/features/drawer/components/form';
import { ListDrawerFeature } from '@front/src/components/list-drawer';
import { AffiliatedPersonAppBarFeature } from '@front/src/features/affiliated-person/features/app-bar';

const AffiliatedPersonDrawerFeature = () => (
  <ListDrawerFeature>
    <AffiliatedPersonAppBarFeature />
    <AffiliatedPersonDrawerForm />
    <AffiliatedPersonDrawerResultTable>
      <AffiliatedPersonDrawerListWidget />
    </AffiliatedPersonDrawerResultTable>
  </ListDrawerFeature>
);

export default AffiliatedPersonDrawerFeature;
