import { default as axios } from '@front/src/config/axios';
import { bidURLAPI } from '@front/src/features/project-sales-info/features/bid/utils/constant';
import type { ProjectSalesInfoBidResultParams } from '@front/src/features/project-sales-info/features/bid/features/bid-result/types/parameter';

const url = {
  createBidResult: (id: number) => `${bidURLAPI}/${id}/bid-result`,
};

export const projectSalesInfoBidResultCreateModalApi = {
  createBidResult: async (data: { params: ProjectSalesInfoBidResultParams; id: number }) => {
    await axios.post(url.createBidResult(data.id!), data.params);
  },
};
