import type { ReactNode } from 'react';
import React from 'react';
import classes from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/layout/select-button-part.module.scss';

export { SelectButtonPart as ProjectSalesInfoAffiliatedCompanyPersonCreateModalLayoutSelectButtonPart };

interface Props {
  children: ReactNode;
}

const SelectButtonPart = ({ children }: Props) => (
  <div className={classes.container}>{children}</div>
);
