import React, { useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useAffiliatedCompanyDetailModalCharacterFamilyInformationLogic } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/widgets/useLogic';
import { AffiliatedCompanyPersonDetailModalCharacterFamilyInformationContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/widgets/context';
import { AffiliatedCompanyPersonDetailModalCharacterFamilyInformationButtonGroup } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/components/button-group';
import { AffiliatedCompanyPersonDetailModalCharacterFamilyInformationTable } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/components/family-information-table';

interface Props {
  readOnly?: boolean;
  id?: number;
  menuId?: number;
}

const AffiliatedCompanyPersonDetailModalCharacterFamilyInformationWidget = ({
  readOnly,
  id,
  menuId,
}: Props) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    d: { familyInformationList },
    h: {
      onCreateFamilyInformation,
      setIdList,
      onDeleteFamilyInformation,
      onUpdateFamilyInformation,
    },
    idList,
    isLoading,
  } = useAffiliatedCompanyDetailModalCharacterFamilyInformationLogic({ id, menuId });
  const value = useMemo(
    () => ({
      d: { familyInformationList },
      h: {
        onCreateFamilyInformation,
        setIdList,
        onDeleteFamilyInformation,
        onUpdateFamilyInformation,
      },
      isLoading,
      idList,
      readOnly,
    }),
    [
      familyInformationList,
      isLoading,
      onCreateFamilyInformation,
      setIdList,
      idList,
      onDeleteFamilyInformation,
      onUpdateFamilyInformation,
      readOnly,
    ]
  );

  return (
    <AffiliatedCompanyPersonDetailModalCharacterFamilyInformationContext.Provider value={value}>
      <Layout>
        <Header title="가족정보">
          <AffiliatedCompanyPersonDetailModalCharacterFamilyInformationButtonGroup />
        </Header>
        <Body>
          <AffiliatedCompanyPersonDetailModalCharacterFamilyInformationTable />
        </Body>
      </Layout>
    </AffiliatedCompanyPersonDetailModalCharacterFamilyInformationContext.Provider>
  );
};

export default AffiliatedCompanyPersonDetailModalCharacterFamilyInformationWidget;
