import { useQuery } from 'react-query';
import { affiliatedCompanyPersonCharacterBasicApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/basic/query/api';
import type { AffiliatedCompanyPersonCharacterBasicView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/basic/types/view';

export const affiliatedCompanyPersonCharacterBasicQuery = {
  usePersonCharacterBasicGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyPersonCharacterBasicView>({
      queryKey: ['affiliated-company', 'person', 'character', 'basic', id],
      queryFn: () => affiliatedCompanyPersonCharacterBasicApi.getPersonCharacterBasic(id, menuId),
      enabled: !!id,
      cacheTime: 0,
    });
    return {
      data,
      isLoading,
    };
  },
};
