import { useQuery } from 'react-query';
import type { ProjectCMPaymentHistoryView } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/types/view';
import { projectSalesInfoCMPaymentHistoryUpdateModalApi } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/update-modal/query/api';

export const projectSalesInfoCMPaymentHistoryUpdateModalQuery = {
  useCMPaymentHistoryDetailGet: (itemId: number) => {
    const { data, isLoading } = useQuery<ProjectCMPaymentHistoryView>({
      queryFn: () =>
        projectSalesInfoCMPaymentHistoryUpdateModalApi.getCMPaymentHistoryDetail(itemId),
      queryKey: ['project-sales-info', 'collection', 'CM-payment-history', 'detail', itemId],
    });
    return {
      data,
      isLoading,
    };
  },
};
