import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonQuery } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/query/query';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonMutation } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/query/mutation';

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonRepository = {
  useListGet: (id?: number) => {
    const { data, isLoading } =
      projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonQuery.useListGet(id);
    return {
      data,
      isLoading,
    };
  },
  useDelete: () => {
    const { mutate } = projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonMutation.useDelete();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useUpdate: () => {
    const { mutate } = projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonMutation.useUpdate();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
