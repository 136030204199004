import React from 'react';
import { TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';

export { TableHeader as AffiliatedCompanyProjectListTableHeader };

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <Th
        width="120px"
        rowSpan={2}
      >
        견적상태
      </Th>
      <Th
        width="144px"
        rowSpan={2}
      >
        계약상태
      </Th>
      <Th
        width="140px"
        rowSpan={2}
      >
        프로젝트번호
      </Th>
      <Th
        width="200px"
        rowSpan={2}
      >
        프로젝트명
      </Th>
      <Th
        width="160px"
        rowSpan={2}
      >
        동수
      </Th>
      <Th
        width="160px"
        rowSpan={2}
      >
        관계사 구분
      </Th>
      <Th
        width="100px"
        rowSpan={2}
      >
        주요인물
      </Th>
      <Th
        width="160px"
        rowSpan={2}
      >
        기여도
      </Th>
      <Th
        width="360px"
        colSpan={2}
      >
        견적
      </Th>
      <Th
        width="1000px"
        colSpan={7}
      >
        계약
      </Th>
    </TableRow>
    <TableRow>
      <Th width="180px">총금액</Th>
      <Th width="180px">풍동금액</Th>
      <Th width="180px">총금액</Th>
      <Th width="180px">풍동금액</Th>
      <Th width="180px">수금액</Th>
      <Th width="180px">미수금액</Th>
      <Th width="188px">업무진행단계</Th>
      <Th width="200px">기성진행단계</Th>
      <Th width="360px">발주처</Th>
    </TableRow>
  </TableHead>
);
