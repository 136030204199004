import React from 'react';
import type { TableCellProps } from '@mui/material';
import { Box, Table, TableCell } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material/styles';

interface CustomTableCellProps extends TableCellProps {
  children: React.ReactNode;
  left?: string | number;
  sx?: SxProps<Theme>;
}

const StyledTableCell = ({ children, left, sx = {}, ...rest }: Readonly<CustomTableCellProps>) => (
  <TableCell
    sx={{
      left,
      zIndex: 1,
      height: '45px',
      margin: 0,
      ...sx,
    }}
    {...rest}
  >
    {children}
  </TableCell>
);

interface TcProps extends CustomTableCellProps {
  isFixed?: boolean;
  required?: boolean;
}

const Tc = ({ sx = {}, isFixed, children, left, ...rest }: Readonly<TcProps>) => {
  const stickySx =
    left !== undefined
      ? {
          position: 'sticky',
          left,
          borderRight: isFixed ? `2px solid ${ColorPalette._252627}` : 'default',
        }
      : {};
  return (
    <StyledTableCell
      sx={{ ...sx, ...stickySx } as SxProps<Theme>}
      {...rest}
    >
      {children}
    </StyledTableCell>
  );
};

export const Thc = ({
  required = false,
  children,
  sx = {},
  width = '100px',
  ...rest
}: Readonly<TcProps>) => (
  <Tc
    align="center"
    sx={{
      padding: '0',
      backgroundColor: ColorPalette._f1f5fc,
      fontWeight: '500',
      ...sx,
    }}
    width={width}
    {...rest}
  >
    <>
      {required ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '2px',
          }}
        >
          {children}
          <Box
            sx={{
              color: ColorPalette._eb4c4c,
            }}
          >
            *
          </Box>
        </Box>
      ) : (
        <>{children}</>
      )}
    </>
  </Tc>
);

export const Tbc = ({ children, sx = {}, ...rest }: Readonly<TcProps>) => (
  <Tc
    align="left"
    sx={{
      padding: '0 8px',
      textWrap: 'wrap',
      fontSize: '13px',
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Tc>
);

interface CustomTableProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

export const CustomTable = ({ children, sx = {} }: Readonly<CustomTableProps>) => (
  <Table
    sx={{
      tableLayout: 'fixed',
      borderCollapse: 'separate',
      borderSpacing: 0,
      borderRadius: '5px',
      '& > thead': {
        '& > tr:first-of-type': {
          '& > th:first-of-type': {
            borderTopLeftRadius: '5px',
          },
          '& > th:last-child': {
            borderTopRightRadius: '5px',
          },
        },
      },
      '& > tbody': {
        '& > tr': {
          '& > td:first-of-type': {},
          '& > td:last-child': {
            borderRight: `1px solid ${ColorPalette._e4e9f2}`,
          },
        },
        '& > tr:last-child': {
          '& > td:first-of-type': {
            borderBottomLeftRadius: '5px',
          },
          '& > td': {
            borderBottom: `1px solid ${ColorPalette._e4e9f2}`,
          },
          '& > td:last-child': {
            borderBottomRightRadius: '5px',
          },
        },
      },
      '& td, & th': {
        borderLeft: `1px solid ${ColorPalette._e4e9f2}`,
        borderTop: `1px solid ${ColorPalette._e4e9f2}`,
      },
      ...sx,
    }}
  >
    {children}
  </Table>
);
