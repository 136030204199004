import { createContext } from 'react';
import { noOp } from '@front/src/utils';

export { Context as AffiliatedPersonDetailDeleteContext };

interface State {
  onDelete: (id?: number) => void;
}

const Context = createContext<State>({
  onDelete: noOp,
});
