import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import { default as axios } from '@front/src/config/axios';

import type { ProjectCMPaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/types/parameter';

const url = {
  createCMPaymentHistory: (id: number) => `${projectSalesInfoURL}/${id}/cm-amount-payment-history`,
};

export const projectSalesInfoCMPaymentHistoryCreateModalApi = {
  createCMPaymentHistory: async (id: number, params: ProjectCMPaymentHistoryParams) => {
    const { data } = await axios.post(url.createCMPaymentHistory(id), {
      ...params,
      companyId: params.companyId,
    });
    return data;
  },
};
