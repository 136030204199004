import {
  contractEarningsApiUrl,
  smProjectCollectionApiUrl,
} from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/utils/constants';
import { default as axios } from '@front/src/config/axios';
import type { ContractEarningsDetailCreateParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/types/parameters';

const url = {
  createContractEarningsItem: (itemId: number) =>
    `${smProjectCollectionApiUrl}/${itemId}/collection`,
  getConsortiumCompany: (id: number) => `${contractEarningsApiUrl}/${id}/consortium-company`,
};

export const projectSalesInfoContractEarningsItemCreateModalApi = {
  createContractEarningsItem: async (data: ContractEarningsDetailCreateParams) =>
    await axios.post(url.createContractEarningsItem(data.itemId!), data.params),
  getConsortiumCompany: async (id: number) => {
    const { data } = await axios.get(url.getConsortiumCompany(id));
    return data;
  },
};
