import { useQuery } from 'react-query';
import { projectSalesInfoBidInfoApi } from '@front/src/features/project-sales-info/features/bid/features/bid-information/query/api';

export const projectSalesInfoBidInfoQuery = {
  useProjectSalesInfoBidInfoListGet: (id: number) => {
    const { data, isLoading } = useQuery({
      queryKey: ['project-sales-info', 'bid-info', 'list', id],
      queryFn: () => projectSalesInfoBidInfoApi.getBidInfoList(id),
    });
    return {
      data,
      isLoading,
    };
  },
};
