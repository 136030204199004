import React from 'react';
import { ProjectSalesInfoSubjectReviewModalDesignDateDetail } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date/features/detail/components/detail';
import { ProjectSalesInfoSubjectReviewModalDesignDateDetailProvider } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date/features/detail/widgets/provider';

export { Widget as ProjectSalesInfoSubjectReviewModalDesignDateDetailWidget };

const Widget = () => (
  <ProjectSalesInfoSubjectReviewModalDesignDateDetailProvider>
    <ProjectSalesInfoSubjectReviewModalDesignDateDetail />
  </ProjectSalesInfoSubjectReviewModalDesignDateDetailProvider>
);
