import { affiliatedPersonAppBarCompanySelectQuery } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/query/query';

export { repository as affiliatedPersonAppBarCompanySelectRepository };

const repository = {
  useListGet: (params) => {
    const { data, remove } = affiliatedPersonAppBarCompanySelectQuery.useListGet(params);
    return {
      data,
      remove,
    };
  },
};
