import React from 'react';
import {
  TableBodyBox,
  TableBox,
  TableHeadBox,
  TableRowBox,
  ThcBox,
} from '@front/src/features/personal-settings-modal/features/ui-settings/components';
import { PersonalSettingsModalUISettingsHeaderSettingsTableContent } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/components/TableContent';
import { personalSettingsModalUISettingsHeaderSettingsPositionRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/repository/repository';

export { PositionTable as PersonalSettingsModalUISettingsHeaderSettingsPositionTable };
interface Props {
  authMenuId: number | undefined;
}
const PositionTable = ({ authMenuId }: Props) => {
  const { data: list } =
    personalSettingsModalUISettingsHeaderSettingsPositionRepository.useGetHeaderUsageList(
      authMenuId
    );
  return (
    <TableBox>
      <TableHeadBox>
        <TableRowBox>
          <ThcBox>디렉토리 (1단계)</ThcBox>
          <ThcBox>디렉토리 (2단계)</ThcBox>
          <ThcBox>디렉토리 (3단계)</ThcBox>
          <ThcBox>탭</ThcBox>
          <ThcBox>섹션</ThcBox>
          <ThcBox>현황</ThcBox>
        </TableRowBox>
      </TableHeadBox>
      <TableBodyBox>
        {list && (
          <PersonalSettingsModalUISettingsHeaderSettingsTableContent authMenuId={authMenuId} />
        )}
      </TableBodyBox>
    </TableBox>
  );
};
