import { createAction } from 'typesafe-actions';
import type { ProposalId, ProposalShortVO } from '@front/ost_proposal/domain';
import type { ProposalQuery } from '@front/ost_proposal/query';
import type {
  VoteAddParameter,
  VoteMemoAddParameter,
  VoteMemoChangeParameter,
} from '@front/ost_vote/parameter';
import type { VoteMemoId, VoteMemoVO, VoteVO } from '@front/ost_vote/domain';
import type { VoteMemoQuery } from '@front/ost_vote/query';

export enum VoteAction {
  /**
   * 투찰 목록 관련 액션
   */
  setId = 'vote/id/set',
  setList = 'vote/list/set',
  setFilter = 'vote/filter/set',
  toggleDrawer = 'vote/drawer/toggle',
  setLoading = 'vote/loading/set',
  searchFilterOpen = 'vote/search-filter-open/set',
  /**
   * 투찰 기능
   */
  add = 'vote/add',
  setVote = 'vote/set',
  /**
   * 투찰 메모 관련 액션
   */
  setMemoDrawerOpen = 'vote/drawer/set',
  addMemo = 'vote/memo/add',
  setMemoFilter = 'vote/memo/filter',
  setMemoList = 'vote/memo/list/set',
  changeMemo = 'vote/memo/change',
  deleteMemo = 'vote/memo/delete',
  setMemoLoading = 'vote/memo/loading/set',
}

export const voteAction = {
  /**
   * 투찰 목록 관련 액션
   */
  setId: createAction(VoteAction.setId)<ProposalId | undefined>(),
  setList: createAction(VoteAction.setList)<ProposalShortVO[]>(),
  setFilter: createAction(VoteAction.setFilter)<ProposalQuery>(),
  toggleDrawer: createAction(VoteAction.toggleDrawer)<boolean>(),
  setLoading: createAction(VoteAction.setLoading)<boolean>(),
  searchFilterOpen: createAction(VoteAction.searchFilterOpen)<boolean>(),
  /**
   * 투찰 기능
   */
  add: createAction(VoteAction.add)<VoteAddParameter>(),
  setVote: createAction(VoteAction.setVote)<VoteVO>(),

  /**
   * 투찰 메모 관련 액션
   */
  setMemoDrawerOpen: createAction(VoteAction.setMemoDrawerOpen)<boolean>(),
  addMemo: createAction(VoteAction.addMemo)<VoteMemoAddParameter>(),
  setMemoFilter: createAction(VoteAction.setMemoFilter)<VoteMemoQuery>(),
  setMemoList: createAction(VoteAction.setMemoList)<VoteMemoVO[]>(),
  changeMemo: createAction(VoteAction.changeMemo)<VoteMemoChangeParameter>(),
  deleteMemo: createAction(VoteAction.deleteMemo)<VoteMemoId>(),
  setMemoLoading: createAction(VoteAction.setMemoLoading)<boolean>(),
};
