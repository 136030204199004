import React, { Suspense } from 'react';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';
import { ProjectSalesInfoAbstractAffiliatedCompanyListProvider } from '@front/src/features/project-sales-info/features/abstract/features/affiliated-company/widgets/list/provider/provider';
import { ProjectSalesInfoAbstractAffiliatedCompanyList } from '@front/src/features/project-sales-info/features/abstract/features/affiliated-company/widgets/list/components/list';

export { Widget as ProjectSalesInfoAbstractAffiliatedCompanyListWidget };

const Widget = () => (
  <Suspense fallback={<TableLoadingSpinner colSpan={7} />}>
    <ProjectSalesInfoAbstractAffiliatedCompanyListProvider>
      <ProjectSalesInfoAbstractAffiliatedCompanyList />
    </ProjectSalesInfoAbstractAffiliatedCompanyListProvider>
  </Suspense>
);
