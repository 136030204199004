import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { defaultSearchParameter } from '@front/src/features/affiliated-person/features/memo/utils';
import { useAffiliatedPersonMemoState } from '@front/src/features/affiliated-person/features/memo/useState';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import Divider from '@front/layouts/Divider';
import { Box } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';

export { SearchForm as AffiliatedPersonMemoSearchForm };

const SearchForm = () => {
  const { setSearchParameter } = useAffiliatedPersonMemoState(
    useShallow((state) => ({
      setSearchParameter: state.setSearchParameter,
    }))
  );
  const methods = useForm({
    defaultValues: defaultSearchParameter,
  });
  const onSubmit = methods.handleSubmit((data) => {
    setSearchParameter({
      keyword: data.keyword || null,
    });
  });
  const handleKeyDown = useCallback(
    async (e) => {
      if (e.isComposing || e.keyCode === 229) return;
      if (e.key !== 'Enter') return;
      await onSubmit();
    },
    [onSubmit]
  );
  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          width: '100%',
          padding: '0 10px',
          margin: '5px 0',
        }}
      >
        <HookFormInput
          name="keyword"
          onKeyDown={handleKeyDown}
          placeholder="검색어 입력 후 엔터"
        />
        <Divider sx={{ margin: '10px 0 0 0' }} />
      </Box>
    </FormProvider>
  );
};
