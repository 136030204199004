import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import type { AffiliatedPersonSalesActivityConvertedView } from '@front/src/features/affiliated-person/features/sales-activity/types/view';
import { AffiliatedPersonSalesActivityRowContext } from '@front/src/features/affiliated-person/features/sales-activity/widgets/row/provider/context';

export { Provider as AffiliatedPersonSalesActivityRowProvider };

interface Props {
  children: ReactNode;
  item: AffiliatedPersonSalesActivityConvertedView;
}

const Provider = ({ children, item }: Props) => {
  const value = useMemo(() => ({ item }), [item]);
  return (
    <AffiliatedPersonSalesActivityRowContext.Provider value={value}>
      {children}
    </AffiliatedPersonSalesActivityRowContext.Provider>
  );
};
