import React from 'react';
import { getLeftListFromHeaderList } from '@front/src/utils';
import { commonRepository } from '@front/src/api/repository';
import type { OutputOptions } from '@front/src/features/work/features/work/features/output/components/Table';
import TableRowNoResultUI from '@front/src/components/components-with-design/compound/table/TableRowNoResult';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

export interface UIBuilderTableRowProps<T = any> extends UIBuilderTableBodyProps {
  item: T;
}

export interface UIBuilderTableBodyProps extends Omit<SectionComponentProps, 'name'> {
  rowActions?: UIBuilderRowActions;
  outputOptions?: OutputOptions;
}

export interface UIBuilderRowActions {
  onUpdate: any;
  onCreate?: any;
  onSeqDown?: any;
  onSeqUp?: any;
  onRemove?: any;
  onUpdateFile?: any;
}

interface Props<T> extends UIBuilderTableBodyProps {
  list?: T[];
  RowComponent: (props: UIBuilderTableRowProps) => JSX.Element;
}

export default function UIBuilderTableBody<T extends { id: number }>({
  RowComponent,
  list = [],
  readOnly,
  sectionId,
  menuId,
  ...rest
}: Readonly<Props<T>>) {
  const { data: headerList } = commonRepository.useGetHeaderList(sectionId, menuId);
  const leftList = getLeftListFromHeaderList(headerList, readOnly);
  const getColSpan = () => {
    if (!headerList) return 0;
    if (readOnly) return headerList.length + 1;
    return headerList.length + 2;
  };
  if (list.length === 0 || !leftList) return <TableRowNoResultUI colSpan={getColSpan()} />;
  return (
    <>
      {list.map((item) => (
        <RowComponent
          key={item.id}
          sectionId={sectionId}
          item={item}
          readOnly={readOnly}
          menuId={menuId}
          {...rest}
        />
      ))}
    </>
  );
}
