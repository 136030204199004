import React, { useMemo } from 'react';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalForm } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/components/form';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/widgets/context';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalLogic } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/widgets/useLogic';

export { Widget as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalWidget };
const Widget = () => {
  const {
    d: { detail, affiliatedCompanyOptionList, list },
    h: { onUpdate, onCreate },
    isLoading,
    id,
  } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalLogic();
  const value = useMemo(
    () => ({
      d: { detail, list, affiliatedCompanyOptionList },
      h: { onUpdate, onCreate },
    }),
    [onUpdate, detail, onCreate, list, affiliatedCompanyOptionList]
  );
  if (typeof id !== 'undefined' && (typeof detail === 'undefined' || isLoading)) {
    return <LoadingSpinner height="648px" />;
  }
  return (
    <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalContext.Provider value={value}>
      <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalForm />
    </ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalContext.Provider>
  );
};
