import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import type { WorkOutputBundleView } from '@front/src/features/work/features/work/features/output-bundle/types/view';
import type {
  WorkOutputBundleCreateParameter,
  WorkOutputBundleUpdateParameter,
} from '@front/src/features/work/features/work/features/output-bundle/types/parameter';
import {
  ModalBodyUI,
  ModalContentUI,
  ModalHeaderUI,
} from '@front/src/components/components-with-design/layout/ModalUI';
import InputWithHookForm from '@front/src/components/hook-form/Input';
import { useSnackbar } from 'notistack';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';

interface Props {
  item: WorkOutputBundleView;
  onCreate: (params: WorkOutputBundleCreateParameter) => void;
  onUpdate: (params: WorkOutputBundleUpdateParameter) => void;
  isCreate: boolean;
  onClose: () => void;
}

export default function WorkOutputBundleModalForm({
  item,
  onUpdate,
  onCreate,
  isCreate,
  onClose,
}: Readonly<Props>) {
  const methods = useForm({
    values: {
      name: isCreate ? '' : item.name ?? '',
    },
  });
  const { handleSubmit } = methods;
  const { enqueueSnackbar } = useSnackbar();
  const onValid = (data) => {
    if (isCreate) {
      onCreate({
        name: data.name,
      });
    } else {
      onUpdate({
        id: item.id,
        name: data.name,
      });
    }
  };
  const onInvalid = (errors) => {
    if (errors.name) {
      enqueueSnackbar(errors.name.message, { variant: 'error' });
    }
  };
  const onSubmit = handleSubmit(onValid, onInvalid);
  return (
    <FormProvider {...methods}>
      <ModalBodyUI>
        <ModalHeaderUI>
          <ButtonBasicUI
            shape="secondary2"
            size="medium"
            onClick={onClose}
          >
            취소
          </ButtonBasicUI>
          <ButtonBasicUI
            shape="primary2"
            size="medium"
            onClick={onSubmit}
          >
            확인
          </ButtonBasicUI>
        </ModalHeaderUI>
        <ModalContentUI>
          <InputWithHookForm
            name="name"
            placeholder="추가된 폴더"
            required="폴더명을 입력하세요"
          />
        </ModalContentUI>
      </ModalBodyUI>
    </FormProvider>
  );
}
