import React from 'react';
import type { FormikLayoutProps } from 'layouts/PageLayout';
import PageLayout from 'layouts/PageLayout';
import SearchForm from 'user/view/Page/SearchForm';
import type { ListProps } from 'user/view/Page/List';
import List from 'user/view/Page/List';
import type { UserQuery } from 'user/query';

interface Props extends ListProps, FormikLayoutProps<UserQuery> {}

export default function (props: Props) {
  return (
    <PageLayout
      title="유저 목록"
      filter={<SearchForm />}
      body={<List {...props} />}
      formik={props.formik}
    />
  );
}
