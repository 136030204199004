import React from 'react';
import { Box } from '@mui/material';
import TextBox from '@front/layouts/Text';

interface Props {
  children: React.ReactNode;
  title: string;
}

const SectionLayout = ({ children, title }: Props) => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      display: 'grid',
      rowGap: '10px',
    }}
  >
    <TextBox variant="heading3">{title}</TextBox>
    {children}
  </Box>
);

export default SectionLayout;
