import type { UserNotificationSearchParameter } from '@front/src/features/notification/types/parameter';
import { create } from 'zustand';

interface State {
  searchFilter: UserNotificationSearchParameter;
}

interface Action {
  setSearchFilter: (params: UserNotificationSearchParameter) => void;
}

const useNotificationStore = create<State & Action>((set) => ({
  searchFilter: {
    sourceType: ' ',
    type: ' ',
    isUnread: false,
    isBookmarked: false,
  },
  setSearchFilter: (searchFilter: UserNotificationSearchParameter) => set(() => ({ searchFilter })),
}));

export default useNotificationStore;
