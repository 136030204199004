import React, { useEffect, useMemo } from 'react';
import { ProjectSalesInfoContractContractModal } from '@front/src/features/project-sales-info/features/contract/features/contract/components/contract-modal';
import { ProjectSalesInfoContractContractContext } from '@front/src/features/project-sales-info/features/contract/features/contract/widgets/context';
import { useProjectSalesInfoContractContractLogic } from '@front/src/features/project-sales-info/features/contract/features/contract/widgets/useLogic';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { ProjectSalesInfoContractContractButtonGroup } from '@front/src/features/project-sales-info/features/contract/features/contract/components/contract-button-group';
import { ProjectSalesInfoContractContractTable } from '@front/src/features/project-sales-info/features/contract/features/contract/components/contract-table';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoContractContractState } from '@front/src/features/project-sales-info/features/contract/features/contract/widgets/useState';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoContractContractWidget = ({ readOnly, name }: Readonly<Props>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  const {
    d: { list, CMMethodOptionList },
    h: { onDelete },
    isLoading,
  } = useProjectSalesInfoContractContractLogic();
  const value = useMemo(
    () => ({
      d: { list, CMMethodOptionList },
      h: { onDelete },
    }),
    [list, onDelete, CMMethodOptionList]
  );
  const { setReadOnly } = useProjectSalesInfoContractContractState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  if (isLoading) {
    return <LoadingSpinner height="172px" />;
  }
  return (
    <ProjectSalesInfoContractContractContext.Provider value={value}>
      <ProjectSalesInfoContractContractModal />
      <Layout>
        <Header title={name}>
          <ProjectSalesInfoContractContractButtonGroup />
        </Header>
        <Body>
          <ProjectSalesInfoContractContractTable />
        </Body>
      </Layout>
    </ProjectSalesInfoContractContractContext.Provider>
  );
};

export default ProjectSalesInfoContractContractWidget;
