import type React from 'react';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material/styles';
import workCellComponent from '@front/src/features/work/features/work/components/cellComponent';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/TableBody';

export interface CellComponentProps<T = any> extends UIBuilderTableRowProps<T> {
  isEditMode?: boolean;
  onSubmit?: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  onClick?: () => void;
  sx?: SxProps<Theme>;
  isForm?: boolean;
}

export interface CellComponent<T = any> {
  [key: number]: React.FunctionComponent<CellComponentProps<T>>;
}

export const cellComponent: CellComponent = {
  ...workCellComponent,
};
