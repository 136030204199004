import type { RefObject } from 'react';
import React, { useCallback } from 'react';
import { AffiliatedCompanySelectorWidget } from '@front/src/features/affiliated-company-selector/widgets/widget';
import { useFormContext } from 'react-hook-form';
import { spliceAndPushParticipantListIfExist } from '@front/src/features/affiliated-company/utils';
import { useAffiliatedCompanyMeetingHistoryState } from '@front/src/features/affiliated-company/features/meeting-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { FormParticipantSelector as AffiliatedCompanyMeetingHistoryModalFormParticipantSelector };

interface Props {
  inputRef?: RefObject<HTMLInputElement>;
}

const FormParticipantSelector = ({ inputRef }: Props) => {
  const { setValue, getValues } = useFormContext();
  const handleSelector = useCallback(
    async (company, person) => {
      setValue(
        'participantList',
        spliceAndPushParticipantListIfExist(getValues('participantList'), person)
      );
    },
    [setValue, getValues]
  );
  const { readOnly } = useAffiliatedCompanyMeetingHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <AffiliatedCompanySelectorWidget
      inputRef={inputRef}
      value={{
        textValue: '',
      }}
      onCompanySelect={() => {}}
      onPersonSelect={handleSelector}
      usePersonSelector
      disabled={readOnly}
    />
  );
};
