import { create } from 'zustand';

interface State {
  addressId?: number;
  setAddressId: (addressId: number) => void;
  idList: number[];
  setIdList: (idList: number[]) => void;
}

export { useState as useAffiliatedCompanyOutlineAddressState };

const useState = create<State>((set) => ({
  setAddressId: (addressId: number) => set(() => ({ addressId })),
  idList: [],
  setIdList: (idList: number[]) => set(() => ({ idList })),
}));
