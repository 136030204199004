import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import { useQuery } from 'react-query';
import type { WorkCarouselView } from '@front/src/features/work/features/work/features/carousel/types/view';

const workCarouselQuery = {
  useListGet: (workId?: number, menuId?: number) => {
    const { data } = useQuery<
      WorkCarouselView[],
      unknown,
      WorkCarouselView[],
      (string | number | undefined)[]
    >({
      queryKey: ['work', 'carousel', workId],
      queryFn: () => workWorkApi.getCarousel(workId, menuId),
      suspense: true,
      enabled: !!menuId,
    });
    return {
      data,
    };
  },
};

export default workCarouselQuery;
