import type { CSSProperties } from 'react';
import React, { useCallback, useMemo, useRef } from 'react';
import type { CompanySearchHelper } from '@front/src/features/affiliated-company-selector/widgets/useLogic';
import { useAffiliatedCompanyModalListStyles } from '@front/src/features/affiliated-company-selector/components/affiliated-company-selector-modal-list-style';
import {
  InputAdornment,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import CircularProgress from '@front/components/CircularProgress';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import Input from '@front/layouts/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorPalette } from '@front/assets/theme';
import type { AffiliatedCompanyShortView } from '@front/src/features/affiliated-company/types/view';
import type { AffiliatedCompanyCategory } from '@front/src/features/affiliated-company/types/domain';

type AffiliatedCompanySelectorModalCompanyListProps = {
  usePersonSelector: boolean;
  helper: CompanySearchHelper;
  onSelect: (company: AffiliatedCompanyShortView) => void;
  exclude?: number[];
};

export default function AffiliatedCompanySelectorModalCompanyList(
  props: AffiliatedCompanySelectorModalCompanyListProps
) {
  const { setCategory, setKeyword, setTargetCompany, result, targetCompany, category } =
    props.helper;

  const handleCompanySelect = useCallback(
    (company: AffiliatedCompanyShortView) => {
      props.onSelect?.(company);
      props.usePersonSelector && setTargetCompany(company);
    },
    [setTargetCompany]
  );

  const data = useMemo(() => {
    if (!result.data) {
      return [];
    }
    return result.data.filter((item) => {
      if (!item.id) {
        return false;
      }
      return !props.exclude?.includes(item.id);
    });
  }, [result.data, props.exclude]);

  return (
    <div style={{ height: '100%' }}>
      <Form
        setTargetCompany={setTargetCompany}
        setCategory={setCategory}
        setKeyword={setKeyword}
        category={category}
      />
      <List
        onSelect={handleCompanySelect}
        isLoading={result.isLoading}
        data={data}
        targetCompanyId={targetCompany?.id}
      />
    </div>
  );
}

type FormProp = {
  setTargetCompany: (AffiliatedCompanyShortView) => void;
  setCategory: (category: AffiliatedCompanyCategory) => void;
  setKeyword: (keyword: string) => void;
  category: AffiliatedCompanyCategory;
};

const Form = (prop: FormProp) => {
  const inputRef = useRef<HTMLInputElement>();
  const { setKeyword, setTargetCompany } = prop;

  const handleSearch = useCallback(() => {
    if (inputRef.current) {
      setKeyword(inputRef.current.value);
      setTargetCompany(undefined);
    }
  }, [inputRef, setTargetCompany]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleSearch();
      }
    },
    [setKeyword, setTargetCompany, handleSearch]
  );

  return (
    <div style={{ marginBottom: '10px' }}>
      <Input
        inputRef={inputRef}
        style={{ width: '79%' }}
        variant="outlined"
        placeholder="검색할 회사명 입력(엔터)"
        onKeyDown={handleKeyDown}
        endAdornment={
          <InputAdornment
            position="end"
            sx={{ marginRight: '10px' }}
            onClick={handleSearch}
          >
            <FontAwesomeIcon
              icon="search"
              style={{
                fontSize: '16px',
                color: ColorPalette._386dd6,
                cursor: 'pointer',
              }}
            />
          </InputAdornment>
        }
      />
    </div>
  );
};

type ListProp = {
  onSelect: (company: AffiliatedCompanyShortView) => void;
  isLoading: boolean;
  data: AffiliatedCompanyShortView[];
  targetCompanyId: number | undefined;
};

const List = (props: ListProp) => {
  const tableRowHeight = 35;
  const tableRef = useRef<FixedSizeList>(null);
  const { isLoading, data, onSelect, targetCompanyId } = props;
  const classes = useAffiliatedCompanyModalListStyles();
  const handleCompanySelect = useCallback(
    (company: AffiliatedCompanyShortView) => {
      onSelect(company);
    },
    [onSelect]
  );

  const TableRowWrap = useCallback(
    (props: any) => {
      const index: number = props.index;
      const company = props.data[index] as AffiliatedCompanyShortView;
      const style: CSSProperties = { ...props.style };
      return (
        <TableRow
          component="div"
          className={classes.row}
          style={style}
          onClick={() => {
            handleCompanySelect(company);
          }}
        >
          <TableCell
            className={classes.cell_company}
            component="div"
          >
            <Radio
              disableRipple={true}
              value={company.id}
              checked={targetCompanyId === company.id}
            />
          </TableCell>
          <TableCell
            className={classes.cell_company}
            component="div"
          >
            <span>{company.name}</span>
          </TableCell>
        </TableRow>
      );
    },
    [handleCompanySelect, targetCompanyId, classes]
  );

  const initialFocusItemOffset = useMemo(() => {
    let foundIndex = 0;
    if (props.targetCompanyId) {
      data.forEach((company, index) => {
        if (company.id === props.targetCompanyId) {
          foundIndex = index;
          return false;
        }
      });
    }
    return foundIndex;
  }, [data, props.targetCompanyId]);

  return (
    <div
      className={classes.root}
      style={{
        height: '100%',
      }}
    >
      <Table
        component="div"
        stickyHeader
        aria-label="sticky table"
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        <TableHead
          component="div"
          className={classes.thead}
        >
          <TableRow
            component="div"
            className={classes.row}
          >
            <TableCell
              className={classes.cell_company}
              sx={{ whiteSpace: 'nowrap' }}
              component="div"
            >
              선택
            </TableCell>
            <TableCell
              className={classes.cell_company}
              component="div"
            >
              업체명
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody
          component="div"
          className={classes.tbody}
        >
          {isLoading && (
            <TableRow
              component="div"
              className={classes.no_result}
            >
              <TableCell
                component="div"
                colSpan={3}
                children={<CircularProgress size={30} />}
              />
            </TableRow>
          )}
          {!isLoading && data && data.length === 0 && (
            <TableRow
              component="div"
              className={classes.no_result}
            >
              <TableCell
                component="div"
                colSpan={3}
                children="결과가 없습니다."
              />
            </TableRow>
          )}
          {!isLoading && data && data.length > 0 && (
            <AutoSizer>
              {({ height, width }) => (
                <FixedSizeList
                  ref={tableRef}
                  initialScrollOffset={
                    initialFocusItemOffset > 0 ? (initialFocusItemOffset - 1) * tableRowHeight : 0
                  }
                  overscanCount={5}
                  height={height}
                  itemCount={data ? data.length : 0}
                  itemSize={tableRowHeight}
                  width={width}
                  itemData={data}
                  className="scroll-bar-holder"
                >
                  {TableRowWrap as any}
                </FixedSizeList>
              )}
            </AutoSizer>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
