import React from 'react';
import { AccountingSettingsManagementAccountingAccountTableFeature } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table';
import { AccountingSettingsManagementAccountingAccountFeatureProvider } from '@front/src/features/accounting/features/settings/features/management-accounting-account/provider/provider';
import { accountingSettingsContentLayout } from '@front/src/features/accounting/features/settings/components/layouts/content';
import { AccountingSettingsManagementAccountingAccountHeaderFeature } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/header';
import { useGetReadOnly } from '@front/src/hooks/useGetReadOnly';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AccountingSettingsManagementAccountingAccountFeature = ({ name }: Readonly<Props>) => {
  const { Layout, Header, Body } = accountingSettingsContentLayout;
  const { readOnly } = useGetReadOnly();
  return (
    <AccountingSettingsManagementAccountingAccountFeatureProvider readOnly={readOnly}>
      <Layout>
        <Header
          title={name}
          rightComponent={<AccountingSettingsManagementAccountingAccountHeaderFeature />}
        />
        <Body>
          <AccountingSettingsManagementAccountingAccountTableFeature />
        </Body>
      </Layout>
    </AccountingSettingsManagementAccountingAccountFeatureProvider>
  );
};

export default AccountingSettingsManagementAccountingAccountFeature;
