import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import useWorkWorkDetailState from '@front/src/features/work/features/work/features/detail/useState';
import { useCustomDialog } from '@front/src/features/dialog';
import useWorkDetailAddTop from '@front/src/features/work/features/work/features/detail/repository/useAddTop';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import WorkDetailTableBody from '@front/src/features/work/features/work/features/detail/components/TableBody';
import workDetailMutation from '@front/src/features/work/features/work/features/detail/query/mutation';
import useWorkDetailAddBottom from '@front/src/features/work/features/work/features/detail/repository/useAddBottom';
import useWorkDetailUpdate from '@front/src/features/work/features/work/features/detail/repository/useUpdate';
import useWorkDetailCreate from '@front/src/features/work/features/work/features/detail/repository/useCreate';
import useWorkDetailSeqUp from '@front/src/features/work/features/work/features/detail/repository/useSeqUp';
import useWorkDetailSeqDown from '@front/src/features/work/features/work/features/detail/repository/useSeqDown';
import { getCommonControlPanelHeaderItemList } from '@front/src/features/work/features/work/utils/constant';

export default function WorkDetailTable({
  maxWidth,
  dataId,
  menuId,
  sectionId,
  ...rest
}: Readonly<SectionComponentProps>) {
  const { clearEditOpenList } = useWorkWorkDetailState(
    useShallow((state) => ({
      clearEditOpenList: state.clearEditOpenList,
    }))
  );
  const {
    onAddTop,
    onAddBottom,
    onRemove,
    onRemoveAll,
    onCreate,
    onSeqUp,
    onSeqDown,
    onUpdate,
    isLoading,
  } = useMutationLogic({ dataId, menuId, sectionId });
  const actions = {
    onRemoveAll,
    onAddTop,
    onAddBottom,
    onCloseAll: clearEditOpenList,
  };
  const controlPanelHeaderItemList = getCommonControlPanelHeaderItemList({
    actions,
  });
  const rowActions = {
    onUpdate,
    onCreate,
    onRemove,
    onSeqUp,
    onSeqDown,
  };
  return (
    <UiBuilderTable
      isLoading={isLoading}
      rowActions={rowActions}
      BodyComponent={WorkDetailTableBody}
      controlPanelHeaderItemList={controlPanelHeaderItemList}
      dataId={dataId}
      menuId={menuId}
      sectionId={sectionId}
      {...rest}
      // sx={{
      //   maxWidth,
      // }}
    />
  );
}

function useMutationLogic({ dataId, menuId, sectionId }) {
  const { setRecentUpdatedId, addEditOpenId } = useWorkWorkDetailState(
    useShallow((state) => ({
      setRecentUpdatedId: state.setRecentUpdatedId,
      addEditOpenId: state.addEditOpenId,
    }))
  );
  const { confirm } = useCustomDialog();
  const { mutate: onDeleteAll, isLoading: isDeleteAllLoading } = workDetailMutation.useDeleteAll({
    dataId,
    menuId,
    sectionId,
  });
  const {
    run: onAddTop,
    setCallback: setAddTopCallback,
    isLoading: isAddTopLoading,
  } = useWorkDetailAddTop(dataId, menuId);
  setAddTopCallback({
    onSuccess: ({ data }) => {
      setRecentUpdatedId(data);
      addEditOpenId(data);
    },
  });
  const {
    run: onAddBottom,
    setCallback: setAddBottomCallback,
    isLoading: isAddBottomLoading,
  } = useWorkDetailAddBottom(dataId, menuId);
  setAddBottomCallback({
    onSuccess: ({ data }) => {
      setRecentUpdatedId(data);
      addEditOpenId(data);
    },
  });
  const {
    run: onUpdate,
    setCallback: setUpdateCallback,
    isLoading: isUpdateLoading,
  } = useWorkDetailUpdate(dataId, menuId);
  setUpdateCallback({
    onSuccess: ({ data }, _, context) => {
      !context && setRecentUpdatedId(data);
    },
  });
  const {
    run: onCreate,
    setCallback: setCreateCallback,
    isLoading: isCreateLoading,
  } = useWorkDetailCreate(dataId, menuId);
  setCreateCallback({
    onSuccess: ({ data }) => {
      setRecentUpdatedId(data);
      addEditOpenId(data);
    },
  });
  const { mutate: onDelete, isLoading: isDeleteLoading } = workDetailMutation.useDelete({
    dataId,
    menuId,
    sectionId,
  });
  const {
    run: onSeqUp,
    setCallback: setSeqUpCallback,
    isLoading: isSeqUpLoading,
  } = useWorkDetailSeqUp(dataId, menuId);
  setSeqUpCallback({
    onSuccess: ({ data }) => setRecentUpdatedId(data),
  });
  const {
    run: onSeqDown,
    setCallback: setSeqDownCallback,
    isLoading: isSeqDownLoading,
  } = useWorkDetailSeqDown(dataId, menuId);
  setSeqDownCallback({
    onSuccess: ({ data }) => setRecentUpdatedId(data),
  });
  const onRemove = (id) => {
    confirm({
      lineBreakChildren: [{ value: '선택한 내용을 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDelete(id);
      },
    });
  };
  const onRemoveAll = () => {
    confirm({
      lineBreakChildren: [{ value: '작성자의 데이터가 전체 삭제됩니다.' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDeleteAll();
      },
    });
  };
  const isLoading =
    isDeleteAllLoading ||
    isAddTopLoading ||
    isAddBottomLoading ||
    isUpdateLoading ||
    isCreateLoading ||
    isDeleteLoading ||
    isSeqUpLoading ||
    isSeqDownLoading;
  return {
    isLoading,
    onAddTop,
    onAddBottom,
    onUpdate,
    onCreate,
    onSeqUp,
    onSeqDown,
    onRemove,
    onRemoveAll,
  };
}
