import type { ReactNode } from 'react';
import React from 'react';

import classes from '@front/src/components/layout/article-item-view/article-item-layout.module.scss';

interface Props {
  children: ReactNode;
}

export const ArticleItemLayout = ({ children }: Props) => (
  <div className={classes.container}>{children}</div>
);
