import { OldTd } from '@front/layouts/Table';
import classes from '@front/src/features/affiliated-company/features/meeting-history/components/meeting-history-table-row.module.scss';
import Checkbox from '@front/layouts/Checkbox';
import { TableRow } from '@mui/material';
import React, { useCallback, useContext, useState } from 'react';
import { spliceAndPushIfExist } from '@front/src/features/affiliated-company/utils';
import type { AffiliatedCompanyMeetingHistoryShortView } from '@front/src/features/affiliated-company/features/meeting-history/types/view';
import { purposeCategoryKOR } from '@front/src/features/affiliated-company/types/domain';
import { AffiliatedCompanyMeetingHistoryContext } from '@front/src/features/affiliated-company/features/meeting-history/widgets/context';
import { getCodeIfExist } from '@front/src/features/project-selector-modal/utils';
import { useAffiliatedCompanyMeetingHistoryState } from '@front/src/features/affiliated-company/features/meeting-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import { useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

export { MeetingHistoryTableRow as AffiliatedCompanyMeetingHistoryTableRow };

interface Props {
  meeting: AffiliatedCompanyMeetingHistoryShortView;
  index: number;
}

const MeetingHistoryTableRow = ({ meeting, index }: Props) => {
  const {
    h: { onUpdateModalOpen, setIdList },
    idList,
  } = useContext(AffiliatedCompanyMeetingHistoryContext);
  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
      event.nativeEvent.stopPropagation();
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  const [isCheckboxHovered, setIsCheckboxHovered] = useState(false);
  const handleCheckboxEnter = () => setIsCheckboxHovered((prev) => !prev);
  const handleCheckboxLeave = () => setIsCheckboxHovered((prev) => !prev);
  const { readOnly } = useAffiliatedCompanyMeetingHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const menuId = useGlobalNavBarState((state) => state.menuId);
  const loginUser = useSelector((root: RootState) => root.login.detail);
  const currentAuth = loginUser?.menus?.find((item) => item.id === (menuId ? +menuId : 0))
    ?.authorization.type;
  return (
    <TableRow
      key={meeting.id}
      onClick={() => onUpdateModalOpen(meeting.id)}
      hover={!isCheckboxHovered}
      sx={{ cursor: 'pointer' }}
    >
      <OldTd>
        <div className={classes.th}>
          <Checkbox
            checked={idList.includes(meeting.id)}
            onMouseEnter={handleCheckboxEnter}
            onMouseLeave={handleCheckboxLeave}
            onClick={(e) => e.stopPropagation()}
            onChange={(event) => handleCheckboxChange(event, meeting.id)}
            disabled={readOnly}
          />
        </div>
      </OldTd>
      <OldTd>{index + 1}</OldTd>
      <OldTd>{meeting.date ?? '-'}</OldTd>
      <OldTd>
        {meeting.participantList.length === 0 && '-'}
        {meeting.participantList.length > 0 &&
          meeting.participantList.map((item) => <div key={item.id}>{item.name}</div>)}
      </OldTd>
      <OldTd>
        {meeting.companionList.length === 0 && '-'}
        {meeting.companionList.length > 0 &&
          meeting.companionList.map((item) => <div key={item.id}>{item.name}</div>)}
      </OldTd>
      <OldTd>{purposeCategoryKOR[meeting.purpose] ?? '-'}</OldTd>
      <OldTd>
        {(meeting.projectList.length === 0 || currentAuth === 'V') && '-'}
        {meeting.projectList.length > 0 &&
          currentAuth !== 'V' &&
          meeting.projectList.map((item) => (
            <div key={item.id}>{getCodeIfExist(item.code) + ' ' + item.nickName}</div>
          ))}
      </OldTd>
      <OldTd>{meeting.summary ?? '-'}</OldTd>
    </TableRow>
  );
};
