import { useIsMutating } from 'react-query';

export const useNotificationMutationLoading = () => {
  const isNotificationDeleteLoading = useIsMutating({ mutationKey: ['notification', 'delete'] });
  const isNotificationBookmarkLoading = useIsMutating({
    mutationKey: ['notification', 'bookmark'],
  });
  const isNotificationReadLoading = useIsMutating({ mutationKey: ['notification', 'read'] });

  return !!(
    isNotificationBookmarkLoading ||
    isNotificationDeleteLoading ||
    isNotificationReadLoading
  );
};
