import React, { useCallback, useContext, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { accountingUploadCategoryList } from '@front/src/features/accounting/features/upload/utils/constant';
import Button from '@front/layouts/Button';
import { ECOUNT_FILE_TYPES } from '@front/src/features/accounting/features/upload/features/ecount-data/types/domain';
import { AccountingUploadEcountDataUploadContext } from '@front/src/features/accounting/features/upload/features/ecount-data/widgets/upload/provider/context';
import { HookFormFileUploader } from '@front/src/components/file-uploader/hook-form-file-uploader';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { AccountingUploadEcountDataContext } from '@front/src/features/accounting/features/upload/features/ecount-data/provider/context';
import { useAccountingUploadEcountDataState } from '@front/src/features/accounting/features/upload/features/ecount-data/useState';
import { shallow } from 'zustand/shallow';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { getIsShowForAuthorizationFromMenu } from '@front/src/utils';
import { useShallow } from 'zustand/react/shallow';

export { Form as AccountingUploadEcountDataForm };

const Form = () => {
  const { isModalMode } = useContext(AccountingUploadEcountDataContext);
  const { onTexAccountingCreate, onBankAccountCreate, onDailyReportCreate, isLoading } = useContext(
    AccountingUploadEcountDataUploadContext
  );
  const { ecountFileType, setEcountFileType } = useAccountingUploadEcountDataState(
    (state) => ({
      ecountFileType: state.ecountFileType,
      setEcountFileType: state.setEcountFileType,
    }),
    shallow
  );
  const methods = useForm({
    values: {
      category: isModalMode ? ECOUNT_FILE_TYPES.FINANCIAL_ACCOUNT : '',
      file: null,
    },
  });
  const { setValue, getValues } = methods;
  const handleUploadClick = useCallback(() => {
    if (!ecountFileType || !getValues('file')) {
      return;
    }
    const file = getValues('file') ?? {
      filename: '',
      ext: '',
      size: 0,
      readableSize: '',
    };
    const params = {
      file,
      category: ecountFileType,
    };
    if (ecountFileType === ECOUNT_FILE_TYPES.FINANCIAL_ACCOUNT) {
      onTexAccountingCreate(params);
    } else if (ecountFileType === ECOUNT_FILE_TYPES.BANK_ACCOUNT) {
      onBankAccountCreate(params);
    } else if (ecountFileType === ECOUNT_FILE_TYPES.DAILY_FUNDS) {
      onDailyReportCreate(params);
    }
    setValue('file', null);
  }, [
    setValue,
    getValues,
    ecountFileType,
    onTexAccountingCreate,
    onBankAccountCreate,
    onDailyReportCreate,
  ]);
  const handleSelectChange = useCallback(
    (e) => {
      setEcountFileType(e.target.value);
    },
    [setEcountFileType]
  );
  const label = useMemo(() => {
    if (ecountFileType === ECOUNT_FILE_TYPES.FINANCIAL_ACCOUNT) {
      return '회계 계정 등록 파일 선택';
    }
    if (ecountFileType === ECOUNT_FILE_TYPES.BANK_ACCOUNT) {
      return '기초 계좌 등록 파일 선택';
    }
  }, [ecountFileType]);
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const isShow = useMemo(() => {
    if (!loginUser?.menus) return false;
    return getIsShowForAuthorizationFromMenu(loginUser.menus);
  }, [loginUser]);
  const { readOnly } = useAccountingUploadEcountDataState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!isModalMode && (
        <HookFormSelect
          width="160px"
          isDefaultPossible
          defaultLabel="처리 대상 선택"
          name="category"
          onChange={handleSelectChange}
          optionList={accountingUploadCategoryList}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '400px',
          height: '60px',
        }}
      >
        <HookFormFileUploader
          name="file"
          isPossibleDownload={false}
          label={label}
        />
      </Box>
      {isShow && (
        <Button
          onClick={handleUploadClick}
          disabled={readOnly}
        >
          UPLOAD
        </Button>
      )}
    </FormProvider>
  );
};
