import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAccountingSettingsBankAccountUpdateBankNameLogic } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/update-bank-name/provider/useLogic';
import { AccountingSettingsBankAccountUpdateBankNameContext } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/update-bank-name/provider/context';
import type { BankAccountView } from '@front/src/features/accounting/features/settings/features/bank-account/types/view';

export { Provider as AccountingSettingsBankAccountUpdateBankNameProvider };

interface Props {
  children: ReactNode;
  item: BankAccountView;
}

const Provider = ({ children, item }: Props) => {
  const { onUpdate } = useAccountingSettingsBankAccountUpdateBankNameLogic();
  const value = useMemo(() => ({ onUpdate, item }), [onUpdate, item]);
  return (
    <AccountingSettingsBankAccountUpdateBankNameContext.Provider value={value}>
      {children}
    </AccountingSettingsBankAccountUpdateBankNameContext.Provider>
  );
};
