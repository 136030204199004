import { affiliatedPersonMemoRepository } from '@front/src/features/affiliated-person/features/memo/repository/repository';

export { useLogic as useAffiliatedPersonMemoUpdateLogic };

const useLogic = () => {
  const { run: onUpdate } = affiliatedPersonMemoRepository.useUpdate();
  return {
    onUpdate,
  };
};
