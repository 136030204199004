import { affiliatedPersonDrawerQuery } from '@front/src/features/affiliated-person/features/drawer/query/query';

export { repository as affiliatedPersonDrawerRepository };

const repository = {
  useListGet: () => {
    const { data, remove } = affiliatedPersonDrawerQuery.useListGet();
    return {
      data,
      remove,
    };
  },
};
