import React, { useContext } from 'react';
import { AccountingSettingsBankAccountListContext } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/list/provider/context';
import { AccountingSettingsBankAccountListTableRow } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/list/components/table-row';
import { NoResult } from '@front/src/components/layout/table/no-result';

export { TableBody as AccountingSettingsBankAccountListTableBody };

const TableBody = () => {
  const { list } = useContext(AccountingSettingsBankAccountListContext);
  if (list?.length === 0) {
    return (
      <NoResult
        colSpan={8}
        height="40px"
      />
    );
  }
  return (
    <>
      {list?.map((item) => (
        <AccountingSettingsBankAccountListTableRow
          key={item.id}
          item={item}
        />
      ))}
    </>
  );
};
