import { default as axios } from '@front/src/config/axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import type { ProjectEstimationConfirmedParameter } from '@front/src/features/project-sales-info/features/estimation/features/estimation-confirmed/types/parameter';

export { api as projectSalesInfoEstimationConfirmedApi };
const url = {
  getDetail: (id?) => `${projectSalesInfoURL}/${id}/estimation-confirmed`,
  update: (id?) => `${projectSalesInfoURL}/estimation-confirmed/${id}`,
};

const api = {
  getDetail: async (id?: number) => {
    const { data } = await axios.get(url.getDetail(id));
    return data;
  },
  update: async (params: ProjectEstimationConfirmedParameter) => {
    await axios.put(url.update(params.id), params);
  },
};
