import dayjs from 'dayjs';
import { ColorPalette } from '@front/assets/theme';

export const getBorderColorByReviewedState = (reviewed: boolean, date: string) => {
  if (reviewed) {
    return ColorPalette._386dd6;
  } else {
    if (dayjs().isAfter(date)) {
      return ColorPalette._eb4c4c;
    } else {
      return 'transparent';
    }
  }
};
