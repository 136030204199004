import React from 'react';
import { AccountingUploadSalesInfoExpectModalUpdateProvider } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/widgets/update/provider/provider';
import { AccountingUploadSalesInfoExpectModalButtonGroup } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/widgets/update/components/button-group';

export { Widget as AccountingUploadSalesInfoExpectModalUpdateWidget };

const Widget = () => (
  <AccountingUploadSalesInfoExpectModalUpdateProvider>
    <AccountingUploadSalesInfoExpectModalButtonGroup />
  </AccountingUploadSalesInfoExpectModalUpdateProvider>
);
