import { create } from 'zustand';

import type { SnackbarShowProps } from '@front/src/features/snackbar/types';
import { SnackbarSeverityType } from '@front/src/features/snackbar/types';

interface State {
  isOpen: boolean;
  message: string;
  severity: SnackbarSeverityType;
  show: (props: SnackbarShowProps) => void;
  setIsOpen: (isOpen: boolean) => void;
}

export { useState as useSnackbarState };

const useState = create<State>((set) => ({
  isOpen: false,
  message: '',
  severity: SnackbarSeverityType.info,
  show: ({ message, severity = SnackbarSeverityType.warning }) =>
    set(() => ({ isOpen: true, message, severity })),
  setIsOpen: (isOpen: boolean) => set(() => ({ isOpen })),
}));
