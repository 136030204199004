import React, { useCallback } from 'react';
import { headerHistoryRepository } from '@front/src/features/header-history/repository/repository';
import Select from '@front/layouts/Select';
import { MenuItem } from '@mui/material';
import { useHeaderHistoryState } from '@front/src/features/header-history/useState';
import { useShallow } from 'zustand/react/shallow';

export default function HeaderHistoryHeaderSelect() {
  const { data: list } = headerHistoryRepository.useGetHeaderList();
  const { headerId, setHeaderId } = useHeaderHistoryState(
    useShallow((state) => ({
      setHeaderId: state.setHeaderId,
      headerId: state.headerId,
    }))
  );
  const handleChange = useCallback(
    (e) => {
      setHeaderId(e.target.value);
    },
    [setHeaderId]
  );
  return (
    <Select
      variant="outlined"
      value={headerId}
      onChange={handleChange}
      displayEmpty
    >
      <MenuItem value="">전체</MenuItem>
      {list?.map((item) => (
        <MenuItem
          key={item.id}
          value={item.headerId}
        >
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
}
