import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { ProjectSalesInfoBidInfoParams } from '@front/src/features/project-sales-info/features/bid/features/bid-information/types/parameter';
import { projectSalesInfoBidInfoCreateModalMutation } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/query/mutation';
import { projectSalesInfoBidInfoCreateModalQuery } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/query';

export const projectSalesInfoBidInfoCreateModalRepository = {
  useProjectSalesInfoBidInfoDetailCreate: (id: number) => {
    const { mutate } =
      projectSalesInfoBidInfoCreateModalMutation.useProjectSalesInfoBidInfoCreate(id);
    const callback = getNoOpCallback();
    return {
      run: (params: ProjectSalesInfoBidInfoParams) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useEstimateIdListGet: (id: number) => {
    const { data, isLoading } = projectSalesInfoBidInfoCreateModalQuery.useEstimateIdListGet(id);
    return {
      data,
      isLoading,
    };
  },
};
