import { useAffiliatedCompanyDetailState } from '@front/src/features/affiliated-company/widgets/detail/useState';
import { useQueryClient } from 'react-query';
import { affiliatedCompanyMeetingHistoryModalRepository } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/repository/repository';
import {
  useAffiliatedCompanyMeetingHistoryModalState,
  useAffiliatedCompanyMeetingHistoryModalUserListState,
} from '@front/src/features/affiliated-company/features/meeting-history/features/modal/widgets/useState';
import { affiliatedCompanyOutlineRepository } from '@front/src/features/affiliated-company/features/outline/repository/repository';
import { useShallow } from 'zustand/react/shallow';

export { useLogic as useAffiliatedCompanyMeetingHistoryModalLogic };

const useLogic = (menuId) => {
  const queryClient = useQueryClient();
  const { id } = useAffiliatedCompanyDetailState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const {
    meetingHistoryId,
    setIsUpdateModalOpen,
    isCreateModalOpen,
    setIsCreateModalOpen,
    isUpdateModalOpen,
  } = useAffiliatedCompanyMeetingHistoryModalState(
    useShallow((state) => ({
      meetingHistoryId: state.meetingHistoryId,
      setIsUpdateModalOpen: state.setIsUpdateModalOpen,
      isCreateModalOpen: state.isCreateModalOpen,
      setIsCreateModalOpen: state.setIsCreateModalOpen,
      isUpdateModalOpen: state.isUpdateModalOpen,
    }))
  );
  const { userIdList, setUserIdList } = useAffiliatedCompanyMeetingHistoryModalUserListState(
    useShallow((state) => ({
      userIdList: state.userIdList,
      setUserIdList: state.setUserIdList,
    }))
  );
  const { data: detail, isLoading } =
    affiliatedCompanyMeetingHistoryModalRepository.useMeetingHistoryGet(meetingHistoryId, menuId);
  const { run: onMeetingHistoryCreate, setCallback: setCreateCallback } =
    affiliatedCompanyMeetingHistoryModalRepository.useMeetingHistoryCreate(menuId);
  const { run: onMeetingHistoryUpdate, setCallback: setUpdateCallback } =
    affiliatedCompanyMeetingHistoryModalRepository.useMeetingHistoryUpdate(menuId);
  const { data: userList } =
    affiliatedCompanyMeetingHistoryModalRepository.useUserListGet(userIdList);
  const { data: detailOutline } = affiliatedCompanyOutlineRepository.useOutlineGet(id, menuId);
  setCreateCallback({
    onSuccess: () => {
      setIsCreateModalOpen(false);
    },
  });
  setUpdateCallback({
    onSuccess: () => {
      setIsUpdateModalOpen(false);
    },
  });
  const d = { detail, userList, status: detailOutline?.status };
  const updateList = async () => {
    await queryClient.invalidateQueries({
      queryKey: ['affiliated-company', 'meeting-history', 'list'],
    });
  };
  const h = {
    onCreateModalClose: async () => {
      await updateList();
      setIsCreateModalOpen(false);
    },
    onUpdateModalClose: async () => {
      await updateList();
      setIsUpdateModalOpen(false);
    },
    onMeetingHistoryCreate: (params) =>
      onMeetingHistoryCreate({
        ...params,
        id,
      }),
    onMeetingHistoryUpdate: (params) =>
      onMeetingHistoryUpdate({
        ...params,
        id: meetingHistoryId,
      }),
    setUserIdList,
  };
  return {
    d,
    h,
    isCreateModalOpen,
    isUpdateModalOpen,
    isLoading,
    id,
    userIdList,
  };
};
