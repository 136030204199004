import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyRepresentativeContractInformationMutation } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/query/mutation';
import { affiliatedCompanyRepresentativeContractInformationQuery } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/query/query';

export const affiliatedCompanyRepresentativeContractInformationRepository = {
  usePersonOutlineRepresentativeContactInformationGet: (id?: number, menuId?) => {
    const { data, isLoading } =
      affiliatedCompanyRepresentativeContractInformationQuery.usePersonOutlineRepresentativeContactInformationGet(
        id,
        menuId
      );
    return {
      data,
      isLoading,
    };
  },
  usePersonOutlineRepresentativeContactInformationUpdate: (menuId) => {
    const { mutate } =
      affiliatedCompanyRepresentativeContractInformationMutation.usePersonOutlineRepresentativeContactInformationUpdate(
        menuId
      );
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useCompanyUpdate: (menuId) => {
    const { mutate } =
      affiliatedCompanyRepresentativeContractInformationMutation.useCompanyUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
