import { default as axios } from '@front/src/config/axios';
import type { IdDeleteParams } from '@front/src/features/project-sales-info/features/bid/features/bid-information/types/parameter';
import { bidURLAPI } from '@front/src/features/project-sales-info/features/bid/utils/constant';

const url = {
  getBidInfoList: (id: number) => `${bidURLAPI}/${id}/bid-information`,
  deleteBidInfo: () => `${bidURLAPI}/bid-information`,
};

export const projectSalesInfoBidInfoApi = {
  getBidInfoList: async (id: number) => {
    const { data } = await axios.get(url.getBidInfoList(id));
    return data;
  },
  deleteBidInfo: async (params: IdDeleteParams) => {
    await axios.delete(url.deleteBidInfo(), { data: params });
  },
};
