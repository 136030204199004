import { createContext } from 'react';
import { noOp } from '@front/common/contants';

import type { DefaultFunction } from '@front/src/types';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import type { AffiliatedCompanyMeetingHistoryShortView } from '@front/src/features/affiliated-company/features/meeting-history/types/view';

export { Context as AffiliatedCompanyMeetingHistoryContext };

interface State {
  d: {
    meetingList?: AffiliatedCompanyMeetingHistoryShortView[];
  };
  h: {
    onCreateModalOpen: DefaultFunction;
    onUpdateModalOpen: (id?: number) => void;
    setIdList: (idList: number[]) => void;
    onMeetingHistoryDelete: (params: AffiliatedCompanyIdListDeleteParameter) => void;
  };
  isLoading: boolean;
  idList: number[];
}

const Context = createContext<State>({
  d: {
    meetingList: [],
  },
  h: {
    onCreateModalOpen: noOp,
    onUpdateModalOpen: noOp,
    onMeetingHistoryDelete: noOp,
    setIdList: noOp,
  },
  isLoading: false,
  idList: [],
});
