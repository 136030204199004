import type { DepartmentId, DepartmentVO } from 'department/domain';

export interface DepartmentParameter
  extends Omit<DepartmentVO, 'id' | 'seq' | 'userList' | 'childrenList'> {
  id?: DepartmentId;
  menuId?: number | undefined;
}

export const initialDepartmentParameter = {
  edit: true,
} as unknown as DepartmentParameter;
