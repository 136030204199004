import React, { useCallback, useContext } from 'react';
import { Radio, TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { shallow } from 'zustand/shallow';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/widgets/context';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/widgets/useState';
import { getValueIfExist } from '@front/src/utils';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { AffiliatedCompanyTableBody as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyTableBody };
const AffiliatedCompanyTableBody = () => {
  const {
    d: { list },
    isLoading,
  } = useContext(ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyContext);
  const { id, setId } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalState(
    (state) => ({
      setId: state.setId,
      id: state.id,
    }),
    shallow
  );
  const { readOnly } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const handleClick = useCallback(
    (id) => {
      if (readOnly) return;
      setId(id);
    },
    [setId, readOnly]
  );
  if (typeof list === 'undefined' || isLoading) {
    return <TableLoadingSpinner colSpan={10} />;
  }
  if (list.length === 0) {
    return <NoResult colSpan={10} />;
  }
  return (
    <>
      {list.map((item) => (
        <TableRow
          key={item.id}
          hover
          sx={{ cursor: 'pointer' }}
          onClick={() => handleClick(item.id)}
        >
          <OldTd>
            <Radio
              value={item.id}
              checked={item.id === id}
              onChange={() => handleClick(item.id)}
              disabled={readOnly}
            />
          </OldTd>
          <OldTd>{getValueIfExist(item.buildingCompany?.name)}</OldTd>
          <OldTd>{getValueIfExist(item.structuralDesignCompany?.name)}</OldTd>
          <OldTd>{getValueIfExist(item.implementingCompany?.name)}</OldTd>
          <OldTd>{getValueIfExist(item.constructingCompany?.name)}</OldTd>
          <OldTd>{getValueIfExist(item.recommenderName)}</OldTd>
          <OldTd>{getValueIfExist(item.clientInContract?.name)}</OldTd>
          <OldTd>{getValueIfExist(item.client?.name)}</OldTd>
          <OldTd>{getValueIfExist(item.etcCompany?.name)}</OldTd>
          <OldTd>{getValueIfExist(item.note)}</OldTd>
        </TableRow>
      ))}
    </>
  );
};
