import React, { useCallback, useEffect } from 'react';
import AffiliatedCompanySelector from '@front/src/features/affiliated-company-selector/components/affiliated-company-selector';
import { useAffiliatedCompanySelectorLogic } from '@front/src/features/affiliated-company-selector/widgets/useLogic';
import { AffiliatedCompanySelectorModal } from '@front/src/features/affiliated-company-selector/components/affiliated-company-selector-modal';
import type { AffiliatedCompanySelectorWidgetProp } from '@front/src/features/affiliated-company-selector/types/affiliated-company-selector-types';
import type { AffiliatedCompanyShortView } from '@front/src/features/affiliated-company/types/view';
import type { AffiliatedCompanyPersonShortView } from '@front/src/features/affiliated-company/features/person/types/view';

export { Widget as AffiliatedCompanySelectorWidget };

/**
 * 관계사 선택기 위젯
 * @param props
 * @constructor
 */
function Widget(props: AffiliatedCompanySelectorWidgetProp) {
  const {
    usePersonSelector = false,
    title,
    onCompanySelect,
    onPersonSelect,
    isCompany = false,
    from,
    disabled,
  } = props;
  const {
    company: companyHelper,
    person: personHelper,
    modalOpen,
    setModalOpen,
    inputValue,
    setInputValue,
  } = useAffiliatedCompanySelectorLogic({
    defaultCompanyCategory: props.defaultCompanyCategory,
    isCompany,
    usePersonSelector,
  });
  const handleModalOpen = useCallback(() => {
    if (disabled) return;
    setModalOpen(true);
  }, [setModalOpen, disabled]);
  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);
  const handleCompanySelect = useCallback(
    (company: AffiliatedCompanyShortView | undefined) => {
      if (usePersonSelector) {
        personHelper.setKeyword('');
        companyHelper.setTargetCompany(company);
      } else {
        setInputValue({
          companyId: company?.id,
          textValue: company?.name,
        });
        onCompanySelect?.(company, setInputValue);
        handleModalClose();
      }
    },
    [onCompanySelect, usePersonSelector, handleModalClose]
  );
  const handlePersonSelect = useCallback(
    (person: AffiliatedCompanyPersonShortView | undefined) => {
      if (typeof person === 'undefined') {
        onPersonSelect?.(undefined, undefined, setInputValue);
      } else {
        onPersonSelect?.(companyHelper.targetCompany, person, setInputValue);
      }
      handleModalClose();
    },
    [onPersonSelect, handleModalClose, companyHelper]
  );
  useEffect(() => {
    companyHelper.setKeyword('');
    setInputValue(props.value);
    companyHelper.setTargetCompany({
      id: props.value.companyId,
      name: '',
      address: '',
    });
    personHelper.setTargetPersonId(props.value.personId);
  }, [props.value, props.value.personId, props.value.companyId, modalOpen]);
  return (
    <AffiliatedCompanySelector
      inputRef={props.inputRef}
      onButtonClick={handleModalOpen}
      value={inputValue}
      onCompanySelect={handleCompanySelect}
      onPersonSelect={handlePersonSelect}
      defaultValue={props.defaultTextValue}
      disabled={props.disabled}
      placeHolder={props.placeHolder}
      usePersonSelector={usePersonSelector}
      from={from}
    >
      {modalOpen && (
        <AffiliatedCompanySelectorModal
          usePersonSelector={usePersonSelector}
          title={title}
          onClose={handleModalClose}
          companySearchHelper={companyHelper}
          personSearchHelper={personHelper}
          onCompanySelect={handleCompanySelect}
          onPersonSelect={handlePersonSelect}
          exclude={props.exclude}
          position={props.position}
        />
      )}
    </AffiliatedCompanySelector>
  );
}
