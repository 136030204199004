import React from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoAbstractProjectInfoTable } from '@front/src/features/project-sales-info/features/abstract/features/project-info/components/table';
import { ProjectSalesInfoAbstractProjectInfoDetailWidget } from '@front/src/features/project-sales-info/features/abstract/features/project-info/widgets/detail/widget';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

/**
 * @module 영업정보>요약탭>프로젝트정보섹션
 */
const ProjectSalesInfoAbstractProjectInfoFeature = ({ name }: Readonly<Props>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  return (
    <Layout>
      <Header title={name} />
      <Body>
        <ProjectSalesInfoAbstractProjectInfoTable>
          <ProjectSalesInfoAbstractProjectInfoDetailWidget />
        </ProjectSalesInfoAbstractProjectInfoTable>
      </Body>
    </Layout>
  );
};

export default ProjectSalesInfoAbstractProjectInfoFeature;
