import { projectSalesInfoCMSaveHistoryQuery } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/query';
import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { ProjectCMSaveHistoryParameter } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/types/parameter';
import { projectSalesInfoCMSaveHistoryMutation } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/query/mutation';

export const projectSalesInfoCMSaveHistoryRepository = {
  useCMSaveHistoryGet: (id: number) => {
    const { data, isLoading } = projectSalesInfoCMSaveHistoryQuery.useCMSaveHistoryGet(id);
    return {
      data,
      isLoading,
    };
  },
  useCMSaveHistoryUpdate: (id: number) => {
    const { mutate } = projectSalesInfoCMSaveHistoryMutation.useCMSaveHistoryUpdate(id);
    const callback = getNoOpCallback();
    return {
      run: (params: ProjectCMSaveHistoryParameter) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
