import React, { useContext } from 'react';
import Button from '@front/layouts/Button';
import { useProjectSalesInfoBidInfoState } from '@front/src/features/project-sales-info/features/bid/features/bid-information/widgets/useState';
import { useFormContext } from 'react-hook-form';
import classes from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/update-modal/components/modal-table.module.scss';
import { ProjectSalesInfoBidInfoUpdateModalContext } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/update-modal/widgets/context';
import { strToYNConvertor } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/update-modal/utils/constants';
import dayjs from 'dayjs';
import {DATE_FORMAT, DATE_TIME_FORMAT} from '@front/src/utils';
import { useShallow } from 'zustand/react/shallow';

export { ModalBtnGroup as ProjectSalesInfoBidInfoUpdateModalBtnGroup };

const ModalBtnGroup = () => {
  const setIsUpdateModalOpen = useProjectSalesInfoBidInfoState(
    (state) => state.setIsUpdateModalOpen
  );
  const {
    h: { onUpdate },
  } = useContext(ProjectSalesInfoBidInfoUpdateModalContext);
  const { handleSubmit } = useFormContext();
  const onSubmit = handleSubmit((data) => {
    onUpdate({
      id: data.id,
      startDate: data.startDate ? dayjs(data.startDate).format(DATE_FORMAT) : null,
      endDate: data.endDate ? dayjs(data.endDate).format(DATE_TIME_FORMAT) : null,
      estimationId: data.estimationId || null,
      isBid: data.isBid === '' ? null : strToYNConvertor[data.isBid],
      isConfirmed: data.isConfirmed === '' ? null : strToYNConvertor[data.isConfirmed],
      note: data.note || null,
      bidNoticeFile: data.bidNoticeFile || null,
      bidGuideLineFile: data.bidGuideLineFile || null,
    });
    setIsUpdateModalOpen();
  });
  const { readOnly } = useProjectSalesInfoBidInfoState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );

  return (
    <div className={classes.btn__container}>
      <Button
        onClick={() => setIsUpdateModalOpen()}
        fullWidth={true}
        shape="basic2"
      >
        취소
      </Button>
      <Button
        fullWidth={true}
        onClick={onSubmit}
        disabled={readOnly}
      >
        수정
      </Button>
    </div>
  );
};
