import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { AccountingUploadApi } from '@front/src/features/accounting/features/upload/features/ecount-data/query/api';
import { handleError } from '@front/src/utils';
import type { AccountingUploadEcountUploadParameter } from '@front/src/features/accounting/features/upload/features/ecount-data/types/parameter';

export { Mutation as accountingUploadMutation };

const Mutation = {
  useCreateTaxAccounting: () => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: AccountingUploadEcountUploadParameter) =>
        AccountingUploadApi.createTaxAccounting(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['accounting', 'upload', 'ecount-data', 'list'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['accounting', 'settings', 'text-accounting-account', 'all'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
      isLoading,
    };
  },
  useCreateBankAccount: () => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: AccountingUploadEcountUploadParameter) =>
        AccountingUploadApi.createBankAccount(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['accounting', 'upload', 'ecount-data', 'list'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['accounting', 'settings', 'bank-account', 'list'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
      isLoading,
    };
  },
  useCreateDailyReport: () => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: AccountingUploadEcountUploadParameter) =>
        AccountingUploadApi.createDailyReport(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['accounting', 'upload', 'ecount-data', 'list'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['accounting', 'settings', 'daily-report-status', 'list'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
      isLoading,
    };
  },
};
