import React from 'react';
import { useController } from 'react-hook-form';
import { useInput } from '@front/src/components/input-with-hook-form/useInput';
import { useExtraInput } from '@front/src/components/input-with-hook-form/useExtraInput';
import { onChangeValidator } from '@front/src/components/input-with-hook-form/onChangeValidator';
import type { InputType } from '@front/src/features/affiliated-company/types/domain';
import type { SxProps } from '@mui/system';
import TextFieldBasicUI from '@front/src/components/components-with-design/component/TextFieldBasicUI';
import type { OutlinedInputProps } from '@mui/material/OutlinedInput';

interface Props extends Omit<OutlinedInputProps, 'required'> {
  name: string;
  onSubmit?: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  placeholder?: string;
  type?: string;
  inputType?: InputType;
  required?: string;
  sx?: SxProps;
  onPressChange?: () => void;
  disabled?: boolean;
}

export default function InputWithHookForm({
  name,
  onSubmit,
  onBlur,
  type = 'text',
  inputType,
  required,
  onPressChange,
  sx,
  readOnly,
  ...rest
}: Readonly<Props>) {
  const { control, validateString, validateNumber } = useInput({
    type,
    name,
  });
  const validateInput = useExtraInput(inputType);
  const {
    field: { value, onChange },
    formState: { defaultValues },
  } = useController({ name, control, rules: { required } });
  const currentVal = defaultValues?.[name];

  function handleChange(e) {
    const newValue = onChangeValidator(e, value, inputType);
    onChange({
      ...e,
      target: { ...e.target, value: newValue },
    });
    validateString(e, currentVal);
    onPressChange?.();
  }

  async function handleBlur(e) {
    onBlur?.(value);
    const isPositive = validateNumber(e, value, currentVal);
    const isValid = validateInput(value);
    if (value === currentVal) return;
    if (onSubmit && isPositive && isValid) {
      await onSubmit();
    }
  }

  if (readOnly) return (
    <div style={{
      textAlign: 'left',
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word'
    }}>
      {value}
    </div>
  );
  return (
    <TextFieldBasicUI
      type={type}
      value={value ?? ''}
      onChange={(e) => {
        handleChange(e);
      }}
      onBlur={handleBlur}
      sx={{
        ...sx,
      }}
      {...rest}
    />
  );
}
