import React, { memo, useEffect, useMemo } from 'react';
import { ProjectSalesInfoProjectDivisionContext } from '@front/src/features/project-sales-info/features/project-division/widgets/context';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import CircularProgress from '@front/components/CircularProgress';
import { ProjectSalesInfoProjectDivisionForm } from '@front/src/features/project-sales-info/features/project-division/components/form';
import { ProjectSalesInfoProjectDivisionLogic } from '@front/src/features/project-sales-info/features/project-division/widgets/useLogic';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoProjectDivisionState } from '@front/src/features/project-sales-info/features/project-division/widgets/useState';
import { useGetReadOnly } from '@front/src/hooks/useGetReadOnly';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoProjectDivisionWidget = ({ name }: Readonly<Props>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    isLoading,
    id,
    d: { projectDivision, businessTypeOptionList, bidSortOptionList },
    h: { useProjectDivisionUpdate, useCodeUpdate },
  } = ProjectSalesInfoProjectDivisionLogic();
  const value = useMemo(
    () => ({
      d: { projectDivisionData: projectDivision, businessTypeOptionList, bidSortOptionList },
      h: {
        onProjectDivisionUpdate: useProjectDivisionUpdate,
        onCodeUpdate: useCodeUpdate,
      },
      isLoading,
      id,
    }),
    [
      projectDivision,
      useProjectDivisionUpdate,
      isLoading,
      bidSortOptionList,
      businessTypeOptionList,
    ]
  );
  const { readOnly } = useGetReadOnly();
  const { setReadOnly } = useProjectSalesInfoProjectDivisionState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  if (typeof projectDivision === 'undefined' || isLoading) {
    return <Spinner />;
  }
  return (
    <ProjectSalesInfoProjectDivisionContext.Provider value={value}>
      <Layout>
        <Header title={name} />
        <Body>
          <ProjectSalesInfoProjectDivisionForm />
        </Body>
      </Layout>
    </ProjectSalesInfoProjectDivisionContext.Provider>
  );
};

const Spinner = memo(() => {
  const { Layout, Header } = AddDeleteTable;
  return (
    <Layout height="556px">
      <Header title="기본정보" />
      <CircularProgress size={30} />
    </Layout>
  );
});

export default ProjectSalesInfoProjectDivisionWidget;
