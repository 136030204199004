import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { TableRow } from '@mui/material';

import type { RootState } from '@front/services/reducer';
import { OldTd } from '@front/layouts/Table';
import DrawIoItem from '@front/ost_proposal/view/detail/draw_io/DrawIoItem';
import type { DrawingId } from '@front/ost_proposal/domain';
import type { DrawIoData } from '@front/ost_proposal/view/detail/draw_io/integration/useDrawIo';

interface Props {
  deleteDrawing: (id: DrawingId) => void;
  onClickText: (originalDrawData: DrawIoData | undefined) => () => void;
  onDownloadFile?: (drawIoData: DrawIoData) => void;
  isContributor: boolean;
}

const DrawIoList = ({ deleteDrawing, onClickText, onDownloadFile, isContributor }: Props) => {
  const [drawingList] = useSelector((root: RootState) => [root.proposal.drawingList], shallowEqual);
  return (
    <>
      {drawingList.length === 0 && (
        <TableRow>
          <OldTd colSpan={5}>{'첨부 파일이 없습니다.'}</OldTd>
        </TableRow>
      )}
      {drawingList.map((item, index) => (
        <DrawIoItem
          key={item.id}
          item={item}
          index={index}
          deleteDrawing={deleteDrawing}
          onClickText={onClickText}
          onDownloadFile={onDownloadFile}
          isContributor={isContributor}
        />
      ))}
    </>
  );
};

export default DrawIoList;
