import React, { memo } from 'react';
import { Box } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';

import TextBox from '@front/layouts/Text';
import { ColorPalette } from '@front/assets/theme';
import type { RootState } from '@front/services/reducer';
import { ProposalStatus, proposalStatusName } from '@front/ost_proposal/domain';

const ProposalStateTitle = () => {
  const statusMap = useSelector((root: RootState) => root.campaign.detail?.statusMap, shallowEqual);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        padding: '10px',
        border: `1px solid ${ColorPalette._e4e9f2}`,
        backgroundColor: ColorPalette._d7e2f7,
        borderRadius: '5px',
      }}
    >
      <Box>
        <TextBox
          variant={'heading1'}
          sx={{
            width: '13%',
          }}
        >
          제안 현황
        </TextBox>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr repeat(6, 2fr 2fr 1fr)',
          placeItems: 'center',
        }}
      >
        <TextBox variant="body9">{'('}</TextBox>
        <TextBox variant="body9">{proposalStatusName(ProposalStatus.WRITING)}</TextBox>
        <TextBox variant="body9">{statusMap?.WRITING}</TextBox>
        <TextBox variant="body9">{'/'}</TextBox>
        <TextBox variant="body9">{proposalStatusName(ProposalStatus.SUBMISSION)}</TextBox>
        <TextBox variant="body9">{statusMap?.SUBMISSION}</TextBox>
        <TextBox variant="body9">{'/'}</TextBox>
        <TextBox variant="body9">{proposalStatusName(ProposalStatus.ESTIMATING)}</TextBox>
        <TextBox variant="body9">{statusMap?.ESTIMATING}</TextBox>
        <TextBox variant="body9">{'/'}</TextBox>
        <TextBox variant="body9">{proposalStatusName(ProposalStatus.APPROVING)}</TextBox>
        <TextBox variant="body9">{statusMap?.APPROVING}</TextBox>
        <TextBox variant="body9">{'/'}</TextBox>
        <TextBox variant="body9">{proposalStatusName(ProposalStatus.VOTING)}</TextBox>
        <TextBox variant="body9">{statusMap?.VOTING}</TextBox>
        <TextBox variant="body9">{'/'}</TextBox>
        <TextBox variant="body9">{proposalStatusName(ProposalStatus.END)}</TextBox>
        <TextBox variant="body9">{statusMap?.END}</TextBox>
        <TextBox variant="body9">{')'}</TextBox>
      </Box>
    </Box>
  );
};

const StateTitle = memo(ProposalStateTitle);

export default StateTitle;
