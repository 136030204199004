import React from 'react';
import type { SectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import ProjectSalesInfoAbstractProjectInfoFeature from '@front/src/features/project-sales-info/features/abstract/features/project-info';
import ProjectSalesInfoAbstractAffiliatedCompanyFeature from '@front/src/features/project-sales-info/features/abstract/features/affiliated-company';
import ProjectSalesInfoAbstractEstimationFeature from '@front/src/features/project-sales-info/features/abstract/features/estimation';
import ProjectSalesInfoAbstractContractFeature from '@front/src/features/project-sales-info/features/abstract/features/contract';
import ProjectSalesInfoAbstractCollectionFeature from '@front/src/features/project-sales-info/features/abstract/features/collection';

export { sectionComponent as projectSalesInfoAbstractSectionComponent };

const sectionComponent: SectionComponent = {
  11: ({ ...rest }) => <ProjectSalesInfoAbstractProjectInfoFeature {...rest} />,
  12: ({ ...rest }) => <ProjectSalesInfoAbstractAffiliatedCompanyFeature {...rest} />,
  13: ({ ...rest }) => <ProjectSalesInfoAbstractEstimationFeature {...rest} />,
  14: ({ ...rest }) => <ProjectSalesInfoAbstractContractFeature {...rest} />,
  15: ({ ...rest }) => <ProjectSalesInfoAbstractCollectionFeature {...rest} />,
};
