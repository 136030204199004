import { projectSalesInfoSubjectReviewModalRepository } from '@front/src/features/project-sales-info/features/subject-review/features/modal/repository';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoSubjectReviewModalState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/useState';

export { useLogic as useProjectSalesInfoSubjectReviewModalDesignDateDeleteButtonLogic };

const useLogic = () => {
  const { setIsOpen, setId } = useProjectSalesInfoSubjectReviewModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      setId: state.setId,
    }),
    shallow
  );
  const { run: onDelete, setCallback } = projectSalesInfoSubjectReviewModalRepository.useDelete();
  setCallback({
    onSuccess: () => {
      setId(undefined);
      setIsOpen(false);
    },
  });
  return {
    onDelete,
  };
};
