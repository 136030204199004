import { useAccountingSettingsManagementAccountingAccountTableState } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/useState';
import { accountingManagementAccountingAccountTableRepository } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/repository/repository';
import { useContext, useEffect, useMemo } from 'react';
import { AccountingSettingsManagementAccountingAccountContext } from '@front/src/features/accounting/features/settings/features/management-accounting-account/provider/context';
import { useAccountingSettingsManagementAccountingAccountTableContextMenu } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/hooks/useContextMenu';
import { getTableRowNum } from '@front/src/features/accounting/utils';
import { useShallow } from 'zustand/react/shallow';

export { useLogic as useAccountingSettingsManagementAccountingAccountTableCategoryListLogic };

const useLogic = () => {
  const { readOnly } = useContext(AccountingSettingsManagementAccountingAccountContext);
  const {
    parentIdList,
    setUpdateId,
    setTargetItem,
    setParentIdList,
    setAnchorEl,
    setCategoryMircoName,
    setIsOpen,
  } = useAccountingSettingsManagementAccountingAccountTableState(
    useShallow((state) => ({
      parentIdList: state.parentIdList,
      setUpdateId: state.setUpdateId,
      setTargetItem: state.setTargetItem,
      setAnchorEl: state.setAnchorEl,
      setParentIdList: state.setParentIdList,
      setCategoryMircoName: state.setCategoryMircoName,
      setIsOpen: state.setIsOpen,
    }))
  );
  const { data: list, remove } = accountingManagementAccountingAccountTableRepository.useListGet({
    parentIdList,
  });
  const { run: onCreate } = accountingManagementAccountingAccountTableRepository.useCreate();
  const { run: onInsert, setCallback: setInsertCallback } =
    accountingManagementAccountingAccountTableRepository.useInsert();
  const { run: onUpdate, setCallback: setUpdateCallback } =
    accountingManagementAccountingAccountTableRepository.useUpdate();
  const { run: onDelete, setCallback: setDeleteCallback } =
    accountingManagementAccountingAccountTableRepository.useDelete();
  setUpdateCallback({
    onSuccess: (item) => {
      handleClose();
      setIsOpen(false);
      setTargetItem(item);
      setUpdateId(undefined);
    },
  });
  setDeleteCallback({
    onSuccess: () => {
      handleClose();
    },
  });
  setInsertCallback({
    onSuccess: (id) => {
      setUpdateId(id);
      handleClose();
    },
  });
  const tableRowNum = useMemo(() => getTableRowNum(list), [list]);
  const {
    handleKeyDown,
    handleMoveDown,
    handleDelete,
    handleInsert,
    handleChangeName,
    handleMoveUp,
    handleClose,
  } = useAccountingSettingsManagementAccountingAccountTableContextMenu({
    list,
    onDelete,
    onUpdate,
    onInsert,
    readOnly,
  });
  useEffect(() => {
    setParentIdList([]);
    setUpdateId(undefined);
    setTargetItem(undefined);
    setAnchorEl(null);
    setCategoryMircoName(undefined);
  }, [setParentIdList, setUpdateId, setTargetItem, setAnchorEl, setCategoryMircoName]);
  return {
    list,
    onCreate,
    onUpdate,
    handleKeyDown,
    handleMoveDown,
    handleDelete,
    handleInsert,
    handleChangeName,
    handleMoveUp,
    handleClose,
    tableRowNum,
    remove,
  };
};
