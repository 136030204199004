import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import type { ProposalId } from '@front/ost_proposal/domain';
import { ProposalStatus } from '@front/ost_proposal/domain';
import { ColorPalette } from '@front/assets/theme';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import type { UserId } from '@front/user/domain';
import { Box } from '@mui/material';

interface LinkProps {
  to: string;
  title?: string;
  state?: object;
}

const LinkTag = ({ to, title, state }: LinkProps) => (
  <Link
    to={to}
    state={state}
    style={{
      textDecoration: 'none',
    }}
  >
    <Box
      sx={{
        color: ColorPalette._252627,
        '&:hover': {
          textDecoration: 'underline',
          color: ColorPalette._386dd6,
        },
      }}
    >
      {title}
    </Box>
  </Link>
);

interface Props {
  id?: ProposalId;
  title?: string;
  status?: ProposalStatus;
  managerId?: UserId;
}

const RowTitle = ({ id, title, status, managerId }: Props) => {
  const { loginId } = useSelector(
    (root: RootState) => ({ loginId: root.login.detail?.id }),
    shallowEqual
  );

  if (status === ProposalStatus.ESTIMATING) {
    return (
      <LinkTag
        to={`/ost/evaluation-list/${id}`}
        title={title}
        state={{
          isManager: managerId === loginId,
        }}
      />
    );
  }

  if (status === ProposalStatus.VOTING || status === ProposalStatus.STOP_VOTING) {
    return (
      <LinkTag
        to={`/ost/vote-list/${id}`}
        title={title}
        state={{
          isManager: managerId === loginId,
        }}
      />
    );
  }

  return (
    <LinkTag
      to={`/ost/proposal-center/${id}`}
      title={title}
      state={{
        isManager: managerId === loginId,
      }}
    />
  );
};

const memoized = memo(RowTitle);

export { memoized as ProposalRowTitle };
