import React from 'react';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/widgets/useState';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalWidget } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/widgets/widget';
import { useShallow } from 'zustand/react/shallow';

const ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalWrapper = () => {
  const { isOpen } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalState(
    useShallow((state) => ({
      isOpen: state.isOpen,
    }))
  );
  if (!isOpen) {
    return <></>;
  }
  return <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalWidget />;
};

export default ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalWrapper;
