import React, { useCallback, useMemo } from 'react';
import type { ProceedingsAttachedFileId } from '../../domain';
import AttachedFileListSection from '../../view/Form/AttachedFileListSection';
import { useDispatch, useSelector } from 'react-redux';
import { proceedingsAction } from '../../action';
import type { ProceedingsAttachedFileParameter } from '../../parameter';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import type { RootState } from '@front/services/reducer';
import { getAttendance, getReader, getWriter } from '@front/proceedings/util/getAuthority';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';

interface Props extends SectionComponentProps {}

export default function AttachedFileListRoute({ name }: Readonly<Props>) {
  const { Layout, Header, Body } = AddDeleteTable;
  const dispatch = useDispatch();
  const addAttachedFile = useCallback(
    (params: ProceedingsAttachedFileParameter) =>
      dispatch(proceedingsAction.addAttachedFile(params)),
    [dispatch]
  );
  const deleteAttachedFile = useCallback(
    (id: ProceedingsAttachedFileId) => dispatch(proceedingsAction.deleteAttachedFile(id)),
    [dispatch]
  );
  const { detail, access } = useSelector((root: RootState) => root.proceedings);
  const isReader: boolean = useMemo(() => {
    if (access && detail) {
      return getReader(access, detail);
    }
    return false;
  }, [detail?.readerList, access]);
  const isWriter: boolean = useMemo(() => {
    if (access && detail) {
      return getWriter(access, detail);
    }
    return false;
  }, [detail?.writer, access]);
  const isAttendance: boolean = useMemo(() => {
    if (access && detail) {
      return getAttendance(access, detail);
    }
    return false;
  }, [detail?.attendanceList, access]);
  return (
    <Layout>
      <Header title={name} />
      <Body>
        {detail && (
          <AttachedFileListSection
            list={detail.fileList}
            addAttachedFile={addAttachedFile}
            deleteAttachedFile={deleteAttachedFile}
            isReader={isReader}
            isWriter={isWriter}
            isAttendance={isAttendance}
            detail={detail}
          />
        )}
      </Body>
    </Layout>
  );
}
