import React from 'react';
import WorkApprovalReadingFeature from '@front/src/features/work/features/work/features/approval/features/reading';
import ModalUI from '@front/src/components/components-with-design/layout/ModalUI';
import { useFormContext } from 'react-hook-form';

interface Props {
  workId: number;
  sectionId: number;
  dataId: number;
  menuId?: number;
}

export default function WorkApprovalModal({ workId, ...rest }: Readonly<Props>) {
  const { setValue, watch } = useFormContext();
  const open = watch('workId') === workId;
  const onClose = () => setValue('workId', undefined);
  return (
    <ModalUI
      open={open}
      onClose={onClose}
      title="상신 시점 업무 열람"
    >
      <WorkApprovalReadingFeature
        workId={workId}
        onClose={onClose}
        {...rest}
      />
    </ModalUI>
  );
}
