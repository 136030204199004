import type { ProjectContractEarningsConsortiumCompanyView } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/views';
import {formatDateOrNull} from "@front/src/features/project-sales-info/utils";

export const paymentHistoryApiUrl = '/api/project';

export const covertToConstructingCompanyOptionList = (
  data?: ProjectContractEarningsConsortiumCompanyView[]
) => {
  const companyList = data?.map((item) => item.structuralDesignCompany);
  const filteredCompanyList = companyList?.filter((item) => item);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const newCompanyList = Array.from(
    new Map(filteredCompanyList?.map((obj) => [obj?.id, obj])).values()
  );
  return newCompanyList.map((item) => ({
    label: item?.name,
    value: String(item?.id ?? ''),
  }));
};

export const spliceAndPushIfExist = (idList: number[], id: number) => {
  const index = idList.findIndex((item) => item === id);
  const updatedIdList = [...idList];
  if (index !== -1) {
    updatedIdList.splice(index, 1);
  } else {
    updatedIdList.push(id);
  }
  return updatedIdList;
};

export const convertProjectReviewAmountPaymentHistoryForParameter = (data) => ({
  id: data.id,
  paymentType: data.paymentType || null,
  paymentDate: formatDateOrNull(data.paymentDate),
  note: data.note || null,
  paymentAmount: data.paymentAmount ? +(data.paymentAmount as string).replace(/,/g, '') : null,
  paymentRequestDate: formatDateOrNull(data.paymentRequestDate),
  taxInvoiceIssuedDate: formatDateOrNull(data.taxInvoiceIssuedDate),
  structureCompanyId: data.structureCompanyId || null,
});
