import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workReferenceMutation from '@front/src/features/work/features/work/features/reference/query/mutation';

const useWorkReferenceAddBottom = (id: number, menuId) => {
  const callback = getNoOpCallback();
  const { mutate, isLoading } = workReferenceMutation.useAddBottom(id, menuId);
  return {
    run: () => mutate(undefined, callback),
    setCallback: getSetCallbackFunc(callback),
    isLoading,
  };
};

export default useWorkReferenceAddBottom;
