import { useQuery } from 'react-query';
import { affiliatedCompanyPersonRelationshipApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/query/api';
import type { AffiliatedCompanyPersonRelationshipView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/types/view';

export const affiliatedCompanyPersonRelationshipQuery = {
  usePersonRelationshipGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyPersonRelationshipView[]>({
      queryKey: ['affiliated-company', 'person', 'relationship', id],
      queryFn: () => affiliatedCompanyPersonRelationshipApi.getPersonRelationship(id, menuId),
      enabled: !!id,
    });
    return {
      data,
      isLoading,
    };
  },
};
