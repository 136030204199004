import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanyOutlineNumberUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/number/types/parameter';
import { convertEmptyToNull, getMenuIdHeaders } from '@front/src/utils';
import { baseUrl } from '@front/src/features/affiliated-company/utils/constant';

const url = {
  updateOutlineNumber: (id?) => `${baseUrl}/${id}/number`,
};

export const affiliatedCompanyOutlineNumberApi = {
  updateOutlineNumber: async (params: AffiliatedCompanyOutlineNumberUpdateParameter, menuId) => {
    await axios.put(url.updateOutlineNumber(params.id), convertEmptyToNull(params), {
      headers: getMenuIdHeaders(menuId),
    });
  },
};
