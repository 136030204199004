import { useMutation, useQueryClient } from 'react-query';
import { useCustomDialog } from '@front/src/features/dialog';
import { projectSalesInfoCMSaveHistoryApi } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/query/api';
import type { ProjectCMSaveHistoryParameter } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/types/parameter';
import { handleError } from '@front/src/utils';

export const projectSalesInfoCMSaveHistoryMutation = {
  useCMSaveHistoryUpdate: (id: number) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectCMSaveHistoryParameter) =>
        projectSalesInfoCMSaveHistoryApi.updateCMSaveHistory(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project-sales-info', 'collection', 'CM-save-history', id],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
