import type Page from 'type/Page';
import type { DepartmentShortVO } from 'department/domain';
import { departmentCategoryName } from 'department/domain';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { OldTd, OldTh, Table } from 'layouts/Table';
import Button from 'layouts/Button';
import TextLink from 'layouts/TextLink';
import TableLayout from 'layouts/TableLayout';
import { useNavigate } from 'react-router-dom';
import { useDepartmentState } from '@front/department/useState';
import { useShallow } from 'zustand/react/shallow';

function AddButton() {
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/personnel/department-management/add');
  };
  const { readOnly } = useDepartmentState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Button
      onClick={onClick}
      children="등록"
      disabled={readOnly}
    />
  );
}

export interface ListProps {
  page: Page<DepartmentShortVO> | undefined;
}

export default function ({ page }: ListProps) {
  return (
    <TableLayout
      pagination={page ? { ...page } : undefined}
      sizeFieldName="size"
      titleRightComponent={<AddButton />}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <OldTh>No.</OldTh>
              <OldTh>조직명</OldTh>
              <OldTh>유형</OldTh>
              <OldTh>상위 조직</OldTh>
              <OldTh>소속 유저 수</OldTh>
              <OldTh>하위 조직 수</OldTh>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!page || page.content.length === 0) && (
              <TableRow>
                <OldTd
                  colSpan={6}
                  children="결과가 없습니다."
                />
              </TableRow>
            )}
            {page &&
              page.content.map((item, i) => {
                const no = i + 1 + page.size * page.number;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    key={item.id}
                  >
                    <OldTd>{no}</OldTd>
                    <OldTd>
                      <TextLink onClick={`/personnel/department-management/${item.id}`}>
                        {item.name}
                      </TextLink>
                    </OldTd>
                    <OldTd>{departmentCategoryName(item.category)}</OldTd>
                    <OldTd>{item.parent ? item.parent.name : '-'}</OldTd>
                    <OldTd>{item.userCount || 0}</OldTd>
                    <OldTd>{item.childrenCount || 0}</OldTd>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </TableLayout>
  );
}
