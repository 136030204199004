import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { proposalAction } from '@front/ost_proposal/action';
import { FormikProvider, useFormik } from 'formik';
import type { ProposalSubmissionCancelReasonParameter } from '@front/ost_proposal/parameter';
import { CancelSubmissionModal } from '@front/ost_proposal/view/modal/cancel_submission';

const CancelSubmissionModalService = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(
    (root: RootState) => root.proposal.setProposalSubmissionCancelModalOpen,
    shallowEqual
  );
  const onClose = useCallback(
    () => dispatch(proposalAction.setProposalSubmissionCancelModal(false)),
    [dispatch]
  );
  const onSubmit = useCallback(
    (parameter: ProposalSubmissionCancelReasonParameter) =>
      dispatch(proposalAction.addProposalSubmissionCancelReason(parameter)),
    [dispatch]
  );
  const formik = useFormik<ProposalSubmissionCancelReasonParameter>({
    initialValues: {} as ProposalSubmissionCancelReasonParameter,
    onSubmit: (values) => {
      onSubmit(values);
      formik.resetForm();
    },
  });

  const onBlur = useCallback(
    (e) => {
      const value = e.target.value || '';
      if (formik.values.reason !== value) {
        formik.setFieldValue('reason', value);
      }
    },
    [formik]
  );

  return (
    <>
      {isOpen && (
        <FormikProvider value={formik}>
          <CancelSubmissionModal
            open={isOpen}
            onClose={onClose}
            onBlur={onBlur}
          />
        </FormikProvider>
      )}
    </>
  );
};

export default CancelSubmissionModalService;
