import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAccountingSettingsTexAccountingAccountListLogic } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/list/provider/useLogic';
import { AccountingSettingsTexAccountingAccountListContext } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/list/provider/context';

export { Provider as AccountingSettingsTexAccountingAccountListProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { list, tableRowNum, countList } = useAccountingSettingsTexAccountingAccountListLogic();
  const value = useMemo(() => ({ list, countList, tableRowNum }), [list, countList, tableRowNum]);
  return (
    <AccountingSettingsTexAccountingAccountListContext.Provider value={value}>
      {children}
    </AccountingSettingsTexAccountingAccountListContext.Provider>
  );
};
