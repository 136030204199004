import React from 'react';
import { AccountingSettingsManagementDailyReportStatusHeaderUploadModal } from '@front/src/features/accounting/features/settings/features/daily-report-status/widgets/upload/component/modal';
import { AccountingSettingsDailyReportStatusUploadProvider } from '@front/src/features/accounting/features/settings/features/daily-report-status/widgets/upload/provider/provider';
import { AccountingSettingsDailyReportStatusUploadButton } from '@front/src/features/accounting/features/settings/features/daily-report-status/widgets/upload/component/upload-button';

export { Widget as AccountingSettingsDailyReportStatusUploadWidget };

const Widget = () => (
  <>
    <AccountingSettingsManagementDailyReportStatusHeaderUploadModal />
    <AccountingSettingsDailyReportStatusUploadProvider>
      <AccountingSettingsDailyReportStatusUploadButton />
    </AccountingSettingsDailyReportStatusUploadProvider>
  </>
);
