import { useQuery } from 'react-query';
import { affiliatedCompanyRepresentativeContractInformationApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/query/api';
import type { AffiliatedCompanyPersonOutlineRepresentativeContactInformationView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/types/view';

export const affiliatedCompanyRepresentativeContractInformationQuery = {
  usePersonOutlineRepresentativeContactInformationGet: (id?: number, menuId?) => {
    const { data, isLoading } =
      useQuery<AffiliatedCompanyPersonOutlineRepresentativeContactInformationView>({
        queryKey: [
          'affiliated-company',
          'person',
          'outline',
          'representative-contact-information',
          id,
        ],
        queryFn: () =>
          affiliatedCompanyRepresentativeContractInformationApi.getPersonOutlineRepresentativeContactInformation(
            id,
            menuId
          ),
        enabled: !!id,
        cacheTime: 0,
      });
    return {
      data,
      isLoading,
    };
  },
};
