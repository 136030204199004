import { createContext } from 'react';
import type {
  ProjectContractEarningsCollectionStatusView,
  ProjectContractEarningsView,
} from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/views';

interface State {
  d: {
    contractEarningsStatus?: ProjectContractEarningsCollectionStatusView;
    contractEarnings?: ProjectContractEarningsView[];
  };
  isLoading: boolean;
  isStatusLoading: boolean;
  isModalOpen: boolean;
}

export { Context as ProjectSalesInfoContractEarningsContext };

const Context = createContext<State>({
  d: {},
  isLoading: false,
  isStatusLoading: false,
  isModalOpen: false,
});
