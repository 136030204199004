import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import { PersonTabType } from '@front/src/features/affiliated-company/types/domain';

export { Context as AffiliatedCompanyPersonDetailModalContext };

interface State {
  h: {
    setTab: (tab: PersonTabType) => void;
    onDelete: (id: number) => void;
  };
  tab: PersonTabType;
  personId?: number;
}

const Context = createContext<State>({
  h: {
    setTab: noOp,
    onDelete: noOp,
  },
  tab: PersonTabType.OUTLINE,
  personId: undefined,
});
