import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { shallow } from 'zustand/shallow';
import { projectSalesInfoEstimationComparedRepository } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/repository';

export { useLogic as useProjectSalesInfoEstimationComparedLogic };

const useLogic = () => {
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );

  const { data: list, isLoading } = projectSalesInfoEstimationComparedRepository.useListGet(id);
  const { run: onDelete } = projectSalesInfoEstimationComparedRepository.useDelete();
  const d = { list };
  const h = { onDelete };
  return {
    d,
    h,
    isLoading,
  };
};
