import React from 'react';
import { PersonalSettingsModalUISettingsModalSettingsSelectableHeaderTableRow } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/components/SelectableHeaderTableRow';
import { useFieldArray, useFormContext } from 'react-hook-form';

export { SelectableHeaderTableData as PersonalSettingsModalUISettingsModalSettingsSelectableHeaderTableData };

const SelectableHeaderTableData = () => {
  const { control } = useFormContext();
  const { fields } = useFieldArray({
    name: 'selectableHeaderList',
    control,
  });

  return (
    <>
      {fields.map((f, i) => (
        <PersonalSettingsModalUISettingsModalSettingsSelectableHeaderTableRow
          key={f.id}
          index={i}
        />
      ))}
    </>
  );
};
