import React, { useCallback, useContext } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import { useProjectSalesInfoCollectionManagementModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/widgets/useState';
import Checkbox from '@front/layouts/Checkbox';
import type { UserRecordView } from '@front/src/types';
import { ProjectSalesInfoContractEarningsCollectionManagementContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/widgets/context';
import type { ProjectCollectionManagementParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/types/parameter';
import dayjs from 'dayjs';
import { spliceAndPushIfExist } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/utils/constants';
import { getValueIfEmpty, getValueIfExist } from '@front/src/utils';

export { ManageCollectionTableRow as ProjectSalesInfoCollectionManagementTableRow };

interface Props {
  item: ProjectCollectionManagementParams;
  id: string;
  index: number;
}

const ManageCollectionTableRow = ({ item, index }: Props) => {
  const { idList, setIdList, setIsUpdateModalOpen, setIdx } =
    useProjectSalesInfoCollectionManagementModalState((state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
      setIsUpdateModalOpen: state.setIsUpdateModalOpen,
      setIdx: state.setIdx,
    }));
  const {
    d: { divisionDetail },
  } = useContext(ProjectSalesInfoContractEarningsCollectionManagementContext);
  const handleCheckboxChange = useCallback(
    (index) => () => {
      setIdList(spliceAndPushIfExist(idList, index));
    },
    [setIdList, idList]
  );
  const onClick = () => {
    setIsUpdateModalOpen();
    setIdx(index);
  };
  return (
    <TableRow
      hover={true}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <OldTd>
        <Checkbox
          checked={idList.includes(index)}
          onChange={handleCheckboxChange(index)}
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
        />
      </OldTd>
      <OldTd>{index + 1}</OldTd>
      <OldTd>{getValueIfEmpty(item?.unpaidReason)}</OldTd>
      <OldTd>{getValueIfEmpty(item?.collectionDueDate)}</OldTd>
      <OldTd>{getValueIfEmpty(item?.billingDate)}</OldTd>
      <OldTd>{getValueIfEmpty(item?.collectionForecastDate)}</OldTd>
      <OldTd>
        {item?.collectionPossibilityRatio ? item.collectionPossibilityRatio + '%' : '-'}
      </OldTd>
      <OldTd>{getValueIfExist((divisionDetail?.salesManager as UserRecordView)?.name)}</OldTd>
      <OldTd>{getValueIfEmpty(item?.affiliatedPersonName)}</OldTd>
      <OldTd>{getValueIfEmpty(item?.comment)}</OldTd>
      <OldTd>{item?.createdAt ? dayjs(item?.createdAt).format('YYYY-MM-DD') : '-'}</OldTd>
    </TableRow>
  );
};
