import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import type { EvaluationMemoQuery } from '@front/ost_evaluation/query';
import { evaluationAction } from '@front/ost_evaluation/action';
import type { ProposalId } from '@front/ost_proposal/domain';
import EvaluationMemoFilter from '@front/ost_evaluation/view/memo/Filter';

interface Props {
  id?: ProposalId;
}

const EvaluationFilterService = ({ id }: Props) => {
  const dispatch = useDispatch();
  const setFilter = useCallback(
    (query: EvaluationMemoQuery) => dispatch(evaluationAction.setMemoFilter(query)),
    [dispatch]
  );
  const formik = useFormik<EvaluationMemoQuery>({
    initialValues: {} as EvaluationMemoQuery,
    onSubmit: (values) => {
      setFilter(values);
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [id]);
  return (
    <FormikProvider value={formik}>
      <EvaluationMemoFilter />
    </FormikProvider>
  );
};

export default EvaluationFilterService;
