import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { WorkDetailUpdateParameter } from '@front/src/features/work/features/work/features/detail/types/parameter';
import workDetailMutation from '@front/src/features/work/features/work/features/detail/query/mutation';

const useWorkDetailUpdate = (workId: number, menuId) => {
  const callback = getNoOpCallback();
  const { mutate, isLoading } = workDetailMutation.useUpdate(workId, menuId);
  return {
    run: (params: WorkDetailUpdateParameter) => mutate(params, callback),
    setCallback: getSetCallbackFunc(callback),
    isLoading,
  };
};

export default useWorkDetailUpdate;
