import React, { useContext } from 'react';
import { ProjectSalesInfoCMSaveHistoryContext } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/widgets/context';
import { ProjectSalesInfoCMSaveHistoryTableRow } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/components/CM-save-history-table-row';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';

export { CmSaveHistoryTableList as ProjectSalesInfoCMSaveHistoryTableList };

const CmSaveHistoryTableList = () => {
  const {
    d: { CMSaveHistoryList },
    isLoading,
  } = useContext(ProjectSalesInfoCMSaveHistoryContext);
  if (typeof CMSaveHistoryList === 'undefined' || isLoading)
    return <TableLoadingSpinner colSpan={8} />;
  if (CMSaveHistoryList?.length === 0) return <NoResult colSpan={8} />;
  return (
    <>
      {CMSaveHistoryList?.map((item) => (
        <ProjectSalesInfoCMSaveHistoryTableRow
          item={item}
          key={item.id}
        />
      ))}
    </>
  );
};
