import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workOutputMutation from '@front/src/features/work/features/work/features/output/query/mutation';

const useWorkOutputAddTop = (bundleId: number, menuId) => {
  const callback = getNoOpCallback();
  const { mutate, isLoading } = workOutputMutation.useAddTop(bundleId, menuId);
  return {
    run: () => mutate(undefined, callback),
    setCallback: getSetCallbackFunc(callback),
    isLoading,
  };
};

export default useWorkOutputAddTop;
