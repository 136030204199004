import { useQuery } from 'react-query';
import { projectSalesInfoContractContractUpdateModalApi } from '@front/src/features/project-sales-info/features/contract/features/contract/features/update-modal/query/api';
import type { ProjectContractContractView } from '@front/src/features/project-sales-info/features/contract/features/contract/types/view';

export { query as projectSalesInfoContractContractUpdateModalQuery };

const query = {
  useDetailGet: (id?: number) => {
    const { data, isLoading } = useQuery<ProjectContractContractView>({
      queryFn: () => projectSalesInfoContractContractUpdateModalApi.getDetail(id),
      queryKey: ['project', 'sales-info', 'contract', 'contract', id],
      enabled: !!id,
      cacheTime: 0,
    });
    return {
      data,
      isLoading,
    };
  },
};
