import type { ReactNode } from 'react';
import React from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { affiliatedPersonAppBarCreateModalTableHeaderNameList } from '@front/src/features/affiliated-person/features/app-bar/utils';

export { CreateModalTable as AffiliatedPersonCreateModalTable };

interface Props {
  widget: ReactNode;
}

const CreateModalTable = ({ widget }: Props) => (
  <TableContainer
    sx={{
      maxHeight: '200px',
    }}
  >
    <Table>
      <TableHead>
        <TableRow>
          {affiliatedPersonAppBarCreateModalTableHeaderNameList.map(({ name, width }) => (
            <Th
              key={name}
              width={width}
            >
              {name}
            </Th>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{widget}</TableBody>
    </Table>
  </TableContainer>
);
