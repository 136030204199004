import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import type { ArrangementAttachedFileId } from '@front/ost_proposal/domain';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import { ArrangementFileItem } from '@front/ost_proposal/view/detail/advisorForm/attached_file/Item';

interface Props {
  deleteArrangementAttachedFile: (id: ArrangementAttachedFileId) => void;
  isAdvisor: boolean;
}

export { List as ArrangementFileList };
const List = ({ deleteArrangementAttachedFile, isAdvisor }: Props) => {
  const [arrangementFileList] = useSelector(
    (root: RootState) => [root.proposal.arrangementFileList],
    shallowEqual
  );

  return (
    <>
      {arrangementFileList.length === 0 && (
        <TableRow>
          <OldTd colSpan={3}>{'첨부 파일이 없습니다.'}</OldTd>
        </TableRow>
      )}
      {arrangementFileList.map((item, index) => (
        <ArrangementFileItem
          key={item.id}
          item={item}
          index={index}
          deleteArrangementAttachedFile={deleteArrangementAttachedFile}
          isAdvisor={isAdvisor}
        />
      ))}
    </>
  );
};
