import type { ColumnData } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/table/types';

export const columns: ColumnData[] = [
  {
    width: '2%',
    label: '',
    dataKey: 'checkbox',
  },
  {
    width: '4%',
    label: '동명',
    dataKey: 'name',
  },
  {
    width: '4%',
    label: '층수',
    dataKey: 'floor',
  },
  {
    width: '4%',
    label: '높이(m)',
    dataKey: 'height',
  },
  {
    width: '6%',
    label: '바닥면적(㎡)',
    dataKey: 'floorArea',
  },
  {
    width: '4%',
    label: '형상비',
    dataKey: 'aspectRatio',
  },
  {
    width: '6%',
    label: '형상비 3이상',
    dataKey: 'hasAspectRatioOverThree',
  },
  {
    width: '4%',
    label: '(5)',
    dataKey: 'hasConditionOfSpecialWindExperiment',
  },
  {
    width: '8%',
    label: '실험 종류',
    dataKey: 'experimentType',
  },
];
