import { projectSalesInfoBidInfoRepository } from '@front/src/features/project-sales-info/features/bid/features/bid-information/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';

export { useLogic as useProjectSalesInfoBidInfoLogic };

const useLogic = () => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { data: bidInfoList, isLoading: isLoading } =
    projectSalesInfoBidInfoRepository.useProjectSalesInfoBidInfoListGet(id!);
  const { run: onDelete } = projectSalesInfoBidInfoRepository.useProjectSalesInfoBidInfoDelete(id!);
  return {
    h: { onDelete },
    d: { bidInfoList },
    isLoading,
  };
};
