import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import DatePickerUI from '@front/src/components/components-with-design/component/DatePickerUI';
import type { DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import dayjs, {Dayjs} from "dayjs";

interface Props extends DatePickerProps<any, any> {
  name: string;
  onSubmit?: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  required?: boolean;
}

export default function DatePickerWithHookForm({
  name,
  onSubmit,
  required,
  readOnly,
  ...rest
}: Readonly<Props>) {
  const { control, getValues } = useFormContext();
  const {
    field: { value, onChange: onHookFormChange },
  } = useController({ name, control, rules: { required } });

  const handleChange = (value: string | Dayjs) => {
    onHookFormChange(value? dayjs(value) : null);
    onSubmit?.();
  };

  if (readOnly && getValues(name) === null) {
    return (<>
      -
    </>);
  }

  if (readOnly && getValues(name) !== null) {
    return (<>
      dayjs(getValues(name)).format(DATE_FORMAT)
    </>);
  }

  return (
    <DatePickerUI
      value={ value ? dayjs(value) : null}
      onChange={handleChange}
      {...rest}
    />
  );
}
