import { useQuery } from 'react-query';
import { affiliatedPersonApi } from '@front/src/features/affiliated-person/api/api';
import type { AffiliatedPersonShortView } from '@front/src/features/affiliated-person/types/view';

export { query as affiliatedPersonAppBarQuery };
const query = {
  useListByPersonNameGet: (params) => {
    const { data, remove } = useQuery<AffiliatedPersonShortView[]>({
      queryFn: () => affiliatedPersonApi.getListByPersonName(params),
      queryKey: ['affiliated-person', 'app-bar', 'person-list', params],
      suspense: true,
      enabled: !!params.personName,
    });
    return {
      data,
      remove,
    };
  },
};
