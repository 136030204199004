import React, { useCallback } from 'react';
import { TableRow } from '@mui/material';
import type {
  MenuShortView,
  MyPageAuthorizationTabView,
} from '@front/src/features/my-page/features/authorization/types/view';
import { WorkTd } from '@front/src/features/work/features/work/components/work-td';

export { Row as MyPageAuthorizationTabRow };

interface Props {
  item: MyPageAuthorizationTabView;
  adminMenuList?: MenuShortView[];
  notDevelopmentMenuList?: MenuShortView[];
}

const Row = ({ item, adminMenuList, notDevelopmentMenuList }: Props) => {
  const renderRow = useCallback(() => {
    if (notDevelopmentMenuList?.map((e) => e.id).includes(item.id)) {
      return null;
    }
    if (
      adminMenuList
        ?.filter((e) => e.id)
        .map((e) => e.id)
        .includes(item.id)
    ) {
      if (adminMenuList?.filter((e) => e.code.includes(item.code)).length > 1) {
        return (
          <>
            <WorkTd> </WorkTd>
            <WorkTd> </WorkTd>
            <WorkTd> </WorkTd>
          </>
        );
      }
      return (
        <>
          <WorkTd> </WorkTd>
          <WorkTd> </WorkTd>
          <WorkTd>O</WorkTd>
        </>
      );
    }
    switch (item.authorization.type) {
      case 'V':
        return (
          <>
            <WorkTd>O</WorkTd>
            <WorkTd> </WorkTd>
            <WorkTd> </WorkTd>
          </>
        );
      case 'R':
        return (
          <>
            <WorkTd> </WorkTd>
            <WorkTd>O</WorkTd>
            <WorkTd> </WorkTd>
          </>
        );
      case 'W':
        return (
          <>
            <WorkTd> </WorkTd>
            <WorkTd> </WorkTd>
            <WorkTd>O</WorkTd>
          </>
        );
      case null:
        return (
          <>
            <WorkTd> </WorkTd>
            <WorkTd> </WorkTd>
            <WorkTd> </WorkTd>
          </>
        );
    }
  }, [item]);
  return <TableRow>{renderRow()}</TableRow>;
};
