import { create } from 'zustand';

export { useState as usePersonalSettingsModalUISettingsHeaderSettingsHistoryState };

interface State {
  header?: {
    id: number;
    name: string;
  };
}

interface Actions {
  setHeader: (header?: { id: number; name: string }) => void;
}

const useState = create<State & Actions>((set) => ({
  setHeader: (header) => set(() => ({ header })),
}));
