import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import type { ProjectEstimationCompetitorParameter } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/types/parameter';
import { projectSalesInfoEstimationCompetitorCreateModalApi } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/features/create-modal/query/api';
import { useCustomDialog } from '@front/src/features/dialog';

export { mutation as projectSalesInfoEstimationCompetitorCreateModalMutation };

const mutation = {
  useCreate: () => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectEstimationCompetitorParameter) =>
        projectSalesInfoEstimationCompetitorCreateModalApi.create(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'estimation', 'estimation-competitor', 'list'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
