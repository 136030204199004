export const userNotificationUrlApi = '/api/user-notification';

export const workCategoryOptionList = [
  {
    label: '프로젝트',
    value: 'PROJECT',
  },
  {
    label: '회계',
    value: 'ACCOUNTING',
  },
  {
    label: '관계사',
    value: 'AFFILIATED_COMPANY',
  },
  {
    label: 'OST',
    value: 'OST',
  },
  {
    label: '결재함',
    value: 'APPROVAL',
  },
  {
    label: '회의록',
    value: 'PROCEEDINGS',
  },
  {
    label: '업무',
    value: 'WORK',
  },
];

export const statusOptionList = [
  {
    label: '읽지않음',
    value: 'UNREAD',
  },
  {
    label: '북마크',
    value: 'IMPORTANT',
  },
];

export const CARD_HEIGHT = '155px';
export const CARD_DETAILS_HEIGHT = '360px';

export const NOTE_HEIGHT = '40px';
export const NOTE_DETAILS_HEIGHT = '230px';
