import type { ReactNode } from 'react';
import React from 'react';
import { Box } from '@mui/material';
import { useAccountingUploadSalesInfoState } from '@front/src/features/accounting/features/upload/features/sales-info/useState';
import { useShallow } from 'zustand/react/shallow';
import { AccountingUploadSalesInfoPerformanceModalMonthSelect } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/components/month-select';

export { Body as AccountingUploadSalesInfoPerformanceModalBody };

interface Props {
  children: ReactNode;
}

const Body = ({ children }: Props) => {
  const { year } = useAccountingUploadSalesInfoState(
    useShallow((state) => ({
      year: state.year,
    }))
  );
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          gap: '12px',
        }}
      >
        <Box>{year}년도</Box>
        <Box>
          <AccountingUploadSalesInfoPerformanceModalMonthSelect />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
