import type { ReactNode } from 'react';
import React from 'react';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';

export { MenuTable as MyPageAuthorizationMenuTable };

interface Props {
  children: ReactNode;
}

const MenuTable = ({ children }: Props) => (
  <Box
    sx={{
      outline: `1px solid ${ColorPalette._e4e9f2}`,
      borderRadius: '4px',
    }}
  >
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        height: '50px',
        backgroundColor: ColorPalette._f1f5fc,
        fontSize: '15px',
        fontWeight: 500,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        메뉴명 (1단계)
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        메뉴명 (2단계)
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        메뉴명 (3단계)
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        탭 이름
      </Box>
    </Box>
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
      }}
    >
      {children}
    </Box>
  </Box>
);
