import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { AccountingSettingsTexAccountingAccountGetDateContext } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/get-date/provider/context';
import { convertDateToStringFormat, DATE_TIME_FORMAT } from '@front/src/utils';
import TextBox from '@front/layouts/Text';

export { UpdatedAt as AccountingSettingsTexAccountingAccountUpdatedAt };

const UpdatedAt = () => {
  const { createdAt } = useContext(AccountingSettingsTexAccountingAccountGetDateContext);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '12px',
      }}
    >
      <TextBox variant="body1">E계정 데이터 업데이트 일시:</TextBox>
      <TextBox variant="body1">{convertDateToStringFormat(createdAt, DATE_TIME_FORMAT)}</TextBox>
    </Box>
  );
};
