// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ciF7HIiOBhNDV7gU8NqZ{width:2%}.u07hwonblaHcKozp9Elb{width:4%}.XTKhzY5l42LzArEt1mgx{width:6%;white-space:nowrap}.OlihSJKzV4xKrS1zCXgE{width:8%}.pd5QTfsitqAJT1e6zICj{width:10%}.MUkmJqeMl5n8xl5LiU4Q{word-break:keep-all}.iClxuh_SOGzgjCUrDsKV{display:flex;justify-content:center;padding:20px;border:1px solid #e4e9f2}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/components/contract-earnings-table.module.scss"],"names":[],"mappings":"AAAA,sBACE,QAAA,CAGF,sBACE,QAAA,CAGF,sBACE,QAAA,CACA,kBAAA,CAGF,sBACE,QAAA,CAGF,sBACE,SAAA,CAGF,sBACE,mBAAA,CAKF,sBACE,YAAA,CACA,sBAAA,CACA,YAAA,CACA,wBAAA","sourcesContent":[".width__2 {\n  width: 2%;\n}\n\n.width__4 {\n  width: 4%;\n}\n\n.width__6 {\n  width: 6%;\n  white-space: nowrap;\n}\n\n.width__8 {\n  width: 8%;\n}\n\n.width__10 {\n  width: 10%;\n}\n\n.bid__th {\n  word-break: keep-all;\n}\n\n@import \"@front/assets/colors.scss\";\n\n.no__result {\n  display: flex;\n  justify-content: center;\n  padding: 20px;\n  border: 1px solid $_e4e9f2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"width__2": `ciF7HIiOBhNDV7gU8NqZ`,
	"width__4": `u07hwonblaHcKozp9Elb`,
	"width__6": `XTKhzY5l42LzArEt1mgx`,
	"width__8": `OlihSJKzV4xKrS1zCXgE`,
	"width__10": `pd5QTfsitqAJT1e6zICj`,
	"bid__th": `MUkmJqeMl5n8xl5LiU4Q`,
	"no__result": `iClxuh_SOGzgjCUrDsKV`
};
export default ___CSS_LOADER_EXPORT___;
