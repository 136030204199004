import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useAffiliatedCompanyProjectState } from '@front/src/features/affiliated-company/features/project/useState';
import { Box } from '@mui/material';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { projectSalesInfoAffiliatedCompanyCategoryOptionList } from '@front/src/features/project-sales-info/features/affiliated-company/utils/constant';
import { affiliatedCompanyPersonContributionOptionList } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/utils/constant';
import { AffiliatedCompanyProjectListContext } from '@front/src/features/affiliated-company/features/project/widgets/list/provider/context';
import { getLocaleStringIfExist } from '@front/src/utils';
import { AffiliatedCompanyProjectTd } from '@front/src/features/affiliated-company/features/project/widgets/list/components/td';
import { useShallow } from 'zustand/react/shallow';

export { TableFilterRow as AffiliatedCompanyProjectTableFilterRow };

const TableFilterRow = () => {
  const {
    list,
    estimationStatusVariableList,
    contractStatusVariableList,
    contractStepVariableList,
  } = useContext(AffiliatedCompanyProjectListContext);
  const { setParameter, parameter, setDefaultList, defaultList } = useAffiliatedCompanyProjectState(
    useShallow((state) => ({
      setParameter: state.setParameter,
      parameter: state.parameter,
      setDefaultList: state.setDefaultList,
      defaultList: state.defaultList,
    }))
  );
  const methods = useForm({
    values: parameter,
  });
  const onSubmit = methods.handleSubmit((data) => {
    setParameter({
      estimationStatus: data.estimationStatus,
      contractStatus: data.contractStatus,
      category: data.category,
      contribution: data.contribution,
      contractStep: data.contractStep,
      contractEarningsStep: data.contractEarningsStep,
      orderCompanyName: data.orderCompanyName,
    });
  });
  const projectIdList = useMemo(() => {
    if (!list) return [];
    return Array.from(new Set(list.map((l) => l.projectId)));
  }, [list]);
  const getSum = useCallback(() => {
    let estimationTotalAmountSum = 0;
    let contractTotalAmountSum = 0;
    let estimationExperimentAmountSum = 0;
    let contractExperimentAmountSum = 0;
    let collectionAmountSum = 0;
    let unpaidAmountSum = 0;
    projectIdList.forEach((id) => {
      estimationTotalAmountSum += list!.find((l) => l.projectId === id)!.estimationTotalAmount;
      contractTotalAmountSum += list!.find((l) => l.projectId === id)!.contractTotalAmount;
      estimationExperimentAmountSum += list!.find(
        (l) => l.projectId === id
      )!.estimationExperimentAmount;
      contractExperimentAmountSum += list!.find(
        (l) => l.projectId === id
      )!.contractExperimentAmount;
      collectionAmountSum += list!.find((l) => l.projectId === id)!.collectionAmount;
      unpaidAmountSum += list!.find((l) => l.projectId === id)!.unpaidAmount;
    });
    return {
      estimationTotalAmountSum,
      contractTotalAmountSum,
      estimationExperimentAmountSum,
      contractExperimentAmountSum,
      collectionAmountSum,
      unpaidAmountSum,
    };
  }, [projectIdList, list]);
  const {
    estimationTotalAmountSum,
    contractTotalAmountSum,
    estimationExperimentAmountSum,
    unpaidAmountSum,
    contractExperimentAmountSum,
    collectionAmountSum,
  } = getSum();
  const getContractEarningsStepOptionList = () => {
    if (!defaultList) return [];
    if (defaultList?.[0]?.contractEarningsStep === null) return [];
    const from = Array.from(
      new Set(
        defaultList!
          .filter((l) => l.contractEarningsStep !== null)
          .map((l) => l.contractEarningsStep)
      )
    );
    return from.map((f) => ({
      label: f,
      value: f,
    }));
  };
  const getOrderCompanyNameOptionList = () => {
    if (!defaultList) return [];
    if (defaultList?.[0]?.orderCompanyName === null) return [];
    const orderCompanyNameList: string[] = [];
    for (const d of defaultList) {
      if (d.orderCompanyName === null) continue;
      orderCompanyNameList.push(...d.orderCompanyName.split(', '));
    }
    const from = Array.from(new Set(orderCompanyNameList));
    return from.map((f) => ({
      label: f,
      value: f,
    }));
  };
  const getOptionList = useCallback(
    () => ({
      estimationStatusOptionList:
        estimationStatusVariableList?.map((e) => ({
          value: e.value,
          label: e.label,
        })) ?? [],
      contractStatusOptionList:
        contractStatusVariableList?.map((c) => ({
          value: c.value,
          label: c.label,
        })) ?? [],
      contractStepOptionList:
        contractStepVariableList?.map((c) => ({
          value: c.value,
          label: c.label,
        })) ?? [],
    }),
    [estimationStatusVariableList, contractStatusVariableList, contractStepVariableList]
  );
  const { estimationStatusOptionList, contractStatusOptionList, contractStepOptionList } =
    getOptionList();
  useEffect(() => {
    if (!list) return;
    if (
      parameter.estimationStatus == '' &&
      parameter.contractStatus == '' &&
      parameter.category == '' &&
      parameter.contribution == '' &&
      parameter.contractStep == '' &&
      parameter.contractEarningsStep == '' &&
      parameter.orderCompanyName == ''
    ) {
      setDefaultList(list);
    }
  }, [parameter, list, setDefaultList]);
  const { readOnly } = useAffiliatedCompanyProjectState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <AffiliatedCompanyProjectTd width="120px">
          <HookFormSelect
            width="100px"
            isDefaultPossible
            defaultLabel="전체"
            name="estimationStatus"
            optionList={estimationStatusOptionList}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </AffiliatedCompanyProjectTd>
        <AffiliatedCompanyProjectTd width="144px">
          <HookFormSelect
            width="124px"
            isDefaultPossible
            defaultLabel="전체"
            name="contractStatus"
            optionList={contractStatusOptionList}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </AffiliatedCompanyProjectTd>
        <AffiliatedCompanyProjectTd width="140px">건 합계</AffiliatedCompanyProjectTd>
        <AffiliatedCompanyProjectTd width="200px" />
        <AffiliatedCompanyProjectTd width="160px" />
        <AffiliatedCompanyProjectTd width="160px">
          <HookFormSelect
            width="140px"
            isDefaultPossible
            defaultLabel="전체"
            name="category"
            optionList={projectSalesInfoAffiliatedCompanyCategoryOptionList}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </AffiliatedCompanyProjectTd>
        <AffiliatedCompanyProjectTd width="100px" />
        <AffiliatedCompanyProjectTd width="160px">
          <HookFormSelect
            width="140px"
            isDefaultPossible
            defaultLabel="전체"
            name="contribution"
            optionList={affiliatedCompanyPersonContributionOptionList}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </AffiliatedCompanyProjectTd>
        <AffiliatedCompanyProjectTd width="180px">
          {getLocaleStringIfExist(estimationTotalAmountSum)}
        </AffiliatedCompanyProjectTd>
        <AffiliatedCompanyProjectTd width="180px">
          {getLocaleStringIfExist(contractTotalAmountSum)}
        </AffiliatedCompanyProjectTd>
        <AffiliatedCompanyProjectTd width="180px">
          {getLocaleStringIfExist(estimationExperimentAmountSum)}
        </AffiliatedCompanyProjectTd>
        <AffiliatedCompanyProjectTd width="180px">
          {getLocaleStringIfExist(contractExperimentAmountSum)}
        </AffiliatedCompanyProjectTd>
        <AffiliatedCompanyProjectTd width="180px">
          {getLocaleStringIfExist(collectionAmountSum)}
        </AffiliatedCompanyProjectTd>
        <AffiliatedCompanyProjectTd width="180px">
          {getLocaleStringIfExist(unpaidAmountSum)}
        </AffiliatedCompanyProjectTd>
        <AffiliatedCompanyProjectTd width="188px">
          <HookFormSelect
            width="168px"
            isDefaultPossible
            defaultLabel="전체"
            name="contractStep"
            optionList={contractStepOptionList}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </AffiliatedCompanyProjectTd>
        <AffiliatedCompanyProjectTd width="200px">
          <HookFormSelect
            width="180px"
            isDefaultPossible
            defaultLabel="전체"
            name="contractEarningsStep"
            optionList={getContractEarningsStepOptionList()}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </AffiliatedCompanyProjectTd>
        <AffiliatedCompanyProjectTd width="360px">
          <HookFormSelect
            width="340px"
            isDefaultPossible
            defaultLabel="전체"
            name="orderCompanyName"
            optionList={getOrderCompanyNameOptionList()}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </AffiliatedCompanyProjectTd>
      </Box>
    </FormProvider>
  );
};
