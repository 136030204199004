import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { projectSalesInfoProjectDivisionModalApi } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/query/api';
import { handleError } from '@front/src/utils';
import type { ProjectStepInformationView } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/type/view';
import type { IdDeleteParameter } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/type/parameter';

export const projectSalesInfoProjectDivisionModalMutation = {
  useProjectDivisionModalCreate: (id: number) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
      () => projectSalesInfoProjectDivisionModalApi.createProjectDivisionModalData(id),
      {
        onSuccess: async (res) => {
          await queryClient.invalidateQueries({
            queryKey: ['project-sales-info', 'project-division', 'modal', id],
          });
          return res;
        },
        onError: async (error) => handleError(error, alert),
      }
    );
    return {
      mutate,
    };
  },

  useProjectDivisionModalUpdate(id: number) {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
      (params: ProjectStepInformationView) =>
        projectSalesInfoProjectDivisionModalApi.updateProjectDivisionModalData(params),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: ['project-sales-info', 'project-division', 'modal', id],
          });
        },
        onError: async (error) => handleError(error, alert),
      }
    );
    return {
      mutate,
    };
  },
  useProjectDivisionModalDelete(id: number) {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
      (params: IdDeleteParameter) =>
        projectSalesInfoProjectDivisionModalApi.deleteProjectDivisionModalData(params),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: ['project-sales-info', 'project-division', 'modal', id],
          });
        },
        onError: async (error) => handleError(error, alert),
      }
    );
    return {
      mutate,
    };
  },
};
