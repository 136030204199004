import React from 'react';
import type { AvatarProps } from '@mui/material/Avatar';
import Avatar from '@mui/material/Avatar';
import { ColorPalette } from '@front/assets/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

interface Props extends AvatarProps {
  size: 'small' | 'medium' | 'large';
}

const componentObj = {
  small: SmallAvatar,
  medium: MediumAvatar,
  large: LargeAvatar,
};

export default function AvatarUI({ size, ...rest }: Readonly<Props>) {
  const Component = componentObj[size];
  return (
    <Component {...rest} />
  );
}

interface ComponentProps extends AvatarProps {}

function SmallAvatar({ ...rest }: Readonly<ComponentProps>) {
  return (
    <Avatar
      {...rest}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        width: '24px',
        height: '24px',
        fontSize: '18px',
        color: ColorPalette.line.line02,
        background: ColorPalette.background.bg02,
        border: `1px solid ${ColorPalette.line.line}`,
      }}
    >
      <FontAwesomeIcon icon={faUser} />
    </Avatar>
  );
}

function MediumAvatar({ ...rest }: Readonly<ComponentProps>) {
  return (
    <Avatar
      {...rest}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        width: '26px',
        height: '26px',
        fontSize: '19px',
        color: ColorPalette.line.line02,
        background: ColorPalette.background.bg02,
        border: `1px solid ${ColorPalette.line.line}`,
      }}
    >
      <FontAwesomeIcon icon={faUser} />
    </Avatar>
  );
}

function LargeAvatar({ ...rest }: Readonly<ComponentProps>) {
  return (
    <Avatar
      {...rest}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        width: '260px',
        height: '260px',
        fontSize: '200px',
        color: ColorPalette.line.line02,
        background: ColorPalette.background.bg02,
        border: `1px solid ${ColorPalette.line.line}`,
      }}
    >
      <FontAwesomeIcon icon={faUser} />
    </Avatar>
  );
}
