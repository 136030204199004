import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';
import type { FormikProps } from 'formik';

import type { CampaignMemoId, CampaignMemoVO } from '@front/ost_campaign/domain';
import type { CampaignMemoUpdateParameter } from '@front/ost_campaign/parameter';
import type { LoginVO } from '@front/login/domain';
import DateFormat from '@front/layouts/DateFormat';
import Tooltip from '@front/components/Tooltip';
import { ColorPalette } from '@front/assets/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  formik: FormikProps<CampaignMemoUpdateParameter>;

  login?: LoginVO;
  memo: CampaignMemoVO;
  onModify: (params: CampaignMemoUpdateParameter) => () => void;
  onDelete: (campaignMemoId: CampaignMemoId) => () => void;
}

const MemoItemTitle = ({ formik, login, memo, onModify, onDelete }: Props) => (
  <>
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'unwrap',
        width: '100%',
        justifyContent: memo.writer.id === (login?.id ?? 1) ? 'space-between' : 'flex-start',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'unwrap',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: 'bold',
            marginRight: '4px',
          }}
        >
          <DateFormat
            date={memo.createdAt}
            format="YYYY-MM-DD HH:mm"
          />
        </Typography>
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: 'bold',
            marginRight: '4px',
          }}
        >
          {memo.writer.department.name === '한양풍동실험연구소'
            ? '회사'
            : memo.writer.department.name}
        </Typography>
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: 'bold',
          }}
        >
          {memo.writer.name}
        </Typography>
      </Box>
      {memo.writer.id === (login?.id ?? 1) && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'unwrap',
            visibility: formik.values.id === memo.id ? 'hidden' : 'visible',
          }}
        >
          <Tooltip title={'수정'}>
            <FontAwesomeIcon
              icon={'pen'}
              style={{
                cursor: 'pointer',
                fontSize: '11px',
                color: ColorPalette._9bb6ea,
                marginRight: '10px',
              }}
              onClick={onModify({
                id: memo.id,
                description: memo.description,
              })}
            />
          </Tooltip>
          <Tooltip title={'삭제'}>
            <FontAwesomeIcon
              icon="trash"
              style={{
                cursor: 'pointer',
                fontSize: '11px',
                color: ColorPalette._9bb6ea,
              }}
              onClick={onDelete(memo.id)}
            />
          </Tooltip>
        </Box>
      )}
    </Box>
    {memo.modifiedAt && (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'unwrap',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontSize: '11px',
            fontWeight: 'bold',
            marginRight: '4px',
          }}
        >
          (
          <DateFormat
            date={memo.modifiedAt}
            format="YYYY-MM-DD HH:mm"
          />{' '}
          편집됨)
        </Typography>
      </Box>
    )}
  </>
);

const TitleItemMemo = memo(MemoItemTitle);

export default TitleItemMemo;
