import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@front/layouts/IconButton';
import { useShallow } from 'zustand/react/shallow';
import { Box } from '@mui/material';
import { useMemoDrawerState } from '@front/src/components/memo-drawer/useState';

export { ClosedChildren as MemoDrawerClosedChildren };

const ClosedChildren = () => {
  const { toggle } = useMemoDrawerState(
    useShallow((state) => ({
      toggle: state.toggle,
    }))
  );
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '49px',
      }}
    >
      <IconButton
        tooltip="메모 열기"
        onClick={toggle}
      >
        <FontAwesomeIcon icon="angle-left" />
      </IconButton>
    </Box>
  );
};
