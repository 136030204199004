import { default as axios } from '@front/src/config/axios';
import { bidURLAPI } from '@front/src/features/project-sales-info/features/bid/utils/constant';
import type { ProjectSalesInfoBidResultParams } from '@front/src/features/project-sales-info/features/bid/features/bid-result/types/parameter';

const url = {
  getBidResultDetail: (id: number) => `${bidURLAPI}/bid-result/${id}`,
  updateBidResult: (id: number) => `${bidURLAPI}/bid-result/${id}`,
};

export const projectSalesInfoBidResultUpdateModalApi = {
  getBidResultDetail: async (id: number) => {
    const { data } = await axios.get(url.getBidResultDetail(id));
    return data;
  },
  updateBidResult: async (params: ProjectSalesInfoBidResultParams) => {
    await axios.put(url.updateBidResult(params.id!), params);
  },
};
