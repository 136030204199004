// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s6cFm06TEgE_QYh08erw{display:grid;grid-template-columns:1fr 1fr;gap:20px}.INrGk4FHVn0Xajw6ni0D{display:flex;flex-direction:column;gap:20px}`, "",{"version":3,"sources":["webpack://./front/src/features/affiliated-company/features/person/features/detail-modal/widgets/section/character/widget.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,QAAA","sourcesContent":[".container {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 20px;\n}\n\n.section {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `s6cFm06TEgE_QYh08erw`,
	"section": `INrGk4FHVn0Xajw6ni0D`
};
export default ___CSS_LOADER_EXPORT___;
