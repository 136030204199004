import { AffiliatedCompanyProjectRepository } from '@front/src/features/affiliated-company/features/project/repository/repository';
import { useAffiliatedCompanyProjectState } from '@front/src/features/affiliated-company/features/project/useState';
import { shallow } from 'zustand/shallow';
import { useAffiliatedCompanyDetailState } from '@front/src/features/affiliated-company/widgets/detail/useState';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import {
  projectSalesInfoContractStatusKey,
  projectSalesInfoContractStepKey,
} from '@front/src/features/project-sales-info/features/contract/utils/constant';
import { ProjectSalesInfoEstimationConfirmedStatusKey } from '@front/src/features/project-sales-info/features/estimation/features/estimation-confirmed/utils/constant';

export { useLogic as useAffiliatedCompanyProjectListLogic };

const useLogic = (menuId) => {
  const { id } = useAffiliatedCompanyDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { parameter } = useAffiliatedCompanyProjectState(
    (state) => ({
      parameter: state.parameter,
    }),
    shallow
  );
  const { data: list } = AffiliatedCompanyProjectRepository.useListGet(
    {
      id,
      estimationStatus: parameter.estimationStatus || null,
      category: parameter.category || null,
      contractEarningsStep: parameter.contractEarningsStep || null,
      contractStatus: parameter.contractStatus || null,
      contractStep: parameter.contractStep || null,
      contribution: parameter.contribution || null,
      orderCompanyName: parameter.orderCompanyName || null,
    },
    menuId
  );
  const { data: estimationStatusVariableList } = adminManageableVariableRepository.useListGet(
    ProjectSalesInfoEstimationConfirmedStatusKey,
    menuId
  );
  const { data: contractStatusVariableList } = adminManageableVariableRepository.useListGet(
    projectSalesInfoContractStatusKey,
    menuId
  );
  const { data: contractStepVariableList } = adminManageableVariableRepository.useListGet(
    projectSalesInfoContractStepKey,
    menuId
  );
  return {
    list,
    estimationStatusVariableList,
    contractStatusVariableList,
    contractStepVariableList,
  };
};
