import React from 'react';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import ArrangementCommentList from '@front/ost_proposal/view/detail/advisorForm/arrangement_comment/contents/ArrangementCommentList';
import type { ArrangementCommentParameter } from '@front/ost_proposal/parameter';
import type {
  ArrangementCommentId,
  ProposalArrangementCommentEditVO,
} from '@front/ost_proposal/domain';

interface Props {
  addArrangementComment: (comment: ArrangementCommentParameter) => void;
  updateArrangementComment: (comment: ArrangementCommentParameter) => void;
  deleteArrangementComment: (id: ArrangementCommentId) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  setArrangementCommentId: (id: ArrangementCommentId | undefined) => void;
  setArrangementCommentEditInput: (state: ProposalArrangementCommentEditVO) => void;
  setArrangementCommentParentId: (id: ArrangementCommentId | undefined) => void;
  setReplyArrangementCommentParentId: (id: ArrangementCommentId | undefined) => void;
}

function ArrangementCommentContents({
  updateArrangementComment,
  deleteArrangementComment,
  setArrangementCommentId,
  setArrangementCommentEditInput,
  setArrangementCommentParentId,
  addArrangementComment,
  setReplyArrangementCommentParentId,
}: Props) {
  return (
    <Box
      sx={{
        width: '100%',
        border: `1px solid ${ColorPalette._e4e9f2}}`,
        padding: '10px',
      }}
    >
      <ArrangementCommentList
        addArrangementComment={addArrangementComment}
        updateArrangementComment={updateArrangementComment}
        deleteArrangementComment={deleteArrangementComment}
        setArrangementCommentId={setArrangementCommentId}
        setArrangementCommentEditInput={setArrangementCommentEditInput}
        setArrangementCommentParentId={setArrangementCommentParentId}
        setReplyArrangementCommentParentId={setReplyArrangementCommentParentId}
      />
    </Box>
  );
}

export default ArrangementCommentContents;
