import type { ProjectDivisionParams } from '@front/src/features/project-sales-info/features/project-division/types/parameter';
import { projectSalesInfoProjectDivisionMutation } from '@front/src/features/project-sales-info/features/project-division/query/form/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import { projectSalesInfoProjectDivisionQuery } from '@front/src/features/project-sales-info/features/project-division/query/form/query';

export const projectSalesInfoProjectDivisionRepository = {
  useProjectDivisionGet: (id: number) => {
    const { data, isLoading } = projectSalesInfoProjectDivisionQuery.useProjectDivisionGet(id);
    return {
      data,
      isLoading,
    };
  },
  useCodeCreate: (id: number) => {
    const { mutate } = projectSalesInfoProjectDivisionMutation.useCodeCreate(id);
    const callback = getNoOpCallback();
    return {
      run: () => {
        mutate(id, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useProjectDivisionUpdate: (id: number) => {
    const { mutate } = projectSalesInfoProjectDivisionMutation.useProjectDivisionUpdate(id);
    const callback = getNoOpCallback();
    return {
      run: (params: ProjectDivisionParams) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
