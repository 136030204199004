import React, { useMemo } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { useProjectSalesInfoBidInfoState } from '@front/src/features/project-sales-info/features/bid/features/bid-information/widgets/useState';
import { ProjectSalesInfoBidInfoCreateModalContext } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/widgets/context';
import { useProjectSalesInfoBidInfoCreateModalLogic } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/widgets/useLogic';
import { ProjectSalesInfoBidInfoCreateModalTable } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/components/modal-table';
import { bidInfoModalLayout } from '@front/src/features/project-sales-info/features/bid/features/bid-information/components/layout';
import { isMobileDevice } from '@front/util/PwaUtil';

export { Widget as ProjectSalesInfoBidInfoCreateModalWidget };

const Widget = () => {
  const { Layout, Body } = bidInfoModalLayout;
  const { isCreateModalOpen, setIsCreateModalOpen } = useProjectSalesInfoBidInfoState((state) => ({
    isCreateModalOpen: state.isCreateModalOpen,
    setIsCreateModalOpen: state.setIsCreateModalOpen,
  }));
  const {
    h: { onCreate },
    d: { estimationIdList },
  } = useProjectSalesInfoBidInfoCreateModalLogic();
  const value = useMemo(
    () => ({
      h: { onCreate },
      d: { estimationIdList },
    }),
    [onCreate, estimationIdList]
  );
  return (
    <ModalLayout
      position="left"
      width={isMobileDevice() ? '29dvw' : '560px'}
      open={isCreateModalOpen}
      onClose={() => setIsCreateModalOpen()}
      title="입찰 추가"
    >
      <ProjectSalesInfoBidInfoCreateModalContext.Provider value={value}>
        <Layout>
          <Body>
            <ProjectSalesInfoBidInfoCreateModalTable />
          </Body>
        </Layout>
      </ProjectSalesInfoBidInfoCreateModalContext.Provider>
    </ModalLayout>
  );
};
