import { useQuery } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';

const workNameQuery = {
  useGetOne: (workId, menuId) => {
    const { data } = useQuery({
      queryFn: () => workWorkApi.getName(workId, menuId),
      queryKey: ['work', workId, 'name'],
      enabled: !!workId,
      suspense: true,
    });
    return { data };
  },
};

export default workNameQuery;
