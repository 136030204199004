import dayjs from 'dayjs';

export class MemoMessage {
  title: string;
  labelList: string[];
  labelConvertor: any;
  detail: any;

  constructor(title: string, labelList: string[], labelConvertor: any, detail: any) {
    this.title = title;
    this.labelList = labelList || [];
    this.labelConvertor = labelConvertor;
    this.detail = detail;
  }
}

export const makeMessage = (message: MemoMessage) => {
  const contents = message.labelList
    .map((l) => {
      const label = message.labelConvertor(l);
      let value = message.detail[l];
      value = getValue(l, value);
      if (!value) {
        return null;
      }
      return `${label}: ${value}`;
    })
    .filter((value) => value !== null)
    .join('\n');
  return `[${message.title}]` + '\n' + `${contents}`;
};

function getValue(l: string, value: any) {
  if (l === 'writer') {
    value = value.name;
  } else if (l.endsWith('Time')) {
    if (value) {
      value = dayjs(value).format('YYYY-MM-DD HH:mm');
    }
  } else if (l === 'content') {
    if (value) {
      if (value.length > 100) {
        value = value.substring(0, 100) + '...';
      }
    }
  }
  return value;
}
