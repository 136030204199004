import React, { memo } from 'react';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import Input from '@front/layouts/Input';

interface Props {
  ostName?: string;
}

const FormOst = ({ ostName }: Props) => (
  <DataFieldWithLabel
    label="귀속 OST"
    labelSX={{ minWidth: '80px' }}
  >
    <Input
      readOnly={true}
      key={ostName}
      variant="outlined"
      defaultValue={ostName}
    />
  </DataFieldWithLabel>
);

const CampaignFormOst = memo(FormOst);

export default CampaignFormOst;
