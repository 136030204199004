import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import { affiliatedCompanyOutlineShortApi } from '@front/src/features/affiliated-company/features/outline/features/short/query/api';
import type { AffiliatedCompanyOutlineShortUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/short/types/parameter';

export const affiliatedCompanyOutlineShortMutation = {
  useOutlineShortUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyOutlineShortUpdateParameter) =>
        affiliatedCompanyOutlineShortApi.updateOutlineShort(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['affiliated-company', 'drawer', 'search']);
        await queryClient.invalidateQueries(['affiliated-company', 'outline']);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
