import { useMutation, useQueryClient } from 'react-query';
import type {
  WorkReferenceFileUpdateParameter,
  WorkReferenceUpdateParameter,
} from '@front/src/features/work/features/work/features/reference/types/parameter';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import { convertEmptyToNull } from '@front/src/utils';
import type { CommonGetParameter } from '@front/src/types/parameter';

const workReferenceMutation = {
  useAddTop: (workId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: () => workWorkApi.createApprovalReferenceTop(workId, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reference'],
        });

        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useAddBottom: (workId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: () => workWorkApi.createApprovalReferenceBottom(workId, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reference'],
        });

        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useCreate: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.createReference(id, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reference'],
        });

        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useUpdateFile: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: WorkReferenceFileUpdateParameter) =>
        workWorkApi.updateReferenceFile(params, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reference'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'carousel'],
        });

        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useUpdate: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: WorkReferenceUpdateParameter) =>
        workWorkApi.updateReference(params, menuId),
      onMutate: async (data: WorkReferenceUpdateParameter) => {
        const { data: previousDataList } =
          (queryClient.getQueryData(['work', 'reference']) as any) ?? {};
        const newData = convertEmptyToNull(data);

        const previousData = previousDataList?.find((item) => item?.id === newData.id);
        const prevData = convertEmptyToNull(previousData);

        return newData.content === prevData?.content;
      },
      onSuccess: async (data, _, context) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reference'],
        });

        return { data, context };
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useSeqUp: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.updateReferenceSeqUp(id, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reference'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'carousel'],
        });
        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useSeqDown: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.updateReferenceSeqDown(id, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reference'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'carousel'],
        });
        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useDelete: (params: CommonGetParameter) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.deleteReference(id, params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reference'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'carousel'],
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useDeleteAll: (params: CommonGetParameter) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: () => workWorkApi.deleteAllReference(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reference'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'carousel'],
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
};

export default workReferenceMutation;
