import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workOutputMutation from '@front/src/features/work/features/work/features/output/query/mutation';

const useWorkOutputSeqUp = (bundleId: number, menuId) => {
  const callback = getNoOpCallback();
  const { mutate, isLoading } = workOutputMutation.useSeqUp(bundleId, menuId);
  return {
    run: (id: number) => mutate(id, callback),
    setCallback: getSetCallbackFunc(callback),
    isLoading,
  };
};

export default useWorkOutputSeqUp;
