export const projectActivityURL = '/api/project';

export const activityCategoryListKeyword = '영업관리-활동형태';

export const salesInfoActivityTableHeaderNameList = [
  {
    name: ' ',
    width: '2dvw',
    colSpan: 1,
  },
  {
    name: 'No.',
    width: '2dvw',
    colSpan: 1,
  },
  {
    name: '날짜',
    width: '8dvw',
    colSpan: 1,
  },
  {
    name: '시간',
    width: '7dvw',
    colSpan: 1,
  },
  {
    name: '형태',
    width: '7dvw',
    colSpan: 1,
  },
  {
    name: '담당자',
    width: '12dvw',
    colSpan: 2,
  },
  {
    name: '관계사',
    width: '20dvw',
    colSpan: 2,
  },
  {
    name: '관계사 인물',
    width: '6dvw',
    colSpan: 1,
  },
  {
    name: '부서',
    width: '4dvw',
    colSpan: 1,
  },
  {
    name: '직책',
    width: '4dvw',
    colSpan: 1,
  },
  {
    name: '차기활동',
    width: '16dvw',
    colSpan: 2,
  },
];
