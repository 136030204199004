import React, { useContext } from 'react';
import { SalesInfoActivityRowContext } from '@front/src/features/project-sales-info/features/activity/widgets/row/provider/context';
import { Td } from '@front/src/components/layout/table/td';

export { CellNo as SalesInfoActivityTableCellNo };

const CellNo = () => {
  const { item } = useContext(SalesInfoActivityRowContext);
  return <Td rowSpan={item.sameActivitySize + 1}>{item.index + 1}</Td>;
};
