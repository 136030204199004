import React, { useCallback } from 'react';
import { ProjectSalesInfoEstimationEstimationUpdateModalWidget } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/update-modal/widgets/widget';
import { useProjectSalesInfoEstimationEstimationUpdateModalState } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/update-modal/widgets/useState';
import { useProjectSalesInfoEstimationEstimationCreateModalState } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/create-modal/widgets/useState';
import { ProjectSalesInfoEstimationEstimationCreateModalWidget } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/create-modal/widgets/widget';
import ModalLayout from '@front/layouts/ModalLayout';
import { isMobileDevice } from '@front/util/PwaUtil';
import { useShallow } from 'zustand/react/shallow';

export { EstimationModal as ProjectSalesInfoEstimationEstimationModal };

const EstimationModal = () => {
  const { isOpen: isCreateModalOpen, setIsOpen: setIsCreateModalOpen } =
    useProjectSalesInfoEstimationEstimationCreateModalState(
      useShallow((state) => ({
        isOpen: state.isOpen,
        setIsOpen: state.setIsOpen,
      }))
    );
  const { isOpen: isUpdateModalOpen, setIsOpen: setIsUpdateModalOpen } =
    useProjectSalesInfoEstimationEstimationUpdateModalState(
      useShallow((state) => ({
        isOpen: state.isOpen,
        setIsOpen: state.setIsOpen,
      }))
    );
  const handleCreateModalCloseClick = useCallback(() => {
    setIsCreateModalOpen(false);
  }, [setIsCreateModalOpen]);
  const handleUpdateModalCloseClick = useCallback(() => {
    setIsUpdateModalOpen(false);
  }, [setIsUpdateModalOpen]);
  return (
    <>
      {isCreateModalOpen && (
        <ModalLayout
          position="left"
          width={isMobileDevice() ? '29dvw' : '560px'}
          title="견적 추가"
          open={isCreateModalOpen}
          onClose={handleCreateModalCloseClick}
        >
          <ProjectSalesInfoEstimationEstimationCreateModalWidget />
        </ModalLayout>
      )}
      {isUpdateModalOpen && (
        <ModalLayout
          position="left"
          width={isMobileDevice() ? '29dvw' : '560px'}
          title="견적 수정"
          open={isUpdateModalOpen}
          onClose={handleUpdateModalCloseClick}
        >
          <ProjectSalesInfoEstimationEstimationUpdateModalWidget />
        </ModalLayout>
      )}
    </>
  );
};
