import { default as axios } from '@front/src/config/axios';
import type { ProjectSalesInfoBidInfoParams } from '@front/src/features/project-sales-info/features/bid/features/bid-information/types/parameter';
import { estimationURLAPI } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/utils/constants';
import { bidURLAPI } from '@front/src/features/project-sales-info/features/bid/utils/constant';
import { toFormData } from '@front/services/api';

const url = {
  getBidInfoDetail: (id: number) => `${bidURLAPI}/bid-information/${id}`,
  updateBidInfo: (id: number) => `${bidURLAPI}/bid-information/${id}`,
  getEstimateId: (id: number) => `${estimationURLAPI}/${id}/estimation`,
};

export const projectSalesInfoBidInfoUpdateModalApi = {
  getBidInfoDetail: async (id: number) => {
    const { data } = await axios.get(url.getBidInfoDetail(id));
    return data;
  },
  updateBidInfo: async (params: ProjectSalesInfoBidInfoParams) => {
    await axios.put(
      url.updateBidInfo(params.id!),
      toFormData({
        ...params,
        bidNoticeFile: params.bidNoticeFile,
        bidGuideLineFile: params.bidGuideLineFile,
      })
    );
  },
  getEstimateId: async (id: number) => {
    const { data } = await axios.get(url.getEstimateId(id));
    return data;
  },
};
