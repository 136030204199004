export const enum MobileNotificationParentsType {
  PROJECT = 'PROJECT', // 영업정보 관리
  PROCEEDINGS = 'PROCEEDINGS', // 회의록
  OST = 'OST', // OST
  VOTE = 'VOTE', // 투찰 목록
  APPROVAL = 'APPROVAL', // 결재함
  AFFILIATED_COMPANY = 'AFFILIATED_COMPANY', // 관계사
}

export const parentsTypeInKor = {
  PROJECT: '영업정보 관리',
  PROCEEDINGS: '회의록',
  PROPOSAL: '제안',
  OST: 'OST',
  CAMPAIGN: '캠페인 관리',
  EVALUATION: '심사 목록',
  REWARD: '포상 관리',
  VOTE: '투찰 목록',
  ARRANGEMENT: '협의 목록',
  APPROVAL: '결재함',
  AFFILIATED_COMPANY: '관계사',
  WORK: '업무',
};
