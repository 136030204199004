import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import type {
  OstAddParameter,
  OstMemoAddParameter,
  OstMemoUpdateParameter,
  OstTrimmedMeanBottomRateUpdateParameter,
  OstTrimmedMeanTopRateUpdateParameter,
  OstUpdateNameParameter,
  OstUpdatePeriodParameter,
  OstUpdateStatusParameter,
  OstUpdateTotalBudgetParameter,
} from '@front/ost/parameter';
import ostApi from '@front/ost/api';
import { ostAction } from '@front/ost/action';
import type { OstId, OstMemoId, OstVO } from '@front/ost/domain';
import { ostStatusName } from '@front/ost/domain';
import { getErrorCode, getErrorMessage } from '@front/type/Error';
import { dialogAction } from '@front/dialog/action';
import { campaignApi } from '@front/ost_campaign/api';
import type { RootState } from '@front/services/reducer';
import { navigationAction } from '@front/navigate/action';
import { campaignAction } from '@front/ost_campaign/action';
import type { CampaignVO } from '@front/ost_campaign/domain';
import type { OstMemoSearchQuery, OstSearchQuery } from '@front/ost/query';
import type { CampaignUpdateManagerParameter } from '@front/ost_campaign/parameter';

import { AxiosError } from 'axios';
import { voteAction } from '@front/ost_vote/action';
import { CustomHttpStatus, getCustomErrorMessage } from '@front/common/type/http-status.type';

function* setFilter({ payload: query }: { payload: OstSearchQuery }) {
  yield put(ostAction.setIsLoading(true));
  try {
    const list: OstVO[] = yield call(ostApi.getList, query);
    yield put(ostAction.setList(list));
    yield put(ostAction.setIsLoading(false));
  } catch (e) {
    const message = getErrorMessage(ostAction.setFilter, e);
    const code = getErrorCode(ostAction.setFilter, e);
    if (code === CustomHttpStatus.NO_AUTHORIZATION) {
      yield put(dialogAction.openBlock(getCustomErrorMessage(CustomHttpStatus.NO_AUTHORIZATION)));
      return;
    }
    if (e instanceof AxiosError && Array.isArray(e.response?.data?.message)) {
      yield put(dialogAction.openError(e.response?.data?.message));
      return;
    }
    yield put(dialogAction.openError(message));
  }
}

function* add({ payload: params }: { payload: OstAddParameter }) {
  const filter: OstSearchQuery = yield select((root) => root.ost.filter);
  try {
    const add: OstVO = yield call(ostApi.add, params);
    yield put(ostAction.setFilter(filter));
    yield put(ostAction.openAddModal(false));
    yield put(navigationAction.navigate(`/ost/ost-management/${add.id}`));
    yield put(dialogAction.openAlert('등록이 완료 됐습니다.'));
  } catch (e) {
    const message = getErrorMessage(ostAction.add, e);
    yield put(dialogAction.openError(message));
  }
}

function* setDetail() {
  const { id } = yield select((root: RootState) => ({
    id: root.ost.id,
  }));
  try {
    const detail: OstVO = yield call(ostApi.getDetail, id);
    yield put(ostAction.setDetail(detail));
  } catch (e) {
    const message = getErrorMessage(ostAction.setDetail, e);
    const code = getErrorCode(voteAction.setMemoFilter, e);
    if (code === CustomHttpStatus.NO_AUTHORIZATION) {
      yield put(dialogAction.openBlock(getCustomErrorMessage(CustomHttpStatus.NO_AUTHORIZATION)));
      return;
    }
    yield put(dialogAction.openError(message));
  }
}

function* setCampaignList() {
  const { id } = yield select((root: RootState) => ({
    id: root.ost.id,
  }));
  try {
    const campaignList: CampaignVO[] = yield call(ostApi.getCampaignList, id);
    yield put(ostAction.setCampaignList(campaignList));
  } catch (e) {
    const message = getErrorMessage(ostAction.setDetail, e);
    yield put(dialogAction.openError(message));
  }
}

function* setId({ payload: id }: { payload: OstId }) {
  try {
    yield fork(setDetail);
    yield fork(setCampaignList);
    yield put(navigationAction.navigate(`/ost/ost-management/${id}`));
  } catch (e) {
    const message = getErrorMessage(ostAction.setId, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateManager({ payload: params }: { payload: CampaignUpdateManagerParameter }) {
  try {
    if (params.managerId) {
      yield call(campaignApi.updateManager, params.campaignId, params.managerId);
      yield fork(setCampaignList);
    }
  } catch (e) {
    const message = getErrorMessage(campaignAction.updateManager, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateName({ payload: params }: { payload: OstUpdateNameParameter }) {
  const { id, filter } = yield select((root: RootState) => ({
    id: root.ost.id,
    filter: root.ost.filter,
  }));
  try {
    yield call(ostApi.updateName, id, params);
    yield put(ostAction.setFilter(filter));
  } catch (e) {
    const message = getErrorMessage(ostAction.updateName, e);
    yield put(dialogAction.openError(message));
  }
}

function* updatePeriod({ payload: params }: { payload: OstUpdatePeriodParameter }) {
  const { id } = yield select((root: RootState) => ({
    id: root.ost.id,
  }));
  try {
    yield call(ostApi.updatePeriod, id, params);
    yield put(ostAction.setId(id));
  } catch (e) {
    const message = getErrorMessage(ostAction.updatePeriod, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateTotalBudget({ payload: params }: { payload: OstUpdateTotalBudgetParameter }) {
  const { id } = yield select((root: RootState) => ({
    id: root.ost.id,
  }));
  try {
    yield call(ostApi.updateTotalBudget, id, params);
    yield put(ostAction.setId(id));
  } catch (e) {
    const message = getErrorMessage(ostAction.updateTotalBudget, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateStatus({ payload: params }: { payload: OstUpdateStatusParameter }) {
  const { id, filter } = yield select((root: RootState) => ({
    id: root.ost.id,
    filter: root.ost.filter,
  }));
  try {
    yield call(ostApi.updateStatus, id, params);
    yield put(ostAction.setId(id));
    yield put(ostAction.setFilter(filter));
    if (params.status) {
      yield put(dialogAction.openAlert(`OST를 ${ostStatusName(params.status)} 상태로 합니다.`));
    }
  } catch (e) {
    const message = getErrorMessage(ostAction.updateStatus, e);
    yield put(dialogAction.openError(message));
  }
}

function* deleteDetail() {
  const { id, filter } = yield select((root: RootState) => ({
    id: root.ost.id,
    filter: root.ost.filter,
  }));
  try {
    yield call(ostApi.delete, id);
    yield put(ostAction.setFilter(filter));
    yield put(navigationAction.navigate(`/ost/ost-management`));
  } catch (e) {
    const message = getErrorMessage(ostAction.deleteDetail, e);
    yield put(dialogAction.openError(message));
  }
}

function* addMemo({ payload: params }: { payload: OstMemoAddParameter }) {
  const { id, filter } = yield select((root: RootState) => ({
    id: root.ost.id,
    filter: root.ost.memoFilter,
  }));
  try {
    yield call(ostApi.addMemo, id, params);
    yield fork(setMemoFilter, { payload: filter });
  } catch (e) {
    const message = getErrorMessage(ostAction.addMemo, e);
    yield put(dialogAction.openError(message));
  }
}

function* setMemoFilter({ payload: query }: { payload: OstMemoSearchQuery }) {
  const { id } = yield select((root: RootState) => ({
    id: root.ost.id,
  }));
  try {
    const list = yield call(ostApi.getMemoList, id, query);
    yield put(ostAction.setMemoList(list));
  } catch (e) {
    const message = getErrorMessage(ostAction.setMemoFilter, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateMemo({ payload: params }: { payload: OstMemoUpdateParameter }) {
  const { id, filter } = yield select((root: RootState) => ({
    id: root.ost.id,
    filter: root.ost.memoFilter,
  }));
  try {
    yield call(ostApi.updateMemo, id, params);
    yield fork(setMemoFilter, { payload: filter });
  } catch (e) {
    const message = getErrorMessage(ostAction.updateMemo, e);
    yield put(dialogAction.openError(message));
  }
}

function* deleteMemo({ payload: ostMemoId }: { payload: OstMemoId }) {
  const { ostId, filter } = yield select((root: RootState) => ({
    ostId: root.ost.id,
    filter: root.ost.memoFilter,
  }));
  try {
    yield call(ostApi.deleteMemo, ostId, ostMemoId);
    yield fork(setMemoFilter, { payload: filter });
  } catch (e) {
    const message = getErrorMessage(ostAction.deleteMemo, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateTrimmedMeanBottomRate({
  payload: params,
}: {
  payload: OstTrimmedMeanBottomRateUpdateParameter;
}) {
  const { id } = yield select((root: RootState) => ({
    id: root.ost.id,
  }));
  try {
    yield call(ostApi.updateTrimmedMeanBottomRate, id, params);
    yield put(ostAction.setId(id));
  } catch (e) {
    const message = getErrorMessage(ostAction.updateTrimmedMeanBottomRate, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateTrimmedMeanTopRate({
  payload: params,
}: {
  payload: OstTrimmedMeanTopRateUpdateParameter;
}) {
  const { id } = yield select((root: RootState) => ({
    id: root.ost.id,
  }));
  try {
    yield call(ostApi.updateTrimmedMeanTopRate, id, params);
    yield put(ostAction.setId(id));
  } catch (e) {
    const message = getErrorMessage(ostAction.updateTrimmedMeanTopRate, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchSetFilter() {
  yield takeLatest(ostAction.setFilter, setFilter);
}

function* watchAdd() {
  yield takeLatest(ostAction.add, add);
}

function* watchSetId() {
  yield takeLatest(ostAction.setId, setId);
}

function* watchUpdateName() {
  yield takeLatest(ostAction.updateName, updateName);
}

function* watchUpdatePeriod() {
  yield takeLatest(ostAction.updatePeriod, updatePeriod);
}

function* watchUpdateTotalBudget() {
  yield takeLatest(ostAction.updateTotalBudget, updateTotalBudget);
}

function* watchUpdateStatus() {
  yield takeLatest(ostAction.updateStatus, updateStatus);
}

function* watchDeleteDetail() {
  yield takeLatest(ostAction.deleteDetail, deleteDetail);
}

function* watchUpdateManager() {
  yield takeLatest(campaignAction.updateManager, updateManager);
}

function* watchSetMemoFilter() {
  yield takeLatest(ostAction.setMemoFilter, setMemoFilter);
}

function* watchAddMemo() {
  yield takeLatest(ostAction.addMemo, addMemo);
}

function* watchUpdateMemo() {
  yield takeLatest(ostAction.updateMemo, updateMemo);
}

function* watchDeleteMemo() {
  yield takeLatest(ostAction.deleteMemo, deleteMemo);
}

function* watchUpdateTrimmedMeanBottomRate() {
  yield takeLatest(ostAction.updateTrimmedMeanBottomRate, updateTrimmedMeanBottomRate);
}

function* watchUpdateTrimmedMeanTopRate() {
  yield takeLatest(ostAction.updateTrimmedMeanTopRate, updateTrimmedMeanTopRate);
}

function* ostSaga() {
  yield fork(watchSetFilter);
  yield fork(watchAdd);
  yield fork(watchSetId);

  yield fork(watchUpdateName);
  yield fork(watchUpdatePeriod);
  yield fork(watchUpdateTotalBudget);
  yield fork(watchUpdateStatus);

  yield fork(watchDeleteDetail);
  yield fork(watchUpdateManager);

  yield fork(watchSetMemoFilter);
  yield fork(watchAddMemo);
  yield fork(watchUpdateMemo);
  yield fork(watchDeleteMemo);

  yield fork(watchUpdateTrimmedMeanBottomRate);
  yield fork(watchUpdateTrimmedMeanTopRate);
}

export default ostSaga;
