import { projectSalesActivityRepository } from '@front/src/features/project-sales-info/features/activity/repository/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoActivityState } from '@front/src/features/project-sales-info/features/activity/useState';
import { useCallback } from 'react';

export { useLogic as useProjectSalesInfoActivityCreateButtonLogic };

const useLogic = () => {
  const { id } = useProjectSalesInfoDetailState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const { setId } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      setId: state.setId,
    }))
  );
  const { run: onCreate, setCallback } = projectSalesActivityRepository.useActivityCreate(id!);
  const handleCreate = useCallback(() => {
    onCreate(id);
  }, [onCreate, id]);
  setCallback({
    onSuccess: (res) => {
      setId(res.data);
    },
  });
  return { onCreate: handleCreate };
};
