import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyCareerQuery } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/query/query';
import { affiliatedCompanyCareerMutation } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/query/mutation';

export const affiliatedCompanyCareerRepository = {
  usePersonOutlineCareerCreate: (menuId) => {
    const { mutate } = affiliatedCompanyCareerMutation.usePersonOutlineCareerCreate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  usePersonOutlineCareerListGet: (id?: number, menuId?) => {
    const { data, isLoading } = affiliatedCompanyCareerQuery.usePersonOutlineCareerListGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
  usePersonOutlineCareerUpdate: (menuId) => {
    const { mutate } = affiliatedCompanyCareerMutation.usePersonOutlineCareerUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  usePersonOutlineCareerDelete: (menuId) => {
    const { mutate } = affiliatedCompanyCareerMutation.usePersonOutlineCareerDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
