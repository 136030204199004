import { useEffect, useRef } from 'react';

export const useDebounce = (time: number) => {
  const timeoutRef = useRef<number | null>(null);

  useEffect(
    () => () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    },
    []
  );

  return (fnc: (...args: any[]) => void) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => fnc(), time);
  };
};

export default useDebounce;
