import { Box } from '@mui/material';
import React from 'react';
import type { MenuView } from '@front/src/types';
import { useGnbLogic } from '@front/src/features/global-nav-bar/widgets/list/components/useGnbLogic';
import { GlobalNavBarClosedChildrenDepth3Menu } from '@front/src/features/global-nav-bar/components/depth3-menu';

export { Depth2Menu as GlobalNavBarClosedChildrenDepth2Menu };

interface Props extends MenuView {}

const Depth2Menu = ({ path, hasDrawer, children, id, icon, title, gnbClosed }: Props) => {
  const { hasChild, onClick, clickable, active } = useGnbLogic({
    path,
    hasDrawer,
    children,
    id,
  });
  return (
    <>
      <Box
        sx={{
          borderBottom: '1px dashed rgba(255,255,255,0.1)'
      }}
      >
        <Box
          onClick={onClick}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '32px',
            backgroundColor: active ? 'rgba(255,255,255,0.3)' : 'transparent',
            cursor: clickable ? 'pointer' : 'default',
            color: clickable ? '#eee' : '#4c9eeb',
            fontWeight: 'bold',
            fontSize:'0.9rem',
          }}
        >
          {title}
        </Box>
        {hasChild &&
          children?.map((child) => {
            if (child.depth === null) return;
            return (
              <GlobalNavBarClosedChildrenDepth3Menu
                key={child.title}
                gnbClosed={gnbClosed}
                {...child}
              />
            );
          })}
      </Box>
    </>
  );
};
