import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import type { FormikContextType } from 'formik';
import { FormikProvider } from 'formik';
import { ColorPalette } from 'assets/theme';
import { isMobileDevice } from '@front/util/PwaUtil';

interface TitleProps {
  title?: React.ReactNode;
  titleRightComponent?: React.ReactNode;
}

export interface PageLayoutProps extends TitleProps {
  body: React.ReactNode;
  footer?: React.ReactNode;
  modifiedAt?: Date | null;
  modals?: JSX.Element | JSX.Element[];
  showTitle?: boolean;
  zeroContentPaddingTop?: boolean;
}

export interface SearchPageLayoutProps extends PageLayoutProps {
  filter: React.ReactNode;
}

export interface FormikLayoutProps<T> {
  formik: FormikContextType<T>;
}

interface FormikPageLayoutProps<T> extends PageLayoutProps, FormikLayoutProps<T> {}

export default function PageLayout<T>(
  props: PageLayoutProps | SearchPageLayoutProps | FormikPageLayoutProps<T>
) {
  function isFormikForm(props: PageLayoutProps): props is FormikPageLayoutProps<T> {
    return typeof (props as any).formik !== 'undefined';
  }

  const { title, titleRightComponent, modals } = props;
  const showTitle = typeof props.showTitle != 'undefined' ? props.showTitle : true;
  return (
    <Paper
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: isMobileDevice() ? '50px' : '',
        overflow: 'hidden',
      }}
    >
      {showTitle && (
        <Box
          sx={{
            display: 'flex',
            padding: '20px',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            {typeof title === 'string' && (
              <Typography
                sx={{
                  fontSize: '18px',
                  lineHeight: '26px',
                  color: ColorPalette._252627,
                  fontWeight: 'bold',
                }}
              >
                {title}
              </Typography>
            )}
            {typeof title !== 'string' && title}
          </Box>
          {titleRightComponent}
        </Box>
      )}
      {isFormikForm(props) && (
        <FormikProvider value={props.formik}>
          <PageContent {...props} />
        </FormikProvider>
      )}
      {!isFormikForm(props) && <PageContent {...props} />}
      {Array.isArray(modals) ? modals.map((modal) => <Box key={modal.type}>{modal}</Box>) : modals}
    </Paper>
  );
}

function PageContent(props: PageLayoutProps) {
  function isSearchForm(props: PageLayoutProps): props is SearchPageLayoutProps {
    return typeof (props as any).filter !== 'undefined';
  }

  const contentPadding = props?.zeroContentPaddingTop ? '0px 20px 0 20px' : '20px 20px 0 20px';
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        p: '20px',
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        {isSearchForm(props) && props.filter}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: contentPadding,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '10px',
            backgroundColor: ColorPalette._e4e9f2,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: ColorPalette._697183,
          },
        }}
      >
        <Box>{props.body}</Box>
        {props.footer && <Box>{props.footer}</Box>}
      </Box>
    </Box>
  );
}
