import { useQuery } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';

const useWorkOutputBundleQuery = {
  useListGet: (id?: number, menuId?: number) => {
    const { data } = useQuery({
      queryFn: () => workWorkApi.getOutputBundle(id, menuId),
      queryKey: ['work', 'output-bundle', id, menuId],
      suspense: true,
      enabled: !!menuId,
    });
    return { data };
  },
};

export default useWorkOutputBundleQuery;
