import type { ReactNode } from 'react';
import React from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { affiliatedCompanySalesActivityTableHeaderList } from '@front/src/features/affiliated-company/features/sales-activity/utils';

export { SalesActivityTable as AffiliatedCompanySalesActivityTable };

interface Props {
  widget: ReactNode;
}

const SalesActivityTable = ({ widget }: Props) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          {affiliatedCompanySalesActivityTableHeaderList.map(({ name, colSpan, width }) => (
            <Th
              key={name}
              colSpan={colSpan}
              width={width}
            >
              {name}
            </Th>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{widget}</TableBody>
    </Table>
  </TableContainer>
);
