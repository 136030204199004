import { useEffect, useMemo } from 'react';
import { getTableRowNum } from '@front/src/features/accounting/utils';
import { useAccountingSettingsTaxAccountingAccountState } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/useState';
import { AccountingSettingsTaxAccountingAccountRepository } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/repository/repository';

export { useLogic as useAccountingSettingsTexAccountingAccountListLogic };

const useLogic = () => {
  const setParentIdList = useAccountingSettingsTaxAccountingAccountState(
    (state) => state.setParentIdList
  );
  const { data: countList } = AccountingSettingsTaxAccountingAccountRepository.useCountGet();
  const { data: list } = AccountingSettingsTaxAccountingAccountRepository.useAllGet();
  const tableRowNum = useMemo(() => getTableRowNum<any>(list), [list]);
  useEffect(() => {
    setParentIdList([]);
  }, [setParentIdList]);
  return {
    countList,
    list,
    tableRowNum,
  };
};
