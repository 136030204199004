import React, { memo, useMemo } from 'react';
import { TableBody, TableContainer } from '@mui/material';
import { Table } from '@front/layouts/Table';
import CircularProgress from '@front/components/CircularProgress';
import { AffiliatedCompanyOutlineNumberTable } from '@front/src/features/affiliated-company/features/outline/features/number/components/number-table';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useAffiliatedCompanyOutlineNumberLogic } from '@front/src/features/affiliated-company/features/outline/features/number/widgets/useLogic';
import { AffiliatedCompanyOutlineNumberContext } from '@front/src/features/affiliated-company/features/outline/features/number/widgets/context';
import { AffiliatedCompanyOutlineNumberBusinessRegistrationWidget } from '@front/src/features/affiliated-company/features/outline/features/number/features/business-registration/widgets/widget';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AffiliatedCompanyOutlineNumberWidget = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    d: { detailOutline },
    h: { onOutlineNumberUpdate },
    isLoading,
  } = useAffiliatedCompanyOutlineNumberLogic(menuId);

  const value = useMemo(
    () => ({
      d: { detailOutline },
      h: { onOutlineNumberUpdate },
    }),
    [onOutlineNumberUpdate, detailOutline]
  );
  if (typeof detailOutline === 'undefined' || isLoading) {
    return <Spinner />;
  }
  return (
    <Layout>
      <Header title={name} />
      <Body>
        <TableContainer>
          <Table>
            <TableBody>
              <AffiliatedCompanyOutlineNumberContext.Provider value={value}>
                <AffiliatedCompanyOutlineNumberTable />
              </AffiliatedCompanyOutlineNumberContext.Provider>
              <AffiliatedCompanyOutlineNumberBusinessRegistrationWidget menuId={menuId} />
            </TableBody>
          </Table>
        </TableContainer>
      </Body>
    </Layout>
  );
};

const Spinner = memo(() => {
  const { Layout } = AddDeleteTable;
  return (
    <Layout height="290px">
      <CircularProgress size={30} />
    </Layout>
  );
});

Spinner.displayName = 'AffiliatedCompanyOutlineNumberWidgetSpinner';

export default AffiliatedCompanyOutlineNumberWidget;
