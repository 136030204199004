import React from 'react';
import PersonnelContainer from '@front/personnel/controller/container';
import OstLogService from 'personnel/service/ost_log';
import useSetMenuAndTab from '@front/src/hooks/useSetMenuAndTab';

const Wrapper = () => {
  const { menuId } = useSetMenuAndTab();
  if (!menuId) return <></>;
  return (
    <PersonnelContainer>
      <OstLogService menuId={menuId} />
    </PersonnelContainer>
  );
};

export { Wrapper as PersonnelOstLog };
