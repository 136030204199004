import React, { memo } from 'react';
import { TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';

export { TableHeader as AccountingSettingsBankAccountListTableHeader };

const TableHeader = memo(() => (
  <TableHead>
    <TableRow>
      <Th
        center
        width="12%"
      >
        통합계좌명
      </Th>
      <Th
        center
        width="12%"
      >
        계좌명
      </Th>
      <Th
        center
        width="12%"
      >
        계좌코드
      </Th>
      <Th
        center
        width="12%"
      >
        계정명(코드)
      </Th>
      <Th
        center
        width="12%"
      >
        검색창내용
      </Th>
      <Th
        center
        width="20%"
      >
        적요
      </Th>
      <Th
        center
        width="8%"
      >
        외화통장
      </Th>
      <Th
        center
        width="4%"
      >
        사용
      </Th>
    </TableRow>
  </TableHead>
));

TableHeader.displayName = 'AccountingSettingsBankAccountListTableHeader';
