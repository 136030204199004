import React from 'react';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoEstimationComparedUpdateModalState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/features/update-modal/widgets/useState';
import { ProjectSalesInfoEstimationComparedUpdateModalWidget } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/features/update-modal/widgets/widget';
import { ProjectSalesInfoEstimationComparedCreateModalWidget } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/features/create-modal/widgets/widget';
import { useProjectSalesInfoEstimationComparedCreateModalState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/features/create-modal/widgets/useState';

export { EstimationComparedModal as ProjectSalesInfoEstimationComparedModal };

const EstimationComparedModal = () => {
  const { isOpen: isCreateModalOpen } = useProjectSalesInfoEstimationComparedCreateModalState(
    (state) => ({
      isOpen: state.isOpen,
    }),
    shallow
  );
  const { isOpen: isUpdateModalOpen } = useProjectSalesInfoEstimationComparedUpdateModalState(
    (state) => ({
      isOpen: state.isOpen,
    }),
    shallow
  );
  if (isCreateModalOpen) {
    return <ProjectSalesInfoEstimationComparedCreateModalWidget />;
  }
  if (isUpdateModalOpen) {
    return <ProjectSalesInfoEstimationComparedUpdateModalWidget />;
  }
  return <></>;
};
