import React from 'react';
import { ProjectSalesInfoActivityAffiliatesItemProvider } from '@front/src/features/project-sales-info/features/activity/widgets/activity-affiliates-item/provider/proivder';
import { SalesInfoActivityTableAffiliatesCellGroup } from '@front/src/features/project-sales-info/features/activity/widgets/activity-affiliates-item/components/affiliates-cell-group';

export { Widget as ProjectSalesInfoActivityAffiliatesItemWidget };

const Widget = () => (
  <ProjectSalesInfoActivityAffiliatesItemProvider>
    <SalesInfoActivityTableAffiliatesCellGroup />
  </ProjectSalesInfoActivityAffiliatesItemProvider>
);
