import React, { memo } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import CircularProgress from '@front/components/CircularProgress';

export { TableLoadingSpinner };

interface Props {
  height?: string;
  colSpan?: number;
  size?: number;
  padding?: string;
}

const TableLoadingSpinner = memo(({ height, colSpan, padding, size = 20 }: Props) => (
  <TableRow
    sx={{
      height,
    }}
  >
    <OldTd colSpan={colSpan}>
      <CircularProgress
        size={size}
        sx={{ padding }}
      />
    </OldTd>
  </TableRow>
));

TableLoadingSpinner.displayName = 'LoadingSpinner';
