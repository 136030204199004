import type { ReactNode } from 'react';
import React, { forwardRef } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import type { DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { RenderDateInput } from '@front/components/RenderDateInput';
import { DATE_FORMAT } from '@front/src/utils';
import type { CalendarPickerView } from '@mui/x-date-pickers';

interface Props<TInputDate = string, TDate = unknown>
  extends Omit<DatePickerProps<TInputDate, TDate>, 'renderInput'> {
  variant?: 'outlined' | 'standard';
  width?: string;
  label?: ReactNode;
  views?: readonly CalendarPickerView[];
  inputFormat?: string;
  openTo?: CalendarPickerView;
}

export { DatePicker };
const DatePicker = forwardRef<HTMLInputElement, Props>(
  ({ width, variant = 'outlined', inputFormat = DATE_FORMAT, value, ...rest }, ref) => (
    <div style={{ width }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MuiDatePicker
          ref={ref}
          value={value}
          inputFormat={inputFormat}
          {...rest}
          renderInput={(parameter) => (
            <RenderDateInput
              value={value}
              variant={variant}
              parameter={parameter}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  )
);

DatePicker.displayName = 'DatePicker';
