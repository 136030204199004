import { useQuery } from 'react-query';
import type { ProjectEstimationConfirmedView } from '@front/src/features/project-sales-info/features/estimation/features/estimation-confirmed/types/view';
import { projectSalesInfoEstimationConfirmedApi } from '@front/src/features/project-sales-info/features/estimation/features/estimation-confirmed/query/api';

export { query as projectSalesInfoEstimationConfirmedQuery };

const query = {
  useDetailGet: (id?: number) => {
    const { data, isLoading } = useQuery<ProjectEstimationConfirmedView>({
      queryFn: () => projectSalesInfoEstimationConfirmedApi.getDetail(id),
      queryKey: ['project', 'sales-info', 'estimation', 'confirmed', id],
      enabled: !!id,
    });
    return {
      data,
      isLoading,
    };
  },
};
