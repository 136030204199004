import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { DefaultFunction } from '@front/src/types';
import type { ProjectEstimationComparedParameter } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/types/parameter';

export { context as ProjectSalesInfoEstimationComparedCreateModalContext };

interface State {
  h: {
    onCreate: (params: ProjectEstimationComparedParameter) => void;
    onClose: DefaultFunction;
  };
}

const context = createContext<State>({
  h: {
    onCreate: noOp,
    onClose: noOp,
  },
});
