import React, { useCallback } from 'react';
import { Alert as MuiAlert, Snackbar as MuiSnackbar } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { useSnackbarState } from '@front/src/features/snackbar/useState';

const autoHideDuration = 5000;

const SnackbarFeature = () => {
  const { isOpen, setIsOpen, message, severity } = useSnackbarState(
    useShallow((state) => ({
      isOpen: state.isOpen,
      setIsOpen: state.setIsOpen,
      message: state.message,
      severity: state.severity,
    }))
  );
  const onClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setIsOpen(false);
    },
    [setIsOpen]
  );
  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isOpen}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
    >
      <MuiAlert
        sx={{ boxShadow: 3 }}
        severity={severity}
        onClose={onClose}
      >
        {message}
      </MuiAlert>
    </MuiSnackbar>
  );
};

export default SnackbarFeature;
