import React from 'react';
import FormControl from '@mui/material/FormControl';
import OutlinedInput, { type OutlinedInputProps } from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import { ColorPalette } from '@front/assets/theme';

export default function TextFieldBasicUI({
  error,
  disabled,
  sx = {},
  ...rest
}: Readonly<OutlinedInputProps>) {
  return (
    <FormControl
      error={error}
      sx={{
        width: '100%',
        ...sx,
      }}
    >
      <OutlinedInput
        sx={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          // height: '32px',
          // padding: '6px 10px',
          fontSize: '1.4rem',
          fontWeight: '400',
          lineHeight: 'normal',
          color: ColorPalette.greyscale.text_primary,
          backgroundColor: disabled ? ColorPalette.greyscale.disabled : ColorPalette.background.bg,
          borderRadius: '5px',
          gap: '10px',

          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${ColorPalette.line.line02}`,
          },

          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: disabled
              ? `1px solid ${ColorPalette.line.line02}`
              : `1px solid ${ColorPalette.main.main_hover}`,
          },

          '&:active .MuiOutlinedInput-notchedOutline': {
            border: disabled
              ? `1px solid ${ColorPalette.line.line02}`
              : `1px solid ${ColorPalette.main.main_hover}`,
          },

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: disabled
              ? `1px solid ${ColorPalette.line.line02}`
              : `1px solid ${ColorPalette.main.main_hover}`,

            '&:focus-visible': {
              border: `none`,
              outline: `2px solid ${ColorPalette.sub.sub_primary}`,
              outlineOffset: '-2px',
            },
          },

          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${ColorPalette.line.line02}`,
          },

          '&::placeholder': {
            color: ColorPalette.greyscale.text_quaternary,
          },

          '& .MuiInputAdornment-root': {
            width: '24px',
            height: '24px',
            marginLeft: '0',
          },
          '& .MuiIconButton-root': {
            padding: '0px',
          },
        }}
        inputProps={{
          sx: {
            height: '32px',
            padding: '6px 10px',
            boxSizing: 'border-box',
          },
        }}
        id="outlined-adornment-password"
        type="text"
        aria-describedby="component-error-text"
        {...rest}
      />
      {error && (
        <FormHelperText
          sx={{
            fontSize: '1.2rem',
            color: ColorPalette.sub2.sub2_primary,
            margin: '2px 0 0 0',
          }}
          id="component-error-text"
        >
          Error
        </FormHelperText>
      )}
    </FormControl>
  );
}
