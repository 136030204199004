import React, { useCallback } from 'react';
import { Box, TextareaAutosize } from '@mui/material';

import { ColorPalette } from '@front/assets/theme';
import type { ProposalContentVO } from '@front/ost_proposal/domain';
import type { SnackbarSeverityType } from '@front/components/Snackbar/action';
import type { ProposalContentChangeParameter } from '@front/ost_proposal/parameter';
import { FileUploadWithPreview } from '@front/components/FileUploadWithPreview/FileUploadWithPreview';

interface Props {
  item: ProposalContentVO;
  updateContent: (params: ProposalContentChangeParameter) => void;
  openSnackbar: (message: string, severity?: SnackbarSeverityType) => void;
  isContributor: boolean;
  readOnly: boolean;
}

export const ContentBeforeComparisionForm = ({
  updateContent,
  openSnackbar,
  isContributor,
  item,
  readOnly,
}: Props) => {
  const onBeforeBlur = useCallback(
    (e) => {
      if (readOnly) {
        return;
      }
      const value = e.target.value;
      if (value === '') {
        openSnackbar('제안 내용을 입력 하세요.');
        return;
      }
      if (value !== item.beforeContent) {
        updateContent({
          id: item.id,
          beforeContent: value,
        });
      }
    },
    [readOnly, updateContent, openSnackbar, isContributor]
  );

  const onAfterBlur = useCallback(
    (e) => {
      if (readOnly) {
        return;
      }
      const value = e.target.value;
      if (value === '') {
        openSnackbar('제안 내용을 입력 하세요.');
        return;
      }
      if (value !== item.beforeContent) {
        updateContent({
          id: item.id,
          afterContent: value,
        });
      }
    },
    [updateContent, openSnackbar, isContributor]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        width: '100%',
        border: `1px solid ${ColorPalette._e4e9f2}`,
        padding: '10px',
        borderRadius: '5px',
        minHeight: '300px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '50%',
            marginRight: '10px',
          }}
        >
          <FileUploadWithPreview
            readOnly={readOnly}
            update={updateContent}
            item={item}
            fieldName={'beforeFile'}
            deleteFlagName={'beforeFileDeleted'}
            label="현행"
          />
          <TextareaAutosize
            readOnly={readOnly}
            style={{
              border: `1px solid ${ColorPalette._e4e9f2}`,
              marginTop: '10px',
              width: '100%',
              minHeight: '10px',
              padding: '10px',
              fontFamily: 'Noto Sans KR',
            }}
            minRows={8}
            placeholder="제안 내용을 입력 하세요."
            defaultValue={item.beforeContent}
            onBlur={onBeforeBlur}
          />
        </Box>
        <Box
          sx={{
            width: '50%',
          }}
        >
          <FileUploadWithPreview
            readOnly={readOnly}
            update={updateContent}
            item={item}
            fieldName={'afterFile'}
            deleteFlagName={'afterFileDeleted'}
            label="개선 방향"
          />
          <TextareaAutosize
            readOnly={readOnly}
            style={{
              border: `1px solid ${ColorPalette._e4e9f2}`,
              marginTop: '10px',
              width: '100%',
              minHeight: '10px',
              padding: '10px',
              fontFamily: 'Noto Sans KR',
            }}
            minRows={8}
            placeholder="제안 내용을 입력 하세요."
            defaultValue={item.afterContent}
            onBlur={onAfterBlur}
          />
        </Box>
      </Box>
    </Box>
  );
};
