import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@front/layouts/IconButton';
import { useListDrawerState } from '@front/src/components/list-drawer/useState';
import { useShallow } from 'zustand/react/shallow';
import { Box } from '@mui/material';

export { ClosedChildren as ListDrawerClosedChildren };

const ClosedChildren = () => {
  const { toggle } = useListDrawerState(
    useShallow((state) => ({
      toggle: state.toggle,
    }))
  );
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '49px',
      }}
    >
      <IconButton
        tooltip="목록 열기"
        onClick={toggle}
      >
        <FontAwesomeIcon icon="angle-right" />
      </IconButton>
    </Box>
  );
};
