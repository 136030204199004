// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag_CKjlzIxzWq21iCHq8{height:max-content}`, "",{"version":3,"sources":["webpack://./front/src/components/layout/article-item-view/article-item-body.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA","sourcesContent":[".container {\n  height: max-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ag_CKjlzIxzWq21iCHq8`
};
export default ___CSS_LOADER_EXPORT___;
