import React from 'react';
import { useFormContext } from 'react-hook-form';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';

interface Props {
  name?: string;
  readOnly: boolean;
}

export default function WorkCarouselHeader({ readOnly, name }: Readonly<Props>) {
  const { TitleRightWrap, TitleWithSubTitleWrap } = SectionWrapperUI;
  const { getValues, setValue } = useFormContext();
  const onOpenCreateModal = () => {
    setValue('openCreateModal', true);
  };
  const renderRight = () => {
    if (readOnly) return <></>;
    return (
      <TitleRightWrap>
        <ButtonBasicUI
          shape="ghost"
          size="medium"
          onClick={() => {}}
        >
          순서이동
        </ButtonBasicUI>
        <ButtonBasicUI
          shape="primary"
          size="medium"
          onClick={onOpenCreateModal}
        >
          추가
        </ButtonBasicUI>
      </TitleRightWrap>
    );
  };
  return (
    <TitleWithSubTitleWrap
      title={name ?? '이미지 슬라이드'}
      subTitle={`총 ${getValues('list').length}개`}
      right={renderRight()}
    />
  );
}
