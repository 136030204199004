import type { ReactNode } from 'react';
import React from 'react';
import ImgCarouselUI from '@front/src/components/components-with-design/compound/ImgCarouselUI';
import type { WorkCarouselView } from '@front/src/features/work/features/work/features/carousel/types/view';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';
import WorkCarouselImageModal from '@front/src/features/work/features/work/features/carousel/components/ImageModal';

interface Props {
  item: WorkCarouselView;
  onRemove: (id: number) => void;
  readOnly: boolean;
}

export default function WorkCarouselCard({ onRemove, item, readOnly }: Readonly<Props>) {
  const { setValue } = useFormContext();
  const onClick = (e) => {
    e.stopPropagation();
    setValue('imageModalId', item.id);
  };
  const onDelete = (e) => {
    e.stopPropagation();
    onRemove(item.id);
  };
  const onDownload = (e) => {
    e.stopPropagation();
    window.location.assign(`/api/file-item/${item.file?.id}`);
  };
  return (
    <CarouselCardWrapper>
      <WorkCarouselImageModal item={item} />
      <ImgCarouselUI
        order={item.seq}
        onDelete={onDelete}
        onDownload={onDownload}
        onClick={onClick}
        readOnly={readOnly}
        sx={{
          position: 'absolute',
        }}
      />
      <img
        src={`/api/file-item/${item.file?.id}`}
        alt="캐러셀 이미지"
      />
    </CarouselCardWrapper>
  );
}

interface ChildrenProps {
  children: ReactNode;
}

function CarouselCardWrapper({ children }: ChildrenProps) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        '&:hover *': {
          visibility: 'visible',
        },
      }}
    >
      {children}
    </Box>
  );
}
