import React, { Suspense, useEffect, useState } from 'react';
import {
  Accordion,
  Box,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import NotificationControlPanel from '@front/src/features/note/components/ControlPanel';
import { FormProvider, useForm } from 'react-hook-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Table } from '@front/layouts/Table';
import { Tbc, Thc } from '@front/src/components/ui-builder/table';
import type { SxProps } from '@mui/system';
import type { ApprovalDocumentByNoteView, NoteItemView } from '@front/src/features/note/types/view';
import noteQuery from '@front/src/features/note/query/query';
import DrawerCommentSection from '@front/src/components/drawer-comment/DrawerCommentSection';
import noteMutation from '@front/src/features/note/query/mutation';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT, YY_MM_DD_HH_MM } from '@front/src/utils';
import NoteCardBody from '@front/src/features/note/components/NoteCardBody';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import { UserId } from '@front/user/domain';
import UserIcon from '@front/layouts/UserIcon';
import { NoResult } from '@front/src/components/layout/table/no-result';
import useIdGroup from '@front/src/features/note/hooks/useIdGroup';
import useNoteStore from '@front/src/features/note/useState';
import { useShallow } from 'zustand/react/shallow';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { getBorderColorByReviewedState } from '@front/src/features/note/utils';

interface Props {
  item: NoteItemView;
  setIsEditOpen?: () => void;
  isEditOpen?: boolean;
}

interface CardDetailTableProps {
  sx: SxProps;
  approvalDocument?: ApprovalDocumentByNoteView[];
}

export default function NoteCard({ item }: Props) {
  const methods = useForm({
    values: {
      contents: item.contents ?? '',
      comment: '',
    },
  });

  const [isEditOpen, setIsEditOpen] = useState(false);
  // const [isHeaderOpen, setIsHeaderOpen] = useState(false);
  // const [isFooterOpen, setIsFooterOpen] = useState(false);

  const { cardState, setCardState } = useNoteStore(
    useShallow((state) => ({
      cardState: state.cardState,
      setCardState: state.setCardState,
    }))
  );

  const isHeaderOpen = cardState[item.id]?.isHeaderOpen ?? false;
  const isFooterOpen = cardState[item.id]?.isFooterOpen ?? false;

  const handleEdit = () => {
    setIsEditOpen((prev) => !prev);
    methods.reset({ contents: item.contents ?? '' });
  };

  const handleOpen = (expanded: boolean, isHeader: boolean) => {
    setCardState(item.id, isHeader ? { isHeaderOpen: expanded } : { isFooterOpen: expanded });
  };

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          '& .MuiAccordionSummary-root': {
            minHeight: '40px',
            padding: '10px',
          },
          '& .MuiAccordionSummary-content': {
            margin: 0,
          },
        }}
      >
        <Accordion
          disableGutters={true}
          onChange={(_, expanded) => handleOpen(expanded, true)}
          expanded={isHeaderOpen}
          sx={{ ...SX_NOTECARD_HEADER_WRAP }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ overflow: 'hidden' }}
          >
            <CardHeader
              item={item}
              setIsEditOpen={handleEdit}
              isEditOpen={isEditOpen}
            />
          </AccordionSummary>
          <AccordionDetails>
            {isHeaderOpen && (
              <Suspense
                fallback={
                  <LoadingSpinner
                    width="100%"
                    height="100px"
                    padding="20px"
                  />
                }
              >
                <CardHeaderDetail item={item} />
              </Suspense>
            )}
          </AccordionDetails>
        </Accordion>
        <Box sx={{ ...SX_NOTECARD_BODY_WRAP }}>
          <NoteCardBody
            isEditOpen={isEditOpen}
            handleEdit={handleEdit}
            item={item}
          />
        </Box>
        <Accordion
          disableGutters={true}
          onChange={(_, expanded) => handleOpen(expanded, false)}
          expanded={isFooterOpen}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            댓글 {item.commentCount}건
          </AccordionSummary>
          <AccordionDetails>
            {isFooterOpen && (
              <Suspense
                fallback={
                  <LoadingSpinner
                    width="100%"
                    height="100px"
                    padding="20px"
                  />
                }
              >
                <CardFooter item={item} />
              </Suspense>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </FormProvider>
  );
}

const NoteApprovalStatusTable = ({ sx, approvalDocument }: CardDetailTableProps) => {
  const isEmpty = approvalDocument?.length === 0;
  const isApprover = approvalDocument?.[0]?.myRole?.includes('결재자');
  const isReferencer = approvalDocument?.[0]?.myRole?.includes('참조자');
  return (
    <TableContainer sx={sx}>
      <Table>
        <TableHead>
          <TableRow>
            <Thc>요청 일시</Thc>
            <Thc>제목</Thc>
            <Thc>결재 상태</Thc>
            {(isApprover || isReferencer) && <Thc>나의 역할</Thc>}
            {isApprover && <Thc>나의 결재</Thc>}
          </TableRow>
        </TableHead>
        <TableBody>
          {isEmpty && <NoResult colSpan={5} />}
          {!isEmpty &&
            approvalDocument?.map((item) => (
              <TableRow key={item.submissionDate + item.title}>
                <Tbc>{dayjs(item?.submissionDate).format(YY_MM_DD_HH_MM) ?? '-'}</Tbc>
                <Tbc>{item?.title ?? '-'}</Tbc>
                <Tbc>{item?.status ?? '-'}</Tbc>
                {(isApprover || isReferencer) && <Tbc>{item?.myRole ?? '-'}</Tbc>}
                {isApprover && <Tbc>{item?.myOrder ?? '-'}</Tbc>}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CardHeader = ({ item, setIsEditOpen, isEditOpen }: Props) => {
  const { mutate: toggleRead } = noteMutation.useToggleRead(item.reviewerInfoId ?? 0, {
    isRead: !item.readAt,
  });

  const onClick = (e) => {
    e.stopPropagation();
    toggleRead();
  };
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        display="flex"
        gap="5px"
        alignItems="center"
      >
        <NotificationControlPanel
          item={item}
          setIsEditOpen={setIsEditOpen}
          isEditOpen={isEditOpen}
        />
        <Box sx={{ ...SX_NOTECARD_HEADER_DATA_WRAP }}>
          <UserIcon user={UserId(item.writerId)} />
          <Typography sx={{ ...SX_NOTECARD_HEADER_DATA }}>
            {item.writerName} {dayjs(item.modifiedAt ?? item.createdAt).format(DATE_TIME_FORMAT)}
            {item.reviewerCount ? `(${item.reviewerCount}명 공유)` : ''}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        gap="5px"
        alignItems="center"
      >
        <Typography sx={{ ...SX_NOTECARD_HEADER_DATA, marginLeft: '5px' }}>
          {item.approvalDocumentStatusName}
          {item.approvalDocumentRelatedDate
            ? `(${dayjs(item.approvalDocumentRelatedDate).format(DATE_TIME_FORMAT)})`
            : ''}
        </Typography>
        {item.isReviewer && (
          <ButtonBasicUI onClick={onClick}>
            <Typography>{item.readAt ? '읽지 않음' : '읽음'}</Typography>
          </ButtonBasicUI>
        )}
      </Box>
    </Box>
  );
};

const CardHeaderDetail = ({ item }: Props) => {
  const { data: noteDetail } = noteQuery.useGetDetail(item.id);
  const setClickedReviewer = useNoteStore((state) => state.setClickedReviewer);
  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoColumns: '1fr 2fr 1fr',
        rowGap: '5px',
      }}
    >
      <Typography>최종 수정 일시</Typography>
      <Typography sx={{ gridColumn: '2 / span 2' }}>{noteDetail?.modifiedAt ?? '-'}</Typography>
      <Typography>회람자</Typography>
      <Box
        display="flex"
        gap="5px"
      >
        {noteDetail?.reviewerList?.map((reviewer) => (
          <UserIcon
            key={reviewer?.id}
            user={UserId(reviewer?.id ?? 0)}
            sx={{
              cursor: 'pointer',
              border: `1px solid ${getBorderColorByReviewedState(
                !!reviewer?.readAt,
                noteDetail?.reviewDate
              )}`,
            }}
            onClick={() => setClickedReviewer(`@[${reviewer.name}](${reviewer.id})`)}
          />
        ))}
      </Box>
      <Typography sx={{ justifySelf: 'end' }}>
        {noteDetail?.reviewDate ? dayjs(noteDetail?.reviewDate).format('YY-MM-DD') : '-'} 까지
      </Typography>
      <Typography sx={{ gridColumn: '1 / span 3' }}>결재 현황</Typography>
      <NoteApprovalStatusTable
        sx={{ gridColumn: '1 / span 3', '& .MuiTableCell-root': { height: '35px' } }}
        approvalDocument={noteDetail?.approvalDocumentList}
      />
    </Box>
  );
};

const CardFooter = ({ item }: Props) => {
  const commentQueryParams = {
    targetId: item.id,
    targetType: 'note',
  };
  const { menuId } = useIdGroup();
  const { data: commentList } = noteQuery.useGetCommentList(commentQueryParams);
  const { data: userList } = noteQuery.useGetAuthUserList({ menuId });
  const methods = useForm({
    values: {
      comment: '',
    },
  });
  const { mutate: createComment, isLoading: isCreateLoading } =
    noteMutation.useCreateComment(commentQueryParams);
  const { mutate: updateComment, isLoading: isUpdateLoading } =
    noteMutation.useUpdateComment(commentQueryParams);
  const { mutate: deleteComment, isLoading: isDeleteLoading } =
    noteMutation.useDeleteComment(commentQueryParams);
  const { mentionList, setMentionList, clickedReviewer, noteState, setNoteState } = useNoteStore(
    useShallow((state) => ({
      mentionList: state.mentionList,
      setMentionList: state.setMentionList,
      clickedReviewer: state.clickedReviewer,
      noteState: state.noteState,
      setNoteState: state.setNoteState,
    }))
  );

  const setMentionListHandler = (mentionList: number[]) => {
    setMentionList(mentionList);
  };

  const onSubmit = methods.handleSubmit((data) => {
    createComment({
      targetId: item.id,
      targetType: 'NOTE',
      contents: data.comment.replace(/@\[(.*?)\]\(\d+\)/g, '@$1'),
      mentionList: mentionList,
    });
    methods.reset();
    setNoteState({ comment: '' });
  });

  const commentVal = methods.watch('comment');

  useEffect(() => {
    setNoteState({ comment: commentVal });
  }, [commentVal]);

  useEffect(() => {
    if (commentVal === '' && noteState.comment !== '') {
      methods.setValue('comment', noteState.comment);
    }
  }, [noteState, commentVal]);

  return (
    <FormProvider {...methods}>
      <DrawerCommentSection>
        <DrawerCommentSection.Input
          onSubmit={onSubmit}
          userList={userList?.map((item) => ({ id: item.id, display: item.name })) ?? []}
          isMention={true}
          setMentionList={setMentionListHandler}
          clickedReviewer={clickedReviewer}
        />
        <DrawerCommentSection.List
          comments={commentList}
          onDelete={deleteComment}
          onUpdate={updateComment}
        />
      </DrawerCommentSection>
    </FormProvider>
  );
};

const SX_NOTECARD_BODY_WRAP = {
  backgroundColor: 'white',
  minHeight: '40px',
  padding: '10px 14PX',
  display: 'flex',
  alignItems: 'center',
};

const SX_NOTECARD_HEADER_WRAP = {
  borderBottom: '1px solid var(--design-v-10-light-line-line, #DCE0E7)',
  boxShadow: 'none',
};

const SX_NOTECARD_HEADER_DATA_WRAP = {
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
};

const SX_NOTECARD_HEADER_DATA = {
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
};
