import { useQuery } from 'react-query';
import { personalSettingsModalApi } from '@front/src/features/personal-settings-modal/api/api';
import type {
  AuthSearchParams,
  AuthUserSearchParams,
} from '@front/src/features/admin/features/access-authorization-settings/type/parameter';

export const personalSettingsModalQuery = {
  useUserListGet: (menuId: number, params: AuthUserSearchParams, authMenuId) => {
    const { data } = useQuery({
      queryKey: ['personal-settings-modal', menuId, params.keyword],
      queryFn: () => personalSettingsModalApi.getUserList(menuId, params, authMenuId),
      select: (res) => res.data,
      suspense: true,
    });

    return {
      data,
    };
  },

  useDeptListGet: (menuId: number, params: AuthSearchParams, authMenuId) => {
    const { data } = useQuery({
      queryKey: ['personal-settings-modal-dept', menuId, params.name],
      queryFn: () => personalSettingsModalApi.getDeptList(menuId, params, authMenuId),
      select: (res) => res.data,
      suspense: true,
    });

    return {
      data,
    };
  },
};
