import React, { useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { PersonalSettingsModalUISettingsHeaderSettingsListDrawerContent } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/components/ListDrawerContent';
import { usePersonalSettingsModalUISettingsHeaderSettingsHistoryState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/history/useState';
import { usePersonalSettingsModalUISettingsHeaderSettingsPositionState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/useState';
import Drawer from '@front/layouts/Drawer';

export { ListDrawer as PersonalSettingsModalUISettingsHeaderSettingsListDrawer };
interface Props {
  authMenuId: number | undefined;
}
const ListDrawer = ({ authMenuId }: Props) => {
  const { header: historyHeader } = usePersonalSettingsModalUISettingsHeaderSettingsHistoryState(
    useShallow((state) => ({
      header: state.header,
    }))
  );
  const { header: positionHeader } = usePersonalSettingsModalUISettingsHeaderSettingsPositionState(
    useShallow((state) => ({
      header: state.header,
    }))
  );
  const open = useMemo(() => !!historyHeader || !!positionHeader, [historyHeader, positionHeader]);
  return (
    <Drawer
      open={open}
      openedWidth={800}
    >
      <PersonalSettingsModalUISettingsHeaderSettingsListDrawerContent authMenuId={authMenuId} />
    </Drawer>
  );
};
