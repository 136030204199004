import { create } from 'zustand';
import type { ApprovalHasFileListParams } from '@front/src/features/drawer-approval/types/parameter';
import type { HasFileItemView } from '@front/src/features/drawer-approval/types/view';

interface State {
  editOpenList: string[];
  isReferenceUserSelectorModalOpen: boolean;

  imgModalId: string;
  isHasModalOpen: boolean;
  selectedHasList: HasFileItemView[];
  hasQueryParams: ApprovalHasFileListParams;
}

interface Action {
  setEditOpenList: (editId?: string) => void;
  setIsReferenceUserSelectorModalOpen: (isOpen: boolean) => void;

  setImgModalId: (imgModalId: string) => void;
  setIsHasModalOpen: (isHasModalOpen: boolean) => void;
  setSelectedHasList: (file: HasFileItemView) => void;
  toggleAllSelectedHasList: (isChecked: boolean, list: HasFileItemView[]) => void;
  resetSelectedHasList: () => void;
  setHasQueryParams: (hasQueryParams: ApprovalHasFileListParams) => void;
}

const useApprovalStore = create<State & Action>((set) => ({
  editOpenList: [],
  isReferenceUserSelectorModalOpen: false,
  imgModalId: '',
  isHasModalOpen: false,
  selectedHasList: [],
  hasQueryParams: {},

  setEditOpenList: (editId?: string) =>
    set((state) => {
      if (state.editOpenList.includes(editId ?? ''))
        return { editOpenList: state.editOpenList.filter((id) => id !== editId) };
      return { editOpenList: editId ? [...state.editOpenList, editId] : [] };
    }),
  setIsReferenceUserSelectorModalOpen: (isOpen: boolean) =>
    set({ isReferenceUserSelectorModalOpen: isOpen }),
  setImgModalId: (imgModalId: string) => set({ imgModalId }),
  setIsHasModalOpen: (isHasModalOpen: boolean) => set({ isHasModalOpen }),
  setSelectedHasList: (file: HasFileItemView | HasFileItemView[]) =>
    set((state) => {
      if (Array.isArray(file)) return { selectedHasList: file };
      if (state.selectedHasList.includes(file))
        return { selectedHasList: state.selectedHasList.filter((item) => item.id !== file.id) };
      return { selectedHasList: [...state.selectedHasList, file] };
    }),
  toggleAllSelectedHasList: (isChecked: boolean, list: HasFileItemView[]) =>
    set(() => ({
      selectedHasList: isChecked ? [] : list,
    })),
  resetSelectedHasList: () => set({ selectedHasList: [] }),
  setHasQueryParams: (hasQueryParams: ApprovalHasFileListParams) =>
    set((state) => ({
      hasQueryParams: { ...state.hasQueryParams, ...hasQueryParams },
    })),
}));

export default useApprovalStore;
