import { Table } from '@front/src/components/layout/table/table';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { OldTd } from '@front/layouts/Table';
import React, { useContext } from 'react';
import { HookFormText } from '@front/src/components/text-with-hook-form/text-with-hook-form';
import { ProjectSalesInfoProjectDivisionContext } from '@front/src/features/project-sales-info/features/project-division/widgets/context';
import { useFormContext } from 'react-hook-form';
import { changeViewToParams } from '@front/src/features/project-sales-info/features/project-division/utils/constants';
import type { ProjectDivisionView } from '@front/src/features/project-sales-info/features/project-division/types/view';
import { useProjectSalesInfoProjectDivisionState } from '@front/src/features/project-sales-info/features/project-division/widgets/useState';
import { shallow } from 'zustand/shallow';
import { ProjectSalesInfoProjectDivisionModalWidget } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/widgets/widget';
import Button from '@front/layouts/Button';
import { getValueIfExist } from '@front/src/utils';
import { useShallow } from 'zustand/react/shallow';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";

export { Index as ProjectSalesInfoProjectDivisionEstimateDateTable };

const Index = () => {
  const {
    h: { onProjectDivisionUpdate },
    d: { projectDivisionData },
  } = useContext(ProjectSalesInfoProjectDivisionContext);
  const { handleSubmit } = useFormContext();
  const { setIsModalOpen } = useProjectSalesInfoProjectDivisionState(
    (state) => ({ setIsModalOpen: state.setIsModalOpen, isModalOpen: state.isModalOpen }),
    shallow
  );
  const onClick = () => setIsModalOpen();
  const onSubmit = handleSubmit((data) =>
    onProjectDivisionUpdate(changeViewToParams(data as ProjectDivisionView))
  );
  const { readOnly } = useProjectSalesInfoProjectDivisionState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow key={Math.random()}>
            <Th>견적접수일</Th>
            <OldTd sx={{textAlign: 'left'}}>
              <DatePickerWithHookForm
                name="estimationReceptionDate"
                onSubmit={onSubmit}
                disabled={readOnly}
              />
            </OldTd>
          </TableRow>
          <TableRow>
            <Th>예상착수시기</Th>
            <OldTd
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '58px',
              }}
            >
              <HookFormText name="expectedWorkStartDate" />
              <Button onClick={onClick}>예상착수시기 및 사업진행단계 수정</Button>
              <ProjectSalesInfoProjectDivisionModalWidget />
            </OldTd>
          </TableRow>
          <TableRow>
            <Th>사업진행단계</Th>
            <OldTd sx={{ textAlign: 'left' }}>
              {getValueIfExist(projectDivisionData?.businessStatusStep)}
            </OldTd>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
