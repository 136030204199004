import React, { useCallback, useMemo } from 'react';
import Button from '@front/layouts/Button';
import { useAccountingSettingsBankAccountState } from '@front/src/features/accounting/features/settings/features/bank-account/useState';
import { shallow } from 'zustand/shallow';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { getIsShowForAuthorizationFromMenu } from '@front/src/utils';
import { useShallow } from 'zustand/react/shallow';

export { UploadButton as AccountingSettingsBankAccountUploadButton };

const UploadButton = () => {
  const { setIsOpen } = useAccountingSettingsBankAccountState(
    (state) => ({
      setIsOpen: state.setIsOpen,
    }),
    shallow
  );
  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const isShow = useMemo(() => {
    if (!loginUser?.menus) return false;
    return getIsShowForAuthorizationFromMenu(loginUser.menus);
  }, [loginUser]);
  const { readOnly } = useAccountingSettingsBankAccountState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  if (!isShow) return <></>;
  return (
    <Button
      onClick={handleClick}
      disabled={readOnly}
    >
      E계정 업로드
    </Button>
  );
};
