import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import { OstStatus } from '@front/ost/domain';
import type { RootState } from '@front/services/reducer';
import OstUpdateForm from '@front/ost/view/detail/UpdateForm';
import { ostAction } from '@front/ost/action';
import { CampaignStatus } from '@front/ost_campaign/domain';

const OstUpdateFormService = () => {
  const dispatch = useDispatch();
  const {
    name,
    startDate,
    endDate,
    totalBudget,
    status,
    trimmedMeanBottomRate,
    trimmedMeanTopRate,
  } = useSelector(
    (root: RootState) => ({
      name: root.ost.detail?.name,
      startDate: root.ost.detail?.startDate,
      endDate: root.ost.detail?.endDate,
      totalBudget: root.ost.detail?.totalBudget,
      status: root.ost.detail?.status,
      trimmedMeanBottomRate: root.ost.detail?.trimmedMeanBottomRate,
      trimmedMeanTopRate: root.ost.detail?.trimmedMeanTopRate,
    }),
    shallowEqual
  );

  const [campaignList] = useSelector(
    (root: RootState) => [root.ost.detail?.campaignList],
    shallowEqual
  );

  const openSnackbar = useCallback(
    (message, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );

  const onStatusChange = useCallback(
    (e) => {
      const value: OstStatus = e.target.value;
      if (!value) {
        return;
      }
      if (status === value) {
        return;
      }

      if (value === OstStatus.ON_GOING) {
        if (
          !name ||
          !startDate ||
          !endDate ||
          !totalBudget ||
          trimmedMeanBottomRate === null ||
          trimmedMeanTopRate === null
        ) {
          openSnackbar('OST 정보를 모두 입력해야 진행 상태로 할 수 있습니다.');
          return;
        }
      }

      if (value === OstStatus.DONE && campaignList && campaignList.length > 0) {
        for (const campaign of campaignList) {
          if (campaign.status === CampaignStatus.ON_GOING) {
            openSnackbar('진행 중인 캠페인이 있습니다.');
            return;
          }
        }
      }

      dispatch(
        ostAction.updateStatus({
          status: value,
        })
      );
    },
    [
      dispatch,
      openSnackbar,
      name,
      startDate,
      endDate,
      totalBudget,
      status,
      campaignList,
      trimmedMeanBottomRate,
      trimmedMeanTopRate,
    ]
  );

  return <OstUpdateForm onStatusChange={onStatusChange} />;
};

export default OstUpdateFormService;
