import React, { useRef } from 'react';
import type { ArrangementCommentParameter } from '@front/ost_proposal/parameter';
import type {
  ArrangementCommentId,
  ProposalArrangementCommentEditVO,
} from '@front/ost_proposal/domain';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import { AddArrangementCommentLayout } from '@front/ost_proposal/view/detail/advisorForm/arrangement_comment/add_arrangement_comment_row';
import ArrangementCommentContents from '@front/ost_proposal/view/detail/advisorForm/arrangement_comment/contents';
import { ArticleItem } from '@front/src/components/article-item';

interface Props {
  addArrangementComment: (comment: ArrangementCommentParameter) => void;
  updateArrangementComment: (comment: ArrangementCommentParameter) => void;
  deleteArrangementComment: (id: ArrangementCommentId) => void;
  setArrangementCommentId: (id: ArrangementCommentId | undefined) => void;
  setArrangementCommentParentId: (id: ArrangementCommentId | undefined) => void;
  setReplyArrangementCommentParentId: (id: ArrangementCommentId | undefined) => void;
  setArrangementCommentEditInput: (state: ProposalArrangementCommentEditVO) => void;
}

export { Layout as ArrangementCommentLayout };
const Layout = ({
  addArrangementComment,
  updateArrangementComment,
  deleteArrangementComment,
  setArrangementCommentId,
  setArrangementCommentParentId,
  setReplyArrangementCommentParentId,
  setArrangementCommentEditInput,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [arrangementCommentList] = useSelector(
    (root: RootState) => [root.proposal.arrangementCommentList],
    shallowEqual
  );
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        width: '100%',
        padding: '10px',
      }}
    >
      <ArticleItem
        title="협의 피드백"
        children={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'nowrap',
              width: '100%',
              border: `1px solid ${ColorPalette._e4e9f2}`,
              padding: '10px',
              borderRadius: '5px',
              marginTop: '10px',
            }}
          >
            <AddArrangementCommentLayout
              addArrangementComment={addArrangementComment}
              inputRef={inputRef}
            />
            {arrangementCommentList.length > 0 && (
              <ArrangementCommentContents
                addArrangementComment={addArrangementComment}
                updateArrangementComment={updateArrangementComment}
                deleteArrangementComment={deleteArrangementComment}
                inputRef={inputRef}
                setArrangementCommentId={setArrangementCommentId}
                setArrangementCommentEditInput={setArrangementCommentEditInput}
                setArrangementCommentParentId={setArrangementCommentParentId}
                setReplyArrangementCommentParentId={setReplyArrangementCommentParentId}
              />
            )}
          </Box>
        }
      />
    </Box>
  );
};
