import { useQuery } from 'react-query';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyModalApi } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/query/api';
import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/types/view';

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyModalQuery = {
  useDetailGet: (id?: number) => {
    const { data, isLoading } =
      useQuery<ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalView>({
        queryFn: () => projectSalesInfoAffiliatedCompanyAffiliatedCompanyModalApi.getDetail(id),
        queryKey: ['project', 'sales-info', 'affiliated-company', 'affiliated-company'],
        enabled: !!id,
        cacheTime: 0,
      });
    return {
      data,
      isLoading,
    };
  },
};
