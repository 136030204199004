import notificationQuery from '@front/src/features/notification/query/query';

const useGetNotificationFilterList = () => {
  const { data } = notificationQuery.useGetFilterList();
  const filterList = data?.map((item) => ({
    id: item.id,
    label: item.title,
    value: item.alarmTypeUrl,
  }));
  const defaultValue = {
    id: 0,
    label: '전체 디렉토리',
    value: ' ',
  };

  return [defaultValue, ...(filterList ?? [])];
};

export default useGetNotificationFilterList;
