import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { useAffiliatedPersonSalesActivityState } from '@front/src/features/affiliated-person/features/sales-activity/useState';
import { AffiliatedPersonSalesActivityRowWidget } from '@front/src/features/affiliated-person/features/sales-activity/widgets/row/widget';

export { TableBody as AffiliatedPersonSalesActivityTableBody };

const TableBody = () => {
  const { list } = useAffiliatedPersonSalesActivityState(
    useShallow((state) => ({
      list: state.list,
    }))
  );
  if (list?.length === 0) {
    return <NoResult colSpan={14} />;
  }
  return (
    <>
      {list?.map((item) => (
        <AffiliatedPersonSalesActivityRowWidget
          key={item.id}
          item={item}
        />
      ))}
    </>
  );
};
