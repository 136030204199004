import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import { projectSalesInfoPaymentHistoryUpdateModalRepository } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/update-modal/repository';
import { useProjectSalesInfoPaymentHistoryState } from '@front/src/features/project-sales-info/features/collection/features/payment-history/widgets/useState';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';

export { useLogic as useProjectSalesInfoPaymentHistoryUpdateModalLogic };
const useLogic = () => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const itemId = useProjectSalesInfoPaymentHistoryState((state) => state.itemId);
  const { run: onUpdate } =
    projectSalesInfoPaymentHistoryUpdateModalRepository.usePaymentHistoryUpdate(id!);
  const { data: paymentHistoryDetail } =
    projectSalesInfoPaymentHistoryUpdateModalRepository.usePaymentHistoryDetailGet(itemId!);
  const { data: isPaidOptionList } =
    adminManageableVariableRepository.useListGet('영업관리-지급여부');
  const { data: consortiumCompanyList } =
    projectSalesInfoPaymentHistoryUpdateModalRepository.useConsortiumCompanyGet(id!);
  return {
    d: {
      consortiumCompanyList,
      isPaidOptionList,
      paymentHistoryDetail,
    },
    h: {
      onUpdate,
    },
  };
};
