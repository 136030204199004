import React from 'react';
import { Box } from '@mui/material';

interface Props {
  list: string[];
}

const ListInCell = ({ list }: Props) => (
  <Box
    sx={{
      display: 'grid',
      placeItems: 'center',
    }}
  >
    {list.length <= 0 ? (
      <Box>-</Box>
    ) : (
      <>
        {list.map((item) => (
          <Box key={item}>{item}</Box>
        ))}
      </>
    )}
  </Box>
);

export default ListInCell;
