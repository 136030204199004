import React, { memo, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { SelectChangeEvent } from '@mui/material';

import CampaignForm from '@front/ost_campaign/view/detail/CampaignForm';
import { campaignAction } from '@front/ost_campaign/action';
import type { RootState } from '@front/services/reducer';

interface Props {
  onChangeStatus: (e: SelectChangeEvent<unknown>) => void;
}

const CampaignUpdateFormService = ({ onChangeStatus }: Props) => {
  const dispatch = useDispatch();

  const {
    name,
    totalReward,
    manager,
    startDate,
    endDate,
    status,
    fixedResultSum,
    ostName,
    estimationStartDate,
    estimationEndDate,
    notice,
    isNoticeExposed,
    voteStartDate,
    voteEndDate,
    estimationCompletionRate,
  } = useSelector(
    (root: RootState) => ({
      name: root.campaign.detail?.name,
      totalReward: root.campaign.detail?.totalReward,
      startDate: root.campaign.detail?.startDate,
      endDate: root.campaign.detail?.endDate,
      manager: root.campaign.detail?.manager,
      status: root.campaign.detail?.status,
      fixedResultSum: root.campaign.detail?.fixedResultSum,
      ostName: root.campaign.detail?.ostName,
      estimationStartDate: root.campaign.detail?.estimationStartDate,
      estimationEndDate: root.campaign.detail?.estimationEndDate,
      voteStartDate: root.campaign.detail?.voteStartDate,
      voteEndDate: root.campaign.detail?.voteEndDate,
      notice: root.campaign.detail?.notice,
      isNoticeExposed: root.campaign.detail?.isNoticeExposed,
      estimationCompletionRate: root.campaign.detail?.estimationCompletionRate,
    }),
    shallowEqual
  );

  const updateName = useCallback(
    (params) => {
      dispatch(campaignAction.updateName(params));
    },
    [dispatch]
  );

  const updateStartDate = useCallback(
    (params) => {
      dispatch(campaignAction.updateStartDate(params));
    },
    [dispatch]
  );

  const updateEndDate = useCallback(
    (params) => {
      dispatch(campaignAction.updateEndDate(params));
    },
    [dispatch]
  );

  const updateEstimationStartDate = useCallback(
    (params) => {
      dispatch(campaignAction.updateEstimationStartDate(params));
    },
    [dispatch]
  );

  const updateEstimationEndDate = useCallback(
    (params) => {
      dispatch(campaignAction.updateEstimationEndDate(params));
    },
    [dispatch]
  );

  const updateVoteStartDate = useCallback(
    (params) => {
      dispatch(campaignAction.updateVoteStartDate(params));
    },
    [dispatch]
  );

  const updateVoteEndDate = useCallback(
    (params) => {
      dispatch(campaignAction.updateVoteEndDate(params));
    },
    [dispatch]
  );

  const updateTotalReward = useCallback(
    (params) => {
      dispatch(campaignAction.updateTotalReward(params));
    },
    [dispatch]
  );

  const updateManager = useCallback(
    (params) => {
      dispatch(campaignAction.updateManager(params));
    },
    [dispatch]
  );

  const updateEstimationCompletionRate = useCallback(
    (params) => {
      dispatch(campaignAction.updateEstimationCompletionRate(params));
    },
    [dispatch]
  );

  return (
    <CampaignForm
      name={name}
      totalReward={totalReward}
      status={status}
      manager={manager}
      startDate={startDate}
      endDate={endDate}
      estimationStartDate={estimationStartDate}
      estimationEndDate={estimationEndDate}
      voteStartDate={voteStartDate}
      voteEndDate={voteEndDate}
      fixedResultSum={fixedResultSum}
      estimationCompletionRate={estimationCompletionRate}
      updateName={updateName}
      updateStartDate={updateStartDate}
      updateEndDate={updateEndDate}
      updateTotalReward={updateTotalReward}
      updateManager={updateManager}
      onChangeStatus={onChangeStatus}
      ostName={ostName}
      updateEstimationStartDate={updateEstimationStartDate}
      updateEstimationEndDate={updateEstimationEndDate}
      isNoticeExposed={isNoticeExposed}
      notice={notice}
      updateVoteStartDate={updateVoteStartDate}
      updateVoteEndDate={updateVoteEndDate}
      updateEstimationCompletionRate={updateEstimationCompletionRate}
    />
  );
};

const CampaignFormService = memo(CampaignUpdateFormService);

export default CampaignFormService;
