import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useMyPageAuthorizationMenuLogic } from '@front/src/features/my-page/features/authorization/widgets/menu/provider/useLogic';
import { MyPageAuthorizationMenuContext } from '@front/src/features/my-page/features/authorization/widgets/menu/provider/context';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

export { Provider as MyPageAuthorizationMenuProvider };

interface Props extends SectionComponentProps {
  children: ReactNode;
}

const Provider = ({ children, menuId }: Props) => {
  const { list } = useMyPageAuthorizationMenuLogic(menuId);
  const value = useMemo(
    () => ({
      list,
    }),
    [list]
  );
  return (
    <MyPageAuthorizationMenuContext.Provider value={value}>
      {children}
    </MyPageAuthorizationMenuContext.Provider>
  );
};
