// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XrPKEmthISdZ0N12I4nc{display:flex;width:300px;gap:10px}.eDCQcYKFzs0o0pvv5IWE{display:flex;flex-direction:column;gap:15px;align-items:center;width:100%}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/features/create-modal/components/create-modal.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,kBAAA,CACA,UAAA","sourcesContent":[".btn__group {\n  display: flex;\n  width: 300px;\n  gap: 10px;\n}\n\n.modal__group {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n  align-items: center;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn__group": `XrPKEmthISdZ0N12I4nc`,
	"modal__group": `eDCQcYKFzs0o0pvv5IWE`
};
export default ___CSS_LOADER_EXPORT___;
