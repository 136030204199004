import type { ReactNode } from 'react';
import React, { useCallback } from 'react';
import { Box, TableBody, TableContainer, TableRow } from '@mui/material';
import Button from '@front/layouts/Button';
import { Table, OldTd } from '@front/layouts/Table';
import { FormProvider, useForm } from 'react-hook-form';
import { useAffiliatedPersonAppBarState } from '@front/src/features/affiliated-person/features/app-bar/useState';
import { Th } from '@front/src/components/layout/table/th';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { useShallow } from 'zustand/react/shallow';

export { ConfirmModalBody as AffiliatedPersonConfirmModalBody };

interface Props {
  widget: ReactNode;
}

const getPersonNameForFormValue = (personName: string, personNumber: number) => {
  if (personNumber === 0) {
    return personName;
  }
  return personName + ` ${(personNumber + 1).toString().padStart(2, '0')}`;
};

const getCompanyForFormValue = (company) => {
  const copy = { ...company };
  if (copy.id === null) {
    copy.name = 'None';
  }
  return copy;
};

const ConfirmModalBody = ({ widget }: Props) => {
  const { createParameter, setIsConfirmModalOpen, personNumber } = useAffiliatedPersonAppBarState(
    useShallow((state) => ({
      createParameter: state.createParameter,
      setIsConfirmModalOpen: state.setIsConfirmModalOpen,
      personNumber: state.personNumber,
    }))
  );
  const methods = useForm({
    defaultValues: {
      personName: getPersonNameForFormValue(createParameter.personName, personNumber),
      affiliatedCompany: getCompanyForFormValue(createParameter.affiliatedCompany),
    },
  });
  const handleClose = useCallback(() => {
    setIsConfirmModalOpen(false);
  }, [setIsConfirmModalOpen]);
  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          width: '100%',
        }}
      >
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <Th width="100px">이름</Th>
                <OldTd>
                  <HookFormInput
                    name="personName"
                    readOnly
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th width="100px">소속회사</Th>
                <OldTd>
                  <HookFormInput
                    name="affiliatedCompany.name"
                    readOnly
                  />
                </OldTd>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            border: '1px solid black',
            borderRadius: '16px',
            fontSize: '12px',
          }}
        >
          위와 같이 인물을 등록 하시겠습니까?
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '12px',
          }}
        >
          <Button
            shape="basic2"
            onClick={handleClose}
          >
            취소
          </Button>
          {widget}
        </Box>
      </Box>
    </FormProvider>
  );
};
