import { AffiliatedCompanyProjectQuery } from '@front/src/features/affiliated-company/features/project/query/query';
import type { AffiliatedCompanyProjectListGetParameter } from '@front/src/features/affiliated-company/features/project/types/parameter';

export { Repository as AffiliatedCompanyProjectRepository };

const Repository = {
  useListGet: (params: AffiliatedCompanyProjectListGetParameter, menuId) => {
    const { data, remove } = AffiliatedCompanyProjectQuery.useListGet(params, menuId);
    return {
      data,
      remove,
    };
  },
};
