import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import { projectSalesInfoActivityMutation } from '@front/src/features/project-sales-info/features/activity/query/mutation';
import { projectSalesInfoActivityQuery } from '@front/src/features/project-sales-info/features/activity/query/query';
import type {
  SalesInfoActivityAffiliatesUpdateParams,
  SalesInfoActivityUpdateParams,
  SalesInfoActivityUserUpdateParams,
} from '@front/src/features/project-sales-info/features/activity/types/parameters';
import type { IdListDeleteParameter } from '@front/src/types/parameter';

export const projectSalesActivityRepository = {
  useActivityListGet: (id: number) => {
    const { data, remove } = projectSalesInfoActivityQuery.useActivityListGet(id);
    return { data, remove };
  },
  useActivityUpdate: (id: number) => {
    const { mutate } = projectSalesInfoActivityMutation.useActivityUpdate(id);
    const callback = getNoOpCallback();
    return {
      run: (params: SalesInfoActivityUpdateParams) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useUsersUpdate: (id: number) => {
    const { mutate } = projectSalesInfoActivityMutation.useUsersUpdate(id);
    const callback = getNoOpCallback();
    return {
      run: (params: SalesInfoActivityUserUpdateParams) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useAffiliatesUpdate: (id: number) => {
    const { mutate } = projectSalesInfoActivityMutation.useAffiliatesUpdate(id);
    const callback = getNoOpCallback();
    return {
      run: (params: SalesInfoActivityAffiliatesUpdateParams) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useActivityCreate: (id: number) => {
    const { mutate } = projectSalesInfoActivityMutation.useActivityCreate(id);
    const callback = getNoOpCallback();
    return {
      run: (id) => {
        mutate(id, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useUsersCreate: (id: number) => {
    const { mutate } = projectSalesInfoActivityMutation.useUsersCreate(id);
    const callback = getNoOpCallback();
    return {
      run: (activityId: number) => {
        mutate(activityId, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useAffiliatesCreate: (id: number) => {
    const { mutate } = projectSalesInfoActivityMutation.useAffiliatesCreate(id);
    const callback = getNoOpCallback();
    return {
      run: (userId: number) => {
        mutate(userId, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useActivityDelete: (id: number) => {
    const { mutate } = projectSalesInfoActivityMutation.useActivityDelete(id);
    const callback = getNoOpCallback();
    return {
      run: (params: IdListDeleteParameter) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useUsersDelete: (id: number) => {
    const { mutate } = projectSalesInfoActivityMutation.useUsersDelete(id);
    const callback = getNoOpCallback();
    return {
      run: (userId: number) => {
        mutate(userId, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useAffiliatesDelete: (id: number) => {
    const { mutate } = projectSalesInfoActivityMutation.useAffiliatesDelete(id);
    const callback = getNoOpCallback();
    return {
      run: (affiliatesId: number) => {
        mutate(affiliatesId, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useCategoryOptionListGet: (key: string) => {
    const { data } = projectSalesInfoActivityQuery.useCategoryOptionListGet(key);
    return {
      data,
    };
  },
};
