import React, { useContext } from 'react';
import { ProjectSalesInfoContractEarningsItemCreateModalWidget } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/widgets/widget';
import { useContractEarningsItemState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/useState';
import { ProjectSalesInfoContractEarningsItemContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/context';

export { DetailCreateModalWrapper as ProjectSalesInfoContractEarningsItemDetailCreateModalWrapper };

const DetailCreateModalWrapper = () => {
  const { isDetailCreateModalOpen, itemId } = useContractEarningsItemState((state) => ({
    isDetailCreateModalOpen: state.isDetailCreateModalOpen,
    itemId: state.itemId,
  }));
  const { itemId: contextItemId } = useContext(ProjectSalesInfoContractEarningsItemContext);
  return (
    <>
      {itemId === contextItemId && isDetailCreateModalOpen && (
        <ProjectSalesInfoContractEarningsItemCreateModalWidget />
      )}
    </>
  );
};
