import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workReviewerMutation from '@front/src/features/work/features/work/features/reviewer/query/mutation';

const useWorkReviewerSeqDown = (menuId) => {
  const callback = getNoOpCallback();
  const { mutate, isLoading } = workReviewerMutation.useSeqDown(menuId);
  return {
    run: (id: number) => mutate(id, callback),
    setCallback: getSetCallbackFunc(callback),
    isLoading,
  };
};

export default useWorkReviewerSeqDown;
