import React, { useCallback, useEffect, useMemo } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { PersonalSettingsModalUISettingsHeaderSettingsTableContentNewNameCheckDuplicateButton } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/components/CheckDuplicateButton';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import { usePersonalSettingsModalUISettingsHeaderSettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/useState';
import { useShallow } from 'zustand/react/shallow';

export { NewName as PersonalSettingsModalUISettingsHeaderSettingsTableContentNewName };

interface Props {
  index: number;
}

const NewName = ({ index }: Readonly<Props>) => {
  const { control, watch } = useFormContext();
  const { editOpenIdList } = usePersonalSettingsModalUISettingsHeaderSettingsState(
    useShallow((state) => ({
      editOpenIdList: state.editOpenIdList,
    }))
  );
  const open = useMemo(() => {
    const id = watch(`list.${index}.id`);
    return editOpenIdList.includes(id);
  }, [editOpenIdList, watch]);
  const { newName, isDuplicate } = useWatch({
    name: `list.${index}`,
    control,
  });
  const {
    field: { onChange: onIsPassedChange },
  } = useController({ name: `list.${index}.isPassed`, control });
  const {
    field: { onChange: onIsDuplicateChange },
  } = useController({ name: `list.${index}.isDuplicate`, control });
  const handleInputChange = useCallback(() => {
    onIsDuplicateChange(false);
    onIsPassedChange(false);
  }, [onIsPassedChange, onIsDuplicateChange]);

  useEffect(() => {
    if (newName === '') {
      onIsPassedChange(true);
    }
  }, [newName, onIsPassedChange]);
  const sx = useMemo(
    () => ({
      color: isDuplicate ? ColorPalette._eb4c4c : 'inherit',
    }),
    [isDuplicate]
  );
  if (open) {
    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '3fr 1fr',
          gap: '4px',
        }}
      >
        <HookFormInput
          name={`list.${index}.newName`}
          placeholder="변경 입력 후 중복 확인"
          onPressChange={handleInputChange}
          sx={sx}
        />
        <Box>
          <PersonalSettingsModalUISettingsHeaderSettingsTableContentNewNameCheckDuplicateButton
            index={index}
          />
        </Box>
      </Box>
    );
  }
  return <>{newName ?? '-'}</>;
};
