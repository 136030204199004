import React, { useEffect, useState } from 'react';
import ImageModal from '@front/components/FileUploadWithPreview/ImageModal';
import type { AttachFileVO } from '@front/ost_proposal/domain';
import Button from '@front/layouts/Button';
import { Box } from '@mui/material';

interface Props {
  item: AttachFileVO;
}

const ImageModalService = ({ item }: Props) => {
  const [imageSrc, setImageSrc] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!item.file.id) {
      setImageSrc('');
      return;
    }

    if (item.file.id) {
      setImageSrc(`/api/file-item/${item.file.id}`);
    }
  }, [item]);

  return (
    <Box>
      {isOpen && (
        <ImageModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          imageSrc={imageSrc}
        />
      )}
      <Box
        onClick={() => setIsOpen(true)}
        sx={{ marginLeft: '10px' }}
      >
        <Button shape={'basic1'}>미리보기</Button>
      </Box>
    </Box>
  );
};

export default ImageModalService;
