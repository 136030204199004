import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalMutation } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/query/mutation';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalQuery } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/query/query';
import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonSearchParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/types/parameter';

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalRepository = {
  usePersonListGet: (
    params: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonSearchParameter
  ) => {
    const { data, isLoading } =
      projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalQuery.usePersonListGet(
        params
      );
    return {
      data,
      isLoading,
    };
  },
  useCreate: () => {
    const { mutate } =
      projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalMutation.useCreate();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
