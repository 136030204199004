import React, { useContext } from 'react';
import { AccountingUploadSalesInfoGetYearContext } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/get-year/provider/context';
import { AccountingUploadSalesInfoGetYearTableRow } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/get-year/components/table-row';
import { getTypeForAccountingUploadSalesInfoFormByList } from '@front/src/features/accounting/features/upload/features/sales-info/features/utils';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { useAccountingUploadSalesInfoState } from '@front/src/features/accounting/features/upload/features/sales-info/useState';
import { useShallow } from 'zustand/react/shallow';

export { TableBody as AccountingUploadSalesInfoGetYearTableBody };

const TableBody = () => {
  const { list } = useContext(AccountingUploadSalesInfoGetYearContext);
  const { year } = useAccountingUploadSalesInfoState(
    useShallow((state) => ({
      year: state.year,
    }))
  );
  if (typeof year === 'undefined' || list?.length === 0) {
    return <NoResult colSpan={17} />;
  }
  return (
    <>
      {getTypeForAccountingUploadSalesInfoFormByList.map((type) => {
        const listByType = list?.filter((l) => l.type === type);
        return (
          <AccountingUploadSalesInfoGetYearTableRow
            key={type}
            type={type}
            listByType={listByType}
          />
        );
      })}
    </>
  );
};
