import React from 'react';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import useModalOpen from '@front/src/hooks/useModalOpen';
import UiBuilderHistoryModal from '@front/src/components/ui-builder/table/HistoryModal';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends Pick<SectionComponentProps, 'dataId' | 'sectionId'> {}

export default function WorkWorkScheduleButtonGroup({ dataId, sectionId }: Readonly<Props>) {
  const { TitleRightWrap } = SectionWrapperUI;
  const { onOpen, open, onClose } = useModalOpen();
  const setLeftDrawerState = useGlobalNavBarState((state) => state.setLeftDrawerState);
  const setNoteId = useGlobalNavBarState((state) => state.setNoteId);
  const onOpenLeftOverlay = () => {
    sessionStorage.setItem('dataId', dataId ? dataId.toString() : '');
    setNoteId(0);
    setLeftDrawerState('approval');
  };
  return (
    <TitleRightWrap>
      <ButtonBasicUI
        shape="primary"
        size="medium"
        onClick={onOpenLeftOverlay}
      >
        일정 변경 신청
      </ButtonBasicUI>
      <UiBuilderHistoryModal
        sectionId={sectionId}
        onClose={onClose}
        open={open}
      />
      <ButtonBasicUI
        shape="ghost"
        size="medium"
        onClick={onOpen}
      >
        변경 이력
      </ButtonBasicUI>
    </TitleRightWrap>
  );
}
