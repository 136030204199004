import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import type { ProjectSalesInfoBidResultParams } from '@front/src/features/project-sales-info/features/bid/features/bid-result/types/parameter';
import type { ProjectSalesInfoBidResultView } from '@front/src/features/project-sales-info/features/bid/features/bid-result/types/view';

interface State {
  d: {
    bidResultDetail?: ProjectSalesInfoBidResultView;
  };
  h: {
    onUpdate: (params: ProjectSalesInfoBidResultParams) => void;
  };
  isLoading: boolean;
}

export { Context as ProjectSalesInfoBidResultUpdateModalContext };

const Context = createContext<State>({
  d: {},
  h: {
    onUpdate: noOp,
  },
  isLoading: false,
});
