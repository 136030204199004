import type { ReactNode } from 'react';
import React from 'react';

import { ArticleItemView } from '@front/src/components/layout/article-item-view';

interface Props {
  buttonsNode?: ReactNode;
  title?: ReactNode;
  children: ReactNode;
}

export const ArticleItem = ({ title, children, buttonsNode }: Props) => {
  const { Header, Title, Layout, Button, Body } = ArticleItemView;

  return (
    <Layout>
      <Header>
        {title && <Title>{title}</Title>}
        {buttonsNode && <Button>{buttonsNode}</Button>}
      </Header>
      <Body>{children}</Body>
    </Layout>
  );
};
