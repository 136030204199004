export const affiliatedPersonAppBarCreateModalTableHeaderNameList = [
  {
    name: '업체구분',
    width: '100px',
  },
  {
    name: '업체명',
    width: '220px',
  },
  {
    name: '이름',
    width: '80px',
  },
  {
    name: '직위',
    width: '80px',
  },
  {
    name: '핸드폰번호',
    width: '160px',
  },
];

export const defaultAffiliatedPersonCreateForm = {
  personName: '',
  affiliatedCompany: {
    id: null,
    name: '',
    category: null,
    code: null,
  },
};
