import { projectSalesInfoCollectionSaveHistoryRepository } from '@front/src/features/project-sales-info/features/collection/features/save-history/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';

export { useLogic as useProjectSalesInfoCollectionSaveHistoryLogic };
const useLogic = () => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { data: saveHistoryList, isLoading } =
    projectSalesInfoCollectionSaveHistoryRepository.useSaveHistoryGet(id!);
  const { run: onUpdate } = projectSalesInfoCollectionSaveHistoryRepository.useSaveHistoryUpdate(
    id!
  );
  return {
    h: { onUpdate },
    d: { saveHistoryList },
    isLoading,
  };
};
