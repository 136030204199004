import React, { useContext, useRef } from 'react';
import { MobileNotificationListContext } from '@front/src/features/mobile-notification/widgets/list/provider/context';
import { MobileNotificationListItemWidget } from '@front/src/features/mobile-notification/widgets/list/widgets/item/widget';
import { Card, Typography } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type,
} from 'react-swipeable-list';
import DeleteIcon from '@mui/icons-material/Delete';
import PushPinIcon from '@mui/icons-material/PushPin';
import 'react-swipeable-list/dist/styles.css';
import classes from '@front/src/features/mobile-notification/widgets/list/components/mobile-notification-list.module.scss';
import EastIcon from '@mui/icons-material/East';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useMobileNotificationState } from '@front/src/features/mobile-notification/useState';
import { useUserMobileNotificationState } from '@front/user_notification/useState';
import { useNavigate } from 'react-router-dom';
import type { UserNotificationView } from '@front/src/features/mobile-notification/types/view';
import { isMobileDevice } from '@front/util/PwaUtil';

export const MobileNotificationList = () => {
  const { mobileNotificationList, onDelete, onToggle, onPinUpdate } = useContext(
    MobileNotificationListContext
  );
  const isEmpty = mobileNotificationList?.length === 0;
  const openedIdList = useMobileNotificationState((state) => state.openedIdList);
  const setIsMobileNotificationModalOpen = useUserMobileNotificationState(
    (state) => state.setIsMobileNotificationModalOpen
  );
  const navigate = useNavigate();
  const leadingRef = useRef<HTMLDivElement[] | null[]>(new Array(mobileNotificationList?.length));
  const trailingRef = useRef<HTMLDivElement[] | null[]>(new Array(mobileNotificationList?.length));
  const projectCodeClickHandler = (idx: number) => {
    if (!mobileNotificationList?.[idx].forwardUrl) return;
    onToggle({
      id: mobileNotificationList?.[idx].id,
      isRead: true,
    });
    navigate(`${mobileNotificationList?.[idx].forwardUrl}`);
    setIsMobileNotificationModalOpen();
    localStorage.setItem(
      'tabList',
      JSON.stringify(mobileNotificationList?.[idx].authorizedTabList)
    );
  };

  const onReadToggle = (notification: UserNotificationView) => {
    onToggle({
      id: notification.id,
      isRead: !notification.readAt,
    });
  };
  const leadingActions = (idx: number) => (
    <LeadingActions>
      <SwipeAction onClick={() => onDelete(mobileNotificationList![idx].id)}>
        <Card
          className={classes.swipe__action__card}
          ref={(el) => (leadingRef.current[idx] = el)}
          style={{ backgroundColor: ColorPalette._eb4c4c }}
        >
          <DeleteIcon fontSize="large" />
        </Card>
      </SwipeAction>
      <SwipeAction onClick={() => onReadToggle(mobileNotificationList![idx])}>
        <Card
          className={classes.swipe__action__card}
          style={{
            backgroundColor: mobileNotificationList![idx].readAt
              ? ColorPalette._b2b4b7
              : ColorPalette._DAE8FC,
          }}
        >
          <CheckCircleOutlineIcon fontSize="large" />
        </Card>
      </SwipeAction>
    </LeadingActions>
  );
  const trailingActions = (idx: number) => (
    <TrailingActions>
      <SwipeAction
        onClick={() =>
          onPinUpdate({
            id: mobileNotificationList![idx].id,
            isImportant: !mobileNotificationList![idx].isImportant,
          })
        }
      >
        <Card
          className={classes.swipe__action__card}
          style={{
            backgroundColor: mobileNotificationList![idx].isImportant
              ? ColorPalette._b2b4b7
              : ColorPalette._386dd6,
          }}
          ref={(el) => (trailingRef.current[idx] = el)}
        >
          <PushPinIcon fontSize="large" />
        </Card>
      </SwipeAction>
      <SwipeAction onClick={() => projectCodeClickHandler(idx)}>
        <Card
          className={classes.swipe__action__card}
          style={{
            backgroundColor: mobileNotificationList![idx].forwardUrl
              ? ColorPalette._ffb72b
              : ColorPalette._DAE8FC,
          }}
        >
          <EastIcon fontSize="large" />
        </Card>
      </SwipeAction>
    </TrailingActions>
  );
  return (
    <div className={classes.container}>
      {isEmpty && (
        <Typography sx={{ color: ColorPalette._f1f5fc, textAlign: 'center' }}>
          알림이 없습니다.
        </Typography>
      )}
      <SwipeableList type={Type.IOS}>
        {mobileNotificationList?.map((notification, idx) => (
          <SwipeableListItem
            leadingActions={leadingActions(idx)}
            trailingActions={trailingActions(idx)}
            key={notification.id}
            blockSwipe={openedIdList.includes(notification.id) || !isMobileDevice()}
          >
            <MobileNotificationListItemWidget
              refs={{ leadingRef, trailingRef }}
              item={notification}
              idx={idx}
            />
          </SwipeableListItem>
        ))}
      </SwipeableList>
    </div>
  );
};
