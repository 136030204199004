import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';

import useDialog from '@front/dialog/hook';
import Button from '@front/layouts/Button';
import type { RootState } from '@front/services/reducer';
import type { ProposalContentId } from '@front/ost_proposal/domain';
import { ProposalContentType, ProposalStatus } from '@front/ost_proposal/domain';
import type { SnackbarSeverityType } from '@front/components/Snackbar/action';
import type { ProposalContentChangeParameter } from '@front/ost_proposal/parameter';
import { ContentTextForm } from '@front/ost_proposal/view/detail/content/ContentTextForm';
import { ContentImageTextForm } from '@front/ost_proposal/view/detail/content/ContentImageTextForm';
import { ContentBeforeComparisionForm } from '@front/ost_proposal/view/detail/content/ContentBeforeComparisionForm';
import { FilePaths } from '@front/common/filePath';

interface Props {
  deleteContent: (id: ProposalContentId) => void;
  updateContent: (params: ProposalContentChangeParameter) => void;
  openSnackbar: (message: string, severity?: SnackbarSeverityType) => void;
  isContributor: boolean;
}

function ContentList({ deleteContent, updateContent, openSnackbar, isContributor }: Props) {
  const { confirm } = useDialog();
  const [contentList] = useSelector((root: RootState) => [root.proposal.contentList], shallowEqual);
  const { status, proposalId } = useSelector(
    (root: RootState) => ({
      status: root.proposal.detail!.status,
      proposalId: root.proposal.detail!.id,
    }),
    shallowEqual
  );

  const onClickDelete = useCallback(
    (id) => () => {
      confirm({
        children: '해당 내용을 삭제하시겠습니까?',
        confirmText: '확인',
        afterConfirm: () => {
          if (contentList.length <= 1) {
            openSnackbar('최소 한 개의 내용을 입력해주세요');
            return;
          }
          deleteContent(id);
        },
      });
    },
    [confirm, deleteContent, openSnackbar, contentList]
  );

  const readOnly = status !== ProposalStatus.WRITING || !isContributor;

  return (
    <>
      {contentList.map((item) => (
        <Box key={`${item.id}`}>
          {item.type === ProposalContentType.BEFORE_COMPARISON_FORM && (
            <ContentBeforeComparisionForm
              item={item}
              updateContent={updateContent}
              openSnackbar={openSnackbar}
              isContributor={isContributor}
              readOnly={readOnly}
            />
          )}
          {item.type === ProposalContentType.IMAGE_TEXT_FORM && (
            <ContentImageTextForm
              item={item}
              updateContent={updateContent}
              openSnackbar={openSnackbar}
              isContributor={isContributor}
              readOnly={readOnly}
            />
          )}
          {item.type === ProposalContentType.TEXT_FORM && (
            <ContentTextForm
              item={item}
              updateContent={updateContent}
              readOnly={readOnly}
              dataId={proposalId}
              filePath={FilePaths.PROPOSAL}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              margin: '5px 0',
            }}
          >
            {!readOnly && (
              <Button
                shape="basic3"
                onClick={onClickDelete(item.id)}
              >
                삭제
              </Button>
            )}
          </Box>
        </Box>
      ))}
    </>
  );
}

export default ContentList;
