import type { ReactNode } from 'react';
import React from 'react';
import { Box } from '@mui/material';

export { Header as AccountingSettingsContentLayoutHeader };

interface Props {
  leftComponent?: ReactNode;
  rightComponent?: ReactNode;
  title?: string;
}

const Header = ({ rightComponent, title }: Props) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    }}
  >
    {title && (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '16px',
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
        }}
      >
        {title}
      </Box>
    )}
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '12px',
      }}
    >
      {rightComponent}
    </Box>
  </Box>
);
