import React from 'react';
import { Box } from '@mui/material';
import CircularProgress from '@front/components/CircularProgress';

const LoadingBar = () => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <CircularProgress
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    />
  </Box>
);

export default LoadingBar;
