import type { ReactNode } from 'react';
import React from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table as MuiTable } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';

export { Table as AccountingUploadSalesInfoExpectModalTable };

interface Props {
  children: ReactNode;
}

const Table = ({ children }: Props) => (
  <TableContainer>
    <MuiTable>
      <TableHead>
        <TableRow>
          <Th width="88px">항목</Th>
          <Th width="60px">내용</Th>
          <Th width="160px">금액</Th>
          <Th width="80px">관련 프로젝트 (건)</Th>
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </MuiTable>
  </TableContainer>
);
