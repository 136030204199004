import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workReferenceMutation from '@front/src/features/work/features/work/features/reference/query/mutation';

const useWorkReferenceSeqUp = (menuId) => {
  const callback = getNoOpCallback();
  const { mutate, isLoading } = workReferenceMutation.useSeqUp(menuId);
  return {
    run: (id: number) => mutate(id, callback),
    setCallback: getSetCallbackFunc(callback),
    isLoading,
  };
};

export default useWorkReferenceSeqUp;
