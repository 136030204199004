import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';

import React, { useEffect, useMemo } from 'react';
import { ProjectSalesInfoBidInfoTable } from '@front/src/features/project-sales-info/features/bid/features/bid-information/components/bid-info-table';
import { ProjectSalesInfoBidInfoBtn } from '@front/src/features/project-sales-info/features/bid/features/bid-information/components/bid-info-btn';
import { useProjectSalesInfoBidInfoLogic } from '@front/src/features/project-sales-info/features/bid/features/bid-information/widgets/useLogic';
import { ProjectSalesInfoBidInfoContext } from '@front/src/features/project-sales-info/features/bid/features/bid-information/widgets/context';
import { ProjectSalesInfoBidInfoUpdateModalWrapper } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/update-modal/components/modal-wrapper';
import { ProjectSalesInfoBidInfoCreateModalWrapper } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/components/modal-wrapper';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoBidInfoState } from '@front/src/features/project-sales-info/features/bid/features/bid-information/widgets/useState';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoBidInfoWidget = ({ readOnly, name }: Readonly<Props>) => {
  const { Header, Layout, Body } = AddDeleteTable;
  const {
    h: { onDelete },
    d: { bidInfoList },
    isLoading,
  } = useProjectSalesInfoBidInfoLogic();

  const value = useMemo(
    () => ({
      h: { onDelete },
      d: { bidInfoList },
      isLoading,
    }),
    [onDelete, bidInfoList, isLoading]
  );
  const { setReadOnly } = useProjectSalesInfoBidInfoState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <ProjectSalesInfoBidInfoContext.Provider value={value}>
      <ProjectSalesInfoBidInfoUpdateModalWrapper />
      <ProjectSalesInfoBidInfoCreateModalWrapper />
      <Layout>
        <Header title={name}>
          <ProjectSalesInfoBidInfoBtn />
        </Header>
        <Body overflow={true}>
          <ProjectSalesInfoBidInfoTable />
        </Body>
      </Layout>
    </ProjectSalesInfoBidInfoContext.Provider>
  );
};

export default ProjectSalesInfoBidInfoWidget;
