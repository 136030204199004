import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { AccountingSettingsManagementAccountingAccountHeaderGetUpdatedAtContext } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/header/widgets/get-updated-at/provider/context';
import { convertDateToStringFormat, DATE_TIME_FORMAT, getValueIfExist } from '@front/src/utils';
import TextBox from '@front/layouts/Text';

export { Text as AccountingSettingsManagementAccountingAccountHeaderText };

const Text = () => {
  const { detail } = useContext(
    AccountingSettingsManagementAccountingAccountHeaderGetUpdatedAtContext
  );
  if (typeof detail === 'undefined') {
    return <Box>E계정 데이터 없음</Box>;
  }
  return (
    <TextBox variant="body1">
      E계정 데이터 업데이트 일시: {convertDateToStringFormat(detail?.createdAt, DATE_TIME_FORMAT)} (
      {getValueIfExist(detail?.writer.name)})
    </TextBox>
  );
};
