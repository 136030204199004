import { accountingManagementAccountingAccountTableRepository } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/repository/repository';

export { useLogic as useAccountingSettingsManagementAccountingAccountTableHeaderLogic };

const useLogic = () => {
  const { data: detail, isLoading } =
    accountingManagementAccountingAccountTableRepository.useCountGet();
  return {
    detail,
    isLoading,
  };
};
