// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Bmwp1zqefqeoz78wqLTH{width:100%}`, "",{"version":3,"sources":["webpack://./front/src/components/layout/drawer-app-bar/button-wrapper.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA","sourcesContent":[".container {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Bmwp1zqefqeoz78wqLTH`
};
export default ___CSS_LOADER_EXPORT___;
