import type { ProjectDivisionParams } from '@front/src/features/project-sales-info/features/project-division/types/parameter';
import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import type { ProjectDivisionView } from '@front/src/features/project-sales-info/features/project-division/types/view';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';

interface State {
  d: {
    projectDivisionData?: ProjectDivisionView;
    code?: number;
    businessTypeOptionList?: VariableInformationView[];
    bidSortOptionList?: VariableInformationView[];
  };
  h: {
    onProjectDivisionUpdate: (params: ProjectDivisionParams) => void;
    onCodeUpdate: () => void;
  };
  isLoading: boolean;
  id?: number;
}

export { Context as ProjectSalesInfoProjectDivisionContext };

const Context = createContext<State>({
  d: {},
  h: { onProjectDivisionUpdate: noOp, onCodeUpdate: noOp },
  isLoading: false,
});
