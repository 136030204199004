import React, { useEffect } from 'react';
import { AccountingSettingsTexAccountingAccountGetDateWidget } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/get-date/widget';
import { AccountingSettingsTexAccountingAccountGetCountWidget } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/get-count/widget';
import { AccountingSettingsTexAccountingAccountListWidget } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/list/widget';
import { accountingSettingsManagementAccountingTableLayout } from '@front/src/features/accounting/features/settings/components/layouts/table';
import { accountingSettingsContentLayout } from '@front/src/features/accounting/features/settings/components/layouts/content';
import { AccountingSettingsTexAccountingAccountUploadWidget } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/upload/widget';
import { useGetReadOnly } from '@front/src/hooks/useGetReadOnly';
import { useAccountingSettingsTaxAccountingAccountState } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/useState';
import { useShallow } from 'zustand/react/shallow';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AccountingSettingsTaxAccountingAccountFeature = ({ name }: Readonly<Props>) => {
  const { Layout, Header, Body } = accountingSettingsContentLayout;
  const { Table, TableHeader, TableBody } = accountingSettingsManagementAccountingTableLayout;
  const { readOnly } = useGetReadOnly();
  const { setReadOnly } = useAccountingSettingsTaxAccountingAccountState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <Layout>
      <Header
        title={name}
        rightComponent={
          <>
            <AccountingSettingsTexAccountingAccountGetDateWidget />
            <AccountingSettingsTexAccountingAccountUploadWidget />
          </>
        }
      />
      <Body>
        <Table>
          <TableHeader>
            <AccountingSettingsTexAccountingAccountGetCountWidget />
          </TableHeader>
          <TableBody>
            <AccountingSettingsTexAccountingAccountListWidget />
          </TableBody>
        </Table>
      </Body>
    </Layout>
  );
};

export default AccountingSettingsTaxAccountingAccountFeature;
