import React from 'react';
import { controlPanelTableCellWidth, getLeftListFromHeaderList } from '@front/src/utils';
import { commonRepository } from '@front/src/api/repository';
import type { ControlPanelItem } from '@front/src/types/domain';
import UIBuilderTableCell from '@front/src/components/ui-builder/table/Cell';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import ContextMenuUI from '@front/src/components/components-with-design/compound/ContextMenuUI';
import type { NoteMenuIdView } from '@front/src/features/note/types/view';
import useNoteStore from '@front/src/features/note/useState';
import { useShallow } from 'zustand/react/shallow';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import { useCustomDialog } from '@front/src/features/dialog';
import { ColorPalette } from '@front/assets/theme';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';

interface RowProps extends CellComponentProps {
  itemList: ControlPanelItem[];
}

export default function UiBuilderTableRow({
  readOnly,
  sx = {},
  item,
  sectionId,
  itemList,
  menuId,
  ...rest
}: Readonly<RowProps>) {
  const { data: headerList, stickyIdx } = commonRepository.useGetHeaderList(sectionId, menuId);
  const leftList = getLeftListFromHeaderList(headerList, readOnly);
  const tableCellList = headerList?.map((h, index) => {
    const left = stickyIdx !== -1 && index <= stickyIdx ? leftList?.[index] : undefined;
    return (
      <UIBuilderTableCell
        key={`${item.id} - ${h.id}`}
        header={h}
        item={item}
        left={left}
        readOnly={readOnly}
        sectionId={sectionId}
        menuId={menuId}
        {...rest}
      />
    );
  });
  const left = stickyIdx === -1 ? undefined : 0;
  const renderControlPanel = () => {
    if (readOnly) return <></>;
    return (
      <TableCellUI
        width={`${controlPanelTableCellWidth}px`}
        // left={left}
      >
        <ContextMenuUI itemList={itemList} />
      </TableCellUI>
    );
  };
  const { openNoteOverlay } = useNoteCountClick({
    rowId: item.id,
    sectionId,
    readOnly,
  });
  const { rowId, sectionId: noteSectionId } = useNoteStore(
    useShallow((state) => ({
      rowId: state.rowId,
      sectionId: state.sectionId,
    }))
  );
  const renderNote = () => (
    <TableCellUI
      // width={`${controlPanelTableCellWidth}px`}
      onClick={openNoteOverlay}
      sx={{
        maxWidth: `${controlPanelTableCellWidth}px`,
        backgroundColor:
          sectionId === noteSectionId && rowId === item.id ? ColorPalette._d2e7fa : 'inherit',
        textDecoration: readOnly ? 'none' : 'underline',
        cursor: 'pointer',
      }}
    >
      {item.noteCount}
    </TableCellUI>
  );
  return (
    <TableRowUI
      sx={{
        ...sx,
      }}
    >
      {renderControlPanel()}
      {renderNote()}
      {tableCellList}
      {/* 컬럼에 명시적으로 설정한 너비가 정상적으로 반영되기 위한 여백 컬럼(헤더/바디 각각 추가) */}
      <TableCellUI
        sx={{width:'auto'}}
      />
    </TableRowUI>
  );
}

interface NoteCountClickProps {
  rowId: number;
  sectionId: number;
  readOnly: boolean;
}

function useNoteCountClick({ rowId, sectionId, readOnly }: Readonly<NoteCountClickProps>) {
  const { alert } = useCustomDialog();
  const setIsRightDrawerOpen = useGlobalNavBarState((state) => state.setIsRightDrawerOpen);
  const { setRowId, setSectionId } = useNoteStore(
    useShallow((state) => ({
      setRowId: state.setRowId,
      setSectionId: state.setSectionId,
    }))
  );
  const openNoteOverlay = () => {
    if (readOnly) return;
    const noteAuth: NoteMenuIdView = JSON.parse(sessionStorage.getItem('noteAuth') ?? '{}');
    if (!noteAuth.noteId || noteAuth.type === 'X') {
      alert({
        title: '권한 오류',
        children: [{ value: '접근 권한이 없습니다.' }],
      });
      return;
    }
    setIsRightDrawerOpen(true);
    setRowId(rowId);
    setSectionId(sectionId);
  };
  return {
    openNoteOverlay,
  };
}
