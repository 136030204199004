export const spliceAndPushIfExist = (idList: number[], id: number) => {
  const index = idList.findIndex((item) => item === id);
  const updatedIdList = [...idList];
  if (index !== -1) {
    updatedIdList.splice(index, 1);
  } else {
    updatedIdList.push(id);
  }
  return updatedIdList;
};
