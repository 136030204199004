import React, { useCallback, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Input from '@front/layouts/Input';
import type { RootState } from '@front/services/reducer';
import { ostAction } from '@front/ost/action';

export const TrimmedMeanTopRateInput = () => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLInputElement>(null);

  const { trimmedMeanTopRate } = useSelector(
    (root: RootState) => ({
      trimmedMeanTopRate: root.ost.detail?.trimmedMeanTopRate,
    }),
    shallowEqual
  );

  const onBlur = useCallback(
    (e) => {
      let value = e.target.value;
      if (!value) {
        dispatch(ostAction.updateTrimmedMeanTopRate({ trimmedMeanTopRate: undefined }));
        return;
      }
      value = +value;
      if (value < 0) {
        if (!ref.current || typeof trimmedMeanTopRate === 'undefined') {
          return;
        }
        ref.current.value = trimmedMeanTopRate.toString();
        return;
      }
      dispatch(ostAction.updateTrimmedMeanTopRate({ trimmedMeanTopRate: value }));
    },
    [dispatch, ref, trimmedMeanTopRate]
  );

  return (
    <Input
      inputProps={{ min: 0, max: 100 }}
      isPercent
      type="number"
      key={trimmedMeanTopRate}
      inputRef={ref}
      variant="outlined"
      defaultValue={trimmedMeanTopRate}
      onBlur={onBlur}
    />
  );
};
