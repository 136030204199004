import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import type { ModalHeaderView } from '@front/src/types';
import UIBuilderEditModalButtonSection from '@front/src/components/ui-builder/table/EditModalButtonSection';
import UIBuilderEditModalTableBody from '@front/src/components/ui-builder/table/EditModalTableBody';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import { ModalContentUI } from '@front/src/components/components-with-design/layout/ModalUI';

interface Props extends Omit<CellComponentProps, 'isEditMode'> {
  headerList: ModalHeaderView[];
  onClose: () => void;
}

export default function UIBuilderEditModalContent({ ...rest }: Readonly<Props>) {
  return (
    <ModalContentUI>
      <UIBuilderEditModalButtonSection {...rest} />
      <TableContainerUI>
        <TableTableUI>
          <TableBodyUI>
            <UIBuilderEditModalTableBody {...rest} />
          </TableBodyUI>
        </TableTableUI>
      </TableContainerUI>
    </ModalContentUI>
  );
}
