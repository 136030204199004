import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import { affiliatedCompanyPersonCharacterCharacterRepository } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/character/repository/repository';
import { useAffiliatedCompanyDetailModalCharacterCharacterState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/character/widgets/useState';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';

export { useLogic as useAffiliatedCompanyDetailModalCharacterCharacterLogic };

interface Props {
  id?: number;
  menuId?: number;
}

const useLogic = ({ id, menuId }: Props) => {
  const { idList, setIdList } = useAffiliatedCompanyDetailModalCharacterCharacterState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { modalId } = useAffiliatedCompanyPersonState(
    (state) => ({
      modalId: state.modalId,
    }),
    shallow
  );
  const { data: characterList, isLoading } =
    affiliatedCompanyPersonCharacterCharacterRepository.usePersonCharacterCharacterListGet(
      id ?? modalId,
      menuId
    );
  const { run: onCreateCharacter } =
    affiliatedCompanyPersonCharacterCharacterRepository.usePersonCharacterCharacterCreate(menuId);
  const { run: onDeleteCharacter } =
    affiliatedCompanyPersonCharacterCharacterRepository.usePersonCharacterCharacterDelete(menuId);
  const { run: onUpdateCharacter } =
    affiliatedCompanyPersonCharacterCharacterRepository.usePersonCharacterCharacterUpdate(menuId);
  const handleCharacterCreate = useCallback(() => {
    onCreateCharacter(modalId);
  }, [onCreateCharacter, modalId]);
  const d = { characterList };
  const h = {
    onCreateCharacter: handleCharacterCreate,
    setIdList,
    onDeleteCharacter,
    onUpdateCharacter,
  };
  return { d, h, isLoading, idList };
};
