import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAccountingUploadSalesInfoExpectModalUpdateLogic } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/widgets/update/provider/useLogic';
import { AccountingUploadSalesInfoExpectModalUpdateContext } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/widgets/update/provider/context';

export { Provider as AccountingUploadSalesInfoExpectModalUpdateProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { onUpdate } = useAccountingUploadSalesInfoExpectModalUpdateLogic();
  const value = useMemo(
    () => ({
      onUpdate,
    }),
    [onUpdate]
  );
  return (
    <AccountingUploadSalesInfoExpectModalUpdateContext.Provider value={value}>
      {children}
    </AccountingUploadSalesInfoExpectModalUpdateContext.Provider>
  );
};
