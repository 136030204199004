import type { ReactNode } from 'react';
import React, { useContext, useMemo } from 'react';
import { AccountingUploadEcountDataListContext } from '@front/src/features/accounting/features/upload/features/ecount-data/widgets/list/provider/context';
import { useAccountingUploadEcountDataListLogic } from '@front/src/features/accounting/features/upload/features/ecount-data/widgets/list/provider/useLogic';
import { AccountingUploadEcountDataContext } from '@front/src/features/accounting/features/upload/features/ecount-data/provider/context';

export { Provider as AccountingUploadEcountDataListProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { isModalMode } = useContext(AccountingUploadEcountDataContext);
  const { list } = useAccountingUploadEcountDataListLogic(isModalMode);
  const value = useMemo(() => ({ list }), [list]);
  return (
    <AccountingUploadEcountDataListContext.Provider value={value}>
      {children}
    </AccountingUploadEcountDataListContext.Provider>
  );
};
