// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.szVBry002K02R1MjgaaZ{width:100%;display:flex;justify-content:space-between;align-items:center;gap:12px}`, "",{"version":3,"sources":["webpack://./front/src/components/layout/drawer-search-form/item-box.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,QAAA","sourcesContent":[".container {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `szVBry002K02R1MjgaaZ`
};
export default ___CSS_LOADER_EXPORT___;
