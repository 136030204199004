import { projectSalesInfoAbstractProjectInfoQuery } from '@front/src/features/project-sales-info/features/abstract/features/project-info/query/query';

export { repository as projectSalesInfoAbstractProjectInfoRepository };
const repository = {
  useDetailGet: (id?) => {
    const { data, remove } = projectSalesInfoAbstractProjectInfoQuery.useDetailGet(id);
    return {
      data,
      remove,
    };
  },
};
