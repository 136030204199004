import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonRepository } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';

export { useLogic as useProjectSalesInfoCollectionManagementModalCreateModalLogic };
const useLogic = () => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { data: affiliatedPersonList } =
    projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonRepository.useListGet(id!);

  return {
    d: {
      affiliatedPersonList,
    },
  };
};
