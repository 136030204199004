import { default as axios } from '@front/src/config/axios';
import { toFormData } from '@front/services/api';
import type {
  ApprovalDocumentParameter,
  ArrangementCommentParameter,
  CommentParameter,
  DrawingParameter,
  ProposalAddParameter,
  ProposalApprovalParameter,
  ProposalArrangementInformationStatusParameter,
  ProposalContentAddParameter,
  ProposalContentChangeParameter,
  ProposalContributorUpdateParameter,
  ProposalEstimatorUpdateGradeParameter,
  ProposalFixedResultUpdateParameter,
  ProposalGradeUpdateParameter,
  ProposalMemoAddParameter,
  ProposalMemoChangeParameter,
  ProposalStatusUpdateParameter,
  ProposalSubmissionCancelReasonParameter,
  ProposalUpdateArrangementInformationAdvisorParameter,
  ProposalUpdateArrangementPlanParameter,
  TitleUpdateParameter,
} from '@front/ost_proposal/parameter';

import type { ProposalMemoQuery, ProposalQuery } from '@front/ost_proposal/query';
import type {
  ArrangementCommentId,
  CommentId,
  DrawingId,
  DrawingVO,
  ProposalArrangementPlanId,
  ProposalContentId,
  ProposalContributorId,
  ProposalId,
  ProposalMemoId,
} from '@front/ost_proposal/domain';

import type { CurrentPage, TargetPage } from '@front/navigate/domain';

class ProposalApi {
  /**
   * 제안 등록 API
   * @param params
   */
  async add(params: ProposalAddParameter): Promise<void> {
    const { data } = await axios.post('/api/ost/proposal', params);
    return data;
  }

  /**
   * 제안 목록 조회 API
   * @param query
   */
  async getProposalList(query: ProposalQuery): Promise<void> {
    const { data } = await axios.get(
      `/api/ost/proposal/list?keyword=${query.keyword}&status=${query.status}`
    );
    return data;
  }

  /**
   * 제안 상세 API
   * @param id
   */
  async getOne(id: ProposalId): Promise<void> {
    const { data } = await axios.get(`/api/ost/proposal/${id}`);
    return data;
  }

  /**
   * 제안 삭제 API
   * @param id
   */
  async deleteDetail(id: ProposalId): Promise<void> {
    const { data } = await axios.delete(`/api/ost/proposal/${id}`);
    return data;
  }

  /**
   * 제안 댓글 관련 API
   * @param params
   * @param id
   */

  async addComment(params: CommentParameter, id: ProposalId): Promise<void> {
    const { data } = await axios.post(`/api/ost/proposal/${id}/comment`, params);
    return data;
  }

  async getCommentList(id: ProposalId, category: TargetPage) {
    if (typeof category === 'undefined') {
      return [];
    }
    const { data } = await axios.get(
      `/api/ost/proposal/${id}/comment?category=${category?.toLowerCase()}`
    );
    return data;
  }

  async updateComment(params: CommentParameter): Promise<void> {
    const { data } = await axios.patch(`/api/ost/proposal/comment/${params.id}`, {
      content: params.content,
      category: params.category,
    });
    return data;
  }

  async deleteComment(id: CommentId): Promise<void> {
    const { data } = await axios.delete(`/api/ost/proposal/comment/${id}`);
    return data;
  }

  /**
   * 제안 드로잉 관련 API
   * @param params
   * @param id
   */
  async addDrawing(id: ProposalId, params: DrawingParameter): Promise<void> {
    const { data } = await axios.post(`/api/ost/proposal/${id}/drawing`, params);
    return data;
  }

  async getDrawingList(id: ProposalId): Promise<DrawingVO[]> {
    const { data } = await axios.get(`/api/ost/proposal/${id}/drawing`);
    return data;
  }

  async deleteDrawing(drawingId: DrawingId): Promise<void> {
    const { data } = await axios.delete(`/api/ost/proposal/drawing/${drawingId}`);
    return data;
  }

  async updateDrawing(drawingId: DrawingId, stringfiedData: string): Promise<void> {
    const { data } = await axios.patch(`/api/ost/proposal/drawing/${drawingId}`, {
      stringfiedData,
    });
    return data;
  }

  /**
   * 제안 첨부 파일 관련 API
   * @param params
   * @param id
   */
  async addAttachedFile(params: FormData, id: ProposalId): Promise<void> {
    const { data } = await axios.post(`/api/ost/proposal/${id}/attached-file`, toFormData(params));
    return data;
  }

  async getAttachedFileList(id: ProposalId): Promise<void> {
    const { data } = await axios.get(`/api/ost/proposal/${id}/attached-file`);
    return data;
  }

  async deleteAttachedFile(fileId: ProposalId): Promise<void> {
    const { data } = await axios.delete(`/api/ost/proposal/attached-file/${fileId}`);
    return data;
  }

  /**
   * 제안 주제 관련 API
   * @param params
   * @param id
   */
  async updateTitle(params: TitleUpdateParameter, id: ProposalId): Promise<void> {
    const { data } = await axios.patch(`/api/ost/proposal/${id}/title`, params);
    return data;
  }

  async getProposalTitle(id: ProposalId): Promise<void> {
    const { data } = await axios.get(`/api/ost/proposal/${id}/title`);
    return data;
  }

  /**
   * 제안 메모 관련 API
   * @param id
   * @param params
   */
  async addMemo(id: ProposalId, params: ProposalMemoAddParameter): Promise<void> {
    const { data } = await axios.post(`/api/proposal/${id}/memo`, params);
    return data;
  }

  async getMemoList(id: ProposalId, query: ProposalMemoQuery): Promise<void> {
    const { data } = await axios.get(`/api/proposal/${id}/memo?keyword=${query.keyword}`);
    return data;
  }

  async changeMemo(id: ProposalId, params: ProposalMemoChangeParameter): Promise<void> {
    const { data } = await axios.put(`/api/proposal/${id}/memo/${params.id}`, params);
    return data;
  }

  async deleteMemo(id: ProposalId, memoId: ProposalMemoId): Promise<void> {
    const { data } = await axios.delete(`/api/proposal/${id}/memo/${memoId}`);
    return data;
  }

  /**
   * 제안 내용 관련 API
   */
  async addContent(id: ProposalId, params: ProposalContentAddParameter): Promise<void> {
    const { data } = await axios.post(`/api/ost/proposal/${id}/content`, params);
    return data;
  }

  async deleteContent(id: ProposalId, contentId: ProposalContentId): Promise<void> {
    const { data } = await axios.delete(`/api/ost/proposal/${id}/content/${contentId}`);
    return data;
  }

  async updateContent(id: ProposalId, params: ProposalContentChangeParameter): Promise<void> {
    const { data } = await axios.put(
      `/api/ost/proposal/${id}/content/${params.id}`,
      toFormData({
        beforeContent: params.beforeContent,
        beforeFile: params.beforeFile,
        afterContent: params.afterContent,
        afterFile: params.afterFile,
        beforeFileDeleted: params.beforeFileDeleted,
        afterFileDeleted: params.afterFileDeleted,
      }),
      {
        headers: {
          'Content-Type': 'multipart/form-data', // 파일 업로드를 위한 Section-Type 설정
        },
      }
    );
    return data;
  }

  async getContentList(id: ProposalId): Promise<void> {
    const { data } = await axios.get(`/api/ost/proposal/${id}/content`);
    return data;
  }

  /**
   * 참여자 관련 api
   */
  async addContributor(id: ProposalId): Promise<void> {
    const { data } = await axios.post(`/api/ost/proposal/${id}/contributor`);
    return data;
  }

  async getContributorList(id: ProposalId): Promise<void> {
    const { data } = await axios.get(`/api/ost/proposal/${id}/contributor`);
    return data;
  }

  async updateContributor(
    id: ProposalId,
    params: ProposalContributorUpdateParameter
  ): Promise<void> {
    const { data } = await axios.patch(`/api/ost/proposal/${id}/contributor/${params.id}`, {
      rate: params.rate,
      note: params.note,
      isNoteDeleted: params.isNoteDeleted,
      contributorId: params.contributorId,
      isRateDeleted: params.isRateDeleted,
    });
    return data;
  }

  async deleteContributor(id: ProposalId, contributorId: ProposalContributorId): Promise<void> {
    const { data } = await axios.delete(`/api/ost/proposal/${id}/contributor/${contributorId}`);
    return data;
  }

  /**
   * 제안 등급 관련 api
   */
  async updateGradeByContributor(
    id: ProposalId,
    params: ProposalGradeUpdateParameter
  ): Promise<void> {
    const { data } = await axios.patch(`/api/ost/proposal/${id}/contributor/grade`, params);
    return data;
  }

  async getGradeByContributor(id: ProposalId): Promise<void> {
    const { data } = await axios.get(`/api/ost/proposal/${id}/contributor/grade`);
    return data;
  }

  /**
   * 제안 제출
   */
  async submitProposal(id: ProposalId): Promise<void> {
    const { data } = await axios.get(`/api/ost/proposal/${id}/submit`);
    return data;
  }

  /**
   * 제안 진척 현황
   */
  async updateStatus(id: ProposalId, params: ProposalStatusUpdateParameter): Promise<void> {
    const { data } = await axios.patch(`/api/ost/proposal/${id}/status`, { status: params.status });
    return data;
  }

  /**
   * 권한 체크
   */
  async getPermission(id: ProposalId, params: CurrentPage): Promise<void> {
    const { data } = await axios.get(
      `/api/ost/proposal/${id}/permission?where=${params.targetPage.toLowerCase()}`
    );
    return data;
  }

  /**
   * 평가 등급
   */
  async updateEvaluationGrade(
    id: ProposalId,
    params: ProposalEstimatorUpdateGradeParameter
  ): Promise<void> {
    const { data } = await axios.put(`/api/ost/proposal/${id}/estimator/${params.id}/grade`, {
      grade: params.grade,
      opinion: params.opinion,
      isOpinionDeleted: params.isOpinionDeleted,
    });
    return data;
  }

  /**
   * 평가 목록
   */
  async getEstimatorList(id: ProposalId): Promise<void> {
    const { data } = await axios.get(`/api/ost/proposal/${id}/estimator-list`);
    return data;
  }

  /**
   * 제안 투찰 중지 및 종료
   */
  async endVote(id: ProposalId): Promise<void> {
    const { data } = await axios.post(`/api/ost/proposal/${id}/end-vote`);
    return data;
  }

  /**
   * 협의자 요청
   */
  async requestAdvisor(id: ProposalId): Promise<void> {
    const { data } = await axios.get(`/api/ost/proposal/${id}/request-advisor`);
    return data;
  }

  /**
   * 협의 정보 상태 업데이트
   */
  async updateProposalArrangementInformationStatus(
    id: ProposalId,
    params: ProposalArrangementInformationStatusParameter
  ) {
    const { data } = await axios.put(`/api/ost/proposal/${id}/arrangement-information/status`, {
      status: params.status,
    });
    return data;
  }

  /**
   * 협의자 업데이트
   */
  async updateProposalArrangementInformationAdvisor(
    id: ProposalId,
    params: ProposalUpdateArrangementInformationAdvisorParameter
  ) {
    const { data } = await axios.put(
      `/api/ost/proposal/${id}/arrangement-information/advisor`,
      params
    );
    return data;
  }

  /**
   * 협의 계획
   */
  async addProposalArrangementPlan(id: ProposalId) {
    const { data } = await axios.post(`/api/ost/proposal/${id}/arrangement-information/plan`);
    return data;
  }

  async getProposalArrangementPlanList(id: ProposalId) {
    const { data } = await axios.get(`/api/ost/proposal/${id}/arrangement-information/plan`);
    return data;
  }

  async updateProposalArrangementPlan(
    id: ProposalId,
    params: ProposalUpdateArrangementPlanParameter
  ) {
    const { data } = await axios.patch(
      `/api/ost/proposal/${id}/arrangement-information/plan/${params.id}`,
      {
        ...params,
      }
    );
    return data;
  }

  async deleteProposalArrangementPlan(id: ProposalId, planId: ProposalArrangementPlanId) {
    const { data } = await axios.delete(
      `/api/ost/proposal/${id}/arrangement-information/plan/${planId}`
    );
    return data;
  }

  /**
   * 제안 댓글 관련 API
   * @param params
   * @param id
   */

  async addArrangementComment(params: ArrangementCommentParameter, id: ProposalId): Promise<void> {
    const { data } = await axios.post(`/api/ost/proposal/${id}/arrangement-comment`, params);
    return data;
  }

  async getArrangementCommentList(id: ProposalId): Promise<void> {
    const { data } = await axios.get(`/api/ost/proposal/${id}/arrangement-comment`);
    return data;
  }

  async updateArrangementComment(params: ArrangementCommentParameter): Promise<void> {
    const { data } = await axios.patch(`/api/ost/proposal/arrangement-comment/${params.id}`, {
      content: params.content,
    });
    return data;
  }

  async deleteArrangementComment(id: ArrangementCommentId): Promise<void> {
    const { data } = await axios.delete(`/api/ost/proposal/arrangement-comment/${id}`);
    return data;
  }

  /**
   * 제안 첨부 파일 관련 API
   */
  async addArrangementAttachedFile(params: FormData, id: ProposalId): Promise<void> {
    const { data } = await axios.post(
      `/api/ost/proposal/${id}/arrangement-attached-file`,
      toFormData(params)
    );
    return data;
  }

  async getArrangementAttachedFileList(id: ProposalId): Promise<void> {
    const { data } = await axios.get(`/api/ost/proposal/${id}/arrangement-attached-file`);
    return data;
  }

  async deleteArrangementAttachedFile(fileId: ProposalId): Promise<void> {
    const { data } = await axios.delete(`/api/ost/proposal/arrangement-attached-file/${fileId}`);
    return data;
  }

  async addProposalSubmissionCancelReason(
    id: ProposalId,
    params: ProposalSubmissionCancelReasonParameter
  ): Promise<void> {
    const { data } = await axios.post(`/api/ost/proposal/${id}/cancel-reason`, params);
    return data;
  }

  async agreeProposalSubmissionCancel(id: ProposalId, isAgreed: boolean): Promise<void> {
    const { data } = await axios.post(
      `/api/ost/proposal/${id}/cancel-reason/agree?isAgreed=${isAgreed}`
    );
    return data;
  }

  async getProposalSubmissionCancel(id: ProposalId): Promise<void> {
    const { data } = await axios.get(`/api/ost/proposal/${id}/cancel-reason`);
    return data;
  }

  /**
   * 결재 상신 등록(기안 등록)
   */
  async addApprovalDocument(params: ApprovalDocumentParameter): Promise<void> {
    const { data } = await axios.post(`/api/approval-document`, params);
    return data;
  }

  /**
   * 기안 get
   */
  async getApprovalDocument(id: ProposalId) {
    const { data } = await axios.get(`/api/ost/proposal/${id}/approval-document`);
    return data;
  }

  /**
   * 결재 목록
   */
  async getApprovalList(id: ProposalId): Promise<void> {
    const { data } = await axios.get(`/api/ost/proposal/${id}/approval-list`);
    return data;
  }

  /**
   * 결재
   */
  async updateProposalApproval(params: ProposalApprovalParameter): Promise<void> {
    const { data } = await axios.put(`/api/proposal-approval/${params.id}`, {
      comment: params.comment,
      grade: params.grade,
      result: params.result,
    });
    return data;
  }

  /**
   * 확정금액
   */
  async updateFixedResult(params: ProposalFixedResultUpdateParameter): Promise<void> {
    const { data } = await axios.put(`/api/ost/proposal/${params.proposalId}/fixed-result`, {
      fixedResult: params.fixedResult,
    });
    return data;
  }
}

export const proposalApi = new ProposalApi();
