import React from 'react';
import { TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { FormProvider, useForm } from 'react-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { ProjectSalesInfoAffiliatedCompanyWebHardCreateModalCompanySelect } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/create-modal/components/company-select';
import { ProjectSalesInfoAffiliatedCompanyWebHardCreateModalWebHardSelect } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/create-modal/components/web-hard-select';
import { ProjectSalesInfoAffiliatedCompanyWebHardCreateModalButtonGroup } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/create-modal/components/button-group';

export { ModalForm as ProjectSalesInfoAffiliatedCompanyWebHardCreateModalForm };

const ModalForm = () => {
  const methods = useForm({
    values,
  });
  return (
    <FormProvider {...methods}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <Th>회사명</Th>
              <TableCell>
                <ProjectSalesInfoAffiliatedCompanyWebHardCreateModalCompanySelect />
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>웹하드</Th>
              <TableCell>
                <ProjectSalesInfoAffiliatedCompanyWebHardCreateModalWebHardSelect />
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>ID</Th>
              <TableCell>
                <HookFormInput name="webHardUsername" />
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>PW</Th>
              <TableCell>
                <HookFormInput name="webHardPassword" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ProjectSalesInfoAffiliatedCompanyWebHardCreateModalButtonGroup />
    </FormProvider>
  );
};

const values = {
  companyId: '',
  webHardId: '',
  webHardUsername: '',
  webHardPassword: '',
};
