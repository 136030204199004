import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationUpdateContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/features/update/provider/context';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedCompanyPersonDetailModalOutlineState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/section/outline/useState';

export { RemoveButton as AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationRemoveButton };

const RemoveButton = () => {
  const { onUpdate } = useContext(
    AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationUpdateContext
  );
  const { modalId } = useAffiliatedCompanyPersonState(
    useShallow((state) => ({
      modalId: state.modalId,
    }))
  );
  const handleButtonClick = useCallback(() => {
    onUpdate({
      personId: modalId,
      affiliatedCompanyId: null,
    });
  }, [onUpdate, modalId]);
  const { readOnly } = useAffiliatedCompanyPersonDetailModalOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Button
      onClick={handleButtonClick}
      disabled={readOnly}
    >
      소속회사해지
    </Button>
  );
};
