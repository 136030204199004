import React from 'react';
import Input from '@front/layouts/Input';
import { useController, useFormContext } from 'react-hook-form';

export { SelectableHeaderTableNote as PersonalSettingsModalUISettingsModalSettingsSelectableHeaderTableNote };

interface Props {
  index: number;
}

const SelectableHeaderTableNote = ({ index }: Readonly<Props>) => {
  const { control } = useFormContext();
  const {
    field: { value: note, onChange: onNoteChange },
  } = useController({
    name: `selectableHeaderList.${index}.note`,
    control,
  });
  return (
    <Input
      variant="outlined"
      value={note}
      onChange={onNoteChange}
    />
  );
};
