import { commentApiURL, noteApiURL } from '@front/src/utils';
import type {
  AuthUserView,
  CommentView,
  NoteDetailView,
  NoteFilterView,
  NoteMenuIdView,
  NoteView,
  ReviewerView,
  ReviewHistoryView,
} from '@front/src/features/note/types/view';
import type {
  AuthUserListQueryParams,
  CommentFilterParams,
  CommentParams,
  CommentUpdateParams,
  NoteCreateParams,
  NoteFilterParams,
  NoteParams,
  NoteUpdateParams,
  ReviewCreateParams,
  ReviewerUpdateParams,
  ReviewUpdateRequest,
} from '@front/src/features/note/types/parameter';
import axios from '@front/src/config/noteAxios';
import type { AxiosResponse } from 'axios';

const url = {
  getList: () => `${noteApiURL}`,
  getDetail: (noteId: number) => `${noteApiURL}/${noteId}`,
  getFilter: () => `${noteApiURL}/filter`,
  toggleRead: (reviewerId: number) => `${noteApiURL}/reviewer/${reviewerId}/read-at`,
  updateReview: (reviewId: number) => `${noteApiURL}/review/${reviewId}`,
  createNote: () => `${noteApiURL}`,
  deleteNote: (noteId: number) => `${noteApiURL}/${noteId}`,
  updateNote: (noteId: number) => `${noteApiURL}/${noteId}`,

  getCommentList: () => `${commentApiURL}`,
  createComment: () => `${commentApiURL}`,
  updateComment: (commentId: number) => `${commentApiURL}/${commentId}`,
  deleteComment: (commentId: number) => `${commentApiURL}/${commentId}`,

  getReviewerDetail: (noteId: number) => `${noteApiURL}/${noteId}/review`,
  getReviewHistoryList: (noteId: number) => `${noteApiURL}/${noteId}/review-history-list`,
  updateReviewer: (reviewId) => `${noteApiURL}/review/${reviewId}`,
  createReview: (noteId: number) => `${noteApiURL}/${noteId}/review`,

  getNoteMenuId: (menuId: number) => `${noteApiURL}/menu/${menuId}`,
  getAuthUserList: () => `/api/admin/authorized-user`,
};

export const noteApi = {
  getList: async <T = NoteView, R = NoteParams>(params: R) => {
    const { data } = await axios.get<T, AxiosResponse<T>, R>(url.getList(), {
      params,
    });
    return data;
  },
  getDetail: async <T = NoteDetailView>(noteId: number) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getDetail(noteId));
    return data;
  },
  getFilter: async <T = NoteFilterView[], R = NoteFilterParams>(params: R) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getFilter(), { params });
    return data;
  },
  toggleRead: async <T = unknown, R = ReviewerUpdateParams>(reviewerId: number, params: R) => {
    await axios.patch<T, AxiosResponse<T>, R>(url.toggleRead(reviewerId), params);
  },
  createNote: async <T = unknown, R = NoteCreateParams>(params: R) => {
    await axios.post<T, AxiosResponse<T>, R>(url.createNote(), params);
  },
  deleteNote: async <T = unknown>(noteId: number) => {
    await axios.delete<T, AxiosResponse<T>>(url.deleteNote(noteId));
  },
  updateNote: async <T = unknown, R = NoteUpdateParams>(noteId: number, params: R) => {
    await axios.patch<T, AxiosResponse<T>, R>(url.updateNote(noteId), params);
  },

  getCommentList: async <T = CommentView[], R = CommentFilterParams>(params: R) => {
    const { data } = await axios.get<T, AxiosResponse<T>, R>(url.getCommentList(), { params });
    return data;
  },
  createComment: async <T = unknown, R = CommentParams>(params: R) => {
    await axios.post<T, AxiosResponse<T>, R>(url.createComment(), params);
  },
  updateComment: async <T = unknown, R = CommentUpdateParams>(params: R) => {
    await axios.patch<T, AxiosResponse<T>, R>(
      url.updateComment((params as CommentUpdateParams).id),
      params
    );
  },
  deleteComment: async <T = unknown>(commentId: number) => {
    await axios.delete<T, AxiosResponse<T>>(url.deleteComment(commentId));
  },

  getReviewerDetail: async <T = ReviewerView>(noteId: number) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getReviewerDetail(noteId));
    return data;
  },
  updateReview: async <T = unknown, R = ReviewUpdateRequest>(reviewId: number, params: R) => {
    await axios.patch<T, AxiosResponse<T>, R>(url.updateReview(reviewId), params);
  },
  getReviewHistoryList: async <T = ReviewHistoryView[]>(noteId: number) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getReviewHistoryList(noteId));
    return data;
  },
  createReview: async <T = unknown, R = ReviewCreateParams>(noteId: number, params: R) => {
    await axios.post<T, AxiosResponse<T>, R>(url.createReview(noteId), params);
  },

  getNoteMenuId: async <T = NoteMenuIdView>(menuId: number) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getNoteMenuId(menuId));
    return data;
  },
  getAuthUserList: async <T = AuthUserView[]>(params: AuthUserListQueryParams) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getAuthUserList(), { params });
    return data;
  },
};
