import { Box, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { DefaultFunction } from 'type/Function';
import type { ConfirmVO } from 'dialog/domain';
import { DialogStatus } from 'dialog/domain';
import Button from 'layouts/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextBox from 'layouts/Text';
import { ColorPalette } from 'assets/theme';
import type { ModalPositionType } from '@front/layouts/ModalLayout';
import Select from '@front/layouts/Select';

interface Props extends ConfirmVO {
  open: boolean;
  onClose: DefaultFunction;
  onConfirm: DefaultFunction;
  position?: ModalPositionType;
}

export default function Confirm(props: Props) {
  const [positionType, setPositionType] = useState(props.position ?? 'center');
  const sxPosition = useMemo(() => {
    switch (positionType) {
      case 'center': {
        return {
          transform: 'translate(-50%, -50%)',
          top: '50%',
          left: '50%',
        };
      }
      case 'top': {
        return {
          transform: 'translate(-50%, 0%)',
          top: '0',
          left: '50%',
        };
      }
      case 'bottom': {
        return {
          transform: 'translate(-50%, 0%)',
          bottom: '0',
          left: '50%',
        };
      }
      case 'left': {
        return {
          transform: 'translate(0%, -50%)',
          top: '50%',
          left: '0',
        };
      }
      case 'right': {
        return {
          transform: 'translate(0%, -50%)',
          top: '50%',
          right: '0',
        };
      }
    }
  }, [positionType]);
  const handleSelectChange = useCallback(
    (e) => {
      setPositionType(e.target.value);
    },
    [setPositionType]
  );
  const getButtonColor = useCallback(() => {
    if (props.status === DialogStatus.ERROR) {
      return 'error';
    } else {
      if (props.status === DialogStatus.WARN) {
        return 'warning';
      } else {
        return undefined;
      }
    }
  }, [props]);
  useEffect(() => {
    return () => setPositionType('center');
  }, []);

  return (
    <>
      {props.open && (
        <Dialog
          open={props.open}
          onClose={props.onClose}
          fullWidth={true}
          maxWidth="sm"
          PaperProps={{
            sx: {
              position: 'fixed',
              margin: '0px',
              ...sxPosition,
            },
          }}
          sx={{
            '& .MuiDialog-paper': {
              borderRadius: 0,
            },
            width: '100%',
            height: '100%',
          }}
        >
          <DialogTitle
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: ColorPalette._2d3a54,
              color: ColorPalette._ffffff,
              height: '50px',
              padding: '0 20px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <TextBox variant="heading2" />
              <Box
                display="flex"
                gap="10px"
                alignItems="center"
              >
                <Select
                  variant="outlined"
                  value={positionType}
                  onChange={handleSelectChange}
                >
                  {optionList.map(({ value, label }) => (
                    <MenuItem
                      key={value}
                      value={value}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                <FontAwesomeIcon
                  icon="xmark"
                  onClick={props.onClose}
                  style={{
                    cursor: 'pointer',
                    fontSize: '18px',
                  }}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                flexWrap: 'wrap',
                display: 'flex',
                width: '100%',
                paddingTop: '30px',
                justifyContent: 'center',
              }}
            >
              {props.children}
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
              paddingBottom: '20px',
            }}
          >
            <Button
              onClick={props.onConfirm}
              color={getButtonColor()}
            >
              {props.confirmText}
            </Button>
            <Button
              onClick={props.onClose}
              shape="basic2"
            >
              {props.closeText}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

const optionList = [
  {
    value: 'center',
    label: '중앙',
  },
  {
    value: 'top',
    label: '상단',
  },
  {
    value: 'bottom',
    label: '하단',
  },
  {
    value: 'left',
    label: '왼쪽',
  },
  {
    value: 'right',
    label: '오른쪽',
  },
];
