import React, { useContext } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import { AffiliatedPersonAppBarPersonListContext } from '@front/src/features/affiliated-person/features/app-bar/widgets/person-list/provider/context';
import { NoResult } from '@front/src/components/layout/table/no-result';

export { List as AffiliatedPersonAppBarPersonList };

const List = () => {
  const { list } = useContext(AffiliatedPersonAppBarPersonListContext);
  if (typeof list === 'undefined' || list.length === 0) {
    return (
      <NoResult
        colSpan={5}
        height="40px"
      />
    );
  }
  return (
    <>
      {list.map((item) => (
        <TableRow key={item.id}>
          <OldTd>{item.affiliatedCompany?.category}</OldTd>
          <OldTd>{item.affiliatedCompany?.name}</OldTd>
          <OldTd>{item.person?.name}</OldTd>
          <OldTd>{item.person?.position}</OldTd>
          <OldTd>{item.person?.phoneNumber}</OldTd>
        </TableRow>
      ))}
    </>
  );
};
