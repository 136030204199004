import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import type { SalesAccountingAmountInformationGetParameter } from '@front/src/features/accounting/features/upload/features/sales-info/types/parameter';
import { AccountingUploadSalesInfoGoalModalMutation } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/query/mutation';
import { AccountingUploadSalesInfoGoalModalQuery } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/query/query';

export { Repository as AccountingUploadSalesInfoGoalModalRepository };

const Repository = {
  useGetList: (params: SalesAccountingAmountInformationGetParameter) => {
    const { data, remove } = AccountingUploadSalesInfoGoalModalQuery.useGetList(params);
    return {
      data,
      remove,
    };
  },
  useUpdate: () => {
    const { mutate, isLoading } = AccountingUploadSalesInfoGoalModalMutation.useUpdate();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
      isLoading,
    };
  },
};
