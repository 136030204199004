import React from 'react';
import { AffiliatedPersonDetailDeleteProvider } from '@front/src/features/affiliated-person/features/detail/widgets/delete/provider/provider';
import { AffiliatedPersonDetailDeleteButton } from '@front/src/features/affiliated-person/features/detail/widgets/delete/components/delete-button';

export { Widget as AffiliatedPersonDetailDeleteWidget };

const Widget = () => (
  <AffiliatedPersonDetailDeleteProvider>
    <AffiliatedPersonDetailDeleteButton />
  </AffiliatedPersonDetailDeleteProvider>
);
