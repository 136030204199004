import React from 'react';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import ApprovalForm from '@front/src/features/drawer-approval/components/ApprovalForm';
import ApprovalStepTable from '@front/src/features/drawer-approval/components/ApprovalStepTable';
import { FormProvider, useForm } from 'react-hook-form';
import type { ApprovalDocumentCreateParameter } from '@front/src/features/drawer-approval/types/parameter';
import ApprovalReferenceSection from '@front/src/features/drawer-approval/components/ApprovalReferenceSection';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { approvalQuery } from '@front/src/features/drawer-approval/query/query';
import useIdGroup from '@front/src/features/note/hooks/useIdGroup';
import ApprovalDescriptionTable from '@front/src/features/drawer-approval/components/ApprovalDescriptionTable';
import { approvalDocumentQuery } from '@front/src/features/drawer-approval-document/query/query';
import {
  APPROVAL_DOCUMENT_STATUS,
  BODY_TEMPLATE_TYPE,
  COMMON_TARGET_TYPE,
} from '@front/type/domain';

export default function ApprovalContent() {
  const { menuId, dataId, noteId, documentId, sectionId } = useIdGroup();
  const { data: tempDocument } = approvalDocumentQuery.useGetApprovalDocument(documentId, {
    where: 'approval-document',
    noteId,
  });
  const newTemp =
    tempDocument?.status === APPROVAL_DOCUMENT_STATUS.TEMPORARY ? tempDocument : undefined;
  const methods = useForm<ApprovalDocumentCreateParameter>({
    values: {
      request: {
        id: documentId ?? 0,
        title: newTemp?.title ?? '',
        content: newTemp?.contents.body ?? '',
        approvalList:
          newTemp?.approvalSteps.map((item, index) => ({
            seq: index + 1,
            userId: item.manager.id,
          })) ?? [],
        referenceList:
          newTemp?.referenceList.map((item, index) => ({
            seq: index + 1,
            userId: item.id,
          })) ?? [],
        status: newTemp?.status ?? APPROVAL_DOCUMENT_STATUS.SUBMISSION,
        ...(newTemp?.status === APPROVAL_DOCUMENT_STATUS.TEMPORARY
          ? {
              deleteFileItemList: [],
              beforeDeleteFileItemId: 0,
              afterDeleteFileItemId: 0,
              contentsId: newTemp?.contents.id ?? 0,
            }
          : {}),
        dataId: 0,
        menuId: 0,
        noteId: 0,
        contentsType: BODY_TEMPLATE_TYPE.BASIC,
        hasFileItemIdAddList: [],
        hasFileItemFileAddList: [],
      },
      fileItemList:
        newTemp?.fileItemList.map((item) => ({
          id: item.id,
          requestDelete: false,
          subPath: '',
          menuId: item.menuId,
          dataId: item.dataId,
          targetId: documentId,
          sectionId,
          targetType: COMMON_TARGET_TYPE.APPROVAL,
        })) ?? [],
    },
  });
  const setLeftDrawerState = useGlobalNavBarState((state) => state.setLeftDrawerState);
  const onClose = () => {
    setLeftDrawerState('');
  };
  const { data: sourceTitle } = approvalQuery.useGetApprovalDocumentSourceTitle({ menuId, dataId });

  return (
    <FormProvider {...methods}>
      <Box sx={{ ...SX_MODAL }}>
        <Box sx={{ ...SX_HEADER }}>
          <Typography sx={{ ...SX_HEADER_TITLE }}>결재 상신</Typography>
          <CloseIcon
            onClick={onClose}
            sx={{ ...SX_CLOSE }}
          />
        </Box>
        <ApprovalForm />
        <Box sx={{ ...SX_BODY }}>
          <Box
            display="flex"
            flexDirection="column"
            gap="10px"
          >
            <Typography sx={{ ...SX_APPROVAL_ORIGIN_NAME }}>{sourceTitle}</Typography>
            <Box
              display="flex"
              flexDirection="column"
            >
              <Typography sx={{ ...SX_APPROVAL_SECTION }}>제목</Typography>
              <HookFormInput
                name="request.title"
                placeholder="제목을 입력해 주세요"
              />
            </Box>
            <ApprovalStepTable />
            <ApprovalReferenceSection />
            <Box>
              <Typography sx={{ ...SX_APPROVAL_SECTION }}>본문</Typography>
              <ApprovalDescriptionTable fileItemList={tempDocument?.fileItemList} />
            </Box>
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
}

const SX_MODAL = {
  display: 'flex',
  flexDirection: 'column',
  background: '#ffffff',
  boxShadow: '20px 0px 10px rgba(0,0,0,0.15)',
  overflow: 'hidden',
};

const SX_HEADER = {
  display: 'flex',
  height: '42px',
  padding: '0px 14px',
  alignItems: 'center',
  gap: '10px',
  alignSelf: 'stretch',
  color: 'white',
  position: 'relative',
  background: 'var(--design-v-10-light-background-bg-04, #1F314E)',
};

const SX_BODY = {
  height: 'calc(100dvh - 102px)',
  overflow: 'auto',
  padding: '14px',
};

const SX_HEADER_TITLE = {
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  '& > span': {
    color: 'var(--design-v-10-light-sub-sub-primary, #FFB611)',
    borderLeft: '1px solid #B0B5BE',
    paddingLeft: '10px',
    marginLeft: '10px',
  },
};

const SX_CLOSE = {
  cursor: 'pointer',
  position: 'absolute',
  right: '14px',
  width: '18px',
  height: '18px',
};

const SX_APPROVAL_ORIGIN_NAME = {
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
};

export const SX_APPROVAL_SECTION = {
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  margin: '10px 0',
};
