import React from 'react';
import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';

interface Props extends BoxProps {
  size: 'small' | 'medium' | 'large';
}

const componentObj = {
  small: SmallTitle,
  medium: MediumTitle,
  large: LargeTitle,
};

export default function TitleUI({ size, ...rest }: Readonly<Props>) {
  const Component = componentObj[size];
  return <Component {...rest} />;
}

interface ComponentProps extends Omit<Props, 'size'> {}

function SmallTitle({ ...rest }: Readonly<ComponentProps>) {
  return (
    <Box
      {...rest}
      sx={{
        fontSize: '1.3rem',
        fontWeight: '600',
      }}
    />
  );
}

function MediumTitle({ ...rest }: Readonly<ComponentProps>) {
  return (
    <Box
      {...rest}
      sx={{
        fontSize: '1.4rem',
        fontWeight: '600',
      }}
    />
  );
}

function LargeTitle({ ...rest }: Readonly<ComponentProps>) {
  return (
    <Box
      {...rest}
      sx={{
        fontSize: '1.6rem',
        fontWeight: '600',
      }}
    />
  );
}
