import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { AffiliatedPersonAppBarCompanySelectModalContext } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/provider/context';
import type { AffiliatedPersonCompanyView } from '@front/src/features/affiliated-person/types/view';

export { Provider as AffiliatedPersonAppBarCompanySelectModalProvider };

interface Props {
  children: ReactNode;
  onCompanySelect: (company: AffiliatedPersonCompanyView) => void;
  setIsOpen: (isOpen: boolean) => void;
}

const Provider = ({ children, setIsOpen, onCompanySelect }: Props) => {
  const value = useMemo(
    () => ({
      setIsOpen,
      onCompanySelect,
    }),
    [setIsOpen, onCompanySelect]
  );
  return (
    <AffiliatedPersonAppBarCompanySelectModalContext.Provider value={value}>
      {children}
    </AffiliatedPersonAppBarCompanySelectModalContext.Provider>
  );
};
