import React, { useContext } from 'react';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { AccountingSettingsDailyReportStatusListContext } from '@front/src/features/accounting/features/settings/features/daily-report-status/widgets/list/provider/context';
import { AccountingSettingsDailyReportStatusListTableRow } from '@front/src/features/accounting/features/settings/features/daily-report-status/widgets/list/components/table-row';

export { TableBody as AccountingSettingsDailyReportStatusListTableBody };

const TableBody = () => {
  const { list } = useContext(AccountingSettingsDailyReportStatusListContext);
  if (list.length === 0) {
    return (
      <NoResult
        colSpan={9}
        height="40px"
      />
    );
  }
  return (
    <>
      {list.map((item, index) => (
        <AccountingSettingsDailyReportStatusListTableRow
          key={`${item.id}_${index}`}
          list={list}
          index={index}
          item={item}
        />
      ))}
    </>
  );
};
