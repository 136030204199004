import type { ReactNode, RefObject } from 'react';
import React from 'react';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import TextBox from '@front/layouts/Text';

export { TableBodyCell as AccountingSettingsManagementAccountingTableLayoutTableBodyCell };

interface Props {
  sx?: SxProps<Theme>;
  children?: ReactNode;
  onClick?: (e) => void;
  onKeyDown?: (e) => void;
  onContextMenu?: (e) => void;
  justifyContent?: string;
  textIndent?: string;
  cellRef?: RefObject<any>;
  nowrap?: boolean;
}

const TableBodyCell = ({
  sx,
  children,
  onClick,
  justifyContent,
  onContextMenu,
  onKeyDown,
  textIndent,
  cellRef,
  nowrap,
}: Props) => (
  <Box
    onClick={onClick}
    onContextMenu={onContextMenu}
    onKeyDown={onKeyDown}
    tabIndex={-1}
    sx={{
      width: '100%',
      outline: `1px solid ${ColorPalette._e4e9f2}`,
    }}
  >
    <TextBox
      variant="body2"
      cellRef={cellRef}
      sx={{
        display: 'flex',
        alignItems: 'center',
        minWidth: '200px',
        whiteSpace: nowrap ? 'nowrap' : 'normal',
        height: '40px',
        justifyContent: justifyContent,
        textIndent: textIndent,
        ...sx,
      }}
    >
      {children}
    </TextBox>
  </Box>
);
