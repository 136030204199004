import type { ReactNode } from 'react';
import React from 'react';
import type { TabProps } from '@mui/material/Tab';
import Tab from '@mui/material/Tab';
import { ColorPalette } from '@front/assets/theme';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';

interface Props {
  shape?: 'main' | 'main2';
  disabled?: boolean;
  children: ReactNode;
  onChange: (event: React.SyntheticEvent, value: any) => void;
  value?: any;
}

const componentObj = {
  main: TabMainUI,
  main2: TabMain2UI,
};

export default function TabUI({ shape = 'main', ...rest }: Readonly<Props>) {
  const Component = componentObj[shape];
  return <Component {...rest} />;
}

export function TabMainUI({ children, onChange, value }: Readonly<Props>) {
  return (
    <Box
      sx={{
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          left: 0,
          bottom: '0',
          width: '100%',
          borderBottom: `1px solid ${ColorPalette.background.bg05}`,
          zIndex: '-1',
        },
      }}
    >
      <Tabs
        value={value}
        onChange={onChange}
        sx={{
          minHeight: 'auto',
          padding: '0px 14px',

          '& .MuiTabs-flexContainer': {
            gap: '3px',
          },
          '& .MuiTabs-indicator': {
            height: '0px',
          },
        }}
      >
        {children}
      </Tabs>
    </Box>
  );
}

export function TabMain2UI({ children, value, onChange }: Readonly<Props>) {
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Tabs
        value={value}
        onChange={onChange}
        sx={{
          minHeight: 'auto',
          padding: '0px 14px',

          '& .MuiTabs-flexContainer': {
            gap: '4px',
          },
          '& .MuiTabs-indicator': {
            height: '0px',
          },
        }}
      >
        {children}
      </Tabs>
    </Box>
  );
}

export function TabMainItemUI({ disabled = false, ...rest }: Readonly<TabProps>) {
  return (
    <Tab
      sx={{
        minHeight: '2.2rem',
        fontSize: '1.4rem',
        fontWeight: '600',
        lineHeight: 'normal',
        padding: '1rem 0.6rem',
        borderRadius: '5px 5px 0px 0px',

        '&.MuiTab-root': {
          color: ColorPalette.greyscale.white,
          background: disabled ? ColorPalette.greyscale.disabled : ColorPalette.background.bg05,
          borderBottom: `1px solid ${ColorPalette.background.bg05}`,
        },

        '&.Mui-selected': {
          color: ColorPalette.greyscale.text_primary,
          background: ColorPalette.background.bg,
          border: `1px solid ${ColorPalette.background.bg05}`,
          borderBottom: `1px solid ${ColorPalette.background.bg}`,
        },
      }}
      {...rest}
    />
  );
}

export function TabMain2ItemUI({ disabled = false, ...rest }: Readonly<TabProps>) {
  return (
    <Tab
      sx={{
        minHeight: '42px',
        fontSize: '1.6rem',
        fontWeight: '600',
        lineHeight: 'normal',
        padding: '8px 22px',
        borderRadius: '5px 5px 0px 0px',

        '&.MuiTab-root': {
          color: ColorPalette.greyscale.white,
          background: disabled ? ColorPalette.greyscale.disabled : ColorPalette.background.bg05,
        },

        '&.Mui-selected': {
          color: ColorPalette.greyscale.text_primary,
          background: ColorPalette.background.bg,
          borderBottom: `1px solid ${ColorPalette.background.bg}`,
        },
      }}
      {...rest}
    />
  );
}
