import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import type { ArrangementCommentParameter } from '@front/ost_proposal/parameter';
import { proposalAction } from '@front/ost_proposal/action';
import type {
  ArrangementCommentId,
  ProposalArrangementCommentEditVO,
} from '@front/ost_proposal/domain';
import { ArrangementCommentLayout } from '@front/ost_proposal/view/detail/advisorForm/arrangement_comment/Layout';

export { ArrangementComment as ArrangementCommentService };
const ArrangementComment = () => {
  const dispatch = useDispatch();
  const addArrangementComment = useCallback(
    (params: ArrangementCommentParameter) => dispatch(proposalAction.addArrangementComment(params)),
    [dispatch]
  );
  const deleteArrangementComment = useCallback(
    (id: ArrangementCommentId) => dispatch(proposalAction.deleteArrangementComment(id)),
    [dispatch]
  );
  const updateArrangementComment = useCallback(
    (params: ArrangementCommentParameter) =>
      dispatch(proposalAction.updateArrangementComment(params)),
    [dispatch]
  );
  const setArrangementCommentId = useCallback(
    (id: ArrangementCommentId | undefined) => dispatch(proposalAction.setArrangementCommentId(id)),
    [dispatch]
  );
  const setArrangementCommentParentId = useCallback(
    (id: ArrangementCommentId | undefined) =>
      dispatch(proposalAction.setArrangementCommentParentId(id)),
    [dispatch]
  );
  const setReplyArrangementCommentParentId = useCallback(
    (id: ArrangementCommentId | undefined) =>
      dispatch(proposalAction.setReplyArrangementCommentParentId(id)),
    [dispatch]
  );
  const setArrangementCommentEditInput = useCallback(
    (state: ProposalArrangementCommentEditVO) =>
      dispatch(proposalAction.setArrangementCommentEditInput(state)),
    [dispatch]
  );

  return (
    <ArrangementCommentLayout
      addArrangementComment={addArrangementComment}
      deleteArrangementComment={deleteArrangementComment}
      updateArrangementComment={updateArrangementComment}
      setArrangementCommentId={setArrangementCommentId}
      setArrangementCommentParentId={setArrangementCommentParentId}
      setArrangementCommentEditInput={setArrangementCommentEditInput}
      setReplyArrangementCommentParentId={setReplyArrangementCommentParentId}
    />
  );
};
