import React, { useMemo } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { useProjectSalesInfoEstimationComparedCreateModalLogic } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/features/create-modal/widgets/useLogic';
import { ProjectSalesInfoEstimationComparedCreateModalContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/features/create-modal/widgets/context';
import { ProjectSalesInfoEstimationComparedCreateModalForm } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/features/create-modal/components/modal-form';
import { isMobileDevice } from '@front/util/PwaUtil';

export { Widget as ProjectSalesInfoEstimationComparedCreateModalWidget };
const Widget = () => {
  const {
    h: { onClose, onCreate },
    isOpen,
  } = useProjectSalesInfoEstimationComparedCreateModalLogic();
  const value = useMemo(
    () => ({
      h: { onClose, onCreate },
    }),
    [onClose, onCreate]
  );
  return (
    <ProjectSalesInfoEstimationComparedCreateModalContext.Provider value={value}>
      <ModalLayout
        position="left"
        width={isMobileDevice() ? '29dvw' : '560px'}
        title="대비견적 추가"
        open={isOpen}
        onClose={onClose}
      >
        <ProjectSalesInfoEstimationComparedCreateModalForm />
      </ModalLayout>
    </ProjectSalesInfoEstimationComparedCreateModalContext.Provider>
  );
};
