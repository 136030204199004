import React, { useMemo } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { ProjectSalesInfoEstimationCompetitorCreateModalContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/features/create-modal/widgets/context';
import { useProjectSalesInfoEstimationCompetitorCreateModalLogic } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/features/create-modal/widgets/useLogic';
import { ProjectSalesInfoEstimationCompetitorCreateModalForm } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/features/create-modal/components/modal-form';
import { isMobileDevice } from '@front/util/PwaUtil';

export { Widget as ProjectSalesInfoEstimationCompetitorCreateModalWidget };
const Widget = () => {
  const {
    h: { onClose, onCreate },
    isOpen,
  } = useProjectSalesInfoEstimationCompetitorCreateModalLogic();
  const value = useMemo(
    () => ({
      h: { onCreate, onClose },
    }),
    [onCreate, onClose]
  );
  return (
    <ProjectSalesInfoEstimationCompetitorCreateModalContext.Provider value={value}>
      <ModalLayout
        position="left"
        width={isMobileDevice() ? '29dvw' : '560px'}
        title="경쟁사 정보 추가"
        open={isOpen}
        onClose={onClose}
      >
        <ProjectSalesInfoEstimationCompetitorCreateModalForm />
      </ModalLayout>
    </ProjectSalesInfoEstimationCompetitorCreateModalContext.Provider>
  );
};
