import { useQuery } from 'react-query';
import { projectSalesInfoEstimationEstimationApi } from '@front/src/features/project-sales-info/features/estimation/features/estimation/query/api';
import type { ProjectEstimationView } from '@front/src/features/project-sales-info/features/estimation/features/estimation/types/view';

export { query as projectSalesInfoEstimationEstimationQuery };

const query = {
  useListGet: (id?: number) => {
    const { data, isLoading } = useQuery<ProjectEstimationView[]>({
      queryFn: () => projectSalesInfoEstimationEstimationApi.getList(id),
      queryKey: ['project', 'sales-info', 'estimation', 'estimation', 'list', id],
      enabled: !!id,
    });
    return {
      data,
      isLoading,
    };
  },
};
