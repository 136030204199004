import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { AffiliatedCompanyOutlineShortUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/short/types/parameter';
import type { AffiliatedCompanyOutlineView } from '@front/src/features/affiliated-company/features/outline/types/view';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';

export { Context as AffiliatedCompanyOutlineShortContext };

interface State {
  d: {
    detailOutline?: AffiliatedCompanyOutlineView;
    categoryOptionList?: VariableInformationView[];
  };
  h: {
    onOutlineShortUpdate: (params: AffiliatedCompanyOutlineShortUpdateParameter) => void;
  };
}

const Context = createContext<State>({
  d: {},
  h: {
    onOutlineShortUpdate: noOp,
  },
});
