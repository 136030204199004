import React, { useCallback, useContext, useMemo } from 'react';
import { TableRow as MuiTableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import type { SalesAccountingAmountType } from '@front/src/features/accounting/features/upload/features/sales-info/types/domain';
import {
  SalesAccountingAmountSubType,
  SalesAccountingAmountSubTypeObjectForLabel,
  SalesAccountingAmountTypeObjectForLabel,
} from '@front/src/features/accounting/features/upload/features/sales-info/types/domain';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import { useFormContext, useWatch } from 'react-hook-form';
import { AccountingUploadSalesInfoGoalModalListContext } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/widgets/list/provider/context';
import { NOT_INTEGER_REGEX } from '@front/src/features/affiliated-company/utils/constant';

export { TableRow as AccountingUploadSalesInfoGoalModalListTableRow };

interface Props {
  index: number;
  type: SalesAccountingAmountType;
}

const TableRow = ({ index, type }: Props) => {
  const { list } = useContext(AccountingUploadSalesInfoGoalModalListContext);
  const { getValues, setValue, control } = useFormContext();
  const getTotalAndAverage = useCallback(() => {
    let sumValue = 0;
    let count = 0;
    for (let month = 1; month < 13; month += 1) {
      const amountValue = getValues(`info.${index}.amountMonth${month}`);
      if (amountValue === '') {
        continue;
      }
      count += 1;
      const amount = +amountValue.replace(NOT_INTEGER_REGEX, '');
      sumValue += amount;
    }
    const averageResult = count === 0 ? null : sumValue / count;
    const sumResult = count === 0 ? null : sumValue;
    return { sumResult, averageResult };
  }, [getValues, index]);
  const handleChange = useCallback(() => {
    const { averageResult, sumResult } = getTotalAndAverage();
    setValue(`info.${index}.total`, sumResult?.toLocaleString() ?? '-');
    setValue(`info.${index}.average`, averageResult?.toLocaleString() ?? '-');
  }, [getTotalAndAverage, setValue, index]);
  const total = useWatch({ control, name: `info.${index}.total` });
  const average = useWatch({ control, name: `info.${index}.average` });
  const readOnly = useMemo(() => list?.[0]?.isConfirmed, [list]);
  if (index === 6) {
    return (
      <MuiTableRow>
        <OldTd>{SalesAccountingAmountTypeObjectForLabel[type]}</OldTd>
        <OldTd>
          {SalesAccountingAmountSubTypeObjectForLabel[SalesAccountingAmountSubType.TARGET]}
        </OldTd>
        <OldTd>{total}</OldTd>
        <OldTd>{average}</OldTd>
        <OldTd key={`${type}-${1}`}>
          <HookFormInput
            name={`info.${index}.amountMonth${1}`}
            inputType={InputType.AMOUNT}
            onPressChange={handleChange}
            readOnly={readOnly}
          />
        </OldTd>
        {Array.from({ length: 10 }, (_, i) => i + 2).map((month) => (
          <OldTd key={`${type}-${month}`}>-</OldTd>
        ))}
        <OldTd key={`${type}-${12}`}>
          <HookFormInput
            name={`info.${index}.amountMonth${12}`}
            inputType={InputType.AMOUNT}
            onPressChange={handleChange}
            readOnly={readOnly}
          />
        </OldTd>
      </MuiTableRow>
    );
  }
  return (
    <MuiTableRow>
      <OldTd>{SalesAccountingAmountTypeObjectForLabel[type]}</OldTd>
      <OldTd>
        {SalesAccountingAmountSubTypeObjectForLabel[SalesAccountingAmountSubType.TARGET]}
      </OldTd>
      <OldTd>{total}</OldTd>
      <OldTd>{average}</OldTd>
      {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
        <OldTd key={`${type}-${month}`}>
          <HookFormInput
            name={`info.${index}.amountMonth${month}`}
            inputType={InputType.AMOUNT}
            onPressChange={handleChange}
            readOnly={readOnly}
          />
        </OldTd>
      ))}
    </MuiTableRow>
  );
};
