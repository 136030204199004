import React, { useMemo } from 'react';
import { useProjectSalesInfoSubjectReviewExperimentLogic } from '@front/src/features/project-sales-info/features/subject-review/features/experiment/widgets/useLogic';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { ProjectSalesInfoSubjectReviewExperimentCodeTable } from '@front/src/features/project-sales-info/features/subject-review/features/experiment/components/code-table';
import { ProjectSalesInfoSubjectReviewExperimentExperimentTable } from '@front/src/features/project-sales-info/features/subject-review/features/experiment/components/experiment-table';
import { ProjectSalesInfoSubjectReviewExperimentContext } from '@front/src/features/project-sales-info/features/subject-review/features/experiment/widgets/context';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';

interface Props extends SectionComponentProps {}

/**
 * @module 영업정보>실험대상검토탭
 */
const ProjectSalesInfoSubjectReviewExperimentWidget = ({ name }: Readonly<Props>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    d: { detail },
    isLoading,
  } = useProjectSalesInfoSubjectReviewExperimentLogic();
  const value = useMemo(
    () => ({
      d: { detail },
    }),
    [detail]
  );
  if (isLoading) {
    return <LoadingSpinner height="400px" />;
  }
  return (
    <ProjectSalesInfoSubjectReviewExperimentContext.Provider value={value}>
      <Layout>
        <Header title={name} />
        <Body>
          <ProjectSalesInfoSubjectReviewExperimentCodeTable />
          <ProjectSalesInfoSubjectReviewExperimentExperimentTable />
        </Body>
      </Layout>
    </ProjectSalesInfoSubjectReviewExperimentContext.Provider>
  );
};

export default ProjectSalesInfoSubjectReviewExperimentWidget;
