import React, { useEffect, useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoContractConfirmedTable } from '@front/src/features/project-sales-info/features/contract/features/confirmed/components/contract-confirmed-table';
import { useProjectSalesInfoContractConfirmedLogic } from '@front/src/features/project-sales-info/features/contract/features/confirmed/widgets/useLogic';
import { ProjectSalesInfoContractConfirmedContext } from '@front/src/features/project-sales-info/features/contract/features/confirmed/widgets/context';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { VatRatioHeader } from '@front/src/components/vat-ratio-header/vat-ratio-header';
import { useProjectSalesInfoContractConfirmedState } from '@front/src/features/project-sales-info/features/contract/features/confirmed/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoContractConfirmedWidget = ({ readOnly, name }: Readonly<Props>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  const {
    d: { detail, statusVariableList, stepVariableList },
    h: { onUpdate },
    isLoading,
  } = useProjectSalesInfoContractConfirmedLogic();
  const value = useMemo(
    () => ({
      d: { detail, statusVariableList, stepVariableList },
      h: { onUpdate },
    }),
    [detail, onUpdate, statusVariableList, stepVariableList]
  );
  const { setReadOnly } = useProjectSalesInfoContractConfirmedState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  if (isLoading) {
    return <LoadingSpinner height="164px" />;
  }
  return (
    <Layout>
      <Header title={name}>
        <VatRatioHeader />
      </Header>
      <Body>
        <ProjectSalesInfoContractConfirmedContext.Provider value={value}>
          <ProjectSalesInfoContractConfirmedTable />
        </ProjectSalesInfoContractConfirmedContext.Provider>
      </Body>
    </Layout>
  );
};

export default ProjectSalesInfoContractConfirmedWidget;
