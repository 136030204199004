import React from 'react';
import { ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalWidget } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/widgets/widget';
import { useProjectSalesInfoAffiliatedCompanyWebHardUpdateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/widgets/useState';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoAffiliatedCompanyWebHardCreateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/create-modal/widgets/useState';
import { ProjectSalesInfoAffiliatedCompanyWebHardCreateModalWidget } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/create-modal/widgets/widget';

export { WebHardModalWrapper as ProjectSalesInfoAffiliatedCompanyWebHardModalWrapper };

const WebHardModalWrapper = () => {
  const { isOpen: isCreateModalOpen } = useProjectSalesInfoAffiliatedCompanyWebHardCreateModalState(
    (state) => ({
      isOpen: state.isOpen,
    }),
    shallow
  );
  const { isOpen: isUpdateModalOpen } = useProjectSalesInfoAffiliatedCompanyWebHardUpdateModalState(
    (state) => ({
      isOpen: state.isOpen,
    }),
    shallow
  );
  if (isCreateModalOpen) {
    return <ProjectSalesInfoAffiliatedCompanyWebHardCreateModalWidget />;
  }
  if (isUpdateModalOpen) {
    return <ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalWidget />;
  }
  return <></>;
};
