import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Paper } from '@mui/material';
import type { FormikHelpers } from 'formik';
import { ErrorMessage, Form, Formik } from 'formik';
import type { UserAddParameter, UserInviteParameter } from '../../parameter';
import { userApi } from '../../api';
import type { UserVO } from '../../domain';
import logo from 'assets/loginLogo.webp';
import DataFieldWithLabel from '../../../layouts/DataFieldLabel';
import Input from '../../../layouts/Input';
import TextBox from '../../../layouts/Text';

const UserAuthenticationForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const email: string | null = searchParams.get('email');
  const authKey: string | null = searchParams.get('authKey');

  if (!email || !authKey) {
    return (
      <Paper sx={{ width: '100%', overflow: 'hidden', padding: '30px', textAlign: 'center' }}>
        <Grid
          item
          sm={12}
        >
          <h2>계정 등록</h2>
        </Grid>
        <> 잘못된 접근입니다.</>
      </Paper>
    );
  }

  const [detail, setDetail] = useState<UserVO | undefined>();

  const handler = {
    submit: (values: any, { setSubmitting, setErrors }: FormikHelpers<any>) => {
      const error: any = {};

      const name: string = values.name;
      if (!name) {
        error.name = '이름 입력은 필수입니다.';
      }

      const username: string = values.username;
      if (!username) {
        error.username = '아이디 입력은 필수입니다.';
      }

      const password: string = values.password;
      if (!password) {
        error.password = '비밀번호 입력은 필수입니다.';
      }

      const passwordCheck: string = values.passwordCheck;
      if (!passwordCheck) {
        error.passwordCheck = '비밀번호 확인 입력은 필수입니다.';
      }

      if (password !== passwordCheck) {
        error.passwordCheck = '비밀번호 확인이 일치하지 않습니다.';
      }

      if (Object.keys(error).length > 0) {
        setErrors(error);
        setSubmitting(false);
        return;
      }

      const params: UserAddParameter = {
        name,
        username,
        password,
        email,
        authKey,
      };
      userApi
        .add(params)
        .then(() => {
          window.alert('가입이 완료되었습니다.');
          navigate('/login');
        })
        .catch((e) => {
          alert(e.response.data.message);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  };

  useEffect(() => {
    if (email && authKey) {
      userApi
        .authenticateInvitation({
          email,
          authKey,
        } as UserInviteParameter)
        .then((value) => {
          setDetail(value);
        });
    }
  }, [email, authKey]);
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
      }}
    >
      {detail && (
        <Formik
          initialValues={{
            name: detail.name,
            email: detail.email,
            username: '',
            password: '',
            passwordCheck: '',
          }}
          onSubmit={handler.submit}
        >
          {({ values, isSubmitting, handleChange, handleSubmit }) => (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: '50%',
                alignItems: 'center',
                flexWrap: 'wrap',
                flexDirection: 'column',
                marginTop: '25dvh',
              }}
            >
              <img
                src={logo}
                width="auto"
                alt="한양풍동실험연구소_로고"
              />
              <h2>계정 등록</h2>
              <Form>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    width: '460px',
                    marginBottom: '10px',
                  }}
                >
                  <DataFieldWithLabel
                    label="이메일"
                    labelPosition="top"
                  >
                    <Input
                      name="email"
                      type="email"
                      autoComplete="off"
                      defaultValue={values.email ?? ''}
                      disabled
                    />
                  </DataFieldWithLabel>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    width: '460px',
                    marginBottom: '10px',
                  }}
                >
                  <DataFieldWithLabel
                    label="이름"
                    labelPosition="top"
                  >
                    <Input
                      name="name"
                      type="name"
                      autoComplete="off"
                      defaultValue={values.name ?? ''}
                      onChange={handleChange}
                      placeholder="이름을 입력하세요"
                      required
                    />
                  </DataFieldWithLabel>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    width: '460px',
                    marginBottom: '10px',
                  }}
                >
                  <DataFieldWithLabel
                    label="아이디"
                    labelPosition="top"
                  >
                    <Input
                      type="email"
                      autoComplete="off"
                      name="username"
                      value={values.username}
                      onChange={handleChange}
                      placeholder="사용할 아이디를 입력하세요"
                      required
                    />
                  </DataFieldWithLabel>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    width: '460px',
                    marginBottom: '10px',
                  }}
                >
                  <DataFieldWithLabel
                    label="비밀번호"
                    labelPosition="top"
                  >
                    <Input
                      type="password"
                      autoComplete="off"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      placeholder="신규 비밀번호는 영문, 숫자, 특수문자 조합 8자리 이상으로 입력해 주세요"
                      required
                    />
                  </DataFieldWithLabel>
                  <ErrorMessage
                    name="password"
                    render={(msg) => <TextBox variant={'body20'}>{msg}</TextBox>}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    width: '460px',
                    marginBottom: '10px',
                  }}
                >
                  <DataFieldWithLabel
                    label="비밀번호"
                    labelPosition="top"
                  >
                    <Input
                      type="password"
                      autoComplete="off"
                      name="passwordCheck"
                      value={values.passwordCheck}
                      onChange={handleChange}
                      placeholder="신규 비밀번호를 다시 입력해주세요."
                      required
                    />
                  </DataFieldWithLabel>
                  <ErrorMessage
                    name="passwordCheck"
                    render={(msg) => <TextBox variant={'body20'}>{msg}</TextBox>}
                  />
                </Box>
                <Box
                  sx={{
                    width: '460px',
                  }}
                >
                  <Button
                    sx={{
                      width: '100%',
                    }}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? ' 가입 요청 중' : '가입'}
                  </Button>
                </Box>
              </Form>
            </Box>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default UserAuthenticationForm;
