import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import { projectSalesInfoEstimationEstimationUpdateModalApi } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/update-modal/query/api';
import type { ProjectEstimationParameter } from '@front/src/features/project-sales-info/features/estimation/features/estimation/types/parameter';
import { useCustomDialog } from '@front/src/features/dialog';

export { mutation as projectSalesInfoEstimationEstimationUpdateModalMutation };

const mutation = {
  useUpdate: () => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectEstimationParameter) =>
        projectSalesInfoEstimationEstimationUpdateModalApi.update(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'estimation', 'estimation', 'list'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'estimation', 'confirmed'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'header'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
