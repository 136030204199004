import React from 'react';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableHeadUI from '@front/src/components/components-with-design/compound/table/TableHeadUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import { approvalBoxQuery } from '@front/src/features/approval-box/query/query';
import useApprovalBoxStore from '@front/src/features/approval-box/useState';
import ApprovalBoxTableRow from '@front/src/features/approval-box/components/ApprovalBoxTableRow';
import { NoResult } from '@front/src/components/layout/table/no-result';

export default function ApprovalBoxTable() {
  const boxSearchParams = useApprovalBoxStore((state) => state.boxSearchParams);
  const { data: approvalBoxList } = approvalBoxQuery.useGetApprovalBoxSearchList(boxSearchParams);
  return (
    <TableContainerUI>
      <TableTableUI>
        <TableHeadUI>
          <TableRowUI>
            <TableCellUI
              isHead={true}
              sx={{ width: '12%' }}
            >
              제목/요청자
            </TableCellUI>
            <TableCellUI
              isHead={true}
              sx={{ width: '4%' }}
            >
              요청일시
            </TableCellUI>
            <TableCellUI
              isHead={true}
              sx={{ width: '2%' }}
            >
              상태
            </TableCellUI>
          </TableRowUI>
        </TableHeadUI>
        <TableBodyUI>
          {approvalBoxList?.length === 0 && <NoResult colSpan={3} />}
          {approvalBoxList?.map((item) => (
            <ApprovalBoxTableRow
              key={item.id}
              item={item}
            />
          ))}
        </TableBodyUI>
      </TableTableUI>
    </TableContainerUI>
  );
}
