import { useQuery } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';

const workInterimReportDateQuery = {
  useGetList: (id?: number, menuId?: number) => {
    const { data } = useQuery({
      queryKey: ['work', 'interim-report-date', id],
      queryFn: () => workWorkApi.getScheduleInterimReportDate(id, menuId),
      suspense: true,
    });
    return {
      data,
    };
  },
};

export default workInterimReportDateQuery;
