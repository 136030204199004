import type { ReactNode } from 'react';
import React from 'react';
import { Box } from '@mui/material';

export { Layout as AccountingSettingsContentLayoutLayout };

interface Props {
  children: ReactNode;
}

const Layout = ({ children }: Props) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
    }}
  >
    {children}
  </Box>
);
