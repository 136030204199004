import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

export default function WorkCarouselInputFile() {
  const { control } = useFormContext();
  const {
    field: { onChange },
  } = useController({ control, name: 'images', rules: { required: '이미지를 추가해주세요' } });
  const handleChange = (e) => {
    onChange(Array.from(e.target.files));
  };
  return (
    <input
      type="file"
      multiple
      onChange={handleChange}
    />
  );
}
