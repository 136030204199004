import notificationQuery from '@front/src/features/notification/query/query';
import useNotificationStore from '@front/src/features/notification/useState';

const useGetNotificationList = () => {
  const searchFilter = useNotificationStore((state) => state.searchFilter);
  const reducedFilter = Object.entries(searchFilter).reduce((acc, [key, value]) => {
    if (value !== ' ' && value) {
      acc[key] = value;
    }
    return acc;
  }, {});
  const { data } = notificationQuery.useGetList(reducedFilter);
  const list = data?.searchList;
  const bookmarkedList = list?.filter((item) => item.isBookmarked);
  const filteredList = list?.filter((item) => !item.isBookmarked);

  return {
    unreadCount: data?.unreadCount,
    bookmarkCount: data?.bookmarkCount,
    bookmarkedList,
    filteredList,
    totalList: list,
  };
};

export default useGetNotificationList;
