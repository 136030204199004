import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { FileItemRecordView } from '@front/src/features/affiliated-company/types/view';
import type { AffiliatedCompanyOutlineBusinessRegistrationFileUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/number/features/business-registration/types/parameter';

export { Context as AffiliatedCompanyOutlineNumberBusinessRegistrationFileContext };

interface State {
  d: {
    businessRegistrationNumberFile?: FileItemRecordView;
  };
  h: {
    onOutlineBusinessRegistrationFileUpdate: (
      params: AffiliatedCompanyOutlineBusinessRegistrationFileUpdateParameter
    ) => void;
  };
}

const Context = createContext<State>({
  d: {},
  h: {
    onOutlineBusinessRegistrationFileUpdate: noOp,
  },
});
