import type { ReactNode } from 'react';
import React from 'react';
import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import TitleUI from '@front/src/components/components-with-design/component/TitleUI';
import { ColorPalette } from '@front/assets/theme';

const SectionWrapperUI = {
  Layout,
  TitleWrap,
  TitleRightWrap,
  TitleWithSubTitleWrap,
};

export default SectionWrapperUI;

function Layout({ ...rest }: Readonly<BoxProps>) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.4rem',
      }}
      {...rest}
    />
  );
}

interface TitleWrapProps {
  left?: string | null;
  right?: ReactNode;
}

function TitleWrap({ left, right }: Readonly<TitleWrapProps>) {
  if (!left && !right) return <></>;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <TitleUI size="medium">{left}</TitleUI>
      {right}
    </Box>
  );
}

interface TitleWithSubTitleWrapProps {
  title: string;
  subTitle: string;
  right?: ReactNode;
  children?: ReactNode;
}

function TitleWithSubTitleWrap({
  subTitle,
  title,
  right,
  children,
}: Readonly<TitleWithSubTitleWrapProps>) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <TitleUI size="medium">{title}</TitleUI>
        <Box
          sx={{
            width: '16px',
            border: `1px solid ${ColorPalette.line.line02}`,
            height: '0px',
            transform: 'rotate(-90deg)',
          }}
        />
        <TitleUI size="medium">{subTitle}</TitleUI>
        {children}
      </Box>
      {right}
    </Box>
  );
}

interface TitleRightWrapProps {
  children: ReactNode;
}

function TitleRightWrap({ children }: TitleRightWrapProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '12px',
      }}
    >
      {children}
    </Box>
  );
}
