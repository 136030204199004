import { projectSalesInfoProjectDivisionRepository } from '@front/src/features/project-sales-info/features/project-division/repository/form/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';

export { useLogic as ProjectSalesInfoProjectDivisionLogic };
const useLogic = () => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { isLoading, data: projectDivision } =
    projectSalesInfoProjectDivisionRepository.useProjectDivisionGet(id!);
  const { run: useProjectDivisionUpdate } =
    projectSalesInfoProjectDivisionRepository.useProjectDivisionUpdate(id!);
  const { run: useCodeUpdate } = projectSalesInfoProjectDivisionRepository.useCodeCreate(id!);
  const { data: businessTypeOptionList } =
    adminManageableVariableRepository.useListGet('영업관리-사업유형');
  const { data: bidSortOptionList } =
    adminManageableVariableRepository.useListGet('영업관리-입찰구분');
  const h = {
    useProjectDivisionUpdate,
    useCodeUpdate: () => useCodeUpdate(),
  };
  const d = { projectDivision, businessTypeOptionList, bidSortOptionList };
  return { d, h, isLoading, id };
};
