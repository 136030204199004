import React from 'react';
import { ProjectSalesInfoSubjectReviewModalExperimentTargetProvider } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/experiment-target/widgets/provider';
import { ProjectSalesInfoSubjectReviewModalExperimentTargetDetail } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/experiment-target/components/detail';

export { Widget as ProjectSalesInfoSubjectReviewModalExperimentTargetWidget };

const Widget = () => (
  <ProjectSalesInfoSubjectReviewModalExperimentTargetProvider>
    <ProjectSalesInfoSubjectReviewModalExperimentTargetDetail />
  </ProjectSalesInfoSubjectReviewModalExperimentTargetProvider>
);
