import { baseUrl } from '@front/src/features/affiliated-company/utils/constant';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { default as axios } from '@front/src/config/axios';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getMeetingHistoryList: (id?) => `${baseUrl}/${id}/meeting-history`,
  deleteMeetingHistory: () => `${baseUrl}/meeting-history`,
};

export const affiliatedCompanyMeetingHistoryApi = {
  getMeetingHistoryList: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getMeetingHistoryList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  deleteMeetingHistory: async (params: AffiliatedCompanyIdListDeleteParameter, menuId) => {
    await axios.delete(url.deleteMeetingHistory(), {
      data: params,
      headers: getMenuIdHeaders(menuId),
    });
  },
};
