import React, { useCallback, useContext, useEffect } from 'react';
import Button from '@front/layouts/Button';
import { useContractEarningsItemState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/useState';
import { useFormContext, useWatch } from 'react-hook-form';
import { ProjectSalesInfoContractEarningsItemCreateModalContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/widgets/context';
import type { SmProjectCollectionParameter } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/parameters';
import classes from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/components/detail-create-modal-btn.module.scss';
import { useProjectSalesInfoCollectionManagementModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/widgets/useState';
import type { ProjectCollectionManagementParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/types/parameter';
import {
  convertEmptyClientStateToNull,
  convertEmptyToNull,
  convertYNToBooleanForParameter,
} from '@front/src/utils';
import { useSnackbar } from '@front/src/features/snackbar';
import { useShallow } from 'zustand/react/shallow';
import {formatDateOrNull} from "@front/src/features/project-sales-info/utils";

export { DetailCreateModalBtn as ProjectSalesInfoContractEarningsItemDetailCreateModalBtn };

interface Props {
  isCollectionCompleted: boolean;
}

const DetailCreateModalBtn = ({ isCollectionCompleted }: Props) => {
  const setIsDetailCreateModalOpen = useContractEarningsItemState(
    (state) => state.setIsDetailCreateModalOpen
  );
  const collectionManagementList = useProjectSalesInfoCollectionManagementModalState(
    (state) => state.collectionManagementList
  );
  const {
    h: { onCreate },
  } = useContext(ProjectSalesInfoContractEarningsItemCreateModalContext);
  const {
    handleSubmit,
    formState: { errors },
  } = useFormContext<SmProjectCollectionParameter>();
  const { show } = useSnackbar();
  const hasError = Object.keys(errors).length > 0;
  useEffect(() => {
    if (hasError) {
      show({
        message: '필수값 입력 요망',
      });
    }
  }, [hasError, show]);
  const { control } = useFormContext();
  const dueDate = useWatch({ name: 'dueDate', control });
  const onSubmit = handleSubmit((data) => {
    onCreate(
      convertEmptyToNull({
        ...data,
        claimLetterSentDate: formatDateOrNull(data.claimLetterSentDate),
        taxInvoiceIssuedDate: formatDateOrNull(data.taxInvoiceIssuedDate),
        depositDate: formatDateOrNull(data.depositDate),
        dueDate: formatDateOrNull(data.dueDate),
        CMAmount: +(data.CMAmount as string).replace(/,/g, ''),
        convertedCMAmount: +(data.convertedCMAmount as string).replace(/,/g, ''),
        collectionCMAmount: +(data.collectionCMAmount as string).replace(/,/g, ''),
        convertedCollectionCMAmount: +(data.convertedCollectionCMAmount as string).replace(
          /,/g,
          ''
        ),
        amount: +(data.amount as string).replace(/,/g, ''),
        reviewAmount: +(data.reviewAmount as string).replace(/,/g, ''),
        netAmount: +(data.netAmount as string).replace(/,/g, ''),
        collectionAmount: +(data.collectionAmount as string).replace(/,/g, ''),
        netCollectionAmount: +(data.netCollectionAmount as string).replace(/,/g, ''),
        collectionReviewAmount: +(data.collectionReviewAmount as string).replace(/,/g, ''),
        delayPeriod: (data.delayPeriod as string) ? +data.delayPeriod : '',
        isCm: convertYNToBooleanForParameter(data.isCm) ?? '',
        collectionPeriod: (data.collectionPeriod as string) ? +data.collectionPeriod : '',
        collectionManagementList: convertEmptyClientStateToNull(
          collectionManagementList as unknown as ProjectCollectionManagementParams[]
        ),
      }) as SmProjectCollectionParameter
    );
    setIsDetailCreateModalOpen();
  });
  const handleSaveClick = useCallback(() => {
    const isNoData =
      !collectionManagementList.at(-1)?.collectionDueDate ||
      !collectionManagementList.at(-1)?.collectionForecastDate ||
      !collectionManagementList.at(-1)?.collectionPossibilityRatio;
    if ((isCollectionCompleted && !dueDate) || (!isCollectionCompleted && (!dueDate || isNoData))) {
      show({
        message: '필수값 입력 요망',
      });
      return;
    }
    onSubmit();
  }, [isCollectionCompleted, onSubmit, dueDate, collectionManagementList, show]);
  const { readOnly } = useContractEarningsItemState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <div className={classes.btn__group}>
      <Button
        fullWidth={true}
        shape="basic2"
        onClick={() => setIsDetailCreateModalOpen()}
      >
        취소
      </Button>
      <Button
        fullWidth={true}
        onClick={handleSaveClick}
        disabled={readOnly}
      >
        저장
      </Button>
    </div>
  );
};
