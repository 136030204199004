import React, { Suspense } from 'react';
import { Box } from '@mui/material';
import NotificationButtonRoute from '@front/src/app/component/notificationButton';
import AccountButtonRoute from '@front/src/app/component/accountButton';
import LogoutButtonRoute from '@front/src/app/component/logoutButton';
import Divider from '@front/layouts/Divider';
import { GlobalNavBarHamburger } from '@front/src/features/global-nav-bar/components/hamburger';
import { GlobalNavBarMenuList } from '@front/src/features/global-nav-bar/components/menu-list';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import ApprovalButton from "@front/src/app/view/ApprovalButton";
import {ColorPalette} from "@front/assets/theme";

export { ClosedChildren as GlobalNavBarClosedChildren };
const ClosedChildren = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '49px',
      }}
    >
      <GlobalNavBarHamburger />
    </Box>
    <Divider
      sx={{
        m: 0,
        borderBottom: '1px solid #49576F',
      }}
    />
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'space-around',
        gap: '10px',
        py: '12px',
      }}
    >
      <Suspense
        fallback={
          <LoadingSpinner
            width="24px"
            height="24px"
          />
        }
      >
        <NotificationButtonRoute />
      </Suspense>
      <Suspense
        fallback={
          <LoadingSpinner
            width="24px"
            height="24px"
          />
        }
      >
        <ApprovalButton />
      </Suspense>
      <AccountButtonRoute />
      <LogoutButtonRoute />
    </Box>
    <Box sx={{
      height: 'calc(100dvh - 240px)',
      overflowX: 'hidden',
      overflowY: 'auto',
      scrollbarGutter: 'stable',
      '&::-webkit-scrollbar': {
        width: '2px',
        backgroundColor: ColorPalette._697183,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: ColorPalette._4c576d,
      },
    }}>
      <GlobalNavBarMenuList />
    </Box>
  </Box>
);
