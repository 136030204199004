import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { ProjectSalesInfoActivityUserItemContext } from '@front/src/features/project-sales-info/features/activity/widgets/activity-user-item/provider/context';
import { useProjectSalesInfoActivityUserItemLogic } from '@front/src/features/project-sales-info/features/activity/widgets/activity-user-item/provider/useLogic';

export { Provider as ProjectSalesInfoActivityUserItemProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { onCreate, onUpdate, onDelete } = useProjectSalesInfoActivityUserItemLogic();
  const value = useMemo(
    () => ({
      onCreate,
      onUpdate,
      onDelete,
    }),
    [onCreate, onUpdate, onDelete]
  );
  return (
    <ProjectSalesInfoActivityUserItemContext.Provider value={value}>
      {children}
    </ProjectSalesInfoActivityUserItemContext.Provider>
  );
};
