import { personBaseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanyPersonOutlineBasicUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/basic/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getPersonOutlineBasic: (id?) => `${personBaseUrl}/${id}/outline-basic`,
  updatePersonOutlineBasic: (id?) => `${personBaseUrl}/${id}/outline-basic`,
};

export const affiliatedCompanyPersonOutlineBasicApi = {
  getPersonOutlineBasic: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getPersonOutlineBasic(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updatePersonOutlineBasic: async (
    params: AffiliatedCompanyPersonOutlineBasicUpdateParameter,
    menuId
  ) => {
    await axios.put(url.updatePersonOutlineBasic(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
};
