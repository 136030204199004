import React, { memo, useCallback, useRef } from 'react';
import Input from '@front/layouts/Input';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import type { CampaignUpdateNameParameter } from '@front/ost_campaign/parameter';
import { useDispatch } from 'react-redux';

interface Props {
  name?: string;
  updateName: (params: CampaignUpdateNameParameter) => void;
}

const Name = ({ name, updateName }: Props) => {
  const dispatch = useDispatch();
  const refCampaignNameInput = useRef<HTMLInputElement>(null);

  const onBlur = useCallback(
    (e) => {
      const value = (e.target as HTMLInputElement).value;
      if (!value.trim()) {
        dispatch(
          snackbarAction.show({
            message: '캠페인 이름을 입력 하세요.',
            severity: SnackbarSeverityType.warning,
          })
        );
        if (refCampaignNameInput.current && name) {
          refCampaignNameInput.current.value = name;
        }
        return;
      }
      updateName({
        name: value,
      });
    },
    [name, updateName, dispatch]
  );

  return (
    <DataFieldWithLabel
      label="캠페인명"
      labelSX={{ minWidth: '80px' }}
    >
      <Input
        key={name}
        inputRef={refCampaignNameInput}
        variant="outlined"
        defaultValue={name}
        onBlur={onBlur}
      />
    </DataFieldWithLabel>
  );
};

const NameForm = memo(Name);

export default NameForm;
