import React from 'react';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/TableBody';
import type { WorkApprovalDocumentView } from '@front/src/features/work/features/work/features/approval/types/view';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import { useFormContext } from 'react-hook-form';
import PlusOne from '@mui/icons-material/PlusOne';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import useApprovalDocumentStore from '@front/src/features/drawer-approval-document/useState';
import { useShallow } from 'zustand/react/shallow';

export default function WorkApprovalTableRow({
  item,
  ...rest
}: Readonly<UIBuilderTableRowProps<WorkApprovalDocumentView>>) {
  const { setValue } = useFormContext();
  const setLeftDrawerState = useGlobalNavBarState((state) => state.setLeftDrawerState);
  const { setDocumentId, setParams } = useApprovalDocumentStore(
    useShallow((state) => ({
      setDocumentId: state.setDocumentId,
      setParams: state.setParams,
    }))
  );
  const onOpenApprovalDocument = () => {
    setLeftDrawerState('approval-document');
    setDocumentId(item.approvalDocument.id);
    setParams({
      where: 'approval-document',
      noteId: 0,
    });
  };
  const onOpenModal = () => {
    setValue('workId', item.workId);
  };
  const itemList = [
    {
      text: '기안서 열람',
      icon: PlusOne,
      action: onOpenApprovalDocument,
    },
    {
      text: '상신 시점 업무 열람',
      icon: PlusOne,
      action: onOpenModal,
    },
  ];
  return (
    <UiBuilderTableRow
      item={item}
      itemList={itemList}
      {...rest}
    />
  );
}
