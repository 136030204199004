import React, { useCallback, useContext } from 'react';
import { AffiliatedCompanySelectorWidget } from '@front/src/features/affiliated-company-selector/widgets/widget';
import { useFormContext, useWatch } from 'react-hook-form';
import { AffiliatedCompanyPersonDetailModalCharacterBasicContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/basic/widgets/context';

export { Selector as AffiliatedCompanyPersonDetailModalCharacterBasicSelector };

interface Props {
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
}

const Selector = ({ onSubmit }: Props) => {
  const { readOnly } = useContext(AffiliatedCompanyPersonDetailModalCharacterBasicContext);
  const { setValue, control } = useFormContext();
  const handleSelector = useCallback(
    async (company) => {
      setValue('favoriteAffiliatedCompany', company);
      await onSubmit();
    },
    [onSubmit, setValue]
  );
  const favoriteAffiliatedCompany = useWatch({ name: 'favoriteAffiliatedCompany', control });
  return (
    <div
      style={{
        width: '200px',
      }}
    >
      <AffiliatedCompanySelectorWidget
        disabled={readOnly}
        value={{
          companyId: favoriteAffiliatedCompany?.id,
          textValue: favoriteAffiliatedCompany?.name ?? '',
        }}
        onCompanySelect={handleSelector}
        usePersonSelector={false}
      />
    </div>
  );
};
