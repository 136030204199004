import { accountingUploadRepository } from '@front/src/features/accounting/features/upload/features/ecount-data/repository/repository';
import { useAccountingUploadEcountDataState } from '@front/src/features/accounting/features/upload/features/ecount-data/useState';
import { shallow } from 'zustand/shallow';

export { useLogic as useAccountingUploadEcountDataListLogic };

const useLogic = (isModalMode?: boolean) => {
  const { data: list } = accountingUploadRepository.useGetList();
  const { ecountFileType } = useAccountingUploadEcountDataState(
    (state) => ({
      ecountFileType: state.ecountFileType,
    }),
    shallow
  );
  return {
    list: isModalMode ? list?.filter((item) => item.category === ecountFileType) : list,
  };
};
