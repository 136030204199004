import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyExtraCompanyQuery } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/query/query';
import { affiliatedCompanyExtraCompanyMutation } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/query/mutation';

export const affiliatedCompanyExtraCompanyRepository = {
  usePersonOutlineExtraCompanyCreate: (menuId) => {
    const { mutate } =
      affiliatedCompanyExtraCompanyMutation.usePersonOutlineExtraCompanyCreate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  usePersonOutlineExtraCompanyGet: (id?: number, menuId?) => {
    const { data, isLoading } = affiliatedCompanyExtraCompanyQuery.usePersonOutlineExtraCompanyGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
  usePersonOutlineExtraCompanyDelete: (menuId) => {
    const { mutate } =
      affiliatedCompanyExtraCompanyMutation.usePersonOutlineExtraCompanyDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
