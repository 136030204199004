import React, { Suspense } from 'react';
import { AffiliatedCompanyProjectListProvider } from '@front/src/features/affiliated-company/features/project/widgets/list/provider/provider';
import { AffiliatedCompanyProjectList } from '@front/src/features/affiliated-company/features/project/widgets/list/components/list';
import { AffiliatedCompanyProjectTableFilterRow } from '@front/src/features/affiliated-company/features/project/widgets/list/components/table-filter-row';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { AffiliatedCompanyProjectListTableBody } from '@front/src/features/affiliated-company/features/project/widgets/list/components/table-body';

export { Widget as AffiliatedCompanyProjectListWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <Suspense fallback={<LoadingSpinner height="60px" />}>
    <AffiliatedCompanyProjectListProvider menuId={menuId}>
      <AffiliatedCompanyProjectListTableBody>
        <AffiliatedCompanyProjectTableFilterRow />
        <AffiliatedCompanyProjectList />
      </AffiliatedCompanyProjectListTableBody>
    </AffiliatedCompanyProjectListProvider>
  </Suspense>
);
