import type { FocusEvent } from 'react';
import React, { memo, useCallback } from 'react';
import type { FormikProps } from 'formik';

import type { CampaignMemoId, CampaignMemoVO } from '@front/ost_campaign/domain';
import FormUpdateMemo from '@front/ost_campaign/view/memo/formUpdate';
import type { CampaignMemoUpdateParameter } from '@front/ost_campaign/parameter';

interface Props {
  formik: FormikProps<CampaignMemoUpdateParameter>;
  memo: CampaignMemoVO;
  onSave: (campaignMemoId: CampaignMemoId) => () => void;
  onCancel: (campaignMemoId: CampaignMemoId) => () => void;
}

const MemoUpdateFormService = ({ formik, memo, onSave, onCancel }: Props) => {
  const blurInputMemoHandler = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const value = e.target.value || undefined;
      if (formik.values.description !== value) {
        formik.setFieldValue('description', value);
      }
    },
    [formik]
  );

  return (
    <FormUpdateMemo
      formik={formik}
      memo={memo}
      onBlur={blurInputMemoHandler}
      onSave={onSave}
      onCancel={onCancel}
    />
  );
};

const FormUpdateMemoService = memo(MemoUpdateFormService);

export default FormUpdateMemoService;
