import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { ProjectDesignOutlineParameter } from '@front/src/features/project-sales-info/features/design-outline/types/parameter';

const url = {
  getDesignOutline: (id: number) => `${projectSalesInfoURL}/${id}/design-outline`,
  updateDesignOutline: (id: number) => `${projectSalesInfoURL}/design-outline/${id}`,
  getCityData2: (code?: string) => `/api/address?code=${code?.slice(0, 2)}00000000`,
  getCityData1: () => '/api/address?code=00000000',
};

export const projectSalesInfoDesignOutlineApi = {
  useProjectDesignOutlineGet: async (id: number) => {
    const { data } = await axios.get(url.getDesignOutline(id));
    return data;
  },
  useProjectDesignOutlineUpdate: async (params: ProjectDesignOutlineParameter) => {
    await axios.put(url.updateDesignOutline(params.id!), params);
  },

  useCity1DataGet: async () => {
    const { data } = await axios.get(url.getCityData1());
    return data;
  },
  useCity2DataGet: async (code?: string) => {
    const { data } = await axios.get(url.getCityData2(code));
    return data;
  },
};
