import { useQuery } from 'react-query';
import type { ProjectSalesInfoBidInfoView } from '@front/src/features/project-sales-info/features/bid/features/bid-information/types/view';
import { projectSalesInfoBidInfoUpdateModalApi } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/update-modal/query/api';
import type { ProjectEstimationView } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/types/view';

export const projectSalesInfoBidInfoUpdateModalQuery = {
  useProjectSalesInfoBidInfoDetailGet: (id: number) => {
    const { data, isLoading } = useQuery<ProjectSalesInfoBidInfoView>({
      queryKey: ['project-sales-info', 'bid-info', 'detail', id],
      queryFn: () => projectSalesInfoBidInfoUpdateModalApi.getBidInfoDetail(id),
    });
    return {
      data,
      isLoading,
    };
  },
  useEstimateIdListGet: (id: number) => {
    const { data, isLoading } = useQuery<ProjectEstimationView[]>({
      queryKey: ["project-sales-info', 'bid-info', 'create-modal-query", id],
      queryFn: () => projectSalesInfoBidInfoUpdateModalApi.getEstimateId(id),
    });
    return { data, isLoading };
  },
};
