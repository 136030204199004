import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { IdListDeleteParameter } from '@front/src/types/parameter';
import type { ProjectContractContractView } from '@front/src/features/project-sales-info/features/contract/features/contract/types/view';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';

export { context as ProjectSalesInfoContractContractContext };

interface State {
  d: {
    list?: ProjectContractContractView[];
    CMMethodOptionList?: VariableInformationView[];
  };
  h: {
    onDelete: (params: IdListDeleteParameter) => void;
  };
}

const context = createContext<State>({
  d: {},
  h: {
    onDelete: noOp,
  },
});
