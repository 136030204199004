import { create } from 'zustand';

interface State {
  detailId?: number;
  setDetailId: (id: number) => void;
  isManagementModalOpen: boolean;
  setIsManagementModalOpen: () => void;
  defaultReviewAmount: string;
  setDefaultReviewAmount: (value: string) => void;
  defaultNetAmount: string;
  setDefaultNetAmount: (value: string) => void;
  defaultCollectionReviewAmount: string;
  setDefaultCollectionReviewAmount: (value: string) => void;
  defaultNetCollectionAmount: string;
  setDefaultNetCollectionAmount: (value: string) => void;
}

export { useState as useProjectSalesInfoContractEarningsItemCreateModalState };

const useState = create<State>((set) => ({
  setDetailId: (detailId: number) => set({ detailId }),
  isManagementModalOpen: false,
  setIsManagementModalOpen: () =>
    set(({ isManagementModalOpen }) => ({ isManagementModalOpen: !isManagementModalOpen })),
  defaultReviewAmount: '',
  setDefaultReviewAmount: (defaultReviewAmount) =>
    set({
      defaultReviewAmount,
    }),
  defaultNetAmount: '',
  setDefaultNetAmount: (defaultNetAmount) =>
    set({
      defaultNetAmount,
    }),
  defaultCollectionReviewAmount: '',
  setDefaultCollectionReviewAmount: (defaultCollectionReviewAmount) =>
    set({
      defaultCollectionReviewAmount,
    }),
  defaultNetCollectionAmount: '',
  setDefaultNetCollectionAmount: (defaultNetCollectionAmount) =>
    set({
      defaultNetCollectionAmount,
    }),
}));
