import type { DefaultFunction } from '@front/type/Function';
import React from 'react';
import classes from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form-button.module.scss';
import Button from '@front/layouts/Button';
import { useAffiliatedCompanyMeetingHistoryState } from '@front/src/features/affiliated-company/features/meeting-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

interface Props {
  onCancel: DefaultFunction;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
}

export { FormButton as AffiliatedCompanyMeetingHistoryModalFormButton };
const FormButton = ({ onCancel, onSubmit }: Props) => {
  const { readOnly } = useAffiliatedCompanyMeetingHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <div className={classes.footer}>
      <Button
        shape={'basic2'}
        onClick={onCancel}
        disabled={readOnly}
      >
        취소
      </Button>
      <Button
        onClick={onSubmit}
        disabled={readOnly}
      >
        저장
      </Button>
    </div>
  );
};
