import type { UserVO } from '@front/user/domain';
import type { AxiosResponse } from 'axios';
import type { ListDepartment } from '@front/department_tree/entity';
import type { UserWithDepartmentIdQuery } from '@front/user/query';
import { default as axios } from '@front/src/config/axios';

const url = {
  getUser: (id) => `/api/admin/user/${id}`,
  getDepartmentList: () => '/api/department/all',
  getUserList: () => '/api/user',
};

const userSelectorApi = {
  getUser: async <T = UserVO>(id: number) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getUser(id));
    return data;
  },
  getDepartmentList: async <T = ListDepartment[]>() => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getDepartmentList());
    return data;
  },
  getUserList: async <T = UserVO[], R = UserWithDepartmentIdQuery>(params: R) => {
    const { data } = await axios.get<T, AxiosResponse<T, R>>(url.getUserList(), { params });
    return data;
  },
};

export default userSelectorApi;
