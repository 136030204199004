import { default as axios } from '@front/src/config/axios';

import type { CampaignMemoQuery, CampaignQuery } from '@front/ost_campaign/query';
import type { UserId } from '@front/user/domain';
import type { ProposalId, ProposalVO } from '@front/ost_proposal/domain';
import type {
  CampaignAddParameter,
  CampaignBannerUpdateContentParameter,
  CampaignBannerUpdateIsExposedParameter,
  CampaignMemoAddParameter,
  CampaignMemoUpdateParameter,
  CampaignUpdateApprovalParameter,
  CampaignUpdateEndDateParameter,
  CampaignUpdateEstimationCompletionRateParameter,
  CampaignUpdateEstimationEndDateParameter,
  CampaignUpdateEstimationStartDateParameter,
  CampaignUpdateIsNoticeExposedParameter,
  CampaignUpdateNameParameter,
  CampaignUpdateNoticeParameter,
  CampaignUpdateParameter,
  CampaignUpdateProposalCategoryParameter,
  CampaignUpdateStartDateParameter,
  CampaignUpdateStatusParameter,
  CampaignUpdateVoteEndDateParameter,
  CampaignUpdateVoteStartDateParameter,
  EstimatorListUpsertParameter,
  ProposalCategoryAddParameter,
  ProposalPaymentDateParameter,
} from '@front/ost_campaign/parameter';
import type {
  CampaignBannerId,
  CampaignEstimatorVO,
  CampaignId,
  CampaignMemoId,
  CampaignMemoVO,
  CampaignVO,
  ProposalApprovalId,
  ProposalCategoryId,
  ProposalCategoryVO,
} from '@front/ost_campaign/domain';

class CampaignAPI {
  async add(params: CampaignAddParameter): Promise<void> {
    const { data } = await axios.post('/api/ost/campaign', params);
    return data;
  }

  async getCampaignList(query: CampaignQuery): Promise<void> {
    const { data } = await axios.get(
      `/api/ost/campaign?keyword=${query.keyword}&status=${query.status}`
    );
    return data;
  }

  async getOne(id: CampaignId): Promise<void> {
    const { data } = await axios.get(`/api/ost/campaign/${id}`);
    return data;
  }

  async delete(campaignId: CampaignId): Promise<void> {
    const { data } = await axios.delete(`/api/ost/campaign/${campaignId}`);
    return data;
  }

  async update(id: CampaignId, params: CampaignUpdateParameter): Promise<CampaignVO> {
    const { data } = await axios.patch(`/api/ost/campaign/${id}`, params);
    return data;
  }

  async addProposalCategory(id: CampaignId, params: ProposalCategoryAddParameter): Promise<void> {
    const { data } = await axios.post(`/api/ost/campaign/${id}/proposal-category`, params);
    return data;
  }

  async getProposalCategoryList(id: CampaignId): Promise<ProposalCategoryVO[]> {
    const { data } = await axios.get(`/api/ost/campaign/${id}/proposal-category`);
    return data;
  }

  async deleteProposalCategory(
    campaignId: CampaignId,
    proposalCategoryId: ProposalCategoryId
  ): Promise<void> {
    const { data } = await axios.delete(
      `/api/ost/campaign/${campaignId}/proposal-category/${proposalCategoryId}`
    );
    return data;
  }

  async getEstimatorList(campaignId: CampaignId): Promise<CampaignEstimatorVO[]> {
    const { data } = await axios.get(`/api/ost/campaign/${campaignId}/estimator-list`);
    return data;
  }

  async upsertCampaignEstimatorList(
    campaignId: CampaignId,
    params: EstimatorListUpsertParameter
  ): Promise<void> {
    const { data } = await axios.put(`/api/ost/campaign/${campaignId}/estimator-list`, params);
    return data;
  }

  async getProposalList(campaignId: CampaignId): Promise<ProposalVO[]> {
    const { data } = await axios.get(`/api/ost/campaign/${campaignId}/proposal-list`);
    return data;
  }

  async getProposalApprovalList(campaignId: CampaignId): Promise<ProposalVO[]> {
    const { data } = await axios.get(`/api/campaign-approval/${campaignId}`);
    return data;
  }

  async upsertProposalEstimatorList(
    proposalId: ProposalId,
    params: EstimatorListUpsertParameter
  ): Promise<void> {
    const { data } = await axios.put(`/api/ost/proposal/${proposalId}/estimator-list`, params);
    return data;
  }

  async deleteCampaignEstimator(campaignId: CampaignId, estimatorId: UserId): Promise<void> {
    const { data } = await axios.delete(`/api/ost/campaign/${campaignId}/estimator/${estimatorId}`);
    return data;
  }

  async upsertProposalPaymentDate(
    proposalId: ProposalId,
    params: ProposalPaymentDateParameter
  ): Promise<void> {
    const { data } = await axios.put(`/api/ost/proposal/${proposalId}/payment-date`, params);
    return data;
  }

  async addMemo(campaignId: CampaignId, params: CampaignMemoAddParameter): Promise<void> {
    const { data } = await axios.post(`/api/campaign/${campaignId}/memo`, params);
    return data;
  }

  async getMemoList(campaignId: CampaignId, query: CampaignMemoQuery): Promise<CampaignMemoVO[]> {
    const { data } = await axios.get(`/api/campaign/${campaignId}/memo?keyword=${query.keyword}`);
    return data;
  }

  async updateMemo(
    campaignId: CampaignId,
    campaignMemoId: CampaignMemoId,
    params: CampaignMemoUpdateParameter
  ): Promise<CampaignMemoVO[]> {
    const { data } = await axios.put(`/api/campaign/${campaignId}/memo/${campaignMemoId}`, params);
    return data;
  }

  async deleteMemo(
    campaignId: CampaignId,
    campaignMemoId: CampaignMemoId
  ): Promise<CampaignMemoVO[]> {
    const { data } = await axios.delete(`/api/campaign/${campaignId}/memo/${campaignMemoId}`);
    return data;
  }

  async updateName(id: CampaignId, params: CampaignUpdateNameParameter): Promise<void> {
    const { data } = await axios.put(`/api/ost/campaign/${id}/name`, params);
    return data;
  }

  async updateStartDate(id: CampaignId, params: CampaignUpdateStartDateParameter): Promise<void> {
    const { data } = await axios.put(`/api/ost/campaign/${id}/start-date`, params);
    return data;
  }

  async updateEndDate(id: CampaignId, params: CampaignUpdateEndDateParameter): Promise<void> {
    const { data } = await axios.put(`/api/ost/campaign/${id}/end-date`, params);
    return data;
  }

  async updateEstimationStartDate(
    id: CampaignId,
    params: CampaignUpdateEstimationStartDateParameter
  ): Promise<void> {
    const { data } = await axios.put(`/api/ost/campaign/${id}/estimation-start-date`, params);
    return data;
  }

  async updateVoteStartDate(
    id: CampaignId,
    params: CampaignUpdateVoteStartDateParameter
  ): Promise<void> {
    const { data } = await axios.put(`/api/ost/campaign/${id}/vote-start-date`, params);
    return data;
  }

  async updateVoteEndDate(
    id: CampaignId,
    params: CampaignUpdateVoteEndDateParameter
  ): Promise<void> {
    const { data } = await axios.put(`/api/ost/campaign/${id}/vote-end-date`, params);
    return data;
  }

  async updateEstimationEndDate(
    id: CampaignId,
    params: CampaignUpdateEstimationEndDateParameter
  ): Promise<void> {
    const { data } = await axios.put(`/api/ost/campaign/${id}/estimation-end-date`, params);
    return data;
  }

  async updateTotalReward(id: CampaignId, totalReward?: number): Promise<void> {
    const { data } = await axios.put(`/api/ost/campaign/${id}/total-reward`, { totalReward });
    return data;
  }

  async updateManager(campaignId: CampaignId, managerId: UserId): Promise<void> {
    const { data } = await axios.put(`/api/ost/campaign/${campaignId}/manager`, {
      managerId,
    });
    return data;
  }

  async updateStatus(id: CampaignId, params: CampaignUpdateStatusParameter): Promise<void> {
    const { data } = await axios.put(`/api/ost/campaign/${id}/status`, params);
    return data;
  }

  async updateProposalCategoryList(
    id: CampaignId,
    params: CampaignUpdateProposalCategoryParameter
  ): Promise<void> {
    const { data } = await axios.put(`/api/ost/campaign/${id}/proposal-category`, params);
    return data;
  }

  async getCampaignListUnderOnGoing(): Promise<void> {
    const { data } = await axios.get(`/api/ost/campaign/list`);
    return data;
  }

  async updateNotice(id: CampaignId, params: CampaignUpdateNoticeParameter): Promise<void> {
    const { data } = await axios.put(`/api/ost/campaign/${id}/notice`, params);
    return data;
  }

  async updateIsNoticeExposed(
    id: CampaignId,
    params: CampaignUpdateIsNoticeExposedParameter
  ): Promise<void> {
    const { data } = await axios.put(`/api/ost/campaign/${id}/is-notice-exposed`, params);
    return data;
  }

  /**
   * 결재선 추가
   */
  async addApprovalLine(id: CampaignId): Promise<void> {
    const { data } = await axios.post(`/api/campaign-approval/${id}`);
    return data;
  }

  /**
   * 결재자 업데이트
   */
  async updateApproval(params: CampaignUpdateApprovalParameter): Promise<void> {
    const { data } = await axios.patch(`/api/campaign-approval/${params.id}`, {
      managerId: params.manageId,
      note: params.note,
    });
    return data;
  }

  /**
   * 결재자 삭제
   */
  async deleteApproval(id: ProposalApprovalId): Promise<void> {
    const { data } = await axios.delete(`/api/campaign-approval/${id}`);
    return data;
  }

  /**
   * 평가 완료 비율 업데이트
   */
  async updateEstimationCompletionRate(
    id: CampaignId,
    params: CampaignUpdateEstimationCompletionRateParameter
  ): Promise<void> {
    const { data } = await axios.put(`/api/ost/campaign/${id}/estimation-completion-rate`, params);
    return data;
  }

  /**
   * 캠페인 안내 컨텐츠 업데이트
   */
  async updateCampaignBannerContent(
    id: CampaignBannerId,
    params: CampaignBannerUpdateContentParameter
  ): Promise<void> {
    const { data } = await axios.put(`/api/ost/campaign/banner/${id}/content`, {
      content: params.beforeContent,
    });
    return data;
  }

  /**
   * 캠페인 안내 노출 업데이트
   */
  async updateCampaignBannerIsExposed(
    id: CampaignBannerId,
    params: CampaignBannerUpdateIsExposedParameter
  ): Promise<void> {
    const { data } = await axios.put(`/api/ost/campaign/banner/${id}/is-exposed`, params);
    return data;
  }
}

export const campaignApi = new CampaignAPI();
