import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { projectSalesInfoContractEarningsItemApi } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/query/api';
import { handleError } from '@front/src/utils';
import type { CollectionIdDeleteParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/parameters';
import type { ProjectContractEarningsNameParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/types/parameter';

export const projectSalesInfoContractEarningsItemMutation = {
  useContractEarningsDelete: (itemId: number, id: number) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: () => projectSalesInfoContractEarningsItemApi.deleteContractEarnings(itemId),
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'project-sales-info',
          'collection',
          'contract-earnings',
          id,
        ]);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },

  useContractEarningsItemDetailsDelete: (itemId: number, id: number) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: CollectionIdDeleteParams) =>
        projectSalesInfoContractEarningsItemApi.deleteContractEarningsItemDetails(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'project-sales-info',
          'collection',
          'contract-earnings',
          'item',
          itemId,
        ]);
        await queryClient.invalidateQueries([
          'project-sales-info',
          'collection',
          'save-history',
          id,
        ]);
        await queryClient.invalidateQueries([
          'project-sales-info',
          'collection',
          'contract-earnings',
          'status',
          id,
        ]);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useContractEarningsItemNameUpdate: (id: number) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectContractEarningsNameParams) =>
        projectSalesInfoContractEarningsItemApi.updateContractEarningsName(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'project-sales-info',
          'collection',
          'contract-earnings',
          id,
        ]);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
