import { useCallback } from 'react';
import { useAffiliatedCompanyDetailState } from '@front/src/features/affiliated-company/widgets/detail/useState';
import { shallow } from 'zustand/shallow';
import { affiliatedCompanyOutlineRepository } from '@front/src/features/affiliated-company/features/outline/repository/repository';
import type { AffiliatedCompanyOutlineNumberUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/number/types/parameter';
import { affiliatedCompanyOutlineNumberRepository } from '@front/src/features/affiliated-company/features/outline/features/number/repository/repository';

export { useLogic as useAffiliatedCompanyOutlineNumberLogic };

const useLogic = (menuId) => {
  const { id } = useAffiliatedCompanyDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { data: detailOutline, isLoading } = affiliatedCompanyOutlineRepository.useOutlineGet(
    id,
    menuId
  );
  const { run: onOutlineNumberUpdate } =
    affiliatedCompanyOutlineNumberRepository.useOutlineNumberUpdate(menuId);
  const handleOutlineNumberUpdate = useCallback(
    (params: AffiliatedCompanyOutlineNumberUpdateParameter) => {
      onOutlineNumberUpdate({ ...params, id });
    },
    [onOutlineNumberUpdate, id]
  );
  const d = { detailOutline };
  const h = { onOutlineNumberUpdate: handleOutlineNumberUpdate };
  return { d, h, isLoading };
};
