import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import type { RootState } from 'services/reducer';
import type { SxProps } from '@mui/system';

interface ProgressProps {
  loading?: boolean;
  sx?: SxProps;
}

const Progress = (props: ProgressProps) => {
  const { progress } = useSelector((root: RootState) => root.progress);
  return (
    <Backdrop
      sx={{ ...props?.sx, color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}
      open={!!progress || !!props.loading}
    >
      {progress && <CircularProgress color="inherit" />}
    </Backdrop>
  );
};

export default Progress;
