export enum SnackbarSeverityType {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

export interface SnackbarShowProps {
  message: string;
  severity?: SnackbarSeverityType;
}
