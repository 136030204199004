import { useMutation, useQueryClient } from 'react-query';
import { useCustomDialog } from '@front/src/features/dialog';
import { handleError } from '@front/src/utils';
import { affiliatedCompanyPersonDetailModalApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/query/api';

export { mutation as affiliatedPersonDetailMutation };

const mutation = {
  useDelete: () => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => affiliatedCompanyPersonDetailModalApi.deletePerson(id),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-person', 'memo', 'list'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-person', 'drawer', 'list'],
        });
        return data;
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
