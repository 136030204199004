import { useShallow } from 'zustand/react/shallow';
import { useEffect } from 'react';
import { useAffiliatedPersonAppBarState } from '@front/src/features/affiliated-person/features/app-bar/useState';
import { affiliatedPersonAppBarRepository } from '@front/src/features/affiliated-person/features/app-bar/repository/repository';
import { defaultAffiliatedPersonCreateForm } from '@front/src/features/affiliated-person/features/app-bar/utils';

export { useLogic as useAffiliatedPersonAppBarPersonListLogic };

const useLogic = () => {
  const { createParameter, setCreateParameter, setPersonNumber } = useAffiliatedPersonAppBarState(
    useShallow((state) => ({
      createParameter: state.createParameter,
      setCreateParameter: state.setCreateParameter,
      setPersonNumber: state.setPersonNumber,
    }))
  );
  const { data: list, remove } = affiliatedPersonAppBarRepository.useListByPersonNameGet({
    personName: createParameter.personName,
  });
  useEffect(() => {
    if (typeof list === 'undefined') return;
    setPersonNumber(list.length);
  }, [setPersonNumber, list]);
  useEffect(
    () => () => {
      remove();
      setCreateParameter(defaultAffiliatedPersonCreateForm);
    },
    [remove, setCreateParameter]
  );
  return {
    list,
  };
};
