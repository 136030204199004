import { create } from 'zustand';

interface State {
  id?: number;
  setId: (id?: number) => void;
  readOnly?: boolean;
  setReadOnly: (readOnly?: boolean) => void;
}

export { useState as useProjectSalesInfoCMSaveHistoryState };

const useState = create<State>((set) => ({
  setId: (id?: number) => set({ id }),
  readOnly: false,
  setReadOnly: (readOnly) => set({ readOnly }),
}));
