import React, { useContext } from 'react';
import { Box, Button } from '@mui/material';
import DataFieldWithLabel from 'layouts/DataFieldLabel';
import Input from 'layouts/Input';
import type { PasswordValidation } from 'login/parameter';
import { PasswordValidationCode } from 'login/parameter';
import TextBox from 'layouts/Text';
import { FormikContext } from 'formik';
import logo from 'assets/loginLogo.webp';

interface Props {
  passwordValidation?: PasswordValidation;
}

function FormToChangePassword({ passwordValidation }: Props) {
  const formik = useContext(FormikContext);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        flexWrap: 'wrap',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          width: '100%',
          maxWidth: '460px',
          padding: '20px',
          marginBottom: '40px',
        }}
      >
        <img
          src={logo}
          width="100%"
          alt="한양풍동실험연구소_로고"
        />
        <h2 style={{ textAlign: 'center' }}>비밀번호 재설정</h2>
        <DataFieldWithLabel
          label="신규 비밀번호"
          labelPosition="top"
        >
          <Input
            key={formik.values.newPassword}
            type="password"
            autoComplete="off"
            defaultValue={formik.values.newPassword ?? ''}
            placeholder="영문, 숫자, 특수문자 조합 8자리 이상 입력하세요."
            onBlur={(e) => {
              const value = e.target.value || undefined;
              if (formik.values.newPassword !== value) {
                formik.setFieldValue('newPassword', value);
              }
            }}
          />
        </DataFieldWithLabel>
        <br />
        {[
          PasswordValidationCode.PASSWORD_SAME,
          PasswordValidationCode.PASSWORD_ROLE_VIOLATION,
          PasswordValidationCode.USER_NEW_PASSWORD_NOT_BLANK,
        ].includes(passwordValidation?.code as PasswordValidationCode) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '15px',
            }}
          >
            <TextBox variant="body20">{passwordValidation?.message}</TextBox>
          </Box>
        )}

        <DataFieldWithLabel
          label="신규 비밀번호 확인"
          labelPosition="top"
        >
          <Input
            key={formik.values.newPasswordConfirm}
            type="password"
            autoComplete="off"
            defaultValue={formik.values.newPasswordConfirm}
            placeholder="확인용 비밀번호를 다시 입력하세요."
            onBlur={(e) => {
              const value = e.target.value || undefined;
              if (formik.values.checkPassword !== value) {
                formik.setFieldValue('newPasswordConfirm', value);
              }
            }}
          />
        </DataFieldWithLabel>
        {[
          PasswordValidationCode.PASSWORD_NOT_EQUAL_NEW_PASSWORD,
          PasswordValidationCode.USER_NEW_PASSWORD_CONFIRM_NOT_BLANK,
        ].includes(passwordValidation?.code as PasswordValidationCode) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '15px',
            }}
          >
            <TextBox variant="body20">{passwordValidation?.message}</TextBox>
          </Box>
        )}
        <br />
        <Button
          sx={{
            width: '100%',
            marginTop: '10px',
          }}
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          확인
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '10px',
        }}
      />
    </Box>
  );
}

export default FormToChangePassword;
