import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import type { RangeStatic } from 'quill';
import Box from '@mui/material/Box';
import 'react-quill/dist/quill.snow.css';
import { ColorPalette } from '@front/assets/theme';
import { commonRepository } from '@front/src/api/repository';
import type { SxProps } from '@mui/system';

interface EditorComponentProps<T extends { id?: any; beforeContent: string }> {
  readOnly?: boolean;
  value: T;
  update?: (params: { id: any; beforeContent: string }) => void;
  disabled?: boolean;
  placeholder?: string;
  isBulk?: boolean;
  menuId?: number;
  dataId?: number;
  sectionId: number;
}

export default function EditorUI<T extends { id?: any; beforeContent: string }>({
  disabled = false,
  placeholder = '',
  ...rest
}: EditorComponentProps<T>) {
  return (
    <DefaultEditor
      placeholder={placeholder}
      sx={{
        background: disabled ? ColorPalette.greyscale.disabled : ColorPalette.background.bg,
        border: `1px solid ${ColorPalette.line.line02}`,
        borderRadius: '5px',

        '&:hover': {
          border: disabled
            ? `1px solid ${ColorPalette.line.line02}`
            : `1px solid ${ColorPalette.main.main_hover}`,
        },

        '&:active': {
          border: disabled
            ? `1px solid ${ColorPalette.line.line02}`
            : `1px solid ${ColorPalette.main.main_hover}`,
        },

        '& .ql-toolbar.ql-snow': {
          border: 'none',
          borderBottom: `1px solid ${ColorPalette.line.line}`,
          padding: '10px',

          '& .ql-picker,': {
            color: ColorPalette.greyscale.text_primary,
          },
          '& .ql-stroke': {
            stroke: ColorPalette.greyscale.text_primary,
          },
          '& .ql-fill': {
            fill: ColorPalette.greyscale.text_primary,
          },
        },

        '& .ql-editor': {
          fontSize: '1.4rem',
          border: 'none',
          padding: '10px',

          '&.ql-blank::before': {
            fontStyle: 'normal',
            color: ColorPalette.greyscale.text_quaternary,
            left: '10px',
          },
        },

        // Button states: General states (hover, focus, active)
        '.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active':
          {
            color: `${ColorPalette.main.main_primary} !important`,
            stroke: `${ColorPalette.main.main_primary} !important`,
          },

        // Button .ql-stroke states
        '.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke':
          {
            color: `${ColorPalette.main.main_primary} !important`,
            stroke: `${ColorPalette.main.main_primary} !important`,
          },

        // Button .ql-stroke-miter states
        '.ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter':
          {
            color: `${ColorPalette.main.main_primary} !important`,
            stroke: `${ColorPalette.main.main_primary} !important`,
          },

        // .ql-picker-label states
        '.ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active':
          {
            color: `${ColorPalette.main.main_primary} !important`,
            stroke: `${ColorPalette.main.main_primary} !important`,
          },

        // .ql-picker-item states
        '.ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected':
          {
            color: `${ColorPalette.main.main_primary} !important`,
            stroke: `${ColorPalette.main.main_primary} !important`,
          },

        // Additional states for .ql-stroke under .ql-picker-label and .ql-picker-item
        '.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke':
          {
            color: `${ColorPalette.main.main_primary} !important`,
            stroke: `${ColorPalette.main.main_primary} !important`,
          },

        '.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke':
          {
            color: `${ColorPalette.main.main_primary} !important`,
            stroke: `${ColorPalette.main.main_primary} !important`,
          },

        // Additional states for .ql-stroke-miter under .ql-picker-label and .ql-picker-item
        '.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter':
          {
            color: `${ColorPalette.main.main_primary} !important`,
            stroke: `${ColorPalette.main.main_primary} !important`,
          },

        '.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter':
          {
            color: `${ColorPalette.main.main_primary} !important`,
            stroke: `${ColorPalette.main.main_primary} !important`,
          },

        '.ql-snow a': {
          color: `${ColorPalette.main.main_primary} !important`,
        },

        '.ql-toolbar.ql-snow .ql-picker-label:focus, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus':
          {
            outlineColor: `${ColorPalette.sub.sub_primary} !important`,
          },
      }}
      {...rest}
    />
  );
}

interface Props {
  readOnly?: boolean;
  value: any;
  update?: (params: { id: any; beforeContent: string }) => void;
  disabled?: boolean;
  sx?: SxProps;
  placeholder?: string;
  isBulk?: boolean;
  menuId?: number;
  dataId?: number;
  sectionId: number;
}

function DefaultEditor({
  readOnly,
  value,
  update,
  placeholder,
  isBulk,
  sx = {},
  menuId,
  dataId,
  sectionId,
}: Readonly<Props>) {
  const { quillRef, modules, formats, editorValue, setEditorValue, setIsUpdated } = useLogic({
    readOnly,
    value,
    update,
    menuId,
    dataId,
    sectionId,
  });
  return (
    <Box
      sx={{
        ...sx,
      }}
    >
      <ReactQuill
        style={{}}
        readOnly={readOnly}
        ref={quillRef}
        theme="snow"
        modules={modules}
        formats={formats}
        value={editorValue || ''}
        placeholder={placeholder}
        onChange={(beforeContent, delta, source, editor) => {
          setEditorValue(editor.getHTML());
          isBulk &&
            update?.({
              id: value.id,
              beforeContent: editor.getHTML(),
            });
        }}
        onBlur={() => {
          if (editorValue === value.beforeContent) {
            return;
          }
          update?.({
            id: value.id,
            beforeContent: editorValue,
          });
          setIsUpdated(true);
        }}
      />
    </Box>
  );
}

const useLogic = ({ readOnly, update, value, menuId, dataId, sectionId }) => {
  const quillRef = useRef<ReactQuill>(null);
  const [editorValue, setEditorValue] = useState<string>('');
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const { run: onCreate, setCallback } = commonRepository.useCreateImage(menuId);
  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.addEventListener('change', async () => {
      const formData = new FormData();
      const request = {
        menuId,
        dataId,
        sectionId,
      };
      formData.append(`request`, new Blob([JSON.stringify(request)], { type: 'application/json' }));
      formData.append('image', input.files?.[0] ?? '');
      onCreate(formData);
    });
  };
  setCallback({
    onSuccess: ({ data }) => {
      if (!quillRef.current) {
        return;
      }
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection();
      if (!range) return;
      editor.insertEmbed(range.index, 'image', data);
      editor.setSelection((range.index + 1) as unknown as RangeStatic);
    },
  });
  const modules = useMemo(
    () =>
      readOnly
        ? { toolbar: false }
        : {
            toolbar: {
              container: [
                [{ font: ['Noto Sans KR'] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                ['link', 'image'],
                [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
                ['clean'],
              ],
              handlers: {
                image: () => {
                  imageHandler();
                },
              },
            },
          },
    [readOnly]
  );

  const formats = [
    //'font',
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'color',
    'background',
  ];
  useEffect(() => {
    const debounceId = setTimeout(() => {
      if (editorValue === value.beforeContent || readOnly) {
        return;
      }
      if (isUpdated) {
        clearTimeout(debounceId);
        setIsUpdated(false);
        return;
      }
      update?.({
        id: value.id,
        beforeContent: editorValue,
      });
    }, 3000);
    return () => {
      clearTimeout(debounceId);
    };
  }, [editorValue, isUpdated]);

  useEffect(() => {
    // 내부 스타일을 위해서 useRef를 이용해서 처리... -> webpack css loader 수정 필요할 듯.
    const editor = quillRef.current!.getEditor();
    const styleTag = document.createElement('style');
    styleTag.innerHTML =
      '.ql-editor { min-height: 200px; border: 1px solid #e4e9f2; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px; } .ql-container.ql-snow{border:none;}';
    editor.addContainer(styleTag);
  }, []);

  useEffect(() => {
    setEditorValue(value.beforeContent);
  }, [value.id, value.beforeContent]);
  return { quillRef, modules, formats, editorValue, setEditorValue, setIsUpdated };
};
