import React, { useState } from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import { cellComponent } from '@front/src/components/ui-builder/cellComponent';
import type { HeaderSettingView } from '@front/src/types';
import UIBuilderEditModal from '@front/src/components/ui-builder/table/EditModal';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';

interface Props extends CellComponentProps {
  header: HeaderSettingView;
  left?: number;
}

export default function UIBuilderTableCell({ header, isEditMode, left, ...rest }: Readonly<Props>) {
  const [open, setOpen] = useState<boolean>(false);
  const onClose = () => setOpen(false);
  const hasModal = header.modalHeaderList.length > 0;
  const handleClick = () => {
    if (!hasModal || isEditMode) return;
    setOpen(true);
  };
  const CellComponent = cellComponent[header.id];
  const sx = !hasModal || isEditMode ? {} : { textDecoration: 'underline', cursor: 'pointer' };
  return (
    <TableCellUI
    // left={left}
    // isFixed={header.isFixed}
    >
      {hasModal && (
        <UIBuilderEditModal
          open={open}
          onClose={onClose}
          headerName={header.name}
          headerList={header.modalHeaderList}
          {...rest}
        />
      )}
      <CellComponent
        isEditMode={isEditMode}
        onClick={handleClick}
        sx={sx}
        {...rest}
      />
    </TableCellUI>
  );
}
