import React, { Suspense } from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import WorkIndicatorTable from '@front/src/features/work/features/work/features/creator/features/indicator/components/Table';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';

/**
 * @module 업무-기본정보탭-지시자섹션
 */
export default function WorkIndicatorFeature({ name, ...rest }: Readonly<SectionComponentProps>) {
  const { Layout, TitleWrap } = SectionWrapperUI;
  return (
    <Layout>
      <TitleWrap left={name} />
      <Suspense fallback={<LoadingSpinnerUI />}>
        <WorkIndicatorTable {...rest} />
      </Suspense>
    </Layout>
  );
}
