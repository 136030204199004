import { affiliatedPersonDetailRepository } from '@front/src/features/affiliated-person/features/detail/repository/repository';
import { useNavigate } from 'react-router-dom';

export { useLogic as useAffiliatedPersonDetailDeleteLogic };

const useLogic = () => {
  const { run: onDelete, setCallback } = affiliatedPersonDetailRepository.useDelete();
  const navigate = useNavigate();
  setCallback({
    onSuccess: () => {
      navigate(`/affiliated-person`);
    },
  });
  return {
    onDelete,
  };
};
