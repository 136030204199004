import type { ApprovalDocumentDetailQueryParams } from '@front/src/features/drawer-approval-document/types/parameter';
import { create } from 'zustand';

interface State {
  params: ApprovalDocumentDetailQueryParams;
  documentId: number;

  imgModalId: number;
}

interface Action {
  setParams: (params: ApprovalDocumentDetailQueryParams) => void;
  setDocumentId: (documentId: number) => void;

  setImgModalId: (imgModalId: number) => void;
}

const useApprovalDocumentStore = create<State & Action>((set) => ({
  params: {
    where: '',
  },
  documentId: 0,
  imgModalId: 0,

  setParams: (params: ApprovalDocumentDetailQueryParams) => set({ params }),
  setDocumentId: (documentId: number) => set({ documentId }),
  setImgModalId: (imgModalId: number) => set({ imgModalId }),
}));

export default useApprovalDocumentStore;
