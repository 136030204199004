import React, { useCallback, useContext } from 'react';
import { TableRow } from '@mui/material';
import { AccountingSettingsManagementAccountingReadListContext } from '@front/src/features/accounting/features/settings/features/management-accounting-read/widgets/list/provider/context';
import { OldTd } from '@front/layouts/Table';
import { useAccountingSettingsManagementAccountingReadState } from '@front/src/features/accounting/features/settings/features/management-accounting-read/useState';
import { shallow } from 'zustand/shallow';
import { ColorPalette } from '@front/assets/theme';

export { ListItem as AccountingSettingsManagementAccountingReadListItem };

interface Props {
  sequence: number;
}

const ListItem = ({ sequence }: Props) => {
  const { list, eAccountAndManualList } = useContext(
    AccountingSettingsManagementAccountingReadListContext
  );
  const { setParentIdList, parentIdList, setCategoryMircoName, categoryMircoName } =
    useAccountingSettingsManagementAccountingReadState(
      (state) => ({
        setParentIdList: state.setParentIdList,
        parentIdList: state.parentIdList,
        setCategoryMircoName: state.setCategoryMircoName,
        categoryMircoName: state.categoryMircoName,
      }),
      shallow
    );
  const sequenceList = list?.filter((t) => t.sequence === sequence);
  const handleCategoryClick = useCallback(
    (item) => {
      const { length } = parentIdList;
      const { depth } = item;
      if (depth < 3) {
        setCategoryMircoName(undefined);
      }
      // 상위 뎁스를 클릭시 제거
      if (length > depth) {
        for (let i = 0; i < length - depth; i += 1) {
          parentIdList.pop();
        }
      }
      // 하위 뎁스 클릭시 추가
      if (parentIdList[depth - 1] !== item.id) {
        setParentIdList([...parentIdList, item.id]);
        return;
      }
      // 동일 뎁스 클릭시 하나 제거 후 추가
      const copy = [...parentIdList];
      copy.pop();
      setParentIdList([...copy, item.id]);
    },
    [parentIdList, setParentIdList, setCategoryMircoName]
  );
  const handleCategoryMicroClick = useCallback(
    (name) => {
      setCategoryMircoName(name);
    },
    [setCategoryMircoName]
  );
  const handleCellClick = useCallback(
    (item) => {
      if (item.depth > 2) {
        handleCategoryMicroClick(item.name);
        return;
      }
      handleCategoryClick(item);
    },
    [handleCategoryMicroClick, handleCategoryClick]
  );
  const getStylesSelectedByCategory = useCallback(
    (item) => {
      if (parentIdList.includes(item.id) || categoryMircoName === item.name) {
        return {
          backgroundColor: ColorPalette._9bb6ea,
          fontWeight: 'bold',
          cursor: 'default',
        };
      }
    },
    [parentIdList, categoryMircoName]
  );
  return (
    <TableRow>
      {categoryListWithDepthAndLabel.map((cell) => {
        const target = sequenceList?.find((t) => t.depth === cell.depth);
        if (!target) {
          return <OldTd key={`cell-${sequence}-${cell.label}`} />;
        }
        if (cell.label === '통장명') {
          return <OldTd key={`cell-${sequence}-${cell.label}`}>{target.alias}</OldTd>;
        }
        return (
          <OldTd
            key={`cell-${sequence}-${cell.label}`}
            onClick={() => handleCellClick(target)}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: ColorPalette._9bb6ea,
              },
              ...getStylesSelectedByCategory(target),
            }}
          >
            {target.name}
          </OldTd>
        );
      })}
      {eAccountListWithDepthAndLabel.map((cell) => {
        const eAccount = eAccountAndManualList?.[sequence];
        if (!eAccount) {
          return <OldTd key={`cell-${sequence}-${cell.label}`} />;
        }
        if (cell.label === 'E계정') {
          return <OldTd key={`cell-${sequence}-${cell.label}`}>{eAccount?.nameWithCode}</OldTd>;
        }
        if (cell.label === '한풍메뉴얼') {
          return (
            <OldTd
              key={`cell-${sequence}-${cell.label}`}
              sx={{
                textAlign: 'start',
              }}
            >
              {eAccount?.manual}
            </OldTd>
          );
        }
      })}
    </TableRow>
  );
};

interface RowProps {
  depth: number;
  label: string;
}

const categoryListWithDepthAndLabel: RowProps[] = [
  {
    depth: 0,
    label: '통장명',
  },
  {
    depth: 0,
    label: '대분류',
  },
  {
    depth: 1,
    label: '증분류',
  },
  {
    depth: 2,
    label: '소분류',
  },
  {
    depth: 3,
    label: '미분류',
  },
];

const eAccountListWithDepthAndLabel: RowProps[] = [
  {
    depth: 4,
    label: 'E계정',
  },
  {
    depth: 4,
    label: '한풍메뉴얼',
  },
];
