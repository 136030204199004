import { createAction } from 'typesafe-actions';
import type { DepartmentQuery } from 'department/query';
import type Page from 'type/Page';
import type { DepartmentId, DepartmentShortVO, DepartmentVO } from 'department/domain';
import type { DepartmentParameter } from 'department/parameter';
import type { ApiStatus } from 'components/DataFieldProps';

export enum DepartmentActionType {
  setFilter = 'department/filter/set',
  setPage = 'department/page/set',
  setList = 'department/list/set',
  requestList = 'department/list/request',
  setId = 'department/id/set',
  setOne = 'department/one/set',
  upsert = 'department/upsert',
  requestUpsert = 'department/upsert/request',
  deleteOne = 'department/delete',
  requestDelete = 'department/delete/request',
}

export const departmentAction = {
  setFilter: createAction(DepartmentActionType.setFilter)<DepartmentQuery>(),
  setPage: createAction(DepartmentActionType.setPage)<Page<DepartmentShortVO> | undefined>(),
  setList: createAction(DepartmentActionType.setList)<DepartmentShortVO[]>(),
  requestList: createAction(DepartmentActionType.requestList)<number | undefined>(),
  setId: createAction(DepartmentActionType.setId)<{
    id: DepartmentId | undefined;
    menuId?: number | undefined;
  }>(),
  setOne: createAction(DepartmentActionType.setOne)<DepartmentVO | undefined>(),
  upsert: createAction(DepartmentActionType.upsert)<DepartmentParameter>(),
  requestUpsert: createAction(DepartmentActionType.requestUpsert)<ApiStatus>(),
  deleteOne: createAction(DepartmentActionType.deleteOne)<{
    id: DepartmentId;
    menuId?: number | undefined;
  }>(),
  requestDelete: createAction(DepartmentActionType.requestDelete)<ApiStatus>(),
};
