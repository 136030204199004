import React, { useCallback } from 'react';
import { Box, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Tooltip from '@front/components/Tooltip';
import { fileTypeText } from '@front/util/CommonText';
import Button from '@front/layouts/Button';
import { OldTh, Table } from '@front/layouts/Table';

import type { DrawingParameter } from '@front/ost_proposal/parameter';
import type { DrawingId } from '@front/ost_proposal/domain';
import { ProposalStatus } from '@front/ost_proposal/domain';

import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import DrawIoList from '@front/ost_proposal/view/detail/draw_io/DrawIoList';
import type { DrawIoData } from '@front/ost_proposal/view/detail/draw_io/integration/useDrawIo';
import { drawIoTemplate } from '@front/ost_proposal/view/detail/draw_io/integration/DrawioTemplate';
import { ArticleItem } from '@front/src/components/article-item';

interface Props {
  addDrawing: (params: DrawingParameter) => void;
  deleteDrawing: (id: DrawingId) => void;
  updateDrawing: (params: DrawingParameter) => void;
  onClickText: (originalDrawData: DrawIoData | undefined) => () => void;
  drawIo: { open: (originalDrawData: DrawIoData | undefined) => void };
  onDownloadFile?: (drawIoData: DrawIoData) => void;
  isContributor: boolean;
}

const DrawIoLayOut = ({
  deleteDrawing,
  onClickText,
  onDownloadFile,
  drawIo,
  isContributor,
}: Props) => {
  const status = useSelector((root: RootState) => root.proposal.detail!.status, shallowEqual);

  const startNewDrawing = useCallback(() => {
    drawIo.open({
      name: undefined,
      lastModified: undefined,
      xml: drawIoTemplate,
    });
  }, [drawIo]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        width: '100%',
        padding: '10px',
      }}
    >
      <ArticleItem
        title="제안 드로잉"
        children={
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <OldTh width="15%">날짜</OldTh>
                  <OldTh width="15%">부서</OldTh>
                  <OldTh width="10%">기록자</OldTh>
                  <OldTh
                    width="60%"
                    colSpan={2}
                  >
                    파일명
                  </OldTh>
                </TableRow>
              </TableHead>
              <TableBody>
                <DrawIoList
                  deleteDrawing={deleteDrawing}
                  onClickText={onClickText}
                  onDownloadFile={onDownloadFile}
                  isContributor={isContributor}
                />
              </TableBody>
            </Table>
          </TableContainer>
        }
        buttonsNode={
          <>
            {status === ProposalStatus.WRITING && isContributor && (
              <Tooltip title={fileTypeText}>
                <Button
                  component="label"
                  onClick={startNewDrawing}
                >
                  드로잉 추가
                </Button>
              </Tooltip>
            )}
          </>
        }
      />
    </Box>
  );
};

export default DrawIoLayOut;
