import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type {
  WorkReviewerDeleteAllParameter,
  WorkReviewerDeleteParameter,
  WorkReviewerParams,
} from '@front/src/features/work/features/work/features/reviewer/types/parameter';
import { convertEmptyToNull } from '@front/src/utils';

const workReviewerMutation = {
  useCreate: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.createReviewer(id, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reviewer'],
        });
        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useAddTop: (workId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: () => workWorkApi.addTopReviewer(workId, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reviewer'],
        });

        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useAddBottom: (workId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: () => workWorkApi.addBottomReviewer(workId, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reviewer'],
        });
        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useUpdate: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: WorkReviewerParams) => workWorkApi.updateReviewer(params, menuId),
      onMutate: async (data: WorkReviewerParams) => {
        const { data: previousDataList } =
          (queryClient.getQueryData(['work', 'reviewer']) as any) ?? {};
        const newData = convertEmptyToNull(data);

        const previousData = previousDataList?.find((item) => item?.id === newData.id);
        const prevData = convertEmptyToNull(previousData);

        return newData.userId === (prevData?.user?.id ?? null) && newData.note === prevData?.note;
      },
      onSuccess: async (data, _, context) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reviewer'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'role'],
        });
        return { data, context };
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useSeqUp: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.updateReviewerSeqUp(id, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reviewer'],
        });
        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useSeqDown: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.updateReviewerSeqDown(id, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reviewer'],
        });
        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useDelete: (params: WorkReviewerDeleteParameter) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.deleteReviewer({ id, ...params }),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reviewer'],
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useDeleteAll: (params: WorkReviewerDeleteAllParameter) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: () => workWorkApi.deleteAllReviewer(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reviewer'],
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
};

export default workReviewerMutation;
