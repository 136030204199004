import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workReferenceMutation from '@front/src/features/work/features/work/features/reference/query/mutation';
import type { WorkReferenceUpdateParameter } from '@front/src/features/work/features/work/features/reference/types/parameter';

const useWorkReferenceUpdate = (menuId) => {
  const callback = getNoOpCallback();
  const { mutate, isLoading } = workReferenceMutation.useUpdate(menuId);
  return {
    run: (params: WorkReferenceUpdateParameter) => mutate(params, callback),
    setCallback: getSetCallbackFunc(callback),
    isLoading,
  };
};

export default useWorkReferenceUpdate;
