import { paymentHistoryApiUrl } from '@front/src/features/project-sales-info/features/collection/features/payment-history/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { ProjectReviewAmountPaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/payment-history/types/parameter';

const url = {
  getPaymentHistoryDetail: (itemId: number) =>
    `${paymentHistoryApiUrl}/review-amount-payment-history/${itemId}`,
  updatePaymentHistoryDetail: (id: number) =>
    `${paymentHistoryApiUrl}/review-amount-payment-history/${id}`,
  getConsortiumCompany: (id: number) => `${paymentHistoryApiUrl}/${id}/consortium-company`,
};

export const projectSalesInfoPaymentHistoryUpdateModalApi = {
  getPaymentHistoryDetail: async (itemId: number) => {
    const { data } = await axios.get(url.getPaymentHistoryDetail(itemId));
    return data;
  },
  updatePaymentHistoryDetail: async (params: ProjectReviewAmountPaymentHistoryParams) =>
    await axios.put(url.updatePaymentHistoryDetail(params.id!), params),
  getConsortiumCompany: async (id: number) => {
    const { data } = await axios.get(url.getConsortiumCompany(id));
    return data;
  },
};
