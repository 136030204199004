import React, { useCallback } from 'react';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import { useDispatch } from 'react-redux';
import type { TitleUpdateParameter } from '@front/ost_proposal/parameter';
import { proposalAction } from '@front/ost_proposal/action';
import ProposalTitle from '@front/ost_proposal/view/detail/title/ProposalTitle';

interface Props {
  isContributor: boolean;
}

const TitleService = ({ isContributor }: Props) => {
  const dispatch = useDispatch();
  const updateTitle = useCallback(
    (params: TitleUpdateParameter) => dispatch(proposalAction.updateTitle(params)),
    [dispatch]
  );
  const openSnackbar = useCallback(
    (message, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );
  return (
    <ProposalTitle
      openSnackbar={openSnackbar}
      updateTitle={updateTitle}
      isContributor={isContributor}
    />
  );
};

export default TitleService;
