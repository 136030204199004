import type { TextFieldProps } from '@mui/material';
import { isValidDate } from '../util/DateUtil';
import Input from '../layouts/Input';
import React from 'react';

interface Props {
  parameter: TextFieldProps;
  variant?: 'standard' | 'outlined';
  value?: string | null;
}

export function RenderDateInput({ parameter, variant = 'standard' }: Props) {
  const value = parameter.inputProps?.value;
  const error = value != '' && !isValidDate(value);
  return (
    <Input
      {...parameter.InputProps}
      inputRef={parameter.inputRef}
      variant={variant}
      inputProps={parameter.inputProps}
      error={error}
    />
  );
}
