import { paymentHistoryApiUrl } from '@front/src/features/project-sales-info/features/collection/features/payment-history/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { ProjectReviewAmountPaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/payment-history/types/parameter';

const url = {
  createPaymentHistoryDetail: (id: number) =>
    `${paymentHistoryApiUrl}/${id}/review-amount-payment-history`,
  getConsortiumCompany: (id: number) => `${paymentHistoryApiUrl}/${id}/consortium-company`,
};

export const projectSalesInfoPaymentHistoryCreateModalApi = {
  createPaymentHistoryDetail: async (params: ProjectReviewAmountPaymentHistoryParams) =>
    await axios.post(url.createPaymentHistoryDetail(params.id!), params),
  getConsortiumCompany: async (id: number) => {
    const { data } = await axios.get(url.getConsortiumCompany(id));
    return data;
  },
};
