import React, { useContext } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { Table } from '@front/src/components/layout/table/table';
import CircularProgress from '@front/components/CircularProgress';
import { AffiliatedCompanyPersonDetailModalGiftHistoryContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/widgets/context';
import { AffiliatedCompanyPersonDetailModalGiftHistoryTableRow } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/components/gift-history-table-row';
import { NoResultBox } from '@front/src/components/no-result-box/no-result-box';

export { GiftHistoryTable as AffiliatedCompanyPersonDetailModalGiftHistoryTable };
const GiftHistoryTable = () => {
  const {
    d: { giftHistoryList },
    isLoading,
  } = useContext(AffiliatedCompanyPersonDetailModalGiftHistoryContext);
  if (typeof giftHistoryList === 'undefined' || isLoading) {
    return <CircularProgress size={30} />;
  }
  if (giftHistoryList.length === 0) {
    return <NoResultBox message="저장된 선물이력이 없습니다." />;
  }
  return (
    <TableContainer>
      <Table>
        <TableHeader />
        <TableBody>
          {giftHistoryList.map((item, i) => (
            <AffiliatedCompanyPersonDetailModalGiftHistoryTableRow
              key={item.id}
              item={item}
              index={i}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <Th
        center
        width="40px"
      >
        {' '}
      </Th>
      <Th
        center
        width="40px"
      >
        No.
      </Th>
      <Th
        center
        width="100px"
      >
        대상구분
      </Th>
      <Th
        center
        width="80px"
      >
        대상
      </Th>
      <Th
        center
        width="160px"
      >
        선물일자
      </Th>
      <Th
        center
        width="180px"
      >
        선물내용
      </Th>
      <Th
        center
        width="160px"
      >
        선물목적
      </Th>
      <Th
        center
        width="180px"
      >
        금액
      </Th>
      <Th
        center
        width="120px"
      >
        전달방식
      </Th>
      <Th
        center
        width="160px"
      >
        전달자
      </Th>
      <Th
        center
        width="200px"
      >
        비고
      </Th>
    </TableRow>
  </TableHead>
);
