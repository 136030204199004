import { notificationApi } from '@front/src/features/notification/api/api';
import { useQuery } from 'react-query';
import type { UserNotificationSearchParameter } from '@front/src/features/notification/types/parameter';

const notificationQuery = {
  useGetList: (params: UserNotificationSearchParameter) => {
    const { data } = useQuery({
      queryKey: ['user-notification', 'list', params],
      queryFn: () => notificationApi.getList(params),
      suspense: true,
    });

    return {
      data,
    };
  },

  useGetLink: (notificationId: number) => {
    const { data } = useQuery({
      queryKey: ['user-notification', 'link', notificationId],
      queryFn: () => notificationApi.getLink(notificationId),
      suspense: true,
    });

    return {
      data,
    };
  },

  useGetCount: () => {
    const { data } = useQuery({
      queryKey: ['user-notification', 'count'],
      queryFn: () => notificationApi.getCount(),
      suspense: true,
    });

    return {
      data,
    };
  },

  useGetFilterList: () => {
    const { data } = useQuery({
      queryKey: ['user-notification', 'filter'],
      queryFn: () => notificationApi.getFilterList(),
      suspense: true,
    });

    return {
      data,
    };
  },
};

export default notificationQuery;
