import { default as axios } from '@front/src/config/axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';

const url = {
  getList: (id?) => `${projectSalesInfoURL}/${id}/affiliated-company-person`,
  getDetail: (id?) => `${projectSalesInfoURL}/affiliated-company-person/${id}`,
};

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalApi = {
  getList: async (id?: number) => {
    const { data } = await axios.get(url.getList(id));
    return data;
  },
  getDetail: async (id?: number) => {
    const { data } = await axios.get(url.getDetail(id));
    return data;
  },
};
