export const YNOptionList = [
  { label: 'Y', value: 'Y' },
  { label: 'N', value: 'N' },
];

export const convertBooleanToYN = (boolean: boolean | null | undefined) => {
  let result;
  if (boolean) {
    result = 'Y';
  } else if (boolean === null || boolean === undefined) {
    result = '';
  } else {
    result = 'N';
  }
  return result;
};

export const YNToBoolean = {
  Y: true,
  N: false,
};

export const convertDataFromDesignOutlineForForm = (data) => ({
  id: data.id,
  address: data.address || null,
  city1: data.city1 || null,
  city2: data.city2 || null,
  blockArea: data.blockArea || null,
  landArea: data.landArea || null,
  grossArea: data.grossArea || null,
  highest: data.highest || null,
  topFloor: data.topFloor || null,
  totalCountOfComplex: data.totalCountOfComplex || null,
  countOfHousehold: data.countOfHousehold || null,
  buildPurpose: data.buildPurpose || null,
  isTargetOfSafetyEvaluation:
    data.isTargetOfSafetyEvaluation === '' ? null : YNToBoolean[data.isTargetOfSafetyEvaluation],
});
