import { shallow } from 'zustand/shallow';
import { affiliatedCompanyPersonCharacterBasicRepository } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/basic/repository/repository';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';

export { useLogic as useAffiliatedCompanyPersonDetailModalCharacterBasicLogic };

interface Props {
  id?: number;
  menuId?: number;
}

const useLogic = ({ id, menuId }: Props) => {
  const { modalId } = useAffiliatedCompanyPersonState(
    (state) => ({
      modalId: state.modalId,
    }),
    shallow
  );
  const { isLoading, data: personCharacterBasic } =
    affiliatedCompanyPersonCharacterBasicRepository.usePersonCharacterBasicGet(
      id ?? modalId,
      menuId
    );
  const { run: onPersonCharacterBasicUpdate } =
    affiliatedCompanyPersonCharacterBasicRepository.usePersonCharacterBasicUpdate(menuId);
  const h = {
    onPersonCharacterBasicUpdate: (params) =>
      onPersonCharacterBasicUpdate({ ...params, id: modalId }),
  };
  const d = { personCharacterBasic };
  return { d, h, isLoading };
};
