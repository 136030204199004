import { projectSalesActivityRepository } from '@front/src/features/project-sales-info/features/activity/repository/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoActivityState } from '@front/src/features/project-sales-info/features/activity/useState';

export { useLogic as useProjectSalesInfoActivityDeleteButtonLogic };

const useLogic = () => {
  const { id } = useProjectSalesInfoDetailState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const { setIdList } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      setIdList: state.setIdList,
    }))
  );
  const { run: onDelete, setCallback } = projectSalesActivityRepository.useActivityDelete(id!);
  setCallback({
    onSuccess: () => {
      setIdList([]);
    },
  });
  return { onDelete };
};
