import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { projectSalesInfoSubjectReviewShapeRatioQuery } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/query/query';
import { projectSalesInfoSubjectReviewShapeRatioMutation } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/query/mutation';

export const projectSalesInfoSubjectReviewShapeRatioRepository = {
  useListGet: (id?: number) => {
    const { data, isLoading } = projectSalesInfoSubjectReviewShapeRatioQuery.useListGet(id);
    return {
      data,
      isLoading,
    };
  },
  useCreate: () => {
    const { mutate } = projectSalesInfoSubjectReviewShapeRatioMutation.useCreate();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useUpdate: () => {
    const { mutate } = projectSalesInfoSubjectReviewShapeRatioMutation.useUpdate();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
