import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import React from 'react';
import Box from '@mui/material/Box';
import InputWithHookForm from '@front/src/components/hook-form/Input';

interface Props extends CellComponentProps {
  name: string;
}

export default function NumberAttr({
  name,
  isEditMode,
  onSubmit,
  sx = {},
  onClick,
  isForm,
  readOnly,
}: Readonly<Props>) {
  return (
    <Box
      onClick={onClick}
      sx={{
        ...sx,
      }}
    >
      <InputWithHookForm
        type="number"
        name={name}
        onSubmit={isForm ? undefined : onSubmit}
        readOnly={readOnly || !isEditMode}
      />
    </Box>
  );
}
