import { default as axios } from '@front/src/config/axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import type { IdListDeleteParameter } from '@front/src/types/parameter';

export { api as projectSalesInfoContractContractApi };
const url = {
  getList: (id?) => `${projectSalesInfoURL}/${id}/contract`,
  delete: () => `${projectSalesInfoURL}/contract`,
  getEstimationList: (id?) => `${projectSalesInfoURL}/${id}/estimation`,
};

const api = {
  getList: async (id?: number) => {
    const { data } = await axios.get(url.getList(id));
    return data;
  },
  delete: async (params: IdListDeleteParameter) => {
    await axios.delete(url.delete(), { data: params });
  },
  getEstimationList: async (id?: number) => {
    const { data } = await axios.get(url.getEstimationList(id), {
      params: {
        confirmed: true,
      },
    });
    return data;
  },
};
