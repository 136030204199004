import React, { memo, useCallback, useRef } from 'react';
import type { CampaignUpdateEstimationCompletionRateParameter } from '@front/ost_campaign/parameter';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import Input from '@front/layouts/Input';
import { useDispatch } from 'react-redux';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import { campaignAction } from '@front/ost_campaign/action';

interface Props {
  estimationCompletionRate?: number;
  updateEstimationCompletionRate: (params: CampaignUpdateEstimationCompletionRateParameter) => void;
}

const EstimationCompletionRate = ({ estimationCompletionRate }: Props) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const openSnackbar = useCallback(
    (message, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );
  const updateEstimationCompletionRate = useCallback(
    (params: CampaignUpdateEstimationCompletionRateParameter) => {
      dispatch(campaignAction.updateEstimationCompletionRate(params));
    },
    [dispatch]
  );

  const onBlur = useCallback(
    (e) => {
      const value = e.target.value.replace(/,/g, '');
      if (value === '') {
        updateEstimationCompletionRate({
          estimationCompletionRate: undefined,
        });
        return;
      }
      if (Number.isNaN(+value)) {
        openSnackbar('숫자만 입력 가능합니다.');
        if (inputRef.current) {
          if (estimationCompletionRate) {
            inputRef.current.value = estimationCompletionRate.toString();
          } else {
            inputRef.current.value = '';
          }
        }
        return;
      }
      if (100 < value) {
        openSnackbar('심사 완료 비율은 100%를 넘길 수 없습니다.');
        return;
      }
      updateEstimationCompletionRate({
        estimationCompletionRate: +value,
      });
    },
    [estimationCompletionRate, openSnackbar, updateEstimationCompletionRate]
  );
  return (
    <DataFieldWithLabel
      label="심사 완료 방식"
      labelSX={{ minWidth: '80px' }}
    >
      <Input
        isPercent
        type="number"
        key={estimationCompletionRate}
        inputRef={inputRef}
        variant="outlined"
        defaultValue={estimationCompletionRate}
        onBlur={onBlur}
      />
    </DataFieldWithLabel>
  );
};

const EstimationCompletionRateForm = memo(EstimationCompletionRate);
export default EstimationCompletionRateForm;
