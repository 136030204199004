import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { AffiliatedCompanyCharacterCharacterContext } from '@front/src/features/affiliated-company/features/character/features/character/widgets/context';
import { useAffiliatedCompanyCharacterState } from '@front/src/features/affiliated-company/features/character/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { CharacterButton as AffiliatedCompanyCharacterCharacterButton };
const CharacterButton = () => {
  const {
    h: { onCharacterCreate, onCharacterDelete, setIdList },
    idList,
  } = useContext(AffiliatedCompanyCharacterCharacterContext);
  const handleDeleteClick = useCallback(() => {
    if (idList.length === 0) {
      return;
    }
    onCharacterDelete({ idList });
    setIdList([]);
  }, [onCharacterDelete, idList, setIdList]);
  const { readOnly } = useAffiliatedCompanyCharacterState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      <Button
        onClick={handleDeleteClick}
        disabled={readOnly}
      >
        선택삭제
      </Button>
      <Button
        onClick={onCharacterCreate}
        disabled={readOnly}
      >
        특징 추가
      </Button>
    </>
  );
};
