// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bRErBdyXxugGSzHtwLZ1{display:flex;gap:10px;width:300px}.LoMijBgSc_UwfFXgu1EW{display:flex;gap:10px;flex-direction:column;align-items:center;width:100%}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/collection/features/payment-history/features/create-modal/components/create-modal-table.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,WAAA,CAGF,sBACE,YAAA,CACA,QAAA,CACA,qBAAA,CACA,kBAAA,CACA,UAAA","sourcesContent":[".btn__group {\n  display: flex;\n  gap: 10px;\n  width: 300px;\n}\n\n.table__group {\n  display: flex;\n  gap: 10px;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn__group": `bRErBdyXxugGSzHtwLZ1`,
	"table__group": `LoMijBgSc_UwfFXgu1EW`
};
export default ___CSS_LOADER_EXPORT___;
