import type { OptionType } from '@front/src/types';
import { ProjectKeywordType } from '@front/src/features/project-selector-modal/types/domain';

export const baseUrl = `/api/project`;

export const projectTypeList: OptionType[] = [
  {
    label: '프로젝트명',
    value: ProjectKeywordType.NICK_NAME,
  },
  {
    label: '프로젝트 번호',
    value: ProjectKeywordType.CODE,
  },
];
