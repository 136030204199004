import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workWorkerMutation from '@front/src/features/work/features/work/features/worker/query/mutation';

const useWorkWorkerSeqUp = (menuId) => {
  const callback = getNoOpCallback();
  const { mutate, isLoading } = workWorkerMutation.useSeqUp(menuId);
  return {
    run: (id: number) => mutate(id, callback),
    setCallback: getSetCallbackFunc(callback),
    isLoading,
  };
};

export default useWorkWorkerSeqUp;
