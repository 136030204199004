import React from 'react';
import { PersonalSettingsModalAuthSettingsWidget } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/user-settings/widget';
import { PersonalSettingsModalUISettingsFeature } from '@front/src/features/personal-settings-modal/features/ui-settings';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { usePersonalSettingsModalState } from '@front/src/features/personal-settings-modal/useState';
import { useShallow } from 'zustand/react/shallow';

export { Section as PersonalSettingsModalSection };

interface Props {
  authMenuId: number | undefined;
  onClose: () => void;
}

const Section = ({ authMenuId, onClose }: Props) => {
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const { tab } = usePersonalSettingsModalState(
    useShallow((state) => ({
      tab: state.tab,
    }))
  );
  const { canAccessPageAuthorization, canAccessPageView } = loginUser?.role ?? {};
  if (canAccessPageAuthorization && tab === '권한설정')
    return <PersonalSettingsModalAuthSettingsWidget authMenuId={authMenuId} />;
  if (canAccessPageView && tab === 'UI설정')
    return (
      <PersonalSettingsModalUISettingsFeature
        authMenuId={authMenuId}
        onClose={onClose}
      />
    );
  return <></>;
};
