import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { AffiliatedCompanyPersonOutlineRepresentativeContactInformationView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/types/view';
import type { AffiliatedCompanyPersonOutlineRepresentativeContactInformationUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/types/parameter';

export { Context as AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationContext };

interface State {
  d: {
    personOutlineRepresentativeContactInformation?: AffiliatedCompanyPersonOutlineRepresentativeContactInformationView;
  };
  h: {
    onPersonOutlineRepresentativeContactInformationUpdate: (
      params: AffiliatedCompanyPersonOutlineRepresentativeContactInformationUpdateParameter
    ) => void;
  };
  isLoading: boolean;
}

const Context = createContext<State>({
  d: {},
  h: {
    onPersonOutlineRepresentativeContactInformationUpdate: noOp,
  },
  isLoading: false,
});
