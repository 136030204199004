import { noteApi } from '@front/src/features/note/api/api';
import type {
  AuthUserListQueryParams,
  CommentFilterParams,
  NoteFilterParams,
  NoteParams,
} from '@front/src/features/note/types/parameter';
import { useQuery } from 'react-query';

const noteQuery = {
  useGetList: (params: NoteParams) => {
    const { data } = useQuery({
      queryKey: ['note', 'list', params],
      queryFn: () => noteApi.getList(params),
      refetchOnWindowFocus: false,
      suspense: true,
    });

    return { data };
  },

  useGetCommentList: (params: CommentFilterParams) => {
    const { data } = useQuery({
      queryKey: ['note', 'comment', params],
      queryFn: () => noteApi.getCommentList(params),
      suspense: true,
    });

    return { data };
  },

  useGetDetail: (noteId: number) => {
    const { data } = useQuery({
      queryKey: ['note', 'detail', noteId],
      queryFn: () => noteApi.getDetail(noteId),
      suspense: true,
    });

    return { data };
  },

  useGetReviewHistoryList: (noteId: number) => {
    const { data } = useQuery({
      queryKey: ['note', 'review-history', noteId],
      queryFn: () => noteApi.getReviewHistoryList(noteId),
      suspense: true,
    });

    return { data };
  },

  useGetFilter: (params: NoteFilterParams) => {
    const { data } = useQuery({
      queryKey: ['note', 'filter', params],
      queryFn: () => noteApi.getFilter(params),
      suspense: true,
    });

    return { data };
  },

  useGetReviewerDetail: (noteId: number) => {
    const { data } = useQuery({
      queryKey: ['note', 'reviewer', noteId],
      queryFn: () => noteApi.getReviewerDetail(noteId),
      suspense: true,
    });

    return { data };
  },

  useGetNoteMenuId: (menuId: number) => {
    const { data } = useQuery({
      queryKey: ['note', 'menuId', menuId],
      queryFn: () => noteApi.getNoteMenuId(menuId),
      suspense: true,
    });

    return { data };
  },

  useGetAuthUserList: (params: AuthUserListQueryParams) => {
    const { data } = useQuery({
      queryKey: ['note', 'authUserList'],
      queryFn: () => noteApi.getAuthUserList(params),
    });

    return { data };
  },
};

export default noteQuery;
