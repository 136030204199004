// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RDu_t3iamvaKXfplK8lR{display:flex;justify-content:space-between;padding:5px 0;gap:10px}`, "",{"version":3,"sources":["webpack://./front/src/features/mobile-notification/components/layouts/header.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,aAAA,CACA,QAAA","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-between;\n  padding: 5px 0;\n  gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RDu_t3iamvaKXfplK8lR`
};
export default ___CSS_LOADER_EXPORT___;
