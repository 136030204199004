import React from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import workCategoryMutation from '@front/src/features/work/features/work/features/category/query/mutation';
import WorkCategoryTableBody from '@front/src/features/work/features/work/features/category/components/TableBody';

export default function WorkWorkCategoryTable({
  maxWidth,
  dataId,
  menuId,
  ...rest
}: Readonly<SectionComponentProps>) {
  const { isLoading, onUpdate } = useMutationLogic({ dataId, menuId });
  const rowActions = {
    onUpdate,
  };
  return (
    <UiBuilderTable
      isLoading={isLoading}
      rowActions={rowActions}
      BodyComponent={WorkCategoryTableBody}
      dataId={dataId}
      menuId={menuId}
      {...rest}
      // sx={{
      //   maxWidth,
      // }}
    />
  );
}

function useMutationLogic({ dataId, menuId }) {
  const { mutate: onUpdate, isLoading } = workCategoryMutation.useUpdate(dataId, menuId);
  return {
    isLoading,
    onUpdate,
  };
}
