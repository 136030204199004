// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.P10BMxYHGUPg2UeaalwB{width:1200px}.i1ahNnPHi6AgiC13tOUR{display:flex;gap:12px;padding:8px}`, "",{"version":3,"sources":["webpack://./front/src/features/affiliated-company/features/meeting-history/features/modal/components/form-companion-table.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGF,sBACE,YAAA,CACA,QAAA,CACA,WAAA","sourcesContent":[".container {\n  width: 1200px;\n}\n\n.user__list {\n  display: flex;\n  gap: 12px;\n  padding: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `P10BMxYHGUPg2UeaalwB`,
	"user__list": `i1ahNnPHi6AgiC13tOUR`
};
export default ___CSS_LOADER_EXPORT___;
