import React, { useContext } from 'react';
import { ProjectSalesInfoSubjectReviewModalDesignDateDetailContext } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date/features/detail/widgets/context';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';
import { ProjectSalesInfoSubjectReviewModalDesignDateDetailTableRow } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date/features/detail/components/detail-table-row';

export { Detail as ProjectSalesInfoSubjectReviewModalDesignDateDetail };
const Detail = () => {
  const { isLoading } = useContext(ProjectSalesInfoSubjectReviewModalDesignDateDetailContext);
  if (isLoading) {
    return (
      <TableLoadingSpinner
        colSpan={3}
        height="62px"
      />
    );
  }
  return <ProjectSalesInfoSubjectReviewModalDesignDateDetailTableRow />;
};
