import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from 'services/reducer';
import { initialPersonnelQuery } from 'personnel/query';
import { personnelAction } from 'personnel/action';
import { useFormik } from 'formik';
import PersonnelPage from 'personnel/view/Page';
import { departmentAction } from 'department/action';
import { usePersonnelState } from '@front/personnel/useState';
import { useShallow } from 'zustand/react/shallow';
import { useGetReadOnly } from '@front/src/hooks/useGetReadOnly';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { Box } from '@mui/material';

function Element({ menuId, ...rest }: Readonly<SectionComponentProps>) {
  const dispatch = useDispatch();
  const { page } = useSelector((root: RootState) => root.personnel);
  const setFilter = useCallback(
    (formikProps) => dispatch(personnelAction.setFilter({ ...formikProps, menuId })),
    [dispatch]
  );
  const formik = useFormik({
    initialValues: initialPersonnelQuery,
    onSubmit: (values) => {
      setFilter({
        ...values,
        startDate: values.startDate && values.startDate.format('YYYY-MM-DD'),
        endDate: values.endDate && values.endDate.format('YYYY-MM-DD'),
      });
    },
  });
  useEffect(() => {
    dispatch(departmentAction.requestList(menuId));
    setFilter(initialPersonnelQuery);
  }, [menuId]);
  return (
    <PersonnelPage
      page={page}
      formik={formik}
      menuId={menuId}
      {...rest}
    />
  );
}

const PersonnelPageRoute = ({ name, ...rest }: Readonly<SectionComponentProps>) => {
  const { Layout, Body } = AddDeleteTable;
  const { readOnly } = useGetReadOnly();
  const { setReadOnly } = usePersonnelState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <Layout>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '16px',
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
          p: '20px',
        }}
      >
        {name}
      </Box>
      <Body
        sx={{
          p: '20px',
        }}
      >
        <Element {...rest} />
      </Body>
    </Layout>
  );
};

export default PersonnelPageRoute;
