import React, { useEffect } from 'react';
import { Box, Divider } from '@mui/material';
import { PersonalSettingsModalUISettingsNavBar } from '@front/src/features/personal-settings-modal/features/ui-settings/components/NavBar';
import { PersonalSettingsModalUISettingsTabList } from '@front/src/features/personal-settings-modal/features/ui-settings/components/TabList';
import { useShallow } from 'zustand/react/shallow';
import { UISettingsType } from '@front/src/features/personal-settings-modal/features/ui-settings/utils/constant';
import { PersonalSettingsModalUISettingsHeaderSettingsFeature } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings';
import { PersonalSettingsModalUISettingsModalSettingsFeature } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings';
import { personalSettingsModalUISettingsRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/repository/repository';
import { usePersonalSettingsModalUISettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/useState';

export { Index as PersonalSettingsModalUISettingsFeature };

interface Props {
  authMenuId: number | undefined;
  onClose: () => void;
}

const Index = ({ authMenuId, onClose }: Props) => {
  const { data: sectionList } =
    personalSettingsModalUISettingsRepository.useGetSectionList(authMenuId);
  const { sectionId, type, setSectionId, setType } = usePersonalSettingsModalUISettingsState(
    useShallow((state) => ({
      sectionId: state.sectionId,
      setSectionId: state.setSectionId,
      type: state.type,
      setType: state.setType,
    }))
  );
  useEffect(
    () => () => {
      setSectionId(undefined);
      setType(UISettingsType.HEADER_SETTINGS);
    },
    [setSectionId, setType]
  );
  if (!sectionList) return <></>;
  if (sectionList.length === 0) {
    return (
      <Box
        sx={{
          height: '64dvh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        설정이 가능한 섹션이 없습니다
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        p: '12px 0',
      }}
    >
      <PersonalSettingsModalUISettingsNavBar authMenuId={authMenuId} />
      <Divider
        orientation="vertical"
        sx={{
          height: '72dvh',
        }}
      />
      {sectionId && (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <PersonalSettingsModalUISettingsTabList />
          <Box
            sx={{
              p: '12px',
            }}
          >
            {type === UISettingsType.HEADER_SETTINGS && (
              <PersonalSettingsModalUISettingsHeaderSettingsFeature
                authMenuId={authMenuId}
                onClose={onClose}
              />
            )}
            {type === UISettingsType.MODAL_SETTINGS && (
              <PersonalSettingsModalUISettingsModalSettingsFeature authMenuId={authMenuId} />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
