import { useController, useForm } from 'react-hook-form';
import React from 'react';
import userSelectorRepository from '@front/src/features/user-selector/repository/repository';
import UserSelectorModalFeature from '@front/src/features/user-selector/features/modal';
import UserSelectorInput from '@front/src/features/user-selector/features/input/components/Input';
import type { UserSelectorInputProps } from '@front/src/features/user-selector/features/input';

interface Props extends UserSelectorInputProps {
  onOpen: () => void;
  onClose: () => void;
  open: boolean;
  multi: boolean;
  readOnly: boolean;
  onClickForRemove?: () => void;
}

export default function UserSelectorInputAndModal({
  value,
  readOnly,
  typeList,
  menuIdList,
  ...rest
}: Readonly<Props>) {
  const { data: allUser } = userSelectorRepository.useGetAllUser();
  const getInputList = () => {
    if (Array.isArray(value)) {
      return value.map((id) => allUser?.find((a) => a.id === id));
    }
    const find = allUser?.find((a) => a.id === value);
    if (!find) return [];
    return [find];
  };
  const { control } = useForm({
    values: {
      inputList: getInputList(),
    },
  });
  const {
    field: { value: inputList },
  } = useController({
    control,
    name: 'inputList',
  });
  const convertedValue = inputList.map((s) => s?.id as number);
  return (
    <>
      {!readOnly && (
        <UserSelectorModalFeature
          value={convertedValue}
          menuIdList={menuIdList}
          typeList={typeList}
          {...rest}
        />
      )}
      <UserSelectorInput
        readOnly={readOnly}
        control={control}
        {...rest}
      />
    </>
  );
}
