import React, { useEffect } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoCollectionContractEarningsBtn } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/components/contract-earnings-btn';
import { ProjectSalesInfoContractEarningsStatus } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/components/contract-earnings-status';
import { ProjectSalesInfoContractEarningsModalWrapper } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/modal/components/modal-wrapper';
import { ProjectSalesInfoContractEarningsItem } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/components/contract-earnings-item';
import { ProviderWrapper } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/widgets/provider';
import { ProjectSalesInfoCollectionContractEarningsSurtax } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/components/contract-earnings-surtax';
import { useProjectSalesInfoContractEarningsState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoContractEarningsWidget = ({ readOnly, name }: Readonly<Props>) => {
  const { Header, Layout, Body } = AddDeleteTable;
  const { setReadOnly } = useProjectSalesInfoContractEarningsState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <ProviderWrapper>
      <ProjectSalesInfoContractEarningsModalWrapper />
      <Layout>
        <Header title={name}>
          <ProjectSalesInfoCollectionContractEarningsSurtax />
          <ProjectSalesInfoCollectionContractEarningsBtn />
        </Header>
        <Body>
          <ProjectSalesInfoContractEarningsStatus />
          <ProjectSalesInfoContractEarningsItem />
        </Body>
      </Layout>
    </ProviderWrapper>
  );
};

export default ProjectSalesInfoContractEarningsWidget;
