import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { ProjectSalesInfoBidResultContext } from '@front/src/features/project-sales-info/features/bid/features/bid-result/widgets/context';
import { useProjectSalesInfoBidResultState } from '@front/src/features/project-sales-info/features/bid/features/bid-result/widgets/useState';
import { handleDeleteIdListConfirmAfterClick } from '@front/src/utils';
import { useCustomDialog } from '@front/src/features/dialog';
import { useShallow } from 'zustand/react/shallow';

export { BidInfoBtn as ProjectSalesInfoBidResultBtn };

const BidInfoBtn = () => {
  const {
    h: { onDelete },
  } = useContext(ProjectSalesInfoBidResultContext);
  const { confirm } = useCustomDialog();
  const { setIdList, idList, setIsCreateModalOpen } = useProjectSalesInfoBidResultState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
      setIsCreateModalOpen: state.setIsCreateModalOpen,
    })
  );
  const onDeleteClick = useCallback(
    () =>
      handleDeleteIdListConfirmAfterClick(idList, confirm, () => {
        onDelete({ idList });
        setIdList([]);
      }),
    [onDelete, idList, setIdList]
  );
  const { readOnly } = useProjectSalesInfoBidResultState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      <Button
        onClick={onDeleteClick}
        disabled={readOnly}
      >
        선택삭제
      </Button>
      <Button
        onClick={() => setIsCreateModalOpen()}
        disabled={readOnly}
      >
        추가
      </Button>
    </>
  );
};
