import { createContext } from 'react';
import type { AccountingUploadHistoryTableRowView } from '@front/src/features/accounting/features/upload/features/ecount-data/types/view';

export { Context as AccountingSettingsManagementAccountingAccountHeaderGetUpdatedAtContext };

interface State {
  detail?: AccountingUploadHistoryTableRowView;
}

const Context = createContext<State>({});
