import { create } from 'zustand';

interface State {
  idList: number[];
  setIdList: (idList: number[]) => void;
  id?: number;
  setId: (id?: number) => void;
}

export { useState as useProjectSalesInfoSubjectReviewModalComplexInformationState };

const useState = create<State>((set) => ({
  idList: [],
  setIdList: (idList) => set(() => ({ idList })),
  setId: (id) => set(() => ({ id })),
}));
