import React, { useEffect, useMemo } from 'react';
import { ProjectSalesInfoContractEarningsTable } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/components/contract-earnings-table';
import { collectionModalLayout } from '@front/src/features/project-sales-info/features/collection/components/layout';
import { ProjectSalesInfoContractEarningsHeader } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/components/contract-earnings-table-header';
import { ProjectSalesInfoContractEarningsItemDetailCreateModalWrapper } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/components/detail-create-modal-wrapper';
import { useProjectSalesInfoContractEarningsItemLogic } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/useLogic';
import { ProjectSalesInfoContractEarningsItemContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/context';
import { ProjectSalesInfoContractEarningsItemDetailUpdateModalWrapper } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/components/detail-update-modal-wrapper';
import { useContractEarningsItemState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { Widget as ProjectSalesInfoContractEarningsItemWidget };

interface Props {
  itemId: number;
  name: string;
  readOnly?: boolean;
}

const Widget = ({ itemId, name, readOnly }: Props) => {
  const { Layout, Header, Body } = collectionModalLayout;
  const {
    h: { onDeleteDetails, onDelete, onUpdate },
    d: {
      contractEarningsItemList,
      bondClassificationOptionList,
      affiliatedCompanyPersonList,
      collectionPossibilityOptionList,
    },
    isLoading,
    idList,
    setIdList,
  } = useProjectSalesInfoContractEarningsItemLogic({ itemId });
  const value = useMemo(
    () => ({
      h: {
        onDeleteDetails,
        onDelete,
        onUpdate,
      },
      d: {
        contractEarningsItemList,
        bondClassificationOptionList,
        collectionPossibilityOptionList,
        affiliatedCompanyPersonList,
      },
      isLoading,
      name,
      itemId,
      idList,
      setIdList,
    }),
    [
      isLoading,
      collectionPossibilityOptionList,
      contractEarningsItemList,
      onDelete,
      onDeleteDetails,
      onUpdate,
      name,
      idList,
      setIdList,
      itemId,
      affiliatedCompanyPersonList,
      bondClassificationOptionList,
    ]
  );
  const { setReadOnly } = useContractEarningsItemState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <ProjectSalesInfoContractEarningsItemContext.Provider value={value}>
      <ProjectSalesInfoContractEarningsItemDetailCreateModalWrapper />
      <ProjectSalesInfoContractEarningsItemDetailUpdateModalWrapper />
      <Layout padding="10px 0">
        <Header margin="10px 0 0 0">
          <ProjectSalesInfoContractEarningsHeader />
        </Header>
        <Body padding="0px">
          <ProjectSalesInfoContractEarningsTable />
        </Body>
      </Layout>
    </ProjectSalesInfoContractEarningsItemContext.Provider>
  );
};
