// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QANynaGTe026WtSExNmf{display:flex;width:100%}.FJTYxP5cqeabUpYk5S8A{justify-content:space-between}.e3YkP96Jw53SH0qBGdGH{justify-content:flex-end}.L4dSJUqHc6TAuBcmFmQQ{display:flex;gap:10px;width:100%;justify-content:space-between}.OVyMecBloqYxXjcpVZ98{font-size:16px;font-weight:bold}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/collection/components/layout/header.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CAGF,sBACE,6BAAA,CAGF,sBACE,wBAAA,CAGF,sBACE,YAAA,CACA,QAAA,CACA,UAAA,CACA,6BAAA,CAGF,sBACE,cAAA,CACA,gBAAA","sourcesContent":[".container {\n  display: flex;\n  width: 100%;\n}\n\n.between {\n  justify-content: space-between;\n}\n\n.end {\n  justify-content: flex-end;\n}\n\n.children {\n  display: flex;\n  gap: 10px;\n  width: 100%;\n  justify-content: space-between;\n}\n\n.title {\n  font-size: 16px;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `QANynaGTe026WtSExNmf`,
	"between": `FJTYxP5cqeabUpYk5S8A`,
	"end": `e3YkP96Jw53SH0qBGdGH`,
	"children": `L4dSJUqHc6TAuBcmFmQQ`,
	"title": `OVyMecBloqYxXjcpVZ98`
};
export default ___CSS_LOADER_EXPORT___;
