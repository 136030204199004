import React, { useCallback, useContext } from 'react';
import { TableRow } from '@mui/material';
import { spliceAndPushIfExist } from '@front/src/features/affiliated-company/utils';
import Input from '@front/layouts/Input';
import type { AffiliatedCompanyAddressView } from '@front/src/features/affiliated-company/features/outline/features/address/types/view';
import { AffiliatedCompanyOutlineAddressContext } from '@front/src/features/affiliated-company/features/outline/features/address/widgets/context';
import TableSideHeaderWithCheckbox from '@front/src/components/table-side-header-with-checkbox/table-side-header-with-checkbox';
import { OldTd } from '@front/layouts/Table';
import { useAffiliatedCompanyOutlineState } from '@front/src/features/affiliated-company/features/outline/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { AddressTableRow as AffiliatedCompanyOutlineAddressTableRow };

interface Props {
  item: AffiliatedCompanyAddressView;
  index: number;
}

const AddressTableRow = ({ item, index }: Props) => {
  const {
    h: { setIdList, openAddressModal, setAddressId },
    idList,
  } = useContext(AffiliatedCompanyOutlineAddressContext);
  const handleCheckboxChange = useCallback(
    (id) => () => {
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  const { readOnly } = useAffiliatedCompanyOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <TableRow>
      <TableSideHeaderWithCheckbox
        title="회사주소"
        index={index}
        idList={idList}
        item={item}
        handleCheckboxChange={handleCheckboxChange}
        disabled={readOnly}
      />
      <OldTd>
        <Input
          name="address"
          variant="outlined"
          onClick={() => {
            setAddressId(item.id);
            openAddressModal();
          }}
          value={item.address ?? ''}
          openModal
          disabled={readOnly}
        />
      </OldTd>
    </TableRow>
  );
};
