import { useMutation, useQueryClient } from 'react-query';
import { useCustomDialog } from '@front/src/features/dialog';
import { handleError } from '@front/src/utils';
import type { AffiliatedCompanyMeetingHistoryUpdateParameter } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/types/parameter';
import { affiliatedCompanyMeetingHistoryModalApi } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/query/api';

export const affiliatedCompanyMeetingHistoryModalMutation = {
  useMeetingHistoryCreate: (menuId) => {
    const queryClient = useQueryClient();
    const { alert } = useCustomDialog();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyMeetingHistoryUpdateParameter) =>
        affiliatedCompanyMeetingHistoryModalApi.createMeetingHistory(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-company', 'meeting-history', 'list'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useMeetingHistoryUpdate: (menuId) => {
    const queryClient = useQueryClient();
    const { alert } = useCustomDialog();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyMeetingHistoryUpdateParameter) =>
        affiliatedCompanyMeetingHistoryModalApi.updateMeetingHistory(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-company', 'meeting-history'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
