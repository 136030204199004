import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { WorkDetailUpdateParameter } from '@front/src/features/work/features/work/features/detail/types/parameter';
import { convertEmptyToNull } from '@front/src/utils';
import type { CommonGetParameter } from '@front/src/types/parameter';

const workDetailMutation = {
  useCreate: (workId, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.createDetail(id, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'detail', workId],
        });

        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useAddTop: (workId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: () => workWorkApi.addTopDetail(workId, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({});
        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useAddBottom: (workId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: () => workWorkApi.addBottomDetail(workId, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'detail', workId],
        });

        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useUpdate: (workId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: WorkDetailUpdateParameter) => workWorkApi.updateDetail(params, menuId),
      onMutate: async (data: WorkDetailUpdateParameter) => {
        const { data: previousDataList } =
          (queryClient.getQueryData(['work', 'detail']) as any) ?? {};
        const newData = convertEmptyToNull(data);

        const previousData = previousDataList?.find((item) => item?.id === newData.id);
        const prevData = convertEmptyToNull(previousData);

        return newData.content === prevData?.content && newData.date === prevData?.date;
      },
      onSuccess: async (data, _, context) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'detail', workId],
        });

        return { data, context };
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useSeqUp: (workId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.updateDetailSeqUp(id, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'detail', workId],
        });

        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useSeqDown: (workId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.updateDetailSeqDown(id, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'detail', workId],
        });

        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useDelete: (params: CommonGetParameter) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.deleteDetail(id, params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'detail', params.dataId],
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useDeleteAll: (params: CommonGetParameter) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: () => workWorkApi.deleteAllDetail(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'detail', params.dataId],
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
};

export default workDetailMutation;
