import { Box } from '@mui/material';
import React, { memo, useCallback } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import TextBox from '@front/layouts/Text';
import { ostAction } from '@front/ost/action';
import { ColorPalette } from '@front/assets/theme';
import type { RootState } from '@front/services/reducer';
import OstMemoItemService from '@front/ost/service/memo/item';
import CircularProgress from '@front/components/CircularProgress';
import type { OstMemoUpdateParameter } from '@front/ost/parameter';
import { fixedMemoFormAndFilterHeight } from '@front/util/ConstantCommonVariable';

const MemoListService = () => {
  const dispatch = useDispatch();
  const [memoList] = useSelector((root: RootState) => [root.ost.memoList], shallowEqual);
  const { isMemoLoading } = useSelector(
    (root: RootState) => ({
      isMemoLoading: root.ost.isMemoLoading,
    }),
    shallowEqual
  );

  const onSubmit = useCallback(
    (params: OstMemoUpdateParameter) => {
      dispatch(ostAction.updateMemo(params));
      formik.resetForm();
    },
    [dispatch]
  );

  const formik = useFormik({
    initialValues: {
      description: '',
    } as OstMemoUpdateParameter,
    onSubmit: onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: `calc(100dvh - ${fixedMemoFormAndFilterHeight}px)`,
          flexWrap: 'nowrap',
          flexDirection: 'column',
          overflowY: 'hidden',
          alignItems: 'flex-start',
          padding: '0 0 15px 0',
        }}
      >
        <Box
          className="scroll-bar-holder"
          sx={{
            width: '100%',
            height: `100%`,
            overflowY: 'auto',
            padding: '0 10px 0px 10px',
          }}
        >
          {memoList.length === 0 && (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                border: `1px solid ${ColorPalette._e4e9f2}`,
                borderRadius: '5px',
                marginBottom: '10px',
                backgroundColor: ColorPalette._ffffff,
                padding: '15px',
                justifyContent: 'center',
              }}
            >
              <TextBox
                variant="body2"
                sx={{ minHeight: '30px', lineHeight: '30px' }}
              >
                {isMemoLoading && <CircularProgress size={30} />}
                {!isMemoLoading && <>해당하는 메모가 없습니다.</>}
              </TextBox>
            </Box>
          )}

          {memoList.length > 0 &&
            memoList.map((memo) => (
              <OstMemoItemService
                key={memo.id}
                memo={memo}
              />
            ))}
        </Box>
      </Box>
    </FormikProvider>
  );
};

const OstMemoListService = memo(MemoListService);

export default OstMemoListService;
