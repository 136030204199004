import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { shallow } from 'zustand/shallow';
import { ProjectSalesInfoSubjectReviewModalComplexInformationCreateContext } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/create/widgets/context';
import { useProjectSalesInfoSubjectReviewModalState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/useState';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoSubjectReviewShapeRatioState } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/useState';

export { CreateButton as ProjectSalesInfoSubjectReviewModalComplexInformationCreateButton };

const CreateButton = () => {
  const { onCreate } = useContext(
    ProjectSalesInfoSubjectReviewModalComplexInformationCreateContext
  );
  const { id } = useProjectSalesInfoSubjectReviewModalState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const handleAddClick = useCallback(() => {
    onCreate(id);
  }, [id, onCreate]);
  const { readOnly } = useProjectSalesInfoSubjectReviewShapeRatioState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Button
      onClick={handleAddClick}
      disabled={readOnly}
    >
      동추가
    </Button>
  );
};
