import type { ReactNode } from 'react';
import React, { useCallback } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { useAffiliatedPersonAppBarState } from '@front/src/features/affiliated-person/features/app-bar/useState';
import { useShallow } from 'zustand/react/shallow';
import { AffiliatedPersonConfirmModalBody } from '@front/src/features/affiliated-person/features/app-bar/components/confirm-modal-body';

export { ConfirmModal as AffiliatedPersonConfirmModal };

interface Props {
  widget: ReactNode;
}

const ConfirmModal = ({ widget }: Props) => {
  const { isConfirmModalOpen, setIsConfirmModalOpen } = useAffiliatedPersonAppBarState(
    useShallow((state) => ({
      isConfirmModalOpen: state.isConfirmModalOpen,
      setIsConfirmModalOpen: state.setIsConfirmModalOpen,
    }))
  );
  const handleClose = useCallback(() => {
    setIsConfirmModalOpen(false);
  }, [setIsConfirmModalOpen]);
  return (
    <ModalLayout
      title="신규 인물 등록 확인 메세지"
      open={isConfirmModalOpen}
      width="24dvw"
      onClose={handleClose}
    >
      <AffiliatedPersonConfirmModalBody widget={widget} />
    </ModalLayout>
  );
};
