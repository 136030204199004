import React, { useMemo } from 'react';
import { useProjectSalesInfoSubjectReviewModalKaKaoMapLogic } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/kakao-map/widgets/useLogic';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { ProjectSalesInfoSubjectReviewModalKaKaoMap } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/kakao-map/components/kakao-map';
import { ProjectSalesInfoSubjectReviewModalKaKaoMapContext } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/kakao-map/widgets/context';

export { Widget as ProjectSalesInfoSubjectReviewModalKaKaoMapWidget };

const Widget = () => {
  const {
    d: { address },
    h: { remove },
    isLoading,
  } = useProjectSalesInfoSubjectReviewModalKaKaoMapLogic();
  const value = useMemo(
    () => ({
      d: { address },
      h: { remove },
    }),
    [address, remove]
  );
  if (isLoading) {
    return <LoadingSpinner height="900px" />;
  }
  return (
    <ProjectSalesInfoSubjectReviewModalKaKaoMapContext.Provider value={value}>
      <ProjectSalesInfoSubjectReviewModalKaKaoMap />
    </ProjectSalesInfoSubjectReviewModalKaKaoMapContext.Provider>
  );
};
