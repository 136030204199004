import { useQuery } from 'react-query';
import type { AccountingSettingsManagementAccountingAccountTableCategoryListGetParameter } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/types/parameter';
import type {
  AccountingSettingsManagementAccountingAccountCountView,
  AccountingSettingsManagementAccountingAccountTableCategoryView,
} from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/types/view';
import { accountingManagementAccountingAccountTableApi } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/query/api';

export { Query as accountingManagementAccountingAccountTableQuery };

const Query = {
  useCountGet: () => {
    const { data, isLoading } = useQuery<AccountingSettingsManagementAccountingAccountCountView>({
      queryFn: () => accountingManagementAccountingAccountTableApi.getCountMap(),
      queryKey: ['accounting', 'management-accounting-account', 'table-header-count'],
      suspense: true,
    });
    return {
      data,
      isLoading,
    };
  },
  useListGet: (
    params: AccountingSettingsManagementAccountingAccountTableCategoryListGetParameter
  ) => {
    const { data, isLoading, remove } = useQuery<
      AccountingSettingsManagementAccountingAccountTableCategoryView[]
    >({
      queryFn: () => accountingManagementAccountingAccountTableApi.getList(params),
      queryKey: [
        'accounting',
        'management-accounting-account',
        'category-list',
        ...params.parentIdList,
      ],
      suspense: true,
      cacheTime: 0,
    });
    return {
      data,
      isLoading,
      remove,
    };
  },
};
