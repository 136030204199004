import { useQuery } from 'react-query';
import type { ProjectDesignOutlineView } from '@front/src/features/project-sales-info/features/design-outline/types/view';
import { projectSalesInfoDesignOutlineApi } from '@front/src/features/project-sales-info/features/design-outline/query/api';
import type { AddressView } from '@front/src/types';

export const projectSalesInfoDesignOutlineQuery = {
  useProjectDesignOutlineGet: (id: number) => {
    const { data, isLoading } = useQuery<ProjectDesignOutlineView>({
      queryKey: ['project-sales-info', 'design-outline', id],
      queryFn: () => projectSalesInfoDesignOutlineApi.useProjectDesignOutlineGet(id),
      enabled: !!id,
    });
    return {
      data,
      isLoading,
    };
  },

  useCity1DataGet: (id: number) => {
    const { data } = useQuery<AddressView[]>({
      queryKey: ['project-sales-info', 'design-outline', 'city1', id],
      queryFn: () => projectSalesInfoDesignOutlineApi.useCity1DataGet(),
      enabled: !!id,
    });
    return {
      data,
    };
  },
  useCity2DataGet: (id: number, code?: string) => {
    const { data } = useQuery<AddressView[]>({
      queryKey: ['project-sales-info', 'design-outline', 'city2', code, id],
      queryFn: () => projectSalesInfoDesignOutlineApi.useCity2DataGet(code),
      enabled: !!id && !!code,
    });
    return {
      data,
    };
  },
};
