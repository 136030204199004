import React, { useMemo } from 'react';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalLogic } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/detail-modal/widgets/useLogic';
import ModalLayout from '@front/layouts/ModalLayout';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/detail-modal/widgets/context';
import AffiliatedCompanyPersonDetailModalCharacterBasicWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/basic/widgets/widget';
import { projectSalesInfoAffiliatedCompanyPersonDetailModalLayout } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/detail-modal/components/layout';
import AffiliatedCompanyPersonDetailModalCharacterHobbyWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/widgets/widget';
import AffiliatedCompanyPersonDetailModalCharacterFamilyInformationWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/widgets/widget';
import AffiliatedCompanyPersonDetailModalCharacterPreferenceWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/widgets/widget';
import AffiliatedCompanyPersonDetailModalCharacterCharacterWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/character/widgets/widget';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyDetailModalDetailTable } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/detail-modal/components/detail-table';

const ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalWidget = () => {
  const { Layout, Footer, Section } = projectSalesInfoAffiliatedCompanyPersonDetailModalLayout;
  const {
    d: { detail },
    h: { onClose },
    isLoading,
    isOpen,
    personId,
  } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalLogic();
  const value = useMemo(
    () => ({
      d: { detail },
      isLoading,
    }),
    [detail, isLoading]
  );
  return (
    <ModalLayout
      title="관계사 인물 상세정보"
      width="80dvw"
      height="80dvh"
      open={isOpen}
      onClose={onClose}
    >
      <Layout>
        <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalContext.Provider
          value={value}
        >
          <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyDetailModalDetailTable />
        </ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalContext.Provider>
        <Footer>
          <Section>
            <AffiliatedCompanyPersonDetailModalCharacterBasicWidget
              readOnly
              id={personId}
            />
            <AffiliatedCompanyPersonDetailModalCharacterHobbyWidget
              readOnly
              id={personId}
            />
            <AffiliatedCompanyPersonDetailModalCharacterPreferenceWidget
              readOnly
              id={personId}
            />
          </Section>
          <Section>
            <AffiliatedCompanyPersonDetailModalCharacterFamilyInformationWidget
              readOnly
              id={personId}
            />
            <AffiliatedCompanyPersonDetailModalCharacterCharacterWidget
              readOnly
              id={personId}
            />
          </Section>
        </Footer>
      </Layout>
    </ModalLayout>
  );
};

export default ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalWidget;
