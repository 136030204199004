import React from 'react';
import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import workWorkerQuery from '@front/src/features/work/features/work/features/worker/query/query';
import type { WorkWorkerView } from '@front/src/features/work/features/work/features/worker/types/view';
import WorkWorkerTableRow from '@front/src/features/work/features/work/features/worker/components/TableRow';

export default function WorkWorkerTableBody({
  sectionId,
  dataId,
  menuId,
  ...rest
}: Readonly<UIBuilderTableBodyProps>) {
  const { data: list } = workWorkerQuery.useGetList({
    dataId,
    menuId,
    sectionId,
  });
  return (
    <UIBuilderTableBody<WorkWorkerView>
      list={list}
      RowComponent={WorkWorkerTableRow}
      sectionId={sectionId}
      dataId={dataId}
      menuId={menuId}
      {...rest}
    />
  );
}
