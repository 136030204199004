import React from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import WorkApprovalTableBody from '@front/src/features/work/features/work/features/approval/components/TableBody';
import PlusOne from '@mui/icons-material/PlusOne';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

export default function WorkApprovalTable({ dataId, ...rest }: Readonly<SectionComponentProps>) {
  const setLeftDrawerState = useGlobalNavBarState((state) => state.setLeftDrawerState);
  const setNoteId = useGlobalNavBarState((state) => state.setNoteId);
  const action = () => {
    sessionStorage.setItem('dataId', dataId.toString());
    setNoteId(0);
    setLeftDrawerState('approval');
  };
  const itemList = [
    {
      text: '결재 상신',
      icon: PlusOne,
      action,
    },
  ];
  return (
    <UiBuilderTable
      BodyComponent={WorkApprovalTableBody}
      controlPanelHeaderItemList={itemList}
      dataId={dataId}
      {...rest}
    />
  );
}
