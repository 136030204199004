import React, { Suspense } from 'react';
import { AffiliatedPersonAppBarPersonList } from '@front/src/features/affiliated-person/features/app-bar/widgets/person-list/components/list';
import { AffiliatedPersonAppBarPersonListProvider } from '@front/src/features/affiliated-person/features/app-bar/widgets/person-list/provider/provider';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';

export { Widget as AffiliatedPersonAppBarPersonListWidget };
const Widget = () => (
  <Suspense
    fallback={
      <TableLoadingSpinner
        colSpan={5}
        height="40px"
      />
    }
  >
    <AffiliatedPersonAppBarPersonListProvider>
      <AffiliatedPersonAppBarPersonList />
    </AffiliatedPersonAppBarPersonListProvider>
  </Suspense>
);
