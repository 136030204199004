import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useShallow } from 'zustand/react/shallow';
import type { WorkDetailView } from '@front/src/features/work/features/work/features/detail/types/view';
import { ColorPalette } from '@front/assets/theme';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import dayjs from 'dayjs';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/TableBody';
import useWorkWorkDetailState from '@front/src/features/work/features/work/features/detail/useState';
import { useCustomDialog } from '@front/src/features/dialog';
import { YYYY_MM_DD } from '@front/src/utils';
import { getCommonControlPanelItemList } from '@front/src/features/work/features/work/utils/constant';
import type { NoteMenuIdView } from '@front/src/features/note/types/view';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import useNoteStore from '@front/src/features/note/useState';

export default function WorkDetailTableRow({
  item,
  rowActions,
  ...rest
}: UIBuilderTableRowProps<WorkDetailView>) {
  const { recentUpdatedId, editOpenList, addEditOpenId } = useWorkWorkDetailState(
    useShallow((state) => ({
      recentUpdatedId: state.recentUpdatedId,
      editOpenList: state.editOpenList,
      addEditOpenId: state.addEditOpenId,
    }))
  );
  const methods = useForm({
    values: getFormValues(item),
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    rowActions?.onUpdate({
      ...getParameter(data),
      date: data.date ? data.date.format(YYYY_MM_DD) : null,
    });
  });
  const isEditOpen = editOpenList.includes(item.id);
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const { alert } = useCustomDialog();
  const onRemoveWithValidation = () => {
    if (item.user?.id !== loginUser?.id) {
      alert({
        children: [
          {
            value: '작성자만 삭제할 수 있습니다.',
          },
        ],
      });
      return;
    }
    rowActions?.onRemove(item.id);
  };
  const onEditWithValidation = () => {
    if (item.user?.id !== loginUser?.id) {
      alert({
        children: [
          {
            value: '작성자만 수정할 수 있습니다.',
          },
        ],
      });
      return;
    }
    addEditOpenId(item.id);
  };
  const setIsRightDrawerOpen = useGlobalNavBarState((state) => state.setIsRightDrawerOpen);
  const { setRowId, setSectionId } = useNoteStore(
    useShallow((state) => ({
      setRowId: state.setRowId,
      setSectionId: state.setSectionId,
    }))
  );
  const actions = {
    onAdd: () => rowActions?.onCreate(item.id),
    onRemove: onRemoveWithValidation,
    onMoveUp: () => rowActions?.onSeqDown(item.id),
    onMoveDown: () => rowActions?.onSeqUp(item.id),
    onEdit: onEditWithValidation,
    onNoteOpen: () => {
      const noteAuth: NoteMenuIdView = JSON.parse(sessionStorage.getItem('noteAuth') ?? '{}');
      if (!noteAuth.noteId || noteAuth.type === 'X') {
        alert({
          title: '권한 오류',
          children: [{ value: '접근 권한이 없습니다.' }],
        });
        return;
      }
      setIsRightDrawerOpen(true);
      setRowId(item.id);
      setSectionId(rest.sectionId);
    },
  };
  const itemList = getCommonControlPanelItemList({ actions, isEditOpen });
  const sx = {
    backgroundColor: recentUpdatedId === item.id ? ColorPalette._d2e7fa : 'inherit',
  };
  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        item={item}
        onSubmit={onSubmit}
        sx={sx}
        itemList={itemList}
        isEditMode={isEditOpen}
        {...rest}
      />
    </FormProvider>
  );
}

function getFormValues(item) {
  return {
    id: item.id,
    date: item.date ? dayjs(item.date) : null,
    content: item.content ?? '',
    textAttr1: item.textAttr1 ?? '',
    textAttr2: item.textAttr2 ?? '',
    textAttr3: item.textAttr3 ?? '',
    numberAttr1: item.numberAttr1,
    numberAttr2: item.numberAttr2,
    numberAttr3: item.numberAttr3,
    dateAttr1: item.dateAttr1 ? dayjs(item.dateAttr1) : null,
    dateAttr2: item.dateAttr2 ? dayjs(item.dateAttr2) : null,
    dateAttr3: item.dateAttr3 ? dayjs(item.dateAttr3) : null,
  };
}

function getParameter(data) {
  const params = {
    ...data,
  };
  Object.keys(params).forEach((key) => {
    params[key] = params[key] || null;
  });
  return params;
}
