import { createContext } from 'react';
import type { SmProjectBriefPersonView } from '@front/src/features/project-sales-info/features/abstract/features/affiliated-company/types/view';

interface State {
  list?: SmProjectBriefPersonView[];
}

export { Context as ProjectSalesInfoAbstractAffiliatedCompanyListContext };

const Context = createContext<State>({});
