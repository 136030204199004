import React, { useCallback, useContext, useEffect } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import type { ProjectReviewSavePaymentHistoryView } from '@front/src/features/project-sales-info/features/collection/features/save-history/types/view';
import { FormProvider, useForm } from 'react-hook-form';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import {
  strToYNConvertorCollection,
  YNOptionListCollection,
  YNToStrConvertorCollection,
} from '@front/src/features/project-sales-info/features/collection/features/save-history/utils/constant';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { ProjectSalesInfoCollectionSaveHistoryContext } from '@front/src/features/project-sales-info/features/collection/features/save-history/widgets/context';
import { useProjectSalesInfoCollectionSaveHistoryState } from '@front/src/features/project-sales-info/features/collection/features/save-history/widgets/useState';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useShallow } from 'zustand/react/shallow';
import { useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";
import {formatDateOrNull} from "@front/src/features/project-sales-info/utils";

export { SaveHistoryTableRow as ProjectSalesInfoCollectionSaveHistoryTableRow };

interface Props {
  item: ProjectReviewSavePaymentHistoryView;
}

const SaveHistoryTableRow = ({ item }: Props) => {
  const {
    h: { onUpdate },
  } = useContext(ProjectSalesInfoCollectionSaveHistoryContext);
  const { id, setId } = useProjectSalesInfoCollectionSaveHistoryState(
    useShallow((state) => ({
      id: state.id,
      setId: state.setId,
    }))
  );
  const { Text } = AddDeleteTable;
  const methods = useForm({
    values: {
      id: item.id,
      isSaved: YNToStrConvertorCollection(item?.isSaved) ?? '',
      saveDate: item?.saveDate ?? '',
      note: item?.note ?? '',
    },
  });
  const { getValues } = methods;
  const isSelected = item.id === id;
  const { readOnly } = useProjectSalesInfoCollectionSaveHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const handleRowClick = useCallback(() => {
    if (readOnly) return;
    setId(item.id);
  }, [setId, item.id, readOnly]);
  const onSubmit = methods.handleSubmit((data) => {
    onUpdate({
      ...data,
      isSaved: data.isSaved === '' ? null : strToYNConvertorCollection[data.isSaved],
      saveDate: formatDateOrNull(data.saveDate),
      note: data.note || null,
    });
  });
  const renderIfSelected = useCallback(
    (name) => {
      if (isSelected) {
        return (
          <HookFormInput
            name={name}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        );
      }
      return <Text>{getValues(name) || '-'}</Text>;
    },
    [isSelected, onSubmit, getValues, Text, readOnly]
  );
  const menuId = useGlobalNavBarState((state) => state.menuId);
  const loginUser = useSelector((root: RootState) => root.login.detail);
  const currentAuth = loginUser?.menus?.find((item) => item.id === (menuId ? +menuId : 0))
    ?.authorization.type;

  useEffect(() => {
    if (currentAuth === 'V') setId();
  }, [setId, currentAuth]);
  return (
    <FormProvider {...methods}>
      <TableRow
        hover={!readOnly}
        style={{
          height: '51px',
          cursor: readOnly ? 'default' : 'pointer',
        }}
        onClick={handleRowClick}
      >
        <OldTd>{item.collectionNameCompleted ?? '-'}</OldTd>
        <OldTd>{item.paymentRatio ? item.paymentRatio + '%' : '-'}</OldTd>
        <OldTd>{item.reviewAmount?.toLocaleString() ?? '-'}</OldTd>
        <OldTd>
          {isSelected ? (
            <HookFormSelect
              height="0px"
              isDefaultPossible
              name="isSaved"
              optionList={YNOptionListCollection}
              onSubmit={onSubmit}
              defaultLabel="선택"
            />
          ) : (
            <Text>{getValues('isSaved') || '-'}</Text>
          )}
        </OldTd>
        <OldTd sx={{textAlign:'left'}}>
          {isSelected ? (
            <DatePickerWithHookForm
              name="saveDate"
              onSubmit={onSubmit}
            />
          ) : (
            <Text>{getValues('saveDate') || '-'}</Text>
          )}
        </OldTd>
        <OldTd>{renderIfSelected('note')}</OldTd>
      </TableRow>
    </FormProvider>
  );
};
