import { default as axios } from '@front/src/config/axios';

import { projectActivityURL } from '@front/src/features/project-sales-info/features/activity/utils/constants';
import type { IdListDeleteParameter } from '@front/src/types/parameter';
import type {
  SalesInfoActivityAffiliatesUpdateParams,
  SalesInfoActivityUpdateParams,
  SalesInfoActivityUserUpdateParams,
} from '@front/src/features/project-sales-info/features/activity/types/parameters';

const url = {
  getActivityList: (id: number) => `${projectActivityURL}/${id}/sales-activity`,
  createActivity: (id: number) => `${projectActivityURL}/${id}/sales-activity`,
  createUsers: (activityId: number) =>
    `${projectActivityURL}/sales-activity/${activityId}/sales-activity-user`,
  createAffiliates: (userId: number) =>
    `${projectActivityURL}/sales-activity-user/${userId}/sales-activity-affiliates`,
  updateActivity: () => `${projectActivityURL}/sales-activity`,
  updateUsers: () => `${projectActivityURL}/sales-activity-user`,
  updateAffiliates: () => `${projectActivityURL}/sales-activity-affiliates`,
  deleteActivity: () => `${projectActivityURL}/sales-activity`,
  deleteUsers: (userId: number) => `${projectActivityURL}/sales-activity-user/${userId}`,
  deleteAffiliates: (affiliatesId: number) =>
    `${projectActivityURL}/sales-activity-affiliates/${affiliatesId}`,
};

export const projectSalesInfoActivityApi = {
  getActivityList: async (id: number) => await axios.get(url.getActivityList(id)),
  createActivity: async (id: number) => await axios.post(url.createActivity(id)),
  createUsers: async (activityId: number) => await axios.post(url.createUsers(activityId)),
  createAffiliates: async (userId: number) => await axios.post(url.createAffiliates(userId)),
  updateActivity: async (params: SalesInfoActivityUpdateParams) =>
    await axios.put(url.updateActivity(), params),
  updateUsers: async (params: SalesInfoActivityUserUpdateParams) =>
    await axios.put(url.updateUsers(), params),
  updateAffiliates: async (params: SalesInfoActivityAffiliatesUpdateParams) =>
    await axios.put(url.updateAffiliates(), params),
  deleteActivity: async (params: IdListDeleteParameter) =>
    await axios.delete(url.deleteActivity(), { data: params }),
  deleteUsers: async (userId: number) => await axios.delete(url.deleteUsers(userId)),
  deleteAffiliates: async (affiliatesId: number) =>
    await axios.delete(url.deleteAffiliates(affiliatesId)),
};
