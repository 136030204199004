export const affiliatedPersonSalesActivityTableHeaderList = [
  {
    name: 'No.',
    width: '2vw',
    colSpan: 1,
  },
  {
    name: '코드',
    width: '4vw',
    colSpan: 1,
  },
  {
    name: '프로젝트',
    width: '8vw',
    colSpan: 1,
  },
  {
    name: '날짜',
    width: '5vw',
    colSpan: 1,
  },
  {
    name: '시간',
    width: '4vw',
    colSpan: 1,
  },
  {
    name: '형태',
    width: '4vw',
    colSpan: 1,
  },
  {
    name: '담당자',
    width: '4vw',
    colSpan: 1,
  },
  {
    name: '관계사',
    width: '11vw',
    colSpan: 1,
  },
  {
    name: '관계사 인물',
    width: '6vw',
    colSpan: 1,
  },
  {
    name: '부서',
    width: '4vw',
    colSpan: 1,
  },
  {
    name: '직책',
    width: '4vw',
    colSpan: 1,
  },
  {
    name: '차기활동',
    width: '9vw',
    colSpan: 2,
  },
];
