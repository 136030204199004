import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { projectSalesInfoEstimationCompetitorCreateModalMutation } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/features/create-modal/query/mutation';

export { repository as projectSalesInfoEstimationCompetitorCreateModalRepository };

const repository = {
  useCreate: () => {
    const { mutate } = projectSalesInfoEstimationCompetitorCreateModalMutation.useCreate();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
