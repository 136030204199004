import React from 'react';
import { useProjectSalesInfoCollectionManagementModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/widgets/useState';
import { ProjectSalesInfoCollectionManagementModalUpdateModalWidget } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/features/update-modal/widgets/widget';

export { ModalWrapper as ProjectSalesInfoCollectionManagementModalUpdateModalWrapper };

const ModalWrapper = () => {
  const isUpdateModalOpen = useProjectSalesInfoCollectionManagementModalState(
    (state) => state.isUpdateModalOpen
  );
  return <>{isUpdateModalOpen && <ProjectSalesInfoCollectionManagementModalUpdateModalWidget />}</>;
};
