import { toFormData } from '@front/services/api';
import type { PersonnelShortVO, PersonnelVO } from 'personnel/domain';
import type { PersonnelQuery } from 'personnel/query';
import type Page from 'type/Page';
import type { PersonnelParameter } from 'personnel/parameter';
import axios from 'axios';
import { getMenuIdHeaders } from '@front/src/utils';

class PersonnelApi {
  async getPage(query: PersonnelQuery): Promise<Page<PersonnelShortVO>> {
    const { data } = await axios.get('/api/personnel', {
      params: {
        ...query,
        sex: query.sex?.join(',') as unknown as string,
        hiredType: query.hiredType?.join(',') as unknown as string,
        departmentId: query.departmentId?.join(',') as unknown as string,
      },
      headers: getMenuIdHeaders(query.menuId),
    });
    return data;
  }
  async getOne(params): Promise<PersonnelVO> {
    const { data } = await axios.get(`/api/personnel/${params.id}`, {
      headers: getMenuIdHeaders(params.menuId),
    });
    return data;
  }
  async update(params: PersonnelParameter): Promise<void> {
    const formData = toFormData(params);
    const { data } = await axios.put(`/api/personnel/${params.id}`, formData, {
      headers: getMenuIdHeaders(params.menuId),
    });
    return data;
  }
  async getProposalLog(params): Promise<PersonnelVO> {
    const { data } = await axios.get(`/api/personnel/${params.id}/ost-log/proposal`, {
      headers: getMenuIdHeaders(params.menuId),
    });
    return data;
  }
  async getVoteLog(params): Promise<PersonnelVO> {
    const { data } = await axios.get(`/api/personnel/${params.id}/ost-log/vote`, {
      headers: getMenuIdHeaders(params.menuId),
    });
    return data;
  }
}

export const personnelApi = new PersonnelApi();
