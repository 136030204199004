import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { ostRewardManagementAction } from '@front/ost_reward/action';
import { DetailStatusTableRowDetailIndividualPaymentStatusTable } from '@front/ost_reward/view/detail/detail-status-table-row-detail-individual-payment-status';
import { DetailStatusTableRowDetailPaymentStatusTable } from '@front/ost_reward/view/detail/detail-status-table-row-detail-payment-status';
import LoadingBar from '@front/layouts/LoadingBar';
import { Box } from '@mui/material';

export const DetailStatusTableRowDetail = () => {
  const dispatch = useDispatch();

  const { monthDetail } = useSelector(
    (root: RootState) => ({
      monthDetail: root.ostRewardMangement.monthDetail,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(ostRewardManagementAction.getMonthDetail());
  }, [dispatch]);

  if (typeof monthDetail === 'undefined') {
    return (
      <Box
        sx={{
          width: '100%',
          height: '80dvh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoadingBar />
      </Box>
    );
  }

  return (
    <>
      <DetailStatusTableRowDetailPaymentStatusTable paymentStatus={monthDetail.paymentStatus} />
      <DetailStatusTableRowDetailIndividualPaymentStatusTable
        ostName={monthDetail.paymentStatus.ostName}
        individualList={monthDetail.individualList}
      />
    </>
  );
};
