import {
  convertEmptyToNullForAmountParameter,
  convertEmptyToNullForParameter,
} from '@front/src/utils';

export const convertEstimationCompetitorDataForParameter = (data) => ({
  companyId: convertEmptyToNullForParameter(data.company?.id),
  clientCompanyId: convertEmptyToNullForParameter(data.clientCompany?.id),
  experimentInformation: convertEmptyToNullForParameter(data.experimentInformation),
  experimentAmount: convertEmptyToNullForAmountParameter(data.experimentAmount),
  reviewAmount: convertEmptyToNullForAmountParameter(data.reviewAmount),
  totalAmount: convertEmptyToNullForAmountParameter(data.totalAmount),
  unitPrice: convertEmptyToNullForAmountParameter(data.unitPrice),
  date: convertEmptyToNullForParameter(data.date),
  pdf: data.pdf,
  note: convertEmptyToNullForParameter(data.note),
});
