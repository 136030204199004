import { AuthorizationType } from '@front/user/domain';
import type { ControlPanelTextListProps } from '@front/src/types/domain';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import type { AuthorizationPersonalSettingsUserView } from '@front/src/features/personal-settings-modal/features/auth-settings/types/view';

export const settingsModalRowDummy = (): AuthorizationPersonalSettingsUserView => {
  const userId = Math.random();
  return {
    id: userId,
    employeeId: '',
    name: '',
    jobDuty: '',
    department: { id: Math.random(), name: '' },
    adminRoleManagement: {
      id: Math.random(),
      name: '',
      canAccessUserManagement: false,
      canAccessVariableManagement: false,
      canAccessMenuAuthorization: false,
      canAccessPageAuthorization: false,
      canAccessPageView: false,
    },
    menus: [
      {
        id: Math.random(),
        authorizationDepartment: {
          id: Math.random(),
          type: AuthorizationType.X,
        },
        authorizationUser: {
          id: Math.random(),
          type: AuthorizationType.X,
        },
      },
    ],
    isNew: true,
    originalId: userId,
  };
};

export const getPersonalSettingsModalControlPanelHeaderItemList = ({
  actions,
}: ControlPanelTextListProps) => {
  if (!actions) return [];
  return [
    { text: '최상단에 추가', icon: AddCircleOutlineIcon, action: actions.onAddTop },
    { text: '최하단에 추가', icon: AddCircleOutlineIcon, action: actions.onAddBottom },
    { text: '전체 수정 닫기', icon: HighlightOffIcon, action: actions.onCloseAll },
  ];
};

export const getPersonalSettingsModalControlPanelItemList = ({
  actions,
  isEditOpen,
}: ControlPanelTextListProps) => {
  if (!actions) return [];
  return [
    { text: '추가', icon: AddCircleOutlineIcon, action: actions.onAdd },
    { text: isEditOpen ? '수정 닫기' : '수정', icon: EditIcon, action: actions.onEdit },
    { text: '삭제', icon: DeleteIcon, action: actions.onRemove },
  ];
};

export const personalSettingsModalTab = ['권한설정', 'UI설정'];
