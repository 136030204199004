import userSelectorQuery from '@front/src/features/user-selector/query/query';

const userSelectorRepository = {
  useGetUser: (id) => {
    const { data } = userSelectorQuery.useGetUser(id);
    return { data };
  },
  useGetAllUser: () => {
    const { data } = userSelectorQuery.useGetAllUser();
    return {
      data,
    };
  },
  useGetDepartmentList: () => {
    const { data } = userSelectorQuery.useGetDepartmentList();
    return { data };
  },
  useGetUserList: (params) => {
    const { data } = userSelectorQuery.useGetUserList(params);
    return {
      data,
    };
  },
};

export default userSelectorRepository;
