import React, { memo } from 'react';
import Button from '@front/layouts/Button';
import { Box } from '@mui/material';

interface Props {
  onOpen: () => void;
}

const EvaluationController = ({ onOpen }: Props) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    }}
  >
    <Button
      shape="basic1"
      onClick={onOpen}
    >
      캠페인 담당자 직권 결재 상신 처리
    </Button>
  </Box>
);

const memoized = memo(EvaluationController);

export { memoized as OstEvaluationController };
