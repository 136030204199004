const headerMeta: Record<
  number,
  {
    width: number;
  }
> = {
  1: { // 업무-기본정보T-협업자S-부서
    width: 150,
  },
  2: { // 업무-기본정보T-협업자S-직책
    width: 100,
  },
  3: { // 업무-기본정보T-협업자S-성명
    width: 200,
  },
  4: { // 업무-기본정보T-협업자S-역할
    width: 150,
  },
  5: { // 업무-기본정보T-협업자S-참여율(%)
    width: 100,
  },
  6: { // 업무-기본정보T-협업자S-상세역할
    width: 550,
  },
  7: {
    width: 80,
  },
  8: {
    width: 140,
  },
  9: {
    width: 100,
  },
  10: {
    width: 120,
  },
  11: {
    width: 100,
  },
  12: {
    width: 140,
  },
  13: {
    width: 400,
  },
  14: { // 업무-기본정보T-회람자S-부서
    width: 150,
  },
  15: { // 업무-기본정보T-회람자S-직책
    width: 100,
  },
  16: { // 업무-기본정보T-회람자S-성명
    width: 200,
  },
  17: {
    width: 600,
  },
  18: {
    width: 126,
  },
  19: {
    width: 120,
  },
  20: {
    width: 980,
  },
  21: {
    width: 120,
  },
  22: {
    width: 120,
  },
  23: {
    width: 300,
  },
  24: {
    width: 400,
  },
  25: {
    width: 120,
  },
  26: {
    width: 120,
  },
  27: {
    width: 300,
  },
  28: {
    width: 400,
  },
  29: {
    width: 100,
  },
  30: {
    width: 100,
  },
  31: {
    width: 100,
  },
  32: {
    width: 100,
  },
  33: {
    width: 100,
  },
  34: {
    width: 100,
  },
  35: {
    width: 100,
  },
  36: {
    width: 100,
  },
  37: {
    width: 100,
  },
  38: {
    width: 100,
  },
  39: {
    width: 100,
  },
  40: {
    width: 100,
  },
  41: {
    width: 100,
  },
  42: {
    width: 100,
  },
  43: {
    width: 100,
  },
  44: {
    width: 100,
  },
  45: {
    width: 100,
  },
  46: {
    width: 100,
  },
  47: {
    width: 100,
  },
  48: {
    width: 100,
  },
  49: {
    width: 100,
  },
  50: {
    width: 100,
  },
  51: {
    width: 100,
  },
  52: {
    width: 100,
  },
  53: {
    width: 100,
  },
  54: {
    width: 100,
  },
  55: {
    width: 100,
  },
  56: {
    width: 100,
  },
  57: {
    width: 100,
  },
  58: {
    width: 100,
  },
  59: {
    width: 100,
  },
  60: {
    width: 100,
  },
  61: {
    width: 100,
  },
  62: {
    width: 100,
  },
  63: {
    width: 100,
  },
  64: {
    width: 100,
  },
  65: {
    width: 100,
  },
  66: {
    width: 100,
  },
  67: {
    width: 100,
  },
  68: {
    width: 260,
  },
  69: {
    width: 260,
  },
  70: {
    width: 260,
  },
  71: {
    width: 260,
  },
  72: {
    width: 260,
  },
  73: {
    width: 260,
  },
  74: {
    width: 260,
  },
  75: {
    width: 260,
  },
  76: {
    width: 260,
  },
  77: {
    width: 260,
  },
  78: {
    width: 260,
  },
  79: {
    width: 260,
  },
  80: {
    width: 260,
  },
  81: {
    width: 260,
  },
  82: {
    width: 260,
  },
  83: {
    width: 260,
  },
  84: {
    width: 260,
  },
  85: {
    width: 260,
  },
  86: {
    width: 260,
  },
  87: {
    width: 260,
  },
  88: {
    width: 260,
  },
  89: {
    width: 260,
  },
  90: {
    width: 260,
  },
  91: {
    width: 260,
  },
  92: {
    width: 260,
  },
  93: {
    width: 260,
  },
  94: {
    width: 260,
  },
  95: {
    width: 260,
  },
  96: {
    width: 260,
  },
  97: {
    width: 260,
  },
  98: {
    width: 260,
  },
  99: {
    width: 260,
  },
  100: {
    width: 260,
  },
  101: {
    width: 260,
  },
  102: {
    width: 260,
  },
  103: {
    width: 260,
  },
  104: {
    width: 260,
  },
  105: {
    width: 260,
  },
  106: {
    width: 260,
  },
  107: {
    width: 260,
  },
  108: {
    width: 260,
  },
  109: {
    width: 260,
  },
  110: {
    width: 260,
  },
  111: {
    width: 260,
  },
  112: {
    width: 260,
  },
  113: {
    width: 260,
  },
  114: {
    width: 260,
  },
  115: {
    width: 260,
  },
  116: {
    width: 260,
  },
  117: {
    width: 260,
  },
  118: {
    width: 260,
  },
  119: {
    width: 260,
  },
  120: {
    width: 260,
  },
  121: {
    width: 260,
  },
  122: {
    width: 260,
  },
  123: {
    width: 260,
  },
  124: {
    width: 260,
  },
  125: {
    width: 260,
  },
  126: {
    width: 260,
  },
  127: {
    width: 260,
  },
  128: {
    width: 260,
  },
  129: {
    width: 260,
  },
  130: {
    width: 260,
  },
  131: {
    width: 100,
  },
  132: {
    width: 100,
  },
  133: {
    width: 100,
  },
  134: {
    width: 100,
  },
  135: {
    width: 100,
  },
  136: {
    width: 100,
  },
  137: {
    width: 100,
  },
  138: {
    width: 100,
  },
  139: {
    width: 100,
  },
  140: {
    width: 100,
  },
  141: {
    width: 100,
  },
  142: {
    width: 100,
  },
  143: {
    width: 100,
  },
  144: {
    width: 100,
  },
  145: {
    width: 100,
  },
  146: {
    width: 100,
  },
  147: {
    width: 100,
  },
  148: {
    width: 100,
  },
  149: {
    width: 100,
  },
  150: {
    width: 100,
  },
  151: {
    width: 100,
  },
  152: {
    width: 100,
  },
  153: {
    width: 100,
  },
  154: {
    width: 100,
  },
  155: {
    width: 100,
  },
  156: {
    width: 100,
  },
  157: {
    width: 100,
  },
  158: {
    width: 100,
  },
  159: {
    width: 100,
  },
  160: {
    width: 100,
  },
  161: {
    width: 100,
  },
  162: {
    width: 100,
  },
  163: {
    width: 100,
  },
  164: {
    width: 100,
  },
  165: {
    width: 100,
  },
  166: {
    width: 100,
  },
  167: {
    width: 100,
  },
  168: {
    width: 100,
  },
  169: {
    width: 100,
  },
  170: {
    width: 100,
  },
  171: {
    width: 100,
  },
  172: {
    width: 100,
  },
  173: {
    width: 100,
  },
  174: {
    width: 100,
  },
  175: {
    width: 100,
  },
  176: {
    width: 100,
  },
  177: {
    width: 100,
  },
  178: {
    width: 100,
  },
  179: {
    width: 100,
  },
  180: {
    width: 100,
  },
  181: {
    width: 100,
  },
  182: {
    width: 100,
  },
  183: {
    width: 100,
  },
  184: {
    width: 100,
  },
  185: {
    width: 100,
  },
  186: {
    width: 100,
  },
  187: {
    width: 100,
  },
  188: {
    width: 100,
  },
  189: {
    width: 100,
  },
  190: { // 업무-기본정보T-업무구분S-영역
    width: 150,
  },
  191: { // 업무-기본정보T-업무구분S-부서
    width: 150,
  },
  192: { // 업무-기본정보T-업무구분S-협업
    width: 150,
  },
  193: { // 업무-기본정보T-업무구분S-영향
    width: 150,
  },
  194: { // 업무-기본정보T-업무구분S-보안
    width: 150,
  },
  195: {
    width: 500,
  },
  196: {
    width: 260,
  },
  197: {
    width: 260,
  },
  198: {
    width: 260,
  },
  200: {
    width: 260,
  },
  201: {
    width: 260,
  },
  202: {
    width: 260,
  },
  203: {
    width: 260,
  },
  204: {
    width: 260,
  },
  205: { // 업무-기본정보T-지시자S-부서
    width: 150,
  },
  206: { // 업무-기본정보T-지시자S-직책
    width: 100,
  },
  207: { // 업무-기본정보T-지시자S-성명
    width: 200,
  },
  208: { // 업무-기본정보T-지시자S-지시 세부사항/주안점
    width: 800,
  },
  209: {
    width: 260,
  },
  210: {
    width: 260,
  },
  211: {
    width: 260,
  },
  212: {
    width: 260,
  },
  213: {
    width: 260,
  },
  214: {
    width: 260,
  },
  215: {
    width: 260,
  },
  216: {
    width: 260,
  },
  217: {
    width: 260,
  },
  218: {
    width: 260,
  },
  219: {
    width: 260,
  },
  220: {
    width: 260,
  },
  221: {
    width: 260,
  },
  222: {
    width: 260,
  },
  223: {
    width: 260,
  },
  224: {
    width: 260,
  },
  225: {
    width: 260,
  },
  226: {
    width: 260,
  },
  227: {
    width: 260,
  },
  228: {
    width: 260,
  },
  229: {
    width: 260,
  },
  230: {
    width: 260,
  },
  231: {
    width: 260,
  },
  232: {
    width: 260,
  },
  233: {
    width: 260,
  },
  234: {
    width: 260,
  },
  235: {
    width: 260,
  },
  236: {
    width: 260,
  },
  237: {
    width: 260,
  },
  238: {
    width: 260,
  },
  239: {
    width: 260,
  },
  240: {
    width: 260,
  },
  241: {
    width: 260,
  },
  242: {
    width: 260,
  },
  243: {
    width: 260,
  },
  244: {
    width: 260,
  },
  245: {
    width: 260,
  },
  246: {
    width: 260,
  },
  247: {
    width: 260,
  },
  248: {
    width: 260,
  },
  249: {
    width: 260,
  },
  250: {
    width: 260,
  },
  251: {
    width: 260,
  },
  252: {
    width: 260,
  },
  253: {
    width: 260,
  },
  254: {
    width: 260,
  },
  255: {
    width: 260,
  },
  256: {
    width: 260,
  },
  257: {
    width: 260,
  },
  258: {
    width: 260,
  },
  259: {
    width: 260,
  },
  260: {
    width: 260,
  },
  261: {
    width: 260,
  },
  262: {
    width: 260,
  },
  263: {
    width: 260,
  },
  264: {
    width: 260,
  },
  265: {
    width: 260,
  },
  266: {
    width: 260,
  },
  267: {
    width: 260,
  },
  268: {
    width: 260,
  },
  269: {
    width: 260,
  },
  270: {
    width: 260,
  },
  271: {
    width: 260,
  },
  272: {
    width: 260,
  },
  273: {
    width: 260,
  },
  274: {
    width: 260,
  },
  275: {
    width: 260,
  },
  276: {
    width: 260,
  },
  277: {
    width: 260,
  },
  278: {
    width: 260,
  },
  279: {
    width: 260,
  },
  280: {
    width: 260,
  },
  281: {
    width: 260,
  },
  282: {
    width: 260,
  },
  283: {
    width: 260,
  },
  284: {
    width: 260,
  },
  285: {
    width: 260,
  },
  286: { // 업무-기본정보T-담당자S-부서
    width: 150,
  },
  287: { // 업무-기본정보T-담당자S-직책
    width: 100,
  },
  288: { // 업무-기본정보T-담당자S-성명
    width: 200,
  },
  289: { // 업무-기본정보T-담당자S-지시 세부사항/주안점
    width: 800,
  },
  290: {
    width: 260,
  },
  291: {
    width: 260,
  },
  292: {
    width: 260,
  },
  293: {
    width: 260,
  },
  294: {
    width: 260,
  },
  295: {
    width: 260,
  },
  296: {
    width: 260,
  },
  297: {
    width: 260,
  },
  298: {
    width: 260,
  },
  299: {
    width: 260,
  },
  300: {
    width: 260,
  },
  301: {
    width: 260,
  },
  302: {
    width: 260,
  },
  303: {
    width: 260,
  },
  304: {
    width: 260,
  },
  305: {
    width: 260,
  },
  306: {
    width: 260,
  },
  307: {
    width: 260,
  },
  308: {
    width: 260,
  },
  309: {
    width: 260,
  },
  310: {
    width: 260,
  },
};

export default headerMeta;
