import DepartmentPage from 'department/view/Page';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from 'services/reducer';
import React, { useCallback, useEffect } from 'react';
import type { DepartmentQuery } from 'department/query';
import { initialDepartmentQuery } from 'department/query';
import { departmentAction } from 'department/action';
import { useFormik } from 'formik';
import { useGetReadOnly } from '@front/src/hooks/useGetReadOnly';
import { useDepartmentState } from '@front/department/useState';
import { useShallow } from 'zustand/react/shallow';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';

function Element(props: Readonly<SectionComponentProps>) {
  const dispatch = useDispatch();
  const { page } = useSelector((root: RootState) => root.department);
  const setFilter = useCallback(
    (formikProps: DepartmentQuery) =>
      dispatch(departmentAction.setFilter({ ...formikProps, menuId: props.menuId })),
    [dispatch]
  );
  const formik = useFormik<DepartmentQuery>({
    initialValues: initialDepartmentQuery,
    onSubmit: (values) => {
      setFilter(values);
    },
  });
  const { readOnly } = useGetReadOnly();
  const { setReadOnly } = useDepartmentState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  useEffect(() => {
    setFilter(initialDepartmentQuery);
  }, []);
  useEffect(() => {
    formik.setSubmitting(false);
  }, [page]);
  return (
    <DepartmentPage
      formik={formik}
      page={page}
      {...props}
    />
  );
}

const DepartmentManagementRoute = (props: Readonly<SectionComponentProps>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  return (
    <Layout>
      <Header title={props.name} />
      <Body>
        <Element {...props} />
      </Body>
    </Layout>
  );
};

export default DepartmentManagementRoute;
