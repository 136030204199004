import React, { useMemo } from 'react';
import { useProjectSalesInfoContractEarningsItemUpdateModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/widgets/useState';
import ModalLayout from '@front/layouts/ModalLayout';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoCollectionManagementModalTable } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/components/manage-collection-table';
import { ProjectSalesInfoCollectionManagementCreateModalBtn } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/components/manage-collection-btn';
import { ProjectSalesInfoCollectionManagementModalCreateModalWrapper } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/features/create-modal/components/modal-wrapper';
import { ProjectSalesInfoContractEarningsCollectionManagementContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/widgets/context';
import { useProjectSalesInfoContractEarningsCollectionManagementLogic } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/widgets/useLogic';
import { ProjectSalesInfoCollectionManagementModalUpdateModalWrapper } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/features/update-modal/components/modal-wrapper';
import { shallow } from 'zustand/shallow';

export { Widget as ProjectSalesInfoContractEarningsItemManageCollectionModalWidget };

const Widget = () => {
  const { Body, Layout, Header } = AddDeleteTable;
  const { isManagementModalOpen, setIsManagementModalOpen } =
    useProjectSalesInfoContractEarningsItemUpdateModalState(
      (state) => ({
        isManagementModalOpen: state.isManagementModalOpen,
        setIsManagementModalOpen: state.setIsManagementModalOpen,
      }),
      shallow
    );
  const {
    d: { divisionDetail },
  } = useProjectSalesInfoContractEarningsCollectionManagementLogic();
  const value = useMemo(
    () => ({
      d: {
        divisionDetail,
      },
    }),
    [divisionDetail]
  );

  return (
    <ModalLayout
      position="top"
      width="66dvw"
      height="224px"
      open={isManagementModalOpen}
      onClose={() => setIsManagementModalOpen()}
      title="수금관리"
    >
      <ProjectSalesInfoContractEarningsCollectionManagementContext.Provider value={value}>
        <ProjectSalesInfoCollectionManagementModalCreateModalWrapper />
        <ProjectSalesInfoCollectionManagementModalUpdateModalWrapper />
        <Layout width="100%">
          <Header>
            <ProjectSalesInfoCollectionManagementCreateModalBtn />
          </Header>
          <Body>
            <ProjectSalesInfoCollectionManagementModalTable />
          </Body>
        </Layout>
      </ProjectSalesInfoContractEarningsCollectionManagementContext.Provider>
    </ModalLayout>
  );
};
