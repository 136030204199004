import { default as axios } from '@front/src/config/axios';
import type { ProjectExperimentInformationParameter } from '@front/src/features/project-sales-info/features/subject-review/features/modal/types/parameter';
import { projectSalesInfoAspectRatioExaminationUrl } from '@front/src/features/project-sales-info/features/subject-review/features/modal/utils/constant';
import { toFormData } from '@front/services/api';

const url = {
  update: (id?) => `${projectSalesInfoAspectRatioExaminationUrl}/experiment-information/${id}`,
  getDetail: (id?) => `${projectSalesInfoAspectRatioExaminationUrl}/${id}/experiment-information`,
  delete: (id?) => `${projectSalesInfoAspectRatioExaminationUrl}/experiment-information/${id}`,
};

export const projectSalesInfoSubjectReviewModalApi = {
  getDetail: async (id?: number) => {
    const { data } = await axios.get(url.getDetail(id));
    return data;
  },
  update: async (params: ProjectExperimentInformationParameter) => {
    await axios.put(
      url.update(params.id),
      toFormData({
        ...params,
        file: params.file,
      })
    );
  },
  delete: async (id: number) => {
    await axios.delete(url.delete(id));
  },
};
