import { useQuery } from 'react-query';
import { affiliatedPersonMemoApi } from '@front/src/features/affiliated-person/api/api';
import type { MemoView } from '@front/src/types';
import type { AffiliatedPersonMemoListGetParameter } from '@front/src/features/affiliated-person/features/memo/types/parameter';

export { query as affiliatedPersonMemoQuery };
const query = {
  useListGet: (params: AffiliatedPersonMemoListGetParameter, menuId) => {
    const { data, remove } = useQuery<MemoView[]>({
      queryFn: () => affiliatedPersonMemoApi.getList(params, menuId),
      queryKey: ['affiliated-person', 'memo', 'list', params],
      suspense: true,
      enabled: !!params.personId,
    });
    return { data, remove };
  },
};
