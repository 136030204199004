import React from 'react';
import type { SnackbarSeverityType } from '@front/components/Snackbar/action';
import type { TitleUpdateParameter } from '@front/ost_proposal/parameter';
import ProposalTitleInput from '@front/ost_proposal/view/detail/title/ProposalTitleInput';
import { ArticleItem } from '@front/src/components/article-item';
import { Box } from '@mui/material';

interface Props {
  openSnackbar: (message: string, severity?: SnackbarSeverityType) => void;
  updateTitle: (params: TitleUpdateParameter) => void;
  isContributor: boolean;
}

function ProposalTitle({ openSnackbar, updateTitle, isContributor }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        padding: '10px',
        maxHeight: '200px',
      }}
    >
      <ArticleItem
        title="제안명"
        children={
          <ProposalTitleInput
            openSnackbar={openSnackbar}
            updateTitle={updateTitle}
            isContributor={isContributor}
          />
        }
      />
    </Box>
  );
}

export default ProposalTitle;
