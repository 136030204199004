import { mobileNotificationRepository } from '@front/src/features/mobile-notification/repository';
import { userNotificationAction } from '@front/user_notification/action';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ostAction } from '@front/ost/action';
import { proposalAction } from '@front/ost_proposal/action';
import type { RootState } from '@front/services/reducer';
import { campaignAction } from '@front/ost_campaign/action';
import { voteAction } from '@front/ost_vote/action';
import { evaluationAction } from '@front/ost_evaluation/action';
import { arrangementAction } from '@front/ost_arrangement/action';
import { useUserMobileNotificationState } from '@front/user_notification/useState';
import { useParams } from 'react-router';
import { useMobileNotificationState } from '@front/src/features/mobile-notification/useState';

export { useLogic as MobileNotificationListItemUseLogic };
const useLogic = () => {
  const dispatch = useDispatch();
  const { '*': pathVariables } = useParams<{ '*': string }>();
  const { filter: proposalFilter } = useSelector(
    (root: RootState) => ({
      filter: root.proposal.filter,
    }),
    shallowEqual
  );
  const { filter: ostFilter } = useSelector(
    (root: RootState) => ({
      filter: root.ost.filter,
    }),
    shallowEqual
  );
  const { filter: campaignFilter } = useSelector(
    (root: RootState) => ({
      filter: root.campaign.filter,
    }),
    shallowEqual
  );
  const { filter: voteFilter } = useSelector(
    (root: RootState) => ({
      filter: root.vote.filter,
    }),
    shallowEqual
  );
  const { filter: evaluationFilter } = useSelector(
    (root: RootState) => ({
      filter: root.evaluation.filter,
    }),
    shallowEqual
  );
  const { filter: arrangementFilter } = useSelector(
    (root: RootState) => ({
      filter: root.arrangement.filter,
    }),
    shallowEqual
  );
  const { login } = useSelector(
    (root: RootState) => ({
      login: root.login.detail,
    }),
    shallowEqual
  );
  const prevMenuId = useMobileNotificationState((state) => state.prevMenuId);
  const setFilters = () => {
    dispatch(userNotificationAction.requestCount());
    if (pathVariables === undefined) {
      return;
    }
    const currentMenuId = sessionStorage.getItem('menuId');
    if (pathVariables.includes('proposal') && prevMenuId === currentMenuId)
      dispatch(proposalAction.setFilter(proposalFilter));
    if (pathVariables.includes('campaign') && prevMenuId === currentMenuId)
      dispatch(campaignAction.setFilter(campaignFilter));
    if (pathVariables.includes('vote') && prevMenuId === currentMenuId)
      dispatch(voteAction.setFilter(voteFilter));
    if (pathVariables.includes('evaluation') && prevMenuId === currentMenuId)
      dispatch(evaluationAction.setFilter(evaluationFilter));
    if (pathVariables.includes('arrangement') && prevMenuId === currentMenuId)
      dispatch(arrangementAction.setFilter(arrangementFilter));
    if (!(login && login.role.id === 1)) return;
    if (pathVariables.includes('ost-management') && prevMenuId === currentMenuId)
      dispatch(
        ostAction.setFilter(
          ostFilter || {
            keyword: '',
            status: '',
          }
        )
      );
  };
  const { run: onToggle, setCallback: setToggleCallback } =
    mobileNotificationRepository.useUserNotificationToggle();
  setToggleCallback({
    onSuccess: () => {
      setFilters();
    },
  });
  const setIsMobileNotificationModalOpen = useUserMobileNotificationState(
    (state) => state.setIsMobileNotificationModalOpen
  );
  const { run: onNavigateToggle, setCallback: setNavigateToggleCallback } =
    mobileNotificationRepository.useUserNotificationNavigateToggle();
  setNavigateToggleCallback({
    onSuccess: () => {
      setIsMobileNotificationModalOpen();
      setFilters();
    },
  });
  const { run: onDelete } = mobileNotificationRepository.useUserNotificationDelete();
  const { run: onDeleteAll } = mobileNotificationRepository.useUserNotificationDeleteAll();
  const { run: onPinUpdate } = mobileNotificationRepository.useUserNotificationPinUpdate();

  return {
    onToggle,
    onDelete,
    onDeleteAll,
    onPinUpdate,
    onNavigateToggle,
  };
};
