import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { projectSalesInfoAffiliatedCompanyWebHardUpdateModalMutation } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/query/mutation';
import { projectSalesInfoAffiliatedCompanyWebHardUpdateModalQuery } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/query/query';

export const projectSalesInfoAffiliatedCompanyWebHardUpdateModalRepository = {
  useDetailGet: (id?: number) => {
    const { data, isLoading } =
      projectSalesInfoAffiliatedCompanyWebHardUpdateModalQuery.useDetailGet(id);
    return {
      data,
      isLoading,
    };
  },
  useUpdate: () => {
    const { mutate } = projectSalesInfoAffiliatedCompanyWebHardUpdateModalMutation.useUpdate();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
