import { create } from 'zustand';

export { useState as useAlertModalState };

interface State {
  open: boolean;
  descriptionList: string[];
  title: string;
}

interface Actions {
  setOpen: (open: boolean, callback?: () => void) => void;
  reset: () => void;
  setDescriptionList: (descriptionList: string[]) => void;
  setTitle: (title: string) => void;
}

const useState = create<State & Actions>((set) => ({
  isDirty: false,
  open: false,
  setOpen: (open, callback) => set(() => ({ open, callback })),
  reset: () =>
    set(() => ({
      open: false,
      descriptionList: ['헤더명 중복 여부를 확인해주세요.'],
    })),
  descriptionList: ['헤더명 중복 여부를 확인해주세요.'],
  setDescriptionList: (descriptionList: string[]) =>
    set(() => ({
      descriptionList,
    })),
  title: '알림',
  setTitle: (title) => set(() => ({ title })),
}));
