import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAccountingSettingsBankAccountListLogic } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/list/provider/useLogic';
import { AccountingSettingsBankAccountListContext } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/list/provider/context';

export { Provider as AccountingSettingsBankAccountListProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { list, onUpdate } = useAccountingSettingsBankAccountListLogic();
  const value = useMemo(() => ({ list, onUpdate }), [list, onUpdate]);
  return (
    <AccountingSettingsBankAccountListContext.Provider value={value}>
      {children}
    </AccountingSettingsBankAccountListContext.Provider>
  );
};
