import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { DefaultFunction } from '@front/src/types';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';
import type { ProjectSalesInfoContractContractParameter } from '@front/src/features/project-sales-info/features/contract/features/contract/types/parameter';
import type { ProjectContractConfirmedView } from '@front/src/features/project-sales-info/features/contract/features/confirmed/types/view';
import type { ProjectEstimationView } from '@front/src/features/project-sales-info/features/estimation/features/estimation/types/view';

export { context as ProjectSalesInfoContractContractCreateModalContext };

interface State {
  d: {
    projectFullName?: string;
    categoryList?: VariableInformationView[];
    contractConfirmed?: ProjectContractConfirmedView;
    estimationList?: ProjectEstimationView[];
  };
  h: {
    onCreate: (params: ProjectSalesInfoContractContractParameter) => void;
    onClose: DefaultFunction;
  };
}

const context = createContext<State>({
  d: {},
  h: {
    onCreate: noOp,
    onClose: noOp,
  },
});
