import React, { useContext } from 'react';
import { Box } from '@mui/material';
import type { PersonModalShortView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/types/view';
import { MuiTableVirtualizer } from '@front/src/components/virtualizer-with-mui';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/widgets/context';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalResultTableBodyRowContent } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/result-table-body-row-content';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalResultTableBodyRow } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/result-table-body-row';

export { ResultTable as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalResultTable };

interface ColumnData {
  dataKey: keyof PersonModalShortView | 'checkbox';
  label: string;
  width: number | string;
}

const ResultTable = () => {
  const {
    d: { list },
    isLoading,
  } = useContext(ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalContext);
  const isListLoading = typeof list === 'undefined' || isLoading;
  const isListEmpty = !isListLoading && list?.length === 0;

  const columns: ColumnData[] = [
    {
      dataKey: 'checkbox',
      label: '선택',
      width: '2%',
    },
    {
      dataKey: 'affiliatedCompanyCategory',
      label: '업체구분',
      width: '2%',
    },
    {
      dataKey: 'affiliatedCompanyName',
      label: '업체명',
      width: '8%',
    },
    {
      dataKey: 'name',
      label: '이름',
      width: '2%',
    },
    {
      dataKey: 'position',
      label: '직위',
      width: '2%',
    },
    {
      dataKey: 'phoneNumber',
      label: '핸드폰',
      width: '4%',
    },
    {
      dataKey: 'email',
      label: '이메일',
      width: '6%',
    },
  ];
  return (
    <Box
      sx={{
        width: '100%',
        height: '580px',
      }}
    >
      <MuiTableVirtualizer
        columns={columns}
        list={list}
        RowComponent={
          ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalResultTableBodyRowContent
        }
        colSpan={7}
        isTableFit={true}
        isListLoading={isListLoading}
        isListEmpty={isListEmpty}
        NewRow={
          ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalResultTableBodyRow
        }
      />
    </Box>
  );
};
