import { useAccountingUploadSalesInfoState } from '@front/src/features/accounting/features/upload/features/sales-info/useState';
import { SalesAccountingAmountSubType } from '@front/src/features/accounting/features/upload/features/sales-info/types/domain';
import { useShallow } from 'zustand/react/shallow';
import { AccountingUploadSalesInfoGoalModalRepository } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/repository/repository';
import { useEffect } from 'react';

export { useLogic as useAccountingUploadSalesInfoGoalModalListLogic };

const useLogic = () => {
  const { year } = useAccountingUploadSalesInfoState(
    useShallow((state) => ({
      year: state.year,
    }))
  );
  const { data: list, remove } = AccountingUploadSalesInfoGoalModalRepository.useGetList({
    year,
    subType: SalesAccountingAmountSubType.TARGET,
  });
  useEffect(
    () => () => {
      remove();
    },
    [remove]
  );
  return {
    list,
  };
};
