import React, { useCallback } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import Checkbox from '@front/layouts/Checkbox';
import {
  convertDateToStringFormat,
  DATE_TIME_FORMAT,
  downloadFile,
  getChecked,
  getValueIfExist,
  handleCheckboxChange,
} from '@front/src/utils';
import classes from '@front/src/features/project-sales-info/features/contract/features/history/components/contract-history-table-row.module.scss';
import { shallow } from 'zustand/shallow';
import type { ProjectContractHistoryView } from '@front/src/features/project-sales-info/features/contract/features/history/types/view';
import { useProjectSalesInfoContractHistoryState } from '@front/src/features/project-sales-info/features/contract/features/history/widgets/useState';
import { useProjectSalesInfoContractHistoryModalState } from '@front/src/features/project-sales-info/features/contract/features/history/features/modal/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { ContractHistoryTableRow as ProjectSalesInfoContractHistoryTableRow };

interface Props {
  item: ProjectContractHistoryView;
  index: number;
}

const ContractHistoryTableRow = ({ item, index }: Props) => {
  const { idList, setIdList } = useProjectSalesInfoContractHistoryState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { setIsOpen, setId } = useProjectSalesInfoContractHistoryModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      setId: state.setId,
    }),
    shallow
  );
  const { readOnly } = useProjectSalesInfoContractHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const handleIndexClick = useCallback(
    (id) => {
      setId(id);
      setIsOpen(true);
    },
    [setIsOpen, setId]
  );
  return (
    <TableRow>
      <OldTd>
        <Checkbox
          checked={getChecked(item.id, idList)}
          onChange={() => handleCheckboxChange(item.id, idList, setIdList)}
          disabled={readOnly}
        />
      </OldTd>
      <OldTd>
        <div
          className={classes.index}
          onClick={() => handleIndexClick(item.id)}
          aria-hidden
        >
          {index + 1}
        </div>
      </OldTd>
      <OldTd>{getValueIfExist(item.category)}</OldTd>
      <OldTd>{getValueIfExist(item.type)}</OldTd>
      <OldTd>
        {item.hwp?.id ? (
          <div
            className={classes.file}
            onClick={downloadFile(item.hwp?.id)}
            aria-hidden
          >
            {getValueIfExist(item.hwp?.filename)}
          </div>
        ) : (
          '-'
        )}
      </OldTd>
      <OldTd>
        {item.pdf?.id ? (
          <div
            className={classes.file}
            onClick={downloadFile(item.pdf?.id)}
            aria-hidden
          >
            {item.pdf?.filename}
          </div>
        ) : (
          '-'
        )}
      </OldTd>
      <OldTd>{convertDateToStringFormat(item.createdAt, DATE_TIME_FORMAT)}</OldTd>
      <OldTd>{getValueIfExist(item.writer?.name)}</OldTd>
    </TableRow>
  );
};
