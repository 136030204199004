import React, { useCallback, useContext } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { TableCell, TableRow } from '@mui/material';
import Checkbox from '@front/layouts/Checkbox';
import { OldTd } from '@front/layouts/Table';
import { spliceAndPushIfExist } from '@front/src/features/affiliated-company/utils';
import UserSelector from '@front/components/UserSelector';
import type { UserId } from '@front/user/domain';
import type { GiftTargetType } from '@front/src/features/affiliated-company/types/domain';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import { AffiliatedCompanyPersonDetailModalGiftHistoryContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/widgets/context';
import {
  giftTargetTypeList,
} from '@front/src/features/affiliated-company/utils/constant';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import type { AffiliatedCompanyPersonGiftHistoryView } from '@front/src/features/affiliated-company/features/gift-history/types/view';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedCompanyPersonDetailModalGiftHistoryState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/widgets/useState';
import { useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";
import {formatDateOrNull} from "@front/src/features/project-sales-info/utils";

interface Props {
  item: AffiliatedCompanyPersonGiftHistoryView;
  index: number;
}

interface AffiliatedCompanyPersonDetailModalGiftHistoryForm {
  id: number;
  type: GiftTargetType | '';
  date: string;
  gift: string;
  purpose: string;
  amount: string;
  deliveryMethod: string;
  senderId: number;
  note: string;
}

const getValues = (item: AffiliatedCompanyPersonGiftHistoryView) => ({
  id: item.id,
  type: item.type ?? '',
  date: item.date ?? '',
  gift: item.gift ?? '',
  purpose: item.purpose ?? '',
  amount: item.amount ? item.amount.toLocaleString() : '',
  deliveryMethod: item.deliveryMethod ?? '',
  senderId: item.sender?.id,
  note: item.note ?? '',
});

export { AddressTableRow as AffiliatedCompanyPersonDetailModalGiftHistoryTableRow };
const AddressTableRow = ({ item, index }: Props) => {
  const {
    h: { setIdList, onGiftHistoryUpdate },
    idList,
  } = useContext(AffiliatedCompanyPersonDetailModalGiftHistoryContext);
  const methods = useForm<AffiliatedCompanyPersonDetailModalGiftHistoryForm>({
    values: getValues(item),
  });
  const handleCheckboxChange = useCallback(
    (id) => () => {
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  const onSubmit = methods.handleSubmit((data) => {
    const amount = Number(data.amount.split(',').join(''));
    onGiftHistoryUpdate({ ...data, amount, date: formatDateOrNull(data.date) });
  });
  const {
    field: { value, onChange },
  } = useController({ name: 'senderId', control: methods.control });
  const handleUserSelectorChange = useCallback(
    async (e) => {
      onChange(e);
      await onSubmit();
    },
    [onChange, onSubmit]
  );
  const { readOnly } = useAffiliatedCompanyPersonDetailModalGiftHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const menuId = useGlobalNavBarState((state) => state.menuId);
  const loginUser = useSelector((root: RootState) => root.login.detail);
  const currentAuth = loginUser?.menus?.find((item) => item.id === (menuId ? +menuId : 0))
    ?.authorization.type;
  return (
    <FormProvider {...methods}>
      <TableRow key={Math.random()}>
        <OldTd>
          <Checkbox
            checked={idList.includes(item.id)}
            onChange={handleCheckboxChange(item.id)}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>{index + 1}</OldTd>
        <TableCell>
          <HookFormSelect
            name="type"
            defaultLabel="선택"
            optionList={giftTargetTypeList}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </TableCell>
        <OldTd>{item.name}</OldTd>
        <OldTd>
          <DatePickerWithHookForm
            name="date"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <HookFormInput
            name="gift"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <HookFormInput
            name="purpose"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <HookFormInput
            name="amount"
            onSubmit={onSubmit}
            inputType={InputType.AMOUNT}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <HookFormInput
            name="deliveryMethod"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <UserSelector
            value={currentAuth === 'V' ? undefined : (value as UserId)}
            onChange={handleUserSelectorChange}
            readOnly={readOnly}
          />
        </OldTd>
        <OldTd>
          <HookFormInput
            name="note"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
      </TableRow>
    </FormProvider>
  );
};
