import React, { useCallback, useContext } from 'react';
import { TableRow } from '@mui/material';
import { useProjectSalesInfoActivityState } from '@front/src/features/project-sales-info/features/activity/useState';
import { useShallow } from 'zustand/react/shallow';
import { ProjectSalesInfoActivityItemWidget } from '@front/src/features/project-sales-info/features/activity/widgets/activity-item/widget';
import { ProjectSalesInfoActivityUserItemWidget } from '@front/src/features/project-sales-info/features/activity/widgets/activity-user-item/widget';
import { ProjectSalesInfoActivityAffiliatesItemWidget } from '@front/src/features/project-sales-info/features/activity/widgets/activity-affiliates-item/widget';
import { SalesInfoActivityTableRowNote } from '@front/src/features/project-sales-info/features/activity/widgets/row/components/note';
import { SalesInfoActivityRowContext } from '@front/src/features/project-sales-info/features/activity/widgets/row/provider/context';

export { Row as SalesInfoActivityTableRow };

const Row = () => {
  const { item } = useContext(SalesInfoActivityRowContext);
  const { setId, id } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      setId: state.setId,
      id: state.id,
    }))
  );
  const { readOnly } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const handleRowClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (readOnly) return;
      const activityId = item.activity?.id;
      if (!activityId) return;
      if (id === activityId) return;
      setId(activityId);
    },
    [setId, item, id, readOnly]
  );
  return (
    <>
      <TableRow
        onClick={handleRowClick}
        sx={{
          height: '54px',
          cursor: readOnly ? 'default' : 'pointer',
        }}
      >
        {item.isFirstActivity && <ProjectSalesInfoActivityItemWidget />}
        {item.isFirstActivityUser && <ProjectSalesInfoActivityUserItemWidget />}
        <ProjectSalesInfoActivityAffiliatesItemWidget />
      </TableRow>
      {item.isLastActivity && (
        <TableRow
          onClick={handleRowClick}
          sx={{
            height: '90px',
            cursor: readOnly ? 'default' : 'pointer',
          }}
        >
          <SalesInfoActivityTableRowNote />
        </TableRow>
      )}
    </>
  );
};
