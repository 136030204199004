import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAccountingUploadSalesInfoPerformanceModalListLogic } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/widgets/list/provider/useLogic';
import { AccountingUploadSalesInfoPerformanceModalListContext } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/widgets/list/provider/context';

export { Provider as AccountingUploadSalesInfoPerformanceModalListProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { list, isConfirmed } = useAccountingUploadSalesInfoPerformanceModalListLogic();
  const value = useMemo(
    () => ({
      list,
      isConfirmed,
    }),
    [list, isConfirmed]
  );
  return (
    <AccountingUploadSalesInfoPerformanceModalListContext.Provider value={value}>
      {children}
    </AccountingUploadSalesInfoPerformanceModalListContext.Provider>
  );
};
