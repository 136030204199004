import { personBaseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { convertEmptyToNull, getMenuIdHeaders } from '@front/src/utils';
import type { AffiliatedCompanyPersonCharacterFamilyInformationUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/types/parameter';

const url = {
  getPersonCharacterFamilyInformationList: (id?) => `${personBaseUrl}/${id}/family-information`,
  createPersonCharacterFamilyInformation: (id?) => `${personBaseUrl}/${id}/family-information`,
  deletePersonCharacterFamilyInformation: () => `${personBaseUrl}/family-information`,
  updatePersonCharacterFamilyInformation: (id?) => `${personBaseUrl}/family-information/${id}`,
};

export const affiliatedCompanyFamilyInformationApi = {
  getPersonCharacterFamilyInformationList: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getPersonCharacterFamilyInformationList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  createPersonCharacterFamilyInformation: async (id?: number, menuId?) => {
    await axios.post(url.createPersonCharacterFamilyInformation(id), null, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  deletePersonCharacterFamilyInformation: async (
    params: AffiliatedCompanyIdListDeleteParameter,
    menuId
  ) => {
    await axios.delete(url.deletePersonCharacterFamilyInformation(), {
      data: params,
      headers: getMenuIdHeaders(menuId),
    });
  },
  updatePersonCharacterFamilyInformation: async (
    params: AffiliatedCompanyPersonCharacterFamilyInformationUpdateParameter,
    menuId
  ) => {
    await axios.put(
      url.updatePersonCharacterFamilyInformation(params.id),
      convertEmptyToNull(params),
      {
        headers: getMenuIdHeaders(menuId),
      }
    );
  },
};
