import React, { memo, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import { Box } from '@mui/material';

import type { CampaignMemoUpdateParameter } from '@front/ost_campaign/parameter';
import { campaignAction } from '@front/ost_campaign/action';
import type { RootState } from '@front/services/reducer';
import TextBox from '@front/layouts/Text';
import CircularProgress from '@front/components/CircularProgress';
import ItemMemoService from '@front/ost_campaign/service/memo/item';
import { fixedMemoFormAndFilterHeight } from '@front/util/ConstantCommonVariable';
import { ColorPalette } from '@front/assets/theme';

const MemoListService = () => {
  const dispatch = useDispatch();
  const [memoList] = useSelector((root: RootState) => [root.campaign.memoList], shallowEqual);
  const { formHeight, isLoadingMemo } = useSelector(
    (root: RootState) => root.campaign,
    shallowEqual
  );

  const onSubmit = useCallback(
    (values: CampaignMemoUpdateParameter) => {
      dispatch(campaignAction.updateMemo(values));
      formik.resetForm();
    },
    [dispatch]
  );

  const formik = useFormik({
    initialValues: {} as CampaignMemoUpdateParameter,
    onSubmit: onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: `calc(100dvh - ${fixedMemoFormAndFilterHeight + formHeight}px)`,
          flexWrap: 'nowrap',
          flexDirection: 'column',
          overflowY: 'hidden',
          alignItems: 'flex-start',
          padding: '0 0 15px 0',
        }}
      >
        <Box
          className="scroll-bar-holder"
          sx={{
            width: '100%',
            height: `100%`,
            overflowY: 'auto',
            padding: '0 10px 0px 10px',
          }}
        >
          {memoList.length <= 0 && (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                border: `1px solid ${ColorPalette._e4e9f2}`,
                borderRadius: '5px',
                marginBottom: '10px',
                backgroundColor: ColorPalette._ffffff,
                padding: '15px',
                justifyContent: 'center',
              }}
            >
              <TextBox
                variant="body2"
                sx={{ minHeight: '30px', lineHeight: '30px' }}
              >
                {isLoadingMemo && <CircularProgress size={30} />}
                {!isLoadingMemo && <>해당하는 메모가 없습니다.</>}
              </TextBox>
            </Box>
          )}

          {memoList.length > 0 &&
            memoList.map((memo) => (
              <ItemMemoService
                key={memo.id}
                formik={formik}
                memo={memo}
              />
            ))}
        </Box>
      </Box>
    </FormikProvider>
  );
};

const ListMemoService = memo(MemoListService);

export default ListMemoService;
