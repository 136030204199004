import { createContext } from 'react';
import type { ProjectDesignOutlineView } from '@front/src/features/project-sales-info/features/design-outline/types/view';
import { noOp } from '@front/common/contants';
import type { ProjectDesignOutlineParameter } from '@front/src/features/project-sales-info/features/design-outline/types/parameter';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';
import type { AddressView } from '@front/src/types';

interface State {
  d: {
    projectDesignOutlineData?: ProjectDesignOutlineView;
    cityOptionList1?: AddressView[];
    cityOptionList2?: AddressView[];
    structurePurposeOptionList?: VariableInformationView[];
  };
  h: {
    onUpdate: (params: ProjectDesignOutlineParameter) => void;
    setRegCode: (code: string) => void;
  };
  isLoading: boolean;
}

export { Context as ProjectSalesInfoDesignOutlineContext };

const Context = createContext<State>({
  d: {},
  h: {
    onUpdate: noOp,
    setRegCode: noOp,
  },
  isLoading: false,
});
