import { create } from 'zustand';
import { HAS_VAT_OPTION } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/types/domain';

interface State {
  itemId?: number;
  setItemId: (id: number) => void;
  detailId?: number;
  setDetailId: (id: number) => void;
  isDetailCreateModalOpen: boolean;
  setIsDetailCreateModalOpen: () => void;
  isDetailUpdateModalOpen: boolean;
  setIsDetailUpdateModalOpen: () => void;
  idList: number[];
  setIdList: (idList: number[]) => void;
  name: string;
  setName: (name: string) => void;
  hasVat: HAS_VAT_OPTION;
  setHasVat: (hasVat: HAS_VAT_OPTION) => void;
  readOnly?: boolean;
  setReadOnly: (readOnly?: boolean) => void;
}

export { useState as useContractEarningsItemState };

const useState = create<State>((set) => ({
  setItemId: (id: number) => set({ itemId: id }),
  setDetailId: (id: number) => set({ detailId: id }),
  isDetailCreateModalOpen: false,
  setIsDetailCreateModalOpen: () =>
    set(({ isDetailCreateModalOpen }) => ({ isDetailCreateModalOpen: !isDetailCreateModalOpen })),
  idList: [],
  setIdList: (idList: number[]) => set({ idList }),
  isDetailUpdateModalOpen: false,
  setIsDetailUpdateModalOpen: () =>
    set(({ isDetailUpdateModalOpen }) => ({ isDetailUpdateModalOpen: !isDetailUpdateModalOpen })),
  name: '',
  setName: (name: string) => set({ name }),
  hasVat: HAS_VAT_OPTION.EXCLUDE,
  setHasVat: (hasVat: HAS_VAT_OPTION) => set({ hasVat }),
  readOnly: false,
  setReadOnly: (readOnly) => set({ readOnly }),
}));
