import { useQuery } from 'react-query';
import { affiliatedPersonApi } from '@front/src/features/affiliated-person/api/api';

export { query as affiliatedPersonSalesActivityQuery };
const query = {
  useListGet: (id: number) => {
    const { data } = useQuery({
      queryKey: ['affiliated-person', id, 'sales-activity'],
      queryFn: () => affiliatedPersonApi.getSalesActivityList(id),
      select: (res) => res.data,
      suspense: true,
      enabled: !!id,
    });
    return {
      data,
    };
  },
};
