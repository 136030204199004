import React from 'react';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoContractReviewModalState } from '@front/src/features/project-sales-info/features/contract/features/reivew/features/modal/widgets/useState';
import { ProjectSalesInfoContractReviewModalWidget } from '@front/src/features/project-sales-info/features/contract/features/reivew/features/modal/widgets/widget';

export { ReviewModal as ProjectSalesInfoContractReviewModal };

const ReviewModal = () => {
  const { isOpen } = useProjectSalesInfoContractReviewModalState(
    (state) => ({
      isOpen: state.isOpen,
    }),
    shallow
  );
  if (!isOpen) {
    return <></>;
  }
  return <ProjectSalesInfoContractReviewModalWidget />;
};
