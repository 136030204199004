import { useDispatch } from 'react-redux';
import React, { memo, useCallback } from 'react';
import { FormikProvider, useFormik } from 'formik';

import { ostAction } from '@front/ost/action';
import OstMemoAddForm from '@front/ost/view/memo/addForm';
import type { OstMemoAddParameter } from '@front/ost/parameter';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import { userNotificationAction } from '@front/user_notification/action';
import { useQueryClient } from 'react-query';

const MemoAddFormService = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const openSnackbar = useCallback(
    (message, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    (params: OstMemoAddParameter) => {
      if (!params.description.trim()) {
        openSnackbar('내용을 입력해주세요.');
        formik.resetForm();
        return;
      }
      dispatch(ostAction.addMemo(params));
      formik.resetForm();
    },
    [dispatch]
  );

  const formik = useFormik({
    initialValues: {
      description: '',
      attendanceList: [],
    } as OstMemoAddParameter,
    onSubmit: onSubmit,
  });

  const onBlur = useCallback(
    (e) => {
      const value = e.target.value ?? '';
      if (value !== formik.values.description) {
        formik.setFieldValue('description', value);
      }
    },
    [formik]
  );

  const onChange = useCallback(
    (list) => {
      formik.setFieldValue('attendanceList', list);
    },
    [formik]
  );

  const onClick = useCallback(async () => {
    dispatch(userNotificationAction.requestCount());
    await queryClient.invalidateQueries(['global-nav-bar']);
    formik.handleSubmit();
  }, [formik]);

  return (
    <FormikProvider value={formik}>
      <OstMemoAddForm
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
      />
    </FormikProvider>
  );
};

const OstMemoAddFormService = memo(MemoAddFormService);

export default OstMemoAddFormService;
