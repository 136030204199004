import { AccountingUploadSalesInfoQuery } from '@front/src/features/accounting/features/upload/features/sales-info/query/query';
import type { SalesAccountingAmountInformationGetParameter } from '@front/src/features/accounting/features/upload/features/sales-info/types/parameter';

export { Repository as AccountingUploadSalesInfoRepository };

const Repository = {
  useGetList: (params: SalesAccountingAmountInformationGetParameter) => {
    const { data, remove } = AccountingUploadSalesInfoQuery.useGetList(params);
    return {
      data,
      remove,
    };
  },
};
