import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import type { ProjectSalesInfoBidResultView } from '@front/src/features/project-sales-info/features/bid/features/bid-result/types/view';
import type { IdDeleteParams } from '@front/src/features/project-sales-info/features/bid/features/bid-result/types/parameter';

interface State {
  d: {
    bidResultList?: ProjectSalesInfoBidResultView[];
  };
  h: {
    onDelete: (params: IdDeleteParams) => void;
  };
  isLoading: boolean;
}

export { Context as ProjectSalesInfoBidResultContext };

const Context = createContext<State>({
  d: {},
  h: {
    onDelete: noOp,
  },
  isLoading: false,
});
