import React from 'react';
import classes from '@front/src/features/project-sales-info/features/activity/components/layouts/body.module.scss';

export { Body as ActivityBody };

interface Props {
  children: React.ReactNode;
}

const Body = ({ children }: Props) => <div className={classes.container}>{children}</div>;
