import { projectSalesInfoBidInfoQuery } from '@front/src/features/project-sales-info/features/bid/features/bid-information/query';
import { projectSalesInfoBidInfoMutation } from '@front/src/features/project-sales-info/features/bid/features/bid-information/query/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { IdDeleteParams } from '@front/src/features/project-sales-info/features/bid/features/bid-information/types/parameter';

export const projectSalesInfoBidInfoRepository = {
  useProjectSalesInfoBidInfoListGet: (id: number) => {
    const { data, isLoading } = projectSalesInfoBidInfoQuery.useProjectSalesInfoBidInfoListGet(id);
    return {
      data,
      isLoading,
    };
  },

  useProjectSalesInfoBidInfoDelete: (id: number) => {
    const { mutate } = projectSalesInfoBidInfoMutation.useProjectSalesInfoDelete(id);
    const callback = getNoOpCallback();
    return {
      run: (params: IdDeleteParams) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
