import React from 'react';
import type { CommentParameter } from '@front/ost_proposal/parameter';
import { Box } from '@mui/material';
import AddCommentRow from '@front/ost_proposal/view/detail/comment/addCommentRow/AddCommentRow';
import TextBox from '@front/layouts/Text';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';

interface Props {
  inputRef: React.RefObject<HTMLInputElement>;
  addComment: (comment: CommentParameter) => void;
}

const AddCommentLayout = ({ inputRef, addComment }: Props) => {
  const loginUserName = useSelector((state: RootState) => state.login.detail?.name, shallowEqual);
  return (
    <Box
      sx={{
        display: 'grid',
        width: '100%',
        gridTemplateRows: '20px auto',
        gridTemplateAreas: `"name"
       "contents"`,
        marginBottom: '10px',
      }}
    >
      <Box
        sx={{
          display: 'inherit',
          width: '100%',
          gridArea: 'name',
        }}
      >
        <TextBox variant="body7">{loginUserName}</TextBox>
      </Box>
      <AddCommentRow
        addComment={addComment}
        inputRef={inputRef}
      />
    </Box>
  );
};

export default AddCommentLayout;
