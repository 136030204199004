import type { ProjectCMPaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/types/parameter';
import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';

interface State {
  h: {
    onCreate: (params: ProjectCMPaymentHistoryParams) => void;
  };
  d: {
    CMPaymentMethodOptionList?: VariableInformationView[];
    CMPaymentStatusOptionList?: VariableInformationView[];
  };
}

export { Context as ProjectSalesInfoCMPaymentHistoryCreateModalContext };

const Context = createContext<State>({
  d: {},
  h: {
    onCreate: noOp,
  },
});
