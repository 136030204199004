import type { ReactNode } from 'react';
import React, { memo, useContext } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table as MuiTable } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { AccountingUploadEcountDataContext } from '@front/src/features/accounting/features/upload/features/ecount-data/provider/context';

export { Table as AccountingUploadEcountDataTable };

interface Props {
  children: ReactNode;
}

const Table = ({ children }: Props) => (
  <TableContainer>
    <MuiTable>
      <TableHeader />
      <TableBody>{children}</TableBody>
    </MuiTable>
  </TableContainer>
);

const TableHeader = memo(() => {
  const { isModalMode } = useContext(AccountingUploadEcountDataContext);
  return (
    <TableHead>
      <TableRow>
        <Th width="17%">일시</Th>
        {!isModalMode && <Th width="16%">업로드 구분</Th>}
        {!isModalMode && <Th width="17%">업로드 파일</Th>}
        <Th width="10%">실행자</Th>
        <Th width="40%">작업결과</Th>
      </TableRow>
    </TableHead>
  );
});

TableHeader.displayName = 'AccountingUploadEcountDataTableHeader';
