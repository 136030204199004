import React, { useCallback, useContext } from 'react';
import { Box } from '@mui/material';
import TextBox from '@front/layouts/Text';
import { FormProvider, useForm } from 'react-hook-form';
import type { AccountingUploadSalesInfoFormValues } from '@front/src/features/accounting/features/upload/features/sales-info/types';
import { useAccountingUploadSalesInfoState } from '@front/src/features/accounting/features/upload/features/sales-info/useState';
import { AccountingUploadSalesInfoGoalModalUpdateWidget } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/widgets/update/widget';
import { AccountingUploadSalesInfoGoalModalListTable } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/widgets/list/components/table';
import { useShallow } from 'zustand/react/shallow';
import { AccountingUploadSalesInfoGoalModalListContext } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/widgets/list/provider/context';
import { getValuesForAccountingUploadSalesInfoGoalModalForm } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/utils';
import { AccountingUploadSalesInfoInfoText } from '@front/src/features/accounting/features/upload/features/sales-info/components/info-text';
import { convertDateToStringFormat, DATE_TIME_FORMAT } from '@front/src/utils';

export { List as AccountingUploadSalesInfoGoalModalList };

const List = () => {
  const { list } = useContext(AccountingUploadSalesInfoGoalModalListContext);
  const { year } = useAccountingUploadSalesInfoState(
    useShallow((state) => ({
      year: state.year,
    }))
  );
  const methods = useForm<AccountingUploadSalesInfoFormValues>({
    values: getValuesForAccountingUploadSalesInfoGoalModalForm(list),
  });
  const getValuesFromList = useCallback(() => {
    const modifiedAt = list?.[0]?.modifiedAt ?? list?.[0]?.createdAt;
    return {
      confirmedAt: convertDateToStringFormat(list?.[0]?.confirmedAt, DATE_TIME_FORMAT),
      modifiedAt: convertDateToStringFormat(modifiedAt, DATE_TIME_FORMAT),
    };
  }, [list]);
  const { confirmedAt, modifiedAt } = getValuesFromList();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '12px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <TextBox
          variant="body11"
          sx={{
            fontSize: '16px',
          }}
        >
          {year}년도
        </TextBox>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <AccountingUploadSalesInfoInfoText
            label="목표 확정 일시"
            value={confirmedAt}
          />
          <AccountingUploadSalesInfoInfoText
            label="목표 업데이트 일시"
            value={modifiedAt}
          />
          <TextBox variant="body11">단위: 만원, 부가세 포함</TextBox>
        </Box>
      </Box>
      <FormProvider {...methods}>
        <AccountingUploadSalesInfoGoalModalListTable />
        <AccountingUploadSalesInfoGoalModalUpdateWidget />
      </FormProvider>
    </Box>
  );
};
