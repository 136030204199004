import { create } from 'zustand';

interface State {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  companyId?: number;
  setCompanyId: (id?: number) => void;
}

export { useState as useProjectSalesInfoAffiliatedCompanyWebHardCreateModalState };

const useState = create<State>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen: boolean) => set(() => ({ isOpen })),
  setCompanyId: (companyId?: number) => set(() => ({ companyId })),
}));
