import React from 'react';
import { useProjectSalesInfoBidInfoState } from '@front/src/features/project-sales-info/features/bid/features/bid-information/widgets/useState';
import { ProjectSalesInfoBidInfoUpdateModalWidget } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/update-modal/widgets/widget';

export { ModalWrapper as ProjectSalesInfoBidInfoUpdateModalWrapper };

const ModalWrapper = () => {
  const isUpdateModalOpen = useProjectSalesInfoBidInfoState((state) => state.isUpdateModalOpen);
  return <>{isUpdateModalOpen && <ProjectSalesInfoBidInfoUpdateModalWidget />}</>;
};
