import React, { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { usePersonalSettingsModalUISettingsHeaderSettingsHistoryState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/history/useState';
import { useShallow } from 'zustand/react/shallow';
import { usePersonalSettingsModalUISettingsHeaderSettingsPositionState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/useState';
import { PersonalSettingsModalUISettingsHeaderSettingsPositionFeature } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PersonalSettingsModalUISettingsHeaderSettingsTableContentHistoryFeature } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/history';

export { ListDrawerContent as PersonalSettingsModalUISettingsHeaderSettingsListDrawerContent };
interface Props {
  authMenuId: number | undefined;
}
const ListDrawerContent = ({ authMenuId }: Props) => {
  const { header: historyHeader, setHeader: setHistoryHeader } =
    usePersonalSettingsModalUISettingsHeaderSettingsHistoryState(
      useShallow((state) => ({
        header: state.header,
        setHeader: state.setHeader,
      }))
    );
  const { header: positionHeader, setHeader: setPositionHeader } =
    usePersonalSettingsModalUISettingsHeaderSettingsPositionState(
      useShallow((state) => ({
        header: state.header,
        setHeader: state.setHeader,
      }))
    );
  const handleClose = useCallback(() => {
    setHistoryHeader(undefined);
    setPositionHeader(undefined);
  }, [setHistoryHeader, setPositionHeader]);
  const title = useMemo(() => {
    if (historyHeader !== undefined) {
      return (
        <Box
          sx={{
            fontSize: '16px',
            fontWeight: 500,
          }}
        >
          변경 이력 - {historyHeader.name}
        </Box>
      );
    }
    if (!positionHeader) return <></>;
    if (positionHeader.isDuplicate) {
      return (
        <Box
          sx={{
            display: 'flex',
            gap: '4px',
          }}
        >
          <Box
            sx={{
              fontSize: '16px',
              fontWeight: 500,
            }}
          >
            중복 위치 - {positionHeader.name}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              fontSize: '12px',
            }}
          >
            (중복되는 헤더명의 사용 위치가 체크 표시됩니다.)
          </Box>
        </Box>
      );
    }
    return (
      <Box
        sx={{
          fontSize: '16px',
          fontWeight: 500,
        }}
      >
        사용 위치 - {positionHeader.name}
      </Box>
    );
  }, [historyHeader, positionHeader]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {title}
        <FontAwesomeIcon
          icon="xmark"
          onClick={handleClose}
          style={{
            cursor: 'pointer',
            fontSize: '24px',
          }}
        />
      </Box>
      <Box>
        {historyHeader !== undefined && (
          <PersonalSettingsModalUISettingsHeaderSettingsTableContentHistoryFeature
            authMenuId={authMenuId}
          />
        )}
        {positionHeader !== undefined && (
          <PersonalSettingsModalUISettingsHeaderSettingsPositionFeature authMenuId={authMenuId} />
        )}
      </Box>
    </Box>
  );
};
