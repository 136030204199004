import type { MouseEventHandler, ReactNode } from 'react';
import React, { useCallback } from 'react';
import type { MyPageAuthorizationMenuView } from '@front/src/features/my-page/features/authorization/types/view';
import { useMyPageAuthorizationState } from '@front/src/features/my-page/features/authorization/useState';
import { Box } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { ColorPalette } from '@front/assets/theme';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material/styles';

export { CommonList as MyPageAuthorizationMenuCommonList };

interface Props {
  depth: number;
  list: MyPageAuthorizationMenuView[];
}

const CommonList = ({ list, depth }: Props) => {
  const { tableRowNum, setClicked, clicked, setTabId } = useMyPageAuthorizationState(
    useShallow((state) => ({
      tableRowNum: state.tableRowNum,
      setClicked: state.setClicked,
      clicked: state.clicked,
      setTabId: state.setTabId,
    }))
  );
  const handleCellClick = useCallback(
    (item) => {
      if (item.isTab || item.isTab === null || item.children === null || item.children.length === 0)
        return;
      setTabId(item.id);
      if (item.depth == 1) {
        setClicked([item.id]);
        return;
      }
      const copy = [...clicked];
      if (clicked.length < item.depth) {
        copy.push(item.id);
      } else if (clicked.length == item.depth) {
        copy.pop();
        copy.push(item.id);
      } else if (clicked.length > item.depth) {
        for (let i = 0; i < clicked.length - item.depth; i++) {
          copy.pop();
        }
        copy.push(item.id);
      }
      setClicked(copy);
    },
    [setClicked, clicked]
  );
  const renderList = useCallback(
    () =>
      new Array(tableRowNum).fill(0).map((_, i) => {
        if (list.length === 0) {
          return <Td key={`depth${depth}-${i}`} />;
        }
        const item = list[i];
        if (!item) {
          return <Td key={`depth${depth}-${i}`} />;
        }
        const isClicked = clicked.includes(item.id);
        return (
          <Td
            key={item.id}
            onClick={() => handleCellClick(item)}
            sx={{
              cursor: item.children === null || item.children.length === 0 ? 'default' : 'pointer',
              backgroundColor: isClicked ? ColorPalette._9bb6ea : 'none',
            }}
          >
            <Box
              sx={{
                paddingLeft: '5px',
              }}
            >
              {item.name}
            </Box>
          </Td>
        );
      }),
    [list, tableRowNum, handleCellClick, depth]
  );
  return <Box>{renderList()}</Box>;
};

interface TdProps {
  children?: ReactNode;
  sx?: SxProps<Theme>;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const Td = ({ children, sx, ...rest }: TdProps) => (
  <Box
    {...rest}
    sx={{
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      outline: `1px solid ${ColorPalette._e4e9f2}`,
      fontSize: '14px',
      ...sx,
    }}
  >
    {children}
  </Box>
);
