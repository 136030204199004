import React from 'react';
import { AffiliatedPersonAppBarPersonCreateProvider } from '@front/src/features/affiliated-person/features/app-bar/widgets/create/provider/provider';
import { AffiliatedPersonCreateButton } from '@front/src/features/affiliated-person/features/app-bar/widgets/create/components/create-button';

export { Widget as AffiliatedPersonCreateWidget };
const Widget = () => (
  <AffiliatedPersonAppBarPersonCreateProvider>
    <AffiliatedPersonCreateButton />
  </AffiliatedPersonAppBarPersonCreateProvider>
);
