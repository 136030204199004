import React, { useContext } from 'react';
import { AffiliatedPersonMemoListContext } from '@front/src/features/affiliated-person/features/memo/widgets/list/provider/context';
import { MemoNoResult } from '@front/src/components/no-result-box/memo-no-result';
import { AffiliatedPersonMemoItem } from '@front/src/features/affiliated-person/features/memo/widgets/list/components/item';
import { Box } from '@mui/material';

export { List as AffiliatedPersonMemoList };

const List = () => {
  const { list } = useContext(AffiliatedPersonMemoListContext);
  if (typeof list === 'undefined' || list?.length === 0) {
    return <MemoNoResult />;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'calc(100vh - 340px)',
        padding: '0 10px 15px 10px',
        overflowY: 'auto',
      }}
    >
      {list?.map((item) => (
        <AffiliatedPersonMemoItem
          key={item.id}
          item={item}
        />
      ))}
    </Box>
  );
};
