import { baseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanyOutlineShortUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/short/types/parameter';
import { adminManageableVariableApiURL } from '@front/src/features/manageable-variable/utils/constants';
import { manageableVariable } from '@front/src/features/affiliated-company/features/outline/features/short/utils/constants';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  updateOutlineShort: (id?) => `${baseUrl}/${id}/short`,
  getCategoryOptionList: () => `${adminManageableVariableApiURL}`,
};

export const affiliatedCompanyOutlineShortApi = {
  updateOutlineShort: async (params: AffiliatedCompanyOutlineShortUpdateParameter, menuId) => {
    await axios.put(url.updateOutlineShort(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  getCategoryOptionList: async (menuId) => {
    const { data } = await axios.get(url.getCategoryOptionList(), {
      params: { key: manageableVariable.AffiliatedCompanyOutlineShortCategory },
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
