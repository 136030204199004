import { noOp } from '@front/src/utils';
import { createContext } from 'react';
import type { AffiliatedCompanyPersonOutlineRepresentativeContactInformationUpdateCompanyParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/types/parameter';

export { Context as AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationUpdateContext };

interface State {
  onUpdate: (
    params: AffiliatedCompanyPersonOutlineRepresentativeContactInformationUpdateCompanyParameter
  ) => void;
}

const Context = createContext<State>({
  onUpdate: noOp,
});
