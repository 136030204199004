import React from 'react';
import type { SectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import AffiliatedCompanyOutlineShortWidget from '@front/src/features/affiliated-company/features/outline/features/short/widgets/widget';
import AffiliatedCompanyOutlineAddressWidget from '@front/src/features/affiliated-company/features/outline/features/address/widgets/widget';
import AffiliatedCompanyOutlineNumberWidget from '@front/src/features/affiliated-company/features/outline/features/number/widgets/widget';
import AffiliatedCompanyOutlinePrimaryBusinessWidget from '@front/src/features/affiliated-company/features/outline/features/primary-business/widgets/widget';
import AffiliatedCompanyOutlineInfoWidget from '@front/src/features/affiliated-company/features/outline/features/info/widgets/widget';
import AffiliatedCompanyOutlineWebHardWidget from '@front/src/features/affiliated-company/features/outline/features/web-hard/widgets/widget';
import AffiliatedCompanyOutlineBankInfoWidget from '@front/src/features/affiliated-company/features/outline/features/bank-info/widgets/widget';

export { sectionComponent as affiliatedCompanyOutlineSectionComponent };

const sectionComponent: SectionComponent = {
  41: ({ ...rest }) => <AffiliatedCompanyOutlineShortWidget {...rest} />,
  42: ({ ...rest }) => <AffiliatedCompanyOutlineAddressWidget {...rest} />,
  43: ({ ...rest }) => <AffiliatedCompanyOutlineNumberWidget {...rest} />,
  44: ({ ...rest }) => <AffiliatedCompanyOutlinePrimaryBusinessWidget {...rest} />,
  45: ({ ...rest }) => <AffiliatedCompanyOutlineInfoWidget {...rest} />,
  46: ({ ...rest }) => <AffiliatedCompanyOutlineWebHardWidget {...rest} />,
  47: ({ ...rest }) => <AffiliatedCompanyOutlineBankInfoWidget {...rest} />,
};
