import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { DefaultFunction } from '@front/src/types';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';
import type { ProjectSalesInfoContractReviewHistoryParameter } from '@front/src/features/project-sales-info/features/contract/features/review-history/features/modal/types/parameter';
import type { ProjectContractReviewHistoryView } from '@front/src/features/project-sales-info/features/contract/features/review-history/types/view';
import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/types/view';

export { context as ProjectSalesInfoContractReviewHistoryModalContext };

interface State {
  d: {
    detail?: ProjectContractReviewHistoryView;
    categoryList?: VariableInformationView[];
    typeList?: VariableInformationView[];
    companyList?: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyView[];
  };
  h: {
    onCreate: (params: ProjectSalesInfoContractReviewHistoryParameter) => void;
    onUpdate: (params: ProjectSalesInfoContractReviewHistoryParameter) => void;
    onClose: DefaultFunction;
  };
}

const context = createContext<State>({
  d: {},
  h: {
    onCreate: noOp,
    onUpdate: noOp,
    onClose: noOp,
  },
});
