import React, { useCallback } from 'react';
import IconButton from '@front/layouts/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

export { Hamburger as GlobalNavBarHamburger };
const Hamburger = () => {
  const { open, toggle } = useGlobalNavBarState(
    useShallow((state) => ({
      open: state.open,
      toggle: state.toggle,
    }))
  );
  const handleButtonClick = useCallback(() => {
    toggle();
  }, [toggle]);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <IconButton
        tooltip={'메인 메뉴 ' + (open ? '접기' : '펴기')}
        onClick={handleButtonClick}
      >
        <FontAwesomeIcon icon={'bars'} />
      </IconButton>
    </Box>
  );
};
