import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useShallow } from 'zustand/react/shallow';
import type { WorkWorkerView } from '@front/src/features/work/features/work/features/worker/types/view';
import { ColorPalette } from '@front/assets/theme';
import { getCommonControlPanelItemList } from '@front/src/features/work/features/work/utils/constant';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import useWorkWorkerState from '@front/src/features/work/features/work/features/worker/useState';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/TableBody';
import type { NoteMenuIdView } from '@front/src/features/note/types/view';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import useNoteStore from '@front/src/features/note/useState';

export default function WorkWorkerTableRow({
  item,
  rowActions,
  sectionId,
  dataId,
  menuId,
  ...rest
}: Readonly<UIBuilderTableRowProps<WorkWorkerView>>) {
  const { editOpenList, addEditOpenId, colored } = useWorkWorkerState(
    useShallow((state) => ({
      editOpenList: state.editOpenList,
      addEditOpenId: state.addEditOpenId,
      colored: state.colored,
    }))
  );
  const methods = useForm({
    values: getFormValues(item),
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    rowActions?.onUpdate({
      ...data,
      menuId,
      dataId,
      sectionId,
    });
  });
  const setIsRightDrawerOpen = useGlobalNavBarState((state) => state.setIsRightDrawerOpen);
  const { setRowId, setSectionId } = useNoteStore(
    useShallow((state) => ({
      setRowId: state.setRowId,
      setSectionId: state.setSectionId,
    }))
  );
  const actions = {
    onAdd: () => rowActions?.onCreate(item.id),
    onRemove: () => rowActions?.onRemove(item.id),
    onMoveUp: () => rowActions?.onSeqDown(item.id),
    onMoveDown: () => rowActions?.onSeqUp(item.id),
    onEdit: () => addEditOpenId(item.id),
    onNoteOpen: () => {
      const noteAuth: NoteMenuIdView = JSON.parse(sessionStorage.getItem('noteAuth') ?? '{}');
      if (!noteAuth.noteId || noteAuth.type === 'X') {
        alert({
          title: '권한 오류',
          children: [{ value: '접근 권한이 없습니다.' }],
        });
        return;
      }
      setIsRightDrawerOpen(true);
      setRowId(item.id);
      setSectionId(sectionId);
    },
  };
  const isEditOpen = editOpenList.includes(item.id);
  const itemList = getCommonControlPanelItemList({ actions, isEditOpen });
  const sx = {
    backgroundColor: colored === item.id ? `${ColorPalette._d2e7fa}` : 'inherit',
  };
  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        sx={sx}
        item={item}
        onSubmit={onSubmit}
        itemList={itemList}
        isEditMode={isEditOpen}
        sectionId={sectionId}
        dataId={dataId}
        menuId={menuId}
        {...rest}
      />
    </FormProvider>
  );
}

const getFormValues = (item) => ({
  id: item.id,
  workerRole: item.workerRole ?? '',
  participationRate: item.participationRate ?? '',
  roleDetail: item.roleDetail ?? '',
  userId: item.user?.id,
  textAttr1: item.textAttr1 ?? '',
  textAttr2: item.textAttr2 ?? '',
  textAttr3: item.textAttr3 ?? '',
  numberAttr1: item.numberAttr1,
  numberAttr2: item.numberAttr2,
  numberAttr3: item.numberAttr3,
  dateAttr1: item.dateAttr1 ?? '',
  dateAttr2: item.dateAttr2 ?? '',
  dateAttr3: item.dateAttr3 ?? '',
});
