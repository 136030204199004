import React from 'react';
import type { MemoView } from '@front/src/types';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import { AffiliatedPersonMemoItemHeader } from '@front/src/features/affiliated-person/features/memo/widgets/list/components/item-header';
import { AffiliatedPersonMemoItemBody } from '@front/src/features/affiliated-person/features/memo/widgets/list/components/item-body';
import { FormProvider, useForm } from 'react-hook-form';
import type { AffiliatedPersonMemoItemFormType } from '@front/src/features/affiliated-person/features/memo/types';

export { Item as AffiliatedPersonMemoItem };

interface Props {
  item: MemoView;
}

const Item = ({ item }: Props) => {
  const methods = useForm<AffiliatedPersonMemoItemFormType>({
    defaultValues: {
      memoId: item.id,
      description: item.description,
      attendanceList: item.attendanceList,
      createdAt: item.createdAt,
      modifiedAt: item.modifiedAt,
      writer: item.writer,
    },
  });
  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginBottom: '10px',
          padding: '15px',
          border: `1px solid ${ColorPalette._e4e9f2}`,
          borderRadius: '5px',
          backgroundColor: ColorPalette._ffffff,
        }}
      >
        <AffiliatedPersonMemoItemHeader />
        <AffiliatedPersonMemoItemBody />
      </Box>
    </FormProvider>
  );
};
