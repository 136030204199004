import React, { useMemo } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { useProjectSalesInfoContractHistoryModalLogic } from '@front/src/features/project-sales-info/features/contract/features/history/features/modal/widgets/useLogic';
import { ProjectSalesInfoContractHistoryModalContext } from '@front/src/features/project-sales-info/features/contract/features/history/features/modal/widgets/context';
import { ProjectSalesInfoContractHistoryModalTable } from '@front/src/features/project-sales-info/features/contract/features/history/features/modal/components/modal-table';
import { isMobileDevice } from '@front/util/PwaUtil';

export { Widget as ProjectSalesInfoContractHistoryModalWidget };
const Widget = () => {
  const {
    d: { detail, categoryList, typeList },
    h: { onClose, onUpdate, onCreate },
    isLoading,
    isOpen,
    isCategoryLoading,
    isTypeLoading,
  } = useProjectSalesInfoContractHistoryModalLogic();
  const value = useMemo(
    () => ({
      d: { detail, categoryList, typeList },
      h: { onClose, onUpdate, onCreate },
      isLoading,
      isCategoryLoading,
      isTypeLoading,
    }),
    [
      detail,
      categoryList,
      isLoading,
      onClose,
      onUpdate,
      onCreate,
      isCategoryLoading,
      typeList,
      isTypeLoading,
    ]
  );
  return (
    <ProjectSalesInfoContractHistoryModalContext.Provider value={value}>
      <ModalLayout
        position="left"
        width={isMobileDevice() ? '29dvw' : '580px'}
        title="계약서 이력 등록/수정"
        open={isOpen}
        onClose={onClose}
      >
        <ProjectSalesInfoContractHistoryModalTable />
      </ModalLayout>
    </ProjectSalesInfoContractHistoryModalContext.Provider>
  );
};
