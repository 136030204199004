import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import type {
  CampaignApprovalVO,
  CampaignEstimatorVO,
  CampaignId,
  CampaignMemoId,
  CampaignMemoVO,
  CampaignShortVO,
  CampaignVO,
  ProposalCategoryId,
  ProposalCategoryVO,
} from '@front/ost_campaign/domain';
import { campaignStatusName } from '@front/ost_campaign/domain';
import type {
  CampaignAddParameter,
  CampaignBannerUpdateContentParameter,
  CampaignBannerUpdateIsExposedParameter,
  CampaignMemoAddParameter,
  CampaignMemoUpdateParameter,
  CampaignUpdateApprovalParameter,
  CampaignUpdateEndDateParameter,
  CampaignUpdateEstimationCompletionRateParameter,
  CampaignUpdateEstimationEndDateParameter,
  CampaignUpdateEstimationStartDateParameter,
  CampaignUpdateIsNoticeExposedParameter,
  CampaignUpdateNameParameter,
  CampaignUpdateNoticeParameter,
  CampaignUpdateProposalCategoryParameter,
  CampaignUpdateStartDateParameter,
  CampaignUpdateStatusParameter,
  CampaignUpdateTotalRewardParameter,
  CampaignUpdateVoteEndDateParameter,
  CampaignUpdateVoteStartDateParameter,
  EstimatorListUpsertParameter,
  ProposalPaymentDateParameter,
} from '@front/ost_campaign/parameter';
import ostApi from '@front/ost/api';
import { ostAction } from '@front/ost/action';
import type { UserId } from '@front/user/domain';
import { voteAction } from '@front/ost_vote/action';
import { dialogAction } from '@front/dialog/action';
import { campaignApi } from '@front/ost_campaign/api';
import type { RootState } from '@front/services/reducer';
import { navigationAction } from '@front/navigate/action';
import { campaignAction } from '@front/ost_campaign/action';
import { getErrorCode, getErrorMessage } from '@front/type/Error';
import type { ProposalId, ProposalVO } from '@front/ost_proposal/domain';
import { userNotificationAction } from '@front/user_notification/action';
import type { CampaignMemoQuery, CampaignQuery } from '@front/ost_campaign/query';
import type { ProposalFixedResultUpdateParameter } from '@front/ost_proposal/parameter';
import { proposalApi } from '@front/ost_proposal/api';
import { proposalAction } from '@front/ost_proposal/action';

import { CustomHttpStatus, getCustomErrorMessage } from '@front/common/type/http-status.type';

/**
 * 캠페인 목록 및 검색 관련 saga
 */
function* setFilter({ payload: filter }: { payload: CampaignQuery }) {
  yield put(campaignAction.setLoading(true));
  try {
    const campaignList: CampaignShortVO[] = yield call(campaignApi.getCampaignList, filter);
    yield put(campaignAction.setList(campaignList));
    yield put(campaignAction.setLoading(false));
  } catch (e) {
    const message = getErrorMessage(campaignAction.setFilter, e);
    yield put(dialogAction.openError(message));
  }
}

function* setCampaignList() {
  const { id } = yield select((root: RootState) => ({
    id: root.ost.id,
  }));
  try {
    const campaignList: CampaignVO[] = yield call(ostApi.getCampaignList, id);
    yield put(ostAction.setCampaignList(campaignList));
  } catch (e) {
    const message = getErrorMessage(ostAction.setCampaignList, e);
    yield put(dialogAction.openError(message));
  }
}

/**
 * 캠페인 등록 관련 saga
 */
function* addCampaign({ payload: params }: { payload: CampaignAddParameter }) {
  try {
    yield call(campaignApi.add, params);
    yield fork(setCampaignList);
    yield put(dialogAction.openAlert('등록이 완료 됐습니다.'));
  } catch (e) {
    const message = getErrorMessage(campaignAction.add, e);
    yield put(dialogAction.openError(message));
  }
}

/**
 * 캠페인 상세 조회
 */
function* setId({ payload: campaignId }: { payload: CampaignId | undefined }) {
  try {
    if (!campaignId) {
      return;
    }
    const detail: CampaignVO = yield call(campaignApi.getOne, campaignId);
    yield fork(setProposalCategoryList);
    yield fork(setEstimatorList);
    yield fork(setProposalList);
    yield fork(setApprovalList);
    // yield fork(setCampaignBanner);
    yield put(campaignAction.setDetail(detail));
  } catch (e) {
    const message = getErrorMessage(campaignAction.setFilter, e);
    const code = getErrorCode(campaignAction.setFilter, e);
    if (code === CustomHttpStatus.NO_AUTHORIZATION) {
      yield put(dialogAction.openBlock(getCustomErrorMessage(CustomHttpStatus.NO_AUTHORIZATION)));
      return;
    }
    yield put(dialogAction.openError(message));
  }
}

/**
 * 캠페인 상세 삭제
 */
function* deleteDetail() {
  const campaignId: CampaignId = yield select((root: RootState) => root.campaign.id);
  const filter: CampaignQuery = yield select((root: RootState) => root.campaign.filter);
  try {
    yield call(campaignApi.delete, campaignId);
    yield put(campaignAction.setFilter(filter));
    yield put(navigationAction.navigate(`/ost/campaign-management`));
  } catch (e) {
    const message = getErrorMessage(campaignAction.deleteDetail, e);
    yield put(dialogAction.openError(message));
  }
}

/**
 * 캠페인 상세 제안 구분 목록
 */
function* setProposalCategoryList() {
  const id: CampaignId = yield select((root: RootState) => root.campaign.id);
  try {
    const proposalCategoryList: ProposalCategoryVO[] = yield call(
      campaignApi.getProposalCategoryList,
      id
    );
    yield put(campaignAction.setProposalCategoryList(proposalCategoryList));
  } catch (e) {
    const message = getErrorMessage(campaignAction.setProposalCategoryList, e);
    yield put(dialogAction.openError(message));
  }
}

/**
 * 캠페인 상세 제안 구분 추가
 */
function* addProposalCategory({ payload: params }) {
  const id: CampaignId = yield select((root: RootState) => root.campaign.id);
  try {
    yield call(campaignApi.addProposalCategory, id, params);
    yield fork(setProposalCategoryList);
    yield put(campaignAction.setAddProposalCategoryModalOpen(false));
  } catch (e) {
    const message = getErrorMessage(campaignAction.addProposalCategory, e);
    yield put(dialogAction.openError(message));
  }
}

/**
 * 캠페인 상세 제안 구분 삭제
 */
function* deleteProposalCategory({ payload: proposalCategoryId }: { payload: ProposalCategoryId }) {
  const campaignId: CampaignId = yield select((root: RootState) => root.campaign.id);
  try {
    yield call(campaignApi.deleteProposalCategory, campaignId, proposalCategoryId);
    yield fork(setProposalCategoryList);
  } catch (e) {
    const message = getErrorMessage(campaignAction.deleteProposalCategory, e);
    yield put(dialogAction.openError(message));
  }
}

function* setEstimatorList() {
  const campaignId: CampaignId = yield select((root: RootState) => root.campaign.id);
  try {
    const estimatorList: CampaignEstimatorVO[] = yield call(
      campaignApi.getEstimatorList,
      campaignId
    );
    yield put(campaignAction.setEstimatorList(estimatorList));
  } catch (e) {
    const message = getErrorMessage(campaignAction.setEstimatorList, e);
    yield put(dialogAction.openError(message));
  }
}

function* upsertCampaignEstimatorList({
  payload: campaignEstimatorList,
}: {
  payload: EstimatorListUpsertParameter;
}) {
  const campaignId: CampaignId = yield select((root: RootState) => root.campaign.id);
  try {
    yield call(campaignApi.upsertCampaignEstimatorList, campaignId, campaignEstimatorList);
    yield fork(setEstimatorList);
    yield fork(setProposalList);
  } catch (e) {
    const message = getErrorMessage(campaignAction.upsertCampaignEstimatorList, e);
    yield put(dialogAction.openError(message));
  }
}

function* setProposalList() {
  const campaignId: CampaignId = yield select((root: RootState) => root.campaign.id);
  try {
    const proposalList: ProposalVO[] = yield call(campaignApi.getProposalList, campaignId);
    yield put(campaignAction.setProposalList(proposalList));
  } catch (e) {
    const message = getErrorMessage(campaignAction.setProposalList, e);
    yield put(dialogAction.openError(message));
  }
}

function* setApprovalList() {
  const campaignId: CampaignId = yield select((root: RootState) => root.campaign.id);
  try {
    const approvalList: CampaignApprovalVO[] = yield call(
      campaignApi.getProposalApprovalList,
      campaignId
    );
    yield put(campaignAction.setApprovalList(approvalList));
  } catch (e) {
    const message = getErrorMessage(campaignAction.setApprovalList, e);
    yield put(dialogAction.openError(message));
  }
}

function* upsertProposalEstimatorList({
  payload: proposalEstimatorList,
}: {
  payload: EstimatorListUpsertParameter;
}) {
  const proposalId: ProposalId = yield select(
    (root: RootState) => root.campaign.proposalEstimatorModalId
  );
  try {
    yield call(campaignApi.upsertProposalEstimatorList, proposalId, proposalEstimatorList);
    yield fork(setProposalList);
  } catch (e) {
    const message = getErrorMessage(campaignAction.upsertProposalEstimatorList, e);
    yield put(dialogAction.openError(message));
  }
}

function* deleteCampaignEstimator({ payload: userId }: { payload: UserId }) {
  const campaignId: CampaignId = yield select((root: RootState) => root.campaign.id);
  try {
    yield call(campaignApi.deleteCampaignEstimator, campaignId, userId);
    yield fork(setEstimatorList);
    yield fork(setProposalList);
  } catch (e) {
    const message = getErrorMessage(campaignAction.deleteCampaignEstimator, e);
    yield put(dialogAction.openError(message));
  }
}

function* upsertProposalPaymentDate({
  payload: paymentDate,
}: {
  payload: ProposalPaymentDateParameter;
}) {
  const proposalId: ProposalId = yield select(
    (root: RootState) => root.campaign.proposalEstimatorModalId
  );
  try {
    yield call(campaignApi.upsertProposalPaymentDate, proposalId, paymentDate);
    yield fork(setProposalList);
  } catch (e) {
    const message = getErrorMessage(campaignAction.upsertProposalPaymentDate, e);
    yield put(dialogAction.openError(message));
  }
}

function* addMemo({ payload: params }: { payload: CampaignMemoAddParameter }) {
  const campaignId: CampaignId = yield select((root: RootState) => root.campaign.id);
  const filter: CampaignMemoQuery = yield select((root: RootState) => root.campaign.filterMemo);
  try {
    yield call(campaignApi.addMemo, campaignId, params);
    yield fork(setFilterMemo, { payload: filter });
    if (Array.isArray(params.attendanceList) && params.attendanceList.length > 0) {
      yield put(userNotificationAction.requestCount());
    }
  } catch (e) {
    const message = getErrorMessage(campaignAction.addMemo, e);
    yield put(dialogAction.openError(message));
  }
}

function* setFilterMemo({ payload: filter }: { payload: CampaignMemoQuery }) {
  yield put(campaignAction.setIsLoadingMemo(true));
  const campaignId: CampaignId = yield select((root: RootState) => root.campaign.id);
  try {
    const memoList: CampaignMemoVO[] = yield call(campaignApi.getMemoList, campaignId, filter);
    yield put(campaignAction.setMemoList(memoList));
    yield put(campaignAction.setIsLoadingMemo(false));
  } catch (e) {
    const message = getErrorMessage(campaignAction.setFilterMemo, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateMemo({ payload: params }: { payload: CampaignMemoUpdateParameter }) {
  const campaignId: CampaignId = yield select((root: RootState) => root.campaign.id);
  const filter: CampaignMemoQuery = yield select((root: RootState) => root.campaign.filterMemo);
  try {
    yield call(campaignApi.updateMemo, campaignId, params.id, params);
    yield put(campaignAction.setFilterMemo(filter));
  } catch (e) {
    const message = getErrorMessage(campaignAction.updateMemo, e);
    yield put(dialogAction.openError(message));
  }
}

function* deleteMemo({ payload: campaignMemoId }: { payload: CampaignMemoId }) {
  const campaignId: CampaignId = yield select((root: RootState) => root.campaign.id);
  const filter: CampaignMemoQuery = yield select((root: RootState) => root.campaign.filterMemo);
  try {
    yield call(campaignApi.deleteMemo, campaignId, campaignMemoId);
    yield put(campaignAction.setFilterMemo(filter));
  } catch (e) {
    const message = getErrorMessage(campaignAction.deleteMemo, e);
    yield put(dialogAction.openError(message));
  }
}

/**
 * 캠페인 상세 업데이트
 */
function* updateName({ payload: params }: { payload: CampaignUpdateNameParameter }) {
  const campaignId: CampaignId = yield select((root: RootState) => root.campaign.id);
  const filter: CampaignQuery = yield select((root: RootState) => root.campaign.filter);
  try {
    yield call(campaignApi.updateName, campaignId, params);
    yield put(campaignAction.setFilter(filter));
    yield put(campaignAction.setId(campaignId));
  } catch (e) {
    const message = getErrorMessage(campaignAction.updateName, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateStartDate({ payload: params }: { payload: CampaignUpdateStartDateParameter }) {
  const campaignId: CampaignId = yield select((root: RootState) => root.campaign.id);
  try {
    yield call(campaignApi.updateStartDate, campaignId, params);
    yield put(campaignAction.setId(campaignId));
  } catch (e) {
    const message = getErrorMessage(campaignAction.updateStartDate, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateEndDate({ payload: params }: { payload: CampaignUpdateEndDateParameter }) {
  const campaignId: CampaignId = yield select((root: RootState) => root.campaign.id);
  try {
    yield call(campaignApi.updateEndDate, campaignId, params);
    yield put(campaignAction.setId(campaignId));
  } catch (e) {
    const message = getErrorMessage(campaignAction.updateEndDate, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateTotalReward({ payload: params }: { payload: CampaignUpdateTotalRewardParameter }) {
  try {
    yield call(campaignApi.updateTotalReward, params.campaignId, params.totalReward);
    // yield put(campaignAction.setId(campaignId));
    yield fork(setCampaignList);
  } catch (e) {
    const message = getErrorMessage(campaignAction.updateTotalReward, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateStatus({ payload: params }: { payload: CampaignUpdateStatusParameter }) {
  const campaignId: CampaignId = yield select((root: RootState) => root.campaign.id);
  const filter: CampaignQuery = yield select((root: RootState) => root.campaign.filter);
  try {
    yield call(campaignApi.updateStatus, campaignId, params);
    yield put(campaignAction.setFilter(filter));
    yield put(campaignAction.setId(campaignId));
    if (params.status) {
      yield put(
        dialogAction.openAlert(`캠페인을 ${campaignStatusName(params.status)} 상태로 합니다.`)
      );
    }
  } catch (e) {
    const message = getErrorMessage(campaignAction.updateStatus, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateProposalCategoryList({
  payload: params,
}: {
  payload: CampaignUpdateProposalCategoryParameter;
}) {
  const campaignId: CampaignId = yield select((root: RootState) => root.campaign.id);
  try {
    yield call(campaignApi.updateProposalCategoryList, campaignId, params);
    yield put(campaignAction.setId(campaignId));
  } catch (e) {
    const message = getErrorMessage(campaignAction.updateProposalCategoryList, e);
    yield put(dialogAction.openError(message));
  }
}

function* getCampaignListUnderOnGoing() {
  try {
    const campaignList: CampaignShortVO[] = yield call(campaignApi.getCampaignListUnderOnGoing);
    yield put(campaignAction.setCampaignListUnderOnGoing(campaignList));
  } catch (e) {
    const message = getErrorMessage(campaignAction.getCampaignListUnderOnGoing, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateEstimationStartDate({
  payload: params,
}: {
  payload: CampaignUpdateEstimationStartDateParameter;
}) {
  const { id } = yield select((root: RootState) => ({ id: root.campaign.id }));
  try {
    yield call(campaignApi.updateEstimationStartDate, id, params);
    yield put(campaignAction.setId(id));
  } catch (e) {
    const message = getErrorMessage(campaignAction.updateEstimationStartDate, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateEstimationEndDate({
  payload: params,
}: {
  payload: CampaignUpdateEstimationEndDateParameter;
}) {
  const { id } = yield select((root: RootState) => ({ id: root.campaign.id }));
  try {
    yield call(campaignApi.updateEstimationEndDate, id, params);
    yield put(campaignAction.setId(id));
  } catch (e) {
    const message = getErrorMessage(campaignAction.updateEstimationEndDate, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateVoteStartDate({
  payload: params,
}: {
  payload: CampaignUpdateVoteStartDateParameter;
}) {
  const { id } = yield select((root: RootState) => ({ id: root.campaign.id }));
  try {
    yield call(campaignApi.updateVoteStartDate, id, params);
    yield put(campaignAction.setId(id));
  } catch (e) {
    const message = getErrorMessage(campaignAction.updateVoteStartDate, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateVoteEndDate({ payload: params }: { payload: CampaignUpdateVoteEndDateParameter }) {
  const { id } = yield select((root: RootState) => ({ id: root.campaign.id }));
  try {
    yield call(campaignApi.updateVoteEndDate, id, params);
    yield put(campaignAction.setId(id));
  } catch (e) {
    const message = getErrorMessage(campaignAction.updateVoteEndDate, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateNotice({ payload: params }: { payload: CampaignUpdateNoticeParameter }) {
  const { id } = yield select((root: RootState) => ({ id: root.campaign.id }));
  try {
    yield call(campaignApi.updateNotice, id, params);
    yield put(campaignAction.setId(id));
  } catch (e) {
    const message = getErrorMessage(campaignAction.updateNotice, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateIsNoticeExposed({
  payload: params,
}: {
  payload: CampaignUpdateIsNoticeExposedParameter;
}) {
  const { id } = yield select((root: RootState) => ({ id: root.campaign.id }));
  try {
    yield call(campaignApi.updateIsNoticeExposed, id, params);
    yield put(campaignAction.setId(id));
  } catch (e) {
    const message = getErrorMessage(campaignAction.updateIsNoticeExposed, e);
    yield put(dialogAction.openError(message));
  }
}

function* addApproval() {
  const { id } = yield select((root: RootState) => ({ id: root.campaign.id }));
  try {
    yield call(campaignApi.addApprovalLine, id);
    yield put(campaignAction.setId(id));
  } catch (e) {
    const message = getErrorMessage(campaignAction.addApproval, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateApproval({ payload: params }: { payload: CampaignUpdateApprovalParameter }) {
  const { id } = yield select((root: RootState) => ({ id: root.campaign.id }));
  try {
    yield call(campaignApi.updateApproval, params);
    yield put(campaignAction.setId(id));
  } catch (e) {
    const message = getErrorMessage(campaignAction.updateApproval, e);
    yield put(dialogAction.openError(message));
  }
}

function* deleteApproval({ payload: id }: { payload: ProposalCategoryId }) {
  const { id: campaignId } = yield select((root: RootState) => ({ id: root.campaign.id }));
  try {
    yield call(campaignApi.deleteApproval, id);
    yield put(campaignAction.setId(campaignId));
  } catch (e) {
    const message = getErrorMessage(campaignAction.deleteApproval, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateEstimationCompletionRate({
  payload: params,
}: {
  payload: CampaignUpdateEstimationCompletionRateParameter;
}) {
  const { id } = yield select((root: RootState) => ({ id: root.campaign.id }));
  try {
    yield call(campaignApi.updateEstimationCompletionRate, id, params);
    yield put(campaignAction.setId(id));
  } catch (e) {
    getErrorCode(voteAction.setFilter, e);
    const message = getErrorMessage(campaignAction.updateEstimationCompletionRate, e);
    yield put(dialogAction.openError(message));
  }
}

/**
 * 확정금액
 */
function* updateFixedResult({ payload: params }: { payload: ProposalFixedResultUpdateParameter }) {
  try {
    yield call(proposalApi.updateFixedResult, params);
    yield fork(setProposalList);
  } catch (e) {
    getErrorCode(voteAction.setFilter, e);
    const message = getErrorMessage(proposalAction.updateFixedResult, e);
    yield put(dialogAction.openError(message));
  }
}

/**
 * 캠페인 안내 컨텐츠 업데이트
 */
function* updateCampaignBannerContent({
  payload: params,
}: {
  payload: CampaignBannerUpdateContentParameter;
}) {
  const { id: bannerId } = yield select((root: RootState) => ({
    id: root.campaign.detail?.campaignBanner.id,
  }));
  const { id } = yield select((root: RootState) => ({
    id: root.campaign.id,
  }));
  try {
    yield call(campaignApi.updateCampaignBannerContent, bannerId, params);
    yield put(campaignAction.setId(id));
  } catch (e) {
    const message = getErrorMessage(campaignAction.updateCampaignBannerContent, e);
    yield put(dialogAction.openError(message));
  }
}

/**
 * 캠페인 안내 노출 업데이트
 */
function* updateCampaignBannerIsExposed({
  payload: params,
}: {
  payload: CampaignBannerUpdateIsExposedParameter;
}) {
  const { id: bannerId } = yield select((root: RootState) => ({
    id: root.campaign.detail?.campaignBanner.id,
  }));
  const { id } = yield select((root: RootState) => ({
    id: root.campaign.id,
  }));
  try {
    yield call(campaignApi.updateCampaignBannerIsExposed, bannerId, params);
    yield put(campaignAction.setId(id));
  } catch (e) {
    const message = getErrorMessage(campaignAction.updateCampaignBannerExposed, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchSetFilter() {
  yield takeLatest(campaignAction.setFilter, setFilter);
}

function* watchAddCampaign() {
  yield takeLatest(campaignAction.add, addCampaign);
}

function* watchSetId() {
  yield takeLatest(campaignAction.setId, setId);
}

function* watchDeleteCampaign() {
  yield takeLatest(campaignAction.deleteDetail, deleteDetail);
}

function* watchUpsertCampaignEstimator() {
  yield takeLatest(campaignAction.upsertCampaignEstimatorList, upsertCampaignEstimatorList);
}

function* watchAddProposalCategory() {
  yield takeLatest(campaignAction.addProposalCategory, addProposalCategory);
}

function* watchDeleteProposalCategory() {
  yield takeLatest(campaignAction.deleteProposalCategory, deleteProposalCategory);
}

function* watchUpsertProposalEstimator() {
  yield takeLatest(campaignAction.upsertProposalEstimatorList, upsertProposalEstimatorList);
}

function* watchDeleteProposalEstimator() {
  yield takeLatest(campaignAction.deleteCampaignEstimator, deleteCampaignEstimator);
}

function* watchUpsertProposalPaymentDate() {
  yield takeLatest(campaignAction.upsertProposalPaymentDate, upsertProposalPaymentDate);
}

function* watchAddMemo() {
  yield takeLatest(campaignAction.addMemo, addMemo);
}

function* watchSetMemoFilter() {
  yield takeLatest(campaignAction.setFilterMemo, setFilterMemo);
}

function* watchUpdateMemo() {
  yield takeLatest(campaignAction.updateMemo, updateMemo);
}

function* watchDeleteMemo() {
  yield takeLatest(campaignAction.deleteMemo, deleteMemo);
}

function* watchUpdateName() {
  yield takeLatest(campaignAction.updateName, updateName);
}

function* watchUpdateStartDate() {
  yield takeLatest(campaignAction.updateStartDate, updateStartDate);
}

function* watchUpdateEndDate() {
  yield takeLatest(campaignAction.updateEndDate, updateEndDate);
}

function* watchUpdateTotalReward() {
  yield takeLatest(campaignAction.updateTotalReward, updateTotalReward);
}

function* watchUpdateStatus() {
  yield takeLatest(campaignAction.updateStatus, updateStatus);
}

function* watchUpdateProposalCategoryList() {
  yield takeLatest(campaignAction.updateProposalCategoryList, updateProposalCategoryList);
}

function* watchGetCampaignListUnderOnGoing() {
  yield takeLatest(campaignAction.getCampaignListUnderOnGoing, getCampaignListUnderOnGoing);
}

function* watchUpdateEstimationStartDate() {
  yield takeLatest(campaignAction.updateEstimationStartDate, updateEstimationStartDate);
}

function* watchUpdateEstimationEndDate() {
  yield takeLatest(campaignAction.updateEstimationEndDate, updateEstimationEndDate);
}

function* watchUpdateNotice() {
  yield takeLatest(campaignAction.updateNotice, updateNotice);
}

function* watchUpdateIsNoticeExposed() {
  yield takeLatest(campaignAction.updateIsNoticeExposed, updateIsNoticeExposed);
}

function* watchUpdateVoteStartDate() {
  yield takeLatest(campaignAction.updateVoteStartDate, updateVoteStartDate);
}

function* watchUpdateVoteEndDate() {
  yield takeLatest(campaignAction.updateVoteEndDate, updateVoteEndDate);
}

function* watchAddApproval() {
  yield takeLatest(campaignAction.addApproval, addApproval);
}

function* watchUpdateApproval() {
  yield takeLatest(campaignAction.updateApproval, updateApproval);
}

function* watchDeleteApproval() {
  yield takeLatest(campaignAction.deleteApproval, deleteApproval);
}

function* watchUpdateEstimationCompletionRate() {
  yield takeLatest(campaignAction.updateEstimationCompletionRate, updateEstimationCompletionRate);
}

function* watchUpdateFixedResult() {
  yield takeLatest(proposalAction.updateFixedResult, updateFixedResult);
}

function* watchUpdateCampaignBannerContent() {
  yield takeLatest(campaignAction.updateCampaignBannerContent, updateCampaignBannerContent);
}

function* watchUpdateCampaignBannerIsExposed() {
  yield takeLatest(campaignAction.updateCampaignBannerExposed, updateCampaignBannerIsExposed);
}

export default function* campaignSaga() {
  yield fork(watchSetFilter);
  yield fork(watchAddCampaign);
  yield fork(watchSetId);
  yield fork(watchDeleteCampaign);

  yield fork(watchAddProposalCategory);
  yield fork(watchDeleteProposalCategory);
  yield fork(watchUpsertCampaignEstimator);

  yield fork(watchUpsertProposalEstimator);
  yield fork(watchDeleteProposalEstimator);
  yield fork(watchUpsertProposalPaymentDate);
  yield fork(watchAddMemo);
  yield fork(watchSetMemoFilter);
  yield fork(watchUpdateMemo);
  yield fork(watchDeleteMemo);

  yield fork(watchUpdateName);
  yield fork(watchUpdateStartDate);
  yield fork(watchUpdateEndDate);
  yield fork(watchUpdateEstimationStartDate);
  yield fork(watchUpdateEstimationEndDate);

  yield fork(watchUpdateNotice);
  yield fork(watchUpdateIsNoticeExposed);

  yield fork(watchUpdateTotalReward);
  // yield fork(watchUpdateManager);
  yield fork(watchUpdateStatus);
  yield fork(watchGetCampaignListUnderOnGoing);

  yield fork(watchUpdateVoteStartDate);
  yield fork(watchUpdateVoteEndDate);
  yield fork(watchAddApproval);
  yield fork(watchUpdateApproval);
  yield fork(watchDeleteApproval);
  yield fork(watchUpdateProposalCategoryList);
  yield fork(watchUpdateEstimationCompletionRate);

  /**
   * 확정 금액
   */
  yield fork(watchUpdateFixedResult);

  /**
   * 캠페인 안내
   */
  yield fork(watchUpdateCampaignBannerContent);
  yield fork(watchUpdateCampaignBannerIsExposed);
}
