import type { ProceedingsAccessVO, ProceedingsVO } from '../domain';
import { ProceedingsAuthority } from '../domain';

export function getWriter(access: ProceedingsAccessVO, detail: ProceedingsVO) {
  return access.authority === ProceedingsAuthority.WRITER && detail.writer.id === access.user.id;
}

export function getReader(access: ProceedingsAccessVO, detail: ProceedingsVO) {
  return (
    access.authority === ProceedingsAuthority.READER &&
    detail.readerList.filter((item) => item.id === access.user.id).length > 0
  );
}

export function getAttendance(access: ProceedingsAccessVO, detail: ProceedingsVO) {
  return (
    access.authority === ProceedingsAuthority.ATTENDANCE &&
    detail.attendanceList.filter((item) => item.id === access.user.id).length > 0
  );
}
