import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import type { AffiliatedCompanyPersonCreateParameter } from '@front/src/features/affiliated-company/features/person/types/parameter';
import { affiliatedCompanyPersonApi } from '@front/src/features/affiliated-company/features/person/query/api';

export const affiliatedCompanyPersonMutation = {
  usePersonCreate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyPersonCreateParameter) =>
        affiliatedCompanyPersonApi.createPerson(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['affiliated-company', 'person']);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
