import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useProjectSalesInfoSubjectReviewModalSpecialWindLogic } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/special-wind/widgets/useLogic';
import { ProjectSalesInfoSubjectReviewModalSpecialWindContext } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/special-wind/widgets/context';

export { Provider as ProjectSalesInfoSubjectReviewModalSpecialWindProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { onUpdate, detail, isLoading } = useProjectSalesInfoSubjectReviewModalSpecialWindLogic();
  const value = useMemo(
    () => ({
      onUpdate,
      detail,
      isLoading,
    }),
    [onUpdate, detail, isLoading]
  );
  return (
    <ProjectSalesInfoSubjectReviewModalSpecialWindContext.Provider value={value}>
      {children}
    </ProjectSalesInfoSubjectReviewModalSpecialWindContext.Provider>
  );
};
