import React from 'react';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import {
  statusOptionList,
  workCategoryOptionList,
} from '@front/src/features/mobile-notification/constants/constant';
import classes from '@front/src/features/mobile-notification/widgets/header/components/mobile-notification-selector-group.module.scss';
import { useMobileNotificationState } from '@front/src/features/mobile-notification/useState';

export const MobileNotificationSelectorGroup = () => {
  const setParameter = useMobileNotificationState((state) => state.setParameter);
  const methods = useForm({
    defaultValues: {
      type: '',
      status: '',
    },
  });
  const onSubmit = methods.handleSubmit((data) => {
    setParameter(data);
  });
  return (
    <FormProvider {...methods}>
      <div className={classes.container}>
        <HookFormSelect
          name="type"
          optionList={workCategoryOptionList}
          width="100%"
          defaultLabel="업무(전체)"
          isDefaultPossible={true}
          style={{ minWidth: '60px' }}
          onSubmit={onSubmit}
        />
        <HookFormSelect
          name="status"
          optionList={statusOptionList}
          width="100%"
          defaultLabel="상태(전체)"
          isDefaultPossible={true}
          style={{ minWidth: '60px' }}
          onSubmit={onSubmit}
        />
      </div>
    </FormProvider>
  );
};
