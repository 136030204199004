// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BGVKq405_twQCQeiRsfl{display:flex;justify-content:center;gap:10px;width:300px;margin-top:20px}.o0quu4eyffi0ranFCOva{width:500px}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/collection/features/contract-earnings/features/modal/components/contract-earnings-modal.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,QAAA,CACA,WAAA,CACA,eAAA,CAGF,sBACE,WAAA","sourcesContent":[".btn__container {\n  display: flex;\n  justify-content: center;\n  gap: 10px;\n  width: 300px;\n  margin-top: 20px;\n}\n\n.input__container {\n  width: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn__container": `BGVKq405_twQCQeiRsfl`,
	"input__container": `o0quu4eyffi0ranFCOva`
};
export default ___CSS_LOADER_EXPORT___;
