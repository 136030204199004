import React, { memo } from 'react';
import UserSelector from '@front/components/UserSelector';
import type { UserVO } from '@front/user/domain';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';

import type { CampaignUpdateManagerParameter } from '@front/ost_campaign/parameter';

interface Props {
  manager?: UserVO;
  updateManager: (params: CampaignUpdateManagerParameter) => void;
}

const Manager = ({ manager }: Props) => (
  // const onChange = useCallback(
  //   (value) => {
  //     if (manager?.id === value) {
  //       return;
  //     }
  //     if (!value) {
  //       updateManager({
  //         managerId: undefined,
  //       });
  //       return;
  //     }
  //     updateManager({
  //       managerId: value as UserId,
  //     });
  //   },
  //   [manager, updateManager]
  // );

  <DataFieldWithLabel
    label="캠페인 담당자"
    labelSX={{ minWidth: '80px' }}
  >
    <UserSelector
      readOnly={true}
      value={manager?.id}
      onChange={() => {}}
    />
  </DataFieldWithLabel>
);
const ManagerForm = memo(Manager);

export default ManagerForm;
