import { create } from 'zustand';

export { useState as useAffiliatedCompanyPersonDetailModalOutlineEducationState };

interface State {
  idList: number[];
  setIdList: (idList: number[]) => void;
  id?: number;
  setId: (id?: number) => void;
}

const useState = create<State>((set) => ({
  idList: [],
  setIdList: (idList) =>
    set(() => ({
      idList,
    })),
  setId: (id) =>
    set(() => ({
      id,
    })),
}));
