import { default as axios } from '@front/src/config/axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';

const url = {
  getDetail: (id?) => `${projectSalesInfoURL}/${id}/aspect-ratio-examination/complex-experiment`,
};

export const projectSalesInfoSubjectReviewExperimentApi = {
  getDetail: async (id?: number) => {
    const { data } = await axios.get(url.getDetail(id));
    return data;
  },
};
