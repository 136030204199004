export const drawerTableHeaderNameList = [
  {
    label: '코드',
    width: '76px',
  },
  {
    label: '이름',
    width: '64px',
  },
  {
    label: '소속회사',
    width: '168px',
  },
];
