import React, { useMemo } from 'react';
import { useProjectSalesInfoAffiliatedCompanyWebHardUpdateModalLogic } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/widgets/useLogic';
import { ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/widgets/context';
import { ProjectSalesInfoAffiliatedCompanyWebHardUpdateModal } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/components/modal';
import { Backdrop, CircularProgress } from '@mui/material';

export { Widget as ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalWidget };
const Widget = () => {
  const {
    d: { companyList, webHardList, detail },
    h: { onUpdate },
    isLoading,
    isWebHardListLoading,
  } = useProjectSalesInfoAffiliatedCompanyWebHardUpdateModalLogic();
  const value = useMemo(
    () => ({
      d: { companyList, webHardList, detail },
      h: { onUpdate },
      isWebHardListLoading,
    }),
    [companyList, webHardList, onUpdate, detail]
  );
  if (isLoading) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return (
    <ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalContext.Provider value={value}>
      <ProjectSalesInfoAffiliatedCompanyWebHardUpdateModal />
    </ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalContext.Provider>
  );
};
