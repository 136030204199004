import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import React from 'react';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { convertToOptionList } from '@front/src/utils';
import type { WorkScheduleView } from '@front/src/features/work/features/work/features/schedule/types/view';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import Box from '@mui/material/Box';
import { HookFormDatePicker } from '@front/src/components/date-picker-with-hook-form/date-picker-with-hook-form';
import { useFormContext } from 'react-hook-form';

const workScheduleCellComponent = {
  286: ({ ...rest }: CellComponentProps) => <WorkScheduleStatus {...rest} />,
  287: ({ ...rest }: CellComponentProps) => <WorkScheduleCompletionRate {...rest} />,
  288: ({ ...rest }: CellComponentProps) => <WorkScheduleInstructionDate {...rest} />,
  289: ({ ...rest }: CellComponentProps) => <WorkScheduleCompletionDate {...rest} />,
  290: ({ ...rest }: CellComponentProps) => <WorkScheduleNextExecutionDate {...rest} />,
};

interface Props extends CellComponentProps<WorkScheduleView> {}

const WorkScheduleStatus = ({ onSubmit, readOnly, isEditMode }: Readonly<Props>) => {
  const { data: statusList } = adminManageableVariableRepository.useListGet('일정-상태');
  const { getValues } = useFormContext();
  if (!statusList) return <></>;
  if (isEditMode) {
    return (
      <HookFormSelect
        isDefaultPossible
        defaultLabel="선택"
        name="status"
        optionList={convertToOptionList(statusList)}
        height="40px"
        onSubmit={onSubmit}
        readOnly={readOnly}
        style={{
          textAlign: 'left',
        }}
      />
    );
  }
  return getValues('status');
};

const WorkScheduleCompletionRate = ({ onSubmit, readOnly, isEditMode }: Readonly<Props>) => {
  const { getValues } = useFormContext();
  if (isEditMode) {
    return (
      <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <HookFormInput
          width="100%"
          name="completionRate"
          onSubmit={onSubmit}
          readOnly={readOnly}
          inputType={InputType.RATIO}
          placeholder="숫자만 입력"
        />
        <Box>%</Box>
      </Box>
    );
  }
  return getValues('completionRate');
};

const WorkScheduleInstructionDate = ({ onSubmit, readOnly, isEditMode }: Readonly<Props>) => {
  const { getValues } = useFormContext();
  if (isEditMode) {
    return (
      <HookFormDatePicker
        name="instructionDate"
        onSubmit={onSubmit}
        readOnly={readOnly}
      />
    );
  }
  return getValues('instructionDate');
};

const WorkScheduleCompletionDate = ({ onSubmit, readOnly, isEditMode }: Readonly<Props>) => {
  const { getValues } = useFormContext();
  if (isEditMode) {
    return (
      <HookFormDatePicker
        name="completionDate"
        onSubmit={onSubmit}
        readOnly={readOnly}
      />
    );
  }
  return getValues('completionDate');
};

const WorkScheduleNextExecutionDate = ({ onSubmit, readOnly, isEditMode }: Readonly<Props>) => {
  const { getValues } = useFormContext();
  if (isEditMode) {
    return (
      <HookFormDatePicker
        name="nextExecutionDate"
        onSubmit={onSubmit}
        readOnly={readOnly}
      />
    );
  }
  return getValues('nextExecutionDate');
};

export default workScheduleCellComponent;
