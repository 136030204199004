import { createContext } from 'react';
import type { TaxAccountingView } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/types/view';
import type { AccountingSettingsManagementAccountingReadAccountView } from '@front/src/features/accounting/features/settings/features/management-accounting-read/types/view';

export { Context as AccountingSettingsManagementAccountingReadListContext };

interface State {
  list?: AccountingSettingsManagementAccountingReadAccountView[];
  eAccountAndManualList?: TaxAccountingView[];
  tableRowNum: number;
}

const Context = createContext<State>({
  tableRowNum: 20,
});
