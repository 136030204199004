import type { ReactNode } from 'react';
import React, { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { DatePicker } from '@front/src/components/date-picker/date-picker';
import { DATE_FORMAT } from '@front/src/utils';
import type { CalendarPickerView } from '@mui/x-date-pickers';
import { useSnackbar } from '@front/src/features/snackbar';
import { useDebounce } from '@front/src/components/date-picker-with-hook-form/useDebounce';
import dayjs from 'dayjs';
import { YEAR_MONTH_DAY_REGEX } from '@front/src/features/affiliated-company/utils/constant';

export { HookFormDatePicker };

interface Props {
  name: string;
  onChange?: (value) => Promise<void>;
  width?: string;
  format?: string;
  label?: ReactNode;
  views?: readonly CalendarPickerView[];
  inputFormat?: string;
  openTo?: CalendarPickerView;
  regexp?: RegExp;
  onSubmit?: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  onPressChange?: () => void;
  message?: string;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
}

const HookFormDatePicker = ({
  name,
  onSubmit,
  regexp = YEAR_MONTH_DAY_REGEX,
  message = '날짜 형식을 맞춰주세요.',
  format = DATE_FORMAT,
  required,
  onPressChange,
  ...rest
}: Props) => {
  const { show } = useSnackbar();
  const { control } = useFormContext();
  const {
    field: { value, onChange: onHookFormChange },
  } = useController({ name, control, rules: { required: required } });
  const debounce = useDebounce(500);
  const changeHandler = useCallback(
    async (inputValue: string) => {
      onHookFormChange(inputValue);
      if (inputValue !== '' && !inputValue.match(regexp)) {
        show({ message });
        onHookFormChange('');
        return;
      }
      await onSubmit?.();
    },
    [onHookFormChange, onSubmit, regexp, show, message]
  );
  const handleChange = useCallback(
    (date, inputValue: string | undefined) => {
      if (inputValue || date === null) debounce(() => changeHandler(inputValue ?? ''));
      else changeHandler(dayjs(date).format(format));
      onPressChange?.();
    },
    [changeHandler, debounce, format]
  );
  return (
    <DatePicker
      value={value}
      onChange={handleChange}
      {...rest}
    />
  );
};
