import { useQuery } from 'react-query';
import { projectSalesInfoProjectDivisionApi } from '@front/src/features/project-sales-info/features/project-division/query/form/api';
import type { ProjectDivisionView } from '@front/src/features/project-sales-info/features/project-division/types/view';

export const projectSalesInfoProjectDivisionQuery = {
  useProjectDivisionGet: (id: number) => {
    const { data, isLoading } = useQuery<ProjectDivisionView>({
      queryKey: ['project-sales-info', 'project-division', id],
      queryFn: () => projectSalesInfoProjectDivisionApi.getProjectDivision(id),
      enabled: !!id,
    });
    return {
      data,
      isLoading,
    };
  },
};
