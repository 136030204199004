import React, { useCallback, useMemo } from 'react';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import Button from '@front/layouts/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { DrawerSearchForm } from '@front/src/components/layout/drawer-search-form';
import { useAffiliatedPersonDrawerState } from '@front/src/features/affiliated-person/features/drawer/useState';
import { useShallow } from 'zustand/react/shallow';

export { Form as AffiliatedPersonDrawerForm };

const Form = () => {
  const { Layout, ItemBox, Input } = DrawerSearchForm;
  const { setKeyword } = useAffiliatedPersonDrawerState(
    useShallow((state) => ({
      setKeyword: state.setKeyword,
    }))
  );
  const methods = useForm({
    defaultValues: {
      keyword: '',
    },
  });
  const { reset, handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    setKeyword(data.keyword);
  });
  const handleReset = useCallback(async () => {
    reset();
    await onSubmit();
  }, [reset, onSubmit]);
  const sx = useMemo(
    () => ({
      width: '100%',
    }),
    []
  );
  const handleKeyDown = useCallback(
    async (e) => {
      const { isComposing, key, keyCode } = e;
      if (isComposing || keyCode === 229) return;
      if (key !== 'Enter') return;
      await onSubmit();
    },
    [onSubmit]
  );
  return (
    <FormProvider {...methods}>
      <Layout>
        <ItemBox>
          <Input>
            <HookFormInput
              name="keyword"
              placeholder="업체명, 인물이름, 인물핸드폰 검색"
              onKeyDown={handleKeyDown}
            />
          </Input>
        </ItemBox>
        <ItemBox>
          <Button
            onClick={handleReset}
            shape="basic2"
            sx={sx}
          >
            초기화
          </Button>
          <Button
            onClick={onSubmit}
            sx={sx}
          >
            검색
          </Button>
        </ItemBox>
      </Layout>
    </FormProvider>
  );
};
