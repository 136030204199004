import type { ProjectEstimationView } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/types/view';
import { useQuery } from 'react-query';
import { projectSalesInfoBidInfoCreateModalApi } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/query/api';

export const projectSalesInfoBidInfoCreateModalQuery = {
  useEstimateIdListGet: (id: number) => {
    const { data, isLoading } = useQuery<ProjectEstimationView[]>({
      queryKey: ["project-sales-info', 'bid-Info', 'create-modal-query", id],
      queryFn: () => projectSalesInfoBidInfoCreateModalApi.getEstimateId(id),
    });
    return { data, isLoading };
  },
};
