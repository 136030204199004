import React, { useCallback, useMemo } from 'react';
import { ControlPanel } from '@front/src/components/control-panel/control-panel';
import { useShallow } from 'zustand/react/shallow';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EditIcon from '@mui/icons-material/Edit';
import PushPinIcon from '@mui/icons-material/PushPin';
import { useController, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { usePersonalSettingsModalUISettingsHeaderSettingsHistoryState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/history/useState';
import { usePersonalSettingsModalUISettingsHeaderSettingsPositionState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/useState';
import HistoryIcon from '@mui/icons-material/History';
import LinkIcon from '@mui/icons-material/Link';
import { openNewTabFromUrl } from '@front/src/utils';
import { personalSettingsModalUISettingsHeaderSettingsRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/repository/repository';
import { usePersonalSettingsModalUISettingsHeaderSettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/useState';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export { RowControlPanel as PersonalSettingsModalUISettingsHeaderSettingsRowControlPanel };

interface Props {
  index: number;
  authMenuId: number | undefined;
}

const RowControlPanel = ({ index, authMenuId }: Readonly<Props>) => {
  const { data: url } =
    personalSettingsModalUISettingsHeaderSettingsRepository.useGetLink(authMenuId);
  const { setHeader: setHistoryHeader } =
    usePersonalSettingsModalUISettingsHeaderSettingsHistoryState(
      useShallow((state) => ({
        setHeader: state.setHeader,
      }))
    );
  const { setHeader: setPositionHeader } =
    usePersonalSettingsModalUISettingsHeaderSettingsPositionState(
      useShallow((state) => ({
        setHeader: state.setHeader,
      }))
    );
  const { addEditOpenId, editOpenIdList } = usePersonalSettingsModalUISettingsHeaderSettingsState(
    useShallow((state) => ({
      addEditOpenId: state.addEditOpenId,
      editOpenIdList: state.editOpenIdList,
    }))
  );
  const { control, watch, getValues } = useFormContext();
  const { fields, swap, update } = useFieldArray({
    name: 'list',
    control,
  });
  const { seq, isFixed, headerId, currentName, id } = useWatch({
    name: `list.${index}`,
    control,
  });
  const isEditOpen = useMemo(() => editOpenIdList.includes(id), [editOpenIdList, id]);
  const handleEditOpen = useCallback(() => {
    addEditOpenId(id);
  }, [addEditOpenId, id]);
  const handleSeqDown = useCallback(() => {
    if (index + 1 >= getValues('list').length) return;
    swap(index, index + 1);
  }, [swap, index, seq]);
  const handleSeqUp = useCallback(() => {
    if (index - 1 < 0) return;
    swap(index, index - 1);
  }, [swap, index, seq, getValues]);
  const {
    field: { onChange: onIsFixedChange },
  } = useController({ control, name: `list.${index}.isFixed` });
  const handleIsFixed = useCallback(() => {
    if (isFixed) {
      onIsFixedChange(false);
      return;
    }
    fields.forEach((_, i) => {
      const target = watch(`list.${i}`);
      if (target.isFixed) {
        update(i, {
          ...target,
          isFixed: false,
        });
      }
    });
    onIsFixedChange(true);
  }, [onIsFixedChange, fields, update, isFixed, watch]);
  const handleHistoryClick = useCallback(() => {
    setPositionHeader(undefined);
    setHistoryHeader({
      id: headerId,
      name: currentName,
    });
  }, [setHistoryHeader, setPositionHeader, headerId, currentName]);
  const handlePositionClick = useCallback(() => {
    setHistoryHeader(undefined);
    setPositionHeader({
      id: headerId,
      name: currentName,
    });
  }, [setPositionHeader, headerId, currentName, setHistoryHeader]);
  const handleOpenNewTab = useCallback(() => {
    if (url === undefined) return;
    openNewTabFromUrl(url);
  }, [url]);
  const itemList = useMemo(
    () => [
      {
        text: isEditOpen ? '수정 닫기' : '수정',
        icon: EditIcon,
        action: handleEditOpen,
      },
      {
        text: '이동(위)',
        icon: ArrowUpwardIcon,
        action: handleSeqUp,
      },
      {
        text: '이동(아래)',
        icon: ArrowDownwardIcon,
        action: handleSeqDown,
      },
      {
        text: isFixed ? '틀고정 해제' : '틀고정',
        icon: PushPinIcon,
        action: handleIsFixed,
      },
      {
        text: '변경 이력',
        icon: HistoryIcon,
        action: handleHistoryClick,
      },
      {
        text: '사용 위치',
        icon: LocationOnIcon,
        action: handlePositionClick,
      },
      {
        text: '실제 페이지 새탭으로 열기',
        icon: LinkIcon,
        action: handleOpenNewTab,
      },
    ],
    [
      isEditOpen,
      handleEditOpen,
      handleSeqDown,
      handleSeqUp,
      handleIsFixed,
      handleHistoryClick,
      handlePositionClick,
      handleOpenNewTab,
      isFixed,
    ]
  );
  return <ControlPanel itemList={itemList} />;
};
