import type { FieldValues } from 'react-hook-form';
import type { SalesAccountingAmountInformationView } from '@front/src/features/accounting/features/upload/features/sales-info/types/view';
import type { SalesAccountingAmountInformation } from '@front/src/features/accounting/features/upload/features/sales-info/types/parameter';
import {
  SalesAccountingAmountSubType,
  SalesAccountingAmountType,
} from '@front/src/features/accounting/features/upload/features/sales-info/types/domain';
import { getTypeForAccountingUploadSalesInfoFormByList } from '@front/src/features/accounting/features/upload/features/sales-info/features/utils';
import { NOT_INTEGER_REGEX } from '@front/src/features/affiliated-company/utils/constant';

interface InfoType {
  total: string;
  average: string;
  amountMonth1: string;
  amountMonth2: string;
  amountMonth3: string;
  amountMonth4: string;
  amountMonth5: string;
  amountMonth6: string;
  amountMonth7: string;
  amountMonth8: string;
  amountMonth9: string;
  amountMonth10: string;
  amountMonth11: string;
  amountMonth12: string;
}

const getInfoMonth = (list: SalesAccountingAmountInformationView[]) => {
  const result: InfoType = {
    total: '-',
    average: '-',
    amountMonth1: '',
    amountMonth2: '',
    amountMonth3: '',
    amountMonth4: '',
    amountMonth5: '',
    amountMonth6: '',
    amountMonth7: '',
    amountMonth8: '',
    amountMonth9: '',
    amountMonth10: '',
    amountMonth11: '',
    amountMonth12: '',
  };
  let total = 0;
  let count = 0;
  for (let month = 1; month < 13; month += 1) {
    const find = list?.find((o) => o.month === month);
    if (!find) {
      continue;
    }
    count += 1;
    const amount = find.amount;
    total += amount;
    result[`amountMonth${month}`] = amount.toLocaleString();
  }
  if (count !== 0) {
    result['total'] = total.toLocaleString();
    result['average'] = (total / count).toLocaleString();
  }
  return result;
};

export const getValuesForAccountingUploadSalesInfoGoalModalForm = (
  list?: SalesAccountingAmountInformationView[]
) => {
  if (!list)
    return {
      info: [],
    };
  const info: InfoType[] = [];
  const orderList = list.filter((l) => l.type === SalesAccountingAmountType.ORDER);
  const reviewList = list.filter((l) => l.type === SalesAccountingAmountType.REVIEW);
  const netOrderList = list.filter((l) => l.type === SalesAccountingAmountType.NET_ORDER);
  const collectionList = list.filter((l) => l.type === SalesAccountingAmountType.COLLECTION);
  const savedReviewList = list.filter((l) => l.type === SalesAccountingAmountType.SAVED_REVIEW);
  const netCollectionList = list.filter((l) => l.type === SalesAccountingAmountType.NET_COLLECTION);
  const netOrderBalanceList = list.filter(
    (l) => l.type === SalesAccountingAmountType.NET_ORDER_BALANCE
  );
  info.push(getInfoMonth(orderList));
  info.push(getInfoMonth(reviewList));
  info.push(getInfoMonth(netOrderList));
  info.push(getInfoMonth(collectionList));
  info.push(getInfoMonth(savedReviewList));
  info.push(getInfoMonth(netCollectionList));
  info.push(getInfoMonth(netOrderBalanceList));
  return {
    info,
  };
};

export const convertAccountingUploadSalesInfoGoalUpdateParameterFromGoalModalForm = (
  year,
  data: FieldValues,
  list?: SalesAccountingAmountInformationView[]
) => {
  const { info } = data;
  const result: SalesAccountingAmountInformation[] = [];
  info.forEach((item, index) => {
    for (let month = 1; month < 13; month += 1) {
      const itemAmountValue = item[`amountMonth${month}`];
      if (itemAmountValue === '') {
        continue;
      }
      const amount = +itemAmountValue?.replace(NOT_INTEGER_REGEX, '');
      result.push({
        id: list?.find(
          (l) =>
            l.type === getTypeForAccountingUploadSalesInfoFormByList[index] &&
            l.year === year &&
            l.month === month
        )?.id,
        amount,
        type: getTypeForAccountingUploadSalesInfoFormByList[index],
        subType: SalesAccountingAmountSubType.TARGET,
        year,
        month,
      });
    }
  });
  return { list: result };
};
