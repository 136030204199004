import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { ProjectSalesInfoCMPaymentHistoryContext } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/widgets/context';
import { useProjectSalesInfoCMPaymentHistoryState } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/widgets/useState';
import { shallow } from 'zustand/shallow';
import { handleDeleteIdListConfirmAfterClick } from '@front/src/utils';
import { useCustomDialog } from '@front/src/features/dialog';
import { useShallow } from 'zustand/react/shallow';

export { CmPaymentHistoryBtn as ProjectSalesInfoCMPaymentHistoryBtn };

const CmPaymentHistoryBtn = () => {
  const {
    h: { onDelete },
  } = useContext(ProjectSalesInfoCMPaymentHistoryContext);
  const { setIsCreateModalOpen, idList, setIdList } = useProjectSalesInfoCMPaymentHistoryState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
      setIsCreateModalOpen: state.setIsCreateModalOpen,
    }),
    shallow
  );
  const { confirm } = useCustomDialog();
  const onDeleteClick = useCallback(
    () =>
      handleDeleteIdListConfirmAfterClick(idList, confirm, () => {
        onDelete({ idList });
        setIdList([]);
      }),
    [onDelete, idList, setIdList, confirm]
  );
  const { readOnly } = useProjectSalesInfoCMPaymentHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      <Button
        onClick={onDeleteClick}
        disabled={readOnly}
      >
        선택삭제
      </Button>
      <Button
        onClick={() => setIsCreateModalOpen()}
        disabled={readOnly}
      >
        이력 추가
      </Button>
    </>
  );
};
