import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import ApprovalBoxForm from '@front/src/features/approval-box/components/ApprovalBoxForm';
import { approvalBoxQuery } from '@front/src/features/approval-box/query/query';
import BadgeUI from '@front/src/components/components-with-design/component/BadgeUI';
import useApprovalBoxStore from '@front/src/features/approval-box/useState';
import { APPROVAL_BOX_TYPE } from '@front/type/domain';
import { useShallow } from 'zustand/react/shallow';
import ApprovalBoxDetailWrapper from '@front/src/features/approval-box/components/ApprovalBoxDetailWrapper';
import ApprovalBoxDetailForm from '@front/src/features/approval-box/components/ApprovalBoxDetailForm';

export default function ApprovalBoxContent() {
  const setLeftDrawerState = useGlobalNavBarState((state) => state.setLeftDrawerState);
  const { setBoxSearchParams, boxSearchParams, isBoxList, setIsBoxList } = useApprovalBoxStore(
    useShallow((state) => ({
      setBoxSearchParams: state.setBoxSearchParams,
      boxSearchParams: state.boxSearchParams,
      isBoxList: state.isBoxList,
      setIsBoxList: state.setIsBoxList,
    }))
  );

  const onClose = () => {
    setLeftDrawerState('');
    setIsBoxList(true);
  };

  const onBoxClick = (boxType: APPROVAL_BOX_TYPE) => {
    setBoxSearchParams({
      type: boxType.toLowerCase(),
      filter: boxType === APPROVAL_BOX_TYPE.APPROVAL ? 'waiting' : 'on-going',
    });
    setIsBoxList(true);
  };

  const { data: count } = approvalBoxQuery.useGetApprovalBoxCount();

  useEffect(() => {
    if (count?.approvalDocumentCount) {
      setBoxSearchParams({ type: APPROVAL_BOX_TYPE.APPROVAL_DOCUMENT.toLowerCase() });
      return;
    }
    if (count?.approvalCount) {
      setBoxSearchParams({ type: APPROVAL_BOX_TYPE.APPROVAL.toLowerCase() });
      return;
    }
    if (count?.referenceCount) {
      setBoxSearchParams({ type: APPROVAL_BOX_TYPE.REFERENCE.toLowerCase() });
      return;
    }
  }, [count]);

  return (
    <Box sx={{ ...SX_MODAL }}>
      <Box sx={{ ...SX_HEADER }}>
        <Typography sx={{ ...SX_HEADER_TITLE }}>결재</Typography>
        <Box
          display="flex"
          gap="5px"
          sx={{ ...SX_HEADER_BUTTON_WRAP }}
        >
          <ButtonBasicUI
            className={
              boxSearchParams.type === APPROVAL_BOX_TYPE.APPROVAL_DOCUMENT.toLowerCase()
                ? 'active'
                : ''
            }
            onClick={() => onBoxClick(APPROVAL_BOX_TYPE.APPROVAL_DOCUMENT)}
          >
            <Typography>상신함</Typography>
            <BadgeUI badgeContent={count?.approvalDocumentCount}>
              <Box>{}</Box>
            </BadgeUI>
          </ButtonBasicUI>
          <ButtonBasicUI
            className={
              boxSearchParams.type === APPROVAL_BOX_TYPE.APPROVAL.toLowerCase() ? 'active' : ''
            }
            onClick={() => onBoxClick(APPROVAL_BOX_TYPE.APPROVAL)}
          >
            <Typography>수신함</Typography>
            <BadgeUI badgeContent={count?.approvalCount}>
              <Box>{}</Box>
            </BadgeUI>
          </ButtonBasicUI>
          <ButtonBasicUI
            className={
              boxSearchParams.type === APPROVAL_BOX_TYPE.REFERENCE.toLowerCase() ? 'active' : ''
            }
            onClick={() => onBoxClick(APPROVAL_BOX_TYPE.REFERENCE)}
          >
            <Typography>참조함</Typography>
            <BadgeUI badgeContent={count?.referenceCount}>
              <Box>{}</Box>
            </BadgeUI>
          </ButtonBasicUI>
          <CloseIcon
            onClick={onClose}
            sx={{ ...SX_CLOSE }}
          />
        </Box>
      </Box>
      <Box sx={{ ...SX_BODY }}>
        {isBoxList ? (
          <Box
            sx={{
              height: '100%',
              padding: '14px',
              background: 'var(--design-v-10-light-background-bg-03, #D8DCE2)',
            }}
          >
            <ApprovalBoxForm />
          </Box>
        ) : (
          <Box>
            <Box
              sx={{
                padding: '14px',
                marginBottom: '10px',
                background: 'var(--design-v-10-light-background-bg-03, #D8DCE2)',
              }}
            >
              <ApprovalBoxDetailForm />
            </Box>
            <Box
              sx={{
                padding: '14px',
                backgroundColor: 'white',
              }}
            >
              <ApprovalBoxDetailWrapper />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

const SX_MODAL = {
  display: 'flex',
  flexDirection: 'column',
  background: '#ffffff',
  boxShadow: '20px 0px 10px rgba(0,0,0,0.15)',
  overflow: 'hidden',
};

const SX_HEADER = {
  display: 'flex',
  height: '42px',
  padding: '0px 14px',
  alignItems: 'center',
  gap: '10px',
  alignSelf: 'stretch',
  color: 'white',
  position: 'relative',
  background: 'var(--design-v-10-light-background-bg-04, #1F314E)',
};

const SX_BODY = {
  height: 'calc(100dvh - 42px)',
  overflow: 'auto',
  padding: 0,
  background: '#ffffff',
};

const SX_HEADER_TITLE = {
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  '& > span': {
    color: 'var(--design-v-10-light-sub-sub-primary, #FFB611)',
    borderLeft: '1px solid #B0B5BE',
    paddingLeft: '10px',
    marginLeft: '10px',
  },
};

const SX_HEADER_BUTTON_WRAP = {
  position: 'absolute',
  right: 0,
  alignItems: 'center',
  gap: '10px',
  '& .MuiButtonBase-root': {
    minHeight: '26px',
    minWidth: '86px',
    padding: '4px 10px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    background: 'var(--design-v-10-light-main-main-secondary, #77A0E5)',
  },
  '& .active': {
    background: 'var(--design-v-10-light-main-main-primary, #2F6ACA)',
  },
  '& .MuiBadge-badge': {
    right: '-7px',
  },
};

const SX_CLOSE = {
  cursor: 'pointer',
  width: '18px',
  height: '18px',
  marginRight: '14px',
};
