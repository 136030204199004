import { create } from 'zustand';
import type { AffiliatedCompanySalesActivityConvertedView } from '@front/src/features/affiliated-company/features/sales-activity/types/view';

export { useState as useAffiliatedCompanySalesActivityState };

interface State {
  list: AffiliatedCompanySalesActivityConvertedView[];
  readOnly?: boolean;
}

interface Actions {
  setList: (list: AffiliatedCompanySalesActivityConvertedView[]) => void;
  setReadOnly: (readOnly?: boolean) => void;
}

const useState = create<State & Actions>((set) => ({
  list: [],
  setList: (list) => set(() => ({ list })),
  readOnly: false,
  setReadOnly: (readOnly) => set(() => ({ readOnly })),
}));
