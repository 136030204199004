import type {
  ProceedingsAccessVO,
  ProceedingsId,
  ProceedingsMemoVO,
  ProceedingsShortVO,
  ProceedingsVO,
} from './domain';
import { createReducer } from 'typesafe-actions';
import type { ProceedingsQuery } from './query';
import { initialProceedingsQuery } from './query';
import { ProceedingsAction } from './action';
import type { DepartmentVO } from '../department/domain';
import type { ApiStatus } from '../components/DataFieldProps';

export interface ProceedingsState {
  id?: ProceedingsId;
  listDrawerOpen: boolean;
  filter: ProceedingsQuery;
  listLoading: boolean;
  list: ProceedingsShortVO[];
  addModalOpen: boolean;
  searchFilterOpen: boolean;
  departmentList: DepartmentVO[];
  detail?: ProceedingsVO;
  requestDelete: ApiStatus;
  attendanceModalOpen: boolean;
  readerModalOpen: boolean;
  memoDrawerOpen: boolean;
  memoList: ProceedingsMemoVO[];
  memoLoading: boolean;
  memoFilter: ProceedingsQuery;
  access?: ProceedingsAccessVO;
  requestAccess: ApiStatus;
  requestAddMemo: ApiStatus;
}

const initial: ProceedingsState = {
  listDrawerOpen: true,
  filter: initialProceedingsQuery,
  listLoading: false,
  list: [],
  addModalOpen: false,
  searchFilterOpen: false,
  departmentList: [],
  requestDelete: 'idle',
  attendanceModalOpen: false,
  readerModalOpen: false,
  memoDrawerOpen: true,
  memoList: [],
  memoFilter: initialProceedingsQuery,
  memoLoading: false,
  requestAccess: 'idle',
  requestAddMemo: 'idle',
};
export const proceedingsReducer = createReducer<ProceedingsState>(initial, {
  [ProceedingsAction.toggleDrawer]: (state, action) => ({
    ...state,
    listDrawerOpen: action.payload,
  }),
  [ProceedingsAction.setAddModal]: (state, action) => ({
    ...state,
    addModalOpen: action.payload,
  }),
  [ProceedingsAction.setId]: (state, action) => ({
    ...state,
    id: action.payload,
  }),
  [ProceedingsAction.setFilter]: (state, action) => ({
    ...state,
    filter: action.payload,
  }),
  [ProceedingsAction.setListLoading]: (state, action) => ({
    ...state,
    listLoading: action.payload,
  }),
  [ProceedingsAction.setList]: (state, action) => ({
    ...state,
    list: action.payload,
  }),
  [ProceedingsAction.searchFilterOpen]: (state, action) => ({
    ...state,
    searchFilterOpen: action.payload,
  }),
  [ProceedingsAction.setDepartmentList]: (state, action) => ({
    ...state,
    departmentList: action.payload,
  }),
  [ProceedingsAction.setDetail]: (state, action) => ({
    ...state,
    detail: action.payload,
  }),
  [ProceedingsAction.addAttendanceModal]: (state, action) => ({
    ...state,
    attendanceModalOpen: action.payload,
  }),
  [ProceedingsAction.addReaderModal]: (state, action) => ({
    ...state,
    readerModalOpen: action.payload,
  }),
  [ProceedingsAction.setMemoDrawerOpen]: (state, action) => ({
    ...state,
    memoDrawerOpen: action.payload,
  }),
  [ProceedingsAction.setMemoList]: (state, action) => ({
    ...state,
    memoList: action.payload,
  }),
  [ProceedingsAction.setMemoFilter]: (state, action) => ({
    ...state,
    memoFilter: action.payload,
  }),
  [ProceedingsAction.setMemoLoading]: (state, action) => ({
    ...state,
    memoLoading: action.payload,
  }),
  [ProceedingsAction.requestDelete]: (state, action) => ({
    ...state,
    requestDelete: action.payload,
  }),
  [ProceedingsAction.setAccess]: (state, action) => ({
    ...state,
    access: action.payload,
  }),
  [ProceedingsAction.requestAccess]: (state, action) => ({
    ...state,
    requestAccess: action.payload,
  }),
  [ProceedingsAction.requestAddMemo]: (state, action) => ({
    ...state,
    requestAddMemo: action.payload,
  }),
});
