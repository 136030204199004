import React, { useCallback } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import type { ProjectCMPaymentHistoryView } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/types/view';
import Checkbox from '@front/layouts/Checkbox';
import { useProjectSalesInfoCMPaymentHistoryState } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/widgets/useState';
import { shallow } from 'zustand/shallow';
import { spliceAndPushIfExist } from '@front/src/utils';
import { ColorPalette } from '@front/assets/theme';
import { useShallow } from 'zustand/react/shallow';

export { CmPaymentHistoryTableRow as ProjectSalesInfoCmPaymentHistoryTableRow };

interface Props {
  item: ProjectCMPaymentHistoryView;
  index: number;
}

const CmPaymentHistoryTableRow = ({ item, index }: Props) => {
  const { setItemId, setIsUpdateModalOpen, idList, setIdList } =
    useProjectSalesInfoCMPaymentHistoryState(
      (state) => ({
        setItemId: state.setItemId,
        setIsUpdateModalOpen: state.setIsUpdateModalOpen,
        idList: state.idList,
        setIdList: state.setIdList,
      }),
      shallow
    );
  const handleCheckboxChange = useCallback(
    (id) => () => {
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  const { readOnly } = useProjectSalesInfoCMPaymentHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const handleRowClick = () => {
    setItemId(item.id);
    setIsUpdateModalOpen();
  };
  return (
    <TableRow sx={{ whiteSpace: 'nowrap' }}>
      <OldTd>
        <Checkbox
          checked={idList.includes(item.id)}
          onChange={handleCheckboxChange(item.id)}
          disabled={readOnly}
        />
      </OldTd>
      <OldTd
        onClick={handleRowClick}
        sx={{ fontWeight: 'bold', color: ColorPalette._386dd6, cursor: 'pointer' }}
      >
        {index + 1}
      </OldTd>
      <OldTd>{item.company?.name}</OldTd>
      <OldTd>{item.paymentRequestDate}</OldTd>
      <OldTd>{item.paymentAmount?.toLocaleString()}</OldTd>
      <OldTd>{item.paymentMethod}</OldTd>
      <OldTd>{item.paymentType}</OldTd>
      <OldTd>{item.paymentDate}</OldTd>
      <OldTd>
        <div
          style={{
            maxWidth: '300px',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
          }}
        >
          {item.note ?? '-'}
        </div>
      </OldTd>
    </TableRow>
  );
};
