import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type {
  WorkBasicInfoParams,
  WorkBasicSearchWordParams,
} from '@front/src/features/work/features/work/features/basic-info/types/parameter';

const workBasicInfoMutation = {
  useUpdate: (workId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: WorkBasicInfoParams) =>
        workWorkApi.updateBasicInfo(params, workId, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'basic'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'drawer'],
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useSearchWordCreate: (workId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: WorkBasicSearchWordParams) =>
        workWorkApi.createSearchWord(params, workId, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'basic'],
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useSearchWordDelete: (workId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: WorkBasicSearchWordParams) =>
        workWorkApi.deleteSearchWord(params, workId, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'basic'],
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
};

export default workBasicInfoMutation;
