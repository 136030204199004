import React, { useEffect, useMemo } from 'react';
import { ProjectSalesInfoCollectionSaveHistoryContext } from '@front/src/features/project-sales-info/features/collection/features/save-history/widgets/context';
import { useProjectSalesInfoCollectionSaveHistoryLogic } from '@front/src/features/project-sales-info/features/collection/features/save-history/widgets/useLogic';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoCollectionSaveHistoryTable } from '@front/src/features/project-sales-info/features/collection/features/save-history/components/save-history-table';
import { ProjectSalesInfoCollectionHelp } from '@front/src/features/project-sales-info/features/collection/components/help';
import { useProjectSalesInfoCollectionSaveHistoryState } from '@front/src/features/project-sales-info/features/collection/features/save-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoCollectionSaveHistoryWidget = ({ readOnly, name }: Readonly<Props>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    h: { onUpdate },
    d: { saveHistoryList },
    isLoading,
  } = useProjectSalesInfoCollectionSaveHistoryLogic();
  const value = useMemo(
    () => ({
      h: { onUpdate },
      d: { saveHistoryList },
      isLoading,
    }),
    [onUpdate, saveHistoryList, isLoading]
  );
  const { setReadOnly } = useProjectSalesInfoCollectionSaveHistoryState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <ProjectSalesInfoCollectionSaveHistoryContext.Provider value={value}>
      <Layout>
        <Header title={name}>
          <ProjectSalesInfoCollectionHelp />
        </Header>
        <Body>
          <ProjectSalesInfoCollectionSaveHistoryTable />
        </Body>
      </Layout>
    </ProjectSalesInfoCollectionSaveHistoryContext.Provider>
  );
};

export default ProjectSalesInfoCollectionSaveHistoryWidget;
