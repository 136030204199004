import React, { useCallback, useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAffiliatedPersonDetailState } from '@front/src/features/affiliated-person/features/detail/useState';
import { useShallow } from 'zustand/react/shallow';
import Tooltip from '@front/components/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AffiliatedPersonMemoDeleteContext } from '@front/src/features/affiliated-person/features/memo/widgets/delete/provider/context';
import { useCustomDialog } from '@front/src/features/dialog';
import { ColorPalette } from '@front/assets/theme';

export { DeleteButton as AffiliatedPersonMemoDeleteButton };

const DeleteButton = () => {
  const { onDelete } = useContext(AffiliatedPersonMemoDeleteContext);
  const { id: personId } = useAffiliatedPersonDetailState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const { handleSubmit } = useFormContext();
  const onSubmit = handleSubmit((data) => {
    onDelete({
      personId,
      memoId: data.memoId,
    });
  });
  const { confirm } = useCustomDialog();
  const handleIconClick = useCallback(() => {
    confirm({
      confirmText: '확인',
      children: '정말로 삭제하시겠습니까?',
      afterConfirm: () => {
        onSubmit();
      },
    });
  }, [onSubmit, confirm]);
  const iconStyle = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      color: ColorPalette._9bb6ea,
      fontSize: '11px',
      cursor: 'pointer',
    }),
    []
  );
  return (
    <Tooltip title="삭제">
      <FontAwesomeIcon
        icon="trash"
        style={iconStyle}
        onClick={handleIconClick}
      />
    </Tooltip>
  );
};
