import { create } from 'zustand';
import { PersonTabType } from '@front/src/features/affiliated-company/types/domain';

export { useState as useAffiliatedPersonDetailState };

interface State {
  id?: number;
  setId: (id?: number) => void;
  tab: string;
  setTab: (tab: string) => void;
}

const useState = create<State>((set) => ({
  setId: (id) =>
    set(() => ({
      id,
    })),
  tab: PersonTabType.OUTLINE,
  setTab: (tab) =>
    set(() => ({
      tab,
    })),
}));
