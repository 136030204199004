import React from 'react';
import { Box } from '@mui/material';
import Button from '@front/layouts/Button';

interface Props {
  onOpen: () => void;
}

export { Head as ApprovalHead };
const Head = ({ onOpen }: Props) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      padding: '0 10px',
    }}
  >
    <Button
      shape="basic1"
      onClick={onOpen}
    >
      결재창
    </Button>
  </Box>
);
