import React from 'react';
import type { Control } from 'react-hook-form';
import { useController } from 'react-hook-form';
import type { UserVO } from '@front/user/domain';

import UserSelectorCommonInput from '@front/src/features/user-selector/features/input/components/CommonInput';
import AvatarUI from '@front/src/components/components-with-design/component/AvatarUI';

interface Props {
  onOpen: () => void;
  control: Control<{ inputList: (UserVO | undefined)[] }, any>;
  onChange: (value: number | number[] | undefined) => void;
}

export default function UserSelectorDefaultInput({ onOpen, control, onChange }: Readonly<Props>) {
  const {
    field: { value: inputList },
  } = useController({
    control,
    name: 'inputList',
  });
  const handleIconClick = (e) => {
    e.stopPropagation();
    onOpen();
  };
  const endAdornment = (
    <AvatarUI
      size="small"
      onClick={handleIconClick}
    />
  );
  const value = inputList?.[0]?.id;
  const onClick = () => {
    if (!value) {
      onOpen();
      return;
    }
    onChange(undefined);
  };
  return (
    <UserSelectorCommonInput
      onClick={onClick}
      value={value}
      endAdornment={endAdornment}
      sx={{
        cursor: 'pointer',
      }}
    />
  );
}
