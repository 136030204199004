import React, { useContext } from 'react';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { ProjectSalesInfoContractContractContext } from '@front/src/features/project-sales-info/features/contract/features/contract/widgets/context';
import { ProjectSalesInfoContractContractTableRow } from '@front/src/features/project-sales-info/features/contract/features/contract/components/contract-table-row';

export { ContractTableBody as ProjectSalesInfoContractContractTableBody };

const ContractTableBody = () => {
  const {
    d: { list },
  } = useContext(ProjectSalesInfoContractContractContext);
  if (list?.length === 0) {
    return (
      <NoResult
        height="52px"
        colSpan={12}
      />
    );
  }
  return (
    <>
      {list?.map((item) => (
        <ProjectSalesInfoContractContractTableRow
          key={item.id}
          item={item}
        />
      ))}
    </>
  );
};
