import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { AffiliatedCompanyWebHardView } from '@front/src/features/affiliated-company/features/outline/features/web-hard/types/view';
import type { ProjectSalesInfoAffiliatedCompanyWebHardParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/types/parameter';
import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/types/view';
import type { ProjectSalesInfoAffiliatedCompanyWebHardDetailView } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/types/view';

export { Context as ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalContext };

interface State {
  d: {
    companyList?: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyView[];
    webHardList?: AffiliatedCompanyWebHardView[];
    detail?: ProjectSalesInfoAffiliatedCompanyWebHardDetailView;
  };
  h: {
    onUpdate: (params: ProjectSalesInfoAffiliatedCompanyWebHardParameter) => void;
  };
  isWebHardListLoading: boolean;
}

const Context = createContext<State>({
  d: {
    companyList: [],
    webHardList: [],
  },
  h: {
    onUpdate: noOp,
  },
  isWebHardListLoading: false,
});
