import type { ReactNode } from 'react';
import React from 'react';
import classes from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/layout/layout.module.scss';

export { Layout as ProjectSalesInfoAffiliatedCompanyPersonCreateModalLayoutLayout };

interface Props {
  children: ReactNode;
}

const Layout = ({ children }: Props) => <div className={classes.container}>{children}</div>;
