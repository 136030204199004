import React, { ReactNode } from 'react';
import ResizableListDrawer from '@front/src/components/list-drawer/components/resize-list-drawer';

interface Props {
  children: ReactNode;
  leftDrawerState: boolean;
}

const ResizableLeftDrawer = ({ children, leftDrawerState }: Props) => {
  return (
    <ResizableListDrawer
      isOpen={leftDrawerState}
      anchor="left"
      overlay={true}
    >
      {children}
    </ResizableListDrawer>
  );
};

export default ResizableLeftDrawer;
