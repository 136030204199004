import React from 'react';
import workApprovalQuery from '@front/src/features/work/features/work/features/approval/query/query';
import UIBuilderTableBody, {
  type UIBuilderTableBodyProps,
} from '@front/src/components/ui-builder/table/TableBody';
import WorkApprovalTableRow from '@front/src/features/work/features/work/features/approval/components/TableRow';
import { FormProvider, useForm } from 'react-hook-form';
import type { WorkApprovalDocumentView } from '@front/src/features/work/features/work/features/approval/types/view';

export default function WorkApprovalTableBody({
  sectionId,
  dataId,
  menuId,
  ...rest
}: Readonly<UIBuilderTableBodyProps>) {
  const { data } = workApprovalQuery.useGetList({
    dataId,
    menuId,
    sectionId,
  });
  const methods = useForm({
    values: {
      list: data?.approvalDocumentList ?? [],
      workId: undefined,
    },
  });
  const { getValues } = methods;
  return (
    <FormProvider {...methods}>
      <UIBuilderTableBody<WorkApprovalDocumentView>
        list={getValues('list')}
        RowComponent={WorkApprovalTableRow}
        sectionId={sectionId}
        dataId={dataId}
        menuId={menuId}
        {...rest}
      />
    </FormProvider>
  );
}
