import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/types/view';
import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/types/parameter';
import type { OptionType } from '@front/src/components/Select';
import type { ProjectAffiliatedCompanyPersonView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/types/view';

export { Context as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalContext };

interface State {
  d: {
    list?: ProjectAffiliatedCompanyPersonView[];
    detail?: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalView;
    affiliatedCompanyOptionList?: OptionType[];
  };
  h: {
    onUpdate: (params: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalParameter) => void;
    onCreate: (params: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalParameter) => void;
  };
}

const Context = createContext<State>({
  d: {},
  h: {
    onUpdate: noOp,
    onCreate: noOp,
  },
});
