import type { ProjectStepInformationView } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/type/view';
import {
  convertManagerIdToNull
} from '@front/src/features/project-sales-info/features/project-division/utils/constants';
import {formatDateOrNull} from "@front/src/features/project-sales-info/utils";

export const spliceAndPushIfExist = (idList: number[], id: number) => {
  const index = idList.findIndex((item) => item === id);
  const updatedIdList = [...idList];
  if (index !== -1) {
    updatedIdList.splice(index, 1);
  } else {
    updatedIdList.push(id);
  }
  return updatedIdList;
};

export const changeModalViewToParams = (view: ProjectStepInformationView) => ({
  id: view.id,
  date: formatDateOrNull(view.date),
  expectedWorkStartDate: formatDateOrNull(view.expectedWorkStartDate),
  businessStatusStep: view.businessStatusStep || null,
  salesManagerId: convertManagerIdToNull(view.salesManager),
  affiliatedCompanyManagerId: convertManagerIdToNull(view.affiliatedCompanyManager),
  note: view.note || null,
});
