import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { AccountingUploadSalesInfoContext } from '@front/src/features/accounting/features/upload/features/sales-info/provider/context';
import { useShallow } from 'zustand/react/shallow';
import { useAccountingUploadSalesInfoState } from '@front/src/features/accounting/features/upload/features/sales-info/useState';
import { useAccountingUploadSalesInfoPerformanceModalState } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/useState';

export { PerformanceButton as AccountingUploadSalesInfoPerformanceButton };

const PerformanceButton = () => {
  const { readOnly } = useContext(AccountingUploadSalesInfoContext);
  const { year } = useAccountingUploadSalesInfoState(
    useShallow((state) => ({
      year: state.year,
    }))
  );
  const { setIsOpen } = useAccountingUploadSalesInfoPerformanceModalState(
    useShallow((state) => ({
      setIsOpen: state.setIsOpen,
    }))
  );
  const handleButtonClick = useCallback(() => {
    if (!year) return;
    setIsOpen(true);
  }, [setIsOpen, year]);
  return (
    <Button
      onClick={handleButtonClick}
      disabled={!year || readOnly}
    >
      실적 마감
    </Button>
  );
};
