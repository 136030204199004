import { shallow } from 'zustand/shallow';
import { projectSalesInfoAffiliatedCompanyWebHardRepository } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';

export { useLogic as useProjectSalesInfoAffiliatedCompanyWebHardLogic };

const useLogic = () => {
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { data: list, isLoading } =
    projectSalesInfoAffiliatedCompanyWebHardRepository.useListGet(id);
  const { run: onDelete } = projectSalesInfoAffiliatedCompanyWebHardRepository.useDelete();
  const d = {
    list,
  };
  const h = {
    onDelete,
  };
  return {
    d,
    h,
    isLoading,
  };
};
