import React, { useMemo } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { useProjectSalesInfoContractReviewHistoryModalLogic } from '@front/src/features/project-sales-info/features/contract/features/review-history/features/modal/widgets/useLogic';
import { ProjectSalesInfoContractReviewHistoryModalContext } from '@front/src/features/project-sales-info/features/contract/features/review-history/features/modal/widgets/context';
import { ProjectSalesInfoContractReviewHistoryModalTable } from '@front/src/features/project-sales-info/features/contract/features/review-history/features/modal/components/modal-table';
import { isMobileDevice } from '@front/util/PwaUtil';

export { Widget as ProjectSalesInfoContractReviewHistoryModalWidget };
const Widget = () => {
  const {
    d: { detail, categoryList, typeList, companyList },
    h: { onClose, onUpdate, onCreate },
    isOpen,
  } = useProjectSalesInfoContractReviewHistoryModalLogic();
  const value = useMemo(
    () => ({
      d: { detail, categoryList, typeList, companyList },
      h: { onClose, onUpdate, onCreate },
    }),
    [detail, categoryList, onClose, onUpdate, onCreate, typeList, companyList]
  );
  return (
    <ProjectSalesInfoContractReviewHistoryModalContext.Provider value={value}>
      <ModalLayout
        position="left"
        width={isMobileDevice() ? '29dvw' : '560px'}
        title="구검 계약서 이력 등록/수정"
        open={isOpen}
        onClose={onClose}
      >
        <ProjectSalesInfoContractReviewHistoryModalTable />
      </ModalLayout>
    </ProjectSalesInfoContractReviewHistoryModalContext.Provider>
  );
};
