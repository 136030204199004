import React, { useContext } from 'react';
import { TableBody, TableContainer } from '@mui/material';
import { Table } from '@front/src/components/layout/table/table';
import CircularProgress from '@front/components/CircularProgress';
import { AffiliatedCompanyPersonDetailModalCharacterHobbyContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/widgets/context';
import { AffiliatedCompanyPersonDetailModalCharacterHobbyTableRow } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/components/hobby-table-row';
import { NoResultBox } from '@front/src/components/no-result-box/no-result-box';

export { HobbyTable as AffiliatedCompanyPersonDetailModalCharacterHobbyTable };
const HobbyTable = () => {
  const {
    d: { hobbyList },
    isLoading,
  } = useContext(AffiliatedCompanyPersonDetailModalCharacterHobbyContext);
  if (typeof hobbyList === 'undefined' || isLoading) {
    return <CircularProgress size={30} />;
  }
  if (hobbyList.length === 0) {
    return <NoResultBox message="저장된 취미가 없습니다." />;
  }
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {hobbyList.map((item, i) => (
            <AffiliatedCompanyPersonDetailModalCharacterHobbyTableRow
              key={item.id}
              item={item}
              index={i}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
