import { useQuery } from 'react-query';
import { affiliatedCompanyMeetingHistoryApi } from '@front/src/features/affiliated-company/features/meeting-history/query/api';
import type { AffiliatedCompanyMeetingHistoryShortView } from '@front/src/features/affiliated-company/features/meeting-history/types/view';

export const affiliatedCompanyMeetingHistoryQuery = {
  useMeetingHistoryListGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyMeetingHistoryShortView[]>({
      queryFn: () => affiliatedCompanyMeetingHistoryApi.getMeetingHistoryList(id, menuId),
      queryKey: ['affiliated-company', 'meeting-history', 'list', id],
      enabled: !!id && !!menuId,
      cacheTime: 0,
    });
    return {
      data,
      isLoading,
    };
  },
};
