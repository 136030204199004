import type { CSSProperties } from 'react';
import React, { memo, useMemo } from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { affiliatedPersonPath } from '@front/src/features/affiliated-company/utils/constant';
import { ColorPalette } from '@front/assets/theme';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';
import type { AffiliatedPersonShortView } from '@front/src/features/affiliated-person/types/view';
import { getValueIfExist } from '@front/src/utils';
import classes from '@front/src/features/affiliated-person/features/drawer/widgets/list/components/row.module.scss';
import { NotificationBadge } from '@front/components/badge/badge';
import { changeViewport, isMobileDevice, ViewportMode } from '@front/util/PwaUtil';
import { useMobileMenuState } from '@front/mobile-menu/useState';
import { useBreadCrumbState } from '@front/state/useState';
import { useAffiliatedPersonDrawerState } from '@front/src/features/affiliated-person/features/drawer/useState';
import { useAffiliatedPersonMemoState } from '@front/src/features/affiliated-person/features/memo/useState';

export { Row as AffiliatedPersonDrawerRow };

interface Props {
  index: number;
  data: AffiliatedPersonShortView[];
  style: CSSProperties;
}

const Row = memo(({ index, data, style }: Props) => {
  const item: AffiliatedPersonShortView = data[index];
  const { modalId: id } = useAffiliatedCompanyPersonState(
    useShallow((state) => ({
      modalId: state.modalId,
    }))
  );
  const count = data[index].alarmCount;
  const sx = useMemo(
    () => ({
      backgroundColor: item.id === id ? ColorPalette._cddaf5 : '',
      position: 'relative',
    }),
    [item.id, id]
  );
  const setIsDetails = useMobileMenuState((state) => state.setIsDetails);
  const { setDrawerToggleHandler, setMemoToggleHandler, setViewportMode } = useBreadCrumbState(
    useShallow((state) => ({
      setMemoToggleHandler: state.setMemoToggleHandler,
      setDrawerToggleHandler: state.setDrawerToggleHandler,
      setViewportMode: state.setViewportMode,
    }))
  );
  const setIsOpen = useAffiliatedPersonDrawerState((state) => state.setIsOpen);
  const { setIsOpen: setIsMemoOpen } = useAffiliatedPersonMemoState(
    useShallow((state) => ({
      setIsOpen: state.setIsOpen,
    }))
  );
  const onClick = () => {
    if (isMobileDevice()) {
      setViewportMode(changeViewport(ViewportMode.DESKTOP));
      setIsOpen(false);
      setIsDetails(true);
      setDrawerToggleHandler(setIsOpen);
      setMemoToggleHandler(setIsMemoOpen);
    }
  };
  return (
    <Link
      to={`${affiliatedPersonPath}/${item.id}/outline`}
      state={item.id}
      style={{
        color: ColorPalette._252627,
        textDecoration: 'none',
      }}
      className={`${count > 9 ? classes.link_large : classes.link_small}`}
      onClick={onClick}
    >
      <Box
        style={style}
        sx={{
          ...sx,
          display: 'flex',
          width: '100%',
          borderBottom: `1px solid ${ColorPalette._e4e9f2}`,
          textAlign: 'center',
          alignItems: 'center',
          fontSize: '14px',
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1)',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '84px',
            padding: '5px',
            color: ColorPalette._1c6ebb,
            letterSpacing: '-1px',
            fontWeight: '500',
          }}
        >
          {getValueIfExist(item.person?.code)}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '72px',
            padding: '5px',
          }}
        >
          {getValueIfExist(item.person?.name)}
        </Box>
        <Box
          sx={{
            display: 'block',
            width: '180px',
            padding: '5px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            wordBreak: 'break-all',
          }}
        >
          {getValueIfExist(item.affiliatedCompany?.name)}
        </Box>
        <div
          className={classes.badge_container}
          style={{
            justifyContent: 'left',
          }}
        >
          <NotificationBadge count={count}>
            <span />
          </NotificationBadge>
        </div>
      </Box>
    </Link>
  );
});

Row.displayName = 'AffiliatedPersonDrawerRow';
