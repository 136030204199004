import { create } from 'zustand';

interface State {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  modalId?: number;
  setModalId: (id?: number) => void;
  personId?: number;
  setPersonId: (personId?: number) => void;
}

export { useState as useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalState };

const useState = create<State>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen: boolean) => set(() => ({ isOpen })),
  setModalId: (modalId?: number) => set(() => ({ modalId })),
  setPersonId: (personId?: number) => set(() => ({ personId })),
}));
