import noteMutation from '@front/src/features/note/query/mutation';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

const useComment = (type: string) => {
  const noteId = useGlobalNavBarState((state) => state.noteId);
  const commentQueryParams = {
    targetId: noteId ?? 0,
    targetType: type,
  };

  const { mutate: createComment } = noteMutation.useCreateComment(commentQueryParams);
  const { mutate: updateComment } = noteMutation.useUpdateComment(commentQueryParams);
  const { mutate: deleteComment } = noteMutation.useDeleteComment(commentQueryParams);
  return { createComment, updateComment, deleteComment };
};

export default useComment;
