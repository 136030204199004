import { projectSalesActivityRepository } from '@front/src/features/project-sales-info/features/activity/repository/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';

export { useLogic as useProjectSalesInfoActivityItemLogic };
const useLogic = () => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { run: onActivityUpdate } = projectSalesActivityRepository.useActivityUpdate(id!);
  const { run: onUserCreate } = projectSalesActivityRepository.useUsersCreate(id!);
  return {
    onActivityUpdate,
    onUserCreate,
  };
};
