import { Box } from '@mui/material';
import { FormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import React, { useCallback, useContext } from 'react';

import Button from '@front/layouts/Button';
import ModalLayout from '@front/layouts/ModalLayout';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import { RequestReportApprovalModalForm } from '@front/ost_proposal/view/modal/approval_document/Form';

interface Props {
  open: boolean;
  onClose: () => void;
}

const ApprovalDocumentModal = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();
  const formik = useContext(FormikContext);

  const openSnackbar = useCallback(
    (message, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );

  const onClick = useCallback(() => {
    if (typeof formik.values.content === 'undefined') {
      openSnackbar('내용을 입력주세요.');
      return;
    }
    formik.handleSubmit();
  }, [openSnackbar, formik]);

  return (
    <ModalLayout
      width={600}
      height={300}
      open={open}
      onClose={onClose}
      title={'캠페인 담당자 직권 결재 상신'}
      footer={
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            columnGap: '10px',
          }}
        >
          <Button
            shape={'basic1'}
            onClick={onClick}
          >
            확인
          </Button>
          <Button
            shape={'basic3'}
            onClick={onClose}
          >
            취소
          </Button>
        </Box>
      }
    >
      <RequestReportApprovalModalForm />
    </ModalLayout>
  );
};

export default ApprovalDocumentModal;
