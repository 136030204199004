import type { FocusEvent } from 'react';
import React, { memo, useContext } from 'react';
import type { OstMemoId, OstMemoVO } from '@front/ost/domain';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import Input from '@front/layouts/Input';
import Button from '@front/layouts/Button';
import { FormikContext } from 'formik';

interface Props {
  memo: OstMemoVO;
  onSave: (id: OstMemoId) => () => void;
  onCancel: (id: OstMemoId) => () => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
}

const MemoItemUpdateForm = ({ memo, onSave, onCancel, onBlur }: Props) => {
  const formik = useContext(FormikContext);
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        padding: '12px 0',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          border: `1px solid ${ColorPalette._e4e9f2}`,
          marginBottom: '5px',
        }}
      >
        <Input
          required
          multiline
          variant="outlined"
          placeholder="메모 입력"
          key={formik.values.description}
          defaultValue={formik.values.description ?? ''}
          onBlur={onBlur}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Button
            sx={{
              margin: '0px 5px',
            }}
            onClick={onSave(memo.id)}
          >
            저장
          </Button>
          <Button
            shape="basic2"
            onClick={onCancel(memo.id)}
          >
            취소
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const OstMemoItemUpdateForm = memo(MemoItemUpdateForm);

export default OstMemoItemUpdateForm;
