import React, { useCallback } from 'react';
import { OldTd } from '@front/layouts/Table';
import { TableRow } from '@mui/material';
import type { ProjectSalesInfoBidInfoView } from '@front/src/features/project-sales-info/features/bid/features/bid-information/types/view';
import Checkbox from '@front/layouts/Checkbox';
import { useProjectSalesInfoBidInfoState } from '@front/src/features/project-sales-info/features/bid/features/bid-information/widgets/useState';
import { spliceAndPushIfExist } from '@front/src/features/project-sales-info/features/bid/features/bid-information/utils/constants';
import dayjs from 'dayjs';
import {
  convertBooleanToYNForLabel,
  DATE_TIME_FORMAT,
  downloadFile,
  getValueIfExist,
} from '@front/src/utils';
import { useShallow } from 'zustand/react/shallow';
import { FileDisplayText } from '@front/src/components/file-display-text/file-display-text';

export { BidInfoTableRow as ProjectSalesInfoBidInfoTableRow };

interface Props {
  item: ProjectSalesInfoBidInfoView;
  index: number;
}

const BidInfoTableRow = ({ item, index }: Props) => {
  const { setIdList, idList, setIsUpdateModalOpen, setDetailId, isHover, setIsHover } =
    useProjectSalesInfoBidInfoState(
      useShallow((state) => ({
        setIdList: state.setIdList,
        idList: state.idList,
        setIsUpdateModalOpen: state.setIsUpdateModalOpen,
        setDetailId: state.setDetailId,
        isHover: state.isHover,
        setIsHover: state.setIsHover,
      }))
    );
  const handleCheckboxChange = useCallback(
    (id) => () => {
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  const { readOnly } = useProjectSalesInfoBidInfoState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const onRowClick = () => {
    setDetailId(item.id);
    setIsUpdateModalOpen();
  };
  const endDate = dayjs(item.endDate).format(DATE_TIME_FORMAT);
  const noticeGuideFileName = item.noticeGuideFile?.filename;
  const bidNoticeFileName = item.bidNoticeFile?.filename;
  return (
    <TableRow
      hover={isHover}
      style={{ cursor: 'pointer' }}
      onClick={onRowClick}
    >
      <OldTd>
        <Checkbox
          checked={idList.includes(item.id)}
          onChange={handleCheckboxChange(item.id)}
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
          disabled={readOnly}
        />
      </OldTd>
      <OldTd>{index + 1}</OldTd>
      <OldTd sx={{ whiteSpace: 'nowrap' }}>{getValueIfExist(item.startDate)}</OldTd>
      <OldTd sx={{ whiteSpace: 'nowrap' }}>{endDate === 'Invalid Date' ? '-' : endDate}</OldTd>
      <OldTd>{getValueIfExist(item.estimation?.estimationCode)}</OldTd>
      <OldTd>{convertBooleanToYNForLabel(item.isBid)}</OldTd>
      <OldTd>{convertBooleanToYNForLabel(item.isConfirmed)}</OldTd>
      <OldTd
        sx={{ whiteSpace: 'nowrap' }}
        onMouseEnter={() => bidNoticeFileName && setIsHover()}
        onMouseLeave={() => bidNoticeFileName && setIsHover()}
        onClick={(e: React.MouseEvent) => bidNoticeFileName && e.stopPropagation()}
      >
        <FileDisplayText
          fileName={item.bidNoticeFile?.filename ?? ''}
          onClick={downloadFile(item.bidNoticeFile?.id)}
        />
      </OldTd>
      <OldTd
        sx={{ whiteSpace: 'nowrap' }}
        onMouseEnter={() => noticeGuideFileName && setIsHover()}
        onMouseLeave={() => noticeGuideFileName && setIsHover()}
        onClick={(e: React.MouseEvent) => noticeGuideFileName && e.stopPropagation()}
      >
        <FileDisplayText
          fileName={item.noticeGuideFile?.filename ?? ''}
          onClick={downloadFile(item.noticeGuideFile?.id)}
        />
      </OldTd>
      <OldTd>{getValueIfExist(item.note)}</OldTd>
    </TableRow>
  );
};
