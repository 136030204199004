import { useQuery } from 'react-query';
import { affiliatedCompanyPersonGiftHistoryApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/query/api';
import type { AffiliatedCompanyPersonGiftHistoryView } from '@front/src/features/affiliated-company/features/gift-history/types/view';

export const affiliatedCompanyPersonGiftHistoryQuery = {
  usePersonGiftHistoryListGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyPersonGiftHistoryView[]>({
      queryKey: ['affiliated-company', 'person', 'gift-history', id],
      queryFn: () => affiliatedCompanyPersonGiftHistoryApi.getPersonGiftHistoryList(id, menuId),
      enabled: !!id,
      cacheTime: 0,
    });
    return {
      data,
      isLoading,
    };
  },
};
