import type { ReactNode } from 'react';
import React from 'react';
import classes from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/detail-modal/components/layout/footer.module.scss';

interface Props {
  children: ReactNode;
}

export { Footer as ProjectSalesInfoAffiliatedCompanyPersonDetailModalLayoutFooter };
const Footer = ({ children }: Props) => <div className={classes.container}>{children}</div>;
