import React from 'react';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  onOpen: () => void;
}

export default function UserSelectorPlusIcon({ onOpen }: Readonly<Props>) {
  return (
    <Box
      onClick={onOpen}
      sx={{
        display: 'flex',
        width: '25px',
        height: '25px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '18px',
        borderRadius: '25px',
        backgroundColor: ColorPalette._e4e9f2,
        color: ColorPalette._386dd6,
        border: `1px solid ${ColorPalette._e4e9f2}`,
        overflow: 'hidden',
        cursor: 'pointer',
      }}
    >
      <FontAwesomeIcon icon="plus" />
    </Box>
  );
}
