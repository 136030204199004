import React, { Suspense } from 'react';
import { AccountingUploadSalesInfoGoalModalListProvider } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/widgets/list/provider/provider';
import { AccountingUploadSalesInfoGoalModalList } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/widgets/list/components/list';
import { LoadingSpinner } from '@front/src/components/loading-spinner';

export { Widget as AccountingUploadSalesInfoGoalModalListWidget };

const Widget = () => (
  <Suspense fallback={<LoadingSpinner height="428px" />}>
    <AccountingUploadSalesInfoGoalModalListProvider>
      <AccountingUploadSalesInfoGoalModalList />
    </AccountingUploadSalesInfoGoalModalListProvider>
  </Suspense>
);
