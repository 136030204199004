import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { projectSalesInfoSubjectReviewModalQuery } from '@front/src/features/project-sales-info/features/subject-review/features/modal/query/query';
import { projectSalesInfoSubjectReviewModalMutation } from '@front/src/features/project-sales-info/features/subject-review/features/modal/query/mutation';

export const projectSalesInfoSubjectReviewModalRepository = {
  useDetailGet: (id?: number) => {
    const { data, isLoading, remove } = projectSalesInfoSubjectReviewModalQuery.useDetailGet(id);
    return {
      data,
      isLoading,
      remove,
    };
  },
  useUpdate: () => {
    const { mutate } = projectSalesInfoSubjectReviewModalMutation.useUpdate();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useDelete: () => {
    const { mutate } = projectSalesInfoSubjectReviewModalMutation.useDelete();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
