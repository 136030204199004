import { default as axios } from '@front/src/config/axios';
import { toFormData } from '@front/services/api';
import type {
  ProceedingsAddParameter,
  ProceedingsMemoAddParameter,
  ProceedingsMemoChangeParameter,
  ProceedingsUpdateParameter,
} from './parameter';
import type { ProceedingsMemoQuery, ProceedingsQuery } from './query';
import type { ProceedingsId, ProceedingsMemoId } from './domain';

class ProceedingsApi {
  async add(params: ProceedingsAddParameter): Promise<void> {
    const { data } = await axios.post('/api/proceedings', params);
    return data;
  }

  async getFilteredList(query: ProceedingsQuery): Promise<void> {
    const { data } = await axios.get(
      `/api/proceedings/list?keyword=${query.keyword}&status=${query.status}&department=${query.department}&startTime=${query.startTime}&endTime=${query.endTime}`
    );
    return data;
  }

  async getDetail(id: ProceedingsId): Promise<void> {
    const { data } = await axios.get(`/api/proceedings/${id}`);
    return data;
  }

  async update(id: ProceedingsId, params: ProceedingsUpdateParameter): Promise<void> {
    const { data } = await axios.patch(`/api/proceedings/${id}`, {
      ...params,
      content: params.beforeContent,
    });
    return data;
  }

  async delete(id: ProceedingsId): Promise<void> {
    const { data } = await axios.delete(`/api/proceedings/${id}`);
    return data;
  }

  async addMemo(id: ProceedingsId, params: ProceedingsMemoAddParameter): Promise<void> {
    const { data } = await axios.post(`/api/proceedings/${id}/memo`, params);
    return data;
  }

  async getMemoList(id: ProceedingsId, query: ProceedingsMemoQuery): Promise<void> {
    const { data } = await axios.get(`/api/proceedings/${id}/memo?keyword=${query.keyword}`);
    return data;
  }

  async changeMemo(id: ProceedingsId, params: ProceedingsMemoChangeParameter): Promise<void> {
    const { data } = await axios.patch(`/api/proceedings/${id}/memo/${params.id}`, params);
    return data;
  }

  async deleteMemo(id: ProceedingsId, memoId: ProceedingsMemoId): Promise<void> {
    const { data } = await axios.delete(`/api/proceedings/${id}/memo/${memoId}`);
    return data;
  }

  async addComment(id: ProceedingsId, params: ProceedingsMemoAddParameter): Promise<void> {
    const { data } = await axios.post(`/api/proceedings/${id}/comment`, params);
    return data;
  }

  async updateComment(id: ProceedingsId, params: ProceedingsMemoChangeParameter): Promise<void> {
    const { data } = await axios.patch(`/api/proceedings/${id}/comment/${params.id}`, params);
    return data;
  }

  async deleteComment(id: ProceedingsId, commentId: ProceedingsMemoId): Promise<void> {
    const { data } = await axios.delete(`/api/proceedings/${id}/comment/${commentId}`);
    return data;
  }

  async addAttachedFile(id: ProceedingsId, params: FormData): Promise<void> {
    const { data } = await axios.post(`/api/proceedings/${id}/attached-file`, toFormData(params));
    return data;
  }

  async deleteAttachedFile(id: ProceedingsId, fileId: ProceedingsMemoId): Promise<void> {
    const { data } = await axios.delete(`/api/proceedings/${id}/attached-file/${fileId}`);
    return data;
  }

  async canAccess(id: ProceedingsId): Promise<void> {
    const { data } = await axios.get(`/api/proceedings/${id}/access`);
    return data;
  }
}

export const proceedingsApi = new ProceedingsApi();
