import { default as axios } from '@front/src/config/axios';
import { convertEmptyToNull, getMenuIdHeaders } from '@front/src/utils';
import { baseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { toFormData } from '@front/services/api';
import type {
  AffiliatedCompanyOutlineBankBookFileUpdateParameter,
  AffiliatedCompanyOutlineBankInfoUpdateParameter,
} from '@front/src/features/affiliated-company/features/outline/features/bank-info/types/parameter';
import { adminManageableVariableApiURL } from '@front/src/features/manageable-variable/utils/constants';
import { manageableVariable } from '@front/src/features/affiliated-company/features/outline/features/short/utils/constants';

const url = {
  updateBankInfo: (id?) => `${baseUrl}/${id}/bank-info`,
  updateBankBookFile: (id?) => `${baseUrl}/${id}/bank-book-file`,
  getBankNameOptionList: () => `${adminManageableVariableApiURL}`,
};

export const affiliatedCompanyOutlineBankInfoApi = {
  updateBankInfo: async (params: AffiliatedCompanyOutlineBankInfoUpdateParameter, menuId) => {
    await axios.put(url.updateBankInfo(params.id), convertEmptyToNull(params), {
      headers: getMenuIdHeaders(menuId),
    });
  },
  updateBankBookFile: async (
    params: AffiliatedCompanyOutlineBankBookFileUpdateParameter,
    menuId
  ) => {
    await axios.put(
      url.updateBankBookFile(params.id),
      toFormData({
        file: params.bankBookFile,
      }),
      {
        headers: getMenuIdHeaders(menuId),
      }
    );
  },
  getBankNameOptionList: async (menuId) => {
    const { data } = await axios.get(url.getBankNameOptionList(), {
      params: { key: manageableVariable.AffiliatedCompanyOutlineBankInfoBankName },
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
