import { create } from 'zustand';

interface State {
  idList: number[];
  setIdList: (idList: number[]) => void;
}

export { useState as useAffiliatedCompanyCharacterPartnerCompanyState };

const useState = create<State>((set) => ({
  idList: [],
  setIdList: (idList: number[]) => set(() => ({ idList })),
}));
