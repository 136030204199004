import { adminAccessAuthSettingsUserQuery } from '@front/src/features/admin/features/access-authorization-settings/features/user-authorization/query/query';
import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type {
  AuthUpdateParams,
  AuthUserSearchParams,
} from '@front/src/features/admin/features/access-authorization-settings/type/parameter';
import { adminAccessAuthSettingsUserMutation } from '@front/src/features/admin/features/access-authorization-settings/features/user-authorization/query/mutation';
import adminAccessAuthSettingsQuery from '@front/src/features/admin/features/access-authorization-settings/query/query';

export const adminAccessAuthSettingsUserRepository = {
  useUserListGet: (menuId: number, params: AuthUserSearchParams, authMenuId?: number) => {
    const { data } = adminAccessAuthSettingsUserQuery.useUserListGet(menuId, params, authMenuId);
    return {
      data,
    };
  },

  useTabListGet: (menuId: number, authMenuId?: number) => {
    const { data } = adminAccessAuthSettingsQuery.useTabListGet(menuId, authMenuId);
    return {
      data,
    };
  },

  useAuthUpdate: (menuId: number, params: AuthUserSearchParams, authMenuId?: number) => {
    const callback = getNoOpCallback();
    const { mutate, isLoading } = adminAccessAuthSettingsUserMutation.useAuthUserUpdate(
      menuId,
      params,
      authMenuId
    );
    return {
      run: (params?: AuthUpdateParams) => mutate(params, callback),
      setCallbacks: getSetCallbackFunc(callback),
      isLoading,
    };
  },
};
