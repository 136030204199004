import { shallow } from 'zustand/shallow';
import { useAffiliatedCompanyPersonDetailModalGiftHistoryState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/widgets/useState';
import { affiliatedCompanyPersonGiftHistoryRepository } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/repository/repository';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';

export { useLogic as useAffiliatedCompanyPersonDetailModalGiftHistoryLogic };

const useLogic = (menuId) => {
  const { modalId } = useAffiliatedCompanyPersonState(
    (state) => ({
      modalId: state.modalId,
    }),
    shallow
  );
  const { idList, setIdList } = useAffiliatedCompanyPersonDetailModalGiftHistoryState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { run: onGiftHistoryCreate } =
    affiliatedCompanyPersonGiftHistoryRepository.usePersonGiftHistoryCreate(menuId);
  const { isLoading, data: giftHistoryList } =
    affiliatedCompanyPersonGiftHistoryRepository.usePersonGiftHistoryListGet(modalId, menuId);
  const { run: onGiftHistoryUpdate } =
    affiliatedCompanyPersonGiftHistoryRepository.usePersonGiftHistoryUpdate(menuId);
  const { run: onGiftHistoryDelete } =
    affiliatedCompanyPersonGiftHistoryRepository.usePersonGiftHistoryDelete(menuId);
  const h = {
    onGiftHistoryCreate: () => onGiftHistoryCreate(modalId),
    onGiftHistoryUpdate,
    onGiftHistoryDelete,
    setIdList,
  };
  const d = { giftHistoryList };
  return { d, h, isLoading, idList };
};
