import { useQuery } from 'react-query';
import { AccountingSettingsBankAccountApi } from '@front/src/features/accounting/features/settings/features/bank-account/query/api';
import type { BankAccountView } from '@front/src/features/accounting/features/settings/features/bank-account/types/view';

export { Query as AccountingSettingsBankAccountQuery };

const Query = {
  useGetList: () => {
    const { data, isLoading } = useQuery<BankAccountView[]>({
      queryFn: () => AccountingSettingsBankAccountApi.getList(),
      queryKey: ['accounting', 'settings', 'bank-account', 'list'],
      suspense: true,
    });
    return {
      data,
      isLoading,
    };
  },
};
