import React, { useContext, useEffect } from 'react';
import { TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { OldTd, Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { FormProvider, useForm } from 'react-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import { HookFormFileUploader } from '@front/src/components/file-uploader/hook-form-file-uploader';
import { ProjectSalesInfoEstimationExperimentAmountHookForm } from '@front/src/features/project-sales-info/features/estimation/components/experiment-amount-hook-form';
import { ProjectSalesInfoEstimationReviewAmountHookForm } from '@front/src/features/project-sales-info/features/estimation/components/review-amount-hook-form';
import { ProjectSalesInfoEstimationComparedUpdateModalButtonGroup } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/features/update-modal/components/modal-button-group';
import { ProjectSalesInfoEstimationComparedUpdateModalContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/features/update-modal/widgets/context';
import {
  calcUnitPrice,
  convertAmountToStringForForm,
  convertDateToStringFormat,
  convertNullToEmptyForForm,
  DATE_TIME_FORMAT,
  getValueIfExist,
} from '@front/src/utils';
import { HookFormCompanySelector } from '@front/src/components/company-selector-with-hook-form';
import { HookFormTextarea } from '@front/src/components/textarea-with-hook-form/textarea-with-hook-form';
import { useProjectSalesInfoEstimationComparedState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";

export { ModalForm as ProjectSalesInfoEstimationComparedUpdateModalForm };

const getValues = (detail) => ({
  estimationDate: convertNullToEmptyForForm(detail?.estimationDate),
  competitorCompany: convertNullToEmptyForForm(detail?.competitorCompany),
  clientCompany: detail?.clientCompany,
  experimentInformation: convertNullToEmptyForForm(detail?.experimentInformation),
  experimentAmount: convertAmountToStringForForm(detail?.experimentAmount),
  reviewAmount: convertAmountToStringForForm(detail?.reviewAmount),
  totalAmount: convertAmountToStringForForm(detail?.totalAmount),
  unitPrice: convertAmountToStringForForm(detail?.unitPrice),
  date: convertNullToEmptyForForm(detail?.date),
  pdf: detail?.pdf,
  note: convertNullToEmptyForForm(detail?.note),
});
const ModalForm = () => {
  const {
    d: { detail },
  } = useContext(ProjectSalesInfoEstimationComparedUpdateModalContext);
  const methods = useForm({
    values: getValues(detail),
  });
  const { watch, setValue } = methods;
  const experimentAmount = watch('experimentAmount');
  const experimentInformation = watch('experimentInformation');
  const { readOnly } = useProjectSalesInfoEstimationComparedState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  useEffect(() => {
    const newUnitPrice = calcUnitPrice(experimentInformation, +experimentAmount.replace(/,/g, ''));
    setValue('unitPrice', newUnitPrice);
  }, [experimentAmount, experimentInformation, setValue]);
  return (
    <FormProvider {...methods}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow key={Math.random()}>
              <Th
                center={false}
                width="120px"
              >
                견적일자
              </Th>
              <OldTd sx={{textAlign:'left'}}>
                <DatePickerWithHookForm
                  name="estimationDate"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>풍동업체명</Th>
              <OldTd>
                <HookFormCompanySelector
                  position="left"
                  name="competitorCompany"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>의뢰처</Th>
              <OldTd>
                <HookFormCompanySelector
                  position="left"
                  name="clientCompany"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>실험정보</Th>
              <OldTd>
                <HookFormInput
                  width="200px"
                  name="experimentInformation"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>풍동금액</Th>
              <OldTd>
                <ProjectSalesInfoEstimationExperimentAmountHookForm readOnly={readOnly} />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>구검비</Th>
              <OldTd>
                <ProjectSalesInfoEstimationReviewAmountHookForm readOnly={readOnly} />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>총액</Th>
              <OldTd>
                <HookFormInput
                  width="200px"
                  name="totalAmount"
                  inputType={InputType.AMOUNT}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>순동단가</Th>
              <OldTd>
                <HookFormInput
                  width="200px"
                  name="unitPrice"
                  inputType={InputType.AMOUNT}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>일정</Th>
              <OldTd>
                <HookFormInput
                  width="160px"
                  name="date"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>PDF 업로드</Th>
              <OldTd>
                <HookFormFileUploader
                  name="pdf"
                  ext="pdf"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>등록일시</Th>
              <TableCell>
                {convertDateToStringFormat(detail?.createdAt, DATE_TIME_FORMAT)}
              </TableCell>
            </TableRow>
            <TableRow>
              <Th center={false}>등록자</Th>
              <TableCell>{getValueIfExist(detail?.writer?.name)}</TableCell>
            </TableRow>
            <TableRow>
              <Th center={false}>비고</Th>
              <OldTd sx={{ padding: '10px' }}>
                <HookFormTextarea
                  name="note"
                  minRows={2}
                  resize="none"
                  disabled={readOnly}
                  variable={true}
                />
              </OldTd>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ProjectSalesInfoEstimationComparedUpdateModalButtonGroup />
    </FormProvider>
  );
};
