import React, { Suspense } from 'react';
import { MyPageAuthorizationMenuProvider } from '@front/src/features/my-page/features/authorization/widgets/menu/provider/provider';
import { MyPageAuthorizationMenuDepth1List } from '@front/src/features/my-page/features/authorization/widgets/menu/components/depth1-list';
import { MyPageAuthorizationMenuDepth2List } from '@front/src/features/my-page/features/authorization/widgets/menu/components/depth2-list';
import { MyPageAuthorizationMenuDepth3List } from '@front/src/features/my-page/features/authorization/widgets/menu/components/depth3-list';
import { MyPageAuthorizationMenuTabList } from '@front/src/features/my-page/features/authorization/widgets/menu/components/tab-list';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

export { Widget as MyPageAuthorizationMenuWidget };

const Widget = ({ ...rest }: Readonly<SectionComponentProps>) => (
  <Suspense>
    <MyPageAuthorizationMenuProvider {...rest}>
      <MyPageAuthorizationMenuDepth1List />
      <MyPageAuthorizationMenuDepth2List />
      <MyPageAuthorizationMenuDepth3List />
      <MyPageAuthorizationMenuTabList />
    </MyPageAuthorizationMenuProvider>
  </Suspense>
);
