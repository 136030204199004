import React, { useCallback } from 'react';
import Information from '@front/ost_proposal/view/detail/advisorForm/Information';
import { useDispatch } from 'react-redux';
import { proposalAction } from '@front/ost_proposal/action';
import type { ProposalArrangementInformationStatusParameter } from '@front/ost_proposal/parameter';

interface Props {
  isAdvisor: boolean;
}

const InformationService = ({ isAdvisor }: Props) => {
  const dispatch = useDispatch();
  const onUpdate = useCallback(
    (params: ProposalArrangementInformationStatusParameter) =>
      dispatch(proposalAction.updateProposalArrangementInformationStatus(params)),
    [dispatch]
  );
  return (
    <Information
      onUpdate={onUpdate}
      isAdvisor={isAdvisor}
    />
  );
};

export default InformationService;
