import {
  convertEmptyToNullForAmountParameter,
  convertEmptyToNullForNumberParameter,
  convertEmptyToNullForParameter,
  convertYNToBooleanForParameter,
} from '@front/src/utils';
import type { ProjectEstimationView } from '@front/src/features/project-sales-info/features/estimation/features/estimation/types/view';
import {formatDateOrNull} from "@front/src/features/project-sales-info/utils";

export const isPossibleSave = (input, confirmedHasVat) => {
  if (confirmedHasVat === null) {
    return true;
  }
  if (confirmedHasVat && input) {
    return true;
  }
  if (!confirmedHasVat && typeof input === 'boolean' && !input) {
    return true;
  }
  return false;
};

export const convertContractContractDataForParameter = (data) => ({
  isConfirmed: convertYNToBooleanForParameter(data.isConfirmed),
  orderIntakeDate: formatDateOrNull(data.orderIntakeDate),
  contractDate: formatDateOrNull(data.contractDate),
  category: convertEmptyToNullForParameter(data.category),
  orderCompanyId: convertEmptyToNullForParameter(data.orderCompany?.id),
  estimationId: convertEmptyToNullForNumberParameter(data.estimationId),
  complexName: convertEmptyToNullForParameter(data.complexName),
  experimentInformation: convertEmptyToNullForParameter(data.experimentInformation),
  experimentAmount: convertEmptyToNullForAmountParameter(data.experimentAmount),
  reviewAmount: convertEmptyToNullForAmountParameter(data.reviewAmount),
  totalAmount: convertEmptyToNullForAmountParameter(data.totalAmount),
  unitPrice: convertEmptyToNullForAmountParameter(data.unitPrice),
  schedule: convertEmptyToNullForParameter(data.schedule),
  CMAmount: convertEmptyToNullForAmountParameter(data.CMAmount),
  convertedCMAmount: convertEmptyToNullForAmountParameter(data.convertedCMAmount),
  isCm: convertYNToBooleanForParameter(data.isCm),
  CMMethod: convertEmptyToNullForParameter(data.CMMethod),
  note: convertEmptyToNullForParameter(data.note),
  hwp: data.hwp,
  pdf: data.pdf,
});

export const getEstimationOptionList = (list?: ProjectEstimationView[]) => {
  if (typeof list === 'undefined') {
    return [];
  }
  return list.map((item) => ({
    label: item.estimationCode,
    value: item.id.toString(),
  }));
};
