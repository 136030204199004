import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { ProjectReviewAmountPaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/payment-history/types/parameter';
import { projectSalesInfoPaymentHistoryUpdateModalMutation } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/update-modal/query/mutation';
import { projectSalesInfoPaymentHistoryUpdateModalQuery } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/update-modal/query';

export const projectSalesInfoPaymentHistoryUpdateModalRepository = {
  usePaymentHistoryUpdate: (id: number) => {
    const { mutate } =
      projectSalesInfoPaymentHistoryUpdateModalMutation.usePaymentHistoryDetailUpdate(id);
    const callback = getNoOpCallback();
    return {
      run: (params: ProjectReviewAmountPaymentHistoryParams) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
  usePaymentHistoryDetailGet: (itemId: number) => {
    const { data, isLoading } =
      projectSalesInfoPaymentHistoryUpdateModalQuery.usePaymentHistoryDetailGet(itemId);
    return {
      data,
      isLoading,
    };
  },
  useConsortiumCompanyGet: (id: number) => {
    const { data, isLoading } =
      projectSalesInfoPaymentHistoryUpdateModalQuery.useConsortiumCompanyGet(id);
    return {
      data,
      isLoading,
    };
  },
};
