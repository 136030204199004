export enum ParentsType {
  PROJECT = 'PROJECT',
  PROCEEDINGS = 'PROCEEDINGS',
  OST = 'OST',
  PROPOSAL = 'PROPOSAL',
  VOTE = 'VOTE',
  APPROVAL = 'APPROVAL',
  AFFILIATED_COMPANY = 'AFFILIATED_COMPANY',
  WORK = 'WORK',
  ACCOUNTING = 'ACCOUNTING',
  REWARD = 'REWARD',
  EVALUATION = 'EVALUATION',
  ARRANGEMENT = 'ARRANGEMENT',
  CAMPAIGN = 'CAMPAIGN',
  AFFILIATED_COMPANY_PERSON = 'AFFILIATED_COMPANY_PERSON',
}
