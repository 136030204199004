import React, { useCallback } from 'react';
import Input from '@front/layouts/Input';
import { Box } from '@mui/material';
import Button from '@front/layouts/Button';
import { useController, useForm, useFormContext } from 'react-hook-form';

export default function UserSelectorSearchForm() {
  const { reset, setValue } = useFormContext();
  const handleKeyDown = useCallback(
    async (e) => {
      if (e.isComposing || e.keyCode === 229) return;
      if (e.key !== 'Enter') return;
      setValue('keyword', e.target.value);
    },
    [setValue]
  );
  const { control } = useForm({
    defaultValues: {
      keyword: '',
    },
  });
  const {
    field: { value, onChange },
  } = useController({
    control,
    name: 'keyword',
  });
  const handleSearchClick = useCallback(() => {
    setValue('keyword', value);
    setValue('departmentId', undefined);
  }, [setValue, value]);
  const handleReset = useCallback(() => {
    reset();
    setValue('keyword', '');
    setValue('departmentId', undefined);
  }, [reset, setValue]);
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '20px',
      }}
    >
      <Input
        variant="standard"
        placeholder="전체 부서 사용자 대상 검색"
        onKeyDown={handleKeyDown}
        value={value}
        onChange={onChange}
      />
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
        }}
      >
        <Button
          shape="basic2"
          onClick={handleReset}
        >
          초기화
        </Button>
        <Button onClick={handleSearchClick}>검색</Button>
      </Box>
    </Box>
  );
}
