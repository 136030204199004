import React from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoSubjectReviewModalComplexInformationDeleteWidget } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/delete/widgets/widget';
import { ProjectSalesInfoSubjectReviewModalComplexInformationCreateWidget } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/create/widgets/widget';
import { ProjectSalesInfoSubjectReviewModalComplexInformationListWidget } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/table/widgets/widget';

export { Index as ProjectSalesInfoSubjectReviewModalComplexInformationFeature };

const Index = () => {
  const { Layout, Header, Body, Help } = AddDeleteTable;
  return (
    <Layout>
      <Header title={<Help>행을 클릭하여 수정할 수 있습니다.</Help>}>
        <ProjectSalesInfoSubjectReviewModalComplexInformationDeleteWidget />
        <ProjectSalesInfoSubjectReviewModalComplexInformationCreateWidget />
      </Header>
      <Body>
        <ProjectSalesInfoSubjectReviewModalComplexInformationListWidget />
      </Body>
    </Layout>
  );
};
