import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { AffiliatedPersonCompanySelectModalListContext } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/widgets/list/provider/context';
import { useAffiliatedPersonCompanySelectModalListLogic } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/widgets/list/provider/useLogic';

export { Provider as AffiliatedPersonCompanySelectModalListProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { list } = useAffiliatedPersonCompanySelectModalListLogic();
  const value = useMemo(
    () => ({
      list,
    }),
    [list]
  );
  return (
    <AffiliatedPersonCompanySelectModalListContext.Provider value={value}>
      {children}
    </AffiliatedPersonCompanySelectModalListContext.Provider>
  );
};
