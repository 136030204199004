import React, { useCallback, useMemo } from 'react';
import { ControlPanel } from '@front/src/components/control-panel/control-panel';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { usePersonalSettingsModalUISettingsHeaderSettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/useState';
import { useShallow } from 'zustand/react/shallow';

export { HeaderControlPanel as PersonalSettingsModalUISettingsHeaderSettingsHeaderControlPanel };

const HeaderControlPanel = () => {
  const { editOpenIdList, clearEditOpenIdList } =
    usePersonalSettingsModalUISettingsHeaderSettingsState(
      useShallow((state) => ({
        editOpenIdList: state.editOpenIdList,
        clearEditOpenIdList: state.clearEditOpenIdList,
      }))
    );
  const handleClick = useCallback(() => {
    if (editOpenIdList.length === 0) return;
    clearEditOpenIdList();
  }, [editOpenIdList, clearEditOpenIdList]);
  const itemList = useMemo(
    () => [{ text: '전체 수정 닫기', icon: HighlightOffIcon, action: handleClick }],
    [handleClick]
  );
  return (
    <ControlPanel
      isHeader
      itemList={itemList}
    />
  );
};
