import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import ContributorInfo from '@front/ost_proposal/view/detail/ContributorInfo';
import { proposalAction } from '@front/ost_proposal/action';
import type { ProposalContributorId } from '@front/ost_proposal/domain';
import type { ProposalContributorUpdateParameter } from '@front/ost_proposal/parameter';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import type { UserId } from '@front/user/domain';

const getExclude = (contributorList, estimatorList, item) => {
  let result: UserId[] = [];
  // 참여자 추가
  result = [
    ...result,
    ...contributorList
      .filter((contributor) => contributor.contributor?.id !== item.contributor?.id)
      .map((contributor) => contributor.contributor?.id),
  ];
  // 평가자 추가
  result = [
    ...result,
    ...estimatorList
      .filter((estimatorView) => estimatorView.estimator?.id !== item.contributor?.id)
      .map((estimatorView) => estimatorView.estimator?.id),
  ];
  return result;
};

interface Props {
  isContributor: boolean;
}

function ContributorInfoService({ isContributor }: Props) {
  const dispatch = useDispatch();
  const addContributor = useCallback(() => dispatch(proposalAction.addContributor()), [dispatch]);
  const deleteContributor = useCallback(
    (id: ProposalContributorId) => dispatch(proposalAction.deleteContributor(id)),
    [dispatch]
  );
  const updateContributor = useCallback(
    (params: ProposalContributorUpdateParameter) =>
      dispatch(proposalAction.updateContributor(params)),
    [dispatch]
  );

  const openSnackbar = useCallback(
    (message, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );

  return (
    <ContributorInfo
      getExclude={getExclude}
      openSnackbar={openSnackbar}
      addContributor={addContributor}
      updateContributor={updateContributor}
      deleteContributor={deleteContributor}
      isContributor={isContributor}
    />
  );
}

export default ContributorInfoService;
