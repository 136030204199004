import React from 'react';
import { ProjectSalesInfoActivityUserItemProvider } from '@front/src/features/project-sales-info/features/activity/widgets/activity-user-item/provider/proivder';
import { ProjectSalesInfoActivityUserRow } from '@front/src/features/project-sales-info/features/activity/widgets/activity-user-item/components/row';

export { Widget as ProjectSalesInfoActivityUserItemWidget };

const Widget = () => (
  <ProjectSalesInfoActivityUserItemProvider>
    <ProjectSalesInfoActivityUserRow />
  </ProjectSalesInfoActivityUserItemProvider>
);
