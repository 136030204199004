import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAccountingSettingsManagementAccountingAccountHeaderGetUpdatedAtLogic } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/header/widgets/get-updated-at/provider/useLogic';
import { AccountingSettingsManagementAccountingAccountHeaderGetUpdatedAtContext } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/header/widgets/get-updated-at/provider/context';

export { Provider as AccountingSettingsManagementAccountingAccountHeaderGetUpdatedAtProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { detail } = useAccountingSettingsManagementAccountingAccountHeaderGetUpdatedAtLogic();
  const value = useMemo(() => ({ detail }), [detail]);
  return (
    <AccountingSettingsManagementAccountingAccountHeaderGetUpdatedAtContext.Provider value={value}>
      {children}
    </AccountingSettingsManagementAccountingAccountHeaderGetUpdatedAtContext.Provider>
  );
};
