import React, { useMemo } from 'react';
import { MobileNotificationListItemUseLogic } from '@front/src/features/mobile-notification/widgets/list/widgets/item/provider/useLogic';
import { MobileNotificationListItemContext } from '@front/src/features/mobile-notification/widgets/list/widgets/item/provider/context';

export { Provider as MobileNotificationListItemProvider };

interface Props {
  children: React.ReactNode;
}

const Provider = ({ children }: Props) => {
  const { onToggle, onDelete, onPinUpdate, onNavigateToggle } =
    MobileNotificationListItemUseLogic();
  const value = useMemo(
    () => ({
      onToggle,
      onDelete,
      onPinUpdate,
      onNavigateToggle,
    }),
    [onToggle, onDelete, onPinUpdate, onNavigateToggle]
  );
  return (
    <MobileNotificationListItemContext.Provider value={value}>
      {children}
    </MobileNotificationListItemContext.Provider>
  );
};
