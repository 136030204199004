import React, { memo, useContext } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { OldTd, Table } from '@front/layouts/Table';
import classes from '@front/src/features/project-sales-info/features/bid/features/bid-result/components/bid-result-table.module.scss';
import { ProjectSalesInfoBidResultTableRow } from '@front/src/features/project-sales-info/features/bid/features/bid-result/components/bid-result-table-row';
import CircularProgress from '@front/components/CircularProgress';
import { ProjectSalesInfoBidResultContext } from '@front/src/features/project-sales-info/features/bid/features/bid-result/widgets/context';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';

export { Index as ProjectSalesInfoBidResultTable };

const Index = () => {
  const {
    d: { bidResultList },
    isLoading,
  } = useContext(ProjectSalesInfoBidResultContext);
  const isListLoading = typeof bidResultList === 'undefined' || isLoading;
  const isListEmpty = bidResultList?.length === 0;
  return (
    <TableContainer
      sx={{
        ...useGetMaxWidthSx({ hasMemo: true }),
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <Th
              center={true}
              className={classes.width__2}
            >
              {}
            </Th>
            <Th
              center={true}
              className={classes.width__2}
            >
              회차
            </Th>
            <Th
              center={true}
              className={classes.width__10}
            >
              업체명
            </Th>
            <Th
              center={true}
              className={classes.width__6}
            >
              총금액
            </Th>
            <Th
              center={true}
              className={classes.width__4}
            >
              낙찰여부
            </Th>
            <Th
              center={true}
              className={classes.width__10}
            >
              비고
            </Th>
          </TableRow>
        </TableHead>
        <TableBody>
          {isListLoading && <Spinner />}
          {!isListLoading && isListEmpty && <NoResult />}
          {!isListLoading &&
            !isListEmpty &&
            bidResultList?.map((item, index, list) => (
              <ProjectSalesInfoBidResultTableRow
                key={item.id}
                item={item}
                index={index}
                list={list}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const NoResult = memo(() => (
  <TableRow>
    <OldTd colSpan={10}>저장된 입찰결과가 없습니다.</OldTd>
  </TableRow>
));

const Spinner = memo(() => (
  <TableRow
    sx={{
      height: '54px',
    }}
  >
    <OldTd colSpan={8}>
      <CircularProgress size={30} />
    </OldTd>
  </TableRow>
));
