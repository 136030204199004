import React, { Suspense, useCallback, useEffect } from 'react';
import NotificationButton from '@front/src/app/view/NotificationButton';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useUserMobileNotificationState } from '@front/user_notification/useState';
import { userNotificationAction } from '@front/user_notification/action';
import { MobileNotificationFeature } from '@front/src/features/mobile-notification';
import { useShallow } from 'zustand/react/shallow';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import notificationQuery from '@front/src/features/notification/query/query';

export default function NotificationButtonRoute() {
  const dispatch = useDispatch();
  // const { count } = useSelector((root: RootState) => root.userNotification);
  const { data: count } = notificationQuery.useGetCount();
  const { pathname } = useLocation();
  const {} = useUserMobileNotificationState(
    useShallow((state) => ({
      setIsMobileNotificationModalOpen: state.setIsMobileNotificationModalOpen,
    }))
  );
  const setLeftDrawerState = useGlobalNavBarState((state) => state.setLeftDrawerState);

  const onModalOpen = useCallback(() => {
    // setIsMobileNotificationModalOpen();
    setLeftDrawerState('notification');
  }, [dispatch, setLeftDrawerState]);

  useEffect(() => {
    dispatch(userNotificationAction.requestCount());
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <LoadingSpinner
          width="2rem"
          height="2rem"
        />
      }
    >
      <MobileNotificationFeature />
      <NotificationButton
        count={count ?? 0}
        onClick={onModalOpen}
      />
    </Suspense>
  );
}
