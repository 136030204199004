import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import { affiliatedCompanyPersonCharacterBasicApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/basic/query/api';
import type { AffiliatedCompanyPersonCharacterBasicUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/basic/types/parameter';

export const affiliatedCompanyPersonCharacterBasicMutation = {
  usePersonCharacterBasicUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyPersonCharacterBasicUpdateParameter) =>
        affiliatedCompanyPersonCharacterBasicApi.updatePersonCharacterBasic(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-company', 'person', 'character', 'basic'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
