import type { ApprovalBoxSearchParams } from '@front/src/features/approval-box/type/parameter';
import { create } from 'zustand';
import { APPROVAL_BOX_TYPE } from '@front/type/domain';
import type { ApprovalDocumentDetailQueryParams } from '@front/src/features/drawer-approval-document/types/parameter';

interface State {
  boxSearchParams: ApprovalBoxSearchParams;
  isBoxList: boolean;
  documentId: number;
  params: ApprovalDocumentDetailQueryParams;
}

interface Action {
  setBoxSearchParams: (params: Partial<ApprovalBoxSearchParams>) => void;
  setIsBoxList: (isBoxList: boolean) => void;
  setDocumentId: (documentId: number) => void;
}

const useApprovalBoxStore = create<State & Action>((set) => ({
  boxSearchParams: {
    type: APPROVAL_BOX_TYPE.APPROVAL_DOCUMENT.toLowerCase(),
    filter: 'all',
  },
  isBoxList: true,
  documentId: 0,
  params: {
    where: 'approval',
  },
  setBoxSearchParams: (params) =>
    set((state) => ({ boxSearchParams: { ...state.boxSearchParams, ...params } })),
  setIsBoxList: (isBoxList) => set({ isBoxList }),
  setDocumentId: (documentId) => set({ documentId }),
}));

export default useApprovalBoxStore;
