export enum CustomHttpStatus {
  NO_AUTHORIZATION = '801',
}
export function getCustomErrorMessage(type: CustomHttpStatus): string {
  switch (type) {
    case CustomHttpStatus.NO_AUTHORIZATION:
      return '접근 권한이 없습니다.';
    default:
      return '-';
  }
}
