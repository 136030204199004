// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Wg1cZYW12T7CpotYjCfW{display:flex;flex-direction:column;gap:20px;width:100%}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/subject-review/features/modal/components/layout/section.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,UAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Wg1cZYW12T7CpotYjCfW`
};
export default ___CSS_LOADER_EXPORT___;
