import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import type { ProjectSalesInfoBidInfoParams } from '@front/src/features/project-sales-info/features/bid/features/bid-information/types/parameter';
import { handleError } from '@front/src/utils';
import { projectSalesInfoBidInfoUpdateModalApi } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/update-modal/query/api';

export const projectSalesInfoBidInfoUpdateModalMutation = {
  useProjectSalesInfoBidInfoUpdate: (id: number) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectSalesInfoBidInfoParams) =>
        projectSalesInfoBidInfoUpdateModalApi.updateBidInfo(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['project-sales-info', 'bid-info', 'list', id]);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
