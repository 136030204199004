import noteQuery from '@front/src/features/note/query/query';
import useNoteStore from '@front/src/features/note/useState';
import { useShallow } from 'zustand/react/shallow';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

const useGetNoteList = () => {
  const menuId = useGlobalNavBarState((state) => state.menuId);
  const dataId = sessionStorage.getItem('dataId');
  const { sectionId, rowId, noteFilter, isUnreadReview, isWaitApproval, keyword } = useNoteStore(
    useShallow((state) => ({
      sectionId: state.sectionId,
      rowId: state.rowId,
      noteFilter: state.noteFilter,
      isUnreadReview: state.isUnreadReview,
      isWaitApproval: state.isWaitApproval,
      keyword: state.keyword,
    }))
  );
  const params = {
    menuId: Number(menuId) || 0,
    dataId: Number(dataId) || 0,
    sectionId,
    rowId,
    noteFilter,
    isUnreadReview,
    isWaitApproval,
    keyword,
  };
  const { data } = noteQuery.useGetList(params);
  return {
    noteList: data?.searchList,
    unreadReviewCount: data?.unreadReviewCount,
    waitApprovalCount: data?.waitApprovalCount,
    sectionName: data?.sectionName,
  };
};

export default useGetNoteList;
