import { shallow } from 'zustand/shallow';
import { useAffiliatedCompanyDetailState } from '@front/src/features/affiliated-company/widgets/detail/useState';
import { useAffiliatedCompanyCharacterPartnerCompanyState } from '@front/src/features/affiliated-company/features/character/features/partner-company/widgets/useState';
import { affiliatedCompanyCharacterPartnerCompanyRepository } from '@front/src/features/affiliated-company/features/character/features/partner-company/repository/repository';

export { useLogic as useAffiliatedCompanyCharacterPartnerCompanyLogic };

const useLogic = (menuId) => {
  const { id } = useAffiliatedCompanyDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { idList, setIdList } = useAffiliatedCompanyCharacterPartnerCompanyState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { data: partnerCompanyList, isLoading } =
    affiliatedCompanyCharacterPartnerCompanyRepository.useCharacterPartnerCompanyGet(id, menuId);
  const { run: onPartnerCompanyCreate } =
    affiliatedCompanyCharacterPartnerCompanyRepository.useCharacterPartnerCompanyCreate(menuId);
  const { run: onPartnerCompanyUpdate } =
    affiliatedCompanyCharacterPartnerCompanyRepository.useCharacterPartnerCompanyUpdate(menuId);
  const { run: onPartnerCompanyDelete } =
    affiliatedCompanyCharacterPartnerCompanyRepository.useCharacterPartnerCompanyDelete(menuId);
  const h = {
    onPartnerCompanyCreate: () => onPartnerCompanyCreate(id),
    onPartnerCompanyUpdate,
    onPartnerCompanyDelete,
    setIdList,
  };
  const d = { partnerCompanyList };
  return {
    d,
    h,
    isLoading,
    idList,
    id,
  };
};
