import React, { memo, useContext } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { OldTd, Table } from '@front/layouts/Table';
import classes from '@front/src/features/project-sales-info/features/collection/features/payment-history/components/payment-history-table.module.scss';
import { ProjectSalesInfoCollectionPaymentHistoryContext } from '@front/src/features/project-sales-info/features/collection/features/payment-history/widgets/context';
import { ProjectSalesInfoCollectionPaymentHistoryTableRow } from '@front/src/features/project-sales-info/features/collection/features/payment-history/components/payment-history-table-row';
import CircularProgress from '@front/components/CircularProgress';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';

export { PaymentHistoryTable as ProjectSalesInfoPaymentHistoryTable };

const PaymentHistoryTable = () => {
  const {
    d: { paymentHistoryList },
    isLoading,
  } = useContext(ProjectSalesInfoCollectionPaymentHistoryContext);
  const isListLoading = typeof paymentHistoryList === 'undefined' || isLoading;
  const isListEmpty = paymentHistoryList?.length === 0;
  return (
    <TableContainer
      sx={{
        ...useGetMaxWidthSx({ hasMemo: true }),
      }}
    >
      <Table>
        <TableHead>
          <TableRow className={classes.bid__th}>
            <Th
              noWrap={true}
              className={classes.width__2}
            >
              {}
            </Th>
            <Th
              noWrap={true}
              className={classes.width__2}
            >
              순번
            </Th>
            <Th
              noWrap={true}
              className={classes.width__8}
            >
              계약 구조사
            </Th>
            <Th
              noWrap={true}
              className={classes.width__6}
            >
              지급요청일
            </Th>
            <Th
              noWrap={true}
              className={classes.width__6}
            >
              세금계산서 발행일
            </Th>
            <Th
              noWrap={true}
              className={classes.width__6}
            >
              지급액
            </Th>
            <Th
              noWrap={true}
              className={classes.width__4}
            >
              지급여부
            </Th>
            <Th
              noWrap={true}
              className={classes.width__4}
            >
              지급일
            </Th>
            <Th
              noWrap={true}
              className={classes.width__4}
            >
              비고
            </Th>
          </TableRow>
        </TableHead>
        <TableBody>
          {isListLoading && <Spinner />}
          {!isListLoading && isListEmpty && <NoResult />}
          {!isListLoading &&
            !isListEmpty &&
            paymentHistoryList?.map((item, index) => (
              <ProjectSalesInfoCollectionPaymentHistoryTableRow
                key={item.id}
                item={item}
                index={index}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const NoResult = memo(() => (
  <TableRow>
    <OldTd colSpan={10}>저장된 입찰정보가 없습니다.</OldTd>
  </TableRow>
));

const Spinner = memo(() => (
  <TableRow
    sx={{
      height: '54px',
    }}
  >
    <OldTd colSpan={10}>
      <CircularProgress size={30} />
    </OldTd>
  </TableRow>
));
