import noteQuery from '@front/src/features/note/query/query';
import useNoteStore from '@front/src/features/note/useState';
import { useShallow } from 'zustand/react/shallow';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

const useGetNoteFilterList = () => {
  const { sectionId, rowId } = useNoteStore(
    useShallow((state) => ({
      sectionId: state.sectionId,
      rowId: state.rowId,
    }))
  );
  const menuId = useGlobalNavBarState((state) => state.menuId);
  const dataId = sessionStorage.getItem('dataId');
  const params = {
    menuId: Number(menuId) || 0,
    dataId: Number(dataId) || 0,
    sectionId,
    rowId,
  };
  const { data } = noteQuery.useGetFilter(params);
  return data?.map((item) => ({
    label: item.label,
    value: item.value,
    count: item.count,
  }));
};

export default useGetNoteFilterList;
