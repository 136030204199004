import React from 'react';
import { Box, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Button from '@front/layouts/Button';
import { Table, OldTh } from '@front/layouts/Table';
import { PlanList } from '@front/ost_proposal/view/detail/advisorForm/table/List';
import type { ProposalUpdateArrangementPlanParameter } from '@front/ost_proposal/parameter';
import type { ProposalArrangementPlanId } from '@front/ost_proposal/domain';
import { ProposalArrangementInformationStatus, ProposalStatus } from '@front/ost_proposal/domain';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { ArticleItem } from '@front/src/components/article-item';

interface Props {
  onAdd: () => void;
  onUpdate: (params: ProposalUpdateArrangementPlanParameter) => void;
  onDelete: (id: ProposalArrangementPlanId) => void;
  isAdvisor: boolean;
}

export { TableLayout as PlanTable };
const TableLayout = ({ onAdd, onUpdate, onDelete, isAdvisor }: Props) => {
  const status = useSelector((root: RootState) => root.proposal.detail!.status, shallowEqual);
  const arrangementStatus = useSelector(
    (root: RootState) => root.proposal.detail!.arrangementStatus,
    shallowEqual
  );
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        width: '100%',
        padding: '10px',
      }}
    >
      <ArticleItem
        title="수행 계획"
        buttonsNode={
          <>
            {isAdvisor &&
              (status === ProposalStatus.WRITING || status === ProposalStatus.SUBMISSION) &&
              arrangementStatus === ProposalArrangementInformationStatus.ON_GOING && (
                <Box
                  sx={{
                    marginLeft: 'auto',
                  }}
                >
                  <Button onClick={onAdd}>추가</Button>
                </Box>
              )}
          </>
        }
        children={
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <OldTh>착수일</OldTh>
                  <OldTh>종료일</OldTh>
                  <OldTh>활동명</OldTh>
                  <OldTh colSpan={isAdvisor ? 2 : 1}>비고</OldTh>
                </TableRow>
              </TableHead>
              <TableBody>
                <PlanList
                  onUpdate={onUpdate}
                  onDelete={onDelete}
                  isAdvisor={isAdvisor}
                />
              </TableBody>
            </Table>
          </TableContainer>
        }
      />
    </Box>
  );
};
