import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { projectSalesInfoBidResultCreateModalRepository } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/create-modal/repository';

export { useLogic as useProjectSalesInfoBidResultCreateModalLogic };
const useLogic = () => {
  const id = useProjectSalesInfoDetailState((state) => state.id);

  const { run: onCreate } =
    projectSalesInfoBidResultCreateModalRepository.useProjectSalesInfoBidResultDetailCreate(id!);
  return {
    h: {
      onCreate,
    },
  };
};
