import React, { Suspense } from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import WorkOutputBundleList from '@front/src/features/work/features/work/features/output-bundle/components/List';

export default function WorkOutputBundleFeature({ ...rest }: Readonly<SectionComponentProps>) {
  const { Layout } = SectionWrapperUI;
  return (
    <Layout>
      <Suspense fallback={<LoadingSpinnerUI />}>
        <WorkOutputBundleList {...rest} />
      </Suspense>
    </Layout>
  );
}
