import React, { useMemo } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { modalWithTab } from '@front/src/features/affiliated-company/components/layout/modal-with-tab';
import { useAffiliatedCompanyPersonDetailModalLogic } from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/useLogic';
import { AffiliatedCompanyPersonDetailModalTab } from '@front/src/features/affiliated-company/features/person/features/detail-modal/components/tab';
import { AffiliatedCompanyPersonDetailModalSectionWidget } from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/section/widget';
import { AffiliatedCompanyPersonDetailModalContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/context';
import { AffiliatedCompanyPersonDeleteButton } from '@front/src/features/affiliated-company/features/person/features/detail-modal/components/delete-button';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { Widget as AffiliatedCompanyPersonDetailModalWidget };

const Widget = () => {
  const { Layout, Header, Section } = modalWithTab;
  const {
    tab,
    isDetailModalOpen,
    modalId: personId,
    h: { setTab, onDetailModalClose, onDelete },
  } = useAffiliatedCompanyPersonDetailModalLogic();
  const value = useMemo(
    () => ({
      h: { setTab, onDelete },
      tab,
      personId,
    }),
    [tab, setTab, personId, onDelete]
  );
  const { readOnly } = useAffiliatedCompanyPersonState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <ModalLayout
      title="인물 상세"
      width="84dvw"
      open={isDetailModalOpen}
      onClose={onDetailModalClose}
    >
      <AffiliatedCompanyPersonDetailModalContext.Provider value={value}>
        <Layout>
          <Header button={<AffiliatedCompanyPersonDeleteButton />}>
            <AffiliatedCompanyPersonDetailModalTab />
          </Header>
          <Section>
            <AffiliatedCompanyPersonDetailModalSectionWidget readOnly={readOnly} />
          </Section>
        </Layout>
      </AffiliatedCompanyPersonDetailModalContext.Provider>
    </ModalLayout>
  );
};
