import React, { useContext, useMemo } from 'react';
import { MyPageAuthorizationMenuContext } from '@front/src/features/my-page/features/authorization/widgets/menu/provider/context';
import { MyPageAuthorizationMenuCommonList } from '@front/src/features/my-page/features/authorization/widgets/menu/components/common-list';
import { useMyPageAuthorizationState } from '@front/src/features/my-page/features/authorization/useState';
import { useShallow } from 'zustand/react/shallow';

export { Depth2List as MyPageAuthorizationMenuDepth2List };

const depth = 2;
const Depth2List = () => {
  const { list } = useContext(MyPageAuthorizationMenuContext);
  const { clicked, setTabId } = useMyPageAuthorizationState(
    useShallow((state) => ({
      clicked: state.clicked,
      setTabId: state.setTabId,
    }))
  );
  const depth2List = useMemo(() => {
    if (!list) return [];
    if (clicked.length <= depth - 2) return [];
    const find = list.find((l) => l.id === clicked[0]);
    if (!find || find.children.some((item) => item.isTab)) return [];
    return find.children;
  }, [list, clicked, setTabId]);
  return (
    <MyPageAuthorizationMenuCommonList
      list={depth2List}
      depth={depth}
    />
  );
};
