import React from 'react';
import { personalSettingsModalUISettingsHeaderSettingsPositionRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/repository/repository';
import { FormProvider, useForm } from 'react-hook-form';
import { PersonalSettingsModalUISettingsHeaderSettingsTableContentDepth1 } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/components/Depth1';
import { Box } from '@mui/material';
import { PersonalSettingsModalUISettingsHeaderSettingsTableContentDepth2 } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/components/Depth2';
import { PersonalSettingsModalUISettingsHeaderSettingsTableContentDepth3 } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/components/Depth3';
import { PersonalSettingsModalUISettingsHeaderSettingsTableContentTab } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/components/Tab';
import { PersonalSettingsModalUISettingsHeaderSettingsTableContentSection } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/components/Section';
import { PersonalSettingsModalUISettingsHeaderSettingsTableContentStatus } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/components/Status';

export { TableContent as PersonalSettingsModalUISettingsHeaderSettingsTableContent };
interface Props {
  authMenuId: number | undefined;
}

const TableContent = ({ authMenuId }: Props) => {
  const { data: list } =
    personalSettingsModalUISettingsHeaderSettingsPositionRepository.useGetHeaderUsageList(
      authMenuId
    );
  const methods = useForm({
    values: {
      depth1: list?.map((l) => ({
        ...l,
        children: l.children,
      })),
      depth2: [],
      depth3: [],
      tab: [],
      section: [],
      status: [],
      depth1Id: undefined,
      depth2Id: undefined,
      depth3Id: undefined,
      tabId: undefined,
    },
  });

  return (
    <FormProvider {...methods}>
      <Box sx={sx}>
        <PersonalSettingsModalUISettingsHeaderSettingsTableContentDepth1 />
      </Box>
      <Box sx={sx}>
        <PersonalSettingsModalUISettingsHeaderSettingsTableContentDepth2 />
      </Box>
      <Box sx={sx}>
        <PersonalSettingsModalUISettingsHeaderSettingsTableContentDepth3 />
      </Box>
      <Box sx={sx}>
        <PersonalSettingsModalUISettingsHeaderSettingsTableContentTab />
      </Box>
      <Box sx={sx}>
        <PersonalSettingsModalUISettingsHeaderSettingsTableContentSection />
      </Box>
      <Box sx={sx}>
        <PersonalSettingsModalUISettingsHeaderSettingsTableContentStatus authMenuId={authMenuId} />
      </Box>
    </FormProvider>
  );
};

const sx = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '1px',
};
