import { projectSalesInfoSubjectReviewExperimentRepository } from '@front/src/features/project-sales-info/features/subject-review/features/experiment/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { shallow } from 'zustand/shallow';

export { useLogic as useProjectSalesInfoSubjectReviewExperimentLogic };

const useLogic = () => {
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { data: detail, isLoading } =
    projectSalesInfoSubjectReviewExperimentRepository.useDetailGet(id);
  const d = { detail };
  const h = {};
  return {
    d,
    h,
    isLoading,
  };
};
