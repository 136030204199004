import React, { useContext } from 'react';
import Button from '@front/layouts/Button';
import { AffiliatedCompanyPersonContext } from '@front/src/features/affiliated-company/features/person/widgets/context';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { CreateButton as AffiliatedCompanyPersonCreateButton };
const CreateButton = () => {
  const {
    h: { onCreateModalOpen },
  } = useContext(AffiliatedCompanyPersonContext);
  const { readOnly } = useAffiliatedCompanyPersonState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Button
      onClick={onCreateModalOpen}
      disabled={readOnly}
    >
      인물추가
    </Button>
  );
};
