import type { ReactNode } from 'react';
import React from 'react';
import classes from '@front/src/components/layout/modal/layout.module.scss';

export { Layout as ModalLayoutLayout };

interface Props {
  children: ReactNode;
}

const Layout = ({ children }: Props) => <div className={classes.container}>{children}</div>;
