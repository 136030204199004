import {
  approvalDocumentUrlPath,
  approvalUrlPath,
} from '@front/src/features/drawer-approval/utils/constants';
import type { AxiosResponse } from 'axios';
import axios from '@front/src/config/axios';
import type {
  ApprovalDecisionUpdateParams,
  ApprovalDocumentDetailQueryParams,
} from '@front/src/features/drawer-approval-document/types/parameter';
import type { ApprovalDocumentDetailView } from '@front/src/features/drawer-approval-document/types/view';

const url = {
  getApprovalDocument: (documentId: number) => `${approvalDocumentUrlPath}/${documentId}`,
  cancelApprovalDocument: (documentId: number) => `${approvalDocumentUrlPath}/${documentId}/cancel`,
  decideApprovalDocument: (approvalId: number) => `${approvalUrlPath}/${approvalId}`,

  getAllFiles: () => '/api/file-item/all',
};

export const approvalDocumentApi = {
  getApprovalDocument: async <T = ApprovalDocumentDetailView>(
    documentId: number,
    params: ApprovalDocumentDetailQueryParams
  ) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getApprovalDocument(documentId), {
      params,
    });
    return data;
  },
  cancelApprovalDocument: async <T = unknown>(documentId: number) =>
    await axios.patch<T, AxiosResponse<T>>(url.cancelApprovalDocument(documentId)),
  decideApprovalDocument: async <T = unknown, R = ApprovalDecisionUpdateParams>(
    approvalId: number,
    params: R
  ) => await axios.patch<T, AxiosResponse<T>, R>(url.decideApprovalDocument(approvalId), params),

  getAllFiles: async <T = unknown>(params: { fileItemIdList: number[] }) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getAllFiles(), { params });
    return data;
  },
};
