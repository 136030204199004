import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { ProjectStepInformationView } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/type/view';
import type { IdDeleteParameter } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/type/parameter';

const url = {
  getProjectDivisionModalData: (id?) => `${projectSalesInfoURL}/${id}/step-information`,
  createProjectDivisionModalData: (id?) => `${projectSalesInfoURL}/${id}/step-information`,
  updateProjectDivisionModalData: (id?) => `${projectSalesInfoURL}/step-information/${id}`,
  deleteProjectDivisionModalData: () => `${projectSalesInfoURL}/step-information`,
};

export const projectSalesInfoProjectDivisionModalApi = {
  getProjectDivisionModalData: async (id?: number) => {
    const { data } = await axios.get(url.getProjectDivisionModalData(id));
    return data;
  },
  createProjectDivisionModalData: async (id?: number) =>
    await axios.post(url.createProjectDivisionModalData(id)),
  updateProjectDivisionModalData: async (params: ProjectStepInformationView) => {
    await axios.put(url.updateProjectDivisionModalData(params.id), params);
  },
  deleteProjectDivisionModalData: async (params: IdDeleteParameter) => {
    await axios.delete(url.deleteProjectDivisionModalData(), { data: params });
  },
};
