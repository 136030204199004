import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { AffiliatedCompanyPersonDetailModalOutlineEducationContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/widgets/provider/context';
import { useAffiliatedCompanyPersonDetailModalOutlineEducationLogic } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/widgets/provider/useLogic';

export { Provider as AffiliatedCompanyPersonDetailModalOutlineEducationProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { onUpdate, onDelete, list, onCreate } =
    useAffiliatedCompanyPersonDetailModalOutlineEducationLogic(menuId);
  const value = useMemo(
    () => ({
      onUpdate,
      onDelete,
      list,
      onCreate,
    }),
    [onUpdate, onDelete, list, onCreate]
  );
  return (
    <AffiliatedCompanyPersonDetailModalOutlineEducationContext.Provider value={value}>
      {children}
    </AffiliatedCompanyPersonDetailModalOutlineEducationContext.Provider>
  );
};
