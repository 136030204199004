import React, { useCallback } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import Checkbox from '@front/layouts/Checkbox';
import {
  convertDateToStringFormat,
  DATE_TIME_FORMAT,
  downloadFile,
  getChecked,
  getLocaleStringIfExist,
  getValueIfExist,
  handleCheckboxChange,
} from '@front/src/utils';
import classes from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/components/estimation-competitor-table-row.module.scss';
import { shallow } from 'zustand/shallow';
import type { ProjectEstimationCompetitorView } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/types/view';
import { useProjectSalesInfoEstimationCompetitorState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/widgets/useState';
import { useProjectSalesInfoEstimationCompetitorUpdateModalState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/features/update-modal/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { EstimationCompetitorTableRow as ProjectSalesInfoEstimationCompetitorTableRow };

interface Props {
  item: ProjectEstimationCompetitorView;
  index: number;
}

const EstimationCompetitorTableRow = ({ item, index }: Props) => {
  const { idList, setIdList } = useProjectSalesInfoEstimationCompetitorState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { setIsOpen, setId } = useProjectSalesInfoEstimationCompetitorUpdateModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      setId: state.setId,
    }),
    shallow
  );
  const { readOnly } = useProjectSalesInfoEstimationCompetitorState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const handleCodeClick = useCallback(
    (id) => {
      setId(id);
      setIsOpen(true);
    },
    [setIsOpen, setId]
  );
  return (
    <TableRow>
      <OldTd>
        <Checkbox
          checked={getChecked(item.id, idList)}
          onChange={() => handleCheckboxChange(item.id, idList, setIdList)}
          disabled={readOnly}
        />
      </OldTd>
      <OldTd>{index + 1}</OldTd>
      <OldTd>
        <div
          className={classes.name}
          onClick={() => handleCodeClick(item.id)}
          aria-hidden
        >
          {getValueIfExist(item.company?.name)}
        </div>
      </OldTd>
      <OldTd>{getValueIfExist(item.clientCompany?.name)}</OldTd>
      <OldTd>{getValueIfExist(item.experimentInformation)}</OldTd>
      <OldTd>{getLocaleStringIfExist(item.experimentAmount)}</OldTd>
      <OldTd>{getLocaleStringIfExist(item.reviewAmount)}</OldTd>
      <OldTd>{getLocaleStringIfExist(item.totalAmount)}</OldTd>
      <OldTd>{getLocaleStringIfExist(item.unitPrice)}</OldTd>
      <OldTd>{item.date}</OldTd>
      <OldTd>
        {item.pdf?.id ? (
          <div
            className={classes.file}
            onClick={downloadFile(item.pdf?.id)}
            aria-hidden
          >
            {item.pdf?.filename}
          </div>
        ) : (
          '-'
        )}
      </OldTd>
      <OldTd>{convertDateToStringFormat(item.createdAt, DATE_TIME_FORMAT)}</OldTd>
      <OldTd>{getValueIfExist(item.writer?.name)}</OldTd>
      <OldTd>{getValueIfExist(item.note)}</OldTd>
    </TableRow>
  );
};
