import { create } from 'zustand';
import type { AuthUserSearchParams } from '@front/src/features/admin/features/access-authorization-settings/type/parameter';
import type {
  AuthPersonalSettingsModalCreateDTO,
  AuthPersonalSettingsModalUpdateDTO,
} from '@front/src/features/personal-settings-modal/features/auth-settings/types/parameter';

export { useState as usePersonalSettingsModalState };

interface State {
  searchKeyword: AuthUserSearchParams;
  anchorEls: {
    [key: string]: HTMLElement | null;
  };
  editOpenList: number[];
  coloredId: number;
  newItemIdList: number[];
  deleteList: number[];
  tab: string;
  authType: string;
}

interface Actions {
  setSearchKeyword: (searchKeyword: AuthUserSearchParams) => void;
  setAnchorEls: (anchorEls: { [key: string]: HTMLElement | null }) => void;
  setEditOpenList: (editId?: number) => void;
  clearEditOpenList: () => void;
  setColoredId: (coloredId: number) => void;
  setNewItemIdList: (newItemIdList: number[]) => void;
  createList: AuthPersonalSettingsModalCreateDTO[];
  setCreateList: (createList: AuthPersonalSettingsModalCreateDTO[]) => void;
  updateCreateList: (data: AuthPersonalSettingsModalCreateDTO) => void;
  updateList: AuthPersonalSettingsModalUpdateDTO[];
  setUpdateList: (updateList: AuthPersonalSettingsModalUpdateDTO[]) => void;
  updateUpdateList: (data: AuthPersonalSettingsModalUpdateDTO) => void;
  setDeleteList: (deleteList: number[]) => void;
  setTab: (tab: string) => void;
  setAuthType: (authType: string) => void;
}

const useState = create<State & Actions>((set) => ({
  searchKeyword: { keyword: '' },
  setSearchKeyword: (searchKeyword: AuthUserSearchParams) => set(() => ({ searchKeyword })),
  anchorEls: {},
  setAnchorEls: (anchorEls: { [key: string]: HTMLElement | null }) => set(() => ({ anchorEls })),
  editOpenList: [],
  setEditOpenList: (editId?: number) =>
    set((state) => {
      let newList: number[];
      if (!editId) newList = [];
      else {
        if (state.editOpenList.includes(editId))
          newList = state.editOpenList.filter((item) => item !== editId);
        else newList = [...state.editOpenList, editId];
      }
      return {
        editOpenList: newList,
      };
    }),
  clearEditOpenList: () => set(() => ({ editOpenList: [] })),
  coloredId: 0,
  setColoredId: (coloredId: number) => set(() => ({ coloredId })),
  newItemIdList: [],
  setNewItemIdList: (newItemIdList: number[]) => set(() => ({ newItemIdList })),
  createList: [],
  setCreateList: (createList: AuthPersonalSettingsModalCreateDTO[]) => set(() => ({ createList })),
  updateCreateList: (data: AuthPersonalSettingsModalCreateDTO) =>
    set((state) => ({
      createList: state.createList.map((item) =>
        item.userId === data.userId
          ? {
              ...item,
              type: data.type,
            }
          : item
      ),
    })),
  updateList: [],
  setUpdateList: (updateList: AuthPersonalSettingsModalUpdateDTO[]) => set(() => ({ updateList })),
  updateUpdateList: (data: AuthPersonalSettingsModalUpdateDTO) =>
    set((state) => ({
      updateList: state.updateList.map((item) =>
        item.userId === data.userId
          ? {
              ...item,
              type: data.type,
            }
          : item
      ),
    })),
  deleteList: [],
  setDeleteList: (deleteList: number[]) => set(() => ({ deleteList })),
  tab: '권한설정',
  setTab: (tab: string) => set(() => ({ tab })),
  authType: '개인권한',
  setAuthType: (authType: string) => set(() => ({ authType })),
  // reset: () =>
  //   set(() => ({
  //     isSettingsModalOpen: false,
  //     searchKeyword: { keyword: '' },
  //     anchorEls: {},
  //     editOpenList: [],
  //     coloredId: 0,
  //     newItemIdList: [],
  //     deleteList: [],
  //     tab: '권한설정',
  //     authType: '개인권한',
  //   })),
}));
