import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAccountingSettingDailyReportStatusListLogic } from '@front/src/features/accounting/features/settings/features/daily-report-status/widgets/list/provider/useLogic';
import { AccountingSettingsDailyReportStatusListContext } from '@front/src/features/accounting/features/settings/features/daily-report-status/widgets/list/provider/context';

export { Provider as AccountingSettingsDailyReportStatusListProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { list, sumList } = useAccountingSettingDailyReportStatusListLogic();
  const value = useMemo(() => ({ list, sumList }), [list, sumList]);
  return (
    <AccountingSettingsDailyReportStatusListContext.Provider value={value}>
      {children}
    </AccountingSettingsDailyReportStatusListContext.Provider>
  );
};
