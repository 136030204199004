import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import type { WorkCarouselCreateParameter } from '@front/src/features/work/features/work/features/carousel/types/parameter';
import { WorkCarouselPosition } from '@front/src/features/work/features/work/features/carousel/uilts/constant';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import {
  ModalBodyUI,
  ModalContentUI,
} from '@front/src/components/components-with-design/layout/ModalUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import RadioWithHookForm from '@front/src/components/hook-form/Radio';
import WorkCarouselInputFile from '@front/src/features/work/features/work/features/carousel/components/InputFile';
import WorkCarouselCreateFormButtonGroup from '@front/src/features/work/features/work/features/carousel/components/CreateFormButtonGroup';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {
  onCreate: (params: WorkCarouselCreateParameter) => void;
  onClose: () => void;
}

export default function WorkCarouselCreateForm({ ...rest }: Readonly<Props>) {
  const methods = useForm({
    values: {
      position: '',
      images: [],
    },
  });
  return (
    <FormProvider {...methods}>
      <ModalBodyUI>
        <WorkCarouselCreateFormButtonGroup {...rest} />
        <ModalContentUI>
          <TableContainerUI>
            <TableTableUI>
              <TableBodyUI>
                <TableRowUI>
                  <TableCellUI
                    isHead
                    width="200px"
                  >
                    추가 위치
                  </TableCellUI>
                  <TableCellUI align="left">
                    <RadioWithHookForm
                      required="추가 위치를 선택해주세요."
                      name="position"
                      radioList={radioList}
                    />
                  </TableCellUI>
                </TableRowUI>
                <TableRowUI>
                  <TableCellUI isHead>이미지</TableCellUI>
                  <TableCellUI>
                    <WorkCarouselInputFile />
                  </TableCellUI>
                </TableRowUI>
              </TableBodyUI>
            </TableTableUI>
          </TableContainerUI>
        </ModalContentUI>
      </ModalBodyUI>
    </FormProvider>
  );
}

const radioList = [
  {
    label: '맨 앞',
    value: WorkCarouselPosition.FRONT,
  },
  {
    label: '맨 뒤',
    value: WorkCarouselPosition.BACK,
  },
];
