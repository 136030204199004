import { useQuery } from 'react-query';
import { projectSalesInfoAbstractApi } from '@front/src/features/project-sales-info/features/abstract/query/api';
import type { SmProjectBriefCollectionView } from '@front/src/features/project-sales-info/features/abstract/features/collection/types/view';

export { query as projectSalesInfoAbstractCollectionQuery };
const query = {
  useListGet: (id?) => {
    const { data, remove } = useQuery<SmProjectBriefCollectionView[]>({
      queryKey: ['project', 'sales-info', 'abstract', 'collection', id],
      queryFn: () => projectSalesInfoAbstractApi.getCollectionList(id),
      enabled: !!id,
      suspense: true,
    });
    return {
      data,
      remove,
    };
  },
};
