import { Header } from '@front/src/features/project-sales-info/features/collection/components/layout/header';
import { Layout } from '@front/src/features/project-sales-info/features/collection/components/layout/layout';
import { Body } from '@front/src/features/project-sales-info/features/collection/components/layout/body';
import { Footer } from '@front/src/features/project-sales-info/features/collection/components/layout/footer';

export const collectionModalLayout = {
  Layout,
  Body,
  Footer,
  Header,
};
