import React from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { AffiliatedCompanyMeetingHistoryTableBody } from '@front/src/features/affiliated-company/features/meeting-history/components/meeting-history-table-body';

export { MeetingHistoryTable as AffiliatedCompanyMeetingHistoryTable };

const MeetingHistoryTable = () => (
  <TableContainer>
    <Table>
      <TableHeadRow />
      <TableBody>
        <AffiliatedCompanyMeetingHistoryTableBody />
      </TableBody>
    </Table>
  </TableContainer>
);

const TableHeadRow = () => (
  <TableHead>
    <TableRow>
      <Th
        center
        width="40px"
      >
        {' '}
      </Th>
      <Th
        center
        width="40px"
      >
        No.
      </Th>
      <Th
        center
        width="120px"
      >
        날짜
      </Th>
      <Th
        center
        width="100px"
      >
        대상
      </Th>
      <Th
        center
        width="100px"
      >
        한풍참석자
      </Th>
      <Th
        center
        width="100px"
      >
        목적
      </Th>
      <Th
        center
        width="200px"
      >
        관련PJT
      </Th>
      <Th
        center
        width="400px"
      >
        미팅 요약
      </Th>
    </TableRow>
  </TableHead>
);
