import React, { useMemo } from 'react';
import { useProjectSalesInfoContractEarningsItemUpdateModalLogic } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/widgets/useLogic';
import { ProjectSalesInfoContractEarningsItemUpdateModalContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/widgets/context';

export { Provider as ProjectSalesInfoContractEarningsItemUpdateModalProvider };

const Provider = ({ children }: { children: React.ReactNode }) => {
  const {
    h: { onUpdate },
    d: { consortiumCompanyList, contractEarningsItemDetails, confirmedContractDetail },
    isLoading,
    remove,
  } = useProjectSalesInfoContractEarningsItemUpdateModalLogic();
  const value = useMemo(
    () => ({
      h: {
        onUpdate,
      },
      d: {
        consortiumCompanyList,
        contractEarningsItemDetails,
        confirmedContractDetail,
      },
      isLoading,
      remove,
    }),
    [
      onUpdate,
      consortiumCompanyList,
      contractEarningsItemDetails,
      isLoading,
      confirmedContractDetail,
    ]
  );
  return (
    <ProjectSalesInfoContractEarningsItemUpdateModalContext.Provider value={value}>
      {' '}
      {children}
    </ProjectSalesInfoContractEarningsItemUpdateModalContext.Provider>
  );
};
