import type { CampaignVO } from '@front/ost_campaign/domain';
import type { UserId, UserVO } from '@front/user/domain';

export enum OstStatus {
  READY = 'READY',
  ON_GOING = 'ON_GOING',
  DONE = 'DONE',
}

export const ostStatusList = [OstStatus.READY, OstStatus.ON_GOING, OstStatus.DONE];

export const ostStatusName = (status: OstStatus) => {
  switch (status) {
    case OstStatus.READY:
      return '준비';
    case OstStatus.ON_GOING:
      return '진행';
    case OstStatus.DONE:
      return '종료';
    default:
      return '-';
  }
};

export type OstId = number & { readonly _brand: symbol };

export interface OstShortVO {
  id: OstId;
  name: string;
  status: OstStatus;
  alarmCount: number;
}

export interface OstVO extends OstShortVO {
  isFavorite: boolean;
  startDate?: string;
  endDate?: string;
  totalBudget?: number;
  campaignList?: CampaignVO[];
  unspentBudget?: number;
  trimmedMeanBottomRate?: number;
  trimmedMeanTopRate?: number;
}

export type OstMemoId = number & { readonly _brand: symbol };

export interface OstMemoVO {
  id: OstMemoId;
  description: string;
  writer: UserVO;
  createdAt: Date;
  modifiedAt?: Date;
  attendanceList: UserId[];
  isOpenAttendanceList: boolean;
}
