import React from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import type { WorkScheduleInterimReportDateParameter } from '@front/src/features/work/features/work/features/schedule/features/interim-report-date/types/parameter';
import WorkInterimReportDateRow from '@front/src/features/work/features/work/features/schedule/features/interim-report-date/components/Row';
import workInterimReportDateQuery from '@front/src/features/work/features/work/features/schedule/features/interim-report-date/query/query';

interface Props extends SectionComponentProps {
  onCreate: () => void;
  onUpdate: (params: WorkScheduleInterimReportDateParameter) => void;
  onDelete: (id: number) => void;
}

export default function WorkInterimReportDateFeature({ dataId, menuId, ...rest }: Readonly<Props>) {
  const { data: list } = workInterimReportDateQuery.useGetList(dataId, menuId);
  return (
    <>
      {list?.map((item, index) => (
        <WorkInterimReportDateRow
          item={item}
          index={index}
          key={item.id}
          size={list?.length}
          dataId={dataId}
          menuId={menuId}
          {...rest}
        />
      ))}
    </>
  );
}
