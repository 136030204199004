import { default as axios } from '@front/src/config/axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';

const url = {
  getList: (id?) => `${projectSalesInfoURL}/${id}/consortium-company`,
  delete: (id?) => `${projectSalesInfoURL}/consortium-company/${id}`,
};

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyApi = {
  getList: async (id?: number) => {
    const { data } = await axios.get(url.getList(id));
    return data;
  },
  delete: async (id: number) => {
    await axios.delete(url.delete(id));
  },
};
