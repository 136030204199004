import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import Fade from 'components/Fade';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material';

interface Props {
  openedWidth?: number;
  closedWidth?: number;
  open: boolean;
  children: ReactNode;
  sx?: SxProps<Theme>;
  closedChildren?: ReactNode;
}

export default function Drawer({
  openedWidth,
  closedWidth,
  open,
  children,
  sx,
  closedChildren,
}: Props) {
  const AppDrawer = useMemo(
    () =>
      styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
        '& .MuiDrawer-paper': {
          zIndex: theme.zIndex.drawer + 1,
          padding: 0,
          position: 'relative',
          whiteSpace: 'nowrap',
          border: 'none',
          width: `${openedWidth ?? 260}px`,
          // paddingTop: '50px',
          overflowY: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          boxSizing: 'border-box',
          ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            width: `${closedWidth ?? 0}px`,
          }),
          ...(sx ?? {}),
        },
      })),
    [openedWidth, closedWidth]
  );
  return (
    <AppDrawer
      variant="permanent"
      open={open}
      sx={sx}
    >
      {!open && <>{closedChildren}</>}
      <Fade
        in={open}
        timeout={{
          enter: 0,
        }}
      >
        {children}
      </Fade>
    </AppDrawer>
  );
}
