import React, { memo } from 'react';
import CircularProgress from '@front/components/CircularProgress';

export { LoadingSpinner };

interface Props {
  width?: string;
  height?: string;
  padding?: string;
}

const LoadingSpinner = memo(({ width, height, padding }: Props) => (
  <div
    style={{
      width,
      height,
      padding,
    }}
  >
    <CircularProgress size={30} />
  </div>
));

LoadingSpinner.displayName = 'LoadingSpinner';
