import { AccountingSettingsBankAccountRepository } from '@front/src/features/accounting/features/settings/features/bank-account/repository/repository';

export { useLogic as useAccountingSettingsBankAccountListLogic };

const useLogic = () => {
  const { data: list } = AccountingSettingsBankAccountRepository.useGetList();
  const { run: onUpdate } = AccountingSettingsBankAccountRepository.useUpdate();
  return {
    list,
    onUpdate,
  };
};
