import type { AdminRoleNameView, UserVO } from 'user/domain';

import type { DepartmentId } from 'department/domain';

export interface UserChangeParameter
  extends Omit<
    UserVO,
    'department' | 'createdAt' | 'loginAt' | 'passwordChangedAt' | 'username' | 'profile'
  > {
  departmentId: DepartmentId;
}

export interface UserInviteParameter {
  email: string;
  name?: string;
  employeeId?: string;
  departmentId?: number;
  role: AdminRoleNameView;
  authKey?: string;
}

export const initialUserParameter = {
  edit: false,
} as unknown as UserChangeParameter;

export interface UserPasswordChangeParameter {
  username: string;
}

export interface UserAddParameter {
  name: string;
  username: string;
  password: string;
  email: string;
  authKey: string;
}
