import { call, delay, fork, put, select, take } from 'redux-saga/effects';
import type { ProposalShortVO } from '@front/ost_proposal/domain';
import { getErrorCode, getErrorMessage } from '@front/type/Error';
import { dialogAction } from '@front/dialog/action';
import type { RootState } from '@front/services/reducer';
import { userNotificationAction } from '@front/user_notification/action';
import { arrangementAction } from '@front/ost_arrangement/action';
import { arrangementApi } from '@front/ost_arrangement/api';

import { CustomHttpStatus, getCustomErrorMessage } from '@front/common/type/http-status.type';

/**
 * 제안 목록 및 검색 관련 saga
 */
function* watchFilter() {
  while (true) {
    const { payload: query } = yield take(arrangementAction.setFilter);
    yield put(arrangementAction.setLoading(true));
    try {
      const list: ProposalShortVO[] = yield call(arrangementApi.getProposalList, query);
      yield put(arrangementAction.setList(list));
    } catch (e) {
      const message = getErrorMessage(arrangementAction.setFilter, e);
      yield put(dialogAction.openError(message));
    } finally {
      yield delay(500);
      yield put(arrangementAction.setLoading(false));
    }
  }
}

function* watchAddMemo() {
  while (true) {
    const { payload: params } = yield take(arrangementAction.addMemo);
    const id = yield select((root: RootState) => root.proposal.id);
    const memoFilter = yield select((root: RootState) => root.arrangement.memoFilter);
    try {
      yield call(arrangementApi.addMemo, id, params);
      // yield put(arrangementAction.setId(id));
      yield put(arrangementAction.setMemoFilter(memoFilter));
      if (Array.isArray(params.attendanceList) && params.attendanceList.length > 0) {
        yield put(userNotificationAction.requestCount());
      }
    } catch (e) {
      const message = getErrorMessage(arrangementAction.addMemo, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchFilterMemo() {
  while (true) {
    const { payload: params } = yield take(arrangementAction.setMemoFilter);
    yield put(arrangementAction.setMemoLoading(true));
    const id = yield select((root: RootState) => root.proposal.id);
    try {
      const list = yield call(arrangementApi.getMemoList, id, params);
      yield put(arrangementAction.setMemoList(list));
    } catch (e) {
      const message = getErrorMessage(arrangementAction.setMemoFilter, e);
      const code = getErrorCode(arrangementAction.setMemoFilter, e);
      if (code === CustomHttpStatus.NO_AUTHORIZATION) {
        yield put(dialogAction.openBlock(getCustomErrorMessage(CustomHttpStatus.NO_AUTHORIZATION)));
        return;
      }
      yield put(dialogAction.openError(message));
    } finally {
      yield put(arrangementAction.setMemoLoading(false));
    }
  }
}

function* watchChangeMemo() {
  while (true) {
    const { payload: params } = yield take(arrangementAction.changeMemo);
    const id = yield select((root: RootState) => root.proposal.id);
    const memoFilter = yield select((root: RootState) => root.arrangement.memoFilter);
    try {
      yield call(arrangementApi.changeMemo, id, params);
      yield put(arrangementAction.setMemoFilter(memoFilter));
    } catch (e) {
      const message = getErrorMessage(arrangementAction.addMemo, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchMemoDelete() {
  while (true) {
    const { payload: memoId } = yield take(arrangementAction.deleteMemo);
    const id = yield select((root: RootState) => root.proposal.id);
    const memoFilter = yield select((root: RootState) => root.arrangement.memoFilter);
    try {
      yield call(arrangementApi.deleteMemo, id, memoId);
      yield put(arrangementAction.setMemoFilter(memoFilter));
    } catch (e) {
      const message = getErrorMessage(arrangementAction.deleteMemo, e);
      yield put(dialogAction.openError(message));
    }
  }
}

export default function* arrangementSaga() {
  yield fork(watchChangeMemo);
  yield fork(watchAddMemo);
  yield fork(watchFilterMemo);
  yield fork(watchMemoDelete);
  yield fork(watchFilter);
}
