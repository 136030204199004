import { EducationLevel } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/types/domain';

export const educationLevelObjectFormLabel = {
  [EducationLevel.ELEMENTARY_SCHOOL]: '초등학교',
  [EducationLevel.MIDDLE_SCHOOL]: '중학교',
  [EducationLevel.HIGH_SCHOOL]: '고등학교',
  [EducationLevel.UNIVERSITY]: '대학',
  [EducationLevel.MASTER_GRADUATE_SCHOOL]: '대학원-석사',
  [EducationLevel.PHD_GRADUATE_SCHOOL]: '대학원-박사',
};

export const educationLevelOptionList = [
  {
    label: educationLevelObjectFormLabel[EducationLevel.ELEMENTARY_SCHOOL],
    value: EducationLevel.ELEMENTARY_SCHOOL,
  },
  {
    label: educationLevelObjectFormLabel[EducationLevel.MIDDLE_SCHOOL],
    value: EducationLevel.MIDDLE_SCHOOL,
  },
  {
    label: educationLevelObjectFormLabel[EducationLevel.HIGH_SCHOOL],
    value: EducationLevel.HIGH_SCHOOL,
  },
  {
    label: educationLevelObjectFormLabel[EducationLevel.UNIVERSITY],
    value: EducationLevel.UNIVERSITY,
  },
  {
    label: educationLevelObjectFormLabel[EducationLevel.MASTER_GRADUATE_SCHOOL],
    value: EducationLevel.MASTER_GRADUATE_SCHOOL,
  },
  {
    label: educationLevelObjectFormLabel[EducationLevel.PHD_GRADUATE_SCHOOL],
    value: EducationLevel.PHD_GRADUATE_SCHOOL,
  },
];

export const affiliatedCompanyPersonEducationTableHeaderNameList = [
  {
    width: '20px',
    value: '',
  },
  {
    width: '140px',
    value: '교육과정',
  },
  {
    width: '100px',
    value: '학교명',
  },
  {
    width: '100px',
    value: '전공',
  },
  {
    width: '100px',
    value: '학번',
  },
  {
    width: '100px',
    value: '지도교수 / 담임',
  },
  {
    width: '200px',
    value: '비고',
  },
];
