import type { ReactNode } from 'react';
import React from 'react';
import { Box } from '@mui/material';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material/styles';

export { Body as AddDeleteTableBody };

interface Props {
  children: ReactNode;
  overflow?: boolean;
  sx?: SxProps<Theme>;
}

const Body = ({ children, overflow, sx }: Props) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      overflow: overflow ? 'auto' : 'visible',
      ...sx,
    }}
  >
    {children}
  </Box>
);
