import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { projectSalesInfoContractContractCreateModalQuery } from '@front/src/features/project-sales-info/features/contract/features/contract/features/create-modal/query/query';
import { projectSalesInfoContractContractCreateModalMutation } from '@front/src/features/project-sales-info/features/contract/features/contract/features/create-modal/query/mutation';

export { repository as projectSalesInfoContractContractCreateModalRepository };

const repository = {
  useDetailGet: (id?: number) => {
    const { data, isLoading } = projectSalesInfoContractContractCreateModalQuery.useDetailGet(id);
    return {
      data,
      isLoading,
    };
  },
  useCreate: () => {
    const { mutate } = projectSalesInfoContractContractCreateModalMutation.useCreate();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
