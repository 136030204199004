import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedPersonMemoState } from '@front/src/features/affiliated-person/features/memo/useState';
import { useFormContext } from 'react-hook-form';
import { AffiliatedPersonMemoListUpdateForm } from '@front/src/features/affiliated-person/features/memo/widgets/list/components/update-form';
import { AffiliatedPersonMemoContent } from '@front/src/features/affiliated-person/features/memo/widgets/list/components/content';

export { ItemBody as AffiliatedPersonMemoItemBody };

const ItemBody = () => {
  const { getValues } = useFormContext();
  const { id: memoId } = useAffiliatedPersonMemoState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  if (getValues('memoId') === memoId) {
    return <AffiliatedPersonMemoListUpdateForm />;
  }
  return <AffiliatedPersonMemoContent />;
};
