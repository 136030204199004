import { createReducer } from 'typesafe-actions';
import { NavigationActionType } from '@front/navigate/action';
import type { CurrentPage } from '@front/navigate/domain';

export interface NavigationState {
  to?: string;
  currentPage?: CurrentPage;
}

const initial: NavigationState = {};

export const navigationReducer = createReducer(initial, {
  [NavigationActionType.navigate]: (state, action) => ({
    ...state,
    to: action.payload,
  }),
  [NavigationActionType.setCurrentPage]: (state, action) => ({
    ...state,
    currentPage: action.payload,
  }),
});
