import React, { memo, useMemo } from 'react';
import CircularProgress from '@front/components/CircularProgress';
import { AffiliatedCompanyOutlineShortTable } from '@front/src/features/affiliated-company/features/outline/features/short/components/short-table';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useAffiliatedCompanyOutlineShortLogic } from '@front/src/features/affiliated-company/features/outline/features/short/widgets/useLogic';
import { AffiliatedCompanyOutlineShortContext } from '@front/src/features/affiliated-company/features/outline/features/short/widgets/context';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AffiliatedCompanyOutlineShortWidget = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    d: { detailOutline, categoryOptionList },
    h: { onOutlineShortUpdate },
    isLoading,
  } = useAffiliatedCompanyOutlineShortLogic(menuId);
  const value = useMemo(
    () => ({
      d: { detailOutline, categoryOptionList },
      h: { onOutlineShortUpdate },
    }),
    [onOutlineShortUpdate, detailOutline, categoryOptionList]
  );
  if (typeof detailOutline === 'undefined' || isLoading) {
    return <Spinner />;
  }
  return (
    <AffiliatedCompanyOutlineShortContext.Provider value={value}>
      <Layout>
        <Header title={name} />
        <Body>
          <AffiliatedCompanyOutlineShortTable
            key={`${detailOutline.name} ${detailOutline.oldName} ${detailOutline.category} ${detailOutline.status}`}
          />
        </Body>
      </Layout>
    </AffiliatedCompanyOutlineShortContext.Provider>
  );
};

const Spinner = memo(() => {
  const { Layout } = AddDeleteTable;
  return (
    <Layout height="174px">
      <CircularProgress size={30} />
    </Layout>
  );
});

Spinner.displayName = 'AffiliatedCompanyOutlineShortWidgetSpinner';

export default AffiliatedCompanyOutlineShortWidget;
