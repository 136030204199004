import { createContext } from 'react';
import type { SmProjectBriefContractView } from '@front/src/features/project-sales-info/features/abstract/features/contract/types/view';

interface State {
  detail?: SmProjectBriefContractView;
}

export { Context as ProjectSalesInfoAbstractContractDetailContext };

const Context = createContext<State>({});
