import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import { default as axios } from '@front/src/config/axios';

import type { IdListDeleteParameter } from '@front/src/types/parameter';

const url = {
  getCMPaymentHistory: (id: number) => `${projectSalesInfoURL}/${id}/cm-amount-payment-history`,
  deleteCMPaymentHistory: () => `${projectSalesInfoURL}/cm-amount-payment-history`,
};

export const projectSalesInfoCMPaymentHistoryApi = {
  getCMPaymentHistory: async (id: number) => {
    const { data } = await axios.get(url.getCMPaymentHistory(id));
    return data;
  },
  deleteCMPaymentHistory: async (params: IdListDeleteParameter) => {
    await axios.delete(url.deleteCMPaymentHistory(), { data: params });
  },
};
