import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { AffiliatedCompanyPersonDetailModalOutlineCareerContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/widgets/context';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedCompanyPersonDetailModalOutlineState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/section/outline/useState';

export { CareerButton as AffiliatedCompanyPersonDetailModalOutlineCareerButton };
const CareerButton = () => {
  const {
    h: { onCareerCreate, onCareerDelete, setIdList },
    idList,
  } = useContext(AffiliatedCompanyPersonDetailModalOutlineCareerContext);
  const handleDeleteClick = useCallback(() => {
    onCareerDelete({ idList });
    setIdList([]);
  }, [onCareerDelete, idList, setIdList]);
  const { readOnly } = useAffiliatedCompanyPersonDetailModalOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      <Button
        onClick={handleDeleteClick}
        disabled={readOnly}
      >
        선택삭제
      </Button>
      <Button
        onClick={onCareerCreate}
        disabled={readOnly}
      >
        경력추가
      </Button>
    </>
  );
};
