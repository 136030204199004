import React from 'react';
import { OldTd } from '@front/layouts/Table';
import { TableRow } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import ProposalHistoryItem from '@front/personnel/view/Page/Log/Proposal/Item';

const ProposalHistoryList = () => {
  const [proposalLogList] = useSelector(
    (root: RootState) => [root.personnel.proposalLogList],
    shallowEqual
  );
  return (
    <>
      {proposalLogList.length === 0 ? (
        <TableRow>
          <OldTd colSpan={10}>{'제안 이력이 없습니다.'}</OldTd>
        </TableRow>
      ) : (
        <>
          {proposalLogList.map((item, index) => (
            <ProposalHistoryItem
              key={item.id}
              item={item}
              index={index}
            />
          ))}
        </>
      )}
    </>
  );
};

export default ProposalHistoryList;
