import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import NotificationControlPanel from '@front/src/features/notification/components/ControlPanel';
import { ColorPalette } from '@front/assets/theme';
import type { NotificationView } from '@front/src/features/notification/types/view';
import { notificationTypeInKor } from '@front/src/features/notification/utils/constants';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from '@front/src/utils';

interface Props {
  item: NotificationView;
}

export default function NotificationCard({ item }: Props) {
  return (
    <Card
      sx={{
        padding: '5px',
        background: item.readAt ? ColorPalette._e4e9f2 : '',
        opacity: item.readAt ? 0.6 : 1
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          gap="5px"
        >
          <NotificationControlPanel
            notificationId={item.id}
            isRead={!!item.readAt}
            isBookmarked={item.isBookmarked}
            isDeleted={item.isDeleted}
          />
          <Typography
            sx={{
              border: '1px solid',
              borderRadius: '20px',
              padding: '3px 15px',
              fontSize: '12px',
              color: item.readAt ? ColorPalette._4c576d : '',
            }}
          >
            {notificationTypeInKor[item.type]}
          </Typography>
        </Box>
        <Box
          display="flex"
          gap="5px"
        >
          <Typography
            fontSize="12px"
            color={item.readAt ? ColorPalette._4c576d : ColorPalette._9b9ea4}
          >
            {dayjs(item.createdAt).format(DATE_TIME_FORMAT)}
          </Typography>
          {item.isBookmarked && <BookmarkIcon />}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap="10px"
        padding="5px 0 0 5px"
      >
        <Typography
          fontSize="16px"
          fontWeight="bold"
          color={item.readAt ? ColorPalette._4c576d : ''}
          sx={{
            textDecoration: item.isDeleted ? 'line-through' : '',
          }}
        >
          {item.title}
        </Typography>
        <Typography
          fontSize="12px"
          color={item.readAt ? ColorPalette._4c576d : ColorPalette._9b9ea4}
          sx={{
            textDecoration: item.isDeleted ? 'line-through' : '',
          }}
        >
          {item.contents}
        </Typography>
        <Typography
          fontSize="12px"
          color={item.readAt ? ColorPalette._4c576d : ColorPalette._9b9ea4}
        >
          {item.source}
        </Typography>
      </Box>
    </Card>
  );
}
