import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import { default as axios } from '@front/src/config/axios';

const url = {
  getProjectInfo: (id) => `${projectSalesInfoURL}/${id}/brief/project-info`,
  getAffiliatedCompanyList: (id) => `${projectSalesInfoURL}/${id}/brief/person-list`,
  getEstimation: (id) => `${projectSalesInfoURL}/${id}/brief/estimation`,
  getContract: (id) => `${projectSalesInfoURL}/${id}/brief/contract`,
  getCollectionList: (id) => `${projectSalesInfoURL}/${id}/brief/collection-list`,
};

export { api as projectSalesInfoAbstractApi };
const api = {
  getProjectInfo: async (id) => {
    const { data } = await axios.get(url.getProjectInfo(id));
    return data;
  },
  getAffiliatedCompanyList: async (id) => {
    const { data } = await axios.get(url.getAffiliatedCompanyList(id));
    return data;
  },
  getEstimation: async (id) => {
    const { data } = await axios.get(url.getEstimation(id));
    return data;
  },
  getContract: async (id) => {
    const { data } = await axios.get(url.getContract(id));
    return data;
  },
  getCollectionList: async (id) => {
    const { data } = await axios.get(url.getCollectionList(id));
    return data;
  },
};
