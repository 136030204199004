import React, { useCallback } from 'react';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/widgets/useState';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import Checkbox from '@front/layouts/Checkbox';
import { spliceAndPushPersonIdIfExist } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/utils';
import classes from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/selected-part-section-table-body.module.scss';
import { ColorPalette } from '@front/assets/theme';
import { getValueIfExist } from '@front/src/utils';

export { SelectedPartSectionTableBody as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalSelectedPartSectionTableBody };

const SelectedPartSectionTableBody = () => {
  const { selectedList, deleteIdList, setDeleteIdList } =
    useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalState((state) => ({
      selectedList: state.selectedList,
      deleteIdList: state.deleteIdList,
      setDeleteIdList: state.setDeleteIdList,
    }));
  const handleCheckbox = useCallback(
    (item) => {
      setDeleteIdList(
        spliceAndPushPersonIdIfExist(deleteIdList, `${item.id}${item.affiliatedCompanyId}`)
      );
    },
    [setDeleteIdList, deleteIdList]
  );
  const getChecked = useCallback(
    (id) => deleteIdList.map((item) => item).includes(id),
    [deleteIdList]
  );
  if (selectedList.length === 0) {
    return <NoResult colSpan={5} />;
  }
  return (
    <>
      {selectedList.map((item) => (
        <TableRow
          hover
          key={`${item.id}${item.affiliatedCompanyId}`}
          onClick={() => handleCheckbox(item)}
          className={classes.cursor__pointer}
          sx={{
            backgroundColor: deleteIdList.includes(`${item.id}${item.affiliatedCompanyId}`)
              ? ColorPalette._cddaf5
              : '',
          }}
        >
          <OldTd>
            <Checkbox
              checked={getChecked(`${item.id}${item.affiliatedCompanyId}`)}
              onChange={() => handleCheckbox(item)}
            />
          </OldTd>
          <OldTd>{getValueIfExist(item.affiliatedCompanyCategory)}</OldTd>
          <OldTd>{getValueIfExist(item.affiliatedCompanyName)}</OldTd>
          <OldTd>{getValueIfExist(item.name)}</OldTd>
          <OldTd>{getValueIfExist(item.position)}</OldTd>
        </TableRow>
      ))}
    </>
  );
};
