import React, { useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useAffiliatedCompanyDetailModalCharacterCharacterLogic } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/character/widgets/useLogic';
import { AffiliatedCompanyPersonDetailModalCharacterCharacterContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/character/widgets/context';
import { AffiliatedCompanyPersonDetailModalCharacterCharacterButtonGroup } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/character/components/button-group';
import { AffiliatedCompanyPersonDetailModalCharacterCharacterTable } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/character/components/character-table';

interface Props {
  readOnly?: boolean;
  id?: number;
  menuId?: number;
}

const AffiliatedCompanyPersonDetailModalCharacterCharacterWidget = ({
  readOnly,
  id,
  menuId,
}: Props) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    d: { characterList },
    h: { onCreateCharacter, setIdList, onDeleteCharacter, onUpdateCharacter },
    idList,
    isLoading,
  } = useAffiliatedCompanyDetailModalCharacterCharacterLogic({ id, menuId });
  const value = useMemo(
    () => ({
      d: { characterList },
      h: { onCreateCharacter, setIdList, onDeleteCharacter, onUpdateCharacter },
      isLoading,
      idList,
      readOnly,
    }),
    [
      characterList,
      isLoading,
      onCreateCharacter,
      setIdList,
      idList,
      onDeleteCharacter,
      onUpdateCharacter,
      readOnly,
    ]
  );
  return (
    <AffiliatedCompanyPersonDetailModalCharacterCharacterContext.Provider value={value}>
      <Layout>
        <Header title="특징">
          <AffiliatedCompanyPersonDetailModalCharacterCharacterButtonGroup />
        </Header>
        <Body>
          <AffiliatedCompanyPersonDetailModalCharacterCharacterTable />
        </Body>
      </Layout>
    </AffiliatedCompanyPersonDetailModalCharacterCharacterContext.Provider>
  );
};

export default AffiliatedCompanyPersonDetailModalCharacterCharacterWidget;
