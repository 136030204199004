import React, { useContext } from 'react';
import { OldTd } from '@front/layouts/Table';
import { TableRow } from '@mui/material';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { ProjectSalesInfoAbstractEstimationDetailContext } from '@front/src/features/project-sales-info/features/abstract/features/estimation/widgets/detail/provider/context';
import { getLocaleStringIfExist, getValueIfExist } from '@front/src/utils';

export { Detail as ProjectSalesInfoAbstractEstimationDetail };

const Detail = () => {
  const { detail } = useContext(ProjectSalesInfoAbstractEstimationDetailContext);
  if (!detail) return <NoResult colSpan={8} />;
  return (
    <TableRow>
      <OldTd>{getValueIfExist(detail.estimationDate)}</OldTd>
      <OldTd>{getLocaleStringIfExist(detail.totalAmount)}</OldTd>
      <OldTd>{getValueIfExist(detail.schedule)}</OldTd>
      <OldTd>{getValueIfExist(detail.latestContactDate)}</OldTd>
      <OldTd>{getValueIfExist(detail.expectedCallDate)}</OldTd>
      <OldTd>{getValueIfExist(detail.expectedWorkStartDate)}</OldTd>
      <OldTd>{getValueIfExist(detail.winningPossibility)}</OldTd>
      <OldTd>{getValueIfExist(detail.clientCompanyName)}</OldTd>
    </TableRow>
  );
};
