import React, { useState } from 'react';
import type { SwiperProps } from 'swiper/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useFormContext } from 'react-hook-form';
import type { Swiper as SwiperClass } from 'swiper/types';
import { EffectCoverflow } from 'swiper/modules';
import Box from '@mui/material/Box';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import WorkCarouselCard from '@front/src/features/work/features/work/features/carousel/components/Card';
import ButtonIconUI from '@front/src/components/components-with-design/component/ButtonIconUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutoSizer from 'react-virtualized-auto-sizer';
import TableContainerLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/TableContainerLoadingSpinnerUI';
import workCarouselMutation from '@front/src/features/work/features/work/features/carousel/query/mutation';
import { useCustomDialog } from '@front/src/features/dialog';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

export default function WorkCarousel({
  readOnly,
  menuId,
  dataId,
  sectionId,
}: Readonly<SectionComponentProps>) {
  const { getValues } = useFormContext();
  const [swiper, setSwiper] = useState<SwiperClass>(null);
  const params: SwiperProps = {
    ref: setSwiper,
    onSwiper: setSwiper,
    coverflowEffect: {
      rotate: 0,
      stretch: 100,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    modules: [EffectCoverflow],
    effect: 'coverflow',
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    initialSlide: 0,
    centeredSlides: true,
    loop: true,
    // grabCursor: true,
  };
  const handlePrev = () => {
    swiper?.slidePrev();
  };
  const handleNext = () => {
    swiper?.slideNext();
  };
  const { isLoading, onRemove } = useMutationLogic({ dataId, menuId, sectionId });
  if (getValues('list').length === 0) return <></>;
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '400px',
      }}
    >
      <TableContainerLoadingSpinnerUI open={isLoading} />
      <AutoSizer>
        {({ width, height }) => (
          <div
            style={{
              width,
              height,
            }}
          >
            <Swiper {...params}>
              {getValues('list')?.map((item) => (
                <SwiperSlide key={item.id}>
                  <WorkCarouselCard
                    item={item}
                    onRemove={onRemove}
                    readOnly={readOnly}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <ButtonIconUI
              shape="ghost"
              size="medium"
              onClick={handlePrev}
              sx={{
                position: 'absolute',
                zIndex: 1,
                top: '50%',
                left: 0,
              }}
            >
              <FontAwesomeIcon icon="angle-left" />
            </ButtonIconUI>
            <ButtonIconUI
              shape="ghost"
              size="medium"
              onClick={handleNext}
              sx={{
                position: 'absolute',
                zIndex: 1,
                top: '50%',
                right: 0,
              }}
            >
              <FontAwesomeIcon icon="angle-right" />
            </ButtonIconUI>
          </div>
        )}
      </AutoSizer>
    </Box>
  );
}

function useMutationLogic({ dataId, menuId, sectionId }) {
  const { mutate: onDelete, isLoading } = workCarouselMutation.useDelete({
    dataId,
    menuId,
    sectionId,
  });
  const { confirm } = useCustomDialog();
  const onRemove = (id) => {
    confirm({
      title: '삭제 확인',
      lineBreakChildren: [{ value: '이미지를 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDelete(id);
      },
    });
  };
  return {
    onRemove,
    isLoading,
  };
}
