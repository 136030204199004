import useDialog from '@front/dialog/hook';
import { Box, TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import dayjs from 'dayjs';
import TextLink from '@front/layouts/TextLink';
import Button from '@front/layouts/Button';
import type { AttachFileVO } from '@front/ost_proposal/domain';
import { AttachedFileId, ProposalStatus } from '@front/ost_proposal/domain';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import ImageModalService from '@front/ost_proposal/service/modal/imageModal';
import { TargetPage } from '@front/navigate/domain';

interface Props {
  item: AttachFileVO;
  index: number;
  deleteAttachedFile: (id: AttachedFileId) => void;
  isContributor: boolean;
}

function FileItem({ item, index, deleteAttachedFile, isContributor }: Props) {
  const { confirm } = useDialog();
  const status = useSelector((root: RootState) => root.proposal.detail!.status, shallowEqual);
  const currentPage = useSelector(
    (root: RootState) => root.navigation.currentPage!.targetPage,
    shallowEqual
  );
  return (
    <TableRow key={`${item.file.id}_${index}`}>
      <OldTd>{dayjs(item.file.createdAt).format('YYYY-MM-DD')}</OldTd>
      {currentPage === TargetPage.VOTE && <OldTd>****</OldTd>}
      {currentPage !== TargetPage.VOTE && <OldTd>{item.writer.department.name}</OldTd>}
      {currentPage === TargetPage.VOTE && <OldTd>{`제안자${index + 1}`}</OldTd>}
      {currentPage !== TargetPage.VOTE && <OldTd>{item.writer.name}</OldTd>}
      <OldTd>
        <TextLink
          onClick={() => {
            window.location.assign(`/api/file-item/${item.file.id}`);
          }}
        >
          {item.file.filename}
        </TextLink>
      </OldTd>

      {status === ProposalStatus.WRITING && isContributor && (
        <OldTd>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              shape="basic3"
              onClick={() => {
                confirm({
                  children: '해당 첨부 파일을 삭제하시겠습니까?',
                  confirmText: '확인',
                  afterConfirm: () => {
                    deleteAttachedFile(AttachedFileId(item.id));
                  },
                });
              }}
            >
              삭제
            </Button>
            {['png', 'jpg', 'jpeg'].includes(item.file.ext) && <ImageModalService item={item} />}
          </Box>
        </OldTd>
      )}
    </TableRow>
  );
}

export default FileItem;
