import { create } from 'zustand';
import type { FieldValues, UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form';

export { useState as usePersonalSettingsModalUISettingsModalSettingsState };

interface State {
  parentId?: number;
}

interface Actions {
  setParentId: (id?: number) => void;
  append: UseFieldArrayAppend<FieldValues, 'childList'>;
  setAppend: (append: UseFieldArrayAppend<FieldValues, 'childList'>) => void;
  remove: UseFieldArrayRemove;
  setRemove: (remove: UseFieldArrayRemove) => void;
}

const useState = create<State & Actions>((set) => ({
  setParentId: (parentId) => set(() => ({ parentId })),
  append: () => {},
  setAppend: (append) => set(() => ({ append })),
  remove: () => {},
  setRemove: (remove) => set(() => ({ remove })),
}));
