import { projectSalesInfoCMPaymentHistoryRepository } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';

export { useLogic as useProjectSalesInfoCMPaymentHistoryLogic };
const useLogic = () => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { run: onDelete } = projectSalesInfoCMPaymentHistoryRepository.useCMPaymentHistoryDelete(
    id!
  );
  const { data: CMPaymentHistoryList, isLoading } =
    projectSalesInfoCMPaymentHistoryRepository.useCMPaymentHistoryGet(id!);
  return {
    d: {
      CMPaymentHistoryList,
    },
    h: {
      onDelete,
    },
    isLoading,
  };
};
