import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import type { ProjectAspectRatioExaminationView } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/types/view';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import {
  convertBooleanToYNIfExistForForm,
  convertDateToStringFormat,
  convertNullToEmptyForForm,
  convertYNToBooleanForParameter,
  DATE_TIME_FORMAT,
  getValueIfExist,
  YNOptionList,
} from '@front/src/utils';
import { useCustomDialog } from '@front/src/features/dialog';
import {
  getShapeRatioFileName,
  projectSalesInfoSubjectReviewShapeRatioExcelDownload,
} from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/utils';
import { ProjectSalesInfoSubjectReviewShapeRatioListItemContext } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/features/list/features/list-item/widgets/context';
import { useProjectSalesInfoSubjectReviewModalState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/useState';
import classes from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/features/list/features/list-item/components/list-item.module.scss';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useShallow } from 'zustand/react/shallow';
import { FileDisplayText } from '@front/src/components/file-display-text/file-display-text';
import { useProjectSalesInfoSubjectReviewShapeRatioState } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/useState';
import { useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import {useGlobalNavBarState} from "@front/src/features/global-nav-bar/useState";

export { ListItem as ProjectSalesInfoSubjectReviewShapeRatioListItem };

interface Props {
  item: ProjectAspectRatioExaminationView;
  index: number;
  existIsConfirmed: boolean;
}

const ListItem = ({ item, index, existIsConfirmed }: Props) => {
  const { ItemTableRow, Text, ItemTableCell } = AddDeleteTable;
  const { onUpdate } = useContext(ProjectSalesInfoSubjectReviewShapeRatioListItemContext);
  const { id, setId, setIsOpen } = useProjectSalesInfoSubjectReviewModalState(
    useShallow((state) => ({
      id: state.id,
      setId: state.setId,
      setIsOpen: state.setIsOpen,
    }))
  );
  const { alert } = useCustomDialog();
  const methods = useForm({
    values: getValuesForForm(item),
  });
  const { handleSubmit, getValues, reset } = methods;
  const onSubmit = handleSubmit((data) => {
    const before = item.isConfirmed;
    const after = convertYNToBooleanForParameter(data.isConfirmed);
    if (!before && after && existIsConfirmed) {
      reset();
      alert({
        children: [
          {
            value:
              '형상비 검토 확정 데이터 변경시 기존의 확정된 형상비 검토 데이터의 확정 여부를 변경하시기 바랍니다.',
          },
        ],
      });
      return;
    }
    onUpdate({
      ...data,
      id: item.id,
      isConfirmed: after,
    });
  });
  const fileName = useMemo(
    () => getShapeRatioFileName(item.projectCode, item.gettingDesignDate),
    [item.projectCode, item.gettingDesignDate]
  );
  const handleFileClick = useCallback(() => {
    projectSalesInfoSubjectReviewShapeRatioExcelDownload(item.complexInformationList, fileName);
  }, [item.complexInformationList, fileName]);
  const { readOnly } = useProjectSalesInfoSubjectReviewShapeRatioState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const handleNameClick = useCallback(() => {
    setId(item.id);
    setIsOpen(true);
  }, [setId, setIsOpen, item.id, readOnly]);
  const isSelected = item.id === id;
  const handleRowClick = useCallback(() => {
    if (readOnly) return;
    setId(item.id);
  }, [readOnly, setId, item.id]);

  const renderIfSelected = useCallback(
    (name) => {
      if (isSelected) {
        return (
          <HookFormInput
            name={name}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        );
      }
      return <Text>{getValues(name) || '-'}</Text>;
    },
    [isSelected, onSubmit, getValues, Text, readOnly]
  );
  const renderSelectIfSelected = useCallback(
    (name, optionList) => {
      if (isSelected) {
        return (
          <HookFormSelect
            isDefaultPossible
            defaultLabel="선택"
            name={name}
            optionList={optionList}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        );
      }
      return <Text>{getValues(name) || '-'}</Text>;
    },
    [isSelected, onSubmit, getValues, Text, readOnly]
  );
  const menuId = useGlobalNavBarState((state) => state.menuId);
  const loginUser = useSelector((root: RootState) => root.login.detail);
  const currentAuth = loginUser?.menus?.find((item) => item.id === (menuId ? +menuId : 0))
    ?.authorization.type;

  useEffect(() => {
    if (currentAuth === 'V') setId();
  }, [setId, currentAuth]);

  return (
    <FormProvider {...methods}>
      <ItemTableRow onClick={handleRowClick}>
        <ItemTableCell
          height="56px"
          center
        >
          {index + 1}
        </ItemTableCell>
        <ItemTableCell center>{getValueIfExist(item.gettingDesignDate)}</ItemTableCell>
        <ItemTableCell center>{renderSelectIfSelected('isConfirmed', YNOptionList)}</ItemTableCell>
        <ItemTableCell center>{getValueIfExist(item.code)}</ItemTableCell>
        <ItemTableCell center>
          {getValueIfExist(item.complexExperimentInfo?.experiment?.name)}
        </ItemTableCell>
        <ItemTableCell center>
          {fileName && currentAuth !== 'V' ? (
            <FileDisplayText
              onClick={handleFileClick}
              fileName={fileName + '.xlsx'}
            />
          ) : (
            '-'
          )}
        </ItemTableCell>
        <ItemTableCell center>
          <div
            onClick={handleNameClick}
            aria-hidden
            className={classes.name}
          >
            {getValueIfExist(item.writer?.name)}
          </div>
        </ItemTableCell>
        <ItemTableCell center>
          {convertDateToStringFormat(item.createdAt, DATE_TIME_FORMAT)}
        </ItemTableCell>
        <ItemTableCell center>{renderIfSelected('note')}</ItemTableCell>
      </ItemTableRow>
    </FormProvider>
  );
};

const getValuesForForm = (item) => ({
  isConfirmed: convertBooleanToYNIfExistForForm(item.isConfirmed),
  note: convertNullToEmptyForForm(item.note),
});
