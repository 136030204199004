import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import { useFormContext } from 'react-hook-form';
import DateAttr from '@front/src/components/ui-builder/DateAttr';
import NumberAttr from '@front/src/components/ui-builder/NumberAttr';
import TextAttr from '@front/src/components/ui-builder/TextAttr';
import Box from '@mui/material/Box';
import { AuthorizationType } from '@front/user/domain';
import UserSelectorInputFeature from '@front/src/features/user-selector/features/input';
import type { WorkCreatorView } from '@front/src/features/work/features/work/features/creator/types/view';
import workWorkQuery from '@front/src/features/work/features/work/query/query';
import { WorkCreatorType } from '@front/src/features/work/utils/constants';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { useCustomDialog } from '@front/src/features/dialog';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import type { ConfirmView } from '@front/src/features/dialog/types';
import InputWithHookForm from "@front/src/components/hook-form/Input";

const workManagerCellComponent = {
  286: ({ ...rest }: CellComponentProps) => <WorkManagerDepartment {...rest} />,
  287: ({ ...rest }: CellComponentProps) => <WorkManagerJobDuty {...rest} />,
  288: ({ ...rest }: CellComponentProps) => <WorkManagerUser {...rest} />,
  289: ({ ...rest }: CellComponentProps) => <WorkManagerDescription {...rest} />,
  290: ({ ...rest }: CellComponentProps) => <WorkManagerNumberAttr1 {...rest} />,
  291: ({ ...rest }: CellComponentProps) => <WorkManagerTextAttr1 {...rest} />,
  292: ({ ...rest }: CellComponentProps) => <WorkManagerDateAttr1 {...rest} />,
  293: ({ ...rest }: CellComponentProps) => <WorkManagerNumberAttr2 {...rest} />,
  294: ({ ...rest }: CellComponentProps) => <WorkManagerTextAttr2 {...rest} />,
  295: ({ ...rest }: CellComponentProps) => <WorkManagerDateAttr2 {...rest} />,
  296: ({ ...rest }: CellComponentProps) => <WorkManagerNumberAttr3 {...rest} />,
  297: ({ ...rest }: CellComponentProps) => <WorkManagerTextAttr3 {...rest} />,
  298: ({ ...rest }: CellComponentProps) => <WorkManagerDateAttr3 {...rest} />,
};

export default workManagerCellComponent;

interface Props extends CellComponentProps<WorkCreatorView> {}

const WorkManagerDepartment = ({ sx = {}, onClick, item }: Props) => (
  <Box
    sx={sx}
    onClick={onClick}
  >
    {item.user?.department}
  </Box>
);
const WorkManagerJobDuty = ({ sx = {}, onClick, item }: Props) => (
  <Box
    sx={sx}
    onClick={onClick}
  >
    {item.user?.jobDuty}
  </Box>
);

const WorkManagerUser = ({
  onClick,
  sx = {},
  item,
  isEditMode,
  readOnly,
  onSubmit,
  dataId,
  menuId,
}: Props) => {
  const { setValue, watch } = useFormContext();
  const { data: workRole } = workWorkQuery.useGetRole(dataId, menuId);
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const { confirm } = useCustomDialog();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const confirmProps: ConfirmView = {
    title: '담당자 삭제 확인 모달',
    lineBreakChildren: [
      { value: '역할명에서 제외되면 해당 업무를 열람할 수 없습니다.' },
      { value: '제외 하시겠습니까?' },
    ],
  };
  const handleChange = (value) => {
    if (value !== undefined) {
      setValue('userId', value);
      onSubmit?.();
      return;
    }
    if (
      !workRole?.creators.filter((c) => c.user).find((c) => c.type === WorkCreatorType.INDICATOR)
    ) {
      enqueueSnackbar('지시자 또는 담당자가 최소 1명 필요합니다.');
      return;
    }
    if (watch('userId') === loginUser?.id) {
      confirm({
        ...confirmProps,
        afterConfirm: () => {
          setValue('userId', undefined);
          onSubmit?.();
          navigate('/work');
          enqueueSnackbar('열람 권한이 없습니다', { variant: 'error' });
        },
      });
      return;
    }
    confirm({
      ...confirmProps,
      afterConfirm: () => {
        setValue('userId', undefined);
        onSubmit?.();
      },
    });
  };
  const menuIdList = menuId ? [menuId] : [];
  const typeList = [AuthorizationType.W];
  return (
    <Box
      sx={sx}
      onClick={onClick}
    >
      {readOnly || !isEditMode ? (
        item.user?.name
      ) : (
        <UserSelectorInputFeature
          value={item.user?.id}
          onChange={handleChange}
          readOnly={readOnly}
          menuIdList={menuIdList}
          typeList={typeList}
        />
      )}
    </Box>
  );
};

function WorkManagerDescription({ onClick, sx = {}, onSubmit, isEditMode, readOnly }: Props) {
  return (
    <Box
      sx={sx}
      onClick={onClick}
    >
      <InputWithHookForm
        name="description"
        onSubmit={onSubmit}
        placeholder="직접 입력"
        readOnly={readOnly || !isEditMode}
      />
    </Box>
  );
}

const WorkManagerNumberAttr1 = ({ ...rest }: Props) => (
  <NumberAttr
    name="numberAttr1"
    {...rest}
  />
);

const WorkManagerTextAttr1 = ({ ...rest }: Props) => (
  <TextAttr
    name="textAttr1"
    {...rest}
  />
);

const WorkManagerDateAttr1 = ({ ...rest }: Props) => (
  <DateAttr
    name="dateAttr1"
    {...rest}
  />
);

const WorkManagerNumberAttr2 = ({ ...rest }: Props) => (
  <NumberAttr
    name="numberAttr2"
    {...rest}
  />
);

const WorkManagerTextAttr2 = ({ ...rest }: Props) => (
  <TextAttr
    name="textAttr2"
    {...rest}
  />
);

const WorkManagerDateAttr2 = ({ ...rest }: Props) => (
  <DateAttr
    name="dateAttr2"
    {...rest}
  />
);

const WorkManagerNumberAttr3 = ({ ...rest }: Props) => (
  <NumberAttr
    name="numberAttr3"
    {...rest}
  />
);

const WorkManagerTextAttr3 = ({ ...rest }: Props) => (
  <TextAttr
    name="textAttr3"
    {...rest}
  />
);

const WorkManagerDateAttr3 = ({ ...rest }: Props) => (
  <DateAttr
    name="dateAttr3"
    {...rest}
  />
);
