import { createContext } from 'react';
import type { ProjectContractEarningsParameters } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/parameters';
import { noOp } from '@front/common/contants';

interface State {
  h: {
    onCreate: (params: ProjectContractEarningsParameters) => void;
  };
}

export { Context as ProjectSalesInfoContractEarningsModalContext };

const Context = createContext<State>({
  h: {
    onCreate: noOp,
  },
});
