import React from 'react';
import type { ProposalArrangementInformationStatusParameter } from '@front/ost_proposal/parameter';
import { Box, MenuItem } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import TextBox from '@front/layouts/Text';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import Select from '@front/layouts/Select';
import type { ProposalArrangementInformationStatus } from '@front/ost_proposal/domain';
import {
  proposalArrangementInformationStatusList,
  proposalArrangementInformationStatusName,
  ProposalStatus,
} from '@front/ost_proposal/domain';
import useDialog from '@front/dialog/hook';
import { ArticleItem } from '@front/src/components/article-item';

interface Props {
  onUpdate: (params: ProposalArrangementInformationStatusParameter) => void;
  isAdvisor: boolean;
}

const Information = ({ onUpdate, isAdvisor }: Props) => {
  const { confirm } = useDialog();
  const advisor = useSelector((root: RootState) => root.proposal.detail!.advisor, shallowEqual);
  const arrangementStatus = useSelector(
    (root: RootState) => root.proposal.detail!.arrangementStatus,
    shallowEqual
  );
  const status = useSelector((root: RootState) => root.proposal.detail!.status, shallowEqual);
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        width: '100%',
        padding: '10px',
        maxHeight: '200px',
      }}
    >
      <ArticleItem
        title={
          <>
            <TextBox variant={'heading3'}>협의 활동</TextBox>
            <TextBox variant={'body9'}>(사내/외 전문가 참여 제안 지원)</TextBox>
          </>
        }
        children={
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              border: `1px solid ${ColorPalette._e4e9f2}`,
              padding: '10px',
              borderRadius: '5px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '20%',
              }}
            >
              <DataFieldWithLabel
                label="소속"
                labelSX={{
                  color: `${ColorPalette._252627}`,
                  fontWeight: 'bold',
                }}
              >
                <TextBox
                  variant="body9"
                  sx={{
                    maxWidth: '70%',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {advisor.department.name}
                </TextBox>
              </DataFieldWithLabel>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '20%',
              }}
            >
              <DataFieldWithLabel
                label="담당자"
                labelSX={{
                  color: `${ColorPalette._252627}`,
                  fontWeight: 'bold',
                }}
              >
                <TextBox variant="body9">{advisor.name}</TextBox>
              </DataFieldWithLabel>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '30%',
              }}
            >
              <DataFieldWithLabel
                label="이메일"
                labelSX={{
                  color: `${ColorPalette._252627}`,
                  fontWeight: 'bold',
                }}
              >
                <TextBox variant="body9">{advisor.email}</TextBox>
              </DataFieldWithLabel>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '30%',
              }}
            >
              <DataFieldWithLabel
                label="작성 상태"
                labelSX={{
                  color: `${ColorPalette._252627}`,
                  fontWeight: 'bold',
                }}
              >
                <Select
                  displayEmpty
                  readOnly={
                    !isAdvisor ||
                    !(status === ProposalStatus.WRITING || status === ProposalStatus.SUBMISSION)
                  }
                  variant="outlined"
                  value={arrangementStatus || ''}
                  onChange={(e) => {
                    const value = e.target.value as ProposalArrangementInformationStatus;
                    if (arrangementStatus !== value) {
                      confirm({
                        children: `${proposalArrangementInformationStatusName(
                          value
                        )} 상태로 변경하시겠습니까?`,
                        confirmText: '확인',
                        afterConfirm: () => {
                          onUpdate({ status: value });
                        },
                      });
                    }
                  }}
                >
                  <MenuItem
                    disabled
                    value=""
                  >
                    상태명
                  </MenuItem>
                  {proposalArrangementInformationStatusList.map((item) => (
                    <MenuItem
                      key={`${item}`}
                      value={item}
                    >
                      {proposalArrangementInformationStatusName(item)}
                    </MenuItem>
                  ))}
                </Select>
              </DataFieldWithLabel>
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default Information;
