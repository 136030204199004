import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useProjectSalesInfoActivityCreateButtonLogic } from '@front/src/features/project-sales-info/features/activity/widgets/create-button/provider/useLogic';
import { ProjectSalesInfoActivityCreateButtonContext } from '@front/src/features/project-sales-info/features/activity/widgets/create-button/provider/context';

export { Provider as ProjectSalesInfoActivityCreateButtonProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { onCreate } = useProjectSalesInfoActivityCreateButtonLogic();
  const value = useMemo(() => ({ onCreate }), [onCreate]);
  return (
    <ProjectSalesInfoActivityCreateButtonContext.Provider value={value}>
      {children}
    </ProjectSalesInfoActivityCreateButtonContext.Provider>
  );
};
