import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ApprovalLineSettingForm from '@front/ost_campaign/view/detail/ApprovalLineSettingForm';
import { campaignAction } from '@front/ost_campaign/action';
import type { CampaignUpdateApprovalParameter } from '@front/ost_campaign/parameter';
import type { UserId } from '@front/user/domain';
import type { CampaignApprovalVO, ProposalApprovalId } from '@front/ost_campaign/domain';

const ApprovalLineSettingFormService = () => {
  const dispatch = useDispatch();
  const onAdd = useCallback(() => dispatch(campaignAction.addApproval()), [dispatch]);
  const onUpdate = useCallback(
    (params: CampaignUpdateApprovalParameter) => dispatch(campaignAction.updateApproval(params)),
    [dispatch]
  );
  const onDelete = useCallback(
    (id: ProposalApprovalId) => {
      dispatch(campaignAction.deleteApproval(id));
    },
    [dispatch]
  );
  const getExclude = (approvalList: CampaignApprovalVO[], item: CampaignApprovalVO) => {
    let result: UserId[] = [];
    result = [
      ...result,
      ...approvalList
        .filter((approvalInformation) => approvalInformation.manager?.id !== item.manager?.id)
        .map((approvalInformation) => approvalInformation.manager?.id),
    ];
    return result;
  };

  return (
    <ApprovalLineSettingForm
      onAdd={onAdd}
      onUpdate={onUpdate}
      onDelete={onDelete}
      getExclude={getExclude}
    />
  );
};

const ApprovalLineSettingService = memo(ApprovalLineSettingFormService);

export default ApprovalLineSettingService;
