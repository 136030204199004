import React, { useCallback } from 'react';
import { TableRow } from '@mui/material';
import { useAccountingUploadSalesInfoExpectModalState } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/useState';
import { useShallow } from 'zustand/react/shallow';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { getValueIfExist, openNewTabFromUrl } from '@front/src/utils';
import { OldTd } from '@front/layouts/Table';
import { ColorPalette } from '@front/assets/theme';

export { ProjectTableBody as AccountingUploadSalesInfoExpectModalProjectTableBody };

const ProjectTableBody = () => {
  const { projectList } = useAccountingUploadSalesInfoExpectModalState(
    useShallow((state) => ({
      projectList: state.projectList,
    }))
  );
  const handleRowClick = useCallback((id) => {
    if (!id) return;
    openNewTabFromUrl(`/project/sales-info/${id}/abstract`);
  }, []);
  if (!projectList || projectList.length === 0) {
    return <NoResult height="40px" />;
  }
  return (
    <>
      {projectList?.map(({ projectId, code }) => (
        <TableRow
          key={projectId}
          onClick={() => handleRowClick(projectId)}
          sx={{
            '&:hover': {
              textDecoration: 'underline',
              color: ColorPalette._386dd6,
              cursor: 'pointer',
            },
          }}
        >
          <OldTd>{getValueIfExist(code)}</OldTd>
        </TableRow>
      ))}
    </>
  );
};
