import React, { useEffect } from 'react';
import { sectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import { commonRepository } from '@front/src/api/repository';
import { useDispatch } from 'react-redux';
import { loginAction } from '@front/login/action';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';
import { useGetReadOnly } from '@front/src/hooks/useGetReadOnly';

interface Props {
  hasMemo?: boolean;
  menuId?: number;
  dataId?: number;
  readOnly?: boolean;
}

export default function CommonSectionList({ hasMemo, menuId, dataId, readOnly }: Readonly<Props>) {
  const dispatch = useDispatch();
  const { data: list } = commonRepository.useGetSectionList(menuId);
  const { maxWidth } = useGetMaxWidthSx({ hasMemo });
  const { readOnly: isReadOnly } = useGetReadOnly();
  useEffect(() => {
    dispatch(loginAction.requestDetail());
  }, [dispatch]);
  return (
    <>
      {list?.map(({ id, ...rest }) => {
        const SectionComponent = sectionComponent[id];
        return (
          <SectionComponent
            key={id}
            readOnly={readOnly ?? isReadOnly}
            maxWidth={maxWidth}
            sectionId={id}
            dataId={dataId}
            menuId={menuId}
            {...rest}
          />
        );
      })}
    </>
  );
}
