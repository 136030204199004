import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { ProjectSalesInfoAbstractContractRepository } from '@front/src/features/project-sales-info/features/abstract/features/contract/repository/repository';

export { useLogic as useProjectSalesInfoAbstractContractDetailLogic };
const useLogic = () => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { data: detail } = ProjectSalesInfoAbstractContractRepository.useDetailGet(id!);
  return {
    detail,
  };
};
