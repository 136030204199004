import { useQuery } from 'react-query';
import { projectSalesInfoAffiliatedCompanyWebHardUpdateModalApi } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/query/api';
import type { ProjectSalesInfoAffiliatedCompanyWebHardDetailView } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/types/view';

export const projectSalesInfoAffiliatedCompanyWebHardUpdateModalQuery = {
  useDetailGet: (id?: number) => {
    const { data, isLoading } = useQuery<ProjectSalesInfoAffiliatedCompanyWebHardDetailView>({
      queryKey: ['project', 'sales-info', 'affiliated-company', 'web-hard', 'detail', id],
      queryFn: () => projectSalesInfoAffiliatedCompanyWebHardUpdateModalApi.getDetail(id),
      enabled: !!id,
      cacheTime: 0,
    });
    return {
      data,
      isLoading,
    };
  },
};
