import React, { Suspense } from 'react';
import { MyPageAuthorizationTabList } from '@front/src/features/my-page/features/authorization/widgets/tab/components/tab-list';
import { MyPageAuthorizationTabProvider } from '@front/src/features/my-page/features/authorization/widgets/tab/provider/provider';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

export { Widget as MyPageAuthorizationTabWidget };
const Widget = ({ ...rest }: Readonly<SectionComponentProps>) => (
  <Suspense>
    <MyPageAuthorizationTabProvider {...rest}>
      <MyPageAuthorizationTabList />
    </MyPageAuthorizationTabProvider>
  </Suspense>
);
