import React, { useCallback, useEffect, useRef } from 'react';
import type { TaxAccountingView } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/types/view';
import { useAccountingSettingsTaxAccountingAccountState } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/useState';
import { getParentIdList } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/utils';
import { ColorPalette } from '@front/assets/theme';
import { shallow } from 'zustand/shallow';
import { accountingSettingsManagementAccountingTableLayout } from '@front/src/features/accounting/features/settings/components/layouts/table';

export { ListItem as AccountingSettingsTexAccountingAccountListItem };

interface Props {
  item?: TaxAccountingView;
}

const ListItem = ({ item }: Props) => {
  const { TableBodyCell } = accountingSettingsManagementAccountingTableLayout;
  const cellRef = useRef<HTMLDivElement>(null);
  const { setParentIdList, parentIdList, setWidthInfo } =
    useAccountingSettingsTaxAccountingAccountState(
      (state) => ({
        setParentIdList: state.setParentIdList,
        parentIdList: state.parentIdList,
        setWidthInfo: state.setWidthInfo,
      }),
      shallow
    );
  useEffect(() => {
    if (cellRef.current) {
      const { width } = cellRef.current.getBoundingClientRect();
      setWidthInfo({ width, depth: item?.depth });
    }
  }, [item?.depth, setWidthInfo]);
  if (!item) {
    return <TableBodyCell cellRef={cellRef} />;
  }
  const handleClick = useCallback(() => {
    const idList = [];
    getParentIdList(item, idList);
    setParentIdList(idList);
  }, [setParentIdList, item]);
  return (
    <TableBodyCell
      sx={{
        cursor: 'pointer',
        ':hover': { backgroundColor: ColorPalette._9bb6ea, fontWeight: 'bold' },
        backgroundColor: parentIdList?.includes(item.id) ? ColorPalette._9bb6ea : 'none',
        fontWeight: parentIdList?.includes(item.id) ? 'bold' : 'normal',
        padding: '0 5px',
      }}
      onClick={handleClick}
      textIndent="5px"
      cellRef={cellRef}
      nowrap={true}
    >
      {item.nameWithCode}
    </TableBodyCell>
  );
};
