import React from 'react';
import type { TableCellProps } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { ColorPalette } from '@front/assets/theme';

interface Props extends TableCellProps {
  isHead?: boolean;
  size?: 'small' | 'medium';
}

export default function TableCellUI({
  isHead = false,
  size = 'medium',
  sx = {},
  ...rest
}: Readonly<Props>) {
  return (
    <TableCell
      align="center"
      sx={{
        height: size === 'small' ? '28px' : '36px',
        fontSize: '1.3rem',
        fontWeight: isHead ? '600' : '400',
        verticalAlign: 'middle',
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        background: isHead ? ColorPalette.background.bg02 : 'transparent',
        borderRight: `1px solid ${ColorPalette.line.line}`,
        borderBottom: `1px solid ${ColorPalette.line.line}`,
        padding: '0 4px',
        '&:last-child': {
          borderRight: `0px`,
        },
        ...sx,
      }}
      {...rest}
    />
  );
}
