import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import { default as axios } from '@front/src/config/axios';

import type { ProjectCMPaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/types/parameter';

const url = {
  getCMPaymentHistoryDetail: (itemId: number) =>
    `${projectSalesInfoURL}/cm-amount-payment-history/${itemId}`,
  updateCMPaymentHistory: (itemId: number) =>
    `${projectSalesInfoURL}/cm-amount-payment-history/${itemId}`,
};

export const projectSalesInfoCMPaymentHistoryUpdateModalApi = {
  getCMPaymentHistoryDetail: async (itemId: number) => {
    const { data } = await axios.get(url.getCMPaymentHistoryDetail(itemId));
    return data;
  },
  updateCMPaymentHistory: async (params: ProjectCMPaymentHistoryParams) =>
    await axios.put(url.updateCMPaymentHistory(params.id!), params),
};
