import React, { useMemo } from 'react';
import { useProjectSalesInfoCMPaymentHistoryCreateModalLogic } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/create-modal/widgets/useLogic';
import { ProjectSalesInfoCMPaymentHistoryCreateModalContext } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/create-modal/widgets/context';

export { Provider as ProjectSalesInfoCMPaymentHistoryCreateModalProvider };

interface Props {
  children: React.ReactNode;
}

const Provider = ({ children }: Props) => {
  const {
    h: { onCreate },
    d: { CMPaymentMethodOptionList, CMPaymentStatusOptionList },
  } = useProjectSalesInfoCMPaymentHistoryCreateModalLogic();
  const value = useMemo(
    () => ({
      h: {
        onCreate,
      },
      d: {
        CMPaymentMethodOptionList,
        CMPaymentStatusOptionList,
      },
    }),
    [onCreate, CMPaymentMethodOptionList, CMPaymentStatusOptionList]
  );
  return (
    <ProjectSalesInfoCMPaymentHistoryCreateModalContext.Provider value={value}>
      {children}
    </ProjectSalesInfoCMPaymentHistoryCreateModalContext.Provider>
  );
};
