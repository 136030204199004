import React, { useMemo } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { useProjectSalesInfoContractReviewModalLogic } from '@front/src/features/project-sales-info/features/contract/features/reivew/features/modal/widgets/useLogic';
import { ProjectSalesInfoContractReviewModalContext } from '@front/src/features/project-sales-info/features/contract/features/reivew/features/modal/widgets/context';
import { ProjectSalesInfoContractReviewModalTable } from '@front/src/features/project-sales-info/features/contract/features/reivew/features/modal/components/modal-table';
import { isMobileDevice } from '@front/util/PwaUtil';

export { Widget as ProjectSalesInfoContractReviewModalWidget };
const Widget = () => {
  const {
    d: { detail, categoryList, companyList },
    h: { onClose, onUpdate, onCreate },
    isOpen,
  } = useProjectSalesInfoContractReviewModalLogic();
  const value = useMemo(
    () => ({
      d: { detail, categoryList, companyList },
      h: { onClose, onUpdate, onCreate },
    }),
    [detail, categoryList, onClose, onUpdate, onCreate, companyList]
  );
  return (
    <ProjectSalesInfoContractReviewModalContext.Provider value={value}>
      <ModalLayout
        position="left"
        width={isMobileDevice() ? '29dvw' : '560px'}
        title="구검 계약 내용 등록/수정"
        open={isOpen}
        onClose={onClose}
      >
        <ProjectSalesInfoContractReviewModalTable />
      </ModalLayout>
    </ProjectSalesInfoContractReviewModalContext.Provider>
  );
};
