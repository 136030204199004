import { AccountingSettingsTaxAccountingAccountRepository } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/repository/repository';

export { useLogic as useAccountingSettingsTexAccountingAccountGetDateLogic };

const useLogic = () => {
  const { data: createdAt } = AccountingSettingsTaxAccountingAccountRepository.useCreatedAt();
  return {
    createdAt,
  };
};
