import React from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { FormProvider, useForm } from 'react-hook-form';
import workScheduleQuery from '@front/src/features/work/features/work/features/schedule/query/query';
import workScheduleMutation from '@front/src/features/work/features/work/features/schedule/query/mutation';
import workInterimReportDateMutation from '@front/src/features/work/features/work/features/schedule/features/interim-report-date/query/mutation';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import dayjs from 'dayjs';
import BackdropLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/BackdropLoadingSpinnerUI';
import DatePickerWithHookForm from '@front/src/components/hook-form/DatePicker';
import WorkInterimReportDateFeature from '@front/src/features/work/features/work/features/schedule/features/interim-report-date';
import InputWithHookForm from '@front/src/components/hook-form/Input';
import { YYYY_MM_DD } from '@front/src/utils';

export default function WorkScheduleTable({
  readOnly,
  dataId,
  menuId,
  sectionId,
  ...rest
}: Readonly<SectionComponentProps>) {
  const { data: detail } = workScheduleQuery.useGetDetail(dataId, menuId);
  const {
    onUpdate,
    onInterimReportDateCreate,
    onInterimReportDateUpdate,
    onInterimReportDateDelete,
    isLoading,
  } = useMutationLogic({ dataId, menuId, sectionId });
  const methods = useForm({
    values: getFormValues(detail),
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    onUpdate({
      instructionDate: data.instructionDate && data.instructionDate.format(YYYY_MM_DD),
      instructionDateNote: data.instructionDateNote || null,
      finalReportDate: data.finalReportDate && data.finalReportDate.format(YYYY_MM_DD),
      finalReportDateNote: data.finalReportDateNote || null,
      dueDate: data.dueDate && data.dueDate.format(YYYY_MM_DD),
      dueDateNote: data.dueDateNote || null,
      completionDate: data.completionDate && data.completionDate.format(YYYY_MM_DD),
      completionDateNote: data.completionDateNote || null,
      nextExecutionDate: data.nextExecutionDate && data.nextExecutionDate.format(YYYY_MM_DD),
      nextExecutionDateNote: data.nextExecutionDateNote || null,
    });
  });
  return (
    <FormProvider {...methods}>
      <TableContainerUI
        sx={{
          position: 'relative',
        }}
      >
        <BackdropLoadingSpinnerUI
          sx={{
            position: 'absolute',
          }}
          open={isLoading}
        />
        <TableTableUI>
          <TableBodyUI>
            <TableRowUI>
              <TableCellUI
                isHead
                width="160px"
              >
                지시일
              </TableCellUI>
              <TableCellUI width="140px">
                <DatePickerWithHookForm
                  name="instructionDate"
                  onSubmit={onSubmit}
                  readOnly={readOnly}
                />
              </TableCellUI>
              <TableCellUI>
                <InputWithHookForm
                  name="instructionDateNote"
                  readOnly={readOnly}
                  onSubmit={onSubmit}
                  placeholder="지시 목적 및 KPI가 있다면 해당 내용을 입력해주세요"
                />
              </TableCellUI>
            </TableRowUI>
            <WorkInterimReportDateFeature
              onCreate={onInterimReportDateCreate}
              onUpdate={onInterimReportDateUpdate}
              onDelete={onInterimReportDateDelete}
              readOnly={readOnly}
              dataId={dataId}
              menuId={menuId}
              sectionId={sectionId}
              {...rest}
            />
            <TableRowUI>
              <TableCellUI isHead>최종보고일</TableCellUI>
              <TableCellUI>
                <DatePickerWithHookForm
                  name="finalReportDate"
                  onSubmit={onSubmit}
                  readOnly={readOnly}
                />
              </TableCellUI>
              <TableCellUI>
                <InputWithHookForm
                  name="finalReportDateNote"
                  readOnly={readOnly}
                  onSubmit={onSubmit}
                  placeholder="최종 보고 목적 및 KPI가 있다면 해당 내용을 입력해주세요"
                />
              </TableCellUI>
            </TableRowUI>
            <TableRowUI>
              <TableCellUI isHead>마감예정일</TableCellUI>
              <TableCellUI>
                <DatePickerWithHookForm
                  name="dueDate"
                  onSubmit={onSubmit}
                  readOnly={readOnly}
                />
              </TableCellUI>
              <TableCellUI>
                <InputWithHookForm
                  name="dueDateNote"
                  readOnly={readOnly}
                  onSubmit={onSubmit}
                  placeholder="마감 예정 목적 및 KPI가 있다면 해당 내용을 입력해주세요"
                />
              </TableCellUI>
            </TableRowUI>
            <TableRowUI>
              <TableCellUI isHead>완료일</TableCellUI>
              <TableCellUI>
                <DatePickerWithHookForm
                  name="completionDate"
                  onSubmit={onSubmit}
                  readOnly={readOnly}
                />
              </TableCellUI>
              <TableCellUI>
                <InputWithHookForm
                  name="completionDateNote"
                  readOnly={readOnly}
                  onSubmit={onSubmit}
                  placeholder="완료 목적 및 KPI가 있다면 해당 내용을 입력해주세요"
                />
              </TableCellUI>
            </TableRowUI>
            <TableRowUI>
              <TableCellUI isHead>차기수행일</TableCellUI>
              <TableCellUI>
                <DatePickerWithHookForm
                  name="nextExecutionDate"
                  onSubmit={onSubmit}
                  readOnly={readOnly}
                />
              </TableCellUI>
              <TableCellUI>
                <InputWithHookForm
                  name="nextExecutionDateNote"
                  readOnly={readOnly}
                  onSubmit={onSubmit}
                  placeholder="차기 수행 목적 및 KPI가 있다면 해당 내용을 입력해주세요"
                />
              </TableCellUI>
            </TableRowUI>
          </TableBodyUI>
        </TableTableUI>
      </TableContainerUI>
    </FormProvider>
  );
}

function useMutationLogic({ dataId, menuId, sectionId }) {
  const { mutate: onUpdate, isLoading: isUpdateLoading } = workScheduleMutation.useUpdate(
    dataId,
    menuId
  );
  const { mutate: onInterimReportDateCreate, isLoading: isInterimReportDateCreateLoading } =
    workInterimReportDateMutation.useCreate(dataId, menuId);
  const { mutate: onInterimReportDateUpdate, isLoading: isInterimReportDateUpdateLoading } =
    workInterimReportDateMutation.useUpdate(menuId);
  const { mutate: onInterimReportDateDelete, isLoading: isInterimReportDateDeleteLoading } =
    workInterimReportDateMutation.useDelete({
      dataId,
      menuId,
      sectionId,
    });
  const isLoading =
    isUpdateLoading ||
    isInterimReportDateCreateLoading ||
    isInterimReportDateUpdateLoading ||
    isInterimReportDateDeleteLoading;
  return {
    isLoading,
    onUpdate,
    onInterimReportDateCreate,
    onInterimReportDateUpdate,
    onInterimReportDateDelete,
  };
}

function getFormValues(detail) {
  return {
    instructionDate: detail?.instructionDate ? dayjs(detail.instructionDate) : null,
    instructionDateNote: detail?.instructionDateNote ?? '',
    finalReportDate: detail?.finalReportDate ? dayjs(detail.finalReportDate) : null,
    finalReportDateNote: detail?.finalReportDateNote ?? '',
    dueDate: detail?.dueDate ? dayjs(detail.dueDate) : null,
    dueDateNote: detail?.dueDateNote ?? '',
    completionDate: detail?.completionDate ? dayjs(detail.completionDate) : null,
    completionDateNote: detail?.completionDateNote ?? '',
    nextExecutionDate: detail?.nextExecutionDate ? dayjs(detail.nextExecutionDate) : null,
    nextExecutionDateNote: detail?.nextExecutionDateNote ?? '',
  };
}
