import { useQuery } from 'react-query';
import { projectSalesInfoSubjectReviewExperimentApi } from '@front/src/features/project-sales-info/features/subject-review/features/experiment/query/api';
import type { ProjectExperimentTargetByComplexInformationView } from '@front/src/features/project-sales-info/features/subject-review/types/view';

export const projectSalesInfoSubjectReviewExperimentQuery = {
  useDetailGet: (id?: number) => {
    const { data, isLoading } = useQuery<ProjectExperimentTargetByComplexInformationView>({
      queryFn: () => projectSalesInfoSubjectReviewExperimentApi.getDetail(id),
      queryKey: ['project', 'sales-info', 'subject-review', 'experiment', id],
      enabled: !!id,
    });
    return {
      data,
      isLoading,
    };
  },
};
