import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { AccountingSettingsManagementAccountingAccountTableCategoryListContext } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/provider/context';
import { useAccountingSettingsManagementAccountingAccountTableCategoryListLogic } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/provider/useLogic';

export { Provider as AccountingSettingsManagementAccountingAccountTableCategoryListProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const {
    list,
    onCreate,
    onUpdate,
    handleDelete,
    handleInsert,
    handleMoveDown,
    handleMoveUp,
    handleChangeName,
    handleKeyDown,
    handleClose,
    tableRowNum,
  } = useAccountingSettingsManagementAccountingAccountTableCategoryListLogic();
  const value = useMemo(
    () => ({
      list,
      onCreate,
      onUpdate,
      handleDelete,
      handleInsert,
      handleMoveDown,
      handleMoveUp,
      handleChangeName,
      handleKeyDown,
      handleClose,
      tableRowNum,
    }),
    [
      list,
      onCreate,
      onUpdate,
      handleDelete,
      handleInsert,
      handleMoveDown,
      handleMoveUp,
      handleChangeName,
      handleKeyDown,
      handleClose,
      tableRowNum,
    ]
  );
  return (
    <AccountingSettingsManagementAccountingAccountTableCategoryListContext.Provider value={value}>
      {children}
    </AccountingSettingsManagementAccountingAccountTableCategoryListContext.Provider>
  );
};
