import { create } from 'zustand';
import { defaultAffiliatedPersonCreateForm } from '@front/src/features/affiliated-person/features/app-bar/utils';
import type { AffiliatedPersonCreateFormType } from '@front/src/features/affiliated-person/features/app-bar/types';

export { useState as useAffiliatedPersonAppBarState };

interface State {
  isCreateModalOpen: boolean;
  setIsCreateModalOpen: (isCreateModalOpen: boolean) => void;
  isConfirmModalOpen: boolean;
  setIsConfirmModalOpen: (isCreateModalOpen: boolean) => void;
  createParameter: AffiliatedPersonCreateFormType;
  setCreateParameter: (personName: AffiliatedPersonCreateFormType) => void;
  isSearchModalOpen: boolean;
  setIsSearchModalOpen: (isSearchModalOpen: boolean) => void;
  personNumber: number;
  setPersonNumber: (personNumber: number) => void;
}

const useState = create<State>((set) => ({
  isCreateModalOpen: false,
  setIsCreateModalOpen: (isCreateModalOpen) =>
    set(() => ({
      isCreateModalOpen,
    })),
  isConfirmModalOpen: false,
  setIsConfirmModalOpen: (isConfirmModalOpen) =>
    set(() => ({
      isConfirmModalOpen,
    })),
  createParameter: defaultAffiliatedPersonCreateForm,
  setCreateParameter: (createParameter) =>
    set(() => ({
      createParameter,
    })),
  isSearchModalOpen: false,
  setIsSearchModalOpen: (isSearchModalOpen) =>
    set(() => ({
      isSearchModalOpen,
    })),
  personNumber: 0,
  setPersonNumber: (personNumber) =>
    set(() => ({
      personNumber,
    })),
}));
