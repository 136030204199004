import React, { useMemo } from 'react';
import { AffiliatedCompanyOutlineNumberTableRowBusinessRegistrationFile } from '@front/src/features/affiliated-company/features/outline/features/number/features/business-registration/components/file';
import { useAffiliatedCompanyOutlineNumberBusinessRegistrationFileLogic } from '@front/src/features/affiliated-company/features/outline/features/number/features/business-registration/widgets/useLogic';
import { AffiliatedCompanyOutlineNumberBusinessRegistrationFileContext } from '@front/src/features/affiliated-company/features/outline/features/number/features/business-registration/widgets/context';

export { Widget as AffiliatedCompanyOutlineNumberBusinessRegistrationWidget };

interface Props {
  menuId?: number | undefined;
}

const Widget = ({ menuId }: Props) => {
  const {
    d: { businessRegistrationNumberFile },
    h: { onOutlineBusinessRegistrationFileUpdate },
  } = useAffiliatedCompanyOutlineNumberBusinessRegistrationFileLogic(menuId);
  const value = useMemo(
    () => ({
      d: { businessRegistrationNumberFile },
      h: { onOutlineBusinessRegistrationFileUpdate },
    }),
    [businessRegistrationNumberFile, onOutlineBusinessRegistrationFileUpdate]
  );
  return (
    <AffiliatedCompanyOutlineNumberBusinessRegistrationFileContext.Provider value={value}>
      <AffiliatedCompanyOutlineNumberTableRowBusinessRegistrationFile />
    </AffiliatedCompanyOutlineNumberBusinessRegistrationFileContext.Provider>
  );
};
