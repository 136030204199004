import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { MyPageAuthorizationTabContext } from '@front/src/features/my-page/features/authorization/widgets/tab/provider/context';
import { useMyPageAuthorizationTabLogic } from '@front/src/features/my-page/features/authorization/widgets/tab/provider/useLogic';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

export { Provider as MyPageAuthorizationTabProvider };

interface Props extends SectionComponentProps {
  children: ReactNode;
}

const Provider = ({ children, menuId }: Props) => {
  const { list, adminMenuList, notDevelopmentMenuList } = useMyPageAuthorizationTabLogic(menuId);
  const value = useMemo(
    () => ({
      list,
      adminMenuList,
      notDevelopmentMenuList,
    }),
    [list, adminMenuList, notDevelopmentMenuList]
  );
  return (
    <MyPageAuthorizationTabContext.Provider value={value}>
      {children}
    </MyPageAuthorizationTabContext.Provider>
  );
};
