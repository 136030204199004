import { Box } from '@mui/material';
import { FormikContext } from 'formik';
import type { KeyboardEvent } from 'react';
import React, { memo, useContext, useEffect } from 'react';

import Input from '@front/layouts/Input';
import Divider from '@front/layouts/Divider';
import { ostRewardManagementAction } from '@front/ost_reward/action';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';

interface Props {
  onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
}

export { memoized as OstRewardManagementMemoSearchForm };

const SearchForm = ({ onKeyDown }: Props) => {
  const dispatch = useDispatch();
  const formik = useContext(FormikContext);
  const { id } = useSelector(
    (root: RootState) => ({ id: root.ostRewardMangement.id }),
    shallowEqual
  );

  useEffect(() => {
    if (id) {
      dispatch(ostRewardManagementAction.setMemoFilter({ keyword: '' }));
    }
  }, [id, dispatch]);

  return (
    <Box
      sx={{
        padding: '0 10px',
        margin: '10px 0',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        flex: 1,
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Input
          key={formik.values.keyword}
          defaultValue={formik.values.keyword ?? ''}
          variant="outlined"
          placeholder="검색어 입력 후 엔터"
          onKeyDown={onKeyDown}
        />
      </Box>
      <Divider sx={{ margin: '10px 0 0 0', padding: '0' }} />
    </Box>
  );
};

const memoized = memo(SearchForm);
