import React, { useCallback, useContext, useMemo } from 'react';
import type { AccountingSettingsManagementAccountingAccountTableCategoryView } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/types/view';
import { ColorPalette } from '@front/assets/theme';
import { useAccountingSettingsManagementAccountingAccountTableState } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/useState';
import { AccountingSettingsManagementAccountingAccountContext } from '@front/src/features/accounting/features/settings/features/management-accounting-account/provider/context';
import { shallow } from 'zustand/shallow';
import { accountingSettingsManagementAccountingTableLayout } from '@front/src/features/accounting/features/settings/components/layouts/table';

export { AliasItem as AccountingSettingsManagementAccountingAccountTableBodyCellAliasItem };

interface Props {
  item: AccountingSettingsManagementAccountingAccountTableCategoryView;
  handleCategoryClick: (item) => void;
}

const AliasItem = ({ item, handleCategoryClick }: Props) => {
  const { readOnly } = useContext(AccountingSettingsManagementAccountingAccountContext);
  const { TableBodyCell } = accountingSettingsManagementAccountingTableLayout;
  const { parentIdList, setAnchorEl, setTargetItem, setUpdateId } =
    useAccountingSettingsManagementAccountingAccountTableState(
      (state) => ({
        parentIdList: state.parentIdList,
        setAnchorEl: state.setAnchorEl,
        setTargetItem: state.setTargetItem,
        setUpdateId: state.setUpdateId,
      }),
      shallow
    );
  const styleIsSelected = useMemo(() => {
    const selected = parentIdList?.includes(item.id);
    return {
      backgroundColor: selected ? ColorPalette._cddaf5 : ColorPalette._ffffff,
      fontWeight: selected ? 'bold' : 'normal',
      cursor: selected ? 'default' : 'pointer',
    };
  }, [parentIdList, item.id]);
  const handleRowClick = useCallback(() => {
    handleCategoryClick(item);
  }, [handleCategoryClick, item]);
  const handleContextMenu = useCallback(
    (e) => {
      e.preventDefault();
      if (readOnly) return;
      setTargetItem(item);
      setAnchorEl(e.currentTarget);
      setUpdateId(undefined);
    },
    [setAnchorEl, setTargetItem, setUpdateId, readOnly, item]
  );
  return (
    <TableBodyCell
      sx={{
        ...styleIsSelected,
      }}
      onClick={handleRowClick}
      onContextMenu={handleContextMenu}
    >
      {item.alias}
    </TableBodyCell>
  );
};
