import { create } from 'zustand';

export { useState as useDepartmentState };

interface State {
  readOnly?: boolean;
}

interface Actions {
  setReadOnly: (readOnly?: boolean) => void;
}

const useState = create<State & Actions>((set) => ({
  readOnly: false,
  setReadOnly: (readOnly) => set({ readOnly }),
}));
