import {
  contractEarningsApiUrl,
  smProjectCollectionApiUrl,
} from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/utils/constants';
import { default as axios } from '@front/src/config/axios';
import type { CollectionIdDeleteParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/parameters';
import type { ProjectContractEarningsNameParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/types/parameter';

const url = {
  deleteContractEarnings: (itemId: number) =>
    `${contractEarningsApiUrl}/contract-earnings/${itemId}`,

  getContractEarningsItem: (itemId: number) => `${smProjectCollectionApiUrl}/${itemId}/collection`,
  deleteContractEarningsItem: () => `${smProjectCollectionApiUrl}/collection`,
  updateContractEarningsName: (itemId: number) =>
    `${contractEarningsApiUrl}/contract-earnings/${itemId}`,
};

export const projectSalesInfoContractEarningsItemApi = {
  deleteContractEarnings: async (itemId: number) =>
    await axios.delete(url.deleteContractEarnings(itemId)),

  getContractEarningsItem: async (itemId: number) => {
    const { data } = await axios.get(url.getContractEarningsItem(itemId));
    return data;
  },
  deleteContractEarningsItemDetails: async (params: CollectionIdDeleteParams) =>
    await axios.delete(url.deleteContractEarningsItem(), { data: params }),
  updateContractEarningsName: async (params: ProjectContractEarningsNameParams) =>
    await axios.put(url.updateContractEarningsName(params.id!), params),
};
