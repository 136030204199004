import { workApi } from '@front/src/features/work/utils/constants';
import type {
  WorkBasicInfoParams,
  WorkBasicSearchWordParams,
} from '@front/src/features/work/features/work/features/basic-info/types/parameter';
import type { WorkCategoryParams } from '@front/src/features/work/features/work/features/category/types/parameter';
import type {
  WorkWorkerDeleteAllParameter,
  WorkWorkerDeleteParameter,
  WorkWorkerParams,
} from '@front/src/features/work/features/work/features/worker/types/parameter';
import type { WorkScheduleParams } from '@front/src/features/work/features/work/features/schedule/types/parameter';
import type {
  WorkReviewerDeleteAllParameter,
  WorkReviewerDeleteParameter,
  WorkReviewerParams,
} from '@front/src/features/work/features/work/features/reviewer/types/parameter';
import type { WorkDetailUpdateParameter } from '@front/src/features/work/features/work/features/detail/types/parameter';
import type {
  WorkReferenceFileUpdateParameter,
  WorkReferenceUpdateParameter,
} from '@front/src/features/work/features/work/features/reference/types/parameter';
import type {
  WorkOutputBundleCreateParameter,
  WorkOutputBundleUpdateParameter,
} from '@front/src/features/work/features/work/features/output-bundle/types/parameter';
import type {
  WorkOutputContentUpdateParameter,
  WorkOutputFileUpdateParameter,
} from '@front/src/features/work/features/work/features/output/types/parameter';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { WorkScheduleView } from '@front/src/features/work/features/work/features/schedule/types/view';
import type { WorkNameView } from '@front/src/features/work/features/work/features/name/types/view';
import type { WorkNameUpdateParameter } from '@front/src/features/work/features/work/features/name/types/parameter';
import type { WorkRoleView } from '@front/src/features/work/features/work/types/view';
import type { WorkInterimReportDateView } from '@front/src/features/work/features/work/features/schedule/features/interim-report-date/types/view';
import type { WorkScheduleInterimReportDateParameter } from '@front/src/features/work/features/work/features/schedule/features/interim-report-date/types/parameter';
import type { WorkCreatorView } from '@front/src/features/work/features/work/features/creator/types/view';
import type { WorkCreatorParameter } from '@front/src/features/work/features/work/features/creator/types/parameter';
import type { WorkBasicInfoView } from '@front/src/features/work/features/work/features/basic-info/types/view';
import type { WorkWorkerView } from '@front/src/features/work/features/work/features/worker/types/view';
import type { WorkReviewerView } from '@front/src/features/work/features/work/features/reviewer/types/view';
import type { WorkCategoryView } from '@front/src/features/work/features/work/features/category/types/view';
import type { WorkReferenceView } from '@front/src/features/work/features/work/features/reference/types/view';
import type { WorkOutputBundleView } from '@front/src/features/work/features/work/features/output-bundle/types/view';
import type { WorkOutputView } from '@front/src/features/work/features/work/features/output/types/view';
import type { WorkCarouselView } from '@front/src/features/work/features/work/features/carousel/types/view';
import type {
  WorkApprovalDocumentView,
  WorkApprovalView,
} from '@front/src/features/work/features/work/features/approval/types/view';
import type { WorkDetailView } from '@front/src/features/work/features/work/features/detail/types/view';
import type { CommonGetParameter } from '@front/src/types/parameter';

const url = {
  getBasicInfo: (id?: number) => `${workApi}/${id}/basic`,
  updateBasicInfo: (id?: number) => `${workApi}/${id}/basic`,
  getCategory: (id?: number) => `${workApi}/${id}/category`,
  updateCategory: (id?: number) => `${workApi}/${id}/category`,
  getWorkerList: (id?: number) => `${workApi}/${id}/worker`,
  addTopWorker: (id?: number) => `${workApi}/${id}/worker/top`,
  addBottomWorker: (id?: number) => `${workApi}/${id}/worker/bottom`,
  createWorker: (id?: number) => `${workApi}/worker/${id}`,
  updateWorker: (id?: number) => `${workApi}/worker/${id}`,
  deleteWorker: (id?: number) => `${workApi}/worker/${id}`,
  deleteAllWorker: (id?: number) => `${workApi}/${id}/worker`,
  updateWorkerSeqUp: (id?: number) => `${workApi}/worker/${id}/seq-up`,
  updateWorkerSeqDown: (id?: number) => `${workApi}/worker/${id}/seq-down`,
  createSearchWord: (id?: number) => `${workApi}/${id}/search-word`,
  deleteSearchWord: (id?: number) => `${workApi}/${id}/search-word`,
  getSchedule: (id?: number) => `${workApi}/${id}/schedule`,
  updateSchedule: (id?: number) => `${workApi}/${id}/schedule`,
  createScheduleInterimReportDate: (id?: number) => `${workApi}/${id}/interim-report-date`,
  getScheduleInterimReportDate: (id?: number) => `${workApi}/${id}/interim-report-date`,
  updateScheduleInterimReportDate: (id?: number) => `${workApi}/interim-report-date/${id}`,
  deleteScheduleInterimReportDate: (id?: number) => `${workApi}/interim-report-date/${id}`,
  getIndicator: (id?: number) => `${workApi}/${id}/indicator`,
  updateIndicator: (id?: number) => `${workApi}/indicator/${id}`,
  getManager: (id?: number) => `${workApi}/${id}/manager`,
  updateManager: (id?: number) => `${workApi}/manager/${id}`,
  getReviewerList: (id?: number) => `${workApi}/${id}/reviewer`,
  createReviewer: (id?: number) => `${workApi}/reviewer/${id}`,
  addTopReviewer: (id?: number) => `${workApi}/${id}/reviewer/top`,
  addBottomReviewer: (id?: number) => `${workApi}/${id}/reviewer/bottom`,
  updateReviewer: (id?: number) => `${workApi}/reviewer/${id}`,
  deleteReviewer: (id: number | undefined) => `${workApi}/reviewer/${id}`,
  deleteAllReviewer: (id?: number) => `${workApi}/${id}/reviewer`,
  updateReviewerSeqUp: (id?: number) => `${workApi}/reviewer/${id}/seq-up`,
  updateReviewerSeqDown: (id?: number) => `${workApi}/reviewer/${id}/seq-down`,
  getDetailList: (id?: number) => `${workApi}/${id}/detail`,
  createDetail: (id?: number) => `${workApi}/detail/${id}`,
  addTopDetail: (id?: number) => `${workApi}/${id}/detail/top`,
  addBottomDetail: (id?: number) => `${workApi}/${id}/detail/bottom`,
  updateDetail: (id?: number) => `${workApi}/detail/${id}`,
  deleteDetail: (id?: number) => `${workApi}/detail/${id}`,
  deleteAllDetail: (id?: number) => `${workApi}/${id}/detail`,
  updateDetailSeqUp: (id?: number) => `${workApi}/detail/${id}/seq-up`,
  updateDetailSeqDown: (id?: number) => `${workApi}/detail/${id}/seq-down`,
  createApprovalReferenceTop: (id?: number) => `${workApi}/${id}/reference/top`,
  createApprovalReferenceBottom: (id?: number) => `${workApi}/${id}/reference/bottom`,
  createReference: (id?: number) => `${workApi}/reference/${id}`,
  getReference: (id?: number) => `${workApi}/${id}/reference`,
  updateReferenceFile: (id?: number) => `${workApi}/reference/${id}/file`,
  updateReference: (id?: number) => `${workApi}/reference/${id}/content`,
  updateReferenceSeqUp: (id?: number) => `${workApi}/reference/${id}/seq-up`,
  updateReferenceSeqDown: (id?: number) => `${workApi}/reference/${id}/seq-down`,
  deleteReference: (id?: number) => `${workApi}/reference/${id}`,
  deleteAllReference: (id?: number) => `${workApi}/${id}/reference`,
  createOutputBundle: (id?: number) => `${workApi}/${id}/output-bundle`,
  getOutputBundle: (id?: number) => `${workApi}/${id}/output-bundle`,
  updateOutputBundle: (id?: number) => `${workApi}/output-bundle/${id}`,
  deleteOutputBundle: (id?: number) => `${workApi}/output-bundle/${id}`,
  createOutputTop: (id?: number) => `${workApi}/output-bundle/${id}/top`,
  createOutputBottom: (id?: number) => `${workApi}/output-bundle/${id}/bottom`,
  createOutput: (id?: number) => `${workApi}/output/${id}`,
  updateOutputFile: (id?: number) => `${workApi}/output/${id}/file`,
  updateOutput: (id?: number) => `${workApi}/output/${id}`,
  updateOutputSeqUp: (id?: number) => `${workApi}/output/${id}/seq-up`,
  updateOutputSeqDown: (id?: number) => `${workApi}/output/${id}/seq-down`,
  deleteOutput: (id?: number) => `${workApi}/output/${id}`,
  deleteAllOutput: (id?: number) => `${workApi}/output-bundle/${id}/all`,
  createCarousel: (id?: number) => `${workApi}/${id}/carousel`,
  getCarousel: (id?: number) => `${workApi}/${id}/carousel`,
  deleteCarousel: (id?: number) => `${workApi}/carousel/${id}`,
  getName: (id?: number) => `/api/work/${id}/name`,
  updateName: (id?: number) => `/api/work/${id}/name`,
  getRole: (id?: number) => `/api/work/${id}/role`,
  getOutputList: (id?: number) => `/api/work/output-bundle/${id}`,
  getWorkApproval: (id?: number) => `/api/work/${id}/approval`,
  getWorkApprovalDocumentList: (id?: number) => `/api/work/${id}/approval-document`,
};

export const workWorkApi = {
  getBasicInfo: async <T = WorkBasicInfoView>(id?: number, menuId?: number) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getBasicInfo(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updateBasicInfo: async (params: WorkBasicInfoParams, id: number, menuId?: number) =>
    await axios.patch(url.updateBasicInfo(id), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  getCategory: async <T = WorkCategoryView>(params: CommonGetParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getCategory(params.dataId), {
      headers: getMenuIdHeaders(params.menuId),
      params,
    });
    return data;
  },
  updateCategory: async (params: WorkCategoryParams, id: number, menuId?: number) =>
    await axios.patch(url.updateCategory(id), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  getWorkerList: async <T = WorkWorkerView[]>(params: CommonGetParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getWorkerList(params.dataId), {
      headers: getMenuIdHeaders(params.menuId),
      params,
    });
    return data;
  },
  addTopWorker: async (id: number, menuId?: number) =>
    await axios.post(url.addTopWorker(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  addBottomWorker: async (id: number, menuId?: number) =>
    await axios.post(url.addBottomWorker(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  createWorker: async (id: number, menuId?: number) =>
    await axios.post(url.createWorker(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  updateWorker: async (params: WorkWorkerParams, menuId?: number) =>
    await axios.patch(url.updateWorker(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  deleteWorker: async (params: WorkWorkerDeleteParameter) =>
    await axios.delete(url.deleteWorker(params.id), {
      headers: getMenuIdHeaders(params.menuId),
      data: params,
    }),
  deleteAllWorker: async (params: WorkWorkerDeleteAllParameter) =>
    await axios.delete(url.deleteAllWorker(params.dataId), {
      headers: getMenuIdHeaders(params.menuId),
      data: params,
    }),
  updateWorkerSeqUp: async (id: number, menuId?: number) =>
    await axios.patch(url.updateWorkerSeqUp(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  updateWorkerSeqDown: async (id: number, menuId?: number) =>
    await axios.patch(url.updateWorkerSeqDown(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  createSearchWord: async (params: WorkBasicSearchWordParams, id: number, menuId?: number) =>
    await axios.post(url.createSearchWord(id), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  deleteSearchWord: async (params: WorkBasicSearchWordParams, id: number, menuId?: number) =>
    await axios.delete(url.deleteSearchWord(id), {
      headers: getMenuIdHeaders(menuId),
      data: params,
    }),
  getSchedule: async <T = WorkScheduleView>(id?: number, menuId?: number) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getSchedule(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updateSchedule: async (params: WorkScheduleParams, id, menuId?: number) =>
    await axios.patch(url.updateSchedule(id), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  createScheduleInterimReportDate: async (id: number, menuId?: number) =>
    await axios.post(url.createScheduleInterimReportDate(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  getScheduleInterimReportDate: async <T = WorkInterimReportDateView[]>(
    id?: number,
    menuId?: number
  ) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getScheduleInterimReportDate(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updateScheduleInterimReportDate: async <T, R = WorkScheduleInterimReportDateParameter>(
    id,
    params: R,
    menuId?: number
  ) =>
    await axios.patch<T, AxiosResponse<T>, R>(url.updateScheduleInterimReportDate(id), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  deleteScheduleInterimReportDate: async (id: number, params: CommonGetParameter) =>
    await axios.delete(url.deleteScheduleInterimReportDate(id), {
      headers: getMenuIdHeaders(params.menuId),
      data: params,
    }),
  getIndicator: async <T = WorkCreatorView>(params: CommonGetParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getIndicator(params.dataId), {
      headers: getMenuIdHeaders(params.menuId),
      params,
    });
    return data;
  },
  updateIndicator: async (id, params: WorkCreatorParameter, menuId?: number) =>
    await axios.patch(url.updateIndicator(id), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  getManager: async <T = WorkCreatorView>(params: CommonGetParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getManager(params.dataId), {
      headers: getMenuIdHeaders(params.menuId),
      params,
    });
    return data;
  },
  updateManager: async (id, params: WorkCreatorParameter, menuId?: number) =>
    await axios.patch(url.updateManager(id), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  getReviewerList: async <T = WorkReviewerView[]>(params: CommonGetParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getReviewerList(params.dataId), {
      headers: getMenuIdHeaders(params.menuId),
      params,
    });
    return data;
  },
  createReviewer: async (id: number, menuId?: number) =>
    await axios.post(url.createReviewer(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  addTopReviewer: async (id: number, menuId?: number) =>
    await axios.post(url.addTopReviewer(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  addBottomReviewer: async (id: number, menuId?: number) =>
    await axios.post(url.addBottomReviewer(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  updateReviewer: async (params: WorkReviewerParams, menuId?: number) =>
    await axios.patch(url.updateReviewer(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  deleteReviewer: async (params: WorkReviewerDeleteParameter) =>
    await axios.delete(url.deleteReviewer(params.id), {
      headers: getMenuIdHeaders(params.menuId),
      data: params,
    }),
  deleteAllReviewer: async (params: WorkReviewerDeleteAllParameter) =>
    await axios.delete(url.deleteAllReviewer(params.dataId), {
      headers: getMenuIdHeaders(params.menuId),
      data: params,
    }),
  updateReviewerSeqUp: async (id: number, menuId?: number) =>
    await axios.patch(url.updateReviewerSeqUp(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  updateReviewerSeqDown: async (id: number, menuId?: number) =>
    await axios.patch(url.updateReviewerSeqDown(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  getDetailList: async <T = WorkDetailView[]>(params: CommonGetParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getDetailList(params.dataId), {
      headers: getMenuIdHeaders(params.menuId),
      params,
    });
    return data;
  },
  createDetail: async (id: number, menuId?: number) =>
    await axios.post(url.createDetail(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  addTopDetail: async (id: number, menuId?: number) =>
    await axios.post(url.addTopDetail(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  addBottomDetail: async (id: number, menuId?: number) =>
    await axios.post(url.addBottomDetail(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  updateDetail: async (params: WorkDetailUpdateParameter, menuId?: number) =>
    await axios.patch(url.updateDetail(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  deleteDetail: async (id: number, params: CommonGetParameter) =>
    await axios.delete(url.deleteDetail(id), {
      headers: getMenuIdHeaders(params.menuId),
      data: params,
    }),
  deleteAllDetail: async (params: CommonGetParameter) =>
    await axios.delete(url.deleteAllDetail(params.dataId), {
      headers: getMenuIdHeaders(params.menuId),
      data: params,
    }),
  updateDetailSeqUp: async (id: number, menuId?: number) =>
    await axios.patch(url.updateDetailSeqUp(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  updateDetailSeqDown: async (id: number, menuId?: number) =>
    await axios.patch(url.updateDetailSeqDown(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  createApprovalReferenceTop: async (id: number, menuId?: number) =>
    await axios.post(url.createApprovalReferenceTop(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  createApprovalReferenceBottom: async (id: number, menuId?: number) =>
    await axios.post(url.createApprovalReferenceBottom(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  createReference: async (id: number, menuId?: number) =>
    await axios.post(url.createReference(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  getReference: async <T = WorkReferenceView[]>(params: CommonGetParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getReference(params.dataId), {
      headers: getMenuIdHeaders(params.menuId),
      params,
    });
    return data;
  },
  updateReferenceFile: async (params: WorkReferenceFileUpdateParameter, menuId?: number) =>
    await axios.patch(url.updateReferenceFile(params.id), params.formData, {
      headers: getMenuIdHeaders(menuId),
    }),
  updateReference: async (params: WorkReferenceUpdateParameter, menuId?: number) =>
    await axios.patch(url.updateReference(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  updateReferenceSeqUp: async (id: number, menuId?: number) =>
    await axios.patch(url.updateReferenceSeqUp(id), {
      headers: getMenuIdHeaders(menuId),
    }),
  updateReferenceSeqDown: async (id: number, menuId?: number) =>
    await axios.patch(url.updateReferenceSeqDown(id), {
      headers: getMenuIdHeaders(menuId),
    }),
  deleteReference: async (id: number, params: CommonGetParameter) =>
    await axios.delete(url.deleteReference(id), {
      headers: getMenuIdHeaders(params.menuId),
      data: params,
    }),
  deleteAllReference: async (params: CommonGetParameter) =>
    await axios.delete(url.deleteAllReference(params.dataId), {
      headers: getMenuIdHeaders(params.menuId),
      data: params,
    }),
  createOutputBundle: async (
    params: WorkOutputBundleCreateParameter,
    id: number,
    menuId?: number
  ) =>
    await axios.post(url.createOutputBundle(id), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  getOutputBundle: async <T = WorkOutputBundleView[]>(id?: number, menuId?: number) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getOutputBundle(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updateOutputBundle: async (params: WorkOutputBundleUpdateParameter, menuId?: number) =>
    await axios.patch(url.updateOutputBundle(params.id), params, {
      headers: getMenuIdHeaders(menuId),
      data: params,
    }),
  deleteOutputBundle: async (id: number, params: CommonGetParameter) =>
    await axios.delete(url.deleteOutputBundle(id), {
      headers: getMenuIdHeaders(params.menuId),
      data: params,
    }),
  createOutputTop: async (id: number, menuId?: number) =>
    await axios.post(url.createOutputTop(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  createOutputBottom: async (id: number, menuId?: number) =>
    await axios.post(url.createOutputBottom(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  createOutput: async (id: number, menuId?: number) =>
    await axios.post(url.createOutput(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  updateOutput: async (params: WorkOutputContentUpdateParameter, menuId?: number) =>
    await axios.patch(url.updateOutput(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  updateOutputFile: async (params: WorkOutputFileUpdateParameter, menuId?: number) =>
    await axios.patch(url.updateOutputFile(params.id), params.formData, {
      headers: getMenuIdHeaders(menuId),
    }),
  updateOutputSeqUp: async (id: number, menuId?: number) =>
    await axios.patch(url.updateOutputSeqUp(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  updateOutputSeqDown: async (id: number, menuId?: number) =>
    await axios.patch(url.updateOutputSeqDown(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  deleteOutput: async (id: number, params: CommonGetParameter) =>
    await axios.delete(url.deleteOutput(id), {
      headers: getMenuIdHeaders(params.menuId),
      data: params,
    }),
  deleteAllOutput: async (id: number, params: CommonGetParameter) =>
    await axios.delete(url.deleteAllOutput(id), {
      headers: getMenuIdHeaders(params.menuId),
      data: params,
    }),
  createCarousel: async (params, workId?, menuId?: number) =>
    await axios.post(url.createCarousel(workId), params.formData, {
      headers: getMenuIdHeaders(menuId),
    }),
  getCarousel: async <T = WorkCarouselView[]>(id?: number, menuId?: number) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getCarousel(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  deleteCarousel: async (id: number, params: CommonGetParameter) =>
    await axios.delete(url.deleteCarousel(id), {
      headers: getMenuIdHeaders(params.menuId),
      data: params,
    }),
  getName: async <T = WorkNameView>(id: number, menuId?: number) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getName(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updateName: async <T, R = WorkNameUpdateParameter>(params: R, id, menuId?: number) =>
    await axios.patch<T, AxiosResponse<T>, R>(url.updateName(id), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  getRole: async <T = WorkRoleView>(id?: number, menuId?: number) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getRole(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getOutputList: async <T = WorkOutputView[]>(params: CommonGetParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getOutputList(params.bundleId), {
      headers: getMenuIdHeaders(params.menuId),
      params,
    });
    return data;
  },
  getWorkApproval: async <T = WorkApprovalView>(params: CommonGetParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getWorkApproval(params.dataId), {
      headers: getMenuIdHeaders(params.menuId),
      params,
    });
    return data;
  },
  getWorkApprovalDocumentList: async <T = WorkApprovalDocumentView[]>(
    dataId: number,
    menuId?: number
  ) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getWorkApprovalDocumentList(dataId), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};

const getMenuIdHeaders = (menuId) => ({
  'Menu-Id': menuId,
});
