import { projectSalesActivityRepository } from '@front/src/features/project-sales-info/features/activity/repository/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { useShallow } from 'zustand/react/shallow';
import { activityCategoryListKeyword } from '@front/src/features/project-sales-info/features/activity/utils/constants';
import { useEffect } from 'react';
import { useProjectSalesInfoActivityState } from '@front/src/features/project-sales-info/features/activity/useState';
import type {
  SalesActivityAffiliatesConvertedView,
  SalesActivityAffiliatesView,
} from '@front/src/features/project-sales-info/features/activity/types/views';

export { useLogic as useSalesInfoActivityListLogic };

const useLogic = () => {
  const { id } = useProjectSalesInfoDetailState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const { setList } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      setList: state.setList,
    }))
  );
  const {
    data: activityAffiliatesList,
  }: {
    data: SalesActivityAffiliatesView[];
  } = projectSalesActivityRepository.useActivityListGet(id!);
  const { data: categoryList } = projectSalesActivityRepository.useCategoryOptionListGet(
    activityCategoryListKeyword
  );
  useEffect(() => {
    if (typeof activityAffiliatesList === 'undefined') return;
    const convertedList: SalesActivityAffiliatesConvertedView[] = [];
    const activityIdListBySet = Array.from(
      new Set(activityAffiliatesList.map((aa) => aa.activity.id))
    );
    activityAffiliatesList.forEach((target) => {
      const idListFromSameActivity = activityAffiliatesList
        .filter((aa) => aa.activity.id === target.activity.id)
        .map((aa) => aa.id);
      const idListFromSameActivityUser = activityAffiliatesList
        .filter((aa) => aa.activityUser.id === target.activityUser.id)
        .map((aa) => aa.id);
      const minIdFromSameActivity = idListFromSameActivity.at(0);
      const maxIdFromSameActivity = idListFromSameActivity.at(-1);
      const minIdFromSameActivityUser = idListFromSameActivityUser.at(0);
      const convertedTarget: SalesActivityAffiliatesConvertedView = {
        ...target,
        isFirstActivity: false,
        isLastActivity: false,
        isFirstActivityUser: false,
        sameActivitySize: 1,
        sameActivityUserSize: 1,
        index: 1,
      };
      convertedTarget.isFirstActivity = target.id === minIdFromSameActivity;
      convertedTarget.isLastActivity = target.id === maxIdFromSameActivity;
      convertedTarget.isFirstActivityUser = target.id === minIdFromSameActivityUser;
      convertedTarget.sameActivitySize = idListFromSameActivity.length;
      convertedTarget.sameActivityUserSize = idListFromSameActivityUser.length;
      convertedTarget.index = activityIdListBySet.indexOf(convertedTarget.activity.id);
      convertedList.push(convertedTarget);
    });
    setList(convertedList);
  }, [activityAffiliatesList, setList]);
  return { categoryList };
};
