import React from 'react';
import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import workDetailQuery from '@front/src/features/work/features/work/features/detail/query/query';
import type { WorkDetailView } from '@front/src/features/work/features/work/features/detail/types/view';
import WorkDetailTableRow from '@front/src/features/work/features/work/features/detail/components/TableRow';

export default function WorkDetailTableBody({
  sectionId,
  dataId,
  menuId,
  ...rest
}: Readonly<UIBuilderTableBodyProps>) {
  const { data: list } = workDetailQuery.useGetList({
    dataId,
    menuId,
    sectionId,
  });
  return (
    <UIBuilderTableBody<WorkDetailView>
      list={list}
      RowComponent={WorkDetailTableRow}
      sectionId={sectionId}
      dataId={dataId}
      menuId={menuId}
      {...rest}
    />
  );
}
