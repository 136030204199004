import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import TextBox from '@front/layouts/Text';
import CircularProgress from '@front/components/CircularProgress';
import type { ListDepartment } from '@front/department_tree/entity';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useFormContext } from 'react-hook-form';
import userSelectorRepository from '@front/src/features/user-selector/repository/repository';

interface Node {
  id: number;
  name: string;
  children: Node[];
}

export default function UserSelectorDepartmentList() {
  const { data: departmentList } = userSelectorRepository.useGetDepartmentList();
  const { setValue, watch } = useFormContext();
  const toNode = (item?: ListDepartment) => {
    if (!item) return undefined;
    const children: ListDepartment[] = departmentList
      ? departmentList.filter((childItem) => childItem.parentId === item.id)
      : [];
    return {
      id: item.id,
      name: item.name,
      children: children.length === 0 ? [] : children.map(toNode),
    };
  };
  const initRoot = toNode(departmentList?.[0]);
  const expanded: string[] = [];
  const renderLabel = useCallback(
    (item) => (
      <Box
        sx={{
          display: 'block',
          width: '100%',
          fontSize: '13px',
          padding: '3px 0',
        }}
        onClick={async (e) => {
          setValue('keyword', '');
          setValue('departmentId', item.id);
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {item.name}
      </Box>
    ),
    [setValue]
  );
  const renderTree = (nodes: Node) => {
    expanded.push(nodes.id.toString());
    return (
      <TreeItem
        className={watch('departmentId') == nodes.id ? 'Mui-focused howoocast' : ''}
        onClick={(event) => {
          event.nativeEvent.stopPropagation();
          return false;
        }}
        key={nodes.id}
        nodeId={nodes.id.toString()}
        label={renderLabel(nodes)}
      >
        {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
      </TreeItem>
    );
  };
  if (!departmentList || departmentList.length === 0) {
    return (
      <TextBox
        variant="body2"
        sx={{ width: '100%', height: '100%' }}
      >
        <CircularProgress
          size={30}
          sx={{ justifyContent: 'center', alignItems: 'center' }}
        />
      </TextBox>
    );
  }
  return (
    <TreeView
      className="scroll-bar-holder"
      aria-label="customized"
      defaultExpanded={expanded}
      selected={watch('departmentId')}
      defaultCollapseIcon={<RemoveCircleOutlineIcon />}
      defaultExpandIcon={<ControlPointIcon />}
      sx={{
        width: '100%',
        height: '100%',
        flexGrow: 1,
        '& [role=group]': {
          borderLeft: '1px solid #c1c1c1',
        },
      }}
    >
      {renderTree(initRoot)}
    </TreeView>
  );
}
