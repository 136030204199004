import React, { useEffect, useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { AffiliatedCompanyPersonCreateModal } from '@front/src/features/affiliated-company/features/person/components/create-modal/modal';
import { AffiliatedCompanyPersonCreateButton } from '@front/src/features/affiliated-company/features/person/components/create-button';
import { AffiliatedCompanyPersonTable } from '@front/src/features/affiliated-company/features/person/components/person-table';
import { AffiliatedCompanyPersonDetailModalWidget } from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/widget';
import { useAffiliatedCompanyPersonLogic } from '@front/src/features/affiliated-company/features/person/widgets/useLogic';
import { AffiliatedCompanyPersonContext } from '@front/src/features/affiliated-company/features/person/widgets/context';
import { useShallow } from 'zustand/react/shallow';
import { useGetReadOnly } from '@front/src/hooks/useGetReadOnly';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AffiliatedCompanyPersonWidget = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    d: { personList },
    h: { onCreatePerson, onCreateModalOpen, onDetailModalOpen, onCreateModalClose },
    isLoading,
    isDetailModalOpen,
    isCreateModalOpen,
  } = useAffiliatedCompanyPersonLogic(menuId);
  const value = useMemo(
    () => ({
      d: { personList },
      h: {
        onCreatePerson,
        onCreateModalOpen,
        onDetailModalOpen,
        onCreateModalClose,
      },
      isLoading,
      isDetailModalOpen,
      isCreateModalOpen,
    }),
    [
      personList,
      onCreatePerson,
      isLoading,
      onCreateModalOpen,
      onDetailModalOpen,
      onCreateModalClose,
      isDetailModalOpen,
      isCreateModalOpen,
    ]
  );
  const { setReadOnly } = useAffiliatedCompanyPersonState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  const { readOnly } = useGetReadOnly();
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <AffiliatedCompanyPersonContext.Provider value={value}>
      <AffiliatedCompanyPersonCreateModal />
      <AffiliatedCompanyPersonDetailModalWidget />
      <Layout>
        <Header title={name}>
          <AffiliatedCompanyPersonCreateButton />
        </Header>
        <Body>
          <AffiliatedCompanyPersonTable />
        </Body>
      </Layout>
    </AffiliatedCompanyPersonContext.Provider>
  );
};

export default AffiliatedCompanyPersonWidget;
