import { default as axios } from '@front/src/config/axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import type { ProjectSalesInfoAffiliatedCompanyWebHardParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/types/parameter';

const url = {
  create: (id?) => `${projectSalesInfoURL}/${id}/affiliated-company-web-hard`,
};

export const projectSalesInfoAffiliatedCompanyWebHardCreateModalApi = {
  create: async (params: ProjectSalesInfoAffiliatedCompanyWebHardParameter) => {
    await axios.post(url.create(params.id), params);
  },
};
