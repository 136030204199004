import React, { useEffect, useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonTable } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/components/table';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonLogic } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/widgets/useLogic';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/widgets/context';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonButtonGroup } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/components/button-group';
import { ProjectSalesInfoAffiliatedCompanyPersonHelp } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/components/help';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/widgets/useState';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalWrapper from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/components/create-modal-wrapper';
import ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalWrapper from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/components/update-modal-wrapper';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonWidget = ({
  readOnly,
  name,
}: Readonly<Props>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  const {
    d: { list },
    h: { onDelete, onUpdate },
    isLoading,
  } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonLogic();
  const value = useMemo(
    () => ({
      d: { list },
      h: { onDelete, onUpdate },
      isLoading,
    }),
    [list, isLoading, onDelete, onUpdate]
  );
  const { setReadOnly } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <>
      <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalWrapper />
      <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalWrapper />
      <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonContext.Provider value={value}>
        <Layout>
          <Header title={name}>
            <ProjectSalesInfoAffiliatedCompanyPersonHelp />
            <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonButtonGroup />
          </Header>
          <Body>
            <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonTable />
          </Body>
        </Layout>
      </ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonContext.Provider>
    </>
  );
};

export default ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonWidget;
