import React, { Suspense } from 'react';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { AccountingUploadSalesInfoPerformanceModalListProvider } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/widgets/list/provider/provider';
import { AccountingUploadSalesInfoPerformanceModalForm } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/widgets/list/components/form';

export { Widget as AccountingUploadSalesInfoPerformanceModalListWidget };

const Widget = () => (
  <Suspense fallback={<LoadingSpinner height="592px" />}>
    <AccountingUploadSalesInfoPerformanceModalListProvider>
      <AccountingUploadSalesInfoPerformanceModalForm />
    </AccountingUploadSalesInfoPerformanceModalListProvider>
  </Suspense>
);
