import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { Table } from '@front/layouts/Table';
import classes from '@front/src/features/affiliated-company/features/outline/features/short/components/short-table.module.scss';
import { isEmpty } from '@front/components/DataFieldProps';
import { useSnackbar } from '@front/src/features/snackbar';
import type { AffiliatedCompanyOutlineShortUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/short/types/parameter';
import { AffiliatedCompanyStatus } from '@front/src/features/affiliated-company/types/domain';
import { affiliatedCompanyStatusRadioList } from '@front/src/features/affiliated-company/utils/constant';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { HookFormRadio } from '@front/src/components/radio-with-hook-form/radio-with-hook-form';
import { AffiliatedCompanyOutlineShortContext } from '@front/src/features/affiliated-company/features/outline/features/short/widgets/context';
import { convertToOptionList } from '@front/src/utils';
import { useAffiliatedCompanyOutlineState } from '@front/src/features/affiliated-company/features/outline/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import { WorkTd } from '@front/src/features/work/features/work/components/work-td';

export { ShortTable as AffiliatedCompanyOutlineShortTable };
const ShortTable = () => {
  const {
    h: { onOutlineShortUpdate },
    d: { detailOutline, categoryOptionList },
  } = useContext(AffiliatedCompanyOutlineShortContext);
  const methods = useForm<AffiliatedCompanyOutlineShortUpdateParameter>({
    values: {
      name: detailOutline?.name,
      category: detailOutline?.category ?? '',
      status: detailOutline?.status ?? AffiliatedCompanyStatus.NORMAL,
    },
  });
  const { handleSubmit, setValue } = methods;
  const onSubmit = handleSubmit((data) => {
    onOutlineShortUpdate(data);
  });
  const { show } = useSnackbar();
  const handleBlur = useCallback(
    async (value) => {
      if (value === detailOutline?.name) {
        return;
      }
      if (isEmpty(value)) {
        setValue('name', detailOutline?.name);
        show({ message: '회사명을 입력해주세요.' });
        return;
      }
      await onSubmit();
    },
    [show, onSubmit, detailOutline, setValue]
  );
  const { readOnly } = useAffiliatedCompanyOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow key={detailOutline?.name ?? Math.random()}>
              <Th>회사명</Th>
              <WorkTd>
                <HookFormInput
                  name="name"
                  onBlur={handleBlur}
                  disabled={readOnly}
                />
              </WorkTd>
            </TableRow>
            <TableRow key={detailOutline?.oldName ?? Math.random()}>
              <Th>회사명(구)</Th>
              <WorkTd>
                <div className={classes.td}>
                  <div>{detailOutline?.oldName}</div>
                  {detailOutline?.nameChangedDate && (
                    <div className={classes.modified__at}>
                      ({detailOutline?.nameChangedDate} 변경)
                    </div>
                  )}
                </div>
              </WorkTd>
            </TableRow>
            <TableRow key={detailOutline?.category ?? Math.random()}>
              <Th>업체구분</Th>
              <TableCell>
                <HookFormSelect
                  width="100px"
                  name="category"
                  defaultLabel="구분"
                  optionList={convertToOptionList(categoryOptionList)!}
                  onSubmit={onSubmit}
                  disabled={readOnly}
                />
              </TableCell>
            </TableRow>
            <TableRow key={detailOutline?.status ?? Math.random()}>
              <Th>상태</Th>
              <TableCell>
                {readOnly ? (
                  '-'
                ) : (
                  <HookFormRadio
                    name="status"
                    onSubmit={onSubmit}
                    radioList={affiliatedCompanyStatusRadioList}
                    direction="row"
                    disabled={readOnly}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </FormProvider>
  );
};
