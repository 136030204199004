import React, { useMemo } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { bidInfoModalLayout } from '@front/src/features/project-sales-info/features/bid/features/bid-information/components/layout';
import { useProjectSalesInfoBidResultState } from '@front/src/features/project-sales-info/features/bid/features/bid-result/widgets/useState';
import { useProjectSalesInfoBidResultUpdateModalLogic } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/update-modal/widgets/useLogic';
import { ProjectSalesInfoBidResultUpdateModalContext } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/update-modal/widgets/context';
import { ProjectSalesInfoBidResultUpdateModalTable } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/update-modal/components/modal-table';
import { useShallow } from 'zustand/react/shallow';
import { isMobileDevice } from '@front/util/PwaUtil';

export { Widget as ProjectSalesInfoBidResultUpdateModalWidget };

const Widget = () => {
  const { Layout, Body } = bidInfoModalLayout;
  const { isUpdateModalOpen, setIsUpdateModalOpen } = useProjectSalesInfoBidResultState(
    useShallow((state) => ({
      isUpdateModalOpen: state.isUpdateModalOpen,
      setIsUpdateModalOpen: state.setIsUpdateModalOpen,
    }))
  );
  const {
    h: { onUpdate },
    d: { bidResultDetail },
    isLoading,
  } = useProjectSalesInfoBidResultUpdateModalLogic();
  const value = useMemo(
    () => ({
      h: { onUpdate },
      d: { bidResultDetail },
      isLoading,
    }),
    [onUpdate, isLoading, bidResultDetail]
  );
  return (
    <ModalLayout
      position="left"
      width={isMobileDevice() ? '29dvw' : '560px'}
      open={isUpdateModalOpen}
      onClose={() => setIsUpdateModalOpen()}
      title="입찰 수정"
    >
      <ProjectSalesInfoBidResultUpdateModalContext.Provider value={value}>
        <Layout>
          <Body>
            <ProjectSalesInfoBidResultUpdateModalTable />
          </Body>
        </Layout>
      </ProjectSalesInfoBidResultUpdateModalContext.Provider>
    </ModalLayout>
  );
};
