import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { ProjectSalesInfoSubjectReviewModalExperimentTargetContext } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/experiment-target/widgets/context';
import { useProjectSalesInfoSubjectReviewModalExperimentTargetLogic } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/experiment-target/widgets/useLogic';

export { Provider as ProjectSalesInfoSubjectReviewModalExperimentTargetProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { onUpdate, isLoading, detail } =
    useProjectSalesInfoSubjectReviewModalExperimentTargetLogic();
  const value = useMemo(() => ({ onUpdate, isLoading, detail }), [onUpdate, isLoading, detail]);
  return (
    <ProjectSalesInfoSubjectReviewModalExperimentTargetContext.Provider value={value}>
      {children}
    </ProjectSalesInfoSubjectReviewModalExperimentTargetContext.Provider>
  );
};
