import React from 'react';
import UIBuilderTableBody, {
  type UIBuilderTableBodyProps,
} from '@front/src/components/ui-builder/table/TableBody';
import WorkOutputTableBodyRow from '@front/src/features/work/features/work/features/output/components/TableRow';
import workOutputQuery from '@front/src/features/work/features/work/features/output/query/query';
import type { WorkOutputView } from '@front/src/features/work/features/work/features/output/types/view';

export default function WorkOutputTableBody({
  outputOptions,
  sectionId,
  dataId,
  menuId,
  ...rest
}: Readonly<UIBuilderTableBodyProps>) {
  const { data: list } = workOutputQuery.useGetList({
    bundleId: outputOptions?.bundleId,
    dataId,
    menuId,
    sectionId,
  });
  return (
    <UIBuilderTableBody<WorkOutputView>
      list={list}
      RowComponent={WorkOutputTableBodyRow}
      outputOptions={outputOptions}
      sectionId={sectionId}
      dataId={dataId}
      menuId={menuId}
      {...rest}
    />
  );
}
