import React from 'react';
import WorkOutputBundleModalForm from '@front/src/features/work/features/work/features/output/components/Form';
import type { WorkOutputBundleView } from '@front/src/features/work/features/work/features/output-bundle/types/view';
import type {
  WorkOutputBundleCreateParameter,
  WorkOutputBundleUpdateParameter,
} from '@front/src/features/work/features/work/features/output-bundle/types/parameter';
import ModalUI from '@front/src/components/components-with-design/layout/ModalUI';

interface Props {
  item: WorkOutputBundleView;
  onCreate: (params: WorkOutputBundleCreateParameter) => void;
  onUpdate: (params: WorkOutputBundleUpdateParameter) => void;
  open: boolean;
  isCreate: boolean;
  onClose: () => void;
}

export default function WorkOutputModal({ open, onClose, isCreate, ...rest }: Readonly<Props>) {
  const title = `${isCreate ? '폴더 추가' : '폴더명 수정'}`;
  return (
    <ModalUI
      open={open}
      onClose={onClose}
      title={title}
    >
      <WorkOutputBundleModalForm
        isCreate={isCreate}
        onClose={onClose}
        {...rest}
      />
    </ModalUI>
  );
}
