// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IP7MD5mcNb08QqUxXoby{width:2%}.OEqUO69iLL46NBFQVhNQ{width:4%}.pu6C9c6o8a9ASruYOzm3{width:6%;white-space:nowrap}._Pa67gYzpg2V4cwnV2a3{width:8%}.vBMsuk5tse9hPem9hNrK{width:10%}._L4DD9BAhnluenFY60R4{word-break:keep-all}._On4KJQIYoFpEE3GSxsP{display:flex;justify-content:center;padding:20px;border:1px solid #e4e9f2}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/components/manage-collection-table.module.scss"],"names":[],"mappings":"AAAA,sBACE,QAAA,CAGF,sBACE,QAAA,CAGF,sBACE,QAAA,CACA,kBAAA,CAGF,sBACE,QAAA,CAGF,sBACE,SAAA,CAGF,sBACE,mBAAA,CAKF,sBACE,YAAA,CACA,sBAAA,CACA,YAAA,CACA,wBAAA","sourcesContent":[".width__2 {\n  width: 2%;\n}\n\n.width__4 {\n  width: 4%;\n}\n\n.width__6 {\n  width: 6%;\n  white-space: nowrap;\n}\n\n.width__8 {\n  width: 8%;\n}\n\n.width__10 {\n  width: 10%;\n}\n\n.bid__th {\n  word-break: keep-all;\n}\n\n@import \"@front/assets/colors.scss\";\n\n.no__result {\n  display: flex;\n  justify-content: center;\n  padding: 20px;\n  border: 1px solid $_e4e9f2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"width__2": `IP7MD5mcNb08QqUxXoby`,
	"width__4": `OEqUO69iLL46NBFQVhNQ`,
	"width__6": `pu6C9c6o8a9ASruYOzm3`,
	"width__8": `_Pa67gYzpg2V4cwnV2a3`,
	"width__10": `vBMsuk5tse9hPem9hNrK`,
	"bid__th": `_L4DD9BAhnluenFY60R4`,
	"no__result": `_On4KJQIYoFpEE3GSxsP`
};
export default ___CSS_LOADER_EXPORT___;
