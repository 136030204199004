import { TableBody, TableContainer, TableRow } from '@mui/material';
import { OldTd, Table } from '@front/layouts/Table';

import React, { useContext } from 'react';
import { ProjectSalesInfoContractEarningsContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/widgets/context';
import { ContractHeaderWrapper } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/components/contract-earnings-header';
import { useContractEarningsItemState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/useState';
import { useRefineStatus } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/hooks/useRefineStatus';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';

export { ContractEarningsStatus as ProjectSalesInfoContractEarningsStatus };

const ContractEarningsStatus = () => {
  const {
    d: { contractEarningsStatus },
  } = useContext(ProjectSalesInfoContractEarningsContext);
  const hasVat = useContractEarningsItemState((state) => state.hasVat);
  const refinedStatus = useRefineStatus(hasVat, contractEarningsStatus);
  return (
    <TableContainer
      sx={{
        ...useGetMaxWidthSx({ hasMemo: true }),
      }}
    >
      <Table>
        <ContractHeaderWrapper />
        <TableBody>
          <TableRow>
            <OldTd>{refinedStatus?.contractAmount?.toLocaleString() ?? '-'}</OldTd>
            <OldTd>{refinedStatus?.status ?? '-'}</OldTd>
            <OldTd>{refinedStatus?.collectionAmount?.toLocaleString() ?? '-'}</OldTd>
            <OldTd>{refinedStatus?.netCollectionAmount?.toLocaleString() ?? '-'}</OldTd>
            <OldTd>
              {refinedStatus?.collectionRatio ? refinedStatus?.collectionRatio + '%' : '-'}
            </OldTd>
            <OldTd>{refinedStatus?.unpaidAmount?.toLocaleString() ?? '-'}</OldTd>
            <OldTd>{refinedStatus?.netUnpaidAmount?.toLocaleString() ?? '-'}</OldTd>
            <OldTd>{refinedStatus?.dueAmount?.toLocaleString() ?? '-'}</OldTd>
            <OldTd>{refinedStatus?.netDueAmount?.toLocaleString() ?? '-'}</OldTd>
            <OldTd>{refinedStatus?.unclaimedAmount?.toLocaleString() ?? '-'}</OldTd>
            <OldTd>{refinedStatus?.netUnclaimedAmount?.toLocaleString() ?? '-'}</OldTd>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
