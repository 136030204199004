import React from 'react';
import { Box, MenuItem } from '@mui/material';
import TextBox from '../../../layouts/Text';
import Select from '../../../layouts/Select';
import type { ProceedingsVO, ProceedingType } from '../../domain';
import { ProceedingsStatus, proceedingStatusList, proceedingStatusName } from '../../domain';
import type { ProceedingsUpdateParameter } from '../../parameter';
import DataFieldWithLabel from '../../../layouts/DataFieldLabel';

interface Props {
  detail: ProceedingsVO;
  update: (params: ProceedingsUpdateParameter) => void;
  isReader: boolean;
  isWriter: boolean;
  isAttendance: boolean;
}

function BasicInfoSection({ detail, update, isReader, isAttendance }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <TextBox
        variant={'body2'}
        sx={{
          width: '20%',
        }}
      >
        회의코드 : {detail.code}
      </TextBox>
      <Box sx={{ width: '20%' }}>
        <DataFieldWithLabel
          label="회의록 상태"
          labelSX={{ minWidth: '80px' }}
        >
          <Select
            displayEmpty
            readOnly={isReader || (isAttendance && detail.status !== ProceedingsStatus.READING)}
            variant="outlined"
            value={detail.status ?? ''}
            onChange={(e) => {
              const value = e.target.value as ProceedingType;
              if (detail.status !== value) {
                update({
                  status: value,
                });
              }
            }}
          >
            <MenuItem
              disabled
              value=""
            >
              회의록 상태
            </MenuItem>
            {proceedingStatusList.map((item, index) => (
              <MenuItem
                key={`${item}_${index}`}
                value={item}
              >
                {proceedingStatusName(item)}
              </MenuItem>
            ))}
          </Select>
        </DataFieldWithLabel>
      </Box>
    </Box>
  );
}

export default BasicInfoSection;
