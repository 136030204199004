import React, { useEffect, useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useProjectSalesInfoAffiliatedCompanyWebHardLogic } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/widgets/useLogic';
import { ProjectSalesInfoAffiliatedCompanyWebHardTable } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/components/web-hard-table';
import { ProjectSalesInfoAffiliatedCompanyWebHardContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/widgets/context';
import { ProjectSalesInfoAffiliatedCompanyWebHardModalWrapper } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/components/web-hard-modal-wrapper';
import { ProjectSalesInfoAffiliatedCompanyWebHardButtonGroup } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/components/button-group';
import { Backdrop, CircularProgress } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoAffiliatedCompanyWebHardState } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/widgets/useState';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoAffiliatedCompanyWebHardWidget = ({ readOnly, name }: Readonly<Props>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  const {
    d: { list },
    h: { onDelete },
    isLoading,
  } = useProjectSalesInfoAffiliatedCompanyWebHardLogic();
  const value = useMemo(
    () => ({
      d: { list },
      h: { onDelete },
    }),
    [list, onDelete]
  );
  const { setReadOnly } = useProjectSalesInfoAffiliatedCompanyWebHardState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  if (isLoading) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return (
    <>
      <ProjectSalesInfoAffiliatedCompanyWebHardModalWrapper />
      <ProjectSalesInfoAffiliatedCompanyWebHardContext.Provider value={value}>
        <Layout>
          <Header title={name}>
            <ProjectSalesInfoAffiliatedCompanyWebHardButtonGroup />
          </Header>
          <Body>
            <ProjectSalesInfoAffiliatedCompanyWebHardTable />
          </Body>
        </Layout>
      </ProjectSalesInfoAffiliatedCompanyWebHardContext.Provider>
    </>
  );
};

export default ProjectSalesInfoAffiliatedCompanyWebHardWidget;
