import type { ReactNode } from 'react';
import React, { useEffect } from 'react';
import classes from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/section/character/widget.module.scss';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedCompanyPersonDetailModalCharacterState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/section/character/useState';
import AffiliatedCompanyPersonDetailModalCharacterBasicWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/basic/widgets/widget';
import AffiliatedCompanyPersonDetailModalCharacterHobbyWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/widgets/widget';
import AffiliatedCompanyPersonDetailModalCharacterPreferenceWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/widgets/widget';
import AffiliatedCompanyPersonDetailModalCharacterFamilyInformationWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/widgets/widget';
import AffiliatedCompanyPersonDetailModalCharacterCharacterWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/character/widgets/widget';

interface Props {
  readOnly?: boolean;
  menuId?: number;
}

const AffiliatedCompanyPersonDetailModalCharacterWidget = ({ readOnly, menuId }: Props) => {
  const { setReadOnly } = useAffiliatedCompanyPersonDetailModalCharacterState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <Layout>
      <Article>
        <AffiliatedCompanyPersonDetailModalCharacterBasicWidget
          readOnly={readOnly}
          menuId={menuId}
        />
        <AffiliatedCompanyPersonDetailModalCharacterHobbyWidget
          readOnly={readOnly}
          menuId={menuId}
        />
        <AffiliatedCompanyPersonDetailModalCharacterPreferenceWidget
          readOnly={readOnly}
          menuId={menuId}
        />
      </Article>
      <Article>
        <AffiliatedCompanyPersonDetailModalCharacterFamilyInformationWidget
          readOnly={readOnly}
          menuId={menuId}
        />
        <AffiliatedCompanyPersonDetailModalCharacterCharacterWidget
          readOnly={readOnly}
          menuId={menuId}
        />
      </Article>
    </Layout>
  );
};

const Layout = ({ children }: { children: ReactNode }) => (
  <div className={classes.container}>{children}</div>
);

const Article = ({ children }: { children: ReactNode }) => (
  <div className={classes.section}>{children}</div>
);

export default AffiliatedCompanyPersonDetailModalCharacterWidget;
