import { create } from 'zustand';
import type { AccountingSettingsManagementAccountingReadEAccountAndManualIncomeAndExpenseGetParams } from '@front/src/features/accounting/features/settings/features/management-accounting-read/types/parameter';

export { useState as useAccountingSettingsManagementAccountingReadState };

interface State {
  parentIdList: number[];
  setParentIdList: (parentIdList: number[]) => void;
  categoryMircoName?: string;
  setCategoryMircoName: (categoryMircoName) => void;
  period?: AccountingSettingsManagementAccountingReadEAccountAndManualIncomeAndExpenseGetParams;
  setPeriod: (
    period: AccountingSettingsManagementAccountingReadEAccountAndManualIncomeAndExpenseGetParams
  ) => void;
  readOnly?: boolean;
  setReadOnly: (readOnly?: boolean) => void;
}

const useState = create<State>((set) => ({
  parentIdList: [],
  setParentIdList: (parentIdList) =>
    set({
      parentIdList,
    }),
  setCategoryMircoName: (categoryMircoName) =>
    set({
      categoryMircoName,
    }),
  setPeriod: (period) =>
    set({
      period,
    }),
  readOnly: false,
  setReadOnly: (readOnly) => set({ readOnly }),
}));
