import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalQuery } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/detail-modal/query/query';

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalRepository = {
  useDetailGet: (id?: number) => {
    const { data, isLoading } =
      projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalQuery.useDetailGet(id);
    return {
      data,
      isLoading,
    };
  },
};
