import { Box, Collapse, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ColorPalette } from '@front/assets/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationBadge } from '@front/components/badge/badge';
import type { MenuView } from '@front/src/types';
import Depth3Menu from '@front/src/features/global-nav-bar/widgets/list/components/Depth3Menu';
import { useGnbLogic } from '@front/src/features/global-nav-bar/widgets/list/components/useGnbLogic';

interface Props extends MenuView {}

export default function Depth2Menu(props: Props) {
  const [open, setOpen] = useState<boolean>(true);
  const { onClick, clickable, active, hasChild } = useGnbLogic({
    path: props.path,
    hasDrawer: props.hasDrawer,
    children: props.children,
    id: props.id,
  });
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        width: '100%',
        color: ColorPalette._ffffff,
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        cursor: clickable ? 'pointer' : 'default',
        backgroundColor: 'var(--design-v-10-light-background-bg-05, #35455F);',
        borderRadius: '5px'
      }}
    >
      <Box
        sx={{
          paddingLeft: '30px',
          display: 'flex',
          flexWrap: 'nowrap',
          width: '100%',
          height: '38px',
          alignItems: 'center',
          backgroundColor: active ? ColorPalette._4c9eeb : 'transparent',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <FontAwesomeIcon
            icon={props.icon}
            style={{
              padding: '0 10px',
              fontSize: '6px',
            }}
          />
          <NotificationBadge
            count={props.count}
            type="menu"
          >
            <Typography
              sx={{
                fontSize: '13px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setOpen(!open);
              }}
            >
              {props.title}
            </Typography>
          </NotificationBadge>
        </Box>
        {hasChild && (
          <FontAwesomeIcon
            icon="angle-up"
            onClick={() => {
              setOpen(!open);
            }}
            style={{
              cursor: 'pointer',
              transition: 'transform .2s',
              transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
              padding: '0 10px',
              fontSize: '13px',
            }}
          />
        )}
      </Box>
      {hasChild && (
        <Collapse
          in={open}
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              flexWrap: 'wrap',
            }}
          >
            {props.children?.map((child) => {
              if (child.depth === null) return;
              return (
                <Depth3Menu
                  key={child.title}
                  {...child}
                />
              );
            })}
          </Box>
        </Collapse>
      )}
    </Box>
  );
}
