import React from 'react';
import classes from '@front/src/features/mobile-notification/components/layouts/body.module.scss';

interface Props {
  children: React.ReactNode;
  isMobile: boolean;
}

export const Body = ({ children, isMobile }: Props) => (
  <div
    className={classes.container}
    style={{ padding: isMobile ? '0px' : '0px 100px' }}
  >
    {children}
  </div>
);
