export const enum ParentsType {
  PROPOSAL = 'PROPOSAL',
  PROCEEDINGS = 'PROCEEDINGS',
  PROJECT = 'PROJECT',
  CAMPAIGN = 'CAMPAIGN',
  EVALUATION = 'EVALUATION',
  VOTE = 'VOTE',
  OST = 'OST',
  WORK = 'WORK',
}

export const enum ApprovalStatus {
  WAITING = 'WAITING', // 결재 대기
  APPOINTED = 'APPOINTED', // 결재 예정
  COMPLETED = 'COMPLETED', // 결재 종료
  ON_GOING = 'ON_GOING', // 진행중
  TEMPORARY = 'TEMPORARY', // 작성중
}

export const enum ApprovalDocumentStatus {
  TEMPORARY = 'TEMPORARY', // 임시 저장
  SUBMISSION = 'SUBMISSION', // 제출
  ON_GOING = 'ON_GOING', // 결재 진행
  COMPLETED = 'COMPLETED', // 결재 완료
  REJECTED = 'REJECTED', // 반려
  CANCELED = 'CANCELED', // 상신취소
}

export const enum ApprovalCategory {
  NORMAL = 'NORMAL', // 일반
  REFERENCE = 'REFERENCE', // 묶음
}

export type DefaultFunction<P = undefined> = P extends undefined ? () => void : (p: P) => void;

export * from '@front/src/types/view';

export interface OptionType<T = string> {
  value: T;
  label: string;
}

export const enum ComponentEnumType {
  TABLE = 'TABLE',
  FORM = 'FORM',
  CUSTOM = 'CUSTOM',
  FUNCTIONAL_TABLE = 'FUNCTIONAL_TABLE',
}
