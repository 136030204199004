import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import type { ProjectDesignOutlineParameter } from '@front/src/features/project-sales-info/features/design-outline/types/parameter';
import { projectSalesInfoDesignOutlineApi } from '@front/src/features/project-sales-info/features/design-outline/query/api';
import { handleError } from '@front/src/utils';

export const projectSalesInfoDesignOutlineMutation = {
  useProjectDesignOutlineUpdate: (id: number) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectDesignOutlineParameter) =>
        projectSalesInfoDesignOutlineApi.useProjectDesignOutlineUpdate(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['project-sales-info', 'design-outline', id]);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
