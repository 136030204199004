import React from 'react';
import { useProjectSalesInfoPaymentHistoryState } from '@front/src/features/project-sales-info/features/collection/features/payment-history/widgets/useState';
import { ProjectSalesInfoPaymentHistoryUpdateModalWidget } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/update-modal/widgets/widget';

export { ModalWrapper as ProjectSalesInfoPaymentHistoryUpdateModalWrapper };

const ModalWrapper = () => {
  const isUpdateModalOpen = useProjectSalesInfoPaymentHistoryState(
    (state) => state.isUpdateModalOpen
  );
  return <>{isUpdateModalOpen && <ProjectSalesInfoPaymentHistoryUpdateModalWidget />}</>;
};
