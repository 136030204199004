import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyRepository } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/repository';

export { useLogic as useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyLogic };

const useLogic = () => {
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { data: list, isLoading } =
    projectSalesInfoAffiliatedCompanyAffiliatedCompanyRepository.useListGet(id);
  const { run: onDelete } =
    projectSalesInfoAffiliatedCompanyAffiliatedCompanyRepository.useDelete();
  const h = {
    onDelete,
  };
  const d = { list };
  return {
    d,
    h,
    isLoading,
  };
};
