import React from 'react';
import classes from '@front/src/features/mobile-notification/components/layouts/header.module.scss';

interface Props {
  children: React.ReactNode;
  isMobile: boolean;
}

export const Header = ({ children, isMobile }: Props) => (
  <div
    className={classes.container}
    style={{ justifyContent: isMobile ? 'space-between' : 'space-around' }}
  >
    {children}
  </div>
);
