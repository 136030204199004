import { projectSalesActivityRepository } from '@front/src/features/project-sales-info/features/activity/repository/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { useShallow } from 'zustand/react/shallow';

export { useLogic as useProjectSalesInfoActivityAffiliatesItemLogic };

const useLogic = () => {
  const { id } = useProjectSalesInfoDetailState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const { run: onUpdate } = projectSalesActivityRepository.useAffiliatesUpdate(id!);
  const { run: onDelete } = projectSalesActivityRepository.useAffiliatesDelete(id!);
  return { onUpdate, onDelete };
};
