import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { projectSalesInfoSubjectReviewModalComplexInformationQuery } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/query/query';
import { projectSalesInfoSubjectReviewModalComplexInformationMutation } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/query/mutation';

export { repository as projectSalesInfoSubjectReviewModalComplexInformationRepository };
const repository = {
  useCreate: () => {
    const { mutate } = projectSalesInfoSubjectReviewModalComplexInformationMutation.useCreate();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useListGet: (id?: number) => {
    const { data, isLoading } =
      projectSalesInfoSubjectReviewModalComplexInformationQuery.useListGet(id);
    return {
      data,
      isLoading,
    };
  },
  useUpdate: () => {
    const { mutate } = projectSalesInfoSubjectReviewModalComplexInformationMutation.useUpdate();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useDelete: () => {
    const { mutate } = projectSalesInfoSubjectReviewModalComplexInformationMutation.useDelete();
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
