import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { IdListDeleteParameter } from '@front/src/types/parameter';
import type { ProjectEstimationCompetitorView } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/types/view';

export { context as ProjectSalesInfoEstimationCompetitorContext };

interface State {
  d: {
    list?: ProjectEstimationCompetitorView[];
  };
  h: {
    onDelete: (params: IdListDeleteParameter) => void;
  };
  isLoading: boolean;
}

const context = createContext<State>({
  d: {},
  h: {
    onDelete: noOp,
  },
  isLoading: false,
});
