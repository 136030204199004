import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import DateAttr from '@front/src/components/ui-builder/DateAttr';
import NumberAttr from '@front/src/components/ui-builder/NumberAttr';
import TextAttr from '@front/src/components/ui-builder/TextAttr';
import dayjs from 'dayjs';
import { DATE_FORMAT, downloadFileFromS3 } from '@front/src/utils';
import { FileDisplayText } from '@front/src/components/file-display-text/file-display-text';
import type { WorkReferenceView } from '@front/src/features/work/features/work/features/reference/types/view';
import Box from '@mui/material/Box';
import WorkReferenceTableBodyRowFile from '@front/src/features/work/features/work/features/reference/components/File';
import WorkImageZoomButton from '@front/src/features/work/features/work/components/ZoomImageButton';

const workReferenceCellComponent = {
  21: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowCreatedAt {...rest} />,
  22: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowUserName {...rest} />,
  23: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowFile {...rest} />,
  104: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowNumberAttr1 {...rest} />,
  105: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowTextAttr1 {...rest} />,
  106: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowDateAttr1 {...rest} />,
  107: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowNumberAttr2 {...rest} />,
  108: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowTextAttr2 {...rest} />,
  109: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowDateAttr2 {...rest} />,
  110: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowNumberAttr3 {...rest} />,
  111: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowTextAttr3 {...rest} />,
  112: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowDateAttr3 {...rest} />,
};

export default workReferenceCellComponent;

interface Props extends CellComponentProps<WorkReferenceView> {}

const WorkReferenceTableRowCreatedAt = ({ item, sx = {}, onClick }: Readonly<Props>) => (
  <Box
    onClick={onClick}
    sx={sx}
  >
    {item.createdAt ? dayjs(item.createdAt).format(DATE_FORMAT) : '-'}
  </Box>
);

const WorkReferenceTableRowUserName = ({ item, sx = {}, onClick }: Readonly<Props>) => (
  <Box
    onClick={onClick}
    sx={sx}
  >
    {item.user.name}
  </Box>
);

const WorkReferenceTableRowFile = ({
  item,
  isEditMode,
  onClick,
  sx = {},
  readOnly,
  rowActions,
  sectionId,
  dataId,
  menuId,
}: Readonly<Props>) => {
  const renderContent = () => {
    if (isEditMode && !readOnly && rowActions?.onUpdateFile) {
      return (
        <WorkReferenceTableBodyRowFile
          dataId={dataId}
          item={item}
          onUpdateFile={rowActions.onUpdateFile}
          sectionId={sectionId}
          menuId={menuId}
        />
      );
    }
    if (!item.fileItem) return <>-</>;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <FileDisplayText
          fileName={item.fileItem.filename}
          onClick={downloadFileFromS3(item.fileItem.id)}
          align="left"
        />
        <WorkImageZoomButton<WorkReferenceView> item={item} />
      </Box>
    );
  };
  return (
    <Box
      onClick={onClick}
      sx={sx}
    >
      {renderContent()}
    </Box>
  );
};

const WorkReferenceTableRowNumberAttr1 = ({ ...rest }: Readonly<Props>) => (
  <NumberAttr
    name="numberAttr1"
    {...rest}
  />
);

const WorkReferenceTableRowTextAttr1 = ({ ...rest }: Readonly<Props>) => (
  <TextAttr
    name="textAttr1"
    {...rest}
  />
);

const WorkReferenceTableRowDateAttr1 = ({ ...rest }: Readonly<Props>) => (
  <DateAttr
    name="dateAttr1"
    {...rest}
  />
);

const WorkReferenceTableRowNumberAttr2 = ({ ...rest }: Readonly<Props>) => (
  <NumberAttr
    name="numberAttr2"
    {...rest}
  />
);

const WorkReferenceTableRowTextAttr2 = ({ ...rest }: Readonly<Props>) => (
  <TextAttr
    name="textAttr2"
    {...rest}
  />
);

const WorkReferenceTableRowDateAttr2 = ({ ...rest }: Readonly<Props>) => (
  <DateAttr
    name="dateAttr2"
    {...rest}
  />
);

const WorkReferenceTableRowNumberAttr3 = ({ ...rest }: Readonly<Props>) => (
  <NumberAttr
    name="numberAttr3"
    {...rest}
  />
);

const WorkReferenceTableRowTextAttr3 = ({ ...rest }: Readonly<Props>) => (
  <TextAttr
    name="textAttr3"
    {...rest}
  />
);

const WorkReferenceTableRowDateAttr3 = ({ ...rest }: Readonly<Props>) => (
  <DateAttr
    name="dateAttr3"
    {...rest}
  />
);
