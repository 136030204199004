import React from 'react';
import {
  cellComponent,
  type CellComponentProps,
} from '@front/src/components/ui-builder/cellComponent';
import type { ModalHeaderView } from '@front/src/types';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';

interface Props extends Omit<CellComponentProps, 'isEditMode'> {
  headerList: ModalHeaderView[];
}

export default function UIBuilderEditModalTableBody({
  headerList,
  readOnly,
  ...rest
}: Readonly<Props>) {
  return (
    <>
      {headerList.map((c) => {
        const Component = cellComponent[c.headerSettingId];
        return (
          <TableRowUI key={c.headerSettingId}>
            <TableCellUI width="260px">{c.name}</TableCellUI>
            <TableCellUI>
              <Component
                isForm
                isEditMode={!readOnly}
                readOnly={readOnly}
                {...rest}
              />
            </TableCellUI>
          </TableRowUI>
        );
      })}
    </>
  );
}
