import React from 'react';
import { Box } from '@mui/material';
import type { Control } from 'react-hook-form';
import { useController, useFieldArray } from 'react-hook-form';
import type { UserVO } from '@front/user/domain';

import UserSelectorPlusIcon from '@front/src/features/user-selector/features/input/components/PlusIcon';
import UserSelectorUserIcon from '@front/src/features/user-selector/components/UserIcon';

interface Props {
  readOnly: boolean;
  onOpen: () => void;
  control: Control<{ inputList: (UserVO | undefined)[] }, any>;
  onChange: (value: number | number[] | undefined) => void;
}

export default function UserSelectorMultiInput({
  onOpen,
  readOnly,
  control,
  onChange,
}: Readonly<Props>) {
  const {
    field: { value: inputList },
  } = useController({ control, name: 'inputList' });
  const { remove, fields } = useFieldArray({
    name: 'inputList',
    control,
  });
  const handleIconClick = (index) => {
    if (readOnly) return;
    remove(index);
    onChange(inputList.map((i) => i!.id));
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        gap: '10px',
      }}
    >
      {fields.map((f, i) => (
        <UserSelectorUserIcon
          readOnly={readOnly}
          key={`user-icon-${f.id}`}
          user={f}
          onClick={() => handleIconClick(i)}
        />
      ))}
      {!readOnly && <UserSelectorPlusIcon onOpen={onOpen} />}
    </Box>
  );
}
