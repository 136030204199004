import type { ReactNode } from 'react';
import React from 'react';
import { Box } from '@mui/material';

export { TableBody as AffiliatedCompanyProjectListTableBody };

interface Props {
  children: ReactNode;
}

const TableBody = ({ children }: Props) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    {children}
  </Box>
);
