import { useQuery } from 'react-query';
import { accessAuthSettingsApi } from '@front/src/features/admin/features/access-authorization-settings/api/api';
import type { AuthUserSearchParams } from '@front/src/features/admin/features/access-authorization-settings/type/parameter';

export const adminAccessAuthSettingsUserQuery = {
  useUserListGet: (menuId: number, params: AuthUserSearchParams, authMenuId?: number) => {
    const { data } = useQuery({
      queryKey: ['admin', 'access-auth-settings', 'user', menuId, params.keyword],
      queryFn: () => accessAuthSettingsApi.getUserList(menuId, params, authMenuId),
      suspense: true,
    });

    return {
      data,
    };
  },
};
