import userSelectorRepository from '@front/src/features/user-selector/repository/repository';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import type { AuthorizationType, UserVO } from '@front/user/domain';
import UserSelectorDepartmentList from '@front/src/features/user-selector/features/form/components/DepartmentList';
import UserSelectorUserSelectForm from '@front/src/features/user-selector/features/form/components/UserSelectForm';
import UserSelectorSearchForm from '@front/src/features/user-selector/features/form/components/SearchForm';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

interface Props {
  value: number | number[] | undefined;
  onChange: (value: UserVO | UserVO[] | undefined) => void;
  multi?: boolean;
  menuIdList?: number[];
  typeList?: AuthorizationType[];
}

export default function UserSelectorForm({
  value,
  onChange,
  multi = false,
  menuIdList = [],
  typeList = [],
}: Readonly<Props>) {
  const { data: allUser } = userSelectorRepository.useGetAllUser();
  const selectedList = useMemo(() => {
    if (Array.isArray(value)) {
      return value.map((userId) => allUser!.find((a) => a.id === userId));
    }
    const find = allUser!.find((a) => a.id === value);
    if (!find) {
      return [];
    }
    return [find];
  }, [value, allUser]);
  const methods = useForm({
    values: {
      keyword: '',
      departmentId: null,
      selectedList,
    },
  });
  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <UserSelectorSearchForm />
        <Box
          sx={{
            display: 'flex',
            gap: '2px',
          }}
        >
          <Box
            sx={{
              flex: 2,
              border: `1px solid ${ColorPalette._e4e9f2}`,
              borderRadius: '5px',
              maxHeight: '40dvh',
              overflowY: 'auto',
            }}
          >
            <UserSelectorDepartmentList />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ArrowRightIcon />
          </Box>
          <UserSelectorUserSelectForm
            multi={multi}
            menuIdList={menuIdList}
            typeList={typeList}
            onChange={onChange}
          />
        </Box>
      </Box>
    </FormProvider>
  );
}
