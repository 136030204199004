import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import type { WorkCategoryView } from '@front/src/features/work/features/work/features/category/types/view';
import EditIcon from '@mui/icons-material/Edit';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/TableBody';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import useNoteStore from '@front/src/features/note/useState';
import { useShallow } from 'zustand/react/shallow';
import type { NoteMenuIdView } from '@front/src/features/note/types/view';
import NoteIcon from '@mui/icons-material/Note';

export default function WorkWorkCategoryTableRow({
  rowActions,
  item,
  ...rest
}: Readonly<UIBuilderTableRowProps<WorkCategoryView>>) {
  const [open, setOpen] = useState<boolean>(false);
  const methods = useForm({
    values: getFormValues(item),
  });
  const setIsRightDrawerOpen = useGlobalNavBarState((state) => state.setIsRightDrawerOpen);
  const { setRowId, setSectionId } = useNoteStore(
    useShallow((state) => ({
      setRowId: state.setRowId,
      setSectionId: state.setSectionId,
    }))
  );
  const onNoteOpen = () => {
    const noteAuth: NoteMenuIdView = JSON.parse(sessionStorage.getItem('noteAuth') ?? '{}');
    if (!noteAuth.noteId || noteAuth.type === 'X') {
      alert({
        title: '권한 오류',
        children: [{ value: '접근 권한이 없습니다.' }],
      });
      return;
    }
    setIsRightDrawerOpen(true);
    setRowId(item.id);
    setSectionId(rest.sectionId);
  };
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    rowActions?.onUpdate(data);
  });
  const itemList = [
    {
      text: open ? '수정 닫기' : '수정',
      icon: EditIcon,
      action: () => setOpen(!open),
    },
    { text: '비고', icon: NoteIcon, action: onNoteOpen },
  ];
  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        item={item}
        onSubmit={onSubmit}
        itemList={itemList}
        isEditMode={open}
        {...rest}
      />
    </FormProvider>
  );
}

const getFormValues = (item) => ({
  area: item.area ?? '',
  department: item.department ?? '',
  collaboration: item.collaboration ?? '',
  impact: item.impact ?? '',
  security: item.security ?? '',
  textAttr1: item.textAttr1 ?? '',
  textAttr2: item.textAttr2 ?? '',
  textAttr3: item.textAttr3 ?? '',
  numberAttr1: item.numberAttr1,
  numberAttr2: item.numberAttr2,
  numberAttr3: item.numberAttr3,
  dateAttr1: item.dateAttr1 ?? '',
  dateAttr2: item.dateAttr2 ?? '',
  dateAttr3: item.dateAttr3 ?? '',
});
