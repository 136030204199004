import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { AffiliatedCompanyOutlineAddressContext } from '@front/src/features/affiliated-company/features/outline/features/address/widgets/context';
import { useAffiliatedCompanyOutlineState } from '@front/src/features/affiliated-company/features/outline/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { ButtonGroup as AffiliatedCompanyOutlineAddressButtonGroup };
const ButtonGroup = () => {
  const {
    h: { onCreateAddress, onDeleteAddress, setIdList },
    idList,
  } = useContext(AffiliatedCompanyOutlineAddressContext);
  const handleDeleteClick = useCallback(() => {
    onDeleteAddress({ idList });
    setIdList([]);
  }, [onDeleteAddress, idList, setIdList]);
  const { readOnly } = useAffiliatedCompanyOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      <Button
        onClick={handleDeleteClick}
        disabled={readOnly}
      >
        선택삭제
      </Button>
      <Button
        onClick={onCreateAddress}
        disabled={readOnly}
      >
        주소추가
      </Button>
    </>
  );
};
