import type { ReactNode } from 'react';
import React from 'react';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';

export { TableBodyCell as AccountingSettingsManagementAccountingAccountTableLayoutTableBodyCell };

interface Props {
  sx?: SxProps<Theme>;
  children?: ReactNode;
  onClick?: (e) => void;
  onKeyDown?: (e) => void;
  onContextMenu?: (e) => void;
  justifyContent?: string;
}

const TableBodyCell = ({
  sx,
  children,
  onClick,
  justifyContent,
  onContextMenu,
  onKeyDown,
}: Props) => (
  <Box
    onClick={onClick}
    onContextMenu={onContextMenu}
    onKeyDown={onKeyDown}
    tabIndex={-1}
    sx={{
      width: '100%',
      outline: `1px solid ${ColorPalette._e4e9f2}`,
    }}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '220px',
        height: '40px',
        justifyContent: justifyContent,
        fontSize: '14px',
        ...sx,
      }}
    >
      {children}
    </Box>
  </Box>
);
