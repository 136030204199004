import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationUpdateLogic } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/features/update/provider/useLogic';
import { AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationUpdateContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/features/update/provider/context';

export { Provider as AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationUpdateProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { onUpdate } =
    useAffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationUpdateLogic(menuId);
  const value = useMemo(
    () => ({
      onUpdate,
    }),
    [onUpdate]
  );
  return (
    <AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationUpdateContext.Provider
      value={value}
    >
      {children}
    </AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationUpdateContext.Provider>
  );
};
