import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { DefaultFunction } from '@front/src/types';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';
import type { ProjectSalesInfoContractHistoryParameter } from '@front/src/features/project-sales-info/features/contract/features/history/features/modal/types/parameter';
import type { ProjectContractHistoryView } from '@front/src/features/project-sales-info/features/contract/features/history/types/view';

export { context as ProjectSalesInfoContractHistoryModalContext };

interface State {
  d: {
    detail?: ProjectContractHistoryView;
    categoryList?: VariableInformationView[];
    typeList?: VariableInformationView[];
  };
  h: {
    onCreate: (params: ProjectSalesInfoContractHistoryParameter) => void;
    onUpdate: (params: ProjectSalesInfoContractHistoryParameter) => void;
    onClose: DefaultFunction;
  };
  isLoading: boolean;
  isCategoryLoading: boolean;
  isTypeLoading: boolean;
}

const context = createContext<State>({
  d: {},
  h: {
    onCreate: noOp,
    onUpdate: noOp,
    onClose: noOp,
  },
  isLoading: false,
  isCategoryLoading: false,
  isTypeLoading: false,
});
