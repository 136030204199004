import { useShallow } from 'zustand/react/shallow';
import { useEffect } from 'react';
import { defaultAffiliatedPersonCompanySearchForm } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/utils';
import { useAffiliatedPersonAppBarCompanySelectModalState } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/useState';
import { affiliatedPersonAppBarCompanySelectRepository } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/repository/repository';

export { useLogic as useAffiliatedPersonCompanySelectModalListLogic };

const useLogic = () => {
  const { searchParameter, setSearchParameter } = useAffiliatedPersonAppBarCompanySelectModalState(
    useShallow((state) => ({
      searchParameter: state.searchParameter,
      setSearchParameter: state.setSearchParameter,
    }))
  );
  const { data: list, remove } =
    affiliatedPersonAppBarCompanySelectRepository.useListGet(searchParameter);
  useEffect(
    () => () => {
      remove();
      setSearchParameter(defaultAffiliatedPersonCompanySearchForm);
    },
    [remove, setSearchParameter]
  );
  return {
    list,
  };
};
