import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { ProjectSalesInfoBidInfoParams } from '@front/src/features/project-sales-info/features/bid/features/bid-information/types/parameter';
import { projectSalesInfoBidInfoUpdateModalQuery } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/update-modal/query';
import { projectSalesInfoBidInfoUpdateModalMutation } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/update-modal/query/mutation';

export const projectSalesInfoBidInfoUpdateModalRepository = {
  useProjectSalesInfoBidInfoDetailGet: (id: number) => {
    const { data, isLoading } =
      projectSalesInfoBidInfoUpdateModalQuery.useProjectSalesInfoBidInfoDetailGet(id);
    return {
      data,
      isLoading,
    };
  },
  useProjectSalesInfoBidInfoDetailUpdate: (id: number) => {
    const { mutate } =
      projectSalesInfoBidInfoUpdateModalMutation.useProjectSalesInfoBidInfoUpdate(id);
    const callback = getNoOpCallback();
    return {
      run: (params: ProjectSalesInfoBidInfoParams) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useEstimateIdListGet: (id: number) => {
    const { data, isLoading } = projectSalesInfoBidInfoUpdateModalQuery.useEstimateIdListGet(id);
    return {
      data,
      isLoading,
    };
  },
};
