import React, { memo, Suspense } from 'react';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import ResizableLeftDrawer from '@front/src/components/list-drawer/components/resizable-left-drawer';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import ApprovalBoxContent from '@front/src/features/approval-box/components/ApprovalBoxContent';

function ApprovalBoxFeature() {
  const leftDrawerState = useGlobalNavBarState((state) => state.leftDrawerState);

  return (
    <ResizableLeftDrawer leftDrawerState={leftDrawerState === 'approval-box'}>
      <Suspense fallback={<LoadingSpinner />}>
        <ApprovalBoxContent />
      </Suspense>
    </ResizableLeftDrawer>
  );
}

export default memo(ApprovalBoxFeature);
