import type { SectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import React from 'react';
import AffiliatedCompanyPersonDetailModalOutlineBasicWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/basic/widgets/widget';
import AffiliatedCompanyPersonDetailModalOutlineEducationWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/widgets/widget';
import AffiliatedCompanyPersonDetailModalOutlineCareerWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/widgets/widget';
import AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/widgets/widget';
import AffiliatedCompanyPersonDetailModalOutlineExtraCompanyWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/widgets/widget';
import AffiliatedPersonCharacterFeature from '@front/src/features/affiliated-person/features/detail/features/character';
import AffiliatedPersonRelationFeature from '@front/src/features/affiliated-person/features/detail/features/relation';
import AffiliatedPersonGiftHistoryFeature from '@front/src/features/affiliated-person/features/detail/features/gift-history';
import AffiliatedCompanyPersonDetailModalMeetingHistoryWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/meeting-history/widgets/widget';
import AffiliatedPersonSalesActivityFeature from '@front/src/features/affiliated-person/features/sales-activity';

export { sectionComponent as affiliatedCompanyPersonSectionComponent };

const sectionComponent: SectionComponent = {
  56: ({ ...rest }) => <AffiliatedCompanyPersonDetailModalOutlineBasicWidget {...rest} />,
  57: ({ ...rest }) => <AffiliatedCompanyPersonDetailModalOutlineEducationWidget {...rest} />,
  58: ({ ...rest }) => <AffiliatedCompanyPersonDetailModalOutlineCareerWidget {...rest} />,
  59: ({ ...rest }) => (
    <AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationWidget {...rest} />
  ),
  60: ({ ...rest }) => <AffiliatedCompanyPersonDetailModalOutlineExtraCompanyWidget {...rest} />,
  61: ({ ...rest }) => <AffiliatedPersonCharacterFeature {...rest} />,
  62: ({ ...rest }) => <AffiliatedPersonRelationFeature {...rest} />,
  63: ({ ...rest }) => <AffiliatedPersonGiftHistoryFeature {...rest} />,
  64: ({ ...rest }) => <AffiliatedCompanyPersonDetailModalMeetingHistoryWidget {...rest} />,
  65: ({ ...rest }) => <AffiliatedPersonSalesActivityFeature {...rest} />,
};
