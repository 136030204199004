import React, { Suspense } from 'react';
import { AccountingSettingsManagementAccountingReadListProvider } from '@front/src/features/accounting/features/settings/features/management-accounting-read/widgets/list/provider/provider';
import { AccountingSettingsManagementAccountingReadList } from '@front/src/features/accounting/features/settings/features/management-accounting-read/widgets/list/components/list';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';

export { Widget as AccountingSettingsManagementAccountingAccountListWidget };

const Widget = () => (
  <Suspense
    fallback={
      <TableLoadingSpinner
        colSpan={7}
        height="800px"
      />
    }
  >
    <AccountingSettingsManagementAccountingReadListProvider>
      <AccountingSettingsManagementAccountingReadList />
    </AccountingSettingsManagementAccountingReadListProvider>
  </Suspense>
);
