import React from 'react';
import { ProjectSalesInfoCMPaymentHistoryUpdateModalProvider } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/update-modal/widgets/provider';
import { collectionModalLayout } from '@front/src/features/project-sales-info/features/collection/components/layout';
import { useProjectSalesInfoCMPaymentHistoryState } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/widgets/useState';
import ModalLayout from '@front/layouts/ModalLayout';
import { ProjectSalesInfoCMPaymentHistoryUpdateModalTable } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/update-modal/components/update-modal-table';
import { isMobileDevice } from '@front/util/PwaUtil';
import { useShallow } from 'zustand/react/shallow';

export { Widget as ProjectSalesInfoCMPaymentHistoryUpdateModalWidget };

const Widget = () => {
  const { Layout } = collectionModalLayout;
  const { isUpdateModalOpen, setIsUpdateModalOpen } = useProjectSalesInfoCMPaymentHistoryState(
    useShallow((state) => ({
      isUpdateModalOpen: state.isUpdateModalOpen,
      setIsUpdateModalOpen: state.setIsUpdateModalOpen,
    }))
  );
  return (
    <ModalLayout
      position="left"
      width={isMobileDevice() ? '29dvw' : '560px'}
      open={isUpdateModalOpen}
      onClose={() => setIsUpdateModalOpen()}
      title="CM 지급 내역 수정"
    >
      <ProjectSalesInfoCMPaymentHistoryUpdateModalProvider>
        <Layout width="80%">
          <ProjectSalesInfoCMPaymentHistoryUpdateModalTable />
        </Layout>
      </ProjectSalesInfoCMPaymentHistoryUpdateModalProvider>
    </ModalLayout>
  );
};
