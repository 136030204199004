import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import { AccountingSettingsBankAccountApi } from '@front/src/features/accounting/features/settings/features/bank-account/query/api';
import type { AccountingSettingsBankAccountUpdateParameter } from '@front/src/features/accounting/features/settings/features/bank-account/types/parameter';

export { Mutation as AccountingSettingsBankAccountMutation };

const Mutation = {
  useUpdate: () => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: AccountingSettingsBankAccountUpdateParameter) =>
        AccountingSettingsBankAccountApi.update(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['accounting', 'settings', 'bank-account', 'list'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
      isLoading,
    };
  },
};
