import Tooltip from '@front/components/Tooltip';
import React from 'react';
import type { DefaultFunction } from '@front/type/Function';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorPalette } from '@front/assets/theme';
import { Box, IconButton } from '@mui/material';

interface Props {
  openModal: DefaultFunction;
}

export default ({ openModal }: Props) => (
  <Tooltip
    title="계정 정보"
    placement="bottom"
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <IconButton
        onClick={openModal}
        sx={{
          backgroundColor: ColorPalette._697183,
          width: '22px',
          height: '22px',
          overflow: 'hidden',
          fontSize: '22px',
          color: ColorPalette._2d3a54,
          '&:hover': {
            backgroundColor: ColorPalette._e4e9f2,
          },
        }}
      >
        <FontAwesomeIcon
          icon="user"
          style={{ paddingTop: '6px' }}
        />
      </IconButton>
    </Box>
  </Tooltip>
);
