import type { ProjectReviewSavePaymentHistoryView } from '@front/src/features/project-sales-info/features/collection/features/save-history/types/view';
import type { ProjectReviewSavePaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/save-history/types/parameter';
import { createContext } from 'react';
import { noOp } from '@front/common/contants';

interface State {
  d: {
    saveHistoryList?: ProjectReviewSavePaymentHistoryView[];
  };
  h: {
    onUpdate: (params: ProjectReviewSavePaymentHistoryParams) => void;
  };
  isLoading: boolean;
}

export { Context as ProjectSalesInfoCollectionSaveHistoryContext };

const Context = createContext<State>({
  d: {},
  h: {
    onUpdate: noOp,
  },
  isLoading: false,
});
