import React, { useCallback, useEffect, useMemo } from 'react';
import { PersonalSettingsModalUISettingsModalSettingsCurrentSettingsChildrenTableRow } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/components/ChildrenTableRow';
import {
  TableRowBox,
  TbcBox,
} from '@front/src/features/personal-settings-modal/features/ui-settings/components';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { usePersonalSettingsModalUISettingsModalSettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/useState';
import { useShallow } from 'zustand/react/shallow';

export { ChildrenTableData as PersonalSettingsModalUISettingsModalSettingsCurrentSettingsChildrenTableData };
interface Props {
  sectionId: number;
  authMenuId: number | undefined;
}
const ChildrenTableData = ({ authMenuId, sectionId }: Props) => {
  const { control } = useFormContext();
  const { setAppend, setRemove } = usePersonalSettingsModalUISettingsModalSettingsState(
    useShallow((state) => ({
      setAppend: state.setAppend,
      setRemove: state.setRemove,
    }))
  );
  const { fields, swap, append, remove } = useFieldArray({
    name: `childList`,
    control,
  });
  const emptyRows = useMemo(() => Array(20 - fields.length).fill(null), [fields]);
  const renderEmpty = useCallback(
    (rows) =>
      rows.map((_, index) => (
        <TableRowBox key={`children-empty-${index}`}>
          <TbcBox
            sx={{
              flex: 2,
            }}
          />
          <TbcBox
            sx={{
              flex: 1,
            }}
          />
        </TableRowBox>
      )),
    []
  );
  useEffect(() => {
    setAppend(append);
    setRemove(remove);
  }, [setAppend, setRemove, append, remove]);
  return (
    <>
      {fields.map((f, i) => (
        <PersonalSettingsModalUISettingsModalSettingsCurrentSettingsChildrenTableRow
          key={f.id}
          index={i}
          swap={swap}
          length={fields.length}
          sectionId={sectionId}
          authMenuId={authMenuId}
        />
      ))}
      {renderEmpty(emptyRows)}
    </>
  );
};
