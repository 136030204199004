import { shallow } from 'zustand/shallow';
import { affiliatedCompanyRepresentativeContractInformationRepository } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/repository/repository';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';

export { useLogic as useAffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationLogic };

const useLogic = () => {
  const { modalId } = useAffiliatedCompanyPersonState(
    (state) => ({
      modalId: state.modalId,
    }),
    shallow
  );
  const { isLoading, data: personOutlineRepresentativeContactInformation } =
    affiliatedCompanyRepresentativeContractInformationRepository.usePersonOutlineRepresentativeContactInformationGet(
      modalId
    );
  const { run: onPersonOutlineRepresentativeContactInformationUpdate } =
    affiliatedCompanyRepresentativeContractInformationRepository.usePersonOutlineRepresentativeContactInformationUpdate();
  const h = {
    onPersonOutlineRepresentativeContactInformationUpdate: (params) =>
      onPersonOutlineRepresentativeContactInformationUpdate({ ...params, id: modalId }),
  };
  const d = { personOutlineRepresentativeContactInformation };
  return { d, h, isLoading };
};
