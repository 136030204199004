import type { IdListDeleteParameter } from '@front/src/types/parameter';
import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import type { ProjectCMPaymentHistoryView } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/types/view';

interface State {
  d: { CMPaymentHistoryList?: ProjectCMPaymentHistoryView[] };
  h: { onDelete: (params: IdListDeleteParameter) => void };
  isLoading: boolean;
}

export { Context as ProjectSalesInfoCMPaymentHistoryContext };

const Context = createContext<State>({
  d: {},
  h: {
    onDelete: noOp,
  },
  isLoading: false,
});
