import React from 'react';
import { AccountingUploadEcountDataFeature } from '@front/src/features/accounting/features/upload/features/ecount-data';
import { useGetReadOnly } from '@front/src/hooks/useGetReadOnly';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';

interface Props extends SectionComponentProps {}

const AccountingUploadHistoryManagementUploadFeature = ({ name }: Readonly<Props>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const { readOnly } = useGetReadOnly();
  return (
    <Layout>
      <Header title={name} />
      <Body>
        <AccountingUploadEcountDataFeature readOnly={readOnly} />
      </Body>
    </Layout>
  );
};

export default AccountingUploadHistoryManagementUploadFeature;
