import React from 'react';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import dayjs from 'dayjs';
import { YY_MM_DD } from '@front/src/utils';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import {
  ModalBodyUI,
  ModalContentUI,
} from '@front/src/components/components-with-design/layout/ModalUI';

import type { CommonImageView } from '@front/src/features/work/features/work/components/ZoomImageButton';

interface Props<T> {
  item: T;
  onClose: () => void;
}

export default function WorkZoomImage<T extends CommonImageView>({
  item,
  onClose,
}: Readonly<Props<T>>) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px',
          gap: '10px',
          background: ColorPalette.background.bg,
          borderBottom: `1px solid ${ColorPalette.line.line02}`,
        }}
      >
        <Box>
          {`날짜: ${dayjs(item.createdAt).format(YY_MM_DD)} / 등록자: ${item.user.name} / 파일명: ${
            item.fileItem.filename
          }`}
        </Box>
        <ButtonBasicUI onClick={onClose}>닫기</ButtonBasicUI>
      </Box>
      <ModalBodyUI>
        <ModalContentUI sx={{overflowY: 'hidden', justifyContent: 'center'}}>
          <img
            src={`/api/file-item/${item.fileItem?.id}`}
            alt="이미지 크게 보기"
            style={{
              width: 'auto',
              height: '100%',
            }}
          />
        </ModalContentUI>
      </ModalBodyUI>
    </>
  );
}
