import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type {
  WorkWorkerDeleteAllParameter,
  WorkWorkerDeleteParameter,
  WorkWorkerParams,
} from '@front/src/features/work/features/work/features/worker/types/parameter';
import { convertEmptyToNull } from '@front/src/utils';
import { useDispatch } from 'react-redux';
import { userNotificationAction } from '@front/user_notification/action';

const workWorkerMutation = {
  useCreate: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.createWorker(id, menuId),
      onSettled: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'worker'],
        });
        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useAddTop: (workId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: () => workWorkApi.addTopWorker(workId, menuId),
      onSettled: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'worker'],
        });
        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useAddBottom: (workId: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: () => workWorkApi.addBottomWorker(workId, menuId),
      onSettled: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'worker'],
        });
        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useUpdate: (menuId) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: WorkWorkerParams) => workWorkApi.updateWorker(params, menuId),
      onMutate: async (data: WorkWorkerParams) => {
        const { data: previousDataList } =
          (queryClient.getQueryData(['work', 'worker']) as any) ?? {};
        const newData = convertEmptyToNull(data);

        const previousData = previousDataList?.find((item) => item?.id === newData.id);
        const prevData = convertEmptyToNull(previousData);

        return (
          newData.userId === (prevData?.user?.id ?? null) &&
          newData.roleDetail === prevData?.roleDetail &&
          newData.role === prevData?.role &&
          newData.participationRate === prevData?.participationRate
        );
      },
      onSettled: async (data, _, context) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'worker'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'drawer'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'role'],
        });
        await queryClient.invalidateQueries(['global-nav-bar']);
        dispatch(userNotificationAction.requestCount());

        return { data, context };
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useSeqUp: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.updateWorkerSeqUp(id, menuId),
      onSettled: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'worker'],
        });
        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useSeqDown: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.updateWorkerSeqDown(id, menuId),
      onSettled: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'worker'],
        });
        return data;
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useDelete: (params: WorkWorkerDeleteParameter) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { mutate, isLoading } = useMutation({
      mutationFn: (id: number) => workWorkApi.deleteWorker({ ...params, id }),
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'worker'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'drawer'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'role'],
        });
        await queryClient.invalidateQueries(['global-nav-bar']);
        dispatch(userNotificationAction.requestCount());
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
  useDeleteAll: (params: WorkWorkerDeleteAllParameter) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { mutate, isLoading } = useMutation({
      mutationFn: () => workWorkApi.deleteAllWorker(params),
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'worker'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'drawer'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'role'],
        });
        await queryClient.invalidateQueries(['global-nav-bar']);
        dispatch(userNotificationAction.requestCount());
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
};

export default workWorkerMutation;
