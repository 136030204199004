import React from 'react';
import { ProjectSalesInfoCollectionManagementModalCreateModalWidget } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/features/create-modal/widgets/widget';
import { useProjectSalesInfoCollectionManagementModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/widgets/useState';

export { ModalWrapper as ProjectSalesInfoCollectionManagementModalCreateModalWrapper };

const ModalWrapper = () => {
  const isCreateModalOpen = useProjectSalesInfoCollectionManagementModalState(
    (state) => state.isCreateModalOpen
  );
  return <>{isCreateModalOpen && <ProjectSalesInfoCollectionManagementModalCreateModalWidget />}</>;
};
