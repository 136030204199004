import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import TextBox from '@front/layouts/Text';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';

const CampaignInfoModalForm = () => {
  const campaign = useSelector((root: RootState) => root.proposal.detail!.campaign, shallowEqual);
  const [rest, setRest] = useState<string>('');
  useEffect(() => {
    if (typeof campaign.totalReward === 'undefined') {
      return;
    }
    if (typeof campaign.fixedResultSum === 'undefined') {
      return;
    }
    setRest((campaign.totalReward - campaign.fixedResultSum).toLocaleString());
  }, [campaign.totalReward, campaign.fixedResultSum]);

  return (
    <Box
      sx={{
        width: '100%',
        border: `1px solid ${ColorPalette._e4e9f2}`,
        padding: '15px 20px',
        borderRadius: '5px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          rowGap: '5px',
        }}
      >
        <Box>
          <DataFieldWithLabel
            label="캠페인명"
            labelSX={{
              color: `${ColorPalette._252627}`,
              fontWeight: 'bold',
              minWidth: '100px',
            }}
          >
            <TextBox
              variant="body9"
              sx={{
                maxWidth: '70%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {campaign.name}
            </TextBox>
          </DataFieldWithLabel>
        </Box>
        <Box>
          <DataFieldWithLabel
            label="접수 기간"
            labelSX={{
              color: `${ColorPalette._252627}`,
              fontWeight: 'bold',
              minWidth: '100px',
            }}
          >
            <TextBox
              variant="body9"
              sx={{
                maxWidth: '70%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {`${campaign.startDate} ~ ${campaign.endDate}`}
            </TextBox>
          </DataFieldWithLabel>
        </Box>
        <Box>
          <DataFieldWithLabel
            label="심사 기간"
            labelSX={{
              color: `${ColorPalette._252627}`,
              fontWeight: 'bold',
              minWidth: '100px',
            }}
          >
            <TextBox
              variant="body9"
              sx={{
                maxWidth: '70%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {`${
                campaign.estimationStartDate && campaign.estimationEndDate
                  ? `${campaign.estimationStartDate} ~ ${campaign.estimationEndDate}`
                  : '없음'
              }`}
            </TextBox>
          </DataFieldWithLabel>
        </Box>
        <Box>
          <DataFieldWithLabel
            label="투찰 기간"
            labelSX={{
              color: `${ColorPalette._252627}`,
              fontWeight: 'bold',
              minWidth: '100px',
            }}
          >
            <TextBox
              variant="body9"
              sx={{
                maxWidth: '70%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {`${
                campaign.voteStartDate && campaign.voteEndDate
                  ? `${campaign.voteStartDate} ~ ${campaign.voteEndDate}`
                  : '없음'
              }`}
            </TextBox>
          </DataFieldWithLabel>
        </Box>
        <Box>
          <DataFieldWithLabel
            label="심사 완료 방식"
            labelSX={{
              color: `${ColorPalette._252627}`,
              fontWeight: 'bold',
              minWidth: '100px',
            }}
          >
            <TextBox
              variant="body9"
              sx={{
                maxWidth: '70%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {`${campaign.estimationCompletionRate ? campaign.estimationCompletionRate : 0}%`}
            </TextBox>
          </DataFieldWithLabel>
        </Box>
        <Box>
          <DataFieldWithLabel
            label="캠페인 담당자"
            labelSX={{
              color: `${ColorPalette._252627}`,
              fontWeight: 'bold',
              minWidth: '100px',
            }}
          >
            <TextBox
              variant="body9"
              sx={{
                maxWidth: '70%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {campaign.manager.name}
            </TextBox>
          </DataFieldWithLabel>
        </Box>
        <Box>
          <DataFieldWithLabel
            label="잔여 상금"
            labelSX={{
              color: `${ColorPalette._252627}`,
              fontWeight: 'bold',
              minWidth: '100px',
            }}
          >
            <TextBox
              variant="body9"
              sx={{
                maxWidth: '70%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {`${campaign.totalReward.toLocaleString()}원 (총액 ${
                rest && rest.toLocaleString()
              }원)`}
            </TextBox>
          </DataFieldWithLabel>
        </Box>
      </Box>
    </Box>
  );
};

export default CampaignInfoModalForm;
