import React from 'react';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoEstimationCompetitorUpdateModalState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/features/update-modal/widgets/useState';
import { ProjectSalesInfoEstimationCompetitorUpdateModalWidget } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/features/update-modal/widgets/widget';
import { useProjectSalesInfoEstimationCompetitorCreateModalState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/features/create-modal/widgets/useState';
import { ProjectSalesInfoEstimationCompetitorCreateModalWidget } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/features/create-modal/widgets/widget';

export { EstimationCompetitorModal as ProjectSalesInfoEstimationCompetitorModal };

const EstimationCompetitorModal = () => {
  const { isOpen: isCreateModalOpen } = useProjectSalesInfoEstimationCompetitorCreateModalState(
    (state) => ({
      isOpen: state.isOpen,
    }),
    shallow
  );
  const { isOpen: isUpdateModalOpen } = useProjectSalesInfoEstimationCompetitorUpdateModalState(
    (state) => ({
      isOpen: state.isOpen,
    }),
    shallow
  );
  if (isCreateModalOpen) {
    return <ProjectSalesInfoEstimationCompetitorCreateModalWidget />;
  }
  if (isUpdateModalOpen) {
    return <ProjectSalesInfoEstimationCompetitorUpdateModalWidget />;
  }
  return <></>;
};
