import React from 'react';
import { headerHistoryRepository } from '@front/src/features/header-history/repository/repository';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from '@front/src/utils';
import TableRowLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/TableRowLoadingSpinnerUI';
import TableRowNoResultUI from '@front/src/components/components-with-design/compound/table/TableRowNoResult';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';

export default function HeaderHistoryTableContent() {
  const { data: list } = headerHistoryRepository.useGetHistoryList();
  if (list === undefined) return <TableRowLoadingSpinnerUI colSpan={6} />;
  if (list.length === 0) return <TableRowNoResultUI colSpan={6} />;
  return (
    <>
      {list.map((item) => (
        <TableRowUI key={item.id}>
          <TableCellUI>{dayjs(item.createdAt).format(DATE_TIME_FORMAT)}</TableCellUI>
          <TableCellUI>{item.currentName}</TableCellUI>
          <TableCellUI>{item.beforeName}</TableCellUI>
          <TableCellUI>{item.currentName}</TableCellUI>
          <TableCellUI>{item.note}</TableCellUI>
          <TableCellUI>{item.writerName}</TableCellUI>
        </TableRowUI>
      ))}
    </>
  );
}
