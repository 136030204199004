import { useMutation, useQueryClient } from 'react-query';
import type { AdminManageableVariableUpdateParams } from '@front/src/features/manageable-variable/types/parameter';
import { adminManageableVariableApi } from '@front/src/features/manageable-variable/query/api';

export const adminManageableVariableMutation = {
  useUpdate: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AdminManageableVariableUpdateParams) =>
        adminManageableVariableApi.update(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['admin', 'manageable-variable'],
        });
      },
      onError: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['admin', 'manageable-variable'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useCreate: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: () => adminManageableVariableApi.create(menuId),
      onSuccess: async (res) => {
        await queryClient.invalidateQueries({
          queryKey: ['admin', 'manageable-variable'],
        });
        return res;
      },
    });
    return {
      mutate,
    };
  },
};
