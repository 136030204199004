import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import type { AffiliatedCompanySalesActivityConvertedView } from '@front/src/features/affiliated-company/features/sales-activity/types/view';
import { AffiliatedCompanySalesActivityRowContext } from '@front/src/features/affiliated-company/features/sales-activity/widgets/row/provider/context';

export { Provider as AffiliatedCompanySalesActivityRowProvider };

interface Props {
  children: ReactNode;
  item: AffiliatedCompanySalesActivityConvertedView;
}

const Provider = ({ children, item }: Props) => {
  const value = useMemo(() => ({ item }), [item]);
  return (
    <AffiliatedCompanySalesActivityRowContext.Provider value={value}>
      {children}
    </AffiliatedCompanySalesActivityRowContext.Provider>
  );
};
