import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import type { AttachedFileId } from '@front/ost_proposal/domain';
import AttachedFileLayout from '@front/ost_proposal/view/detail/attached_file/AttachedFileLayout';
import type { AttachedFileParameter } from '@front/ost_proposal/parameter';
import { proposalAction } from '@front/ost_proposal/action';

interface Props {
  isContributor: boolean;
}

function AttachedFileListService({ isContributor }: Props) {
  const dispatch = useDispatch();
  const addAttachedFile = useCallback(
    (params: AttachedFileParameter) => dispatch(proposalAction.addAttachedFile(params)),
    [dispatch]
  );
  const deleteAttachedFile = useCallback(
    (id: AttachedFileId) => dispatch(proposalAction.deleteAttachedFile(id)),
    [dispatch]
  );

  return (
    <AttachedFileLayout
      addAttachedFile={addAttachedFile}
      deleteAttachedFile={deleteAttachedFile}
      isContributor={isContributor}
    />
  );
}

export default AttachedFileListService;
