import React from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { OldTh, Table } from '@front/layouts/Table';
import VoteHistoryList from '@front/personnel/view/Page/Log/Vote/List';

const VoteHistoryTable = () => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <OldTh>No</OldTh>
          <OldTh>주제</OldTh>
          <OldTh>제안자</OldTh>
          <OldTh>구분</OldTh>
          <OldTh>심사자</OldTh>
          <OldTh>제안등급</OldTh>
          <OldTh>투찰현황</OldTh>
          <OldTh>내 투찰금액</OldTh>
          <OldTh>투찰일</OldTh>
        </TableRow>
      </TableHead>
      <TableBody>
        <VoteHistoryList />
      </TableBody>
    </Table>
  </TableContainer>
);

export default VoteHistoryTable;
