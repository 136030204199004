import React from 'react';
import { approvalBoxQuery } from '@front/src/features/approval-box/query/query';
import ApprovalDocumentDetailWidget from '@front/src/components/Drawer/ApprovalDocumentDetailWidget';
import useApprovalBoxStore from '@front/src/features/approval-box/useState';
import { useShallow } from 'zustand/react/shallow';

export default function ApprovalBoxDetailWrapper() {
  const { documentId, params } = useApprovalBoxStore(
    useShallow((state) => ({
      documentId: state.documentId,
      params: state.params,
    }))
  );
  const { data: boxDetail } = approvalBoxQuery.useGetApprovalBoxDetail(documentId, params);

  return <ApprovalDocumentDetailWidget documentDetail={boxDetail} />;
}
