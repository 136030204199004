import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { ControlPanel } from '@front/src/components/control-panel/control-panel';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@front/layouts/Button';
import HookFormMention from '@front/src/components/mention-with-hook-form/hook-form-mention';
import { FormProvider, useForm } from 'react-hook-form';
import type { CommentView } from '@front/src/features/note/types/view';
import UserIcon from '@front/layouts/UserIcon';
import { UserId } from '@front/user/domain';
import type { CommentUpdateParams } from '@front/src/features/note/types/parameter';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from '@front/src/utils';
import { HookFormTextarea } from '@front/src/components/textarea-with-hook-form/textarea-with-hook-form';
import { useCustomDialog } from '@front/src/features/dialog';

interface Props {
  item: CommentView;
  onDelete: (commentId: number) => void;
  onUpdate: (params: CommentUpdateParams) => void;
  isMention?: boolean;
}

interface ControlPanelProps {
  handleDelete: () => void;
  handleEdit: () => void;
  isEditMode: boolean;
}

export default function DrawerCommentItem({ item, onDelete, onUpdate, isMention }: Props) {
  const [isEditMode, setIsEditMode] = useState(false);
  const { confirm } = useCustomDialog();

  const handleEdit = () => {
    setIsEditMode((prev) => !prev);
  };

  const methods = useForm({
    values: {
      contents: item?.contents ?? '',
    },
  });

  const onUpdateClick = methods.handleSubmit((data) => {
    onUpdate({ id: item.id, contents: data.contents });
    setIsEditMode(false);
  });

  const handleDelete = () => {
    confirm({
      title: '삭제 확인',
      lineBreakChildren: [{ value: '댓글을 삭제하시겠습니까?' }],
      afterConfirm: () => onDelete(item.id),
    });
  };

  return (
    <FormProvider {...methods}>
      <Box sx={{
        background: 'var(--design-v-10-light-background-bg-02, #EBEEF2)',
        padding: '10px 0'
      }}>
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Box
            sx={{
              display: 'flex',
              gap: '5px',
              marginBottom: '5px'
            }}
          >
            <CommentControlPanel
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              isEditMode={isEditMode}
            />
            <Box
                sx={{...SX_NOTECARD_HEADER_USERINFO_WRAP}}
            >
              <UserIcon user={UserId(item.writer.profile?.id)} sx={{border: '1px solid rgba(0,0,0,0.08)'}}/>
              <Typography>
                <>
                  {item.writer.name} {dayjs(item.createdAt).format(DATE_TIME_FORMAT)}
                </>
              </Typography>
              {item.modifiedAt && (
                <Typography>({dayjs(item.modifiedAt).format(DATE_TIME_FORMAT)} 최종수정)</Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            padding: '5px',
            borderTop: '1px solid var(--design-v-10-light-line-line, #DCE0E7)',
          }}
        >
          {isEditMode ? (
            isMention ? (
              <HookFormMention
                name="contents"
                userList={[]}
              />
            ) : (
              <HookFormTextarea
                name="contents"
                sx={{ height: 63 }}
                resize="none"
              />
            )
          ) : (
            <Typography
              sx={{
                paddingLeft: '10px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {item.contents}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            textAlign: 'right',
          }}
        >
          {isEditMode && <Button onClick={onUpdateClick}>등록</Button>}
        </Box>
      </Box>
    </FormProvider>
  );
}

const CommentControlPanel = ({ handleEdit, handleDelete, isEditMode }: ControlPanelProps) => {
  const itemList = [
    {
      text: isEditMode ? '수정 닫기' : '수정',
      icon: isEditMode ? HighlightOffIcon : EditIcon,
      action: handleEdit,
    },
    {
      text: '삭제',
      icon: DeleteIcon,
      action: handleDelete,
    },
  ];
  return <ControlPanel itemList={itemList} />;
};

const SX_NOTECARD_HEADER_USERINFO_WRAP = {
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
}