import { accountingDailyReportURL } from '@front/src/features/accounting/features/settings/utils/constant';
import { default as axios } from '@front/src/config/axios';

export { Api as AccountingSettingsDailyReportStatusApi };

const url = {
  getList: () => `${accountingDailyReportURL}`,
};

const Api = {
  getList: async () => {
    const { data } = await axios.get(url.getList());
    return data;
  },
};
