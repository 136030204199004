import React from 'react';
import MemoDrawer from 'components/Memo/view/MemoDrawer';

interface Props {
  isOpen: boolean;
  setDrawer: (open: boolean) => void;
  children: React.ReactNode;
}

export default function MemoDrawerService({ isOpen, setDrawer, children }: Props) {
  return (
    <MemoDrawer
      isOpen={isOpen}
      setDrawer={setDrawer}
      children={children}
    />
  );
}
