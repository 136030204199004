import React, { memo } from 'react';
import { ProjectSalesInfoSubjectReviewModalComplexInformationListItemProvider } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/table/features/item/widgets/provider';
import { ProjectSalesInfoSubjectReviewModalComplexInformationListItem } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/table/features/item/components/item';
import type { ProjectComplexInformationView } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/types/view';

export { Widget as ProjectSalesInfoSubjectReviewModalComplexInformationListItemWidget };

interface Props {
  item: ProjectComplexInformationView;
}

const Widget = memo(({ item }: Props) => (
  <ProjectSalesInfoSubjectReviewModalComplexInformationListItemProvider>
    <ProjectSalesInfoSubjectReviewModalComplexInformationListItem item={item} />
  </ProjectSalesInfoSubjectReviewModalComplexInformationListItemProvider>
));
