import { create } from 'zustand';

export { useState as useBlockModalState };

interface State {
  isDirty: boolean;
}

interface Actions {
  setIsDirty: (isDirty: boolean, callback?: () => void) => void;
  callback?: () => void;
}

const useState = create<State & Actions>((set) => ({
  isDirty: false,
  setIsDirty: (isDirty, callback) => set(() => ({ isDirty, callback })),
}));
