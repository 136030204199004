import { useQuery } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { CommonGetParameter } from '@front/src/types/parameter';

const workOutputQuery = {
  useGetList: (params: CommonGetParameter) => {
    const { data } = useQuery({
      queryFn: () => workWorkApi.getOutputList(params),
      queryKey: ['work', 'output-bundle', 'output', params.bundleId],
      suspense: true,
      enabled: !!params.menuId,
    });
    return { data };
  },
};

export default workOutputQuery;
