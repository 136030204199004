import React, { useContext, useMemo } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { getOptionListFromVariableList } from '@front/src/features/manageable-variable/utils';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { FormProvider, useForm } from 'react-hook-form';
import {
  convertEmptyToNullForParameter,
  convertEmptyToNullIfNumberForParameter,
  convertNullToEmptyForForm,
  convertYNToBooleanForParameter,
} from '@front/src/utils';
import { shallow } from 'zustand/shallow';
import type { ProjectExperimentInformationView } from '@front/src/features/project-sales-info/features/subject-review/features/modal/types/view';
import { useProjectSalesInfoSubjectReviewModalState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/useState';
import { ProjectSalesInfoSubjectReviewModalDesignDateDetailContext } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date/features/detail/widgets/context';
import { useProjectSalesInfoSubjectReviewShapeRatioState } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/useState';
import { useShallow } from 'zustand/react/shallow';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";
import {formatDateOrNull} from "@front/src/features/project-sales-info/utils";

export { DetailTableRow as ProjectSalesInfoSubjectReviewModalDesignDateDetailTableRow };

const DetailTableRow = () => {
  const { detail, onUpdate, codeList } = useContext(
    ProjectSalesInfoSubjectReviewModalDesignDateDetailContext
  );
  const methods = useForm({
    values: getValues(detail),
  });
  const { id } = useProjectSalesInfoSubjectReviewModalState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const optionList = useMemo(() => getOptionListFromVariableList(codeList), [codeList]);
  const onSubmit = methods.handleSubmit((data) => {
    onUpdate({
      id,
      gettingDesignDate: formatDateOrNull(data.gettingDesignDate),
      experimentStandardCode: convertEmptyToNullForParameter(data.experimentStandardCode),
      countOfSiteModel: convertEmptyToNullIfNumberForParameter(data.countOfSiteModel),
      hasConditionOfSpecialWindExperiment: convertYNToBooleanForParameter(
        detail?.hasConditionOfSpecialWindExperiment
      ),
      isNearCoastBy3Km: convertYNToBooleanForParameter(detail?.isNearCoastBy3Km),
      basicWindSpeed: convertEmptyToNullForParameter(detail?.basicWindSpeed),
      file: detail?.windSpeedMapPdf,
      hasWindEnvironment: convertYNToBooleanForParameter(detail?.hasWindEnvironment),
      hasBuildingWind: convertYNToBooleanForParameter(detail?.hasBuildingWind),
      isChanged: false,
    });
  });
  const { readOnly } = useProjectSalesInfoSubjectReviewShapeRatioState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <TableRow key={Math.random()}>
        <OldTd>
          <DatePickerWithHookForm
            name="gettingDesignDate"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <HookFormSelect
            height="60px"
            defaultLabel="선택"
            isDefaultPossible
            name="experimentStandardCode"
            optionList={optionList}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <HookFormInput
            name="countOfSiteModel"
            onSubmit={onSubmit}
            type="number"
            disabled={readOnly}
          />
        </OldTd>
      </TableRow>
    </FormProvider>
  );
};

const getValues = (detail?: ProjectExperimentInformationView) => ({
  gettingDesignDate: convertNullToEmptyForForm(detail?.gettingDesignDate),
  experimentStandardCode: convertNullToEmptyForForm(detail?.experimentStandardCode),
  countOfSiteModel: convertNullToEmptyForForm(detail?.countOfSiteModel),
});
