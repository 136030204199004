import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import Input from '@front/layouts/Input';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import type { VoteAddParameter } from '@front/ost_vote/parameter';
import type { SnackbarSeverityType } from '@front/components/Snackbar/action';
import VoteInput from '@front/ost_proposal/view/detail/vote/VoteInput';
import { ArticleItem } from '@front/src/components/article-item';
import TextBox from '@front/layouts/Text';

interface Props {
  vote: (params: VoteAddParameter) => void;
  openSnackbar: (message: string, severity?: SnackbarSeverityType) => void;
}

const VoteLayout = ({ vote, openSnackbar }: Props) => {
  const { totalReward, grade, id, price, status, ratio, fixedResultSum } = useSelector(
    (root: RootState) => ({
      totalReward: root.proposal.detail!.campaign.totalReward,
      fixedResultSum: root.proposal.detail!.campaign.fixedResultSum,
      grade: root.proposal.detail?.grade,
      id: root.proposal.detail!.id,
      price: root.vote.detail?.price,
      status: root.proposal.detail?.status,
      ratio: root.vote.detail?.ratio,
    }),
    shallowEqual
  );
  const [rest, setRest] = useState<string>('');

  useEffect(() => {
    if (typeof totalReward === 'undefined') {
      return;
    }
    if (typeof fixedResultSum === 'undefined') {
      return;
    }
    setRest((totalReward - fixedResultSum).toLocaleString());
  }, [totalReward, fixedResultSum]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        width: '100%',
        padding: '10px',
      }}
    >
      <ArticleItem
        title="아이디어 가치 심사"
        children={
          <Box
            sx={{
              padding: '10px',
              borderRadius: '5px',
              border: `1px solid ${ColorPalette._e4e9f2}`,
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '4.5fr 4.5fr 1fr',
                columnGap: '12px',
                marginBottom: '10px',
              }}
            >
              <DataFieldWithLabel
                label="총액"
                labelSX={{
                  width: '50px',
                }}
              >
                <Input
                  isAmount
                  key={totalReward}
                  variant="outlined"
                  readOnly
                  defaultValue={`${totalReward ? totalReward.toLocaleString() : 0}`}
                />
              </DataFieldWithLabel>
              <DataFieldWithLabel label="잔여 금액">
                <Input
                  isAmount
                  key={rest}
                  variant="outlined"
                  readOnly
                  defaultValue={rest}
                />
              </DataFieldWithLabel>
              <DataFieldWithLabel label="투찰률">
                <TextBox
                  variant="body9"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {ratio}%
                </TextBox>
              </DataFieldWithLabel>
            </Box>
            <Box
              sx={{
                width: '51%',
              }}
            >
              <VoteInput
                vote={vote}
                totalReward={totalReward}
                grade={grade}
                id={id}
                price={price}
                status={status}
                openSnackbar={openSnackbar}
              />
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default VoteLayout;
