import { shallow } from 'zustand/shallow';
import { useAffiliatedCompanyDetailState } from '@front/src/features/affiliated-company/widgets/detail/useState';
import { affiliatedCompanyGiftHistoryRepository } from '@front/src/features/affiliated-company/features/gift-history/repository/repository';
import { useAffiliatedCompanyGiftHistoryState } from '@front/src/features/affiliated-company/features/gift-history/widgets/useState';

export { useLogic as useAffiliatedCompanyGiftHistoryLogic };

const useLogic = (menuId) => {
  const { id } = useAffiliatedCompanyDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { parameter, setParameter } = useAffiliatedCompanyGiftHistoryState(
    (state) => ({
      parameter: state.parameter,
      setParameter: state.setParameter,
    }),
    shallow
  );
  const { data: giftList, isLoading } =
    affiliatedCompanyGiftHistoryRepository.useGiftHistoryListGet(
      {
        ...parameter,
        id,
      },
      menuId
    );
  const h = { setParameter };
  const d = { giftList };
  return {
    d,
    h,
    isLoading,
  };
};
