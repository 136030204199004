import { useQuery } from 'react-query';
import type { AccountingUploadHistoryTableRowView } from '@front/src/features/accounting/features/upload/features/ecount-data/types/view';
import { AccountingUploadApi } from '@front/src/features/accounting/features/upload/features/ecount-data/query/api';

export { Query as accountingUploadQuery };

const Query = {
  useGetList: () => {
    const { data, isLoading } = useQuery<AccountingUploadHistoryTableRowView[]>({
      queryFn: () => AccountingUploadApi.getList(),
      queryKey: ['accounting', 'upload', 'ecount-data', 'list'],
      suspense: true,
    });
    return {
      data,
      isLoading,
    };
  },
};
