import { affiliatedPersonMemoQuery } from '@front/src/features/affiliated-person/features/memo/query/query';
import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedPersonMemoMutation } from '@front/src/features/affiliated-person/features/memo/query/mutation';
import type {
  AffiliatedPersonMemoCreateParameter,
  AffiliatedPersonMemoListGetParameter,
} from '@front/src/features/affiliated-person/features/memo/types/parameter';

export { repository as affiliatedPersonMemoRepository };

const repository = {
  useCreate: (menuId) => {
    const { mutate } = affiliatedPersonMemoMutation.useCreate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params: AffiliatedPersonMemoCreateParameter) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useListGet: (params: AffiliatedPersonMemoListGetParameter, menuId) => {
    const { data, remove } = affiliatedPersonMemoQuery.useListGet(params, menuId);
    return {
      data,
      remove,
    };
  },
  useUpdate: (menuId) => {
    const { mutate } = affiliatedPersonMemoMutation.useUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useDelete: (menuId) => {
    const { mutate } = affiliatedPersonMemoMutation.useDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
