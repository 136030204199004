import React, { memo, Suspense } from 'react';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import ResizableLeftDrawer from '@front/src/components/list-drawer/components/resizable-left-drawer';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import ApprovalDocumentContent from '@front/src/features/drawer-approval-document/components/ApprovalDocumentContent';

function ApprovalDocumentFeature() {
  const leftDrawerState = useGlobalNavBarState((state) => state.leftDrawerState);

  return (
    <ResizableLeftDrawer leftDrawerState={leftDrawerState === 'approval-document'}>
      <Suspense
        fallback={
          <LoadingSpinner
            height="100%"
            width="100%"
          />
        }
      >
        <ApprovalDocumentContent />
      </Suspense>
    </ResizableLeftDrawer>
  );
}

export default memo(ApprovalDocumentFeature);
