import { useQuery } from 'react-query';
import type { AffiliatedCompanyPersonOutlineBasicView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/basic/types/view';
import { affiliatedCompanyPersonOutlineBasicApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/basic/query/api';
import { useNavigate } from 'react-router-dom';

export const affiliatedCompanyPersonOutlineBasicQuery = {
  usePersonOutlineBasicGet: (id?: number, menuId?) => {
    const navigate = useNavigate();
    const { data, isLoading } = useQuery<AffiliatedCompanyPersonOutlineBasicView>({
      queryKey: ['affiliated-company', 'person', 'outline', 'basic', id],
      queryFn: () => affiliatedCompanyPersonOutlineBasicApi.getPersonOutlineBasic(id, menuId),
      enabled: !!id,
      onError: (err: any) => {
        if (err?.response?.data?.code.split('.')?.[1] === 'not_found') {
          navigate(`/affiliated-person`);
        }
      },
    });
    return {
      data,
      isLoading,
    };
  },
};
