import React, { Suspense } from 'react';
import { DrawerLoadingSpinner } from '@front/src/components/Drawer/drawer-loading-spinner';
import { AffiliatedPersonDrawerListProvider } from '@front/src/features/affiliated-person/features/drawer/widgets/list/provider/provider';
import { AffiliatedPersonDrawerList } from '@front/src/features/affiliated-person/features/drawer/widgets/list/components/list';

export { Widget as AffiliatedPersonDrawerListWidget };

const Widget = () => (
  <Suspense fallback={<DrawerLoadingSpinner colSpan={3} />}>
    <AffiliatedPersonDrawerListProvider>
      <AffiliatedPersonDrawerList />
    </AffiliatedPersonDrawerListProvider>
  </Suspense>
);
