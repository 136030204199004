type Parameter = {
  [key: string | number]: any;
};

export function toFormData(parameter: Parameter, prefix?: string, formData?: FormData): FormData {
  const result = formData ?? new FormData();
  const keys = Object.keys(parameter);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = parameter[key];
    if (typeof value === 'undefined' || value === null || Number.isNaN(value) || value === '') {
      continue;
    }
    const name = prefix ? (isNaN(parseInt(key)) ? `${prefix}.${key}` : prefix) : key;
    if (Array.isArray(value)) {
      value.forEach((item, i) => toFormData(item, `${name}[${i}]`, result));
      continue;
    }
    if (value instanceof File || value instanceof Blob) {
      result.append(name, value);
      continue;
    }
    if (typeof value === 'object') {
      toFormData(value, name, result);
      continue;
    }
    result.append(name, value);
  }
  return result;
}
