import type { ReactNode } from 'react';
import React from 'react';
import { Box, TableContainer } from '@mui/material';
import { Table as MuiTable } from '@front/layouts/Table';
import { AffiliatedCompanyProjectListTableHeader } from '@front/src/features/affiliated-company/features/project/components/table-header';
import { ColorPalette } from '@front/assets/theme';

interface Props {
  children: ReactNode;
}

export { Table as AffiliatedCompanyProjectListTable };

const Table = ({ children }: Props) => (
  <Box
    sx={{
      border: `1px solid ${ColorPalette._e4e9f2}`,
      borderRadius: '8px',
      overflow: 'auto',
    }}
  >
    <TableContainer
      sx={{
        width: '2544px',
        overflowX: 'visible',
      }}
    >
      <MuiTable>
        <AffiliatedCompanyProjectListTableHeader />
      </MuiTable>
    </TableContainer>
    {children}
  </Box>
);
