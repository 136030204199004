import React, { useRef } from 'react';
import { Box } from '@mui/material';
import TextBox from '@front/layouts/Text';
import Input from '@front/layouts/Input';
import Button from '@front/layouts/Button';
import { ColorPalette } from '@front/assets/theme';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import type { ArrangementCommentParameter } from '@front/ost_proposal/parameter';
import type { ArrangementCommentId } from '@front/ost_proposal/domain';
import useDialog from '@front/dialog/hook';

interface Props {
  setArrangementCommentParentId: (id: ArrangementCommentId | undefined) => void;
  addArrangementComment: (comment: ArrangementCommentParameter) => void;
  arrangementCommentParentId: ArrangementCommentId;
}

const ReplyArrangementAddRowLayout = ({
  setArrangementCommentParentId,
  addArrangementComment,
  arrangementCommentParentId,
}: Props) => {
  const loginUserName = useSelector((state: RootState) => state.login.detail?.name, shallowEqual);
  const inputRef = useRef<HTMLInputElement>(null);
  const { error } = useDialog();
  return (
    <Box
      sx={{
        display: 'grid',
        gridArea: 'reply',
        width: '100%',
        gridTemplateRows: '20px auto',
        gridTemplateAreas: `"name"
                                    "contents"`,
        marginBottom: '10px',
      }}
    >
      <Box
        sx={{
          display: 'inherit',
          width: '100%',
          gridArea: 'name',
        }}
      >
        <TextBox variant="body7">{loginUserName}</TextBox>
      </Box>
      <Box
        sx={{
          display: 'inherit',
          width: '100%',
          gridArea: 'contents',
        }}
      >
        <Input
          variant="outlined"
          endAdornment={
            <>
              <Button
                shape="basic3"
                onClick={() => {
                  setArrangementCommentParentId(undefined);
                }}
                sx={{
                  marginRight: '8px',
                }}
              >
                취소
              </Button>
              <Button
                onClick={() => {
                  if (inputRef.current?.value.trim() === '') {
                    error('내용을 입력해주세요.');
                    inputRef.current.value = '';
                  } else {
                    if (inputRef.current) {
                      addArrangementComment({
                        parentId: arrangementCommentParentId,
                        content: inputRef.current.value,
                      });
                      inputRef.current.value = '';
                    }
                  }
                }}
              >
                등록
              </Button>
            </>
          }
          sx={{
            border: `1px solid ${ColorPalette._e4e9f2}`,
          }}
          multiline
          placeholder="제안 피드백을 작성 후 등록 버튼을 클릭 하세요."
          inputRef={inputRef}
        />
      </Box>
    </Box>
  );
};

export default ReplyArrangementAddRowLayout;
