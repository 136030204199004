import { create } from 'zustand';

interface State {
  isCreateModalOpen: boolean;
  isUpdateModalOpen: boolean;
  setIsCreateModalOpen: (isOpen: boolean) => void;
  setIsUpdateModalOpen: (isOpen: boolean) => void;
  meetingHistoryId?: number;
  setMeetingHistoryId: (id?: number) => void;
}

export { useState as useAffiliatedCompanyMeetingHistoryModalState };

const useState = create<State>((set) => ({
  isCreateModalOpen: false,
  isUpdateModalOpen: false,
  setIsCreateModalOpen: (isCreateModalOpen) => set(() => ({ isCreateModalOpen })),
  setIsUpdateModalOpen: (isUpdateModalOpen) => set(() => ({ isUpdateModalOpen })),
  setMeetingHistoryId: (meetingHistoryId) => set(() => ({ meetingHistoryId })),
}));

interface UserListState {
  userIdList: number[];
  setUserIdList: (list: number[]) => void;
}

export { useUserListState as useAffiliatedCompanyMeetingHistoryModalUserListState };

const useUserListState = create<UserListState>((set) => ({
  userIdList: [],
  setUserIdList: (userIdList) => set(() => ({ userIdList })),
}));
