import * as firebase from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FCM_APP_ID,
  // measurementId: REACT_APP_FCM_MEASUREMENT_ID,
};
export const app = firebase.initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

// export const authService = getAuth();

export const firebaseClass = {
  messaging: getMessaging(firebase.initializeApp(firebaseConfig)),
};
