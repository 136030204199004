import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HookFormFileUploader } from '@front/src/components/file-uploader/hook-form-file-uploader';
import type { WorkReferenceView } from '@front/src/features/work/features/work/features/reference/types/view';
import Box from '@mui/material/Box';
import { downloadFileFromS3 } from '@front/src/utils';
import type { WorkReferenceFileUpdateParameter } from '@front/src/features/work/features/work/features/reference/types/parameter';

interface Props {
  item: WorkReferenceView;
  onUpdateFile?: (params: WorkReferenceFileUpdateParameter) => void;
  sectionId: number;
  dataId: number;
  menuId?: number;
}

export default function WorkReferenceTableBodyRowFile({
  item,
  onUpdateFile,
  sectionId,
  dataId,
  menuId,
}: Readonly<Props>) {
  const methods = useForm({
    values: getFormValues(item),
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    const request = {
      menuId,
      dataId,
      sectionId,
    };
    const formData = new FormData();
    formData.append(`request`, new Blob([JSON.stringify(request)], { type: 'application/json' }));
    formData.append('image', data.file.multipartFile);
    onUpdateFile?.({
      id: data.id,
      formData,
    });
  });
  return (
    <FormProvider {...methods}>
      <Box>
        <HookFormFileUploader
          name="file"
          onSubmit={onSubmit}
          download={downloadFileFromS3}
          id={`${item.id}-reference`}
          isPossibleDownload={false}
        />
      </Box>
    </FormProvider>
  );
}

function getFormValues(item) {
  return {
    id: item.id,
    file: item.file ?? '',
  };
}
