import { HAS_VAT_OPTION } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/types/domain';

export const hasVatOptionList = [
  {
    label: HAS_VAT_OPTION.INCLUDE,
    value: HAS_VAT_OPTION.INCLUDE,
  },
  {
    label: HAS_VAT_OPTION.EXCLUDE,
    value: HAS_VAT_OPTION.EXCLUDE,
  },
];

export const unpaidReasonOptionList = [
  {
    label: '1. PF지연',
    value: '1. PF지연',
  },
  {
    label: '2. 원청지급지연',
    value: '2. 원청지급지연',
  },
  {
    label: '3. 회사내규',
    value: '3. 회사내규',
  },
  {
    label: '4. 프로젝트지연',
    value: '4. 프로젝트지연',
  },
  {
    label: '5. 홀딩',
    value: '5. 홀딩',
  },
  {
    label: '6. 입금완료',
    value: '6. 입금완료',
  },
];

export const contractEarningsCaseOptionList = [
  {
    label: 'ㄱ. 한풍시기금',
    value: 'ㄱ. 한풍시기금',
  },
  {
    label: 'ㄴ. PF',
    value: 'ㄴ. PF',
  },
  {
    label: 'ㄷ. 인허가',
    value: 'ㄷ. 인허가',
  },
  {
    label: 'ㄹ. 지급시기X',
    value: 'ㄹ. 지급시기X',
  },
  {
    label: 'ㅁ. 한풍기준금 홀딩',
    value: 'ㅁ. 한풍기준금 홀딩',
  },
  {
    label: 'ㅂ. 고객기준금 홀딩',
    value: 'ㅂ. 고객기준금 홀딩',
  },
  {
    label: 'ㅅ. 수금완료',
    value: 'ㅅ. 수금완료',
  },
  {
    label: 'N1. 미착수-한풍시기금',
    value: 'N1. 미착수-한풍시기금',
  },
  {
    label: 'N2. 미착수-PF',
    value: 'N2. 미착수-PF',
  },
  {
    label: 'N3. 미착수-인허가',
    value: 'N3. 미착수-인허가',
  },
  {
    label: 'N4. 미착수-지급시기X',
    value: 'N4. 미착수-지급시기X',
  },
];

export const depositCompleteKeyword = '6. 입금완료';
