import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { OldTd, Table } from '@front/layouts/Table';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import type { SmProjectCollectionParameter } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/parameters';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { HookFormText } from '@front/src/components/text-with-hook-form/text-with-hook-form';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import Button from '@front/layouts/Button';
import { ProjectSalesInfoContractEarningsItemDetailCreateModalBtn } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/components/detail-create-modal-btn';
import { ProjectSalesInfoContractEarningsItemCreateModalContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/widgets/context';
import { convertToDepositCompanyOptionList } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/utils/constants';
import { convertToManageableOptionList } from '@front/src/features/project-sales-info/features/collection/utils/constant';
import { useProjectSalesInfoContractEarningsItemCreateModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/widgets/useState';
import { useProjectSalesInfoCollectionManagementModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/widgets/useState';
import { useContractEarningsItemState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/useState';
import dayjs from 'dayjs';
import { convertYNToBooleanForParameter, YNOptionList } from '@front/src/utils';
import RequiredMark from '@front/layouts/RequiredMark';
import { ProjectSalesInfoContractEarningsItemContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/context';
import {
  contractEarningsCaseOptionList,
  depositCompleteKeyword,
} from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/utils/constants';
import { useShallow } from 'zustand/react/shallow';
import { HookFormTextarea } from '@front/src/components/textarea-with-hook-form/textarea-with-hook-form';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";

export { DetailCreateModalTable as ProjectSalesInfoContractEarningsItemDetailCreateModalTable };

const DetailCreateModalTable = () => {
  const {
    d: { consortiumCompanyList, confirmedContractDetail },
  } = useContext(ProjectSalesInfoContractEarningsItemCreateModalContext);
  const {
    d: { bondClassificationOptionList },
  } = useContext(ProjectSalesInfoContractEarningsItemContext);
  const {
    setIsManagementModalOpen,
    defaultReviewAmount,
    setDefaultReviewAmount,
    setDefaultNetAmount,
    defaultNetAmount,
    setDefaultCollectionReviewAmount,
    setDefaultNetCollectionAmount,
    defaultCollectionReviewAmount,
    defaultNetCollectionAmount,
  } = useProjectSalesInfoContractEarningsItemCreateModalState(
    useShallow((state) => ({
      setIsManagementModalOpen: state.setIsManagementModalOpen,
      defaultReviewAmount: state.defaultReviewAmount,
      setDefaultReviewAmount: state.setDefaultReviewAmount,
      defaultNetAmount: state.defaultNetAmount,
      setDefaultNetAmount: state.setDefaultNetAmount,
      defaultCollectionReviewAmount: state.defaultCollectionReviewAmount,
      setDefaultCollectionReviewAmount: state.setDefaultCollectionReviewAmount,
      defaultNetCollectionAmount: state.defaultNetCollectionAmount,
      setDefaultNetCollectionAmount: state.setDefaultNetCollectionAmount,
    }))
  );
  const name = useContractEarningsItemState((state) => state.name);
  const {
    collectionManagementList,
    addCollectionManagementList,
    deleteDueCollectionManagementList,
  } = useProjectSalesInfoCollectionManagementModalState(
    useShallow((state) => ({
      collectionManagementList: state.collectionManagementList,
      addCollectionManagementList: state.addCollectionManagementList,
      deleteDueCollectionManagementList: state.deleteDueCollectionManagementList,
    }))
  );
  const methods = useForm<SmProjectCollectionParameter>({
    values: {
      name: '',
      contractEarningsName: name ?? '',
      ordererCompanyId: '',
      ordererCompanyRatio: '',
      paymentRatio: '',
      CMRatio: '',
      paymentCondition: '',
      amount: '',
      reviewAmount: '',
      CMAmount: '',
      convertedCMAmount: '',
      netAmount: '',
      collectionAmount: '',
      collectionReviewAmount: '',
      collectionCMAmount: '',
      convertedCollectionCMAmount: '',
      netCollectionAmount: '',
      contractEarningsCase: '',
      claimLetterSentDate: null,
      taxInvoiceIssuedDate: null,
      depositCompanyId: '',
      depositDate: null,
      dueDate: null,
      isCm: '',
      bondClassification: '',
      delayPeriod: '',
      collectionPeriod: '',
      note: '',
    },
  });
  const { watch, setValue, control, getValues } = methods;
  const collectionDueDate = collectionManagementList.at(-1)?.collectionDueDate ?? '-';
  const billingDate = collectionManagementList.at(-1)?.billingDate ?? '-';
  const collectionForecastDate = collectionManagementList.at(-1)?.collectionForecastDate ?? '-';
  const collectionPossibility = collectionManagementList.at(-1)?.collectionPossibilityRatio ?? '';
  const unpaidReason = collectionManagementList.at(-1)?.unpaidReason ?? '-';
  const dueDate = watch('dueDate');
  const delayPeriod = useMemo(() => dayjs().diff(dayjs(dueDate), 'day'), [dueDate]);
  const collectionPeriod = dayjs(collectionDueDate).diff(dayjs(billingDate), 'day');
  useEffect(() => {
    setValue('collectionPeriod', isNaN(collectionPeriod) ? '' : collectionPeriod);
  }, [collectionPeriod, setValue]);
  useEffect(() => {
    setValue('delayPeriod', isNaN(delayPeriod) ? '' : delayPeriod);
  }, [delayPeriod, setValue]);
  const isCm = convertYNToBooleanForParameter(watch('isCm'));
  useEffect(() => {
    if (isCm) return;
    setValue('CMRatio', '');
    setValue('CMAmount', '');
    setValue('convertedCMAmount', '');
    setValue('collectionCMAmount', '');
    setValue('convertedCollectionCMAmount', '');
  }, [isCm, setValue]);
  const depositDate = watch('depositDate');
  useEffect(() => {
    if (depositDate) {
      if (collectionManagementList.at(-1)?.unpaidReason === depositCompleteKeyword) return;
      addCollectionManagementList({
        collectionDueDate: collectionManagementList.at(-1)?.collectionDueDate ?? '',
        billingDate: collectionManagementList.at(-1)?.billingDate ?? '',
        collectionForecastDate: collectionManagementList.at(-1)?.collectionForecastDate ?? '',
        collectionPossibilityRatio:
          collectionManagementList.at(-1)?.collectionPossibilityRatio ?? '',
        unpaidReason: depositCompleteKeyword,
        affiliatedPersonId: collectionManagementList.at(-1)?.affiliatedPerson?.id ?? '',
        affiliatedPersonName: collectionManagementList.at(-1)?.affiliatedPerson?.name ?? '',
        comment: '',
        createdAt: dayjs().format('YYYY-MM-DD'),
      });
    } else {
      if (unpaidReason !== depositCompleteKeyword) return;
      deleteDueCollectionManagementList([collectionManagementList.length - 1]);
    }
  }, [
    depositDate,
    addCollectionManagementList,
    deleteDueCollectionManagementList,
    collectionManagementList,
    unpaidReason,
  ]);
  useEffect(() => {
    if (unpaidReason !== depositCompleteKeyword) {
      setValue('depositDate', '');
    }
  }, [unpaidReason, setValue]);
  const contractEarningsCase = useWatch({
    name: 'contractEarningsCase',
    control,
  });
  const isCollectionCompleted = useMemo(
    () => contractEarningsCase === 'ㅅ. 수금완료',
    [contractEarningsCase]
  );
  const handleAmountBlur = useCallback(
    (value) => {
      if (!value) return;
      const { totalAmount, reviewAmount } = confirmedContractDetail!;
      if (!totalAmount || !reviewAmount) return;
      const amount = +value.replace(/\D/g, '');
      const result1 = Math.round((amount / (totalAmount ?? 1)) * (reviewAmount ?? 1));
      setDefaultReviewAmount(result1.toLocaleString());
      setValue('reviewAmount', result1.toLocaleString());
      const result2 = amount - result1;
      setDefaultNetAmount(result2.toLocaleString());
      setValue('netAmount', result2.toLocaleString());
    },
    [setValue, confirmedContractDetail, setDefaultReviewAmount, setDefaultNetAmount]
  );
  const handleReviewAmountBlur = useCallback(
    (value) => {
      if (!value) return;
      const amount = +getValues('amount').replace(/\D/g, '');
      const reviewAmount = +value.replace(/\D/g, '');
      const result = amount - reviewAmount;
      setDefaultNetAmount(result.toLocaleString());
      setValue('netAmount', result.toLocaleString());
    },
    [getValues, setValue, setDefaultNetAmount]
  );
  const handleCollectionAmountBlur = useCallback(
    (value) => {
      if (!value) return;
      const { totalAmount, reviewAmount } = confirmedContractDetail!;
      if (!totalAmount || !reviewAmount) return;
      const collectionAmount = +value.replace(/\D/g, '');
      const result1 = Math.round((collectionAmount / (totalAmount ?? 1)) * (reviewAmount ?? 1));
      setDefaultCollectionReviewAmount(result1.toLocaleString());
      setValue('collectionReviewAmount', result1.toLocaleString());
      const result2 = collectionAmount - result1;
      setDefaultNetCollectionAmount(result2.toLocaleString());
      setValue('netCollectionAmount', result2.toLocaleString());
    },
    [
      setValue,
      setDefaultCollectionReviewAmount,
      setDefaultNetCollectionAmount,
      confirmedContractDetail,
    ]
  );
  const handleCollectionReviewAmountBlur = useCallback(
    (value) => {
      if (!value) return;
      const collectionAmount = +getValues('collectionAmount').replace(/\D/g, '');
      const collectionReviewAmount = +value.replace(/\D/g, '');
      const result = collectionAmount - collectionReviewAmount;
      setDefaultNetCollectionAmount(result.toLocaleString());
      setValue('netCollectionAmount', result.toLocaleString());
    },
    [getValues, setValue, setDefaultNetCollectionAmount]
  );
  const { readOnly } = useContractEarningsItemState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <div
        style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}
      >
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <Th width="140px">기성구분</Th>
                <OldTd>
                  <HookFormInput
                    name="name"
                    width="200px"
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>기성단계명</Th>
                <OldTd sx={{ textAlign: 'left' }}>
                  <HookFormText name="contractEarningsName" />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>발주처명</Th>
                <OldTd>
                  <HookFormSelect
                    name="ordererCompanyId"
                    optionList={convertToDepositCompanyOptionList(consortiumCompanyList) ?? []}
                    width="280px"
                    defaultLabel="선택"
                    isDefaultPossible={true}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>발주처비율</Th>
                <OldTd sx={{ display: 'flex', gap: '5px', alignItems: 'center', height: '58px' }}>
                  <HookFormInput
                    name="ordererCompanyRatio"
                    width="50px"
                    inputType={InputType.RATIO}
                    disabled={readOnly}
                  />
                  <span>(%)</span>
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>지급비율</Th>
                <OldTd sx={{ display: 'flex', gap: '5px', alignItems: 'center', height: '58px' }}>
                  <HookFormInput
                    name="paymentRatio"
                    width="50px"
                    inputType={InputType.RATIO}
                    disabled={readOnly}
                  />
                  <span>(%)</span>
                </OldTd>
              </TableRow>
              {isCm && (
                <TableRow>
                  <Th>CM비율</Th>
                  <OldTd sx={{ display: 'flex', gap: '5px', alignItems: 'center', height: '58px' }}>
                    <HookFormInput
                      name="CMRatio"
                      width="50px"
                      inputType={InputType.RATIO}
                      disabled={readOnly}
                    />
                    <span>(%)</span>
                  </OldTd>
                </TableRow>
              )}
              <TableRow>
                <Th>지급조건</Th>
                <OldTd>
                  <HookFormInput
                    name="paymentCondition"
                    width="200px"
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>금액</Th>
                <OldTd>
                  <HookFormInput
                    name="amount"
                    inputType={InputType.AMOUNT}
                    width="200px"
                    onBlur={handleAmountBlur}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>구검비</Th>
                <OldTd>
                  <HookFormInput
                    name="reviewAmount"
                    inputType={InputType.AMOUNT}
                    width="200px"
                    defaultValue={defaultReviewAmount}
                    onBlur={handleReviewAmountBlur}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              {isCm && (
                <TableRow>
                  <Th>CM비</Th>
                  <OldTd>
                    <HookFormInput
                      name="CMAmount"
                      inputType={InputType.AMOUNT}
                      width="200px"
                      disabled={readOnly}
                    />
                  </OldTd>
                </TableRow>
              )}
              {isCm && (
                <TableRow>
                  <Th>환산CM비</Th>
                  <OldTd>
                    <HookFormInput
                      name="convertedCMAmount"
                      inputType={InputType.AMOUNT}
                      width="200px"
                      disabled={readOnly}
                    />
                  </OldTd>
                </TableRow>
              )}
              <TableRow>
                <Th>순금액</Th>
                <OldTd>
                  <HookFormInput
                    name="netAmount"
                    inputType={InputType.AMOUNT}
                    width="200px"
                    defaultValue={defaultNetAmount}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>수금액</Th>
                <OldTd>
                  <HookFormInput
                    name="collectionAmount"
                    inputType={InputType.AMOUNT}
                    width="200px"
                    onBlur={handleCollectionAmountBlur}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>수금 구검비</Th>
                <OldTd>
                  <HookFormInput
                    name="collectionReviewAmount"
                    inputType={InputType.AMOUNT}
                    width="200px"
                    onBlur={handleCollectionReviewAmountBlur}
                    defaultValue={defaultCollectionReviewAmount}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              {isCm && (
                <TableRow>
                  <Th>수금 CM비</Th>
                  <OldTd>
                    <HookFormInput
                      name="collectionCMAmount"
                      inputType={InputType.AMOUNT}
                      width="200px"
                      disabled={readOnly}
                    />
                  </OldTd>
                </TableRow>
              )}
              {isCm && (
                <TableRow>
                  <Th>수금 환산CM비</Th>
                  <OldTd>
                    <HookFormInput
                      name="convertedCollectionCMAmount"
                      inputType={InputType.AMOUNT}
                      width="200px"
                      disabled={readOnly}
                    />
                  </OldTd>
                </TableRow>
              )}
              <TableRow>
                <Th>순수금액</Th>
                <OldTd>
                  <HookFormInput
                    name="netCollectionAmount"
                    inputType={InputType.AMOUNT}
                    width="200px"
                    defaultValue={defaultNetCollectionAmount}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>
                  <RequiredMark
                    required={!isCollectionCompleted}
                    text="고객예정일"
                    isRed={!isCollectionCompleted}
                    size="16px"
                  />
                </Th>
                <OldTd>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {collectionDueDate || '-'}
                    <Button
                      onClick={() => setIsManagementModalOpen()}
                      disabled={readOnly}
                    >
                      관리
                    </Button>
                  </div>
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>청구일</Th>
                <OldTd>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {billingDate || '-'}{' '}
                    <Button
                      onClick={() => setIsManagementModalOpen()}
                      disabled={readOnly}
                    >
                      관리
                    </Button>
                  </div>
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>
                  <RequiredMark
                    required={!isCollectionCompleted}
                    text="한풍예측일"
                    isRed={!isCollectionCompleted}
                    size="16px"
                  />
                </Th>
                <OldTd>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {collectionForecastDate || '-'}
                    <Button
                      onClick={() => setIsManagementModalOpen()}
                      disabled={readOnly}
                    >
                      관리
                    </Button>
                  </div>
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>
                  <RequiredMark
                    required={!isCollectionCompleted}
                    text="수금가능성"
                    isRed={!isCollectionCompleted}
                    size="16px"
                  />
                </Th>
                <OldTd align="left">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {collectionPossibility ? collectionPossibility + '%' : '-'}
                    <Button onClick={() => setIsManagementModalOpen()}>관리</Button>
                  </div>
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>미수금사유</Th>
                <OldTd align="left">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {unpaidReason || '-'}
                    <Button
                      onClick={() => setIsManagementModalOpen()}
                      disabled={readOnly}
                    >
                      관리
                    </Button>
                  </div>
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>
                  <RequiredMark
                    required={true}
                    text="기성CASE"
                    isRed={true}
                    size="16px"
                  />
                </Th>
                <OldTd>
                  <HookFormSelect
                    name="contractEarningsCase"
                    optionList={contractEarningsCaseOptionList}
                    width="150px"
                    defaultLabel="선택"
                    isDefaultPossible={true}
                    required={true}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>청구공문 송부일</Th>
                <OldTd sx={{textAlign:'left'}}>
                  <DatePickerWithHookForm
                    name="claimLetterSentDate"
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>세금계산서 발행일</Th>
                <OldTd sx={{textAlign:'left'}}>
                  <DatePickerWithHookForm
                    name="taxInvoiceIssuedDate"
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>입금처명</Th>
                <OldTd>
                  <HookFormSelect
                    name="depositCompanyId"
                    defaultLabel="선택"
                    width="200px"
                    optionList={convertToDepositCompanyOptionList(consortiumCompanyList) ?? []}
                    isDefaultPossible={true}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>입금일</Th>
                <OldTd sx={{textAlign:'left'}}>
                  <DatePickerWithHookForm
                    name="depositDate"
                    key={depositDate}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>
                  <RequiredMark
                    required={true}
                    text="응당일"
                    isRed={true}
                    size="16px"
                  />
                </Th>
                <OldTd sx={{textAlign:'left'}}>
                  <DatePickerWithHookForm
                    name="dueDate"
                    required={true}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>CM 여부</Th>
                <OldTd>
                  <HookFormSelect
                    name="isCm"
                    optionList={YNOptionList}
                    width="150px"
                    defaultLabel="선택"
                    isDefaultPossible={true}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>채권구분</Th>
                <OldTd>
                  <HookFormSelect
                    name="bondClassification"
                    optionList={convertToManageableOptionList(bondClassificationOptionList)}
                    width="150px"
                    defaultLabel="선택"
                    isDefaultPossible={true}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>지연기간</Th>
                <OldTd align="left">
                  <HookFormText name="delayPeriod" />
                  {watch('delayPeriod') !== '' && '일'}
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>수금기간</Th>
                <OldTd align="left">
                  <HookFormText name="collectionPeriod" />
                  {watch('collectionPeriod') !== '' && '일'}
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>비고</Th>
                <OldTd>
                  <HookFormTextarea
                    name="note"
                    minRows={2}
                    resize="none"
                    disabled={readOnly}
                    variable={true}
                  />
                </OldTd>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <ProjectSalesInfoContractEarningsItemDetailCreateModalBtn
          isCollectionCompleted={isCollectionCompleted}
        />
      </div>
    </FormProvider>
  );
};
