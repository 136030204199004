import { baseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import { convertEmptyToNull, getMenuIdHeaders } from '@front/src/utils';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import type { AffiliatedCompanyCharacterCharacterUpdateParameter } from '@front/src/features/affiliated-company/features/character/features/character/types/parameter';

const url = {
  createCharacterCharacter: (id?) => `${baseUrl}/${id}/character`,
  getCharacterCharacter: (id?) => `${baseUrl}/${id}/character`,
  updateCharacterCharacter: (id?) => `${baseUrl}/character/${id}`,
  deleteCharacterCharacter: () => `${baseUrl}/character`,
};

export const affiliatedCompanyCharacterCharacterApi = {
  createCharacterCharacter: async (id?: number, menuId?) => {
    await axios.post(url.createCharacterCharacter(id), null, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  getCharacterCharacter: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getCharacterCharacter(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updateCharacterCharacter: async (
    params: AffiliatedCompanyCharacterCharacterUpdateParameter,
    menuId
  ) => {
    await axios.put(url.updateCharacterCharacter(params.id), convertEmptyToNull(params), {
      headers: getMenuIdHeaders(menuId),
    });
  },
  deleteCharacterCharacter: async (params: AffiliatedCompanyIdListDeleteParameter, menuId) => {
    await axios.delete(url.deleteCharacterCharacter(), {
      data: params,
      headers: getMenuIdHeaders(menuId),
    });
  },
};
