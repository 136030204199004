import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { ModalPositionType } from 'layouts/ModalLayout';
import ModalLayout from 'layouts/ModalLayout';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from 'services/reducer';
import { ColorPalette } from 'assets/theme';
import { addressModalAction } from 'components/AddressModal/action';
import type { Address } from 'components/AddressModal/domain';
import { initialAddress } from 'components/AddressModal/domain';
import type { FormikContextType } from 'formik';
import type { AddressQuery } from 'components/AddressModal/query';
import { initialAddressQuery } from 'components/AddressModal/query';
import Footer from 'components/AddressModal/view/Footer';
import DefaultMessage from 'components/AddressModal/view/Form/DefaultMessage';
import PaginationSection from 'components/AddressModal/view/Form/PaginationSection';
import DetailAddressInput from 'components/AddressModal/view/Form/DetailAddressInput';
import AddressSearchSection from 'components/AddressModal/view/Form/AddressSearchSection';
import AddressForm from 'components/AddressModal/view/Form/AddressForm';
import { isMobileDevice } from '@front/util/PwaUtil';

export interface UpdateByFormik {
  formik: FormikContextType<any> | undefined;
  fieldName: string | string[];
}

/*TODO: 확장 가능성 있음. 생각해보기*/
export interface UpdateByDispatch {
  onUpdate: (params: { address: string; id?: number }) => void;
  id?: number;
}

interface Props {
  updateByFormik?: UpdateByFormik;
  updateByDispatch?: UpdateByDispatch;
  position?: ModalPositionType;
}

export const AddressModal = ({ updateByFormik, updateByDispatch, position }: Props) => {
  const dispatch = useDispatch();
  const boxRef = useRef<Address>(initialAddress);
  const { addressModal, list, totalPage } = useSelector((state: RootState) => state.address);

  const [addressValue, setAddressValue] = useState<Address>(initialAddress);
  const [detailAddress, setDetailAddress] = useState<string>('');
  const [query, setQuery] = useState<AddressQuery>(initialAddressQuery);
  const [addressList, setAddressList] = useState<Address[]>([]);
  const [isSaved, setIsSaved] = useState<boolean>(false);

  const onClose = useCallback(() => dispatch(addressModalAction.addressModal(false)), [dispatch]);
  const setFilter = useCallback(
    (query: AddressQuery) => dispatch(addressModalAction.setFilter(query)),
    [dispatch]
  );

  useEffect(() => {
    if (addressModal) {
      setFilter(query);
    }
  }, [query.page]);

  useEffect(() => {
    setAddressList(list);
  }, [list]);

  useEffect(() => {
    setQuery(initialAddressQuery);
    setDetailAddress('');
    setAddressList([]);
  }, [isSaved]);

  return (
    <ModalLayout
      position={position}
      width={isMobileDevice() ? '29dvw' : '560px'}
      title="주소 검색"
      open={addressModal}
      onClose={onClose}
      footer={
        <Footer
          formik={updateByFormik?.formik ?? undefined}
          fieldName={updateByFormik?.fieldName ?? undefined}
          addressValue={addressValue}
          detailAddress={detailAddress}
          onClose={onClose}
          setIsSaved={setIsSaved}
          isSaved={isSaved}
          onUpdate={updateByDispatch?.onUpdate}
          id={updateByDispatch?.id}
        />
      }
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          height: '100%',
        }}
      >
        <AddressSearchSection
          query={query}
          setQuery={setQuery}
        />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexWrap: 'nowrap',
            padding: '10px',
            border: `1px solid ${ColorPalette._e4e9f2}`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '500px',
              padding: '10px',
            }}
          >
            <Box
              sx={{
                height: '450px',
              }}
            >
              {addressList.length > 0 &&
                addressList.map((item, index) => (
                  <AddressForm
                    key={`${index}_${item.zipNo}`}
                    item={item}
                    boxRef={boxRef}
                    setAddressValue={setAddressValue}
                    index={index}
                  />
                ))}
              {addressList.length === 0 && <DefaultMessage />}
            </Box>
            {addressList.length > 0 && (
              <PaginationSection
                totalPage={totalPage}
                setQuery={setQuery}
              />
            )}
          </Box>
        </Box>
        <DetailAddressInput
          detailAddress={detailAddress}
          setDetailAddress={setDetailAddress}
        />
      </Box>
    </ModalLayout>
  );
};
