import React, { memo, useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import MemoDrawerService from '@front/components/Memo/route/drawer';
import type { RootState } from '@front/services/reducer';
import { campaignAction } from '@front/ost_campaign/action';
import FormMemoService from '@front/ost_campaign/service/memo/form';
import FilterMemoService from '@front/ost_campaign/service/memo/filter';
import ListMemoService from '@front/ost_campaign/service/memo/list';
import { initialCampaignMemoQuery } from '@front/ost_campaign/query';

const MemoCampaignService = () => {
  const dispatch = useDispatch();
  const { isOpenMemo, id } = useSelector((root: RootState) => root.campaign, shallowEqual);

  const setIsOpenMemo = useCallback(
    (isOpen: boolean) => {
      dispatch(campaignAction.setIsOpenMemo(isOpen));
    },
    [dispatch]
  );

  useEffect(() => {
    if (id) {
      dispatch(campaignAction.setFilterMemo(initialCampaignMemoQuery));
    }
  }, [id, dispatch]);

  return (
    <MemoDrawerService
      isOpen={isOpenMemo}
      setDrawer={setIsOpenMemo}
    >
      <FormMemoService />
      <FilterMemoService />
      <ListMemoService />
    </MemoDrawerService>
  );
};

const CampaignMemoService = memo(MemoCampaignService);

export default CampaignMemoService;
