import React, { createRef, useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { Box } from '@mui/material';
import { AffiliatedCompanySelectorWidget } from '@front/src/features/affiliated-company-selector/widgets/widget';
import { AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationUpdateContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/features/update/provider/context';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedCompanyPersonDetailModalOutlineState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/section/outline/useState';

export { AddButton as AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationAddButton };

const AddButton = () => {
  const { onUpdate } = useContext(
    AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationUpdateContext
  );
  const { modalId } = useAffiliatedCompanyPersonState(
    useShallow((state) => ({
      modalId: state.modalId,
    }))
  );
  const inputRef = createRef<HTMLInputElement>();
  const handleButtonClick = useCallback(() => {
    if (!inputRef?.current) return;
    inputRef.current.click();
  }, [inputRef]);
  const handleSelect = useCallback(
    (company) => {
      if (!company) return;
      onUpdate({
        personId: modalId,
        affiliatedCompanyId: company?.id,
      });
    },
    [onUpdate, modalId]
  );
  const { readOnly } = useAffiliatedCompanyPersonDetailModalOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      <Box
        sx={{
          display: 'none',
        }}
      >
        <AffiliatedCompanySelectorWidget
          value={{
            textValue: '',
          }}
          inputRef={inputRef}
          onCompanySelect={handleSelect}
          disabled={readOnly}
        />
      </Box>
      <Button
        onClick={handleButtonClick}
        disabled={readOnly}
      >
        소속회사추가
      </Button>
    </>
  );
};
