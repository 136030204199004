import React from 'react';
import SkeletonUI from '@front/src/components/components-with-design/component/skeleton/Skeleton';
import { Box } from '@mui/material';

export default function NoteCardSkeleton() {
  return (
    <Box
      sx={{
        padding: '15px',
        height: '256px',
      }}
    >
      <SkeletonUI variant="rectangular" />
    </Box>
  );
}
