import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import type { ProposalMemoId } from '@front/ost_proposal/domain';
import { FormikProvider, useFormik } from 'formik';
import type { EvaluationMemoChangeParameter } from '@front/ost_evaluation/parameter';
import { evaluationAction } from '@front/ost_evaluation/action';
import EvaluationMemoList from '@front/ost_evaluation/view/memo/List';

interface Props {
  formHeight: number;
}

const EvaluationListService = ({ formHeight }: Props) => {
  const dispatch = useDispatch();
  const detail = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const memoLoading = useSelector((root: RootState) => root.evaluation.memoLoading, shallowEqual);
  const [memoList] = useSelector((root: RootState) => [root.evaluation.memoList], shallowEqual);
  const onChange = useCallback(
    (params: EvaluationMemoChangeParameter) => dispatch(evaluationAction.changeMemo(params)),
    [dispatch]
  );
  const onDelete = useCallback(
    (id: ProposalMemoId) => dispatch(evaluationAction.deleteMemo(id)),
    [dispatch]
  );
  const formik = useFormik<EvaluationMemoChangeParameter>({
    initialValues: {} as EvaluationMemoChangeParameter,
    onSubmit: (values) => {
      onChange(values);
      formik.setValues({} as EvaluationMemoChangeParameter);
    },
  });
  return (
    <FormikProvider value={formik}>
      <EvaluationMemoList
        list={memoList}
        loading={memoLoading}
        login={detail}
        onChange={onChange}
        onDelete={onDelete}
        formHeight={formHeight}
      />
    </FormikProvider>
  );
};

export default EvaluationListService;
