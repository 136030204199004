import React, { memo, useCallback, useContext } from 'react';
import { Box, Menu, MenuItem } from '@mui/material';
import { useAccountingSettingsManagementAccountingAccountTableState } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/useState';
import { AccountingSettingsManagementAccountingAccountTableCategoryListContext } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/provider/context';
import { ColorPalette } from '@front/assets/theme';
import { useShallow } from 'zustand/react/shallow';

export { ContextMenuWithAttribute as AccountingSettingsManagementAccountingAccountContextMenuWithAttribute };

const ContextMenuWithAttribute = () => {
  const {
    handleChangeName,
    handleDelete,
    handleInsert,
    handleMoveDown,
    handleMoveUp,
    handleClose,
    handleKeyDown,
  } = useContext(AccountingSettingsManagementAccountingAccountTableCategoryListContext);
  const { largeAnchorEl, setLargeAnchorEl, setIsOpen } =
    useAccountingSettingsManagementAccountingAccountTableState(
      useShallow((state) => ({
        largeAnchorEl: state.largeAnchorEl,
        setLargeAnchorEl: state.setLargeAnchorEl,
        setIsOpen: state.setIsOpen,
        targetItem: state.targetItem,
      }))
    );
  const open = Boolean(largeAnchorEl);
  const handleContextMenu = useCallback(
    (e) => {
      e.preventDefault();
      setLargeAnchorEl(null);
    },
    [setLargeAnchorEl]
  );
  const handleModalOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  return (
    <Menu
      anchorEl={largeAnchorEl}
      open={open}
      onClose={handleClose}
      onKeyDown={handleKeyDown}
      onContextMenu={handleContextMenu}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuItem onClick={handleChangeName}>
        <Item
          name="이름 변경"
          short="F2"
        />
      </MenuItem>
      <MenuItem onClick={handleMoveUp}>
        <Item
          name="위로 이동"
          short="CTRL + ALT + &uarr;"
        />
      </MenuItem>
      <MenuItem onClick={handleMoveDown}>
        <Item
          name="아래로 이동"
          short="CTRL + ALT + &darr;"
        />
      </MenuItem>
      <MenuItem onClick={handleInsert}>
        <Item
          name="위에 추가"
          short="CTRL + INS"
        />
      </MenuItem>
      <MenuItem onClick={handleDelete}>
        <Item
          name="삭제"
          short="CTRL + DEL"
        />
      </MenuItem>
      <MenuItem onClick={handleModalOpen}>
        <Item
          name="속성"
          short=""
        />
      </MenuItem>
    </Menu>
  );
};

interface Props {
  name: string;
  short: string;
}

const Item = memo(({ name, short }: Props) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '12px',
      width: '100%',
      fontSize: '12px',
    }}
  >
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {name}
    </Box>
    <Box
      sx={{
        display: 'flex',
        color: ColorPalette._386dd6,
        fontWeight: 'bold',
      }}
    >
      {short}
    </Box>
  </Box>
));

Item.displayName = `AccountingSettingsManagementAccountingAccountContextMenuItem`;
