import React, { useMemo } from 'react';
import { useProjectSalesInfoAffiliatedCompanyWebHardCreateModalLogic } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/create-modal/widgets/useLogic';
import { ProjectSalesInfoAffiliatedCompanyWebHardCreateModalContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/create-modal/widgets/context';
import { ProjectSalesInfoAffiliatedCompanyWebHardCreateModal } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/create-modal/components/modal';
import { Backdrop, CircularProgress } from '@mui/material';

export { Widget as ProjectSalesInfoAffiliatedCompanyWebHardCreateModalWidget };
const Widget = () => {
  const {
    d: { companyList, consortiumCompanyList, webHardList },
    h: { onCreate },
    isLoading,
    isWebHardListLoading,
  } = useProjectSalesInfoAffiliatedCompanyWebHardCreateModalLogic();
  const value = useMemo(
    () => ({
      d: { companyList, webHardList, consortiumCompanyList },
      h: { onCreate },
      isWebHardListLoading,
    }),
    [companyList, webHardList, onCreate, consortiumCompanyList]
  );
  if (isLoading) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return (
    <ProjectSalesInfoAffiliatedCompanyWebHardCreateModalContext.Provider value={value}>
      <ProjectSalesInfoAffiliatedCompanyWebHardCreateModal />
    </ProjectSalesInfoAffiliatedCompanyWebHardCreateModalContext.Provider>
  );
};
