// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.V94nYQeAV8cdJS9a0dwp{display:flex;gap:10px;align-items:center}.LZCJKgG3yjxywy39ScQY{font-size:14px;font-weight:bold}.OlevWZkbCuDxmO0I7ZYw{display:flex;flex-direction:column;justify-content:center;align-items:center;gap:20px}.sdPYN_ZcOmxEzmq_C8Rl{display:flex;justify-content:center;gap:50px;width:300px}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/bid/features/bid-result/features/update-modal/components/modal-table.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,kBAAA,CAGF,sBACE,cAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,sBAAA,CACA,QAAA,CACA,WAAA","sourcesContent":[".container {\n  display: flex;\n  gap: 10px;\n  align-items: center;\n}\n\n.result {\n  font-size: 14px;\n  font-weight: bold;\n}\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 20px;\n}\n\n.btn__container {\n  display: flex;\n  justify-content: center;\n  gap: 50px;\n  width: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `V94nYQeAV8cdJS9a0dwp`,
	"result": `LZCJKgG3yjxywy39ScQY`,
	"wrapper": `OlevWZkbCuDxmO0I7ZYw`,
	"btn__container": `sdPYN_ZcOmxEzmq_C8Rl`
};
export default ___CSS_LOADER_EXPORT___;
