import { default as axios } from '@front/src/config/axios';
import type Page from 'type/Page';
import type { UserQuery, UserWithDepartmentIdQuery } from 'user/query';
import type { AdminRoleNameView, UserId, UserVO } from 'user/domain';
import type {
  UserAddParameter,
  UserChangeParameter,
  UserInviteParameter,
  UserPasswordChangeParameter,
} from 'user/parameter';
import type { UrlValidateParameter } from 'login/parameter';

class UserApi {
  async add(parameter: UserAddParameter): Promise<UserVO> {
    const { ...rest } = parameter;
    const { data } = await axios.post('/api/user', rest);
    return data;
  }

  async authenticateInvitation(parameter: UserInviteParameter): Promise<UserVO> {
    const { email, authKey } = parameter;
    const { data } = await axios.get(`/api/user-verification/user-invitation/authenticate`, {
      params: {
        email,
        authKey,
      },
    });
    return data;
  }

  async getPage(query: UserQuery): Promise<Page<UserVO>> {
    const { data } = await axios.get('/api/admin/user', { params: query });
    return data;
  }

  async getList(keyword?: string): Promise<UserVO[]> {
    const { data } = await axios.get('/api/user', { params: keyword });
    return data;
  }

  async getListByDepartmentId(query: UserWithDepartmentIdQuery): Promise<UserVO[]> {
    const { data } = await axios.get('/api/user', {
      params: query,
    });
    return data;
  }

  async getOne(id: UserId): Promise<UserVO> {
    const { data } = await axios.get(`/api/admin/user/${id}`);
    return data;
  }

  async getRoleList(): Promise<AdminRoleNameView[]> {
    const { data } = await axios.get('/api/admin/admin-role-management/role-list');
    return data;
  }

  async change(parameter: UserChangeParameter): Promise<void> {
    const { id, role, ...rest } = parameter;
    const { data } = await axios.put(`/api/admin/user/${id}`, {
      ...rest,
      roleId: role.id,
    });
    return data;
  }

  async invite(parameter: UserInviteParameter): Promise<void> {
    const { role, ...rest } = parameter;
    const { data } = await axios.post(`/api/user-verification/user-invitation`, {
      ...rest,
      roleId: role.id,
    });
    return data;
  }

  async requestChangePasswordEmail(params: UserPasswordChangeParameter): Promise<void> {
    const { data } = await axios.post(`/api/user-verification/password-reset`, params);
    return data;
  }

  async validateUrlForPasswordReset(params: UrlValidateParameter): Promise<void> {
    const { data } = await axios.get(`/api/user-verification/password-reset/validate`, { params });
    return data;
  }

  async initPassword(id: UserId): Promise<void> {
    const { data } = await axios.post(`/api/admin/user/${id}/init-password`);
    return data;
  }
}

export const userApi = new UserApi();
