import { create } from 'zustand';

export { useState as useMyPageAuthorizationState };

interface State {
  tableRowNum: number;
  clicked: number[];
  tabId?: number;
}

interface Actions {
  setClicked: (clicked: number[]) => void;
  setTabId: (tabId?: number) => void;
}

const useState = create<State & Actions>((set) => ({
  tableRowNum: 20,
  clicked: [],
  setClicked: (clicked) =>
    set({
      clicked,
    }),
  setTabId: (tabId) =>
    set({
      tabId,
    }),
}));
