import React from 'react';
import type { ArrangementAttachedFileParameter } from '@front/ost_proposal/parameter';
import type { ArrangementAttachedFileId } from '@front/ost_proposal/domain';
import { ProposalArrangementInformationStatus, ProposalStatus } from '@front/ost_proposal/domain';
import useDialog from '@front/dialog/hook';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { Box, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Tooltip from '@front/components/Tooltip';
import { fileTypeText } from '@front/util/CommonText';
import Button from '@front/layouts/Button';
import { fileToView } from '@front/file-item';
import { OldTh, Table } from '@front/layouts/Table';
import { ArrangementFileList } from '@front/ost_proposal/view/detail/advisorForm/attached_file/List';
import { ArticleItem } from '@front/src/components/article-item';

interface Props {
  addArrangementAttachedFile: (params: ArrangementAttachedFileParameter) => void;
  deleteArrangementAttachedFile: (id: ArrangementAttachedFileId) => void;
  isAdvisor: boolean;
}

export { Layout as ArrangementAttachedFile };
const Layout = ({
  addArrangementAttachedFile,
  deleteArrangementAttachedFile,
  isAdvisor,
}: Props) => {
  const { confirm } = useDialog();
  const status = useSelector((root: RootState) => root.proposal.detail!.status, shallowEqual);
  const arrangementStatus = useSelector(
    (root: RootState) => root.proposal.detail!.arrangementStatus,
    shallowEqual
  );
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        width: '100%',
        padding: '10px',
      }}
    >
      <ArticleItem
        title="첨부 파일"
        buttonsNode={
          <>
            {status === ProposalStatus.WRITING &&
              isAdvisor &&
              arrangementStatus === ProposalArrangementInformationStatus.ON_GOING && (
                <Tooltip title={fileTypeText}>
                  <Button component="label">
                    파일 선택
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        if (!e.target || !e.target.files || e.target.files.length === 0) {
                          return;
                        }
                        confirm({
                          children: '해당 첨부 파일을 등록하시겠습니까?',
                          confirmText: '확인',
                          afterConfirm: () => {
                            addArrangementAttachedFile({
                              file: fileToView(e.target.files![0]),
                            });
                            e.target.value = '';
                          },
                          afterClose: () => {
                            e.target.value = '';
                          },
                        });
                      }}
                    />
                  </Button>
                </Tooltip>
              )}
          </>
        }
        children={
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <OldTh>날짜</OldTh>
                  <OldTh colSpan={2}>파일명</OldTh>
                </TableRow>
              </TableHead>
              <TableBody>
                <ArrangementFileList
                  deleteArrangementAttachedFile={deleteArrangementAttachedFile}
                  isAdvisor={isAdvisor}
                />
              </TableBody>
            </Table>
          </TableContainer>
        }
      />
    </Box>
  );
};
