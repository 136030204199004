import React, { useContext } from 'react';
import { ProjectSalesInfoSubjectReviewModalExperimentTargetContext } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/experiment-target/widgets/context';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';
import { ProjectSalesInfoSubjectReviewModalExperimentTargetTableRow } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/experiment-target/components/experiment-target-table-row';

export { Detail as ProjectSalesInfoSubjectReviewModalExperimentTargetDetail };

const Detail = () => {
  const { isLoading } = useContext(ProjectSalesInfoSubjectReviewModalExperimentTargetContext);
  if (isLoading) {
    return (
      <TableLoadingSpinner
        colSpan={6}
        height="100px"
      />
    );
  }
  return <ProjectSalesInfoSubjectReviewModalExperimentTargetTableRow />;
};
