import React from 'react';
import { Box, Typography } from '@mui/material';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import { useFormContext } from 'react-hook-form';
import UserSelectorModalFeature from '@front/src/features/user-selector/features/modal';
import useApprovalStore from '@front/src/features/drawer-approval/useState';
import { useShallow } from 'zustand/react/shallow';
import userSelectorRepository from '@front/src/features/user-selector/repository/repository';
import { useCustomDialog } from '@front/src/features/dialog';
import { SX_APPROVAL_SECTION } from '@front/src/features/drawer-approval/components/ApprovalContent';

interface Props {
  index: number;
}

export default function ApprovalReferenceSection() {
  const { watch, setValue, getValues } = useFormContext();
  const selectedList = watch(`request.referenceList`).map((item) => item.userId);
  const { isReferenceUserSelectorModalOpen, setIsReferenceUserSelectorModalOpen } =
    useApprovalStore(
      useShallow((state) => ({
        isReferenceUserSelectorModalOpen: state.isReferenceUserSelectorModalOpen,
        setIsReferenceUserSelectorModalOpen: state.setIsReferenceUserSelectorModalOpen,
      }))
    );
  const { data: allUser } = userSelectorRepository.useGetAllUser();
  const userList = allUser?.filter((user) => selectedList.includes(user.id));
  const { alert } = useCustomDialog();

  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <UserSelectorModalFeature
        value={selectedList}
        open={isReferenceUserSelectorModalOpen}
        multi={true}
        onClose={() => setIsReferenceUserSelectorModalOpen(false)}
        onChange={(value) => {
          if (
            getValues('request.approvalList').find((item) =>
              (value as number[]).includes(item.userId)
            )
          ) {
            alert({
              children: [{ value: '결재자를 참조자로 선택할 수 없습니다.' }],
              title: '선택 에러',
              closeText: '닫기',
            });
            return;
          }
          setValue(
            `request.referenceList`,
            (value as number[])?.map((item, index) => ({
              seq: index + 1,
              userId: item,
            }))
          );
        }}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography sx={{ ...SX_APPROVAL_SECTION }}>참조자</Typography>
        <ButtonBasicUI onClick={() => setIsReferenceUserSelectorModalOpen(true)}>
          추가
        </ButtonBasicUI>
      </Box>
      <Box
        display="flex"
        gap="5px"
      >
        {selectedList.length === 0 && (
          <Typography sx={{ ...SX_REFERENCE_USER_PLACEHOLDER }}>참조자를 추가해주세요</Typography>
        )}
        {userList?.map((item) => (
          <Box key={item.id}>
            <Typography sx={{ ...SX_REFERENCE_USER_ITEM }}>{item.name}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

const SX_REFERENCE_USER_PLACEHOLDER = {
  color: 'var(--design-v-10-light-greyscale-text-quaternary, #BABABA)',
  textAlign: 'center',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
};

const SX_REFERENCE_USER_ITEM = {
  background: 'var(--design-v-10-light-background-bg-02, #EBEEF2)',
  padding: '0 10px',
  borderRadius: '10px',
  fontWeight: 600,
};
