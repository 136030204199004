import React, { Suspense, useCallback, useEffect, useLayoutEffect } from 'react';
import type { AppRoute } from '@front/services/routes';
import { Box, Button } from '@mui/material';
import { isMobileDevice } from '@front/util/PwaUtil';
import { mobileNotificationRepository } from '@front/src/features/mobile-notification/repository';
import { NotificationBadge } from '@front/components/badge/badge';
import { useUserMobileNotificationState } from '@front/user_notification/useState';
import { menuAction } from '@front/menu/action';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { RootState } from '@front/services/reducer';
import { loginAction } from '@front/login/action';
import { closeStatus } from '@front/components/DataFieldProps';
import useDialog from '@front/dialog/hook';
import { MOBILE_DEFAULT_ENUM } from '@front/type/domain';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import globalNavBarRepository from '@front/src/features/global-nav-bar/repository/repository';
import logo_rect_type from '@front/assets/logo_rect_type.webp';

function Element() {
  const { data } = mobileNotificationRepository.useUserNotificationCountGet();
  const dispatch = useDispatch();
  const setIsMobileNotificationModalOpen = useUserMobileNotificationState(
    (state) => state.setIsMobileNotificationModalOpen
  );
  const toggleMenu = useCallback(() => dispatch(menuAction.toggleMenu()), [dispatch]);
  const navigate = useNavigate();
  const { requestLogout } = useSelector((root: RootState) => root.login);
  const onLogout = useCallback(() => dispatch(loginAction.logout()), [dispatch]);
  const { data: menuList } = globalNavBarRepository.useListGet();
  const totalMenuCount = menuList?.reduce((curr, next) => curr + next.count, 0);
  const { confirm } = useDialog();
  const onClick = () => {
    confirm({
      children: '로그아웃하시겠습니까?',
      confirmText: '로그아웃',
      afterConfirm: onLogout,
    });
  };

  useEffect(() => {
    closeStatus(
      requestLogout,
      () => {
        dispatch(loginAction.setDetail(undefined));
        navigate('/login');
      },
      () => {
        dispatch(loginAction.requestLogout('idle'));
      }
    );
  }, [requestLogout]);
  useLayoutEffect(() => {
    const preload = () => {
      const image = new Image();
      image.src = logo_rect_type;
    };
    preload();
  }, []);
  return (
    <Box
      sx={{
        width: '100%',
        height: '100dvh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '25px',
      }}
    >
      {isMobileDevice() && (
        <>
          <NotificationBadge count={data}>
            <Button
              sx={{ width: '200px' }}
              onClick={() => setIsMobileNotificationModalOpen()}
            >
              {MOBILE_DEFAULT_ENUM.NOTIFICATION}
            </Button>
          </NotificationBadge>

          <NotificationBadge>
            <Button
              sx={{ width: '200px' }}
              disabled={true}
            >
              {MOBILE_DEFAULT_ENUM.NOTICE}
            </Button>
          </NotificationBadge>

          <NotificationBadge>
            <Button
              sx={{ width: '200px' }}
              disabled={true}
            >
              {MOBILE_DEFAULT_ENUM.MAIL}
            </Button>
          </NotificationBadge>

          <NotificationBadge count={totalMenuCount}>
            <Button
              sx={{ width: '200px' }}
              onClick={toggleMenu}
            >
              {MOBILE_DEFAULT_ENUM.HAS_WORK}
            </Button>
          </NotificationBadge>

          <NotificationBadge>
            <Button
              sx={{ width: '200px' }}
              disabled={true}
            >
              {MOBILE_DEFAULT_ENUM.COMMUNITY}
            </Button>
          </NotificationBadge>

          <NotificationBadge>
            <Button
              sx={{ width: '200px' }}
              disabled={true}
            >
              {MOBILE_DEFAULT_ENUM.SETTINGS}
            </Button>
          </NotificationBadge>

          <NotificationBadge>
            <Button
              sx={{ width: '200px' }}
              onClick={onClick}
            >
              {MOBILE_DEFAULT_ENUM.LOGOUT}
            </Button>
          </NotificationBadge>
        </>
      )}
      {!isMobileDevice() && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            srcSet={logo_rect_type}
            alt="한양풍동실험연구소 로고"
            decoding="async"
            style={{
              opacity: 0.1,
              width: '328px',
              height: '332px',
            }}
          />
        </Box>
      )}
    </Box>
  );
}

const defaultPageRoute: AppRoute = {
  path: '*',
  element: (
    <Suspense fallback={<LoadingSpinner width="100%" />}>
      <Element />
    </Suspense>
  ),
};

export default defaultPageRoute;
