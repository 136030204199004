import { default as axios } from '@front/src/config/axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import type { IdListDeleteParameter } from '@front/src/types/parameter';

export { api as projectSalesInfoContractReviewApi };
const url = {
  getList: (id?) => `${projectSalesInfoURL}/${id}/review-contract`,
  delete: () => `${projectSalesInfoURL}/review-contract`,
};

const api = {
  getList: async (id?: number) => {
    const { data } = await axios.get(url.getList(id));
    return data;
  },
  delete: async (params: IdListDeleteParameter) => {
    await axios.delete(url.delete(), { data: params });
  },
};
