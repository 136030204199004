// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VmoAhiqSukOcoSeBbVLQ{display:flex;justify-content:space-between}`, "",{"version":3,"sources":["webpack://./front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/components/family-information-table-row.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA","sourcesContent":[".th {\n  display: flex;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"th": `VmoAhiqSukOcoSeBbVLQ`
};
export default ___CSS_LOADER_EXPORT___;
