import React, { useContext, useMemo } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { FormProvider, useForm } from 'react-hook-form';
import {
  convertAmountToStringForForm,
  convertEmptyToNullForAmountParameter,
  convertEmptyToNullForParameter,
  convertNullToEmptyForForm,
  getLocaleStringIfExist,
  getValueIfExist,
} from '@front/src/utils';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { shallow } from 'zustand/shallow';
import { getOptionListFromVariableList } from '@front/src/features/manageable-variable/utils';
import { ProjectSalesInfoContractConfirmedContext } from '@front/src/features/project-sales-info/features/contract/features/confirmed/widgets/context';
import type { ProjectContractConfirmedView } from '@front/src/features/project-sales-info/features/contract/features/confirmed/types/view';
import { useProjectSalesInfoContractConfirmedState } from '@front/src/features/project-sales-info/features/contract/features/confirmed/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { ContractConfirmedTableRow as ProjectSalesInfoContractConfirmedTableRow };

const getValues = (detail?: ProjectContractConfirmedView) => ({
  status: convertNullToEmptyForForm(detail?.status),
  contractStep: convertNullToEmptyForForm(detail?.contractStep),
  unitPrice: convertAmountToStringForForm(detail?.unitPrice),
  schedule: convertNullToEmptyForForm(detail?.schedule),
});

const ContractConfirmedTableRow = () => {
  const {
    d: { detail, statusVariableList, stepVariableList },
    h: { onUpdate },
  } = useContext(ProjectSalesInfoContractConfirmedContext);
  const { id } = useProjectSalesInfoDetailState((state) => ({ id: state.id }), shallow);
  const methods = useForm({
    values: getValues(detail),
  });
  const onSubmit = methods.handleSubmit((data) => {
    onUpdate({
      id,
      status: convertEmptyToNullForParameter(data.status),
      contractStep: convertEmptyToNullForParameter(data.contractStep),
      unitPrice: convertEmptyToNullForAmountParameter(data.unitPrice),
      schedule: convertEmptyToNullForParameter(data.schedule),
    });
  });
  const statusOptionList = useMemo(
    () => getOptionListFromVariableList(statusVariableList),
    [statusVariableList]
  );
  const stepOptionList = useMemo(
    () => getOptionListFromVariableList(stepVariableList),
    [stepVariableList]
  );
  const { readOnly } = useProjectSalesInfoContractConfirmedState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <TableRow>
      <FormProvider {...methods}>
        <OldTd>
          <HookFormSelect
            defaultLabel="선택"
            isDefaultPossible
            height="52px"
            name="status"
            optionList={statusOptionList}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <HookFormSelect
            defaultLabel="선택"
            isDefaultPossible
            name="contractStep"
            optionList={stepOptionList}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>{getValueIfExist(detail?.contractDate)}</OldTd>
        <OldTd>{getValueIfExist(detail?.experimentInformation)}</OldTd>
        <OldTd>{getLocaleStringIfExist(detail?.experimentAmount)}</OldTd>
        <OldTd>{getLocaleStringIfExist(detail?.reviewAmount)}</OldTd>
        {detail?.isCm && <OldTd>{getLocaleStringIfExist(detail?.CMAmount)}</OldTd>}
        {detail?.isCm && <OldTd>{getLocaleStringIfExist(detail?.convertedCMAmount)}</OldTd>}
        <OldTd>{getLocaleStringIfExist(detail?.totalAmount)}</OldTd>
        <OldTd>{getLocaleStringIfExist(detail?.unitPrice)}</OldTd>
        <OldTd>
          <HookFormInput
            name="schedule"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
      </FormProvider>
    </TableRow>
  );
};
