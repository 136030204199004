import { accountingManagementAccountingAccountTableRepository } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/repository/repository';

export { useLogic as useAccountingSettingsManagementAccountingReadCountLogic };

const useLogic = () => {
  const { data: detail } = accountingManagementAccountingAccountTableRepository.useCountGet();
  return {
    detail,
  };
};
