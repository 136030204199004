import type { FocusEvent, KeyboardEvent } from 'react';
import React, { memo, useCallback } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import type { ProposalCategoryAddParameter } from '@front/ost_campaign/parameter';
import ModalAddProposalCategory from '@front/ost_campaign/view/modal/AddProposalCategory';
import useDialog from '@front/dialog/hook';
import { campaignAction } from '@front/ost_campaign/action';
import type { RootState } from '@front/services/reducer';

const ModalAddProposalCategoryService = () => {
  const dispatch = useDispatch();
  const { error } = useDialog();

  const isOpen = useSelector(
    (root: RootState) => root.campaign.addProposalCategoryModalOpen,
    shallowEqual
  );
  const onSubmit = useCallback(
    (params: ProposalCategoryAddParameter) => {
      if (!params.name.trim()) {
        error('제안 구분명을 입력해주세요.');
        formik.resetForm();
        return;
      }
      dispatch(campaignAction.addProposalCategory(params));
    },
    [error, dispatch]
  );
  const formik = useFormik({
    initialValues: {} as ProposalCategoryAddParameter,
    onSubmit: onSubmit,
  });

  const onClose = useCallback(() => {
    dispatch(campaignAction.setAddProposalCategoryModalOpen(false));
  }, [dispatch]);

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    const value = e.target.value || '';
    if (formik.values.name !== value) {
      formik.setFieldValue('name', value);
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key.toLowerCase() === 'enter') {
      const value = (e.target as HTMLInputElement).value || '';
      formik.setFieldValue('name', value);
      formik.handleSubmit();
    }
  };

  return (
    <FormikProvider value={formik}>
      <ModalAddProposalCategory
        formik={formik}
        isOpen={isOpen}
        onClose={onClose}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      />{' '}
    </FormikProvider>
  );
};

const ModalAddCategoryService = memo(ModalAddProposalCategoryService);

export default ModalAddCategoryService;
