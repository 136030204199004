import { useMutation, useQueryClient } from 'react-query';
import type { SalesAccountingAmountInformationParameter } from '@front/src/features/accounting/features/upload/features/sales-info/types/parameter';
import { AccountingUploadSalesInfoApi } from '@front/src/features/accounting/features/upload/features/sales-info/query/api';

export { Mutation as AccountingUploadSalesInfoExpectModalMutation };

const Mutation = {
  useUpdate: () => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: SalesAccountingAmountInformationParameter) =>
        AccountingUploadSalesInfoApi.update(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['accounting', 'upload', 'sales-info', 'list'],
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
};
