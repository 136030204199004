import React from 'react';
import { Box } from '@mui/material';
import TextBox from '@front/layouts/Text';
import { ColorPalette } from '@front/assets/theme';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import dayjs from 'dayjs';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { ProposalStatus } from '@front/ost_proposal/domain';

const onForamt = (date) => {
  if (!date) {
    return '';
  }
  return dayjs(date).format('YYYY-MM-DD');
};

const CampaignInfo = () => {
  const { category, name, startDate, endDate, totalReward, voteStartDate, voteEndDate, status } =
    useSelector(
      (root: RootState) => ({
        status: root.proposal.detail!.status,
        category: root.proposal.detail!.category,
        name: root.proposal.detail!.campaign.name,
        startDate: root.proposal.detail!.campaign.startDate,
        endDate: root.proposal.detail!.campaign.endDate,
        totalReward: root.proposal.detail!.campaign.totalReward,
        voteStartDate: root.proposal.detail!.campaign.voteStartDate,
        voteEndDate: root.proposal.detail!.campaign.voteEndDate,
      }),
      shallowEqual
    );

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        width: '100%',
        padding: '10px',
        maxHeight: '200px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          border: `1px solid ${ColorPalette._e4e9f2}`,
          padding: '10px',
          borderRadius: '5px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '20%',
          }}
        >
          <DataFieldWithLabel
            label="이름"
            labelSX={{
              color: `${ColorPalette._252627}`,
              fontWeight: 'bold',
            }}
          >
            <TextBox
              variant="body9"
              sx={{
                maxWidth: '70%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {name}
            </TextBox>
          </DataFieldWithLabel>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '20%',
          }}
        >
          <DataFieldWithLabel
            label="구분"
            labelSX={{
              color: `${ColorPalette._252627}`,
              fontWeight: 'bold',
            }}
          >
            <TextBox variant="body9">{category.name}</TextBox>
          </DataFieldWithLabel>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '30%',
          }}
        >
          {status === ProposalStatus.VOTING ? (
            <DataFieldWithLabel
              label="투찰기간"
              labelSX={{
                color: `${ColorPalette._252627}`,
                fontWeight: 'bold',
              }}
            >
              {voteStartDate === null && voteEndDate === null && (
                <Box sx={{ width: '100px', display: 'flex', justifyContent: 'center' }}>-</Box>
              )}
              {(voteStartDate !== null || voteEndDate !== null) && (
                <>
                  <TextBox variant="body9">{onForamt(voteStartDate)}</TextBox>
                  <TextBox
                    variant="body9"
                    sx={{ marginInline: '10px' }}
                  >
                    {(voteStartDate || voteEndDate) && '~'}
                  </TextBox>
                  <TextBox variant="body9">{onForamt(voteEndDate)}</TextBox>
                </>
              )}
            </DataFieldWithLabel>
          ) : (
            <DataFieldWithLabel
              label="접수기간"
              labelSX={{
                color: `${ColorPalette._252627}`,
                fontWeight: 'bold',
              }}
            >
              <TextBox variant="body9">{onForamt(startDate)}</TextBox>
              <TextBox
                variant="body9"
                sx={{ marginInline: '10px' }}
              >
                ~
              </TextBox>
              <TextBox variant="body9">{onForamt(endDate)}</TextBox>
            </DataFieldWithLabel>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '30%',
          }}
        >
          <DataFieldWithLabel
            label="총액"
            labelSX={{
              color: `${ColorPalette._252627}`,
              fontWeight: 'bold',
            }}
          >
            <TextBox variant="body9">
              {`${totalReward ? totalReward.toLocaleString() : 0} 원`}
            </TextBox>
          </DataFieldWithLabel>
        </Box>
      </Box>
    </Box>
  );
};

export default CampaignInfo;
