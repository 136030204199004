import { create } from 'zustand';
import type { AuthUpdateDto } from '@front/src/features/admin/features/access-authorization-settings/type/parameter';

interface State {
  keyword: string;
  setKeyword: (keyword: string) => void;
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
  editId: number;
  setEditId: (editId: number) => void;
  coloredId: number;
  setColoredId: (coloredId: number) => void;
  updateList: AuthUpdateDto[];
  setUpdateList: (updateList: AuthUpdateDto[]) => void;
}

export { useState as usePersonalSettingsModalDeptSettingsState };

const useState = create<State>((set) => ({
  keyword: '',
  setKeyword: (keyword: string) =>
    set(() => ({
      keyword,
    })),
  isDirty: false,
  setIsDirty: (isDirty: boolean) =>
    set(() => ({
      isDirty,
    })),
  editId: 0,
  setEditId: (editId: number) =>
    set(() => ({
      editId,
    })),
  coloredId: 0,
  setColoredId: (coloredId: number) =>
    set(() => ({
      coloredId,
    })),
  updateList: [],
  setUpdateList: (updateList: AuthUpdateDto[]) => set(() => ({ updateList })),
}));
