import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { AccountingSettingsManagementAccountingAccountTableHeaderContext } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/table-header/provider/context';
import { useAccountingSettingsManagementAccountingAccountTableHeaderLogic } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/table-header/provider/useLogic';

export { Provider as AccountingSettingsManagementAccountingAccountTableHeaderProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { detail, isLoading } = useAccountingSettingsManagementAccountingAccountTableHeaderLogic();
  const value = useMemo(
    () => ({
      detail,
      isLoading,
    }),
    [detail, isLoading]
  );
  return (
    <AccountingSettingsManagementAccountingAccountTableHeaderContext.Provider value={value}>
      {children}
    </AccountingSettingsManagementAccountingAccountTableHeaderContext.Provider>
  );
};
