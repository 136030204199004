import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { projectSalesInfoCMSaveHistoryRepository } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/repository';

export { useLogic as useProjectSalesInfoCMSaveHistoryLogic };
const useLogic = () => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { data: CMSaveHistoryList, isLoading } =
    projectSalesInfoCMSaveHistoryRepository.useCMSaveHistoryGet(id!);
  const { run: onUpdate } = projectSalesInfoCMSaveHistoryRepository.useCMSaveHistoryUpdate(id!);
  return {
    d: {
      CMSaveHistoryList,
    },
    h: {
      onUpdate,
    },
    isLoading,
  };
};
