// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d0tuTQdFJh5b88Uoszqw{width:100%;max-height:80dvh;height:72dvh}`, "",{"version":3,"sources":["webpack://./front/src/features/affiliated-company/components/layout/modal-with-tab/layout.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,gBAAA,CACA,YAAA","sourcesContent":[".container {\n  width: 100%;\n  max-height: 80dvh;\n  height: 72dvh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `d0tuTQdFJh5b88Uoszqw`
};
export default ___CSS_LOADER_EXPORT___;
