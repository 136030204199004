import React, { useCallback, useContext } from 'react';
import { TableRow } from '@mui/material';
import classes from '@front/src/features/project-selector-modal/components/result-table-body.module.scss';
import { OldTd } from '@front/layouts/Table';
import { ProjectSelectorModalBodyContext } from '@front/src/features/project-selector-modal/widgets/body/context';
import { getCodeIfExist } from '@front/src/features/project-selector-modal/utils';
import { NoResult } from '@front/src/components/layout/table/no-result';

export { ResultTableBody as ProjectSelectorModalBodyResultTableBody };

const ResultTableBody = () => {
  const {
    d: { projectList },
    h: { onSelect },
  } = useContext(ProjectSelectorModalBodyContext);
  const handleSelect = useCallback(
    (project) => {
      onSelect(project);
    },
    [onSelect]
  );
  if (projectList?.length === 0) {
    return <NoResult colSpan={3} />;
  }
  return (
    <>
      {projectList?.map((item, index) => (
        <TableRow
          key={item.id}
          hover
          onClick={() => handleSelect(item)}
          className={classes.row}
          aria-hidden
        >
          <OldTd>{index + 1}</OldTd>
          <OldTd>{getCodeIfExist(item.nickName)}</OldTd>
          <OldTd>{getCodeIfExist(item.code)}</OldTd>
        </TableRow>
      ))}
    </>
  );
};
