import React from 'react';
import ButtonIconUI from '@front/src/components/components-with-design/component/ButtonIconUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useModalOpen from '@front/src/hooks/useModalOpen';
import WorkImageZoomModal from '@front/src/features/work/features/work/components/ZoomImageModal';
import type { FileItemView } from '@front/file-item';
import type { UserNameView } from '@front/src/types';

export interface CommonImageView {
  fileItem: FileItemView;
  user: UserNameView;
  createdAt: Date;
}

interface Props<T> {
  item: T;
}

export default function WorkZoomImageButton<T extends CommonImageView>({
  item,
}: Readonly<Props<T>>) {
  const { onOpen, open, onClose } = useModalOpen();
  return (
    <>
      <WorkImageZoomModal<T>
        open={open}
        onClose={onClose}
        item={item}
      />
      <ButtonIconUI
        shape="tertiary"
        size="medium"
        onClick={onOpen}
      >
        <FontAwesomeIcon icon="magnifying-glass" />
      </ButtonIconUI>
    </>
  );
}
