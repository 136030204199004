import React, { Suspense, useEffect } from 'react';
import { useGetReadOnly } from '@front/src/hooks/useGetReadOnly';
import { useAccountingSettingsBankAccountState } from '@front/src/features/accounting/features/settings/features/bank-account/useState';
import { useShallow } from 'zustand/react/shallow';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { AccountingSettingsBankAccountListProvider } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/list/provider/provider';
import { AccountingSettingsBankAccountListHeader } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/list/components/header';
import { AccountingSettingsBankAccountListTable } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/list/components/table';
import { accountingSettingsContentLayout } from '@front/src/features/accounting/features/settings/components/layouts/content';

interface Props extends SectionComponentProps {}

const AccountingSettingsBankAccountFeature = ({ name }: Readonly<Props>) => {
  const { Layout, Header, Body } = accountingSettingsContentLayout;
  const { readOnly } = useGetReadOnly();
  const { setReadOnly } = useAccountingSettingsBankAccountState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <Suspense fallback={<LoadingSpinner height="600px" />}>
      <AccountingSettingsBankAccountListProvider>
        <Layout>
          <Header
            title={name}
            rightComponent={<AccountingSettingsBankAccountListHeader />}
          />
          <Body>
            <AccountingSettingsBankAccountListTable />
          </Body>
        </Layout>
      </AccountingSettingsBankAccountListProvider>
    </Suspense>
  );
};

export default AccountingSettingsBankAccountFeature;
