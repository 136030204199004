import React, { useCallback } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { AccountingUploadSalesInfoExpectModalBody } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/components/body';
import { useAccountingUploadSalesInfoExpectModalState } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/useState';
import { useShallow } from 'zustand/react/shallow';
import { AccountingUploadSalesInfoExpectModalListWidget } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/widgets/list/widget';

export { Modal as AccountingUploadSalesInfoExpectModal };

const Modal = () => {
  const { setIsOpen, isOpen } = useAccountingUploadSalesInfoExpectModalState(
    useShallow((state) => ({
      setIsOpen: state.setIsOpen,
      isOpen: state.isOpen,
    }))
  );
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  return (
    <ModalLayout
      title="예상 등록"
      open={isOpen}
      onClose={handleClose}
      width="72dvw"
      height="636px"
    >
      <AccountingUploadSalesInfoExpectModalBody>
        <AccountingUploadSalesInfoExpectModalListWidget />
      </AccountingUploadSalesInfoExpectModalBody>
    </ModalLayout>
  );
};
