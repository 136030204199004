import React, { Suspense } from 'react';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { PersonalSettingsModalDeptSettings } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/dept-settings/components/dept-settings';
import { PersonalSettingsModalDeptSettingsProvider } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/dept-settings/provider/provider';

export { Widget as PersonalSettingsModalDeptSettingsWidget };
interface Props {
  authMenuId: number | undefined;
}
const Widget = ({ authMenuId }: Props) => (
  <Suspense fallback={<LoadingSpinner />}>
    <PersonalSettingsModalDeptSettingsProvider authMenuId={authMenuId}>
      <PersonalSettingsModalDeptSettings />
    </PersonalSettingsModalDeptSettingsProvider>
  </Suspense>
);
