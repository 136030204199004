import React from 'react';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/detail-modal/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalWidget from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/detail-modal/widgets/widget';

const ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalWrapper = () => {
  const { isOpen } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalState(
    useShallow((state) => ({
      isOpen: state.isOpen,
    }))
  );
  if (!isOpen) {
    return <></>;
  }
  return <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalWidget />;
};

export default ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalWrapper;
