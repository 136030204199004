import { createContext } from 'react';
import type { ProjectShortView } from '@front/src/features/project-selector-modal/types/view';
import { noOp } from '@front/src/utils';

export { Context as ProjectSelectorModalBodyContext };

interface State {
  d: { projectList?: ProjectShortView[] };
  h: {
    onSelect: (project) => void;
  };
}

const Context = createContext<State>({
  d: {},
  h: {
    onSelect: noOp,
  },
});
