import { create } from 'zustand';
import type { AffiliatedPersonMemoListGetParameter } from '@front/src/features/affiliated-person/features/memo/types/parameter';
import { defaultSearchParameter } from '@front/src/features/affiliated-person/features/memo/utils';

export { useState as useAffiliatedPersonMemoState };

interface State {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  formHeight: number;
  setFormHeight: (formHeight: number) => void;
  searchParameter: AffiliatedPersonMemoListGetParameter;
  setSearchParameter: (searchParameter: AffiliatedPersonMemoListGetParameter) => void;
  id?: number;
  setId: (id?: number) => void;
}

const useState = create<State>((set) => ({
  isOpen: true,
  setIsOpen: (isOpen) =>
    set(() => ({
      isOpen,
    })),
  formHeight: 0,
  setFormHeight: (formHeight) => set(() => ({ formHeight })),
  searchParameter: defaultSearchParameter,
  setSearchParameter: (searchParameter) => set(() => ({ searchParameter })),
  setId: (id) => set(() => ({ id })),
}));
