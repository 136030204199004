import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/widgets/context';
import { useFormContext } from 'react-hook-form';
import classes from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/components/button-group.module.scss';
import { shallow } from 'zustand/shallow';
import {
  convertEmptyToNullForNumberParameter,
  convertEmptyToNullForParameter,
} from '@front/src/utils';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/widgets/useState';

export { ButtonGroup as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalButtonGroup };
const ButtonGroup = () => {
  const {
    h: { onUpdate, onCreate },
  } = useContext(ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalContext);
  const { handleSubmit } = useFormContext();
  const { setIsOpen, id } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      id: state.id,
    }),
    shallow
  );
  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  const handleCancelClick = useCallback(() => {
    onClose();
  }, [onClose]);
  const onSubmit = handleSubmit((data) => {
    const dataForParameter = {
      buildingCompanyId: convertEmptyToNullForNumberParameter(data.buildingCompanyId),
      structuralDesignCompanyId: convertEmptyToNullForNumberParameter(
        data.structuralDesignCompanyId
      ),
      implementingCompanyId: convertEmptyToNullForNumberParameter(data.implementingCompanyId),
      constructingCompanyId: convertEmptyToNullForNumberParameter(data.constructingCompanyId),
      recommenderId: convertEmptyToNullForNumberParameter(data.recommenderId),
      clientInContractId: convertEmptyToNullForNumberParameter(data.clientInContractId),
      clientId: convertEmptyToNullForNumberParameter(data.clientId),
      etcId: convertEmptyToNullForNumberParameter(data.etcId),
      note: convertEmptyToNullForParameter(data.note),
    };
    if (typeof id === 'undefined') {
      onCreate(dataForParameter);
      return;
    }
    onUpdate(dataForParameter);
  });
  return (
    <div className={classes.container}>
      <Button
        shape="basic2"
        onClick={handleCancelClick}
      >
        취소
      </Button>
      <Button onClick={onSubmit}>저장</Button>
    </div>
  );
};
