import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import type { VoteMemoChangeParameter } from '@front/ost_vote/parameter';
import type { ProposalMemoId } from '@front/ost_proposal/domain';
import { FormikProvider, useFormik } from 'formik';
import { voteAction } from '@front/ost_vote/action';
import VoteMemoList from '@front/ost_vote/view/memo/List';

interface Props {
  formHeight: number;
}

const VoteListService = ({ formHeight }: Props) => {
  const dispatch = useDispatch();
  const detail = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const memoLoading = useSelector((root: RootState) => root.vote.memoLoading, shallowEqual);
  const [memoList] = useSelector((root: RootState) => [root.vote.memoList], shallowEqual);
  const onChange = useCallback(
    (params: VoteMemoChangeParameter) => dispatch(voteAction.changeMemo(params)),
    [dispatch]
  );
  const onDelete = useCallback(
    (id: ProposalMemoId) => dispatch(voteAction.deleteMemo(id)),
    [dispatch]
  );
  const formik = useFormik<VoteMemoChangeParameter>({
    initialValues: {} as VoteMemoChangeParameter,
    onSubmit: (values) => {
      onChange(values);
      formik.setValues({} as VoteMemoChangeParameter);
    },
  });
  return (
    <FormikProvider value={formik}>
      <VoteMemoList
        list={memoList}
        loading={memoLoading}
        login={detail}
        onChange={onChange}
        onDelete={onDelete}
        formHeight={formHeight}
      />
    </FormikProvider>
  );
};

export default VoteListService;
