import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import type { DefaultFunction } from '@front/src/types';
import type { AffiliatedCompanyPersonCreateParameter } from '@front/src/features/affiliated-company/features/person/types/parameter';
import type { AffiliatedCompanyPersonShortView } from '@front/src/features/affiliated-company/features/person/types/view';

export { Context as AffiliatedCompanyPersonContext };

interface State {
  d: {
    personList?: AffiliatedCompanyPersonShortView[];
  };
  h: {
    onCreatePerson: (params: AffiliatedCompanyPersonCreateParameter) => void;
    onCreateModalClose: (callback?) => void;
    onCreateModalOpen: DefaultFunction;
    onDetailModalOpen: (modalId: number) => void;
  };
  isLoading: boolean;
  isCreateModalOpen: boolean;
  isDetailModalOpen: boolean;
}

const Context = createContext<State>({
  d: {
    personList: [],
  },
  h: {
    onCreatePerson: noOp,
    onCreateModalClose: noOp,
    onCreateModalOpen: noOp,
    onDetailModalOpen: noOp,
  },
  isLoading: false,
  isCreateModalOpen: false,
  isDetailModalOpen: false,
});
