import React, { memo } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { ProjectSalesInfoEstimationCompetitorTableBody } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/components/estimation-competitor-table-body';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';

export { EstimationCompetitorTable as ProjectSalesInfoEstimationCompetitorTable };

const EstimationCompetitorTable = () => (
  <TableContainer
    sx={{
      ...useGetMaxWidthSx({ hasMemo: true }),
      maxHeight: '400px',
      overflow: 'auto',
    }}
  >
    <Table>
      <TableHeader />
      <TableBody>
        <ProjectSalesInfoEstimationCompetitorTableBody />
      </TableBody>
    </Table>
  </TableContainer>
);

const TableHeader = memo(() => (
  <TableHead>
    <TableRow>
      <Th width="40px" />
      <Th width="80px">순번</Th>
      <Th width="200px">경쟁업체명</Th>
      <Th width="200px">의뢰처</Th>
      <Th width="200px">실험정보</Th>
      <Th width="200px">풍동금액</Th>
      <Th width="200px">구검액</Th>
      <Th width="200px">총액</Th>
      <Th width="200px">순동단가</Th>
      <Th width="100px">일정</Th>
      <Th width="200px">PDF파일</Th>
      <Th width="140px">등록일시</Th>
      <Th width="100px">등록자</Th>
      <Th width="200px">비고</Th>
    </TableRow>
  </TableHead>
));

TableHeader.displayName = 'ProjectSalesInfoEstimationCompetitorTableHeader';
