import React from 'react';
import { AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationAddButton } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/features/update/components/add-button';
import { AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationUpdateProvider } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/features/update/provider/provider';
import { AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationRemoveButton } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/features/update/components/remove-button';

export { Widget as AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationUpdateWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationUpdateProvider
    menuId={menuId}
  >
    <AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationRemoveButton />
    <AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationAddButton />
  </AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationUpdateProvider>
);
