import { useQuery } from 'react-query';
import { affiliatedCompanyCareerApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/query/api';
import type { AffiliatedCompanyPersonOutlineCareerView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/types/view';

export const affiliatedCompanyCareerQuery = {
  usePersonOutlineCareerListGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyPersonOutlineCareerView[]>({
      queryKey: ['affiliated-company', 'person', 'outline', 'career', id],
      queryFn: () => affiliatedCompanyCareerApi.getPersonOutlineCareerList(id, menuId),
      enabled: !!id,
      cacheTime: 0,
    });
    return {
      data,
      isLoading,
    };
  },
};
