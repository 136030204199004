import React from 'react';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';

export { Help as PersonalSettingsModalUISettingsHeaderSettingsHelp };

const Help = () => (
  <Box
    sx={{
      p: '12px',
      backgroundColor: ColorPalette._57EF04FF,
      color: ColorPalette._ffffff,
      fontSize: '13px',
    }}
  >
    수정 저장 시, 해당 헤더명이 보여지는 모든 테이블이 수정됩니다.
  </Box>
);
