import React, { useContext } from 'react';
import Button from '@front/layouts/Button';
import { useFormContext } from 'react-hook-form';
import classes from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/update-modal/components/modal-table.module.scss';
import { useProjectSalesInfoBidResultState } from '@front/src/features/project-sales-info/features/bid/features/bid-result/widgets/useState';
import { ProjectSalesInfoBidResultUpdateModalContext } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/update-modal/widgets/context';
import type { ProjectSalesInfoBidResultParams } from '@front/src/features/project-sales-info/features/bid/features/bid-result/types/parameter';
import { convertBidResultForParameter } from '@front/src/features/project-sales-info/features/bid/features/bid-information/utils/constants';
import { useShallow } from 'zustand/react/shallow';

export { ModalBtnGroup as ProjectSalesInfoBidResultUpdateModalBtnGroup };

const ModalBtnGroup = () => {
  const setIsUpdateModalOpen = useProjectSalesInfoBidResultState(
    (state) => state.setIsUpdateModalOpen
  );
  const {
    h: { onUpdate },
  } = useContext(ProjectSalesInfoBidResultUpdateModalContext);
  const { handleSubmit } = useFormContext<ProjectSalesInfoBidResultParams>();
  const onSubmit = handleSubmit((data) => {
    onUpdate(convertBidResultForParameter(data));
    setIsUpdateModalOpen();
  });
  const { readOnly } = useProjectSalesInfoBidResultState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <div className={classes.btn__container}>
      <Button
        onClick={() => setIsUpdateModalOpen()}
        fullWidth={true}
        shape="basic2"
      >
        취소
      </Button>
      <Button
        fullWidth={true}
        onClick={onSubmit}
        disabled={readOnly}
      >
        수정
      </Button>
    </div>
  );
};
