import { default as axios } from '@front/src/config/axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import type { ProjectAspectRatioExaminationParameter } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/types/parameter';

const url = {
  getList: (id?) => `${projectSalesInfoURL}/${id}/aspect-ratio-examination`,
  create: (id?) => `${projectSalesInfoURL}/${id}/aspect-ratio-examination`,
  update: (id?) => `${projectSalesInfoURL}/aspect-ratio-examination/${id}`,
};

export const projectSalesInfoSubjectReviewShapeRatioApi = {
  getList: async (id?: number) => {
    const { data } = await axios.get(url.getList(id));
    return data;
  },
  create: async (id: number) => {
    const { data } = await axios.post(url.create(id));
    return data;
  },
  update: async (params: ProjectAspectRatioExaminationParameter) => {
    await axios.put(url.update(params.id), params);
  },
};
