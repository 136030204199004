import React from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import workIndicatorMutation from '@front/src/features/work/features/work/features/creator/features/indicator/query/mutation';
import WorkIndicatorTableBody from '@front/src/features/work/features/work/features/creator/features/indicator/components/TableBody';

export default function WorkIndicatorTable({
  maxWidth,
  menuId,
  ...rest
}: Readonly<SectionComponentProps>) {
  const { isLoading, onUpdate } = useMutationLogic(menuId);
  const rowActions = {
    onUpdate,
  };
  return (
    <UiBuilderTable
      isLoading={isLoading}
      rowActions={rowActions}
      BodyComponent={WorkIndicatorTableBody}
      menuId={menuId}
      // sx={{
      //   maxWidth,
      // }}
      {...rest}
    />
  );
}

function useMutationLogic(menuId) {
  const { mutate: onUpdate, isLoading } = workIndicatorMutation.useUpdate(menuId);
  return {
    isLoading,
    onUpdate,
  };
}
