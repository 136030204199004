import { create } from 'zustand';

interface State {
  readOnly?: boolean;
  setReadOnly: (readOnly?: boolean) => void;
}

export { useState as useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyState };

const useState = create<State>((set) => ({
  readOnly: false,
  setReadOnly: (readOnly) => set({ readOnly }),
}));
