import { createContext } from 'react';

interface State {
  onDeleteAll?: () => void;
  onToggleAll?: () => void;
}

export { Context as MobileNotificationHeaderContext };

const Context = createContext<State>({});
