import React from 'react';
import { useProjectSalesInfoSubjectReviewModalState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/useState';
import { shallow } from 'zustand/shallow';
import { ProjectSalesInfoSubjectReviewModalFeature } from '@front/src/features/project-sales-info/features/subject-review/features/modal';

export { ModalWrapper as ProjectSalesInfoSubjectReviewModalWrapper };

const ModalWrapper = () => {
  const { isOpen } = useProjectSalesInfoSubjectReviewModalState(
    (state) => ({
      isOpen: state.isOpen,
    }),
    shallow
  );
  if (!isOpen) {
    return <></>;
  }
  return <ProjectSalesInfoSubjectReviewModalFeature />;
};
