import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Box, Collapse } from '@mui/material';
import { AffiliatedCompanyProjectTd } from '@front/src/features/affiliated-company/features/project/widgets/list/components/td';
import { getLocaleStringIfExist, getValueIfExist, openNewTabFromUrl } from '@front/src/utils';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { AffiliatedCompanyProjectListContext } from '@front/src/features/affiliated-company/features/project/widgets/list/provider/context';
import { ColorPalette } from '@front/assets/theme';

export { ListItem as AffiliatedCompanyProjectListItem };

interface Props {
  countList: number[];
  prefixSumList?: number[];
  index: number;
}

const ListItem = ({ prefixSumList, index, countList }: Props) => {
  const { list } = useContext(AffiliatedCompanyProjectListContext);
  const item = list?.[prefixSumList![index]];
  const [open, setOpen] = useState(false);
  const isPossibleClick = useMemo(
    () => prefixSumList![index + 1] - prefixSumList![index] > 1,
    [prefixSumList, index]
  );
  const handlePersonNameClick = useCallback(() => {
    if (!isPossibleClick) return;
    setOpen(!open);
  }, [isPossibleClick, open, setOpen]);
  const isPossibleClickStyle = useMemo(
    () => ({
      cursor: isPossibleClick ? 'pointer' : 'default',
    }),
    [isPossibleClick]
  );
  const handleCodeClick = useCallback((id) => {
    openNewTabFromUrl(`/project/sales-info/${id}/abstract`);
  }, []);
  const renderTd = useCallback(
    (width, value) => (
      <AffiliatedCompanyProjectTd width={width}>
        {getValueIfExist(value)}
      </AffiliatedCompanyProjectTd>
    ),
    []
  );
  const renderAmountTd = useCallback(
    (width, value) => (
      <AffiliatedCompanyProjectTd width={width}>
        {getLocaleStringIfExist(value)}
      </AffiliatedCompanyProjectTd>
    ),
    []
  );
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          minHeight: '48px',
          height: '100%',
        }}
      >
        {renderTd('120px', item?.estimationStatus)}
        {renderTd('144px', item?.contractStatus)}
        <AffiliatedCompanyProjectTd
          width="140px"
          onClick={() => handleCodeClick(item?.projectId)}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
              color: ColorPalette._386dd6,
              cursor: 'pointer',
            },
          }}
        >
          {getValueIfExist(item?.projectCode)}
        </AffiliatedCompanyProjectTd>
        {renderTd('200px', item?.projectNickName)}
        {renderTd('160px', item?.experimentName)}
        {renderTd('160px', item?.companyCategory)}
        <AffiliatedCompanyProjectTd
          width="100px"
          onClick={handlePersonNameClick}
          sx={isPossibleClickStyle}
        >
          {getValueIfExist(item?.personName)}
          {isPossibleClick && (open ? <ExpandLess /> : <ExpandMore />)}
        </AffiliatedCompanyProjectTd>
        {renderTd('160px', item?.contribution)}
        {renderAmountTd('180px', item?.estimationTotalAmount)}
        {renderAmountTd('180px', item?.contractTotalAmount)}
        {renderAmountTd('180px', item?.estimationExperimentAmount)}
        {renderAmountTd('180px', item?.contractExperimentAmount)}
        {renderAmountTd('180px', item?.collectionAmount)}
        {renderAmountTd('180px', item?.unpaidAmount)}
        {renderTd('188px', item?.contractStep)}
        {renderTd('200px', item?.contractEarningsStep)}
        {renderTd('360px', item?.orderCompanyName)}
      </Box>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
      >
        {Array.from({ length: countList[index + 1] - 1 }).map((_, idx) => {
          const num = prefixSumList![index] + 1 + idx;
          return (
            <Box
              key={num}
              sx={{
                display: 'flex',
                minHeight: '48px',
              }}
            >
              {renderTd('120px', list?.[num].estimationStatus)}
              {renderTd('144px', list?.[num].contractStatus)}
              <AffiliatedCompanyProjectTd
                width="140px"
                onClick={() => handleCodeClick(list?.[num].projectId)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                    color: ColorPalette._386dd6,
                    cursor: 'pointer',
                  },
                }}
              >
                {getValueIfExist(list?.[num].projectCode)}
              </AffiliatedCompanyProjectTd>
              {renderTd('200px', list?.[num].projectNickName)}
              {renderTd('160px', list?.[num].experimentName)}
              {renderTd('160px', list?.[num].companyCategory)}
              {renderTd('100px', list?.[num].personName)}
              {renderTd('160px', list?.[num].contribution)}
              {renderAmountTd('180px', list?.[num].estimationTotalAmount)}
              {renderAmountTd('180px', list?.[num].contractTotalAmount)}
              {renderAmountTd('180px', list?.[num].estimationExperimentAmount)}
              {renderAmountTd('180px', list?.[num].contractExperimentAmount)}
              {renderAmountTd('180px', list?.[num].collectionAmount)}
              {renderAmountTd('180px', list?.[num].unpaidAmount)}
              {renderTd('188px', list?.[num].contractStep)}
              {renderTd('200px', list?.[num].contractEarningsStep)}
              {renderTd('360px', list?.[num].orderCompanyName)}
            </Box>
          );
        })}
      </Collapse>
    </Box>
  );
};
