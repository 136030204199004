import { createContext } from 'react';
import type { SmProjectBriefCollectionView } from '@front/src/features/project-sales-info/features/abstract/features/collection/types/view';

interface State {
  list?: SmProjectBriefCollectionView[];
}

export { Context as ProjectSalesInfoAbstractCollectionListContext };

const Context = createContext<State>({});
