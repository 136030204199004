import React from 'react';
import PageLayout from '@front/layouts/PageLayout';
import PersonnelContainerTab from '@front/personnel/view/Container/Tab';
import ListButton from '@front/personnel/view/Container/ListButton';

interface Props {
  children: React.ReactNode;
}

const PersonnelContainer = ({ children }: Props) => (
  <PageLayout
    title="인사카드"
    titleRightComponent={<ListButton />}
    filter={<PersonnelContainerTab />}
    body={children}
  />
);

export default PersonnelContainer;
