import { menuPath } from '@front/src/utils';
import type { MenuView } from '@front/src/types';
import type { AxiosResponse } from 'axios';
import axios from 'axios';

const url = {
  getMenuList: () => `${menuPath}`,
};

const globalNavBarApi = {
  getMenuList: async <T = MenuView[]>() => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getMenuList());
    return data;
  },
};

export default globalNavBarApi;
