import type { ReactNode } from 'react';
import React, { useCallback } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Box } from '@mui/material';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import UserSelector from '@front/components/UserSelector';
import { useAffiliatedPersonMemoState } from '@front/src/features/affiliated-person/features/memo/useState';
import { useShallow } from 'zustand/react/shallow';
import type { AffiliatedPersonMemoCreateFormType } from '@front/src/features/affiliated-person/features/memo/types';
import type { UserId } from '@front/user/domain';

export { CreateForm as AffiliatedPersonMemoCreateForm };

interface Props {
  widget: ReactNode;
}

const CreateForm = ({ widget }: Props) => {
  const { setFormHeight } = useAffiliatedPersonMemoState(
    useShallow((state) => ({
      setFormHeight: state.setFormHeight,
    }))
  );
  const methods = useForm<AffiliatedPersonMemoCreateFormType>({
    defaultValues: {
      description: '',
      attendanceList: [],
    },
  });
  const { control, setValue } = methods;
  const attendanceList = useWatch({ name: 'attendanceList', control });
  const handleUserSelectorChange = useCallback(
    (value) => {
      if (typeof value === 'undefined') return;
      setValue('attendanceList', [...value]);
    },
    [setValue]
  );
  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Box
          sx={{
            padding: '4px 10px',
          }}
        >
          <HookFormInput
            multiline
            name="description"
            placeholder="메모 입력"
          />
        </Box>
        <Box
          sx={{
            padding: '4px 10px',
          }}
        >
          <UserSelector
            multi
            value={attendanceList as UserId[]}
            setFormHeight={setFormHeight}
            onChange={handleUserSelectorChange}
          />
        </Box>
        <Box
          sx={{
            padding: '4px 10px',
          }}
        >
          {widget}
        </Box>
      </Box>
    </FormProvider>
  );
};
