import React, { useEffect, useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { AffiliatedCompanyMeetingHistoryButton } from '@front/src/features/affiliated-company/features/meeting-history/components/meeting-history-button';
import { AffiliatedCompanyMeetingHistoryTable } from '@front/src/features/affiliated-company/features/meeting-history/components/meeting-history-table';
import { useAffiliatedCompanyMeetingHistoryLogic } from '@front/src/features/affiliated-company/features/meeting-history/widgets/useLogic';
import { AffiliatedCompanyMeetingHistoryContext } from '@front/src/features/affiliated-company/features/meeting-history/widgets/context';
import { AffiliatedCompanyMeetingHistoryModalWidget } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/widgets/widget';
import { useShallow } from 'zustand/react/shallow';
import { useGetReadOnly } from '@front/src/hooks/useGetReadOnly';
import { useAffiliatedCompanyMeetingHistoryState } from '@front/src/features/affiliated-company/features/meeting-history/widgets/useState';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AffiliatedCompanyMeetingHistoryWidget = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    d: { meetingList },
    h: { onUpdateModalOpen, onCreateModalOpen, setIdList, onMeetingHistoryDelete },
    idList,
    isLoading,
  } = useAffiliatedCompanyMeetingHistoryLogic(menuId);
  const value = useMemo(
    () => ({
      d: { meetingList },
      h: { onUpdateModalOpen, onCreateModalOpen, setIdList, onMeetingHistoryDelete },
      idList,
      isLoading,
    }),
    [
      meetingList,
      isLoading,
      onUpdateModalOpen,
      setIdList,
      onMeetingHistoryDelete,
      idList,
      onCreateModalOpen,
    ]
  );
  const { setReadOnly } = useAffiliatedCompanyMeetingHistoryState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  const { readOnly } = useGetReadOnly();
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <AffiliatedCompanyMeetingHistoryContext.Provider value={value}>
      <AffiliatedCompanyMeetingHistoryModalWidget menuId={menuId} />
      <Layout>
        <Header title={name}>
          <AffiliatedCompanyMeetingHistoryButton />
        </Header>
        <Body>
          <AffiliatedCompanyMeetingHistoryTable />
        </Body>
      </Layout>
    </AffiliatedCompanyMeetingHistoryContext.Provider>
  );
};

export default AffiliatedCompanyMeetingHistoryWidget;
