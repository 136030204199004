import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { ProjectCMSaveHistoryParameter } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/types/parameter';

const url = {
  getCMSaveHistory: (id: number) => `${projectSalesInfoURL}/${id}/cm-amount-save-history`,
  updateCMSaveHistory: (historyId: number) =>
    `${projectSalesInfoURL}/cm-amount-save-history/${historyId}`,
};

export const projectSalesInfoCMSaveHistoryApi = {
  getCMSaveHistory: async (id: number) => {
    const { data } = await axios.get(url.getCMSaveHistory(id));
    return data;
  },
  updateCMSaveHistory: async (params: ProjectCMSaveHistoryParameter) =>
    await axios.put(url.updateCMSaveHistory(params.id!), params),
};
