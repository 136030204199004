import { create } from 'zustand';

interface State {
  isModalOpen: boolean;
  setIsModalOpen: () => void;
  readOnly?: boolean;
  setReadOnly: (readOnly?: boolean) => void;
}

export { useState as useProjectSalesInfoContractEarningsState };

const useState = create<State>((set) => ({
  isModalOpen: false,
  setIsModalOpen: () => set((state) => ({ isModalOpen: !state.isModalOpen })),
  readOnly: false,
  setReadOnly: (readOnly) => set({ readOnly }),
}));
