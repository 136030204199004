import { create } from 'zustand';
import type { ECOUNT_FILE_TYPES } from '@front/src/features/accounting/features/upload/features/ecount-data/types/domain';

export { useState as useAccountingUploadEcountDataState };

interface State {
  ecountFileType: ECOUNT_FILE_TYPES | '';
  setEcountFileType: (ecountFileType) => void;
  readOnly?: boolean;
  setReadOnly: (readOnly?: boolean) => void;
}

const useState = create<State>((set) => ({
  ecountFileType: '',
  setEcountFileType: (ecountFileType) =>
    set(() => ({
      ecountFileType,
    })),
  readOnly: false,
  setReadOnly: (readOnly) => set({ readOnly }),
}));
