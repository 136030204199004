// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Xny3xf8l_ZTHHT4EglcA{font-size:13px}`, "",{"version":3,"sources":["webpack://./front/src/components/layout/add-delete-table/text.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA","sourcesContent":[".container {\n  font-size: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Xny3xf8l_ZTHHT4EglcA`
};
export default ___CSS_LOADER_EXPORT___;
