import React from 'react';
import AccountingUploadSalesInfoFeature from '@front/src/features/accounting/features/upload/features/sales-info';
import { useGetReadOnly } from '@front/src/hooks/useGetReadOnly';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';

interface Props extends SectionComponentProps {}

const ProjectSalesManagementSalesTargetExpectationFeature = ({ name, sectionId }: Readonly<Props>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const { readOnly } = useGetReadOnly();
  return (
    <Layout>
      <Header title={name} />
      <Body>
        <AccountingUploadSalesInfoFeature sectionId={sectionId} readOnly={readOnly} />
      </Body>
    </Layout>
  );
};

export default ProjectSalesManagementSalesTargetExpectationFeature;
