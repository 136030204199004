import React, { Suspense } from 'react';
import { AccountingUploadSalesInfoDownloadExcelButton } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/download-excel/components/button';
import { AccountingUploadSalesInfoDownloadExcelProvider } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/download-excel/provider/provider';
import Button from '@front/layouts/Button';

export { Widget as AccountingUploadSalesInfoDownloadExcelWidget };

const Widget = () => (
  <Suspense fallback={<Button>엑셀 내보내기</Button>}>
    <AccountingUploadSalesInfoDownloadExcelProvider>
      <AccountingUploadSalesInfoDownloadExcelButton />
    </AccountingUploadSalesInfoDownloadExcelProvider>
  </Suspense>
);
