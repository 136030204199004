import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyModalRepository } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/repository';
import { useCallback } from 'react';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonRepository } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/repository';
import { getAffiliatedCompanyPersonOptionList } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/utils';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { useLogic as useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalLogic };

const useLogic = () => {
  const { id: detailId } = useProjectSalesInfoDetailState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const {
    id: modalId,
    setIsOpen,
    setId,
  } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalState(
    useShallow((state) => ({
      id: state.id,
      setIsOpen: state.setIsOpen,
      setId: state.setId,
    }))
  );
  const { data: list } =
    projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonRepository.useListGet(detailId);
  const { data: detail, isLoading } =
    projectSalesInfoAffiliatedCompanyAffiliatedCompanyModalRepository.useDetailGet(modalId);
  const { run: onCreate, setCallback: setCreateCallback } =
    projectSalesInfoAffiliatedCompanyAffiliatedCompanyModalRepository.useCreate();
  const { run: onUpdate, setCallback: setUpdateCallback } =
    projectSalesInfoAffiliatedCompanyAffiliatedCompanyModalRepository.useUpdate();
  const handleModalClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  setCreateCallback({
    onSuccess: handleModalClose,
  });
  setUpdateCallback({
    onSuccess: () => {
      handleModalClose();
      setId(undefined);
    },
  });
  const handleCreateClick = useCallback(
    (params) => {
      onCreate({ ...params, id: detailId });
    },
    [onCreate, detailId]
  );
  const handleUpdateClick = useCallback(
    (params) => {
      onUpdate({ ...params, id: modalId });
    },
    [onUpdate, modalId]
  );
  const d = {
    detail,
    list,
    affiliatedCompanyOptionList: getAffiliatedCompanyPersonOptionList(list),
  };
  const h = {
    onCreate: handleCreateClick,
    onUpdate: handleUpdateClick,
  };
  return {
    d,
    h,
    isLoading,
    id: modalId,
  };
};
