import { create } from 'zustand';

export { useState as useProjectSalesInfoContractContractState };

interface State {
  idList: number[];
  readOnly?: boolean;
}

interface Actions {
  setIdList: (idList: number[]) => void;
  setReadOnly: (readOnly?: boolean) => void;
}

const useState = create<State & Actions>((set) => ({
  idList: [],
  setIdList: (idList) => set(() => ({ idList })),
  readOnly: false,
  setReadOnly: (readOnly) => set({ readOnly }),
}));
