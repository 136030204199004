import React, { Suspense, useCallback, useEffect } from 'react';
import useId from 'services/useId';
import useDialog from 'dialog/hook';
import type { UserVO } from 'user/domain';
import { UserId } from 'user/domain';
import UserDetail from 'user/view/Detail';
import type { AppRoute } from 'services/routes';
import { useDispatch, useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import { userAction } from 'user/action';
import type { UserChangeParameter, UserPasswordChangeParameter } from 'user/parameter';
import { initialUserParameter } from 'user/parameter';
import type { RootState } from 'services/reducer';
import { closeStatus } from 'components/DataFieldProps';
import { useNavigate } from 'react-router-dom';
import useSetMenuAndTab from '@front/src/hooks/useSetMenuAndTab';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
interface Props {
  menuId?: number | undefined;
}
function Element({ menuId }: Props) {
  const id = useId();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { detail, requestChange } = useSelector((root: RootState) => root.user);
  const { confirm, rollback, alert } = useDialog();
  const setRoleList = useCallback(() => dispatch(userAction.setRoleList()), [dispatch]);
  const change = useCallback(
    (formikProps: UserChangeParameter) => dispatch(userAction.change(formikProps)),
    [dispatch]
  );
  const invite = useCallback((props) => dispatch(userAction.invite(props)), [dispatch]);
  const sendEmail = useCallback(
    (email: UserPasswordChangeParameter) =>
      dispatch(userAction.requestEmailToChangePassword(email)),
    [dispatch]
  );

  const initPassword = useCallback(() => {
    dispatch(userAction.requestInitPassword());
  }, [dispatch]);

  const validate = (values: UserChangeParameter) => {
    const messages: string[] = [];
    if (!values.email) {
      messages.push('이메일은 필수항목 입니다');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      messages.push('이메일이 유효하지 않습니다');
    }
    if (!values.name) messages.push('이름은 필수항목 입니다');
    if (!values.role) messages.push('권한은 필수항목 입니다');
    if (!values.departmentId) messages.push('소속 조직은 필수항목 입니다');
    messages.length > 0 &&
      alert({
        title: '확인',
        children: messages,
      });
    return messages.length === 0;
  };

  const formik = useFormik<UserChangeParameter>({
    initialValues: initialUserParameter,
    onSubmit: (values) => {
      if (values.id) {
        validate(values) &&
          change({
            id: values.id,
            name: values.name,
            email: values.email,
            role: values.role,
            departmentId: values.departmentId ?? (values as unknown as UserVO).department.id,
          });
      } else {
        validate(values) &&
          invite({
            parameter: {
              email: values.email,
              name: values.name,
              employeeId: values.employeeId,
              departmentId: values.departmentId ?? (values as unknown as UserVO).department.id,
              role: values.role,
            },
            callback: () => {
              alert({
                title: '확인',
                children: '초대 메일을 발송하였습니다',
              });
              navigate('/admin/user-management');
            },
          });
      }
    },
  });

  useEffect(() => {
    dispatch(userAction.setId(id ? UserId(id) : undefined));
    setRoleList();
  }, [id]);

  useEffect(() => {
    if (detail) {
      formik.setValues({
        ...detail,
        departmentId: detail.department.id,
        edit: false,
      } as unknown as UserChangeParameter);
    } else {
      formik.setValues(initialUserParameter);
    }
  }, [detail]);

  useEffect(() => {
    closeStatus(
      requestChange,
      () => {
        alert('변경하였습니다.');
        dispatch(userAction.setId(UserId(id!)));
      },
      () => {
        formik.setSubmitting(false);
        dispatch(userAction.requestChange('idle'));
      }
    );
  }, [requestChange]);

  return (
    <FormikProvider value={formik}>
      <UserDetail
        menuId={menuId}
        onCancel={() => {
          rollback(() => {
            if (detail) {
              formik.setValues({
                ...detail,
                edit: false,
              } as unknown as UserChangeParameter);
            } else {
              navigate(-1);
            }
          });
        }}
        onPasswordChange={() => {
          confirm({
            children: '비밀번호 변경 안내 메일을 발송하겠습니까?',
            confirmText: '발송',
            afterConfirm: () => {
              if (detail && detail.username) {
                sendEmail({ username: detail.username });
              }
            },
          });
        }}
        onPasswordInit={() => {
          confirm({
            children: '해당 임직원의 비밀번호를 초기화 하시겠습니까?',
            confirmText: '확인',
            afterConfirm: () => {
              if (detail && detail.username) {
                initPassword();
              }
            },
          });
        }}
      />
    </FormikProvider>
  );
}

const Wrapper = () => {
  const { menuId } = useSetMenuAndTab();
  if (!menuId) return <></>;
  return <Element menuId={menuId} />;
};

const userDetailRoute: AppRoute = {
  path: '/admin/user-management/:id',
  element: (
    <Suspense fallback={<LoadingSpinner />}>
      <Wrapper />
    </Suspense>
  ),
};

export const userAddRoute: AppRoute = {
  path: '/admin/user-management/add',
  element: (
    <Suspense fallback={<LoadingSpinner />}>
      <Element menuId={48} />
    </Suspense>
  ),
};

export default userDetailRoute;
