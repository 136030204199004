import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import HookFormMention from '@front/src/components/mention-with-hook-form/hook-form-mention';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import WorkBasicInfoTableSearchWordRow from '@front/src/features/work/features/work/features/basic-info/components/SearchWorkRow';
import workBasicInfoQuery from '@front/src/features/work/features/work/features/basic-info/query/query';
import workBasicInfoMutation from '@front/src/features/work/features/work/features/basic-info/query/mutation';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import BackdropLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/BackdropLoadingSpinnerUI';
import TypographyUI from '@front/src/components/components-with-design/component/TypographyUI';

export default function WorkBasicInfoTable({
  readOnly,
  dataId,
  menuId,
}: Readonly<SectionComponentProps>) {
  const { data: detail } = workBasicInfoQuery.useGetOne(dataId, menuId);
  const { isLoading, onUpdate, onCreate, onDelete } = useMutationLogic({ dataId, menuId });
  const methods = useForm({
    values: getFormValues(detail),
  });
  const { getValues, handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    onUpdate({ purpose: data.purpose });
  });
  const renderPurpose = () => {
    if (readOnly) return <TypographyUI>{getValues('purpose')}</TypographyUI>;
    return (
      <HookFormMention
        name="purpose"
        userList={[]}
        onBlur={onSubmit}
        placeholder="직접 입력"
      />
    );
  };
  return (
    <TableContainerUI
      sx={{
        // maxWidth,
        position: 'relative',
      }}
    >
      <BackdropLoadingSpinnerUI
        open={isLoading}
        sx={{
          position: 'absolute',
        }}
      />
      <TableTableUI>
        <TableBodyUI>
          <FormProvider {...methods}>
            <TableRowUI>
              <TableCellUI
                isHead
                width="160px"
              >
                코드
              </TableCellUI>
              <TableCellUI align="left">{detail?.code}</TableCellUI>
            </TableRowUI>
            <WorkBasicInfoTableSearchWordRow
              onCreate={onCreate}
              onDelete={onDelete}
              readOnly={readOnly}
              dataId={dataId}
              menuId={menuId}
            />
            <TableRowUI>
              <TableCellUI isHead>업무목적</TableCellUI>
              <TableCellUI>{renderPurpose()}</TableCellUI>
            </TableRowUI>
          </FormProvider>
        </TableBodyUI>
      </TableTableUI>
    </TableContainerUI>
  );
}

function getFormValues(detail) {
  return {
    id: detail?.id,
    searchWord: '',
    purpose: detail?.purpose ?? '',
  };
}

function useMutationLogic({ dataId, menuId }) {
  const { mutate: onUpdate, isLoading: isUpdateLoading } = workBasicInfoMutation.useUpdate(
    dataId,
    menuId
  );
  const { mutate: onCreate, isLoading: isCreateLoading } =
    workBasicInfoMutation.useSearchWordCreate(dataId, menuId);
  const { mutate: onDelete, isLoading: isDeleteLoading } =
    workBasicInfoMutation.useSearchWordDelete(dataId, menuId);
  const isLoading = isUpdateLoading || isCreateLoading || isDeleteLoading;
  return {
    isLoading,
    onUpdate,
    onCreate,
    onDelete,
  };
}
