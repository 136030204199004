import type { ReactNode } from 'react';
import React from 'react';
import { Box } from '@mui/material';

export { Header as AccountingUploadSalesInfoLayoutHeader };

interface Props {
  leftComponent?: ReactNode;
  rightComponent?: ReactNode;
  children?: ReactNode;
  title?: ReactNode;
}

const Header = ({ title, children, leftComponent, rightComponent }: Props) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
    }}
  >
    {title && (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '16px',
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
        }}
      >
        {title}
      </Box>
    )}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Box sx={{}}>{leftComponent}</Box>
      <Box
        sx={{
          display: 'flex',
          gap: '12px',
        }}
      >
        {rightComponent}
      </Box>
    </Box>
    <Box
      sx={{
        display: 'flex',
        gap: '40px',
      }}
    >
      {children}
    </Box>
  </Box>
);
