import React from 'react';
import { Box } from '@mui/material';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import TextBox from '@front/layouts/Text';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';

interface Props extends Omit<CellComponentProps, 'isEditMode' | 'item'> {
  onClose: () => void;
}

export default function UIBuilderEditModalButtonSection({
  onClose,
  onSubmit,
  readOnly,
}: Readonly<Props>) {
  const handleSave = () => {
    onSubmit?.();
    onClose();
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '12px',
      }}
    >
      <TextBox variant="body2">저장하지 않은 내용은 반영되지 않습니다.</TextBox>
      <ButtonBasicUI
        shape="secondary"
        size="medium"
        onClick={onClose}
      >
        취소
      </ButtonBasicUI>
      <ButtonBasicUI
        shape="primary"
        size="medium"
        onClick={handleSave}
        disabled={readOnly}
      >
        저장
      </ButtonBasicUI>
    </Box>
  );
}
