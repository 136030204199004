import React, { memo, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import Input from '@front/layouts/Input';
import { campaignAction } from '@front/ost_campaign/action';
import type { CampaignUpdateTotalRewardParameter } from '@front/ost_campaign/parameter';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import type { CampaignId } from '@front/ost_campaign/domain';

interface Props {
  campaignId: CampaignId;
  totalReward: number;
  totalBudget?: number;
  sumAllocated: number;
}

const AllocatedBudgetInput = ({ campaignId, totalReward, totalBudget, sumAllocated }: Props) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const openSnackbar = useCallback(
    (message, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );

  const updateTotalReward = useCallback(
    (params: CampaignUpdateTotalRewardParameter) => {
      dispatch(campaignAction.updateTotalReward(params));
    },
    [dispatch]
  );

  const onBlur = useCallback(
    (e) => {
      const value = e.target.value.replace(/,/g, '');
      if (!totalBudget) {
        return;
      }
      if (value === '') {
        updateTotalReward({
          campaignId,
          totalReward: undefined,
        });
        return;
      }
      if (Number.isNaN(+value)) {
        openSnackbar('숫자만 입력 가능합니다.');
        if (inputRef.current) {
          if (totalReward) {
            inputRef.current.value = totalReward.toLocaleString();
          } else {
            inputRef.current.value = '';
          }
        }
        return;
      }
      if (totalBudget - sumAllocated + totalReward < value) {
        openSnackbar('총 예산이 부족합니다.');
        return;
      }
      updateTotalReward({
        campaignId,
        totalReward: +value,
      });
    },
    [totalReward, openSnackbar, updateTotalReward, campaignId, totalBudget, sumAllocated]
  );
  return (
    <Input
      isAmount
      key={totalReward}
      inputRef={inputRef}
      variant="outlined"
      defaultValue={totalReward?.toLocaleString()}
      onBlur={onBlur}
    />
  );
};

const OstAllocatedBudgetInput = memo(AllocatedBudgetInput);
export default OstAllocatedBudgetInput;
