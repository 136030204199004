import type { ReactNode } from 'react';
import React from 'react';
import { TableBody, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { salesInfoActivityTableHeaderNameList } from '@front/src/features/project-sales-info/features/activity/utils/constants';
import { useProjectSalesInfoActivityState } from '@front/src/features/project-sales-info/features/activity/useState';
import { useShallow } from 'zustand/react/shallow';
import { Th } from '@front/src/components/layout/table/th';
import { RowClickTableContainer } from '@front/src/components/layout/table/row-click-table-container';

export { ActivityTable as SalesInfoActivityTable };

interface Props {
  listWidget: ReactNode;
  maxWidth?: string;
}

const ActivityTable = ({ listWidget, maxWidth }: Props) => {
  const { setId } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      setId: state.setId,
    }))
  );
  return (
    <RowClickTableContainer
      setId={setId}
      sx={{
        maxHeight: '62vh',
        overflow: 'auto',
        maxWidth,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {salesInfoActivityTableHeaderNameList.map(({ name, width, colSpan }) => (
              <Th
                key={name}
                width={width}
                colSpan={colSpan}
              >
                {name}
              </Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{listWidget}</TableBody>
      </Table>
    </RowClickTableContainer>
  );
};
