import React, { ReactNode } from 'react';
import ResizableListDrawer from '@front/src/components/list-drawer/components/resize-list-drawer';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import { useShallow } from 'zustand/react/shallow';

interface Props {
  children: ReactNode;
}

export default function ResizableRightDrawer({ children }: Props) {
  const { isRightDrawerOpen } = useGlobalNavBarState(
    useShallow((state) => ({
      isRightDrawerOpen: state.isRightDrawerOpen,
    }))
  );

  return (
    <ResizableListDrawer
      anchor="right"
      isOpen={isRightDrawerOpen}
      overlay={true}
    >
      {children}
    </ResizableListDrawer>
  );
}
