import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import type { UserShortVO } from 'user/domain';

import React from 'react';
import type Page from 'type/Page';
import { OldTd, OldTh, Table } from 'layouts/Table';
import TableLayout from 'layouts/TableLayout';
import Button from 'layouts/Button';

function AddButton() {
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/admin/user-management/add');
  };
  return (
    <Button
      onClick={onClick}
      children="등록"
    />
  );
}

export interface ListProps {
  page: Page<UserShortVO> | undefined;
}

export default function ({ page }: ListProps) {
  return (
    <TableLayout
      pagination={page ? { ...page } : undefined}
      sizeFieldName="size"
      titleRightComponent={<AddButton />}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <OldTh>No.</OldTh>
              <OldTh>아이디</OldTh>
              <OldTh>이름</OldTh>
              <OldTh>이메일</OldTh>
              <OldTh>권한</OldTh>
              <OldTh>소속</OldTh>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!page || page.content.length === 0) && (
              <TableRow>
                <OldTd
                  colSpan={6}
                  children="결과가 없습니다."
                />
              </TableRow>
            )}
            {page &&
              page.content.map((item, i) => {
                const no = i + 1 + page.size * page.number;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    key={item.id}
                  >
                    <OldTd>{no}</OldTd>
                    <OldTd>
                      <Link to={`/admin/user-management/${item.id}`}>{item.username}</Link>
                    </OldTd>
                    <OldTd>{item.name}</OldTd>
                    <OldTd>{item.email}</OldTd>
                    <OldTd>{item.role.name}</OldTd>
                    <OldTd>{item.department.name}</OldTd>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </TableLayout>
  );
}
