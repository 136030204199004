import { useQuery } from 'react-query';
import type { SmProjectCollectionView } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/views';
import { projectSalesInfoContractEarningsItemApi } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/query/api';

export const projectSalesInfoContractEarningsItemQuery = {
  useContractEarningsItemGet: (itemId: number) => {
    const { data, isLoading } = useQuery<SmProjectCollectionView[]>({
      queryKey: ['project-sales-info', 'collection', 'contract-earnings', 'item', itemId],
      queryFn: () => projectSalesInfoContractEarningsItemApi.getContractEarningsItem(itemId),
    });
    return {
      data,
      isLoading,
    };
  },
};
