import { shallow } from 'zustand/shallow';
import { useCallback } from 'react';
import { projectSalesInfoEstimationComparedCreateModalRepository } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/features/create-modal/repository';
import { useProjectSalesInfoEstimationComparedCreateModalState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/features/create-modal/widgets/useState';

export { useLogic as useProjectSalesInfoEstimationComparedCreateModalLogic };

const useLogic = () => {
  const { isOpen, setIsOpen } = useProjectSalesInfoEstimationComparedCreateModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      isOpen: state.isOpen,
    }),
    shallow
  );
  const { run: onCreate, setCallback } =
    projectSalesInfoEstimationComparedCreateModalRepository.useCreate();
  setCallback({
    onSuccess: () => {
      handleClose();
    },
  });
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const h = { onCreate, onClose: handleClose };
  return {
    h,
    isOpen,
  };
};
