import { useAffiliatedCompanyDetailState } from '@front/src/features/affiliated-company/widgets/detail/useState';
import { shallow } from 'zustand/shallow';
import { useCallback } from 'react';
import { affiliatedCompanyOutlineRepository } from '@front/src/features/affiliated-company/features/outline/repository/repository';
import { affiliatedCompanyOutlineInfoRepository } from '@front/src/features/affiliated-company/features/outline/features/info/repository/repository';
import type { AffiliatedCompanyOutlineInfoUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/info/types/parameter';

export { useLogic as useAffiliatedCompanyOutlineInfoLogic };

const useLogic = (menuId) => {
  const { id } = useAffiliatedCompanyDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { data: detailOutline, isLoading } = affiliatedCompanyOutlineRepository.useOutlineGet(
    id,
    menuId
  );
  const { run: onOutlineInfoUpdate } =
    affiliatedCompanyOutlineInfoRepository.useOutlineInfoUpdate(menuId);
  const handleUpdate = useCallback(
    (params: AffiliatedCompanyOutlineInfoUpdateParameter) =>
      onOutlineInfoUpdate({
        ...params,
        id,
      }),
    [onOutlineInfoUpdate, id]
  );
  const h = {
    onOutlineInfoUpdate: handleUpdate,
  };
  const d = { detailOutline };
  return { d, h, isLoading };
};
