import { create } from 'zustand';

interface State {
  sectionId?: number;
  headerId: string;
}

interface Actions {
  setSectionId: (sectionId?: number) => void;
  setHeaderId: (headerId: string) => void;
}

export { useState as useHeaderHistoryState };

const useState = create<State & Actions>((set) => ({
  setSectionId: (sectionId) => set({ sectionId }),
  headerId: '',
  setHeaderId: (headerId) => set({ headerId }),
}));
