import React from 'react';
import { ProjectSalesInfoActivityItemProvider } from '@front/src/features/project-sales-info/features/activity/widgets/activity-item/provider/provider';
import { ProjectSalesInfoActivityItemRow } from '@front/src/features/project-sales-info/features/activity/widgets/activity-item/components/activity-item-row';

export { Widget as ProjectSalesInfoActivityItemWidget };

const Widget = () => (
  <ProjectSalesInfoActivityItemProvider>
    <ProjectSalesInfoActivityItemRow />
  </ProjectSalesInfoActivityItemProvider>
);
