import type { CSSProperties, ReactNode } from 'react';
import React from 'react';
import { TableCell } from '@mui/material';

export { ItemTableCell as AddDeleteTableItemTableCell };

interface Props {
  children: ReactNode;
  width?: string;
  height?: string;
  center?: boolean;
  sx?: CSSProperties;
}

const ItemTableCell = ({ children, width, height, sx, center = false }: Props) => (
  <TableCell
    padding="none"
    height={height}
    width={width}
    sx={{
      padding: '0 8px',
      textAlign: center ? 'center' : '',
      ...sx,
    }}
  >
    {children}
  </TableCell>
);
