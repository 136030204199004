import React from 'react';
import Button from '@front/layouts/Button';
import ModalLayout from '@front/layouts/ModalLayout';
import CampaignInfoModalForm from '@front/ost_proposal/view/modal/campaignInfo/Form';

interface Props {
  campaignInfoModalOpen: boolean;
  closeCampaignInfoModal: () => void;
}

const CampaignInfoModal = ({ campaignInfoModalOpen, closeCampaignInfoModal }: Props) => (
  <ModalLayout
    width={600}
    height={350}
    open={campaignInfoModalOpen}
    onClose={closeCampaignInfoModal}
    title={'캠페인 안내'}
    children={<CampaignInfoModalForm />}
    footer={
      <Button
        shape={'basic3'}
        onClick={closeCampaignInfoModal}
      >
        닫기
      </Button>
    }
  />
);

export default CampaignInfoModal;
