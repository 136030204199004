import React, { useCallback } from 'react';
import { useGetCellLogic } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/hooks/useGetCellLogic';
import {
  TableRowBox,
  TbcBox,
} from '@front/src/features/personal-settings-modal/features/ui-settings/components';
import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useFormContext } from 'react-hook-form';
import { useShallow } from 'zustand/react/shallow';
import { usePersonalSettingsModalUISettingsHeaderSettingsPositionState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/useState';

export { Tab as PersonalSettingsModalUISettingsHeaderSettingsTableContentTab };

const Tab = () => {
  const { watch, setValue } = useFormContext();
  const { setMenuId } = usePersonalSettingsModalUISettingsHeaderSettingsPositionState(
    useShallow((state) => ({
      setMenuId: state.setMenuId,
    }))
  );
  const tab = watch('tab');
  const { emptyRows, iconSx, getSx } = useGetCellLogic({
    key: 'tab',
    length: tab.length,
    selected: watch(`tabId`),
  });
  const handleClick = useCallback(
    (id, children) => {
      setValue('tabId', id);
      const mapped = children.map((c) => ({
        ...c,
        children: c.children,
      }));
      if (children.length === 0) {
        setValue('section', []);
        return;
      }
      if (children.some((c) => c.isSection)) {
        setValue('section', mapped);
      }
      setMenuId(id);
    },
    [setMenuId, setValue]
  );
  return (
    <>
      {tab.map((item, i) => {
        const { title, belongsToHeader, id, children } = watch(`tab.${i}`);
        return (
          <TableRowBox key={id}>
            <TbcBox
              sx={getSx(id)}
              onClick={() => handleClick(id, children)}
            >
              <Box>{title}</Box>
              {belongsToHeader && <CheckIcon sx={iconSx} />}
            </TbcBox>
          </TableRowBox>
        );
      })}
      {emptyRows}
    </>
  );
};
