import { createContext } from 'react';
import type {
  TaxAccountingCountView,
  TaxAccountingView,
} from '@front/src/features/accounting/features/settings/features/tax-accounting-account/types/view';

export { Context as AccountingSettingsTexAccountingAccountListContext };

interface State {
  list?: TaxAccountingView[];
  tableRowNum: number;
  countList?: TaxAccountingCountView;
}

const Context = createContext<State>({
  tableRowNum: 20,
});
