import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { dialogAction } from '@front/dialog/action';
import Block from '@front/dialog/view/Block';
import { useNavigate } from 'react-router-dom';

const BlockService = () => {
  const dispatch = useDispatch();
  const { blockProps } = useSelector((root: RootState) => root.dialog);
  const onClose = useCallback(() => dispatch(dialogAction.close()), [dispatch]);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (typeof blockProps !== 'undefined') {
        onClose();
        navigate('/');
      }
    }, 2000);
  }, [blockProps]);

  if (!blockProps) {
    return null;
  }
  return (
    <Block
      {...blockProps}
      open={typeof blockProps !== 'undefined'}
    />
  );
};

export default BlockService;
