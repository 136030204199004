import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';

import type { VoteMemoAddParameter } from '@front/ost_vote/parameter';
import VoteMemoForm from '@front/ost_vote/view/memo/Form';
import { voteAction } from '@front/ost_vote/action';
import type { RootState } from '@front/services/reducer';

interface Props {
  setFormHeight: (height: number) => void;
}

const VoteFormService = ({ setFormHeight }: Props) => {
  const dispatch = useDispatch();

  const [contributorViewList] = useSelector(
    (root: RootState) => [root.proposal.detail?.contributorViewList],
    shallowEqual
  );
  const addMemo = useCallback(
    (params: VoteMemoAddParameter) => dispatch(voteAction.addMemo(params)),
    [dispatch]
  );
  const formik = useFormik<VoteMemoAddParameter>({
    initialValues: {} as VoteMemoAddParameter,
    onSubmit: (values) => {
      addMemo(values);
      formik.resetForm();
    },
  });
  return (
    <FormikProvider value={formik}>
      <VoteMemoForm
        setFormHeight={setFormHeight}
        contributorList={contributorViewList?.map((contributor) => contributor.contributor?.id)}
      />
    </FormikProvider>
  );
};

export default VoteFormService;
