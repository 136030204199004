import type { ReactNode } from 'react';
import React from 'react';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';

export { Table as AccountingSettingsManagementAccountingAccountTableLayoutTable };

interface Props {
  sx?: SxProps<Theme>;
  children?: ReactNode;
}

const Table = ({ sx, children }: Props) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '1100px',
      outline: `1px solid ${ColorPalette._e4e9f2}`,
      borderRadius: '4px',
      ...sx,
    }}
  >
    {children}
  </Box>
);
