import { createContext } from 'react';

export { Context as AccountingSettingsManagementAccountingAccountContext };

interface State {
  readOnly?: boolean;
}

const Context = createContext<State>({
  readOnly: false,
});
