import { affiliatedCompanyPersonMeetingHistoryQuery } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/meeting-history/query/query';

export const affiliatedCompanyPersonMeetingHistoryRepository = {
  usePersonMeetingHistoryListGet: (id?: number, menuId?) => {
    const { data, isLoading } =
      affiliatedCompanyPersonMeetingHistoryQuery.usePersonMeetingHistoryListGet(id, menuId);
    return {
      data,
      isLoading,
    };
  },
};
