import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workOutputBundleMutation from '@front/src/features/work/features/work/features/output-bundle/query/mutation';

const useWorkOutputBundleCreate = (workId: number, menuId) => {
  const callback = getNoOpCallback();
  const { mutate, isLoading } = workOutputBundleMutation.useCreate(workId, menuId);
  return {
    run: (params) => mutate(params, callback),
    setCallback: getSetCallbackFunc(callback),
    isLoading,
  };
};

export default useWorkOutputBundleCreate;
