import React from 'react';
import { Box } from '@mui/material';
import PageLayout from '@front/layouts/PageLayout';
import { isMobileDevice } from '@front/util/PwaUtil';
import { useMobileMenuState } from '@front/mobile-menu/useState';

interface Props {
  children: React.ReactNode;
  titleRightComponent: React.ReactNode;
  title?: string;
}

function DetailLayout({ children, titleRightComponent }: Props) {
  const isMemo = useMobileMenuState((state) => state.isMemo);
  return (
    <PageLayout
      titleRightComponent={titleRightComponent}
      zeroContentPaddingTop={true}
      body={
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100dvh - 100px)',
            paddingTop: isMobileDevice() && !isMemo ? '3.5dvh' : '0px',
          }}
        >
          {children}
        </Box>
      }
    />
  );
}

export default DetailLayout;
