import React, { useCallback, useContext } from 'react';
import { useCustomDialog } from '@front/src/features/dialog';
import Button from '@front/layouts/Button';
import { AffiliatedCompanyPersonDetailModalContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/context';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { DeleteButton as AffiliatedCompanyPersonDeleteButton };

const DeleteButton = () => {
  const { confirm } = useCustomDialog();
  const {
    h: { onDelete },
    personId,
  } = useContext(AffiliatedCompanyPersonDetailModalContext);
  const handleClick = useCallback(() => {
    confirm({
      confirmText: '확인',
      children: '정말로 삭제하시겠습니까?',
      afterConfirm: () => {
        if (typeof personId === 'undefined') {
          return;
        }
        onDelete(personId);
      },
    });
  }, [confirm]);
  const { readOnly } = useAffiliatedCompanyPersonState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Button
      shape="basic3"
      onClick={handleClick}
      disabled={readOnly}
    >
      삭제
    </Button>
  );
};
