import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { ProjectEstimationView } from '@front/src/features/project-sales-info/features/estimation/features/estimation/types/view';
import type { ProjectEstimationParameter } from '@front/src/features/project-sales-info/features/estimation/features/estimation/types/parameter';
import type { DefaultFunction } from '@front/src/types';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';
import type { ProjectExperimentTargetByComplexInformationView } from '@front/src/features/project-sales-info/features/subject-review/types/view';

export { context as ProjectSalesInfoEstimationEstimationUpdateModalContext };

interface State {
  d: {
    detail?: ProjectEstimationView;
    categoryList?: VariableInformationView[];
    experiment?: ProjectExperimentTargetByComplexInformationView;
  };
  h: {
    onUpdate: (params: ProjectEstimationParameter) => void;
    onClose: DefaultFunction;
  };
}

const context = createContext<State>({
  d: {},
  h: {
    onUpdate: noOp,
    onClose: noOp,
  },
});
