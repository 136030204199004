// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h9mY4Iip8gOST1J7wmTu{display:flex;justify-content:center;flex-direction:column;align-items:center;padding:10px;gap:10px;width:100%}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/bid/features/bid-information/components/layout/layout.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,qBAAA,CACA,kBAAA,CACA,YAAA,CACA,QAAA,CACA,UAAA","sourcesContent":[".layout {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  padding: 10px;\n  gap: 10px;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `h9mY4Iip8gOST1J7wmTu`
};
export default ___CSS_LOADER_EXPORT___;
