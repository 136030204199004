import React, { memo, useMemo } from 'react';
import CircularProgress from '@front/components/CircularProgress';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { AffiliatedCompanyPersonDetailModalOutlineBasicContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/basic/widgets/context';
import { AffiliatedCompanyPersonDetailModalOutlineBasic } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/basic/components/basic';
import { useAffiliatedCompanyPersonDetailModalOutlineBasicLogic } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/basic/widgets/useLogic';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AffiliatedCompanyPersonDetailModalOutlineBasicWidget = ({
  name,
  menuId,
}: Readonly<Props>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    h: { onPersonOutlineBasicUpdate },
    d: { personOutlineBasic },
    isLoading,
  } = useAffiliatedCompanyPersonDetailModalOutlineBasicLogic(menuId);
  const value = useMemo(
    () => ({
      d: { personOutlineBasic },
      h: { onPersonOutlineBasicUpdate },
    }),
    [onPersonOutlineBasicUpdate, personOutlineBasic]
  );
  if (typeof personOutlineBasic === 'undefined' || isLoading) {
    return <Spinner />;
  }
  return (
    <Layout>
      <Header title={name} />
      <Body>
        <AffiliatedCompanyPersonDetailModalOutlineBasicContext.Provider value={value}>
          <AffiliatedCompanyPersonDetailModalOutlineBasic />
        </AffiliatedCompanyPersonDetailModalOutlineBasicContext.Provider>
      </Body>
    </Layout>
  );
};

const Spinner = memo(() => {
  const { Layout, Header } = AddDeleteTable;
  return (
    <Layout height="886px">
      <Header title="기본정보" />
      <CircularProgress size={30} />
    </Layout>
  );
});

Spinner.displayName = 'AffiliatedCompanyPersonDetailModalOutlineBasicWidgetSpinner';

export default AffiliatedCompanyPersonDetailModalOutlineBasicWidget;
